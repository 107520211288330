import React from "react"
import { graphql, useFragment } from "react-relay"
import { BlockchainActionModalContent } from "@/components/blockchain/BlockchainActionList/BlockchainActionModalContent.react"
import { OrderDataHeader } from "@/components/blockchain/orders/OrderDataHeader"
import { OrdersHeaderData } from "@/components/blockchain/orders/OrdersHeaderData"
import { trackOpenOnChainCancellationModal } from "@/features/cancel-orders/utils/analytics"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { CancelOrderActionOnChainContent_action$key } from "@/lib/graphql/__generated__/CancelOrderActionOnChainContent_action.graphql"

type CancelOrderActionOnChainContentProps = {
  action: CancelOrderActionOnChainContent_action$key
  transactionHash: string | undefined
  attemptAction: () => Promise<void>
  progress: number
  numActions: number
}

export const CancelOrderActionOnChainContent = ({
  action: actionDataKey,
  transactionHash,
  attemptAction,
  progress,
  numActions,
}: CancelOrderActionOnChainContentProps) => {
  const t = useTranslate("components")

  useMountEffect(() => {
    trackOpenOnChainCancellationModal()
  })

  const { ordersData } = useFragment(
    graphql`
      fragment CancelOrderActionOnChainContent_action on CancelOrderActionType {
        ordersData {
          side
          orderType
          ...OrderDataHeader_order
          ...OrdersHeaderData_orders
        }
      }
    `,
    actionDataKey,
  )

  const isMultipleOrders = ordersData.length > 1
  const firstOrderData = ordersData[0]

  const side = firstOrderData.side
  const isEnglish = firstOrderData.orderType === "ENGLISH"
  const isCriteria = firstOrderData.orderType === "CRITERIA"

  // TODO: Cleanup translations
  const offerTerm =
    side === "ASK"
      ? t(
          "orders.listing",
          {
            0: "listings",
            one: "listing",
            other: "listings",
          },
          { count: ordersData.length },
        )
      : isEnglish
      ? t("orders.bid", "bid")
      : isCriteria
      ? t("orders.collection", "collection offer")
      : t("orders.offer", "offer")

  return (
    <BlockchainActionModalContent>
      <BlockchainActionModalContent.Header>
        <BlockchainActionModalContent.Title>
          {t("blockchain.cancel.title", "Cancel {{offerTerm}}", { offerTerm })}
        </BlockchainActionModalContent.Title>
      </BlockchainActionModalContent.Header>
      <BlockchainActionModalContent.Body>
        {isMultipleOrders ? (
          <OrdersHeaderData
            orders={ordersData}
            transactionHash={transactionHash}
          />
        ) : (
          <OrderDataHeader
            dataKey={firstOrderData}
            transactionHash={transactionHash}
          />
        )}

        <BlockchainActionModalContent.Body.Separator />
        <BlockchainActionModalContent.Body.GoToWallet />
        <BlockchainActionModalContent.Body.Text>
          {t(
            "blockchain.cancel.prompt",
            "You'll be asked to review and confirm this cancellation from your wallet.",
          )}
          {isMultipleOrders ? (
            <>
              {" "}
              {side === "ASK"
                ? t(
                    "blockchain.cancel.multipleOrders.listings",
                    "This will cancel your listings.",
                  )
                : isEnglish
                ? t(
                    "blockchain.cancel.multipleOrders.bids",
                    "This will cancel your bids.",
                  )
                : t(
                    "blockchain.cancel.multipleOrders.offers",
                    "This will cancel your offers.",
                  )}
            </>
          ) : isCriteria ? (
            <>
              {" "}
              {t(
                "orders.cancelOfferWarning",
                "This will cancel your offer on all items that were included in this collection offer.",
              )}
            </>
          ) : null}

          {numActions > 1 && (
            <>
              {" "}
              {t(
                "orders.multipleTransactions",
                "You have {{numTransactions}} transactions to cancel all your orders.",
                { numTransactions: numActions },
              )}
            </>
          )}
        </BlockchainActionModalContent.Body.Text>
        {!progress && (
          <BlockchainActionModalContent.Body.Button onClick={attemptAction}>
            {t("blockchain.cancel.cta", "Continue")}
          </BlockchainActionModalContent.Body.Button>
        )}
      </BlockchainActionModalContent.Body>
    </BlockchainActionModalContent>
  )
}
