import { useCallback, useEffect, useState } from "react"
import { isEqual } from "lodash"
import { GraphQLTaggedNode, VariablesOf } from "react-relay"
import {
  Disposable,
  Subscription,
  OperationType,
  CacheConfig,
} from "relay-runtime"
import { fetch } from "@/lib/graphql/graphql"
import { useIsMountedRef } from "./useIsMounted"
import { useMountEffect } from "./useMountEffect"

type Options = {
  skip?: boolean
  fetchConfig?: CacheConfig
}

/**
 * Executes a query lazily but does not cause the rendering component to suspend.
 *
 * This is useful in cases where suspending would hurt UX, but ideally it would
 * not be necessary if our components executed queries and suspended at the right
 * level in the component tree.
 *
 * Exposed a refetch method to execute the query again with different arguments
 * that are merged with the arguments of the previous execution and a isLoading
 * flag to determine the loading state (since it does not suspend)
 *
 * @param gqlQuery Query to be executed
 * @param variables
 * @param fetchConfig
 * @returns [data, refetch, isLoading, subscription]
 */
export const useNoSuspenseLazyLoadQuery = <TQuery extends OperationType>(
  gqlQuery: GraphQLTaggedNode,
  variables: VariablesOf<TQuery>,
  { fetchConfig = {}, skip }: Options = {},
) => {
  const [queryArgs, setQueryArgs] = useState({
    variables,
    fetchConfig,
  })
  const [data, setData] = useState<TQuery["response"] | null>(null)
  const [isLoading, setIsLoading] = useState(!skip)
  const [disposable, setDisposable] = useState<Disposable>()
  const [subscription, setSubscription] = useState<Subscription>()
  const isMountedRef = useIsMountedRef()

  const refetch = useCallback(
    async (
      variables: Partial<VariablesOf<OperationType>>,
      fetchConfig?: CacheConfig,
    ) => {
      if (skip) {
        return
      }
      const mergedVariables = { ...queryArgs.variables, ...variables }
      const mergedFetchConfig = { ...queryArgs.fetchConfig, ...fetchConfig }

      setIsLoading(true)
      const [data, disposable, subscription] = await fetch<TQuery>(
        gqlQuery,
        mergedVariables,
        mergedFetchConfig,
        undefined,
        { retain: true },
      )
      if (isMountedRef.current) {
        setQueryArgs({
          variables: mergedVariables,
          fetchConfig: mergedFetchConfig,
        })
        setData(data)
        setDisposable(disposable)
        setIsLoading(false)
        setSubscription(subscription)
      }
    },
    [gqlQuery, queryArgs, skip, isMountedRef],
  )

  useMountEffect(() => {
    refetch(variables, fetchConfig)
  })

  useEffect(() => {
    const queryArgsFromProps = { variables, fetchConfig }
    if (!isEqual(queryArgsFromProps, queryArgs)) {
      refetch(variables, fetchConfig)
      setQueryArgs(queryArgsFromProps)
    }
    // Only run when query args from props change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables, fetchConfig])

  useEffect(() => {
    return () => {
      disposable?.dispose()
      subscription?.unsubscribe()
    }
  }, [disposable, subscription])

  return [data, refetch, isLoading, subscription] as const
}
