import React, { Fragment, useContext, useMemo } from "react"
import { InternalContext } from "next-translate/I18nProvider"
import useTranslation from "next-translate/useTranslation"
import { IS_DEVELOPMENT, IS_PRODUCTION } from "@/constants/environment"
import { trackMissingNamespace } from "@/lib/analytics/events/i18nEvents"

type TranslationKey = string

type PluralText = {
  "0": string
  one: string
  other: string
}

type TranslateOptions = {
  forceString?: boolean
}

// When you want to use a new namespace you need to add it to this type list

type Namespace =
  | "about"
  | "account"
  | "activity"
  | "adobe"
  | "assets"
  | "bulkList"
  | "bulkPurchase"
  | "bulkAcceptOffers"
  | "bundle"
  | "careers"
  | "categorization"
  | "category"
  | "cointracker"
  | "collection"
  | "collectionEdit"
  | "collections"
  | "collectionOffers"
  | "common"
  | "components"
  | "designSystem"
  | "drop"
  | "dropCalendar"
  | "explore"
  | "geoUnavailable"
  | "home"
  | "hooks"
  | "login"
  | "mock"
  | "modals"
  | "moonpay"
  | "orders"
  | "owners"
  | "partnership"
  | "pendingTransaction"
  | "phoenix"
  | "rankings"
  | "redeem"
  | "sell"
  | "settings"
  | "solana"
  | "statsV2"
  | "traits"
  | "transfers"
  | "ventures"
  | "watchlist"
  | "deals"
  | "mediaAndMetadataReveal"
  | "creatorStudio"
  | "jsonRpcError"

export const useTranslate = (defaultNS: Namespace) => {
  const innerT = useTranslation(defaultNS).t

  const i18nContext = useContext(InternalContext)

  if (
    process.env.I18N_DEBUG_MODE === "1" &&
    !(i18nContext.ns as Record<string, string>)[defaultNS]
  ) {
    if (IS_DEVELOPMENT) {
      throw new Error(`Missing namespace: ${defaultNS}`)
    } else if (IS_PRODUCTION) {
      trackMissingNamespace({
        namespace: defaultNS,
      })
    }
  }

  return useMemo(() => {
    function t(key: TranslationKey, defaultValue: string): string
    function t(
      key: TranslationKey,
      defaultValue: string,
      variables: Record<string, React.ReactNode>,
    ): JSX.Element
    function t(
      key: TranslationKey,
      defaultValue: string,
      variables: Record<string, string | number>,
      options: { forceString: true },
    ): string
    function t(
      key: TranslationKey,
      defaultValue: PluralText,
      variables: Record<string, React.ReactNode> & { count: number },
    ): JSX.Element
    function t(
      key: TranslationKey,
      defaultValue: string,
      variables: Record<string, string | number> & { count: number },
      options: { forceString: true },
    ): string
    function t(
      key: TranslationKey,
      defaultValue: PluralText,
      variables: Record<string, string | number> & { count: number },
      options: { forceString: true },
    ): string
    function t(
      key: TranslationKey,
      defaultValue: string | PluralText,
      variables?: Record<string, React.ReactNode>,
      options?: TranslateOptions,
    ) {
      if (
        typeof variables === "undefined" &&
        typeof defaultValue === "string"
      ) {
        return innerT(key, {}, { default: defaultValue })
      }

      // If we have non-primitive variables to interpolate, use placeholders
      const safeVariables = variables || {}
      const placeholders = Object.fromEntries(
        Object.entries(safeVariables).map(([key, value]) => [
          key,
          ["string", "number"].includes(typeof value) ? value : `{{${key}}}`,
        ]),
      )

      // Run the t function but leave any placeholders un-interpolated
      let uninterpolatedText = innerT(key, placeholders, {
        default: defaultValue,
      }) as string | PluralText

      if (typeof uninterpolatedText === "object" && "count" in safeVariables) {
        uninterpolatedText =
          uninterpolatedText[
            safeVariables.count === 0
              ? "0"
              : safeVariables.count === 1
              ? "one"
              : "other"
          ]
      }

      // At this point, uninterpolatedText is always a string
      uninterpolatedText = uninterpolatedText as string

      // Build a list of interpolated fragments from this output
      const interpolatedFragments: Array<unknown> = []

      const variableRegex = /{{([^(}})]*)}}/gm
      let match: string[] | null
      let startIndex = 0
      while ((match = variableRegex.exec(uninterpolatedText))) {
        if (safeVariables[match[1]] !== undefined) {
          const beforeFragment = uninterpolatedText
            .slice(startIndex, variableRegex.lastIndex)
            .replace(match[0], "")

          if (beforeFragment.length) {
            interpolatedFragments.push(beforeFragment)
          }

          interpolatedFragments.push(safeVariables[match[1]])
          startIndex = variableRegex.lastIndex
        }
      }
      if (startIndex < uninterpolatedText.length) {
        interpolatedFragments.push(uninterpolatedText.slice(startIndex))
      }

      return options?.forceString
        ? interpolatedFragments.join("")
        : React.createElement(
            Fragment,
            {},
            ...(interpolatedFragments as React.ReactNode[]),
          )
    }
    return t
  }, [innerT])
}
