import React, { Suspense } from "react"
import { Flex, Text } from "@opensea/ui-kit"
import { ErrorBoundary } from "@sentry/nextjs"
import { useFragment, useLazyLoadQuery } from "react-relay"
import styled from "styled-components"
import { Placement } from "tippy.js"
import { AssetMedia } from "@/components/assets/AssetMedia"
import AccountBadge from "@/components/common/AccountBadge.react"
import { CardTooltipContent } from "@/components/common/CardTooltipContent.react"
import { Link } from "@/components/common/Link"
import { Overflow } from "@/components/common/Overflow"
import { PreviewList } from "@/components/common/PreviewList.react"
import { VerifiedIcon } from "@/components/common/VerifiedIcon.react"
import { IS_SERVER } from "@/constants/environment"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { Tooltip } from "@/design-system/Tooltip"
import { useRouter } from "@/hooks/useRouter"
import { useTranslate } from "@/hooks/useTranslate"
import { CollectionTooltip_ownerInfo$key } from "@/lib/graphql/__generated__/CollectionTooltip_ownerInfo.graphql"
import { CollectionTooltipQuery } from "@/lib/graphql/__generated__/CollectionTooltipQuery.graphql"
import { getNodes, graphql } from "@/lib/graphql/graphql"
import { formatAddress } from "@/lib/helpers/address"
import { getCollectionStats } from "@/lib/helpers/collection"
import { getItemUrl } from "@/lib/helpers/item"
import { truncateText } from "@/lib/helpers/stringUtils"
import { captureNoncriticalError } from "@/lib/sentry"

type Props = {
  slug: string
  children: React.ReactNode
  disabled?: boolean
  placement?: Placement
}

export const CollectionTooltip = ({
  slug,
  children,
  disabled,
  placement,
}: Props) => {
  const { pathname } = useRouter()
  return (
    <Tooltip
      animation="fade-vertical"
      appendTo={IS_SERVER ? undefined : document.body}
      arrow={false}
      content={
        <ErrorBoundary
          fallback={({ error }) => {
            captureNoncriticalError(error, { extras: { slug } })
            return <CollectionTooltipError />
          }}
        >
          <Suspense fallback={<CardTooltipContent.Skeleton />}>
            <CollectionTooltipContent slug={slug} />
          </Suspense>
        </ErrorBoundary>
      }
      contentPadding={0}
      disabled={pathname.includes("/collection/") || disabled}
      interactive
      maxWidth={332}
      placement={placement}
      touch={false}
    >
      <Block as="span">{children}</Block>
    </Tooltip>
  )
}

const CollectionTooltipContent = ({ slug }: Pick<Props, "slug">) => {
  const data = useLazyLoadQuery<CollectionTooltipQuery>(
    graphql`
      query CollectionTooltipQuery($slug: CollectionSlug!) {
        collection(collection: $slug) {
          name
          imageUrl
          isCollectionOffersEnabled
          verificationStatus
          owner {
            ...CollectionTooltip_ownerInfo
          }
          ...collection_poll_stats
          ...collection_live_stats
          ...collection_live_listing_stats
        }
        collectionItems(collections: [$slug], first: 3, resultType: ASSETS) {
          edges {
            node {
              ... on AssetType {
                id
                name
                ...AssetMedia_asset
                ...item_url
              }
            }
          }
        }
      }
    `,
    { slug },
  )

  if (!data.collection) {
    return null
  }
  const collection = data.collection
  const { name, imageUrl, isCollectionOffersEnabled, owner } = collection
  const { floor, floorSymbol, volume, volumeSymbol, offer, offerSymbol } =
    getCollectionStats(collection, collection, collection)

  const collectionItems = getNodes(data.collectionItems)

  return (
    <CardTooltipContent
      imageAltText={`collection ${name} logo`}
      imageUrl={imageUrl}
      lowerContent={
        data.collectionItems.edges.length > 0 ? (
          <PreviewList count={collectionItems.length}>
            {collectionItems.map(item => (
              <PreviewList.Item key={item.id}>
                <Link href={getItemUrl(item)}>
                  <AssetMedia
                    alt={item.name ?? ""}
                    asset={item}
                    rawImage
                    size={100}
                    width={100}
                  />
                </Link>
              </PreviewList.Item>
            ))}
          </PreviewList>
        ) : undefined
      }
      mainContent={
        <>
          <Flex className="items-center">
            <Text className="text-start" size="medium" weight="semibold">
              <Flex className="max-w-[238px] items-center">
                <Overflow>{name}</Overflow>
                {collection.verificationStatus === "VERIFIED" && (
                  <VerifiedIconContainer>
                    <VerifiedIcon size="small" />
                  </VerifiedIconContainer>
                )}
              </Flex>
            </Text>
          </Flex>
          <Flex className="w-full">{owner && <OwnerInfo owner={owner} />}</Flex>
        </>
      }
      middleContent={
        <CardTooltipContent.StatsWrapper>
          <CardTooltipContent.Stat
            subtitle="volume"
            symbol={volumeSymbol}
            title={volume}
          />
          <CardTooltipContent.Stat
            subtitle="floor"
            symbol={floorSymbol}
            title={floor}
          />
          {isCollectionOffersEnabled ? (
            <CardTooltipContent.Stat
              subtitle="best offer"
              symbol={offerSymbol}
              title={offer}
            />
          ) : null}
        </CardTooltipContent.StatsWrapper>
      }
      width={332}
    />
  )
}

const CollectionTooltipError = () => {
  const t = useTranslate("components")
  return (
    <CardTooltipContent.Error>
      {t(
        "collectionTooltip.error",
        "There has been an issue fetching this data. Please refresh and try again.",
      )}
    </CardTooltipContent.Error>
  )
}

type OwnerInfoProps = {
  owner: CollectionTooltip_ownerInfo$key
}

const OwnerInfo = ({ owner: ownerKey }: OwnerInfoProps) => {
  const t = useTranslate("components")
  const owner = useFragment(
    graphql`
      fragment CollectionTooltip_ownerInfo on AccountType {
        address
        isCompromised
        config
        user {
          publicUsername
        }
        ...wallet_accountKey
      }
    `,
    ownerKey,
  )

  const { isActiveAccount } = useWallet()
  const { address, user, config, isCompromised } = owner
  const truncatedPublicUsername = user?.publicUsername
    ? truncateText(user.publicUsername, 21)
    : undefined

  const displayText = isActiveAccount(owner)
    ? "you"
    : truncatedPublicUsername || formatAddress(address)

  return (
    <Flex className="max-w-[238px] items-center">
      <Text asChild color="secondary" size="small">
        <p>
          <Overflow>{t("collections.tooltip.byText", displayText)}</Overflow>
        </p>
      </Text>
      <AccountBadge
        config={config}
        isCompromised={isCompromised}
        showTooltip={false}
        variant="tiny"
      />
    </Flex>
  )
}

const VerifiedIconContainer = styled.div`
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
`
