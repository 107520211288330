import { useCallback } from "react"
import { useAppContext } from "./useAppContext"

export const useRefetch = () => {
  const { refetchPublisher } = useAppContext()

  const refetch = useCallback(() => {
    refetchPublisher.publish()
  }, [refetchPublisher])

  return refetch
}
