import {
  Middleware,
  RelayNetworkLayerResponse,
} from "react-relay-network-modern"
import signatures from "@/lib/graphql/signatures.json"

const typedSignatures: Record<string, string | undefined> = signatures

export const getSingleSignedQuery = (query: string) => {
  const signedQuery = typedSignatures[query]
  if (!signedQuery) {
    throw new Error(`Signature missing from generated file ${query}`)
  }
  return signedQuery
}

export const getSignedQuery = (query: string) => {
  let signedQuery: string

  const batchSplit = query.split("BATCH_REQUEST:")
  if (batchSplit.length > 1) {
    const queries = batchSplit[1].split(":")
    signedQuery = JSON.stringify(queries.map(getSingleSignedQuery))
  } else {
    signedQuery = getSingleSignedQuery(query)
  }

  return signedQuery
}

const signQueryMiddleware = (): Middleware => {
  return next =>
    async (req): Promise<RelayNetworkLayerResponse> => {
      const query = req.getID()
      const signedQuery = getSignedQuery(query)
      req.fetchOpts.headers["x-signed-query"] = signedQuery
      return next(req)
    }
}

export default signQueryMiddleware
