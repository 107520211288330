/**
 * @generated SignedSource<<710e09157c1bbc7f5c1b0862bd2193e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BulkPurchaseErrorReason = "ORDER_UNAVAILABLE" | "ORDER_UPDATED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useTotalPrice_errors$data = ReadonlyArray<{
  readonly originalOrder: {
    readonly relayId: string;
  };
  readonly reason: BulkPurchaseErrorReason;
  readonly updatedOrder: {
    readonly payment: {
      readonly symbol: string;
      readonly " $fragmentSpreads": FragmentRefs<"TokenPricePayment">;
    };
    readonly perUnitPriceType: {
      readonly unit: string;
      readonly usd: string;
    };
    readonly relayId: string;
  } | null;
  readonly " $fragmentType": "useTotalPrice_errors";
}>;
export type useTotalPrice_errors$key = ReadonlyArray<{
  readonly " $data"?: useTotalPrice_errors$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTotalPrice_errors">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "useTotalPrice_errors",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderV2Type",
      "kind": "LinkedField",
      "name": "originalOrder",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderV2Type",
      "kind": "LinkedField",
      "name": "updatedOrder",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceType",
          "kind": "LinkedField",
          "name": "perUnitPriceType",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "usd",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unit",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PaymentAssetType",
          "kind": "LinkedField",
          "name": "payment",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "kind": "InlineDataFragmentSpread",
              "name": "TokenPricePayment",
              "selections": [
                (v1/*: any*/)
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BulkPurchaseErrorType",
  "abstractKey": null
};
})();

(node as any).hash = "f910ccec8bf0d7d6d69e660527f649f7";

export default node;
