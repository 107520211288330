import React from "react"
import {
  CenterAligned,
  Skeleton,
  SkeletonProps,
  classNames,
} from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { CSSProperties } from "styled-components"
import { TokenPrice } from "@/components/assets/price/TokenPrice.react"
import { FundsDisplay_walletFunds$key } from "@/lib/graphql/__generated__/FundsDisplay_walletFunds.graphql"
import { bn } from "@/lib/helpers/numberUtils"

type FundsDisplayProps = {
  walletFunds: FundsDisplay_walletFunds$key
}

const FundsDisplayBase = ({
  walletFunds: walletFundsDataKey,
}: FundsDisplayProps) => {
  const { symbol, quantity } = useFragment<FundsDisplay_walletFunds$key>(
    graphql`
      fragment FundsDisplay_walletFunds on WalletFundsType {
        symbol
        quantity
      }
    `,
    walletFundsDataKey,
  )

  // Adjust symbol name for LSOL to display as SOL
  const lsolAdjustedSymbol = symbol === "LSOL" ? "SOL" : symbol

  return (
    <TokenPrice
      color="inherit"
      fontWeight="inherit"
      price={bn(quantity)}
      symbol={lsolAdjustedSymbol}
      symbolVariant="raw"
    />
  )
}

const FundsDisplaySkeleton = ({
  height,
  className,
  ...restProps
}: Omit<SkeletonProps, "height"> & { height?: CSSProperties["height"] }) => (
  <CenterAligned style={{ height: height ?? "28px" }}>
    <Skeleton.Line
      className={classNames("w-[100px]", className)}
      {...restProps}
    />
  </CenterAligned>
)

export const FundsDisplay = Object.assign(FundsDisplayBase, {
  Skeleton: FundsDisplaySkeleton,
})
