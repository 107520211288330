import { get } from "lodash"

type DetectProviderParams<Key extends keyof Window> = {
  timeout?: number
  key: Key
  isInstalled: (wallet: Window[Key] | undefined) => boolean
  initializationEvent?: string
}

export const detectProvider = <
  Key extends keyof Window,
  Provider = Window[Key],
>({
  timeout = 3000,
  key,
  isInstalled,
  initializationEvent,
}: DetectProviderParams<Key>) => {
  let injected = false
  return new Promise<Provider | undefined>(resolve => {
    const handleWallet = () => {
      if (injected) {
        return
      }

      const wallet = get(window, key)
      if (isInstalled(wallet)) {
        injected = true
        resolve(wallet)
      } else {
        resolve(undefined)
      }
    }

    if (get(window, key)) {
      handleWallet()
    } else {
      const timeoutId = setTimeout(handleWallet, timeout)

      if (initializationEvent) {
        const handleInitializationEvent = () => {
          handleWallet()
          window.removeEventListener(
            initializationEvent,
            handleInitializationEvent,
          )
        }

        window.addEventListener(
          initializationEvent,
          handleInitializationEvent,
          { once: true },
        )
      } else if (document.readyState !== "complete") {
        document.addEventListener("readystatechange", function handler() {
          if (document.readyState === "complete") {
            clearTimeout(timeoutId)
            handleWallet()
            document.removeEventListener("readystatechange", handler)
          }
        })
      }
    }
  })
}
