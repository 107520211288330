import React from "react"
import { Dropdown, DropdownContentProps } from "@/design-system/Dropdown"
import { LinkItem } from "@/lib/helpers/links"
import { $nav_height } from "@/styles/variables"
import { NavbarItem } from "../Navbar.react"

type NavbarDropdownProps = Omit<DropdownContentProps, "content" | "items"> & {
  items: readonly NavbarItem[]
}

export const NavbarDropdown = ({
  items,
  children,
  ...restDropdownProps
}: NavbarDropdownProps) => {
  return (
    <Dropdown
      animation="shift-away"
      delay={[150, 150]}
      maxHeight={`calc(100vh - ${$nav_height})`}
      trigger="mouseenter focus"
      {...restDropdownProps}
      content={({ close, List, Item }) => (
        <List>
          {items.map((item: LinkItem | React.ReactElement) =>
            "label" in item ? (
              <Item
                href={item.url}
                key={item.url}
                onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                  item.onClick?.(e)
                  close()
                }}
              >
                <Item.Content>
                  <Item.Title>{item.label}</Item.Title>
                </Item.Content>
              </Item>
            ) : (
              item
            ),
          )}
        </List>
      )}
    >
      {children}
    </Dropdown>
  )
}
