/**
 * @generated SignedSource<<97c2e7bdd7cfdae84b9694bd92135be8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type challengeLoginMessageQuery$variables = {
  address: string;
};
export type challengeLoginMessageQuery$data = {
  readonly auth: {
    readonly loginMessage: string;
  };
};
export type challengeLoginMessageQuery = {
  response: challengeLoginMessageQuery$data;
  variables: challengeLoginMessageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "address"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuthType",
    "kind": "LinkedField",
    "name": "auth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "address",
            "variableName": "address"
          }
        ],
        "kind": "ScalarField",
        "name": "loginMessage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "challengeLoginMessageQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "challengeLoginMessageQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "483ec072c58ef65491363cdbfa9b428b",
    "id": null,
    "metadata": {},
    "name": "challengeLoginMessageQuery",
    "operationKind": "query",
    "text": "query challengeLoginMessageQuery(\n  $address: AddressScalar!\n) {\n  auth {\n    loginMessage(address: $address)\n  }\n}\n"
  }
};
})();

(node as any).hash = "83c2e19c64f8831b76f44e54764ad847";

export default node;
