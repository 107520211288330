import React from "react"
import { CenterAligned, Spinner } from "@opensea/ui-kit"
import styled from "styled-components"

type LoadingProps = {
  relative?: boolean
}

const Loading = ({ relative }: LoadingProps) => {
  const renderSpinner = () => {
    return (
      <CenterAligned>
        <Spinner size="medium" />
      </CenterAligned>
    )
  }

  if (relative) {
    return <SpinnerContainer>{renderSpinner()}</SpinnerContainer>
  }

  return renderSpinner()
}

export default Loading

const SpinnerContainer = styled.div`
  /** Use this class on a div around a Loading element
 * to make it a block inline on the page */
  position: relative;
  width: 100%;
  height: 150px;
`
