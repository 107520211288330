import { swapActionsQuery } from "@/lib/graphql/__generated__/swapActionsQuery.graphql"
import { fetch, graphql } from "@/lib/graphql/graphql"
import { bn } from "@/lib/helpers/numberUtils"

type SwapActionParams = {
  amount: string
  fromAssetDecimals: number
  fromAsset: string
  toAsset: string
}

export const querySwapActions = async ({
  amount,
  fromAssetDecimals,
  fromAsset,
  toAsset,
}: SwapActionParams) => {
  const [
    {
      blockchain: { swapActions },
    },
  ] = await fetch<swapActionsQuery>(
    graphql`
      query swapActionsQuery(
        $fromAssetQuantity: AssetQuantityInputType!
        $toAsset: AssetRelayID!
      ) {
        blockchain {
          swapActions(
            fromAssetQuantity: $fromAssetQuantity
            toAsset: $toAsset
          ) {
            actionsV2 {
              __typename
              ... on AssetSwapActionType {
                method {
                  ...useHandleBlockchainActions_transaction
                }
                ...useHandleBlockchainActions_swap_asset
              }
              ...BlockchainActionList_data
            }
          }
        }
      }
    `,
    {
      fromAssetQuantity: {
        quantity: bn(amount, -fromAssetDecimals).toString(),
        asset: fromAsset,
      },
      toAsset,
    },
    { force: true },
  )

  return swapActions
}
