import {
  Headers,
  Middleware,
  authMiddleware,
  urlMiddleware,
} from "react-relay-network-modern"
import { Environment } from "relay-runtime"
import { RecordMap } from "relay-runtime/lib/store/RelayStoreTypes"
import {
  API_APP_HEADER,
  API_BUILD_ID_HEADER,
  API_KEY_HEADER,
} from "@/constants/api"
import { IS_SERVER } from "@/constants/environment"
import { getWallet } from "@/containers/WalletProvider/wallet"
import API from "@/lib/api"
import { getIsAuthStateSimplificationEnabled } from "@/lib/auth/flags"
import type { RelayMetadata } from "@/lib/graphql/environment/types"
import * as sidecar from "@/server/sidecar/config"
import { getRequestAssociatedAuth } from "./auth"
import cacheMiddleware from "./middlewares/cacheMiddleware"
import metadataMiddleware from "./middlewares/metadataMiddleware"
import retryMiddleware from "./middlewares/retryMiddleware"
import signQueryMiddleware from "./middlewares/signQueryMiddleware"
import tokenRefreshMiddleware, {
  AUTH_TOKEN_PREFIX,
} from "./middlewares/tokenRefreshMiddleware"
import * as graphqlSetup from "./setup"

const getHeaders = (metadata?: RelayMetadata): Headers => {
  const headers: Headers = {
    [API_BUILD_ID_HEADER]: sidecar.getValue("buildId"),
    [API_APP_HEADER]: "opensea-web",
  }

  const ssrApiKey = API.getSsrApiKey()

  // CF-Connecting-IP will be set for SSR requests, and we want to "bill" those to the user.
  // In rare situations, CF-Connecting-IP won't be set, when a fetch is not associated with
  // a user request (for example when we fetch chains).
  if (metadata?.["CF-Connecting-IP"]) {
    headers["CF-Connecting-IP"] = metadata["CF-Connecting-IP"]
  } else if (ssrApiKey) {
    headers[API_KEY_HEADER] = ssrApiKey
  }

  const viewerAddress = IS_SERVER ? metadata?.address : getWallet().address

  if (!getIsAuthStateSimplificationEnabled() && viewerAddress) {
    headers["X-VIEWER-ADDRESS"] = viewerAddress
  }

  const activeAccountAddress = IS_SERVER
    ? metadata?.activeAccountAddress
    : getWallet().activeAccount?.address
  if (getIsAuthStateSimplificationEnabled() && activeAccountAddress) {
    headers["X-AUTH-ADDRESS"] = activeAccountAddress
  }

  if (IS_SERVER) {
    headers["User-Agent"] = "OpenSea/Next/SSR"
  }

  return headers
}

const middlewares = (auth?: RelayMetadata): Array<Middleware | null> => {
  const middleware = [
    cacheMiddleware(
      {
        clearOnMutation: true,
        size: 100, // max 100 requests
        ttl: IS_SERVER ? 3000 : 60000, // 3 seconds on server, 1 minute on client
      },
      auth,
    ),
    metadataMiddleware,
    retryMiddleware,
    urlMiddleware({
      url: _ => API.getGraphqlUrl(),
      headers: _req => getHeaders(auth),
      credentials: "include",
    }),
    authMiddleware({
      token: async _req => {
        const reqAuth = await getRequestAssociatedAuth(auth)
        return reqAuth?.token ?? ""
      },
      allowEmptyToken: true,
      prefix: AUTH_TOKEN_PREFIX,
    }),
    signQueryMiddleware(),
  ]

  if (!IS_SERVER) {
    middleware.push(tokenRefreshMiddleware())
  }

  return middleware
}

export const createServerEnvironment = (
  metadata?: RelayMetadata,
  initialRecords?: RecordMap,
): Environment => {
  return graphqlSetup.createServerEnvironment(
    middlewares(metadata),
    initialRecords,
  )
}

let environment: Environment | undefined

const getClientEnvironment = (initialRecords?: RecordMap): Environment => {
  if (!environment) {
    environment = graphqlSetup.createClientEnvironment(
      middlewares(),
      initialRecords,
    )
  }
  return environment
}

export const getEnvironment = (initialRecords?: RecordMap) => {
  return typeof window === "undefined"
    ? createServerEnvironment(undefined, initialRecords)
    : getClientEnvironment(initialRecords)
}

export const clearEnvironment = () => {
  if (process.env.NODE_ENV !== "test") {
    throw new Error("Only supported in tests")
  }
  environment = undefined
}
