import React, { ContextType, createContext, useContext } from "react"

export type AnalyticsContext = {
  eventSource: string
  eventParams: Record<string, unknown>
}

const DEFAULT_CONTEXT: AnalyticsContext = {
  eventSource: "",
  eventParams: {},
}

export const AnalyticsContext = createContext(DEFAULT_CONTEXT)

type AnalyticsContextProviderProps = {
  children: React.ReactNode
  eventSource: string
  eventParams?: Record<string, unknown>
}

/**
 * @deprecated Use TrackingContexts and useTrackingFn
 */
export const AnalyticsContextProvider = ({
  children,
  eventSource,
  eventParams,
}: AnalyticsContextProviderProps) => {
  return (
    <AnalyticsContext.Provider
      value={{
        eventSource,
        eventParams: eventParams ?? DEFAULT_CONTEXT.eventParams,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}

export type AnalyticsContextType = ContextType<typeof AnalyticsContext>

type UseAnalyticsContextOptions = {
  eventSource?: string
  eventParams?: Record<string, unknown>
}
export const useAnalyticsContext = ({
  eventSource,
  eventParams,
}: UseAnalyticsContextOptions = {}) => {
  const {
    eventSource: eventSourceFromContext,
    eventParams: eventParamsFromContext,
  } = useContext(AnalyticsContext)

  return {
    eventSource: eventSource ?? eventSourceFromContext,
    eventParams: {
      ...eventParamsFromContext,
      ...eventParams,
    },
  }
}
