import { endsWith } from "lodash"
import {
  BigNumber,
  display,
  roundAboveMin,
  shortSymbolDisplay,
} from "@/lib/helpers/numberUtils"

type GetPriceInfoProps = {
  price: BigNumber
  symbol: string
  compactDisplay?: boolean
  maxDecimalPlaces?: number
}

type GetPriceDisplayProps = Omit<GetPriceInfoProps, "symbol"> & {
  fullPriceDisplay: string
}

const getPriceDisplay = ({
  fullPriceDisplay,
  price,
  maxDecimalPlaces,
}: GetPriceDisplayProps) => {
  let priceDisplay =
    maxDecimalPlaces !== undefined
      ? roundAboveMin(price, maxDecimalPlaces)
      : fullPriceDisplay

  const separator = "."
  if (priceDisplay.includes(separator)) {
    const [whole, rem] = priceDisplay.split(separator)

    let padded = rem.padEnd(maxDecimalPlaces || 0, "0")
    if (
      endsWith(padded, "0") &&
      padded.length > (maxDecimalPlaces ? maxDecimalPlaces - 1 : 1)
    ) {
      padded = padded.slice(0, padded.length - 1)
    }

    priceDisplay = [whole, padded].join(separator)
  }

  return priceDisplay
}

type GetPriceInfoVeryCompactProps = Omit<GetPriceInfoProps, "compactDisplay">

export const getCompactPriceInfo = ({
  price,
  symbol,
  maxDecimalPlaces,
}: GetPriceInfoVeryCompactProps) => {
  // This method is similar to getPriceInfo but it always returns compact display
  // and returns a different format (1,000 => 1K, 3,000,000 => 3M, etc.) for large numbers.

  if (price.gte(1000)) {
    const priceDisplay = shortSymbolDisplay(price, {
      noDecimalWholeNumber: true,
    })
    return priceDisplay
  }
  const fullPriceDisplay = display(price, symbol)
  return getPriceDisplay({
    fullPriceDisplay,
    price,
    compactDisplay: true,
    maxDecimalPlaces,
  })
}

export const getPriceInfo = ({
  price,
  symbol,
  compactDisplay,
  maxDecimalPlaces,
}: GetPriceInfoProps) => {
  const fullPriceDisplay = display(price, symbol)
  const priceDisplay = getPriceDisplay({
    fullPriceDisplay,
    price,
    compactDisplay,
    maxDecimalPlaces,
  })

  const isRounded = Number(priceDisplay) !== Number(fullPriceDisplay)

  return { fullPriceDisplay, priceDisplay, isRounded }
}
