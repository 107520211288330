import { ChainIdentifier } from "@/hooks/useChains/types"
import { getTrackingFn } from "@/lib/analytics"
import { Currency } from "./types"

export const trackOpenWallet = getTrackingFn("open wallet")
export const trackCloseWallet = getTrackingFn("close wallet")

export const trackClickConnectWallet = getTrackingFn("click connect wallet")
export const trackClickSwitchNetwork = getTrackingFn("click switch network")

export const trackOpenNetworkBalanceDropdown = getTrackingFn(
  "open network balance dropdown",
)
export const trackCloseNetworkBalanceDropdown = getTrackingFn(
  "close network balance dropdown",
)
export const trackClickAddToBalance = getTrackingFn<{
  symbol: string
  chain: ChainIdentifier
  type: "add" | "swap"
}>("click add to balance")
export const trackClickSwitchChain = getTrackingFn("click switch chain")
export const trackClickBridge = getTrackingFn<{
  fromCurrency: Currency
  toCurrency: Currency
}>("click bridge")

export const trackClickUnwrap = getTrackingFn("click wrap form unwrap")
export const trackClickWrap = getTrackingFn("click wrap form wrap")
export const trackClickArrow = getTrackingFn("click wrap form direction arrow")
export const trackClickMax = getTrackingFn("click wrap form max")
export const trackSubmitAddToOfferBalance = getTrackingFn<{
  quantity: string
}>("submit add to offer balance")
export const trackSubmitWithdrawFromOfferBalance = getTrackingFn<{
  quantity: string
}>("submit withdraw from offer balance")
