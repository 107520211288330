import React from "react"

/**
 * Used to prevent styled-components from passing its styling specific props
 * down to the base component. This can cause issues when props are unintentionally
 * sent to the DOM as attributes.
 */
export const withStyledPropsRemoved = <T extends Record<string, unknown>>(
  Component: React.ComponentType<T>,
  styledPropNames: string[],
): React.ComponentType<T> => {
  return function WithStyledPropsRemoved(props: T) {
    const propsWithStyledPropsRemoved = Object.fromEntries(
      Object.entries(props).filter(
        ([propName]) => !styledPropNames.includes(propName),
      ),
    ) as T
    return <Component {...propsWithStyledPropsRemoved} />
  }
}
