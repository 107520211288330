/**
 * @generated SignedSource<<75f7e48782b45f3de4976eb514ff4c96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useHandleBlockchainActions_bulk_fulfill_orders$data = {
  readonly method: {
    readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_transaction">;
  };
  readonly ordersToFill: ReadonlyArray<{
    readonly orderData: {
      readonly openedAt: string;
    };
  }>;
  readonly " $fragmentType": "useHandleBlockchainActions_bulk_fulfill_orders";
};
export type useHandleBlockchainActions_bulk_fulfill_orders$key = {
  readonly " $data"?: useHandleBlockchainActions_bulk_fulfill_orders$data;
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_bulk_fulfill_orders">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useHandleBlockchainActions_bulk_fulfill_orders"
};

(node as any).hash = "563de34a99746a24b6885bcb55e55c1c";

export default node;
