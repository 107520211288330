/**
 * @generated SignedSource<<770b1004020a1457df7982652cd2aa58>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MintYourOwnCollectionAction_data$data = {
  readonly __typename: "MintYourOwnCollectionActionType";
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_mint_your_own_collection">;
  readonly " $fragmentType": "MintYourOwnCollectionAction_data";
};
export type MintYourOwnCollectionAction_data$key = {
  readonly " $data"?: MintYourOwnCollectionAction_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"MintYourOwnCollectionAction_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MintYourOwnCollectionAction_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useHandleBlockchainActions_mint_your_own_collection",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TransactionSubmissionDataType",
          "kind": "LinkedField",
          "name": "method",
          "plural": false,
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "useHandleBlockchainActions_transaction",
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "useTransaction_transaction",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AddressDataType",
                      "kind": "LinkedField",
                      "name": "source",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AddressDataType",
                      "kind": "LinkedField",
                      "name": "destination",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "data",
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "MintYourOwnCollectionActionType",
  "abstractKey": null
};
})();

(node as any).hash = "a854f415f2f483751a0acadeb4cddef0";

export default node;
