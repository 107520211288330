import { useCallback } from "react"
import { graphql } from "react-relay"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { ChainIdentifier } from "@/hooks/useChains/types"
import {
  useCreateRequestedTransactionMutation,
  useCreateRequestedTransactionMutation$variables,
} from "@/lib/graphql/__generated__/useCreateRequestedTransactionMutation.graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"
import { addBreadcrumb } from "@sentry/nextjs"

export const useCreateRequestedTransaction = () => {
  const { mutate } = useGraphQL()
  const { wallet, chain: walletChain } = useWallet()

  const createRequestedTransaction = useCallback(
    async (
      variables: Omit<
        useCreateRequestedTransactionMutation$variables,
        "nonce" | "fromAddress" | "chain"
      > & { chainId?: ChainIdentifier },
    ) => {
      const chain = variables.chainId ?? walletChain
      if (!wallet.activeAccount?.address || !chain) {
        addBreadcrumb({
          category: "blockchain",
          message: "Create Requested Transaction",
          data: {
            walletChain: walletChain,
            activeWallet: wallet?.getActiveWallet(),
            activeAccount: wallet?.activeAccount,
            chainId: variables.chainId,
          },
          level: "info",
        })
        throw new Error(
          "Tried to create a user transaction without a connected address or chain",
        )
      }

      // Assume nonce to be current transaction count
      const nonce = await wallet.getTransactionCount()

      const result = await mutate<useCreateRequestedTransactionMutation>(
        graphql`
          mutation useCreateRequestedTransactionMutation(
            $calldata: String!
            $chain: ChainScalar!
            $fromAddress: AddressScalar!
            $toAddress: AddressScalar!
            $nonce: Int!
            $value: BigIntScalar
          ) {
            userTransaction {
              request(
                nonce: $nonce
                chain: $chain
                fromAddress: $fromAddress
                calldata: $calldata
                toAddress: $toAddress
                value: $value
              ) {
                relayId
              }
            }
          }
        `,
        {
          ...variables,
          nonce,
          chain: chain,
          fromAddress: wallet.activeAccount.address,
        },
      )
      return result.userTransaction.request.relayId
    },
    [walletChain, mutate, wallet],
  )

  return createRequestedTransaction
}
