/**
 * @generated SignedSource<<17964ae0454c5ddf12cde31c3d8e9da6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useLoginWithPrivyTokenMutation$variables = {
  accessToken: string;
  deviceId: string;
};
export type useLoginWithPrivyTokenMutation$data = {
  readonly ExchangeToken: {
    readonly exchange: {
      readonly address: string;
      readonly isEmployee: boolean;
    };
  };
};
export type useLoginWithPrivyTokenMutation = {
  response: useLoginWithPrivyTokenMutation$data;
  variables: useLoginWithPrivyTokenMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accessToken"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceId"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ExchangeToken",
    "kind": "LinkedField",
    "name": "ExchangeToken",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "deviceId",
            "variableName": "deviceId"
          },
          {
            "kind": "Variable",
            "name": "exchangeToken",
            "variableName": "accessToken"
          }
        ],
        "concreteType": "WebTokenAuth",
        "kind": "LinkedField",
        "name": "exchange",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "address",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isEmployee",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useLoginWithPrivyTokenMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useLoginWithPrivyTokenMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "e107bb81e5e76cd7b459e7df37dba1ca",
    "id": null,
    "metadata": {},
    "name": "useLoginWithPrivyTokenMutation",
    "operationKind": "mutation",
    "text": "mutation useLoginWithPrivyTokenMutation(\n  $deviceId: String!\n  $accessToken: String!\n) {\n  ExchangeToken {\n    exchange(deviceId: $deviceId, exchangeToken: $accessToken) {\n      address\n      isEmployee\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d749b6a71a22a4a165b92074d85989f9";

export default node;
