import { useMemo } from "react"
import { graphql, useFragment } from "react-relay"
import { getTokenPricePayment } from "@/components/assets/price/TokenPrice.react"
import { useTotalPriceOfferDataToAccept_offersToAccept$key } from "@/lib/graphql/__generated__/useTotalPriceOfferDataToAccept_offersToAccept.graphql"
import { BigNumber, bn, displayFiat } from "@/lib/helpers/numberUtils"
import { readOfferDataToAcceptPerUnitPrice } from "../utils/readOfferDataToAcceptPerUnitPrice"

type TotalPricePerSymbol = Record<
  string,
  | {
      price: BigNumber
      usdPrice: BigNumber
      tokenPricePayment: ReturnType<typeof getTokenPricePayment>
    }
  | undefined
>

export const useTotalPriceOfferDataToAccept = (
  offersToAcceptDataKey: useTotalPriceOfferDataToAccept_offersToAccept$key | null,
  maxQuantityToFill?: BigNumber,
) => {
  const ordersToFill = useFragment(
    graphql`
      fragment useTotalPriceOfferDataToAccept_offersToAccept on OfferToAcceptType
      @relay(plural: true) {
        itemFillAmount
        ...readOfferDataToAcceptPerUnitPrice_offerToAccept
      }
    `,
    offersToAcceptDataKey,
  )

  const initialTotals: {
    totalPricePerSymbol: TotalPricePerSymbol
    totalUsdPrice: BigNumber
  } = useMemo(
    () => ({
      totalPricePerSymbol: {},
      totalUsdPrice: bn(0),
    }),
    [],
  )

  const totalPrices = useMemo(() => {
    let includedQuantity = bn(0)
    return (
      ordersToFill?.reduce((acc, orderDataToFill) => {
        if (
          maxQuantityToFill &&
          includedQuantity.isGreaterThanOrEqualTo(maxQuantityToFill)
        ) {
          return acc
        }

        const { usdPrice, price, tokenPricePayment } =
          readOfferDataToAcceptPerUnitPrice(orderDataToFill)

        const quantityToIncludeFromThisOrder = maxQuantityToFill
          ? BigNumber.min(
              maxQuantityToFill.minus(includedQuantity), // Remaining quantity to be included
              orderDataToFill.itemFillAmount, // Total fill amount
            )
          : bn(orderDataToFill.itemFillAmount)

        includedQuantity = includedQuantity.plus(quantityToIncludeFromThisOrder)

        return {
          totalPricePerSymbol: {
            ...acc.totalPricePerSymbol,
            [tokenPricePayment.symbol]: {
              price: (
                acc.totalPricePerSymbol[tokenPricePayment.symbol]?.price ||
                bn(0)
              ).plus(price.times(quantityToIncludeFromThisOrder)),

              usdPrice: (
                acc.totalPricePerSymbol[tokenPricePayment.symbol]?.usdPrice ||
                bn(0)
              ).plus(usdPrice.times(quantityToIncludeFromThisOrder)),

              tokenPricePayment,
            },
          },
          totalUsdPrice: acc.totalUsdPrice.plus(usdPrice),
        }
      }, initialTotals) ?? initialTotals
    )
  }, [initialTotals, maxQuantityToFill, ordersToFill])

  return {
    totalPricePerSymbol: totalPrices.totalPricePerSymbol,
    totalUsdPrice: displayFiat(totalPrices.totalUsdPrice),
  }
}
