/* eslint-disable tailwindcss/no-custom-classname */
import React, { useRef, useState } from "react"
import { Flex, Icon, Input } from "@opensea/ui-kit"
import { useForm } from "react-hook-form"
import { useUpdateEffect } from "react-use"
import styled from "styled-components"
import { Button } from "@/design-system/Button"
import { Form } from "@/design-system/Form"
import { FormControl } from "@/design-system/FormControl"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { MailingSignupFormMutation } from "@/lib/graphql/__generated__/MailingSignupFormMutation.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"
import { EMAIL_VALIDATION_RULE } from "@/lib/helpers/validation"
import { themeVariant } from "@/styles/styleUtils"

type FormData = {
  email: string
}

export const MailingSignupForm = () => {
  const t = useTranslate("components")
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [showButtonSuccess, setShowButtonSuccess] = useState(false)
  const { mutate } = useGraphQL()
  const {
    formState: { errors },
    watch,
    register,
    handleSubmit,
  } = useForm<FormData>({
    mode: "onSubmit",
  })

  const email = watch("email")

  const onSubmit = handleSubmit(async () => {
    try {
      setSuccess(false)
      setIsSubmitting(true)

      const res = await mutate<MailingSignupFormMutation>(
        graphql`
          mutation MailingSignupFormMutation($email: String!) {
            newsletter {
              subscribe(email: $email)
            }
          }
        `,
        {
          email,
        },
      )

      setShowButtonSuccess(res.newsletter.subscribe)
      setSuccess(res.newsletter.subscribe)
    } catch (e) {
      console.error(e)
    } finally {
      setIsSubmitting(false)
    }
  })

  useUpdateEffect(() => {
    if (showButtonSuccess) {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }

      timerRef.current = setTimeout(() => {
        setShowButtonSuccess(false)
        timerRef.current = null
      }, 3000)
    }
  }, [showButtonSuccess])

  useMountEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  })

  return (
    <StyledForm onSubmit={onSubmit}>
      <Flex className="MailingSignupForm--input-container">
        <FormControl
          className="MailingSignupForm--input-main"
          error={errors.email?.message}
          hideLabel
          label={t("mailingSignup.emailLabel", "Email address")}
        >
          <>
            <Input
              className="MailingSignupForm--input"
              placeholder={t(
                "mailingSignup.emailPlaceholder",
                "Your email address",
              )}
              {...register("email", {
                required: "Email is required.",
                pattern: EMAIL_VALIDATION_RULE,
              })}
            />
            {success && (
              <Flex className="mt-1 items-center text-success">
                <Icon className="mr-2" value="check_circle" />
                {t(
                  "mailingSignup.successMessage",
                  "You're signed-up! Please verify your email to complete your subscription",
                )}
              </Flex>
            )}
          </>
        </FormControl>
        <Button
          className="MailingSignupForm--button mb-2 mr-2"
          disabled={isSubmitting}
          isLoading={isSubmitting}
          type="submit"
          onClick={() => {
            if (timerRef.current) {
              clearTimeout(timerRef.current)
            }

            setSuccess(false)
          }}
        >
          {showButtonSuccess
            ? t("mailingSignup.success", "Success!")
            : t("mailingSignup.mainCTA", "Sign up")}
        </Button>
      </Flex>
    </StyledForm>
  )
}

const StyledForm = styled(Form)`
  width: 100%;
  margin-top: 16px;

  ${props =>
    themeVariant({
      variants: {
        light: {
          color: props.theme.colors.oil,
        },
        dark: {
          color: props.theme.colors.fog,
        },
      },
    })}

  .MailingSignupForm--input-container {
    width: 100%;

    .MailingSignupForm--input-main {
      width: 100%;
      padding-right: 8px;
    }

    .MailingSignupForm--input {
      min-width: 50px;
      width: 100%;
      ${props =>
        themeVariant({
          variants: {
            light: {
              background: props.theme.colors.white,
            },
          },
        })}
    }
  }

  .MailingSignupForm--button {
    white-space: nowrap;

    &:hover {
      filter: brightness(1.1);
      border: 0;
      background-color: ${props => props.theme.colors.primary};
    }
  }
`
