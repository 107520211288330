import React, {
  createContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react"
import { noop } from "lodash"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { DropActionContext_data$key } from "@/lib/graphql/__generated__/DropActionContext_data.graphql"

type DropActionContextType = {
  // query is loaded
  ready: boolean
  // drop was already published at the beginning of the flow
  isAlreadyPublished: boolean | null
  // reset the context
  reset: () => void
}

export const DropActionContext = createContext<DropActionContextType>({
  ready: false,
  isAlreadyPublished: null,
  reset: noop,
})

export const DropActionContextProvider = ({
  dataKey,
  children,
}: {
  dataKey: DropActionContext_data$key | null
  children: React.ReactNode
}) => {
  const collection = useFragment<DropActionContext_data$key>(
    graphql`
      fragment DropActionContext_data on CollectionType {
        dropv2 {
          status
        }
      }
    `,
    dataKey,
  )
  const [isAlreadyPublished, setIsAlreadyPublished] = useState<boolean | null>(
    null,
  )

  const collectionRef = useRef(collection)
  collectionRef.current = collection
  const reset = useCallback(() => {
    const isPublished = collectionRef.current?.dropv2?.status === "PUBLISHED"
    setIsAlreadyPublished(isPublished)
  }, [])

  const ready = !!collectionRef.current?.dropv2
  useEffect(() => {
    if (ready) {
      reset()
    }
  }, [ready, reset])

  return (
    <DropActionContext.Provider
      value={{
        ready,
        isAlreadyPublished,
        reset,
      }}
    >
      {children}
    </DropActionContext.Provider>
  )
}
