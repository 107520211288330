import React from "react"
import { SpaceBetween, Text } from "@opensea/ui-kit"
import { Block } from "@/design-system/Block"
import { useTranslate } from "@/hooks/useTranslate"
import {
  bn,
  normalizePriceDisplay,
  displayUSD,
} from "@/lib/helpers/numberUtils"

type BalanceLabelProps = {
  label: string
  symbol: string
  quantity: string
  usdPrice: string | null
  className?: string
}

export const BalanceLabel = ({
  label,
  symbol,
  quantity,
  usdPrice,
  className,
  ...labelProps
}: BalanceLabelProps) => {
  const t = useTranslate("components")
  const balanceQuantity = `${normalizePriceDisplay(quantity)} ${symbol}`
  const balanceValue = usdPrice
    ? `($${displayUSD(bn(usdPrice).times(bn(quantity)))} USD)`
    : undefined
  return (
    <SpaceBetween className={className}>
      <Block marginBottom="4px" marginLeft="4px">
        <Text asChild weight="semibold">
          <label {...labelProps}>{label}</label>
        </Text>
      </Block>
      <div>
        <Text asChild data-testid="balance-quantity-label" size="small">
          <label>
            {t("balanceLabel.label", "Balance: {{balanceQuantity}}", {
              balanceQuantity,
            })}
          </label>
        </Text>
        {balanceValue && (
          <Text
            asChild
            color="secondary"
            data-testid="balance-price-label"
            size="tiny"
          >
            <label>{balanceValue}</label>
          </Text>
        )}
      </div>
    </SpaceBetween>
  )
}
