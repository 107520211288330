/**
 * @generated SignedSource<<6f7a117ed19017ccdb66aba59a3b317b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RarityIndicator_data$data = {
  readonly maxRank: number;
  readonly rank: number;
  readonly rankCount: number | null;
  readonly rankPercentile: number;
  readonly " $fragmentType": "RarityIndicator_data";
};
export type RarityIndicator_data$key = {
  readonly " $data"?: RarityIndicator_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"RarityIndicator_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RarityIndicator_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rank",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rankPercentile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rankCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxRank",
      "storageKey": null
    }
  ],
  "type": "RarityDataType",
  "abstractKey": null
};

(node as any).hash = "6af02df69693b6afed85936a1ceab2f2";

export default node;
