import { memoizeOne } from "@/lib/helpers/memoization"
import { envSchema, Environment } from "../../constants/environment/schema"
import {
  UnleashEnvironment,
  unleashEnvironmentSchema,
} from "../../lib/feature-flags/unleash/environment"

export type SidecarConfig = {
  unleashProxyClientKey: string
  unleashProxyUrl: string
  unleashEnvironment: NonNullable<UnleashEnvironment>
  clientAdminUrl: string
  clientGraphqlApiUrl: string
  clientRestApiUrl: string
  clientSubscriptionUrl: string
  isTestnet: boolean
  environment: Environment
  recaptchaSiteKey: string
  unleashProxyRefreshInterval: number
  internalAssetUrl: string
  externalAssetUrl: string
  solanaRpcUrl: string
  ogImagesPreviewUrl: string
  buildId: string
  counterNetworkWebsite: string
  openseaProUrl: string
  osWalletUrl: string
  externalOSWalletCookieDomain: string
  amplitudeApiKey: string | undefined
  isAuthStateSimplificationEnabled: boolean
}

export const Config: SidecarConfig = {
  unleashProxyUrl:
    process.env.UNLEASH_PROXY_URL ??
    "https://features-proxy.dev.openseabeta.com/proxy",
  unleashProxyClientKey:
    process.env.UNLEASH_PROXY_CLIENT_SECRET ?? "opensea-next",
  unleashEnvironment:
    unleashEnvironmentSchema.parse(process.env.UNLEASH_ENVIRONMENT) ??
    "development",
  clientGraphqlApiUrl:
    process.env.GRAPHQL_CLIENT_API_URL ||
    process.env.CLIENT_API_URL ||
    process.env.GRAPHQL_SERVER_API_URL ||
    process.env.SERVER_API_URL ||
    "https://api.opensea.io/graphql/",
  clientRestApiUrl:
    process.env.CLIENT_REST_API_URL ||
    process.env.CLIENT_API_URL ||
    process.env.SERVER_REST_API_URL ||
    process.env.SERVER_API_URL ||
    "https://api.opensea.io",
  clientSubscriptionUrl:
    process.env.SUBSCRIPTION_API_URL || "wss://opensea.io/__api/subscriptions",
  clientAdminUrl: process.env.ADMIN_URL || "https://admin.privatesea.io",
  isTestnet: process.env.IS_TESTNET === "true",
  environment: envSchema.parse(process.env.ENVIRONMENT),
  unleashProxyRefreshInterval: parseInt(
    process.env.UNLEASH_PROXY_REFRESH_INTERVAL ?? "30",
    10,
  ),
  recaptchaSiteKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? "",
  internalAssetUrl:
    process.env.OPENSEA_INTERNAL_ASSETS_ONLY_URL ||
    "https://storage.opensea.io",
  externalAssetUrl:
    process.env.OPENSEA_USER_ASSET_URL || "https://openseauserdata.com",
  solanaRpcUrl:
    process.env.SOLANA_RPC_URL || "https://api.opensea.io/jsonrpc/v1/solana/",
  ogImagesPreviewUrl:
    process.env.OG_IMAGES_PREVIEW_URL || "https://open-graph.opensea.io",
  buildId: process.env.BUILD_ID ?? "development",
  counterNetworkWebsite:
    process.env.COUNTER_NETWORK_WEBSITE ?? "http://localhost:3000",
  openseaProUrl: process.env.OPENSEA_PRO_URL || "https://pro.opensea.io",
  osWalletUrl: process.env.OS_WALLET_URL || "http://localhost:5001",
  externalOSWalletCookieDomain:
    process.env.EXTERNAL_OS_WALLET_COOKIE_DOMAIN || "",
  amplitudeApiKey: process.env.AMPLITUDE_API_KEY,
  isAuthStateSimplificationEnabled:
    process.env.IS_AUTH_STATE_SIMPLIFICATION_ENABLED === "true",
}

export const SIDECAR_CONFIG_DOCUMENT_ID = "__OS_CONFIG__"

const getDocumentConfig = memoizeOne((): SidecarConfig => {
  return JSON.parse(
    document.querySelector(`#${SIDECAR_CONFIG_DOCUMENT_ID}`)?.textContent ||
      "{}",
  )
})

export const getValue = <Key extends keyof SidecarConfig>(
  key: Key,
): SidecarConfig[Key] => {
  if (typeof window === "undefined") {
    return Config[key]
  }
  const documentConfig = getDocumentConfig()
  if (!(key in documentConfig)) {
    return Config[key]
  }
  return documentConfig[key]
}
