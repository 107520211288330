import { _FragmentRefs } from "relay-runtime"
import { DateRange } from "@/features/orders/hooks/useDateRangeOptions"
import { item_sellUrl$data } from "@/lib/graphql/__generated__/item_sellUrl.graphql"
import { item_url$data } from "@/lib/graphql/__generated__/item_url.graphql"
import { getNodes, graphql } from "@/lib/graphql/graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { getAssetUrl } from "./asset"
import { getBundleSellUrl, getBundleUrl } from "./bundle"
import { stringifyQueryParams } from "./urls"

const readItemUrl = inlineFragmentize<item_url$data>(
  graphql`
    fragment item_url on ItemType @inline {
      __typename
      ... on AssetType {
        ...asset_url
      }
      ... on AssetBundleType {
        ...bundle_url
      }
    }
  `,
  identifiers => identifiers,
)

export const getItemUrl = (ref: item_url$data | _FragmentRefs<"item_url">) => {
  const item = readItemUrl(ref)
  switch (item.__typename) {
    case "AssetType":
      return getAssetUrl(item)
    case "AssetBundleType":
      return getBundleUrl(item)
    default:
      return ""
  }
}

const readItemSellUrl = inlineFragmentize<item_sellUrl$data>(
  graphql`
    fragment item_sellUrl on ItemType @inline {
      __typename
      ... on AssetType {
        ...asset_url
      }
      ... on AssetBundleType {
        slug
        chain {
          identifier
        }
        assetQuantities(first: 18) {
          edges {
            node {
              asset {
                relayId
              }
            }
          }
        }
      }
    }
  `,
  identifiers => identifiers,
)

export const getItemSellUrl = (
  ref: item_sellUrl$data | _FragmentRefs<"item_sellUrl">,
  params?: {
    price?: string
    paymentAssetId?: string
    quantity?: string
    duration?: DateRange
  },
) => {
  const item = readItemSellUrl(ref)
  let url = ""
  switch (item.__typename) {
    case "AssetType":
      url = getAssetUrl(item, "sell")
      break
    case "AssetBundleType":
      url = getBundleSellUrl(
        item.chain.identifier,
        getNodes(item.assetQuantities).map(({ asset }) => asset.relayId),
        item.slug,
      )
      break
    default:
      return ""
  }
  url += stringifyQueryParams(params)

  return url
}
