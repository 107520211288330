import { useMemo } from "react"
import { graphql, useFragment } from "react-relay"
import { getTokenPricePayment } from "@/components/assets/price/TokenPrice.react"
import { useTotalPriceOrderDataToFill_ordersToFill$key } from "@/lib/graphql/__generated__/useTotalPriceOrderDataToFill_ordersToFill.graphql"
import { BigNumber, bn, displayFiat } from "@/lib/helpers/numberUtils"
import { readOrderDataToFillPrices } from "../utils/readOrderDataToFillPrice"

type TotalPricePerSymbol = Record<
  string,
  | {
      price: BigNumber
      usdPrice: BigNumber
      tokenPricePayment: ReturnType<typeof getTokenPricePayment>
    }
  | undefined
>

export const useTotalPriceOrderDataToFill = (
  ordersToFillDataKey: useTotalPriceOrderDataToFill_ordersToFill$key | null,
) => {
  const ordersToFill = useFragment(
    graphql`
      fragment useTotalPriceOrderDataToFill_ordersToFill on OrderToFillType
      @relay(plural: true) {
        ...readOrderDataToFillPrices_orderDataToFill
      }
    `,
    ordersToFillDataKey,
  )

  const initialTotals: {
    totalPricePerSymbol: TotalPricePerSymbol
    totalUsdPrice: BigNumber
  } = useMemo(
    () => ({
      totalPricePerSymbol: {},
      totalUsdPrice: bn(0),
    }),
    [],
  )

  const totalPrices = useMemo(
    () =>
      ordersToFill?.reduce((acc, orderDataToFill) => {
        const { usdPrice, price, tokenPricePayment } =
          readOrderDataToFillPrices(orderDataToFill)

        return {
          totalPricePerSymbol: {
            ...acc.totalPricePerSymbol,
            [tokenPricePayment.symbol]: {
              price: (
                acc.totalPricePerSymbol[tokenPricePayment.symbol]?.price ||
                bn(0)
              ).plus(price),

              usdPrice: (
                acc.totalPricePerSymbol[tokenPricePayment.symbol]?.usdPrice ||
                bn(0)
              ).plus(usdPrice),

              tokenPricePayment,
            },
          },
          totalUsdPrice: acc.totalUsdPrice.plus(usdPrice),
        }
      }, initialTotals) ?? initialTotals,
    [initialTotals, ordersToFill],
  )

  return {
    totalPricePerSymbol: totalPrices.totalPricePerSymbol,
    totalUsdPrice: displayFiat(totalPrices.totalUsdPrice),
  }
}
