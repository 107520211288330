import React from "react"
import { Flex, Media } from "@opensea/ui-kit"
import styled from "styled-components"
import { Link } from "@/components/common/Link"
import OpenSeaLetters from "@/components/svgs/OpenSeaLetters.react"
import { Block } from "@/design-system/Block"
import { useTheme } from "@/design-system/Context/ThemeContext"
import { Image } from "@/design-system/Image"
import { Tooltip } from "@/design-system/Tooltip"
import { useTranslate } from "@/hooks/useTranslate"
import THEMES from "@/styles/themes"
import { OPENSEA_LOGO_IMG } from "../../constants"
import { IS_TESTNET } from "../../constants/testnet"

type NavLogoProps = {
  textFill?: string
}

export const NavLogo = ({ textFill }: NavLogoProps) => {
  const { theme } = useTheme()
  const t = useTranslate("components")
  const letterFill = textFill || THEMES[theme].colors.text.primary

  const renderLogo = ({ isMobile }: { isMobile: boolean }) => (
    <Link className="flex items-center" href="/">
      <Block className="relative" height={40} width={isMobile ? 32 : 40}>
        <Image
          alt="OpenSea Logo"
          layout="fill"
          priority
          src={OPENSEA_LOGO_IMG}
        />
      </Block>
      <Flex className="relative ml-2.5 mt-0.5" data-testid="brand-name">
        <OpenSeaLetters fill={letterFill} width={isMobile ? 92 : 100} />
        {IS_TESTNET && !isMobile ? (
          <Tooltip
            content={
              <>
                {t(
                  "nav.testNetworkAlert",
                  "You are on the OpenSea test network (testnets.opensea.io). Go to {{mainnetLink}} to hop off the test network.",
                  {
                    mainnetLink: (
                      <Link href="https://opensea.io">OpenSea.io</Link>
                    ),
                  },
                )}
              </>
            }
            placement="right"
          >
            <TestnetsBadge> {t("nav.testnets", "Testnets")}</TestnetsBadge>
          </Tooltip>
        ) : null}
      </Flex>
    </Link>
  )

  return (
    <>
      <Media lessThan="md">{renderLogo({ isMobile: true })}</Media>
      <Media greaterThanOrEqual="md">{renderLogo({ isMobile: false })}</Media>
    </>
  )
}

const TestnetsBadge = styled.span`
  background: ${props => props.theme.colors.warning};
  border-radius: 3px;
  bottom: 0px;
  color: ${props => props.theme.colors.text.on.warning};
  font-size: 10px;
  padding: 2px 4px 0 4px;
  position: absolute;
  right: 0;
`
