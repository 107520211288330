import { useCallback } from "react"
import { useGetTransactionRevertReasonQuery } from "@/lib/graphql/__generated__/useGetTransactionRevertReasonQuery.graphql"
import { fetch, graphql } from "@/lib/graphql/graphql"

export const useGetTransactionRevertReason = () => {
  const getTransactionRevertReason = useCallback(
    async (revertReasonBytesStr: string) => {
      const [query] = await fetch<useGetTransactionRevertReasonQuery>(
        graphql`
          query useGetTransactionRevertReasonQuery(
            $revertReasonBytesStr: String!
          ) {
            transactionRevertReason(
              revertReasonBytesStr: $revertReasonBytesStr
            ) {
              ... on RevertReasonType {
                revertReason: reason
              }
              ... on ErrorStringRevertsType {
                errorStringRevertReason: reason
              }
              ... on InvalidTimeRevertsType {
                invalidTimeRevertReason: reason
              }
            }
          }
        `,
        { revertReasonBytesStr },
      )

      const { revertReason, errorStringRevertReason, invalidTimeRevertReason } =
        query.transactionRevertReason

      return { revertReason, errorStringRevertReason, invalidTimeRevertReason }
    },
    [],
  )
  return { getTransactionRevertReason }
}
