import { cva } from "class-variance-authority"
import { rgba } from "polished"
import { css } from "styled-components"
import { HUES } from "@/styles/themes"

export type NavbarBackground = "default" | "dark_override" | "transparent"

export type NavbarInputStylesProps = {
  $background?: NavbarBackground
  $isBackgroundTransparent?: boolean
}

export const navbarInteractiveClasses = cva(undefined, {
  variants: {
    background: {
      transparent:
        "border border-transparent bg-white/[0.12] text-white backdrop-blur-xl backdrop-filter transition-colors duration-200 ease-in-out hover:bg-white/20 focus:bg-[var(--color-white)/20]",
      default:
        "border-transparent bg-component-gray-1 transition-colors duration-200 ease-in-out hover:border-transparent hover:bg-component-gray-2 focus:border-transparent focus:bg-component-gray-2",
    },
  },
})

export const navbarInteractiveStyles = css<NavbarInputStylesProps>`
  ${props =>
    props.$background === "transparent" || props.$isBackgroundTransparent
      ? css`
          border-width: 1px;
          border-style: solid;
          border-color: transparent;
          background-color: ${rgba(HUES.white, 0.12)};
          color: ${HUES.white};
          backdrop-filter: blur(20px);

          transition:
            background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1),
            border-color 0.2s cubic-bezier(0.05, 0, 0.2, 1);

          :hover,
          :focus {
            border-color: transparent;
            background-color: ${rgba(HUES.white, 0.2)};
          }
        `
      : css`
          border-color: transparent;
          background-color: ${props.theme.colors.components.background.gray1};

          :hover,
          :focus {
            border-color: transparent;
            transition: background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1);
            background-color: ${props.theme.colors.components.background.gray2};
          }
        `}
`
