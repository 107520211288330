// Needed to prevent regeneratorRuntime is not defined error
import "regenerator-runtime/runtime"
import {
  Middleware,
  RelayNetworkLayerResponse,
} from "react-relay-network-modern"
import { QueryResponseCache, Variables } from "relay-runtime"
import { trim } from "@/lib/helpers/object"
import { getRequestAssociatedAuth } from "../auth"
import { RelayMetadata } from "../types"

// size - max number of request in cache, least-recently updated entries purged first (default: 100).
// ttl - number in milliseconds, how long records stay valid in cache (default: 900000, 15 minutes).
// allowMutations - allow to cache Mutation requests (default: false)
// allowFormData - allow to cache FormData requests (default: false)
// clearOnMutation - clear the cache on any Mutation (default: false)
// cacheErrors - cache responses with errors (default: false)

type CacheOptions = {
  size: number
  ttl: number
  allowMutations?: boolean
  allowFormData?: boolean
  clearOnMutation?: boolean
  cacheErrors?: boolean
}

const DEFAULT_OPTIONS: CacheOptions = {
  size: 100,
  ttl: 15 * 60 * 1000, // 15 minutes
  allowMutations: false,
  allowFormData: false,
  clearOnMutation: false,
  cacheErrors: false,
}

const queryAliases: { [queryID: string]: string } = {}

const processVariables = async (
  variables: Variables,
  metadata?: RelayMetadata,
): Promise<Variables> => {
  const reqAuth = await getRequestAssociatedAuth(metadata)
  return trim({
    _authToken: reqAuth?.token,
    _viewerAddress: reqAuth?.address,
    ...variables,
  })
}

let cache: QueryResponseCache

export const clearCache = (): void => cache.clear()

const getCacheEntry = async (
  queryID: string,
  rawVariables: Variables,
  metadata?: RelayMetadata,
): Promise<RelayNetworkLayerResponse | undefined> => {
  const variables = await processVariables(rawVariables, metadata)
  // @ts-expect-error TODO: description
  return cache.get(queryID, variables) || undefined
}

const setCacheEntry = async (
  queryID: string,
  rawVariables: Variables,
  response: RelayNetworkLayerResponse,
  metadata?: RelayMetadata,
): Promise<void> => {
  const variables = await processVariables(rawVariables, metadata)
  // @ts-expect-error TODO: description
  cache.set(queryID, variables, response)
  const queryAlias = queryAliases[queryID]
  if (queryAlias) {
    // @ts-expect-error TODO: description
    cache.set(queryAlias, variables, response)
  }
}

const cacheMiddleware = (
  options: CacheOptions,
  metadata?: RelayMetadata,
): Middleware => {
  const {
    size,
    ttl,
    allowMutations,
    allowFormData,
    clearOnMutation,
    cacheErrors,
  } = { ...DEFAULT_OPTIONS, ...options }
  cache = new QueryResponseCache({ size, ttl })
  return next =>
    async (request): Promise<RelayNetworkLayerResponse> => {
      if ("requests" in request) {
        // TODO (joshuawu): Support batch requests
        return next(request)
      }
      if (request.isMutation()) {
        if (clearOnMutation) {
          cache.clear()
        }
        if (!allowMutations) {
          return next(request)
        }
      }
      if (request.isFormData() && !allowFormData) {
        return next(request)
      }
      const queryID = request.getID()
      const variables = request.getVariables()
      if (!request.cacheConfig.force) {
        const cachedResponse = await getCacheEntry(queryID, variables, metadata)
        if (cachedResponse) {
          return cachedResponse
        }
      }
      const response = await next(request)
      if (!response.errors || cacheErrors) {
        setCacheEntry(queryID, variables, response, metadata)
      }
      return response
    }
}
export default cacheMiddleware
