import React, { forwardRef } from "react"
import styled from "styled-components"
import { Block, BlockProps } from "@/design-system/Block"

const Frame = styled(Block).attrs<BlockProps>(props => ({
  as: props.as ?? "section",
}))`
  border-radius: ${props => props.theme.borderRadius.default};
  border: 1px solid ${props => props.theme.colors.components.border.level2};
  overflow: hidden;
`

export default Frame

const Context = React.createContext<{ isFramed?: boolean }>({})

type ProviderProps = {
  children: React.ReactNode
  className?: string
}

export const FrameProvider = forwardRef<HTMLDivElement, ProviderProps>(
  function FrameProvider({ children, className }, ref) {
    return (
      <Context.Provider value={{ isFramed: true }}>
        <div className={className} ref={ref}>
          {children}
        </div>
      </Context.Provider>
    )
  },
)

export const FrameConsumer = Context.Consumer
