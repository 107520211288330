import { Address } from "viem"
import { useBalance } from "wagmi"
import { useWalletProviderBalancePolling } from "@/containers/WalletBalanceProvider/useWalletProviderBalancePolling"
import {
  useConnectedAddress,
  useWallet,
} from "@/containers/WalletProvider/WalletProvider.react"
import { useChains } from "@/hooks/useChains"
import { bn } from "@/lib/helpers/numberUtils"

export const useFetchNativeCurrencyBalance = () => {
  const connectedAddress = useConnectedAddress()
  const { chain: activeChain } = useWallet()
  const { getChain } = useChains()

  const { enabled: isPollingEnabled, pollingInterval } =
    useWalletProviderBalancePolling()

  const { data, refetch, error } = useBalance({
    address: connectedAddress as Address,
    chainId: activeChain
      ? Number(getChain(activeChain).networkId ?? undefined)
      : undefined,
    query: {
      refetchInterval: isPollingEnabled ? pollingInterval : undefined,
    },
  })
  const hasError = Boolean(error)
  const walletBalance = data ? bn(data.value, data.decimals) : undefined

  return {
    balance: walletBalance,
    hasError,
    refetchBalance: refetch,
  }
}
