const selectorOptions = [
  {
    label: "English",
    locale: "en-US",
  },
  {
    label: "Español",
    locale: "es",
  },
  {
    label: "Deutsch",
    locale: "de-DE",
  },
  {
    label: "Français",
    locale: "fr",
  },
  {
    label: "日本語",
    locale: "ja",
  },
  {
    label: "한국어",
    locale: "kr",
  },
  {
    label: "中文 (简体)",
    locale: "zh-CN",
  },
  {
    label: "中文 (繁体)",
    locale: "zh-TW",
  },
]

const locales = selectorOptions.map(option => option.locale)

/** @type {import('next-translate').LoaderConfig} */
module.exports = {
  locales,
  defaultLocale: locales[0],
  pages: {
    "*": [
      "account",
      "activity",
      "assets",
      "common",
      "components",
      "designSystem",
      "hooks",
      "modals",
      "pendingTransaction",
      "phoenix",
      "jsonRpcError",
    ],
    "rgx:^(/collection/|/account|/assets|/category)": [
      "bulkAcceptOffers",
      "collection",
      "collectionEdit",
      "collectionOffers",
      "collections",
      "itemCardContent",
      "moonpay",
      "orders",
      "reportModal",
      "traits",
    ],

    "/": ["home", "rankings", "statsV2", "categorization"],
    "/drops": ["dropCalendar", "drop"],
    "/studio/create": ["creatorStudio"],
    "/studio": ["creatorStudio"],
    "/studio/deploy-contract": ["creatorStudio"],
    "/studio/mint": ["creatorStudio"],
    "/category/[categorySlug]": ["category", "categorization", "home"],
    "/collection/[categorySlug]/drop": ["drop"],
    "/solana-collections": ["solana"],
    "/explore-solana": ["solana"],
    "/explore-collections": ["explore"],

    // Item pages and item action pages
    "rgx:^/assets/.*/.*/.*": [
      "adobe",
      "bulkPurchase",
      "bundle",
      "owners",
      "sell",
      "transfers",
    ],
    "rgx:^/assets/.*/.*/.*/redeem": [
      "adobe",
      "bulkPurchase",
      "bundle",
      "owners",
      "sell",
      "transfers",
    ],

    "rgx:^/rankings": ["rankings", "statsV2"],
    "rgx:^(/bundle/|/bundles/)": ["bundle", "bundles"],
    "/about": ["about"],
    "/account/settings": ["settings"],
    "/bulk-list": ["bulkList"],
    "/careers": ["careers"],
    "/login": ["login"],
    "/my-watchlist": ["watchlist"],
    "/opensea-ventures": ["ventures"],
    "/tax-resources": ["cointracker"],
  },
  selectorOptions,
  logBuild: process.env.NODE_ENV === "development",
}
