import React from "react"
import { Icon, Spinner, Text, classNames, FlexColumn } from "@opensea/ui-kit"
import { Flex } from "@/design-system/Flex"
import { Modal } from "@/design-system/Modal"
import { StepIndicator } from "@/features/primary-drops/components/StepIndicator/StepIndicator.react"
import { useTranslate } from "@/hooks/useTranslate"

type LOADING_STATE = 0 | 1

export const LOADING_CONTENT_STATE = {
  WAITING_SIGNATURE: 0,
  PENDING_TRANSACTION: 1,
} as const

const STEPS = [
  LOADING_CONTENT_STATE.WAITING_SIGNATURE,
  LOADING_CONTENT_STATE.PENDING_TRANSACTION,
] as const

type Props = {
  state: LOADING_STATE
  customRender?: React.ReactNode
}

export const DeployContractProgress = ({ state, customRender }: Props) => {
  const t = useTranslate("creatorStudio")

  const ariaLabel = useAriaLabel()
  const copy = useCopyContent()

  return (
    <>
      <Modal.Header>
        <Modal.Header.Title>
          {t("deployContract.loading.title", "Deploying your contract")}
        </Modal.Header.Title>
      </Modal.Header>
      <Modal.Body data-testid="deploy-contract-action-container">
        <FlexColumn aria-label={ariaLabel[state]} role="dialog">
          {STEPS.map(loadingState => {
            return (
              <>
                <Flex className="relative">
                  {loadingState < 1 && <Line />}
                  <StepIndicator.Circle
                    className={classNames(
                      "min-h-[48px] !min-w-[48px] rounded-full ",
                      {
                        "!border-solid border-level-2 border !bg-transparent":
                          state < loadingState,
                        "!bg-component-gray-1": state >= loadingState,
                      },
                    )}
                    isActive={false}
                    isPast={false}
                  >
                    {state === loadingState ? (
                      <Spinner />
                    ) : state > loadingState ? (
                      <Icon className="text-primary" size={32} value="done" />
                    ) : null}
                  </StepIndicator.Circle>
                  <FlexColumn className="mb-6 mt-[10px] pl-6">
                    <Text.Body weight="semibold">
                      {copy[loadingState].title}
                    </Text.Body>
                    <Text.Body
                      className="mb-2 mt-0.5"
                      color="secondary"
                      size="small"
                    >
                      {copy[loadingState].description}
                    </Text.Body>
                    {state === loadingState && customRender}
                  </FlexColumn>
                </Flex>
              </>
            )
          })}
        </FlexColumn>
      </Modal.Body>
    </>
  )
}

const Line = () => (
  <Flex className="absolute -bottom-2.5 left-6 top-[58px] w-px bg-border-1" />
)

const useAriaLabel = () => {
  const t = useTranslate("creatorStudio")
  return {
    [LOADING_CONTENT_STATE.WAITING_SIGNATURE]: t(
      "deployContract.loading.waitingSignature.ariaDescription",
      "Waiting for signature dialog",
    ),
    [LOADING_CONTENT_STATE.PENDING_TRANSACTION]: t(
      "deployContract.loading.pendingTransaction.ariaDescription",
      "Pending transaction confirmation dialog",
    ),
  }
}

const useCopyContent = () => {
  const t = useTranslate("creatorStudio")
  return {
    [LOADING_CONTENT_STATE.WAITING_SIGNATURE]: {
      title: t(
        "deployContract.loading.waitingSignature.title",
        "Go to your wallet to finish deploying your contract",
      ),
      description: t(
        "deployContract.loading.waitingSignature.description",
        "You’ll be asked to pay gas fees and sign in order to deploy your contract on the blockchain.",
      ),
    },
    [LOADING_CONTENT_STATE.PENDING_TRANSACTION]: {
      title: t(
        "deployContract.loading.pendingTransaction.title",
        "Deploying your contract",
      ),
      description: t(
        "deployContract.loading.pendingTransaction.description",
        "It may take some time for the transaction to be processed.",
      ),
    },
  }
}
