import React from "react"

type Props = {
  className?: string
  width?: number
  fill?: string
}

export const GunzillaLogo = ({
  className,
  fill = "black",
  width = 20,
}: Props) => (
  <svg
    className={className}
    style={{ width, height: width }}
    viewBox="0 0 300 300"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g>
        <path
          d="M247.02,69.95H84.72V208.73l31.41,31.38h56.52l-70.97-70.97v-16.38h49.46l-49.46-49.46v-16.38h79.46l65.88,65.85v16.96h-48.92l48.92,48.92v74.5H73.28l-56.34-56.34V16.93H247.02v53.02Z"
          fill={fill}
        />
      </g>
    </g>
  </svg>
)
