import React, { useState } from "react"
import { Text } from "@opensea/ui-kit"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import {
  EMAIL_AUTH_CODE_EXPIRATION,
  RESEND_CODE_TIMEOUT_SECONDS,
} from "@/features/os-wallet/components/constants"
import { WalletMfaEnrollmentModal } from "@/features/os-wallet/components/OSWalletModal/components/WalletMfaEnrollmentModal.react"
import { useAuthenticateOSWallet } from "@/features/os-wallet/hooks/useOpenOSWalletAfterLogin"
import { useOSWalletAuth } from "@/features/os-wallet/hooks/useOSWalletAuth"
import { useTranslate } from "@/hooks/useTranslate"
import { WalletAuthCodeModal } from "./WalletAuthCodeModal.react"

type Props = {
  email: string
}

export const WalletEmailCodeModal = ({ email }: Props) => {
  const t = useTranslate("common")
  const { sendLoginCode, loginWithCode, checkMfaEnrollment } = useOSWalletAuth()
  const authenticateOSWallet = useAuthenticateOSWallet()
  const { onNext } = useMultiStepFlowContext()
  const [error, setError] = useState<boolean>(false)

  const handleAuthCode = async (authCode: string) => {
    setError(false)
    const tryMfaEnrollment = async (
      accessToken: string,
      privyId: string,
      isRetry: boolean = false,
    ) => {
      const { isReady, isEnrolled } = await checkMfaEnrollment()

      if (!isReady) {
        if (isRetry) {
          throw new Error(
            t(
              "wallet.opensea.mfaEnrollmentError",
              "Could not verify MFA enrollment. Please try again.",
            ),
          )
        }
        // Wait 1 second before retrying.
        await new Promise(resolve => setTimeout(resolve, 1000))
        await tryMfaEnrollment(accessToken, privyId, true)
        return
      }

      if (isEnrolled) {
        await authenticateOSWallet({ accessToken, privyId })
      } else {
        onNext(() => (
          <WalletMfaEnrollmentModal
            accessToken={accessToken}
            privyId={privyId}
          />
        ))
      }
    }

    try {
      const { accessToken, privyId } = await loginWithCode(authCode)
      await tryMfaEnrollment(accessToken, privyId)
    } catch (error) {
      setError(true)
    }
  }

  const handleResendAuthCode = async () => {
    try {
      await sendLoginCode(email)
    } catch (error) {
      setError(true)
    }
  }

  return (
    <WalletAuthCodeModal
      authCodeExpiration={EMAIL_AUTH_CODE_EXPIRATION}
      description={t(
        "wallet.opensea.enterAuthCode.email",
        "A one-time authentication code has been sent to {{email}}",
        {
          email: (
            <Text className="truncate" weight="semibold">
              {email}
            </Text>
          ),
        },
      )}
      error={error}
      icon="mail"
      resendTooltip={t(
        "wallet.opensea.resendTooltip",
        {
          "0": "Check your inbox and spam folder. If no email within {{count}} seconds, click to resend.",
          one: "Check your inbox and spam folder. If no email within {{count}} second, click to resend.",
          other:
            "Check your inbox and spam folder. If no email within {{count}} seconds, click to resend.",
        },
        { count: RESEND_CODE_TIMEOUT_SECONDS },
      )}
      title={t("wallet.opensea.enterLoginCode", "Enter login code")}
      onAuthCode={handleAuthCode}
      onResendAuthCode={handleResendAuthCode}
    />
  )
}
