/* eslint-disable tailwindcss/no-custom-classname */
import React, { useEffect } from "react"
import { CenterAligned, Container, Flex, Text } from "@opensea/ui-kit"
import { ErrorBoundary } from "@sentry/nextjs"
import { useUpdateEffect } from "react-use"
import styled, { css } from "styled-components"
import { TrendingCollections } from "@/components/common/TrendingCollections.react"
import { PageProps } from "@/containers/AppProviders"
import { OpenSeaPage } from "@/containers/OpenSeaPage.react"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { useTheme } from "@/design-system/Context/ThemeContext"
import { Image } from "@/design-system/Image"
import { OpenSeaHead } from "@/features/seo"
import { useRouter } from "@/hooks/useRouter"
import { useTranslate } from "@/hooks/useTranslate"
import { trackErrorPage } from "@/lib/analytics/events/pageEvents"
import { media } from "@/styles/styleUtils"
import { ERROR_PAGE_HELP_CENTER_URL } from "../../../constants"
import { addBreadcrumb } from "@sentry/nextjs"

export type ErrorPageProps = Partial<PageProps> & {
  statusCode: number
  errorId?: string | null
  isFetchError?: boolean
  resetError?: () => void
}

export const ErrorPage = ({
  statusCode,
  errorId,
  isFetchError,
  resetError,
  isPageNotFound,
}: ErrorPageProps) => {
  const t = useTranslate("components")
  const { theme } = useTheme()
  const { asPath: path } = useRouter()

  useUpdateEffect(() => {
    if (resetError) {
      resetError()
    }
  }, [resetError, path])

  useEffect(() => {
    trackErrorPage({ error_code: statusCode, path })
  }, [statusCode, path])

  const render404Content = () => (
    <DivContainer>
      <CenterAligned className="error--404-container">
        <div className="error--404">
          <Text
            asChild
            className="mx-5 w-1/3 text-right text-[180px] [line-height:unset]"
            color="gray-1"
            weight="semibold"
          >
            <p>4</p>
          </Text>

          <Image
            alt="404 image"
            height={630}
            objectFit="contain"
            src={
              theme === "dark"
                ? "/static/images/404-compass-full-dark.gif"
                : "/static/images/404-compass-full.gif"
            }
            width={96}
          />

          <Text
            asChild
            className="mx-5 w-1/3 text-left text-[180px] [line-height:unset]"
            color="gray-1"
            weight="semibold"
          >
            <p>4</p>
          </Text>
        </div>
      </CenterAligned>

      <CenterAligned className="px-[15%] pb-[100px] text-center">
        <Text.Heading asChild className="text-center" size="large">
          <h1>{t("errorPage.title", "This page is lost.")}</h1>
        </Text.Heading>
        <Text.Heading
          asChild
          className="font-normal sm:text-heading-sm"
          color="secondary"
          size="tiny"
        >
          <p>
            {t(
              "errorPage.description",
              "We've explored deep and wide,{{separator}}but we can't find the page you were looking for.",
              { separator: <br /> },
            )}
          </p>
        </Text.Heading>
        <Button className="error--action-button" href="/">
          {t("errorPage.navigateBack", "Navigate back home")}
        </Button>
      </CenterAligned>
    </DivContainer>
  )

  const notFound = statusCode === 404 || isPageNotFound

  return (
    <OpenSeaPage>
      <OpenSeaHead />
      {/* Overflow hidden used for Carousel "card" variants that require a visible overflow */}
      <Container className="overflow-x-hidden">
        {notFound ? (
          render404Content()
        ) : isFetchError ? (
          <FetchErrorContent errorId={errorId} />
        ) : (
          <ErrorContent errorId={errorId} />
        )}

        <ErrorBoundary fallback={<></>}>
          <TrendingCollections />
        </ErrorBoundary>
      </Container>
    </OpenSeaPage>
  )
}

const FetchErrorContent = ({ errorId }: { errorId?: string | null }) => {
  const t = useTranslate("components")
  return (
    <Block>
      <Text.Heading asChild className="text-center" size="large">
        <h1>{t("errorPage.fetchError.title", "There was a network error")}</h1>
      </Text.Heading>
      <Text.Heading
        asChild
        className="text-center font-normal"
        color="secondary"
        size="medium"
      >
        <p>
          {t(
            "errorPage.fetchError.description",
            "There was an issue while connecting to our servers. Please reload and try again.",
          )}
          {errorId && (
            <>
              <br />
              <br />
              <span>
                {t("errorPage.errorId", "Error ID: {{errorId}}", { errorId })}
              </span>
            </>
          )}
        </p>
      </Text.Heading>
    </Block>
  )
}

const ErrorContent = ({ errorId }: { errorId?: string | null }) => {
  const t = useTranslate("components")
  addBreadcrumb({
    category: "error_page",
    message: "This error has thrown an ErrorPage (Oops, something went wrong)",
    level: "error",
  })
  return (
    <Block>
      <Text.Heading asChild className="text-center" size="large">
        <h1>
          {t("errorPage.unknownError.title", "Oops, something went wrong")}
        </h1>
      </Text.Heading>
      <Text.Heading
        asChild
        className="text-center"
        color="secondary"
        size="medium"
      >
        <p>
          {t(
            "errorPage.unknownError.description",
            "Looks like something went wrong on our end. Check the help guide linked below to learn how to fix it.",
          )}
          {errorId && (
            <>
              <br />
              <br />
              <span>
                {t("errorPage.errorId", "Error ID: {{errorId}}", { errorId })}
              </span>
            </>
          )}
        </p>
      </Text.Heading>
      <Flex className="justify-center">
        <Button href={ERROR_PAGE_HELP_CENTER_URL}>
          {t("errorPage.learnMore", "Learn More")}
        </Button>
      </Flex>
    </Block>
  )
}

const DivContainer = styled.div`
  .error--404-container {
    height: 280px;
    margin-top: 44px;

    .error--404 {
      display: flex;
      vertical-align: middle;
      width: 420px;
      max-width: 100%;
      height: 100%;
    }
  }

  .error--collections-featured {
    font-size: 20px;
    height: 200px;
    width: 200px;
    white-space: normal;
    text-align: center;

    ${media({
      sm: css`
        height: 358px;
        width: 326px;
      `,
    })}
  }

  .error--action-button {
    display: inline-block;
    margin-top: 10px;
  }
`
