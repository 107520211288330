import BigNumber from "bignumber.js"
import { graphql, useFragment } from "react-relay"
import { useTranslate } from "@/hooks/useTranslate"
import { useItemFees_item$key } from "@/lib/graphql/__generated__/useItemFees_item.graphql"
import { bn } from "@/lib/helpers/numberUtils"

type ItemFees = {
  creatorFee: number | null
  creatorFeePercentage: string | null
  creatorFeesText: string | null
  creatorRoyaltiesText: string | null
  maxCreatorFee: number | null
  openseaSellerFee: number
  openseaSellerFeePercentage: string
  quantityAfterFees: BigNumber
  mapQuantityAfterFees: (quantity: BigNumber) => BigNumber
}

export const useItemFees = (
  itemDataKey: useItemFees_item$key | null,
  quantity: BigNumber = bn(0),
  optionalCreatorFeeBasisPoints?: number,
): ItemFees => {
  const item = useFragment(
    graphql`
      fragment useItemFees_item on ItemType
      @argumentDefinitions(chain: { type: "ChainScalar" }) {
        __typename

        ... on AssetType {
          totalCreatorFee
          collection {
            openseaSellerFeeBasisPoints
            isCreatorFeesEnforced
          }
        }
        ... on AssetBundleType {
          bundleCollection: collection {
            openseaSellerFeeBasisPoints
            totalCreatorFeeBasisPoints(chain: $chain)
            isCreatorFeesEnforced
          }
        }
      }
    `,
    itemDataKey,
  )
  const t = useTranslate("hooks")

  const isCreatorFeesEnforced =
    item?.__typename === "AssetType"
      ? item.collection.isCreatorFeesEnforced
      : item?.__typename === "AssetBundleType"
      ? item.bundleCollection?.isCreatorFeesEnforced
      : false

  const maxCreatorFee =
    (item?.__typename === "AssetType"
      ? item.totalCreatorFee
      : item?.__typename === "AssetBundleType"
      ? item.bundleCollection?.totalCreatorFeeBasisPoints
      : null) ?? null
  const creatorFee =
    optionalCreatorFeeBasisPoints !== undefined &&
    optionalCreatorFeeBasisPoints >= 0
      ? optionalCreatorFeeBasisPoints
      : maxCreatorFee

  const creatorFeePercentage =
    creatorFee !== null ? `${(creatorFee / 100).toString()}%` : null
  const creatorFeesText =
    creatorFee !== null
      ? t(
          "assetFees.creatorEarnings",
          "Creator earnings: {{totalCreatorFeePercent}}%",
          {
            totalCreatorFeePercent: (creatorFee / 100).toString(),
          },
          { forceString: true },
        )
      : null
  const creatorRoyaltiesText =
    maxCreatorFee !== null
      ? isCreatorFeesEnforced
        ? t(
            "assetFees.creatorEarningsDescription",
            "The creator(s) of this item will receive {{totalCreatorFeePercent}}% for every sale",
            {
              totalCreatorFeePercent: (maxCreatorFee / 100).toString(),
            },
            { forceString: true },
          )
        : t(
            "assetFees.creatorEarningsOptional",
            "The creator(s) of this item can receive up to {{totalCreatorFeePercent}}% for every sale",
            {
              totalCreatorFeePercent: (maxCreatorFee / 100).toString(),
            },
            { forceString: true },
          )
      : null

  const openseaSellerFee =
    (item?.__typename === "AssetType"
      ? item.collection.openseaSellerFeeBasisPoints
      : item?.__typename === "AssetBundleType"
      ? item.bundleCollection?.openseaSellerFeeBasisPoints
      : 0) ?? 0

  const openseaSellerFeePercentage = `${(openseaSellerFee / 100).toString()}%`

  const mapQuantityAfterFees = (q: BigNumber) => {
    return q.isNaN()
      ? bn(0)
      : q.times(bn(1).minus(bn((creatorFee ?? 0) + openseaSellerFee, 4)))
  }

  const quantityAfterFees = mapQuantityAfterFees(quantity)

  return {
    creatorFee,
    creatorFeePercentage,
    creatorFeesText,
    creatorRoyaltiesText,
    openseaSellerFee,
    openseaSellerFeePercentage,
    quantityAfterFees,
    mapQuantityAfterFees,
    maxCreatorFee,
  }
}
