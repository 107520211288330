import React from "react"
import {
  Icon,
  useIsLessThanXl,
  useIsLessThanXXl,
  Text,
  Flex,
} from "@opensea/ui-kit"
import { isFunction } from "lodash"
import { transparentize } from "polished"
import styled from "styled-components"
import { Link } from "@/components/common/Link"
import { IS_SERVER } from "@/constants/environment"
import { Block } from "@/design-system/Block"
import { Tooltip, TooltipProps } from "@/design-system/Tooltip"
import { useTranslate } from "@/hooks/useTranslate"
import { themeVariant } from "@/styles/styleUtils"

type RarityTooltipProps = TooltipProps

export const RarityTooltip = ({
  content,
  ...restProps
}: RarityTooltipProps) => {
  const t = useTranslate("components")
  const isSmallScreen = useIsLessThanXl()
  const isXXLScreen = !useIsLessThanXXl()

  return (
    <Tooltip
      appendTo={IS_SERVER ? undefined : document.body}
      content={
        <Block onClick={e => e.stopPropagation()}>
          {isFunction(content) ? content() : content}
          <Flex className="mb-1.5 mt-2.5 items-center">
            <Divider />
          </Flex>
          <Link href="https://support.opensea.io/articles/8867099">
            <FooterText>
              {t("rarityTooltip.rankedBy", "By OpenRarity")}
              <Icon
                className="-ml-0.5 mr-[-5px] align-text-bottom"
                size={14}
                value="chevron_right"
              />
            </FooterText>
          </Link>
        </Block>
      }
      contentPadding="12px 12px 8px"
      hideOnScroll
      interactive
      popperOptions={{
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              padding: isSmallScreen ? 8 : isXXLScreen ? 56 : 24,
            },
          },
        ],
      }}
      {...restProps}
    />
  )
}

const Divider = styled(Block)`
  left: 0;
  position: absolute;
  right: 0;

  ${props =>
    themeVariant({
      variants: {
        dark: {
          borderTop: `1px solid ${transparentize(
            0.8,
            props.theme.colors.darkGray,
          )}`,
        },
        light: {
          borderTop: `1px solid ${props.theme.colors.fog}`,
        },
      },
    })}
`

const FooterText = styled(Text.Body).attrs({
  className: "text-secondary",
  size: "tiny",
})`
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;
`
