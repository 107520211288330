import React from "react"
import { CenterAligned, Text } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { RarityIndicatorVariant } from "@/components/search/rarity"
import { PLACEHOLDER_IMAGE } from "@/constants"
import { useTranslate } from "@/hooks/useTranslate"
import {
  AssetMedia_asset$data,
  AssetMedia_asset$key,
} from "@/lib/graphql/__generated__/AssetMedia_asset.graphql"
import { graphql } from "@/lib/graphql/graphql"
import {
  isImageUrl,
  isAudioUrl,
  isVideoUrl,
  isWebGlUrl,
  fixAssetHost,
} from "@/lib/helpers/urls"
import { AssetMediaAnimation } from "./AssetMediaAnimation.react"
import { AssetMediaAudio } from "./AssetMediaAudio.react"
import { AssetMediaContainer } from "./AssetMediaContainer.react"
import {
  AssetMediaImage,
  AssetMediaImageBaseProps,
} from "./AssetMediaImage.react"
import { AssetMediaPlaceholderImage } from "./AssetMediaPlaceholderImage.react"
import { AssetMediaVideo } from "./AssetMediaVideo.react"
import { AssetMediaWebgl } from "./AssetMediaWebgl.react"

enum AssetMediaVariant {
  Animation,
  Audio,
  Image,
  PlaceholderImage,
  Video,
  Webgl,
}

type AssetMediaBaseProps = {
  asset: AssetMedia_asset$key
  className?: string
  isMuted?: boolean
  title?: string
  useCustomPlayButton?: boolean
  showControls?: boolean
  showModel?: boolean
  showAssetMediaEditions?: boolean
  onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void
  rarityIndicator?: RarityIndicatorVariant
}

type AssetMediaAudioVideoProps = {
  autoPlay?: boolean
  isMuted?: boolean
  showControls?: boolean
  useCustomPlayButton?: boolean
}

export type AssetMediaProps = AssetMediaBaseProps &
  AssetMediaAudioVideoProps &
  AssetMediaImageBaseProps

export const AssetMedia = ({
  asset: assetKey,
  className,
  showModel,
  title,
  showAssetMediaEditions = false, // Default false to ensure Gallery doesnt show
  rarityIndicator,
  ...rest
}: AssetMediaProps) => {
  const t = useTranslate("components")
  const asset = useFragment(
    graphql`
      fragment AssetMedia_asset on AssetType
      @argumentDefinitions(
        identity: { type: "IdentityInputType", defaultValue: {} }
        showQuantity: { type: "Boolean", defaultValue: false }
        showRarity: { type: "Boolean", defaultValue: false }
      ) {
        animationUrl
        displayImageUrl
        imageUrl
        isDelisted

        ...AssetMediaAnimation_asset
        ...AssetMediaAudio_asset
        ...AssetMediaContainer_asset
          @arguments(
            identity: $identity
            showQuantity: $showQuantity
            showRarity: $showRarity
          )
        ...AssetMediaImage_asset
        ...AssetMediaPlaceholderImage_asset
        ...AssetMediaVideo_asset
        ...AssetMediaWebgl_asset
      }
    `,
    assetKey,
  )

  if (asset.isDelisted) {
    return (
      <CenterAligned className="h-full w-full bg-component-gray-1">
        <Text className="block" size="small" weight="semibold">
          {t("assets.assetMedia.itemDelisted", "Item delisted")}
        </Text>
      </CenterAligned>
    )
  }

  const { animationUrl, audioUrl, imageUrl, imagePreviewUrl, variant } =
    computeUrlsAndVariant(asset)
  const imageConfig: AssetMediaImageBaseProps = rest
  const audioVideoProps: AssetMediaAudioVideoProps = rest

  const assetMediaContainerProps = {
    asset,
    className,
    showAssetMediaEditions,
    title,
    rarityIndicator,
  }

  switch (variant) {
    case AssetMediaVariant.Audio:
      return (
        <AssetMediaContainer {...assetMediaContainerProps}>
          <AssetMediaAudio
            {...audioVideoProps}
            asset={asset}
            imageConfig={imageConfig}
            imageUrl={imageUrl}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            url={audioUrl!}
          />
        </AssetMediaContainer>
      )
    case AssetMediaVariant.Webgl:
      return (
        <AssetMediaContainer {...assetMediaContainerProps}>
          <AssetMediaWebgl
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            animationUrl={animationUrl!}
            asset={asset}
            imageConfig={imageConfig}
            imagePreviewUrl={imageUrl}
            showModel={showModel}
          />
        </AssetMediaContainer>
      )
    case AssetMediaVariant.Video:
      return (
        <AssetMediaContainer {...assetMediaContainerProps}>
          <AssetMediaVideo
            {...audioVideoProps}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            animationUrl={animationUrl!}
            asset={asset}
            imageConfig={imageConfig}
            imagePreviewUrl={imagePreviewUrl}
          />
        </AssetMediaContainer>
      )
    case AssetMediaVariant.Animation:
      return (
        <AssetMediaContainer {...assetMediaContainerProps}>
          <AssetMediaAnimation
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            animationUrl={animationUrl!}
            asset={asset}
            autoPlay={audioVideoProps.autoPlay}
            imageConfig={imageConfig}
            imagePreviewUrl={imageUrl}
          />
        </AssetMediaContainer>
      )
    case AssetMediaVariant.PlaceholderImage:
      return (
        <AssetMediaContainer {...assetMediaContainerProps}>
          <AssetMediaPlaceholderImage
            alt={imageConfig.alt}
            asset={asset}
            url={imageUrl}
          />
        </AssetMediaContainer>
      )
    case AssetMediaVariant.Image:
    default:
      return (
        <AssetMediaContainer {...assetMediaContainerProps}>
          <AssetMediaImage {...imageConfig} asset={asset} url={imageUrl} />
        </AssetMediaContainer>
      )
  }
}

type AssetMediaUrlsAndVariant = {
  animationUrl?: string
  audioUrl?: string
  imageUrl: string
  imagePreviewUrl?: string
  variant: AssetMediaVariant
}

const computeUrlsAndVariant = (
  asset: AssetMedia_asset$data,
): AssetMediaUrlsAndVariant => {
  const {
    animationUrl: rawAnimationUrl,
    displayImageUrl: rawDisplayImageUrl,
    imageUrl: rawImageUrl,
  } = asset

  const animationUrl = fixAssetHost(rawAnimationUrl)
  const displayImageUrl = fixAssetHost(rawDisplayImageUrl) ?? undefined
  const imageUrl = fixAssetHost(rawImageUrl) ?? undefined

  let computedAnimationUrl =
    animationUrl && !isImageUrl(animationUrl)
      ? animationUrl
      : displayImageUrl && isVideoUrl(displayImageUrl)
      ? displayImageUrl
      : undefined
  const computedAudioUrl =
    animationUrl && isAudioUrl(animationUrl) ? animationUrl : undefined

  const computedImageUrl =
    animationUrl && isImageUrl(animationUrl)
      ? animationUrl
      : displayImageUrl || PLACEHOLDER_IMAGE

  let imagePreviewUrl
  let variant
  if (computedAudioUrl && computedImageUrl) {
    variant = AssetMediaVariant.Audio
  } else if (computedAnimationUrl && isWebGlUrl(computedAnimationUrl)) {
    variant = AssetMediaVariant.Webgl
    imagePreviewUrl = computedImageUrl
  } else if (isVideoUrl(computedImageUrl)) {
    variant = AssetMediaVariant.Video
    computedAnimationUrl = computedImageUrl
  } else if (computedAnimationUrl && isVideoUrl(computedAnimationUrl)) {
    variant = AssetMediaVariant.Video
    imagePreviewUrl = computedImageUrl
  } else if (computedAnimationUrl) {
    variant = AssetMediaVariant.Animation
    imagePreviewUrl = computedImageUrl
  } else if (!imageUrl && displayImageUrl) {
    variant = AssetMediaVariant.PlaceholderImage
  } else {
    variant = AssetMediaVariant.Image
  }

  return {
    animationUrl: computedAnimationUrl,
    audioUrl: computedAudioUrl,
    imagePreviewUrl,
    imageUrl: computedImageUrl,
    variant,
  }
}
