const DEAL_URL = "/deal"
export const DEAL_CREATE_URL = "/deal/create"
export const DEAL_HELP_CENTER_ARTICLE_URL =
  "https://support.opensea.io/articles/8867092"

export const getCreateDealUrl = (takerAddress: string) =>
  `${DEAL_CREATE_URL}/${takerAddress}`

export const getDealUrl = (relayId: string) => {
  return `${DEAL_URL}/${relayId}`
}
