import { hasGraphQLResponseError } from "@/lib/graphql/error"
import { IsIpRightsTakedownDelistedMode } from "../../constants/"

export const hasIpRightsViolationError = <T>(
  error: T,
): IsIpRightsTakedownDelistedMode | undefined => {
  const msgText =
    "removed based on a claim of intellectual property infringement"

  if (hasGraphQLResponseError(error, `Collection ${msgText}`)) {
    return "collection"
  }

  if (hasGraphQLResponseError(error, `Collection ${msgText} - owner`)) {
    return "collection-owner"
  }

  if (hasGraphQLResponseError(error, `Asset ${msgText}`)) {
    return "item"
  }

  if (hasGraphQLResponseError(error, `Asset ${msgText} - owner`)) {
    return "item-owner"
  }

  return undefined
}

function getElementPath(el?: HTMLElement): string[] {
  if (!el) {
    return []
  }

  let nodeName = el.nodeName.toLowerCase()

  if (el === document.body) {
    return ["body"]
  }

  if (el.id) {
    nodeName += "#" + el.id
  } else if (el.classList.length) {
    nodeName += "." + Array.from(el.classList).join(".")
  }

  return el.parentElement
    ? [...getElementPath(el.parentElement), nodeName]
    : [nodeName]
}

export function serializeEvent(event: Event) {
  const { type, target } = event

  const error = new Error(`Event thrown as error: ${type}`)
  error.name = "EventError"

  if (target instanceof HTMLElement && typeof window !== "undefined") {
    const targetElementPath = getElementPath(target)
    error.name = `Event at ${
      targetElementPath[targetElementPath.length - 1]
    } thrown as error`

    const priorStack = error.stack?.split("\n").slice(1).join("\n")
    error.stack = `${targetElementPath.join(" > ")}\n${priorStack}`
  }

  return error
}
