import React, { RefObject } from "react"
import { Icon, useIsLessThanLg, UnstyledButton } from "@opensea/ui-kit"
import { transparentize } from "polished"
import styled from "styled-components"
import { useAppContext } from "@/hooks/useAppContext"

type PlayButtonProps = {
  playingRef: RefObject<HTMLAudioElement & HTMLVideoElement>
  isPlaying: boolean
  setIsPlaying: (
    isPlaying: boolean | ((prevState: boolean) => boolean),
  ) => unknown
  autoPlay?: boolean
  useCustomPlayButton?: boolean
}

export const PlayButton = ({
  playingRef,
  isPlaying,
  setIsPlaying,
  autoPlay,
  useCustomPlayButton,
}: PlayButtonProps) => {
  const { stopCurrentlyPlayingMedia, updateContext } = useAppContext()
  const isMdScreen = useIsLessThanLg()

  const stopCurrentlyPlayingMediaFn = (
    refToCompare: (HTMLAudioElement & HTMLVideoElement) | null,
  ) => {
    if (playingRef.current !== refToCompare) {
      playingRef.current?.pause()
      setIsPlaying(false)
    }
  }

  if (!autoPlay && useCustomPlayButton) {
    return (
      <UnstyledButton
        onClick={e => {
          e.preventDefault()
          if (stopCurrentlyPlayingMedia !== undefined) {
            stopCurrentlyPlayingMedia(playingRef.current)
          }

          updateContext({
            stopCurrentlyPlayingMedia: !isPlaying
              ? stopCurrentlyPlayingMediaFn
              : undefined,
          })

          isPlaying ? playingRef.current?.pause() : playingRef.current?.play()
          setIsPlaying(prev => !prev)
        }}
      >
        <PlayIcon
          $isMdScreen={isMdScreen}
          size={isMdScreen ? 20 : 24}
          value={isPlaying ? "pause" : "play_arrow"}
        />
      </UnstyledButton>
    )
  }

  return null
}

const PlayIcon = styled(Icon)<{ $isMdScreen: boolean }>`
  && {
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: ${props => (props.$isMdScreen ? "32px" : "44px")};
    justify-content: center;
    bottom: 8px;
    right: 8px;
    width: ${props => (props.$isMdScreen ? "32px" : "44px")};
    z-index: 1;
    position: absolute;
    color: ${props => props.theme.colors.white};
    background-color: ${props =>
      transparentize(0.6, props.theme.colors.silverChalice)};
    backdrop-filter: blur(10px);

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  }
`
