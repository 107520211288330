import React from "react"
import { HUES } from "../../styles/themes"

type Props = {
  className?: string
  height?: number
  width?: number
  fill?: string
}

export const FacebookLogo = ({
  className,
  fill = HUES.gray,
  width,
  height,
}: Props) => (
  <svg
    className={className}
    fill={fill}
    style={{ height, width }}
    viewBox="0 0 24 24"
  >
    <path d="M13.0297 22V12.8777H16.0904L16.5496 9.32156H13.0297V7.05147C13.0297 6.0222 13.3143 5.32076 14.7919 5.32076L16.6735 5.31999V2.13923C16.3481 2.09695 15.2312 2 13.9312 2C11.2166 2 9.35811 3.65697 9.35811 6.69927V9.32156H6.28809V12.8777H9.35811V22H13.0297Z" />
  </svg>
)
