/**
 * @generated SignedSource<<efa9750609ab4331a14156d4a2a52363>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useTotalItems_ordersData$data = ReadonlyArray<{
  readonly item: {
    readonly asset?: {
      readonly relayId: string;
    };
    readonly assetQuantities?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly asset: {
            readonly relayId: string;
          };
        } | null;
      } | null>;
    };
  } | null;
  readonly " $fragmentType": "useTotalItems_ordersData";
}>;
export type useTotalItems_ordersData$key = ReadonlyArray<{
  readonly " $data"?: useTotalItems_ordersData$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTotalItems_ordersData">;
}>;

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AssetType",
    "kind": "LinkedField",
    "name": "asset",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "relayId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "useTotalItems_ordersData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "AssetQuantityDataType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 30
                }
              ],
              "concreteType": "AssetQuantityTypeConnection",
              "kind": "LinkedField",
              "name": "assetQuantities",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetQuantityTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AssetQuantityType",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": (v0/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "assetQuantities(first:30)"
            }
          ],
          "type": "AssetBundleType",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrderDataType",
  "abstractKey": null
};
})();

(node as any).hash = "123a4acb12ebf516fda59471772ff152";

export default node;
