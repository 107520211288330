/**
 * @generated SignedSource<<51b5835fd4827e610a18c7634e74a497>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type authLogoutMutation$variables = {
  address: string;
  deviceId: string;
};
export type authLogoutMutation$data = {
  readonly AuthTypeV2: {
    readonly webLogout: boolean;
  };
};
export type authLogoutMutation = {
  response: authLogoutMutation$data;
  variables: authLogoutMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "address"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuthTypeV2",
    "kind": "LinkedField",
    "name": "AuthTypeV2",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "address",
            "variableName": "address"
          },
          {
            "kind": "Variable",
            "name": "deviceId",
            "variableName": "deviceId"
          }
        ],
        "kind": "ScalarField",
        "name": "webLogout",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "authLogoutMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "authLogoutMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "751a8ebe04a6e69e87eae3b7e74019df",
    "id": null,
    "metadata": {},
    "name": "authLogoutMutation",
    "operationKind": "mutation",
    "text": "mutation authLogoutMutation(\n  $address: AddressScalar!\n  $deviceId: String!\n) {\n  AuthTypeV2 {\n    webLogout(address: $address, deviceId: $deviceId)\n  }\n}\n"
  }
};
})();

(node as any).hash = "a8f054a38e920ddde28da7b048207ed3";

export default node;
