/**
 * @generated SignedSource<<ae55377a75e10241a06de0f50d192d28>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConfirmationItem_payment_asset$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmationItem_asset_item_payment_asset">;
  readonly " $fragmentType": "ConfirmationItem_payment_asset";
};
export type ConfirmationItem_payment_asset$key = {
  readonly " $data"?: ConfirmationItem_payment_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmationItem_payment_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConfirmationItem_payment_asset",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConfirmationItem_asset_item_payment_asset"
    }
  ],
  "type": "PaymentAssetType",
  "abstractKey": null
};

(node as any).hash = "bfd2343e069890449ebabb894a8a3be8";

export default node;
