import React, { forwardRef } from "react"
import { Text } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { LinkProps } from "@/components/common/Link"
import { Avatar } from "../Avatar"
import { Block, BlockProps } from "../Block"
import { Flex, FlexProps } from "../Flex"
import { Item } from "../Item"
import { getInteractiveElement, isInteractiveElement } from "../utils/element"

export type MenuProps = BlockProps & {
  direction: "horizontal" | "vertical"
  disableScroll?: boolean
}

const MenuAvatar = styled(Avatar).attrs<{ "aria-hidden": string }>({
  "aria-hidden": "true",
})`
  align-self: center;
  order: 2;
`

const MenuBase = styled(Block).attrs({ as: "ul" })<MenuProps>`
  margin: 0;

  &&& {
    padding: 0 8px;
  }

  ${props =>
    props.direction === "horizontal"
      ? css`
          display: flex;
          ${!props.disableScroll &&
          css`
            overflow: auto;
          `}

          ${MenuTitle} {
            flex: 0;
          }
        `
      : css`
          ${StyledMenuItem}, ${StyledListMenuItem} {
            width: 100%;
          }
        `}
`

type BaseMenuItemProps = FlexProps & {
  $active?: boolean
  direction?: "horizontal" | "vertical"
}

type LinkMenuItemProps = BaseMenuItemProps & { href: string } & Omit<
    LinkProps,
    keyof BaseMenuItemProps
  >

type ClickableMenuItemProps = BaseMenuItemProps & {
  onClick?: () => unknown
  href?: string
}

export type MenuItemProps =
  | BaseMenuItemProps
  | LinkMenuItemProps
  | ClickableMenuItemProps

const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
  function MenuItem(props, ref) {
    if (isInteractiveElement(props)) {
      return (
        <StyledListMenuItem ref={ref}>
          <StyledMenuItem {...props} />
        </StyledListMenuItem>
      )
    }

    return (
      <MenuItemWrapper ref={ref}>
        <StyledMenuItem {...props} />
      </MenuItemWrapper>
    )
  },
)

const MenuItemWrapper = styled.li`
  position: relative;
`

const StyledListMenuItem = styled.li`
  position: relative;
`

const MenuTitle = styled(Text.Body).attrs({
  size: "medium",
  weight: "semibold",
})`
  &&& {
    flex: 1;
    text-align: left;
    order: 3;
    color: inherit;
  }
  &:not(:first-child) {
    margin-left: 16px;
  }
`

const MenuExtra = styled(Text.Body).attrs({
  className: "text-inherit",
  size: "tiny",
})``

const MenuSide = styled(Item.Side)``

const StyledMenuItem = styled(Flex).attrs<MenuItemProps>(props => ({
  as: getInteractiveElement(props),
}))<MenuItemProps>`
  width: 100%;
  border-radius: ${props => props.theme.borderRadius.default};
  transition: color 0.25s ease-in-out;
  color: ${props =>
    props.$active
      ? props.theme.colors.text.primary
      : props.theme.colors.text.secondary};

  ${props =>
    props.$active &&
    props.direction === "vertical" &&
    css`
      background-color: ${props =>
        props.theme.colors.components.background.gray1};
    `}

  &:after {
    transition: background-color 0.25s ease-in-out;
    background-color: ${props =>
      props.$active && props.direction === "horizontal"
        ? props.theme.colors.text.primary
        : "transparent"};
    bottom: 0%;
    content: "";
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  :hover {
    color: ${props => props.theme.colors.text.primary};

    ${props =>
      props.direction === "vertical" &&
      css`
        background-color: ${props =>
          props.theme.colors.components.background.gray1};
      `}
  }
  :active {
    color: ${props => props.theme.colors.text.secondary};

    ${props =>
      props.direction === "vertical" &&
      css`
        background-color: ${props =>
          props.theme.colors.components.background.gray2};
      `}
  }
`

export const Menu = Object.assign(MenuBase, {
  Base: MenuBase,
  Item: MenuItem,
  Title: MenuTitle,
  Avatar: MenuAvatar,
  Side: MenuSide,
  Extra: MenuExtra,
})
