/**
 * @generated SignedSource<<a54ebd863d4dba29566f1da2beb9381c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useSetEmbeddedWalletMutation$variables = {
  privyId: string;
};
export type useSetEmbeddedWalletMutation$data = {
  readonly accounts: {
    readonly setEmbeddedWallet: {
      readonly isEmbeddedWallet: boolean;
      readonly relayId: string;
    };
  };
};
export type useSetEmbeddedWalletMutation = {
  response: useSetEmbeddedWalletMutation$data;
  variables: useSetEmbeddedWalletMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "privyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "privyUserDid",
    "variableName": "privyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isEmbeddedWallet",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSetEmbeddedWalletMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountMutationType",
        "kind": "LinkedField",
        "name": "accounts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AccountType",
            "kind": "LinkedField",
            "name": "setEmbeddedWallet",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSetEmbeddedWalletMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountMutationType",
        "kind": "LinkedField",
        "name": "accounts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AccountType",
            "kind": "LinkedField",
            "name": "setEmbeddedWallet",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e5ce0bb03af3cd235606be5af6631474",
    "id": null,
    "metadata": {},
    "name": "useSetEmbeddedWalletMutation",
    "operationKind": "mutation",
    "text": "mutation useSetEmbeddedWalletMutation(\n  $privyId: String!\n) {\n  accounts {\n    setEmbeddedWallet(privyUserDid: $privyId) {\n      relayId\n      isEmbeddedWallet\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2c2cf8293a2262fffa6370c4938e1493";

export default node;
