import React, { useCallback } from "react"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { BigNumber, display } from "@/lib/helpers/numberUtils"
import { GaslessCancelLearnMoreLink } from "../components/GaslessCancelLearnMoreLink.react"
import {
  trackGaslessCancellationFailed,
  trackGaslessCancellationSuccess,
} from "../utils/analytics"
import { GaslessCancelResult } from "../utils/readGaslessCancelResult"

type ShowCancelOrderFinalizedToastProps = Pick<
  GaslessCancelResult,
  "failedCount" | "fulfilledCount" | "canceledQuantity" | "ordersSide"
> & {
  totalCount: number
  initialRemainingQuantity: BigNumber
}

type ShowCancellationPartiallyFilledProps = {
  fulfilledQuantity: BigNumber
}

export const useGaslessCancelOrderToasts = () => {
  const t = useTranslate("components")
  const { showSuccessMessage, showErrorMessage, showWarningMessage } =
    useToasts()

  const showCancelOrderCompletedToast = useCallback(
    ({
      failedCount,
      totalCount,
      fulfilledCount,
      canceledQuantity,
      initialRemainingQuantity,
      ordersSide,
    }: ShowCancelOrderFinalizedToastProps) => {
      if (failedCount > 0) {
        trackGaslessCancellationFailed({
          failedCount,
          totalCount,
          fulfilledCount,
          canceledQuantity: canceledQuantity.toNumber(),
          initialRemainingQuantity: initialRemainingQuantity.toNumber(),
          ordersSide,
        })

        if (totalCount > 1) {
          showWarningMessage(
            <span>
              {t(
                "gaslessCancel.partialFail",
                "{{completed}} of {{total}} cancellations were successful. {{learnMoreLink}}",
                {
                  completed: display(totalCount - failedCount),
                  total: display(totalCount),
                  learnMoreLink: <GaslessCancelLearnMoreLink />,
                },
              )}
            </span>,
            { timeout: false },
          )
          return
        }

        if (fulfilledCount > 0) {
          if (ordersSide === "BID") {
            showErrorMessage(
              <span>
                {t(
                  "gaslessCancel.fail.offer",
                  "A cancellation failed because the offer was fulfilled. {{learnMoreLink}}",
                  { learnMoreLink: <GaslessCancelLearnMoreLink /> },
                )}
              </span>,
              { timeout: false },
            )
            return
          }

          showErrorMessage(
            <span>
              {t(
                "gaslessCancel.fail.listing",
                "A cancellation failed because the listing was fulfilled. {{learnMoreLink}}",
                { learnMoreLink: <GaslessCancelLearnMoreLink /> },
              )}
            </span>,
            { timeout: false },
          )
          return
        }

        showErrorMessage(
          <span>
            {t(
              "gaslessCancel.fail.general",
              "Cancellation failed. Try again or cancel on-chain. {{learnMoreLink}}",
              { learnMoreLink: <GaslessCancelLearnMoreLink /> },
            )}
          </span>,
          { timeout: false },
        )
        return
      }

      const fulfilledQuantity = initialRemainingQuantity.minus(canceledQuantity)
      const fulfilledQuantityCopy = t(
        "gaslessCancel.successWithSomeFulfilled.fulfilledQuantity",
        {
          "0": "{{fulfilledQuantity}} of {{initialRemainingQuantity}} were partially filled before cancellation.",
          one: "{{fulfilledQuantity}} item was partially filled before cancellation.",
          other:
            "{{fulfilledQuantity}} of {{initialRemainingQuantity}} were partially filled before cancellation.",
        },
        {
          fulfilledQuantity: display(fulfilledQuantity),
          initialRemainingQuantity: display(initialRemainingQuantity),
          count: fulfilledQuantity.toNumber(),
        },
      )

      trackGaslessCancellationSuccess({
        failedCount,
        totalCount,
        fulfilledCount,
        canceledQuantity: canceledQuantity.toNumber(),
        initialRemainingQuantity: initialRemainingQuantity.toNumber(),
        ordersSide,
      })

      // Show success toast
      if (ordersSide === "BID") {
        if (fulfilledQuantity.isGreaterThanOrEqualTo(1)) {
          showWarningMessage(
            <span>
              {t(
                "gaslessCancel.successWithSomeFulfilled.offers",
                {
                  "0": "{{displayCount}} offers have been canceled. Some were partially filled before cancellation. {{learnMoreLink}}",
                  one: "{{displayCount}} offer has been canceled. {{fulfilledQuantityCopy}} {{learnMoreLink}}",
                  other:
                    "{{displayCount}} offers have been canceled. Some were partially filled before cancellation. {{learnMoreLink}}",
                },
                {
                  count: totalCount,
                  displayCount: display(totalCount),
                  fulfilledQuantityCopy,
                  learnMoreLink: <GaslessCancelLearnMoreLink />,
                },
              )}
            </span>,
            { timeout: false },
          )
          return
        }

        showSuccessMessage(
          <span>
            {t(
              "gaslessCancel.success.offers",
              {
                "0": "{{displayCount}} offers have been canceled.",
                one: "{{displayCount}} offer has been canceled.",
                other: "{{displayCount}} offers have been canceled.",
              },
              { count: totalCount, displayCount: display(totalCount) },
            )}
          </span>,
        )
        return
      }

      if (ordersSide === "ASK") {
        if (fulfilledQuantity.isGreaterThan(1)) {
          showWarningMessage(
            <span>
              {t(
                "gaslessCancel.successWithSomeFulfilled.listings",
                {
                  "0": "{{displayCount}} listings have been canceled. Some were partially filled before cancellation. {{learnMoreLink}}",
                  one: "{{displayCount}} listing has been canceled. {{fulfilledQuantityCopy}} {{learnMoreLink}}",
                  other:
                    "{{displayCount}} listings have been canceled. Some were partially filled before cancellation. {{learnMoreLink}}",
                },
                {
                  count: totalCount,
                  displayCount: display(totalCount),
                  fulfilledQuantityCopy,
                  learnMoreLink: <GaslessCancelLearnMoreLink />,
                },
              )}
            </span>,
            { timeout: false },
          )
          return
        }

        showSuccessMessage(
          <span>
            {t(
              "gaslessCancel.success.listings",
              {
                "0": "{{displayCount}} listings have been canceled.",
                one: "{{displayCount}} listing has been canceled.",
                other: "{{displayCount}} listings have been canceled.",
              },
              { count: totalCount, displayCount: display(totalCount) },
            )}
          </span>,
        )
        return
      }

      showSuccessMessage(
        <span>
          {t(
            "gaslessCancel.success.orders",
            {
              "0": "{{displayCount}} orders have been canceled.",
              one: "{{displayCount}} order has been canceled.",
              other: "{{displayCount}} orders have been canceled.",
            },
            { count: totalCount, displayCount: display(totalCount) },
          )}
        </span>,
      )
      return
    },
    [showErrorMessage, showSuccessMessage, showWarningMessage, t],
  )

  const showCancellationPartiallyFilledToast = ({
    fulfilledQuantity,
  }: ShowCancellationPartiallyFilledProps) => {
    showErrorMessage(
      <span>
        {t(
          "gaslessCancel.warning.partialFillDuringCancellation",
          {
            "0": "{{fulfilledQuantity}} item was partially filled before cancellation. {{learnMoreLink}}",
            one: "{{fulfilledQuantity}} item was partially filled before cancellation. {{learnMoreLink}}",
            other:
              "{{fulfilledQuantity}} items were partially filled before cancellation. {{learnMoreLink}}",
          },
          {
            count: fulfilledQuantity.toNumber(),
            fulfilledQuantity: display(fulfilledQuantity),
            learnMoreLink: <GaslessCancelLearnMoreLink />,
          },
        )}
      </span>,
      { timeout: false },
    )
  }

  return { showCancelOrderCompletedToast, showCancellationPartiallyFilledToast }
}
