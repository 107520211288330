import React, { useCallback, useState } from "react"
import { FlexColumn, MaterialIcon, RadioButtonGroup } from "@opensea/ui-kit"
import { Link } from "@/components/common/Link"
import { Button } from "@/design-system/Button"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { MFA_STEPS } from "@/features/os-wallet/components/constants"
import { WalletTotpCodeModal } from "@/features/os-wallet/components/OSWalletModal/components/refresh/WalletTotpCodeModal.react"
import { useTranslate } from "@/hooks/useTranslate"
import { OSWalletModalBody } from "./OSWalletModalBody.react"
import { WalletPhoneNumberModal } from "./WalletPhoneNumberModal.react"
import { WalletSelectionModal } from "./WalletSelectionModal.react"

type MfaValue = "phone" | "totp"

type MfaOption = {
  value: MfaValue
  icon: MaterialIcon
  title: string
  description: string
}

const DEFAULT_OPTION: MfaValue = "totp"

export type WalletMfaEnrollmentModalProps = {
  accessToken: string
  privyId: string
}

export const WalletMfaEnrollmentModal = ({
  accessToken,
  privyId,
}: WalletMfaEnrollmentModalProps) => {
  const t = useTranslate("common")
  const { onNext, onReplace } = useMultiStepFlowContext()
  const [mfaValue, setMfaValue] = useState<MfaValue>(DEFAULT_OPTION)
  const options: MfaOption[] = [
    {
      value: "totp",
      icon: "gpp_good" as MaterialIcon,
      title: t("wallet.opensea.securityOptionAuthenticator", "Authenticator"),
      description: t(
        "wallet.opensea.securityOptionAuthenticatorDescription",
        "Most secure option",
      ),
    },
    {
      value: "phone",
      icon: "smartphone",
      title: t("wallet.opensea.securityOptionSms", "SMS"),
      description: t(
        "wallet.opensea.securityOptionSmsDescription",
        "US and Canada only",
      ),
    },
  ]

  const handleContinue = useCallback(async () => {
    if (mfaValue === "phone") {
      onNext(() => (
        <WalletPhoneNumberModal accessToken={accessToken} privyId={privyId} />
      ))
    } else {
      onNext(() => (
        <WalletTotpCodeModal accessToken={accessToken} privyId={privyId} />
      ))
    }
  }, [accessToken, mfaValue, onNext, privyId])

  return (
    <OSWalletModalBody
      className="justify-between"
      showLogo={false}
      step={1}
      steps={MFA_STEPS[mfaValue]}
      title={t("wallet.opensea.chooseSecurity", "Choose Security Option")}
      onPrevious={() => onReplace(<WalletSelectionModal />)}
    >
      <FlexColumn className="rounded-t-[12px]">
        <RadioButtonGroup
          className="mt-2 shadow-elevation-1"
          value={mfaValue}
          onValueChange={value => setMfaValue(value as MfaValue)}
        >
          {options.map(({ value, icon, title, description }) => (
            <RadioButtonGroup.Item
              className="bg-base-1 dark:bg-component-gray-1"
              id={value}
              key={value}
              value={value}
            >
              <RadioButtonGroup.Item.Icon
                className="self-center"
                size={32}
                value={icon}
              />
              <RadioButtonGroup.Item.Content>
                <RadioButtonGroup.Item.Title>
                  {title}
                </RadioButtonGroup.Item.Title>
                <RadioButtonGroup.Item.Description weight="regular">
                  {description}
                </RadioButtonGroup.Item.Description>
                <RadioButtonGroup.Item.Description size="tiny" weight="regular">
                  {value === "totp" &&
                    t(
                      "wallet.opensea.authenticatorRecommendation",
                      "We recommend using Google Authenticator. Please download the app from {{playStoreLink}} or {{appStoreLink}} on your mobile device.",
                      {
                        playStoreLink: (
                          <Link
                            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US&pli=1"
                            target="_blank"
                          >
                            {t(
                              "wallet.opensea.googlePlayStore",
                              "Google Play Store",
                            )}
                          </Link>
                        ),
                        appStoreLink: (
                          <Link
                            href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                            target="_blank"
                          >
                            {t(
                              "wallet.opensea.appleAppStore",
                              "Apple App Store",
                            )}
                          </Link>
                        ),
                      },
                    )}
                </RadioButtonGroup.Item.Description>
              </RadioButtonGroup.Item.Content>
            </RadioButtonGroup.Item>
          ))}
        </RadioButtonGroup>
      </FlexColumn>
      <Button onClick={handleContinue}>
        {t("wallet.opensea.continue.cta", "Continue")}
      </Button>
    </OSWalletModalBody>
  )
}
