import React from "react"

type Props = {
  className?: string
  width?: number
  fill?: string
}

export function ApeLogo({ className, fill = "lightgrey", width = 24 }: Props) {
  return (
    <svg
      style={{ width, height: width }}
      viewBox="0 0 440 440"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill}
    >
      <g clipPath="url(#clip0_66_5903)">
        <rect y="0.939575" width="421.06" height="421.06" rx="210.53" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-81.9623 0.939575C-83.7448 0.939575 -85.1898 2.38461 -85.1898 4.16715V434.766C-85.1898 436.549 -83.7448 437.994 -81.9623 437.994H482.462C484.245 437.994 485.69 436.549 485.69 434.766V4.16714C485.69 2.38461 484.245 0.939575 482.462 0.939575H-81.9623ZM141.41 136.197H112.296L93.5196 292.315H116.937L119.68 258.349H132.76L135.503 292.315H160.186L141.41 136.197ZM121.368 237.674L123.899 205.606L125.798 178.602H126.431L128.33 205.606L131.072 237.674H121.368ZM186.814 292.315H211.92V233.454H219.726C237.658 233.454 248.629 222.906 248.629 204.13V165.733C248.629 146.746 237.658 136.197 219.726 136.197H186.814V292.315ZM217.616 214.678H212.131V154.974H217.616C221.835 154.974 223.523 158.771 223.523 165.733V204.13C223.523 210.881 221.835 214.678 217.616 214.678ZM277.548 136.197V292.315H326.493V271.64H302.653V223.75H323.961V203.075H302.653V156.872H326.493V136.197H277.548Z"
          fill="grey"
        />
      </g>
      <defs>
        <clipPath id="clip0_66_5903">
          <rect
            y="0.939575"
            width="421.06"
            height="421.06"
            rx="210.53"
            fill="currentColor"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
