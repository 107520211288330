import React from "react"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { Popover } from "@/design-system/Popover"
import { useTranslate } from "@/hooks/useTranslate"
import { SocialLinks_collection$key } from "@/lib/graphql/__generated__/SocialLinks_collection.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { getSocialIcon, Social } from "@/lib/helpers/icons"
import { getSocialAccountUrl } from "@/lib/helpers/socialUrls"
import { Link } from "../Link"

type SocialIconProps = Omit<Social, "name">

type Props = {
  collectionKey: SocialLinks_collection$key
  emptyPlaceholder: React.ReactNode
}

export const SocialLinks = ({ collectionKey, emptyPlaceholder }: Props) => {
  const t = useTranslate("components")
  const collection = useFragment(
    graphql`
      fragment SocialLinks_collection on CollectionType {
        discordUrl
        externalUrl
        instagramUsername
        mediumUsername
        telegramUrl
        twitterUsername
        connectedTwitterUsername
      }
    `,
    collectionKey,
  )

  const {
    discordUrl,
    externalUrl,
    instagramUsername,
    mediumUsername,
    telegramUrl,
    twitterUsername,
    connectedTwitterUsername,
  } = collection

  const iconProps: SocialIconProps = {
    fill: "seaBlue",
    height: 24,
    width: 24,
    withHoverChange: true,
  }

  const hasConnectedTwitter = !!connectedTwitterUsername

  const twitterNameToDisplay = hasConnectedTwitter
    ? connectedTwitterUsername
    : twitterUsername

  const links = [
    {
      tooltip: hasConnectedTwitter
        ? t("socialLinks.connectedTwitter", "Connected Twitter")
        : "Twitter",
      url:
        twitterNameToDisplay &&
        getSocialAccountUrl("twitter", twitterNameToDisplay),
      icon: getSocialIcon({ name: "twitter", ...iconProps }),
    },
    {
      tooltip: "Discord",
      url: discordUrl,
      icon: getSocialIcon({ name: "discord", ...iconProps }),
    },
    {
      tooltip: "Website",
      url: externalUrl,
      icon: getSocialIcon({ name: "website", ...iconProps }),
    },
    {
      tooltip: "Instagram",
      url:
        instagramUsername &&
        getSocialAccountUrl("instagram", instagramUsername),
      icon: getSocialIcon({ name: "instagram", ...iconProps }),
    },
    {
      tooltip: "Medium",
      url: mediumUsername && getSocialAccountUrl("medium", mediumUsername),
      icon: getSocialIcon({ name: "medium", ...iconProps }),
    },
    {
      tooltip: "Telegram",
      url: telegramUrl,
      icon: getSocialIcon({ name: "telegram", ...iconProps }),
    },
  ]

  const availableLinks = links.filter(link => link.url)
  if (!availableLinks.length) {
    return <>{emptyPlaceholder}</>
  }

  return (
    <SocialLinkList>
      {availableLinks.map(
        link =>
          link.url && (
            <SocialLink key={link.tooltip}>
              <Popover content={() => link.tooltip}>
                {/* TODO(i18n): translate */}
                <Link aria-label={`${link.tooltip}-link`} href={link.url}>
                  {link.icon}
                </Link>
              </Popover>
            </SocialLink>
          ),
      )}
    </SocialLinkList>
  )
}

const SocialLinkList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: -8px 0 0;
  padding: 0;
`

const SocialLink = styled.li`
  margin-right: 16px;
  margin-top: 8px;
`
