import React, { useCallback, useEffect, useMemo, useState } from "react"
import { keyBy, noop } from "lodash"
import { usePrevious } from "react-use"
import { createContext } from "use-context-selector"
import { filter } from "@/lib/helpers/array"
import { BigNumber } from "@/lib/helpers/numberUtils"
import { useGaslessCancelOrderToasts } from "../hooks/useGaslessCancelOrderToasts.react"
import { getCancellationId } from "../utils/getCancellationId"
import { getResultFromStatuses } from "../utils/getResultFromStatuses"
import { GaslessCancelResult } from "../utils/readGaslessCancelResult"
import { CancelOrderStatusDataPoller } from "./CancelOrderStatusDataPoller.react"

type Cancellation = GaslessCancelResult & {
  initialRemainingQuantity: BigNumber
  id: string
}

type CancelOrderContextType = {
  cancellations: Cancellation[]
  ordersAwaitingFinalization: Set<string>
  /** Add cancellation to context to begin polling for status of the cancellation */
  addCancellation: (
    cancelResult: GaslessCancelResult,
    initialRemainingQuantity: BigNumber,
  ) => void
  /** Update cancel context with results of polling for status of previously added cancellations */
  updateCancellations: (cancelResult: GaslessCancelResult) => void
}

const DEFAULT_CANCEL_ORDER_CONTEXT: CancelOrderContextType = {
  cancellations: [],
  ordersAwaitingFinalization: new Set<string>(),
  addCancellation: noop,
  updateCancellations: noop,
}

export const CancelOrderContext = createContext<CancelOrderContextType>(
  DEFAULT_CANCEL_ORDER_CONTEXT,
)

type CancelOrderContextProviderProps = {
  children: React.ReactNode
}

export const CancelOrderContextProvider = ({
  children,
}: CancelOrderContextProviderProps) => {
  const {
    showCancelOrderCompletedToast,
    showCancellationPartiallyFilledToast,
  } = useGaslessCancelOrderToasts()

  const [cancellations, setCancellations] = useState<Cancellation[]>([])

  const ordersAwaitingFinalization = useMemo(
    () =>
      new Set(
        cancellations.flatMap(cancellation =>
          Array.from(cancellation.ordersAwaitingFinalization),
        ),
      ),
    [cancellations],
  )

  const addCancellation = useCallback(
    (
      cancelResult: GaslessCancelResult,
      initialRemainingQuantity: BigNumber,
    ) => {
      setCancellations(prevCancellations => {
        return [
          ...prevCancellations,
          {
            ...cancelResult,
            initialRemainingQuantity,
            id: getCancellationId(cancelResult),
          },
        ]
      })
    },
    [],
  )

  const updateCancellations = useCallback(
    ({ statuses }: GaslessCancelResult) => {
      setCancellations(prevCancellations =>
        prevCancellations.map(cancellation => {
          // Update each cancellation's statuses with updated statuses if they exist
          const newStatuses = filter(
            Object.values(cancellation.statuses).map(status =>
              status ? statuses[status.orderRelayId] ?? status : undefined,
            ),
          )

          return {
            ...getResultFromStatuses(newStatuses),
            initialRemainingQuantity: cancellation.initialRemainingQuantity,
            id: cancellation.id,
          }
        }),
      )
    },
    [],
  )

  const prevCancellations = usePrevious(cancellations)
  const prevCancellationsById: Partial<Record<string, Cancellation>> = useMemo(
    () => keyBy(prevCancellations, v => v.id),
    [prevCancellations],
  )

  useEffect(() => {
    let hasCompletedCancellation = false

    for (const cancellation of cancellations) {
      // Show toasts for completed cancellations
      if (cancellation.ordersAwaitingFinalization.size === 0) {
        hasCompletedCancellation = true
        showCancelOrderCompletedToast({
          totalCount: Object.keys(cancellation.statuses).length,
          ordersSide: cancellation.ordersSide,
          failedCount: cancellation.failedCount,
          fulfilledCount: cancellation.fulfilledCount,
          canceledQuantity: cancellation.canceledQuantity,
          initialRemainingQuantity: cancellation.initialRemainingQuantity,
        })
      } else {
        // Show toast when any quantity of a still pending cancellation is fulfilled
        const prevCancellation = prevCancellationsById[cancellation.id]
        if (
          prevCancellation?.remainingQuantity.isGreaterThan(
            cancellation.remainingQuantity,
          )
        ) {
          showCancellationPartiallyFilledToast({
            fulfilledQuantity: prevCancellation.remainingQuantity.minus(
              cancellation.remainingQuantity,
            ),
          })
        }
      }
    }

    if (hasCompletedCancellation) {
      // Remove completed cancellations
      setCancellations(prevCancellation =>
        prevCancellation.filter(
          cancellation => cancellation.ordersAwaitingFinalization.size !== 0,
        ),
      )
    }
  }, [
    cancellations,
    prevCancellationsById,
    showCancelOrderCompletedToast,
    showCancellationPartiallyFilledToast,
  ])

  return (
    <CancelOrderContext.Provider
      value={{
        cancellations,
        ordersAwaitingFinalization,
        addCancellation,
        updateCancellations,
      }}
    >
      {children}
      <CancelOrderStatusDataPoller />
    </CancelOrderContext.Provider>
  )
}
