import { useTranslate } from "@/hooks/useTranslate"
import {
  RevertReason,
  ErrorStringReverts,
  InvalidTimeReverts,
} from "@/lib/graphql/__generated__/useGetTransactionRevertReasonQuery.graphql"
type ErrorString = RevertReason | ErrorStringReverts | InvalidTimeReverts

export const useRevertReasonErrorString = () => {
  const t = useTranslate("jsonRpcError")
  const TOKEN_IS_LOCKED = t(
    "tokenIsLocked",
    "NFT is locked and not available for transfer.",
  )
  const DEFAULT_MSG = t(
    "defaultMsg",
    "Execution reverted. Please reach out to the collection owner to troubleshoot.",
  )
  const ACCOUNT_NOT_OWNER = t(
    "accountNotMaker",
    "This account is not the owner of the NFT or has not approved the NFT for transfer.",
  )
  const INSUFFICIENT_BALANCE = t(
    "erc20InsufficientBalance",
    "Offerer doesn't have enough balance",
  )

  const INSUFFICIENT_ALLOWANCE = t(
    "erc20NotApproved",
    "Offerer has approved insufficient allowance of ERC20 token",
  )

  const RevertedReasonEnum: Partial<Record<ErrorString, string>> = {
    ORDER_ALREADY_FILLED: t(
      "orderAlreadyFilled",
      "This listing or offer has already been filled.",
    ),
    INVALID_SIGNER: DEFAULT_MSG,
    INEXACT_FRACTION: t(
      "inexactFraction",
      "Invalid transaction parameters. Please review the transaction again.",
    ),
    TRANSFER_FROM_INCORRECT_OWNER: t(
      "transferFromIncorrectOwner",
      "This account is not the owner of the NFT.",
    ),
    TOKEN_TRANSFER_GENERIC_FAILURE: DEFAULT_MSG,
    TRANSFER_CALLER_NOT_OWNER_NOR_APPROVED: ACCOUNT_NOT_OWNER,
    TOKEN_IS_LOCKED,
    GUARDIAN_ENABLED: TOKEN_IS_LOCKED,
    TOKEN_IS_SOUL_BOUND: TOKEN_IS_LOCKED,
    NOT_TRANSFERRABLE: TOKEN_IS_LOCKED,
    NOT_ALLOWED: TOKEN_IS_LOCKED,
    GATEKEEP: TOKEN_IS_LOCKED,
    NON_TRANSFERRABLE_TOKEN: TOKEN_IS_LOCKED,
    TRANSFER_DISABLED: TOKEN_IS_LOCKED,
    ADDRESS_FILTERED: TOKEN_IS_LOCKED,
    CANT_TRANFER_DURING_MINT: TOKEN_IS_LOCKED,
    ERC721_ERROR_STRING_1: ACCOUNT_NOT_OWNER,
    ERC721_ERROR_STRING_2: ACCOUNT_NOT_OWNER,
    ERC721_ERROR_STRING_3: ACCOUNT_NOT_OWNER,
    ERC721_ERROR_STRING_4: ACCOUNT_NOT_OWNER,
    ERC721Pausable_ERROR_STRING_1: TOKEN_IS_LOCKED,
    ERC1155_ERROR_STRING_2: ACCOUNT_NOT_OWNER,
    ERC1155_ERROR_STRING_3: ACCOUNT_NOT_OWNER,
    ERC1155_ERROR_STRING_4: ACCOUNT_NOT_OWNER,
    ERC1155Pausable_ERROR_STRING_1: t(
      "erc1155PausableString",
      "NFT is not available for transfer.",
    ),
    ERC20_ERROR_STRING_1: INSUFFICIENT_ALLOWANCE,
    ERC20_ERROR_STRING_2: INSUFFICIENT_BALANCE,
    ERC20_ERROR_STRING_3: INSUFFICIENT_BALANCE,
    ERC20_ERROR_STRING_4: INSUFFICIENT_ALLOWANCE,
    ERC721A_ERROR_STRING_1: ACCOUNT_NOT_OWNER,
    APE_MOVE_ERC721_ERROR_STRING_1: TOKEN_IS_LOCKED,
    STAR_NFT_ERROR_STRING: TOKEN_IS_LOCKED,
    PAUSABLE_PAUSED_ERROR_STRING: TOKEN_IS_LOCKED,
    HUNTER_IS_LOCKED_ERROR_STRING: TOKEN_IS_LOCKED,
    NFT_IS_STAKED_ERROR_STRING: t(
      "nftIsStaked",
      "NFT is staked, cannot be transfered.",
    ),
    DEFAULT_ERROR_STRING: DEFAULT_MSG,
    INVALID_TIME_NOT_STARTED: t(
      "invalidTimeNotStarted",
      "Offer or listing has not started",
    ),
    INVALID_TIME_EXPIRED: t(
      "invalidTimeExpired",
      "Offer or listing has expired",
    ),
    ORDER_CANNOT_BE_PARTIALLY_FILLED: t(
      "orderCannotBePartiallyFilled",
      "Order cannot be partially filled",
    ),
    ERC721_ERROR_STRING_5: TOKEN_IS_LOCKED,
    ERC721_ERROR_STRING_6: ACCOUNT_NOT_OWNER,
    CUSTOM_ERROR_STRING_2: TOKEN_IS_LOCKED,
  }

  const getErrorString = (value: ErrorString) => {
    return RevertedReasonEnum[value]
  }

  return { getErrorString }
}
