import React, { forwardRef, useState } from "react"
import { Spinner, FlexColumn, classNames } from "@opensea/ui-kit"
import styled from "styled-components"

type Props = {
  url: string
  className?: string
  id?: string
  title?: string
  allow?: string
  withSpinner?: boolean
  overrides?: {
    IFrameContainer?: React.ComponentType
  }
}

export const IFrame = forwardRef(function IFrame(
  { url, className, id, allow, title, overrides, withSpinner = true }: Props,
  ref: React.Ref<HTMLIFrameElement>,
) {
  const IFrameContainer = overrides?.IFrameContainer ?? StyledIFrameContainer
  const [isIframeLoaded, setIsIframeLoaded] = useState(false)

  return (
    <FlexColumn className={classNames("h-full w-full text-center", className)}>
      <IFrameContainer data-testid="IFrame--container">
        <StyledIFrame
          allow={allow}
          id={id}
          ref={ref}
          src={url}
          title={title}
          onLoad={() => setIsIframeLoaded(true)}
        />

        {isIframeLoaded || !withSpinner ? null : (
          <LoaderContainer>
            <Spinner size="medium" />
          </LoaderContainer>
        )}
      </IFrameContainer>
    </FlexColumn>
  )
})

export const StyledIFrameContainer = styled.div`
  position: relative;
  border-radius: ${props => props.theme.borderRadius.default};
  border: 1px solid ${props => props.theme.colors.components.border.level2};
  height: 100%;
`

const StyledIFrame = styled.iframe`
  border: 0;
  border-radius: ${props => props.theme.borderRadius.default};
  margin: 0 auto;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
