import clsx from "clsx"

export const selectClassNames = (
  namespace: string,
  selection: {
    [name: string]: boolean | undefined
  },
  className?: string,
): string =>
  clsx(
    Object.keys(selection)
      .filter(name => selection[name])
      .map(name => `${namespace}--${name}`)
      .join(" "),
    className,
  )

export const aspectRatioClasses = (aspectRatio: string | undefined) => {
  // Normalize ratio string to use "/" as separator
  const ratio = (aspectRatio ?? "16/9").replace(":", "/")

  // We don't want to dynamically generate classes for every possible ratio, so we'll just use this cheeky little switch statement
  switch (ratio) {
    case "1/1":
      return "aspect-square"
    case "16/9":
      return "aspect-video"
    case "9/16":
      return "aspect-w-9 aspect-h-16"
    case "4/3":
      return "aspect-w-4 aspect h-3"
    case "3/4":
      return "aspect-w-3 aspect-h-4"
    case "5/4":
      return "aspect-w-5 aspect-h-4"
    case "4/5":
      return "aspect-w-4 aspect-h-5"
    case "8/3":
      return "aspect-w-8 aspect-h-3"
    default:
      return "aspect-video"
  }
}
