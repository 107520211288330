import Cookies from "js-cookie"
import { minBy } from "lodash"
import type { GetServerSidePropsContext, NextPageContext } from "next"
import { v4 as uuidv4 } from "uuid"
import { IS_SERVER } from "../../constants/environment"
import Cookie from "../cookie"
import {
  getSessionStorageKey,
  getWalletAddressStorageKey,
  SESSION_STORAGE_KEY_PREFIX,
  WALLET_ADDRESS_STORAGE_KEY_POSTFIX,
} from "./constants"
import { decodeJwtToken } from "./jwt"
import type { Session } from "./types"

export const getOrSetDeviceId = (context?: NextPageContext): string => {
  const cookie = new Cookie<string>("device_id")
  const deviceId = cookie.get(context)
  if (deviceId) {
    return deviceId
  }
  const newDeviceId = uuidv4()
  cookie.set(newDeviceId, {
    secure: true,
    sameSite: "Strict",
    // use max expire time supported as this should be as long lived as possible
    expires: 400,
  })
  return newDeviceId
}

const getJwtCookie = (address: string) => {
  return new Cookie<string | undefined>(getSessionStorageKey(address))
}

export const setSession = (address: string, session: Session): void => {
  if (IS_SERVER) {
    return undefined
  }
  return setToken(address, session.token)
}

export const setToken = (address: string, token: string) => {
  const cookie = getJwtCookie(address)
  cookie.set(token, {
    secure: process.env.NODE_ENV === "production",
    sameSite: "Strict",
    expires: 1,
  })
}

/* Clear session associated with an account key */
export const removeSession = (address: string) => {
  if (IS_SERVER) {
    return
  }
  const cookie = getJwtCookie(address)
  cookie.remove()
}

/* Clear all sessions */
export const removeAllSessions = () => {
  if (IS_SERVER) {
    return
  }

  Object.keys(Cookies.get()).forEach(key => {
    if (key.startsWith(SESSION_STORAGE_KEY_PREFIX)) {
      Cookies.remove(key)
    }
  })
}

/* Get session associated with an account key */
export const getSession = (
  address: string,
  context?: NextPageContext | GetServerSidePropsContext,
): Session | undefined => {
  const cookie = getJwtCookie(address)
  const token = cookie.get(context)
  if (!token) {
    return undefined
  }
  return getSessionFromToken(token)
}

export const getWalletAddressCookie = (address: string) => {
  return new Cookie<string | undefined>(getWalletAddressStorageKey(address))
}

const getSessionFromToken = (token: string): Session => {
  return { token, payload: decodeJwtToken(token) }
}

interface JWTExpirationData {
  jwt_expiration?: string
}

export const isJWTExpirationData = (
  data: unknown,
): data is JWTExpirationData => {
  return (
    typeof data === "object" &&
    data !== null &&
    "jwt_expiration" in data &&
    typeof (data as JWTExpirationData).jwt_expiration === "string"
  )
}

export const getWalletAddressCookies = (): string[] => {
  const allCookies = Cookies.get()
  const walletAddressCookies = Object.keys(allCookies).filter(cookieName =>
    cookieName.endsWith(WALLET_ADDRESS_STORAGE_KEY_POSTFIX),
  )
  return walletAddressCookies
}

export const getAddressFromCookieName = (cookieName: string): string => {
  return cookieName.replace(WALLET_ADDRESS_STORAGE_KEY_POSTFIX, "")
}

export const getOldestWalletAddress = (): string | undefined => {
  const walletAddressCookies = getWalletAddressCookies()
  const walletAddressCookiesSortedByExpiration = walletAddressCookies.map(
    cookieName => {
      const cookie = new Cookie<string | undefined>(cookieName)
      const data = cookie.get()
      if (!data || !isJWTExpirationData(data)) {
        return {}
      }
      return {
        address: getAddressFromCookieName(cookieName),
        expiration: data.jwt_expiration,
      }
    },
  )

  const walletAddressCookieToLogout = minBy(
    walletAddressCookiesSortedByExpiration,
    "expiration",
  )

  return walletAddressCookieToLogout?.address
}
