import { noop } from "lodash"
import { FragmentRef } from "relay-runtime"
import { createContext } from "use-context-selector"
import { Chain, ChainIdentifier } from "@/hooks/useChains/types"
import { LoggedInAccount } from "@/lib/auth"
import Provider from "@/lib/chain/provider"
import Wallet, { WalletAccount } from "@/lib/chain/wallet"
import { wallet_accountKey$data } from "@/lib/graphql/__generated__/wallet_accountKey.graphql"
import { asyncNoop } from "@/lib/helpers/functions"

type WalletContext = {
  chain: ChainIdentifier | null | undefined
  provider: Provider | undefined
  providers: Provider[]
  switchChain: (chain: Chain) => Promise<void>
  switchProvider: (provider: Provider) => Promise<void>
  showIncompatibleNetworkBanner: boolean
  hideIncompatibleNetworkBanner: () => unknown
  login: () => Promise<LoggedInAccount | undefined>
  os2Login: () => Promise<void>
  logout: () => Promise<void>
  isAuthenticated: boolean
  activeAccount: WalletAccount | undefined
  setActiveAccount: React.Dispatch<
    React.SetStateAction<WalletAccount | undefined>
  >
  isActiveAccount: (
    account: FragmentRef<wallet_accountKey$data> | undefined | null,
  ) => boolean
  wallet: Wallet
  setPrivyAccessToken: (accessToken: string) => void
}

export const DEFAULT_CONTEXT: WalletContext = {
  chain: undefined,
  provider: undefined,
  providers: [],
  switchChain: asyncNoop,
  switchProvider: asyncNoop,
  showIncompatibleNetworkBanner: false,
  hideIncompatibleNetworkBanner: noop,
  login: async () => undefined,
  os2Login: async () => undefined,
  logout: async () => undefined,
  isAuthenticated: false,
  activeAccount: undefined,
  setActiveAccount: () => undefined,
  isActiveAccount: () => false,
  wallet: new Wallet(undefined),
  setPrivyAccessToken: () => undefined,
}

export const WalletContext = createContext<WalletContext>(DEFAULT_CONTEXT)
