/**
 * @generated SignedSource<<17a6e081a762f525b22650ed16beea10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type collection_live_listing_stats$data = {
  readonly statsV2: {
    readonly floorPrice: {
      readonly eth: string;
      readonly symbol: string;
      readonly unit: string;
      readonly usd: string;
    } | null;
  };
  readonly " $fragmentType": "collection_live_listing_stats";
};
export type collection_live_listing_stats$key = {
  readonly " $data"?: collection_live_listing_stats$data;
  readonly " $fragmentSpreads": FragmentRefs<"collection_live_listing_stats">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "collection_live_listing_stats"
};

(node as any).hash = "55f9c4e8b5604a7e2f802c00bcc30197";

export default node;
