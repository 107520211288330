import React from "react"

type Props = {
  className?: string
  width?: number
  fill?: string
}

export const BinanceLogo = ({
  className,
  fill = "gray",
  width = 20,
}: Props) => (
  <svg
    className={className}
    fill="none"
    style={{ width, height: width }}
    viewBox="0 0 21 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 3.68L10.37 0L16.74 3.68L14.4 5.04L10.37 2.72L6.34 5.04L4 3.68V3.68ZM16.74 8.32L14.4 6.96L10.37 9.28L6.34 6.96L4 8.32V11.04L8.03 13.36V18L10.37 19.36L12.71 18V13.36L16.74 11.04V8.32V8.32ZM16.74 15.67V12.95L14.4 14.31V17.03L16.74 15.67V15.67ZM18.4 16.63L14.37 18.95V21.67L20.74 17.99V10.64L18.4 12V16.64V16.63ZM16.06 6L18.4 7.36V10.08L20.74 8.72V6L18.4 4.64L16.06 6ZM8.03 19.93V22.65L10.37 24.01L12.71 22.65V19.93L10.37 21.29L8.03 19.93ZM4 15.67L6.34 17.03V14.31L4 12.95V15.67V15.67ZM8.03 5.99L10.37 7.35L12.71 5.99L10.37 4.63L8.03 5.99ZM2.34 7.35L4.68 5.99L2.34 4.63L0 5.99V8.71L2.34 10.07V7.35V7.35ZM2.34 11.99L0 10.63V17.98L6.37 21.66V18.94L2.34 16.62V11.98V11.99Z"
      fill={fill}
    />
  </svg>
)
