import React, { useState } from "react"
import {
  Icon,
  Text,
  RadioGroup,
  Label,
  Flex,
  classNames,
} from "@opensea/ui-kit"
import { useUpdateEffect } from "react-use"
import { EthereumLogo } from "@/components/svgs/ChainLogos/EthereumLogo.react"
import { Block } from "@/design-system/Block"
import { useTheme } from "@/design-system/Context"
import { Tooltip } from "@/design-system/Tooltip"
import { useTranslate } from "@/hooks/useTranslate"
import THEMES from "@/styles/themes"

export type PaymentOption = "crypto" | "card" | null | undefined

export type PaymentMethodProps = {
  onChange?: (paymentOption: PaymentOption) => void
  paymentMethodProps: {
    enabled: boolean
    message: string | undefined
  }
  withTitle?: boolean
  defaultValue?: PaymentOption
}

export const PaymentMethod = ({
  onChange,
  paymentMethodProps: { enabled, message },
  withTitle = true,
  defaultValue = "crypto",
}: PaymentMethodProps) => {
  const t = useTranslate("components")
  const [paymentOption, setPaymentOption] = useState<PaymentOption>()
  const { theme } = useTheme()

  useUpdateEffect(() => {
    onChange?.(paymentOption)
  }, [paymentOption])

  return (
    <>
      {withTitle && (
        <Block marginBottom="20px">
          <Text.Body weight="semibold">
            {t("trade.paymentMethod.title", "Payment Method")}
          </Text.Body>
        </Block>
      )}

      <RadioGroup
        defaultValue={defaultValue ?? undefined}
        onValueChange={v => setPaymentOption(v as PaymentOption)}
      >
        <Flex>
          <RadioGroup.Item id="crypto" value="crypto" />
          <Label htmlFor="crypto">
            <Flex className="items-center">
              <EthereumLogo
                fill={THEMES[theme].colors.text.primary}
                height={24}
                width={24}
              />
              <Text.Body className="ml-2" size="small">
                {t("trade.paymentMethod.crypto.label", "Crypto")}
              </Text.Body>
            </Flex>
          </Label>
        </Flex>
        <Tooltip content={message}>
          <Flex>
            <RadioGroup.Item disabled={!enabled} id="card" value="card" />
            <Label htmlFor="card">
              <Flex
                className={classNames(
                  "items-center",
                  enabled ? "opacity-100" : "opacity-80",
                )}
              >
                <Icon value="credit_card" />
                <Text.Body className="ml-2" size="small">
                  {t("trade.paymentMethod.card.label", "Credit or debit card")}
                </Text.Body>
              </Flex>
            </Label>
          </Flex>
        </Tooltip>
      </RadioGroup>
    </>
  )
}
