import { useCallback } from "react"
import { graphql } from "react-relay"
import {
  useUpdateTransactionStatusToPendingMutation,
  useUpdateTransactionStatusToPendingMutation$variables,
} from "@/lib/graphql/__generated__/useUpdateTransactionStatusToPendingMutation.graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"

export const useUpdateTransactionStatusToPending = () => {
  const { mutate } = useGraphQL()

  const updateTransactionStatusToPending = useCallback(
    async (
      variables: useUpdateTransactionStatusToPendingMutation$variables,
    ) => {
      await mutate<useUpdateTransactionStatusToPendingMutation>(
        graphql`
          mutation useUpdateTransactionStatusToPendingMutation(
            $userTransactionId: UserTransactionRelayID!
            $transactionHash: String!
            $gasLimit: BigIntScalar!
            $maxPriorityFeePerGas: BigIntScalar!
          ) {
            userTransaction {
              updateToPending(
                userTransactionId: $userTransactionId
                transactionHash: $transactionHash
                gasLimit: $gasLimit
                maxPriorityFeePerGas: $maxPriorityFeePerGas
              ) {
                relayId
                state
              }
            }
          }
        `,
        variables,
      )
    },
    [mutate],
  )

  return updateTransactionStatusToPending
}
