import React, { useCallback, useState } from "react"
import { Text, Alert, Flex } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { TokenPrice } from "@/components/assets/price/TokenPrice.react"
import { StackedAssetMedia } from "@/components/assets/StackedAssetMedia"
import { Item } from "@/design-system/Item"
import { CollectionUnreviewedPill } from "@/features/collections/components/CollectionUnreviewedPill.react"
import { useTotalItems } from "@/features/shopping-cart/hooks/useTotalItems"
import { useTotalPriceOrderDataToFill } from "@/features/shopping-cart/hooks/useTotalPriceOrderDataToFill"
import { ItemSideOverflowStacked } from "@/features/shopping-cart/styles"
import { readOrderDataToFillPrices } from "@/features/shopping-cart/utils/readOrderDataToFillPrice"
import { useChains } from "@/hooks/useChains"
import { useTranslate } from "@/hooks/useTranslate"
import {
  useTrackBlockchainAttempt,
  useTrackBlockchainError,
} from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/hooks"
import { BulkFulfillOrdersAction_data$key } from "@/lib/graphql/__generated__/BulkFulfillOrdersAction_data.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { bn } from "@/lib/helpers/numberUtils"
import { EN_DASH } from "@/lib/helpers/stringUtils"
import { isSafelisted } from "@/lib/helpers/verification"
import { useTransaction } from "../useTransaction"
import {
  BaseBlockchainActionProps,
  BlockchainActionModalContent,
} from "./BlockchainActionModalContent.react"
import { useBlockchainActionProgress } from "./useBlockchainActionProgress"
import {
  Transaction,
  useHandleBlockchainActions,
} from "./useHandleBlockchainActions"

type Props = BaseBlockchainActionProps & {
  dataKey: BulkFulfillOrdersAction_data$key
}

export const BulkFulfillOrdersAction = ({ dataKey, onEnd }: Props) => {
  const t = useTranslate("bulkPurchase")
  const [actionIndex, setActionIndex] = useState(0)
  const [transactions, setTransactions] = useState<Transaction[]>([])
  const { getChainName } = useChains()

  const { pollTransaction } = useTransaction()

  const actions = useFragment(
    graphql`
      fragment BulkFulfillOrdersAction_data on BulkFulfillOrdersActionType
      @relay(plural: true) {
        __typename

        maxOrdersToFill
        ordersToFill {
          itemFillAmount
          orderData {
            chain {
              identifier
            }
            item {
              ... on AssetQuantityDataType {
                asset {
                  collection {
                    verificationStatus
                    slug
                  }
                  ...StackedAssetMedia_assets
                }
              }
              ... on AssetBundleType {
                collection {
                  verificationStatus
                  slug
                }
                assetQuantities(first: 30) {
                  edges {
                    node {
                      asset {
                        ...StackedAssetMedia_assets
                      }
                    }
                  }
                }
              }
            }
            ...useTotalItems_ordersData
          }
          ...useTotalPriceOrderDataToFill_ordersToFill
          ...readOrderDataToFillPrices_orderDataToFill
        }
        ...useHandleBlockchainActions_bulk_fulfill_orders
      }
    `,
    dataKey,
  )

  const action = actions[actionIndex]

  const isSubstitutionEnabled =
    action.maxOrdersToFill !== null &&
    Number(action.maxOrdersToFill) !== action.ordersToFill.length

  const maxOrdersToFill = isSubstitutionEnabled
    ? Number(action.maxOrdersToFill)
    : action.ordersToFill.length

  // Sorted by usd price low to high
  const sortedOrdersToFill = action.ordersToFill.slice().sort((a, b) => {
    const bUsdPrice = readOrderDataToFillPrices(b).usdPrice
    const aUsdPrice = readOrderDataToFillPrices(a).usdPrice
    return aUsdPrice.comparedTo(bUsdPrice)
  })

  const minPriceOrdersToFill = sortedOrdersToFill.slice(0, maxOrdersToFill)
  const maxPriceOrdersToFill = sortedOrdersToFill.slice(-maxOrdersToFill)

  const { totalPricePerSymbol, totalUsdPrice } =
    useTotalPriceOrderDataToFill(minPriceOrdersToFill)

  const {
    totalPricePerSymbol: maxTotalPricePerSymbol,
    totalUsdPrice: maxTotalUsdPrice,
  } = useTotalPriceOrderDataToFill(maxPriceOrdersToFill)

  const orderData = action.ordersToFill.map(order => order.orderData)
  const totalItems = useTotalItems({
    ordersDataDataKey: orderData,
  })

  const totalQuantity = action.ordersToFill.reduce(
    (acc, { itemFillAmount }) => acc.plus(itemFillAmount),
    bn(0),
  )

  const showQuantity =
    !totalQuantity.isEqualTo(totalItems.length) && // Total quantity differs from number of items
    !isSubstitutionEnabled // Substitution will always be for maxOrdersToFill items (total quantity includes substitutions and is not relevant)

  const itemCount = action.maxOrdersToFill
    ? Number(action.maxOrdersToFill)
    : totalItems.length

  const { bulkFulfillOrders } = useHandleBlockchainActions()
  const trackBlockchainAttempt = useTrackBlockchainAttempt()
  const [trackBlockchainError, errorTags] = useTrackBlockchainError()

  const executeAction = useCallback(async () => {
    trackBlockchainAttempt({ blockchainAction: "coreAction" })
    const transaction = await bulkFulfillOrders(action)

    const allTransactions = [...transactions, transaction]
    setTransactions(allTransactions)

    if (actionIndex === actions.length - 1) {
      await pollTransaction(transaction)
      onEnd({ transactions: allTransactions })
    } else {
      setActionIndex(index => index + 1)
    }
  }, [
    action,
    actions,
    actionIndex,
    bulkFulfillOrders,
    pollTransaction,
    onEnd,
    transactions,
    trackBlockchainAttempt,
  ])

  const { attemptAction, progress } = useBlockchainActionProgress({
    executeAction,
    action,
    onError: trackBlockchainError({ blockchainAction: "coreAction" }),
    errorTags,
  })

  const title = t("blockchain.approve.title", "Approve purchase")

  const actionText = t(
    "blockchain.approve.purchase",
    "You'll be asked to approve this purchase from your wallet.",
  )

  const chain = action.ordersToFill[0].orderData.chain.identifier
  const collection =
    action.ordersToFill[0].orderData.item?.asset?.collection ||
    action.ordersToFill[0].orderData.item?.collection

  const showUnverifiedPill = !isSafelisted(collection?.verificationStatus)

  return (
    <BlockchainActionModalContent>
      <BlockchainActionModalContent.Header>
        <BlockchainActionModalContent.Title>
          {title}
        </BlockchainActionModalContent.Title>
      </BlockchainActionModalContent.Header>
      <BlockchainActionModalContent.Body>
        <Item variant="unstyled">
          <Item.Avatar size={84}>
            <StackedAssetMedia
              assets={action.ordersToFill.flatMap(({ orderData: { item } }) =>
                item?.assetQuantities
                  ? getNodes(item.assetQuantities).map(({ asset }) => asset)
                  : item?.asset
                  ? item.asset
                  : [],
              )}
              isLoading
              variant="small"
            />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>
              <Text.Body>
                {t(
                  "itemCount",
                  {
                    "0": "{{count}} items",
                    one: "{{count}} item",
                    other: "{{count}} items",
                  },
                  {
                    count: itemCount,
                  },
                )}
              </Text.Body>
            </Item.Title>
            {showQuantity && (
              <Item.Description>
                {t("quantity", "Quantity: {{count}}", {
                  count: totalQuantity.toString(),
                })}
              </Item.Description>
            )}
            <Item.Description>
              {t("chainName", "Chain: {{chainDisplayName}}", {
                chainDisplayName: getChainName(chain),
              })}
            </Item.Description>
            {showUnverifiedPill && collection?.slug && (
              <CollectionUnreviewedPill collectionSlug={collection?.slug} />
            )}
          </Item.Content>
          <Item.Side>
            <Item.Title>
              <ItemSideOverflowStacked>
                <Text.Body weight="semibold">
                  {Object.keys(totalPricePerSymbol).map(symbol => {
                    const price = totalPricePerSymbol[symbol]
                    const maxPrice = maxTotalPricePerSymbol[symbol]
                    const hasPriceRange =
                      maxPrice &&
                      price &&
                      !price.price.isEqualTo(maxPrice.price)
                    return (
                      price && (
                        <Flex key={symbol}>
                          <TokenPrice
                            {...price.tokenPricePayment}
                            fontWeight={600}
                            price={price.price}
                            symbol={
                              maxPrice ? "" : price.tokenPricePayment.symbol
                            }
                            symbolVariant="raw"
                          />
                          {isSubstitutionEnabled && hasPriceRange && (
                            <>
                              {EN_DASH}
                              <TokenPrice
                                {...maxPrice.tokenPricePayment}
                                fontWeight={600}
                                price={maxPrice.price}
                                symbolVariant="raw"
                              />
                            </>
                          )}
                        </Flex>
                      )
                    )
                  })}
                </Text.Body>
              </ItemSideOverflowStacked>
            </Item.Title>
            <Item.Description>
              {totalUsdPrice}
              {isSubstitutionEnabled && totalUsdPrice !== maxTotalUsdPrice && (
                <>
                  {EN_DASH}
                  {maxTotalUsdPrice}
                </>
              )}
            </Item.Description>
          </Item.Side>
        </Item>

        <BlockchainActionModalContent.Body.Separator />

        {actions.length > 1 && (
          <Alert className="mb-4">
            {t(
              "blockchain.fulfill.numTransactions",
              "Due to a marketplace contract upgrade, you will have to submit {{numTransactions}} transactions to fulfill all your purchases.",
              { numTransactions: actions.length },
            )}
          </Alert>
        )}

        <BlockchainActionModalContent.Body.GoToWallet />
        <BlockchainActionModalContent.Body.Text>
          {actionText}
        </BlockchainActionModalContent.Body.Text>
        {!progress && (
          <BlockchainActionModalContent.Body.Button onClick={attemptAction}>
            {t("blockchain.fulfill.cta", "Continue")}
          </BlockchainActionModalContent.Body.Button>
        )}
      </BlockchainActionModalContent.Body>
    </BlockchainActionModalContent>
  )
}
