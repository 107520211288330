import React from "react"

type Props = {
  className?: string
  width?: number
  height?: number
  fill?: string
}

export const AvalancheLogo = ({
  className,
  fill = "#707A83",
  width = 20,
  height = 20,
}: Props) => (
  <svg
    className={className}
    fill={fill}
    style={{ width, height }}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4817 14.091C16.9238 13.3477 17.6301 13.3477 18.0722 14.091L20.811 18.7837C21.2531 19.527 20.8923 20.1316 20.0132 20.1316H14.4898C13.6209 20.1316 13.2602 19.527 13.6921 18.7837L16.4817 14.091ZM11.1819 5.05747C11.624 4.31418 12.3201 4.31418 12.7622 5.05747L13.372 6.13278L14.81 8.60053C15.1606 9.30419 15.1606 10.1317 14.81 10.8354L9.97763 19.0018C9.53556 19.6658 8.80893 20.087 7.99592 20.1316H3.98675C3.10769 20.1316 2.74691 19.5369 3.18899 18.7837L11.1819 5.05747Z"
      fill={fill}
    />
  </svg>
)
