/**
 * @generated SignedSource<<22a8749bddb9476d225eff48664ba842>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type usePaymentMethod_moonpay$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useIsAvailableForBuyWithCard_data">;
  readonly " $fragmentType": "usePaymentMethod_moonpay";
};
export type usePaymentMethod_moonpay$key = {
  readonly " $data"?: usePaymentMethod_moonpay$data;
  readonly " $fragmentSpreads": FragmentRefs<"usePaymentMethod_moonpay">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "listingId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "usePaymentMethod_moonpay",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "listingId",
          "variableName": "listingId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useIsAvailableForBuyWithCard_data"
    }
  ],
  "type": "MoonpayType",
  "abstractKey": null
};

(node as any).hash = "9fc23f77e75da10d158628641a01e5d6";

export default node;
