import { createHash } from "crypto"
import * as Sentry from "@sentry/nextjs"
import { IS_SERVER } from "../../constants/environment"
import { identifyAmplitudeUser } from "./amplitude"

type UserTraits = {
  address: string
  email?: string
  username?: string
  publicUsername?: string
  id?: string
  internalUserId?: string
  moonpayKycStatus?: string
  moonpayKycRejectType?: string
}

export async function trackUser(traitsArgs: UserTraits | null) {
  if (!traitsArgs) {
    Sentry.setUser(null)
    return
  }

  const traits = {
    ...traitsArgs,
    userId: traitsArgs.address,
    hashedUserId: createHash("sha256").update(traitsArgs.address).digest("hex"),
  }

  Sentry.setUser(traits)

  if (!IS_SERVER) {
    identifyAmplitudeUser(traits.address, traits)
  }
}
