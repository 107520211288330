import { useCallback, useMemo } from "react"
import { useTrackingFn } from "@/lib/analytics/hooks/useTrackingFn"
import { useGetCoreMarketplaceAction } from "./useGetCoreMarketplaceAction"

type Properties = {
  errorCode?: number
}

export const useTrackServerError = () => {
  const trackingFn = useTrackingFn<Properties>(
    "core marketplace action server error",
  )

  const coreMarketplaceAction = useGetCoreMarketplaceAction()

  const track = useCallback(
    (properties: Properties) => trackingFn(properties),
    [trackingFn],
  )

  const errorTags = useMemo(
    () => (coreMarketplaceAction ? { coreMarketplaceAction } : undefined),
    [coreMarketplaceAction],
  )

  return [track, errorTags] as const
}
