import {
  MoonpayFlow,
  fetchMoonPayUrlSignatureQuery,
} from "@/lib/graphql/__generated__/fetchMoonPayUrlSignatureQuery.graphql"
import { fetch, graphql } from "@/lib/graphql/graphql"

export const fetchMoonPayUrlSignature = async (
  url: string,
  flow: MoonpayFlow,
) => {
  const [
    {
      moonpay: { urlSignature },
    },
    disposable,
  ] = await fetch<fetchMoonPayUrlSignatureQuery>(
    graphql`
      query fetchMoonPayUrlSignatureQuery($url: String!, $flow: MoonpayFlow!) {
        moonpay {
          urlSignature(url: $url, flow: $flow)
        }
      }
    `,
    { url, flow },
  )
  disposable?.dispose()
  return urlSignature
}
