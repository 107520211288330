import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { useTotalItems } from "@/features/shopping-cart/hooks/useTotalItems"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import { useFulfillingListingsWillReactivateOrders_orders$key } from "@/lib/graphql/__generated__/useFulfillingListingsWillReactivateOrders_orders.graphql"
import { useFulfillingListingsWillReactivateOrdersQuery } from "@/lib/graphql/__generated__/useFulfillingListingsWillReactivateOrdersQuery.graphql"

type UseFulfillingListingsWillReactivateOrdersOptions = {
  listingsDataKey: useFulfillingListingsWillReactivateOrders_orders$key | null
  skip?: boolean
}

export const useFulfillingListingsWillReactivateOrders = ({
  listingsDataKey,
  skip,
}: UseFulfillingListingsWillReactivateOrdersOptions) => {
  const connectedAddress = useConnectedAddress()

  const listings = useFragment(
    graphql`
      fragment useFulfillingListingsWillReactivateOrders_orders on OrderV2Type
      @relay(plural: true) {
        ...useTotalItems_orders
      }
    `,
    listingsDataKey,
  )

  const assets = useTotalItems({ ordersDataKey: listings })

  const [inactiveListings] =
    useNoSuspenseLazyLoadQuery<useFulfillingListingsWillReactivateOrdersQuery>(
      graphql`
        query useFulfillingListingsWillReactivateOrdersQuery(
          $assets: [AssetRelayID!]!
          $toAddress: AddressScalar!
        ) {
          assetsTransferability(assets: $assets, toAddress: $toAddress) {
            assetsWithReactivatableOrders {
              relayId
            }
          }
        }
      `,
      { assets, toAddress: connectedAddress ?? "" },
      {
        skip: skip || !connectedAddress || listings?.length === 0,
      },
    )

  const assetsWithReactivatableOrders =
    inactiveListings?.assetsTransferability.assetsWithReactivatableOrders.map(
      a => a.relayId,
    ) ?? []

  return {
    fulfillingWillReactivateOrders: Boolean(
      assetsWithReactivatableOrders.length,
    ),
    assetsWithReactivatableOrders,
    numItems: assets.length,
  }
}
