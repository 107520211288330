import { useCallback } from "react"
import { graphql } from "react-relay"
import {
  useUpdateTransactionStatusToRejectedMutation,
  useUpdateTransactionStatusToRejectedMutation$variables,
} from "@/lib/graphql/__generated__/useUpdateTransactionStatusToRejectedMutation.graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"

export const useUpdateTransactionStatusToRejected = () => {
  const { mutate } = useGraphQL()

  const updateTransactionStatusToRejected = useCallback(
    async (
      variables: useUpdateTransactionStatusToRejectedMutation$variables,
    ) => {
      await mutate<useUpdateTransactionStatusToRejectedMutation>(
        graphql`
          mutation useUpdateTransactionStatusToRejectedMutation(
            $userTransactionId: UserTransactionRelayID!
          ) {
            userTransaction {
              updateStatus(
                state: REJECTED
                userTransactionId: $userTransactionId
              ) {
                relayId
                state
              }
            }
          }
        `,
        variables,
      )
    },
    [mutate],
  )

  return updateTransactionStatusToRejected
}
