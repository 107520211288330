import { ServerResponse } from "http"
import { ErrorInfo } from "react"
import * as Sentry from "@sentry/nextjs"
import type { NextPageContext } from "next"
import { RRNLRequestError } from "react-relay-network-modern"
import { MAX_VALUE_LENGTH } from "../../sentry.common.config"

type RelayResponse = NonNullable<RRNLRequestError["res"]>

type Response = RelayResponse | ServerResponse

type ExceptionContextResponse = Response | unknown

type CaptureExceptionContext =
  | NextPageContext
  | RRNLRequestError
  | (ErrorInfo & { res?: Response })

export type CapturedError = Error & { statusCode?: number }

export type CaptureExceptionArgs = {
  level: Sentry.SeverityLevel
  ctx?: CaptureExceptionContext | undefined
  extras?: Record<string, unknown>
  tags?: Record<string, string>
}

const isServerResponse = (
  res: ExceptionContextResponse,
): res is ServerResponse => {
  const maybeServerResponse = res as ServerResponse
  return (
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    maybeServerResponse.statusCode !== undefined &&
    typeof maybeServerResponse.getHeader === "function"
  )
}

const isRelayResponse = (
  res: ExceptionContextResponse,
): res is RelayResponse => {
  const maybeRelayResponse = res as RelayResponse
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return maybeRelayResponse.status !== undefined
}

const getTraceId = (res: ExceptionContextResponse) => {
  if (isServerResponse(res)) {
    return res.getHeader("x-trace-id")
  } else if (isRelayResponse(res)) {
    return res.headers?.["x-trace-id"]
  }
  return undefined
}

export const captureException = (
  error: CapturedError,
  { level = "error", ctx = undefined, extras, tags }: CaptureExceptionArgs,
) => {
  Sentry.withScope(scope => {
    scope.setLevel(level)
    scope.setFingerprint([error.name, error.message, level])

    if (error.statusCode) {
      scope.setExtra("statusCode", error.statusCode)
    }

    if (ctx) {
      const { res, ...errorInfo } = ctx

      if (res) {
        const traceId = getTraceId(res)
        if (traceId) {
          scope.setExtra("x-trace-id", traceId)
          scope.setExtra(
            "x-trace-link",
            `https://app.datadoghq.com/apm/traces?traceID=${traceId}`,
          )
        }

        if (isServerResponse(res)) {
          scope.setExtra("statusCode", res.statusCode)
        } else {
          scope.setExtra("statusCode", res.status)
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (errorInfo) {
        // Sets query, pathname, url,
        // method, headers, params,
        // and anything else
        Object.keys(errorInfo).forEach(key =>
          scope.setExtra(key, errorInfo[key as keyof typeof errorInfo]),
        )
      }
    }

    if (extras) {
      scope.setExtras(extras)
    }

    if (tags) {
      for (const tag in tags) {
        scope.setTag(tag, tags[tag])
      }
    }

    // This is the truncation limit for Sentry, but if you send in a message that obscenely exceeds this limit
    // Sentry crashes the app. So we truncate it here to be safe.
    try {
      error.message = error.message.slice(0, MAX_VALUE_LENGTH)
    } catch {
      // Cannot set property message of which has only a getter
    }

    return Sentry.captureException(error)
  })
}
