import { ChainIdentifier } from "@/hooks/useChains/types"
import { useTranslate } from "@/hooks/useTranslate"
import { isPolygon } from "@/lib/helpers/chainUtils"
import { BridgeOrWrapDirection } from "../BridgeOrWrapForm.react"

export const useActionTitle = (
  direction: BridgeOrWrapDirection,
  chain: ChainIdentifier | null | undefined,
): string => {
  const t = useTranslate("components")

  if (!chain) {
    return direction === "out"
      ? t("bridgeOrWrapForm.addToOfferBalance", "Add to offer balance")
      : t(
          "bridgeOrWrapForm.withdrawFromOfferBalance",
          "Withdraw from offer balance",
        )
  }

  if (isPolygon(chain)) {
    return t("bridgeOrWrapForm.bridge", "Bridge")
  }

  return direction === "out"
    ? t("bridgeOrWrapForm.addToOfferBalance", "Add to offer balance")
    : t(
        "bridgeOrWrapForm.withdrawFromOfferBalance",
        "Withdraw from offer balance",
      )
}
