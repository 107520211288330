/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useState } from "react"
import { Icon, Spinner, classNames, inputVariants } from "@opensea/ui-kit"
import clsx from "clsx"
import styled, { css } from "styled-components"
import { FrameConsumer, FrameProvider } from "@/components/layout/Frame.react"
import { useTranslate } from "@/hooks/useTranslate"
import { media, themeVariant } from "@/styles/styleUtils"

export type InputStatus = "standby" | "wait" | "valid" | "invalid"

export type InputProps = {
  containerClassName?: string
  errorInfo?: string
  isRequired?: boolean
  inputClassName?: string
  onChange: (value: string) => unknown
  onSubmit?: (value: string) => unknown
  prefix?: string
  right?: React.ReactNode
  status?: InputStatus
  value: string
  valueInfo?: string
  ariaLabel?: string
} & Pick<
  JSX.IntrinsicElements["input"],
  | "children"
  | "className"
  | "disabled"
  | "inputMode"
  | "placeholder"
  | "type"
  | "name"
  | "min"
  | "max"
  | "id"
  | "autoFocus"
  | "aria-label"
  | "onBlur"
>

// DEPRECATED: use design-sytem/Input instead
export const Input = ({
  children,
  className,
  containerClassName,
  disabled,
  errorInfo,
  inputClassName,
  inputMode,
  isRequired = false,
  min,
  max,
  onBlur,
  onChange,
  onSubmit,
  placeholder,
  prefix,
  right,
  status,
  type,
  value,
  valueInfo,
  name,
  id,
  autoFocus,
  ariaLabel,
}: InputProps) => {
  const t = useTranslate("components")
  const inputRef = useRef<HTMLInputElement>(null)
  const [isRequirementErrorShown, setIsRequirementErrorShown] = useState(false)
  const iconSize = 14

  return (
    <FrameConsumer>
      {({ isFramed }) => (
        <DivContainer
          className={classNames(
            "Input",
            {
              framed: isFramed,
              valid: status === "valid",
            },
            containerClassName,
          )}
        >
          <div
            className={classNames(
              "Input--main border border-level-2",
              inputVariants({ disabled, error: status === "invalid" }),
              className,
            )}
          >
            {children ? (
              <FrameProvider className="Input--label Input--left-label">
                {children}
              </FrameProvider>
            ) : undefined}
            <div
              className="Input--prefix"
              onClick={() => inputRef.current?.focus()}
            >
              {prefix}
            </div>
            <input
              aria-label={ariaLabel}
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              autoFocus={autoFocus}
              className={clsx("browser-default Input--input", inputClassName)}
              data-testid="Input"
              disabled={disabled}
              id={id}
              inputMode={inputMode}
              max={max}
              min={min}
              name={name}
              placeholder={placeholder}
              ref={inputRef}
              required={isRequired}
              spellCheck="false"
              type={type || "text"}
              value={value}
              onBlur={event => {
                setIsRequirementErrorShown(isRequired && !value)
                onBlur?.(event)
              }}
              onChange={e => {
                onChange(e.target.value)
                setIsRequirementErrorShown(false)
              }}
              onKeyDown={e => {
                if (e.key === "Enter" && onSubmit) {
                  onSubmit(value)
                }
              }}
            />
            {right ? (
              <FrameProvider className="Input--label Input--right-label">
                {right}
              </FrameProvider>
            ) : undefined}
          </div>
          {status === "wait" ? (
            <div className="Input--info Input--wait">
              <Spinner />
            </div>
          ) : status === "valid" && valueInfo ? (
            <div className="Input--info">
              <Icon
                className="Input--info-icon"
                size={iconSize}
                value="check"
              />
              <div className="Input--info-text">{valueInfo}</div>
            </div>
          ) : status === "invalid" && errorInfo ? (
            <div className="Input--info text-error">
              <Icon
                className="Input--info-icon"
                size={iconSize}
                value="error"
              />
              <div className="Input--info-text">{errorInfo}</div>
            </div>
          ) : isRequirementErrorShown ? (
            <div className="Input--info">
              <Icon
                className="Input--info-icon"
                size={iconSize}
                value="error"
              />
              <div className="Input--info-text">
                {t("v2.input.validation.required", "This field is required.")}
              </div>
            </div>
          ) : null}
        </DivContainer>
      )}
    </FrameConsumer>
  )
}

const DivContainer = styled.div`
  &.Input--framed {
    margin-top: -1px;
    margin-bottom: -1px;
    border-radius: inherit;

    &:first-child {
      .Input--main {
        border-top: 0;
        border-radius: inherit;
      }
    }

    &:last-child {
      .Input--main {
        border-bottom: 0;
        border-radius: inherit;
      }
    }

    .Input--main {
      border-left: 0;
      border-right: 0;
      border-radius: 0;

      .Input--left-label {
        border-radius: 0;
      }
    }
  }

  &.Input--valid {
    .Input--info-icon {
      color: ${props => props.theme.colors.success};
      font-size: 18px;
    }
  }

  .Input--main {
    border-radius: ${props => props.theme.borderRadius.default};
    display: flex;
    /* NOTE: should not prevent overflows
       because Inputs can contain dropdowns
       overflow: hidden;
     */
    position: relative;

    .Input--label {
      align-items: center;
      color: ${props => props.theme.colors.text.secondary};
      display: flex;
      justify-content: center;
      user-select: none;

      ${props =>
        themeVariant({
          variants: {
            light: {
              backgroundColor: props.theme.colors.surface,
            },
            dark: {
              backgroundColor: props.theme.colors.ash,
              color: props.theme.colors.text.primary,
            },
          },
        })}
    }

    .Input--prefix {
      align-items: center;
      background-color: transparent;
      color: ${props => props.theme.colors.text.secondary};
      display: flex;
      padding-left: 12px;
    }

    .Input--input {
      font-size: 16px;
      background-color: transparent;
      border: none;
      flex: 1 0;
      height: 48px;
      outline: none;
      padding: 0 12px 0 0;
      min-width: 0;
    }

    .Input--left-label {
      border-bottom-left-radius: ${props => props.theme.borderRadius.default};
      border-right: solid 1px
        ${props => props.theme.colors.components.border.level2};
      border-top-left-radius: ${props => props.theme.borderRadius.default};
    }

    .Input--right-label {
      border-left: solid 1px
        ${props => props.theme.colors.components.border.level2};
      max-width: 100px;

      ${media({
        sm: css`
          max-width: none;
        `,
      })}
    }
  }

  .Input--info {
    align-items: center;
    display: flex;
    padding: 4px 4px 6px 4px;

    &.Input--wait {
      padding-top: 6px;
    }

    .Input--info-text {
      font-size: 14px;
      margin-left: 8px;
    }
  }

  input[type="time"]::-webkit-calendar-picker-indicator,
  input[type="date"]::-webkit-calendar-picker-indicator {
    ${themeVariant({
      variants: {
        dark: {
          filter: "invert(1)",
          outline: "none",
        },
      },
    })}
  }
`
