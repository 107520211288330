import React from "react"
import { useIsLessThanMd, Text, classNames } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { ItemQuantityBadge } from "@/components/assets/ItemQuantityBadge.react"
import { StackedAssetMedia } from "@/components/assets/StackedAssetMedia"
import { CollectionLink } from "@/components/collections/CollectionLink"
import { Overflow } from "@/components/common/Overflow"
import { useTheme } from "@/design-system/Context"
import { Flex } from "@/design-system/Flex"
import { Item, ItemProps } from "@/design-system/Item"
import { CollectionOfferDetails_collection$key } from "@/lib/graphql/__generated__/CollectionOfferDetails_collection.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { themeVariant } from "@/styles/styleUtils"
import THEMES from "@/styles/themes"

type CollectionOfferDetailsVariant = "collection-offer-modal" | "default"

type CollectionOfferDetailsProps = {
  collection: CollectionOfferDetails_collection$key
  quantity: number
  trait?: { key: string; value: string }
  renderPriceSide: () => React.ReactNode
  variant?: CollectionOfferDetailsVariant
} & Omit<ItemProps, "variant">

export const CollectionOfferDetails = ({
  collection: collectionDataKey,
  quantity,
  trait,
  renderPriceSide,
  variant = "default",
  ...itemProps
}: CollectionOfferDetailsProps) => {
  const collection = useFragment(
    graphql`
      fragment CollectionOfferDetails_collection on CollectionType {
        representativeAsset {
          assetContract {
            ...CollectionLink_assetContract
          }
          ...StackedAssetMedia_assets
        }
        ...CollectionLink_collection
      }
    `,
    collectionDataKey,
  )

  const { theme } = useTheme()
  const backgroundCover =
    variant === "default"
      ? undefined
      : THEMES[theme].colors.components.background.gray1

  const isMdScreen = !useIsLessThanMd()

  return (
    <Item
      {...(itemProps as ItemProps)}
      className={classNames("border-0", itemProps.className)}
    >
      <ItemContainer $newModalEnabled={variant !== "default"}>
        <ItemQuantityBadge
          overrides={{
            QuantityBadge: {
              props: {
                className: classNames(
                  "right-3",
                  variant !== "default" && "top-[-4px]",
                ),
              },
            },
          }}
          quantity={quantity}
        >
          <StyledItemAvatar
            $newModalEnabled={variant !== "default"}
            size={variant === "default" ? 84 : isMdScreen ? 72 : 48}
            style={{ marginRight: 12 }}
          >
            <StackedAssetMedia
              assets={
                collection.representativeAsset
                  ? [collection.representativeAsset]
                  : []
              }
              backgroundCover={backgroundCover}
              type="collection"
              variant={
                variant === "default" || isMdScreen ? "small" : "xxsmall"
              }
            />
          </StyledItemAvatar>
        </ItemQuantityBadge>

        <Item.Content className="mr-4">
          <Overflow>
            <Item.Title>
              <Text
                asChild
                size={variant === "default" || isMdScreen ? "medium" : "small"}
                weight="semibold"
              >
                <div>
                  <CollectionLink
                    assetContract={
                      collection.representativeAsset?.assetContract
                    }
                    collection={collection}
                    isTiny={variant !== "default" && !isMdScreen}
                    linkStyle={{
                      color: "inherit",
                      fontSize: "inherit",
                      fontWeight: "inherit",
                    }}
                  />
                </div>
              </Text>
            </Item.Title>
          </Overflow>
          {trait && (
            <SelectedTraitContainer
              marginTop={variant !== "default" && isMdScreen ? "4px" : "2px"}
            >
              <Text
                size={variant !== "default" && !isMdScreen ? "tiny" : "small"}
              >
                <Overflow lines={1}>
                  {trait.key}: {trait.value}
                </Overflow>
              </Text>
            </SelectedTraitContainer>
          )}
        </Item.Content>
        <Item.Side>{renderPriceSide()}</Item.Side>
      </ItemContainer>
    </Item>
  )
}

const ItemContainer = styled(Flex)<{ $newModalEnabled: boolean }>`
  padding: ${props => (props.$newModalEnabled ? "16px" : undefined)};
  width: 100%;
  border-radius: ${props =>
    props.$newModalEnabled ? props.theme.borderRadius.default : undefined};
  border: ${props =>
    props.$newModalEnabled
      ? `1px solid ${props.theme.colors.components.background.gray1}`
      : undefined};

  box-shadow: ${props =>
    props.$newModalEnabled ? "0px 4px 15px rgba(0, 0, 0, 0.08)" : undefined};
`

const SelectedTraitContainer = styled(Flex)`
  border-radius: 6px;
  padding: 2px 6px;
  text-transform: capitalize;

  ${themeVariant({
    variants: {
      light: {
        backgroundColor: "mist",
      },
      dark: {
        backgroundColor: "charcoal",
      },
    },
  })}
`

const StyledItemAvatar = styled(Item.Avatar)<{ $newModalEnabled: boolean }>`
  border-radius: ${props =>
    props.$newModalEnabled ? props.theme.borderRadius.default : undefined};
`
