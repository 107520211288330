import React, { Suspense, useEffect } from "react"
import { Media, Container, Spinner, CenterAligned } from "@opensea/ui-kit"
import {
  PreloadedQuery,
  graphql,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay"
import { OpenSeaPage } from "@/containers/OpenSeaPage.react"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { Image } from "@/design-system/Image"
import { OpenSeaHead, pageTitle } from "@/features/seo"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { trackAccountBannedPage } from "@/lib/analytics/events/pageEvents"
import { AccountBannedPageQuery } from "@/lib/graphql/__generated__/AccountBannedPageQuery.graphql"
import { AccountBannedContent } from "./components/AccountBannedContent.react"
import { AccountSuspendedContent } from "./components/AccountSuspendedContent.react"

const ACCOUNT_BANNED_PAGE_QUERY = graphql`
  query AccountBannedPageQuery($address: AddressScalar!) {
    getAccount(address: $address) {
      tsAccountState
    }
  }
`

export const AccountBannedPage = () => {
  const t = useTranslate("components")
  const connectedAddress = useConnectedAddress()

  useMountEffect(() => {
    trackAccountBannedPage()
  })

  const [queryRef, loadQuery, disposeQuery] =
    useQueryLoader<AccountBannedPageQuery>(ACCOUNT_BANNED_PAGE_QUERY)

  useEffect(() => {
    if (connectedAddress) {
      loadQuery({ address: connectedAddress })
    }
    return () => {
      disposeQuery()
    }
  }, [connectedAddress, disposeQuery, loadQuery])

  return (
    <OpenSeaPage hideFooter>
      <OpenSeaHead
        title={pageTitle(
          t("accountBanned.pageTitle", "Your account has been disabled"),
        )}
      />
      <Container>
        <Block paddingTop="5%">
          <CenterAligned>
            <Media greaterThanOrEqual="xl">
              {mediaClassNames => (
                <Image
                  alt="banned"
                  className={mediaClassNames}
                  height={150}
                  src="/static/images/banned.png"
                  width={150}
                />
              )}
            </Media>
            <Media lessThan="xl">
              {mediaClassNames => (
                <Block className={mediaClassNames} paddingTop="10%">
                  <Image
                    alt="banned"
                    height={100}
                    src="/static/images/banned.png"
                    width={100}
                  />
                </Block>
              )}
            </Media>
          </CenterAligned>
          {queryRef ? (
            <Suspense fallback={<AccountBannedPageContentFallback />}>
              <AccountBannedPageContent queryRef={queryRef} />
            </Suspense>
          ) : (
            <AccountBannedPageContentFallback />
          )}
        </Block>
      </Container>
    </OpenSeaPage>
  )
}

type AccountBannedPageContentProps = {
  queryRef: PreloadedQuery<AccountBannedPageQuery>
}

const AccountBannedPageContent = ({
  queryRef,
}: AccountBannedPageContentProps) => {
  const {
    getAccount: { tsAccountState },
  } = usePreloadedQuery(ACCOUNT_BANNED_PAGE_QUERY, queryRef)

  if (tsAccountState === "SUSPENDED") {
    return <AccountSuspendedContent />
  }

  return <AccountBannedContent />
}

const AccountBannedPageContentFallback = () => {
  return (
    <CenterAligned className="mt-10">
      <Spinner size="medium" />
    </CenterAligned>
  )
}
