import React, { Suspense } from "react"
import dynamic from "next/dynamic"
import { ModalLoader } from "@/components/common/ModalLoader.react"
import { Modal, UncontrolledModalProps } from "@/design-system/Modal"
import { useTranslate } from "@/hooks/useTranslate"
import type { SwapModalContentProps } from "./SwapModalContent.react"

export type SwapModalProps = Pick<UncontrolledModalProps, "onClose"> &
  Pick<SwapModalContentProps, "from" | "to" | "onSuccess">

const SwapModalContent = dynamic<SwapModalContentProps>(
  () => import("./SwapModalContent.react").then(mod => mod.SwapModalContent),
  { ssr: false },
)

export const SwapModal = ({ from, to, onSuccess, onClose }: SwapModalProps) => {
  const t = useTranslate("components")
  return (
    <Suspense fallback={<ModalLoader />}>
      <Modal.Header>
        <Modal.Header.Title>
          {t("swapModal.title", "Convert tokens")}
        </Modal.Header.Title>
      </Modal.Header>
      <SwapModalContent
        from={from}
        to={to}
        onSuccess={onSuccess}
        onViewWallet={onClose}
      />
    </Suspense>
  )
}
