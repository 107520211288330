import { useCallback } from "react"
import { EstimateGasPayload } from "@opensea/wallet-messages"
import { resolveTokenTransferTransaction } from "@/actions/transfer"
import { useHandleBlockchainActions } from "@/components/blockchain/BlockchainActionList"
import { useGetChainCurrencies } from "@/components/nav/WalletPopover/components/BridgeOrWrapForm/hooks/useChainCurrencies"
import { resolveSwapTransaction } from "@/components/nav/WalletPopover/components/BridgeOrWrapForm/utils/resolveSwapActions"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { useToasts } from "@/hooks/useToasts"
import { UnreachableCaseError } from "@/lib/helpers/type"
import { ReplyFn } from "./useHandleVesselMessage"

export const useEstimateGas = () => {
  const { attempt } = useToasts()
  const connectedAddress = useConnectedAddress()
  const { estimateGas } = useHandleBlockchainActions()
  const getChainCurrencies = useGetChainCurrencies()

  return useCallback(
    async (payload: EstimateGasPayload, reply: ReplyFn) => {
      if (!connectedAddress) {
        throw new Error("No connected wallet")
      }

      await attempt(async () => {
        const actionForEstimate = payload.actionForEstimate
        switch (actionForEstimate.action) {
          case "Transfer":
            {
              const [transferTransaction, dispose] =
                await resolveTokenTransferTransaction({
                  sender: connectedAddress,
                  recipient: actionForEstimate.toAddress,
                  chain: actionForEstimate.chain as ChainIdentifier,
                  symbol: actionForEstimate.symbol,
                  quantity: actionForEstimate.quantity,
                })
              const gasEstimate = await estimateGas(transferTransaction)
              dispose()
              reply({ success: true, gasEstimate: gasEstimate.toString() })
            }
            return
          case "Wrap": {
            const { baseCurrency, wrappedCurrency } = getChainCurrencies(
              actionForEstimate.chain as ChainIdentifier,
            )
            const [wrapTransaction, dispose] = await resolveSwapTransaction({
              baseCurrency,
              swapCurrency: wrappedCurrency,
              quantity: actionForEstimate.quantity,
              direction: "out",
            })
            const gasEstimate = await estimateGas(wrapTransaction)
            dispose()
            reply({ success: true, gasEstimate: gasEstimate.toString() })
            return
          }
          case "Unwrap": {
            const { baseCurrency, wrappedCurrency } = getChainCurrencies(
              actionForEstimate.chain as ChainIdentifier,
            )
            const [wrapTransaction, dispose] = await resolveSwapTransaction({
              baseCurrency,
              swapCurrency: wrappedCurrency,
              quantity: actionForEstimate.quantity,
              direction: "in",
            })
            const gasEstimate = await estimateGas(wrapTransaction)
            dispose()
            reply({ success: true, gasEstimate: gasEstimate.toString() })
            return
          }
          default:
            throw new UnreachableCaseError(actionForEstimate)
        }
      })
    },
    [attempt, connectedAddress, estimateGas, getChainCurrencies],
  )
}
