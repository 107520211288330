import type { IStorageProvider } from "unleash-proxy-client"

/**
 * By default Unleash stores sessionId and toggles in localStorage. This will
 * crash SSR as localStorage is not available on the server. Storing toggles locally
 * doesn't give us any benefits as we're hydrating the page on SSR anyway.
 *
 * For the sessionId, it means we cannot use it aa the stickiness property, but
 * we should prefer using "address/userId" anyway.
 */
export const unleashSessionStorage: IStorageProvider = {
  save: async (_name: string, _data: unknown) => {
    return
  },
  get: async (_name: string) => {
    return
  },
}
