/* eslint-disable tailwindcss/no-custom-classname */
import React, { Dispatch, SetStateAction, useRef, useState } from "react"
import { Container, FlexColumn } from "@opensea/ui-kit"
import { rgba } from "polished"
import styled, { createGlobalStyle, css } from "styled-components"
import { useThrottledCallback } from "use-debounce"
import { useAccountOrCollectionPageContext } from "@/components/layout/AccountOrCollectionPage/hooks/useAccountOrCollectionPageContext"
import { IS_SERVER, IS_TEST } from "@/constants/environment"
import { Z_INDEX } from "@/constants/zIndex"
import { SwipeableDrawer } from "@/design-system/SwipeableDrawer"
import { useAnnouncementBanner } from "@/features/announcement-banner/utils/context"
import { useAppContext } from "@/hooks/useAppContext"
import { useIsBanned } from "@/hooks/useIsBanned"
import { useIsIosWebview } from "@/hooks/useIsIosWebview"
import { useMountEffect } from "@/hooks/useMountEffect"
import { AnalyticsContextProvider } from "@/lib/analytics"
import {
  trackCloseMobileMenu,
  trackCloseMobileSearch,
  trackOpenMobileMenu,
  trackOpenMobileSearch,
} from "@/lib/analytics/events/appEvents"
import {
  trackCloseSidebarWallet,
  trackOpenSidebarWallet,
} from "@/lib/analytics/events/walletEvents"
import { LinkItem } from "@/lib/helpers/links"
import { media } from "@/styles/styleUtils"
import { HUES } from "@/styles/themes"
import { NAV_HEIGHT_PX, $nav_height } from "@/styles/variables"
import {
  OpenBetaAnnouncementBanner,
  useShowOs2AnnouncementBanner,
} from "../../../features/announcement-banner/components/OpenBetaAnnouncementBanner.react"
import { IncompatibleNetworkBanner } from "../IncompatibleNetworkBanner.react"
import { NavMobile } from "../NavMobile"
import { WalletSidebar } from "../WalletSidebar"
import { NavbarCenter } from "./components/NavbarCenter.react"
import { NavbarLeft } from "./components/NavbarLeft.react"
import { NavbarRight } from "./components/NavbarRight.react"

const EVENT_SOURCE = "NavBar"

interface NavbarProps {
  searchQuery?: string
  isWalletSidebarOpen: boolean
  isMobileMenuVisible: boolean
  setIsMobileMenuVisible: Dispatch<SetStateAction<boolean>>
  isTransparent?: boolean
  isSticky?: boolean
}

export type NavbarItem = LinkItem | React.ReactElement

export const Navbar = ({
  searchQuery,
  isWalletSidebarOpen,
  isMobileMenuVisible,
  setIsMobileMenuVisible,
  isTransparent: isTransparentProp = false,
  isSticky = true,
}: NavbarProps) => {
  const { updateContext } = useAppContext()
  const {
    visibleAnnouncementBannerHeight,
    announcementBannerHeight,
    isAnnouncementBannerSticky,
  } = useAnnouncementBanner()
  const showOs2AnnouncementBanner = useShowOs2AnnouncementBanner()
  const { hasStickyElement } = useAccountOrCollectionPageContext()
  const [isMobileMenuClosing, setIsMobileMenuClosing] = useState(false)
  const [isMobileSearchBarOpen, setIsMobileSearchBarOpen] = useState(false)
  const [isScrolledDown, setIsScrolledDown] = useState(false)
  const walletNavItemRef = useRef<HTMLLIElement>(null)
  const mobileMenuToggleNavItemRef = useRef<HTMLLIElement>(null)
  const mobileSearchBarToggleNavItemRef = useRef<HTMLButtonElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const searchRef = useRef<HTMLInputElement>(null)

  const isActiveAccountBanned = useIsBanned()
  const visibleBannerHeight = isMobileMenuVisible
    ? announcementBannerHeight
    : visibleAnnouncementBannerHeight

  const openWalletSidebar = () => {
    updateContext({ isWalletSidebarOpen: true })
    trackOpenSidebarWallet()
  }
  const closeWalletSidebar = () => {
    updateContext({ isWalletSidebarOpen: false })
    trackCloseSidebarWallet()
  }
  const onWalletSidebarClickAway = (e: MouseEvent | TouchEvent) => {
    const node = e.target as HTMLElement
    if (
      walletNavItemRef.current?.contains(node) ||
      mobileMenuToggleNavItemRef.current?.contains(node)
    ) {
      return
    }

    //  We should not close wallet sidebar on click away if there is a modal open. There might be a cleaner way to do this.
    if (
      node.closest('div[role="dialog"]') ||
      node.querySelector('div[role="dialog"]')
    ) {
      return
    }
    closeWalletSidebar()
  }

  const onNavMobileClickAway = (e: MouseEvent | TouchEvent) => {
    const node = e.target as HTMLElement
    if (isWalletSidebarOpen) {
      return
    }
    closeNavMobile()
    // Without this iOS devices won't open the search bar if the menu is open
    if (mobileSearchBarToggleNavItemRef.current?.contains(node)) {
      openSearchMobile()
    }
  }
  const closeNavMobile = () => {
    trackCloseMobileMenu()
    setIsMobileMenuVisible(false)
    setIsMobileMenuClosing(true)
    updateContext({ isWalletSidebarOpen: false })
  }
  const openNavMobile = () => {
    if (isWalletSidebarOpen) {
      closeWalletSidebar()
    }
    trackOpenMobileMenu()
    setIsMobileMenuVisible(true)
    setIsMobileMenuClosing(false)
  }
  const openSearchMobile = () => {
    trackOpenMobileSearch()
    setIsMobileSearchBarOpen(true)
    searchRef.current?.focus()
  }
  const closeSearchMobile = () => {
    trackCloseMobileSearch()
    setIsMobileSearchBarOpen(false)
  }
  const setIsNavMobileOpen = (isOpen: boolean) =>
    isOpen ? openNavMobile() : closeNavMobile()

  const scrollHandler = useThrottledCallback(
    () => {
      window.requestAnimationFrame(() => {
        setIsScrolledDown(window.scrollY > 10)
      })
    },
    50,
    { leading: true },
  )

  useMountEffect(() => {
    window.addEventListener("scroll", scrollHandler)
    scrollHandler()

    return () => {
      window.removeEventListener("scroll", scrollHandler)
    }
  })

  const isTransparent =
    isTransparentProp &&
    !isWalletSidebarOpen &&
    !isMobileMenuVisible &&
    !isScrolledDown

  const isBordered = isScrolledDown && !hasStickyElement

  const isIosWebview = useIsIosWebview()
  if (isIosWebview) {
    return null
  }

  const banner = isMobileSearchBarOpen ? (
    <GlobalIOSFixForMobileSearch />
  ) : showOs2AnnouncementBanner ? (
    <OpenBetaAnnouncementBanner />
  ) : null

  return (
    <AnalyticsContextProvider eventSource={EVENT_SOURCE}>
      {banner}

      {/* Navbar */}
      <DivContainer
        isBordered={isBordered}
        isSticky={isSticky}
        isTransparent={isTransparent}
        ref={containerRef}
        // Use announcementBannerHeight not visibleAnnouncementBannerHeight as visible will change onScroll
        topOffset={isAnnouncementBannerSticky ? announcementBannerHeight : 0}
      >
        {/* Main */}
        <NavbarContainer
          $isMobileSearchBarOpen={isMobileSearchBarOpen}
          className="h-full"
        >
          <nav className="Navbar--main items-center lg:py-1.5">
            <NavbarLeft
              isMobileSearchBarOpen={isMobileSearchBarOpen}
              isTransparent={isTransparent}
            />

            <SearchBarContainer>
              <NavbarCenter
                closeSearchMobile={closeSearchMobile}
                isActiveAccountBanned={!!isActiveAccountBanned}
                isMobileSearchBarOpen={isMobileSearchBarOpen}
                isTransparent={isTransparent}
                searchQuery={searchQuery}
                searchRef={searchRef}
                visibleBannerHeight={visibleBannerHeight}
              />
            </SearchBarContainer>

            <NavbarRight
              background={isTransparent ? "transparent" : undefined}
              isMobileMenuClosing={isMobileMenuClosing}
              isMobileMenuVisible={isMobileMenuVisible}
              isMobileSearchBarOpen={isMobileSearchBarOpen}
              mobileMenuToggleNavItemRef={mobileMenuToggleNavItemRef}
              mobileSearchBarToggleNavItemRef={mobileSearchBarToggleNavItemRef}
              openSearchMobile={openSearchMobile}
              openWalletSidebar={openWalletSidebar}
              setIsNavMobileOpen={setIsNavMobileOpen}
            />
          </nav>
        </NavbarContainer>

        <IncompatibleNetworkBanner />
      </DivContainer>

      {/* Mobile drawer */}
      <SwipeableDrawer
        anchorSide="right"
        id="swipeable-navmenu-drawer"
        isOpen={isMobileMenuVisible}
        isVisible={IS_SERVER && !IS_TEST}
        navbarOffset={`${NAV_HEIGHT_PX + visibleBannerHeight}px`}
        overflowY="hidden"
        setIsOpen={setIsNavMobileOpen}
        onClickAway={onNavMobileClickAway}
        onClosed={() => setIsMobileMenuClosing(false)}
      >
        <FlexColumn className="h-full">
          <NavMobile onClose={closeNavMobile} />
        </FlexColumn>
      </SwipeableDrawer>

      {/* Deprecated: Wallet Fullscreen modal for mobile */}
      {!isActiveAccountBanned && (
        <WalletSidebar
          bannerHeight={visibleBannerHeight}
          close={closeWalletSidebar}
          isOpen={isWalletSidebarOpen}
          onWalletSidebarClickAway={onWalletSidebarClickAway}
        />
      )}
    </AnalyticsContextProvider>
  )
}

const SearchBarContainer = styled.div`
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  height: 100%;
  pointer-events: all;
  flex: 1 1 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${media({
    xxl: css`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
    `,
  })}
`

// Without this component, iOS devices won't respect the sticky search when the keyboard is open.
// Also when we apply "position: fixed" we also need to set "width: 100vw" to prevent page layout issues
const GlobalIOSFixForMobileSearch = createGlobalStyle`
  ${media(
    {
      sm: css`
        html,
        body {
          overflow: auto;
          position: fixed;
          width: 100vw;
        }
      `,
    },
    { variant: "lessThan" },
  )}
`

const transitionCurve = "--transition-curve: cubic-bezier(0.05, 0, 0.2, 1);"
export const navBannerTransition = css`
  ${transitionCurve}
  transition:
    top 0.4s var(--transition-curve),
    background-color 0.4s var(--transition-curve),
    box-shadow 0.4s var(--transition-curve),
    color 0.4s var(--transition-curve),
    transform 0.4s var(--transition-curve);
`

const DivContainer = styled.div<{
  isBordered: boolean
  isTransparent: boolean
  isSticky: boolean
  topOffset: number
}>`
  ${transitionCurve}
  transition:
    background-color 0.2s var(--transition-curve),
    box-shadow 0.2s var(--transition-curve),
    color 0.2s var(--transition-curve),
    transform 0.4s var(--transition-curve);

  // When we have a banner smooth out the transition
  ${props => props.topOffset > 0 && navBannerTransition}

  border-bottom: ${props =>
    props.isBordered
      ? `1px solid ${props.theme.colors.components.border.level1}`
      : "none"};

  max-width: 100vw;
  height: ${$nav_height};
  top: ${props => props.topOffset}px;
  position: ${props => (props.isSticky ? "sticky" : "relative")};
  z-index: ${Z_INDEX.NAVBAR};
  ${props =>
    props.isTransparent
      ? css`
          background-color: transparent;

          .NavItem--main,
          .NavItem--withIcon .NavItem--icon {
            color: ${HUES.white};
            &:hover,
            &:focus,
            &:focus-within {
              color: ${rgba(HUES.white, 0.8)};
            }
            &:active {
              color: ${rgba(HUES.white, 0.6)};
            }
          }
        `
      : css`
          background-color: ${props.theme.colors.base1};
        `}

  .Navbar--main {
    display: flex;
    height: 100%;
    justify-content: space-between;

    .Navbar--mobile-toggle {
      color: ${props => props.theme.colors.text.primary};
      height: 100%;
    }
  }

  .Navbar--close-search-bar {
    background-color: ${props => props.theme.colors.base1};
  }

  ${media(
    {
      lg: css`
        .Navbar--main .Navbar--brand .Navbar--brand-name {
          display: initial;
        }
      `,
    },
    { variant: "lessThan" },
  )}
`

const NavbarContainer = styled(Container)<{
  $isMobileSearchBarOpen: boolean
}>`
  // We need to use style here because we need to override the container padding

  ${({ $isMobileSearchBarOpen }) =>
    media(
      {
        lg: css`
          padding-left: ${$isMobileSearchBarOpen ? "0px" : undefined};
          padding-right: ${$isMobileSearchBarOpen ? "0px" : undefined};
        `,
      },
      {
        variant: "lessThan",
      },
    )}
`
