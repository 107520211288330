/**
 * @generated SignedSource<<fbd0c93638a0a1de05832337af820b52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type collection_url$data = {
  readonly isCategory: boolean;
  readonly slug: string;
  readonly " $fragmentType": "collection_url";
};
export type collection_url$key = {
  readonly " $data"?: collection_url$data;
  readonly " $fragmentSpreads": FragmentRefs<"collection_url">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "collection_url"
};

(node as any).hash = "244a742709a2c4d3bd44531cf38be4b0";

export default node;
