import { ChainIdentifier } from "@/hooks/useChains/types"

const DEFAULT_WALLET_CONNECT_CLOUD_PROJECT_ID =
  "d87229c37d1f13e33f9487624726e074"
export const WALLET_CONNECT_CLOUD_PROJECT_ID =
  process.env.NEXT_PUBLIC_WALLET_CONNECT_CLOUD_PROJECT_ID ??
  DEFAULT_WALLET_CONNECT_CLOUD_PROJECT_ID

export const WALLET_CONNECT_CUSTOM_RPC_CHAINS: ChainIdentifier[] = [
  "BAOBAB",
  "KLAYTN",
]
