import React, { useState } from "react"
import { FlexColumn, PhoneInput, Text } from "@opensea/ui-kit"
import { Button } from "@/design-system/Button"
import { Form } from "@/design-system/Form"
import { FormControl } from "@/design-system/FormControl"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { MFA_STEPS } from "@/features/os-wallet/components/constants"
import { useOSWalletAuth } from "@/features/os-wallet/hooks/useOSWalletAuth"
import { useForm } from "@/hooks/useForm"
import { useTranslate } from "@/hooks/useTranslate"
import { OSWalletModalBody } from "./OSWalletModalBody.react"
import { WalletMfaEnrollmentModalProps } from "./WalletMfaEnrollmentModal.react"
import { WalletPhoneCodeModal } from "./WalletPhoneCodeModal.react"

type FormData = {
  phoneNumber: string
}

const SUPPORTED_COUNTRIES = ["us", "ca"]

const US_CANADA_PHONE_NUMBER_VALIDATION =
  /^(\+1\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

export const WalletPhoneNumberModal = ({
  accessToken,
  privyId,
}: WalletMfaEnrollmentModalProps) => {
  const t = useTranslate("common")
  const { onPrevious, onNext } = useMultiStepFlowContext()
  const { initMfaEnrollmentWithSms } = useOSWalletAuth()
  const [submitError, setSubmitError] = useState<boolean>(false)

  const {
    formState: { errors, isSubmitting, isValid },
    register,
    handleSubmit,
  } = useForm<FormData>({
    mode: "onSubmit",
  })

  const onSubmit = handleSubmit(async ({ phoneNumber }) => {
    setSubmitError(false)
    try {
      await initMfaEnrollmentWithSms(phoneNumber)
      onNext(() => (
        <WalletPhoneCodeModal
          accessToken={accessToken}
          phoneNumber={phoneNumber}
          privyId={privyId}
        />
      ))
    } catch {
      setSubmitError(true)
    }
  })

  return (
    <OSWalletModalBody
      showLogo={false}
      step={2}
      steps={MFA_STEPS["phone"]}
      title={t("wallet.opensea.enterPhone", "Enter Phone Number")}
      onPrevious={onPrevious}
    >
      <Form className="flex grow flex-col justify-between" onSubmit={onSubmit}>
        <FlexColumn className="gap-4 text-center">
          <FormControl
            error={
              submitError
                ? t(
                    "wallet.opensea.phoneNumber.error",
                    "Unable to send code to this phone number",
                  )
                : errors.phoneNumber?.message
            }
            hideLabel
            label={t("wallet.opensea.phoneNumber.label", "Phone number")}
          >
            <PhoneInput
              className="flex-1 bg-white p-0 shadow-elevation-1 dark:bg-component-gray-1"
              overrides={{
                Select: {
                  className: "ml-[1px]",
                  overrides: {
                    Content: {
                      // Must be higher than design-system Overlay z-index: 100,
                      // otherwise dropdown appears behind the overlay.
                      className: "z-[200]",
                    },
                  },
                },
              }}
              supportedCountries={SUPPORTED_COUNTRIES}
              {...register("phoneNumber", {
                required: t(
                  "wallet.opensea.phoneNumber.required",
                  "Phone number is required.",
                ),
                pattern: {
                  value: US_CANADA_PHONE_NUMBER_VALIDATION,
                  message: t(
                    "wallet.opensea.phoneNumber.invalid",
                    "Invalid phone number",
                  ),
                },
              })}
            />
          </FormControl>
          <Text.Body color="secondary" size="tiny">
            {t(
              "wallet.opensea.thisNumberWillReceiveCode",
              "This number will receive a 6-digit code via text message.",
            )}
          </Text.Body>
        </FlexColumn>
        <Button
          disabled={!isValid || isSubmitting}
          type="submit"
          variant="primary"
        >
          {t("wallet.opensea.sendCode", "Send code")}
        </Button>
      </Form>
    </OSWalletModalBody>
  )
}
