/**
 * @generated SignedSource<<701bda93771ee366575b1b469687434f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderV2OrderType = "BASIC" | "CRITERIA" | "DUTCH" | "ENGLISH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type usePaymentMethod_order$data = {
  readonly item: {
    readonly __typename: string;
    readonly relayId: string;
  };
  readonly itemQuantityType: string;
  readonly orderType: OrderV2OrderType;
  readonly relayId: string;
  readonly " $fragmentType": "usePaymentMethod_order";
};
export type usePaymentMethod_order$key = {
  readonly " $data"?: usePaymentMethod_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"usePaymentMethod_order">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "usePaymentMethod_order",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "orderType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "itemQuantityType",
      "storageKey": null
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};
})();

(node as any).hash = "3d7eade58dca5205d3b2f855897552f0";

export default node;
