import React from "react"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { Dropdown } from "@/design-system/Dropdown"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"

export const LogoutItem = () => {
  const t = useTranslate("components")
  const { logout } = useWallet()
  const { showSuccessMessage } = useToasts()

  return (
    <Dropdown.Item
      onClick={async () => {
        await logout()
        showSuccessMessage(
          t("logoutSuccess", "You have been logged out successfully."),
        )
      }}
    >
      <Dropdown.Item.Avatar icon="logout" />
      <Dropdown.Item.Content>
        <Dropdown.Item.Title>{t("logout", "Log Out")}</Dropdown.Item.Title>
      </Dropdown.Item.Content>
    </Dropdown.Item>
  )
}
