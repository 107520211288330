/**
 * @generated SignedSource<<7661cbd5242e193ab031fc5bad68cf3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderDataHeader_criteriaAsset$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmationItem_assets">;
  readonly " $fragmentType": "OrderDataHeader_criteriaAsset";
};
export type OrderDataHeader_criteriaAsset$key = {
  readonly " $data"?: OrderDataHeader_criteriaAsset$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderDataHeader_criteriaAsset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderDataHeader_criteriaAsset",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConfirmationItem_assets"
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "3b2bd2d530192e0ffd6951dae23bd3ef";

export default node;
