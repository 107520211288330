import type { DocumentProvidedProps } from "@/containers/AppProviders"
import { memoizeOne } from "@/lib/helpers/memoization"

export const SESSION_DOCUMENT_ID = "__OS_SESSION__"

const getDocumentSession = memoizeOne((): string => {
  return JSON.parse(
    document.querySelector(`#${SESSION_DOCUMENT_ID}`)?.textContent || "",
  )
})

export const getSessionId = (
  pageProps: DocumentProvidedProps,
): string | undefined => {
  if (typeof window === "undefined") {
    return pageProps.sessionId
  }
  return getDocumentSession()
}
