import { create } from "zustand"

type TransactionStatus = "pending" | "success" | "error"
type TransactionStatusStore = {
  transactionStatus?: TransactionStatus
  updateTransactionStatus: (transactionStatus?: TransactionStatus) => void
}

export const useTransactionStatusStore = create<TransactionStatusStore>(
  set => ({
    transactionStatus: undefined,
    updateTransactionStatus: (transactionStatus?: TransactionStatus) =>
      set({ transactionStatus }),
  }),
)
