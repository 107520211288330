let current = 0

const get = () => {
  current += 10
  return current
}

export const Z_INDEX = {
  ITEM_CARD_PENDING_TXN_SPINNING_BORDER: get(),
  ITEM_CARD_SELECT_ICON: get(),
  SEARCH_FILTER_OPENER: get(),
  ASSET_SELECTION_BAR: get(),
  ASSET_SEARCH_LIST_TABLE_HEADER: get(),
  ACCOUNT_HEADER_SHADOW: get(),
  PHOENIX_HEADER: get(),
  ACCOUNT_LINKS_BAR: get(),
  PROFILE_IMAGE: get(),
  MANAGER_BAR: get(),
  OVERLAY: get(),
  SEARCH_FILTER: get(),
  DRAWER: get(),
  DRAWER_MOBILE: get(),
  NAVBAR: get(),
  SIDE_NAVBAR: get(),
  NAVSEARCH_OVERLAY: get(),
  MODAL: get(),
  OS_WALLET_POPOVER: get(),
  TOASTS: get(),
}
