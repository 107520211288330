import React, {
  createContext as createReactContext,
  useContext,
  useMemo,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react"
import BigNumber from "bignumber.js"
import { isEmpty, noop } from "lodash"
import { Disposable } from "react-relay"
import { graphql } from "relay-runtime"
import { createContext, useContextSelector } from "use-context-selector"
import { IS_TESTNET } from "@/constants/testnet"
import { useIsMountedRef } from "@/hooks/useIsMounted"
import { useIsOpen } from "@/hooks/useIsOpen"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTrackingFn } from "@/lib/analytics/hooks/useTrackingFn"
import { ShoppingCartTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/ShoppingCartTrackingContext.react"
import { ShoppingCartContextProvider_inline_error$data } from "@/lib/graphql/__generated__/ShoppingCartContextProvider_inline_error.graphql"
import { ShoppingCartContextProvider_inline_order$data } from "@/lib/graphql/__generated__/ShoppingCartContextProvider_inline_order.graphql"
import { ShoppingCartContextProviderErrorsQuery } from "@/lib/graphql/__generated__/ShoppingCartContextProviderErrorsQuery.graphql"
import { ShoppingCartContextProviderQuery } from "@/lib/graphql/__generated__/ShoppingCartContextProviderQuery.graphql"
import { hasGraphQLResponseErrorWithStatus } from "@/lib/graphql/error"
import { fetch } from "@/lib/graphql/graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { bn } from "@/lib/helpers/numberUtils"
import { MapNullable } from "@/lib/helpers/type"
import { captureNoncriticalError } from "@/lib/sentry"
import { useTotalItems } from "../hooks/useTotalItems"
import { trackCartError, trackLoadCartOnPageLoad } from "./analytics"

const MAINNET_LOCAL_STORAGE_KEY = "shoppingCart"
const TESTNET_LOCAL_STORAGE_KEY = "testnet_shoppingCart"

const LOCAL_STORAGE_KEY = IS_TESTNET
  ? TESTNET_LOCAL_STORAGE_KEY
  : MAINNET_LOCAL_STORAGE_KEY

type OrderToQuantity = Record<string, number>

type LocalCartErrorReason = "EXCEEDS_MAKER_OWNERSHIP"
type LocalCartError = { reason: LocalCartErrorReason }

type ShoppingCartContextType = {
  orders: ShoppingCartContextProvider_inline_order$data[]
  errors: ShoppingCartContextProvider_inline_error$data[]
  orderToQuantity: OrderToQuantity
  totalAssetIdsInCart: string[]
  incrementOrderQuantity: (orderRelayId: string) => void
  decrementOrderQuantity: (orderRelayId: string) => void
  setOrderQuantity: (orderRelayId: string, quantity: number) => void
  checkCartErrors: () => Promise<
    ShoppingCartContextProvider_inline_error$data[]
  >
  isOrderInCart: (orderRelayId?: string) => boolean
  isItemInCart: (itemRelayId?: string) => boolean
  localErrors: Partial<Record<string, LocalCartError[]>>
}

const ShoppingCartContext = createContext<ShoppingCartContextType>({
  orders: [],
  errors: [],
  orderToQuantity: {},
  totalAssetIdsInCart: [],
  incrementOrderQuantity: noop,
  decrementOrderQuantity: noop,
  setOrderQuantity: noop,
  checkCartErrors: async () => [],
  isOrderInCart: () => false,
  isItemInCart: () => false,
  localErrors: {},
})

type ShoppingCartViewContextType = {
  isOpen: boolean
  isLoading: boolean
  open: () => void
  close: () => void
  toggle: () => void
}

const ShoppingCartViewContext = createContext<ShoppingCartViewContextType>({
  isOpen: false,
  isLoading: false,
  open: noop,
  close: noop,
  toggle: noop,
})

type ShoppingCartAddOrRemoveOrderContextType = {
  addOrder: (order: ShoppingCartContextProvider_inline_order$data) => void
  removeOrder: (orderRelayId: string) => void
  clear: () => void
  refresh: () => void
}

const ShoppingCartAddOrRemoveOrderContext =
  createReactContext<ShoppingCartAddOrRemoveOrderContextType>({
    addOrder: noop,
    removeOrder: noop,
    clear: noop,
    refresh: noop,
  })

type Props = {
  children: React.ReactNode
  isInitiallyOpen?: boolean
  initialOrderToQuantity?: OrderToQuantity
}

export const ShoppingCartContextProvider = ({
  children,
  isInitiallyOpen,
  initialOrderToQuantity,
}: Props) => {
  const { isOpen, close, open, toggle } = useIsOpen(isInitiallyOpen)

  const [orders, setOrders] = useState<
    ShoppingCartContextProvider_inline_order$data[]
  >([])
  const [errors, setErrors] = useState<
    ShoppingCartContextProvider_inline_error$data[]
  >([])
  const [orderToQuantity, setOrderToQuantity] = useState<OrderToQuantity>(
    initialOrderToQuantity ?? {},
  )

  const [relayDisposables, setRelayDisposables] = useState<
    (Disposable | undefined)[]
  >([])
  const [isLoading, setIsLoading] = useState(false)

  // Use a ref so that we don't set local storage on storage event listener loads
  // This is so we don't waterfall into setting local storage too many times and thus doing more fetches
  // Set this to true on user-initiated actions, and false on storage event listener loads
  const shouldSetLocalStorageRef = useRef(false)

  const isMountedRef = useIsMountedRef()

  const clear = useCallback(() => {
    shouldSetLocalStorageRef.current = true
    setOrders([])
    setErrors([])
    relayDisposables.forEach(disposable => disposable?.dispose())
    setRelayDisposables([])
  }, [relayDisposables])

  const totalAssetIdsInCart = useTotalItems({ ordersDataKey: orders })

  const loadOrders = useCallback(
    async (orderToQuantity: OrderToQuantity) => {
      try {
        const [data, disposable] =
          await fetch<ShoppingCartContextProviderQuery>(
            graphql`
              query ShoppingCartContextProviderQuery(
                $ordersToFill: [OrderToFillInputType!]!
              ) {
                blockchain {
                  bulkPurchase(ordersToFill: $ordersToFill) {
                    orders {
                      ...ShoppingCartContextProvider_inline_order
                    }
                  }
                }
              }
            `,
            {
              ordersToFill: Object.entries(orderToQuantity).map(
                ([order, itemFillAmount]) => ({
                  order,
                  itemFillAmount: itemFillAmount.toString(),
                }),
              ),
            },
            { force: true },
            undefined,
            { retain: true },
          )
        setRelayDisposables(prev => [...prev, disposable])
        return data
      } catch (error) {
        if (hasGraphQLResponseErrorWithStatus(error, 404)) {
          clear()
        }

        captureNoncriticalError(error)

        return { blockchain: { bulkPurchase: { orders: [] } } }
      }
    },
    [clear],
  )

  const loadStoredState = useCallback(async () => {
    // Any invalid values should just be considered as having a quantity of 0
    const storedState = Object.fromEntries(
      Object.entries(
        JSON.parse(
          localStorage.getItem(LOCAL_STORAGE_KEY) || "{}",
        ) as OrderToQuantity,
      ).map(([relayId, quantity]) => [relayId, quantity || 0]),
    )

    const orderToQuantityToLoad = initialOrderToQuantity ?? storedState

    // This is needed so we can "reset" the current cart if a different tab clears its cart.
    if (isEmpty(orderToQuantityToLoad) && !isEmpty(orderToQuantity)) {
      clear()
      return
    }

    if (!isEmpty(orderToQuantityToLoad)) {
      setIsLoading(true)
      const data = await loadOrders(orderToQuantityToLoad)

      if (isMountedRef.current) {
        setIsLoading(false)

        const orders = data.blockchain.bulkPurchase.orders.map(
          readShoppingCartOrder,
        )

        shouldSetLocalStorageRef.current = false
        setOrderToQuantity(
          orders.reduce<OrderToQuantity>((acc, order) => {
            return {
              ...acc,
              [order.relayId]: BigNumber.min(
                (orderToQuantityToLoad as MapNullable<OrderToQuantity>)[
                  order.relayId
                ] ?? 1,
                order.remainingQuantityType,
              ).toNumber(),
            }
          }, {}),
        )
        setOrders(orders)

        return orders
      }
    }
    return
  }, [clear, initialOrderToQuantity, isMountedRef, loadOrders, orderToQuantity])

  const loadStoredStateOnStorageEvent = useCallback(
    async (event: StorageEvent) => {
      if (event.key === LOCAL_STORAGE_KEY) {
        loadStoredState()
      }
    },
    [loadStoredState],
  )

  useMountEffect(() => {
    const onPageLoad = async () => {
      const orders = await loadStoredState()
      if (orders) {
        trackLoadCartOnPageLoad({
          numberOfItems: orders.length,
        })
      }
    }

    onPageLoad()

    window.addEventListener("storage", loadStoredStateOnStorageEvent)

    return () => {
      window.removeEventListener("storage", loadStoredStateOnStorageEvent)
    }
  })

  useEffect(() => {
    setOrderToQuantity(
      orders.reduce<MapNullable<OrderToQuantity>>((acc, order) => {
        return {
          ...acc,
          [order.relayId]: BigNumber.min(
            (orderToQuantity as MapNullable<OrderToQuantity>)[order.relayId] ??
              acc[order.relayId] ??
              1,
            order.remainingQuantityType,
          ).toNumber(),
        }
      }, {}) as OrderToQuantity,
    )
    // orderToQuantity not added to prevent infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders])

  useEffect(() => {
    if (shouldSetLocalStorageRef.current) {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(orderToQuantity))
    }
  }, [orderToQuantity])

  const hasSingleOrder = orders.length === 1

  const isItemInCart = useCallback(
    (itemRelayId?: string) => {
      return totalAssetIdsInCart.some(relayId => relayId === itemRelayId)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(totalAssetIdsInCart)],
  )

  const orderIds = useMemo(() => {
    return orders.map(order => order.relayId)
  }, [orders])

  const isOrderInCart = useCallback(
    (orderRelayId?: string) => {
      return orders.some(order => order.relayId === orderRelayId)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(orderIds)],
  )

  const addOrder = useCallback(
    async (order: ShoppingCartContextProvider_inline_order$data) => {
      shouldSetLocalStorageRef.current = true

      setOrders(orders => [...orders, order])

      // We need to manually load the order with a query here because the source of the order added can be garbage collected at any time
      const data = await loadOrders({ [order.relayId]: 1 })
      const loadedOrder = readShoppingCartOrder(
        data.blockchain.bulkPurchase.orders[0],
      )

      // Then we replace the old order added with this loaded order, if it exists
      setOrders(orders =>
        orders.map(o => (o.relayId === loadedOrder.relayId ? loadedOrder : o)),
      )
    },
    [loadOrders],
  )

  const removeOrder = useCallback(
    (orderRelayId: string) => {
      shouldSetLocalStorageRef.current = true
      setOrders(orders => orders.filter(o => o.relayId !== orderRelayId))
      // Also remove any relevant errors for the order
      setErrors(errors =>
        errors.filter(e => e.originalOrder.relayId !== orderRelayId),
      )

      if (hasSingleOrder) {
        relayDisposables.forEach(d => d?.dispose())
        setRelayDisposables([])
      }
    },
    [hasSingleOrder, relayDisposables],
  )

  const incrementOrderQuantity = useCallback(
    (orderRelayId: string) => {
      shouldSetLocalStorageRef.current = true
      setOrderToQuantity(orderToQuantity => ({
        ...orderToQuantity,
        [orderRelayId]: BigNumber.min(
          orderToQuantity[orderRelayId] + 1,
          orders.find(o => o.relayId === orderRelayId)?.remainingQuantityType ??
            1,
        ).toNumber(),
      }))
    },
    [orders],
  )

  const decrementOrderQuantity = useCallback((orderRelayId: string) => {
    shouldSetLocalStorageRef.current = true
    setOrderToQuantity(orderToQuantity => ({
      ...orderToQuantity,
      [orderRelayId]: orderToQuantity[orderRelayId] - 1,
    }))
  }, [])

  const setOrderQuantity = useCallback(
    (orderRelayId: string, quantity: number) => {
      shouldSetLocalStorageRef.current = true

      setOrderToQuantity(orderToQuantity => ({
        ...orderToQuantity,
        [orderRelayId]: quantity,
      }))
    },
    [],
  )

  const checkCartErrors = useCallback(async () => {
    const [data, disposable] =
      await fetch<ShoppingCartContextProviderErrorsQuery>(
        graphql`
          query ShoppingCartContextProviderErrorsQuery(
            $ordersToFill: [OrderToFillInputType!]!
          ) {
            blockchain {
              bulkPurchase(ordersToFill: $ordersToFill) {
                errors {
                  ...ShoppingCartContextProvider_inline_error
                }
              }
            }
          }
        `,
        {
          ordersToFill: Object.entries(orderToQuantity).map(
            ([order, itemFillAmount]) => ({
              order,
              itemFillAmount: itemFillAmount.toString(),
            }),
          ),
        },
        { force: true },
        undefined,
        { retain: true },
      )

    setRelayDisposables(disposables => [...disposables, disposable])

    const errors = data.blockchain.bulkPurchase.errors.map(
      readShoppingCartError,
    )

    setErrors(errors)

    errors.forEach(error => {
      trackCartError({ errorReason: error.reason })
    })

    return errors
  }, [orderToQuantity])

  const refresh = useCallback(async () => {
    shouldSetLocalStorageRef.current = true
    setOrders(orders =>
      orders
        .map<ShoppingCartContextProvider_inline_order$data | undefined>(
          order => {
            const error = errors.find(
              e => e.originalOrder.relayId === order.relayId,
            )

            if (error?.updatedOrder) {
              return readShoppingCartOrder(error.updatedOrder)
            } else if (error?.reason === "ORDER_UNAVAILABLE") {
              return undefined
            }

            return order
          },
        )
        .flatMap(o => (o ? [o] : [])),
    )
    setErrors([])
  }, [errors])

  const localErrors: Partial<Record<string, LocalCartError[]>> = useMemo(() => {
    // This is in-cart quantity by asset and owner
    const assetAndOwnerToQuantity: Partial<
      Record<string, Partial<Record<string, BigNumber>>>
    > = {}
    const localErrors: Partial<Record<string, LocalCartError[]>> = {}

    orders
      .sort((a, b) => bn(a.priceType.usd).comparedTo(b.priceType.usd))
      .forEach(order => {
        const assetId = order.item.relayId
        const makerId = order.maker.relayId
        const quantityInCartSoFar = bn(
          assetAndOwnerToQuantity[assetId]?.[makerId] ?? 0,
        )
        const makerOwnedQuantity = bn(order.makerOwnedQuantity)

        const newQuantityInCart = quantityInCartSoFar.plus(
          orderToQuantity[order.relayId],
        )
        assetAndOwnerToQuantity[assetId] = {
          ...assetAndOwnerToQuantity[assetId],
          [makerId]: newQuantityInCart,
        }

        if (newQuantityInCart.isGreaterThan(makerOwnedQuantity)) {
          localErrors[order.relayId] = [
            ...(localErrors[order.relayId] ?? []),
            {
              reason: "EXCEEDS_MAKER_OWNERSHIP",
            },
          ]
        }
      })

    return localErrors
  }, [orderToQuantity, orders])

  const value = useMemo(
    () => ({
      orders,
      errors,
      orderToQuantity,
      totalAssetIdsInCart,
      incrementOrderQuantity,
      decrementOrderQuantity,
      setOrderQuantity,
      checkCartErrors,
      isItemInCart,
      isOrderInCart,
      localErrors,
    }),
    [
      checkCartErrors,
      decrementOrderQuantity,
      errors,
      incrementOrderQuantity,
      orderToQuantity,
      orders,
      setOrderQuantity,
      totalAssetIdsInCart,
      isItemInCart,
      isOrderInCart,
      localErrors,
    ],
  )

  const view = useMemo(
    () => ({
      isOpen,
      isLoading,
      open,
      close,
      toggle,
    }),
    [isOpen, isLoading, open, close, toggle],
  )

  const addOrRemove = useMemo(
    () => ({
      addOrder,
      clear,
      removeOrder,
      refresh,
    }),
    [addOrder, clear, removeOrder, refresh],
  )

  return (
    <ShoppingCartContext.Provider value={value}>
      <ShoppingCartViewContext.Provider value={view}>
        <ShoppingCartAddOrRemoveOrderContext.Provider value={addOrRemove}>
          <ShoppingCartTrackingContextProvider>
            {children}
          </ShoppingCartTrackingContextProvider>
        </ShoppingCartAddOrRemoveOrderContext.Provider>
      </ShoppingCartViewContext.Provider>
    </ShoppingCartContext.Provider>
  )
}

export const useShoppingCartOrders = () =>
  useContextSelector(ShoppingCartContext, value => value.orders)

export const useShoppingCartErrors = () =>
  useContextSelector(ShoppingCartContext, value => value.errors)

export const useShoppingCartOrderToQuantity = () =>
  useContextSelector(ShoppingCartContext, value => value.orderToQuantity)

export const useShoppingCartTotalAssetIdsInCart = () =>
  useContextSelector(ShoppingCartContext, value => value.totalAssetIdsInCart)

export const useShoppingCartActions = () => {
  const incrementOrderQuantity = useContextSelector(
    ShoppingCartContext,
    value => value.incrementOrderQuantity,
  )

  const decrementOrderQuantity = useContextSelector(
    ShoppingCartContext,
    value => value.decrementOrderQuantity,
  )

  const setOrderQuantity = useContextSelector(
    ShoppingCartContext,
    value => value.setOrderQuantity,
  )

  const checkCartErrors = useContextSelector(
    ShoppingCartContext,
    value => value.checkCartErrors,
  )

  const isOrderInCart = useContextSelector(
    ShoppingCartContext,
    value => value.isOrderInCart,
  )

  const isItemInCart = useContextSelector(
    ShoppingCartContext,
    value => value.isItemInCart,
  )

  return useMemo(
    () => ({
      incrementOrderQuantity,
      decrementOrderQuantity,
      setOrderQuantity,
      checkCartErrors,
      isOrderInCart,
      isItemInCart,
    }),
    [
      checkCartErrors,
      decrementOrderQuantity,
      incrementOrderQuantity,
      isItemInCart,
      isOrderInCart,
      setOrderQuantity,
    ],
  )
}

export const useShoppingCartViewIsOpen = () =>
  useContextSelector(ShoppingCartViewContext, value => value.isOpen)

export const useShoppingCartViewIsLoading = () =>
  useContextSelector(ShoppingCartViewContext, value => value.isLoading)

export const useShoppingCartViewActions = () => {
  const open = useContextSelector(ShoppingCartViewContext, value => value.open)

  const close = useContextSelector(
    ShoppingCartViewContext,
    value => value.close,
  )

  const toggle = useContextSelector(
    ShoppingCartViewContext,
    value => value.toggle,
  )

  return useMemo(
    () => ({
      open,
      close,
      toggle,
    }),
    [close, open, toggle],
  )
}

export const useShoppingCartAddOrRemoveOrders =
  (): ShoppingCartAddOrRemoveOrderContextType => {
    const trackAddToCart = useTrackingFn("add to cart")
    const trackRemoveFromCart = useTrackingFn("remove from cart")
    const trackClearCart = useTrackingFn("clear cart")
    const trackRefreshCart = useTrackingFn("refresh cart")

    const { addOrder, removeOrder, clear, refresh } = useContext(
      ShoppingCartAddOrRemoveOrderContext,
    )

    const addOrderWithTracking = useCallback(
      (order: ShoppingCartContextProvider_inline_order$data) => {
        addOrder(order)
        trackAddToCart()
      },
      [addOrder, trackAddToCart],
    )
    const removeOrderWithTracking = useCallback(
      (orderRelayId: string) => {
        removeOrder(orderRelayId)
        trackRemoveFromCart()
      },
      [removeOrder, trackRemoveFromCart],
    )

    const clearWithTracking = useCallback(() => {
      clear()
      trackClearCart()
    }, [clear, trackClearCart])

    const refreshWithTracking = useCallback(() => {
      refresh()
      trackRefreshCart()
    }, [refresh, trackRefreshCart])

    return {
      addOrder: addOrderWithTracking,
      removeOrder: removeOrderWithTracking,
      clear: clearWithTracking,
      refresh: refreshWithTracking,
    }
  }

export const useShoppingCartLocalErrors = () =>
  useContextSelector(ShoppingCartContext, value => value.localErrors)

export const readShoppingCartOrder =
  inlineFragmentize<ShoppingCartContextProvider_inline_order$data>(
    graphql`
      fragment ShoppingCartContextProvider_inline_order on OrderV2Type @inline {
        relayId
        makerOwnedQuantity
        # eslint-disable-next-line relay/unused-fields we're using this
        item {
          chain {
            identifier
          }
          relayId
          ... on AssetBundleType {
            assetQuantities(first: 30) {
              edges {
                node {
                  asset {
                    relayId
                  }
                }
              }
            }
          }
        }
        maker {
          relayId
        }
        # eslint-disable-next-line relay/unused-fields used for private listing checks
        taker {
          address
          # eslint-disable-next-line relay/must-colocate-fragment-spreads used for private active account listing checks
          ...wallet_accountKey
        }
        priceType {
          usd
        }
        # eslint-disable-next-line relay/unused-fields we're using this
        payment {
          relayId
        }
        remainingQuantityType
        ...useTotalItems_orders
        # eslint-disable-next-line relay/must-colocate-fragment-spreads The access patterns here make it hard to colocate the fragment
        ...ShoppingCart_orders
      }
    `,
    identifiers => identifiers,
  )

const readShoppingCartError =
  inlineFragmentize<ShoppingCartContextProvider_inline_error$data>(
    graphql`
      fragment ShoppingCartContextProvider_inline_error on BulkPurchaseErrorType
      @inline {
        originalOrder {
          relayId
        }
        updatedOrder {
          ...ShoppingCartContextProvider_inline_order
        }
        reason
        # eslint-disable-next-line relay/must-colocate-fragment-spreads The access patterns here make it hard to colocate the fragment
        ...ShoppingCart_errors
      }
    `,
    identifiers => ({ ...identifiers, updatedOrder: identifiers.updatedOrder }),
  )
