/**
 * @generated SignedSource<<3484e0c7f631cd3690bf7c7d021a1a5a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateBulkOrderAction_data$data = {
  readonly __typename: "CreateBulkOrderActionType";
  readonly orderDatas: ReadonlyArray<{
    readonly item: {
      readonly asset?: {
        readonly " $fragmentSpreads": FragmentRefs<"StackedAssetMedia_assets">;
      };
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"useTotalItems_ordersData" | "useTotalPriceOrderData_orderData">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_create_bulk_order">;
  readonly " $fragmentType": "CreateBulkOrderAction_data";
};
export type CreateBulkOrderAction_data$key = {
  readonly " $data"?: CreateBulkOrderAction_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateBulkOrderAction_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateBulkOrderAction_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderDataType",
      "kind": "LinkedField",
      "name": "orderDatas",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "item",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetType",
                  "kind": "LinkedField",
                  "name": "asset",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "StackedAssetMedia_assets"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "AssetQuantityDataType",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useTotalItems_ordersData"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useTotalPriceOrderData_orderData"
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useHandleBlockchainActions_create_bulk_order",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SignAndPostBulkType",
          "kind": "LinkedField",
          "name": "method",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "clientMessage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "clientSignatureStandard",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "serverSignature",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "orderDatas",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ChainType",
              "kind": "LinkedField",
              "name": "chain",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "identifier",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "CreateBulkOrderActionType",
  "abstractKey": null
};

(node as any).hash = "8eb2300b1ee52200c559db46adf6792f";

export default node;
