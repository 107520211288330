/* eslint-disable tailwindcss/no-custom-classname */
import React, { ReactNode, useCallback, useMemo, useState } from "react"
import {
  useIsHydrated,
  List,
  Text,
  useIsLessThanSm,
  Spinner,
} from "@opensea/ui-kit"
import { noop, orderBy } from "lodash"
import styled, { css } from "styled-components"
import { ExternalLink } from "@/components/common/ExternalLink"
import { LEARN_URL } from "@/constants"
import {
  getWalletConfiguration,
  WALLET_NAME,
  WalletConfiguration,
  WalletSupport,
} from "@/constants/wallet"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { Tooltip } from "@/design-system/Tooltip"
import { useChains } from "@/hooks/useChains"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useRouter } from "@/hooks/useRouter"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { trackSelectWallet } from "@/lib/analytics/events/walletEvents"
import chain from "@/lib/chain/chain"
import { getSolanaChain } from "@/lib/helpers/chainUtils"
import { getDeviceOSClient } from "@/lib/helpers/layout"
import { escapeRelativeUrl } from "@/lib/helpers/urls"
import { getMobileWalletLink } from "@/lib/helpers/wallet"
import { $nav_height } from "@/styles/variables"
import { useWallets } from "./useWallets"

const FEW_WALLET_DISPLAY_COUNT = 6
const VIEW_ALL_BUTTON_TEST_ID = "view-all-wallets"

type WalletAnnotationPillVariant =
  | "Popular"
  | "Solana"
  | "Avalanche"
  | "BNB Chain"
  | "Klaytn"
  | null

type WalletItemAction =
  | { href: string }
  | Pick<JSX.IntrinsicElements["i"], "onClick" | "onKeyPress">

type RenderWalletProps = {
  disabled: boolean
  actionProps: WalletItemAction
  logo?: string
  walletName: WALLET_NAME
  annotation?: ReactNode | null
  annotationPill?: ReactNode | null
  supportedOnPlatform: boolean
  installingWallet?: string
  notSupportedText: string
}

export type ConnectWalletSource =
  | "wallet page"
  | "wallet sidebar"
  | "wallet modal"
  | "network unsupported gate"

export type ConnectCompatibleWalletProps = {
  source: ConnectWalletSource
  chainIdentifier?: ChainIdentifier
  onInstalled?: (walletName: WALLET_NAME) => unknown
  showWalletAnnotation?: boolean
  defaultShowAllOptions?: boolean
  defaultShownWalletCount?: number
  renderList?: (wallets: Array<RenderWalletProps>) => ReactNode
  renderShowMore?: (_: {
    toggleShowMore: () => void
    showingAll: boolean
    label: string
    "data-testid": string
  }) => ReactNode
  customHeader?: ReactNode
  priorityWallets?: Array<WALLET_NAME>
}

export const ConnectCompatibleWallet = ({
  chainIdentifier,
  source,
  onInstalled,
  showWalletAnnotation = true,
  defaultShowAllOptions = false,
  defaultShownWalletCount = FEW_WALLET_DISPLAY_COUNT,
  renderList,
  renderShowMore,
  customHeader,
  priorityWallets,
}: ConnectCompatibleWalletProps) => {
  const t = useTranslate("components")
  const router = useRouter()
  const isMobile = useIsLessThanSm()
  const isHydrated = useIsHydrated()
  const deviceOS = isHydrated ? getDeviceOSClient() : undefined

  const { wallet } = useWallet()
  const [showAllOptions, setShowAllOptions] = useState(defaultShowAllOptions)
  const [installingWallet, setInstallingWallet] = useState<string>()
  const { attempt } = useToasts()
  const { wallets } = useWallets()
  const { getChain } = useChains()

  const isWalletSupportedOnPlatform = useCallback(
    ({ supports }: WalletConfiguration) => {
      if (isMobile) {
        if (deviceOS === "iOS") {
          return supports.includes(WalletSupport.IOS)
        } else if (deviceOS === "Android") {
          return supports.includes(WalletSupport.ANDROID)
        }
        return (
          supports.includes(WalletSupport.ANDROID) ||
          supports.includes(WalletSupport.IOS)
        )
      }

      return supports.includes(WalletSupport.DESKTOP)
    },
    [isMobile, deviceOS],
  )

  const trackWalletSelected = (walletName: WALLET_NAME) => {
    trackSelectWallet({ walletName, source })
  }

  const connectWalletAndMaybeRedirect = async (walletName: WALLET_NAME) => {
    if (installingWallet) {
      return
    }

    trackWalletSelected(walletName)
    setInstallingWallet(walletName)
    try {
      await attempt(async () => {
        await wallet.install(walletName)
        onInstalled?.(walletName)

        const isLoginPage = source === "wallet page"

        // Redirect after connecting wallet if on login page
        if (isLoginPage) {
          let referrer = router.query.referrer as string | undefined
          if (!referrer) {
            if (router.asPathWithoutQuery !== "/login") {
              // After a server side redirect to login page, sometimes we see the path
              // redirected from remains in the URL despite the login page showing.
              // This is a workaround that assumes the URL path is the expected referrer
              // if the login page is rendered with URL path other than /login.
              referrer = router.asPath
            } else {
              // Use account page as default referrer if on login page
              referrer = "/account"
            }
          }
          await router.replace(escapeRelativeUrl(referrer))
        }
      })
    } finally {
      setInstallingWallet(undefined)
    }
  }

  const getWalletAction = (
    walletName: WALLET_NAME,
    { installLink }: WalletConfiguration,
  ): WalletItemAction => {
    const isProviderInstalled = chain.isProviderInstalled(walletName)

    if (isProviderInstalled) {
      return { onClick: () => connectWalletAndMaybeRedirect(walletName) }
    } else if (isMobile) {
      const mobileLink = getMobileWalletLink(walletName, router)
      if (mobileLink) {
        return {
          href: mobileLink,
          onClick: () => trackWalletSelected(walletName),
        }
      }
    } else if (installLink) {
      return {
        href: installLink,
        onClick: () => trackWalletSelected(walletName),
      }
    }

    return { onClick: () => connectWalletAndMaybeRedirect(walletName) }
  }

  const { shownWallets, supportedWallets } = useMemo(() => {
    let supportedWallets = wallets

    if (chainIdentifier) {
      supportedWallets = supportedWallets.filter(wallet =>
        getWalletConfiguration(wallet).supportsChain(getChain(chainIdentifier)),
      )
    }

    supportedWallets = orderBy(
      supportedWallets,
      walletName =>
        isWalletSupportedOnPlatform(getWalletConfiguration(walletName)),
      ["desc"],
    )
    if (priorityWallets) {
      const otherWallets = supportedWallets.filter(
        item => !priorityWallets.includes(item),
      )
      supportedWallets = [...priorityWallets, ...otherWallets]
    }

    const shownWallets = supportedWallets.slice(
      0,
      showAllOptions ? undefined : defaultShownWalletCount,
    )

    return { shownWallets, supportedWallets }
  }, [
    wallets,
    chainIdentifier,
    priorityWallets,
    showAllOptions,
    getChain,
    isWalletSupportedOnPlatform,
  ])

  const getRenderWalletProps = (walletName: WALLET_NAME): RenderWalletProps => {
    const configuration = getWalletConfiguration(walletName)
    const supportedOnPlatform = isWalletSupportedOnPlatform(configuration)

    const notSupportedText = () => {
      const { supports } = configuration
      if (
        !supports.includes(WalletSupport.IOS) &&
        !supports.includes(WalletSupport.ANDROID)
      ) {
        return t("connectCompatibleWallet.desktopOnly", "desktop only")
      } else if (!supports.includes(WalletSupport.DESKTOP)) {
        return t("connectCompatibleWallet.mobileOnly", "mobile only")
      }
      return ""
    }

    const annotationType: WalletAnnotationPillVariant =
      walletName === WALLET_NAME.MetaMask
        ? "Popular"
        : walletName === WALLET_NAME.Core
        ? "Avalanche"
        : walletName === WALLET_NAME.BitKeep
        ? "BNB Chain"
        : walletName === WALLET_NAME.Kaikas
        ? "Klaytn"
        : // Hardcoding phantom to show nothing for now as it supports both evm and solana
        walletName === WALLET_NAME.Phantom
        ? null
        : getWalletConfiguration(walletName).supportsChain(
            getChain(getSolanaChain()),
          )
        ? "Solana"
        : null

    const annotation =
      annotationType === "Popular"
        ? t("connectCompatibleWallet.popularTag", "Popular")
        : annotationType === "Solana"
        ? t("connectCompatibleWallet.solanaTag", "Solana")
        : annotationType === "Avalanche"
        ? t("connectCompatibleWallet.avalancheTag", "Avalanche")
        : annotationType === "BNB Chain"
        ? t("connectCompatibleWallet.bnbChainTag", "BNB Chain")
        : annotationType === "Klaytn"
        ? t("connectCompatibleWallet.klaytnTag", "Klaytn")
        : null

    return {
      disabled: Boolean(installingWallet) || !supportedOnPlatform,
      actionProps: {
        ...(supportedOnPlatform
          ? getWalletAction(walletName, configuration)
          : { onClick: noop }),
      },
      logo: configuration.alternativeLogo,
      walletName,
      annotation:
        showWalletAnnotation && supportedOnPlatform ? annotation : null,
      annotationPill:
        showWalletAnnotation && supportedOnPlatform && annotation ? (
          <List.Item.Side>
            <List.Item.Description>
              <WalletAnnotationPill $variant={annotationType}>
                {annotation}
              </WalletAnnotationPill>
            </List.Item.Description>
          </List.Item.Side>
        ) : null,
      supportedOnPlatform,
      notSupportedText: notSupportedText(),
      installingWallet,
    }
  }

  const renderWallet = ({
    disabled,
    actionProps,
    logo,
    walletName,
    annotationPill,
    notSupportedText,
    installingWallet,
    supportedOnPlatform,
  }: RenderWalletProps) => (
    <List.Item disabled={disabled} key={walletName} {...actionProps}>
      <List.Item.Avatar alt="" height="30px" src={logo ?? ""} />
      <List.Item.Content>
        <List.Item.Title>{walletName}</List.Item.Title>
      </List.Item.Content>
      {walletName !== installingWallet && annotationPill}
      {!supportedOnPlatform && (
        <List.Item.Side>
          <List.Item.Description>{notSupportedText}</List.Item.Description>
        </List.Item.Side>
      )}

      {walletName === installingWallet && (
        <List.Item.Side className="ml-1">
          <Spinner />
        </List.Item.Side>
      )}
    </List.Item>
  )

  const moreThanDefaultShownWallets =
    supportedWallets.length > defaultShownWalletCount

  const showMoreLabel = showAllOptions
    ? t("connectCompatibleWallet.viewLess", "Less")
    : t("connectCompatibleWallet.viewAll", "More")

  return (
    <DivContainer $moreThanDefaultShownWallets={moreThanDefaultShownWallets}>
      {customHeader ?? (
        <Block>
          <Text className="text-secondary" size="medium">
            {t(
              "connectCompatibleWallet.noWalletYet",
              "If you don't have a {{cryptoTooltip}} yet, you can select a provider and create one now.",
              {
                cryptoTooltip: (
                  <Tooltip
                    content={t(
                      "connectCompatibleWallet.walletDefinition",
                      "A crypto wallet is an application or hardware device that allows individuals to store and retrieve digital items.",
                    )}
                    interactive
                    placement="bottom-end"
                  >
                    <ExternalLink
                      href={`${LEARN_URL}/what-is-crypto-wallet`}
                      variant="subtle"
                    >
                      {t("connectCompatibleWallet.wallet", "wallet")}
                    </ExternalLink>
                  </Tooltip>
                ),
              },
            )}
          </Text>
        </Block>
      )}
      {renderList ? (
        <>
          {renderList(shownWallets.map(getRenderWalletProps))}
          {renderShowMore?.({
            toggleShowMore: () => setShowAllOptions(prev => !prev),
            showingAll: showAllOptions,
            label: showMoreLabel,
            "data-testid": VIEW_ALL_BUTTON_TEST_ID,
          })}
        </>
      ) : (
        <Block marginBottom={$nav_height} marginTop="24px">
          <>
            <List className="ConnectCompatibleWallet--wallet-list">
              {shownWallets.map(getRenderWalletProps).map(renderWallet)}
            </List>
            {moreThanDefaultShownWallets && (
              // TODO @naama: Change back to Button when List/Item global design changes are updated
              <List.Item
                className="ConnectCompatibleWallet--show-more"
                data-testid={VIEW_ALL_BUTTON_TEST_ID}
                onClick={() => setShowAllOptions(prev => !prev)}
              >
                {showMoreLabel}
              </List.Item>
            )}
          </>
        </Block>
      )}
    </DivContainer>
  )
}

const DivContainer = styled(Block)<{ $moreThanDefaultShownWallets: boolean }>`
  ${props =>
    props.$moreThanDefaultShownWallets &&
    css`
      .ConnectCompatibleWallet--wallet-list {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
      }
    `}

  .ConnectCompatibleWallet--show-more {
    width: 100%;
    border-radius: 0;
    border-bottom-left-radius: ${props => props.theme.borderRadius.default};
    border-bottom-right-radius: ${props => props.theme.borderRadius.default};
    // TODO @naama: Remove when List/Item global design changes are updated
    font-size: 16px;
    justify-content: center;
  }
`

const WalletAnnotationPill = styled(Block)<{
  $variant: WalletAnnotationPillVariant
}>`
  ${props =>
    props.$variant === "Popular"
      ? css`
          background-color: ${props => props.theme.colors.seaBlue};
          color: ${props => props.theme.colors.white};
        `
      : css`
          background-color: ${props => props.theme.colors.fog};
          color: ${props => props.theme.colors.text.secondary};
        `}

  border-radius: ${props => props.theme.borderRadius.default};
  padding: 4px 8px;
`
