/* eslint-disable tailwindcss/no-custom-classname */
import React, { CSSProperties } from "react"
import { Flex } from "@opensea/ui-kit"
import { graphql, _FragmentRefs } from "relay-runtime"
import styled from "styled-components"
import { Overflow } from "@/components/common/Overflow"
import { Block } from "@/design-system/Block"
import { Tooltip } from "@/design-system/Tooltip"
import { TokenPricePayment$data } from "@/lib/graphql/__generated__/TokenPricePayment.graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { BigNumber } from "@/lib/helpers/numberUtils"
import { selectClassNames } from "@/lib/helpers/styling"
import { PriceContainer } from "./styles"
import { getPriceInfo } from "./utils"

export type TokenPriceProps = {
  price: BigNumber
  symbol: string
  className?: string
  isInline?: boolean
  color?: CSSProperties["color"]
  fontWeight?: CSSProperties["fontWeight"]
  position?: CSSProperties["position"]
  symbolVariant?: "raw" | "avatar" | "both"
  compactDisplay?: boolean
  maxDecimalPlaces?: number
  noTooltip?: boolean
}

const readTokenPricePayment = inlineFragmentize<TokenPricePayment$data>(
  graphql`
    fragment TokenPricePayment on PaymentAssetType @inline {
      symbol
    }
  `,
  identifiers => identifiers,
)

export const getTokenPricePayment = (
  ref: TokenPricePayment$data | _FragmentRefs<"TokenPricePayment">,
): Pick<TokenPriceProps, "symbol"> => {
  const payment = readTokenPricePayment(ref)

  return {
    symbol: payment.symbol,
  }
}

// TODO: Clean this component up
export const TokenPrice = React.memo(function TokenPrice({
  price,
  symbol,
  isInline,
  className,
  color,
  fontWeight,
  position,
  symbolVariant = "avatar",
  compactDisplay,
  maxDecimalPlaces,
  noTooltip = false,
}: TokenPriceProps) {
  const { fullPriceDisplay, priceDisplay, isRounded } = getPriceInfo({
    price,
    symbol,
    compactDisplay,
    maxDecimalPlaces,
  })

  const renderPriceContent = (price: string) => {
    if (symbolVariant === "avatar") {
      return <>{price}</>
    }

    return (
      <>
        {price} <span className="Price--raw-symbol">{symbol}</span>
      </>
    )
  }

  return (
    <PriceContainer
      className={selectClassNames(
        "Price",
        { main: true, isInline, isRawSymbol: symbolVariant === "raw" },
        className,
      )}
      color={color}
      fontWeight={fontWeight}
      position={position}
    >
      {noTooltip ? (
        <Flex className="items-center">
          <SingleLineOverflowContainer>
            {priceDisplay}&nbsp;{symbol}
          </SingleLineOverflowContainer>
        </Flex>
      ) : (
        <Tooltip
          content={isRounded ? renderPriceContent(fullPriceDisplay) : undefined}
        >
          <Flex className="items-center">
            <Overflow className="Price--amount">
              {priceDisplay} {symbol}
            </Overflow>
          </Flex>
        </Tooltip>
      )}
    </PriceContainer>
  )
})

const SingleLineOverflowContainer = styled(Block)`
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  align-items: center;
`
