import React, { useCallback, useState } from "react"
import { graphql, useFragment } from "react-relay"
import { CollectionUnreviewedPill } from "@/features/collections/components/CollectionUnreviewedPill.react"
import { useTranslate } from "@/hooks/useTranslate"
import {
  useTrackBlockchainAttempt,
  useTrackBlockchainError,
} from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/hooks"
import { FulfillOrderAction_data$key } from "@/lib/graphql/__generated__/FulfillOrderAction_data.graphql"
import { isSafelisted } from "@/lib/helpers/verification"
import { OrderDataHeader } from "../orders/OrderDataHeader"
import {
  BaseBlockchainActionProps,
  BlockchainActionModalContent,
} from "./BlockchainActionModalContent.react"
import { useBlockchainActionProgress } from "./useBlockchainActionProgress"
import { useHandleBlockchainActions } from "./useHandleBlockchainActions"

type Props = BaseBlockchainActionProps & {
  dataKey: FulfillOrderAction_data$key
}

export const FulfillOrderAction = ({ dataKey, onEnd }: Props) => {
  const t = useTranslate("components")
  const data = useFragment(
    graphql`
      fragment FulfillOrderAction_data on FulfillOrderActionType {
        __typename
        orderData {
          item {
            __typename
            ... on AssetQuantityDataType {
              asset {
                collection {
                  verificationStatus
                  slug
                }
              }
            }
            ... on AssetBundleType {
              collection {
                verificationStatus
                slug
              }
            }
          }
          side
          ...OrderDataHeader_order
        }
        itemFillAmount
        criteriaAsset {
          ...OrderDataHeader_criteriaAsset
        }
        ...useHandleBlockchainActions_fulfill_order
      }
    `,
    dataKey,
  )

  const { fulfillOrder } = useHandleBlockchainActions()
  const [transactionHash, setTransactionHash] = useState<string>()
  const trackBlockchainAttempt = useTrackBlockchainAttempt()
  const [trackBlockchainError, errorTags] = useTrackBlockchainError()
  const purchasedCollection =
    data.orderData.side === "ASK" &&
    data.orderData.item?.__typename !== "%other"
      ? data.orderData.item?.__typename === "AssetQuantityDataType"
        ? data.orderData.item.asset.collection
        : data.orderData.item?.collection
      : undefined
  const showUnverifiedPill =
    data.orderData.side === "ASK" &&
    !isSafelisted(purchasedCollection?.verificationStatus)

  const executeAction = useCallback(async () => {
    trackBlockchainAttempt({ blockchainAction: "coreAction" })
    const transaction = await fulfillOrder(data)
    setTransactionHash(transaction.transactionHash)

    onEnd({ transaction })
  }, [data, fulfillOrder, onEnd, trackBlockchainAttempt])

  const { attemptAction, progress } = useBlockchainActionProgress({
    executeAction,
    action: data,
    onError: trackBlockchainError({ blockchainAction: "coreAction" }),
    errorTags,
  })

  const title =
    data.orderData.side === "ASK"
      ? t("fullfillAction.approve.title", "Approve purchase")
      : t("fullfillAction.acceptOffer.title", "Accept your offer")

  const actionText =
    data.orderData.side === "ASK"
      ? t(
          "blockchain.approve.purchase",
          "You'll be asked to approve this purchase from your wallet.",
        )
      : t(
          "blockchain.fulfill.sale",
          "You'll be asked to review and confirm this sale from your wallet.",
        )

  return (
    <BlockchainActionModalContent>
      <BlockchainActionModalContent.Header>
        <BlockchainActionModalContent.Title>
          {title}
        </BlockchainActionModalContent.Title>
      </BlockchainActionModalContent.Header>
      <BlockchainActionModalContent.Body>
        <OrderDataHeader
          criteriaAsset={data.criteriaAsset ?? undefined}
          dataKey={data.orderData}
          fulfillOrderItemFillAmount={data.itemFillAmount}
          renderAdditionalContentExtra={
            showUnverifiedPill && purchasedCollection?.slug
              ? () => (
                  <CollectionUnreviewedPill
                    collectionSlug={purchasedCollection?.slug}
                  />
                )
              : undefined
          }
          transactionHash={transactionHash}
        />

        <BlockchainActionModalContent.Body.Separator />

        <BlockchainActionModalContent.Body.GoToWallet />
        <BlockchainActionModalContent.Body.Text>
          {actionText}
        </BlockchainActionModalContent.Body.Text>
        {!progress && (
          <BlockchainActionModalContent.Body.Button onClick={attemptAction}>
            {t("blockchain.fulfill.cta", "Continue")}
          </BlockchainActionModalContent.Body.Button>
        )}
      </BlockchainActionModalContent.Body>
    </BlockchainActionModalContent>
  )
}
