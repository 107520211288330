import React, { useState } from "react"
import { useTransaction } from "@/components/blockchain"
import {
  getCancelAllOrdersActions,
  useHandleBlockchainActions,
} from "@/components/blockchain/BlockchainActionList"
import { NetworkUnsupportedGate } from "@/components/modals/NetworkUnsupportedGate"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { Button } from "@/design-system/Button"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import {
  trackBulkCancelFailure,
  trackBulkCancelSuccess,
  trackClickBulkCancelButton,
} from "@/lib/analytics/events/bulkCancelEvents"
import { getEthereumChain } from "@/lib/helpers/chainUtils"
import { BulkCancellationModal } from "./BulkCancellationModal.react"

type BulkCancelExistingOrdersButtonProps = {
  shouldCancelSeaportOrders?: boolean
  disabled?: boolean
}

export const BulkCancelOrdersButton = ({
  shouldCancelSeaportOrders,
  disabled,
}: BulkCancelExistingOrdersButtonProps) => {
  const t = useTranslate("settings")
  const connectedAddress = useConnectedAddress()

  const [isBulkCancellationDone, setIsBulkCancellationDone] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [numTransactions, setNumTransactions] = useState(1)
  const { showSuccessMessage } = useToasts()
  const { cancelAllOrders } = useHandleBlockchainActions()
  const { pollTransaction } = useTransaction()

  const shouldShowCancelButton = !isBulkCancellationDone

  const bulkCancelExistingOrders = async () => {
    const accountAddress = connectedAddress
    trackClickBulkCancelButton({ address: accountAddress })
    setShowModal(true)
    try {
      if (shouldCancelSeaportOrders) {
        const chain = getEthereumChain()
        const actions = await getCancelAllOrdersActions(chain)
        setNumTransactions(actions.length)

        for (let i = 0; i < actions.length; i++) {
          const transaction = await cancelAllOrders(actions[i])
          if (i == actions.length - 1) {
            await pollTransaction(transaction)
          }
        }
      }

      trackBulkCancelSuccess({ address: accountAddress })
      setShowModal(false)
      showSuccessMessage(
        t("bulkCancel.cancelSuccess", "All listings and offers cancelled"),
      )
      setIsBulkCancellationDone(true)
    } catch (error) {
      trackBulkCancelFailure({ address: accountAddress })
      setShowModal(false)
    }
  }

  return (
    <>
      {shouldShowCancelButton ? (
        <>
          <NetworkUnsupportedGate chainIdentifier={getEthereumChain()}>
            {({ handleIfNotSupported }) => (
              <Button
                className="mr-5 mt-5"
                disabled={disabled}
                onClick={handleIfNotSupported(() => bulkCancelExistingOrders())}
              >
                {t("bulkCancel.cancelCTA", "Cancel all listings and offers")}
              </Button>
            )}
          </NetworkUnsupportedGate>
        </>
      ) : null}
      <BulkCancellationModal
        isOpen={showModal}
        numTransactions={numTransactions}
        onClose={() => setShowModal(false)}
      />
    </>
  )
}
