import React, { useState } from "react"
import { Media } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { BulkPurchaseModal } from "@/components/trade/BulkPurchaseModal"
import { CheckoutGiftRecipient } from "@/components/trade/CheckoutModal/components/CheckoutGiftRecipientInput.react"
import { PaymentOption } from "@/components/trade/CheckoutModal/components/PaymentMethod"
import { Z_INDEX } from "@/constants/zIndex"
import { Drawer } from "@/design-system/Drawer"
import { useAnnouncementBanner } from "@/features/announcement-banner/utils/context"
import {
  useShoppingCartAddOrRemoveOrders,
  useShoppingCartErrors,
  useShoppingCartOrderToQuantity,
  useShoppingCartOrders,
  useShoppingCartViewActions,
  useShoppingCartViewIsOpen,
} from "@/features/shopping-cart/utils/ShoppingCartContextProvider"
import { ShoppingCart_errors$key } from "@/lib/graphql/__generated__/ShoppingCart_errors.graphql"
import { ShoppingCart_orders$key } from "@/lib/graphql/__generated__/ShoppingCart_orders.graphql"
import { ShoppingCartDetailedView } from "./ShoppingCartDetailedView.react"

export const SHOPPING_CART_WIDTH = 375

export type ShoppingCartProps = {
  children: React.ReactElement
  onShoppingCartSidebarClickAway: (e: MouseEvent | TouchEvent) => unknown
}

export const ShoppingCart = ({
  children,
  onShoppingCartSidebarClickAway,
}: ShoppingCartProps) => {
  const orderDataKeys = useShoppingCartOrders()
  const errorDataKeys = useShoppingCartErrors()
  const orderToQuantity = useShoppingCartOrderToQuantity()
  const isOpen = useShoppingCartViewIsOpen()
  const { close } = useShoppingCartViewActions()
  const { clear } = useShoppingCartAddOrRemoveOrders()
  const { visibleAnnouncementBannerHeight } = useAnnouncementBanner()
  const [paymentMethod, setPaymentMethod] = useState<PaymentOption>("crypto")

  const orders = useFragment(
    graphql`
      fragment ShoppingCart_orders on OrderV2Type @relay(plural: true) {
        ...ShoppingCartDetailedView_orders
        ...BulkPurchaseModal_orders
      }
    `,
    orderDataKeys as ShoppingCart_orders$key,
  )

  const errors = useFragment(
    graphql`
      fragment ShoppingCart_errors on BulkPurchaseErrorType
      @relay(plural: true) {
        ...ShoppingCartDetailedView_errors
      }
    `,
    errorDataKeys as ShoppingCart_errors$key,
  )

  const [giftRecipient, setGiftRecipient] = useState<CheckoutGiftRecipient>({
    address: "",
    destination: "",
    isEmpty: true,
    isValid: false,
  })

  const drawerOffsetPx = "24px"

  const shoppingCartDetailedView = isOpen && (
    <ShoppingCartDetailedView
      errors={errors}
      giftRecipient={giftRecipient}
      orders={orders}
      paymentMethod={paymentMethod}
      setGiftRecipient={setGiftRecipient}
      setPaymentMethod={setPaymentMethod}
    />
  )

  return (
    <BulkPurchaseModal
      fillType="CART"
      giftRecipientAddress={giftRecipient.address}
      orderToQuantity={orderToQuantity}
      orders={orders}
      paymentMethod={paymentMethod}
      onPurchased={() => {
        close()
        clear()
      }}
    >
      <Media greaterThanOrEqual="lg">
        {children}
        <Drawer
          bannerHeight={visibleAnnouncementBannerHeight}
          className="bottom-12 rounded-2xl"
          isOpen={isOpen}
          overrides={{
            Overlay: { props: { style: { zIndex: Z_INDEX.MODAL - 1 } } },
          }}
          style={{
            zIndex: Z_INDEX.MODAL,
            right: isOpen ? drawerOffsetPx : 0,
            top: drawerOffsetPx,
          }}
          transitionDuration={0.2}
          width={SHOPPING_CART_WIDTH}
          onClickAway={onShoppingCartSidebarClickAway}
        >
          <Drawer.Body padding="0px">{shoppingCartDetailedView}</Drawer.Body>
        </Drawer>
      </Media>
      <Media lessThan="lg">
        {children}
        {shoppingCartDetailedView}
      </Media>
    </BulkPurchaseModal>
  )
}
