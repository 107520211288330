import React from "react"
import { CenterAligned } from "@opensea/ui-kit"
import { rgba } from "polished"
import { useFragment } from "react-relay"
import styled from "styled-components"
import {
  RarityIndicator,
  RarityIndicatorVariant,
} from "@/components/search/rarity/RarityIndicator.react"
import { useIsRarityEnabled } from "@/components/search/rarity/useIsRarityEnabled"
import { Block } from "@/design-system/Block"
import { AssetMediaContainer_asset$key } from "@/lib/graphql/__generated__/AssetMediaContainer_asset.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { themeVariant } from "@/styles/styleUtils"
import { AssetMediaEditions } from "./AssetMediaEditions.react"
import { asBackgroundColor } from "./helpers"

type AssetMediaContainerProps = {
  asset: AssetMediaContainer_asset$key
  children: React.ReactNode
  className?: string
  title?: string
  showAssetMediaEditions?: boolean
  rarityIndicator?: RarityIndicatorVariant
}

export const AssetMediaContainer = ({
  asset: assetKey,
  className,
  title,
  showAssetMediaEditions = false,
  rarityIndicator,
  children,
}: AssetMediaContainerProps) => {
  const asset = useFragment(
    graphql`
      fragment AssetMediaContainer_asset on AssetType
      @argumentDefinitions(
        identity: { type: "IdentityInputType", defaultValue: {} }
        showQuantity: { type: "Boolean", defaultValue: false }
        showRarity: { type: "Boolean", defaultValue: false }
      ) {
        backgroundColor
        ...AssetMediaEditions_asset
          @arguments(identity: $identity, showQuantity: $showQuantity)
        defaultRarityData @include(if: $showRarity) {
          ...RarityIndicator_data
        }
        collection {
          ...useIsRarityEnabled_collection
        }
      }
    `,
    assetKey,
  )

  const backgroundColor = asBackgroundColor(asset.backgroundColor)

  const isRarityDisplayed = useIsRarityEnabled(asset.collection)
  const rarityData = asset.defaultRarityData

  return (
    <DivContainer className={className} title={title}>
      {showAssetMediaEditions && <AssetMediaEditions asset={asset} />}
      {rarityIndicator && isRarityDisplayed && rarityData && (
        <RarityIndicatorContainer>
          <RarityIndicator dataKey={rarityData} variant={rarityIndicator} />
        </RarityIndicatorContainer>
      )}
      <CenterAligned
        className="relative h-full min-h-[inherit] w-full rounded-[inherit]"
        style={{
          backgroundColor,
        }}
      >
        {children}
      </CenterAligned>
    </DivContainer>
  )
}

const DivContainer = styled.div`
  min-height: inherit;
  border-radius: inherit;
  height: 100%;
  width: 100%;
  position: relative;

  .AssetMedia--animation {
    position: relative;
    min-height: inherit;
    max-height: 100%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    border-radius: inherit;

    .AssetMedia--playback-wrapper {
      height: 100%;
      max-width: 100%;
      width: 100%;
      position: relative;
      justify-content: center;
      border-radius: inherit;

      .AssetMedia--audio {
        width: 100%;
        outline: none;
      }

      .AssetMedia--video {
        height: 100%;
        width: 100%;
      }

      > img {
        border-radius: inherit;
      }
    }
  }

  .AssetMedia--play-icon,
  .AssetMedia--play-shadow {
    position: absolute;
  }

  .AssetMedia--play-icon {
    align-items: center;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.components.border.level2};
    display: flex;
    height: 44px;
    justify-content: center;
    bottom: 8px;
    right: 8px;
    width: 44px;
    z-index: 1;

    ${props =>
      themeVariant({
        variants: {
          light: {
            color: props.theme.colors.charcoal,
            backgroundColor: props.theme.colors.white,
            "&:hover": {
              color: rgba(props.theme.colors.charcoal, 0.8),
            },
          },
          dark: {
            color: props.theme.colors.fog,
            backgroundColor: props.theme.colors.oil,
            "&:hover": {
              color: props.theme.colors.white,
              backgroundColor: props.theme.colors.ash,
            },
          },
        },
      })}

    &:hover {
      box-shadow: ${props =>
        props.theme.colors.components.elevation.level1.regular.shadow};
    }
  }

  .AssetMedia--play-shadow {
    width: 26px;
    height: 26px;
    right: 11px;
    bottom: 11px;
    border-radius: 50%;
    box-shadow: ${props =>
      props.theme.colors.components.elevation.level1.regular.shadow};
    pointer-events: none;
  }

  .AssetMedia--invert {
    filter: grayscale(1) invert(1);
  }
`
const RarityIndicatorContainer = styled(Block)`
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-8px, 8px);
`
