/**
 * @generated SignedSource<<c5649e8b39bbb2e338aab6f56fbe7055>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type walletCheckQuery$variables = {};
export type walletCheckQuery$data = {
  readonly accountHelpers: {
    readonly checkAccount: boolean;
  };
};
export type walletCheckQuery = {
  response: walletCheckQuery$data;
  variables: walletCheckQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AccountHelpersType",
    "kind": "LinkedField",
    "name": "accountHelpers",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "checkAccount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "walletCheckQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "walletCheckQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b638defb067050d37a9ae6cadf6b2392",
    "id": null,
    "metadata": {},
    "name": "walletCheckQuery",
    "operationKind": "query",
    "text": "query walletCheckQuery {\n  accountHelpers {\n    checkAccount\n  }\n}\n"
  }
};
})();

(node as any).hash = "b2159d12c0807b5798e412d82619f2cc";

export default node;
