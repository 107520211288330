import { legacyTrackWithGlobalParams } from "./amplitude/legacyTrackWithGlobalParams"

/**
 * @deprecated Use TrackingContexts and useTrackingFn
 */
export class AnalyticsTracker<
  T extends Record<string, unknown> = Record<string, unknown>,
> {
  constructor(private params: T) {}

  public mergeParams(otherParams: Partial<T>): T {
    this.params = { ...this.params, ...otherParams }
    return this.params
  }

  protected track(
    eventName: string,
    extraParams?: Partial<T> & Record<string, unknown>,
  ): void {
    const params = extraParams ? this.mergeParams(extraParams) : this.params
    legacyTrackWithGlobalParams(eventName, params)
  }
}
