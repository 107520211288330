import React, { useMemo } from "react"
import { WALLET_NAME } from "@/constants/wallet"
import {
  useActiveAccount,
  useWallet,
} from "@/containers/WalletProvider/WalletProvider.react"
import { ChainIdentifier } from "@/hooks/useChains/types"
import chainModule from "@/lib/chain/chain"
import { TrackingContextProvider } from "../TrackingContext.react"

type LockedWalletContext = {
  connected: false
  walletsPresent: string[]
}

type ConnectedWalletContext = {
  chain: ChainIdentifier
  metadata: string | undefined
  name: WALLET_NAME
  connected: true
  isBanned: boolean | undefined
  walletsPresent: string[]
}

type WalletContext = LockedWalletContext | ConnectedWalletContext

export type WalletTrackingContext = {
  Wallet: WalletContext | undefined // undefined when no wallet is detected
}

type WalletTrackingContextProviderProps = {
  children: React.ReactNode
}

export const WalletTrackingContextProvider = ({
  children,
}: WalletTrackingContextProviderProps) => {
  const activeAccount = useActiveAccount()
  const activeAddress = activeAccount?.address

  const { chain: activeChain, provider: activeProvider } = useWallet()

  const isBanned = activeAccount?.metadata?.isBanned

  const properties: WalletContext | undefined = useMemo(() => {
    if (!activeChain || !activeProvider) {
      return {
        connected: false,
        walletsPresent: chainModule.providers.map(provider =>
          provider.getName(),
        ),
      }
    }

    return {
      connected: true,
      isBanned,
      address: activeAddress,
      chain: activeChain,
      name: activeProvider.getName(),
      metadata: activeProvider.getMetadata(),
      walletsPresent: chainModule.providers.map(provider => provider.getName()),
    }
  }, [activeAddress, activeChain, activeProvider, isBanned])

  return (
    <TrackingContextProvider name="Wallet" properties={properties}>
      {children}
    </TrackingContextProvider>
  )
}
