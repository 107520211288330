import React from "react"
import { Spinner } from "@opensea/ui-kit"
import styled from "styled-components"

type Props = {
  className?: string
}

export const ModalLoader = ({ className }: Props) => {
  return (
    <DivContainer className={className}>
      <Spinner />
    </DivContainer>
  )
}

const DivContainer = styled.div`
  width: 100%;
  min-width: 300px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
`
