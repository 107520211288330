import type { ServerResponse } from "http"
import { generateNonce, getCtxNonce } from "@next-safe/middleware/dist/document"
import { IS_PRODUCTION } from "@/constants/environment"

const CSP_NONCE_HEADER = "csp-nonce"

export const getCreateServerResponseNonceIdempotent = (
  res: ServerResponse | undefined,
): string => {
  if (!IS_PRODUCTION || !res) {
    return ""
  }
  let _nonce = getCtxNonce({ res })
  if (!_nonce) {
    _nonce = generateNonce()
    if (res.headersSent) {
      console.warn(
        "Attempt to update response headers that have already been sent",
      )
      return _nonce
    }
    res.setHeader(CSP_NONCE_HEADER, _nonce)
  }
  return _nonce
}
