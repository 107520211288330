import React, { useCallback } from "react"
import { MessagePayloadType } from "@opensea/vessel"
import { MessagePayload } from "@opensea/wallet-messages"
import dynamic from "next/dynamic"
import { resolveTokenTransferAction } from "@/actions/transfer"
import { useTransaction } from "@/components/blockchain"
import { useHandleBlockchainActions } from "@/components/blockchain/BlockchainActionList"
import { useGetChainCurrencies } from "@/components/nav/WalletPopover/components/BridgeOrWrapForm/hooks/useChainCurrencies"
import { resolveSwapAction } from "@/components/nav/WalletPopover/components/BridgeOrWrapForm/utils/resolveSwapActions"
import { useRefreshFunds } from "@/components/nav/WalletPopover/components/WalletSwitcher/useRefreshFunds"
import { WALLET_NAME } from "@/constants/wallet"
import {
  useConnectedAddress,
  useWallet,
} from "@/containers/WalletProvider/WalletProvider.react"
import { useOpenMoonPayBuyWidget } from "@/features/moonpay/sdk-components/MoonPayProvider"
import { useChains } from "@/hooks/useChains"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { useGlobalModal } from "@/hooks/useGlobalModal"
import { useToasts } from "@/hooks/useToasts"
import chainModule from "@/lib/chain/chain"
import { bn } from "@/lib/helpers/numberUtils"
import { useEstimateGas } from "../useEstimateGas"
import { useVesselLogger } from "../useVesselLogger"
import { addBreadcrumb } from "@sentry/nextjs"

export type ReplyFn = (payload: MessagePayloadType) => void

export type UseHandleVesselMessageProps = {
  openOSWallet: () => void
  closeOSWallet: () => void
  triggerOSLogin: () => void
  setAllowClickAway: (allow: boolean) => void
}

const AddFundsModalContent = dynamic(
  async () =>
    (
      await import(
        "@/components/trade/AddFundsModal/AddFundsModalContent.react"
      )
    ).AddFundsModalContent,
  {
    ssr: false,
  },
)

export const useHandleVesselMessage = ({
  openOSWallet,
  closeOSWallet,
  triggerOSLogin,
  setAllowClickAway,
}: UseHandleVesselMessageProps) => {
  const vesselLog = useVesselLogger()
  const { getNativeCurrencySymbol, getChain } = useChains()
  const { logout, switchChain, switchProvider, providers, wallet } = useWallet()
  const { attempt, showErrorMessage } = useToasts()
  const estimateGas = useEstimateGas()
  const { transferAsset, swapAsset } = useHandleBlockchainActions()
  const { pollTransaction } = useTransaction()
  const connectedAddress = useConnectedAddress()
  const getChainCurrencies = useGetChainCurrencies()
  const refreshFunds = useRefreshFunds()
  const openMoonPayBuyWidget = useOpenMoonPayBuyWidget()
  const { openModal } = useGlobalModal()

  return useCallback(
    async (payload: MessagePayload, reply: ReplyFn) => {
      try {
        if (payload.type === "event") {
          // Handle any events from child frame here
          switch (payload.event) {
            case "PollTransaction": {
              await pollTransaction({
                transactionHash: payload.transactionHash,
                chain: payload.chain as ChainIdentifier,
              })
              return
            }
            case "Error": {
              showErrorMessage(payload.message)
              break
            }
            default:
              vesselLog("Received unhandled event", payload.event)
          }
          return
        }

        switch (payload.action) {
          case "AddFunds": {
            closeOSWallet()
            const chain = payload.chain as ChainIdentifier | undefined
            openMoonPayBuyWidget(
              chain
                ? {
                    chain,
                    symbol: payload.symbol ?? getNativeCurrencySymbol(chain),
                  }
                : {},
            )
            reply({ success: true })
            return
          }

          case "DepositFunds": {
            closeOSWallet()
            const chain = payload.chain as ChainIdentifier | undefined
            const props = chain
              ? {
                  chain,
                  symbol: payload.symbol ?? getNativeCurrencySymbol(chain),
                }
              : {}
            openModal(<AddFundsModalContent {...props} />, {})
            reply({ success: true })
            return
          }

          case "SwitchChain": {
            const chain = payload.chain as ChainIdentifier
            await attempt(async () => {
              await switchChain(getChain(chain))
            })
            // Reply with success even if chain switching fails since error is handled by attempt
            reply({ success: true })
            return
          }

          case "SwitchWallet": {
            const walletName = payload.walletName as WALLET_NAME
            const provider = providers.find(
              provider => provider.getName() === walletName,
            )
            await attempt(async () => {
              if (!provider) {
                throw new Error(`Could not find ${walletName} provider`)
              }
              await switchProvider(provider)
            })
            // Reply with success even if chain switching fails since error is handled by attempt
            reply({ success: true })
            return
          }

          case "InstallWallet": {
            const walletName = payload.walletName as WALLET_NAME
            await attempt(async () => {
              if (!Object.values(WALLET_NAME).includes(walletName)) {
                throw new Error(`Invalid wallet name ${walletName}`)
              }
              // addProvider rather than wallet.install since install requires connecting after being logged in.
              await chainModule.addProvider(walletName)
            })
            // Reply with success even if wallet install fails since error is handled by attempt
            reply({ success: true })
            return
          }

          case "RequestLogin": {
            triggerOSLogin()
            reply({ success: true })
            return
          }

          case "Logout": {
            closeOSWallet()
            await logout()
            reply({ success: true })
            return
          }

          case "EstimateGas": {
            await estimateGas(payload, reply)
            return
          }

          case "Transfer": {
            if (!connectedAddress) {
              throw new Error("Cannot transfer without connected address")
            }
            const [transferTransaction, dispose] =
              await resolveTokenTransferAction({
                sender: connectedAddress,
                recipient: payload.toAddress,
                chain: payload.chain as ChainIdentifier,
                symbol: payload.symbol,
                quantity: payload.quantity,
              })
            const transaction = await transferAsset(transferTransaction)
            dispose()
            reply({
              success: true,
              transactionHash: transaction.transactionHash,
              blockExplorerLink: transaction.blockExplorerLink,
            })
            await pollTransaction(transaction)
            return
          }

          case "Wrap": {
            const { baseCurrency, wrappedCurrency } = getChainCurrencies(
              payload.chain as ChainIdentifier,
            )
            const [swapAction, dispose] = await resolveSwapAction({
              baseCurrency,
              swapCurrency: wrappedCurrency,
              quantity: payload.quantity,
              direction: "out",
            })
            const transaction = await swapAsset(swapAction)
            dispose()
            reply({
              success: true,
              transactionHash: transaction.transactionHash,
              blockExplorerLink: transaction.blockExplorerLink,
            })
            await pollTransaction(transaction)
            return
          }

          case "Unwrap": {
            const { baseCurrency, wrappedCurrency } = getChainCurrencies(
              payload.chain as ChainIdentifier,
            )
            const [swapAction, dispose] = await resolveSwapAction({
              baseCurrency,
              swapCurrency: wrappedCurrency,
              quantity: payload.quantity,
              direction: "in",
            })
            const transaction = await swapAsset(swapAction)
            dispose()
            reply({
              success: true,
              transactionHash: transaction.transactionHash,
              blockExplorerLink: transaction.blockExplorerLink,
            })
            await pollTransaction(transaction)
            return
          }

          case "ShowWallet": {
            openOSWallet()
            reply({ success: true })
            return
          }

          case "HideWallet": {
            closeOSWallet()
            reply({ success: true })
            return
          }

          case "RefreshFunds": {
            await refreshFunds()
            reply({ success: true })
            return
          }

          case "SetClickAway":
            setAllowClickAway(payload.allowed)
            reply({ success: true })
            return

          case "PromptTransaction": {
            const { to, value, data } = payload.params
            addBreadcrumb({
              category: "Blockchain",
              message: "PromptTransaction",
              data: {
                to: to,
                value: value,
                data: data,
              },
            })
            const transaction = await wallet.transact({
              destination: to,
              value: value ? bn(value) : undefined,
              data,
            })
            reply({ transactionHash: transaction.hash, success: true })
            return
          }
          default:
            vesselLog("Received unhandled action", payload.action)
        }
      } catch (error) {
        reply({ success: false })
      }
    },
    [
      vesselLog,
      pollTransaction,
      showErrorMessage,
      setAllowClickAway,
      closeOSWallet,
      openMoonPayBuyWidget,
      getNativeCurrencySymbol,
      openModal,
      attempt,
      switchChain,
      getChain,
      providers,
      switchProvider,
      triggerOSLogin,
      logout,
      estimateGas,
      connectedAddress,
      transferAsset,
      getChainCurrencies,
      swapAsset,
      openOSWallet,
      refreshFunds,
      wallet,
    ],
  )
}
