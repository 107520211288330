import { useEffect } from "react"
import { isAfter, isBefore, parseJSON } from "date-fns"
import { isEmpty, sortBy } from "lodash"
import { useUpdate } from "react-use"
import type { _FragmentRefs } from "relay-runtime"
import { useDropStages$data } from "@/lib/graphql/__generated__/useDropStages.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"

const readStage = inlineFragmentize<useDropStages$data>(
  graphql`
    fragment useDropStages on DropStageV2Type @inline {
      startTime
      endTime
    }
  `,
  identifiers => identifiers,
)

export const useDropStages = <
  T extends useDropStages$data | _FragmentRefs<"useDropStages">,
>(
  refs: ReadonlyArray<T>,
): { upcomingStage: T | null; currentStage: T | null; ctaStage: T | null } => {
  const stages = getDropStages(refs)
  const forceUpdate = useUpdate()

  const upcomingStageStartTime = stages.upcomingStage
    ? readStage(stages.upcomingStage).startTime
    : null

  useEffect(() => {
    if (!upcomingStageStartTime) {
      return
    }
    const timeDiff = parseJSON(upcomingStageStartTime).getTime() - Date.now()
    const timeoutId = setTimeout(forceUpdate, timeDiff)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [forceUpdate, upcomingStageStartTime])

  return stages
}

export const getDropStages = <
  T extends useDropStages$data | _FragmentRefs<"useDropStages">,
>(
  stages: ReadonlyArray<T>,
): { upcomingStage: T | null; currentStage: T | null; ctaStage: T | null } => {
  let upcomingStage: T | null = null
  let currentStage: T | null = null
  const now = Date.now()
  const sortedStages = sortBy(stages, "startTime")
  const lastStage = sortedStages[sortedStages.length - 1]
  if (
    sortedStages.length &&
    isAfter(now, parseJSON(readStage(lastStage).endTime))
  ) {
    return {
      upcomingStage: null,
      currentStage: lastStage,
      ctaStage: lastStage,
    }
  }
  for (const stage of sortedStages) {
    const stageData = readStage(stage)
    const start = parseJSON(stageData.startTime)
    const end = parseJSON(stageData.endTime)
    if (isAfter(now, start) && isBefore(now, end)) {
      currentStage = stage
    }
    if (isAfter(start, now) && !upcomingStage) {
      upcomingStage = stage
    }
  }
  let ctaStage = currentStage ?? upcomingStage
  if (!ctaStage && !isEmpty(stages)) {
    ctaStage = stages[0]
  }
  return {
    upcomingStage,
    currentStage,
    ctaStage,
  }
}
