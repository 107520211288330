import React from "react"

type Props = {
  className?: string
  width?: number
  fill?: string
}

export function RoninLogo({
  className,
  fill = "lightgrey",
  width = 24,
}: Props) {
  return (
    <svg
      className={className}
      style={{ width, height: width }}
      viewBox="0 0 22 30.8"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2.36932V21.3712C0.000222641 21.7262 0.0804854 22.0765 0.234859 22.3964C0.389232 22.7162 0.613772 22.9975 0.891892 23.2193L9.88514 30.4102C10.2015 30.6625 10.5947 30.8 11 30.8C11.4053 30.8 11.7985 30.6625 12.1149 30.4102L21.1081 23.2193C21.3862 22.9975 21.6108 22.7162 21.7651 22.3964C21.9195 22.0765 21.9998 21.7262 22 21.3712V2.36932C22 1.74093 21.7494 1.13829 21.3034 0.693956C20.8574 0.249624 20.2524 0 19.6216 0H2.37838C1.74759 0 1.14264 0.249624 0.696611 0.693956C0.250578 1.13829 0 1.74093 0 2.36932Z"
        fill="grey"
      />
      <path
        d="M17.413 10.9788V6.83733C17.413 6.28814 17.1967 5.76144 16.8118 5.37311C16.4268 4.98477 15.9047 4.7666 15.3603 4.7666H6.63621C6.0918 4.7666 5.56968 4.98477 5.18472 5.37311C4.79976 5.76144 4.5835 6.28814 4.5835 6.83733V18.854C4.58369 19.1642 4.65296 19.4704 4.7862 19.75C4.91943 20.0296 5.11323 20.2753 5.35327 20.4692L8.52985 23.0421C8.56755 23.073 8.61317 23.0924 8.66139 23.0981C8.70961 23.1039 8.75846 23.0957 8.80226 23.0746C8.84606 23.0534 8.88301 23.0202 8.90882 22.9787C8.93464 22.9372 8.94825 22.8892 8.94809 22.8402V14.3437C8.94809 14.2751 8.97512 14.2092 9.02324 14.1607C9.07136 14.1122 9.13662 14.0849 9.20468 14.0849H11.514C11.9223 14.0849 12.3139 14.2485 12.6026 14.5398C12.8913 14.831 13.0535 15.226 13.0535 15.6379V22.8402C13.0536 22.889 13.0672 22.9367 13.093 22.978C13.1187 23.0192 13.1555 23.0523 13.1991 23.0734C13.2426 23.0945 13.2912 23.1028 13.3392 23.0972C13.3873 23.0917 13.4328 23.0726 13.4705 23.0421L16.6471 20.4692C16.8871 20.2753 17.0809 20.0296 17.2141 19.75C17.3474 19.4704 17.4166 19.1642 17.4168 18.854V15.1203C17.4168 14.5711 17.2006 14.0444 16.8156 13.656C16.4306 13.2677 15.9085 13.0495 15.3641 13.0495C15.9079 13.0485 16.429 12.8299 16.8131 12.4417C17.1972 12.0534 17.413 11.5273 17.413 10.9788ZM11.5114 12.0142H9.20211C9.13406 12.0142 9.06879 11.9869 9.02067 11.9384C8.97255 11.8898 8.94552 11.824 8.94552 11.7553V7.09617C8.94552 7.02753 8.97255 6.96169 9.02067 6.91315C9.06879 6.8646 9.13406 6.83733 9.20211 6.83733H12.7944C12.8624 6.83733 12.9277 6.8646 12.9758 6.91315C13.0239 6.96169 13.051 7.02753 13.051 7.09617V10.4611C13.051 10.873 12.8888 11.268 12.6 11.5593C12.3113 11.8505 11.9197 12.0142 11.5114 12.0142Z"
        fill="currentColor"
      />
    </svg>
  )
}
