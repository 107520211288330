import React from "react"
import { useIsLessThanLg } from "@opensea/ui-kit"
import { useWindowSize } from "@react-hook/window-size"
import { BottomSheet } from "@/design-system/BottomSheet"
import { ModalProps, MultiStepModal } from "@/design-system/Modal"
import { useIsOpen } from "@/hooks/useIsOpen"

type Props = {
  trigger?: (open: () => unknown) => React.ReactNode
  isOpen?: boolean
  setOpen?: (open: boolean) => unknown
  children: (close: () => unknown) => React.ReactNode
  closable?: boolean
  breakpoint?: number
} & ModalProps

export const ResponsiveModal = ({
  children,
  trigger,
  isOpen: isOpenProp,
  setOpen: setOpenProp,
  closable,
  breakpoint,
  ...modalProps
}: Props) => {
  const isLarge = !useIsLessThanLg()
  const [windowWidth] = useWindowSize()
  let shouldUseModal = isLarge
  if (breakpoint) {
    shouldUseModal = windowWidth >= breakpoint
  }
  const {
    isOpen: isOpenHook,
    open: openHook,
    close: closeHook,
  } = useIsOpen(false)
  const isOpen = isOpenProp ?? isOpenHook
  const open = setOpenProp ? () => setOpenProp(true) : openHook
  const close = setOpenProp ? () => setOpenProp(false) : closeHook
  if (shouldUseModal) {
    return (
      <>
        {trigger ? trigger(open) : null}
        <MultiStepModal
          closable={closable}
          isOpen={isOpen}
          trigger={trigger}
          onClose={close}
          {...modalProps}
        >
          {children}
        </MultiStepModal>
      </>
    )
  }
  return (
    <>
      {trigger ? trigger(open) : null}
      <BottomSheet closable={closable} isOpen={isOpen} onClose={close}>
        {children(close)}
      </BottomSheet>
    </>
  )
}
