import { serializeEvent } from "../helpers/errors"
import { captureException, CapturedError, CaptureExceptionArgs } from "./sentry"

export const captureCriticalError = (
  error: CapturedError,
  ctx?: CaptureExceptionArgs["ctx"],
) => {
  captureException(error, { level: "fatal", ctx })
  console.error(error)
}

export const captureNoncriticalError = (
  error: CapturedError,
  exceptionArgs?: Omit<CaptureExceptionArgs, "level">,
) => {
  const { ctx, extras, tags } = exceptionArgs || {}

  // There are a lot errors that are mistyped error objects that are actually Events hitting Sentry
  // See https://opensea.sentry.io/issues/3822894816/?project=277230
  let reportableError = error as CapturedError | Event

  if (reportableError instanceof Event) {
    reportableError = serializeEvent(reportableError)
  }

  captureException(reportableError, { level: "error", ctx, extras, tags })
  console.error(reportableError)
}

export const captureWarning = (error: Error) => {
  console.warn(error)
}
