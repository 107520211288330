import React, { useState, useEffect, useRef } from "react"
import { Icon, Spinner, VerticalAligned, InputProps } from "@opensea/ui-kit"
import { useUpdateEffect } from "react-use"
import { useDebouncedCallback } from "use-debounce"
import {
  resolveEnsFromAddress,
  resolveAddressFromEns,
  resolveLensName,
} from "@/actions/ens"
import {
  useConnectedAddress,
  useWallet,
} from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { FormControlProps } from "@/design-system/FormControl"
import { useChains } from "@/hooks/useChains"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { UseFormReturn } from "@/hooks/useForm"
import { useTranslate } from "@/hooks/useTranslate"
import {
  addressesEqual,
  isValidAddress,
  getAddressInputPlaceholder,
} from "@/lib/helpers/address"
import {
  getDefaultChainForAddress,
  getEthereumChain,
} from "@/lib/helpers/chainUtils"
import { isQualifiedName } from "@/lib/helpers/ens"
import { validateAddressOrEns } from "@/lib/helpers/validation"
import { CORRECT_BURN_ADDRESS, WRONG_BURN_ADDRESS } from "./constants"
import { useIsDelegated } from "./useIsDelegated"

type UseAddressInputOptions = {
  debounce?: number
}

// DEPRECATED -- prefer useAddressInputV2 instead
export const useAddressInput = (
  value: string,
  { debounce = 500 }: UseAddressInputOptions = {},
) => {
  const valueRef = useRef(value)
  const [resolvedAddress, setResolvedAddress] = useState<string>("")
  const [isResolvingEnsName, setIsResolvingEnsName] = useState(false)

  const tryToResolveEnsAddress = useDebouncedCallback(async (name: string) => {
    try {
      const { address: resolvedAddress } = await resolveAddressFromEns(name)
      if (valueRef.current === name && resolvedAddress) {
        setResolvedAddress(resolvedAddress)
      }
    } finally {
      if (valueRef.current === name) {
        setIsResolvingEnsName(false)
      }
    }
  }, debounce)

  useEffect(() => {
    valueRef.current = value
    if (isQualifiedName(value)) {
      setResolvedAddress("")
      setIsResolvingEnsName(true)
      tryToResolveEnsAddress(value)
    } else {
      setResolvedAddress("")
    }
  }, [value, tryToResolveEnsAddress])

  const address = isValidAddress(value) ? value : resolvedAddress
  return { isResolvingEnsName, address }
}

export const useMultiAddressInput = (
  values: string[],
  { debounce = 500 }: UseAddressInputOptions = {},
) => {
  const valuesRef = useRef<string[]>(values)
  const nameToAddressCache = useRef(new Map<string, string | undefined>())

  const [isResolvingEnsNames, setIsResolvingEnsNames] = useState(
    values.map(() => false),
  )

  const tryToResolveEnsAddresses = useDebouncedCallback(
    async (namesWithIndex: { name: string; index: number }[]) => {
      try {
        const resolvedEnsNames = await Promise.all(
          namesWithIndex.map(async ({ name }) => {
            if (nameToAddressCache.current.has(name)) {
              return nameToAddressCache.current.get(name)
            }

            const { address: resolvedEnsName } =
              await resolveAddressFromEns(name)

            nameToAddressCache.current.set(name, resolvedEnsName)

            return resolvedEnsName
          }),
        )

        const currentValues = [...valuesRef.current]

        namesWithIndex.forEach(({ index: valuesIndex, name }, index) => {
          const resolvedEnsName = resolvedEnsNames[index]

          if (valuesRef.current[valuesIndex] === name && resolvedEnsName) {
            currentValues[valuesIndex] = resolvedEnsName
          }
        })
      } finally {
        const currentResolvingEnsNames = [...isResolvingEnsNames]
        namesWithIndex.forEach(({ index: valuesIndex, name }) => {
          if (valuesRef.current[valuesIndex] === name) {
            currentResolvingEnsNames[valuesIndex] = false
          }
        })
        setIsResolvingEnsNames(currentResolvingEnsNames)
      }
    },
    debounce,
  )

  useEffect(() => {
    valuesRef.current = values

    const isResolvingEnsNamesToSet = values.map(
      value => isQualifiedName(value) && !nameToAddressCache.current.has(value),
    )

    const ensNamesToResolve = values
      .map((value, index) => ({
        name: value,
        index,
      }))
      .filter(
        ({ name }) =>
          isQualifiedName(name) && !nameToAddressCache.current.has(name),
      )

    setIsResolvingEnsNames(isResolvingEnsNamesToSet)
    tryToResolveEnsAddresses(ensNamesToResolve)
    // It's complicated to memo-ize values when being passed in from a form, so just use the raw values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [`${values}`, tryToResolveEnsAddresses])

  const addresses = valuesRef.current.map(value =>
    isValidAddress(value) ? value : nameToAddressCache.current.get(value),
  )

  return { isResolvingEnsNames, addresses }
}

type UseAddressInputResult = {
  formControlProps: Pick<FormControlProps, "captionLeft" | "error" | "label">
  inputProps: InputProps
  isResolvingEnsName: boolean
  isDelegated: boolean
  isLoadingIsDelegated: boolean
  isLoading: boolean
}

export type AddressFormData = {
  address: string
  destination: string
}

type UseAddressInputOptionsV2 = UseAddressInputOptions & {
  chain?: ChainIdentifier
  selfErrorMessage?: string
  allowCrossChainAddress?: boolean
  validateIsSelf?: boolean
  allowEnsNames?: boolean
}

export const useAddressInputV2 = <T extends AddressFormData = AddressFormData>(
  _form: UseFormReturn<T>,
  {
    debounce = 500,
    chain,
    selfErrorMessage,
    allowCrossChainAddress = false,
    validateIsSelf = true,
    allowEnsNames = true,
  }: UseAddressInputOptionsV2 = {},
): UseAddressInputResult => {
  const t = useTranslate("hooks")
  const { register, formState, watch, setValue, setError } =
    _form as unknown as UseFormReturn<AddressFormData>

  const connectedAddress = useConnectedAddress()
  const destination = watch("destination")
  const destinationRef = useRef(destination)
  const [isResolvingEnsName, setIsResolvingEnsName] = useState(false)
  const { getChain, getChainName, getCanonicalChainIdentifier, isEvmChain } =
    useChains()

  const { chain: currentChain } = useWallet()
  const rpcUrl = currentChain ? getChain(currentChain).publicRpcUrl : ""

  const [isResolvingAddressForEns, setIsResolvingAddressForEns] =
    useState(false)
  const [ensNameForAddress, setEnsNameForAddress] = useState<string>("")

  const checkForEns = useDebouncedCallback(async (destination: string) => {
    try {
      const ens = await resolveEnsFromAddress(destination)
      if (ens) {
        setEnsNameForAddress(ens)
      } else {
        setEnsNameForAddress("")
      }
    } finally {
      if (destinationRef.current === destination) {
        setIsResolvingAddressForEns(false)
      }
    }
  }, debounce)

  const tryToResolveEnsAddress = useDebouncedCallback(async (name: string) => {
    try {
      const [{ address: resolvedAddress, isDelegated }, resolvedLensAddress] =
        await Promise.all([
          resolveAddressFromEns(name, chain),
          // When lens resolution fails, the Promise.all fails and breaks ens resolution. Catch so we can still resolve ens.
          resolveLensName(name, rpcUrl).catch(() => null),
        ])

      setIsDelegated(isDelegated)

      if (destinationRef.current === name) {
        if (resolvedLensAddress) {
          setValue("address", resolvedLensAddress, { shouldValidate: true })
        } else if (resolvedAddress) {
          setValue("address", resolvedAddress, { shouldValidate: true })
        } else {
          setError("destination", {
            message: t(
              "addressInput.nameNotRegistered",
              `{{name}} is not currently registered`,
              { name },
              { forceString: true },
            ),
          })
        }
      }
    } catch (err) {
      setError("destination", {
        message: t(
          "addressInput.nameNotRegistered",
          `{{name}} is not currently registered`,
          { name },
          { forceString: true },
        ),
      })
    } finally {
      if (destinationRef.current === name) {
        setIsResolvingEnsName(false)
      }
    }
  }, debounce)

  useEffect(() => {
    register("address", {
      required: true,
      validate: {
        isSelf: address => {
          if (validateIsSelf && addressesEqual(address, connectedAddress)) {
            return (
              selfErrorMessage ??
              t("addressInput.cannotSelfTransfer", "Cannot transfer to self")
            )
          }
          return true
        },
        isWrongBurnAddress: address => {
          if (addressesEqual(address, WRONG_BURN_ADDRESS)) {
            return t(
              "addressInput.wrongBurnAddress",
              "Please transfer to {{burnAddress}} if you are trying to burn your items.",
              { burnAddress: CORRECT_BURN_ADDRESS },
              { forceString: true },
            )
          }

          return true
        },
        isValidOnChain: value => {
          if (allowCrossChainAddress || chain === undefined) {
            return true
          }

          const addressChain = getDefaultChainForAddress(value)
          const itemChain = getCanonicalChainIdentifier(chain)

          return (
            addressChain === itemChain ||
            ((isEvmChain(addressChain) || isEvmChain(itemChain)) &&
              isEvmChain(addressChain) === isEvmChain(itemChain)) ||
            t(
              "addressInput.cannotTransferChain",
              `Cannot transfer {{itemChain}} items to {{addressChain}} address`,
              {
                itemChain: getChainName(itemChain),
                addressChain: getChainName(addressChain),
              },
              { forceString: true },
            )
          )
        },
      },
    })
  }, [
    register,
    chain,
    allowCrossChainAddress,
    selfErrorMessage,
    validateIsSelf,
    connectedAddress,
    getChainName,
    getCanonicalChainIdentifier,
    t,
    isEvmChain,
  ])

  useEffect(() => {
    setIsDelegated(false)
    destinationRef.current = destination
    setEnsNameForAddress("")
    setIsResolvingEnsName(false)
    setIsResolvingAddressForEns(false)
    setValue("address", isValidAddress(destination) ? destination : "", {
      shouldValidate: true,
    })
    if (allowEnsNames && destination && isQualifiedName(destination)) {
      setIsResolvingEnsName(true)
      tryToResolveEnsAddress(destination)
    } else if (destination && isValidAddress(destination)) {
      setIsResolvingAddressForEns(true)
      checkForEns(destination)
    }
  }, [
    destination,
    tryToResolveEnsAddress,
    setValue,
    allowEnsNames,
    checkForEns,
  ])

  const address = watch("address")
  const errorMessage =
    formState.errors["destination"]?.message ||
    formState.errors["address"]?.message

  // Store isDelegated in state so it can be updated when resolving ENS info or below with useIsDelegated hook
  const [isDelegated, setIsDelegated] = useState(false)
  const {
    isDelegated: destinationAddressIsDelegated,
    isLoading: isLoadingDestinationAddressIsDelegated,
  } = useIsDelegated({
    identifier: destination,
    chain,
    allowEnsNames: false, // Do not consider ENS names. Delegation info is retrieved when resolving the name instead
  })
  useUpdateEffect(() => {
    setIsDelegated(destinationAddressIsDelegated)
  }, [destinationAddressIsDelegated])
  const isLoadingIsDelegated =
    isLoadingDestinationAddressIsDelegated || isResolvingEnsName

  const isResolvedEnsName = address && address !== destination
  const isAddressWithEnsName =
    Boolean(ensNameForAddress) && !isQualifiedName(destination)

  return {
    isLoading: isResolvingEnsName || isLoadingDestinationAddressIsDelegated,
    isResolvingEnsName,
    isLoadingIsDelegated,
    isDelegated,
    formControlProps: {
      label: "Address",
      error: errorMessage,
      captionLeft:
        isResolvedEnsName || isDelegated || isAddressWithEnsName ? (
          <Block className="w-full">
            {isDelegated && (
              <Flex alignItems="center" color="success" textAlign="right">
                <Icon size={14} value="check" />{" "}
                {t("addressInput.delegatedAddress", "Address is delegated.")}
              </Flex>
            )}
            {isResolvedEnsName && (
              <Block>
                {t(
                  "addressInput.destinationAddress",
                  "Destination address: {{address}}",
                  { address },
                )}
              </Block>
            )}
            {isAddressWithEnsName && (
              <Block>
                {t(
                  "addressInput.ensNameForAddress",
                  "Destination ENS: {{ensNameForAddress}}",
                  { ensNameForAddress },
                )}
              </Block>
            )}
          </Block>
        ) : undefined,
    },
    inputProps: {
      endEnhancer:
        isResolvingEnsName ||
        isLoadingDestinationAddressIsDelegated ||
        isResolvingAddressForEns ? (
          <VerticalAligned className="ml-3">
            <Spinner />
          </VerticalAligned>
        ) : undefined,
      error: Boolean(errorMessage),
      placeholder: getAddressInputPlaceholder(chain ?? getEthereumChain()),
      ...register("destination", {
        required: t("addressInput.required", "This field is required"),
        validate: { isValidAddress: validateAddressOrEns },
      }),
    },
  }
}
