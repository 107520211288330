/**
 * @generated SignedSource<<59ba7328364a6ab11fde5850ed744caf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GaslessCancellationFailedModal_items$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"StackedAssetMedia_assets">;
  readonly " $fragmentType": "GaslessCancellationFailedModal_items";
}>;
export type GaslessCancellationFailedModal_items$key = ReadonlyArray<{
  readonly " $data"?: GaslessCancellationFailedModal_items$data;
  readonly " $fragmentSpreads": FragmentRefs<"GaslessCancellationFailedModal_items">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "GaslessCancellationFailedModal_items",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StackedAssetMedia_assets"
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};

(node as any).hash = "f4bc885474440d9bfbc81c47f5215876";

export default node;
