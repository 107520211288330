import React, { Suspense } from "react"
import { useLazyLoadQuery } from "react-relay"
import { BlockchainActionList } from "@/components/blockchain/BlockchainActionList/"
import { BlockchainActionModalContent } from "@/components/blockchain/BlockchainActionList/BlockchainActionModalContent.react"
import type {
  CancelOrdersActionModalQuery,
  CancelOrdersActionModalQuery$variables,
} from "@/lib/graphql/__generated__/CancelOrdersActionModalQuery.graphql"
import { graphql } from "@/lib/graphql/graphql"

type BaseProps = {
  onEnd: () => unknown
  onError: (error: Error) => unknown
}

type Props = BaseProps & CancelOrdersActionModalQuery$variables

const CancelOrdersActionModalBase = ({
  orders,
  gaslessCancelEligibleOrders,
  onEnd,
  onError,
}: Props) => {
  const data = useLazyLoadQuery<CancelOrdersActionModalQuery>(
    graphql`
      query CancelOrdersActionModalQuery(
        $orders: [OrderRelayID!]!
        $gaslessCancelEligibleOrders: Boolean!
      ) {
        blockchain {
          bulkCancelOrders(orders: $orders) {
            actions(gaslessCancelEligibleOrders: $gaslessCancelEligibleOrders) {
              ...BlockchainActionList_data
            }
          }
        }
      }
    `,
    { orders, gaslessCancelEligibleOrders },
    { fetchPolicy: "network-only" },
  )

  const {
    blockchain: {
      bulkCancelOrders: { actions },
    },
  } = data

  return (
    <BlockchainActionList dataKey={actions} onEnd={onEnd} onError={onError} />
  )
}

export const CancelOrdersActionModal = (props: Props) => {
  return (
    <Suspense fallback={<BlockchainActionModalContent.Skeleton />}>
      <CancelOrdersActionModalBase {...props} />
    </Suspense>
  )
}
