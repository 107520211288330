import React, { useEffect, useRef, useState } from "react"
import setLanguage from "next-translate/setLanguage"
import { Dropdown, DropdownItemProps } from "@/design-system/Dropdown"
import { TooltipInstance } from "@/design-system/Tooltip"
import { useClickAway } from "@/hooks/useClickAway"
import { updateLocaleCookie } from "@/lib/helpers/i18n"
import { LinkItem as LinkItemType } from "@/lib/helpers/links"
import { $nav_height } from "@/styles/variables"
import { LinkItem } from "./components/LinkItem.react"
import { LocaleItem } from "./components/LocaleItem.react"
import { useAccountDropdownItems } from "./hooks/useAccountDropdownItems.react"

const CLOSE_DELAY_MS = 300

type ItemType = LinkItemType | React.ReactNode

export type AccountDropdownProps = Omit<
  DropdownItemProps<ItemType>,
  "items" | "renderItem"
>

export const AccountDropdown = (props: AccountDropdownProps) => {
  const [isSelectingLanguage, setIsSelectingLanguage] = useState(false)
  const items = useAccountDropdownItems({
    isSelectingLanguage,
    setIsSelectingLanguage,
  })

  // Use ref so that onHide gets updated value immediately
  const isSelectingLanguageRef = useRef(false)
  useEffect(() => {
    isSelectingLanguageRef.current = isSelectingLanguage
  }, [isSelectingLanguage])

  const tippyRef = useRef<TooltipInstance>()
  const dropdownRef = useRef<HTMLUListElement>(null)
  useClickAway(dropdownRef, () => {
    isSelectingLanguageRef.current = false
    tippyRef.current?.hide()
    // Prevents flashing the regular dropdown items on hide
    setTimeout(() => setIsSelectingLanguage(false), CLOSE_DELAY_MS)
  })

  return (
    <Dropdown
      animation="shift-away"
      maxHeight={`calc(100vh - ${$nav_height})`}
      minWidth={245}
      trigger="mouseenter focus"
      {...props}
      dropdownRef={dropdownRef}
      items={items}
      renderItem={({ item, close }) => {
        if (typeof item === "object" && "label" in item) {
          const locale = item.locale
          // item is a LinkItemType
          if (locale) {
            return (
              <LocaleItem
                key={locale}
                {...item}
                onClick={async () => {
                  await setLanguage(locale)
                  updateLocaleCookie(locale)
                  isSelectingLanguageRef.current = false
                  close()
                  // Prevents flashing the regular dropdown items on hide
                  setTimeout(
                    () => setIsSelectingLanguage(false),
                    CLOSE_DELAY_MS,
                  )
                }}
              />
            )
          }

          return <LinkItem key={item.url} {...item} />
        }

        // item is a ReactNode
        return item
      }}
      onHide={() => {
        return isSelectingLanguageRef.current ? false : undefined
      }}
      onMount={instance => {
        tippyRef.current = instance
      }}
    />
  )
}
