import React, { useCallback, useRef, useState } from "react"
import { Text, FlexColumn, UnstyledButton } from "@opensea/ui-kit"
import { useLocalStorage } from "react-use"
import { Button } from "@/design-system/Button"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { useCreateEmbeddedWalletManual } from "@/features/os-wallet/flags"
import { useAuthenticateOSWallet } from "@/features/os-wallet/hooks/useOpenOSWalletAfterLogin"
import { useOSWalletAuth } from "@/features/os-wallet/hooks/useOSWalletAuth"
import { LAST_CONNECTED_EMAIL_KEY } from "@/features/os-wallet/localstorage"
import { useTranslate } from "@/hooks/useTranslate"
import { EMAIL_AUTH_CODE_EXPIRATION } from "../../../constants"
import { AuthCodeExpiration } from "../AuthCodeExpiration.react"
import { OSWalletModalBody } from "./OSWalletModalBody.react"
import { SetupAuthCode } from "./SetupAuthCode.react"
import { WalletMfaEnrollmentModal } from "./WalletMfaEnrollmentModal.react"
import { WalletSignupModal } from "./WalletSignupModal.react"

type Props = {
  email: string
  hasLoginCallback?: boolean
}

export const WalletEmailCodeModal = ({ email, hasLoginCallback }: Props) => {
  const t = useTranslate("common")
  const { onPrevious, onNext } = useMultiStepFlowContext()
  const { sendLoginCode, loginWithCode, checkMfaEnrollment } = useOSWalletAuth()
  const authenticateOSWallet = useAuthenticateOSWallet()
  const [, setLastConnectedEmail] = useLocalStorage<string>(
    LAST_CONNECTED_EMAIL_KEY,
  )
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [expired, setExpired] = useState<boolean>(false)
  const [canResendCode, setCanResendCode] = useState<boolean>(true)
  const [value, setValue] = useState<string>("")
  const resetExpirationRef = useRef<(() => void) | null>(null)
  const authCodeExpiration = EMAIL_AUTH_CODE_EXPIRATION
  const showAuthCodeExpiration =
    authCodeExpiration.minutes || authCodeExpiration.seconds
  const createEmbeddedWalletManual = useCreateEmbeddedWalletManual()

  const handleAuthCode = async (authCode: string) => {
    setError(false)
    setLoading(true)
    const tryMfaEnrollment = async (
      accessToken: string,
      privyId: string,
      isRetry: boolean = false,
    ) => {
      const { isReady, isEnrolled } = await checkMfaEnrollment()

      if (!isReady) {
        if (isRetry) {
          throw new Error(
            t(
              "wallet.opensea.mfaEnrollmentError",
              "Could not verify MFA enrollment. Please try again.",
            ),
          )
        }
        // Wait 1 second before retrying.
        await new Promise(resolve => setTimeout(resolve, 1000))
        await tryMfaEnrollment(accessToken, privyId, true)
        return
      }

      setLastConnectedEmail(email)

      if (isEnrolled) {
        await authenticateOSWallet({
          accessToken,
          privyId,
          shouldOpenWalletAfterAuth: !hasLoginCallback,
        })
      } else {
        onNext(() => (
          <WalletMfaEnrollmentModal
            accessToken={accessToken}
            privyId={privyId}
          />
        ))
      }
    }

    try {
      const { accessToken, privyId, isNewUser } = await loginWithCode(authCode)
      if (isNewUser && createEmbeddedWalletManual) {
        onNext(() => (
          <WalletSignupModal
            email={email}
            onConfirm={async () => await tryMfaEnrollment(accessToken, privyId)}
          />
        ))
      } else {
        await tryMfaEnrollment(accessToken, privyId)
      }
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const handleAuthCodeExpired = useCallback(() => {
    setExpired(true)
  }, [])

  const handleResendAuthCode = async () => {
    try {
      if (!canResendCode) {
        return
      }
      setExpired(false)
      setCanResendCode(false)
      await sendLoginCode(email)
      resetExpirationRef.current?.()
    } catch (error) {
      setError(true)
    }
  }

  return (
    <OSWalletModalBody
      className="justify-between pb-2"
      title={t("wallet.opensea.enterCode", "Enter code")}
      onPrevious={onPrevious}
    >
      <FlexColumn className="items-center gap-4 text-center">
        <SetupAuthCode
          disabled={expired || loading}
          error={error}
          value={value}
          onComplete={handleAuthCode}
        />
        <Text.Body color="secondary" size="tiny">
          {t(
            "wallet.opensea.oneTimeCode",
            "A one time authentication code has been sent to {{emailText}}.",
            {
              emailText: (
                <Text.Body color="secondary" size="tiny" weight="semibold">
                  {email}
                </Text.Body>
              ),
            },
          )}
        </Text.Body>
      </FlexColumn>

      <FlexColumn className="gap-4 text-center">
        <Button
          variant="secondary"
          onClick={async () => {
            setValue(await navigator.clipboard.readText())
          }}
        >
          {t("wallet.opensea.pasteFromClipboard", "Paste from clipboard")}
        </Button>
        {showAuthCodeExpiration &&
          (expired ? (
            <UnstyledButton className="mx-auto" onClick={handleResendAuthCode}>
              <Text.Body color="interactive-primary-styles" size="tiny">
                {t(
                  "wallet.opensea.authCodeExpired",
                  "Code expired, please request a new code.",
                )}
              </Text.Body>
            </UnstyledButton>
          ) : (
            <span>
              <Text.Body color="secondary" size="tiny">
                {t("wallet.opensea.expiresIn", "Expires in ")}
                <AuthCodeExpiration
                  minutes={authCodeExpiration.minutes}
                  seconds={authCodeExpiration.seconds}
                  size="tiny"
                  onEnd={handleAuthCodeExpired}
                  onReset={reset => (resetExpirationRef.current = reset)}
                />
                .&nbsp;
                <UnstyledButton onClick={handleResendAuthCode}>
                  <Text.Body color="interactive-primary-styles" size="tiny">
                    {t("wallet.opensea.resendCode", "Resend code")}
                  </Text.Body>
                </UnstyledButton>
              </Text.Body>
            </span>
          ))}
      </FlexColumn>
    </OSWalletModalBody>
  )
}
