import React from "react"
import { TextBodyProps, Text, classNames } from "@opensea/ui-kit"
import { EMPTY_PRICE_DISPLAY } from "@/constants/index"
import {
  bn,
  display,
  displayFiat,
  NumberInput,
  roundAboveMin,
} from "@/lib/helpers/numberUtils"

type PriceTextBaseProps = {
  compactDisplay?: boolean
  positiveSign?: boolean
  subtleEmpty?: boolean
} & Partial<TextBodyProps>

export type PriceTextTokenProps = {
  unit: NumberInput | null | undefined
  symbol: string | null | undefined
  roundBelowDecimals?: number
} & PriceTextBaseProps

export type PriceTextFiatProps = {
  usd: NumberInput | null | undefined
  usdSuffix?: boolean
} & PriceTextBaseProps

const DEFAULT_SIZE = "small"

const PriceTextToken = ({
  unit,
  symbol,
  roundBelowDecimals,
  size = DEFAULT_SIZE,
  compactDisplay = false,
  positiveSign = false,
  subtleEmpty = false,
  className,
  ...textBodyProps
}: PriceTextTokenProps) => {
  const hasPrice = unit !== null && unit !== undefined && symbol
  return (
    <Text.Body
      className={classNames(
        hasPrice ? "text-primary" : subtleEmpty ? "text-secondary" : undefined,
        className,
      )}
      size={size}
      {...textBodyProps}
    >
      {hasPrice ? (
        <>
          <span>
            {positiveSign && bn(unit).gt(0) && "+"}
            {roundBelowDecimals !== undefined
              ? roundAboveMin(unit, roundBelowDecimals)
              : display(unit, symbol, compactDisplay)}{" "}
          </span>
          <span>{symbol}</span>
        </>
      ) : (
        <>
          {EMPTY_PRICE_DISPLAY}
          {symbol ? ` ${symbol}` : ""}
        </>
      )}
    </Text.Body>
  )
}

const PriceTextFiat = ({
  usd,
  size = DEFAULT_SIZE,
  compactDisplay = false,
  positiveSign = false,
  subtleEmpty = false,
  usdSuffix = false,
  className,
  ...textBodyProps
}: PriceTextFiatProps) => {
  const hasPrice = usd !== null && usd !== undefined
  return (
    <Text.Body
      className={classNames(
        hasPrice ? "text-primary" : subtleEmpty ? "text-secondary" : undefined,
        className,
      )}
      size={size}
      {...textBodyProps}
    >
      {hasPrice ? (
        <>
          <span>
            {positiveSign && bn(usd).gte(0.01) && "+"}
            {displayFiat(usd, compactDisplay)}
          </span>
          {usdSuffix ? <span>USD</span> : null}
        </>
      ) : (
        <>{EMPTY_PRICE_DISPLAY}</>
      )}
    </Text.Body>
  )
}

export const PriceText = Object.assign(PriceTextToken, {
  Fiat: PriceTextFiat,
  Token: PriceTextToken,
})
