import React, { useCallback, useState } from "react"
import { Text, Alert, Flex } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { TokenPrice } from "@/components/assets/price/TokenPrice.react"
import { StackedAssetMedia } from "@/components/assets/StackedAssetMedia"
import { Item } from "@/design-system/Item"
import { useTotalPriceOfferDataToAccept } from "@/features/bulk-accept-offer/hooks/useTotalPriceOfferDataToAccept"
import { readOfferDataToAcceptPrice } from "@/features/bulk-accept-offer/utils/readOfferDataToAcceptPrice"
import { useTotalItems } from "@/features/shopping-cart/hooks/useTotalItems"
import { ItemSideOverflowStacked } from "@/features/shopping-cart/styles"
import { useChains } from "@/hooks/useChains"
import { useTranslate } from "@/hooks/useTranslate"
import {
  useTrackBlockchainAttempt,
  useTrackBlockchainError,
} from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/hooks"
import { BulkAcceptOffersAction_data$key } from "@/lib/graphql/__generated__/BulkAcceptOffersAction_data.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { bn } from "@/lib/helpers/numberUtils"
import { EN_DASH } from "@/lib/helpers/stringUtils"
import { useTransaction } from "../useTransaction"
import {
  BaseBlockchainActionProps,
  BlockchainActionModalContent,
} from "./BlockchainActionModalContent.react"
import { useBlockchainActionProgress } from "./useBlockchainActionProgress"
import {
  Transaction,
  useHandleBlockchainActions,
} from "./useHandleBlockchainActions"

type Props = BaseBlockchainActionProps & {
  dataKey: BulkAcceptOffersAction_data$key
}

export const BulkAcceptOffersAction = ({ dataKey, onEnd }: Props) => {
  const t = useTranslate("bulkPurchase")
  const [actionIndex, setActionIndex] = useState(0)
  const [transactions, setTransactions] = useState<Transaction[]>([])
  const { getChainName } = useChains()

  const { pollTransaction } = useTransaction()

  const actions = useFragment(
    graphql`
      fragment BulkAcceptOffersAction_data on BulkAcceptOffersActionType
      @relay(plural: true) {
        __typename

        maxQuantityToFill
        offersToAccept {
          itemFillAmount
          orderData {
            chain {
              identifier
            }
            item {
              ... on AssetQuantityDataType {
                asset {
                  ...StackedAssetMedia_assets
                }
              }
              ... on AssetBundleType {
                assetQuantities(first: 30) {
                  edges {
                    node {
                      asset {
                        ...StackedAssetMedia_assets
                      }
                    }
                  }
                }
              }
            }
            ...useTotalItems_ordersData
          }
          criteriaAsset {
            relayId
            ...StackedAssetMedia_assets
          }
          ...useTotalPriceOfferDataToAccept_offersToAccept
          ...readOfferDataToAcceptPrice_offerToAccept
        }
        ...useHandleBlockchainActions_bulk_accept_offers
      }
    `,
    dataKey,
  )

  const action = actions[actionIndex]

  const orderData = action.offersToAccept.map(order => order.orderData)
  const criteriaAssets = action.offersToAccept.flatMap(order =>
    order.criteriaAsset?.relayId ? [order.criteriaAsset.relayId] : [],
  )

  const totalItems = useTotalItems({
    ordersDataDataKey: orderData,
    criteriaAssetIds: criteriaAssets,
  })

  const totalQuantity = action.offersToAccept.reduce(
    (acc, { itemFillAmount }) => acc.plus(itemFillAmount),
    bn(0),
  )

  const { bulkAcceptOffers } = useHandleBlockchainActions()
  const trackBlockchainAttempt = useTrackBlockchainAttempt()
  const [trackBlockchainError, errorTags] = useTrackBlockchainError()

  const executeAction = useCallback(async () => {
    trackBlockchainAttempt({ blockchainAction: "coreAction" })
    const transaction = await bulkAcceptOffers(action)

    const allTransactions = [...transactions, transaction]
    setTransactions(allTransactions)

    if (actionIndex === actions.length - 1) {
      await pollTransaction(transaction)
      onEnd({ transactions: allTransactions })
    } else {
      setActionIndex(index => index + 1)
    }
  }, [
    trackBlockchainAttempt,
    bulkAcceptOffers,
    action,
    transactions,
    actionIndex,
    actions.length,
    pollTransaction,
    onEnd,
  ])

  const { attemptAction, progress } = useBlockchainActionProgress({
    executeAction,
    action,
    onError: trackBlockchainError({ blockchainAction: "coreAction" }),
    errorTags,
  })

  const title = t("blockchain.approveSale.title", "Approve sale")

  const actionText = t(
    "blockchain.approveSale.description",
    "You'll be asked to approve this sale from your wallet.",
  )

  const chain = action.offersToAccept[0].orderData.chain.identifier

  const maxQuantityToFill = action.maxQuantityToFill
    ? bn(action.maxQuantityToFill)
    : undefined

  const isSubstitutionEnabled = !!maxQuantityToFill

  const itemCount = action.maxQuantityToFill
    ? maxQuantityToFill
    : bn(totalItems.length)

  const showQuantity =
    !totalQuantity.isEqualTo(totalItems.length) && // Total quantity differs from number of items
    !isSubstitutionEnabled // Substitution will always be for maxQuantityToFill items (total quantity includes substitutions and is not relevant)

  // Sorted by usd price low to high
  const sortedOrdersToFill = action.offersToAccept.slice().sort((a, b) => {
    const bUsdPrice = readOfferDataToAcceptPrice(b).usdPrice
    const aUsdPrice = readOfferDataToAcceptPrice(a).usdPrice
    return aUsdPrice.comparedTo(bUsdPrice)
  })

  const { totalPricePerSymbol, totalUsdPrice } = useTotalPriceOfferDataToAccept(
    sortedOrdersToFill,
    maxQuantityToFill,
  )

  const {
    totalPricePerSymbol: maxTotalPricePerSymbol,
    totalUsdPrice: maxTotalUsdPrice,
  } = useTotalPriceOfferDataToAccept(
    sortedOrdersToFill.slice().reverse(),
    maxQuantityToFill,
  )

  return (
    <BlockchainActionModalContent>
      <BlockchainActionModalContent.Header>
        <BlockchainActionModalContent.Title>
          {title}
        </BlockchainActionModalContent.Title>
      </BlockchainActionModalContent.Header>
      <BlockchainActionModalContent.Body>
        <Item variant="unstyled">
          <Item.Avatar size={84}>
            <StackedAssetMedia
              assets={action.offersToAccept.flatMap(
                ({ orderData: { item }, criteriaAsset }) => {
                  if (criteriaAsset) {
                    return criteriaAsset
                  }

                  return item?.assetQuantities
                    ? getNodes(item.assetQuantities).map(({ asset }) => asset)
                    : item?.asset
                    ? item.asset
                    : []
                },
              )}
              isLoading
              variant="small"
            />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>
              <Text.Body>
                {t(
                  "itemCount",
                  {
                    "0": "{{count}} items",
                    one: "{{count}} item",
                    other: "{{count}} items",
                  },
                  {
                    count: itemCount?.toNumber() ?? 1,
                  },
                )}
              </Text.Body>
            </Item.Title>
            {showQuantity && (
              <Item.Description>
                {t("quantity", "Quantity: {{count}}", {
                  count: totalQuantity.toString(),
                })}
              </Item.Description>
            )}
            <Item.Description>
              {t("chainName", "Chain: {{chainDisplayName}}", {
                chainDisplayName: getChainName(chain),
              })}
            </Item.Description>
          </Item.Content>
          <Item.Side>
            <Item.Title>
              <ItemSideOverflowStacked>
                <Text.Body weight="semibold">
                  {Object.keys(totalPricePerSymbol).map(symbol => {
                    const price = totalPricePerSymbol[symbol]
                    const maxPrice = maxTotalPricePerSymbol[symbol]
                    const hasPriceRange =
                      maxPrice &&
                      price &&
                      !price.price.isEqualTo(maxPrice.price)
                    return (
                      price && (
                        <Flex key={symbol}>
                          <TokenPrice
                            {...price.tokenPricePayment}
                            fontWeight={600}
                            price={price.price}
                            symbol={
                              maxPrice ? "" : price.tokenPricePayment.symbol
                            }
                            symbolVariant="raw"
                          />
                          {isSubstitutionEnabled && hasPriceRange && (
                            <>
                              {EN_DASH}
                              <TokenPrice
                                {...maxPrice.tokenPricePayment}
                                fontWeight={600}
                                price={maxPrice.price}
                                symbolVariant="raw"
                              />
                            </>
                          )}
                        </Flex>
                      )
                    )
                  })}
                </Text.Body>
              </ItemSideOverflowStacked>
            </Item.Title>
            <Item.Description>
              {totalUsdPrice}
              {isSubstitutionEnabled && totalUsdPrice !== maxTotalUsdPrice && (
                <>
                  {EN_DASH}
                  {maxTotalUsdPrice}
                </>
              )}
            </Item.Description>
          </Item.Side>
        </Item>

        <BlockchainActionModalContent.Body.Separator />

        {actions.length > 1 && (
          <Alert className="mb-4">
            {t(
              "blockchain.fulfill.numTransactions",
              "Due to a marketplace contract upgrade, you will have to submit {{numTransactions}} transactions to accept all your offers.",
              { numTransactions: actions.length },
            )}
          </Alert>
        )}

        <BlockchainActionModalContent.Body.GoToWallet />
        <BlockchainActionModalContent.Body.Text>
          {actionText}
        </BlockchainActionModalContent.Body.Text>
        {!progress && (
          <BlockchainActionModalContent.Body.Button onClick={attemptAction}>
            {t("blockchain.fulfill.cta", "Continue")}
          </BlockchainActionModalContent.Body.Button>
        )}
      </BlockchainActionModalContent.Body>
    </BlockchainActionModalContent>
  )
}
