import { useCallback } from "react"
import { useSetEmbeddedWalletMutation$variables } from "@/lib/graphql/__generated__/useSetEmbeddedWalletMutation.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"

export const useSetEmbeddedWallet = () => {
  const { mutate } = useGraphQL()

  return useCallback(
    async (variables: useSetEmbeddedWalletMutation$variables) => {
      return await mutate(
        graphql`
          mutation useSetEmbeddedWalletMutation($privyId: String!) {
            accounts {
              setEmbeddedWallet(privyUserDid: $privyId) {
                relayId
                isEmbeddedWallet
              }
            }
          }
        `,
        variables,
      )
    },
    [mutate],
  )
}
