import React, { useState } from "react"
import {
  CenterAligned,
  Flex,
  Icon,
  UnstyledButton,
  classNames,
} from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { CollectionManagerDeleteModal } from "@/components/modals/CollectionManagerDeleteModal.react"
import { Dropdown } from "@/design-system/Dropdown"
import { useIsSSFEnabled } from "@/hooks/useFlag"
import { useTranslate } from "@/hooks/useTranslate"
import { CollectionCardContextMenu_data$key } from "../../lib/graphql/__generated__/CollectionCardContextMenu_data.graphql"
import { graphql } from "../../lib/graphql/graphql"
import {
  getCollectionEditUrl,
  getCollectionRoyaltiesUrl,
} from "../../lib/helpers/collection"

type Props = {
  dataKey: CollectionCardContextMenu_data$key | null
  connectionId?: string
}

export const CollectionCardContextMenu = ({ dataKey, connectionId }: Props) => {
  const t = useTranslate("components")
  const data = useFragment(
    graphql`
      fragment CollectionCardContextMenu_data on CollectionType {
        ...collection_url
        ...CollectionManagerDeleteModal_data
      }
    `,
    dataKey,
  )
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const isSSFEnabled = useIsSSFEnabled()

  if (!data) {
    return null
  }
  const renderDeleteModal = () => (
    <CollectionManagerDeleteModal
      connectionId={connectionId}
      dataKey={data}
      isCollection
      isOpen={showDeleteModal}
      onClose={() => setShowDeleteModal(false)}
    />
  )

  return (
    <Flex className="absolute right-3 top-3">
      {renderDeleteModal()}
      <Dropdown
        content={({ List, Item, close: closeDropdown }) => (
          <List>
            <ItemContainer>
              <Item href={getCollectionEditUrl(data)}>
                <Item.Avatar icon="edit" />
                <Item.Content>
                  <Item.Title>
                    {t(
                      "collections.contextMenu.editCollection",
                      "Edit collection",
                    )}
                  </Item.Title>
                </Item.Content>
              </Item>
            </ItemContainer>
            <ItemContainer>
              <Item href={getCollectionRoyaltiesUrl(data)}>
                <Item.Avatar icon="attach_money" />
                <Item.Content>
                  <Item.Title>
                    {t("collections.contextMenu.viewEarnings", "View earnings")}
                  </Item.Title>
                </Item.Content>
              </Item>
            </ItemContainer>
            {isSSFEnabled && (
              <ItemContainer>
                <Item
                  onClick={e => {
                    setShowDeleteModal(true)
                    closeDropdown()
                    e.preventDefault()
                  }}
                >
                  <Item.Avatar className="text-red-2" icon="delete" />
                  <Item.Content>
                    <Item.Title color="error">
                      {t(
                        "collections.contextMenu.deleteCollection",
                        "Delete collection",
                      )}
                    </Item.Title>
                  </Item.Content>
                </Item>
              </ItemContainer>
            )}
          </List>
        )}
        placement="bottom-end"
      >
        <CenterAligned
          className={classNames(
            "overflow-hidden rounded-xl bg-elevation-1 p-1 text-primary opacity-90",
            "hover:opacity-100 hover:shadow-elevation-2",
          )}
        >
          <UnstyledButton
            onClick={event => {
              event.stopPropagation()
              event.preventDefault()
            }}
          >
            <Icon
              aria-label={t("collections.contextMenu.more", "More")}
              value="more_vert"
            />
          </UnstyledButton>
        </CenterAligned>
      </Dropdown>
    </Flex>
  )
}

const ItemContainer = styled.div`
  opacity: 0.85;
  :hover {
    opacity: 1;
  }
`
