import { Environment } from "react-relay"
import {
  GraphQLTaggedNode,
  MutationConfig,
  MutationParameters,
  commitMutation as _commitMutation,
} from "relay-runtime"

export type MutationOptions<TOperation extends MutationParameters> = Omit<
  MutationConfig<TOperation>,
  "mutation" | "variables" | "onCompleted" | "onError"
>

export const commitMutation = async <TOperation extends MutationParameters>(
  environment: Environment,
  mutation: GraphQLTaggedNode,
  variables: TOperation["variables"],
  config: MutationOptions<TOperation> = {},
): Promise<TOperation["response"]> => {
  return new Promise((resolve, reject) =>
    _commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) =>
        errors ? reject(errors) : resolve(response),
      onError: reject,
      ...config,
    }),
  )
}
