import { useCallback } from "react"
import { graphql } from "relay-runtime"
import { useGaslessCancelOrdersMutation } from "@/lib/graphql/__generated__/useGaslessCancelOrdersMutation.graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"
import {
  GaslessCancelResult,
  readGaslessCancelResult,
} from "../utils/readGaslessCancelResult"

export const useGaslessCancelOrders = () => {
  const { mutate } = useGraphQL()

  return useCallback(
    async (orderRelayIds: readonly string[]): Promise<GaslessCancelResult> => {
      const {
        orders: {
          gaslessCancel: { statuses },
        },
      } = await mutate<useGaslessCancelOrdersMutation>(
        graphql`
          mutation useGaslessCancelOrdersMutation($orders: [OrderRelayID!]!) {
            orders {
              gaslessCancel(orders: $orders) {
                statuses {
                  order {
                    relayId
                    isCancelled
                    isValid
                    invalidationReason
                    remainingQuantityType
                  }
                  ...readGaslessCancelResult_status
                }
              }
            }
          }
        `,
        {
          orders: orderRelayIds,
        },
      )

      return readGaslessCancelResult(statuses)
    },
    [mutate],
  )
}

export type { GaslessCancelResult }
