import { resolvePaymentAsset } from "@/actions/paymentAsset"
import { Currency } from "@/components/nav/WalletPopover/types"
import { querySwapActions } from "@/lib/helpers/swap"
import { BridgeOrWrapDirection } from "../BridgeOrWrapForm.react"

type ResolveSwapActionsProps = {
  baseCurrency: Currency
  swapCurrency: Currency
  quantity: string
  direction: BridgeOrWrapDirection
}

export const resolveSwapAction = async ({
  baseCurrency,
  swapCurrency,
  quantity,
  direction,
}: ResolveSwapActionsProps) => {
  // load payment assets for currencies
  const [
    [primaryPaymentAsset, disposeMainPaymentAsset],
    [secondaryPaymentAsset, disposeSecondaryPaymentAsset],
  ] = await Promise.all([
    resolvePaymentAsset(baseCurrency.symbol, baseCurrency.chain),
    resolvePaymentAsset(swapCurrency.symbol, swapCurrency.chain),
  ])

  const fromPaymentAsset =
    direction === "out" ? primaryPaymentAsset : secondaryPaymentAsset
  const toPaymentAsset =
    direction === "out" ? secondaryPaymentAsset : primaryPaymentAsset

  // Perform swap
  const swapActions = await querySwapActions({
    amount: quantity,
    fromAssetDecimals: fromPaymentAsset.decimals,
    fromAsset: fromPaymentAsset.assetRelayId,
    toAsset: toPaymentAsset.assetRelayId,
  })

  const dispose = () => {
    disposeMainPaymentAsset?.dispose()
    disposeSecondaryPaymentAsset?.dispose()
  }

  if (swapActions.actionsV2.length !== 1) {
    throw new Error("Expected exactly one swap action")
  }
  const swapAction = swapActions.actionsV2[0]
  if (swapAction.__typename !== "AssetSwapActionType") {
    throw new Error(
      "Expected transfer action to be of type AssetSwapActionType",
    )
  }

  return [swapAction, dispose] as const
}

export const resolveSwapTransaction = async (
  props: ResolveSwapActionsProps,
) => {
  const [swapAction, dispose] = await resolveSwapAction(props)
  const swapTransaction = swapAction.method
  if (!swapTransaction) {
    throw new Error(
      "Expected transfer action to have a TransactionSubmissionDataType method",
    )
  }
  return [swapTransaction, dispose] as const
}
