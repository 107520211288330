import { useState } from "react"
import BigNumber from "bignumber.js"
import { useInterval } from "react-use"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { useIsMountedRef } from "@/hooks/useIsMounted"
import { useMountEffect } from "@/hooks/useMountEffect"

const MOONPAY_WALLET_BALANCE_POLL_INTERVAL = 5_000

export const useOnWalletBalanceIncrease = ({
  onWalletBalanceIncrease,
  symbol = "ETH",
  chain,
}: {
  onWalletBalanceIncrease: () => void
  symbol?: string
  chain?: ChainIdentifier
}) => {
  const { wallet } = useWallet()
  const [lastBalance, setLastBalance] = useState<BigNumber>()
  const isMountedRef = useIsMountedRef()

  useMountEffect(() => {
    const setInitialBalance = async () => {
      const balance = await wallet.getBalanceBySymbol(symbol, chain)
      if (isMountedRef.current) {
        setLastBalance(balance)
      }
    }
    setInitialBalance()
  })

  const checkWalletBalance = async () => {
    if (lastBalance === undefined) {
      return
    }
    const balance = await wallet.getBalanceBySymbol(symbol, chain)
    if (balance.isGreaterThan(lastBalance)) {
      onWalletBalanceIncrease()
      setLastBalance(balance)
    }
  }

  useInterval(checkWalletBalance, MOONPAY_WALLET_BALANCE_POLL_INTERVAL)
}
