import { useCallback } from "react"
import { useTranslate } from "@/hooks/useTranslate"
import { BigNumber, bn, display } from "@/lib/helpers/numberUtils"

type UseValidateQuantityInputOptions = {
  max: BigNumber
  symbol: string
}

export const useValidateQuantityInput = ({
  max,
  symbol,
}: UseValidateQuantityInputOptions): ((
  value: string,
) => string | undefined) => {
  const t = useTranslate("components")

  const validate = useCallback(
    (quantityInputValue: string) => {
      if (quantityInputValue.trim() === "") {
        return t(
          "bridgeOrWrapForm.quantity.errors.required",
          "Quantity is required",
        )
      }

      const quantity = bn(quantityInputValue)

      if (quantity.isNaN()) {
        return t(
          "bridgeOrWrapForm.quantity.errors.number",
          "Quantity must be a number",
        )
      }

      if (max.isZero()) {
        return t(
          "bridgeOrWrapForm.quantity.errors.notEnoughFunds",
          "Not enough funds",
        )
      }

      if (quantity.isLessThanOrEqualTo(0)) {
        return t(
          "bridgeOrWrapForm.quantity.errors.min",
          "Quantity must be more than 0",
        )
      }

      if (quantity.isGreaterThan(max)) {
        return t(
          "bridgeOrWrapForm.quantity.errors.notEnoughFundsWithMax",
          "Max is {{max}} {{symbol}} due to gas fees",
          { max: display(max), symbol },
          { forceString: true },
        )
      }

      return undefined
    },
    [max, symbol, t],
  )

  return validate
}
