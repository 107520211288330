import React, { useCallback, useState } from "react"
import { Text } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { StackedAssetMedia } from "@/components/assets/StackedAssetMedia"
import { Link } from "@/components/common/Link"
import { useTheme } from "@/design-system/Context"
import { Item } from "@/design-system/Item"
import { useChains } from "@/hooks/useChains"
import { useTranslate } from "@/hooks/useTranslate"
import {
  useTrackBlockchainAttempt,
  useTrackBlockchainError,
} from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/hooks"
import { PaymentAssetApprovalAction_data$key } from "@/lib/graphql/__generated__/PaymentAssetApprovalAction_data.graphql"
import THEMES from "@/styles/themes"
import { useTransaction } from "../useTransaction"
import {
  BaseBlockchainActionProps,
  BlockchainActionModalContent,
} from "./BlockchainActionModalContent.react"
import { useBlockchainActionProgress } from "./useBlockchainActionProgress"
import { useHandleBlockchainActions } from "./useHandleBlockchainActions"

type Props = BaseBlockchainActionProps & {
  dataKey: PaymentAssetApprovalAction_data$key
}

export const PaymentAssetApprovalAction = ({ dataKey, onEnd }: Props) => {
  const t = useTranslate("components")
  const actions = useFragment(
    graphql`
      fragment PaymentAssetApprovalAction_data on PaymentAssetApprovalActionType
      @relay(plural: true) {
        __typename
        asset {
          chain {
            identifier
          }
          symbol
          ...StackedAssetMedia_assets
        }
        ...useHandleBlockchainActions_approve_payment_asset
      }
    `,
    dataKey,
  )

  const [actionIndex, setActionIndex] = useState(0)
  const [transactionHash, setTransactionHash] = useState<string>()
  const { getTransactionUrl, getBlockExplorerName } = useChains()
  const { theme } = useTheme()

  const { approvePaymentAsset } = useHandleBlockchainActions()
  const { pollTransaction } = useTransaction()
  const trackBlockchainAttempt = useTrackBlockchainAttempt()
  const [trackBlockchainError, errorTags] = useTrackBlockchainError()

  const executeAction = useCallback(async () => {
    if (actionIndex < actions.length) {
      trackBlockchainAttempt({ blockchainAction: "approval" })
      const transaction = await approvePaymentAsset(actions[actionIndex])

      setTransactionHash(transaction.transactionHash)

      await pollTransaction({ ...transaction, showMessageOnSuccess: true })

      setTransactionHash("")

      if (actionIndex === actions.length - 1) {
        onEnd()
      } else {
        setActionIndex(index => index + 1)
      }
    }
  }, [
    actionIndex,
    actions,
    approvePaymentAsset,
    onEnd,
    pollTransaction,
    trackBlockchainAttempt,
  ])

  const action = actions[actionIndex]

  const { progress, attemptAction } = useBlockchainActionProgress({
    executeAction,
    action,
    onError: trackBlockchainError({ blockchainAction: "approval" }),
    errorTags,
  })

  const assets = actions.map(({ asset }) => asset)

  const asset = action.asset

  return (
    <BlockchainActionModalContent>
      <BlockchainActionModalContent.Header>
        <BlockchainActionModalContent.Title>
          {t(
            "blockchain.paymentAssetApproval.title",
            {
              "0": "Approve currencies",
              one: "Approve currency",
              other: "Approve currencies",
            },
            { count: actions.length },
          )}
        </BlockchainActionModalContent.Title>
      </BlockchainActionModalContent.Header>
      <BlockchainActionModalContent.Body>
        <Item variant="unstyled">
          <Item.Avatar size={84}>
            <StackedAssetMedia
              assets={assets.slice(actionIndex)}
              backgroundCover={
                THEMES[theme].colors.components.elevation.level2.background
              }
              isLoading={!!transactionHash}
              overrides={{ AssetMedia: { props: { objectFit: "none" } } }}
              variant="small"
            />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>
              <Text asChild size="medium" weight="semibold">
                <div>{asset.symbol}</div>
              </Text>
            </Item.Title>
            {transactionHash && (
              <Item.Description>
                <Link
                  href={getTransactionUrl(
                    asset.chain.identifier,
                    transactionHash,
                  )}
                >
                  {t("explorerLink", "View on {{explorerLink}}", {
                    explorerLink: getBlockExplorerName(asset.chain.identifier),
                  })}
                </Link>
              </Item.Description>
            )}
          </Item.Content>

          {actions.length > 1 && (
            <Item.Side>
              <Text asChild size="medium" weight="semibold">
                <div>
                  {t("progressText", "{{currentNumber}} of {{totalNumber}}", {
                    currentNumber: actionIndex + 1,
                    totalNumber: actions.length,
                  })}
                </div>
              </Text>
            </Item.Side>
          )}
        </Item>

        <BlockchainActionModalContent.Body.Separator />
        <BlockchainActionModalContent.Body.GoToWallet />
        <BlockchainActionModalContent.Body.Text>
          {t(
            "blockchain.paymentAssetApproval.prompt",
            "You'll be asked to approve the use of {{symbol}} from your wallet. You only need to do this once.",
            {
              symbol: <Text weight="semibold">{asset.symbol}</Text>,
            },
          )}
        </BlockchainActionModalContent.Body.Text>

        {!progress && (
          <BlockchainActionModalContent.Body.Button onClick={attemptAction}>
            {t("blockchain.paymentAssetApproval.cta", "Continue")}
          </BlockchainActionModalContent.Body.Button>
        )}
      </BlockchainActionModalContent.Body>
    </BlockchainActionModalContent>
  )
}
