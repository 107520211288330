import React, { createContext, useMemo, useContext } from "react"
import { noop } from "lodash"
import { BigNumber } from "@/lib/helpers/numberUtils"
import { useFetchNativeCurrencyBalance } from "./useFetchNativeCurrencyBalance"

type NativeCurrencyBalanceContextType = {
  nativeCurrencyBalance: BigNumber | undefined
  refreshNativeCurrencyBalance: () => void
  errors: Partial<
    Record<
      keyof Omit<
        NativeCurrencyBalanceContextType,
        "errors" | "refreshNativeCurrencyBalance"
      >,
      boolean
    >
  >
}

const DEFAULT_CONTEXT: NativeCurrencyBalanceContextType = {
  nativeCurrencyBalance: undefined,
  refreshNativeCurrencyBalance: noop,
  errors: {},
}

const NativeCurrencyBalanceContext = createContext(DEFAULT_CONTEXT)

type Props = {
  children: React.ReactNode
}

export const NativeCurrencyBalanceProvider = ({ children }: Props) => {
  const {
    balance: nativeCurrencyBalance,
    hasError: nativeCurrencyBalanceHasError,
    refetchBalance,
  } = useFetchNativeCurrencyBalance()

  const value = useMemo(
    () => ({
      nativeCurrencyBalance,
      refreshNativeCurrencyBalance: refetchBalance,
      errors: {
        nativeCurrencyBalance: nativeCurrencyBalanceHasError,
      },
    }),
    [nativeCurrencyBalance, nativeCurrencyBalanceHasError, refetchBalance],
  )

  return (
    <NativeCurrencyBalanceContext.Provider value={value}>
      {children}
    </NativeCurrencyBalanceContext.Provider>
  )
}

export const useNativeCurrencyBalance = () =>
  useContext(NativeCurrencyBalanceContext)
