/**
 * @generated SignedSource<<a9a2714cd234a3afca23e37d502cdd71>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
export type useRefreshFundsMutation$variables = {};
export type useRefreshFundsMutation$data = {
  readonly wallet: {
    readonly refreshFunds: ReadonlyArray<{
      readonly chain: ChainIdentifier;
      readonly quantity: string;
      readonly symbol: string;
    }>;
  };
};
export type useRefreshFundsMutation = {
  response: useRefreshFundsMutation$data;
  variables: useRefreshFundsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chain",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useRefreshFundsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WalletMutationType",
        "kind": "LinkedField",
        "name": "wallet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WalletFundsType",
            "kind": "LinkedField",
            "name": "refreshFunds",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useRefreshFundsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WalletMutationType",
        "kind": "LinkedField",
        "name": "wallet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WalletFundsType",
            "kind": "LinkedField",
            "name": "refreshFunds",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "22a97e751672f1ff6a122309a4a375ef",
    "id": null,
    "metadata": {},
    "name": "useRefreshFundsMutation",
    "operationKind": "mutation",
    "text": "mutation useRefreshFundsMutation {\n  wallet {\n    refreshFunds {\n      symbol\n      chain\n      quantity\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "87c4c23dda9c466a7bad6fa2a5ed81c7";

export default node;
