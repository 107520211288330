import styled from "styled-components"
import { Overflow } from "@/components/common/Overflow"

export const ItemSideOverflow = styled(Overflow)`
  display: flex;
  justify-content: flex-end;
`

export const ItemSideOverflowStacked = styled(Overflow)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
