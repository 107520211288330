import React, { useState } from "react"
import { FlexColumn, PhoneInput, Text } from "@opensea/ui-kit"
import { Button } from "@/design-system/Button"
import { Form } from "@/design-system/Form"
import { FormControl } from "@/design-system/FormControl"
import { Modal } from "@/design-system/Modal"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { SIGNUP_STEPS } from "@/features/os-wallet/components/constants"
import { useOSWalletAuth } from "@/features/os-wallet/hooks/useOSWalletAuth"
import { useForm } from "@/hooks/useForm"
import { useTranslate } from "@/hooks/useTranslate"
import { WalletMfaEnrollmentModalProps } from "./WalletMfaEnrollmentModal.react"
import { WalletModalHeader } from "./WalletModalHeader.react"
import { WalletPhoneCodeModal } from "./WalletPhoneCodeModal.react"

type FormData = {
  phoneNumber: string
}

const SUPPORTED_COUNTRIES = ["us", "ca"]

const US_CANADA_PHONE_NUMBER_VALIDATION =
  /^(\+1\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

export const WalletPhoneNumberModal = ({
  accessToken,
  privyId,
}: WalletMfaEnrollmentModalProps) => {
  const t = useTranslate("common")
  const { onNext } = useMultiStepFlowContext()
  const { initMfaEnrollmentWithSms } = useOSWalletAuth()
  const [submitError, setSubmitError] = useState<boolean>(false)

  const {
    formState: { errors, isSubmitting, isValid },
    register,
    handleSubmit,
  } = useForm<FormData>({
    mode: "onSubmit",
  })

  const onSubmit = handleSubmit(async ({ phoneNumber }) => {
    setSubmitError(false)
    try {
      await initMfaEnrollmentWithSms(phoneNumber)
      onNext(() => (
        <WalletPhoneCodeModal
          accessToken={accessToken}
          phoneNumber={phoneNumber}
          privyId={privyId}
        />
      ))
    } catch {
      setSubmitError(true)
    }
  })

  return (
    <Modal.Body
      className="rounded-[12px]"
      data-testid="wallet-auth-modal"
      style={{ padding: 0 }}
    >
      <FlexColumn className="relative max-h-[760px] overflow-auto rounded-t-[12px] bg-base-2">
        <WalletModalHeader step={4} totalSteps={SIGNUP_STEPS}>
          <Text.Heading asChild className="mt-6" responsive size="small">
            <h2>
              {t("wallet.opensea.enterPhoneNumber.title", "Enter phone number")}
            </h2>
          </Text.Heading>
          <Text asChild className="mt-1" color="secondary" size="small">
            <h3>
              {t(
                "wallet.opensea.enterPhoneNumber.description",
                "Receive 6-digit code via text message",
              )}
            </h3>
          </Text>
        </WalletModalHeader>
        <Form className="mx-6 mb-6 flex flex-col" onSubmit={onSubmit}>
          <FormControl
            error={
              submitError
                ? t(
                    "wallet.opensea.phoneNumber.error",
                    "Unable to send code to this phone number",
                  )
                : errors.phoneNumber?.message
            }
            hideLabel
            label={t("wallet.opensea.phoneNumber.label", "Phone number")}
          >
            <PhoneInput
              className="flex-1 bg-white p-0 dark:bg-component-gray-1"
              overrides={{
                Select: {
                  className: "ml-[1px]",
                  overrides: {
                    Content: {
                      // Must be higher than design-system Overlay z-index: 100,
                      // otherwise dropdown appears behind the overlay.
                      className: "z-[200]",
                    },
                  },
                },
              }}
              supportedCountries={SUPPORTED_COUNTRIES}
              {...register("phoneNumber", {
                required: t(
                  "wallet.opensea.phoneNumber.required",
                  "Phone number is required.",
                ),
                pattern: {
                  value: US_CANADA_PHONE_NUMBER_VALIDATION,
                  message: t(
                    "wallet.opensea.phoneNumber.invalid",
                    "Invalid phone number",
                  ),
                },
              })}
            />
          </FormControl>
          <Button
            className="mt-[184px]"
            disabled={!isValid || isSubmitting}
            type="submit"
            variant="primary"
          >
            {t("wallet.opensea.sendCode", "Send code")}
          </Button>
        </Form>
      </FlexColumn>
    </Modal.Body>
  )
}
