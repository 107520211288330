/**
 * @generated SignedSource<<233f7e6981e51dfd4b8e2aa8a9f3c837>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SettingsMenuQuery$variables = {
  identity: string;
};
export type SettingsMenuQuery$data = {
  readonly getAccount: {
    readonly canViewVerificationForm: boolean;
  };
};
export type SettingsMenuQuery = {
  response: SettingsMenuQuery$data;
  variables: SettingsMenuQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "identity"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "address",
    "variableName": "identity"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canViewVerificationForm",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsMenuQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "getAccount",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SettingsMenuQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "getAccount",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "309a9d65f041eb871ba91357ea6d44c1",
    "id": null,
    "metadata": {},
    "name": "SettingsMenuQuery",
    "operationKind": "query",
    "text": "query SettingsMenuQuery(\n  $identity: AddressScalar!\n) {\n  getAccount(address: $identity) {\n    canViewVerificationForm\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8f8f1b590d768be346944b8d2c95727c";

export default node;
