/**
 * @generated SignedSource<<b9731beb6aad088a5e5bbea932da127d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShoppingCartPaymentMethodQuery$variables = {
  listingId: string;
};
export type ShoppingCartPaymentMethodQuery$data = {
  readonly blockchain: {
    readonly bulkPurchase: {
      readonly orders: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"usePaymentMethod_order">;
      }>;
    };
  };
  readonly moonpay: {
    readonly " $fragmentSpreads": FragmentRefs<"usePaymentMethod_moonpay">;
  };
};
export type ShoppingCartPaymentMethodQuery = {
  response: ShoppingCartPaymentMethodQuery$data;
  variables: ShoppingCartPaymentMethodQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "listingId"
  }
],
v1 = [
  {
    "items": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "order",
            "variableName": "listingId"
          }
        ],
        "kind": "ObjectValue",
        "name": "ordersToFill.0"
      }
    ],
    "kind": "ListValue",
    "name": "ordersToFill"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "listingId",
    "variableName": "listingId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShoppingCartPaymentMethodQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BlockchainType",
        "kind": "LinkedField",
        "name": "blockchain",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "BulkPurchaseType",
            "kind": "LinkedField",
            "name": "bulkPurchase",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderV2Type",
                "kind": "LinkedField",
                "name": "orders",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "usePaymentMethod_order"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MoonpayType",
        "kind": "LinkedField",
        "name": "moonpay",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "FragmentSpread",
            "name": "usePaymentMethod_moonpay"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShoppingCartPaymentMethodQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BlockchainType",
        "kind": "LinkedField",
        "name": "blockchain",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "BulkPurchaseType",
            "kind": "LinkedField",
            "name": "bulkPurchase",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderV2Type",
                "kind": "LinkedField",
                "name": "orders",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "orderType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "item",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v4/*: any*/)
                        ],
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "itemQuantityType",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MoonpayType",
        "kind": "LinkedField",
        "name": "moonpay",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "kind": "ScalarField",
            "name": "fiatCheckoutAvailability",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6f3bdbe30be134d74a3e4730ad9bc865",
    "id": null,
    "metadata": {},
    "name": "ShoppingCartPaymentMethodQuery",
    "operationKind": "query",
    "text": "query ShoppingCartPaymentMethodQuery(\n  $listingId: OrderRelayID!\n) {\n  blockchain {\n    bulkPurchase(ordersToFill: [{order: $listingId}]) {\n      orders {\n        ...usePaymentMethod_order\n        id\n      }\n    }\n  }\n  moonpay {\n    ...usePaymentMethod_moonpay_43Fs8X\n  }\n}\n\nfragment useIsAvailableForBuyWithCard_data_43Fs8X on MoonpayType {\n  fiatCheckoutAvailability(listingId: $listingId)\n}\n\nfragment usePaymentMethod_moonpay_43Fs8X on MoonpayType {\n  ...useIsAvailableForBuyWithCard_data_43Fs8X\n}\n\nfragment usePaymentMethod_order on OrderV2Type {\n  relayId\n  orderType\n  item {\n    __typename\n    relayId\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  itemQuantityType\n}\n"
  }
};
})();

(node as any).hash = "7038b6fecd2de4750c47a4f47310daa1";

export default node;
