import { EthereumProvider } from "@ledgerhq/connect-kit-loader"
import { ethers } from "ethers"
import { WALLET_NAME } from "@/constants/wallet"
import { Chain } from "@/hooks/useChains/types"
import { WALLET_CONNECT_CLOUD_PROJECT_ID } from "./WalletConnect/constants"
import Web3EvmProvider, {
  ETHERS_WEB3_PROVIDER_NETWORK,
  Web3Provider,
} from "./web3EvmProvider"

class LedgerConnectProvider extends Web3EvmProvider {
  provider: Web3Provider
  ledgerConnect: EthereumProvider

  constructor(
    provider: Web3Provider,
    ledgerConnect: EthereumProvider,
    chains: Chain[],
  ) {
    super(chains)
    this.provider = provider
    this.ledgerConnect = ledgerConnect
  }

  connect = async () => {
    const accounts = (await this.ledgerConnect.request({
      method: "eth_requestAccounts",
    })) as string[]
    return this.mapToAccounts(accounts)
  }

  disconnect = () => {
    this.ledgerConnect.disconnect?.()
  }

  getName = () => {
    return WALLET_NAME.LedgerConnect
  }
}

export const createLedgerConnectProvider = async (
  chains: Chain[],
  isLedgerConnectKitDebugLogsEnabled: boolean = false,
): Promise<LedgerConnectProvider> => {
  const { loadConnectKit, SupportedProviders } = await import(
    "@ledgerhq/connect-kit-loader"
  )
  const connectKit = await loadConnectKit()

  if (isLedgerConnectKitDebugLogsEnabled) {
    connectKit.enableDebugLogs()
  }

  connectKit.checkSupport({
    chainId: 1,
    projectId: WALLET_CONNECT_CLOUD_PROJECT_ID,
    providerType: SupportedProviders.Ethereum,
    rpcMap: {
      1: "https://cloudflare-eth.com/", // Mainnet
      137: "https://polygon-rpc.com/", // Polygon
    },
    walletConnectVersion: 2,
  })

  // Side-effect of calling `checkSupport` above is it constrains the type
  const externalProvider =
    (await connectKit.getProvider()) as unknown as EthereumProvider

  const provider = new ethers.providers.Web3Provider(
    externalProvider,
    ETHERS_WEB3_PROVIDER_NETWORK,
  )

  return new LedgerConnectProvider(provider, externalProvider, chains)
}
