/**
 * @generated SignedSource<<5906d1709f239e4a93fa88c44b3baaa2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useHandleBlockchainActions_swap_asset$data = {
  readonly method: {
    readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_transaction">;
  };
  readonly " $fragmentType": "useHandleBlockchainActions_swap_asset";
};
export type useHandleBlockchainActions_swap_asset$key = {
  readonly " $data"?: useHandleBlockchainActions_swap_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_swap_asset">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useHandleBlockchainActions_swap_asset"
};

(node as any).hash = "e94864996108d04a0dda0288d9db9822";

export default node;
