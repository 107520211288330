import React from "react"
import { CenterAligned, FlexColumn, Text } from "@opensea/ui-kit"
import { Link } from "@/components/common/Link"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { SUPPORT_URL, TERMS_OF_SERVICE_URL } from "@/constants"
import { useActiveAccount } from "@/containers/WalletProvider/WalletProvider.react"
import { BulkCancelOrdersSkeleton } from "@/features/settings/components/BulkCancelOrders"
import { BulkCancelOrdersButton } from "@/features/settings/components/BulkCancelOrders/BulkCancelOrdersButton.react"
import { useTranslate } from "@/hooks/useTranslate"

export const AccountBannedContent = () => {
  const t = useTranslate("components")
  const activeAccount = useActiveAccount()

  const noLongerVisible = activeAccount?.metadata?.isBanned
    ? t(
        "accountBanned.noLongerVisible",
        "While your collections and any items in those collections are no longer visible or discoverable on OpenSea, you still have access to your wallet and NFTs in your wallet through other services.",
      )
    : t(
        "accountDisabled.noLongerVisible",
        "Your collections and any items in those collections will remain visible, but can no longer be bought, sold, or transferred using OpenSea.",
      )

  return (
    <CenterAligned className="xl:px-[20%] xl:py-6">
      <Text.Display asChild className="text-center" size="medium">
        <h1>{t("accountBanned.title", "Your account has been disabled")}</h1>
      </Text.Display>

      <FlexColumn className="mt-10 gap-4">
        <Text.Body className="text-center" size="medium">
          {t(
            "accountBanned.description",
            "Your account has been disabled due to activity that goes against our {{tosLink}}. This means you can no longer access OpenSea with your account.",
            {
              tosLink: (
                <Link href={TERMS_OF_SERVICE_URL}>
                  {t("accountBanned.tosLink", "Terms of Service")}
                </Link>
              ),
            },
          )}
        </Text.Body>

        <Text.Body className="text-center" size="medium">
          {noLongerVisible}
        </Text.Body>
        <Text.Body className="text-center" size="medium">
          {t(
            "accountBanned.canCancel",
            "You can cancel any active offers and listings you made using OpenSea below.",
          )}
        </Text.Body>
        <Text.Body className="pb-6 text-center" size="medium">
          {t(
            "accountBanned.contactSupport",
            "If you think there's been a mistake, you can contact our {{supportTeamLink}} for help.",
            {
              supportTeamLink: (
                <Link href={SUPPORT_URL}>
                  {t("accountBanned.supportTeamLink", "support team")}
                </Link>
              ),
            },
          )}
        </Text.Body>
      </FlexColumn>
      <SsrSuspense fallback={<BulkCancelOrdersSkeleton />}>
        <BulkCancelOrdersButton />
      </SsrSuspense>
    </CenterAligned>
  )
}
