/**
 * @generated SignedSource<<b96369102de390dfd037d895bf7de9af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShoppingCartDetailedView_errors$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"OrderList_errors" | "useTotalPrice_errors">;
  readonly " $fragmentType": "ShoppingCartDetailedView_errors";
}>;
export type ShoppingCartDetailedView_errors$key = ReadonlyArray<{
  readonly " $data"?: ShoppingCartDetailedView_errors$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShoppingCartDetailedView_errors">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ShoppingCartDetailedView_errors",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useTotalPrice_errors"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrderList_errors"
    }
  ],
  "type": "BulkPurchaseErrorType",
  "abstractKey": null
};

(node as any).hash = "78f0458fa30d77875d96adce8bd430b8";

export default node;
