import * as sidecar from "../../server/sidecar/config"
import type { Environment } from "./schema"

const getEnvironmentFromNodeEnv = (): Environment => {
  switch (process.env.NODE_ENV) {
    case "development":
      return "development"
    case "test":
      return "test:unit"
    case "production":
      return "production"
    default:
      return "development"
  }
}

export const getEnvironment = (): Environment => {
  const sidecarEnvironment = sidecar.getValue("environment")
  if (sidecarEnvironment) {
    return sidecarEnvironment
  }
  return getEnvironmentFromNodeEnv()
}

export const IS_PRODUCTION = process.env.NODE_ENV === "production"
export const IS_DEVELOPMENT = getEnvironment() === "development"
export const IS_PREVIEW = getEnvironment() === "preview"
export const IS_TEST = process.env.NODE_ENV === "test"
export const IS_STORYBOOK = process.env.STORYBOOK_ON === "true"
export const IS_SERVER = typeof window === "undefined"
