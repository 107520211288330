/**
 * @generated SignedSource<<644e9dc6638c6d479c900e79f5ff9bc7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useHandleBlockchainActions_mint_your_own_collection$data = {
  readonly method: {
    readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_transaction">;
  };
  readonly " $fragmentType": "useHandleBlockchainActions_mint_your_own_collection";
};
export type useHandleBlockchainActions_mint_your_own_collection$key = {
  readonly " $data"?: useHandleBlockchainActions_mint_your_own_collection$data;
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_mint_your_own_collection">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useHandleBlockchainActions_mint_your_own_collection"
};

(node as any).hash = "821405f4dc4da032825e2aa6cb2ea084";

export default node;
