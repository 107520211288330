import React from "react"
import { Flex, Skeleton } from "@opensea/ui-kit"
import { range } from "lodash"
import styled from "styled-components"

const PreviewSkeleton = ({
  count,
  imageSize,
}: {
  count: number
  imageSize: number
}) => {
  return (
    <_PreviewList count={count}>
      {range(count).map((_, i) => (
        <PreviewItem key={i}>
          <Skeleton.Block style={{ height: imageSize, width: imageSize }} />
        </PreviewItem>
      ))}
    </_PreviewList>
  )
}

const _PreviewList = styled(Flex)<{ count: number }>`
  height: 100px;
  width: ${props => props.count * 100}px;
  grid-gap: 2px;
`

const PreviewItemItem = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`

const PreviewItemBorder = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid ${props => props.theme.colors.components.border.level1};
  pointer-events: none;
`

const PreviewItemWrapper = styled(Flex)`
  position: relative;
  width: 100px;
  height: 100px;
  &:first-child > * {
    border-top-left-radius: ${({ theme }) => theme.borderRadius.list};
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius.list};
  }
  &:last-child > * {
    border-top-right-radius: ${({ theme }) => theme.borderRadius.list};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius.list};
  }
`

type PreviewItemProps = {
  children: React.ReactNode
}

const PreviewItem = ({ children }: PreviewItemProps) => (
  <PreviewItemWrapper>
    <PreviewItemItem>{children}</PreviewItemItem>
    <PreviewItemBorder />
  </PreviewItemWrapper>
)

export const PreviewList = Object.assign(_PreviewList, {
  Item: PreviewItem,
  Skeleton: PreviewSkeleton,
})
