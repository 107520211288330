import React, { createContext, forwardRef, useContext } from "react"
import { breakpoints } from "@opensea/ui-kit"
import styled from "styled-components"
import { IS_PRODUCTION } from "@/constants/environment"
import { Block, BlockProps } from "@/design-system/Block"

type GridContextType = {
  level: number
}

export const GridContext = createContext<GridContextType>({
  level: 0,
})

// Note (@auster-eth): Manually list it out instead of using types due to better preview in VSCode
type GridItemSpanConfig = {
  xs?: 6 | 12
  sm?: 6 | 12
  md?: 3 | 6 | 9 | 12
  lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  xl?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  xxl?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  xxxl?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
}

export type GridItemProps = GridItemSpanConfig & BlockProps

export type GridProps = {
  /**
   * Optional prop to configure the gap between rows
   */
  gridRowGap?: number
  gridColumnGap?: number
  columns?: number
  children:
    | React.ReactElement<GridItemProps>[]
    | React.ReactElement<GridItemProps>
} & BlockProps

const MAX_NESTED_LEVEL_ALLOWED = 2
const MAX_COLUMNS = 12

const GridComponent = forwardRef<HTMLDivElement, GridProps>(
  function _GridComponent(props, ref) {
    const { level } = useContext(GridContext)

    if (level >= MAX_NESTED_LEVEL_ALLOWED && !IS_PRODUCTION) {
      throw new Error(
        `Grid is only allowed to be nested a maximum of ${MAX_NESTED_LEVEL_ALLOWED} layers`,
      )
    }

    return (
      <GridContext.Provider value={{ level: level + 1 }}>
        <GridContainer {...props} ref={ref} />
      </GridContext.Provider>
    )
  },
)

const GridContainer = styled(Block)<GridProps>`
  --template-grid-gap: 8px;

  --template-grid-row-gap: ${props =>
    props.gridRowGap !== undefined ? `${props.gridRowGap}px` : "8px"};

  --template-grid-column-gap: ${props =>
    props.gridColumnGap !== undefined ? `${props.gridColumnGap}px` : "8px"};

  --template-num-columns: ${props => props.columns ?? MAX_COLUMNS};

  display: grid;
  grid-template-columns: repeat(var(--template-num-columns), 1fr);
  grid-gap: var(--template-grid-gap);
  grid-row-gap: var(--template-grid-row-gap);
  grid-column-gap: var(--template-grid-column-gap);

  @media (min-width: ${breakpoints.md}px) {
    --template-grid-gap: 16px;
  }

  @media (min-width: ${breakpoints.xxl}px) {
    --template-grid-gap: 24px;
  }
`

type BreakpointKeys = keyof GridItemSpanConfig

const largeToSmallSpanConfigKeys: Array<BreakpointKeys> = [
  "xxxl",
  "xxl",
  "xl",
  "lg",
  "md",
  "sm",
  "xs",
]

const largeToSmallSpanConfigIndexMap: Record<BreakpointKeys, number> = {
  xxxl: 0,
  xxl: 1,
  xl: 2,
  lg: 3,
  md: 4,
  sm: 5,
  xs: 6,
}

function getColSpanSize({
  targetBreakpoint,
  ...breakpointConfiguration
}: GridItemProps & {
  targetBreakpoint: BreakpointKeys
}) {
  if (breakpointConfiguration[targetBreakpoint] !== undefined) {
    return breakpointConfiguration[targetBreakpoint]
  }

  // target and provided breakpoint configuration don't match
  const targetBreakpointIndex = largeToSmallSpanConfigIndexMap[targetBreakpoint]
  const nextSmallestBreakpointSizeToUse = largeToSmallSpanConfigKeys.find(
    (key, index) =>
      !!breakpointConfiguration[key] && targetBreakpointIndex <= index,
  )

  return nextSmallestBreakpointSizeToUse
    ? breakpointConfiguration[nextSmallestBreakpointSizeToUse]
    : MAX_COLUMNS
}

const GridItem = styled(Block)<GridItemProps>`
  // Force grid item to conform to columns
  min-width: 0;
  --grid-item-col-span: ${props =>
    getColSpanSize({ targetBreakpoint: "xs", ...props })};

  grid-column: span var(--grid-item-col-span);

  @media (min-width: ${breakpoints.sm}px) {
    --grid-item-col-span: ${props =>
      getColSpanSize({ targetBreakpoint: "sm", ...props })};
  }

  @media (min-width: ${breakpoints.md}px) {
    --grid-item-col-span: ${props =>
      getColSpanSize({ targetBreakpoint: "md", ...props })};
  }

  @media (min-width: ${breakpoints.lg}px) {
    --grid-item-col-span: ${props =>
      getColSpanSize({ targetBreakpoint: "lg", ...props })};
  }

  @media (min-width: ${breakpoints.xl}px) {
    --grid-item-col-span: ${props =>
      getColSpanSize({ targetBreakpoint: "xl", ...props })};
  }

  @media (min-width: ${breakpoints.xxl}px) {
    --grid-item-col-span: ${props =>
      getColSpanSize({ targetBreakpoint: "xxl", ...props })};
  }

  @media (min-width: ${breakpoints.xxxl}px) {
    --grid-item-col-span: ${props =>
      getColSpanSize({ targetBreakpoint: "xxxl", ...props })};
  }
`

export const Grid = Object.assign(GridComponent, { Item: GridItem })
