/**
 * @generated SignedSource<<c0b32dfd3ce0d11192d9661a444f3ccc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ClientSignatureStandard = "PERSONAL" | "SOLANA" | "SOLANA_MESSAGE" | "TYPED_DATA_V4" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useHandleBlockchainActions_cancel_orders$data = {
  readonly method: {
    readonly __typename: "GaslessCancelType";
    readonly orderRelayIds: ReadonlyArray<string>;
  } | {
    readonly __typename: "SignAndPostOrderCancelType";
    readonly cancelOrderData: {
      readonly message: string;
      readonly payload: string;
    };
    readonly clientSignatureStandard: ClientSignatureStandard;
    readonly serverSignature: string;
  } | {
    readonly __typename: "TransactionSubmissionDataType";
    readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_transaction">;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
  readonly " $fragmentType": "useHandleBlockchainActions_cancel_orders";
};
export type useHandleBlockchainActions_cancel_orders$key = {
  readonly " $data"?: useHandleBlockchainActions_cancel_orders$data;
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_cancel_orders">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useHandleBlockchainActions_cancel_orders"
};

(node as any).hash = "c66b9fd364242022592c8c7199dea239";

export default node;
