/**
 * @generated SignedSource<<db57d9be9d7930709415563a5a09d95c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewFeatureAnnouncementBanner_data$data = {
  readonly ctaText: string;
  readonly heading: string;
  readonly headingMobile: string;
  readonly icon: string | null;
  readonly isClosable: boolean | null;
  readonly text: string;
  readonly url: string | null;
  readonly " $fragmentType": "NewFeatureAnnouncementBanner_data";
};
export type NewFeatureAnnouncementBanner_data$key = {
  readonly " $data"?: NewFeatureAnnouncementBanner_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewFeatureAnnouncementBanner_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewFeatureAnnouncementBanner_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "heading",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "headingMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctaText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isClosable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "icon",
      "storageKey": null
    }
  ],
  "type": "AnnouncementBannerType",
  "abstractKey": null
};

(node as any).hash = "123e39696e50f3bed5f62dade750b6c2";

export default node;
