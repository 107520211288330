/**
 * @generated SignedSource<<6442add58963006fedb6b36689693c68>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetMediaAudio_asset$data = {
  readonly backgroundColor: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"AssetMediaImage_asset">;
  readonly " $fragmentType": "AssetMediaAudio_asset";
};
export type AssetMediaAudio_asset$key = {
  readonly " $data"?: AssetMediaAudio_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssetMediaAudio_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssetMediaAudio_asset",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backgroundColor",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssetMediaImage_asset"
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "365b45bee42cced559eb45ab2237e078";

export default node;
