import { ChainIdentifier } from "@/hooks/useChains/types"

let activeChain: ChainIdentifier | null | undefined

export const getActiveChain = () => {
  return activeChain
}

export const setActiveChain = (chain: ChainIdentifier | null | undefined) => {
  activeChain = chain
}
