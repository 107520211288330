import React from "react"
import { UnstyledButton } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { Link, LinkProps } from "@/components/common/Link"
import { Overflow } from "@/components/common/Overflow"
import { CollectionStatusModal } from "@/components/modals/CollectionStatusModal.react"
import { Modal } from "@/design-system/Modal"
import { CollectionLink_assetContract$key } from "@/lib/graphql/__generated__/CollectionLink_assetContract.graphql"
import { CollectionLink_collection$key } from "@/lib/graphql/__generated__/CollectionLink_collection.graphql"
import { graphql } from "@/lib/graphql/graphql"
import {
  getCollectionUrl,
  readCollectionUrlIdentifier,
} from "@/lib/helpers/collection"
import { selectClassNames } from "@/lib/helpers/styling"
import { verificationStatusHasBadge } from "@/lib/helpers/verification"
import { CollectionTooltip } from "../CollectionTooltip.react"
import { VerificationIcon } from "../VerificationIcon.react"

export type CollectionLinkProps = Pick<
  LinkProps,
  "target" | "tabIndex" | "onClick" | "variant"
> & {
  collection: CollectionLink_collection$key
  isSmall?: boolean
  isTiny?: boolean
  assetContract?: CollectionLink_assetContract$key | null
  linkStyle?: React.CSSProperties
  className?: string
  withTooltip?: boolean
  disabled?: boolean
}

export const CollectionLink = ({
  collection: collectionKey,
  isSmall,
  isTiny,
  assetContract: assetContractKey = null,
  linkStyle,
  className,
  withTooltip = false,
  target = undefined,
  disabled,
  tabIndex,
  variant,
  onClick,
}: CollectionLinkProps) => {
  const collection = useFragment(
    graphql`
      fragment CollectionLink_collection on CollectionType {
        name
        slug
        verificationStatus
        ...collection_url
      }
    `,
    collectionKey,
  )
  const assetContract = useFragment(
    graphql`
      fragment CollectionLink_assetContract on AssetContractType {
        address
        blockExplorerLink
      }
    `,
    assetContractKey,
  )

  const verificationStatus = collection.verificationStatus
  const { slug } = readCollectionUrlIdentifier(collection)

  return (
    <DivContainer className={className}>
      <Link
        className={selectClassNames("CollectionLink", {
          link: true,
          isSmall,
          isTiny,
        })}
        disabled={disabled}
        href={getCollectionUrl(collection)}
        style={linkStyle}
        tabIndex={tabIndex}
        target={target}
        variant={variant}
        onClick={onClick}
      >
        <CollectionTooltip disabled={!withTooltip} slug={slug}>
          <Overflow
            lines={1}
            overrides={{ Tooltip: { disabled: withTooltip } }}
          >
            {collection.name}
          </Overflow>
        </CollectionTooltip>
      </Link>

      {!verificationStatusHasBadge(
        verificationStatus,
      ) ? null : assetContract ? (
        <Modal
          trigger={open => (
            <UnstyledButton
              tabIndex={tabIndex}
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()
                open()
              }}
            >
              <VerificationIcon
                // TODO: Remove after fixing styling on this tooltip for this page
                showTooltip={false}
                size={isTiny ? "tiny" : "small"}
                verificationStatus={verificationStatus}
              />
            </UnstyledButton>
          )}
        >
          <CollectionStatusModal
            address={assetContract.address}
            blockExplorerLink={assetContract.blockExplorerLink}
            verificationStatus={verificationStatus}
          />
        </Modal>
      ) : (
        <VerificationIcon
          // TODO: Remove after fixing styling on this tooltip for this page
          showTooltip={false}
          size={isSmall ? "small" : isTiny ? "tiny" : undefined}
          verificationStatus={verificationStatus}
        />
      )}
    </DivContainer>
  )
}

const DivContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;

  &.CollectionLink--isEditor {
    display: inline-block;
  }

  .CollectionLink--link {
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.CollectionLink--isSmall {
      font-size: 14px;
    }
  }
`
