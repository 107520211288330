import React from "react"
import { FlexColumn, Icon, Spinner, Text, classNames } from "@opensea/ui-kit"
import { Flex } from "@/design-system/Flex"
import { Modal } from "@/design-system/Modal"
import { StepIndicator } from "@/features/primary-drops/components/StepIndicator/StepIndicator.react"
import { useTranslate } from "@/hooks/useTranslate"

type LOADING_STATE = 0 | 1 | 2

export const LOADING_CONTENT_STATE = {
  UPLOADING_TO_IPFS: 0,
  WAITING_SIGNATURE: 1,
  PENDING_TRANSACTION: 2,
} as const

const STEPS = [
  LOADING_CONTENT_STATE.UPLOADING_TO_IPFS,
  LOADING_CONTENT_STATE.WAITING_SIGNATURE,
  LOADING_CONTENT_STATE.PENDING_TRANSACTION,
] as const

type Props = {
  state: LOADING_STATE
  customRender?: React.ReactNode
}

export const MintYourOwnProgress = ({ state, customRender }: Props) => {
  const t = useTranslate("creatorStudio")

  const ariaLabel = useAriaLabel()
  const copy = useCopyContent()

  return (
    <>
      <Modal.Header>
        <Modal.Header.Title>
          {t("createNFT.loading.title", "Creating your item")}
        </Modal.Header.Title>
      </Modal.Header>
      <Modal.Body>
        <FlexColumn aria-label={ariaLabel[state]} role="dialog">
          {STEPS.map(loadingState => {
            return (
              <>
                <Flex className="relative">
                  {loadingState < 2 && <Line />}
                  <StepIndicator.Circle
                    className={classNames("min-h-[48px] w-12 rounded-full ", {
                      "!border-solid border-level-2 border !bg-transparent":
                        state < loadingState,
                      "!bg-component-gray-1": state >= loadingState,
                    })}
                    isActive={false}
                    isPast={false}
                  >
                    {state === loadingState ? (
                      <Spinner />
                    ) : state > loadingState ? (
                      <Icon className="text-primary" size={32} value="done" />
                    ) : null}
                  </StepIndicator.Circle>
                  <FlexColumn className="mb-6 mt-2.5 pl-6">
                    <Text.Body size="medium" weight="semibold">
                      {copy[loadingState].title}
                    </Text.Body>
                    <Text.Body
                      className="mb-2 mt-0.5"
                      color="secondary"
                      size="small"
                    >
                      {copy[loadingState].description}
                    </Text.Body>
                    {state === loadingState && customRender}
                  </FlexColumn>
                </Flex>
              </>
            )
          })}
        </FlexColumn>
      </Modal.Body>
    </>
  )
}

const Line = () => (
  <Flex className="absolute -bottom-2.5 left-6 top-[58px] w-px bg-border-1" />
)

const useAriaLabel = () => {
  const t = useTranslate("collectionEdit")
  return {
    [LOADING_CONTENT_STATE.UPLOADING_TO_IPFS]: t(
      "createNFT.loading.settingUpTransaction.ariaDescription",
      "Setting up transaction dialog",
    ),
    [LOADING_CONTENT_STATE.WAITING_SIGNATURE]: t(
      "createNFT.loading.waitingSignature.ariaDescription",
      "Waiting for signature dialog",
    ),
    [LOADING_CONTENT_STATE.PENDING_TRANSACTION]: t(
      "createNFT.loading.pendingTransaction.ariaDescription",
      "Pending transaction confirmation dialog",
    ),
  }
}

const useCopyContent = () => {
  const t = useTranslate("creatorStudio")
  return {
    [LOADING_CONTENT_STATE.UPLOADING_TO_IPFS]: {
      title: t(
        "createNFT.loading.uploading.title",
        "Uploading to decentralized server",
      ),
      description: t(
        "createNFT.loading.uploading.description",
        "This may take a few minutes.",
      ),
    },
    [LOADING_CONTENT_STATE.WAITING_SIGNATURE]: {
      title: t(
        "createNFT.loading.waitingSignature.title",
        "Go to your wallet to approve this transaction",
      ),
      description: t(
        "createNFT.loading.waitingSignature.description",
        "A blockchain transaction is required to mint your NFT.",
      ),
    },
    [LOADING_CONTENT_STATE.PENDING_TRANSACTION]: {
      title: t(
        "createNFT.loading.pendingTransaction.title",
        "Minting your item",
      ),
      description: t(
        "createNFT.loading.pendingTransaction.description",
        "Please stay on this page and keep this browser tab open.",
      ),
    },
  }
}
