import React, { useCallback } from "react"
import { graphql, useFragment } from "react-relay"
import { useTranslate } from "@/hooks/useTranslate"
import { AssetTransferAction_data$key } from "@/lib/graphql/__generated__/AssetTransferAction_data.graphql"
import {
  BaseBlockchainActionProps,
  BlockchainActionModalContent,
} from "./BlockchainActionModalContent.react"
import { useBlockchainActionProgress } from "./useBlockchainActionProgress"
import { useHandleBlockchainActions } from "./useHandleBlockchainActions"

type Props = BaseBlockchainActionProps & {
  dataKey: AssetTransferAction_data$key
}

export const AssetTransferAction = ({ dataKey, onEnd, title }: Props) => {
  const t = useTranslate("components")
  const data = useFragment(
    graphql`
      fragment AssetTransferAction_data on AssetTransferActionType {
        __typename
        ...useHandleBlockchainActions_transfer_asset
      }
    `,
    dataKey,
  )

  const { transferAsset } = useHandleBlockchainActions()

  const executeAction = useCallback(async () => {
    const transaction = await transferAsset(data)

    onEnd({ transaction })
  }, [data, onEnd, transferAsset])

  const { attemptAction, progress } = useBlockchainActionProgress({
    executeAction,
    action: data,
  })

  return (
    <BlockchainActionModalContent>
      <BlockchainActionModalContent.Header>
        <BlockchainActionModalContent.Title>
          {title ?? t("blockchain.transfer.title", "Transfer your item")}
        </BlockchainActionModalContent.Title>
      </BlockchainActionModalContent.Header>
      <BlockchainActionModalContent.Body>
        <BlockchainActionModalContent.Body.GoToWallet />
        <BlockchainActionModalContent.Body.Text>
          {t(
            "blockchain.transfer.prompt",
            "You'll be asked to approve this transfer from your wallet.",
          )}
        </BlockchainActionModalContent.Body.Text>
        {!progress && (
          <BlockchainActionModalContent.Body.Button onClick={attemptAction}>
            {t("blockchain.transfer.cta", "Continue")}
          </BlockchainActionModalContent.Body.Button>
        )}
      </BlockchainActionModalContent.Body>
    </BlockchainActionModalContent>
  )
}
