import React from "react"
import { HUES } from "../../styles/themes"

type Props = {
  className?: string
  width?: number
  height?: number
  fill?: string
}

export const MediumLogo = ({
  className,
  fill = HUES.gray,
  height,
  width,
}: Props) => (
  <svg
    className={className}
    fill={fill}
    style={{ height, width }}
    viewBox="0 0 24 24"
  >
    <g clipPath="url(#clip0_2556_57001)">
      <path d="M13.5374 12.0045C13.5374 15.7684 10.5069 18.8196 6.76884 18.8196C3.03073 18.8196 0 15.7677 0 12.0045C0 8.24132 3.0305 5.18921 6.76884 5.18921C10.5072 5.18921 13.5374 8.24063 13.5374 12.0045Z" />
      <path d="M20.9549 12.0044C20.9549 15.5473 19.4396 18.4205 17.5705 18.4205C15.7013 18.4205 14.186 15.5473 14.186 12.0044C14.186 8.46158 15.7011 5.58838 17.5702 5.58838C19.4394 5.58838 20.9546 8.46066 20.9546 12.0044" />
      <path d="M23.9839 12.0046C23.9839 15.1782 23.4511 17.7524 22.7936 17.7524C22.1361 17.7524 21.6035 15.1788 21.6035 12.0046C21.6035 8.83039 22.1363 6.25684 22.7936 6.25684C23.4508 6.25684 23.9839 8.83016 23.9839 12.0046Z" />
    </g>
    <defs>
      <clipPath id="clip0_2556_57001">
        <rect height="13.6303" transform="translate(0 5.18921)" width="24" />
      </clipPath>
    </defs>
  </svg>
)
