import { useContext } from "react"
import { GlobalModalContext } from "@/containers/GlobalModalProvider.react"

/**
 * Enables using a global modal where a local modal is not possible, e.g. in
 * virtualized table rows where the row can be removed from the DOM during
 * re-rendering if it's updated in the background.
 *
 * This also supports MultiStepModal.
 *
 * TODO(janclarin): Maybe it makes sense for all modals to use this pattern
 *  since technically we should always only ever have one modal in the DOM.
 */
export const useGlobalModal = () => useContext(GlobalModalContext)
