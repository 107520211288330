import { IS_SERVER } from "@/constants/environment"
import { useRouter } from "./useRouter"

declare global {
  interface Window {
    IS_IOS_WEBVIEW?: boolean
  }
}

export const useIsIosWebview = () => {
  const router = useRouter()
  const iosWebviewQueryParam = router.query["ios_webview"]

  return !!iosWebviewQueryParam || (!IS_SERVER && window.IS_IOS_WEBVIEW)
}
