import React, { useCallback, useState } from "react"
import { graphql, useFragment } from "react-relay"
import { useTransaction } from "@/components/blockchain"
import { SwapDataHeader } from "@/components/blockchain/swaps/SwapDataHeader"
import { useTranslate } from "@/hooks/useTranslate"
import {
  useTrackBlockchainAttempt,
  useTrackBlockchainError,
} from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/hooks"
import { FulfillSwapOrderAction_data$key } from "@/lib/graphql/__generated__/FulfillSwapOrderAction_data.graphql"
import {
  BaseBlockchainActionProps,
  BlockchainActionModalContent,
} from "./BlockchainActionModalContent.react"
import { useBlockchainActionProgress } from "./useBlockchainActionProgress"
import { useHandleBlockchainActions } from "./useHandleBlockchainActions"

type Props = BaseBlockchainActionProps & {
  dataKey: FulfillSwapOrderAction_data$key
}

export const FulfillSwapOrderAction = ({ dataKey, onEnd }: Props) => {
  const t = useTranslate("components")
  const data = useFragment<FulfillSwapOrderAction_data$key>(
    graphql`
      fragment FulfillSwapOrderAction_data on FulfillSwapOrderActionType {
        __typename
        swapData {
          ...SwapDataHeader_swap
        }
        ...useHandleBlockchainActions_fulfill_swap_order
      }
    `,
    dataKey,
  )

  const { fulfillSwapOrder } = useHandleBlockchainActions()
  const [transactionHash, setTransactionHash] = useState<string>()
  const trackBlockchainAttempt = useTrackBlockchainAttempt()
  const [trackBlockchainError, errorTags] = useTrackBlockchainError()
  const { pollTransaction } = useTransaction()

  const executeAction = useCallback(async () => {
    trackBlockchainAttempt({ blockchainAction: "coreAction" })
    const transaction = await fulfillSwapOrder(data)
    setTransactionHash(transaction.transactionHash)

    await pollTransaction({ ...transaction, showMessageOnSuccess: true })

    setTransactionHash("")

    onEnd({ transaction })
  }, [data, fulfillSwapOrder, onEnd, pollTransaction, trackBlockchainAttempt])

  const { attemptAction, progress } = useBlockchainActionProgress({
    executeAction,
    action: data,
    onError: trackBlockchainError({ blockchainAction: "coreAction" }),
    errorTags,
  })

  return (
    <BlockchainActionModalContent>
      <BlockchainActionModalContent.Header>
        <BlockchainActionModalContent.Title>
          {t("blockchain.fulfillSwap.title", "Accept deal")}
        </BlockchainActionModalContent.Title>
      </BlockchainActionModalContent.Header>
      <BlockchainActionModalContent.Body>
        <SwapDataHeader
          data={data.swapData}
          transactionHash={transactionHash}
        />
        <BlockchainActionModalContent.Body.Separator />
        <BlockchainActionModalContent.Body.GoToWallet />
        <BlockchainActionModalContent.Body.Text>
          {t(
            "blockchain.fulfillSwap.description",
            "You'll be asked to approve this deal from your wallet.",
          )}
        </BlockchainActionModalContent.Body.Text>
        {!progress && (
          <BlockchainActionModalContent.Body.Button onClick={attemptAction}>
            {t("blockchain.fulfillSwap.cta", "Continue")}
          </BlockchainActionModalContent.Body.Button>
        )}
      </BlockchainActionModalContent.Body>
    </BlockchainActionModalContent>
  )
}
