import { useCallback, useRef } from "react"
import { useFlag } from "@/hooks/useFlag"

export const useVesselLogger = () => {
  const isVesselLoggingEnabled = useFlag("is_vessel_logging_enabled")

  const isVesselLoggingEnabledRef = useRef(isVesselLoggingEnabled)
  isVesselLoggingEnabledRef.current = isVesselLoggingEnabled

  return useCallback((message: string, ...args: unknown[]) => {
    if (isVesselLoggingEnabledRef.current) {
      console.log(`[vessel][opensea-next] ${message}`, ...args)
    }
  }, [])
}
