import React, { useMemo } from "react"
import { Theme } from "@opensea/next-themes"
import { ThemeVariant } from "@opensea/ui-kit"
import { TrackingContextProvider } from "../TrackingContext.react"

type ThemeContext = {
  theme: Theme
  resolvedTheme: ThemeVariant
}

export type ThemeTrackingContext = {
  Theme: ThemeContext | undefined
}

type ThemeTrackingContextProviderProps = {
  children: React.ReactNode
  theme: Theme
  resolvedTheme: ThemeVariant
}

export const ThemeTrackingContextProvider = ({
  children,
  theme,
  resolvedTheme,
}: ThemeTrackingContextProviderProps) => {
  const properties = useMemo(
    () => ({
      theme,
      resolvedTheme,
    }),
    [theme, resolvedTheme],
  )

  return (
    <TrackingContextProvider name="Theme" properties={properties}>
      {children}
    </TrackingContextProvider>
  )
}
