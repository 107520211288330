import { max } from "date-fns"
import { keyBy } from "lodash"
import { filter } from "@/lib/helpers/array"
import { dateFromISO8601 } from "@/lib/helpers/datetime"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"
import {
  GaslessCancelResult,
  GaslessCancelStatus,
} from "./readGaslessCancelResult"

export const getResultFromStatuses = (
  statuses: GaslessCancelStatus[],
): GaslessCancelResult => {
  const failedCount = statuses.filter(status => status.isFailed).length
  const fulfilledCount = statuses.filter(status => status.isFulfilled).length
  const canceledQuantity = BigNumber.sum(
    ...statuses.map(status => bn(status.quantityCancelled)),
  )
  const remainingQuantity = BigNumber.sum(
    ...statuses.map(status => bn(status.remainingQuantity)),
  )

  const fulfillmentPossibleUntilChainTimes = filter(
    statuses.map(status => status.fulfillmentPossibleUntilChainTimestamp),
  ).map(fulfillmentPossibleUntilChainTimestamp =>
    dateFromISO8601(fulfillmentPossibleUntilChainTimestamp),
  )
  const latestFulfillmentPossibleUntilChainTimestamp =
    fulfillmentPossibleUntilChainTimes.length
      ? max(fulfillmentPossibleUntilChainTimes).toISOString()
      : null

  const ordersTypes = new Set(statuses.map(status => status.orderType))
  const ordersType =
    ordersTypes.size === 1 ? ordersTypes.values().next().value : undefined

  const ordersSides = new Set(statuses.map(status => status.orderSide))
  const ordersSide =
    ordersSides.size === 1 ? ordersSides.values().next().value : undefined

  const ordersAwaitingFinalization = new Set(
    filter(
      statuses.map(status =>
        status.fulfillmentPossibleUntilChainTimestamp
          ? status.orderRelayId
          : null,
      ),
    ),
  )

  const unfinalizedOrders = new Set(
    filter(
      statuses.map(status =>
        !status.isFinalized ? status.orderRelayId : null,
      ),
    ),
  )

  return {
    failedCount,
    fulfilledCount,
    canceledQuantity,
    remainingQuantity,
    latestFulfillmentPossibleUntilChainTimestamp,
    ordersType,
    ordersSide,
    ordersAwaitingFinalization,
    unfinalizedOrders,
    statuses: keyBy(statuses, "orderRelayId"),
  }
}
