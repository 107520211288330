import { useState } from "react"
import { useInterval } from "react-use"
import { captureWarning } from "@/lib/sentry"
import {
  getMoonPayApiRetrieveExtTxUrl,
  MoonPayApiNftTransaction,
  MoonPayApiTransaction,
} from "./MoonPayTx"

const MOONPAY_NFT_TRANSACTION_STATUS_POLL_INTERVAL = 3_000

type MoonPayApiRetrieveExtTransactionResponse = MoonPayApiTransaction[]

export const useMoonPayNftTxStatus = ({
  externalTransactionId,
}: {
  externalTransactionId: string
}) => {
  const [latestNftTransaction, setLatestNftTransaction] =
    useState<MoonPayApiNftTransaction>()
  const [latestCryptoTransaction, setLatestCryptoTransaction] =
    useState<Omit<MoonPayApiTransaction, "nftTransaction">>()

  const pollTxStatus = async () => {
    const response = await fetch(
      getMoonPayApiRetrieveExtTxUrl(externalTransactionId, {
        useNftApiKey: true,
      }),
    )
    if (!response.ok) {
      // Transaction does not exist yet
      return
    }
    const apiResponse: MoonPayApiRetrieveExtTransactionResponse =
      await response.json()
    if (apiResponse.length > 1) {
      captureWarning(
        new Error(
          `MoonPay NFT API returned more than one transaction with external id ${externalTransactionId}`,
        ),
      )
    }
    const [{ nftTransaction, ...cryptoTransaction }] = apiResponse

    if (cryptoTransaction.updatedAt !== latestCryptoTransaction?.updatedAt) {
      setLatestCryptoTransaction(cryptoTransaction)
    }

    if (
      nftTransaction &&
      nftTransaction.updatedAt !== latestNftTransaction?.updatedAt
    ) {
      setLatestNftTransaction(nftTransaction)
    }
  }

  useInterval(pollTxStatus, MOONPAY_NFT_TRANSACTION_STATUS_POLL_INTERVAL)

  return {
    nftTransaction: latestNftTransaction,
    cryptoTransaction: latestCryptoTransaction,
  }
}
