import { useEffect } from "react"
import localforage from "localforage"
import { graphql, useFragment } from "react-relay"
import { getFromBuyWithCardFilterKey } from "@/components/assets/localstorage"
import {
  ItemAnalyticsTracker,
  useItemAnalyticsContext,
} from "@/lib/analytics/ItemAnalyticsContext"
import { useIsAvailableForBuyWithCard_data$key } from "@/lib/graphql/__generated__/useIsAvailableForBuyWithCard_data.graphql"
import { useIsMoonPayAllowed } from "./useIsMoonPayAllowed"

type UseIsAvailableForBuyWithCardOptions = {
  dataKey: useIsAvailableForBuyWithCard_data$key
  tracker?: ItemAnalyticsTracker
  /**
   * Used to inform whether the user arrived on the item via the buy with card
   * filter on the search page. If passed, then a check will be made to track
   * any items that appeared in the buy with card filter but are not available
   */
  itemId?: string
}

type UseIsAvailableForBuyWithCardResult = {
  isAvailableForBuyWithCard: boolean
  isLoading: boolean
}

export const useIsAvailableForBuyWithCard = ({
  dataKey,
  tracker: trackerFromOptions,
  itemId,
}: UseIsAvailableForBuyWithCardOptions): UseIsAvailableForBuyWithCardResult => {
  const { tracker: trackerFromContext } = useItemAnalyticsContext()
  const tracker = trackerFromOptions ?? trackerFromContext

  const { isBuyWithCardAllowed, isLoading: isLoadingBuyWithCardAllowed } =
    useIsMoonPayAllowed()

  const { fiatCheckoutAvailability } = useFragment(
    graphql`
      fragment useIsAvailableForBuyWithCard_data on MoonpayType
      @argumentDefinitions(listingId: { type: "OrderRelayID!" }) {
        fiatCheckoutAvailability(listingId: $listingId)
      }
    `,
    dataKey,
  )

  useEffect(() => {
    const trackNotAvailableFromBuyWithCardFilter = async () => {
      if (!itemId) {
        // if no itemId is passed from props then don't track
        return
      }
      const fromBuyWithCardFilter = await localforage.getItem<boolean>(
        getFromBuyWithCardFilterKey(itemId),
      )
      if (fromBuyWithCardFilter) {
        tracker.trackNotAvailableForMPCheckoutFromBuyWithCardFilter()
        // TODO: Queue job to reindex this asset
      }
      localforage.removeItem(getFromBuyWithCardFilterKey(itemId))
    }

    if (!fiatCheckoutAvailability) {
      trackNotAvailableFromBuyWithCardFilter()
    }

    return () => {
      if (itemId) {
        localforage.removeItem(getFromBuyWithCardFilterKey(itemId))
      }
    }
  }, [fiatCheckoutAvailability, itemId, tracker])

  return {
    isAvailableForBuyWithCard:
      isBuyWithCardAllowed && !!fiatCheckoutAvailability,
    isLoading: isLoadingBuyWithCardAllowed,
  }
}
