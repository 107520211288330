/**
 * @generated SignedSource<<0faf41c5d10d74672d280be595efdc2c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useTotalPriceOfferDataToAccept_offersToAccept$data = ReadonlyArray<{
  readonly itemFillAmount: string;
  readonly " $fragmentSpreads": FragmentRefs<"readOfferDataToAcceptPerUnitPrice_offerToAccept">;
  readonly " $fragmentType": "useTotalPriceOfferDataToAccept_offersToAccept";
}>;
export type useTotalPriceOfferDataToAccept_offersToAccept$key = ReadonlyArray<{
  readonly " $data"?: useTotalPriceOfferDataToAccept_offersToAccept$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTotalPriceOfferDataToAccept_offersToAccept">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "useTotalPriceOfferDataToAccept_offersToAccept",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "itemFillAmount",
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "readOfferDataToAcceptPerUnitPrice_offerToAccept",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderDataType",
          "kind": "LinkedField",
          "name": "orderData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceType",
              "kind": "LinkedField",
              "name": "perUnitPrice",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "usd",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unit",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PaymentAssetType",
              "kind": "LinkedField",
              "name": "payment",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "TokenPricePayment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "symbol",
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "OfferToAcceptType",
  "abstractKey": null
};

(node as any).hash = "8521780f00f30f0ffb211101c8b280d5";

export default node;
