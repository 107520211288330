import React, { useMemo } from "react"
import { graphql, useFragment } from "react-relay"
import { ItemTrackingContext_item$key } from "@/lib/graphql/__generated__/ItemTrackingContext_item.graphql"
import type { VerificationStatus } from "@/lib/helpers/verification"
import { TrackingContextProvider } from "../TrackingContext.react"

type CommonContext = {
  relayId: string
  chain: string
  verificationStatus: VerificationStatus
}

type AssetContext = CommonContext & {
  address: string
  tokenId: string
  isReportedSuspicious: boolean
}

type BundleContext = CommonContext & {
  slug?: string
}

type ItemContext = AssetContext | BundleContext

export type ItemTrackingContext = {
  Item: ItemContext | undefined
}

type ItemTrackingContextProviderProps = {
  item: ItemTrackingContext_item$key | null
  children: React.ReactNode
}

export const ItemTrackingContextProvider = ({
  item: itemDataKey,
  children,
}: ItemTrackingContextProviderProps) => {
  const data = useFragment(
    graphql`
      fragment ItemTrackingContext_item on ItemType {
        relayId
        verificationStatus
        chain {
          identifier
        }
        ... on AssetType {
          tokenId
          isReportedSuspicious
          assetContract {
            address
          }
        }
        ... on AssetBundleType {
          slug
        }
      }
    `,
    itemDataKey,
  )

  const properties: ItemContext | undefined = useMemo(() => {
    if (!data) {
      return undefined
    }

    const {
      relayId,
      chain,
      verificationStatus,
      tokenId,
      assetContract,
      isReportedSuspicious,
      slug,
    } = data

    const commonContext: CommonContext = {
      relayId,
      chain: chain.identifier,
      verificationStatus,
    }

    if (tokenId && assetContract) {
      return {
        ...commonContext,
        tokenId,
        address: assetContract.address,
        isReportedSuspicious,
      }
    }

    return {
      ...commonContext,
      slug: slug ?? undefined,
    }
  }, [data])

  return (
    <TrackingContextProvider name="Item" properties={properties}>
      {children}
    </TrackingContextProvider>
  )
}
