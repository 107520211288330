/* eslint-disable tailwindcss/no-custom-classname */
import React, {
  useRef,
  useState,
  forwardRef,
  Dispatch,
  SetStateAction,
} from "react"
import {
  Icon,
  breakpoints,
  UnstyledButton,
  Spinner,
  classNames,
  VerticalAligned,
  FlexCenter,
  useIsLessThanLg,
  Flex,
  useIsHydrated,
} from "@opensea/ui-kit"
import {
  NavSearchAssetsQuery,
  NavSearchAssetsQuery$data,
} from "@/lib/graphql/__generated__/NavSearchAssetsQuery.graphql"
import { parseJSON, format, isBefore } from "date-fns"
import { rgba } from "polished"
import { useClickAway, useKeyPressEvent } from "react-use"
import styled, { css } from "styled-components"
import { useDebouncedCallback } from "use-debounce"
import { VerificationIcon } from "@/components/collections/VerificationIcon.react"
import AccountBadge from "@/components/common/AccountBadge.react"
import { ChainLogo } from "@/components/common/ChainLogo"
import {
  MOBILE_NAV_ITEM_PADDING,
  MOBILE_NAV_ITEM_HEIGHT,
} from "@/components/nav/constants"
import { MONTH_DATE_FORMAT_STRING } from "@/constants/datetime"
import { Block } from "@/design-system/Block"
import { Dropdown, RenderDropdownContentProps } from "@/design-system/Dropdown"
import { Item } from "@/design-system/Item"
import { List as DSList } from "@/design-system/List"
import { SearchInput, SearchInputProps } from "@/design-system/SearchInput"
import { getDropStages } from "@/features/primary-drops/hooks/useDropStages"
import {
  useAccountRecentViews,
  readAccountRecentViews,
} from "@/features/recent-views/accountRecentViews"
import {
  useCollectionRecentViews,
  readCollectionRecentViews,
  CollectionRecentView,
} from "@/features/recent-views/collectionRecentViews"
import { useIsOpen } from "@/hooks/useIsOpen"
import { useLockBodyScroll } from "@/hooks/useLockBodyScroll"
import { useRouter } from "@/hooks/useRouter"
import { useSize } from "@/hooks/useSize"
import { useTranslate } from "@/hooks/useTranslate"
import {
  trackNavSearchQuery,
  trackNavSearchFailed,
  SearchType,
  trackNavSearchResultClick,
  trackNavSearchEntered,
  trackNavSearchKeyboardShortcut,
} from "@/lib/analytics/events/navSearchEvents"
import {
  NavSearchAccountsQuery,
  NavSearchAccountsQuery$data,
} from "@/lib/graphql/__generated__/NavSearchAccountsQuery.graphql"
import {
  NavSearchCollectionsQuery,
  NavSearchCollectionsQuery$data,
} from "@/lib/graphql/__generated__/NavSearchCollectionsQuery.graphql"
import { NavSearchDroppingCollectionsQuery } from "@/lib/graphql/__generated__/NavSearchDroppingCollectionsQuery.graphql"
import { getFirstGraphqlResponseErrorMessage } from "@/lib/graphql/error"
import { fetch, getNodes, graphql, Node } from "@/lib/graphql/graphql"
import { getAccountUrl } from "@/lib/helpers/accounts"
import { getAssetUrl } from "@/lib/helpers/asset"
import {
  getCollectionUrl,
  getCollectionOverviewUrl,
} from "@/lib/helpers/collection"
import {
  shortSymbolDisplay,
  bn,
  roundAboveMin,
} from "@/lib/helpers/numberUtils"
import { sanitizeQuery } from "@/lib/helpers/search"
import { media, themeVariant } from "@/styles/styleUtils"
import { HUES } from "@/styles/themes"
import { $nav_height } from "@/styles/variables"
import { PLACEHOLDER_IMAGE } from "../../constants"
import { Z_INDEX } from "../../constants/zIndex"
import { navbarInteractiveStyles } from "./styles"

const SEARCH_DEBOUNCE_DELAY_MILLIS = 300
const MIN_CHARS_FOR_ASSET_SEARCH = 5
const MIN_CHARS_FOR_COLLECTIONS_SEARCH = 3
const MIN_CHARS_FOR_DROPS_SEARCH = 3
const MIN_CHARS_FOR_ACCOUNTS_SEARCH = 3
const MIN_CHARS_FOR_SEARCH = 3
const NO_VALUE_PLACEHOLDER = "－"

type Account = Node<NavSearchAccountsQuery$data["accounts"]>
type Asset = NonNullable<Node<NavSearchAssetsQuery$data["searchItems"]>>
type Collection = Node<NavSearchCollectionsQuery$data["searchCollections"]>

type Props = {
  query: string
  closeSearchMobile?: () => void
  bannerHeight: number
} & Pick<GlobalSearchInputProps, "inputRef" | "isBackgroundTransparent">

export const NavSearch = ({
  query: initialQuery,
  bannerHeight,
  inputRef,
  closeSearchMobile,
  isBackgroundTransparent = false,
}: Props) => {
  const t = useTranslate("components")
  const router = useRouter()
  const queryRef = useRef(initialQuery)
  const [error, setError] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const [assets, setAssets] = useState<ReadonlyArray<Asset>>([])
  const [collections, setCollections] = useState<ReadonlyArray<Collection>>([])
  const [droppingCollections, setDroppingCollections] = useState<
    ReadonlyArray<Collection>
  >([])
  const [accounts, setAccounts] = useState<ReadonlyArray<Account>>([])
  const [selected, setSelected] = useState<string>("")
  const [selectedIndex, setSelectedIndex] = useState(-1)
  // Triggered when up and down arrow key are pressed to disable mouse hover
  const [arrowkeyPressed, setArrowKeyPressed] = useState(false)
  const { isOpen: showResults, close, open, setIsOpen } = useIsOpen()
  const searchContainerRef = useRef<HTMLDivElement>(null)
  const resultsContainerRef = useRef<HTMLUListElement>(null)
  const [searchContainerWidth] = useSize(searchContainerRef)
  const accountRecentViews = useAccountRecentViews()
  const collectionRecentViews = useCollectionRecentViews()

  const count =
    collections.length +
      accounts.length +
      assets.length +
      droppingCollections.length +
      collectionRecentViews.items?.length ?? 0
  const isEmpty = count === 0
  const isMobileViewport = !!closeSearchMobile

  const getMintingText = ({
    startTime,
    endTime,
  }: {
    startTime: string
    endTime: string | null
  }) => {
    const now = Date.now()
    const start = parseJSON(startTime)
    const end = endTime ? parseJSON(endTime) : null
    if (isBefore(now, start)) {
      return t("navSearch.mintingOn", "Minting on {{date}}", {
        date: format(start, MONTH_DATE_FORMAT_STRING),
      })
    } else if (!end || isBefore(now, end)) {
      return t("navSearch.mintingNow", "Minting now")
    }
    return t("navSearch.mintEnded", "Mint ended")
  }
  const collectionsToItemProps = (
    collections: ReadonlyArray<Collection>,
    linkType: "default" | "drop",
    type: SearchType,
    sectionIndexPadding: number,
  ) => {
    return collections.map(collection => {
      const { logo, isVerified, name, relayId, statsV2, defaultChain } =
        collection
      const route =
        linkType === "drop"
          ? getCollectionOverviewUrl(collection)
          : getCollectionUrl(collection)
      const floorPrice =
        statsV2.floorPrice && !bn(statsV2.floorPrice.unit).isZero()
          ? roundAboveMin(bn(statsV2.floorPrice.unit), 2)
          : NO_VALUE_PLACEHOLDER
      const { ctaStage } = getDropStages(collection.dropv2?.stages ?? [])
      const mintingText =
        linkType === "drop" && ctaStage
          ? getMintingText({
              startTime: ctaStage.startTime,
              endTime: ctaStage.endTime,
            })
          : null
      const isRedditCollectibleAvatars = name === "Reddit Collectible Avatars"

      return {
        avatarClassName: "rounded-xl",
        content: (
          <Block>
            <Flex>
              <Item.Title size="small">{name}</Item.Title>
              {isVerified && (
                <VerificationIcon size="tiny" verificationStatus="VERIFIED" />
              )}
            </Flex>
            {isRedditCollectibleAvatars ? null : (
              <Flex className="items-center">
                {!mintingText && (
                  <Flex className="mr-1 items-center">
                    <ChainLogo
                      chain={defaultChain.identifier}
                      variant="gray"
                      width={16}
                    />
                  </Flex>
                )}
                <Item.Description>
                  {mintingText ? (
                    mintingText
                  ) : (
                    <>
                      {shortSymbolDisplay(statsV2.totalSupply, {
                        threshold: 1_000_000_000,
                        formatDisplay: true,
                      })}{" "}
                      {t(
                        "navSearch.items",
                        { 0: "items", one: "item", other: "items" },
                        { count: statsV2.totalSupply },
                      )}
                    </>
                  )}
                </Item.Description>
              </Flex>
            )}
          </Block>
        ),
        logo,
        key: relayId,
        route,
        imageSize: 32,
        side:
          linkType === "default" ? (
            <Flex className="mr-4">
              <Item.Description>
                {floorPrice !== NO_VALUE_PLACEHOLDER
                  ? `${floorPrice} ${statsV2.floorPrice?.symbol}`
                  : floorPrice}
              </Item.Description>
            </Flex>
          ) : null,
        onClick: (index: number) => {
          const collection = collections[index - sectionIndexPadding]
          collectionRecentViews.add(readCollectionRecentViews(collection))
        },
        type,
      }
    })
  }

  const assetsToItemProps = (type: SearchType) => {
    return assets.map(asset => {
      const { imageUrl = "", relayId, collection } = asset
      const assetName = asset.name ?? `#${asset.tokenId}`
      return {
        avatarClassName: "rounded-xl",
        content: (
          <Block>
            <Flex>
              {collection?.name && (
                <Item.Title size="small">{collection.name}</Item.Title>
              )}
              {collection?.isVerified && (
                <VerificationIcon size="tiny" verificationStatus="VERIFIED" />
              )}
            </Flex>
            <Item.Description>{assetName}</Item.Description>
          </Block>
        ),
        imageUrl,
        key: relayId,
        route: getAssetUrl(asset),
        logo: imageUrl,
        imageSize: 32,
        type,
        onClick: () => null,
      }
    })
  }

  const viewedCollectionsToItemProps = (
    collections: ReadonlyArray<CollectionRecentView>,
    type: SearchType,
    sectionIndexPadding: number,
  ) => {
    return collections.map(collection => {
      const {
        logo,
        imageUrl,
        isVerified,
        name,
        relayId,
        statsV2,
        defaultChain,
      } = collection
      const isRedditCollectibleAvatars = name === "Reddit Collectible Avatars"

      return {
        avatarClassName: "rounded-xl",
        content: (
          <Block>
            <Flex>
              <Item.Title size="small">{name}</Item.Title>
              {isVerified && (
                <VerificationIcon size="tiny" verificationStatus="VERIFIED" />
              )}
            </Flex>
            {isRedditCollectibleAvatars ? null : (
              <Flex className="items-center">
                <Flex className="mr-1 items-center">
                  <ChainLogo
                    chain={defaultChain.identifier}
                    variant="gray"
                    width={16}
                  />
                </Flex>
                <Item.Description>
                  {shortSymbolDisplay(statsV2.totalSupply, {
                    threshold: 1_000_000_000,
                    formatDisplay: true,
                  })}{" "}
                  {t(
                    "navSearch.items",
                    { 0: "items", one: "item", other: "items" },
                    { count: statsV2.totalSupply },
                  )}
                </Item.Description>
              </Flex>
            )}
          </Block>
        ),
        logo: logo ?? imageUrl ?? null,
        key: relayId,
        route: collection.collectionUrl,
        imageSize: 32,
        side: (
          <Flex className="mr-4">
            <UnstyledButton
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()
                if (count === 1) {
                  close()
                  closeSearchMobile?.()
                }
                collectionRecentViews.remove(collection.relayId)
              }}
            >
              <StyledIcon
                aria-label="close"
                className="pl-4"
                size={20}
                value="close"
              />
            </UnstyledButton>
          </Flex>
        ),
        onClick: (index: number) => {
          collectionRecentViews.add(collections[index - sectionIndexPadding])
        },
        type,
      }
    })
  }

  const getViewedCollections = (query: string) => {
    const sanitizedQuery = sanitizeQuery(query)
    if (sanitizedQuery.length > 0) {
      const sanitizedQueryRe = new RegExp(sanitizedQuery, "i")
      const sanitizedCollections = collectionRecentViews.items.filter(
        collection => {
          const sanitizedCollectionName = sanitizeQuery(collection.name)
          return sanitizedQueryRe.test(sanitizedCollectionName)
        },
      )
      return sanitizedCollections
    }
    return collectionRecentViews.items
  }

  const accountsToItemProps = (
    accounts: ReadonlyArray<Account>,
    type: SearchType,
    sectionIndexPadding: number,
  ) => {
    return accounts.map(account => {
      const { address, config, imageUrl, relayId, user, isCompromised } =
        account
      return {
        avatarClassName: "rounded-full",
        content: (
          <FlexCenter>
            <Item.Title size="small">
              {user?.publicUsername || address}
            </Item.Title>
            <AccountBadge
              config={config}
              isCompromised={isCompromised}
              showTooltip={false}
              variant="tiny"
            />
          </FlexCenter>
        ),
        imageSize: 32,
        logo: imageUrl,
        key: relayId,
        route: getAccountUrl(account),
        type,
        onClick: (index: number) => {
          const account = accounts[index - sectionIndexPadding]
          accountRecentViews.add(readAccountRecentViews(account))
        },
      }
    })
  }

  const searchDroppingCollections = (query: string) => {
    if (sanitizeQuery(query).length < MIN_CHARS_FOR_DROPS_SEARCH) {
      return Promise.resolve([])
    }
    return fetch<NavSearchDroppingCollectionsQuery>(
      graphql`
        query NavSearchDroppingCollectionsQuery($query: String!) {
          searchCollections(first: 4, query: $query, includeOnlyMinting: true) {
            edges {
              node {
                logo
                isVerified
                name
                relayId
                statsV2 {
                  totalSupply
                  floorPrice {
                    unit
                    symbol
                  }
                }
                defaultChain {
                  identifier
                }
                dropv2 {
                  stages {
                    ...useDropStages
                    startTime
                    endTime
                  }
                }
                ...collection_url
                ...collectionRecentViews_data
              }
            }
          }
        }
      `,
      { query },
    ).then(([{ searchCollections }]) => {
      const collections = getNodes(searchCollections)
      return collections
    })
  }

  useClickAway(searchContainerRef, e => {
    const node = e.target as HTMLElement
    if (resultsContainerRef.current?.contains(node)) {
      return
    }
    close()
  })

  const keyboardOpenDropdown = (event: KeyboardEvent) => {
    const targetTag = (
      event.target as HTMLElement | null
    )?.tagName.toUpperCase()
    const input = inputRef.current
    if (input && targetTag !== "INPUT" && targetTag !== "TEXTAREA") {
      event.preventDefault()
      event.stopPropagation()
      // focus after the change event is flushed to prevent "/" being added to the input
      setTimeout(() => {
        trackNavSearchKeyboardShortcut({ key: event.key })
        input.setSelectionRange(input.value.length, input.value.length)
        input.focus()
      }, 0)
    }
  }

  useKeyPressEvent("/", event => {
    keyboardOpenDropdown(event)
  })

  useKeyPressEvent("k", event => {
    // command+k
    if (event.metaKey) {
      keyboardOpenDropdown(event)
    }
  })

  useKeyPressEvent("Escape", event => {
    const node = event.target as HTMLElement
    if (resultsContainerRef.current?.contains(node)) {
      return
    }
    event.preventDefault()
    event.stopPropagation()
    setSelected("")
    setSelectedIndex(-1)
    close()
  })

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault()
      event.stopPropagation()
      const query = queryRef.current
      trackNavSearchEntered({
        query,
        path: window.location.pathname,
      })

      if (selected) {
        const selectedItem = allItems[selectedIndex]
        trackNavSearchResultClick({
          query,
          path: window.location.pathname,
          resultIndex: selectedIndex,
          resultUrl: selectedItem.route,
          resultArea: "dropdown",
          resultType: selectedItem.type,
        })
        selectedItem.onClick(selectedIndex)
        // This disable is needed to pass tests as components are not mounted in unit tests
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (inputRef) {
          inputRef.current?.blur()
        }
        router.push(selectedItem.route)
        close()
        closeSearchMobile?.()
      }
    }

    if (event.key === "ArrowDown") {
      event.preventDefault()
      event.stopPropagation()
      if (
        !isLoading &&
        !isEmpty &&
        (selectedIndex === -1 || selectedIndex < allItems.length - 1)
      ) {
        setSelectedIndex(selectedIndex + 1)
        setSelected(allItems[selectedIndex + 1]?.key || "")
        setArrowKeyPressed(true)
      }
    }

    if (event.key === "ArrowUp") {
      event.preventDefault()
      event.stopPropagation()
      if (
        !isLoading &&
        !isEmpty &&
        (selectedIndex === -1 || selectedIndex > 0)
      ) {
        setSelectedIndex(selectedIndex - 1)
        setSelected(allItems[selectedIndex - 1]?.key || "")
        setArrowKeyPressed(true)
      }
    }
  }

  const searchAccounts = (query: string) => {
    if (sanitizeQuery(query).length < MIN_CHARS_FOR_ACCOUNTS_SEARCH) {
      return Promise.resolve([])
    }
    return fetch<NavSearchAccountsQuery>(
      graphql`
        query NavSearchAccountsQuery($query: String!) {
          accounts(first: 4, query: $query) {
            edges {
              node {
                address
                config
                imageUrl
                relayId
                isCompromised
                user {
                  publicUsername
                }
                ...accounts_url
                ...accountRecentViews_data
              }
            }
          }
        }
      `,
      { query },
    ).then(([{ accounts }]) => {
      const accountNodes = getNodes(accounts)
      return accountNodes
    })
  }

  const searchAssets = (query: string) => {
    if (
      sanitizeQuery(query).length < MIN_CHARS_FOR_ASSET_SEARCH ||
      !query.toLowerCase().endsWith(".eth")
    ) {
      return Promise.resolve([])
    }

    return fetch<NavSearchAssetsQuery>(
      graphql`
        query NavSearchAssetsQuery($query: String!) {
          searchItems(
            first: 4
            querystring: $query
            collections: ["ens"]
            resultType: ASSETS
            excludeHiddenCollections: true
          ) {
            edges {
              node {
                relayId
                ... on AssetType {
                  name
                  ...asset_url
                  imageUrl
                  tokenId
                  collection {
                    name
                    isVerified
                  }
                }
              }
            }
          }
        }
      `,
      { query },
    ).then(([{ searchItems }]) => {
      return getNodes(searchItems)
    })
  }

  const searchCollections = (query: string) => {
    if (
      sanitizeQuery(query).length < MIN_CHARS_FOR_COLLECTIONS_SEARCH ||
      query.toLowerCase().endsWith(".eth")
    ) {
      return Promise.resolve([])
    }
    return fetch<NavSearchCollectionsQuery>(
      graphql`
        query NavSearchCollectionsQuery($query: String!) {
          searchCollections(first: 4, query: $query) {
            edges {
              node {
                logo
                isVerified
                name
                relayId
                statsV2 {
                  totalSupply
                  floorPrice {
                    unit
                    symbol
                  }
                }
                defaultChain {
                  identifier
                }
                dropv2 {
                  stages {
                    ...useDropStages
                    startTime
                    endTime
                  }
                }
                ...collection_url
                ...collectionRecentViews_data
              }
            }
          }
        }
      `,
      { query },
    ).then(([{ searchCollections }]) => {
      const collections = getNodes(searchCollections)
      return collections
    })
  }

  const onChange = useDebouncedCallback(async (query: string) => {
    const isValidQuery =
      query.length >= MIN_CHARS_FOR_SEARCH ||
      (collectionRecentViews.items?.length ?? 0) > 0

    setAccounts([])
    setAssets([])
    setCollections([])
    setDroppingCollections([])
    setIsLoading(isValidQuery)
    setIsOpen(isValidQuery)
    setError(undefined)
    setSelectedIndex(-1)
    setArrowKeyPressed(false)

    if (!isValidQuery) {
      return
    }

    try {
      const [accounts, collections, assets, droppingCollections] =
        await Promise.all([
          searchAccounts(query),
          searchCollections(query),
          searchAssets(query),
          searchDroppingCollections(query),
        ])
      if (queryRef.current !== query) {
        return
      }
      setAccounts(accounts)
      setCollections(collections)
      setAssets(assets)
      setDroppingCollections(droppingCollections)
      trackNavSearchQuery({
        query,
        path: window.location.pathname,
        hits: {
          accounts: accounts.map(a => a.relayId),
          assets: assets.map(a => a.relayId),
          collections: collections.map(c => c.relayId),
          droppingCollections: droppingCollections.map(c => c.relayId),
          recentViews: collectionRecentViews.items.map(c => c.relayId),
        },
      })
    } catch (error) {
      if (queryRef.current === query) {
        setError(
          getFirstGraphqlResponseErrorMessage(error) ??
            t("navSearch.error", "An error occurred while searching."),
        )
        trackNavSearchFailed({
          query,
          path: window.location.pathname,
        })
      }
    } finally {
      if (queryRef.current === query) {
        setIsLoading(false)
      }
    }
  }, SEARCH_DEBOUNCE_DELAY_MILLIS)

  const onFocus = () => {
    if (count > 0) {
      open()
    }
    if (!isLoading && isEmpty) {
      onChange(queryRef.current)
    }
  }

  const filteredRecentViews = getViewedCollections(queryRef.current)

  const viewedCollectionStartIndex = 0
  const viewedCollection = viewedCollectionsToItemProps(
    filteredRecentViews,
    "collection-recent",
    viewedCollectionStartIndex,
  )

  const dropCollectionStartIndex =
    viewedCollectionStartIndex + viewedCollection.length
  const dropCollectionItems = collectionsToItemProps(
    droppingCollections,
    "drop",
    "collection-drops",
    dropCollectionStartIndex,
  )

  const collectionStartIndex =
    dropCollectionStartIndex + dropCollectionItems.length
  const collectionItems = collectionsToItemProps(
    collections,
    "default",
    "collection",
    collectionStartIndex,
  )

  const accountStartIndex = collectionStartIndex + collectionItems.length
  const accountItems = accountsToItemProps(
    accounts,
    "account",
    accountStartIndex,
  )
  const assetItems = assetsToItemProps("asset")

  const allItems = [
    ...viewedCollection,
    ...dropCollectionItems,
    ...collectionItems,
    ...accountItems,
    ...assetItems,
  ]
  const isLessThanLg = useIsLessThanLg()

  const renderDropDownSections = ({
    Item,
  }:
    | Omit<RenderDropdownContentProps, "close" | "List">
    | { Item: typeof DSList.Item }) => {
    const sectionConfigs = [
      {
        header: t("navSearch.headers.recent", "RECENT"),
        items: viewedCollection,
      },
      {
        header: t("navSearch.headers.drops", "DROPS"),
        items: dropCollectionItems,
      },
      {
        header: t("navSearch.headers.collections", "COLLECTIONS"),
        items: collectionItems,
      },
      {
        header: t("navSearch.headers.accounts", "ACCOUNTS"),
        items: accountItems,
      },
      {
        header: t("navSearch.headers.items", "ITEMS"),
        items: assetItems,
      },
    ]

    let sectionIndexPadding = 0
    return sectionConfigs.map(({ header, items }, i) => {
      const sectionComponent = (
        <Section
          Item={Item as typeof DSList.Item}
          arrowkeyPressed={arrowkeyPressed}
          close={close}
          closeSearchMobile={closeSearchMobile}
          header={header}
          items={items}
          key={i}
          query={queryRef.current}
          sectionIndexPadding={sectionIndexPadding}
          selected={selected}
          selectedIndex={selectedIndex}
          setSelected={setSelected}
          setSelectedIndex={setSelectedIndex}
        />
      )
      sectionIndexPadding += items.length
      return sectionComponent
    })
  }

  const renderResults = ({
    List,
    Item,
  }:
    | Omit<RenderDropdownContentProps, "close">
    | {
        List: typeof DSList
        Item: typeof List.Item
      }) => {
    return (
      <List
        className="NavSearch--results lg:border-1 w-full border-0"
        id="NavSearch--results"
        ref={resultsContainerRef}
        role="listbox"
        style={{ width: !isLessThanLg ? searchContainerWidth : "100%" }}
      >
        {renderDropDownSections({ Item: Item as typeof List.Item })}
        {error ? (
          <Item>
            <Item.Content>
              <Item.Description color="error">{error}</Item.Description>
            </Item.Content>
          </Item>
        ) : (
          isLoading && (
            <Item className="justify-center">
              <Spinner />
            </Item>
          )
        )}
        {!isLoading &&
        isEmpty &&
        queryRef.current.length > MIN_CHARS_FOR_SEARCH ? (
          <Item>
            <Item.Content>
              <Item.Description>
                {t("navSearch.noItems", "No items found")}
              </Item.Description>
            </Item.Content>
          </Item>
        ) : (
          <></>
        )}
      </List>
    )
  }

  const isHydrated = useIsHydrated()
  return (
    <>
      <StyledContainer
        $bannerHeight={bannerHeight}
        className="pointer-events-auto"
      >
        <Flex className="justify-center">
          <Dropdown
            animation="shift-away"
            className="NavSearch--dropdown"
            content={renderResults}
            maxWidth={searchContainerWidth}
            offset={[0, 13]}
            visible={showResults && !isMobileViewport}
          >
            <GlobalSearchInput
              aria-activedescendant={selected.length ? selected : undefined}
              aria-controls={showResults ? "NavSearch--results" : undefined}
              aria-label={t("navSearch.searchInput.label", "Search OpenSea")}
              aria-multiline="false"
              className="NavSearch--mobile-search-input"
              initialValue={queryRef.current}
              inputRef={inputRef}
              isBackgroundTransparent={isBackgroundTransparent}
              overrides={{
                Container: {
                  "aria-expanded": showResults,
                  "aria-controls": "NavSearch--results",
                  role: "combobox",
                },
              }}
              placeholder={t("nav.searchPlaceholder", "Search")}
              ref={searchContainerRef}
              role="searchbox"
              showKeyStrokeShortcut={
                // show keystroke hint IFF input isn't focused and not mobile and no query
                !showResults &&
                !isMobileViewport &&
                !queryRef.current &&
                // TODO @jamesuejio disable feature on Firefox due to
                // https://linear.app/opensea/issue/DISC-679/opens-up-quickfind-ui-on-firefox-on-home-page-instead-of-search-bar
                (!isHydrated ||
                  window.navigator.userAgent.indexOf("Firefox") === -1)
              }
              withIcon={!isMobileViewport}
              onChange={event => {
                queryRef.current = event.currentTarget.value
                onChange(event.currentTarget.value)
              }}
              onClick={onFocus}
              onFocus={onFocus}
              onKeyDown={onKeyDown}
            />
          </Dropdown>
        </Flex>
      </StyledContainer>
      {isMobileViewport && showResults && (
        <MobileOverlay>
          {renderResults({ List: DSList, Item: DSList.Item })}
        </MobileOverlay>
      )}
    </>
  )
}

const Section = ({
  header,
  items,
  Item,
  close,
  closeSearchMobile,
  query,
  selected,
  setSelected,
  setSelectedIndex,
  selectedIndex,
  sectionIndexPadding,
  arrowkeyPressed,
}: {
  Item: typeof DSList.Item
  header: string
  items: Array<{
    avatarClassName?: string
    content: React.ReactNode
    logo: string | null
    key: string
    route: string
    side?: React.ReactNode
    type: SearchType
    imageSize?: number
    onClick?: (index: number) => void
  }>
  close: () => void
  closeSearchMobile?: () => void
  query: string
  selected: string
  setSelected: Dispatch<SetStateAction<string>>
  setSelectedIndex: Dispatch<SetStateAction<number>>
  selectedIndex: number
  sectionIndexPadding: number
  arrowkeyPressed: boolean
}) => {
  if (!items.length) {
    return null
  }
  return (
    <>
      <Item className="border-0 px-4 py-2">
        <Item.Content>
          <Item.Title
            className="block translate-y-0.5 uppercase"
            color="secondary"
            size="tiny"
          >
            {header}
          </Item.Title>
        </Item.Content>
      </Item>
      {items.map(
        (
          {
            avatarClassName,
            content,
            logo,
            key,
            route,
            side,
            imageSize,
            onClick,
            type,
          },
          index,
        ) => {
          return (
            <Item
              active={selectedIndex === sectionIndexPadding + index}
              aria-selected={key === selected}
              className="border-0 px-4 py-1 sm:p-1"
              href={route}
              interactive={!arrowkeyPressed}
              key={key}
              role="option"
              onBlur={() => {
                setSelected("")
                setSelectedIndex(-1)
              }}
              onClick={() => {
                trackNavSearchResultClick({
                  query,
                  path: window.location.pathname,
                  resultIndex: sectionIndexPadding + index,
                  resultUrl: route,
                  resultArea: "dropdown",
                  resultType: type,
                })
                onClick?.(sectionIndexPadding + index)
                close()
                closeSearchMobile?.()
              }}
              onFocus={() => {
                setSelected(key)
                setSelectedIndex(sectionIndexPadding + index)
              }}
            >
              <Item.Avatar
                className={classNames(
                  "mx-3 my-2 border border-level-1",
                  avatarClassName,
                )}
                objectFit="cover"
                size={imageSize}
                src={logo || PLACEHOLDER_IMAGE}
              />
              <Item.Content>{content}</Item.Content>
              {side && <Item.Side>{side}</Item.Side>}
            </Item>
          )
        },
      )}
    </>
  )
}

const MobileOverlay = ({ children }: { children: React.ReactNode }) => {
  const ref = useRef<HTMLDivElement>(null)
  useLockBodyScroll(true, ref)
  return <Overlay ref={ref}>{children}</Overlay>
}

const Overlay = styled.div`
  overflow: auto;
  top: ${MOBILE_NAV_ITEM_HEIGHT};
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props => props.theme.colors.base1};
  z-index: ${Z_INDEX.NAVSEARCH_OVERLAY};
  border-top: 1px solid ${props => props.theme.colors.components.border.level2};
`

const StyledContainer = styled(VerticalAligned)<{ $bannerHeight: number }>`
  width: 100%;

  .NavSearch--dropdown {
    height: 100%;
    max-height: ${props =>
      // need additional 13 as it is used as distance from search input to dropdown start
      `calc(100vh - ${$nav_height} - ${props.$bannerHeight}px - 13px)`};
  }

  .NavSearch--mobile-search-input {
    ${media(
      {
        lg: css`
          &,
          &:hover {
            box-shadow: none;
            padding-left: 0;
            background-color: ${props => props.theme.colors.base1};
            color: ${props => props.theme.colors.text.primary};
          }

          &:active {
            box-shadow: none;
          }
        `,
      },
      { variant: "lessThan" },
    )}
  }

  .VerificationIcon--tooltip-content {
    padding: 8px;
  }
`

type GlobalSearchInputProps = Omit<SearchInputProps, "value" | "inputRef"> & {
  initialValue: string
  withIcon: boolean
  inputRef: React.RefObject<HTMLInputElement>
  showKeyStrokeShortcut: boolean
  isBackgroundTransparent: boolean
}

const GlobalSearchInput = forwardRef<HTMLDivElement, GlobalSearchInputProps>(
  function GlobalSearchInput(
    {
      initialValue,
      onChange,
      withIcon,
      inputRef,
      showKeyStrokeShortcut,
      isBackgroundTransparent,
      ...rest
    },
    ref,
  ) {
    const t = useTranslate("components")

    const [value, setValue] = useState(initialValue)

    return (
      <StyledGlobalSearchInput
        $isBackgroundTransparent={isBackgroundTransparent}
        {...rest}
        endEnhancer={
          showKeyStrokeShortcut && (
            <KeyStrokeShortcut
              aria-label={t(
                "navSearch.searchInput.keyStrokeHint",
                "Open using /",
              )}
              isBackgroundTransparent={isBackgroundTransparent}
            >
              /
            </KeyStrokeShortcut>
          )
        }
        inputRef={inputRef}
        overrides={{
          ...rest.overrides,
        }}
        ref={ref}
        value={value}
        withIcon={withIcon}
        onChange={event => {
          setValue(event.currentTarget.value)
          onChange?.(event)
        }}
      />
    )
  },
)

const inputSearchIconSelector = '> div > svg[aria-label="search"]'
const inputClearIconSelector = '> div > button > i[aria-label="close"]'

const inputIconAndTextElementsSelector = `&& input::placeholder, ${inputSearchIconSelector}, ${inputClearIconSelector}`

type NavSearchInputProps = SearchInputProps & {
  $isBackgroundTransparent: boolean
}

/**
 * Used to prevent style props from being passed down to SearchInput
 */
const NavSearchInput = forwardRef<HTMLDivElement, NavSearchInputProps>(
  function NavSearchInput(
    { $isBackgroundTransparent: _isBackgroundTransparent, ...restProps },
    ref,
  ) {
    return <SearchInput {...restProps} ref={ref} />
  },
)

const StyledGlobalSearchInput = styled(NavSearchInput)<NavSearchInputProps>`
  line-height: 26px;
  padding: 8px;
  transition:
    background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1),
    border-color 0.2s cubic-bezier(0.05, 0, 0.2, 1);

  ${media({
    lg: css`
      margin-right: 12px;
    `,
    xxxl: css`
      max-width: 900px;
    `,
  })}

  ${navbarInteractiveStyles}

  ${props =>
    props.$isBackgroundTransparent &&
    css`
      ${inputClearIconSelector}:hover {
        ${props =>
          themeVariant({
            variants: {
              light: {
                color: props.theme.colors.fog,
              },
            },
          })}
      }

      ${inputIconAndTextElementsSelector} {
        ${themeVariant({
          variants: {
            light: {
              color: HUES.white,
            },
            dark: {
              color: rgba(HUES.white, 0.7),
            },
          },
        })}
        opacity: 0.8;
      }

      &:hover,
      &:focus,
      &:focus-within,
      &:active {
        ${inputIconAndTextElementsSelector} {
          ${themeVariant({
            variants: {
              light: {
                opacity: 1,
              },
            },
          })}

          transition: opacity 0.2s cubic-bezier(0.05, 0, 0.2, 1);
        }
      }
    `}

    ${props =>
    props.$isBackgroundTransparent &&
    css`
      i[aria-label="Clear"],
      i[aria-label="Clear"]:hover {
        color: ${props.theme.colors.white};
      }
    `}

  @media not screen and (min-width: ${breakpoints.lg}px) {
    padding: ${MOBILE_NAV_ITEM_PADDING};
    height: ${MOBILE_NAV_ITEM_HEIGHT};
    border-radius: 0;
    border: none;

    i[aria-label="Clear"],
    i[aria-label="Clear"]:hover {
      ${props =>
        themeVariant({
          variants: {
            dark: { color: props.theme.colors.gray },
            light: { color: props.theme.colors.charcoal },
          },
        })}
    }
  }
`
const StyledIcon = styled(Icon)`
  color: ${props => props.theme.colors.text.secondary};

  :hover {
    color: ${props => props.theme.colors.text.primary};
  }
`

const KeyStrokeShortcut = styled(Block)<{
  isBackgroundTransparent?: boolean
}>`
  height: 26px;
  min-width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 400;

  ${props =>
    themeVariant({
      variants: {
        light: {
          backgroundColor: props.isBackgroundTransparent
            ? rgba(props.theme.colors.white, 0.16)
            : props.theme.colors.components.background.gray2,
        },
        dark: {
          backgroundColor: rgba(props.theme.colors.white, 0.16),
        },
      },
    })}
`
