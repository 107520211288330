import { ethers } from "ethers"
import { WALLET_NAME } from "@/constants/wallet"
import { Chain } from "@/hooks/useChains/types"
import BrowserWeb3Provider from "./browserWeb3Provider"
import { detectProvider } from "./detect"
import { ExternalProvider } from "./web3EvmProvider"

type RoninEthereumProvider = ExternalProvider & {
  isRonin: boolean
}

declare global {
  interface Window {
    ronin?: {
      provider?: RoninEthereumProvider
    }
  }
}

const detectRoninProvider = (timeout = 3000) => {
  return detectProvider({
    timeout,
    key: "ronin.provider" as keyof Window,
    initializationEvent: "ronin#initialized",
    isInstalled: (wallet: RoninEthereumProvider | undefined) =>
      wallet !== undefined && wallet.isRonin,
  })
}

class RoninProvider extends BrowserWeb3Provider {
  public getName(): WALLET_NAME {
    return WALLET_NAME.Ronin
  }
}

export const createRoninProvider = async (chains: Chain[]) => {
  const ethereum = await detectRoninProvider()
  if (ethereum) {
    return new RoninProvider(
      new ethers.providers.Web3Provider(ethereum),
      chains,
    )
  }
  return undefined
}
