import React, { useCallback, useEffect, useRef } from "react"
import {
  Icon,
  Media,
  InlineFlex,
  useIsLessThanMd,
  Spinner,
  classNames,
  CenterAligned,
} from "@opensea/ui-kit"
import useMergedRef from "@react-hook/merged-ref"
import { ErrorBoundary } from "@sentry/nextjs"
import { useQueryLoader } from "react-relay"
import { graphql } from "relay-runtime"
import styled from "styled-components"
import { useTransactionStatusStore } from "@/components/blockchain/useTransactionStatusStore"
import { Link } from "@/components/common/Link"
import { AccountDropdown } from "@/components/nav/AccountDropdown/AccountDropdown.react"
import { navButtonStyles } from "@/components/nav/Navbar/elements"
import { useNavbarIconSize } from "@/components/nav/Navbar/useNavbarIconSize"
import { NavbarBackground } from "@/components/nav/styles"
import { Wallet } from "@/components/svgs/material-symbols/Wallet.react"
import { IS_SERVER } from "@/constants/environment"
import { Z_INDEX } from "@/constants/zIndex"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { useIsOSWalletEnabled } from "@/features/os-wallet/flags"
import { useOSWalletTrigger } from "@/features/os-wallet/hooks/useOSWalletTrigger"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { WalletAndAccountButtonQuery } from "@/lib/graphql/__generated__/WalletAndAccountButtonQuery.graphql"
import { HUES } from "@/styles/themes"
import { WalletPopover } from "../../WalletPopover.react"
import {
  ConnectWalletButton,
  WalletPopoverButton,
} from "./components/ConnectWalletButton.react"
import { WalletAndAccountButtonAccountImage } from "./components/WalletAndAccountButtonAccountImage.react"
import { WalletAndAccountButtonBalances } from "./components/WalletAndAccountButtonBalances.react"
import { useGetQueryArgs } from "./hooks/useGetQueryArgs"

export const WALLET_AND_ACCOUNT_BUTTON_QUERY = graphql`
  query WalletAndAccountButtonQuery(
    $address: AddressScalar!
    $wrappedCurrencySymbol: String!
    $wrappedCurrencyChain: ChainScalar!
  ) {
    ...WalletAndAccountButtonAccountImage_data @arguments(address: $address)

    # Used by child components
    # eslint-disable-next-line relay/must-colocate-fragment-spreads
    ...WalletAndAccountButtonFundsDisplay_data
      @arguments(
        address: $address
        wrappedCurrencySymbol: $wrappedCurrencySymbol
        wrappedCurrencyChain: $wrappedCurrencyChain
      )
  }
`

export type WalletAndAccountButtonProps = {
  background?: NavbarBackground
  responsive?: boolean
  visibleModules?: ("wallet" | "account")[]
  containerClassName?: string
  hideBalances?: boolean
}

export const WalletAndAccountButton = ({
  responsive = false,
  background,
  visibleModules,
  containerClassName,
  hideBalances = false,
}: WalletAndAccountButtonProps) => {
  const t = useTranslate("components")
  const { wallet } = useWallet()
  const isLessThanMd = useIsLessThanMd()

  const modules =
    visibleModules ??
    (responsive && isLessThanMd ? ["account"] : ["wallet", "account"])

  const isOSWalletEnabled = useIsOSWalletEnabled()
  const { anchorRef, toggleOSWallet } = useOSWalletTrigger()
  const transactionStatus = useTransactionStatusStore(
    state => state.transactionStatus,
  )
  const updateTransactionStatus = useTransactionStatusStore(
    state => state.updateTransactionStatus,
  )

  const containerRef = useRef<HTMLDivElement>(null)
  const mergedContainerRef = useMergedRef(containerRef, anchorRef)
  const getReferenceClientRect = () => {
    return containerRef.current?.getBoundingClientRect() ?? {}
  }

  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<WalletAndAccountButtonQuery>(WALLET_AND_ACCOUNT_BUTTON_QUERY)

  const getQueryArgs = useGetQueryArgs()

  useMountEffect(() => {
    const unsubWallet = wallet.onChange(() => {
      const queryArgs = getQueryArgs()
      if (queryArgs) {
        loadQuery(queryArgs)
      }
    })

    return () => {
      disposeQuery()
      unsubWallet()
    }
  })

  const refreshData = useCallback(() => {
    const queryArgs = getQueryArgs()
    if (queryArgs) {
      loadQuery(queryArgs)
    }
  }, [getQueryArgs, loadQuery])

  useEffect(() => {
    refreshData()
  }, [refreshData])

  const queryArgs = getQueryArgs()
  const isWalletConnected = Boolean(queryArgs)
  const iconSize = useNavbarIconSize()

  return (
    <InlineFlex ref={isOSWalletEnabled ? undefined : mergedContainerRef}>
      {modules.includes("wallet") && (
        <WalletPopover
          getReferenceClientRect={getReferenceClientRect}
          placement="bottom-end"
          refreshData={refreshData}
        >
          {({ toggle }) => (
            <Block
              ref={isOSWalletEnabled ? mergedContainerRef : undefined}
              style={{ zIndex: Z_INDEX.OS_WALLET_POPOVER }}
            >
              {isWalletConnected ? (
                <WalletPopoverButton
                  $background={background}
                  className={containerClassName}
                  onClick={isOSWalletEnabled ? toggleOSWallet : toggle}
                >
                  <ErrorBoundary
                    fallback={() => (
                      <Wallet
                        size={iconSize}
                        title={t("walletAndAccountButton.icon.title", "Wallet")}
                      />
                    )}
                  >
                    <div className="flex w-6 justify-center">
                      {transactionStatus === "error" ? (
                        <CenterAligned
                          className={classNames(
                            "h-4 w-4 rounded-full p-1",
                            "animate-appear-and-shrink",
                            "bg-red-3",
                          )}
                          onAnimationEnd={() =>
                            updateTransactionStatus(undefined)
                          }
                        >
                          <Icon
                            fill={1}
                            grade={600}
                            size={14}
                            value="priority_high"
                          />
                        </CenterAligned>
                      ) : transactionStatus === "pending" ? (
                        <div className="flex items-center">
                          <Spinner
                            color={
                              background === "transparent" ? "white" : undefined
                            }
                            size="xsmall"
                          />
                        </div>
                      ) : transactionStatus === "success" ? (
                        <CenterAligned
                          className={classNames(
                            "h-4 w-4 rounded-full p-1",
                            "animate-appear-and-shrink",
                            "bg-green-2",
                          )}
                          onAnimationEnd={() =>
                            updateTransactionStatus(undefined)
                          }
                        >
                          <Icon fill={1} size={16} value="done" />
                        </CenterAligned>
                      ) : (
                        <Wallet
                          size={iconSize}
                          title={t(
                            "walletAndAccountButton.icon.title",
                            "Wallet",
                          )}
                        />
                      )}
                    </div>
                    {!hideBalances && (
                      <Media greaterThanOrEqual="xl">
                        {className => (
                          <WalletAndAccountButtonBalances
                            className={className}
                            isBackgroundTransparent={
                              background === "transparent"
                            }
                            queryReference={queryReference}
                          />
                        )}
                      </Media>
                    )}
                  </ErrorBoundary>
                </WalletPopoverButton>
              ) : (
                <ConnectWalletButton $background={background} />
              )}
            </Block>
          )}
        </WalletPopover>
      )}

      {modules.includes("account") && (
        <AccountDropdown
          appendTo={IS_SERVER ? undefined : document.body}
          zIndex={Z_INDEX.OS_WALLET_POPOVER}
        >
          <AccountLink
            $background={background}
            $isOSWalletEnabled={isOSWalletEnabled}
            $noBackground={responsive && isLessThanMd}
            className={classNames(
              "ml-3 flex items-center justify-center",
              containerClassName,
            )}
            href="/account"
          >
            <WalletAndAccountButtonAccountImage
              queryReference={queryReference}
              size={responsive && isLessThanMd ? 32 : iconSize}
            />
          </AccountLink>
        </AccountDropdown>
      )}
    </InlineFlex>
  )
}

type AccountLinkProps = {
  $noBackground?: boolean
  $isOSWalletEnabled: boolean
  $background?: NavbarBackground
}

export const AccountLink = styled(Link)<AccountLinkProps>`
  // Overriding Link color
  &,
  &:hover {
    color: ${props =>
      props.$background === "transparent" ? HUES.white : "inherit"};
  }

  ${navButtonStyles}
`
