import React, { createContext, useContext, useCallback } from "react"
import { noop } from "lodash"
import { IS_TESTNET } from "@/constants/testnet"
import { useLocalForageState } from "@/hooks/useLocalForage"

const MAX_RECENT_VIEWS_ITEMS = 8
const MAINNET_LOCAL_STORAGE_KEY_PREFIX = ""
const TESTNET_LOCAL_STORAGE_KEY_PREFIX = "testnet_"
const LOCAL_STORAGE_KEY_PREFIX = IS_TESTNET
  ? TESTNET_LOCAL_STORAGE_KEY_PREFIX
  : MAINNET_LOCAL_STORAGE_KEY_PREFIX

const generateLocalForageKey = (storageId: string) => {
  return `${LOCAL_STORAGE_KEY_PREFIX}${storageId}`
}

type RecentViewsType<Item extends object> = {
  items: Item[]
  add: (item: Item) => void
  remove: (slug: string) => void
}

type CreateRecentViewsEntityOptions<Item extends object> = {
  storageId: string
  itemIdKey: keyof Item
  maxItems?: number
}

type RecentViewsEntity<Item extends object> = {
  useEntityRecentViews: () => RecentViewsType<Item>
  EntityRecentViewsProvider: (props: {
    children: React.ReactNode
  }) => JSX.Element
}

/**
 * Factory function that generates a RecentViewsProvider class and a
 * useRecentViews hook that can be used to store and manipulate lists of
 * recently viewed entities like collections and accounts.
 *
 * To add a new recent views entity, add the returned entity specific provider
 * to the root RecentViewsProvider in ./RecentViewsProvider.tsx. Then, use the
 * entity specific useRecentViews hook in the component that needs to access
 * the particular recent views list.
 *
 * @param {CreateRecentViewsEntityOptions} options - Options to configure the
 *  recent views provider and hook.
 *
 * @returns {RecentViewsEntity} - An object containing the entity specific
 *  provider and hook.
 */
export const createRecentViewsEntity = <Item extends object>({
  storageId,
  itemIdKey,
  maxItems = MAX_RECENT_VIEWS_ITEMS,
}: CreateRecentViewsEntityOptions<Item>): RecentViewsEntity<Item> => {
  const RecentViewsContext = createContext<RecentViewsType<Item>>({
    items: [],
    add: noop,
    remove: noop,
  })

  const useEntityRecentViews = () => useContext(RecentViewsContext)
  const EntityRecentViewsProvider = ({
    children,
  }: {
    children: React.ReactNode
  }) => {
    const [items, setItems] = useLocalForageState<Item[]>(
      generateLocalForageKey(storageId),
      [],
    )

    const remove = useCallback(
      (id: string) => {
        setItems(prevItems => prevItems.filter(i => i[itemIdKey] !== id))
      },
      [setItems],
    )

    const add = useCallback(
      (item: Item) => {
        setItems(prevItems =>
          [
            item,
            ...prevItems.filter(i => i[itemIdKey] !== item[itemIdKey]),
          ].slice(0, maxItems),
        )
      },
      [setItems],
    )

    return (
      <RecentViewsContext.Provider
        value={{
          items,
          add,
          remove,
        }}
      >
        {children}
      </RecentViewsContext.Provider>
    )
  }

  return {
    useEntityRecentViews,
    EntityRecentViewsProvider,
  }
}
