import React from "react"
import { SizeProvider } from "@opensea/ui-kit"
import { RelayEnvironmentProvider } from "react-relay"
import { Environment, GraphQLTaggedNode, OperationType } from "relay-runtime"
import { RecordMap } from "relay-runtime/lib/store/RelayStoreTypes"
import { AppErrorBoundary } from "@/components/app/AppErrorBoundary"
import { FlagProvider } from "@/containers/FlagProvider.react"
import { AppContext, AppContextProps } from "@/context/app"
import { LocationContext, LocationContextProvider } from "@/context/location"
import { ThemeProvider } from "@/design-system/Context/ThemeContext"
import { AnnouncementBannerProvider } from "@/features/announcement-banner/utils/context"
import { CancelOrderContextProvider } from "@/features/cancel-orders/context/CancelOrderContext.react"
import { MoonPayProvider } from "@/features/moonpay/sdk-components/MoonPayProvider"
import { OSWalletContextProvider } from "@/features/os-wallet"
import { RecentViewsProvider } from "@/features/recent-views/RecentViewsProvider"
import { ShoppingCartContextProvider } from "@/features/shopping-cart/utils/ShoppingCartContextProvider"
import { ChainContextProvider } from "@/hooks/useChains/context"
import { Chain } from "@/hooks/useChains/types"
import Wallet, { WalletState } from "@/lib/chain/wallet"
import { IToggle } from "@/lib/feature-flags/unleash"
import { GraphQLProvider } from "@/lib/graphql/GraphQLProvider"
import { Fonts } from "@/styles/Fonts.react"
import { Theme } from "@/styles/styled"
import { AccountBannedGate } from "./AccountBannedGate.react"
import { AnalyticsWrapper } from "./AnalyticsWrapper.react"
import { GlobalModal } from "./GlobalModal.react"
import { GlobalModalProvider } from "./GlobalModalProvider.react"
import { ToastProvider } from "./ToastProvider.react"
import { WagmiProvider } from "./WagmiProvider"
import { NativeCurrencyBalanceProvider } from "./WalletBalanceProvider/NativeCurrencyBalanceProvider.react"
import { WalletModalProvider } from "./WalletModalProvider.react"
import { WalletProvider } from "./WalletProvider/WalletProvider.react"

export type DocumentProvidedProps = {
  toggles: IToggle[]
  sessionId: string | undefined
  chains: Chain[]
}

export type GetInitialPageProvidedProps<
  T extends OperationType = OperationType,
> = {
  initialRecords?: RecordMap
  ssrEnvironment?: Environment
  variables?: T["variables"]
  walletState?: WalletState
  isPageNotFound?: boolean
  theme?: Theme
  locationContext?: LocationContext
  nonce?: string
  isAuthenticated?: boolean
  // This is unfortunately needed because next-translate wraps our components in a HOC that overrides all props other than getInitialProps
  query?: GraphQLTaggedNode
}

export type PageProps<T extends OperationType = OperationType> =
  DocumentProvidedProps & GetInitialPageProvidedProps<T>

type Props = {
  children: React.ReactNode
  theme: Theme | undefined
  environment: Environment
  wallet: Wallet
  locationContext: LocationContext
  toggles: IToggle[]
  chains: Chain[]
  deviceId: string
  sessionId?: string
  nonce?: string
  appContext: AppContextProps
  isAuthenticated: boolean
}

export const AppProviders = ({
  children,
  theme,
  environment,
  wallet,
  locationContext,
  toggles,
  chains,
  sessionId,
  deviceId,
  nonce,
  appContext,
  isAuthenticated,
}: Props) => {
  return (
    <RelayEnvironmentProvider environment={environment}>
      <AppContext.Provider value={appContext}>
        <GlobalModalProvider>
          <FlagProvider
            deviceId={deviceId}
            sessionId={sessionId}
            toggles={toggles}
            wallet={wallet}
          >
            <ChainContextProvider chains={chains}>
              <LocationContextProvider value={locationContext}>
                <SizeProvider>
                  <ThemeProvider nonce={nonce} theme={theme}>
                    <Fonts />
                    <AppErrorBoundary>
                      <WalletProvider
                        chains={chains}
                        isAuthenticated={isAuthenticated}
                        toggles={toggles}
                        wallet={wallet}
                      >
                        <WagmiProvider>
                          {/* GraphQLProvider has to be a child of WalletProvider */}
                          <GraphQLProvider environment={environment}>
                            <ToastProvider>
                              <MoonPayProvider>
                                <OSWalletContextProvider deviceId={deviceId}>
                                  <RecentViewsProvider>
                                    <ShoppingCartContextProvider>
                                      <CancelOrderContextProvider>
                                        <AnalyticsWrapper
                                          deviceId={deviceId}
                                          nonce={nonce}
                                        >
                                          <AccountBannedGate>
                                            <NativeCurrencyBalanceProvider>
                                              <WalletModalProvider>
                                                <AnnouncementBannerProvider>
                                                  <GlobalModal />
                                                  {children}
                                                </AnnouncementBannerProvider>
                                              </WalletModalProvider>
                                            </NativeCurrencyBalanceProvider>
                                          </AccountBannedGate>
                                        </AnalyticsWrapper>
                                      </CancelOrderContextProvider>
                                    </ShoppingCartContextProvider>
                                  </RecentViewsProvider>
                                </OSWalletContextProvider>
                              </MoonPayProvider>
                            </ToastProvider>
                          </GraphQLProvider>
                        </WagmiProvider>
                      </WalletProvider>
                    </AppErrorBoundary>
                  </ThemeProvider>
                </SizeProvider>
              </LocationContextProvider>
            </ChainContextProvider>
          </FlagProvider>
        </GlobalModalProvider>
      </AppContext.Provider>
    </RelayEnvironmentProvider>
  )
}
