/**
 * @generated SignedSource<<2a98c404075d5fbdd690afcecb9a4f4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetMedia_asset$data = {
  readonly animationUrl: string | null;
  readonly displayImageUrl: string | null;
  readonly imageUrl: string | null;
  readonly isDelisted: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"AssetMediaAnimation_asset" | "AssetMediaAudio_asset" | "AssetMediaContainer_asset" | "AssetMediaImage_asset" | "AssetMediaPlaceholderImage_asset" | "AssetMediaVideo_asset" | "AssetMediaWebgl_asset">;
  readonly " $fragmentType": "AssetMedia_asset";
};
export type AssetMedia_asset$key = {
  readonly " $data"?: AssetMedia_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssetMedia_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "identity"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showQuantity"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showRarity"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssetMedia_asset",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "animationUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDelisted",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssetMediaAnimation_asset"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssetMediaAudio_asset"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "identity",
          "variableName": "identity"
        },
        {
          "kind": "Variable",
          "name": "showQuantity",
          "variableName": "showQuantity"
        },
        {
          "kind": "Variable",
          "name": "showRarity",
          "variableName": "showRarity"
        }
      ],
      "kind": "FragmentSpread",
      "name": "AssetMediaContainer_asset"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssetMediaImage_asset"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssetMediaPlaceholderImage_asset"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssetMediaVideo_asset"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssetMediaWebgl_asset"
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "182b1544bd06771cfad22aaa11c66dde";

export default node;
