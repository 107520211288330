/* eslint-disable tailwindcss/no-custom-classname */
import React from "react"
import { Flex, breakpoints } from "@opensea/ui-kit"
import styled from "styled-components"
import { useChainCurrencies } from "@/components/nav/WalletPopover/components/BridgeOrWrapForm/hooks/useChainCurrencies"
import { Menu } from "@/design-system/Menu"
import { useIsMoonPayAllowed } from "@/features/moonpay"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useTranslate } from "@/hooks/useTranslate"
import { getPolygonChain } from "@/lib/helpers/chainUtils"

export type AddFundsMenuTab = "deposit" | "card" | "wrap"

type AddFundsMenuProps = {
  activeTab: AddFundsMenuTab
  onSelectTab: (tab: AddFundsMenuTab) => void
  symbol: string | undefined
  chain: ChainIdentifier | undefined
}

export const AddFundsMenu = ({
  activeTab,
  onSelectTab,
  symbol,
  chain,
}: AddFundsMenuProps) => {
  const t = useTranslate("components")
  const { isTopupAllowed } = useIsMoonPayAllowed()
  const { baseCurrency, wrappedCurrency } = useChainCurrencies(chain)
  const isBridge = chain === getPolygonChain() && symbol === "ETH"

  const showWrapOption = chain && symbol === wrappedCurrency.symbol
  const showCardOption = isTopupAllowed
  const showDepositOption = !showWrapOption || !showCardOption

  if (!showWrapOption && !showCardOption) {
    return null
  }

  return (
    <StyledMenu>
      <Menu className="AddFundsModal--menu" direction="horizontal">
        {showWrapOption && (
          <Menu.Item
            $active={activeTab === "wrap"}
            className="AddFundsModal--MenuItem text-center"
            direction="horizontal"
            onClick={() => onSelectTab("wrap")}
          >
            <Menu.Avatar icon="swap_horiz" />
            <Menu.Title className="text-[14px] md:text-[16px]">
              {isBridge
                ? t("trade.addFundsModal.menu.bridge", "Bridge {{symbol}}", {
                    symbol: baseCurrency.symbol,
                  })
                : t("trade.addFundsModal.menu.wrap", "Wrap {{symbol}}", {
                    symbol: baseCurrency.symbol,
                  })}
            </Menu.Title>
          </Menu.Item>
        )}
        {showDepositOption && (
          <Menu.Item
            $active={activeTab === "deposit"}
            className="AddFundsModal--MenuItem text-center"
            direction="horizontal"
            onClick={() => onSelectTab("deposit")}
          >
            <Menu.Avatar icon="arrow_downward" />
            <Menu.Title className="text-[14px] md:text-[16px]">
              {t("trade.addFundsModal.menu.depositCrypto", "Deposit crypto")}
            </Menu.Title>
          </Menu.Item>
        )}
        {showCardOption && (
          <Menu.Item
            $active={activeTab === "card"}
            className="AddFundsModal--MenuItem text-center"
            direction="horizontal"
            onClick={() => onSelectTab("card")}
          >
            <Menu.Avatar icon="credit_card" />
            <Menu.Title className="text-[14px] md:text-[16px]">
              {symbol
                ? t(
                    "trade.addFundsModal.menu.buySymbolWithCard",
                    "Buy {{symbol}} with card",
                    { symbol },
                    { forceString: true },
                  )
                : t("trade.addFundsModal.menu.buyWithCard", "Buy with card")}
            </Menu.Title>
          </Menu.Item>
        )}
      </Menu>
    </StyledMenu>
  )
}

const StyledMenu = styled(Flex)`
  &&& {
    width: 100%;
  }

  .AddFundsModal--menu {
    width: 100%;
    border-bottom: 1px solid
      ${props => props.theme.colors.components.border.level2};

    li {
      width: 50%;
    }

    button {
      width: 100%;
      display: block;
      padding: 20px;
      background-color: ${props =>
        props.theme.colors.components.elevation.level2.background};

      @media (max-width: ${breakpoints.sm}px) {
        padding: 11px;
      }
    }

    button > * {
      vertical-align: middle;
    }
  }
`
