import React, { RefCallback, Suspense, useState } from "react"
import { Icon, Text, Skeleton } from "@opensea/ui-kit"
import {
  graphql,
  PreloadedQuery,
  useFragment,
  usePreloadedQuery,
} from "react-relay"
import styled from "styled-components"
import { trackClickAddToBalance } from "@/components/nav/WalletPopover/analytics"
import { StyledListItem } from "@/components/nav/WalletPopover/elements"
import { AddFundsModal } from "@/components/trade/AddFundsModal"
import { UniswapModal } from "@/components/trade/SwapModal/SwapModal.react"
import { Block } from "@/design-system/Block"
import { RenderDropdownContentProps } from "@/design-system/Dropdown"
import { PriceText } from "@/design-system/PriceText"
import { useChains } from "@/hooks/useChains"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { useIsWalletPopoverSwapEnabled } from "@/hooks/useFlag"
import { useTranslate } from "@/hooks/useTranslate"
import { NetworkBalanceDropdownQuery } from "@/lib/graphql/__generated__/NetworkBalanceDropdownQuery.graphql"
import { NetworkTokenBalances_data$key } from "@/lib/graphql/__generated__/NetworkTokenBalances_data.graphql"
import { isPolygon, isSolana } from "@/lib/helpers/chainUtils"
import { useUsdValueSortedTokenQuantities } from "../hooks/useUsdValueSortedTokenQuantities"
import { NETWORK_BALANCE_DROPDOWN_QUERY } from "../NetworkBalanceDropdown.react"

const DEFAULT_SHOWN_TOKEN_COUNT = 3

type NetworkTokenBalancesBaseProps = RenderDropdownContentProps & {
  queryReference: PreloadedQuery<NetworkBalanceDropdownQuery>
  activeChain: ChainIdentifier
  keepOpenOnClickRefCallback: RefCallback<HTMLElement>
}

const NetworkTokenBalancesBase = ({
  queryReference,
  activeChain,
  keepOpenOnClickRefCallback,
}: NetworkTokenBalancesBaseProps) => {
  const t = useTranslate("components")
  const { getNativeCurrencySymbol, getWrappedCurrencySymbol } = useChains()

  const isSwapEnabled = useIsWalletPopoverSwapEnabled()

  const data = usePreloadedQuery(NETWORK_BALANCE_DROPDOWN_QUERY, queryReference)

  const {
    wallet: { funds },
  } = useFragment<NetworkTokenBalances_data$key>(
    graphql`
      fragment NetworkTokenBalances_data on Query
      @argumentDefinitions(
        address: { type: "AddressScalar!" }
        chain: { type: "ChainScalar!" }
      ) {
        wallet(address: $address) {
          funds(chain: $chain) {
            ...useUsdValueSortedTokenQuantities_walletFunds
          }
        }
      }
    `,
    data,
  )

  const tokenQuantities = useUsdValueSortedTokenQuantities(funds, activeChain)

  const [shownTokenCount, setShownTokenCount] = useState(
    DEFAULT_SHOWN_TOKEN_COUNT,
  )

  if (tokenQuantities.length === 0) {
    return null
  }

  return (
    <Block paddingTop="8px">
      <Block paddingX="16px" paddingY="8px">
        <Text.Body className="uppercase text-secondary" size="tiny">
          {t("networkTokenBalances.balance", "Balance")}
        </Text.Body>
      </Block>
      {tokenQuantities.slice(0, shownTokenCount).map(tokenQuantity => {
        const isNativeCurrency =
          getNativeCurrencySymbol(activeChain) === tokenQuantity.symbol
        const isWrappedCurrency =
          getWrappedCurrencySymbol(activeChain) === tokenQuantity.symbol

        if (isSolana(activeChain) && isWrappedCurrency) {
          return null // Do not show wrapped currency on solana
        }

        if (isNativeCurrency || (isPolygon(activeChain) && isWrappedCurrency)) {
          return (
            <AddFundsModal
              chain={tokenQuantity.chain}
              key={`${tokenQuantity.chain}-${tokenQuantity.symbol}`}
              overlayRef={keepOpenOnClickRefCallback}
              symbol={tokenQuantity.symbol}
              trigger={open => (
                <AddTokenListItem
                  onClick={() => {
                    trackClickAddToBalance({
                      symbol: tokenQuantity.symbol,
                      chain: tokenQuantity.chain,
                      type: "add",
                    })
                    open()
                  }}
                >
                  <AddTokenPriceText
                    symbol={tokenQuantity.symbol}
                    unit={tokenQuantity.quantity}
                  />
                  <AddTokenAddIcon />
                  <AddTokenPriceTextFiat usd={tokenQuantity.usdValue} />
                </AddTokenListItem>
              )}
            />
          )
        }

        // Adding wrapped currency is handled in the wallet popover
        // Use Uniswap modal for all other currencies
        if (!isWrappedCurrency && isSwapEnabled) {
          return (
            <UniswapModal
              key={tokenQuantity.symbol}
              overlayRef={keepOpenOnClickRefCallback}
              toCurrency={tokenQuantity}
              trigger={open => (
                <AddTokenListItem
                  onClick={() => {
                    trackClickAddToBalance({
                      symbol: tokenQuantity.symbol,
                      chain: tokenQuantity.chain,
                      type: "swap",
                    })
                    open()
                  }}
                >
                  <AddTokenPriceText
                    symbol={tokenQuantity.symbol}
                    unit={tokenQuantity.quantity}
                  />
                  <AddTokenAddIcon />
                  <AddTokenPriceTextFiat usd={tokenQuantity.usdValue} />
                </AddTokenListItem>
              )}
            />
          )
        }

        return (
          <AddTokenListItem key={tokenQuantity.symbol}>
            <AddTokenPriceText
              symbol={tokenQuantity.symbol}
              unit={tokenQuantity.quantity}
            />
            <AddTokenPriceTextFiat usd={tokenQuantity.usdValue} />
          </AddTokenListItem>
        )
      })}
      {tokenQuantities.length > shownTokenCount ? (
        <StyledListItem
          className="border-0"
          onClick={() =>
            setShownTokenCount(shownTokenCount + DEFAULT_SHOWN_TOKEN_COUNT)
          }
        >
          <StyledListItem.Content>
            <Text.Body className="text-secondary" size="medium">
              {t("networkTokenBalances.showMore", "Show more")}
            </Text.Body>
          </StyledListItem.Content>
        </StyledListItem>
      ) : tokenQuantities.length > DEFAULT_SHOWN_TOKEN_COUNT ? (
        <StyledListItem
          className="border-0"
          onClick={() => setShownTokenCount(DEFAULT_SHOWN_TOKEN_COUNT)}
        >
          <StyledListItem.Content>
            <Text.Body className="text-secondary" size="medium">
              {t("networkTokenBalances.showLess", "Show less")}
            </Text.Body>
          </StyledListItem.Content>
        </StyledListItem>
      ) : null}
    </Block>
  )
}

const NetworkTokenBalancesSkeleton = () => {
  const t = useTranslate("components")

  return (
    <Block paddingTop="8px">
      <Block paddingX="16px" paddingY="8px">
        <Text.Body className="uppercase text-secondary" size="tiny">
          {t("networkTokenBalances.balance", "Balance")}
        </Text.Body>
      </Block>
      <StyledListItem className="border-0">
        <StyledListItem.Content>
          <Skeleton.Line className="h-4 w-[100px]" />
        </StyledListItem.Content>
        <StyledListItem.Side>
          <Skeleton.Line className="h-[15px] w-[60px]" />
        </StyledListItem.Side>
      </StyledListItem>
      <StyledListItem className="border-0">
        <StyledListItem.Content>
          <Skeleton.Line className="h-4 w-[100px]" />
        </StyledListItem.Content>
        <StyledListItem.Side>
          <Skeleton.Line className="h-[15px] w-[60px]" />
        </StyledListItem.Side>
      </StyledListItem>
      <StyledListItem className="border-0">
        <StyledListItem.Content>
          <Skeleton.Line className="h-4 w-[100px]" />
        </StyledListItem.Content>
        <StyledListItem.Side>
          <Skeleton.Line className="h-[15px] w-[60px]" />
        </StyledListItem.Side>
      </StyledListItem>
    </Block>
  )
}

type NetworkTokenBalancesProps = Omit<
  NetworkTokenBalancesBaseProps,
  "queryReference"
> & {
  queryReference: PreloadedQuery<NetworkBalanceDropdownQuery> | undefined | null
}

export const NetworkTokenBalances = ({
  queryReference,
  ...restProps
}: NetworkTokenBalancesProps) => {
  if (!queryReference) {
    return <NetworkTokenBalancesSkeleton />
  }

  return (
    <Suspense fallback={<NetworkTokenBalancesSkeleton />}>
      <NetworkTokenBalancesBase
        queryReference={queryReference}
        {...restProps}
      />
    </Suspense>
  )
}

const AddTokenPriceText = styled(PriceText).attrs({
  size: "medium",
  weight: "semibold",
})``

const AddTokenAddIcon = styled(Icon).attrs({ value: "add", children: "add" })``

const AddTokenPriceTextFiat = styled(PriceText.Fiat).attrs({
  className: "text-secondary",
  size: "medium",
  usdSuffix: true,
})``

const AddTokenListItem = styled(StyledListItem)`
  align-items: center;
  border: none;
  flex-wrap: wrap;
  justify-content: space-between;

  ${AddTokenAddIcon} {
    display: none;
  }

  :hover {
    ${AddTokenAddIcon} + ${AddTokenPriceTextFiat} {
      display: none;
    }

    ${AddTokenAddIcon} {
      display: block;
    }
  }
`
