/**
 * @generated SignedSource<<03b7149165f5d770176f7bfa9daa3d51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type utils_calculateFundsBalance$data = {
  readonly quantity: string;
  readonly usdPrice: string | null;
  readonly " $fragmentType": "utils_calculateFundsBalance";
};
export type utils_calculateFundsBalance$key = {
  readonly " $data"?: utils_calculateFundsBalance$data;
  readonly " $fragmentSpreads": FragmentRefs<"utils_calculateFundsBalance">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "utils_calculateFundsBalance"
};

(node as any).hash = "d9959db5ee3d888e117d42d52b3116f5";

export default node;
