import React, { useCallback } from "react"
import { useCopyToClipboard } from "react-use"
import { Tooltip, TooltipProps } from "@/design-system/Tooltip"
import { useAutoResetToggle } from "@/hooks/useAutoResetToggle"
import { useTranslate } from "@/hooks/useTranslate"

type TextCopierProps = {
  label?: string
  placement?: TooltipProps["placement"]
  text: string
  children: (copy: () => void) => JSX.Element
}

export const TextCopier = ({
  children,
  placement = "top",
  label,
  text,
}: TextCopierProps) => {
  const t = useTranslate("components")
  const [isCopied, onCopied] = useAutoResetToggle()
  const [_, copyToClipboard] = useCopyToClipboard()

  const displayMessage = isCopied
    ? t("textCopier.copied", "Copied!")
    : label ?? t("textCopier.copy", "Copy")

  const copy = useCallback(() => {
    copyToClipboard(text)
    onCopied()
  }, [onCopied, copyToClipboard, text])

  return (
    <Tooltip content={displayMessage} hideOnClick={false} placement={placement}>
      {children(copy)}
    </Tooltip>
  )
}
