import { WalletAccount } from "@/lib/chain/wallet"
import * as sidecar from "../../../server/sidecar/config"
import { UNLEASH_APP_NAME } from "./constants"
import type { IConfig, IToggle, IMutableContext } from "./types"
import { unleashSessionStorage } from "./unleashSessionStorage"

export const BASE_UNLEASH_CONFIG: IConfig = {
  url: sidecar.getValue("unleashProxyUrl"),
  clientKey: sidecar.getValue("unleashProxyClientKey"),
  refreshInterval: sidecar.getValue("unleashProxyRefreshInterval"),
  environment: sidecar.getValue("unleashEnvironment"),
  storageProvider: unleashSessionStorage,
  appName: UNLEASH_APP_NAME,
  fetch: typeof window === "undefined" ? fetch : undefined,
}

export const getUnleashContext = (
  activeAccount: WalletAccount | undefined,
  sessionId: string | undefined,
  deviceId: string,
  properties: { [key: string]: string } = {},
): IMutableContext => {
  const baseConfig: IMutableContext = {
    sessionId,
    properties: {
      isTestnet: JSON.stringify(sidecar.getValue("isTestnet")),
      deviceId,
      ...properties,
    },
  }
  if (!activeAccount) {
    return baseConfig
  }

  return {
    ...baseConfig,
    userId: activeAccount.address,
    properties: {
      ...baseConfig.properties,
      ...(activeAccount.isEmployee ? { isEmployee: "true" } : {}),
    },
  }
}

export const createUnleashConfig = (
  bootstrap: IToggle[] | undefined,
  context: IMutableContext,
): IConfig => {
  return { ...BASE_UNLEASH_CONFIG, bootstrap, context }
}
