/**
 * @generated SignedSource<<ce5a3e404a7bdc6c913e899030f4f720>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TransferAssetInputType = {
  assetQuantity?: AssetQuantityInputType | null;
  recipient: string;
};
export type AssetQuantityInputType = {
  asset: string;
  quantity: string;
};
export type transferQuery$variables = {
  sender: string;
  transferAssetInputs: ReadonlyArray<TransferAssetInputType>;
};
export type transferQuery$data = {
  readonly blockchain: {
    readonly transferActions: {
      readonly actionsV2: ReadonlyArray<{
        readonly __typename: string;
        readonly method?: {
          readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_transaction">;
        };
        readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_transfer_asset">;
      }>;
    };
  };
};
export type transferQuery = {
  response: transferQuery$data;
  variables: transferQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sender"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "transferAssetInputs"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "sender",
    "variableName": "sender"
  },
  {
    "kind": "Variable",
    "name": "transferAssetInputs",
    "variableName": "transferAssetInputs"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "AddressDataType",
    "kind": "LinkedField",
    "name": "source",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "AddressDataType",
    "kind": "LinkedField",
    "name": "destination",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  },
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "data",
    "storageKey": null
  }
],
v7 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TransactionSubmissionDataType",
      "kind": "LinkedField",
      "name": "method",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "useHandleBlockchainActions_transaction",
          "selections": [
            (v3/*: any*/),
            {
              "kind": "InlineDataFragmentSpread",
              "name": "useTransaction_transaction",
              "selections": (v6/*: any*/),
              "args": null,
              "argumentDefinitions": ([]/*: any*/)
            }
          ],
          "args": null,
          "argumentDefinitions": ([]/*: any*/)
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AssetTransferActionType",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "transferQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BlockchainType",
        "kind": "LinkedField",
        "name": "blockchain",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ActionDataType",
            "kind": "LinkedField",
            "name": "transferActions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "actionsV2",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v7/*: any*/),
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "useHandleBlockchainActions_transfer_asset",
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "args": null,
                    "argumentDefinitions": []
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "transferQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BlockchainType",
        "kind": "LinkedField",
        "name": "blockchain",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ActionDataType",
            "kind": "LinkedField",
            "name": "transferActions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "actionsV2",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TransactionSubmissionDataType",
                        "kind": "LinkedField",
                        "name": "method",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "type": "AssetTransferActionType",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0b1494bbaac33df64b625625705ae01f",
    "id": null,
    "metadata": {},
    "name": "transferQuery",
    "operationKind": "query",
    "text": "query transferQuery(\n  $sender: AddressScalar!\n  $transferAssetInputs: [TransferAssetInputType!]!\n) {\n  blockchain {\n    transferActions(sender: $sender, transferAssetInputs: $transferAssetInputs) {\n      actionsV2 {\n        __typename\n        ... on AssetTransferActionType {\n          method {\n            ...useHandleBlockchainActions_transaction\n          }\n        }\n        ...useHandleBlockchainActions_transfer_asset\n      }\n    }\n  }\n}\n\nfragment useHandleBlockchainActions_transaction on TransactionSubmissionDataType {\n  chain {\n    identifier\n  }\n  ...useTransaction_transaction\n}\n\nfragment useHandleBlockchainActions_transfer_asset on AssetTransferActionType {\n  method {\n    ...useHandleBlockchainActions_transaction\n  }\n}\n\nfragment useTransaction_transaction on TransactionSubmissionDataType {\n  chain {\n    identifier\n  }\n  source {\n    value\n  }\n  destination {\n    value\n  }\n  value\n  data\n}\n"
  }
};
})();

(node as any).hash = "fb40ae9f1a3150ef9fc8ae392bf74f36";

export default node;
