import React from "react"
import { Text, Media } from "@opensea/ui-kit"
import styled from "styled-components"
import { NavButton } from "@/components/nav/Navbar/elements"
import { useNavbarIconSize } from "@/components/nav/Navbar/useNavbarIconSize"
import { NavbarInputStylesProps } from "@/components/nav/styles"
import {
  trackClickConnectWallet,
  trackClickSwitchNetwork,
} from "@/components/nav/WalletPopover/analytics"
import { Wallet } from "@/components/svgs/material-symbols/Wallet.react"
import { useWalletModal } from "@/containers/WalletModalProvider.react"
import {
  useActiveAccount,
  useWallet,
} from "@/containers/WalletProvider/WalletProvider.react"
import { useChains } from "@/hooks/useChains"
import { getCounterpartNetworkChain } from "@/hooks/useChains/utils"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { getEthereumChain } from "@/lib/helpers/chainUtils"

export const ConnectWalletButton = ({
  $background,
}: NavbarInputStylesProps) => {
  const t = useTranslate("components")
  const { startWalletModalAuthFlow } = useWalletModal()
  const { isChainEnabled, getChain } = useChains()
  const { chain, switchChain } = useWallet()
  const activeAccount = useActiveAccount()
  const iconSize = useNavbarIconSize()
  const { showErrorMessage } = useToasts()

  // chain is null if unsupported, so we only check if it is undefined for isConnecting
  const isConnecting = chain === undefined && Boolean(activeAccount)
  const isConnected = Boolean(activeAccount)

  const onClick = async () => {
    if (!isConnected) {
      trackClickConnectWallet()
      startWalletModalAuthFlow()
      return
    }

    trackClickSwitchNetwork()

    try {
      if (!chain) {
        await switchChain(getChain(getEthereumChain()))
        return
      }

      if (!isChainEnabled(chain)) {
        await switchChain(getChain(getCounterpartNetworkChain(getChain(chain))))
        return
      }
    } catch (error) {
      showErrorMessage(error.message)
    }
  }

  const ctaText = isConnecting
    ? t("connectWalletButton.initializingWallet", "Connecting")
    : isConnected
    ? t("connectWalletButton.switchNetwork", "Switch network")
    : t("connectWalletButton.connectWallet", "Login")

  return (
    <WalletPopoverButton
      $background={$background}
      disabled={isConnecting}
      onClick={onClick}
    >
      <Wallet
        size={iconSize}
        title={t("connectWalletButton.icon.title", "Wallet")}
      />
      <Media greaterThanOrEqual="lg">
        <Text.Body
          className="h-6"
          size="medium"
          weight={isConnecting ? "regular" : "semibold"}
        >
          {ctaText}
        </Text.Body>
      </Media>
    </WalletPopoverButton>
  )
}

export const WalletPopoverButton = styled(NavButton)`
  display: flex;
  align-items: center;
  column-gap: 8px;
  width: unset;
  min-width: 48px;
`
