import React from "react"
import { AuthCode } from "@opensea/ui-kit"

type Props = {
  disabled: boolean
  error: boolean
  value?: string
  onComplete: (authCode: string) => Promise<void>
}

export const SetupAuthCode = ({
  disabled,
  error,
  value,
  onComplete,
}: Props) => {
  return (
    <AuthCode
      autoFocus
      disabled={disabled}
      error={error}
      overrides={{
        Inputs: {
          className:
            "bg-white dark:bg-component-gray-1 shadow-elevation-1 sm:w-[56px] sm:h-[56px]",
        },
      }}
      value={value}
      onComplete={onComplete}
    />
  )
}
