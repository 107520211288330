import React from "react"
import { Flex, Media, Separator } from "@opensea/ui-kit"
import styled from "styled-components"
import { Block } from "@/design-system/Block"
import { classNames } from "@/styles/styleUtils"
import { HUES } from "@/styles/themes"
import { NavLogo } from "../../NavLogo.react"
import { NavbarDropdowns } from "./NavbarDropdowns.react"

export const NavbarLeft = ({
  isMobileSearchBarOpen,
  isTransparent,
}: {
  isMobileSearchBarOpen: boolean
  isTransparent: boolean
}) => {
  const openseaLettersFillColor = isTransparent ? HUES.white : undefined

  return (
    <Container
      display={{
        _: !isMobileSearchBarOpen ? "flex" : "none",
        lg: "flex",
      }}
      paddingRight={{
        _: "0px",
        md: "24px",
      }}
    >
      <NavLogo textFill={openseaLettersFillColor} />
      <Media greaterThanOrEqual="md">
        {mediaClassNames => (
          <Flex className={mediaClassNames}>
            <Block height="32px">
              <Separator
                className={classNames("mx-6", isTransparent && "bg-white/20")}
                orientation="vertical"
              />
            </Block>
            <NavbarDropdowns isBackgroundTransparent={isTransparent} />
          </Flex>
        )}
      </Media>
    </Container>
  )
}

const Container = styled(Block)`
  align-items: center;
  height: 100%;

  .Navbar--brand {
    align-items: center;
    display: flex;
    font-size: 20px;
    font-weight: 500;
    height: 100%;
    padding: 8px 0;
    position: relative;

    .Navbar--brand-name {
      margin-left: 10px;
      margin-top: 4px;
    }

    .Navbar--brand-testnets {
      background: ${props => props.theme.colors.warning};
      border-radius: 3px;
      bottom: 6px;
      color: ${props => props.theme.colors.text.on.warning};
      font-size: 10px;
      padding: 2px 4px 0 4px;
      position: absolute;
      right: 0;
    }
  }
`
