/**
 * @generated SignedSource<<71ce983a7efcca049d2d31a7e7aab832>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProcessedOrderList_ordersFilled$data = {
  readonly failedOrders: ReadonlyArray<{
    readonly itemFillAmount: string;
    readonly orderData: {
      readonly item: {
        readonly __typename: "AssetBundleType";
        readonly assetQuantities: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly asset: {
                readonly displayName: string;
                readonly " $fragmentSpreads": FragmentRefs<"StackedAssetMedia_assets">;
              };
            } | null;
          } | null>;
        };
        readonly displayName: string;
        readonly relayId: string;
      } | {
        readonly __typename: "AssetQuantityDataType";
        readonly asset: {
          readonly __typename: "AssetType";
          readonly assetContract: {
            readonly " $fragmentSpreads": FragmentRefs<"CollectionLink_assetContract">;
          };
          readonly collection: {
            readonly " $fragmentSpreads": FragmentRefs<"CollectionLink_collection">;
          };
          readonly displayName: string;
          readonly relayId: string;
          readonly " $fragmentSpreads": FragmentRefs<"AssetMedia_asset" | "asset_url">;
        };
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null;
      readonly payment: {
        readonly " $fragmentSpreads": FragmentRefs<"TokenPricePayment">;
      };
      readonly perUnitPrice: {
        readonly unit: string;
      };
      readonly " $fragmentSpreads": FragmentRefs<"useTotalItems_ordersData">;
    };
  }>;
  readonly successfulOrders: ReadonlyArray<{
    readonly itemFilledAmount: string;
    readonly order: {
      readonly item: {
        readonly __typename: "AssetBundleType";
        readonly assetQuantities: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly asset: {
                readonly displayName: string;
                readonly " $fragmentSpreads": FragmentRefs<"StackedAssetMedia_assets">;
              };
            } | null;
          } | null>;
        };
        readonly displayName: string;
      } | {
        readonly __typename: "AssetType";
        readonly assetContract: {
          readonly " $fragmentSpreads": FragmentRefs<"CollectionLink_assetContract">;
        };
        readonly collection: {
          readonly " $fragmentSpreads": FragmentRefs<"CollectionLink_collection">;
        };
        readonly displayName: string;
        readonly " $fragmentSpreads": FragmentRefs<"AssetMedia_asset" | "asset_url">;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      };
      readonly relayId: string;
      readonly " $fragmentSpreads": FragmentRefs<"useTotalItems_orders">;
    };
    readonly pricePaid: {
      readonly symbol: string;
      readonly unit: string;
      readonly usd: string;
    };
  }>;
  readonly " $fragmentType": "ProcessedOrderList_ordersFilled";
};
export type ProcessedOrderList_ordersFilled$key = {
  readonly " $data"?: ProcessedOrderList_ordersFilled$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProcessedOrderList_ordersFilled">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "AssetContractType",
  "kind": "LinkedField",
  "name": "assetContract",
  "plural": false,
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionLink_assetContract"
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "CollectionType",
  "kind": "LinkedField",
  "name": "collection",
  "plural": false,
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionLink_collection"
    }
  ],
  "storageKey": null
},
v5 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "AssetMedia_asset"
},
v6 = {
  "kind": "InlineDataFragmentSpread",
  "name": "asset_url",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetContractType",
      "kind": "LinkedField",
      "name": "assetContract",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tokenId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChainType",
      "kind": "LinkedField",
      "name": "chain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "identifier",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 30
    }
  ],
  "concreteType": "AssetQuantityTypeConnection",
  "kind": "LinkedField",
  "name": "assetQuantities",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetQuantityTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetQuantityType",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetType",
              "kind": "LinkedField",
              "name": "asset",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "StackedAssetMedia_assets"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "assetQuantities(first:30)"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProcessedOrderList_ordersFilled",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderFilledType",
      "kind": "LinkedField",
      "name": "successfulOrders",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderV2Type",
          "kind": "LinkedField",
          "name": "order",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "item",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/),
                    (v5/*: any*/),
                    (v6/*: any*/)
                  ],
                  "type": "AssetType",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v7/*: any*/)
                  ],
                  "type": "AssetBundleType",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "useTotalItems_orders"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceType",
          "kind": "LinkedField",
          "name": "pricePaid",
          "plural": false,
          "selections": [
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "usd",
              "storageKey": null
            },
            (v9/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "itemFilledAmount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderToFillType",
      "kind": "LinkedField",
      "name": "failedOrders",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderDataType",
          "kind": "LinkedField",
          "name": "orderData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "item",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AssetType",
                      "kind": "LinkedField",
                      "name": "asset",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v0/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/),
                        (v4/*: any*/),
                        (v5/*: any*/),
                        (v6/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "AssetQuantityDataType",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v0/*: any*/),
                    (v7/*: any*/)
                  ],
                  "type": "AssetBundleType",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceType",
              "kind": "LinkedField",
              "name": "perUnitPrice",
              "plural": false,
              "selections": [
                (v8/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PaymentAssetType",
              "kind": "LinkedField",
              "name": "payment",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "TokenPricePayment",
                  "selections": [
                    (v9/*: any*/)
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "useTotalItems_ordersData"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "itemFillAmount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BulkPurchaseFilledType",
  "abstractKey": null
};
})();

(node as any).hash = "d33a6e4f98326178af7e6af6b1cc2bd5";

export default node;
