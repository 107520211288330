import React from "react"

type Props = {
  className?: string
  width?: number
  fill?: string
}

export const OptimismLogo = ({
  className,
  fill = "gray",
  width = 20,
}: Props) => (
  <svg
    className={className}
    fill="none"
    height="24"
    style={{ width, height: width }}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.73712 18.316C3.34521 18.316 2.20484 17.9884 1.31589 17.3334C0.43863 16.6667 0 15.7193 0 14.4912C0 14.2338 0.0292665 13.918 0.0877068 13.5437C0.239839 12.7016 0.456209 11.6898 0.736909 10.5084C1.53226 7.29187 3.58505 5.68359 6.89521 5.68359C7.79584 5.68359 8.60288 5.83563 9.31641 6.13971C10.0299 6.4321 10.5913 6.87662 11.0007 7.47317C11.4101 8.05795 11.6148 8.7598 11.6148 9.57852C11.6148 9.82416 11.5856 10.1341 11.527 10.5084C11.3516 11.5494 11.1411 12.5612 10.8954 13.5437C10.4861 15.1462 9.77841 16.3451 8.77249 17.1404C7.76657 17.9241 6.42151 18.316 4.73712 18.316ZM4.98276 15.7895C5.63785 15.7895 6.19336 15.5965 6.64957 15.2105C7.11747 14.8245 7.45081 14.2338 7.6496 13.4385C7.91861 12.339 8.12339 11.3798 8.26374 10.5611C8.31049 10.3154 8.33386 10.064 8.33386 9.80658C8.33386 8.74222 7.77826 8.20999 6.66715 8.20999C6.01206 8.20999 5.45065 8.40298 4.98276 8.78897C4.52664 9.17505 4.1991 9.76572 4.00031 10.5611C3.78974 11.3447 3.57916 12.3039 3.36869 13.4385C3.32184 13.6723 3.29846 13.918 3.29846 14.1753C3.29846 15.2514 3.85996 15.7895 4.98276 15.7895Z"
      fill={fill}
    />
    <path
      d="M12.4202 18.1399C12.2915 18.1399 12.192 18.099 12.1219 18.0171C12.0633 17.9235 12.0459 17.8183 12.0692 17.7013L14.4904 6.29703C14.5138 6.16837 14.5781 6.06308 14.6834 5.98117C14.7887 5.89935 14.8998 5.8584 15.0168 5.8584H19.6838C20.9821 5.8584 22.0231 6.12741 22.8067 6.66543C23.6022 7.20355 23.9998 7.98132 23.9998 8.99893C23.9998 9.29132 23.9648 9.59549 23.8946 9.91125C23.6022 11.2564 23.0115 12.2506 22.1225 12.894C21.2453 13.5373 20.0405 13.8589 18.5082 13.8589H16.1397L15.3326 17.7013C15.3092 17.83 15.2448 17.9352 15.1396 18.0171C15.0343 18.099 14.9232 18.1399 14.8063 18.1399H12.4202ZM18.6311 11.4377C19.1223 11.4377 19.5492 11.3032 19.9118 11.0341C20.2861 10.7651 20.5318 10.3792 20.6487 9.87619C20.6838 9.67731 20.7014 9.50189 20.7014 9.34985C20.7014 9.01062 20.602 8.7533 20.4031 8.57788C20.2042 8.39069 19.8651 8.29718 19.3855 8.29718H17.28L16.6134 11.4377H18.6311Z"
      fill={fill}
    />
  </svg>
)
