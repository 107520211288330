export type Promiseable<T> = T | Promise<T>

export const poll = <T>(opts: {
  delay: number
  fn: () => Promiseable<T | undefined>
  maxTries?: number
}): { value: Promise<T | undefined>; cancel: () => void } => {
  let cancelled = false
  return {
    value: new Promise((resolve, reject) => {
      const callback = async (tryCount: number) => {
        const result = await opts.fn()
        if (result !== undefined || cancelled) {
          resolve(result)
          return
        }
        if (tryCount >= (opts.maxTries ?? Infinity)) {
          reject(new Error("Max retries reached while polling."))
          return
        }
        setTimeout(() => callback(tryCount + 1), opts.delay)
      }
      callback(1).catch(reject)
    }),
    cancel: () => {
      cancelled = true
    },
  }
}

export const wait = (delay: number): Promise<undefined> =>
  new Promise(resolve => setTimeout(resolve, delay))

export const asyncSome = async <T>(
  arr: Array<T>,
  predicate: (e: T) => Promise<boolean>,
): Promise<boolean> => {
  for (const e of arr) {
    if (await predicate(e)) {
      return true
    }
  }
  return false
}
