import type { _FragmentRefs } from "relay-runtime"
import { EditTab } from "@/features/collections/components/CollectionCreateOrUpdatePage/constants"
import type { collection_live_listing_stats$data } from "@/lib/graphql/__generated__/collection_live_listing_stats.graphql"
import type { collection_live_stats$data } from "@/lib/graphql/__generated__/collection_live_stats.graphql"
import type { collection_poll_stats$data } from "@/lib/graphql/__generated__/collection_poll_stats.graphql"
import type { collection_url$data } from "@/lib/graphql/__generated__/collection_url.graphql"
import { getFirstNode, graphql } from "@/lib/graphql/graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import {
  MAX_DISPLAYED_DECIMAL_PLACES,
  BigNumber,
  bn,
  roundAboveMin,
  shortSymbolDisplay,
} from "@/lib/helpers/numberUtils"
import { EMPTY_PRICE_DISPLAY } from "../../constants"

const readCollectionStats = inlineFragmentize<collection_poll_stats$data>(
  graphql`
    fragment collection_poll_stats on CollectionType @inline {
      statsV2 {
        totalListed
      }
      collectionOffers(first: 1) {
        edges {
          node {
            perUnitPriceType {
              unit
              symbol
            }
          }
        }
      }
    }
  `,
  identifiers => identifiers,
)

export const readCollectionLiveStats =
  inlineFragmentize<collection_live_stats$data>(
    graphql`
      fragment collection_live_stats on CollectionType @inline {
        statsV2 {
          numOwners
          totalSupply
          totalQuantity
          totalVolume {
            unit
            symbol
          }
        }
        numOpenCriteriaOffers
        totalCriteriaOfferVolume {
          unit
          symbol
        }
      }
    `,
    identifiers => identifiers,
  )

const readCollectionLiveListingStats =
  inlineFragmentize<collection_live_listing_stats$data>(
    graphql`
      fragment collection_live_listing_stats on CollectionType @inline {
        statsV2 {
          floorPrice {
            unit
            eth
            symbol
            usd
          }
        }
      }
    `,
    identifiers => identifiers,
  )

export const getCollectionStats = (
  ref: collection_poll_stats$data | _FragmentRefs<"collection_poll_stats">,
  liveRef: collection_live_stats$data | _FragmentRefs<"collection_live_stats">,
  liveListingRef:
    | collection_live_listing_stats$data
    | _FragmentRefs<"collection_live_listing_stats">,
) => {
  const { statsV2, collectionOffers } = readCollectionStats(ref)
  const { totalListed } = statsV2

  const {
    statsV2: liveStatsV2,
    totalCriteriaOfferVolume: liveTotalCriteriaOfferVolume,
    numOpenCriteriaOffers: liveNumOpenCriteriaOffers,
  } = readCollectionLiveStats(liveRef)
  const { numOwners, totalQuantity, totalSupply } = liveStatsV2
  const totalCriteriaOffers = liveNumOpenCriteriaOffers
  const totalCriteriaOfferVolume = roundAboveMin(
    bn(liveTotalCriteriaOfferVolume.unit),
    MAX_DISPLAYED_DECIMAL_PLACES,
  )
  const totalCriteriaOfferSymbol = liveTotalCriteriaOfferVolume.symbol

  const { statsV2: liveListingStatsV2 } =
    readCollectionLiveListingStats(liveListingRef)
  const { floorPrice } = liveListingStatsV2

  const items = shortSymbolDisplay(totalSupply, { shortenBillion: true })
  const owners = shortSymbolDisplay(numOwners)

  const isValidFloorPrice = floorPrice && !bn(floorPrice.unit).isZero()
  const floor = !isValidFloorPrice
    ? EMPTY_PRICE_DISPLAY
    : roundAboveMin(bn(floorPrice.unit), MAX_DISPLAYED_DECIMAL_PLACES)
  const floorSymbol = floorPrice?.symbol

  const totalUnitVolume = bn(liveStatsV2.totalVolume.unit)
  const volume = roundAboveMin(totalUnitVolume, 0)
  const volumeSymbol = liveStatsV2.totalVolume.symbol
  const conciseVolume = roundAboveMin(
    totalUnitVolume,
    totalUnitVolume.lt(1) ? MAX_DISPLAYED_DECIMAL_PLACES : 0,
  )
  const totalVolume = roundAboveMin(
    totalUnitVolume,
    MAX_DISPLAYED_DECIMAL_PLACES,
  )

  const highestOfferNode = getFirstNode(collectionOffers)
  const highestOffer = highestOfferNode?.perUnitPriceType.unit
  const offer = highestOffer
    ? roundAboveMin(bn(highestOffer), MAX_DISPLAYED_DECIMAL_PLACES)
    : EMPTY_PRICE_DISPLAY
  const offerSymbol = highestOfferNode?.perUnitPriceType.symbol

  const listedPercentageValue = (totalListed / totalSupply) * 100
  const listedPercentage = totalSupply
    ? `${
        listedPercentageValue < 1
          ? roundAboveMin(listedPercentageValue, 1)
          : Math.round(listedPercentageValue)
      }%`
    : EMPTY_PRICE_DISPLAY

  let uniqueOwnersPercentageValue: BigNumber
  // Special case 1 owner and more than 1 NFT to be 0% unique owners
  if (numOwners === 1 && bn(totalQuantity).isGreaterThan(1)) {
    uniqueOwnersPercentageValue = bn(0)
  } else {
    uniqueOwnersPercentageValue = bn(numOwners).div(totalQuantity).times(100)
  }
  const uniqueOwnersPercentage = !bn(totalQuantity).isZero()
    ? `${
        uniqueOwnersPercentageValue.isLessThan(1)
          ? roundAboveMin(uniqueOwnersPercentageValue, 1)
          : uniqueOwnersPercentageValue.integerValue()
      }%`
    : EMPTY_PRICE_DISPLAY

  return {
    totalVolume,
    conciseVolume,
    items,
    owners,
    floor,
    floorSymbol,
    volume,
    volumeSymbol,
    offer,
    offerSymbol,
    listedCount: totalListed,
    listedPercentage,
    uniqueOwnersCount: numOwners,
    uniqueOwnersPercentage,
    totalCriteriaOffers,
    totalCriteriaOfferVolume,
    totalCriteriaOfferSymbol,
  }
}

export const readCollectionUrlIdentifier =
  inlineFragmentize<collection_url$data>(
    graphql`
      fragment collection_url on CollectionType @inline {
        slug
        isCategory
      }
    `,
    identifiers => identifiers,
  )

const getCollectionSlug = (
  ref: collection_url$data | _FragmentRefs<"collection_url">,
) => {
  const { slug } = readCollectionUrlIdentifier(ref)
  return slug
}

export const getCollectionUrl = (
  ref: collection_url$data | _FragmentRefs<"collection_url">,
) => {
  const { slug, isCategory } = readCollectionUrlIdentifier(ref)
  return isCategory ? `/category/${slug}` : `/collection/${slug}`
}

export const getCollectionOverviewUrl = (
  ref: collection_url$data | _FragmentRefs<"collection_url">,
) => {
  const { slug } = readCollectionUrlIdentifier(ref)
  return `/collection/${slug}/overview`
}

export const getCollectionAnalyticsUrl = (
  ref: collection_url$data | _FragmentRefs<"collection_url">,
) => {
  const { slug, isCategory } = readCollectionUrlIdentifier(ref)
  return isCategory
    ? `/category/${slug}/analytics`
    : `/collection/${slug}/analytics`
}

export const getCollectionEditUrl = (
  ref: collection_url$data | _FragmentRefs<"collection_url">,
  tab?: EditTab,
) => {
  let path = `/collection/${getCollectionSlug(ref)}/edit`
  if (tab) {
    path += `?tab=${tab}`
  }
  return path
}

export const getCollectionAssetCreateUrl = (
  ref: collection_url$data | _FragmentRefs<"collection_url">,
) => {
  return `/collection/${getCollectionSlug(ref)}/assets/create`
}

export const getCollectionRoyaltiesUrl = (
  ref: collection_url$data | _FragmentRefs<"collection_url">,
) => {
  return `/collection/${getCollectionSlug(ref)}/payouts`
}

export const getBrowseCollectionAssetsUrl = (
  ref: collection_url$data | _FragmentRefs<"collection_url">,
) => {
  const { slug } = readCollectionUrlIdentifier(ref)
  return `/collection/${slug}`
}

export const getCollectionDropUrl = (
  ref: collection_url$data | _FragmentRefs<"collection_url">,
) => {
  const { slug } = readCollectionUrlIdentifier(ref)
  return `/collection/${slug}/drop`
}

export const getCollectionDropPreviewUrl = (
  ref: collection_url$data | _FragmentRefs<"collection_url">,
) => {
  const { slug } = readCollectionUrlIdentifier(ref)
  return `/collection/${slug}/drop/preview`
}

export const getCollectionPagePreviewUrl = (
  ref: collection_url$data | _FragmentRefs<"collection_url">,
) => {
  const { slug } = readCollectionUrlIdentifier(ref)
  return `/collection/${slug}/edit/preview`
}
