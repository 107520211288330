import React, { useEffect } from "react"
import {
  Icon,
  Media,
  useIsLessThanSm,
  UnstyledButton,
  Text,
  TextHeadingProps,
  Flex,
} from "@opensea/ui-kit"
import clsx from "clsx"
import styled, { css } from "styled-components"
import { Block, BlockProps } from "@/design-system/Block"
import { useModalContext } from "@/design-system/Modal/ModalContext"
import { media } from "@/styles/styleUtils"
import {
  MOBILE_MODAL_PADDING,
  MODAL_PADDING,
  MODAL_HEADER_TOP_PADDING,
  MOBILE_MODAL_HEADER_Y_PADDING,
  MODAL_HEADING_TITLE_Y_PADDING,
} from "../../constants"

type ModalHeaderProps = {
  onPrevious?: () => unknown
  backLabel?: string
  children?: React.ReactNode
  leftAlign?: boolean
} & BlockProps

const ModalHeaderBase = ({
  onPrevious,
  backLabel = "Back",
  children,
  leftAlign = true,
  ...rest
}: ModalHeaderProps) => {
  const { isModalBodyScrolled, hasModalHeaderTitle, setHasModalHeaderTitle } =
    useModalContext()
  const isMobile = useIsLessThanSm()

  useEffect(() => {
    setHasModalHeaderTitle(Boolean(children))
  }, [children, setHasModalHeaderTitle])

  return (
    <StyledModalHeader
      $hasBorder={isModalBodyScrolled}
      $hasTitle={hasModalHeaderTitle}
      $leftAlign={leftAlign}
      padding={{
        _: `${MOBILE_MODAL_HEADER_Y_PADDING}px ${MOBILE_MODAL_PADDING}px`,
        sm: `${MODAL_HEADER_TOP_PADDING}px ${MODAL_PADDING}px ${MOBILE_MODAL_PADDING}px ${MODAL_PADDING}px`,
      }}
      {...rest}
    >
      {onPrevious && (
        <Flex
          className="absolute items-center"
          style={{
            left: isMobile ? MOBILE_MODAL_PADDING : MODAL_PADDING,
            top: isMobile
              ? MOBILE_MODAL_HEADER_Y_PADDING
              : MODAL_HEADER_TOP_PADDING,
          }}
        >
          <UnstyledButton onClick={onPrevious}>
            <StyledIcon aria-label={backLabel} value="arrow_back" />
          </UnstyledButton>
        </Flex>
      )}
      {children}
    </StyledModalHeader>
  )
}

export const ModalTitle = ({
  children,
  className,
  ...textProps
}: Omit<TextHeadingProps, "size">) => {
  return (
    <StyledTitleContainer>
      <Media greaterThanOrEqual="md">
        {mediaClassNames => (
          <Text.Heading
            asChild
            {...textProps}
            className={clsx(mediaClassNames, className)}
            role="heading"
            size="medium"
          >
            <h4>{children}</h4>
          </Text.Heading>
        )}
      </Media>
      <Media lessThan="md">
        {mediaClassNames => (
          <Text.Heading
            asChild
            {...textProps}
            className={clsx(mediaClassNames, className)}
            role="heading"
            size="small"
          >
            <h4>{children}</h4>
          </Text.Heading>
        )}
      </Media>
    </StyledTitleContainer>
  )
}

export const ModalDescription = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <StyledDescriptionContainer>
      <Media greaterThanOrEqual="md">
        <Text.Body className="text-secondary" size="medium">
          {children}
        </Text.Body>
      </Media>
      <Media lessThan="md">
        <Text.Body className="text-secondary" size="small">
          {children}
        </Text.Body>
      </Media>
    </StyledDescriptionContainer>
  )
}

const StyledTitleContainer = styled(Block)``

const StyledDescriptionContainer = styled(Block)``

const StyledModalHeader = styled(Block).attrs({ as: "header" })<{
  $hasTitle: boolean
  $hasBorder: boolean
  $leftAlign?: boolean
}>`
  border-bottom: ${props =>
    props.$hasBorder &&
    css`1px solid ${props.theme.colors.components.border.level2}`};
  min-height: calc(${MOBILE_MODAL_HEADER_Y_PADDING * 2}px + 24px);
  flex-shrink: 0;

  ${StyledTitleContainer} {
    text-align: ${props => (props.$leftAlign ? "left" : "center")};
    width: 100%;
    word-break: break-word;
    padding: ${MODAL_HEADING_TITLE_Y_PADDING}px
      ${props => (props.$leftAlign ? 0 : MOBILE_MODAL_PADDING)}px;
  }

  ${props =>
    media({
      sm: css`
        ${props.$hasTitle
          ? `min-height: calc(${MODAL_HEADER_TOP_PADDING * 2}px + 24px);`
          : ""}
        ${StyledTitleContainer} {
          padding: ${MODAL_HEADING_TITLE_Y_PADDING}px
            ${props.$leftAlign ? 0 : MODAL_PADDING}px;
        }
      `,
    })}

  ${StyledTitleContainer} + ${StyledDescriptionContainer} {
    margin-top: 8px;
  }
`

const StyledIcon = styled(Icon)`
  color: ${props => props.theme.colors.text.secondary};
`

export const ModalHeader = Object.assign(ModalHeaderBase, {
  Title: ModalTitle,
  Description: ModalDescription,
})
