import { getTrackingFn } from "@/lib/analytics"
import { OrderSide } from "@/lib/graphql/__generated__/OrderType.graphql"

export const trackOpenOnChainCancellationModal = getTrackingFn(
  "open on-chain cancellation modal",
)
export const trackOpenGaslessCancellationModal = getTrackingFn(
  "open gasless cancellation modal",
)
export const trackOpenGaslessCancelProcessingModal = getTrackingFn(
  "open gasless cancel processing modal",
)
export const trackOpenGaslessCancelFailedModal = getTrackingFn(
  "open gasless cancel failed modal",
)

type GaslessCancelResult = {
  failedCount: number
  totalCount: number
  fulfilledCount: number
  canceledQuantity: number
  initialRemainingQuantity: number
  ordersSide?: OrderSide
}

export const trackConfirmGaslessCancel = getTrackingFn("confirm gasless cancel")

export const trackGaslessCancellationSuccess =
  getTrackingFn<GaslessCancelResult>("gasless cancel success")
export const trackGaslessCancellationFailed =
  getTrackingFn<GaslessCancelResult>("gasless cancel failed")
