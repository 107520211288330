/**
 * @generated SignedSource<<a29def510a314ee2a8765d8b50ce7662>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RarityStrategyId = "OPENRARITY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useIsRarityEnabled_collection$data = {
  readonly enabledRarities: ReadonlyArray<RarityStrategyId>;
  readonly slug: string;
  readonly " $fragmentType": "useIsRarityEnabled_collection";
};
export type useIsRarityEnabled_collection$key = {
  readonly " $data"?: useIsRarityEnabled_collection$data;
  readonly " $fragmentSpreads": FragmentRefs<"useIsRarityEnabled_collection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useIsRarityEnabled_collection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enabledRarities",
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "ccce5a3a60daa572978e7bd7b9de7c7b";

export default node;
