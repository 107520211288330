import { graphql, useFragment } from "react-relay"
import { useIsAvailableForBuyWithCard } from "@/features/moonpay/utils/useIsAvailableForBuyWithCard"
import { usePaymentMethod_moonpay$key } from "@/lib/graphql/__generated__/usePaymentMethod_moonpay.graphql"
import { usePaymentMethod_order$key } from "@/lib/graphql/__generated__/usePaymentMethod_order.graphql"
import { bn } from "@/lib/helpers/numberUtils"

type Props = {
  order: usePaymentMethod_order$key
  moonpay: usePaymentMethod_moonpay$key
}

export const usePaymentMethod = ({
  order: orderKey,
  moonpay: moonpayKey,
}: Props) => {
  const order = useFragment(
    graphql`
      fragment usePaymentMethod_order on OrderV2Type {
        relayId
        orderType
        item {
          __typename
          relayId
        }
        itemQuantityType
      }
    `,
    orderKey,
  )

  const moonpay = useFragment(
    graphql`
      fragment usePaymentMethod_moonpay on MoonpayType
      @argumentDefinitions(listingId: { type: "OrderRelayID!" }) {
        ...useIsAvailableForBuyWithCard_data @arguments(listingId: $listingId)
      }
    `,
    moonpayKey,
  )

  const maxQuantity = bn(
    order.orderType === "CRITERIA" ? 1 : order.itemQuantityType,
  )

  const maxQuantityOfOne = maxQuantity.isEqualTo(bn(1))

  const item = order.item

  const { isAvailableForBuyWithCard } = useIsAvailableForBuyWithCard({
    dataKey: moonpay,
    itemId: item.relayId,
  })

  const orderType = order.orderType

  if (
    orderType === "ENGLISH" ||
    item.__typename === "AssetBundleType" ||
    !maxQuantityOfOne
  ) {
    return { enabled: false, message: undefined }
  }

  if (!isAvailableForBuyWithCard) {
    return {
      enabled: false,
      message: undefined,
    }
  }

  return { enabled: true, message: undefined }
}
