import React from "react"
import { classNames, Flex } from "@opensea/ui-kit"

export type WalletSteps = {
  /* 1-based step number */
  step: number
  totalSteps: number
}

type Props = {
  className?: string
} & WalletSteps

export const WalletStepIndicator = ({ className, step, totalSteps }: Props) => {
  if (!step || !totalSteps) {
    return null
  }
  return (
    <Flex
      className={classNames("items-center justify-center gap-3", className)}
    >
      {[...Array(totalSteps)].map((_, index) => (
        <div
          className={classNames(
            "h-3 w-3 rounded-full border-[1.5px] border-white",
            {
              "bg-white": index + 1 < step,
            },
          )}
          key={index}
        />
      ))}
    </Flex>
  )
}
