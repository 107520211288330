import { useMemo } from "react"
import { memoize } from "lodash"
import { estimateGasForSwap } from "../utils/estimateGasForSwap"

export const useMemoizedEstimateGasForSwap = () => {
  return useMemo(() => {
    return memoize(
      estimateGasForSwap,
      ({ baseCurrency, swapCurrency, direction }) => {
        const fromCurrency = direction === "out" ? baseCurrency : swapCurrency
        const toCurrency = direction === "out" ? swapCurrency : baseCurrency
        return `from ${fromCurrency.symbol} on ${fromCurrency.chain} to ${toCurrency.symbol} on ${toCurrency.chain}`
      },
    )
  }, [])
}
