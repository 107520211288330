import { FragmentRef } from "relay-runtime"
import type { AssetCardVariant } from "@/components/search/assets/AssetSearchView"
import { Source } from "@/components/trade/analytics"
import { SelectionBatchAction } from "@/features/assets/components/AssetSelection/types"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { itemEvents_data$data } from "@/lib/graphql/__generated__/itemEvents_data.graphql"
import { itemEvents_dataV2$data } from "@/lib/graphql/__generated__/itemEvents_dataV2.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { Maybe } from "@/lib/helpers/type"
import { getPageTrackingFn, getTrackingFn } from "../utils"

type Item = {
  itemId: string
  chainIdentifier: ChainIdentifier
  // optional for bundles
  address?: string
  tokenId?: string
}

type AnalyticsItem = itemEvents_dataV2$data

type NonSelectionOption = "copyLink" | "editAsset" | "setAsProfilePicture"
type AssetContextMenuOption = SelectionBatchAction | NonSelectionOption

const getItemAnalyticsParams = (item: AnalyticsItem): Item => ({
  address: item.assetContract?.address,
  chainIdentifier: item.chain.identifier,
  tokenId: item.tokenId,
  itemId: item.relayId,
})

const readItem = inlineFragmentize<itemEvents_dataV2$data, Item>(
  graphql`
    fragment itemEvents_dataV2 on ItemType @inline {
      relayId
      chain {
        identifier
      }
      ... on AssetType {
        tokenId
        assetContract {
          address
        }
      }
    }
  `,
  getItemAnalyticsParams,
)

type AnalyticsAsset = itemEvents_data$data

const getAssetAnalyticsParams = (asset: AnalyticsAsset): Item => ({
  itemId: asset.relayId,
  address: asset.assetContract.address,
  chainIdentifier: asset.chain.identifier,
  tokenId: asset.tokenId,
})

export const readAsset = inlineFragmentize<itemEvents_data$data, Item>(
  graphql`
    fragment itemEvents_data on AssetType @inline {
      relayId
      assetContract {
        address
      }
      tokenId
      chain {
        identifier
      }
    }
  `,
  getAssetAnalyticsParams,
)

// Utils
/**
 * @deprecated Use useTrackingFn
 */
export const getItemTrackingFn = (eventName: string) => {
  const trackingFn = getTrackingFn<Item>(eventName)
  return (item: FragmentRef<itemEvents_data$data>) => {
    trackingFn(readAsset(item))
  }
}

/**
 * @deprecated Use useTrackingFn
 */
export const getItemTrackingFnV2 = (eventName: string) => {
  const trackingFn = getTrackingFn<Item>(eventName)
  return (item: FragmentRef<itemEvents_dataV2$data>) => {
    trackingFn(readItem(item))
  }
}

/**
 * @deprecated Use useTrackingFn
 */
export const getItemTrackingFnWithExtraParams = <
  T extends Record<string, unknown>,
>(
  eventName: string,
) => {
  const trackingFn = getTrackingFn<Item & T>(eventName)
  return (item: FragmentRef<itemEvents_data$data>, params: T) => {
    trackingFn({ ...readAsset(item), ...params })
  }
}

/**
 * @deprecated Use useTrackingFn
 */
export const getItemTrackingFnWithExtraParamsV2 = <
  T extends Record<string, unknown>,
>(
  eventName: string,
) => {
  const trackingFn = getTrackingFn<Item & T>(eventName)
  return (item: FragmentRef<itemEvents_dataV2$data>, params: T) => {
    trackingFn({ ...readItem(item), ...params })
  }
}

/**
 * @deprecated Use useTrackingFn
 */
export const getItemFragmentPageTrackingFn = (pageName: string) => {
  const trackingFn = getPageTrackingFn<Item>(pageName)
  return (item: FragmentRef<itemEvents_data$data>) => {
    trackingFn(readAsset(item))
  }
}

// Item Events with Item Params
export const trackClosePurchaseFlowMultiModal = getItemTrackingFnV2(
  "close purchase flow multimodal",
)
export const trackReturnToPreviousStepPurchaseFlowMultiModal =
  getItemTrackingFnV2("return to previous step on purchase flow multimodal")

// Item Events with Item Params & Extra Params
export const trackNavigateToSimilarItems = getItemTrackingFnWithExtraParams<{
  similarItem: Item
  index: number
}>("navigate to similar item")

export const trackSetAssetPrivacy = getTrackingFn<{
  isPrivate: boolean
  numItems: number
}>("set asset privacy")

export const trackClickFeaturedAsset = getItemTrackingFnWithExtraParams<{
  assetName?: Maybe<string>
  creatorUsername?: Maybe<string>
  link: string
}>("click featured asset")

// Asset Modification Events
export const trackCreateAsset = getItemTrackingFnWithExtraParams<{
  collectionSlug?: string
  unlockableContent: boolean
  isNsfw: boolean
}>("create asset")
export const trackEditAsset = getItemTrackingFn("edit asset")
export const trackDeleteAsset = getItemTrackingFn("delete asset")

export const trackAssetRecaptcha = getTrackingFn<{ success: boolean }>(
  "create asset recaptcha",
)

export const trackAssetCreatedFromUI = getTrackingFn("create asset from UI")

export const trackAssetTransfer = getItemTrackingFn("transfer asset")

// Item Events without Item Params
type AssetIdParams = {
  assetId: string
}
export const trackUploadFrozenMetadata = getTrackingFn<AssetIdParams>(
  "upload frozen metadata",
)

type ReviewCollectionModalParams = {
  collectionSlug: string
}

export const trackOpenReviewCollectionModal =
  getItemTrackingFnWithExtraParams<ReviewCollectionModalParams>(
    "open review collection modal",
  )
export const trackCloseReviewCollectionModal =
  getItemTrackingFnWithExtraParams<ReviewCollectionModalParams>(
    "close review collection modal",
  )

export const trackOpenShareDropdown = getItemTrackingFn("open share dropdown")

export const trackRefreshMetadata = getItemTrackingFn("refresh metadata")

export const trackOpenPriceWarningModal = getTrackingFn(
  "open price warning modal",
)

export const trackQuickSingleListingModalOpen = getItemTrackingFnV2(
  "quick single listing modal open",
)

export const trackQuickSingleListingModalClose = getItemTrackingFnV2(
  "quick single listing modal close",
)

export const trackQuickSingleListingModalSubmitted = getItemTrackingFnV2(
  "quick single listing modal submitted",
)

export const trackQuickSingleListingModalClickOpenFullView =
  getItemTrackingFnV2("quick single listing modal open full view click")

export const trackOpenAboveListingPriceWarningModal = getTrackingFn(
  "open above listing price warning modal",
)

export const trackCreateListingButton = getItemTrackingFnWithExtraParamsV2<{
  source: Source
}>("create listing button clicked")

export const trackEditListingButton = getItemTrackingFnWithExtraParamsV2<{
  source: Source
}>("edit listing button clicked")

export const trackEditListingSubmitted = getItemTrackingFnWithExtraParamsV2<{
  previousAmount: number
  newAmount: number
  source: Source
}>("edit listing submitted")

export const trackEditListingWarningModal = getTrackingFn(
  "edit listing modal shown",
)

export const trackDismissEditListingWarningModal = getTrackingFn(
  "edit price listing modal dismissed",
)

export const trackingReviewListings = getTrackingFn(
  "review listings after lower price",
)

export const trackHiddenListingWarning = getTrackingFn<{ variant: string }>(
  "show hidden listing warning",
)

export const trackClickItemCardVariantToggle = getTrackingFn<{
  variant: AssetCardVariant
}>("click item card variant toggle")

export const trackClickItemCardContextMenuOption =
  getItemTrackingFnWithExtraParams<{ option: AssetContextMenuOption }>(
    "click item card context menu option",
  )

export const trackOpenItemCardContextMenu = getItemTrackingFnV2(
  "open item card context menu",
)
