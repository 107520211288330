import "regenerator-runtime/runtime"
import { NextPageContext } from "next"
import {
  RRNLRequestError,
  RelayNetworkLayerRequestBatch,
  GraphQLResponseErrors,
} from "react-relay-network-modern"
import { flatMap } from "@/lib/helpers/array"
import {
  captureNoncriticalError,
  captureWarning,
  captureCriticalError,
} from "@/lib/sentry"
import { LoggedInAccount } from "../auth"

class GraphQLResponseError extends Error {
  status?: number

  constructor(message: string, status?: number) {
    super(message)
    this.status = status
  }
}

const getRRNLRequestErrorMessages = (error: RRNLRequestError): string[][] => {
  const text = error.res?.text
  if (!text) {
    return [error.res?.errors?.map(e => e.message) || []]
  }
  try {
    const errorData = JSON.parse(text)
    return (
      error.req instanceof RelayNetworkLayerRequestBatch
        ? errorData
        : [errorData]
    ).map(
      (data: { errors?: GraphQLResponseErrors }) =>
        data.errors?.map(e => e.message) || [],
    )
  } catch (_) {
    // ignore
  }
  return []
}

export const getGraphQLResponseErrors = (error: RRNLRequestError) => {
  return flatMap(
    flatMap(getRRNLRequestErrorMessages(error), msgs => msgs),
    msg => {
      try {
        return Object.values(JSON.parse(msg) as Record<string, string[]>).map(
          values => new GraphQLResponseError(values.join(" ")),
        )
      } catch (_) {
        const match = msg.match(/^\[(\d+)\] (.+)/)
        const status = match?.[1]
        const message = match?.[2]
        if (status && message) {
          return [new GraphQLResponseError(message, parseInt(status))]
        }
        return [new GraphQLResponseError(msg)]
      }
    },
  )
}

export const maybeGetGraphQLResponseErrors = <T>(
  error: T,
): ReadonlyArray<GraphQLResponseError> => {
  return error instanceof RRNLRequestError
    ? getGraphQLResponseErrors(error)
    : []
}

export const getFirstGraphqlResponseErrorMessage = <T>(error: T) => {
  const responses = maybeGetGraphQLResponseErrors(error)
  return responses.length > 0 ? responses[0].message : undefined
}

export const getFirstGraphqlResponseErrorStatus = <T>(error: T) => {
  const responses = maybeGetGraphQLResponseErrors(error)
  return responses.length > 0 ? responses[0].status : undefined
}

export const hasGraphQLResponseError = <T>(error: T, message: string) =>
  maybeGetGraphQLResponseErrors(error).some(e => e.message === message)

export const hasGraphQLResponseErrorWithStatus = <T>(
  error: T,
  status: number,
  message?: string,
) =>
  maybeGetGraphQLResponseErrors(error).some(
    e => e.status === status && (!message || e.message === message),
  )

export const handleError = async (
  error: RRNLRequestError | unknown,
  login: () => Promise<LoggedInAccount | undefined>,
  logout: () => Promise<unknown>,
  context?: NextPageContext,
): Promise<void> => {
  if (error instanceof RRNLRequestError) {
    if (hasGraphQLResponseErrorWithStatus(error, 401, "Invalid JWT.")) {
      await logout()
      await login()
    } else if (error.res?.text) {
      // Client-sourced issue
      captureNoncriticalError(error, { ctx: context || error })
    } else {
      captureWarning(error)
    }
  } else {
    if (error instanceof Error) {
      captureCriticalError(error, context)
    }
    // Silently ignore non-Error exceptions
  }
}

export const isFetchError = (error: Error) => {
  return !!error.message.match("Failed to fetch") || error.name === "FetchError"
}

export const getErrorId = (error: Error) => {
  return error.message.split("Error id: ")[1]
}
