import { useTranslate } from "@/hooks/useTranslate"

export const useCategoriesInfo = () => {
  const t = useTranslate("hooks")
  return [
    {
      key: "categories.art",
      slug: "art",
      name: t("categories.art.name", "Art"),
      text: t(
        "categories.art.text",
        "An online community of makers, developers, and traders is pushing the art world into new territory. Discover the world's top crypto artists.",
      ),
    },
    {
      key: "categories.gaming",
      slug: "gaming",
      name: t("categories.gaming.name", "Gaming"),
      text: t("categories.gaming.text", "NFTs for gaming."),
    },
    {
      key: "categories.memberships",
      slug: "memberships",
      name: t("categories.memberships.name", "Memberships"),
      text: t("categories.memberships.text", "NFT memberships."),
    },
    {
      key: "categories.pfps",
      slug: "pfps",
      name: t("categories.pfps.name", "PFPs"),
      text: t("categories.pfps.text", "NFT profile pictures."),
    },
    {
      key: "categories.photography",
      slug: "photography",
      name: t("categories.photography.name", "Photography"),
      text: t(
        "categories.photography.text",
        "NFTs are providing photographers new ways of sharing their work. Explore collections from the top photographers around the world.",
      ),
    },
  ] as const
}
