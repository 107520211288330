import { isString } from "lodash"
import {
  IS_DEVELOPMENT,
  IS_TEST,
  getEnvironment,
  IS_SERVER,
} from "@/constants/environment"
import { getAmplitudeSessionId } from "@/lib/analytics/amplitude"
import API from "@/lib/api"
import * as sidecar from "@/server/sidecar/config"

const extractTracingOrigin = (url: string) => {
  // proxied through current origin
  if (url.startsWith("/")) {
    return window.location.origin
  }
  try {
    return new URL(url).origin
  } catch {
    return undefined
  }
}

export async function initDatadogRum() {
  const applicationId =
    process.env.NEXT_PUBLIC_DATADOG_BROWSER_RUM_APPLICATION_ID

  const clientToken = process.env.NEXT_PUBLIC_DATADOG_BROWSER_RUM_CLIENT_ID

  if (
    IS_SERVER ||
    IS_DEVELOPMENT ||
    IS_TEST ||
    !applicationId ||
    !clientToken
  ) {
    return
  }

  const { datadogRum } = await import("@datadog/browser-rum")

  const unleashProxyUrl = sidecar.getValue("unleashProxyUrl")
  const allowedTracingUrls = Array.from(
    new Set(
      [
        extractTracingOrigin(API.getRestUrl()),
        extractTracingOrigin(API.getGraphqlUrl()),
        extractTracingOrigin(unleashProxyUrl),
      ].filter(isString),
    ),
  )

  datadogRum.init({
    applicationId,
    clientToken,
    site: "datadoghq.com",
    service: "opensea-next",
    sessionSampleRate: 15,
    env: getEnvironment(),
    useSecureSessionCookie: true,
    allowedTracingUrls,
    version: sidecar.getValue("buildId"),
    excludedActivityUrls: [
      "https://api.amplitude.com/",
      "https://opensea.io/__api/features/proxy",
      "https://stats.g.doubleclick.net/j/collect",
    ],
  })

  const amplitudeSessionId = await getAmplitudeSessionId()
  datadogRum.setGlobalContextProperty(
    "Amplitude Session Id",
    amplitudeSessionId,
  )
}

export const trackAction = async (name: string, context?: object) => {
  const { datadogRum } = await import("@datadog/browser-rum")
  return datadogRum.addAction(name, context)
}
