/* eslint-disable tailwindcss/no-custom-classname */
import React from "react"
import {
  Icon,
  UnstyledButton,
  Text,
  Label,
  Input,
  FlexColumn,
  Flex,
} from "@opensea/ui-kit"
import BigNumber from "bignumber.js"
import { useCopyToClipboard } from "react-use"
import styled, { css } from "styled-components"
import { ExternalLink } from "@/components/common/ExternalLink"
import { Link } from "@/components/common/Link"
import { ModalLoader } from "@/components/common/ModalLoader.react"
import { LEARN_URL } from "@/constants/index"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { Modal } from "@/design-system/Modal"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { Tooltip } from "@/design-system/Tooltip"
import { useIsMoonPayAllowed } from "@/features/moonpay"
import { useOpenMoonPayBuyWidget } from "@/features/moonpay/sdk-components/MoonPayProvider"
import { useAutoResetToggle } from "@/hooks/useAutoResetToggle"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { useWalletBalance } from "@/hooks/useWalletBalance"
import { trackConsolidatedCheckoutOpenDepositModal } from "@/lib/analytics/events/checkoutEvents"
import { truncateAddress } from "@/lib/helpers/address"
import { display } from "@/lib/helpers/numberUtils"
import { EM_DASH } from "@/lib/helpers/stringUtils"
import { media } from "@/styles/styleUtils"
import { useChains } from "../../../../../hooks/useChains"
import { EnoughFeesPrompt } from "./EnoughFeesPrompt"

export enum ActionType {
  purchase = "purchase",
  mint = "mint",
}

export type CheckoutDepositModalProps = {
  symbol: string
  chain: ChainIdentifier
  amount: BigNumber
  totalPrice: BigNumber
  onFundsAdded: () => void
  action?: ActionType
}

export const CheckoutDepositModal = ({
  symbol,
  chain,
  amount,
  totalPrice,
  onFundsAdded,
  action = ActionType.purchase,
}: CheckoutDepositModalProps) => {
  const t = useTranslate("components")
  const { onNext } = useMultiStepFlowContext()

  const { getChainName } = useChains()
  const { wallet } = useWallet()
  const address = wallet.getActiveAccountKeyStrict().address

  const [_, copy] = useCopyToClipboard()
  const copyWalletAddress = () => {
    copy(address)
    toggleIsCopied()
  }
  const [isCopied, toggleIsCopied] = useAutoResetToggle()

  const balance = useWalletBalance({
    symbol,
    chain,
  })
  const hasEnoughFunds = balance?.isGreaterThanOrEqualTo(totalPrice)

  const { isTopupAllowed, isLoading } = useIsMoonPayAllowed()

  const showAddFundsWithCard = isTopupAllowed && !hasEnoughFunds

  useMountEffect(() => {
    trackConsolidatedCheckoutOpenDepositModal({ address, symbol })
  })

  const displayMessage = isCopied
    ? t("trade.checkoutDepositModal.copied", "Copied!")
    : t("trade.checkoutDepositModal.copy", "Copy")

  const symbolText = symbol
    ? t("trade.checkoutDepositModal.yourWallet", "Your {{symbol}} wallet:", {
        symbol,
      })
    : t("trade.checkoutDepositModal.walletAddress", "Wallet address:")

  const openMoonPayBuyWidget = useOpenMoonPayBuyWidget()

  if (isLoading) {
    return <ModalLoader />
  }

  // Prefix the symbol with the chain's name for common cross-chain currencies.
  // Avoid saying "Etheruem ETH" since that's weird.
  const networkPrefixedSymbol =
    ["ETH", "WETH"].includes(symbol) && chain !== "ETHEREUM"
      ? `${getChainName(chain)} ${symbol}`
      : symbol

  return (
    <DivContainer>
      <Modal.Header>
        <Modal.Header.Title>
          {action === ActionType.purchase
            ? t(
                "trade.checkoutDepositModal.titlePurchase",
                "Add funds to purchase",
              )
            : t("trade.checkoutDepositModal.titleMint", "Add funds to mint")}
        </Modal.Header.Title>
      </Modal.Header>
      <Modal.Body padding="24px 40px">
        <FlexColumn>
          <Block marginBottom="24px">
            <Text.Body asChild className="mb-3" size="medium" weight="semibold">
              <h4>
                {t(
                  "trade.checkoutDepositModal.youNeedAmountAndGas",
                  "You need {{amount}} {{networkPrefixedSymbol}} + {{gasFees}}",
                  {
                    amount: display(totalPrice, symbol, true),
                    networkPrefixedSymbol,
                    gasFees: (
                      <ExternalLink
                        href={`${LEARN_URL}/nft-gas-fees`}
                        target="_blank"
                      >
                        {t("trade.checkoutDepositModal.gasFees", "gas fees")}
                      </ExternalLink>
                    ),
                  },
                )}
              </h4>
            </Text.Body>
            <Text.Body asChild className="text-secondary" size="medium">
              <p>
                {t(
                  "trade.checkoutDepositModal.transfer",
                  "Transfer funds to your wallet or add funds with a card. It can take up to a minute for your balance to update.",
                )}
              </p>
            </Text.Body>
          </Block>
          <Flex className="mb-3 w-full justify-between">
            <Label className="DepositModal--show-on-medium">{symbolText}</Label>
            <Label className="DepositModal--hide-on-medium">
              {t("trade.checkoutDepositModal.walletAddress", "Wallet address:")}
            </Label>
            <Flex className="items-center gap-1">
              <Text>
                {t(
                  "trade.checkoutDepositModal.balance",
                  "Balance: {{amount}}",
                  {
                    amount: balance
                      ? `${display(balance, symbol)} ${symbol}`
                      : EM_DASH,
                  },
                )}
              </Text>
            </Flex>
          </Flex>

          <Flex className="relative w-full">
            <Input
              className="DepositModal--hide-on-medium"
              disabled
              id="walletAddress"
              name="walletAddress"
              readOnly
              value={truncateAddress(address)}
            />
            <Input
              className="DepositModal--show-on-medium"
              disabled
              id="walletAddress"
              name="walletAddress"
              readOnly
              value={address}
            />
            <Tooltip
              content={displayMessage}
              hideOnClick={false}
              placement="top"
            >
              <Block className="absolute right-3.5 top-3.5">
                <UnstyledButton
                  aria-label={t("trade.checkoutDepositModal.copy", "Copy")}
                  onClick={copyWalletAddress}
                >
                  <Icon
                    className="text-secondary"
                    size={21}
                    value="content_copy"
                  />
                </UnstyledButton>
              </Block>
            </Tooltip>
          </Flex>
        </FlexColumn>
      </Modal.Body>
      <Modal.Footer>
        <Block className="w-full" textAlign="center">
          <Button
            className="mb-5 w-full"
            disabled={!hasEnoughFunds}
            onClick={() => {
              if (balance?.isEqualTo(totalPrice)) {
                onNext(<EnoughFeesPrompt />)
              } else {
                onFundsAdded()
              }
            }}
          >
            {t("trade.checkoutDepositModal.continue", "Continue")}
          </Button>
          {showAddFundsWithCard && (
            <Link
              variant="subtle"
              onClick={() => {
                openMoonPayBuyWidget(
                  {
                    amount,
                    chain,
                    symbol,
                    onTransactionCompleted: onFundsAdded,
                  },
                  (content, _modalProps) => onNext(content),
                )
              }}
            >
              {t(
                "trade.checkoutDepositModal.addFundsWithCard",
                "Add funds with card",
              )}
            </Link>
          )}
        </Block>
      </Modal.Footer>
    </DivContainer>
  )
}

const DivContainer = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 20px;
  vertical-align: middle;
  justify-content: center;
  width: 100%;

  .DepositModal--transfer-copy {
    color: ${props => props.theme.colors.text.primary};
  }

  .DepositModal--hide-on-medium {
    display: block;
    ${media({
      md: css`
        display: none;
      `,
    })}
  }

  .DepositModal--show-on-medium {
    display: none;
    ${media({
      md: css`
        display: block;
      `,
    })}
  }

  .DepositModal--address-input {
    padding-right: 40px;
  }

  .DepositModal--label {
    font-weight: 600;
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
    margin: auto;
    line-height: 14px;

    &:hover {
      color: ${props => props.theme.colors.darkSeaBlue};
    }

    .DepositModal--info-icon {
      display: inline-block;
      margin: auto;
      margin-left: 2px;
      vertical-align: middle;
    }
  }

  .DepositModal--button {
    display: flex;
    justify-content: center;
  }
`
