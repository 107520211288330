import { CollectionCreateMutationDataTypeInput } from "@/lib/graphql/__generated__/useCollectionFormCreateMutation.graphql"
import { CollectionModifyMutationDataTypeInput } from "@/lib/graphql/__generated__/useCollectionFormEditMutation.graphql"
import { getTrackingFn } from "../utils"

export const trackClickCreateCollection = getTrackingFn(
  "click create collection",
)
export const trackClickConfirmCreateCollection = getTrackingFn<{
  collectionType: string
}>("click confirm create collection")
export const trackClickDeployContractCollection = getTrackingFn<{
  chain: string
  isProxy: boolean
  symbol: string
}>("click deploy contract collection")
export const trackAddAuthorizedEditor = getTrackingFn<{ collection: string }>(
  "add authorized editor",
)
export const trackRemoveAuthorizedEditor = getTrackingFn<{
  collection: string
}>("remove authorized editor")

export const trackMoveAssetCollection = getTrackingFn("move asset collection")

export type CollectionSlugParams = {
  collectionSlug: string
}
export const trackCreateCollection = getTrackingFn<
  CollectionSlugParams & {
    collectionInput: CollectionCreateMutationDataTypeInput
  }
>("create collection")
export const trackEditCollection = getTrackingFn<{
  intitialCollectionData: CollectionModifyMutationDataTypeInput
  collectionInput: CollectionModifyMutationDataTypeInput
}>("edit collection")

export const trackAddConnectedSocials = getTrackingFn<
  CollectionSlugParams & { networkType: string }
>("add connected socials")

export const trackRemoveConnectedSocials = getTrackingFn<
  CollectionSlugParams & { networkType: string }
>("remove connected socials")

export const trackOpenReviewCollectionDetailsOfferModal =
  getTrackingFn<CollectionSlugParams>(
    "open review collection modal for collection offer",
  )

export const trackCloseReviewCollectionDetailsOfferModal =
  getTrackingFn<CollectionSlugParams>(
    "close review collection modal for collection offer",
  )
