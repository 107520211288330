import styled from "styled-components"
import { Drawer, DrawerProps } from "@/design-system/Drawer"

export type OffsetDrawerProps = DrawerProps & {
  isDragging: boolean
  xOffset: number
}

export const OffsetDrawer = styled(Drawer).attrs<OffsetDrawerProps>(
  ({ isDragging, isOpen, xOffset, anchorSide }) => ({
    style: {
      transform:
        isOpen && !isDragging
          ? "translate3d(0, 0, 0) translate3d(0, 0, 0)"
          : `translate3d(${
              anchorSide === "left" ? "-100%" : "100%"
            }, 0, 0) translate3d(${xOffset}px, 0, 0)`,
    },
  }),
)<OffsetDrawerProps>``
