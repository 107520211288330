import React, { useState } from "react"
import { Text, Input, FlexColumn } from "@opensea/ui-kit"
import { captureException } from "@sentry/nextjs"
import { Link } from "@/components/common/Link"
import { Done } from "@/components/svgs/material-symbols/Done.react"
import { PRIVACY_POLICY_URL } from "@/constants"
import { Button } from "@/design-system/Button"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { useOSWalletAuth } from "@/features/os-wallet/hooks/useOSWalletAuth"
import { useTranslate } from "@/hooks/useTranslate"
import {
  PRIVY_TERMS_URL,
  WALLET_TERMS_OF_SERVICE_URL,
} from "../../../constants"
import { OSWalletModalBody } from "./OSWalletModalBody.react"
import { WalletSelectionModal } from "./WalletSelectionModal.react"

type Props = {
  email: string
  onConfirm: () => Promise<void>
}

export const WalletSignupModal = ({ email, onConfirm }: Props) => {
  const t = useTranslate("common")
  const { onPrevious, onReplace } = useMultiStepFlowContext()
  const { createEmbeddedWallet } = useOSWalletAuth()

  const [error, setError] = useState<boolean>(false)

  return (
    <OSWalletModalBody
      className="justify-between pb-2"
      title={t("wallet.opensea.createWallet", "Create your Wallet")}
      onPrevious={onPrevious}
    >
      <FlexColumn className="gap-4 text-center">
        <Input
          className="flex-1 border-0 bg-white py-2.5 shadow-elevation-1 dark:bg-component-gray-1"
          disabled
          endEnhancer={
            <div className="flex h-[32px] w-[35px] items-center justify-center rounded-full bg-blue-3">
              <Done color="white" />
            </div>
          }
          error={Boolean(error)}
          value={email}
        />
        <Text.Body color="secondary" size="tiny">
          {t(
            "wallet.opensea.newWallet",
            "Signing up will create a new wallet using this email.",
          )}
        </Text.Body>
        {error && (
          <Text.Body color="error" size="tiny">
            {t("wallet.opensea.somethingWrong", "Something went wrong.")}
          </Text.Body>
        )}
      </FlexColumn>

      <FlexColumn className="gap-4 text-center">
        <Button
          onClick={async () => {
            try {
              await createEmbeddedWallet()
              onConfirm()
            } catch (error) {
              setError(true)
              captureException(error, { level: "error" })
            }
          }}
        >
          {t("wallet.opensea.signupAndCreate", "Sign up and Create Wallet")}
        </Button>
        <Button
          variant="secondary"
          onClick={async () => {
            // TODO(jihok): delete from privy
            onReplace(<WalletSelectionModal />)
          }}
        >
          {t("wallet.opensea.cancel", "Cancel")}
        </Button>
        <Text.Body color="secondary" size="tiny">
          {t(
            "wallet.opensea.loginTerms",
            "By continuing you agree to our {{termsLink}} & {{privacyPolicyLink}} and Privy's {{privyTermsLink}}.",
            {
              termsLink: (
                <Link
                  className="text-secondary"
                  href={WALLET_TERMS_OF_SERVICE_URL}
                  target="_blank"
                  variant="subtle"
                >
                  {t("wallet.opensea.terms", "Terms")}
                </Link>
              ),
              privacyPolicyLink: (
                <Link
                  className="text-secondary"
                  href={PRIVACY_POLICY_URL}
                  target="_blank"
                  variant="subtle"
                >
                  {t("wallet.opensea.privacyPolicy", "Privacy Policy")}
                </Link>
              ),
              privyTermsLink: (
                <Link
                  className="text-secondary"
                  href={PRIVY_TERMS_URL}
                  variant="subtle"
                >
                  {t("wallet.opensea.terms", "Terms")}
                </Link>
              ),
            },
          )}
        </Text.Body>
      </FlexColumn>
    </OSWalletModalBody>
  )
}
