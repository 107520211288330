import React, { memo } from "react"
import { Icon, UnstyledButton } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { FundItem } from "@/components/common/FundItem"
import { AddFundsModalContent } from "@/components/trade/AddFundsModal"
import { Dropdown, RenderDropdownContentProps } from "@/design-system/Dropdown"
import { List } from "@/design-system/List"
import { Tooltip } from "@/design-system/Tooltip"
import { useChains } from "@/hooks/useChains"
import { useGlobalModal } from "@/hooks/useGlobalModal"
import { useTranslate } from "@/hooks/useTranslate"
import { trackClickSideBarWalletMoreOptions } from "@/lib/analytics/events/walletEvents"
import {
  FundListItem_data$key,
  Swap,
} from "@/lib/graphql/__generated__/FundListItem_data.graphql"
import { SOLANA_ESCROW_TOKEN_SYMBOL } from "@/lib/helpers/chainUtils"
import { snakeCaseToSentenceCase } from "@/lib/helpers/stringUtils"
import { POLYGON_BRIDGE_LINK } from "../../../constants"
import { SwapModal } from "./SwapModal.react"
import { SupportedSwapIdentifier, SwapIdentifier } from "./types"

type Props = {
  data: FundListItem_data$key
  refetch: () => unknown
}

const FundListItemBase = memo(function FundListItem({
  data: dataKey,
  refetch,
}: Props) {
  const t = useTranslate("components")
  const { openModal } = useGlobalModal()

  const { name, symbol, image, quantity, usdPrice, supportedSwaps, chain } =
    useFragment(
      graphql`
        fragment FundListItem_data on WalletFundsType {
          name
          symbol
          image
          quantity
          usdPrice
          chain
          supportedSwaps {
            swapType
            symbol
            chain {
              identifier
            }
          }
        }
      `,
      dataKey,
    )

  const { getChainName } = useChains()
  const isMaticReverseSwap =
    chain === "MUMBAI" || chain === "MATIC" || chain === "AMOY"
  const from: SwapIdentifier = {
    chain: { identifier: chain },
    symbol,
  }

  const getSwapActionTitle = (swap: Swap, targetChainName: string) => {
    switch (swap) {
      case "DEPOSIT_TO_ESCROW":
        return t(
          "fundListItem.actionTitle.depositToEscrow",
          "Transfer to offer balance",
        )
      case "WITHDRAW_FROM_ESCROW":
        return t(
          "fundListItem.actionTitle.withdrawFromEscrow",
          "Withdraw from offer balance",
        )
      case "BRIDGE":
        return t(
          "fundListItem.actionTitle.bridge",
          "Bridge to {{targetChainName}}",
          { targetChainName },
          { forceString: true },
        )
      case "UNWRAP":
        return t("fundListItem.actionTitle.unwrap", "Unwrap")
      case "WRAP":
        return t("fundListItem.actionTitle.wrap", "Wrap")
      default:
        return snakeCaseToSentenceCase(swap)
    }
  }

  const renderSwapItem = ({
    Item,
    close: closeDropdown,
    to,
  }: RenderDropdownContentProps & { to: SupportedSwapIdentifier }) => {
    const actionTitle = getSwapActionTitle(
      to.swapType,
      getChainName(to.chain.identifier),
    )
    const key = `${to.symbol}-${to.chain}`
    const itemContent = (
      <>
        <Item.Avatar icon="sync" />
        <Item.Content>
          <Item.Title>{actionTitle}</Item.Title>
        </Item.Content>
      </>
    )

    if (isMaticReverseSwap) {
      return (
        <Item href={POLYGON_BRIDGE_LINK} key={key} onClick={closeDropdown}>
          {itemContent}
        </Item>
      )
    }

    return (
      <Item
        key={key}
        onClick={() => {
          openModal(
            <SwapModal from={from} key={key} to={to} onSuccess={refetch} />,
            {},
          )
          closeDropdown()
        }}
      >
        {itemContent}
      </Item>
    )
  }

  const actions = (
    <Dropdown
      content={({ close, List, Item }) => (
        <List>
          {symbol !== SOLANA_ESCROW_TOKEN_SYMBOL && (
            <Item
              onClick={() => {
                openModal(
                  <AddFundsModalContent chain={chain} symbol={symbol} />,
                  {},
                )
                close()
              }}
            >
              <Item.Avatar icon="add_circle" />
              <Item.Content>
                <Item.Title>
                  {t("fundListItem.addFunds", "Add {{symbol}}", { symbol })}
                </Item.Title>
              </Item.Content>
            </Item>
          )}
          {supportedSwaps.map(swap =>
            renderSwapItem({ close, List, Item, to: swap }),
          )}
        </List>
      )}
    >
      <Tooltip content={t("fundListItem.more", "More")} touch={false}>
        <UnstyledButton
          onClick={() =>
            trackClickSideBarWalletMoreOptions({
              symbol,
              chain,
            })
          }
        >
          <Icon aria-label={t("fundListItem.more", "More")} value="more_vert" />
        </UnstyledButton>
      </Tooltip>
    </Dropdown>
  )

  return (
    <FundItem
      Item={List.Item}
      actions={actions}
      chain={chain}
      image={image}
      name={name}
      quantity={quantity}
      symbol={symbol}
      usdPrice={usdPrice}
    />
  )
})

const FundListItemSkeleton = memo(function FundListItemSkeleton() {
  return <FundItem.Skeleton actions />
})

export const FundListItem = Object.assign(FundListItemBase, {
  Skeleton: FundListItemSkeleton,
})
