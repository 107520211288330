/**
 * @generated SignedSource<<19ad626ca1e2794ba2495e1654a2494c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderDataHeader_getOrderDataFromActions$data = {
  readonly orderData?: {
    readonly " $fragmentSpreads": FragmentRefs<"OrderDataHeader_order">;
  };
  readonly " $fragmentType": "OrderDataHeader_getOrderDataFromActions";
};
export type OrderDataHeader_getOrderDataFromActions$key = {
  readonly " $data"?: OrderDataHeader_getOrderDataFromActions$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderDataHeader_getOrderDataFromActions">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "OrderDataHeader_getOrderDataFromActions"
};

(node as any).hash = "210ee4c437cc54432088e91e124005ce";

export default node;
