import { useMemo } from "react"
import { isAfter, max } from "date-fns"
import { useContextSelector } from "use-context-selector"
import { useDebounce } from "use-debounce"
import { filter } from "@/lib/helpers/array"
import { dateFromISO8601 } from "@/lib/helpers/datetime"
import { CancelOrderContext } from "./CancelOrderContext.react"
import {
  POLLING_INTERVAL_AFTER_CHAIN_TIMESTAMP,
  POLLING_INTERVAL_BEFORE_CHAIN_TIMESTAMP,
} from "./CancelOrderStatusDataPoller.react"

export const useDebouncedCancelOrderContext = () => {
  const updateCancellations = useContextSelector(
    CancelOrderContext,
    v => v.updateCancellations,
  )

  const ordersAwaitingFinalization = useContextSelector(
    CancelOrderContext,
    v => v.ordersAwaitingFinalization,
  )

  const cancellations = useContextSelector(
    CancelOrderContext,
    v => v.cancellations,
  )

  const fulfillmentPossibleUntilChainTimes = filter(
    cancellations.map(
      cancellation => cancellation.latestFulfillmentPossibleUntilChainTimestamp,
    ),
  ).map(fulfillmentPossibleUntilChainTimestamp =>
    dateFromISO8601(fulfillmentPossibleUntilChainTimestamp),
  )

  const latestFulfillmentPossibleUntilChainTimestamp =
    fulfillmentPossibleUntilChainTimes.length
      ? max(fulfillmentPossibleUntilChainTimes).toISOString()
      : null

  const isAfterAnyFulfillmentChainTimestamps =
    fulfillmentPossibleUntilChainTimes.some(fulfillmentPossibleUntilChainTime =>
      isAfter(Date.now(), fulfillmentPossibleUntilChainTime),
    )

  const cancelContext = useMemo(
    () => ({
      ordersAwaitingFinalization,
      latestFulfillmentPossibleUntilChainTimestamp,
      updateCancellations,
    }),
    [
      ordersAwaitingFinalization,
      latestFulfillmentPossibleUntilChainTimestamp,
      updateCancellations,
    ],
  )

  return useDebounce(
    cancelContext,
    isAfterAnyFulfillmentChainTimestamps
      ? POLLING_INTERVAL_AFTER_CHAIN_TIMESTAMP
      : POLLING_INTERVAL_BEFORE_CHAIN_TIMESTAMP,
    { trailing: true, leading: false },
  )
}
