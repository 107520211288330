import { ethers } from "ethers"
import { WALLET_NAME } from "@/constants/wallet"
import { Chain } from "@/hooks/useChains/types"
import BrowserWeb3Provider from "./browserWeb3Provider"
import { detectProvider } from "./detect"
import {
  ETHERS_WEB3_PROVIDER_NETWORK,
  ExternalProvider,
} from "./web3EvmProvider"

type BitKeepEthereumProvider = ExternalProvider & {
  isBitKeep: boolean
}

type BitKeep = {
  ethereum: BitKeepProvider
}

declare global {
  interface Window {
    bitkeep?: BitKeep
  }
}

const detectBitKeepProvider = (timeout = 3000) => {
  return detectProvider({
    timeout,
    key: "bitkeep.ethereum" as keyof Window,
    isInstalled: (wallet: BitKeepEthereumProvider | undefined) =>
      wallet !== undefined && wallet.isBitKeep,
  })
}

class BitKeepProvider extends BrowserWeb3Provider {
  public getName(): WALLET_NAME {
    return WALLET_NAME.BitKeep
  }
}

export const createBitKeepProvider = async (chains: Chain[]) => {
  const ethereum = await detectBitKeepProvider()
  if (ethereum) {
    return new BitKeepProvider(
      new ethers.providers.Web3Provider(ethereum, ETHERS_WEB3_PROVIDER_NETWORK),
      chains,
    )
  }
  return undefined
}
