import React, { createContext, useContext, useMemo } from "react"
import type { Chain, ChainIdentifier } from "./types"
import { createChainByIdentifierLookup } from "./utils"

type ChainContextType = {
  chains: Chain[]
}

const ChainContext = createContext<ChainContextType>({
  chains: [],
})

export const useChainContext = () => useContext(ChainContext)

type ChainContextProviderProps = {
  children: React.ReactNode
  chains: Chain[]
}

let _chains: Chain[] = []
let _chainsByIdentifier = createChainByIdentifierLookup([])

export const getChains = () => {
  return _chains
}

export const getChain = (chain: ChainIdentifier) => {
  return _chainsByIdentifier[chain]
}

export const ChainContextProvider = ({
  children,
  chains,
}: ChainContextProviderProps) => {
  const value: ChainContextType = useMemo(() => {
    _chains = chains
    _chainsByIdentifier = createChainByIdentifierLookup(chains)
    return { chains }
  }, [chains])

  return <ChainContext.Provider value={value}>{children}</ChainContext.Provider>
}
