import React from "react"
import { Icon } from "@opensea/ui-kit"
import styled from "styled-components"
import { Flex, FlexProps } from "@/design-system/Flex"
import { themeVariant } from "@/styles/styleUtils"

type Props = {
  isActive: boolean
  isPast: boolean
} & FlexProps

const CircleIndicator = ({ isActive, isPast, ...rest }: Props) => {
  if (isActive) {
    return <ActiveCircle {...rest} />
  } else if (isPast) {
    return (
      <Circle {...rest}>
        <Icon className="text-primary" size={16} value="done" />
      </Circle>
    )
  }
  return <Circle {...rest} />
}

const PADDING_TOP_SECTION = 10
const CIRCLE_RADIUS = 12
const BORDER_WIDTH = 2

const Circle = styled(Flex)`
  z-index: 1;
  margin-top: ${PADDING_TOP_SECTION}px;
  height: ${CIRCLE_RADIUS * 2 + 1}px;
  min-width: ${CIRCLE_RADIUS * 2 + 1}px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.components.background.gray1};
  ${themeVariant({
    variants: {
      light: {
        backgroundColor: "#E4E4E4",
      },
      dark: {
        backgroundColor: "#252525",
      },
    },
  })}
`

const ActiveCircle = styled(Circle)`
  border: ${BORDER_WIDTH}px solid ${props => props.theme.colors.text.primary};
`

const VerticalScheduleLine = styled(Flex)`
  position: absolute;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.components.border.level1};
  left: ${CIRCLE_RADIUS}px;
`

const VerticalLineIndicator = styled(VerticalScheduleLine)<{
  index: number
  numberOfItems: number
}>`
  /* For the first element we only show the bottom half */
  top: ${props => (props.index === 0 ? `${PADDING_TOP_SECTION}px` : "0")};
  /* For the last element we only show the top half */
  bottom: ${props =>
    props.index === props.numberOfItems - 1
      ? `calc(100% - ${PADDING_TOP_SECTION}px)`
      : "0"};
`

export const StepIndicator = {
  Circle: CircleIndicator,
  VerticalLine: VerticalLineIndicator,
}
