import React from "react"
import { Input } from "@opensea/ui-kit"
import { ArrowRightAlt } from "@/components/svgs/material-symbols/ArrowRightAlt.react"
import { Button } from "@/design-system/Button"
import { Form } from "@/design-system/Form"
import { FormControl } from "@/design-system/FormControl"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { useOSWalletAuth } from "@/features/os-wallet/hooks/useOSWalletAuth"
import { useForm } from "@/hooks/useForm"
import { useTranslate } from "@/hooks/useTranslate"
import { EMAIL_VALIDATION_RULE } from "@/lib/helpers/validation"
import { captureException } from "@/lib/sentry"
import { WalletEmailCodeModal } from "./WalletEmailCodeModal.react"

type Props = {
  defaultEmail?: string
  hasLoginCallback?: boolean
}

type FormData = {
  email: string
}

export const OSLogin = ({ defaultEmail, hasLoginCallback }: Props) => {
  const t = useTranslate("common")
  const { sendLoginCode } = useOSWalletAuth()
  const { onNext } = useMultiStepFlowContext()
  const validateEmailDomain = useValidateEmailDomain()
  const {
    formState: { errors, isSubmitting, isValid },
    register,
    setError,
    handleSubmit,
  } = useForm<FormData>({
    mode: "onSubmit",
    defaultValues: {
      email: defaultEmail,
    },
  })

  const onSubmit = handleSubmit(async ({ email }) => {
    try {
      await sendLoginCode(email)
      onNext(() => (
        <WalletEmailCodeModal
          email={email}
          hasLoginCallback={hasLoginCallback}
        />
      ))
    } catch (error) {
      setError("email", {
        type: "manual",
        message: t("wallet.opensea.somethingWrong", "Something went wrong."),
      })
      captureException(error, { level: "error" })
      return
    }
  })

  return (
    <Form className="w-full" onSubmit={onSubmit}>
      <FormControl
        className="w-full"
        error={errors.email?.message}
        hideLabel
        label={t("wallet.opensea.email", "Email")}
      >
        <Input
          autoComplete="email"
          className="flex-1 border-0 bg-white py-2.5 shadow-elevation-1 dark:bg-component-gray-1"
          disabled={isSubmitting}
          placeholder={t(
            "wallet.opensea.continueWithEmail",
            "Continue with email",
          )}
          type="email"
          {...register("email", {
            required: t("wallet.opensea.emailRequired", "Email is required."),
            pattern: EMAIL_VALIDATION_RULE,
            validate: {
              domain: validateEmailDomain,
            },
          })}
          endEnhancer={
            <Button
              aria-label={t("wallet.opensea.login", "Login")}
              className="h-[32px] w-[32px] rounded-lg"
              disabled={!isValid || isSubmitting}
              icon={<ArrowRightAlt />}
              type="submit"
              variant="primary"
            />
          }
        />
      </FormControl>
    </Form>
  )
}

const INVALID_EMAIL_DOMAIN_REGEX: RegExp = /\.(cu|ir|sy|kp)$/gi

const useValidateEmailDomain = () => {
  const t = useTranslate("common")

  return (email: string) =>
    !INVALID_EMAIL_DOMAIN_REGEX.test(email) ||
    t("wallet.opensea.emailDomain", "Email domain is not supported.")
}
