import React from "react"
import Script from "next/script"
import { useMountEffect } from "@/hooks/useMountEffect"
import {
  initializeAmplitude,
  isAmplitudeInitialized,
} from "@/lib/analytics/amplitude/amplitude"
import { GoogleAnalytics } from "@/lib/analytics/ga"
import { useConnectedAddress } from "./WalletProvider/WalletProvider.react"
import { initializeHoneypot } from "@/lib/analytics/honeypot"

type AnalyticsWrapperProps = {
  children: React.ReactNode
  deviceId: string
  nonce?: string
}

export const AnalyticsWrapper = ({
  children,
  deviceId,
  nonce,
}: AnalyticsWrapperProps) => {
  const connectedAddress = useConnectedAddress()

  // Initialize other analytic SDKs
  useMountEffect(() => {
    // Amplitude is used for event tracking
    if (!isAmplitudeInitialized()) {
      initializeAmplitude({ userId: connectedAddress, deviceId })
    }
    // Honeypot handles geofencing
    initializeHoneypot()
  })

  return (
    <>
      <GoogleAnalytics nonce={nonce} />
      <Script
        data-cf-beacon='{"token": "96047490de144c8b91be74ba7605ab69"}'
        nonce={nonce}
        src="https://static.cloudflareinsights.com/beacon.min.js"
        strategy="afterInteractive"
      />
      {children}
    </>
  )
}
