import React, { createContext, useContext, useMemo, useState } from "react"
import { IS_SERVER } from "@/constants/environment"
import { NAV_HEIGHT_PX } from "@/styles/variables"

export const MAX_BANNER_HEIGHT = 120

type AnnouncementBannerContextType = {
  spotlightTopOffset: number
  isAnnouncementBannerShown: boolean
  isAnnouncementBannerSticky: boolean
  announcementBannerHeight: number
  visibleAnnouncementBannerHeight: number
  setIsAnnouncementBannerSticky: (isSticky: boolean) => void
  setIsAnnouncementBannerShown: (isShown: boolean) => void
  setAnnouncementBannerHeight: (height: number) => void
}

const AnnouncementBannerContext = createContext<AnnouncementBannerContextType>({
  spotlightTopOffset: 0,
  isAnnouncementBannerShown: false,
  announcementBannerHeight: 0,
  visibleAnnouncementBannerHeight: 0,
  isAnnouncementBannerSticky: false,
  setIsAnnouncementBannerSticky: () => undefined,
  setIsAnnouncementBannerShown: () => undefined,
  setAnnouncementBannerHeight: () => undefined,
})

type Props = {
  children: React.ReactNode
}

export const AnnouncementBannerProvider = ({ children }: Props) => {
  const [isAnnouncementBannerSticky, setIsAnnouncementBannerSticky] =
    useState(false)
  const [isAnnouncementBannerShown, setIsAnnouncementBannerShown] =
    useState(false)
  const [announcementBannerHeight, setAnnouncementBannerHeight] = useState(0)
  const value = useMemo(
    () => ({
      // COL-5578: This stops the spotlight containers from displaying the page background color on load.
      spotlightTopOffset:
        NAV_HEIGHT_PX + (announcementBannerHeight || MAX_BANNER_HEIGHT),
      isAnnouncementBannerShown,
      announcementBannerHeight: isAnnouncementBannerShown
        ? announcementBannerHeight
        : 0,
      isAnnouncementBannerSticky,
      setIsAnnouncementBannerSticky,
      setIsAnnouncementBannerShown,
      setAnnouncementBannerHeight,
      visibleAnnouncementBannerHeight: isAnnouncementBannerShown
        ? getVisibleAnnouncementBannerHeight(announcementBannerHeight)
        : 0,
    }),
    [
      announcementBannerHeight,
      isAnnouncementBannerShown,
      isAnnouncementBannerSticky,
    ],
  )

  return (
    <AnnouncementBannerContext.Provider value={value}>
      {children}
    </AnnouncementBannerContext.Provider>
  )
}

// Window scrolls over banner so this is height that is visible
const getVisibleAnnouncementBannerHeight = (bannerHeight: number) => {
  return Math.max(bannerHeight - (IS_SERVER ? 0 : window.scrollY), 0)
}

export const useAnnouncementBanner = () => useContext(AnnouncementBannerContext)
