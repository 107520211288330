/**
 * @generated SignedSource<<68e8a123696f112c60cb941f8cf7b065>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useHandleBlockchainActions_update_drop_pre_reveal$data = {
  readonly method: {
    readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_transaction">;
  };
  readonly " $fragmentType": "useHandleBlockchainActions_update_drop_pre_reveal";
};
export type useHandleBlockchainActions_update_drop_pre_reveal$key = {
  readonly " $data"?: useHandleBlockchainActions_update_drop_pre_reveal$data;
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_update_drop_pre_reveal">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useHandleBlockchainActions_update_drop_pre_reveal"
};

(node as any).hash = "a64c1b0431c8b331adbca9054d2b62ad";

export default node;
