/**
 * @generated SignedSource<<27447adffde15fd003797e56706a289d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BlockchainActionList_data$data = ReadonlyArray<{
  readonly __typename: "AskForDepositType";
  readonly __typename: "AskForDepositType";
  readonly " $fragmentSpreads": FragmentRefs<"AskForDepositAction_data">;
  readonly " $fragmentType": "BlockchainActionList_data";
} | {
  readonly __typename: "AskForSwapType";
  readonly __typename: "AskForSwapType";
  readonly " $fragmentSpreads": FragmentRefs<"AskForSwapAction_data">;
  readonly " $fragmentType": "BlockchainActionList_data";
} | {
  readonly __typename: "AssetApprovalActionType";
  readonly " $fragmentSpreads": FragmentRefs<"AssetApprovalAction_data">;
  readonly " $fragmentType": "BlockchainActionList_data";
} | {
  readonly __typename: "AssetBurnToRedeemActionType";
  readonly __typename: "AssetBurnToRedeemActionType";
  readonly " $fragmentSpreads": FragmentRefs<"AssetBurnToRedeemAction_data">;
  readonly " $fragmentType": "BlockchainActionList_data";
} | {
  readonly __typename: "AssetSwapActionType";
  readonly __typename: "AssetSwapActionType";
  readonly " $fragmentSpreads": FragmentRefs<"AssetSwapAction_data">;
  readonly " $fragmentType": "BlockchainActionList_data";
} | {
  readonly __typename: "AssetTransferActionType";
  readonly __typename: "AssetTransferActionType";
  readonly " $fragmentSpreads": FragmentRefs<"AssetTransferAction_data">;
  readonly " $fragmentType": "BlockchainActionList_data";
} | {
  readonly __typename: "BulkAcceptOffersActionType";
  readonly __typename: "BulkAcceptOffersActionType";
  readonly " $fragmentSpreads": FragmentRefs<"BulkAcceptOffersAction_data">;
  readonly " $fragmentType": "BlockchainActionList_data";
} | {
  readonly __typename: "BulkFulfillOrdersActionType";
  readonly __typename: "BulkFulfillOrdersActionType";
  readonly " $fragmentSpreads": FragmentRefs<"BulkFulfillOrdersAction_data">;
  readonly " $fragmentType": "BlockchainActionList_data";
} | {
  readonly __typename: "CancelOrderActionType";
  readonly __typename: "CancelOrderActionType";
  readonly " $fragmentSpreads": FragmentRefs<"CancelOrderAction_data">;
  readonly " $fragmentType": "BlockchainActionList_data";
} | {
  readonly __typename: "CancelSwapOrdersActionType";
  readonly __typename: "CancelSwapOrdersActionType";
  readonly " $fragmentSpreads": FragmentRefs<"CancelSwapOrdersAction_data">;
  readonly " $fragmentType": "BlockchainActionList_data";
} | {
  readonly __typename: "CollectionTokenMetadataUpdateActionType";
  readonly __typename: "CollectionTokenMetadataUpdateActionType";
  readonly " $fragmentSpreads": FragmentRefs<"UpdatePreRevealAction_data">;
  readonly " $fragmentType": "BlockchainActionList_data";
} | {
  readonly __typename: "CreateBulkOrderActionType";
  readonly __typename: "CreateBulkOrderActionType";
  readonly " $fragmentSpreads": FragmentRefs<"CreateBulkOrderAction_data">;
  readonly " $fragmentType": "BlockchainActionList_data";
} | {
  readonly __typename: "CreateOrderActionType";
  readonly __typename: "CreateOrderActionType";
  readonly " $fragmentSpreads": FragmentRefs<"CreateOrderAction_data">;
  readonly " $fragmentType": "BlockchainActionList_data";
} | {
  readonly __typename: "CreateSwapOrderActionType";
  readonly __typename: "CreateSwapOrderActionType";
  readonly " $fragmentSpreads": FragmentRefs<"CreateSwapOrderAction_data">;
  readonly " $fragmentType": "BlockchainActionList_data";
} | {
  readonly __typename: "DropContractDeployActionType";
  readonly __typename: "DropContractDeployActionType";
  readonly " $fragmentSpreads": FragmentRefs<"DeployContractAction_data">;
  readonly " $fragmentType": "BlockchainActionList_data";
} | {
  readonly __typename: "DropMechanicsUpdateActionType";
  readonly __typename: "DropMechanicsUpdateActionType";
  readonly " $fragmentSpreads": FragmentRefs<"UpdateDropMechanicsAction_data">;
  readonly " $fragmentType": "BlockchainActionList_data";
} | {
  readonly __typename: "FulfillOrderActionType";
  readonly __typename: "FulfillOrderActionType";
  readonly " $fragmentSpreads": FragmentRefs<"FulfillOrderAction_data">;
  readonly " $fragmentType": "BlockchainActionList_data";
} | {
  readonly __typename: "FulfillSwapOrderActionType";
  readonly __typename: "FulfillSwapOrderActionType";
  readonly " $fragmentSpreads": FragmentRefs<"FulfillSwapOrderAction_data">;
  readonly " $fragmentType": "BlockchainActionList_data";
} | {
  readonly __typename: "MintActionType";
  readonly __typename: "MintActionType";
  readonly " $fragmentSpreads": FragmentRefs<"MintAction_data">;
  readonly " $fragmentType": "BlockchainActionList_data";
} | {
  readonly __typename: "MintYourOwnCollectionActionType";
  readonly __typename: "MintYourOwnCollectionActionType";
  readonly " $fragmentSpreads": FragmentRefs<"MintYourOwnCollectionAction_data">;
  readonly " $fragmentType": "BlockchainActionList_data";
} | {
  readonly __typename: "PaymentAssetApprovalActionType";
  readonly __typename: "PaymentAssetApprovalActionType";
  readonly " $fragmentSpreads": FragmentRefs<"PaymentAssetApprovalAction_data">;
  readonly " $fragmentType": "BlockchainActionList_data";
} | {
  readonly __typename: "SetCreatorFeesActionType";
  readonly __typename: "SetCreatorFeesActionType";
  readonly " $fragmentSpreads": FragmentRefs<"SetCreatorFeesAction_data">;
  readonly " $fragmentType": "BlockchainActionList_data";
} | {
  readonly __typename: "SetRoyaltyInfoActionType";
  readonly __typename: "SetRoyaltyInfoActionType";
  readonly " $fragmentSpreads": FragmentRefs<"SetRoyaltyInfoAction_data">;
  readonly " $fragmentType": "BlockchainActionList_data";
} | {
  readonly __typename: "SetTransferValidatorActionType";
  readonly __typename: "SetTransferValidatorActionType";
  readonly " $fragmentSpreads": FragmentRefs<"SetTransferValidatorAction_data">;
  readonly " $fragmentType": "BlockchainActionList_data";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "BlockchainActionList_data";
}>;
export type BlockchainActionList_data$key = ReadonlyArray<{
  readonly " $data"?: BlockchainActionList_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"BlockchainActionList_data">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BlockchainActionList_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AssetApprovalAction_data"
        }
      ],
      "type": "AssetApprovalActionType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AskForDepositAction_data"
        }
      ],
      "type": "AskForDepositType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AskForSwapAction_data"
        }
      ],
      "type": "AskForSwapType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AssetSwapAction_data"
        }
      ],
      "type": "AssetSwapActionType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AssetTransferAction_data"
        }
      ],
      "type": "AssetTransferActionType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CreateOrderAction_data"
        }
      ],
      "type": "CreateOrderActionType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CreateBulkOrderAction_data"
        }
      ],
      "type": "CreateBulkOrderActionType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CreateSwapOrderAction_data"
        }
      ],
      "type": "CreateSwapOrderActionType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CancelOrderAction_data"
        }
      ],
      "type": "CancelOrderActionType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CancelSwapOrdersAction_data"
        }
      ],
      "type": "CancelSwapOrdersActionType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FulfillOrderAction_data"
        }
      ],
      "type": "FulfillOrderActionType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FulfillSwapOrderAction_data"
        }
      ],
      "type": "FulfillSwapOrderActionType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BulkAcceptOffersAction_data"
        }
      ],
      "type": "BulkAcceptOffersActionType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BulkFulfillOrdersAction_data"
        }
      ],
      "type": "BulkFulfillOrdersActionType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PaymentAssetApprovalAction_data"
        }
      ],
      "type": "PaymentAssetApprovalActionType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MintAction_data"
        }
      ],
      "type": "MintActionType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DeployContractAction_data"
        }
      ],
      "type": "DropContractDeployActionType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UpdateDropMechanicsAction_data"
        }
      ],
      "type": "DropMechanicsUpdateActionType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SetCreatorFeesAction_data"
        }
      ],
      "type": "SetCreatorFeesActionType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SetTransferValidatorAction_data"
        }
      ],
      "type": "SetTransferValidatorActionType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SetRoyaltyInfoAction_data"
        }
      ],
      "type": "SetRoyaltyInfoActionType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UpdatePreRevealAction_data"
        }
      ],
      "type": "CollectionTokenMetadataUpdateActionType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AssetBurnToRedeemAction_data"
        }
      ],
      "type": "AssetBurnToRedeemActionType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MintYourOwnCollectionAction_data"
        }
      ],
      "type": "MintYourOwnCollectionActionType",
      "abstractKey": null
    }
  ],
  "type": "BlockchainActionType",
  "abstractKey": "__isBlockchainActionType"
};

(node as any).hash = "220f76f58ac418691f5c5216cbb3520f";

export default node;
