import React, { useState } from "react"
import { Text, Checkbox, Alert, Flex, Skeleton } from "@opensea/ui-kit"
import { useLazyLoadQuery } from "react-relay"
import { Link } from "@/components/common/Link"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { useTranslate } from "@/hooks/useTranslate"
import { BulkCancelOrdersQuery } from "@/lib/graphql/__generated__/BulkCancelOrdersQuery.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { BulkCancelOrdersButton } from "./BulkCancelOrdersButton.react"

const query = graphql`
  query BulkCancelOrdersQuery($address: AddressScalar) {
    seaportOffersMade: orders(
      first: 1
      onlySeaport: true
      isExpired: false
      makerAssetIsPayment: true
      includeCriteriaOrders: true
      maker: { address: $address }
    ) {
      count
    }
    seaportActiveListings: orders(
      first: 1
      onlySeaport: true
      isExpired: false
      takerAssetIsPayment: true
      includeCriteriaOrders: true
      maker: { address: $address }
    ) {
      count
    }
    seaportInactiveListings: orders(
      first: 1
      onlySeaport: true
      isInactive: true
      takerAssetIsPayment: true
      includeCriteriaOrders: true
      maker: { address: $address }
    ) {
      count
    }
  }
`

export const BulkCancelOrders = () => {
  const t = useTranslate("settings")
  const connectedAddress = useConnectedAddress()
  const { seaportOffersMade, seaportActiveListings, seaportInactiveListings } =
    useLazyLoadQuery<BulkCancelOrdersQuery>(query, {
      address: connectedAddress,
    })

  const [approved, setApproved] = useState(false)

  const seaportActiveListingsCount = seaportActiveListings.count
  const seaportInactiveListingsCount = seaportInactiveListings.count
  const seaportOffersCount = seaportOffersMade.count

  const activeListingsCount = seaportActiveListingsCount
  const inactiveListingsCount = seaportInactiveListingsCount
  const offersCount = seaportOffersCount

  const seaportTotalCount =
    seaportActiveListingsCount +
    seaportInactiveListingsCount +
    seaportOffersCount

  const showCancelButton = !!seaportTotalCount

  return (
    <>
      {showCancelButton ? (
        <Text>
          {t("bulkCancel.thisWillCancel", "This method will cancel")}{" "}
          <Link href="/account?tab=listings">
            {t(
              "bulkCancel.activeListings",
              {
                "0": "{{count}} active listings",
                one: "{{count}} active listing",
                other: "{{count}} active listings",
              },
              { count: activeListingsCount },
            )}
          </Link>
          {", "}
          <Link href="/account?tab=listings_inactive">
            {t(
              "bulkCancel.inactiveListings.title",
              {
                "0": "{{count}} inactive listings",
                one: "{{count}} inactive listing",
                other: "{{count}} inactive listings",
              },
              { count: inactiveListingsCount },
            )}
          </Link>{" "}
          {t("bulkCancel.theWordAnd", "and")}{" "}
          <Link href="/account/offers">
            {t(
              "bulkCancel.offers",
              {
                "0": "{{count}} offers.",
                one: "{{count}} offer.",
                other: "{{count}} offers.",
              },
              { count: offersCount },
            )}
          </Link>{" "}
          {t(
            "bulkCancel.doNotProceed",
            "Do not proceed if you intend to cancel a single listing or offer.",
          )}
        </Text>
      ) : (
        <Text asChild>
          <p>
            {t(
              "bulkCancel.noListings",
              "You currently do not have any listings or offers to cancel.",
            )}
          </p>
        </Text>
      )}
      <BulkCancelAlert />

      {showCancelButton && (
        <>
          <Flex className="items-center">
            <Checkbox
              checked={approved}
              id="approveBulkCancel"
              name="approveBulkCancel"
              onCheckedChange={checked => setApproved(checked)}
            />
            <Text asChild className="ml-4">
              <label htmlFor="approveBulkCancel">
                {t(
                  "bulkCancel.statementOfIntent",
                  "I intend to cancel all my Ethereum listings and offers",
                )}
              </label>
            </Text>
          </Flex>

          <BulkCancelOrdersButton
            disabled={!approved}
            shouldCancelSeaportOrders={!!seaportTotalCount}
          />
        </>
      )}
    </>
  )
}

const BulkCancelAlert = () => {
  const t = useTranslate("settings")
  return (
    <Alert className="my-5">
      <Alert.Icon color="blue-3" value="attach_money" />

      <Alert.Content>
        <Alert.Body>
          {t(
            "bulkCancel.gasSavings",
            "This method saves gas compared to cancelling an individual listing or offer.",
          )}
        </Alert.Body>
      </Alert.Content>
    </Alert>
  )
}

export const BulkCancelOrdersSkeleton = () => (
  <Skeleton>
    <Skeleton.Row>
      <Skeleton.Line />
    </Skeleton.Row>
    <Skeleton.Row>
      <Skeleton.Line />
    </Skeleton.Row>
    <BulkCancelAlert />
  </Skeleton>
)
