import { useContextSelector } from "use-context-selector"
import { OSWalletContext } from "@/features/os-wallet/OSWalletContextProvider.react"

export const useOSWalletTrigger = () => ({
  anchorRef: useContextSelector(OSWalletContext, context => context.anchorRef),
  openOSWallet: useContextSelector(
    OSWalletContext,
    context => context.openOSWallet,
  ),
  closeOSWallet: useContextSelector(
    OSWalletContext,
    context => context.closeOSWallet,
  ),
  toggleOSWallet: useContextSelector(
    OSWalletContext,
    context => context.toggleOSWallet,
  ),
})
