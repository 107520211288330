/**
 * @generated SignedSource<<cddd2cc7a9f088c67a5d7c0cd72a072f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type readOrderDataPrices$data = {
  readonly item: {
    readonly quantity?: string;
  } | null;
  readonly payment: {
    readonly " $fragmentSpreads": FragmentRefs<"TokenPricePayment">;
  };
  readonly perUnitPrice: {
    readonly unit: string;
    readonly usd: string;
  };
  readonly " $fragmentType": "readOrderDataPrices";
};
export type readOrderDataPrices$key = {
  readonly " $data"?: readOrderDataPrices$data;
  readonly " $fragmentSpreads": FragmentRefs<"readOrderDataPrices">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readOrderDataPrices"
};

(node as any).hash = "ca9bf75cf71051dca6762673479ff92c";

export default node;
