import { pathWithoutQuery } from "@/hooks/useRouter"
import { getIsAuthStateSimplificationEnabled } from "./auth/flags"

const WALLET_REQUIRED_ROUTES = new Set([
  "/account",
  "/account/listings",
  "/account/listings/[collectionSlug]",
  "/account/private",
  "/account/activity",
  "/account/[collectionSlug]",
  "/account/featured",
  "/account/favorites",
  "/account/bids",
  "/account/bids/[collectionSlug]",
  "/account/offers",
  "/account/offers/[collectionSlug]",
  "/account/collected",
  "/account/collected/[collectionSlug]",
  "/account/created",
  "/account/created/[collectionSlug]",
  "/account/deals",
  "/account/deals/active",
  "/account/deals/accepted",
  "/account/deals/canceled",
  "/account/deals/inactive",
  "/account/deals/expired",
  "/account/[collectionSlug]",
  "/assets/[chain]/[assetContractAddress]/[tokenId]/sell",
  "/assets/[chain]/[assetContractAddress]/[tokenId]/transfer",
  "/bulk-list/[chain]",
  "/studio",
  "/studio/collection/deploy",
  "/studio/create",
  "/studio/mint",
  "/deal/create/[takerAddress]",
])

// This is marked legacy as soon as we have auth state simplification enabled, this group will be a subset of the auth required routes
// - with auth state simplification enabled, all previously wallet required routes are auth required routes
// TODO: remove this group when auth state simplification is enabled
const LEGACY_AUTH_REQUIRED_ROUTES = new Set([
  "/account/listings-inactive",
  "/account/listings-inactive/[collectionSlug]",
  "/account/settings",
  "/asset/create",
  "/collections",
  "/collection/create",
  "/collection/[collectionSlug]/payouts",
  "/collection/[collectionSlug]/edit",
  "/collection/[collectionSlug]/assets/create",
  "/collection/[collectionSlug]/asset/[chain]/[assetContractAddress]/[tokenId]/edit",
  "/collection/[collectionSlug]/drop/preview",
  "/collection/[collectionSlug]/edit/preview",
  "/studio",
  "/studio/collection/deploy",
  "/studio/create",
  "/studio/mint",
  "/my-watchlist",
  "/rankings/watchlist",
])

const SIMPLIFIED_AUTH_REQUIRED_ROUTES = new Set<string>([])
LEGACY_AUTH_REQUIRED_ROUTES.forEach(route => {
  SIMPLIFIED_AUTH_REQUIRED_ROUTES.add(route)
})
WALLET_REQUIRED_ROUTES.forEach(route => {
  SIMPLIFIED_AUTH_REQUIRED_ROUTES.add(route)
})

/**
 * Set auth required routes based on the state of auth state simplification flag.
 * - If auth state simplification is enabled, use the simplified set which combines wallet and legacy auth routes.
 * This is a function rather than a constant defined at runtime so tests can more easily mock the flag.
 */
const getAuthRequiredRoutes = () =>
  getIsAuthStateSimplificationEnabled()
    ? SIMPLIFIED_AUTH_REQUIRED_ROUTES
    : LEGACY_AUTH_REQUIRED_ROUTES

const WALLET_REQUIRED_PATTERNS = Array.from(WALLET_REQUIRED_ROUTES).map(
  route => new RegExp(`${route.replace(/\[(.*)\]/, "(.*)")}$`),
)

const getAuthRequiredPatterns = () =>
  Array.from(getAuthRequiredRoutes()).map(
    route => new RegExp(`${route.replace(/\[(.*)\]/, "(.*)")}$`),
  )

export const hrefRequiresWallet = (href: string) => {
  const path = pathWithoutQuery(href)
  return WALLET_REQUIRED_PATTERNS.some(pattern => path.match(pattern))
}

export const pathnameRequiresWallet = (pathname: string) => {
  return WALLET_REQUIRED_ROUTES.has(pathname)
}

export const pathnameRequiresAuth = (pathname: string): boolean => {
  return getAuthRequiredRoutes().has(pathname)
}

export const hrefRequiresAuth = (href: string): boolean => {
  const path = pathWithoutQuery(href)
  return getAuthRequiredPatterns().some(pattern => path.match(pattern))
}
