import React from "react"
import styled from "styled-components"
import { useTheme } from "@/design-system/Context"
import { themeVariant } from "@/styles/styleUtils"
import THEMES from "@/styles/themes"

type Props = {
  className?: string
  height?: number
  width?: number
}

export const FeaturedIcon = ({ height = 24, width = 24, ...rest }: Props) => {
  const { theme } = useTheme()
  return (
    <StyledSvg
      height={height}
      width={width}
      {...rest}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M1.588 18.413C1.97933 18.8043 2.45 19 3 19H13C13.55 19 14.021 18.8043 14.413 18.413C14.8043 18.021 15 17.55 15 17V7C15 6.45 14.8043 5.97933 14.413 5.588C14.021 5.196 13.55 5 13 5H3C2.45 5 1.97933 5.196 1.588 5.588C1.196 5.97933 1 6.45 1 7V17C1 17.55 1.196 18.021 1.588 18.413ZM17 5V19H19V5H17ZM21 5V19H23V5H21Z"
      />
      <path d="M8 15.3637C8.07778 15.3637 8.15244 15.3444 8.224 15.3059C8.29493 15.2667 8.35 15.2016 8.3892 15.1108L9.24413 13.2441L11.1108 12.3892C11.2016 12.35 11.2667 12.2949 11.3059 12.224C11.3444 12.1524 11.3637 12.0778 11.3637 12C11.3637 11.9222 11.3444 11.8476 11.3059 11.776C11.2667 11.7051 11.2016 11.65 11.1108 11.6108L9.24413 10.7559L8.3892 8.8892C8.35 8.79836 8.29493 8.73333 8.224 8.69413C8.15244 8.65556 8.07778 8.63627 8 8.63627C7.92222 8.63627 7.84756 8.65556 7.776 8.69413C7.70507 8.73333 7.65 8.79836 7.6108 8.8892L6.75587 10.7559L4.8892 11.6108C4.79836 11.65 4.73333 11.7051 4.69413 11.776C4.65556 11.8476 4.63627 11.9222 4.63627 12C4.63627 12.0778 4.65556 12.1524 4.69413 12.224C4.73333 12.2949 4.79836 12.35 4.8892 12.3892L6.75587 13.2441L7.6108 15.1108C7.65 15.2016 7.70507 15.2667 7.776 15.3059C7.84756 15.3444 7.92222 15.3637 8 15.3637ZM2.63333 19C2.17973 19 1.79396 18.8413 1.476 18.524C1.15867 18.206 1 17.8203 1 17.3667V6.63333C1 6.17973 1.15867 5.79396 1.476 5.476C1.79396 5.15867 2.17973 5 2.63333 5H13.3667C13.8203 5 14.206 5.15867 14.524 5.476C14.8413 5.79396 15 6.17973 15 6.63333V17.3667C15 17.8203 14.8413 18.206 14.524 18.524C14.206 18.8413 13.8203 19 13.3667 19H2.63333Z" />
      <path
        d="M8 16C7.8912 16 7.8016 15.9802 7.7312 15.9339C7.6608 15.8876 7.5968 15.8149 7.5584 15.7025L6.6304 13.4545L4.288 12.3967C4.1792 12.3504 4.1088 12.2909 4.064 12.2182C4.0192 12.1455 4 12.0463 4 11.9339C4 11.8215 4.0192 11.7289 4.064 11.6496C4.1088 11.5702 4.1792 11.5107 4.288 11.4711L6.624 10.4463L7.552 8.29752C7.5968 8.18512 7.6544 8.1124 7.7248 8.06612C7.8016 8.01983 7.8912 8 7.9936 8C8.096 8 8.192 8.01983 8.2688 8.06612C8.3456 8.1124 8.4032 8.18512 8.4416 8.29752L9.3696 10.4463L11.7056 11.4711C11.8144 11.5174 11.8848 11.5769 11.9296 11.6496C11.9744 11.7289 11.9936 11.8215 11.9936 11.9339C11.9936 12.0463 11.9744 12.1388 11.9296 12.2182C11.8848 12.2975 11.8144 12.357 11.7056 12.3967L9.3696 13.4545L8.4416 15.7025C8.3968 15.8149 8.3392 15.8876 8.2688 15.9339C8.192 15.9802 8.1024 16 7.9936 16H8Z"
        fill={THEMES[theme].colors.base1}
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  ${({ theme }) =>
    themeVariant({
      variants: {
        light: {
          fill: theme.colors.oil,
        },
        dark: {
          fill: theme.colors.fog,
        },
      },
    })}
`
