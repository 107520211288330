import { useCallback } from "react"
import { addBreadcrumb } from "@sentry/nextjs"
import { getUnixTime } from "date-fns"
import { ethers } from "ethers"
import { graphql, readInlineData } from "react-relay"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import {
  GaslessCancelResult,
  useGaslessCancelOrders,
} from "@/features/cancel-orders/hooks/useGaslessCancelOrders"
import { useChains } from "@/hooks/useChains"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { useIsSeaportEnabled } from "@/hooks/useIsSeaportEnabled"
import { TransactionOptions } from "@/lib/chain/provider"
import { useHandleBlockchainActions_approve_asset$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_approve_asset.graphql"
import { useHandleBlockchainActions_approve_payment_asset$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_approve_payment_asset.graphql"
import { useHandleBlockchainActions_ask_for_asset_swap$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_ask_for_asset_swap.graphql"
import { useHandleBlockchainActions_bulk_accept_offers$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_bulk_accept_offers.graphql"
import { useHandleBlockchainActions_bulk_fulfill_orders$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_bulk_fulfill_orders.graphql"
import { useHandleBlockchainActions_burnToRedeem$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_burnToRedeem.graphql"
import { useHandleBlockchainActions_cancel_all_orders$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_cancel_all_orders.graphql"
import { useHandleBlockchainActions_cancel_orders$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_cancel_orders.graphql"
import { useHandleBlockchainActions_cancel_swap_orders$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_cancel_swap_orders.graphql"
import { useHandleBlockchainActions_create_bulk_order$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_create_bulk_order.graphql"
import { useHandleBlockchainActions_create_order$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_create_order.graphql"
import { useHandleBlockchainActions_create_swap_order$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_create_swap_order.graphql"
import { useHandleBlockchainActions_deploy_contract$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_deploy_contract.graphql"
import { useHandleBlockchainActions_fulfill_order$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_fulfill_order.graphql"
import { useHandleBlockchainActions_fulfill_swap_order$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_fulfill_swap_order.graphql"
import { useHandleBlockchainActions_mint_asset$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_mint_asset.graphql"
import { useHandleBlockchainActions_mint_your_own_collection$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_mint_your_own_collection.graphql"
import { useHandleBlockchainActions_set_creator_fees$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_set_creator_fees.graphql"
import { useHandleBlockchainActions_set_royalty_info$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_set_royalty_info.graphql"
import { useHandleBlockchainActions_set_transfer_validator$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_set_transfer_validator.graphql"
import { useHandleBlockchainActions_swap_asset$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_swap_asset.graphql"
import { useHandleBlockchainActions_transaction$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_transaction.graphql"
import { useHandleBlockchainActions_transfer_asset$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_transfer_asset.graphql"
import { useHandleBlockchainActions_update_drop_mechanics$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_update_drop_mechanics.graphql"
import { useHandleBlockchainActions_update_drop_pre_reveal$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_update_drop_pre_reveal.graphql"
import { useHandleBlockchainActionsCancelOrdersMutation } from "@/lib/graphql/__generated__/useHandleBlockchainActionsCancelOrdersMutation.graphql"
import { useHandleBlockchainActionsCreateBulkOrderMutation } from "@/lib/graphql/__generated__/useHandleBlockchainActionsCreateBulkOrderMutation.graphql"
import { useHandleBlockchainActionsCreateOrderMutation } from "@/lib/graphql/__generated__/useHandleBlockchainActionsCreateOrderMutation.graphql"
import { useHandleBlockchainActionsCreateSwapOrderMutation } from "@/lib/graphql/__generated__/useHandleBlockchainActionsCreateSwapOrderMutation.graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"
import { isKlaytn, isPolygon } from "@/lib/helpers/chainUtils"
import { dateFromISO8601 } from "@/lib/helpers/datetime"
import { BigNumber, ETH_DECIMALS } from "@/lib/helpers/numberUtils"
import { querySwapActions } from "@/lib/helpers/swap"
import { UnreachableCaseError } from "@/lib/helpers/type"
import { TransactionHash, useTransaction } from "../useTransaction"

export type Transaction = {
  transactionHash: TransactionHash
  chain: ChainIdentifier
  evmResponse?: ethers.providers.TransactionResponse
  blockExplorerLink?: string
}

const USE_HANDLE_BLOCKCHAIN_ACTIONS_TRANSACTION_FRAGMENT = graphql`
  fragment useHandleBlockchainActions_transaction on TransactionSubmissionDataType
  @inline {
    chain {
      identifier
    }
    ...useTransaction_transaction
  }
`

/**
 * This is only used for 0x exchange signatures. Due to an implementation detail signatures need to be
 * re-ordered
 */
const reorderSignatureRsvToVrs = (signature: string) => {
  if (signature.length !== 132) {
    throw new Error(
      "Expect signature to be a hex thing with a total length of 132 characters (including the '0x' prefix)",
    )
  }
  const vrs = {
    r: signature.substr(2, 64),
    s: signature.substr(66, 64),
    v: signature.substr(-2),
  }

  return `0x${vrs.v}${vrs.r}${vrs.s}`
}

export const useHandleBlockchainActions = () => {
  const {
    transact,
    getBlockExplorerLink,
    estimateGas: estimateTransactionGas,
  } = useTransaction()
  const { wallet } = useWallet()
  const { mutate } = useGraphQL()
  const { chain, switchChain } = useWallet()
  const { isEvmChain, getChain } = useChains()

  const gaslessCancelOrders = useGaslessCancelOrders()

  const isSeaportEnabled = useIsSeaportEnabled(chain)

  const toTransaction = useCallback(
    async (
      dataKey: useHandleBlockchainActions_transaction$key,
      transactionOptions?: TransactionOptions,
    ): Promise<Transaction> => {
      const data = readInlineData(
        USE_HANDLE_BLOCKCHAIN_ACTIONS_TRANSACTION_FRAGMENT,
        dataKey,
      )

      const transactionResponse = await transact(data, transactionOptions)
      const blockExplorerLink = getBlockExplorerLink(
        transactionResponse.hash,
        data.chain.identifier,
      )

      const breadcrumbData = {
        hash: transactionResponse.hash,
        chain: data.chain.identifier,
        link: blockExplorerLink,
      }

      addBreadcrumb({
        category: "blockchain",
        message: "Transaction Started",
        data: breadcrumbData,
        level: "info",
      })

      return {
        transactionHash: transactionResponse.hash,
        evmResponse: transactionResponse.evmResponse,
        chain: data.chain.identifier,
        blockExplorerLink,
      }
    },
    [getBlockExplorerLink, transact],
  )

  const estimateGas = useCallback(
    async (
      dataKey: useHandleBlockchainActions_transaction$key,
    ): Promise<BigNumber> => {
      const data = readInlineData(
        USE_HANDLE_BLOCKCHAIN_ACTIONS_TRANSACTION_FRAGMENT,
        dataKey,
      )

      return await estimateTransactionGas(data)
    },
    [estimateTransactionGas],
  )

  const createOrder = useCallback(
    async (dataKey: useHandleBlockchainActions_create_order$key) => {
      const {
        method: {
          clientMessage,
          clientSignatureStandard,
          serverSignature,
          orderData,
          chain: orderChain,
        },
      } = readInlineData(
        graphql`
          fragment useHandleBlockchainActions_create_order on CreateOrderActionType
          @inline {
            method {
              clientMessage
              clientSignatureStandard
              serverSignature
              orderData
              chain {
                identifier
              }
            }
          }
        `,
        dataKey,
      )

      if (!chain || (isEvmChain(chain) && chain !== orderChain.identifier)) {
        await switchChain(getChain(orderChain.identifier))
      }

      let clientSignature = await (clientSignatureStandard === "PERSONAL"
        ? wallet.sign(clientMessage, {
            clientSignatureStandard,
          })
        : wallet.signTypedData(clientMessage, {
            clientSignatureStandard,
          }))

      // TODO: remove after we migrate off 0x.
      // This is pretty hacky, as it's untyped, but we won't be needing this much longer after 0x is removed. All counter orders can assumed to be 0x, and we assume that down below too.
      const isCounterOrder = Boolean(JSON.parse(orderData).is_counter_order)

      if (
        clientSignatureStandard !== "PERSONAL" &&
        (!isSeaportEnabled || isCounterOrder)
      ) {
        if (isPolygon(chain) || isKlaytn(chain)) {
          // The 02 denotes EIP-712
          // https://github.com/0xProject/0x-protocol-specification/blob/master/v3/v3-specification.md#signature-types
          clientSignature = `${reorderSignatureRsvToVrs(clientSignature)}02`
        }
      }

      return mutate<useHandleBlockchainActionsCreateOrderMutation>(
        graphql`
          mutation useHandleBlockchainActionsCreateOrderMutation(
            $orderData: JSONString!
            $clientSignature: String!
            $serverSignature: String!
          ) {
            orders {
              create(
                orderData: $orderData
                clientSignature: $clientSignature
                serverSignature: $serverSignature
              ) {
                counterOrder {
                  relayId
                }
                order {
                  relayId
                  orderType
                  side
                  item {
                    __typename
                    relayId
                    displayName
                    tradeSummary {
                      bestAsk {
                        relayId
                        createdDate
                        orderType
                        openedAt
                        closedAt
                        isCancelled
                        isFilled
                        isOpen
                        isValid
                        side
                        priceFnEndedAt
                        perUnitPriceType {
                          unit
                          usd
                          symbol
                        }
                        priceType {
                          unit
                          usd
                          symbol
                        }
                        englishAuctionReservePriceType {
                          unit
                          usd
                          symbol
                        }
                        maker {
                          address
                        }
                        taker {
                          address
                        }
                        payment {
                          symbol
                        }
                      }
                    }
                    ... on AssetBundleType {
                      ...bundle_url
                    }
                    ...itemEvents_dataV2
                  }
                }
                transaction {
                  blockExplorerLink
                  chain {
                    identifier
                  }
                  transactionHash
                }
              }
            }
          }
        `,
        {
          orderData,
          clientSignature,
          serverSignature,
        },
        {
          updater: (store, { orders: { create } }) => {
            const { order: newOrder } = create

            // Only handle quick listing scenario.
            const isBasicListing =
              newOrder.orderType === "BASIC" && newOrder.side === "ASK"
            if (!isBasicListing) {
              return
            }

            const itemBestAskRecord = store
              .get(newOrder.relayId)
              ?.getLinkedRecord("item")
              ?.getLinkedRecord("tradeSummary")
              ?.getLinkedRecord("bestAsk")
            if (!itemBestAskRecord) {
              return
            }

            if (newOrder.relayId !== itemBestAskRecord.getValue("relayId")) {
              return
            }

            const itemRecord = store.get(newOrder.item.relayId)
            itemRecord
              ?.getLinkedRecord("orderData")
              ?.setLinkedRecord(itemBestAskRecord, "bestAskV2")
          },
        },
      )
    },
    [
      chain,
      getChain,
      isEvmChain,
      isSeaportEnabled,
      mutate,
      switchChain,
      wallet,
    ],
  )

  const createBulkOrder = useCallback(
    async (dataKey: useHandleBlockchainActions_create_bulk_order$key) => {
      const {
        method: {
          clientMessage,
          clientSignatureStandard,
          serverSignature,
          orderDatas,
          chain: orderChain,
        },
      } = readInlineData(
        graphql`
          fragment useHandleBlockchainActions_create_bulk_order on CreateBulkOrderActionType
          @inline {
            method {
              clientMessage
              clientSignatureStandard
              serverSignature
              orderDatas
              chain {
                identifier
              }
            }
          }
        `,
        dataKey,
      )

      if (!chain || (isEvmChain(chain) && chain !== orderChain.identifier)) {
        await switchChain(getChain(orderChain.identifier))
      }

      const clientSignature = await wallet.signTypedData(clientMessage, {
        clientSignatureStandard,
      })

      return mutate<useHandleBlockchainActionsCreateBulkOrderMutation>(
        graphql`
          mutation useHandleBlockchainActionsCreateBulkOrderMutation(
            $orderDatas: JSONString!
            $clientSignature: String!
            $serverSignature: String!
          ) {
            orders {
              create(
                orderDatas: $orderDatas
                clientSignature: $clientSignature
                serverSignature: $serverSignature
              ) {
                orders {
                  relayId
                  item {
                    __typename
                    relayId
                    displayName
                    ... on AssetBundleType {
                      ...bundle_url
                    }
                  }
                }
                transaction {
                  blockExplorerLink
                  chain {
                    identifier
                  }
                  transactionHash
                }
              }
            }
          }
        `,
        {
          orderDatas,
          clientSignature,
          serverSignature,
        },
      )
    },
    [chain, getChain, isEvmChain, mutate, switchChain, wallet],
  )

  const createSwapOrder = useCallback(
    async (dataKey: useHandleBlockchainActions_create_swap_order$key) => {
      const {
        method: {
          clientMessage,
          clientSignatureStandard,
          serverSignature,
          swapData,
          chain: orderChain,
        },
      } = readInlineData(
        graphql`
          fragment useHandleBlockchainActions_create_swap_order on CreateSwapOrderActionType
          @inline {
            method {
              clientMessage
              clientSignatureStandard
              serverSignature
              swapData
              chain {
                identifier
              }
            }
          }
        `,
        dataKey,
      )

      if (!chain || (isEvmChain(chain) && chain !== orderChain.identifier)) {
        await switchChain(getChain(orderChain.identifier))
      }

      let clientSignature = await (clientSignatureStandard === "PERSONAL"
        ? wallet.sign(clientMessage, {
            clientSignatureStandard,
          })
        : wallet.signTypedData(clientMessage, {
            clientSignatureStandard,
          }))

      if (clientSignatureStandard !== "PERSONAL" && !isSeaportEnabled) {
        if (isPolygon(chain) || isKlaytn(chain)) {
          // The 02 denotes EIP-712
          // https://github.com/0xProject/0x-protocol-specification/blob/master/v3/v3-specification.md#signature-types
          clientSignature = `${reorderSignatureRsvToVrs(clientSignature)}02`
        }
      }

      return mutate<useHandleBlockchainActionsCreateSwapOrderMutation>(
        graphql`
          mutation useHandleBlockchainActionsCreateSwapOrderMutation(
            $swapData: JSONString!
            $clientSignature: String!
            $serverSignature: String!
          ) {
            swaps {
              create(
                swapData: $swapData
                clientSignature: $clientSignature
                serverSignature: $serverSignature
              ) {
                __typename
                relayId
              }
            }
          }
        `,
        {
          swapData,
          clientSignature,
          serverSignature,
        },
      )
    },
    [
      chain,
      getChain,
      isEvmChain,
      isSeaportEnabled,
      mutate,
      switchChain,
      wallet,
    ],
  )

  const cancelOrders = useCallback(
    async (
      dataKey: useHandleBlockchainActions_cancel_orders$key,
    ): Promise<Transaction | GaslessCancelResult | undefined> => {
      const { method } = readInlineData(
        graphql`
          fragment useHandleBlockchainActions_cancel_orders on CancelOrderActionType
          @inline {
            method {
              __typename
              ... on TransactionSubmissionDataType {
                ...useHandleBlockchainActions_transaction
              }
              ... on SignAndPostOrderCancelType {
                cancelOrderData: data {
                  payload
                  message
                }
                serverSignature
                clientSignatureStandard
              }
              ... on GaslessCancelType {
                orderRelayIds
              }
            }
          }
        `,
        dataKey,
      )

      switch (method.__typename) {
        case "TransactionSubmissionDataType": {
          return toTransaction(method)
        }
        case "SignAndPostOrderCancelType": {
          const { cancelOrderData, clientSignatureStandard, serverSignature } =
            method

          const clientSignature = await wallet.sign(cancelOrderData.message, {
            clientSignatureStandard,
          })

          const {
            orders: {
              cancel: { transaction },
            },
          } = await mutate<useHandleBlockchainActionsCancelOrdersMutation>(
            graphql`
              mutation useHandleBlockchainActionsCancelOrdersMutation(
                $input: SignAndPostOrderCancelData!
              ) {
                orders {
                  cancel(input: $input) {
                    transaction {
                      transactionHash
                      blockExplorerLink
                      chain {
                        identifier
                      }
                    }
                  }
                }
              }
            `,
            {
              input: {
                clientSignature,
                serverSignature,
                data: cancelOrderData,
              },
            },
          )

          return transaction
            ? {
                transactionHash: transaction.transactionHash,
                chain: transaction.chain.identifier,
                blockExplorerLink: transaction.blockExplorerLink,
              }
            : undefined
        }
        case "GaslessCancelType": {
          return await gaslessCancelOrders(method.orderRelayIds)
        }
        case "%other":
          throw new UnreachableCaseError(method.__typename as never)
      }
    },
    [gaslessCancelOrders, mutate, toTransaction, wallet],
  )

  const cancelSwapOrders = useCallback(
    async (
      dataKey: useHandleBlockchainActions_cancel_swap_orders$key,
    ): Promise<Transaction> => {
      const { method } = readInlineData(
        graphql`
          fragment useHandleBlockchainActions_cancel_swap_orders on CancelSwapOrdersActionType
          @inline {
            method {
              __typename
              ...useHandleBlockchainActions_transaction
            }
          }
        `,
        dataKey,
      )

      return toTransaction(method)
    },
    [toTransaction],
  )

  const cancelAllOrders = useCallback(
    async (
      dataKey: useHandleBlockchainActions_cancel_all_orders$key,
    ): Promise<Transaction> => {
      const { method } = readInlineData(
        graphql`
          fragment useHandleBlockchainActions_cancel_all_orders on CancelAllOrdersActionType
          @inline {
            method {
              ...useHandleBlockchainActions_transaction
            }
          }
        `,
        dataKey,
      )

      return toTransaction(method)
    },
    [toTransaction],
  )

  const approveAsset = useCallback(
    (dataKey: useHandleBlockchainActions_approve_asset$key) => {
      const { method } = readInlineData(
        graphql`
          fragment useHandleBlockchainActions_approve_asset on AssetApprovalActionType
          @inline {
            method {
              ...useHandleBlockchainActions_transaction
            }
          }
        `,
        dataKey,
      )

      return toTransaction(method)
    },
    [toTransaction],
  )

  const approvePaymentAsset = useCallback(
    (dataKey: useHandleBlockchainActions_approve_payment_asset$key) => {
      const { method } = readInlineData(
        graphql`
          fragment useHandleBlockchainActions_approve_payment_asset on PaymentAssetApprovalActionType
          @inline {
            method {
              ...useHandleBlockchainActions_transaction
            }
          }
        `,
        dataKey,
      )

      return toTransaction(method)
    },
    [toTransaction],
  )

  const fulfillOrder = useCallback(
    (dataKey: useHandleBlockchainActions_fulfill_order$key) => {
      const {
        method,
        orderData: { openedAt },
      } = readInlineData(
        graphql`
          fragment useHandleBlockchainActions_fulfill_order on FulfillOrderActionType
          @inline {
            method {
              ...useHandleBlockchainActions_transaction
            }
            orderData {
              openedAt
            }
          }
        `,
        dataKey,
      )

      return toTransaction(method, {
        transactAtBlockTimestamp: getUnixTime(
          dateFromISO8601(openedAt).getTime(),
        ),
      })
    },
    [toTransaction],
  )

  const fulfillSwapOrder = useCallback(
    (dataKey: useHandleBlockchainActions_fulfill_swap_order$key) => {
      const {
        method,
        swapData: { openedAt },
      } = readInlineData(
        graphql`
          fragment useHandleBlockchainActions_fulfill_swap_order on FulfillSwapOrderActionType
          @inline {
            method {
              ...useHandleBlockchainActions_transaction
            }
            swapData {
              openedAt
            }
          }
        `,
        dataKey,
      )

      return toTransaction(method, {
        transactAtBlockTimestamp: getUnixTime(
          dateFromISO8601(openedAt).getTime(),
        ),
      })
    },
    [toTransaction],
  )

  const bulkFulfillOrders = useCallback(
    (dataKey: useHandleBlockchainActions_bulk_fulfill_orders$key) => {
      const { method, ordersToFill } = readInlineData(
        graphql`
          fragment useHandleBlockchainActions_bulk_fulfill_orders on BulkFulfillOrdersActionType
          @inline {
            method {
              ... on TransactionSubmissionDataType {
                ...useHandleBlockchainActions_transaction
              }
            }
            ordersToFill {
              orderData {
                openedAt
              }
            }
          }
        `,
        dataKey,
      )

      const latestOpenedAt = ordersToFill
        .map(({ orderData }) =>
          getUnixTime(dateFromISO8601(orderData.openedAt).getTime()),
        )
        .reduce((acc, cur) => Math.max(acc, cur), 0)

      return toTransaction(method, {
        transactAtBlockTimestamp: latestOpenedAt,
      })
    },
    [toTransaction],
  )

  const bulkAcceptOffers = useCallback(
    (dataKey: useHandleBlockchainActions_bulk_accept_offers$key) => {
      const { method, offersToAccept } = readInlineData(
        graphql`
          fragment useHandleBlockchainActions_bulk_accept_offers on BulkAcceptOffersActionType
          @inline {
            method {
              ... on TransactionSubmissionDataType {
                ...useHandleBlockchainActions_transaction
              }
            }
            offersToAccept {
              orderData {
                openedAt
              }
            }
          }
        `,
        dataKey,
      )

      const latestOpenedAt = offersToAccept
        .map(({ orderData }) =>
          getUnixTime(dateFromISO8601(orderData.openedAt).getTime()),
        )
        .reduce((acc, cur) => Math.max(acc, cur), 0)

      return toTransaction(method, {
        transactAtBlockTimestamp: latestOpenedAt,
      })
    },
    [toTransaction],
  )

  const mintAsset = useCallback(
    (dataKey: useHandleBlockchainActions_mint_asset$key) => {
      const { method, startTime } = readInlineData(
        graphql`
          fragment useHandleBlockchainActions_mint_asset on MintActionType
          @inline {
            method {
              ...useHandleBlockchainActions_transaction
            }
            startTime
          }
        `,
        dataKey,
      )

      const startsAt = getUnixTime(dateFromISO8601(startTime).getTime())

      return toTransaction(method, {
        transactAtBlockTimestamp: startsAt,
      })
    },
    [toTransaction],
  )

  const transferAsset = useCallback(
    (dataKey: useHandleBlockchainActions_transfer_asset$key) => {
      const { method } = readInlineData(
        graphql`
          fragment useHandleBlockchainActions_transfer_asset on AssetTransferActionType
          @inline {
            method {
              ...useHandleBlockchainActions_transaction
            }
          }
        `,
        dataKey,
      )

      return toTransaction(method)
    },
    [toTransaction],
  )

  const burnToRedeemAsset = useCallback(
    (dataKey: useHandleBlockchainActions_burnToRedeem$key) => {
      const { method } = readInlineData(
        graphql`
          fragment useHandleBlockchainActions_burnToRedeem on AssetBurnToRedeemActionType
          @inline {
            method {
              ...useHandleBlockchainActions_transaction
            }
          }
        `,
        dataKey,
      )

      return toTransaction(method)
    },
    [toTransaction],
  )

  const swapAsset = useCallback(
    (dataKey: useHandleBlockchainActions_swap_asset$key) => {
      const { method } = readInlineData(
        graphql`
          fragment useHandleBlockchainActions_swap_asset on AssetSwapActionType
          @inline {
            method {
              ...useHandleBlockchainActions_transaction
            }
          }
        `,
        dataKey,
      )

      return toTransaction(method)
    },
    [toTransaction],
  )

  const askForAssetSwap = useCallback(
    async ({
      amount,
      dataKey,
    }: {
      amount: string
      dataKey: useHandleBlockchainActions_ask_for_asset_swap$key
    }) => {
      const { fromAsset, toAsset } = readInlineData(
        graphql`
          fragment useHandleBlockchainActions_ask_for_asset_swap on AskForSwapType
          @inline {
            fromAsset {
              decimals
              relayId
            }
            toAsset {
              relayId
            }
          }
        `,
        dataKey,
      )

      const { actionsV2 } = await querySwapActions({
        amount,
        fromAssetDecimals: fromAsset.decimals ?? ETH_DECIMALS,
        fromAsset: fromAsset.relayId,
        toAsset: toAsset.relayId,
      })

      const swapAction = actionsV2[0]
      if (swapAction.__typename !== "AssetSwapActionType") {
        throw new Error("Got invalid swap action")
      }

      return swapAsset(swapAction)
    },
    [swapAsset],
  )

  const deployContract = useCallback(
    async (dataKey: useHandleBlockchainActions_deploy_contract$key) => {
      const { method } = readInlineData(
        graphql`
          fragment useHandleBlockchainActions_deploy_contract on DropContractDeployActionType
          @inline {
            method {
              ...useHandleBlockchainActions_transaction
            }
          }
        `,
        dataKey,
      )

      return toTransaction(method, { isContractDeploy: true })
    },
    [toTransaction],
  )

  const setCreatorFees = useCallback(
    async (dataKey: useHandleBlockchainActions_set_creator_fees$key) => {
      const { method } = readInlineData(
        graphql`
          fragment useHandleBlockchainActions_set_creator_fees on SetCreatorFeesActionType
          @inline {
            method {
              ...useHandleBlockchainActions_transaction
            }
          }
        `,
        dataKey,
      )
      return toTransaction(method)
    },
    [toTransaction],
  )

  const setTransferValidator = useCallback(
    async (dataKey: useHandleBlockchainActions_set_transfer_validator$key) => {
      const { method } = readInlineData(
        graphql`
          fragment useHandleBlockchainActions_set_transfer_validator on SetTransferValidatorActionType
          @inline {
            method {
              ...useHandleBlockchainActions_transaction
            }
          }
        `,
        dataKey,
      )
      return toTransaction(method)
    },
    [toTransaction],
  )

  const setRoyaltyInfo = useCallback(
    async (dataKey: useHandleBlockchainActions_set_royalty_info$key) => {
      const { method } = readInlineData(
        graphql`
          fragment useHandleBlockchainActions_set_royalty_info on SetRoyaltyInfoActionType
          @inline {
            method {
              ...useHandleBlockchainActions_transaction
            }
          }
        `,
        dataKey,
      )
      return toTransaction(method)
    },
    [toTransaction],
  )

  const updateDropMechanics = useCallback(
    async (dataKey: useHandleBlockchainActions_update_drop_mechanics$key) => {
      const { method } = readInlineData(
        graphql`
          fragment useHandleBlockchainActions_update_drop_mechanics on DropMechanicsUpdateActionType
          @inline {
            method {
              ...useHandleBlockchainActions_transaction
            }
          }
        `,
        dataKey,
      )
      return toTransaction(method)
    },
    [toTransaction],
  )

  const updateDropPreReveal = useCallback(
    async (dataKey: useHandleBlockchainActions_update_drop_pre_reveal$key) => {
      const { method } = readInlineData(
        graphql`
          fragment useHandleBlockchainActions_update_drop_pre_reveal on CollectionTokenMetadataUpdateActionType
          @inline {
            method {
              ...useHandleBlockchainActions_transaction
            }
          }
        `,
        dataKey,
      )
      return toTransaction(method)
    },
    [toTransaction],
  )

  const mintYourOwnCollection = useCallback(
    async (
      dataKey: useHandleBlockchainActions_mint_your_own_collection$key,
    ) => {
      const { method } = readInlineData(
        graphql`
          fragment useHandleBlockchainActions_mint_your_own_collection on MintYourOwnCollectionActionType
          @inline {
            method {
              ...useHandleBlockchainActions_transaction
            }
          }
        `,
        dataKey,
      )
      return toTransaction(method)
    },
    [toTransaction],
  )

  return {
    approveAsset,
    approvePaymentAsset,
    bulkFulfillOrders,
    bulkAcceptOffers,
    askForAssetSwap,
    cancelAllOrders,
    cancelOrders,
    cancelSwapOrders,
    createOrder,
    createBulkOrder,
    createSwapOrder,
    fulfillOrder,
    fulfillSwapOrder,
    swapAsset,
    transferAsset,
    mintAsset,
    deployContract,
    updateDropMechanics,
    setCreatorFees,
    setTransferValidator,
    setRoyaltyInfo,
    estimateGas,
    updateDropPreReveal,
    burnToRedeemAsset,
    mintYourOwnCollection,
  }
}
