import React from "react"
import { Flex, classNames, useIsLessThanSm } from "@opensea/ui-kit"
import { useToasts } from "@/hooks/useToasts"
import { Z_INDEX } from "../../constants/zIndex"
import { Toast, ToastT } from "./Toast.react"

type Props = {
  toasts: ToastT[]
}

export const ToastsContainer = ({ toasts }: Props) => {
  const { removeToast, toastYOffset, toastXOffset } = useToasts()
  const isLessThanSm = useIsLessThanSm()

  if (!toasts.length) {
    return null
  }

  return (
    <Flex
      className={classNames(
        "fixed w-full flex-col items-center",
        "sm:w-auto sm:items-end sm:px-6 sm:py-0",
        "lg:px-8 xxl:px-16",
      )}
      data-testid="toasts"
      style={{
        zIndex: Z_INDEX.TOASTS,
        bottom: isLessThanSm ? toastYOffset.mobile : toastYOffset.desktop,
        right: toastXOffset,
      }}
    >
      {toasts.map(toast => (
        <Toast
          key={toast.key}
          timeout={toast.timeout}
          toast={toast}
          onClose={() => removeToast(toast.key)}
        />
      ))}
    </Flex>
  )
}
