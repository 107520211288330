// Heights
export const NAV_HEIGHT_PX = 72
export const $nav_height = `${NAV_HEIGHT_PX}px`

const MINIMAL_NAV_HEIGHT_PX = 72
export const $minimal_nav_height = `${MINIMAL_NAV_HEIGHT_PX}px`

// 40px padding + 40px category links height + 15px offset
export const CATEGORY_BOTTOM_PADDING = 95

export const INPUT_HEIGHT = 48
