const PERCENTILE_BUCKETS = [50, 40, 30, 25, 20, 15, 10, 5, 1, 0.1]

export const getPercentileBucket = (percentile: number): number | undefined => {
  let resultBucket: number | undefined = undefined
  PERCENTILE_BUCKETS.forEach(bucket => {
    if (percentile <= bucket) {
      resultBucket = bucket
    }
  })
  return resultBucket
}
