import React, { useCallback, useState, useEffect } from "react"
import { merge } from "lodash"
import styled, { css } from "styled-components"
import { useIsOpen } from "@/hooks/useIsOpen"
import { ControlledModalProps, Modal } from "../Modal"

export type BottomSheetProps = Pick<
  ControlledModalProps,
  | "children"
  | "isOpen"
  | "onClose"
  | "closeOnOverlayClick"
  | "overrides"
  | "keepMounted"
> & { closable?: boolean }

const BottomSheetBase = ({
  children,
  isOpen: initiallyOpen,
  onClose,
  closable,
  closeOnOverlayClick,
  overrides,
  keepMounted,
}: BottomSheetProps) => {
  const { isOpen, close: closeModal, setIsOpen } = useIsOpen(initiallyOpen)
  const [isClosing, setIsClosing] = useState(false)

  useEffect(() => {
    setIsOpen(initiallyOpen)
    setIsClosing(false)
  }, [initiallyOpen, setIsOpen])

  useEffect(() => {
    if (!isOpen) {
      setIsClosing(true)
    }
  }, [isOpen])

  const onCloseAnimationEnd = useCallback(() => {
    if (isClosing) {
      setIsClosing(false)
      closeModal()
      onClose?.()
    }
  }, [closeModal, isClosing, onClose])

  const defaultOverrides: ControlledModalProps["overrides"] = {
    Dialog: {
      props: {
        $css: [bottomSheetStyles].concat(
          isClosing
            ? closingStyles
            : initiallyOpen && isOpen
            ? openingStyles
            : [],
        ),
        onAnimationEnd: isClosing ? onCloseAnimationEnd : undefined,
      },
    },
    Overlay: {
      props: {
        backgroundColor: undefined,
        transitionDuration: 0.2,
      },
    },
  }

  return (
    <Modal
      closable={closable}
      closeOnOverlayClick={closeOnOverlayClick}
      isOpen={isOpen}
      keepMounted={keepMounted}
      overrides={merge(defaultOverrides, overrides)}
      onClose={() => {
        setIsClosing(true)
      }}
    >
      {children}
    </Modal>
  )
}

const bottomSheetStyles = css`
  && {
    width: 100%;
    margin-top: 16px;
    border: 1px solid ${props => props.theme.colors.components.border.level2};
    border-top-left-radius: ${props => props.theme.borderRadius.modal};
    border-top-right-radius: ${props => props.theme.borderRadius.modal};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    max-height: calc(100vh - 16px);
    max-height: -webkit-fill-available;
    max-width: 100%;
    position: fixed;
    bottom: 0;

    @keyframes slideUp {
      0% {
        transform: translateY(100%);
      }
      100% {
        transform: translateY(0);
      }
    }

    @keyframes slideDown {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(100%);
      }
    }
  }
`

const openingStyles = css`
  && {
    transform: translateY(100%);
    animation: slideUp ease-in-out 0.2s forwards;
  }
`

const closingStyles = css`
  && {
    transform: translateY(100%);
    animation: slideDown ease-in-out 0.2s forwards;
  }
`

const Header = styled(props => <Modal.Header {...props} />)`
  border-bottom: 1px solid
    ${props => props.theme.colors.components.border.level2};
`
const Title = styled(props => <Modal.Title {...props} />)``
const Body = styled(props => <Modal.Body {...props} />)``
const Form = styled(props => <Modal.Form {...props} />)``
const Footer = styled(props => <Modal.Footer {...props} />)``

export const BottomSheet = Object.assign(BottomSheetBase, {
  Header,
  Title,
  Body,
  Footer,
  Form,
})
