import React, { Suspense } from "react"
import { ErrorBoundary } from "@sentry/nextjs"
import { graphql, useLazyLoadQuery } from "react-relay"
import { BlockchainActionList } from "@/components/blockchain/BlockchainActionList"
import { BlockchainActionModalContent } from "@/components/blockchain/BlockchainActionList/BlockchainActionModalContent.react"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { TotalPricePerSymbol } from "@/features/shopping-cart/hooks/useTotalPrice"
import { CoreMarketplaceActionTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/CoreMarketplaceActionContext.react"
import { CoreMarketplaceActionErrorFallback } from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/CoreMarketplaceActionErrorFallback.react"
import { useTrackServerError } from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/hooks"
import {
  BulkPurchaseActionModalQuery,
  BulkPurchaseActionModalQuery$variables,
  BulkPurchaseFillType,
} from "@/lib/graphql/__generated__/BulkPurchaseActionModalQuery.graphql"
import { BulkPurchaseProcessedModal } from "../BulkPurchaseProcessedModal"

export type BulkPurchaseActionModalProps =
  BulkPurchaseActionModalQuery$variables & {
    totalPricePerSymbol: TotalPricePerSymbol
    onClose: () => unknown
    onEnd?: () => unknown
    fillType: BulkPurchaseFillType
  }

const BulkPurchaseActionModalBase = ({
  onClose,
  ordersToFill,
  maxOrdersToFill,
  giftRecipientAddress,
  totalPricePerSymbol,
  fillType,
  onEnd,
}: BulkPurchaseActionModalProps) => {
  const {
    blockchain: {
      bulkPurchase: { actions },
    },
  } = useLazyLoadQuery<BulkPurchaseActionModalQuery>(
    graphql`
      query BulkPurchaseActionModalQuery(
        $ordersToFill: [OrderToFillInputType!]!
        $maxOrdersToFill: BigIntScalar
        $giftRecipientAddress: AddressScalar
        $fillType: BulkPurchaseFillType
      ) {
        blockchain {
          bulkPurchase(
            ordersToFill: $ordersToFill
            maxOrdersToFill: $maxOrdersToFill
            giftRecipientAddress: $giftRecipientAddress
            fillType: $fillType
          ) {
            actions {
              ...BlockchainActionList_data
            }
          }
        }
      }
    `,
    {
      ordersToFill,
      maxOrdersToFill,
      giftRecipientAddress: giftRecipientAddress || undefined,
      fillType,
    },
    { fetchPolicy: "network-only" },
  )

  const { onReplace } = useMultiStepFlowContext()

  return (
    <BlockchainActionList
      dataKey={actions}
      onEnd={({ transactions } = {}) => {
        transactions &&
          onReplace(
            <BulkPurchaseProcessedModal
              fillType={fillType}
              giftRecipientAddress={giftRecipientAddress || undefined}
              maxOrdersToFill={
                maxOrdersToFill ? Number(maxOrdersToFill) : undefined
              }
              ordersToFill={ordersToFill}
              totalPricePerSymbol={totalPricePerSymbol}
              transactionHashes={transactions.map(t => t.transactionHash)}
              onClose={onClose}
            />,
          )
        onEnd?.()
      }}
    />
  )
}

export const BulkPurchaseActionModalWithSuspense = (
  props: BulkPurchaseActionModalProps,
) => {
  const [trackServerError, errorTags] = useTrackServerError()

  return (
    <ErrorBoundary
      fallback={props => {
        return (
          <CoreMarketplaceActionErrorFallback
            errorTags={errorTags}
            trackServerError={trackServerError}
            {...props}
          />
        )
      }}
    >
      <Suspense fallback={<BlockchainActionModalContent.Skeleton />}>
        <BulkPurchaseActionModalBase {...props} />
      </Suspense>
    </ErrorBoundary>
  )
}

export const BulkPurchaseActionModal = (
  props: BulkPurchaseActionModalProps,
) => {
  return (
    <CoreMarketplaceActionTrackingContextProvider
      action={
        props.fillType === "BUY_NOW"
          ? "BuyNow"
          : props.fillType === "CART"
          ? "CheckoutCart"
          : "Sweep"
      }
    >
      <BulkPurchaseActionModalWithSuspense {...props} />
    </CoreMarketplaceActionTrackingContextProvider>
  )
}
