/**
 * @generated SignedSource<<c3bf4e011c5aa024d3492ec750367f29>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useItemFees_item$data = {
  readonly __typename: "AssetBundleType";
  readonly bundleCollection: {
    readonly isCreatorFeesEnforced: boolean;
    readonly openseaSellerFeeBasisPoints: number;
    readonly totalCreatorFeeBasisPoints: number;
  } | null;
  readonly " $fragmentType": "useItemFees_item";
} | {
  readonly __typename: "AssetType";
  readonly collection: {
    readonly isCreatorFeesEnforced: boolean;
    readonly openseaSellerFeeBasisPoints: number;
  };
  readonly totalCreatorFee: number | null;
  readonly " $fragmentType": "useItemFees_item";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "useItemFees_item";
};
export type useItemFees_item$key = {
  readonly " $data"?: useItemFees_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"useItemFees_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "openseaSellerFeeBasisPoints",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCreatorFeesEnforced",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "chain"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useItemFees_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCreatorFee",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionType",
          "kind": "LinkedField",
          "name": "collection",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "AssetType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "bundleCollection",
          "args": null,
          "concreteType": "CollectionType",
          "kind": "LinkedField",
          "name": "collection",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "chain",
                  "variableName": "chain"
                }
              ],
              "kind": "ScalarField",
              "name": "totalCreatorFeeBasisPoints",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "AssetBundleType",
      "abstractKey": null
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};
})();

(node as any).hash = "65b7554695bf5fe37252be2d548a0686";

export default node;
