import { EditTab } from "@/features/collections/components/CollectionCreateOrUpdatePage/constants"
import { DateRange } from "@/features/orders/hooks/useDateRangeOptions"
import { PageTab } from "@/features/primary-drops/constants"
import { PrimaryDropsCalendarPageTab } from "@/features/primary-drops/pages/CalendarPage/PrimaryDropsCalendarPage.react"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { Search } from "@/hooks/useSearch"
import {
  IdentityInputType,
  PriceFilterSymbol,
  PriceFilterType,
  RangeType,
  SearchResultModel,
  SearchSortBy,
  SearchToggle,
  TraitInputType,
  TraitRangeType,
} from "@/lib/graphql/__generated__/AccountCollectedAssetSearchListQuery.graphql"
import {
  RarityFilterField,
  RarityFilterType,
} from "@/lib/graphql/__generated__/CollectionAssetSearchListQuery.graphql"
import type { EventType } from "@/lib/graphql/__generated__/ItemType.graphql"
import { isValidAddress, isValidSolanaAddress } from "@/lib/helpers/address"
import { dateFromISO8601 } from "@/lib/helpers/datetime"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"
import { reverse } from "@/lib/helpers/object"
import { CategorySlug, IsIpRightsTakedownDelistedMode } from "../../constants"
import { CHAIN_IDENTIFIER_ENUM_MAPPING } from "../../constants/chains"
import {
  OfferTypeToggle,
  OrderStatusToggle,
} from "../graphql/__generated__/AccountOffersOrderSearchListPaginationQuery.graphql"
import { makeParam, Param } from "./lib"
import S from "./standard"

type Enum<T extends string> = Exclude<T, "%future added value">

const makeEnumParam = <T extends string>(
  mapping: Record<Enum<T>, string | undefined>,
): Param<Enum<T>> => {
  const reverseMapping = reverse(mapping)

  return makeParam(arg =>
    typeof arg === "string"
      ? reverseMapping[arg] ?? (arg in mapping ? (arg as Enum<T>) : undefined)
      : undefined,
  )
}

const addressParam = makeParam<string>(arg =>
  typeof arg === "string" && isValidAddress(arg) ? arg : undefined,
)

const solanaAddressParam = makeParam<string>(arg =>
  typeof arg === "string" && isValidSolanaAddress(arg) ? arg : undefined,
)

const bigNumberParam = makeParam<BigNumber>(arg => {
  if (typeof arg === "string") {
    const number = bn(arg)
    if (!number.isNaN()) {
      return number
    }
  }
  return undefined
})

const isIpRightsTakedownDelistedParam =
  makeEnumParam<IsIpRightsTakedownDelistedMode>({
    item: undefined,
    "item-owner": undefined,
    collection: undefined,
    "collection-owner": undefined,
  })

const categorySlugParam = makeEnumParam<CategorySlug>({
  art: undefined,
  gaming: undefined,
  memberships: undefined,
  pfps: undefined,
  photography: undefined,
  music: undefined,
  "domain-names": undefined,
  "sports-collectibles": undefined,
  "virtual-worlds": undefined,
})

const chainIdentifierParam = makeEnumParam<ChainIdentifier>(
  CHAIN_IDENTIFIER_ENUM_MAPPING,
)

const dateTimeParam = makeParam<string>(arg => {
  if (typeof arg === "string") {
    const date = dateFromISO8601(arg)
    if (!isNaN(date.getTime())) {
      return arg
    }
  }
  return undefined
})

const dateParam = makeParam<Date>(arg => {
  if (typeof arg === "string") {
    const date = dateFromISO8601(arg)
    if (!isNaN(date.getTime())) {
      return date
    }
  }
  return undefined
})

const eventParam = makeEnumParam<EventType>({
  ASSET_APPROVE: undefined,
  ASSET_TRANSFER: undefined,
  AUCTION_CANCELLED: undefined,
  AUCTION_CREATED: undefined,
  AUCTION_SUCCESSFUL: undefined,
  BID_ENTERED: undefined,
  BID_WITHDRAWN: undefined,
  BULK_CANCEL: undefined,
  COLLECTION_OFFER: undefined,
  CUSTOM: undefined,
  OFFER_ENTERED: undefined,
  PAYOUT: undefined,
  TRAIT_OFFER: undefined,
  SWAP_ACCEPTED: undefined,
  SWAP_CREATED: undefined,
  SWAP_CANCELLED: undefined,
  REDEMPTION: undefined,
})

const identityParam = S.Object<IdentityInputType>({
  address: S.Optional(addressParam),
  name: S.Optional(S.string),
  username: S.Optional(S.string),
})

const rangeParam = S.Object<RangeType>({ max: S.string, min: S.string })

const dateRangeParam = S.Object<DateRange>({
  start: dateParam,
  end: dateParam,
})

const searchResultModelParam = makeEnumParam<SearchResultModel>({
  ASSETS: undefined,
  BUNDLES: undefined,
})

const searchSortByParam = makeEnumParam<SearchSortBy>({
  BIRTH_DATE: undefined,
  CREATED_DATE: undefined,
  EXPIRATION_DATE: undefined,
  LAST_SALE_DATE: undefined,
  LAST_SALE_PRICE: undefined,
  LAST_TRANSFER_DATE: undefined,
  LISTING_DATE: undefined,
  PRICE: undefined,
  UNIT_PRICE: undefined,
  VIEWER_COUNT: undefined,
  FAVORITE_COUNT: undefined,
  RARITY_RANK: undefined,
  STAFF_SORT_1: undefined,
  STAFF_SORT_2: undefined,
  STAFF_SORT_3: undefined,
  ESTIMATED_VALUE: undefined,
  FLOOR_PRICE: undefined,
  BEST_BID: undefined,
})

const searchToggleParam = makeEnumParam<SearchToggle>({
  BUY_NOW: undefined,
  HAS_OFFERS: undefined,
  IS_NEW: undefined,
  ON_AUCTION: undefined,
  IS_AVAILABLE_FOR_MOONPAY_FIAT_CHECKOUT: undefined,
  IS_LISTED: undefined,
})

const searchOrderStatusToggleParam = makeEnumParam<OrderStatusToggle>({
  ACTIVE: undefined,
  EXPIRING: undefined,
  INACTIVE: undefined,
})

const searchOfferTypeToggleParam = makeEnumParam<OfferTypeToggle>({
  COLLECTION: undefined,
  SINGLE: undefined,
  TRAIT: undefined,
})

const traitInputParam = S.Object<TraitInputType>({
  name: S.string,
  values: S.Array(S.string),
})

const traitRangeParam = S.Object<TraitRangeType>({
  name: S.string,
  ranges: S.Array(rangeParam),
})

const priceFilterSymbolParam = makeEnumParam<PriceFilterSymbol>({
  ETH: undefined,
  USD: undefined,
  SOL: undefined,
})

const primaryDropPageTabParam = makeEnumParam<PageTab>({
  mint: undefined,
  roadmap: undefined,
  team: undefined,
  faq: undefined,
})

const primaryDropCalendarPageTabParam =
  makeEnumParam<PrimaryDropsCalendarPageTab>({
    upcoming: undefined,
    past: undefined,
  })

const priceParam = S.Object<PriceFilterType>({
  symbol: priceFilterSymbolParam,
  max: S.Optional(S.string),
  min: S.Optional(S.string),
})

const rarityFilterFieldParam = makeEnumParam<RarityFilterField>({
  RANK: undefined,
  RANK_PERCENTILE: undefined,
})

const rarityParam = S.Object<RarityFilterType>({
  field: rarityFilterFieldParam,
  max: S.Optional(S.string),
  min: S.Optional(S.string),
})

const collectionEditTab = makeEnumParam<EditTab>({
  details: undefined,
  links: undefined,
  earnings: undefined,
  graphics: undefined,
  mintSchedule: undefined,
  dropEditor: undefined,
  dropEarnings: undefined,
  preReveal: undefined,
  upload: undefined,
  preview: undefined,
  redeemables: undefined,
  editor: undefined,
  itemPicker: undefined,
})

const searchParam = S.Object<Search>({
  categories: S.Optional(S.Array(S.string)),
  chains: S.Optional(S.Array(chainIdentifierParam)),
  collection: S.Optional(S.string),
  collections: S.Optional(S.Array(S.string)),
  eventTypes: S.Optional(S.Array(eventParam)),
  identity: S.Optional(identityParam),
  numericTraits: S.Optional(S.Array(traitRangeParam)),
  paymentAssets: S.Optional(S.Array(S.string)),
  priceFilter: S.Optional(priceParam),
  rarityFilter: S.Optional(rarityParam),
  query: S.Optional(S.string),
  resultModel: S.Optional(searchResultModelParam),
  sortAscending: S.Optional(S.boolean),
  sortBy: S.Optional(searchSortByParam),
  stringTraits: S.Optional(S.Array(traitInputParam)),
  toggles: S.Optional(S.Array(searchToggleParam)),
  orderStatusToggles: S.Optional(S.Array(searchOrderStatusToggleParam)),
  offerTypeToggles: S.Optional(S.Array(searchOfferTypeToggleParam)),
  isAutoHidden: S.Optional(S.boolean),
  filterOutListingsWithoutRequestedCreatorFees: S.Optional(S.boolean),
  owner: S.Optional(identityParam),
})

const customParams = {
  Address: addressParam,
  SolanaAddress: solanaAddressParam,
  BigNumber: bigNumberParam,
  CategorySlug: categorySlugParam,
  ChainIdentifier: chainIdentifierParam,
  DateTime: dateTimeParam,
  DateRange: dateRangeParam,
  Enum: makeEnumParam,
  Event: eventParam,
  Identity: identityParam,
  Range: rangeParam,
  Search: searchParam,
  SearchResultModel: searchResultModelParam,
  SearchSortBy: searchSortByParam,
  SearchToggle: searchToggleParam,
  TraitInput: traitInputParam,
  TraitRange: traitRangeParam,
  makeEnumParam,
  isIpRightsTakedownDelisted: isIpRightsTakedownDelistedParam,
  PrimaryDropTab: primaryDropPageTabParam,
  PrimaryDropCalendarTab: primaryDropCalendarPageTabParam,
  CollectionEditTab: collectionEditTab,
} as const

export default customParams
