import React from "react"
import Head from "next/head"
import { FRAME_SITE_URL_STRING, SITE_URL_STRING } from "@/context/location"
import { useDefaultDesc, useDefaultTitle } from "@/hooks/useCopy"
import { useIsFarcasterMintFrameEnabled } from "@/hooks/useFlag"
import { useRouter } from "@/hooks/useRouter"
import { DEFAULT_IMG, TOKEN_STANDARD_TO_NAMES } from "../../../../constants"
import {
  PageLinkedDataJson,
  useLinkedDataJsonScriptElement,
} from "./useLinkedDataJson"

export type TwitterMetadata = {
  title: string
  image: string
  imageAlt: string
  author: string
  url: string
}

type FarcasterMetadata = {
  collection: string
  mintCount: number
  creatorAddress: string
  contractAddress: string
  schema: keyof typeof TOKEN_STANDARD_TO_NAMES
}

type FarcasterFrameMetadata = {
  collectionSlug: string
  collectionName: string
  hasAmount?: boolean
}

type Props = {
  title?: string
  description?: string
  image?: string
  url?: string
  twitterMetadata?: TwitterMetadata
  linkedDataSchema?: PageLinkedDataJson
  farcasterMetadata?: FarcasterMetadata
  farcasterFrameMetadata?: FarcasterFrameMetadata
}

export const OpenSeaHead = ({
  title,
  description,
  image = DEFAULT_IMG,
  url,
  twitterMetadata,
  linkedDataSchema,
  farcasterMetadata,
  farcasterFrameMetadata,
}: Props) => {
  const { origin, asPath, href, locale } = useRouter()
  const canonicalUrl =
    url ||
    `${origin}${locale !== "en-US" ? "/" + locale : ""}${
      asPath.split(/[?#]/)[0]
    }`
  const canonicalUrlNoTrailingSlash = canonicalUrl.replace(/\/+$/, "")
  const defaultTitle = useDefaultTitle()
  const defaultDesc = useDefaultDesc()
  const updatedTwitterCTAUrl = `${twitterMetadata?.url}?utm_medium=organic&utm_source=twitter&utm_campaign=enh-card`

  const ldSchemaElement = useLinkedDataJsonScriptElement(linkedDataSchema)

  const farcasterMintFrameEnabled = useIsFarcasterMintFrameEnabled(
    farcasterFrameMetadata?.collectionSlug,
  )

  return (
    <Head>
      <title key="title">{title || defaultTitle}</title>
      {/* Open Graph tags; https://ogp.me/ */}
      {/* Basic metadata; https://ogp.me/#metadata */}
      <meta
        content={title || defaultTitle}
        key="og:title"
        property="og:title"
      />
      <meta content="website" key="og:type" property="og:type" />
      <meta content={image} key="og:image" property="og:image" />
      <meta content={href} key="og:url" property="og:url" />
      {/* Optional metadata; https://ogp.me/#optional */}
      <meta
        content={description || defaultDesc}
        key="og:description"
        property="og:description"
      />
      <meta content="en_US" key="og:locale" property="og:locale" />
      <meta content="OpenSea" key="og:site_name" property="og:site_name" />
      {twitterMetadata && (
        <>
          <meta content={twitterMetadata.title} name="twitter:title" />
          <meta
            content={`Created by ${twitterMetadata.author}`}
            name="twitter:author"
          />
          <meta content={twitterMetadata.image} name="twitter:image" />
          <meta content={twitterMetadata.imageAlt} name="twitter:image:alt" />
          <meta content={updatedTwitterCTAUrl} name="twitter:url" />
          <meta content="View on OpenSea" name="twitter:cta" />
        </>
      )}
      {farcasterMetadata && (
        <>
          <meta
            content={farcasterMetadata.collection}
            property="eth:nft:collection"
          />
          <meta
            content={farcasterMetadata.mintCount.toString()}
            property="eth:nft:mint_count"
          />
          <meta
            content={farcasterMetadata.creatorAddress}
            property="eth:nft:creator_address"
          />
          <meta
            content={farcasterMetadata.contractAddress}
            property="eth:nft:contract_address"
          />
          <meta content={farcasterMetadata.schema} property="eth:nft:schema" />
        </>
      )}
      {farcasterMintFrameEnabled && farcasterFrameMetadata?.collectionSlug && (
        <>
          <meta content="vNext" name="fc:frame" />
          <meta content={image} name="fc:frame:image" />
          {farcasterFrameMetadata.hasAmount && (
            <meta
              content="Enter amount to mint"
              property="fc:frame:input:text"
            />
          )}
          <meta content="Mint" name="fc:frame:button:1" />
          <meta content="tx" property="fc:frame:button:1:action" />
          <meta
            content={`${FRAME_SITE_URL_STRING}/api/opensea-api/get_seadrop_mint_fulfillment_data?collectionSlug=${farcasterFrameMetadata.collectionSlug}`}
            property="fc:frame:button:1:target"
          />
          {/* TODO we'll need to account for max mints per wallet, max mints at once */}
          <meta
            content={`${FRAME_SITE_URL_STRING}/api/mint_seadrop/callback?slug=${farcasterFrameMetadata.collectionSlug}&collectionName=${farcasterFrameMetadata.collectionName}`}
            property="fc:frame:button:1:post_url"
          />
          <meta content="View on OpenSea" name="fc:frame:button:2" />
          <meta content="link" name="fc:frame:button:2:action" />
          <meta
            content={`${SITE_URL_STRING}/collection/${farcasterFrameMetadata.collectionSlug}/overview`}
            name="fc:frame:button:2:target"
          />
        </>
      )}
      {/* For canonical link hrefs in SEO */}
      <link
        href={canonicalUrlNoTrailingSlash}
        key="canonical"
        rel="canonical"
      />
      {/* For Google. Have to separate because Discord gets confused */}
      <meta
        content={description || defaultDesc}
        key="description"
        name="description"
      />
      {/* LD JSON Schema markup is used by Google to help better understand what a website */}
      {ldSchemaElement}
    </Head>
  )
}
