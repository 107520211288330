/**
 * @generated SignedSource<<71532f4200d9e2987a4eac5027d44fb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type collection_poll_stats$data = {
  readonly collectionOffers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly perUnitPriceType: {
          readonly symbol: string;
          readonly unit: string;
        };
      } | null;
    } | null>;
  };
  readonly statsV2: {
    readonly totalListed: number;
  };
  readonly " $fragmentType": "collection_poll_stats";
};
export type collection_poll_stats$key = {
  readonly " $data"?: collection_poll_stats$data;
  readonly " $fragmentSpreads": FragmentRefs<"collection_poll_stats">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "collection_poll_stats"
};

(node as any).hash = "7213ccf1818b881a1f694b6ffc741086";

export default node;
