import { useMemo } from "react"
import { graphql, useFragment } from "react-relay"
import { getTokenPricePayment } from "@/components/assets/price/TokenPrice.react"
import { OrderToQuantity } from "@/components/trade/BulkPurchaseModal"
import { useTotalPrice_errors$key } from "@/lib/graphql/__generated__/useTotalPrice_errors.graphql"
import { useTotalPrice_orders$key } from "@/lib/graphql/__generated__/useTotalPrice_orders.graphql"
import { BigNumber, bn, displayFiat } from "@/lib/helpers/numberUtils"

type PricesPerSymbol = Record<
  string,
  | {
      prices: BigNumber[]
      usdPrices: BigNumber[]
      tokenPricePayment: ReturnType<typeof getTokenPricePayment>
    }
  | undefined
>

export type TotalPricePerSymbol = Record<
  string,
  | {
      price: BigNumber
      usdPrice: BigNumber
      tokenPricePayment: ReturnType<typeof getTokenPricePayment>
    }
  | undefined
>

export const useTotalPrice = ({
  orders: ordersDataKey,
  errors: errorsDataKey,
  orderToQuantity,
  maxOrdersToFill,
  shouldSort = true,
}: {
  orders: useTotalPrice_orders$key | null
  errors?: useTotalPrice_errors$key | null
  orderToQuantity: OrderToQuantity
  maxOrdersToFill?: number
  shouldSort?: boolean
}) => {
  const orders = useFragment(
    graphql`
      fragment useTotalPrice_orders on OrderV2Type @relay(plural: true) {
        relayId
        perUnitPriceType {
          usd
          unit
        }
        payment {
          symbol
          ...TokenPricePayment
        }
      }
    `,
    ordersDataKey,
  )

  const errors = useFragment(
    graphql`
      fragment useTotalPrice_errors on BulkPurchaseErrorType
      @relay(plural: true) {
        reason
        originalOrder {
          relayId
        }
        updatedOrder {
          relayId
          perUnitPriceType {
            usd
            unit
          }
          payment {
            symbol
            ...TokenPricePayment
          }
        }
      }
    `,
    errorsDataKey ?? [],
  )

  const initialPricesPerSymbol: PricesPerSymbol = useMemo(() => ({}), [])

  const pricesPerSymbol = useMemo(
    () =>
      orders?.reduce((acc, o) => {
        const errorForOrder = errors.find(
          e => e.originalOrder.relayId === o.relayId,
        )

        // If order is not available, skip this order
        if (errorForOrder?.reason === "ORDER_UNAVAILABLE") {
          return acc
        }

        // If order is available, but has been updated, use the updated order
        const order = errorForOrder?.updatedOrder || o

        const { perUnitPriceType, payment } = order

        const basePrice = bn(perUnitPriceType.unit)
        const fiatBasePrice = bn(perUnitPriceType.usd)

        const quantity =
          orderToQuantity[o.relayId] ||
          (errorForOrder?.updatedOrder
            ? orderToQuantity[errorForOrder.updatedOrder.relayId]
            : 0) ||
          0

        const currentPrice = bn(basePrice)

        const fiatCurrentPrice = bn(fiatBasePrice)

        return {
          ...acc,
          [payment.symbol]: {
            prices: (acc[payment.symbol]?.prices || []).concat(
              maxOrdersToFill !== undefined
                ? Array(quantity).fill(currentPrice) // Spread out multi-quantity orders if maxOrdersToFill is supplied
                : currentPrice.multipliedBy(quantity),
            ),

            usdPrices: (acc[payment.symbol]?.usdPrices || []).concat(
              maxOrdersToFill !== undefined
                ? Array(quantity).fill(fiatCurrentPrice) // Spread out multi-quantity orders if maxOrdersToFill is supplied
                : fiatCurrentPrice.multipliedBy(quantity),
            ),

            tokenPricePayment: getTokenPricePayment(payment),
          },
        }
      }, initialPricesPerSymbol) ?? initialPricesPerSymbol,
    // We add an extra rerender key so we can update this value even if it's memo'd.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errors, orderToQuantity, orders],
  )

  const initialTotals: {
    totalPricePerSymbol: TotalPricePerSymbol
    totalUsdPrice: BigNumber
  } = useMemo(
    () => ({
      totalPricePerSymbol: {},
      totalUsdPrice: bn(0),
    }),
    [],
  )

  // When we supply a maxOrdersToFill, we want to supply the maximum possible price, otherwise the
  // transaction has a chance of failing outright.
  // We do this by first aggregating all of the prices per symbol
  // Then we sort them in descending order and take the top N (maxOrdersToFill)
  const totalPrices = useMemo(
    () =>
      Object.entries(pricesPerSymbol).reduce((acc, [symbol, prices]) => {
        if (!prices) {
          return acc
        }

        const { prices: unitPrices, usdPrices, tokenPricePayment } = prices

        const [totalPrice, totalUsdPrice] = [unitPrices, usdPrices].map(p => {
          if (shouldSort) {
            p.sort((a, b) => a.comparedTo(b))
          }
          return p
            .reverse()
            .slice(0, maxOrdersToFill)
            .reduce((a, b) => a.plus(b), bn(0))
        })

        return {
          totalPricePerSymbol: {
            ...acc.totalPricePerSymbol,
            [symbol]: {
              price: totalPrice,
              usdPrice: totalUsdPrice,
              tokenPricePayment,
            },
          },
          totalUsdPrice: acc.totalUsdPrice.plus(totalUsdPrice),
        }
      }, initialTotals),
    [initialTotals, maxOrdersToFill, pricesPerSymbol, shouldSort],
  )

  return {
    totalPricePerSymbol: totalPrices.totalPricePerSymbol,
    totalUsdPrice: displayFiat(totalPrices.totalUsdPrice),
  }
}
