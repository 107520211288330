/**
 * @generated SignedSource<<b3625de068d4b07ff278bb5d6b3a0e92>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderSide = "ASK" | "BID" | "%future added value";
export type OrderTypeEnum = "BASIC" | "CRITERIA" | "DUTCH" | "ENGLISH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CancelOrderActionOnChainContent_action$data = {
  readonly ordersData: ReadonlyArray<{
    readonly orderType: OrderTypeEnum;
    readonly side: OrderSide;
    readonly " $fragmentSpreads": FragmentRefs<"OrderDataHeader_order" | "OrdersHeaderData_orders">;
  }>;
  readonly " $fragmentType": "CancelOrderActionOnChainContent_action";
};
export type CancelOrderActionOnChainContent_action$key = {
  readonly " $data"?: CancelOrderActionOnChainContent_action$data;
  readonly " $fragmentSpreads": FragmentRefs<"CancelOrderActionOnChainContent_action">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CancelOrderActionOnChainContent_action",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderDataType",
      "kind": "LinkedField",
      "name": "ordersData",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "side",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "orderType",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OrderDataHeader_order"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OrdersHeaderData_orders"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CancelOrderActionType",
  "abstractKey": null
};

(node as any).hash = "4e76e8ec660d17fd78a6623922cab7cd";

export default node;
