import React, { ComponentProps, Suspense } from "react"
import { CenterAligned } from "@opensea/ui-kit"
import dynamic from "next/dynamic"
import { graphql, useLazyLoadQuery } from "react-relay"
import Loading from "@/components/common/Loading.react"
import { ModalLoader } from "@/components/common/ModalLoader.react"
import { UniswapWidget as UniswapWidgetType } from "@/components/trade/AddFundsModal/components/UniswapWidget.react"
import { Modal, ModalProps } from "@/design-system/Modal"
import { useTranslate } from "@/hooks/useTranslate"
import {
  SwapModalQuery,
  SwapModalQuery$variables,
} from "@/lib/graphql/__generated__/SwapModalQuery.graphql"
import { bn, NumberInput } from "@/lib/helpers/numberUtils"

const UniswapWidget = dynamic<ComponentProps<typeof UniswapWidgetType>>(
  () =>
    import(
      "@/components/trade/AddFundsModal/components/UniswapWidget.react"
    ).then(mod => mod.UniswapWidget),
  {
    loading: () => (
      <CenterAligned className="h-[356px]">
        <Loading />
      </CenterAligned>
    ),
    ssr: false,
  },
)

type SwapModalContentProps = {
  tokenAddress?: string
  quantity?: NumberInput
}

const SwapModalContent = ({
  tokenAddress,
  quantity,
}: SwapModalContentProps) => {
  const t = useTranslate("components")

  return (
    <>
      <Modal.Header>
        <Modal.Title>{t("swapTokens", "Swap tokens")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UniswapWidget
          requiredAssetAmount={quantity ? bn(quantity) : undefined}
          tokenAddress={tokenAddress}
        />
      </Modal.Body>
    </>
  )
}

type UniswapModalWithToCurrencyContentProps = {
  toCurrency: SwapModalQuery$variables
}

const LazyUniswapModalWithToCurrencyContent = ({
  toCurrency,
}: UniswapModalWithToCurrencyContentProps) => {
  const { paymentAsset } = useLazyLoadQuery<SwapModalQuery>(
    graphql`
      query SwapModalQuery($symbol: String!, $chain: ChainScalar!) {
        paymentAsset(symbol: $symbol, chain: $chain) {
          address
        }
      }
    `,
    toCurrency,
  )

  return <SwapModalContent tokenAddress={paymentAsset.address} />
}

type UniswapModalProps = ModalProps & {
  toCurrency?: SwapModalQuery$variables
}

export const UniswapModal = ({
  toCurrency,
  ...modalProps
}: UniswapModalProps) => {
  const t = useTranslate("components")
  return (
    <Modal {...modalProps}>
      {toCurrency ? (
        <Suspense fallback={<ModalLoader />}>
          <LazyUniswapModalWithToCurrencyContent
            toCurrency={toCurrency}
            {...modalProps}
          />
        </Suspense>
      ) : (
        <>
          <Modal.Header>
            <Modal.Header.Title>
              {t("swapModal.title", "Convert tokens")}
            </Modal.Header.Title>
          </Modal.Header>
          <SwapModalContent />
        </>
      )}
    </Modal>
  )
}
