import React from "react"
import type { MaterialSymbolProps } from "./types"

export const Menu = ({ size = 24, ...rest }: MaterialSymbolProps) => {
  return (
    <svg
      fill="currentColor"
      height={size}
      role="img"
      viewBox="0 -960 960 960"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <title>Menu</title>
      <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
    </svg>
  )
}
