import React from "react"
import { Text, VerticalAligned } from "@opensea/ui-kit"
import styled from "styled-components"
import { Block, BlockProps } from "@/design-system/Block"
import { bn, NumberInput } from "@/lib/helpers/numberUtils"
import { themeVariant } from "@/styles/styleUtils"

type Props = {
  children?: React.ReactNode
  quantity: NumberInput
  overrides?: {
    QuantityBadge: { props: Partial<BlockProps> }
  }
}

export const ItemQuantityBadge = ({ children, quantity, overrides }: Props) => {
  return (
    <VerticalAligned className="relative">
      {children}
      {bn(quantity).gt(1) && (
        <QuantityBadge
          className="right-0 top-0"
          {...overrides?.QuantityBadge.props}
        >
          <Text
            className="text-white"
            size="tiny"
            title="Quantity"
            weight="semibold"
          >
            {quantity.toString()}
          </Text>
        </QuantityBadge>
      )}
    </VerticalAligned>
  )
}

const QuantityBadge = styled(Block)`
  height: 22px;
  width: 22px;
  border-radius: ${props => props.theme.borderRadius.circle};
  position: absolute;
  color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border: 1px solid ${props => props.theme.colors.components.border.level2};

  ${props =>
    themeVariant({
      variants: {
        dark: {
          background: props.theme.colors.ash,
        },
        light: {
          background: props.theme.colors.oil,
        },
      },
    })}
`
