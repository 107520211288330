import React from "react"
import { useDraggable } from "@dnd-kit/core"
import { CSS } from "@dnd-kit/utilities"
import { classNames } from "@opensea/ui-kit"
import styled from "styled-components"
import { Z_INDEX } from "@/constants/zIndex"
import { Block } from "@/design-system/Block"

const StyledSwipeHandle = styled(Block)`
  touch-action: none;
`

type SwipeHandleProps = ReturnType<typeof useDraggable> & {
  className?: string
}

export const SwipeHandle = ({
  attributes,
  listeners,
  setNodeRef,
  transform,
  className,
}: SwipeHandleProps) => {
  const style = {
    transform: CSS.Translate.toString({
      x: transform?.x ? Math.min(transform.x, 0) : 0,
      y: 0,
      scaleX: 0,
      scaleY: 0,
    }),
  }

  return (
    <StyledSwipeHandle
      className={classNames("fixed inset-y-0 right-0", className)}
      ref={setNodeRef}
      style={{ zIndex: Z_INDEX.DRAWER_MOBILE + 1, ...style }}
      title="Swipe handle"
      width="16px"
      {...listeners}
      {...attributes}
      tabIndex={-1}
    />
  )
}
