import React, { useMemo } from "react"
import { TrackingContextProvider } from "../../TrackingContext.react"

type CoreMarketplaceAction =
  | "IndividualOffer"
  | "CollectionOffer"
  | "TraitOffer"
  | "OfferAccept"
  | "BulkOfferAccept"
  | "IndividualListing"
  | "EditListing"
  | "BulkListing"
  | "BuyNow"
  | "CheckoutCart"
  | "Sweep"
  | "IndividualDeal"
  | "DealAccept"
  | "BulkDealCancel"
  | "BurnToRedeem"

type CoreMarketplaceActionContext = {
  coreMarketplaceAction: CoreMarketplaceAction
}

export type CoreMarketplaceActionTrackingContext = {
  CoreMarketplaceAction: CoreMarketplaceActionContext | undefined
}

type Props = {
  action: CoreMarketplaceAction
  children: React.ReactNode
}

/**
 * Adds general tracking on core action attempts as well as errors that may occur from that attempt
 * https://www.notion.so/opensea/Project-It-Just-Works-ca325483f5f94aa988ff378159441c28?pvs=4#f906ca3766534f26ad50a45594348ab3
 * So we can easily have powerful filtering/unsampled counts of core flows and errors.
 */
export const CoreMarketplaceActionTrackingContextProvider = ({
  action,
  children,
}: Props) => {
  const properties: CoreMarketplaceActionContext | undefined = useMemo(() => {
    return {
      coreMarketplaceAction: action,
    }
  }, [action])

  return (
    <TrackingContextProvider
      name="CoreMarketplaceAction"
      properties={properties}
    >
      {children}
    </TrackingContextProvider>
  )
}
