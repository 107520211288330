import { useState } from "react"
import {
  MOONPAY_API_CHECK_IP_URL,
  MoonPayApiCheckIpResponse,
} from "@/features/moonpay"
import { useIsMountedRef } from "@/hooks/useIsMounted"
import { useMountEffect } from "@/hooks/useMountEffect"
import { captureNoncriticalError } from "@/lib/sentry"

type UseIsMoonPayAllowedOptions = {
  allowedByDefault?: boolean
}

type UseIsMoonPayAllowedResult = {
  isTopupAllowed: boolean
  isBuyWithCardAllowed: boolean
  isMintAllowed: boolean
  isLoading: boolean
}

let moonPayAllowedCache:
  | Omit<UseIsMoonPayAllowedResult, "isLoading">
  | undefined = undefined

export const clearMoonPayAllowedCache = () => {
  moonPayAllowedCache = undefined
}

export const useIsMoonPayAllowed = ({
  allowedByDefault = false,
}: UseIsMoonPayAllowedOptions = {}): UseIsMoonPayAllowedResult => {
  const [state, setState] = useState<UseIsMoonPayAllowedResult>({
    isTopupAllowed: allowedByDefault,
    isBuyWithCardAllowed: allowedByDefault,
    isMintAllowed: allowedByDefault,
    isLoading: true,
  })

  const isMountedRef = useIsMountedRef()

  useMountEffect(() => {
    const ipCheck = async () => {
      try {
        const { isBuyAllowed, isNftAllowed, state } = await checkIP()
        if (isMountedRef.current) {
          moonPayAllowedCache = {
            isTopupAllowed: isBuyAllowed,
            isMintAllowed: isNftAllowed && state !== "NY",
            isBuyWithCardAllowed: isNftAllowed,
          }
          setState({
            ...moonPayAllowedCache,
            isLoading: false,
          })
        }
      } catch (error) {
        captureNoncriticalError(error)
        if (isMountedRef.current) {
          setState({
            isTopupAllowed: false,
            isBuyWithCardAllowed: false,
            isMintAllowed: false,
            isLoading: false,
          })
        }
      }
    }

    if (!moonPayAllowedCache) {
      ipCheck()
    } else {
      setState({ ...moonPayAllowedCache, isLoading: false })
    }
  })

  return state
}

const checkIP = async (): Promise<
  Pick<MoonPayApiCheckIpResponse, "isBuyAllowed" | "isNftAllowed"> & {
    state: string | null
  }
> => {
  try {
    const response = await fetch(MOONPAY_API_CHECK_IP_URL)
    if (!response.ok) {
      // if request fails, assume that moonpay features are not available
      return {
        isBuyAllowed: false,
        isNftAllowed: false,
        state: null,
      }
    }
    const json = (await response.json()) as MoonPayApiCheckIpResponse
    return json
  } catch (error) {
    captureNoncriticalError(error)
    return {
      isBuyAllowed: false,
      isNftAllowed: false,
      state: null,
    }
  }
}
