import { classNames, Breakpoint, breakpoints } from "@opensea/ui-kit"
import { css } from "styled-components"
import { variant, VariantArgs } from "styled-system"
import { keys } from "@/lib/helpers/object"
import { UnreachableCaseError, Values } from "../lib/helpers/type"
import { Theme } from "./styled"

export const generateVariants = <T extends string>(
  variants: Record<string, T>,
  getStyle: (variant: T) => Values<NonNullable<VariantArgs["variants"]>>,
): VariantArgs["variants"] => {
  return Object.values(variants).reduce(
    (map, variant) => ({
      ...map,
      [variant]: getStyle(variant),
    }),
    {},
  )
}

type ThemeVariantArgs<TStyle = Record<string, unknown>> = Omit<
  VariantArgs<TStyle, Theme, "theme">,
  "prop" | "variants"
> & {
  variants?: Partial<Record<Theme, TStyle>>
}

export const themeVariant = <TStyle = Record<string, unknown>>(
  variantArgs: ThemeVariantArgs<TStyle>,
) => {
  return variant({ ...variantArgs, prop: "theme" } as VariantArgs)
}

type Css = ReturnType<typeof css>

type MediaOptions = {
  variant?: "greaterThanOrEqual" | "lessThan"
}

export const media = (
  styles: { [key in Breakpoint]?: Css },
  { variant = "greaterThanOrEqual" }: MediaOptions = {},
): Css =>
  keys(styles)
    .sort((a, b) => breakpoints[a] - breakpoints[b])
    .map(k => {
      switch (variant) {
        case "greaterThanOrEqual":
          return css`
            @media (min-width: ${breakpoints[k]}px) {
              ${styles[k]}
            }
          `
        case "lessThan":
          return css`
            @media (max-width: ${breakpoints[k] - 1}px) {
              ${styles[k]}
            }
          `
        default:
          throw new UnreachableCaseError(variant)
      }
    })
    .reduce((agg, arr) => agg.concat(arr), [])

export { classNames }
