/**
 * @generated SignedSource<<019491955206d31c539e4757748ff486>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MoonpayFlow = "CRYPTO" | "NFT" | "%future added value";
export type fetchMoonPayUrlSignatureQuery$variables = {
  flow: MoonpayFlow;
  url: string;
};
export type fetchMoonPayUrlSignatureQuery$data = {
  readonly moonpay: {
    readonly urlSignature: string;
  };
};
export type fetchMoonPayUrlSignatureQuery = {
  response: fetchMoonPayUrlSignatureQuery$data;
  variables: fetchMoonPayUrlSignatureQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "flow"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "url"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MoonpayType",
    "kind": "LinkedField",
    "name": "moonpay",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "flow",
            "variableName": "flow"
          },
          {
            "kind": "Variable",
            "name": "url",
            "variableName": "url"
          }
        ],
        "kind": "ScalarField",
        "name": "urlSignature",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "fetchMoonPayUrlSignatureQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "fetchMoonPayUrlSignatureQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "957b126cb5c9b1d5ad88f7db008865e4",
    "id": null,
    "metadata": {},
    "name": "fetchMoonPayUrlSignatureQuery",
    "operationKind": "query",
    "text": "query fetchMoonPayUrlSignatureQuery(\n  $url: String!\n  $flow: MoonpayFlow!\n) {\n  moonpay {\n    urlSignature(url: $url, flow: $flow)\n  }\n}\n"
  }
};
})();

(node as any).hash = "fb005e3dc0f8b46033bc97451032d30f";

export default node;
