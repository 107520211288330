/**
 * @generated SignedSource<<07a735e97eaf0649fe26d9005c828cda>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderSide = "ASK" | "BID" | "%future added value";
export type OrderTypeEnum = "BASIC" | "CRITERIA" | "DUTCH" | "ENGLISH" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CancelOrderAction_data$data = ReadonlyArray<{
  readonly __typename: "CancelOrderActionType";
  readonly method: {
    readonly __typename: string;
  };
  readonly ordersData: ReadonlyArray<{
    readonly item: {
      readonly asset?: {
        readonly " $fragmentSpreads": FragmentRefs<"GaslessCancellationFailedModal_items" | "GaslessCancellationProcessingModal_items">;
      };
      readonly quantity?: string;
    } | null;
    readonly orderCriteria: {
      readonly collection: {
        readonly representativeAsset: {
          readonly " $fragmentSpreads": FragmentRefs<"GaslessCancellationFailedModal_items" | "GaslessCancellationProcessingModal_items">;
        } | null;
      };
      readonly quantity: string | null;
    } | null;
    readonly orderType: OrderTypeEnum;
    readonly side: OrderSide;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"CancelOrderActionGaslessContent_action" | "CancelOrderActionOnChainContent_action" | "useHandleBlockchainActions_cancel_orders">;
  readonly " $fragmentType": "CancelOrderAction_data";
}>;
export type CancelOrderAction_data$key = ReadonlyArray<{
  readonly " $data"?: CancelOrderAction_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CancelOrderAction_data">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "GaslessCancellationProcessingModal_items"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "GaslessCancellationFailedModal_items"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CancelOrderAction_data",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderDataType",
      "kind": "LinkedField",
      "name": "ordersData",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "orderType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "side",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "item",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetType",
                  "kind": "LinkedField",
                  "name": "asset",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                (v2/*: any*/)
              ],
              "type": "AssetQuantityDataType",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderCriteriaType",
          "kind": "LinkedField",
          "name": "orderCriteria",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CollectionType",
              "kind": "LinkedField",
              "name": "collection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetType",
                  "kind": "LinkedField",
                  "name": "representativeAsset",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "method",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CancelOrderActionOnChainContent_action"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useHandleBlockchainActions_cancel_orders",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "method",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "useHandleBlockchainActions_transaction",
                  "selections": [
                    (v3/*: any*/),
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "useTransaction_transaction",
                      "selections": [
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AddressDataType",
                          "kind": "LinkedField",
                          "name": "source",
                          "plural": false,
                          "selections": (v5/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AddressDataType",
                          "kind": "LinkedField",
                          "name": "destination",
                          "plural": false,
                          "selections": (v5/*: any*/),
                          "storageKey": null
                        },
                        (v4/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "data",
                          "storageKey": null
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "type": "TransactionSubmissionDataType",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": "cancelOrderData",
                  "args": null,
                  "concreteType": "SignAndPostOrderCancelDataType",
                  "kind": "LinkedField",
                  "name": "data",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "payload",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "message",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "serverSignature",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "clientSignatureStandard",
                  "storageKey": null
                }
              ],
              "type": "SignAndPostOrderCancelType",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "orderRelayIds",
                  "storageKey": null
                }
              ],
              "type": "GaslessCancelType",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CancelOrderActionGaslessContent_action"
    }
  ],
  "type": "CancelOrderActionType",
  "abstractKey": null
};
})();

(node as any).hash = "fdcccc2b4b04e76e975a44401e6feec0";

export default node;
