import React from "react"
import { Icon, UnstyledButton } from "@opensea/ui-kit"
import { TokenPrice } from "@/components/assets/price/TokenPrice.react"
import { FundsDisplay } from "@/components/nav/WalletPopover/components/FundsDisplay.react"
import { useNativeCurrencyBalance } from "@/containers/WalletBalanceProvider/NativeCurrencyBalanceProvider.react"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { Tooltip } from "@/design-system/Tooltip"
import { useChains } from "@/hooks/useChains"
import { useTranslate } from "@/hooks/useTranslate"

export const NativeFundsDisplay = () => {
  const t = useTranslate("components")
  const { chain: activeChain } = useWallet()
  const { nativeCurrencyBalance, refreshNativeCurrencyBalance, errors } =
    useNativeCurrencyBalance()
  const { getNativeCurrencySymbol } = useChains()

  const isNativeCurrencyBalanceLoading = nativeCurrencyBalance === undefined

  if (!activeChain) {
    return null
  }

  const nativeCurrencySymbol = getNativeCurrencySymbol(activeChain)

  if (errors.nativeCurrencyBalance) {
    return (
      <Tooltip
        content={
          <>
            {t(
              "nativeFundsDisplay.error",
              "Error fetching native currency funds.",
            )}
            <br />
            {t("nativeFundsDisplay.retry", "Click to retry.")}
          </>
        }
      >
        <UnstyledButton
          onClick={event => {
            event.stopPropagation()
            refreshNativeCurrencyBalance()
          }}
        >
          <Icon value="error" />
        </UnstyledButton>
      </Tooltip>
    )
  }

  if (isNativeCurrencyBalanceLoading) {
    return <NativeFundsDisplaySkeleton />
  }

  return (
    <TokenPrice
      color="inherit"
      fontWeight="inherit"
      price={nativeCurrencyBalance}
      symbol={nativeCurrencySymbol}
      symbolVariant="raw"
    />
  )
}

const NativeFundsDisplaySkeleton = () => (
  <FundsDisplay.Skeleton className="w-[8ch]" height="24px" />
)
