import React from "react"
import { ErrorBoundary } from "@sentry/nextjs"
import dynamic from "next/dynamic"
import { getErrorId, isFetchError } from "@/lib/graphql/error"

const LazyErrorPage = dynamic(async () =>
  import("@/components/pages/ErrorPage").then(mod => mod.ErrorPage),
)

type Props = {
  children: React.ReactNode
}

export const AppErrorBoundary = ({ children }: Props) => {
  if (process.env.NODE_ENV === "development") {
    return <>{children}</>
  }

  return (
    <ErrorBoundary
      beforeCapture={scope => {
        scope.setTag("application_level", "_app.js")
        scope.setTag("severity", "high")
        scope.addBreadcrumb({
          category: "error_boundary",
          message:
            "AppErrorBoundary: This error has thrown an ErrorPage (Oops, something went wrong)",
          level: "error",
        })
      }}
      fallback={({ error, eventId, resetError }) => (
        <LazyErrorPage
          errorId={getErrorId(error) || eventId}
          isFetchError={isFetchError(error)}
          resetError={resetError}
          statusCode={500}
        />
      )}
    >
      {children}
    </ErrorBoundary>
  )
}
