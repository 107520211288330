import React from "react"
import { range } from "lodash"
import { useLazyLoadQuery } from "react-relay"
import { GlobalCollectionCard } from "@/components/collections/GlobalCollectionCard/GlobalCollectionCard.react"
import { Block } from "@/design-system/Block"
import {
  CAROUSEL_RESPONSIVE_CONFIG_DEFAULT,
  Carousel,
} from "@/design-system/Carousel"
import { useCarousel } from "@/hooks/useCarousel"
import {
  CollectionsScrollerQuery,
  CollectionsScrollerQuery$variables,
} from "@/lib/graphql/__generated__/CollectionsScrollerQuery.graphql"
import { getNodes, graphql } from "@/lib/graphql/graphql"
import { SsrSuspense } from "./SsrSuspense.react"

type Props = {
  variables: CollectionsScrollerQuery$variables
}

const LazyCollectionsScroller = ({ variables }: Props) => {
  const data = useLazyLoadQuery<CollectionsScrollerQuery>(
    graphql`
      query CollectionsScrollerQuery(
        $categories: [CategorySlug!]
        $chains: [ChainScalar!]
      ) {
        trendingCollections(
          first: 12
          categories: $categories
          chains: $chains
        ) {
          edges {
            node {
              ...GlobalCollectionCard_data
                @arguments(showContextMenu: false, showStats: false)
            }
          }
        }
      }
    `,
    variables,
  )

  const { showArrows } = useCarousel()

  const { trendingCollections } = data
  const showingTrendingCollections = !showArrows
    ? getNodes(trendingCollections).slice(0, 6)
    : getNodes(trendingCollections)

  return (
    <Block className="w-full">
      <Carousel
        centeredSlides
        centeredSlidesBounds
        dotPaginationBelowSlides
        enableArrowControls={showArrows}
        enableDotPagination
        enableFreeScroll
        enableMousewheel
        id="collection-scroller-carousel"
        renderSlide={({ collection }) => collection}
        responsiveConfig={CAROUSEL_RESPONSIVE_CONFIG_DEFAULT}
        showScrollbar={false}
        slides={showingTrendingCollections.map((collection, idx) => ({
          id: `collections-scroller-carousel-${idx}`,
          collection: (
            <GlobalCollectionCard
              collection={collection}
              key={idx}
              requireBannerImage
            />
          ),
        }))}
        variant="card"
      />
    </Block>
  )
}

export const CollectionsScroller = (props: Props) => {
  const { showArrows } = useCarousel()

  return (
    <SsrSuspense
      fallback={
        <Block maxWidth="90%">
          <Carousel
            centeredSlides
            centeredSlidesBounds
            dotPaginationBelowSlides
            enableArrowControls
            enableDotPagination
            enableFreeScroll
            enableMousewheel
            id="collection-scroller-carousel-skeleton"
            renderSlide={({ collection }) => collection}
            responsiveConfig={CAROUSEL_RESPONSIVE_CONFIG_DEFAULT}
            showScrollbar={false}
            // TODO: Do we need to toggle between 6 and 12? Can it just be 12?
            slides={range(!showArrows ? 6 : 12).map((_, idx) => ({
              id: `collections-scroller-carousel-skeleton-slide-${idx}`,
              collection: <GlobalCollectionCard.Skeleton key={Math.random()} />,
            }))}
          />
        </Block>
      }
    >
      <LazyCollectionsScroller {...props} />
    </SsrSuspense>
  )
}
