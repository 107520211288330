import React from "react"
import { LinkProps, Link as DSLink } from "@opensea/ui-kit"
import { useAnalyticsContext } from "@/lib/analytics"
import { trackOutboundLink } from "@/lib/analytics/ga"
import { getHttpUrl } from "@/lib/helpers/urls"
import { OPEN_SEA_HOST } from "../../../constants"
import { useTrackClickLink } from "../Link/analytics"

export type ExternalLinkProps = Pick<
  LinkProps,
  | "className"
  | "children"
  | "onClick"
  | "aria-label"
  | "target"
  | "rel"
  | "disabled"
  | "title"
  | "variant"
> & {
  href: string
  eventSource?: string
  eventParams?: Record<string, unknown>
}

const MIDDLE_CLICK = 1

export const ExternalLink = React.forwardRef<
  HTMLAnchorElement,
  ExternalLinkProps
>(function ExternalLink(
  {
    href: url,
    eventSource: eventSourceFromProp,
    eventParams: eventParamsFromProp,
    rel,
    children,
    target = "_blank",
    onClick,
    ...rest
  },
  ref,
) {
  const trackClickLink = useTrackClickLink()
  const { eventSource, eventParams } = useAnalyticsContext({
    eventParams: eventParamsFromProp,
    eventSource: eventSourceFromProp,
  })

  const trackClick = (callback?: () => void) => {
    trackOutboundLink(eventSource || url, async () => {
      trackClickLink({
        url,
        target,
        source: eventSource,
        currentUrl: window.location.href,
        type: "external",
        ...eventParams, // Add extra untyped eventParams to the event
      })
      callback && callback()
    })
  }

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const sameTarget = target !== "_blank"
    const isNewTab =
      event.ctrlKey ||
      event.shiftKey ||
      event.metaKey ||
      event.button === MIDDLE_CLICK

    if (sameTarget && !isNewTab) {
      event.preventDefault()
      trackClick(() => {
        window.location.href = url
      })
    } else {
      trackClick()
    }

    onClick?.(event)
  }

  let relProperty = "nofollow noopener"
  // Override rel to empty if OpenSea host URL
  const httpUrl = getHttpUrl(url)
  if (httpUrl) {
    const { host } = httpUrl
    if (host.endsWith(OPEN_SEA_HOST)) {
      relProperty = ""
    }
  }

  if (rel) {
    if (Array.isArray(rel)) {
      relProperty += ` ${rel.join(" ")}`
    } else {
      relProperty += ` ${rel}`
    }
  }

  return (
    <DSLink
      href={url}
      ref={ref}
      rel={relProperty}
      target={target}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </DSLink>
  )
})
