/**
 * @generated SignedSource<<33e937987e651a09c647d83b2bb583f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Config = "AFFILIATE" | "AFFILIATE_BLACKLISTED" | "AFFILIATE_REQUESTED" | "EMPLOYEE" | "MODERATOR" | "PARTNER" | "STAFF" | "VERIFIED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AccountLink_data$data = {
  readonly address: string;
  readonly config: Config | null;
  readonly displayName: string | null;
  readonly isCompromised: boolean;
  readonly user: {
    readonly publicUsername: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileImage_data" | "accounts_url" | "wallet_accountKey">;
  readonly " $fragmentType": "AccountLink_data";
};
export type AccountLink_data$key = {
  readonly " $data"?: AccountLink_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountLink_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "UserType",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicUsername",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountLink_data",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "config",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCompromised",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileImage_data"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "wallet_accountKey",
      "selections": [
        (v0/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "accounts_url",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "AccountType",
  "abstractKey": null
};
})();

(node as any).hash = "34eb3e12ba8b373ed6b27e35acb85532";

export default node;
