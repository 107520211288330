import React from "react"
import { Block } from "@/design-system/Block"
import { Modal } from "@/design-system/Modal"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { trackEnoughForGasFeesPrompt } from "@/lib/analytics/events/checkoutEvents"

export const EnoughFeesPrompt = () => {
  const t = useTranslate("components")
  const { onPrevious } = useMultiStepFlowContext()

  useMountEffect(() => {
    trackEnoughForGasFeesPrompt()
  })

  return (
    <>
      <Modal.Header onPrevious={onPrevious}>
        <Modal.Header.Title>
          {t("trade.enoughFees.title", "Do you have enough for gas fees?")}
        </Modal.Header.Title>
      </Modal.Header>
      <Modal.Body>
        <Block className="text-center" margin="0 20px">
          {t(
            "trade.enoughFees.description",
            "The price of this item excludes gas fees. Make sure you have enough funds in your balance to cover the cost of gas fees.",
          )}
        </Block>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button variant="secondary" onClick={() => onPrevious?.()}>
          {t("trade.enoughFees.addMore", "Add more funds")}
        </Modal.Footer.Button>

        <Modal.Footer.Button
          onClick={() => {
            // Go back twice to checkout modal.
            // Note: If multi modal supported named steps we could jump around without these fickle considerations
            onPrevious?.()
            onPrevious?.()
          }}
        >
          {t("trade.enoughFees.continue", "Continue")}
        </Modal.Footer.Button>
      </Modal.Footer>
    </>
  )
}
