import React from "react"
import { graphql, useFragment } from "react-relay"
import { AddFundsModalContent } from "@/components/trade/AddFundsModal"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { useTranslate } from "@/hooks/useTranslate"
import { AskForDepositAction_data$key } from "@/lib/graphql/__generated__/AskForDepositAction_data.graphql"
import { bn } from "@/lib/helpers/numberUtils"
import {
  BaseBlockchainActionProps,
  BlockchainActionModalContent,
} from "./BlockchainActionModalContent.react"

type Props = BaseBlockchainActionProps & {
  dataKey: AskForDepositAction_data$key
}

export const AskForDepositAction = ({ dataKey, onEnd }: Props) => {
  const t = useTranslate("components")
  const data = useFragment(
    graphql`
      fragment AskForDepositAction_data on AskForDepositType {
        asset {
          chain {
            identifier
          }
          decimals
          symbol
          usdSpotPrice
        }
        minQuantity
      }
    `,
    dataKey,
  )

  const { onPrevious, onNext } = useMultiStepFlowContext()

  // NOTE: This is extremely hacky and copied over from legacy ActionPanel.
  // Seriously reconsider how to do this
  const onDepositAction = () => {
    const onFundsAdded = () => {
      if (onPrevious) {
        // go back twice to the FulfillActionModal
        onPrevious()
        onPrevious()
      } else {
        onEnd()
      }
    }

    onNext(
      data.asset.symbol && (
        <AddFundsModalContent
          chain={data.asset.chain.identifier}
          fundsToAdd={bn(data.minQuantity, data.asset.decimals).multipliedBy(
            data.asset.usdSpotPrice ?? 1,
          )}
          symbol={data.asset.symbol}
          onFundsAdded={onFundsAdded}
        />
      ),
    )
  }

  return (
    <BlockchainActionModalContent>
      <BlockchainActionModalContent.Header>
        <BlockchainActionModalContent.Title>
          {t("blockchain.deposit.title", "Deposit or convert funds")}
        </BlockchainActionModalContent.Title>
      </BlockchainActionModalContent.Header>
      <BlockchainActionModalContent.Body>
        <BlockchainActionModalContent.Body.Text>
          {t(
            "blockchain.deposit.description",
            "You don't have enough funds to complete the purchase. Please deposit or convert your funds.",
          )}
        </BlockchainActionModalContent.Body.Text>
        <BlockchainActionModalContent.Body.Button onClick={onDepositAction}>
          {t("blockchain.deposit.cta", "Deposit")}
        </BlockchainActionModalContent.Body.Button>
      </BlockchainActionModalContent.Body>
    </BlockchainActionModalContent>
  )
}
