/**
 * @generated SignedSource<<96bee59fcd5999bb2983005f016088a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NSFWPreference = "CENSOR" | "HIDE" | "SHOW" | "%future added value";
export type UserModifyMutationInput = {
  bidReceivedEmailsPriceThreshold?: string | null;
  bio?: string | null;
  clientMutationId?: string | null;
  email?: string | null;
  hasAffirmativelyAcceptedOpenseaTerms?: boolean | null;
  nsfwPreference?: NSFWPreference | null;
  receiveAuctionCreationEmails?: boolean | null;
  receiveAuctionExpirationEmails?: boolean | null;
  receiveBidInvalidEmails?: boolean | null;
  receiveBidItemPriceChangeEmails?: boolean | null;
  receiveBidReceivedEmails?: boolean | null;
  receiveBundleInvalidEmails?: boolean | null;
  receiveCancellationEmails?: boolean | null;
  receiveFavoriteAssetListedEmails?: boolean | null;
  receiveItemSoldEmails?: boolean | null;
  receiveMintSuccessEmails?: boolean | null;
  receiveNewAssetReceivedEmails?: boolean | null;
  receiveNewsletter?: boolean | null;
  receiveOutbidEmails?: boolean | null;
  receiveOwnedAssetUpdateEmails?: boolean | null;
  receivePurchaseEmails?: boolean | null;
  receiveReferralEmails?: boolean | null;
  receiveSwapAcceptedEmails?: boolean | null;
  receiveSwapReceivedEmails?: boolean | null;
  username?: string | null;
  websiteUrl?: string | null;
};
export type AcceptTermsOfServiceModalMutation$variables = {
  input: UserModifyMutationInput;
};
export type AcceptTermsOfServiceModalMutation$data = {
  readonly users: {
    readonly modify: {
      readonly email: string | null;
      readonly hasAffirmativelyAcceptedOpenseaTerms: boolean | null;
      readonly publicUsername: string | null;
      readonly relayId: string;
      readonly username: string;
    };
  };
};
export type AcceptTermsOfServiceModalMutation = {
  response: AcceptTermsOfServiceModalMutation$data;
  variables: AcceptTermsOfServiceModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicUsername",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasAffirmativelyAcceptedOpenseaTerms",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AcceptTermsOfServiceModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserMutationType",
        "kind": "LinkedField",
        "name": "users",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "UserType",
            "kind": "LinkedField",
            "name": "modify",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AcceptTermsOfServiceModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserMutationType",
        "kind": "LinkedField",
        "name": "users",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "UserType",
            "kind": "LinkedField",
            "name": "modify",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b7c78ff9f0382c1ec4aa5b15e51a1f44",
    "id": null,
    "metadata": {},
    "name": "AcceptTermsOfServiceModalMutation",
    "operationKind": "mutation",
    "text": "mutation AcceptTermsOfServiceModalMutation(\n  $input: UserModifyMutationInput!\n) {\n  users {\n    modify(input: $input) {\n      relayId\n      username\n      publicUsername\n      hasAffirmativelyAcceptedOpenseaTerms\n      email\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7701f75e345f23f450c111efeaba3328";

export default node;
