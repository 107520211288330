import React, { forwardRef } from "react"
import { UnstyledButton } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { Block } from "@/design-system/Block"

type HeaderProps = {
  children: React.ReactNode
  className?: string
  disabled?: boolean
  onClick?: () => unknown
}

type TextBodyProps = {
  children?: React.ReactNode
  className?: string
}

type FooterProps = {
  children?: React.ReactNode
  className?: string
}

type Props = {
  className?: string
  children: (props: {
    Header: React.ElementType<HeaderProps>
    Body: React.ElementType<TextBodyProps>
    Footer: React.ElementType<FooterProps>
  }) => React.ReactNode
}

export const BasePanel = forwardRef<HTMLDivElement, Props>(function BasePanel(
  { className, children },
  ref,
) {
  return (
    <DivContainer className={className} ref={ref}>
      {children({
        Header: PanelHeader,
        Body: PanelBody,
        Footer: PanelFooter,
      })}
    </DivContainer>
  )
})

export const PanelHeader = styled(UnstyledButton)<HeaderProps>`
  width: 100%;
  align-items: center;
  border-radius: ${props => props.theme.borderRadius.default};
  border-top: 1px solid ${props => props.theme.colors.components.border.level2};
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  padding: 20px;
  user-select: none;
  background: transparent;

  ${props =>
    props.disabled &&
    css`
      cursor: initial;
      color: ${props.theme.colors.text.secondary};
    `}
`

export const PanelBody = styled(Block)`
  border-top: 1px solid ${props => props.theme.colors.components.border.level2};
  color: ${props => props.theme.colors.text.primary};
`

const PanelFooter = styled(Block)`
  border-top: 1px solid ${props => props.theme.colors.components.border.level2};
  color: ${props => props.theme.colors.text.primary};
`

const DivContainer = styled.div`
  border-radius: ${props => props.theme.borderRadius.default};
  border: 1px solid ${props => props.theme.colors.components.border.level2};
  border-top: none; /* Allows the custom headers to style their top borders */
  color: ${props => props.theme.colors.text.primary};
  background-color: ${props =>
    props.theme.type === "light"
      ? props.theme.colors.base2
      : props.theme.colors.components.background.gray1};
  overflow: hidden;
`
