import React, { forwardRef } from "react"
import {
  Icon,
  MaterialIcon,
  IconProps,
  MaterialTheme,
  UnstyledButton,
  breakpoints,
  VerticalAligned,
  Flex,
} from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { Link } from "@/components/common/Link"
import { Block } from "@/design-system/Block"
import { useTheme } from "@/design-system/Context"
import { Image } from "@/design-system/Image"
import { useRouter } from "@/hooks/useRouter"
import { selectClassNames } from "@/lib/helpers/styling"
import { media, classNames } from "@/styles/styleUtils"
import { $nav_height } from "@/styles/variables"
import { MaterialSymbolProps } from "../svgs/material-symbols/types"
import {
  DESKTOP_NAV_ITEM_PADDING,
  MOBILE_NAV_ITEM_HEIGHT,
  MOBILE_NAV_ITEM_PADDING,
} from "./constants"

export type NavItemProps = {
  className?: string
  children?: React.ReactNode
  href?: string
  icon?: MaterialIcon | React.ComponentType<MaterialSymbolProps>
  image?: string
  iconVariant?: MaterialTheme
  iconSize?: IconProps["size"]
  iconTitle?: IconProps["title"]
  iconClassName?: string
  isRoot?: boolean
  locale?: string
  notificationContent?: React.ReactNode
  onClick?: (e: React.MouseEvent<HTMLElement>) => unknown
}

export const NavItem = forwardRef<HTMLLIElement, NavItemProps>(function NavItem(
  {
    children,
    className,
    icon: MaterialSymbol,
    iconVariant,
    isRoot,
    href,
    locale,
    onClick,
    iconTitle,
    iconSize,
    iconClassName,
    image,
    notificationContent,
  },
  ref,
) {
  const router = useRouter()
  const { theme } = useTheme()

  const child = (
    <>
      {MaterialSymbol ? (
        typeof MaterialSymbol === "string" ? (
          <Icon
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className={classNames("NavItem--icon", iconClassName)}
            size={iconSize ?? 32}
            title={iconTitle}
            value={MaterialSymbol}
            variant={iconVariant}
          />
        ) : (
          <MaterialSymbol
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className={classNames("NavItem--icon", iconClassName)}
            size={iconSize ?? 32}
            title={iconTitle}
          />
        )
      ) : image ? (
        <Flex className="mr-2">
          <Image
            alt=""
            height={iconSize ?? 32}
            src={`${image}-${theme}.svg`}
            width={iconSize ?? 32}
          />
        </Flex>
      ) : null}
      {children}
    </>
  )

  if (!href) {
    return (
      <ItemContainer
        className={className}
        isRoot={isRoot}
        ref={ref}
        onClick={onClick}
      >
        <VerticalAligned className="relative w-full">
          <UnstyledButton
            className={selectClassNames("NavItem", {
              main: true,
              withIcon: !!MaterialSymbol,
            })}
          >
            {child}
          </UnstyledButton>
          {notificationContent && (
            <NotificationContainer>{notificationContent}</NotificationContainer>
          )}
        </VerticalAligned>
      </ItemContainer>
    )
  }

  const isNowActive = router.asPath.startsWith(href)

  return (
    <ItemContainer className={className} isRoot={isRoot} ref={ref}>
      <Link
        className={selectClassNames("NavItem", {
          main: true,
          withAfter: isRoot,
          withIcon: !!MaterialSymbol,
          active: isRoot && isNowActive,
        })}
        href={href}
        locale={locale}
        onClick={onClick}
      >
        {child}
      </Link>
    </ItemContainer>
  )
})

const ItemContainer = styled.li<{ isRoot?: boolean; desktopPadding?: string }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: ${({ isRoot }) => (isRoot ? $nav_height : MOBILE_NAV_ITEM_HEIGHT)};
  justify-content: space-between;
  font-weight: 600;
  font-size: 16px;
  flex-shrink: 0;

  .NavItem--main {
    align-items: center;
    color: ${props => props.theme.colors.text.primary};
    display: flex;
    height: 100%;
    padding: ${MOBILE_NAV_ITEM_PADDING};
    position: relative;
    width: 100%;

    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      color: ${props => props.theme.colors.text.secondary};
    }

    ${media({
      lg: css`
        padding: ${DESKTOP_NAV_ITEM_PADDING};
      `,
    })}

    ${media({
      xl: css`
        &.NavItem--withIcon {
          color: ${props => props.theme.colors.text.primary};

          &:hover,
          &:active,
          &:focus {
            color: ${props => props.theme.colors.text.secondary};
          }
        }
      `,
    })}
  }
`

const NotificationContainer = styled(Block)`
  height: 18px;
  width: 18px;
  border-radius: ${props => props.theme.borderRadius.circle};
  position: absolute;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.text.on.primary};
  top: -4px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${breakpoints.sm}px) {
    right: 16px;
  }
`
