import React from "react"

type Props = {
  className?: string
  width?: number
  fill?: string
}

export function ShapeLogo({
  className,
  fill = "lightgrey",
  width = 24,
}: Props) {
  return (
    <svg
      className={className}
      style={{ width, height: width }}
      viewBox="0 0 224.585 224.585"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle fill={fill} cx="112.293" cy="112.293" r="110.793" />
    </svg>
  )
}
