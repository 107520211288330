import React from "react"
import { Text } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { StackedAssetMedia } from "@/components/assets/StackedAssetMedia"
import { Link } from "@/components/common/Link"
import { Item } from "@/design-system/Item"
import { useChains } from "@/hooks/useChains"
import { useTranslate } from "@/hooks/useTranslate"
import { OrdersHeaderData_orders$key } from "@/lib/graphql/__generated__/OrdersHeaderData_orders.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { UnreachableCaseError } from "@/lib/helpers/type"

type Props = {
  orders: OrdersHeaderData_orders$key
  transactionHash?: string
}

export const OrdersHeaderData = ({
  orders: ordersKey,
  transactionHash,
}: Props) => {
  const t = useTranslate("orders")
  const orders = useFragment<OrdersHeaderData_orders$key>(
    graphql`
      fragment OrdersHeaderData_orders on OrderDataType @relay(plural: true) {
        chain {
          identifier
        }
        item {
          __typename
          ... on AssetQuantityDataType {
            asset {
              ...StackedAssetMedia_assets
            }
          }
          ... on AssetBundleType {
            assetQuantities(first: 20) {
              edges {
                node {
                  asset {
                    ...StackedAssetMedia_assets
                  }
                }
              }
            }
          }
          ... on AssetBundleToBeCreatedType {
            assetQuantitiesToBeCreated: assetQuantities {
              asset {
                ...StackedAssetMedia_assets
              }
            }
          }
        }
        orderCriteria {
          collection {
            representativeAsset {
              ...StackedAssetMedia_assets
            }
          }
        }
        orderType
        side
      }
    `,
    ordersKey,
  )

  const { getTransactionUrl, getBlockExplorerName } = useChains()

  const assets = orders.map(order => {
    const { item, orderCriteria, orderType } = order

    if (
      orderType === "CRITERIA" &&
      orderCriteria?.collection.representativeAsset
    ) {
      return orderCriteria.collection.representativeAsset
    }

    if (!item) {
      throw Error("Order has no item or orderCriteria")
    }

    switch (item.__typename) {
      case "AssetQuantityDataType":
        return item.asset
      case "AssetBundleType":
        return getNodes(item.assetQuantities)[0].asset
      case "AssetBundleToBeCreatedType":
        return item.assetQuantitiesToBeCreated[0].asset
      default:
        throw new UnreachableCaseError(item.__typename as never)
    }
  })

  if (!orders.length) {
    return null
  }

  const { chain: firstChain, orderType, side: firstOrderSide } = orders[0]
  const isCollectionOfferFirst = orderType === "CRITERIA"
  const assetMediaType = isCollectionOfferFirst ? "collection" : "asset"

  return (
    <Item variant="unstyled">
      <Item.Avatar size={84}>
        <StackedAssetMedia
          assets={assets}
          isLoading={Boolean(transactionHash)}
          type={assetMediaType}
          variant="small"
        />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>
          <Text asChild size="medium" weight="semibold">
            <div>
              {firstOrderSide === "ASK"
                ? t(
                    "cancelOrders.headerData.title.listings",
                    {
                      0: "{{count}} listings",
                      one: "{{count}} listing",
                      other: "{{count}} listings",
                    },
                    {
                      count: orders.length,
                    },
                  )
                : t(
                    "cancelOrders.headerData.title.offers",
                    {
                      0: "{{count}} offers",
                      one: "{{count}} offer",
                      other: "{{count}} offers",
                    },
                    {
                      count: orders.length,
                    },
                  )}
            </div>
          </Text>
        </Item.Title>

        {transactionHash ? (
          <Item.Description>
            <Link
              href={getTransactionUrl(firstChain.identifier, transactionHash)}
            >
              {t("explorerLink", "View on {{explorerLink}}", {
                explorerLink: getBlockExplorerName(firstChain.identifier),
              })}
            </Link>
          </Item.Description>
        ) : null}
      </Item.Content>
    </Item>
  )
}
