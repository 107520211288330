import { WALLET_NAME } from "@/constants/wallet"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { useTrackingFn } from "@/lib/analytics/hooks/useTrackingFn"

type TrackConnectWalletProps = {
  address: string
  chain: ChainIdentifier
  walletName: WALLET_NAME
}

export const useWalletProviderTracking = () => ({
  trackConnectWallet: useTrackingFn<TrackConnectWalletProps>("connect wallet"),
  trackLogout: useTrackingFn("logout"),
  trackProviderAccountsChange: useTrackingFn<
    Omit<TrackConnectWalletProps, "chain">
  >("provider accounts change"),
})
