import { css } from "styled-components"
import { media } from "@/styles/styleUtils"

export const preventFontSizeZoomStyles = css`
  /* We need a font size of 16 to prevent iOS input zoom */
  font-size: 16px;
  line-height: 26px;
  min-height: 26px;

  ${media({
    sm: css`
      font-size: inherit;
      line-height: inherit;
      min-height: inherit;
    `,
  })}
`

export const interactiveStylesPrimary = css`
  color: ${props => props.theme.colors.text.primary};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.text.secondary};
  }
  &:active {
    color: ${props => props.theme.colors.text.secondary};
  }
`

export const linkStyles = css`
  color: ${props => props.theme.colors.seaBlue};

  :hover {
    color: ${props => props.theme.colors.darkSeaBlue};
  }
`
