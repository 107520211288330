/**
 * @generated SignedSource<<a5276578ba0eda00d66eec73e1264b36>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BulkAcceptOffersAction_data$data = ReadonlyArray<{
  readonly __typename: "BulkAcceptOffersActionType";
  readonly maxQuantityToFill: string | null;
  readonly offersToAccept: ReadonlyArray<{
    readonly criteriaAsset: {
      readonly relayId: string;
      readonly " $fragmentSpreads": FragmentRefs<"StackedAssetMedia_assets">;
    } | null;
    readonly itemFillAmount: string;
    readonly orderData: {
      readonly chain: {
        readonly identifier: ChainIdentifier;
      };
      readonly item: {
        readonly asset?: {
          readonly " $fragmentSpreads": FragmentRefs<"StackedAssetMedia_assets">;
        };
        readonly assetQuantities?: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly asset: {
                readonly " $fragmentSpreads": FragmentRefs<"StackedAssetMedia_assets">;
              };
            } | null;
          } | null>;
        };
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"useTotalItems_ordersData">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"readOfferDataToAcceptPrice_offerToAccept" | "useTotalPriceOfferDataToAccept_offersToAccept">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_bulk_accept_offers">;
  readonly " $fragmentType": "BulkAcceptOffersAction_data";
}>;
export type BulkAcceptOffersAction_data$key = ReadonlyArray<{
  readonly " $data"?: BulkAcceptOffersAction_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"BulkAcceptOffersAction_data">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemFillAmount",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "StackedAssetMedia_assets"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AssetType",
    "kind": "LinkedField",
    "name": "asset",
    "plural": false,
    "selections": [
      (v2/*: any*/)
    ],
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BulkAcceptOffersAction_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxQuantityToFill",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OfferToAcceptType",
      "kind": "LinkedField",
      "name": "offersToAccept",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderDataType",
          "kind": "LinkedField",
          "name": "orderData",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "item",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": (v3/*: any*/),
                  "type": "AssetQuantityDataType",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "first",
                          "value": 30
                        }
                      ],
                      "concreteType": "AssetQuantityTypeConnection",
                      "kind": "LinkedField",
                      "name": "assetQuantities",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AssetQuantityTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "AssetQuantityType",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": (v3/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "assetQuantities(first:30)"
                    }
                  ],
                  "type": "AssetBundleType",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "useTotalItems_ordersData"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetType",
          "kind": "LinkedField",
          "name": "criteriaAsset",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "relayId",
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useTotalPriceOfferDataToAccept_offersToAccept"
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "readOfferDataToAcceptPrice_offerToAccept",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderDataType",
              "kind": "LinkedField",
              "name": "orderData",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriceType",
                  "kind": "LinkedField",
                  "name": "perUnitPrice",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "usd",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "unit",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PaymentAssetType",
                  "kind": "LinkedField",
                  "name": "payment",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "TokenPricePayment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "symbol",
                          "storageKey": null
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useHandleBlockchainActions_bulk_accept_offers",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TransactionSubmissionDataType",
          "kind": "LinkedField",
          "name": "method",
          "plural": false,
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "useHandleBlockchainActions_transaction",
              "selections": [
                (v1/*: any*/),
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "useTransaction_transaction",
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AddressDataType",
                      "kind": "LinkedField",
                      "name": "source",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AddressDataType",
                      "kind": "LinkedField",
                      "name": "destination",
                      "plural": false,
                      "selections": (v5/*: any*/),
                      "storageKey": null
                    },
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "data",
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OfferToAcceptType",
          "kind": "LinkedField",
          "name": "offersToAccept",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderDataType",
              "kind": "LinkedField",
              "name": "orderData",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "openedAt",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "BulkAcceptOffersActionType",
  "abstractKey": null
};
})();

(node as any).hash = "34be01f8e4449f1d1e1c8c022cc0bf43";

export default node;
