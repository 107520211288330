/**
 * @generated SignedSource<<20a110648f8968d302917e8f7b108b60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useHandleBlockchainActions_update_drop_mechanics$data = {
  readonly method: {
    readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_transaction">;
  };
  readonly " $fragmentType": "useHandleBlockchainActions_update_drop_mechanics";
};
export type useHandleBlockchainActions_update_drop_mechanics$key = {
  readonly " $data"?: useHandleBlockchainActions_update_drop_mechanics$data;
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_update_drop_mechanics">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useHandleBlockchainActions_update_drop_mechanics"
};

(node as any).hash = "eddd024165f0ff4cec80de8e4112277e";

export default node;
