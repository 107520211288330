import React, { useEffect, useState } from "react"
import { Text } from "@opensea/ui-kit"
import { useInterval } from "react-use"

export type Expiration = {
  minutes: number
  seconds: number
}

type Props = Expiration & {
  className?: string
  onReset?: (resetFn: () => void) => void
  onEnd?: () => void
  size?: "small" | "medium" | "tiny"
}

export const AuthCodeExpiration = ({
  minutes = 0,
  seconds = 0,
  className,
  onReset,
  onEnd,
  size = "small",
}: Props) => {
  const [remainingTime, setRemainingTime] = useState({
    minutes,
    seconds,
  })

  const update = () => {
    if (remainingTime.minutes === 0 && remainingTime.seconds === 0) {
      onEnd?.()
      return
    }

    if (remainingTime.seconds === 0) {
      setRemainingTime({
        minutes: remainingTime.minutes - 1,
        seconds: 59,
      })
    } else {
      setRemainingTime({
        minutes: remainingTime.minutes,
        seconds: remainingTime.seconds - 1,
      })
    }
  }

  const reset = () => {
    setRemainingTime({
      minutes,
      seconds,
    })
  }

  useInterval(update, 1000)

  useEffect(() => {
    if (onReset) {
      onReset(reset)
    }
  })

  return (
    <Text.Body className={className} color="secondary" size={size}>
      {remainingTime.minutes}:
      {remainingTime.seconds.toString().padStart(2, "0")}
    </Text.Body>
  )
}
