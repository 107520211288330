/**
 * @generated SignedSource<<45c752f7c08129b43c1a33c78a07d46a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FundsDisplay_walletFunds$data = {
  readonly quantity: string;
  readonly symbol: string;
  readonly " $fragmentType": "FundsDisplay_walletFunds";
};
export type FundsDisplay_walletFunds$key = {
  readonly " $data"?: FundsDisplay_walletFunds$data;
  readonly " $fragmentSpreads": FragmentRefs<"FundsDisplay_walletFunds">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FundsDisplay_walletFunds",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "symbol",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantity",
      "storageKey": null
    }
  ],
  "type": "WalletFundsType",
  "abstractKey": null
};

(node as any).hash = "64231ea1595d7030c0eb1008b8e8ad14";

export default node;
