import React, { ComponentProps, ElementRef, forwardRef } from "react"
import {
  List as DSList,
  ListProps as DSListProps,
  ItemProps,
} from "@opensea/ui-kit"
import { Link, LinkProps } from "@/components/common/Link"
import { AvatarImage } from "../Avatar"

export type ListProps = DSListProps

const ListItemAvatar = (props: ComponentProps<typeof DSList.Item.Avatar>) => {
  return <DSList.Item.Avatar {...props} renderImage={AvatarImage} />
}

const RenderLink = (props: LinkProps) => {
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <Link {...props} />
}

const ListItemBase = forwardRef<ElementRef<typeof DSList.Item>, ItemProps>(
  function ListItemBase(props, ref) {
    return <DSList.Item renderLink={RenderLink} {...props} ref={ref} />
  },
)

const ListItem = Object.assign({}, DSList.Item, ListItemBase, {
  Avatar: ListItemAvatar,
})

export const List = Object.assign({}, DSList, {
  Item: ListItem,
})
