import { v4 as uuid } from "uuid"

type MetaContext = {
  loggedAt: number
  internalEventId: string
}

export type MetaTrackingContext = {
  Meta: MetaContext
}

export const getMetaContext = (): MetaContext => {
  return {
    loggedAt: Date.now(),
    internalEventId: uuid(),
  }
}
