import qs from "qs"
import { IS_TESTNET } from "@/constants/testnet"

export const MOONPAY_KEY = IS_TESTNET
  ? "pk_test_U2NZxUmZdjbShj9pQbmtQaLEgVduYsj8"
  : "pk_live_IdYWVj39NNgSrt2t9jBPSgwl9outsydU"

const MOONPAY_NFT_KEY = IS_TESTNET
  ? "pk_test_C2RcPTFuJVkbUUcG3KJcl3d3QzdMNu"
  : "pk_live_fzb75PNGOvcKVjftUr0YT7qQssYbUFE"

export const MOONPAY_API_BASE_QS = qs.stringify(
  {
    apiKey: MOONPAY_KEY,
  },
  { encode: false },
)
export const MOONPAY_NFT_API_BASE_QS = qs.stringify(
  {
    apiKey: MOONPAY_NFT_KEY,
  },
  { encode: false },
)

export const MOONPAY_API_BASE_URL = "https://api.moonpay.com"
export const MOONPAY_API_CHECK_IP_URL = `${MOONPAY_API_BASE_URL}/v4/ip_address?${MOONPAY_API_BASE_QS}`

export const MOONPAY_THEME_ID = "94c3acae-4a54-496b-8d95-15bc4ad62e6e"
