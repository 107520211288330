import React, { ElementRef, forwardRef } from "react"
import {
  ButtonProps,
  Button as DSButton,
  ButtonWithIconProps,
  ButtonWithCustomIconProps,
} from "@opensea/ui-kit"
import { Link, LinkProps } from "@/components/common/Link"

export type { ButtonProps, ButtonWithIconProps, ButtonWithCustomIconProps }
export type ButtonSize = ButtonProps["size"]

const RenderLink = (props: LinkProps) => {
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <Link {...props} />
}

export const Button = forwardRef<ElementRef<typeof DSButton>, ButtonProps>(
  function Button(props, ref) {
    return <DSButton renderLink={RenderLink} {...props} ref={ref} />
  },
)
