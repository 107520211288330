import { IS_SERVER } from "@/constants/environment"
import { getWallet } from "@/containers/WalletProvider/wallet"
import Auth from "@/lib/auth"
import type { RelayMetadata } from "./types"

export const getRequestAssociatedAuth = async (
  metadata?: RelayMetadata,
): Promise<RelayMetadata | undefined> => {
  if (IS_SERVER || metadata) {
    return metadata
  }

  const wallet = getWallet()
  const session = await Auth.UNSAFE_getActiveSession()
  return {
    address: wallet.address,
    activeAccountAddress: wallet.activeAccount?.address,
    token: session?.token,
  }
}
