import React from "react"
import { Z_INDEX } from "@/constants/zIndex"
import { MultiStepModal } from "@/design-system/Modal"
import { WalletSelectionModal } from "./components/refresh/WalletSelectionModal.react"
import { WalletModalHeaderButton } from "./components/WalletModalHeaderButton.react"
import { WalletSelectionModalClassic } from "./components/WalletSelectionModalClassic.react"

type Props = {
  children?: React.ReactNode
  isOpen?: boolean
  onClose?: () => void
  showNewLogin: boolean
  isFromMint: boolean
}

export function OSWalletModal({
  children,
  isOpen = false,
  onClose,
  showNewLogin,
  isFromMint,
}: Props) {
  const overrides = {
    Overlay: {
      props: {
        style: {
          zIndex: Z_INDEX.OS_WALLET_POPOVER,
        },
      },
    },
  }
  return showNewLogin ? (
    <MultiStepModal
      focusFirstFocusableElement={false}
      isOpen={isOpen}
      overrides={overrides}
      size="small"
      onClose={onClose}
    >
      {children ?? <WalletSelectionModal isFromMint={isFromMint} />}
    </MultiStepModal>
  ) : (
    <MultiStepModal
      customCloseIcon={
        <WalletModalHeaderButton
          className="absolute right-[18px] top-[18px]"
          icon="close"
        />
      }
      focusFirstFocusableElement={false}
      isOpen={isOpen}
      overrides={overrides}
      size="small"
      onClose={onClose}
    >
      {children ?? <WalletSelectionModalClassic />}
    </MultiStepModal>
  )
}
