import { CookieAttributes } from "js-cookie"
import { IS_TESTNET } from "@/constants/testnet"
import { WALLET_NAME } from "@/constants/wallet"
import { ChainIdentifier } from "@/hooks/useChains/types"
import Cookie from "@/lib/cookie"
import { EXTERNAL_OS_WALLET_COOKIE_DOMAIN } from "./constants"

const COOKIE_KEY = "ext-os-wallet"
const TESTNET_COOKIE_KEY = "ext-os-wallet-testnet"

type OSWalletAccount = {
  address: string
  walletName: WALLET_NAME
}

type OSWalletData = {
  activeAccount: OSWalletAccount | undefined
  activeChain: ChainIdentifier | null | undefined
  installedWallets: WALLET_NAME[]
  theme: "light" | "dark" | undefined
  deviceId: string | undefined
}

// Stores list of previously seen accounts & currently active account
const externalOSWalletDataCookie = new Cookie<OSWalletData>(
  IS_TESTNET ? TESTNET_COOKIE_KEY : COOKIE_KEY,
)

export const setExternalOSWalletData = async (data: OSWalletData) => {
  const cookieOptions: CookieAttributes = {}

  if (EXTERNAL_OS_WALLET_COOKIE_DOMAIN) {
    cookieOptions.domain = EXTERNAL_OS_WALLET_COOKIE_DOMAIN
  }

  externalOSWalletDataCookie.set(data, cookieOptions)
}
