import React, { createContext, useCallback, useContext, useMemo } from "react"
import {
  GraphQLTaggedNode,
  MutationParameters,
  Environment,
} from "relay-runtime"
import { useContextSelector } from "use-context-selector"
import { WalletContext } from "@/containers/WalletProvider/context"
import { MutationOptions, commitMutation } from "./fetch"

type UseMutationOptions<TOperation extends MutationParameters> =
  MutationOptions<TOperation> & {
    shouldAuthenticate?: boolean
    /* Useful for callbacks that need to execute after login and before mutation, e.g. optimistic updates */
    before?: (() => Promise<unknown>) | (() => unknown)
  }

type UseMutateFn = <TOperation extends MutationParameters>(
  mutation: GraphQLTaggedNode,
  variables: TOperation["variables"],
  options?: UseMutationOptions<TOperation>,
) => Promise<TOperation["response"]>

type GraphQLContext = {
  mutate: UseMutateFn
}

const DEFAULT_CONTEXT: GraphQLContext = {
  mutate: async () => {
    return {}
  },
}

const Context = createContext(DEFAULT_CONTEXT)

export const useGraphQL = () => {
  return useContext(Context)
}

type GraphQLProviderProps = {
  children: React.ReactNode
  environment: Environment
}

export const GraphQLProvider = ({
  children,
  environment,
}: GraphQLProviderProps) => {
  const login = useContextSelector(WalletContext, context => context.login)

  const mutate = useCallback(
    async <TOperation extends MutationParameters>(
      mutation: GraphQLTaggedNode,
      variables: TOperation["variables"],
      {
        shouldAuthenticate = false,
        before,
        ...mutationOptions
      }: UseMutationOptions<TOperation> = {},
    ) => {
      const execute = async () => {
        await before?.()
        return commitMutation(environment, mutation, variables, mutationOptions)
      }
      if (!shouldAuthenticate) {
        return execute()
      }
      const isAuthenticated = await login()
      if (!isAuthenticated) {
        throw new Error("Not logged in.")
      }
      return execute()
    },
    [environment, login],
  )

  const value = useMemo(() => ({ mutate }), [mutate])
  return <Context.Provider value={value}>{children}</Context.Provider>
}
