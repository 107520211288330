import React from "react"

type Props = {
  className?: string
  width?: number
  fill?: string
}

export function MonadLogo({ className, fill = "none", width = 24 }: Props) {
  return (
    <svg
      style={{ width, height: width }}
      viewBox="0 0 33 32"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill}
    >
      <path
        d="M16.8163 0C12.1959 0 0.816406 11.3792 0.816406 15.9999C0.816406 20.6206 12.1959 32 16.8163 32C21.4367 32 32.8164 20.6204 32.8164 15.9999C32.8164 11.3794 21.4369 0 16.8163 0ZM14.323 25.1492C12.3746 24.6183 7.13621 15.455 7.66723 13.5066C8.19825 11.5581 17.3614 6.31979 19.3097 6.8508C21.2582 7.38173 26.4966 16.5449 25.9656 18.4934C25.4346 20.4418 16.2714 25.6802 14.323 25.1492Z"
        fill="currentColor"
      />
    </svg>
  )
}
