import React from "react"
import { Icon } from "@opensea/ui-kit"
import { Dropdown } from "@/design-system/Dropdown"
import { useRouter } from "@/hooks/useRouter"
import { LinkWithLocale } from "@/lib/helpers/links"

type LocaleItemProps = LinkWithLocale & {
  onClick: () => unknown
}

export const LocaleItem = ({ label, locale, onClick }: LocaleItemProps) => {
  const { asPath: routerLocalePath, locale: currentLocale } = useRouter()

  return (
    <Dropdown.Item href={routerLocalePath} key={locale} onClick={onClick}>
      <Dropdown.Item.Content>
        <Dropdown.Item.Title>{label}</Dropdown.Item.Title>
      </Dropdown.Item.Content>
      {locale === currentLocale && (
        <Dropdown.Item.Side>
          <Icon className="text-primary" value="check" />
        </Dropdown.Item.Side>
      )}
    </Dropdown.Item>
  )
}
