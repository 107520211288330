import React from "react"
import { Link } from "@/components/common/Link"
import { useChains } from "@/hooks/useChains"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { useTranslate } from "@/hooks/useTranslate"
import { getEthereumChain, isPolygon, isSolana } from "@/lib/helpers/chainUtils"

export const useCurrencyTitles = (
  chain: ChainIdentifier | null | undefined,
): {
  baseCurrencyTitle: string
  wrappedCurrencyTitle: string
  wrappedCurrencyTitleInfo?: React.ReactNode
} => {
  const t = useTranslate("components")
  const { getNativeCurrencySymbol } = useChains()

  if (!chain) {
    return {
      baseCurrencyTitle: t(
        "symbolBalance",
        "{{symbol}} balance",
        {
          symbol: getNativeCurrencySymbol(getEthereumChain()),
        },
        { forceString: true },
      ),
      wrappedCurrencyTitle: t("bridgeOrWrapForm.offerBalance", "Offer balance"),
    }
  }

  if (isPolygon(chain)) {
    return {
      baseCurrencyTitle: t(
        "bridgeOrWrapForm.polygonMainCurrencyTitle",
        "ETH on Ethereum",
      ),
      wrappedCurrencyTitle: t(
        "polygonSecondaryCurrencyTitle",
        "ETH on Polygon",
      ),
    }
  }

  if (isSolana(chain)) {
    return {
      baseCurrencyTitle: t(
        "symbolBalance",
        "{{symbol}} balance",
        {
          symbol: getNativeCurrencySymbol(chain),
        },
        { forceString: true },
      ),
      wrappedCurrencyTitle: t("bridgeOrWrapForm.offerBalance", "Offer balance"),
    }
  }

  return {
    baseCurrencyTitle: t(
      "symbolBalance",
      "{{symbol}} balance",
      {
        symbol: getNativeCurrencySymbol(chain),
      },
      { forceString: true },
    ),
    wrappedCurrencyTitle: t("bridgeOrWrapForm.offerBalance", "Offer balance"),
    wrappedCurrencyTitleInfo: (
      <>
        {t(
          "bridgeOrWrapForm.offerBalanceInfo.wethMakeOffers",
          "WETH can be used to make offers.",
        )}
        <br />
        {t(
          "bridgeOrWrapForm.offerBalanceInfo.wethSmartContract",
          "Wrapping Ethereum is done via a decentralized smart contract. OpenSea doesn't have custody or control over your WETH.",
        )}
        <br />
        <Link href="https://support.opensea.io/articles/8866978">
          {t(
            "forms.collectionForm.creatorEarnings.learnMoreLink",
            "Learn more",
          )}
        </Link>
      </>
    ),
  }
}
