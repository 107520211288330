import { useEffect } from "react"
import { graphql } from "relay-runtime"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { CancelOrderStatusDataPollerQuery } from "@/lib/graphql/__generated__/CancelOrderStatusDataPollerQuery.graphql"
import { fetch } from "@/lib/graphql/graphql"
import { useGaslessCancelOrderToasts } from "../hooks/useGaslessCancelOrderToasts.react"
import { readGaslessCancelResult } from "../utils/readGaslessCancelResult"
import { useDebouncedCancelOrderContext } from "./useDebouncedCancelOrderContext"

export const POLLING_INTERVAL_BEFORE_CHAIN_TIMESTAMP = 10_000
export const POLLING_INTERVAL_AFTER_CHAIN_TIMESTAMP = 2_000

const fetchCancelResult = async (ordersAwaitingFinalization: string[]) => {
  const [
    {
      blockchain: {
        bulkCancelOrders: { statuses },
      },
    },
    queryDisposable,
  ] = await fetch<CancelOrderStatusDataPollerQuery>(
    graphql`
      query CancelOrderStatusDataPollerQuery($orders: [OrderRelayID!]!) {
        blockchain {
          bulkCancelOrders(orders: $orders) {
            statuses {
              ...readGaslessCancelResult_status
              # Order fields are requested so that relay store is updated when cancellation happens
              # eslint-disable-next-line relay/unused-fields
              order {
                relayId
                isCancelled
                isValid
                invalidationReason
                remainingQuantityType
              }
            }
          }
        }
      }
    `,
    {
      orders: ordersAwaitingFinalization,
    },
  )

  const result = readGaslessCancelResult(statuses)
  queryDisposable?.dispose()

  return result
}

export const CancelOrderStatusDataPoller = () => {
  const t = useTranslate("components")
  const { showSuccessMessage, showErrorMessage } = useToasts()
  const { showCancelOrderCompletedToast: showCancelOrderFinalizedToast } =
    useGaslessCancelOrderToasts()

  const [debouncedCancelContext] = useDebouncedCancelOrderContext()

  useEffect(() => {
    const {
      ordersAwaitingFinalization,
      latestFulfillmentPossibleUntilChainTimestamp,
      updateCancellations,
    } = debouncedCancelContext

    if (!latestFulfillmentPossibleUntilChainTimestamp) {
      return // Nothing to poll for
    }

    // At least one order to poll status for
    const checkCancellationStatus = async () => {
      const cancelResult = await fetchCancelResult(
        Array.from(ordersAwaitingFinalization),
      )

      updateCancellations(cancelResult)
    }

    checkCancellationStatus()
  }, [
    debouncedCancelContext,
    showCancelOrderFinalizedToast,
    showErrorMessage,
    showSuccessMessage,
    t,
  ])

  return null
}
