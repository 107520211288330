import { createContext, useContext } from "react"
import { AssetSuccessModalMode } from "@/components/modals/AssetSuccessModalContent.react"
import { AnalyticsTracker } from "./AnalyticsTracker"

type ItemAnalyticsParams = {
  eventSource: string | undefined

  itemId?: string
  assetId?: string
  collectionSlug?: string
  assetContractAddress?: string
  tokenId?: string
  chainId?: string

  usdValue?: string
  tokenQuantity?: string
  symbol?: string
}

type BaseMoonPayNftTxParams = {
  externalTransactionId: string
  widgetUrl: string
  transactionHash?: string
}

type MoonPayNftPurchaseParams = BaseMoonPayNftTxParams & {
  orderId: string
  dropId?: undefined
}

type MoonPayMintNftParams = BaseMoonPayNftTxParams & {
  dropId: string
  orderId?: undefined
}

type MoonPayNftTxParams = MoonPayNftPurchaseParams | MoonPayMintNftParams

type SuccessModalParams = {
  mode: AssetSuccessModalMode
}

type BuyNowParams = {
  quantity: number
  isSubstitutionEnabled: boolean
  maxPricePerItem?: string
}

/**
 * @deprecated Use TrackingContexts and useTrackingFn
 */
export class ItemAnalyticsTracker extends AnalyticsTracker<ItemAnalyticsParams> {
  // Buy now flow
  trackClickBuyNow = (params: BuyNowParams) =>
    this.track("click buy now", params)
  trackClickBuyNowSubstituteItems = (params: BuyNowParams) =>
    this.track("click buy now substitute items", params)
  trackShowCheckoutModalCancelledListingWarning = () =>
    this.track("show checkout modal cancelled listing warning")
  trackOpenCheckoutModal = () => this.track("open checkout modal")
  trackClickConfirmCheckout = () => this.track("click confirm checkout")
  trackNotAvailableForMPCheckoutFromBuyWithCardFilter = () =>
    this.track("not available for MP checkout from buy with card filter")

  trackOpenGiftRecipientInput = () => this.track("open gift recipient input")
  trackClickCheckoutWithGiftRecipient = () =>
    this.track("click checkout with gift recipient")
  trackCheckoutWithGiftRecipientSuccess = () =>
    this.track("checkout with gift recipient success")

  // Buy now flow - Polygon/Multichain specific
  trackOpenFulfillActionModal = () => this.track("open fulfill action modal")
  trackClickSign = () => this.track("click sign")

  // Buy with card flow
  trackClickBuyWithCard = () => this.track("click buy with card")
  trackOpenMoonPayCheckoutModal = (
    params: Omit<MoonPayNftTxParams, "transactionHash">,
  ) => this.track("open moonpay checkout modal", params)
  trackStartMoonPayNftTx = (params: MoonPayNftTxParams) =>
    this.track("start moonpay nft transaction", params)
  trackMoonPayNftTxFail = (params: MoonPayNftTxParams) =>
    this.track("moonpay nft transaction fail", params)
  trackMoonPayNftTxSuccess = (params: MoonPayNftTxParams) =>
    this.track("moonpay nft transaction success", params)
  trackOpenSuccessModal = (params: SuccessModalParams) =>
    this.track("open success modal", params)
  trackClickSuccessModalViewItem = () =>
    this.track("click success modal view item")
  trackClickSuccessModalViewListing = () =>
    this.track("click success modal view listing")
}

export type ItemAnalyticsContext = {
  tracker: ItemAnalyticsTracker
}

const DEFAULT_ITEM_ANALYTICS_CONTEXT: ItemAnalyticsContext = {
  // The default event source identifies the context that should be present to override it
  tracker: new ItemAnalyticsTracker({ eventSource: "ItemAnalytics" }),
}

export const ItemAnalyticsContext = createContext(
  DEFAULT_ITEM_ANALYTICS_CONTEXT,
)

export const useItemAnalyticsContext = () => useContext(ItemAnalyticsContext)
