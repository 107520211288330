import { graphql } from "react-relay"
import { _FragmentRefs } from "relay-runtime"
import { AccountTab } from "@/features/account/types"
import { accounts_url$data } from "@/lib/graphql/__generated__/accounts_url.graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { getChecksumAddress } from "./address"

const readIdentifiers = inlineFragmentize<accounts_url$data>(
  graphql`
    fragment accounts_url on AccountType @inline {
      address
      user {
        publicUsername
      }
    }
  `,
  identifiers => identifiers,
)

export type GetAccountUrlOptions = {
  tab?: AccountTab
  search?: {
    collection?: string
  }
}

export const getAccountUrl = (
  ref: accounts_url$data | _FragmentRefs<"accounts_url">,
  { tab, search: { collection } = {} }: GetAccountUrlOptions = {},
) => {
  const { address, user } = readIdentifiers(ref)
  return `/${user?.publicUsername ?? getChecksumAddress(address)}${
    tab ? `/${tab}` : ""
  }${collection ? `?search[collections][0]=${collection}` : ""}`
}
