/* eslint-disable import/no-duplicates */
import {
  Interval,
  addDays,
  addMilliseconds,
  differenceInDays,
  differenceInMilliseconds,
  format,
  formatDistanceToNowStrict,
  intervalToDuration,
  intlFormat,
  isAfter,
  isBefore,
  isWithinInterval,
  parseISO,
  startOfWeek,
} from "date-fns"
import defaultLocale from "date-fns/locale/en-US"
/* eslint-enable import/no-duplicates */
import { formatInTimeZone } from "date-fns-tz"
import { useInterval, useUpdate } from "react-use"
import { DateRange } from "@/features/orders/hooks/useDateRangeOptions"
import { useTranslate } from "@/hooks/useTranslate"
import { captureNoncriticalError } from "@/lib/sentry"
import { UnreachableCaseError } from "./type"

type Duration = {
  days: number
  hours: number
  minutes: number
  seconds: number
}

export const useI18nDateAtTime = (timestamp: Date, showSeconds?: boolean) => {
  const t = useTranslate("common")
  return t(
    "datetime.dateAtTime",
    "{{date}} at {{time}}",
    {
      date: intlFormat(timestamp, {
        month: "long",
        day: "numeric",
        year: "numeric",
      }),
      time: intlFormat(timestamp, {
        hour: "numeric",
        minute: "2-digit",
        second: showSeconds ? "2-digit" : undefined,
        hour12: true,
      }),
    },
    {
      forceString: true,
    },
  )
}

export const useFromNow = (): ((timestamp: Date | null) => string) => {
  const t = useTranslate("common")

  const formatDistance: Locale["formatDistance"] = (token, count, options) => {
    options = options || {}

    const formatDistanceLocale: Record<string, string> = {
      xSeconds: t(
        "datetime.long.xSeconds",
        {
          0: "{{count}} seconds",
          one: "{{count}} second",
          other: "{{count}} seconds",
        },
        { count },
        { forceString: true },
      ),
      xMinutes: t(
        "datetime.long.xMinutes",
        {
          0: "{{count}} minutes",
          one: "{{count}} minute",
          other: "{{count}} minutes",
        },
        { count },
        { forceString: true },
      ),
      xHours: t(
        "datetime.long.xHours",
        {
          0: "{{count}} hours",
          one: "{{count}} hour",
          other: "{{count}} hours",
        },
        { count },
        { forceString: true },
      ),
      xDays: t(
        "datetime.long.xDays",
        {
          0: "{{count}} days",
          one: "{{count}} day",
          other: "{{count}} days",
        },
        { count },
        { forceString: true },
      ),
      xMonths: t(
        "datetime.long.xMonths",
        {
          0: "{{count}} months",
          one: "{{count}} month",
          other: "{{count}} months",
        },
        { count },
        { forceString: true },
      ),
      xYears: t(
        "datetime.long.xYears",
        {
          0: "{{count}} years",
          one: "{{count}} year",
          other: "{{count}} years",
        },
        { count },
        { forceString: true },
      ),
    }

    const result = formatDistanceLocale[token]
    return options.comparison > 0
      ? t("datetime.in", "in {{result}}", { result }, { forceString: true })
      : t("datetime.ago", "{{result}} ago", { result }, { forceString: true })
  }

  return (timestamp: Date | null) => {
    if (!timestamp) {
      return ""
    }
    try {
      const distanceToNow = formatDistanceToNowStrict(timestamp, {
        locale: { ...defaultLocale, formatDistance },
      })
      return distanceToNow
    } catch (error) {
      captureNoncriticalError(error, { extras: { timestamp } })
      return ""
    }
  }
}

export const useFromNowShort = (): ((timestamp: Date | null) => string) => {
  const t = useTranslate("common")

  const formatDistance: Locale["formatDistance"] = (token, number, options) => {
    options = options || {}

    const formatDistanceLocale: Record<string, string> = {
      xSeconds: t(
        "datetime.short.xSeconds",
        "{{number}}s",
        { number },
        { forceString: true },
      ),
      xMinutes: t(
        "datetime.short.xMinutes",
        "{{number}}m",
        { number },
        { forceString: true },
      ),
      xHours: t(
        "datetime.short.xHours",
        "{{number}}h",
        { number },
        { forceString: true },
      ),
      xDays: t(
        "datetime.short.xDays",
        "{{number}}d",
        { number },
        { forceString: true },
      ),
      xMonths: t(
        "datetime.short.xMonths",
        "{{number}}mo",
        { number },
        { forceString: true },
      ),
      xYears: t(
        "datetime.short.xYears",
        "{{number}}y",
        { number },
        { forceString: true },
      ),
    }

    const result = formatDistanceLocale[token]
    return options.comparison > 0
      ? t("datetime.in", "in {{result}}", { result }, { forceString: true })
      : t("datetime.ago", "{{result}} ago", { result }, { forceString: true })
  }

  return (timestamp: Date | null) => {
    if (!timestamp) {
      return ""
    }
    try {
      const distanceToNow = formatDistanceToNowStrict(timestamp, {
        locale: { ...defaultLocale, formatDistance },
      })
      return distanceToNow
    } catch (error) {
      captureNoncriticalError(error, { extras: { timestamp } })
      return ""
    }
  }
}

export const dateFromISO8601 = (datetime: string): Date => {
  // Interpret strings w/ no tz suffix as UTC (replicate moment.utc() behavior)
  if (/[^-+]\d{2}:\d{2}(\.\d+)?$/.test(datetime)) {
    return parseISO(datetime + "Z")
  }
  return parseISO(datetime)
}

const padSingleDigit = (n: number): string =>
  `${n <= 9 ? "0" : ""}${Math.max(0, n)}`

export const formatDurationAsClock = (duration: Duration) =>
  `${
    duration.days > 0 ? padSingleDigit(duration.days) + ":" : ""
  }${padSingleDigit(duration.hours)}:${padSingleDigit(
    duration.minutes,
  )}:${padSingleDigit(duration.seconds)}`

const getLocalTimezone = (timeZoneName?: "long" | "short"): string => {
  const tz = new Date().toLocaleString("en", { timeZoneName }).split(" ").pop()
  if (tz === undefined) {
    throw new UnreachableCaseError(tz as never)
  }
  return tz
}

export const formatWithShortTimezone = (
  date: Date,
  pattern: string,
): string => {
  return `${format(date, pattern)} ${getLocalTimezone("short")}`
}

export const getTimeZone = () => {
  return format(new Date(), "z")
}

export const getTimeZoneAbbreviation = (
  date: string | number | Date,
  locale = "en-US",
  format?: string,
) => {
  const timeZone = Intl.DateTimeFormat(locale).resolvedOptions().timeZone
  return formatInTimeZone(date, timeZone, format || "zzz")
}

export const isWithinRange = (
  date: Date | number,
  { start, end }: Partial<Interval>,
): boolean => {
  if (start === undefined && end === undefined) {
    return true
  }

  if (start !== undefined && end !== undefined) {
    if (isAfter(start, end)) {
      return false
    }

    return isWithinInterval(date, { start, end })
  }

  if (start !== undefined) {
    return isAfter(date, start)
  }

  if (end !== undefined) {
    return isBefore(date, end)
  }

  return true
}

export const weekdays = () => {
  const firstDOW = startOfWeek(new Date())
  return Array.from(Array(7)).map((_, index) =>
    format(addDays(firstDOW, index), "EEEEEE"),
  )
}

export const adjustPastDateRange = (dateRange: DateRange): DateRange => {
  const now = new Date()
  if (isBefore(dateRange.start, now)) {
    const timeDiff = differenceInMilliseconds(now, dateRange.start)
    const updatedEndDate = addMilliseconds(dateRange.end, timeDiff)
    return {
      start: now,
      end: updatedEndDate,
    }
  }
  return dateRange
}

export const getDuration = (startTime: string, endTime: string): Duration => {
  const { hours, minutes, seconds } = intervalToDuration({
    start: new Date(startTime),
    end: new Date(endTime),
  })
  // we have to get the days separately because the intervalToDuration function
  // will return months and years too, so the days is just the remaining days
  const days = differenceInDays(new Date(endTime), new Date(startTime))

  return {
    days: days || 0,
    hours: hours || 0,
    minutes: minutes || 0,
    seconds: seconds || 0,
  }
}

export const useFromNowDurationShort = (targetDate: Date) => {
  const now = new Date()
  const t = useTranslate("common")
  const update = useUpdate()

  useInterval(update, 1000)

  const formatDuration = (duration: Duration) =>
    `${duration.days > 0 ? `${duration.days}d ` : ""}${
      duration.hours > 0 ? `${duration.hours}h ` : ""
    }${duration.minutes > 0 ? `${duration.minutes}m` : ""}`.trim()

  if (isBefore(now, targetDate)) {
    const duration = getDuration(now.toISOString(), targetDate.toISOString())

    return t(
      "datetime.duration.in",
      "in {{result}}",
      {
        result: formatDuration(duration),
      },
      { forceString: true },
    )
  }

  const duration = getDuration(targetDate.toISOString(), now.toISOString())

  return t(
    "datetime.duration.ago",
    "{{result}} ago",
    {
      result: formatDuration(duration),
    },
    { forceString: true },
  )
}
