/**
 * @generated SignedSource<<a3f2a0922de6b472162e7d4dfe5e1fd6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Config = "AFFILIATE" | "AFFILIATE_BLACKLISTED" | "AFFILIATE_REQUESTED" | "EMPLOYEE" | "MODERATOR" | "PARTNER" | "STAFF" | "VERIFIED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CollectionTooltip_ownerInfo$data = {
  readonly address: string;
  readonly config: Config | null;
  readonly isCompromised: boolean;
  readonly user: {
    readonly publicUsername: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"wallet_accountKey">;
  readonly " $fragmentType": "CollectionTooltip_ownerInfo";
};
export type CollectionTooltip_ownerInfo$key = {
  readonly " $data"?: CollectionTooltip_ownerInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionTooltip_ownerInfo">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionTooltip_ownerInfo",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCompromised",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "config",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserType",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publicUsername",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "wallet_accountKey",
      "selections": [
        (v0/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "AccountType",
  "abstractKey": null
};
})();

(node as any).hash = "8f1a2d6c0c8ddd5c9b46863275e6f67f";

export default node;
