/* eslint-disable tailwindcss/no-custom-classname */
import React from "react"
import { useFragment } from "react-relay"
import { AssetMediaAnimation_asset$key } from "@/lib/graphql/__generated__/AssetMediaAnimation_asset.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { isYouTubeUrl } from "@/lib/helpers/urls"
import { YOUTUBE_LOGO_IMG } from "../../../constants"
import { AssetMediaContainer } from "./AssetMediaContainer.react"
import {
  AssetMediaImage,
  AssetMediaImageBaseProps,
} from "./AssetMediaImage.react"
import { AssetMediaPlaceholderImage } from "./AssetMediaPlaceholderImage.react"

type AssetMediaAnimationProps = {
  asset: AssetMediaAnimation_asset$key
  autoPlay?: boolean
  animationUrl: string
  imageConfig: AssetMediaImageBaseProps
  imagePreviewUrl?: string
}

export const AssetMediaAnimation = ({
  asset: assetKey,
  animationUrl,
  autoPlay,
  imageConfig,
  imagePreviewUrl,
}: AssetMediaAnimationProps) => {
  const asset = useFragment(
    graphql`
      fragment AssetMediaAnimation_asset on AssetType {
        ...AssetMediaImage_asset
        ...AssetMediaContainer_asset
        ...AssetMediaPlaceholderImage_asset
      }
    `,
    assetKey,
  )

  const showVideo = autoPlay || !imagePreviewUrl
  const isYouTube = isYouTubeUrl(animationUrl)

  if (showVideo) {
    return (
      <div className="AssetMedia--animation" style={{ position: "relative" }}>
        {isYouTube ? (
          // TODO(guscost-opensea): Previously this rendered the YouTube
          // video inline, but between iframe sandboxing rules,
          // youtube.com's open redirect vulnerability, and CORE-3527,
          // there is no expedient way to do this safely.
          <AssetMediaContainer asset={asset}>
            <AssetMediaPlaceholderImage
              alt="YouTube Video"
              asset={asset}
              url={YOUTUBE_LOGO_IMG}
            />
          </AssetMediaContainer>
        ) : (
          // eslint-disable-next-line jsx-a11y/iframe-has-title
          <iframe
            // NOTICE!!!!! -0 SECURITY CRITICAL AREA -- DO NOT ADD any capabilities to
            // this IFrame without discussing with OpenSea Security first
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            // allowFullScreen - Removed and not premitted given that it allows NFTs to
            // pretend to be OpenSea.io and enables phishing attacks
            className="w-full"
            frameBorder="0"
            height="100%"
            id="AssetMedia--frame"
            sandbox="allow-scripts"
            src={animationUrl}
            style={{ minHeight: "100px" }}
          />
        )}
      </div>
    )
  }

  // showVideo being falsey => imagePreviewUrl is truthy
  return (
    <div className="AssetMedia--animation">
      <AssetMediaImage {...imageConfig} asset={asset} url={imagePreviewUrl} />
    </div>
  )
}
