/**
 * @generated SignedSource<<b0a3397882e2ab89724edfb4b0fde221>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useHandleBlockchainActions_cancel_all_orders$data = {
  readonly method: {
    readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_transaction">;
  };
  readonly " $fragmentType": "useHandleBlockchainActions_cancel_all_orders";
};
export type useHandleBlockchainActions_cancel_all_orders$key = {
  readonly " $data"?: useHandleBlockchainActions_cancel_all_orders$data;
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_cancel_all_orders">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useHandleBlockchainActions_cancel_all_orders"
};

(node as any).hash = "2b9273ccc677764d9be7800e41c696b8";

export default node;
