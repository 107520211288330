import { useIsLessThanLg, useIsLessThanMd } from "@opensea/ui-kit"

export const useNavbarIconSize = () => {
  const isLessThenMd = useIsLessThanMd()
  const isLessThanLg = useIsLessThanLg()

  if (isLessThenMd) {
    return 28
  }

  if (isLessThanLg) {
    return 28
  }

  return 24
}
