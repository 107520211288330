import React from "react"
import { graphql, useFragment } from "react-relay"
import { _FragmentRefs } from "relay-runtime"
import { Item } from "@/design-system/Item"
import { CollectionOfferDetails } from "@/features/orders/components/OfferModal/components/CollectionOfferDetails"
import { ConfirmationItem, ConfirmationItemProps } from "@/features/sell"
import { useTranslate } from "@/hooks/useTranslate"
import { OrderDataHeader_criteriaAsset$key } from "@/lib/graphql/__generated__/OrderDataHeader_criteriaAsset.graphql"
import { OrderDataHeader_getOrderDataFromActions$data } from "@/lib/graphql/__generated__/OrderDataHeader_getOrderDataFromActions.graphql"
import { OrderDataHeader_order$key } from "@/lib/graphql/__generated__/OrderDataHeader_order.graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { dateFromISO8601 } from "@/lib/helpers/datetime"
import { bn, displayUSD } from "@/lib/helpers/numberUtils"
import { UnreachableCaseError } from "@/lib/helpers/type"

type Props = {
  dataKey: OrderDataHeader_order$key
  criteriaAsset?: OrderDataHeader_criteriaAsset$key
  fulfillOrderItemFillAmount?: string
  isLoading?: boolean
  transactionHash?: string
  renderContentExtra?: () => React.ReactNode
  renderAdditionalContentExtra?: () => React.ReactNode
  renderExtra?: () => React.ReactNode
}

const readOrderDataFromActions =
  inlineFragmentize<OrderDataHeader_getOrderDataFromActions$data>(
    graphql`
      fragment OrderDataHeader_getOrderDataFromActions on BlockchainActionType
      @inline {
        ... on CreateOrderActionType {
          orderData {
            ...OrderDataHeader_order
          }
        }
        ... on FulfillOrderActionType {
          orderData {
            ...OrderDataHeader_order
          }
        }
      }
    `,
    identifiers => identifiers,
  )

export const getOrderDataFromActions = (
  actions: ReadonlyArray<
    | OrderDataHeader_getOrderDataFromActions$data
    | _FragmentRefs<"OrderDataHeader_getOrderDataFromActions">
  >,
) => {
  return actions
    .map(action => readOrderDataFromActions(action).orderData)
    .find(orderData => orderData)
}

export const OrderDataHeader = ({
  dataKey,
  criteriaAsset: criteriaAssetDataKey,
  fulfillOrderItemFillAmount,
  isLoading,
  transactionHash,
  renderContentExtra,
  renderAdditionalContentExtra,
  renderExtra,
}: Props) => {
  const data = useFragment(
    graphql`
      fragment OrderDataHeader_order on OrderDataType {
        item {
          __typename
          ... on AssetQuantityDataType {
            asset {
              ...ConfirmationItem_assets
            }
            quantity
          }
        }
        recipient {
          address
        }
        side
        openedAt
        closedAt
        perUnitPrice {
          unit
        }
        price {
          unit
          symbol
          usd
        }
        payment {
          ...ConfirmationItem_payment_asset
        }
        englishAuctionReservePrice {
          unit
        }
        isCounterOrder
        orderCriteria {
          collection {
            ...CollectionOfferDetails_collection
          }
          trait {
            traitType
            value
          }
          quantity
        }
      }
    `,
    dataKey,
  )

  const t = useTranslate("components")

  const criteriaAsset = useFragment(
    graphql`
      fragment OrderDataHeader_criteriaAsset on AssetType {
        ...ConfirmationItem_assets
      }
    `,
    criteriaAssetDataKey ?? null,
  )

  const reservedBuyerAddress =
    data.side === "ASK" ? data.recipient?.address : undefined

  const currentPrice = bn(data.perUnitPrice.unit)

  const reservePrice = data.englishAuctionReservePrice
    ? bn(data.englishAuctionReservePrice.unit)
    : undefined

  const duration = {
    start: dateFromISO8601(data.openedAt),
    end: dateFromISO8601(data.closedAt),
  }

  let assets: ConfirmationItemProps["assetDataKey"] = []
  let quantity = fulfillOrderItemFillAmount
    ? bn(fulfillOrderItemFillAmount)
    : bn(1)

  if (data.item) {
    switch (data.item.__typename) {
      case "AssetQuantityDataType":
        {
          assets = [data.item.asset]

          if (!fulfillOrderItemFillAmount) {
            quantity = bn(data.item.quantity)
          }
        }
        break
      case "%other":
        throw new UnreachableCaseError(data.item.__typename as never)
    }
  } else if (criteriaAsset) {
    assets = [criteriaAsset]
  } else if (data.orderCriteria) {
    return (
      <CollectionOfferDetails
        className="p-0"
        collection={data.orderCriteria.collection}
        quantity={
          data.orderCriteria.quantity ? Number(data.orderCriteria.quantity) : 1
        }
        renderPriceSide={() => (
          <>
            <Item.Description>
              {t("blockchain.orderDataHeader.yourOffer", "Your offer")}
            </Item.Description>
            <Item.Title>
              {data.price.unit} {data.price.symbol}
            </Item.Title>
            <Item.Description>
              {`$${displayUSD(data.price.usd)}`}
            </Item.Description>
          </>
        )}
        trait={
          data.orderCriteria.trait?.value
            ? {
                key: data.orderCriteria.trait.traitType,
                value: data.orderCriteria.trait.value,
              }
            : undefined
        }
      />
    )
  }

  if (assets.length === 0) {
    return null
  }

  return (
    <ConfirmationItem
      assetDataKey={assets}
      confirmationDetails={{
        reservedBuyerAddress,
        price: currentPrice,
        reservePrice,
        duration,
        quantity,
      }}
      isLoading={isLoading}
      paymentAssetDataKey={data.payment}
      renderAdditionalContentExtra={renderAdditionalContentExtra}
      renderContentExtra={renderContentExtra}
      renderExtra={renderExtra}
      transactionHash={transactionHash}
      variant="unstyled"
    />
  )
}
