/**
 * @generated SignedSource<<e728436be1c1fcc71c43be6b26b53aaf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BannedAccountWalletButtonQuery$variables = {
  address: string;
};
export type BannedAccountWalletButtonQuery$data = {
  readonly getAccount: {
    readonly isEmbeddedWallet: boolean;
    readonly isWalletBanned: boolean;
  };
};
export type BannedAccountWalletButtonQuery = {
  response: BannedAccountWalletButtonQuery$data;
  variables: BannedAccountWalletButtonQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "address"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "address",
    "variableName": "address"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isWalletBanned",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isEmbeddedWallet",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BannedAccountWalletButtonQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "getAccount",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BannedAccountWalletButtonQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "getAccount",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "93ee7a65f5509573836b3b15b4476848",
    "id": null,
    "metadata": {},
    "name": "BannedAccountWalletButtonQuery",
    "operationKind": "query",
    "text": "query BannedAccountWalletButtonQuery(\n  $address: AddressScalar!\n) {\n  getAccount(address: $address) {\n    isWalletBanned\n    isEmbeddedWallet\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "48f7f2423020ed3647046e5a3cf3292c";

export default node;
