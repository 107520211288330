import { useState } from "react"
import { IS_SERVER } from "@/constants/environment"
import { useMountEffect } from "./useMountEffect"

// https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API

export function usePageVisibility() {
  const [isHidden, setIsHidden] = useState<boolean>(
    IS_SERVER ? false : document.visibilityState === "hidden",
  )

  const browserCompatApi = () => {
    let hidden, visibilityChange
    if ("hidden" in document) {
      hidden = "hidden"
      visibilityChange = "visibilitychange"
    } else if ("mozHidden" in document) {
      // Firefox up to v17
      hidden = "mozHidden"
      visibilityChange = "mozvisibilitychange"
    } else if ("webkitHidden" in document) {
      // Chrome up to v32, Android up to v4.4, Blackberry up to v10
      hidden = "webkitHidden"
      visibilityChange = "webkitvisibilitychange"
    }
    return {
      hidden,
      visibilityChange,
    }
  }

  useMountEffect(() => {
    const { hidden, visibilityChange } = browserCompatApi()

    if (!hidden || !visibilityChange) {
      throw new Error("Page Visibility API not found in this browser")
    }
    setIsHidden(document.visibilityState === "hidden")
    const cb = () => setIsHidden(document.visibilityState === "hidden")
    document.addEventListener(visibilityChange, cb)

    return () => {
      document.removeEventListener(visibilityChange, cb)
    }
  })
  return !isHidden
}
