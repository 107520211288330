import type { ModalSize } from "./types"

export const MODAL_HEADER_TOP_PADDING = 32
export const MOBILE_MODAL_HEADER_Y_PADDING = 24
export const MODAL_HEADER_Y_PADDING_WITHOUT_TITLE = 28
export const MODAL_HEADING_TITLE_Y_PADDING = 0
export const MOBILE_MODAL_PADDING = 16
export const MODAL_PADDING = 24
export const CENTER_ALIGNED_MODAL_PADDING = 48
export const CENTER_ALIGNED_MOBILE_MODAL_PADDING = 32

const DIALOG_MARGIN = 16
export const AXIS_MARGIN = 2 * DIALOG_MARGIN

export const SIZE_MAPPINGS: Record<ModalSize, number> = {
  xsmall: 360,
  small: 420,
  medium: 550,
  large: 700,
  xlarge: 1000,
}
