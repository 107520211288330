import {
  ADMIN_URL,
  GRAPHQL_API_URL,
  REST_API_URL,
  SUBSCRIPTION_API_URL,
} from "../constants/api"

const API = {
  getSsrApiKey: () => process.env.SSR_API_KEY,
  getGraphqlUrl: () => GRAPHQL_API_URL,
  getRestUrl: () => REST_API_URL,
  getAdminUrl: () => ADMIN_URL,
  getSubscriptionUrl: () => SUBSCRIPTION_API_URL,
}

export default API
