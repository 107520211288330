import { useElementState } from "@/hooks/useElementState"

export function useHover<T extends Element>({
  ignoreDisabled,
}: {
  ignoreDisabled?: boolean
} = {}) {
  return useElementState<T>({
    on: "mouseover",
    off: "mouseleave",
    ignoreDisabled,
  })
}
