/**
 * @generated SignedSource<<6275a11a687da3d1fe443b700b486721>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useHandleBlockchainActions_approve_payment_asset$data = {
  readonly method: {
    readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_transaction">;
  };
  readonly " $fragmentType": "useHandleBlockchainActions_approve_payment_asset";
};
export type useHandleBlockchainActions_approve_payment_asset$key = {
  readonly " $data"?: useHandleBlockchainActions_approve_payment_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_approve_payment_asset">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useHandleBlockchainActions_approve_payment_asset"
};

(node as any).hash = "97b37ebec4198fe9d0c7130873d0aa08";

export default node;
