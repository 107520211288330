import { getTrackingFn } from "@/lib/analytics/utils"

export const trackJWTInvalidExpirationDate = getTrackingFn<{
  jwtExpiration: string
}>("jwt - invalid expiration date")

export const trackAuthRefreshDueToUnauthenticated = getTrackingFn<{
  refreshed: boolean
}>("auth refresh due to unauthenticated")

export const trackAuthRefreshDueToExpiredToken = getTrackingFn<{
  refreshed: boolean
}>("auth refresh due to expired token")

export const trackLegacyAuthLoginMutation = getTrackingFn(
  "legacy auth login mutation",
)
export const trackAuthLoginMutation = getTrackingFn(
  "simplified auth login mutation",
)
export const trackAuthLoginMutationError = getTrackingFn<{
  authSimplificationEnabled: boolean
}>("auth login mutation error")

export const trackAuthLogout = getTrackingFn<{
  count: number
}>("auth logout")

export const trackAuthLogoutMaxAuthenticated = getTrackingFn<{
  count: number
  success: boolean
}>("auth logout - max authenticated count reached")

export const trackOs2AuthLoginMutation = getTrackingFn(
  "os to os2 auth login mutation",
)

export const trackOs2AuthLoginMutationError = getTrackingFn(
  "os to os2 auth login mutation error",
)
