import React, { useMemo } from "react"
import { ConnectCompatibleWallet } from "@/components/nav/WalletSidebar/ConnectCompatibleWallet"
import { getWalletConfiguration } from "@/constants/wallet"
import { useWalletModal } from "@/containers/WalletModalProvider.react"
import {
  useActiveAccount,
  useWallet,
} from "@/containers/WalletProvider/WalletProvider.react"
import { Modal } from "@/design-system/Modal"
import { useChains } from "@/hooks/useChains"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useIsOpen } from "@/hooks/useIsOpen"
import { useTranslate } from "@/hooks/useTranslate"

type Props = {
  chainIdentifier?: ChainIdentifier
  children: (_: {
    handleIfNotSupported: (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fn: (...args: any[]) => unknown,
    ) => (...args: unknown[]) => void
  }) => React.ReactNode
  onNotLoggedIn?: () => unknown
  shouldAuthenticate?: boolean
  isFromMint?: boolean
}

export const NetworkUnsupportedGate = ({
  chainIdentifier,
  children,
  onNotLoggedIn,
  shouldAuthenticate,
  isFromMint,
}: Props) => {
  const { getChainName, getChain } = useChains()
  const t = useTranslate("modals")
  const { provider, login, isAuthenticated } = useWallet()
  const { startWalletModalAuthFlow } = useWalletModal()
  const viewer = useActiveAccount()
  const { isOpen, open, close } = useIsOpen()
  const walletName = provider?.getName()

  const isOnSupportedWallet = useMemo(() => {
    if (!chainIdentifier) {
      return true
    }

    return walletName
      ? getWalletConfiguration(walletName).supportsChain(
          getChain(chainIdentifier),
        )
      : false
  }, [chainIdentifier, getChain, walletName])

  const handleIfNotSupported =
    (fn: (...args: unknown[]) => unknown) =>
    async (...args: unknown[]) => {
      if (!viewer || !walletName) {
        startWalletModalAuthFlow(
          shouldAuthenticate
            ? async () => {
                const authenticated = await login()
                if (authenticated) {
                  fn()
                }
              }
            : fn,
          undefined,
          isFromMint,
        )
        onNotLoggedIn?.()
        return
      }

      if (!isOnSupportedWallet) {
        open()
        return
      } else {
        close()
      }

      if (shouldAuthenticate && !isAuthenticated) {
        const authenticated = await login()
        if (!authenticated) {
          return
        }
      }

      fn(...args)
    }

  return (
    <>
      {children({ handleIfNotSupported })}

      <Modal focusFirstFocusableElement={false} isOpen={isOpen} onClose={close}>
        {() =>
          chainIdentifier ? (
            <>
              <Modal.Header>
                <Modal.Header.Title>
                  {t(
                    "networkUnsupported.connectWallet.title",
                    "Connect a wallet that supports {{chainName}}",
                    { chainName: getChainName(chainIdentifier) },
                  )}
                </Modal.Header.Title>
              </Modal.Header>

              <Modal.Body>
                <ConnectCompatibleWallet
                  chainIdentifier={chainIdentifier}
                  showWalletAnnotation={false}
                  source="network unsupported gate"
                />
              </Modal.Body>
            </>
          ) : null
        }
      </Modal>
    </>
  )
}
