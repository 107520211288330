import React, { forwardRef, memo } from "react"
import styled from "styled-components"
import { EscrowFundsInfo } from "@/components/nav/WalletSidebar/EscrowFundsInfo.react"
import { Item as ItemComponent } from "@/design-system/Item"
import { ItemSkeleton } from "@/design-system/ItemSkeleton"
import { List } from "@/design-system/List"
import { Tooltip } from "@/design-system/Tooltip"
import { useChains } from "@/hooks/useChains"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import {
  getSymbolDisplay,
  SOLANA_ESCROW_TOKEN_SYMBOL,
} from "@/lib/helpers/chainUtils"
import {
  bn,
  normalizePriceDisplay,
  displayUSD,
} from "@/lib/helpers/numberUtils"
import { selectClassNames } from "@/lib/helpers/styling"
import { themeVariant } from "@/styles/styleUtils"

export type FundItemProps = {
  symbol: string
  chain: ChainIdentifier
  name?: string | null
  description?: React.ReactNode
  quantity?: string
  image?: string | null
  usdPrice?: string | null
  className?: string
  onClick?: () => unknown
  actions?: React.ReactNode
  Item?: typeof ItemComponent | typeof List.Item
}

const FundItemBase = forwardRef<HTMLDivElement | HTMLLIElement, FundItemProps>(
  function FundItem(
    {
      Item = ItemComponent,
      actions,
      symbol,
      chain,
      description,
      name,
      quantity,
      image,
      usdPrice,
      className,
      onClick,
    },
    ref,
  ) {
    const { getChainName } = useChains()

    const getDescription = () => {
      if (description) {
        return description
      } else if (symbol === SOLANA_ESCROW_TOKEN_SYMBOL) {
        return <EscrowFundsInfo />
      }
      return getChainName(chain)
    }

    return (
      <DivContainer>
        <Item
          className={className}
          ref={
            ref as React.ForwardedRef<HTMLDivElement> &
              React.ForwardedRef<HTMLLIElement>
          }
          onClick={onClick}
        >
          {image && (
            <Item.Avatar
              alt=""
              className={selectClassNames("FundItem", {
                "eth-icon": symbol === "ETH" && chain === "ETHEREUM",
              })}
              src={image}
            />
          )}
          <Item.Content>
            <Tooltip content={name}>
              <Item.Title className="w-fit cursor-pointer">
                {getSymbolDisplay(symbol)}
              </Item.Title>
            </Tooltip>

            {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
            {(description || chain) && (
              <Item.Description>{getDescription()}</Item.Description>
            )}
          </Item.Content>
          {quantity ? (
            <Item.Side>
              <Tooltip
                content={
                  normalizePriceDisplay(quantity) !== quantity
                    ? parseFloat(quantity)
                    : undefined
                }
              >
                <Item.Title>{normalizePriceDisplay(quantity)}</Item.Title>
              </Tooltip>
              {usdPrice && (
                <Item.Description>
                  ${displayUSD(bn(usdPrice).times(bn(quantity)))} USD
                </Item.Description>
              )}
            </Item.Side>
          ) : null}
          {actions && <Item.Action>{actions}</Item.Action>}
        </Item>
      </DivContainer>
    )
  },
)

const FundItemSkeleton = memo(function FundItemSkeleton({
  actions,
}: Partial<FundItemProps>) {
  return (
    <ItemSkeleton>
      <ItemSkeleton.Avatar rounded />
      <ItemSkeleton.Content>
        <ItemSkeleton.Title />
        <ItemSkeleton.Description />
      </ItemSkeleton.Content>

      <ItemSkeleton.Side>
        <ItemSkeleton.Title />
        <ItemSkeleton.Description />
      </ItemSkeleton.Side>

      {actions && <ItemSkeleton.Action className="h-6 w-6" />}
    </ItemSkeleton>
  )
})

const DivContainer = styled.div`
  .FundItem--eth-icon {
    ${themeVariant({
      variants: {
        dark: {
          filter: "brightness(3)",
        },
      },
    })}
  }
`

export const FundItem = Object.assign(FundItemBase, {
  Skeleton: FundItemSkeleton,
})
