import React, { useRef } from "react"
import { CenterAligned, Text } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { Image } from "@/design-system/Image"
import { useSize } from "@/hooks/useSize"
import { useTranslate } from "@/hooks/useTranslate"
import { AssetMediaPlaceholderImage_asset$key } from "@/lib/graphql/__generated__/AssetMediaPlaceholderImage_asset.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { resizeImage } from "@/lib/helpers/urls"

type AssetMediaPlaceholderImageProps = {
  alt?: string
  asset: AssetMediaPlaceholderImage_asset$key
  url: string
}

export const AssetMediaPlaceholderImage = ({
  alt,
  asset: assetKey,
  url,
}: AssetMediaPlaceholderImageProps) => {
  const t = useTranslate("components")
  const asset = useFragment(
    graphql`
      fragment AssetMediaPlaceholderImage_asset on AssetType {
        collection {
          displayData {
            cardDisplayStyle
          }
        }
      }
    `,
    assetKey,
  )

  const cardDisplayStyle =
    asset.collection.displayData.cardDisplayStyle ?? "CONTAIN"

  const placeholderSizeRef = useRef<HTMLDivElement>(null)
  const [placeholderWidth] = useSize(placeholderSizeRef)

  const objectFit =
    cardDisplayStyle === "COVER"
      ? "cover"
      : cardDisplayStyle === "CONTAIN"
      ? "contain"
      : undefined

  const computedAnimationFrameUrl = resizeImage(url, {
    freezeAnimation: true,
  })

  const background = {
    backgroundImage: `url(${computedAnimationFrameUrl})`,
  }

  const itemSizing =
    placeholderWidth < 50
      ? 35
      : placeholderWidth < 130
      ? 60
      : placeholderWidth < 300
      ? 85
      : 150

  const showText = placeholderWidth > 130

  return (
    <CenterAligned
      className="relative w-full rounded-[inherit] bg-base-1"
      ref={placeholderSizeRef}
    >
      <PlaceholderBackground style={background} />
      <CenterAligned className="absolute h-full">
        <PlaceholderImage
          alt={alt}
          height={itemSizing}
          objectFit={objectFit}
          src={url}
          width={itemSizing}
        />
        {showText && (
          <Text
            asChild
            className="w-full px-2.5 text-center text-secondary"
            size="tiny"
            style={{
              textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
          >
            <p>
              {t(
                "assets.placeholder.contentNotAvailable",
                "Content not available yet",
              )}
            </p>
          </Text>
        )}
      </CenterAligned>
    </CenterAligned>
  )
}

const PlaceholderBackground = styled.div`
  background-size: cover;
  background-position: center;
  background-repeat: none;
  border-radius: inherit;
  width: 100%;
  /* Setting the height and padding here makes the background a square */
  height: 0px;
  padding: 50% 0;
  filter: blur(30px);
  mask: linear-gradient(
    0deg,
    rgba(53, 56, 64, 1) 0%,
    rgba(53, 56, 64, 0.4) 100%
  );
  /* Setting overflow hidden to stop blur effect bleeding into background on mobile */
  overflow: hidden;
`

const PlaceholderImage = styled(Image)`
  border-radius: ${props => props.theme.borderRadius.circle};
`
