import React, { useMemo } from "react"
import { useIsLessThanXXl, useTheme, UnstyledButton } from "@opensea/ui-kit"
import { rgba } from "polished"
import styled, { css } from "styled-components"
import { Z_INDEX } from "@/constants/zIndex"
import { useTranslate } from "@/hooks/useTranslate"
import { media, themeVariant } from "@/styles/styleUtils"
import { ButtonProps } from "../Button"
import { Image } from "../Image"
import type { ExperimentalCarouselVariantType } from "./Carousel.react"

type ArrowDirection = "back" | "forward"
const CAROUSEL_ARROW_ICON_DARK_SMALL =
  "/static/images/icons/chevron-os-dark-small.svg"
const CAROUSEL_ARROW_ICON_DARK_LARGE =
  "/static/images/icons/chevron-os-dark-large.svg"
const CAROUSEL_ARROW_ICON_LIGHT_SMALL =
  "/static/images/icons/chevron-os-light-small.svg"
const CAROUSEL_ARROW_ICON_LIGHT_LARGE =
  "/static/images/icons/chevron-os-light-large.svg"

export const ArrowButton = ({
  className,
  direction,
  carouselVariant,
  height,
}: {
  direction: ArrowDirection
  carouselVariant?: ExperimentalCarouselVariantType
} & Partial<ButtonProps> & { height?: number }) => {
  const t = useTranslate("components")
  const { theme } = useTheme()
  const isLessThanXXLargeScreen = useIsLessThanXXl()
  const previousSlideLabel = t("carousel.previous", "Previous slide")
  const nextSlideLabel = t("carousel.next", "Next slide")

  const arrowIconSrc = useMemo(() => {
    const isDarkStyles =
      carouselVariant === "spotlight" ||
      carouselVariant === "item-card" ||
      carouselVariant === "drop-carousel"

    if (theme === "dark" || isDarkStyles) {
      return isLessThanXXLargeScreen
        ? CAROUSEL_ARROW_ICON_DARK_SMALL
        : CAROUSEL_ARROW_ICON_DARK_LARGE
    }
    return isLessThanXXLargeScreen
      ? CAROUSEL_ARROW_ICON_LIGHT_SMALL
      : CAROUSEL_ARROW_ICON_LIGHT_LARGE
  }, [isLessThanXXLargeScreen, theme, carouselVariant])

  return (
    <StyledArrowControlContainer
      $direction={direction}
      $variant={carouselVariant}
      aria-label={direction === "back" ? previousSlideLabel : nextSlideLabel}
      className={className}
      style={{ height }}
    >
      <Image
        alt={direction === "back" ? previousSlideLabel : nextSlideLabel}
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className="arrow_icon"
        layout={carouselVariant === "drop-carousel" ? "fixed" : "fill"}
        width={carouselVariant === "drop-carousel" ? 24 : undefined}
        height={carouselVariant === "drop-carousel" ? 24 : undefined}
        priority
        src={arrowIconSrc}
        style={{ rotate: direction === "back" ? "180deg" : "0" }}
      />
    </StyledArrowControlContainer>
  )
}

const barButtonStyles = (
  direction: ArrowDirection,
  variant: ExperimentalCarouselVariantType,
) => css`
  height: 100%;
  background: ${props => props.theme.colors.components.background.transparent};

  &:hover {
    background: ${props => props.theme.colors.components.background.gray2};
    box-shadow: none;
  }

  ${props =>
    variant === "spotlight" &&
    css`
      ${themeVariant({
        variants: {
          light: {
            // TODO: Add to theme as gray3
            background: rgba(props.theme.colors.gray3, 0.2),

            "&:hover": {
              // TODO: Add to theme as gray4
              background: rgba(props.theme.colors.gray3, 0.4),
            },
          },

          dark: {
            background: props.theme.colors.components.background.transparent,

            "&:hover": {
              background: props.theme.colors.components.background.gray2,
            },
          },
        },
      })}
    `}

  ${direction === "back"
    ? css`
        left: -4px;
        transform: translate(-100%, -50%);
      `
    : css`
        right: -4px;
        transform: translate(100%, -50%);
      `}

  ${media({
    xs: css`
      display: flex;
      width: 24px;
      border-radius: ${props => props.theme.borderRadius.medium};
    `,
    xxl: css`
      width: 40px;
      border-radius: ${props => props.theme.borderRadius.large};
    `,
  })}
`

const overlayButtonStyles = (
  direction: ArrowDirection,
  variant: ExperimentalCarouselVariantType,
) => css`
  height: 100%;
  width: 64px;

  ${variant === "item-card" &&
  css`
    width: 40px;
  `}

  ${direction === "back"
    ? css`
        ${media({
          xs: css`
            left: 0;
          `,
          sm: css`
            left: 0;
          `,
        })}
        background: ${props =>
          props.theme.gradients.carousel.overlay.back.default};
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: ${props => props.theme.borderRadius.large};
        border-bottom-left-radius: ${props => props.theme.borderRadius.large};

        ${variant === "item-card" &&
        css`
          border-bottom-left-radius: 0;
        `}

        &:hover {
          background: ${props =>
            props.theme.gradients.carousel.overlay.back.hover};
        }
      `
    : css`
        ${media({
          xs: css`
            right: 0;
          `,
          sm: css`
            right: 0;
          `,
        })}
        background: ${props =>
          props.theme.gradients.carousel.overlay.forward.default};
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: ${props => props.theme.borderRadius.large};
        border-bottom-right-radius: ${props => props.theme.borderRadius.large};

        ${variant === "item-card" &&
        css`
          border-bottom-right-radius: 0;
        `}

        &:hover {
          background: ${props =>
            props.theme.gradients.carousel.overlay.forward.hover};
        }
      `}
`

const StyledArrowControlContainer = styled(UnstyledButton)<{
  $direction: "back" | "forward"
  $variant?: ExperimentalCarouselVariantType
}>`
  position: absolute;
  top: 50%;
  z-index: ${Z_INDEX.ACCOUNT_HEADER_SHADOW};
  box-shadow: none;
  justify-content: center;
  transform: translateY(-50%);

  &&&& {
    transition: all 0.2s linear;
  }

  ${props =>
    props.$variant === "default" &&
    css`
      ${media({
        xs: css`
          display: none;
        `,
        sm: barButtonStyles(props.$direction, props.$variant),
      })}
    `}

  ${props =>
    props.$variant === "card" &&
    css`
      ${media({
        sm: barButtonStyles(props.$direction, props.$variant),
      })}
    `}

  ${props =>
    props.$variant === "spotlight" &&
    css`
      ${media({
        sm: barButtonStyles(props.$direction, props.$variant),
      })}
    `}

 ${props =>
    (props.$variant === "drop-carousel" || props.$variant === "item-card") &&
    overlayButtonStyles(props.$direction, props.$variant)}

  &:disabled {
    opacity: 0;
    cursor: auto;
    pointer-events: none;
  }
`
