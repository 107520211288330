import React, { useCallback } from "react"
import { CenterAligned, FlexColumn, Text } from "@opensea/ui-kit"
import { useLazyLoadQuery } from "react-relay"
import { IFrame } from "@/components/common/IFrame.react"
import { ModalLoader } from "@/components/common/ModalLoader.react"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { Block } from "@/design-system/Block"
import { Modal } from "@/design-system/Modal"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import {
  MoonPayEventParams,
  MoonPayTxEventParams,
  trackMoonPayTxFail,
  trackMoonPayTxSuccess,
  trackOpenMoonPayModal,
  trackStartMoonPayTx,
} from "@/lib/analytics/events/checkoutEvents"
import { MoonPayTopupModalQuery } from "@/lib/graphql/__generated__/MoonPayTopupModalQuery.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"
import { UnreachableCaseError } from "@/lib/helpers/type"
import { IS_TESTNET } from "../../../../constants/testnet"
import { TxStatus, MoonPayApiTransaction } from "../../utils/MoonPayTx/types"
import { getCurrencyCode } from "../../utils/MoonPayTx/utils"
import { MoonPayAttribution } from "../MoonPayAttribution.react"
import {
  MOONPAY_STAGING_SUPPORTED_CHAIN,
  MOONPAY_STAGING_SUPPORTED_CURRENCY_CODE,
  MOONPAY_STAGING_SUPPORTED_CURRENCY_SYMBOL,
} from "./constants"
import { useMoonPayTxStatus } from "./useMoonPayTxStatus"
import { useOnWalletBalanceIncrease } from "./useOnWalletBalanceIncrease"

export type MoonPayModalProps = {
  symbol?: string
  chain?: ChainIdentifier
  currencyCode?: string
  fiatCurrency?: string
  fiatValue?: BigNumber
  onDone?: () => unknown
  isCheckout?: boolean
  renderTabMenu?: () => React.ReactNode
}

export const LazyMoonPayTopupModal = ({
  symbol,
  chain,
  currencyCode,
  fiatCurrency,
  fiatValue,
  onDone,
  isCheckout = false,
  renderTabMenu,
}: MoonPayModalProps) => {
  const { onPrevious } = useMultiStepFlowContext()
  const t = useTranslate("moonpay")

  const data = useLazyLoadQuery<MoonPayTopupModalQuery>(
    graphql`
      query MoonPayTopupModalQuery($currencyCode: String, $usdAmount: String) {
        moonpay {
          cryptoWidgetData(currencyCode: $currencyCode, usdAmount: $usdAmount) {
            externalTransactionId
            url
          }
        }
      }
    `,
    {
      currencyCode,
      usdAmount: fiatValue?.toFixed(2),
    },
  )
  const { externalTransactionId, url: widgetUrl } =
    data.moonpay.cryptoWidgetData

  const baseAnalyticsParams: MoonPayEventParams = {
    symbol,
    chain,
    fiatCurrency,
    fiatValue: fiatValue?.toNumber(),
    externalTransactionId,
    widgetUrl,
    isCheckout,
  }

  useMountEffect(() => {
    trackOpenMoonPayModal(baseAnalyticsParams)
  })

  const onTxStatusChange = useCallback(
    (
      txStatus: TxStatus,
      {
        baseCurrencyAmount,
        usdRate,
        cryptoTransactionId,
        baseCurrencyId,
        currencyId,
        quoteCurrencyAmount,
      }: MoonPayApiTransaction,
    ) => {
      const analyticsParams: MoonPayTxEventParams = {
        ...baseAnalyticsParams,
        usdAmount: bn(baseCurrencyAmount).times(usdRate).toNumber(),
        cryptoTransactionId,
        baseCurrencyId,
        currencyId,
        quoteCurrencyAmount,
      }
      switch (txStatus) {
        case "not started":
          return
        case "pending":
          return trackStartMoonPayTx(analyticsParams)
        case "successful":
          return trackMoonPayTxSuccess(analyticsParams)
        case "failed":
          return trackMoonPayTxFail(analyticsParams)
        default:
          throw new UnreachableCaseError(txStatus)
      }
    },
    // ignore changes to baseAnalyticsParams
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const { status } = useMoonPayTxStatus({
    externalTransactionId,
    onTxStatusChange,
  })

  const onTxCompleted = useCallback(() => {
    onDone?.()
  }, [onDone])

  useOnWalletBalanceIncrease({
    onWalletBalanceIncrease: onTxCompleted,
    symbol,
    chain,
  })

  return (
    <>
      <Modal.Header onPrevious={onPrevious}>
        <Modal.Header.Title className="text-center">
          {t("topUpModal.title", "Buy crypto with card")}
        </Modal.Header.Title>
      </Modal.Header>

      {renderTabMenu?.()}

      <Modal.Body>
        {(status === "pending" ||
          status === "waitingAuthorization" ||
          status === "completed") && (
          <CenterAligned className="text-center">
            <Text asChild>
              <p>
                {t(
                  "topUpModal.progress",
                  "Sit tight. It may take a few minutes for the funds to reach your wallet.",
                )}
              </p>
            </Text>
          </CenterAligned>
        )}
        <Block height="500px">
          <IFrame
            allow="accelerometer; autoplay; camera; gyroscope; payment"
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className="MoonpayModal--iframe"
            url={widgetUrl}
          />
        </Block>
      </Modal.Body>
      <Modal.Footer>
        <FlexColumn>
          <MoonPayAttribution />
        </FlexColumn>
      </Modal.Footer>
    </>
  )
}

export const MoonPayTopupModal = ({
  symbol,
  chain,
  ...props
}: Omit<MoonPayModalProps, "currencyCode">) => {
  // If in testnets, override these props with currency that MoonPay sandbox supports
  const testnetsOverriddenProps = IS_TESTNET
    ? {
        symbol: MOONPAY_STAGING_SUPPORTED_CURRENCY_SYMBOL,
        chain: MOONPAY_STAGING_SUPPORTED_CHAIN,
        currencyCode: MOONPAY_STAGING_SUPPORTED_CURRENCY_CODE,
      }
    : {
        symbol,
        chain,
        currencyCode: getCurrencyCode(symbol, chain),
      }

  return (
    <SsrSuspense fallback={<ModalLoader />}>
      <LazyMoonPayTopupModal {...props} {...testnetsOverriddenProps} />
    </SsrSuspense>
  )
}
