import { useState } from "react"
import BigNumber from "bignumber.js"
import { useInterval } from "react-use"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useIsMountedRef } from "./useIsMounted"

const DEFAULT_INTERVAL = 3_000

export const useWalletBalance = ({
  symbol = "ETH",
  chain,
  interval = DEFAULT_INTERVAL,
  skip,
}: {
  symbol?: string
  chain?: ChainIdentifier
  interval?: number
  skip?: boolean
}) => {
  const { wallet } = useWallet()
  const [balance, setBalance] = useState<BigNumber>()
  const isMountedRef = useIsMountedRef()

  useMountEffect(() => {
    fetchWalletBalance()
  })

  const fetchWalletBalance = async () => {
    if (skip) {
      return
    }
    const balance = await wallet.getBalanceBySymbol(symbol, chain)
    if (isMountedRef.current) {
      setBalance(balance)
    }
  }

  useInterval(fetchWalletBalance, interval)

  return balance
}
