import React, { useMemo, useRef, useState } from "react"
import {
  Icon,
  UnstyledButton,
  Separator,
  Text,
  InlineFlex,
  Spinner,
} from "@opensea/ui-kit"
import { sortBy, uniqBy } from "lodash"
import { useClickAway } from "react-use"
import styled from "styled-components"
import { CopyAddress } from "@/components/common/CopyAddress.react"
import {
  StyledList,
  StyledListItem,
} from "@/components/nav/WalletPopover/elements"
import { getWalletConfiguration } from "@/constants/wallet"
import {
  useConnectedAddress,
  useWallet,
} from "@/containers/WalletProvider/WalletProvider.react"
import { Avatar } from "@/design-system/Avatar"
import { Dropdown } from "@/design-system/Dropdown"
import { useIsOpen } from "@/hooks/useIsOpen"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import Provider from "@/lib/chain/provider"
import { useRefreshFunds } from "./useRefreshFunds"
import { useWalletSwitcherTracking } from "./useWalletSwitcherTracking"

export const WalletSwitcher = () => {
  const t = useTranslate("components")
  const {
    trackClickRefreshFunds,
    trackClickWalletSwitcherLogout,
    trackClickWalletSwitcherOption,
    trackCloseWalletSwitcher,
    trackOpenWalletSwitcher,
  } = useWalletSwitcherTracking()

  const connectedAddress = useConnectedAddress()
  const { showSuccessMessage } = useToasts()
  const {
    providers,
    provider: activeProvider,
    switchProvider,
    logout,
  } = useWallet()

  const switcherRef = useRef<HTMLDivElement>(null)
  const getReferenceClientRect = () => {
    return switcherRef.current?.getBoundingClientRect() ?? {}
  }

  const {
    isOpen: isDropdownOpen,
    open: openDropdown,
    close: closeDropdown,
    toggle: toggleDropdown,
  } = useIsOpen()
  useClickAway(switcherRef, closeDropdown)

  const onClickLogout = async () => {
    trackClickWalletSwitcherLogout()
    await logout()
    showSuccessMessage(
      t("logoutSuccess", "You have been logged out successfully."),
    )
  }

  const [isRefreshingFunds, setIsRefreshingFunds] = useState(false)
  const refreshFunds = useRefreshFunds()
  const onClickRefreshFunds = async () => {
    setIsRefreshingFunds(true)
    trackClickRefreshFunds()
    await refreshFunds()
    setIsRefreshingFunds(false)
  }

  const onClickProvider = async (provider: Provider) => {
    trackClickWalletSwitcherOption({ walletName: provider.getName() })
    await switchProvider(provider)
    closeDropdown()
  }

  const walletConfiguration = activeProvider
    ? getWalletConfiguration(activeProvider.getName())
    : undefined

  const uniqueProvidersByName = useMemo(
    () =>
      uniqBy(
        sortBy(providers, provider => (provider === activeProvider ? 0 : 1)),
        p => p.getName(),
      ),
    [activeProvider, providers],
  )

  if (!connectedAddress) {
    throw new Error("WalletSwitcher should not be rendered when not connected")
  }

  return (
    <InlineFlex
      className="items-center"
      data-testid="WalletSwitcher"
      ref={switcherRef}
    >
      {walletConfiguration && (
        <UnstyledButton onClick={toggleDropdown}>
          <Avatar alt="" size={36} src={walletConfiguration.alternativeLogo} />
        </UnstyledButton>
      )}
      <Text.Body weight="semibold">
        <StyledCopyAddress address={connectedAddress} />
      </Text.Body>
      <Dropdown
        content={() => (
          <StyledList className="pt-3" showBorder={false}>
            {uniqueProvidersByName.map(provider => {
              const walletName = provider.getName()
              const isActive = provider === activeProvider
              const logoSrc = getWalletConfiguration(
                provider.getName(),
              ).alternativeLogo
              return (
                <StyledListItem
                  key={walletName}
                  onClick={
                    !isActive
                      ? async () => await onClickProvider(provider)
                      : undefined
                  }
                >
                  <StyledListItem.Avatar alt="" size={36} src={logoSrc} />
                  <StyledListItem.Content className="mr-[30px]">
                    <Text.Body
                      className="text-primary"
                      size="medium"
                      weight="semibold"
                    >
                      {walletName}
                    </Text.Body>
                  </StyledListItem.Content>
                  {isActive && (
                    <StyledListItem.Side>
                      <Icon size={20} value="check" />
                    </StyledListItem.Side>
                  )}
                </StyledListItem>
              )
            })}
            <Separator />
            <StyledListItem onClick={onClickRefreshFunds}>
              <StyledListItem.Avatar icon="refresh" />
              <StyledListItem.Content>
                <Text.Body weight="semibold">
                  {t("refreshFunds", "Refresh funds")}
                </Text.Body>
              </StyledListItem.Content>
              {isRefreshingFunds && (
                <StyledListItem.Side>
                  <Spinner />
                </StyledListItem.Side>
              )}
            </StyledListItem>
            <StyledListItem onClick={onClickLogout}>
              <StyledListItem.Avatar icon="logout" />
              <StyledListItem.Content>
                <Text.Body weight="semibold">
                  {t("logout", "Log Out")}
                </Text.Body>
              </StyledListItem.Content>
            </StyledListItem>
          </StyledList>
        )}
        getReferenceClientRect={getReferenceClientRect} // use entire switcher rect as reference
        minWidth="215px"
        visible={isDropdownOpen}
        onHidden={() => trackCloseWalletSwitcher()}
        onShown={() => trackOpenWalletSwitcher()}
        onTrigger={openDropdown}
        onUntrigger={closeDropdown}
      >
        <DropdownTrigger
          aria-label={
            isDropdownOpen
              ? t("walletSwitcher.close", "Close")
              : t("walletSwitcher.open", "Open")
          }
          onClick={toggleDropdown}
        >
          <Icon value={isDropdownOpen ? "expand_less" : "expand_more"} />
        </DropdownTrigger>
      </Dropdown>
    </InlineFlex>
  )
}

const StyledCopyAddress = styled(CopyAddress)`
  border-radius: ${props => props.theme.borderRadius.squircle};
  padding: 8px 0 8px 8px;
  font-weight: 600;
  margin-left: 4px;

  :hover,
  :focus {
    background-color: ${props =>
      props.theme.colors.components.background.gray1};
    padding-right: 8px;
  }
`

const DropdownTrigger = styled(UnstyledButton)`
  margin-left: 4px;
  border-radius: ${props => props.theme.borderRadius.circle};

  transition: background-color 0.2s ease-out;

  :hover,
  :focus {
    background-color: ${props =>
      props.theme.colors.components.background.gray1};
    padding: 6px;
  }
`
