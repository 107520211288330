import React, { useCallback, useState } from "react"
import { graphql, useFragment } from "react-relay"
import NumericInput from "@/components/forms/NumericInput.react"
import { Block } from "@/design-system/Block"
import { useChains } from "@/hooks/useChains"
import { useTranslate } from "@/hooks/useTranslate"
import { AskForSwapAction_data$key } from "@/lib/graphql/__generated__/AskForSwapAction_data.graphql"
import { bn, ETH_DECIMALS } from "@/lib/helpers/numberUtils"
import { useTransaction } from "../useTransaction"
import {
  BaseBlockchainActionProps,
  BlockchainActionModalContent,
} from "./BlockchainActionModalContent.react"
import { useBlockchainActionProgress } from "./useBlockchainActionProgress"
import { useHandleBlockchainActions } from "./useHandleBlockchainActions"

type Props = BaseBlockchainActionProps & {
  dataKey: AskForSwapAction_data$key
}

export const AskForSwapAction = ({ dataKey, onEnd }: Props) => {
  const t = useTranslate("components")
  const data = useFragment(
    graphql`
      fragment AskForSwapAction_data on AskForSwapType {
        __typename
        fromAsset {
          chain {
            identifier
          }
          decimals
          symbol
        }
        toAsset {
          chain {
            identifier
          }
          symbol
        }
        minQuantity
        maxQuantity
        ...useHandleBlockchainActions_ask_for_asset_swap
      }
    `,
    dataKey,
  )

  const isBridgeAction =
    data.fromAsset.chain.identifier !== data.toAsset.chain.identifier

  const { askForAssetSwap } = useHandleBlockchainActions()
  const { pollTransaction } = useTransaction()
  const { getChainName } = useChains()

  // TODO: This is copied from legacy Action panel and needs to be cleaned up
  const decimals = data.fromAsset.decimals ?? ETH_DECIMALS
  const minAmount = bn(data.minQuantity, decimals)

  const [amount, setAmount] = useState<string | undefined>(minAmount.toString())
  const [amountValue, setAmountValue] = useState(minAmount.toString())
  const swapChainName = getChainName(data.toAsset.chain.identifier)
  const maxAmount = data.maxQuantity

  const fromAssetSymbol = data.fromAsset.symbol
  const toAssetSymbol = data.toAsset.symbol

  const title = isBridgeAction
    ? t(
        "blockchain.swap.deposit.title",
        "Deposit {{fromAssetSymbol}} into {{swapChainName}}",
        { fromAssetSymbol, swapChainName },
      )
    : t("blockchain.swap.convert.title", "Convert your {{fromAssetSymbol}}", {
        fromAssetSymbol,
      })

  const text = isBridgeAction
    ? t(
        "blockchain.swap.deposit.description",
        "Once you deposit {{fromAssetSymbol}} into {{swapChainName}}, you won’t need to pay a transaction fee again for future {{swapChainName}} purchases with your deposited {{fromAssetSymbol}}.",
        { fromAssetSymbol, swapChainName },
      )
    : t(
        "blockchain.swap.convert.description",
        "For compatibility, please convert your {{fromAssetSymbol}} into wrapped {{fromAssetSymbol}} ({{toAssetSymbol}}).",
        { fromAssetSymbol, toAssetSymbol },
      )

  const executeAction = useCallback(async () => {
    if (!amount) {
      throw new Error("Please enter an amount")
    }
    const transaction = await askForAssetSwap({ amount, dataKey: data })

    await pollTransaction({
      transactionHash: transaction.transactionHash,
      chain: transaction.chain,
      showMessageOnSuccess: true,
    })

    onEnd()
  }, [amount, askForAssetSwap, data, onEnd, pollTransaction])

  const { attemptAction } = useBlockchainActionProgress({
    executeAction,
    action: data,
  })

  return (
    <BlockchainActionModalContent>
      <BlockchainActionModalContent.Header>
        <BlockchainActionModalContent.Title>
          {title}
        </BlockchainActionModalContent.Title>
      </BlockchainActionModalContent.Header>
      <BlockchainActionModalContent.Body>
        <BlockchainActionModalContent.Body.Text>
          {text}
          {/* TODO: This is copied from legacy Action panel and needs to be cleaned up */}
          <Block marginTop="16px">
            <NumericInput
              inputValue={amountValue}
              isRequired
              max={maxAmount ? bn(maxAmount, decimals) : undefined}
              maxDecimals={decimals}
              min={minAmount}
              placeholder={t("blockchain.swap.amount", "Amount")}
              value={amount}
              onChange={({ inputValue, value }) => {
                setAmount(value)
                setAmountValue(inputValue)
              }}
            >
              <Block margin="0 16px">{fromAssetSymbol}</Block>
            </NumericInput>
          </Block>
        </BlockchainActionModalContent.Body.Text>
        <BlockchainActionModalContent.Body.Button
          disabled={!amount}
          onClick={attemptAction}
        >
          {t("blockchain.swap.cta", "Continue")}
        </BlockchainActionModalContent.Body.Button>
      </BlockchainActionModalContent.Body>
    </BlockchainActionModalContent>
  )
}
