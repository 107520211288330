import React from "react"
import { Text } from "@opensea/ui-kit"
import { Link } from "@/components/common/Link"
import { useTranslate } from "@/hooks/useTranslate"

export const GaslessCancelLearnMoreLink = () => {
  const t = useTranslate("components")

  return (
    <Text.Body className="whitespace-pre" size="medium">
      <Link href="https://support.opensea.io/articles/8866980">
        {t("gaslessCancel.learnMore", "Learn more")}
      </Link>
    </Text.Body>
  )
}
