/**
 * @generated SignedSource<<4503470c4d3934c32a8d7a85a0d8c383>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VerificationStatus = "MINTABLE" | "SAFELISTED" | "UNAPPROVED" | "VERIFIED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type GlobalCollectionCard_data$data = {
  readonly banner: string | null;
  readonly logo: string | null;
  readonly name: string;
  readonly verificationStatus: VerificationStatus;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionCardContextMenu_data" | "GlobalStatsSubtitle_data" | "collection_url">;
  readonly " $fragmentType": "GlobalCollectionCard_data";
};
export type GlobalCollectionCard_data$key = {
  readonly " $data"?: GlobalCollectionCard_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"GlobalCollectionCard_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showContextMenu"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showStats"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GlobalCollectionCard_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "banner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "verificationStatus",
      "storageKey": null
    },
    {
      "condition": "showStats",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GlobalStatsSubtitle_data"
        }
      ]
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "collection_url",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCategory",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "condition": "showContextMenu",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CollectionCardContextMenu_data"
        }
      ]
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "5e5e417d75fb86b1713ea0d7f4dee6c9";

export default node;
