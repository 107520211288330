import React, { Suspense, useEffect, useState } from "react"
import {
  Icon,
  Text,
  Spinner,
  CenterAligned,
  Alert,
  Flex,
} from "@opensea/ui-kit"
import { isFunction, noop } from "lodash"
import qs from "qs"
import { useLazyLoadQuery } from "react-relay"
import styled from "styled-components"
import { StackedAssetMedia } from "@/components/assets/StackedAssetMedia"
import { useTransaction } from "@/components/blockchain/useTransaction"
import { Link } from "@/components/common/Link"
import { ModalLoader } from "@/components/common/ModalLoader.react"
import { TextCopier } from "@/components/common/TextCopier.react"
import { Z_INDEX } from "@/constants/zIndex"
import { useLocationContext } from "@/context/location"
import { Avatar } from "@/design-system/Avatar"
import { Block } from "@/design-system/Block"
import { useTheme } from "@/design-system/Context"
import { IconButton } from "@/design-system/IconButton"
import { Modal } from "@/design-system/Modal"
import { Tooltip } from "@/design-system/Tooltip"
import { useChains } from "@/hooks/useChains"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useFlag } from "@/hooks/useFlag"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useRefetch } from "@/hooks/useRefetch"
import { useTranslate } from "@/hooks/useTranslate"
import { useItemAnalyticsContext } from "@/lib/analytics/ItemAnalyticsContext"
import { AssetSuccessModalContentOrderQuery } from "@/lib/graphql/__generated__/AssetSuccessModalContentOrderQuery.graphql"
import {
  AssetSuccessModalContentQuery,
  AssetSuccessModalContentQuery$data,
} from "@/lib/graphql/__generated__/AssetSuccessModalContentQuery.graphql"
import { clearCache } from "@/lib/graphql/environment/middlewares/cacheMiddleware"
import { fetch, graphql } from "@/lib/graphql/graphql"
import { truncateAddress } from "@/lib/helpers/address"
import { getAssetUrl } from "@/lib/helpers/asset"
import { isSolana } from "@/lib/helpers/chainUtils"
import { getCollectionUrl } from "@/lib/helpers/collection"
import { getSocialIcon } from "@/lib/helpers/icons"
import { poll } from "@/lib/helpers/promise"
import { Values } from "@/lib/helpers/type"
import { captureNoncriticalError } from "@/lib/sentry"
import { OPENSEA_TWITTER_HANDLE } from "../../constants"
import { AssetMedia } from "../assets/AssetMedia"

const MODES = {
  BOUGHT: "bought",
  SOLD: "sold",
  LISTED: "listed",
  TRANSFERRED: "transferred",
  FROZEN: "frozen",
  CREATED: "created",
  OFFERED: "offered",
} as const

export type AssetSuccessModalMode = Values<typeof MODES>

export type AssetSuccessModalContentProps =
  LazyAssetSuccessModalContentProps & {
    orderId?: string
  }

type LazyAssetSuccessModalContentProps = Omit<
  AssetSuccessModalContentBaseProps,
  "data"
> & {
  variables: AssetSuccessModalContentQuery["variables"]
}

type AssetSuccessModalContentBaseProps = {
  didTransactionFail?: boolean
  data: AssetSuccessModalContentQuery$data
  mode: AssetSuccessModalMode
  transaction?: {
    blockExplorerLink?: string
    transactionHash: string
  } | null
  giftRecipientAddress?: string
  itemName?: string
  itemUrl?: string
  onTransactionConfirmed?: () => unknown
  onClose?: () => unknown
}

const TRANSACTION_CONFIRMED_DELAY = 10000
const POLL_DELAY = 1000

const LazyAssetSuccessModalContent = ({
  variables,
  ...rest
}: LazyAssetSuccessModalContentProps) => {
  const data = useLazyLoadQuery<AssetSuccessModalContentQuery>(
    graphql`
      query AssetSuccessModalContentQuery($assetIDs: [AssetRelayID!]!) {
        assets(assets: $assetIDs) {
          displayName
          assetContract {
            chain
          }
          collection {
            ...collection_url
            name
          }
          ...asset_url
          ...AssetMedia_asset
          ...StackedAssetMedia_assets
        }
      }
    `,
    variables,
  )

  return <AssetSuccessModalContentBase {...rest} data={data} />
}

const AssetSuccessModalContentBase = ({
  didTransactionFail: didTransactionInitiallyFail,
  mode,
  transaction,
  data,
  itemName,
  itemUrl,
  giftRecipientAddress,
  onTransactionConfirmed,
  onClose,
}: AssetSuccessModalContentBaseProps) => {
  const { tracker } = useItemAnalyticsContext()
  const t = useTranslate("modals")
  const [didTransactionFail, setDidTransactionFail] = useState(
    didTransactionInitiallyFail ?? false,
  )
  const { pollTransaction } = useTransaction()
  const showSolanaCongestionAlert = useFlag("enable_solana_congestion_alert")
  const refetch = useRefetch()
  const [isTransactionConfirmed, setIsTransactionConfirmed] = useState(false)
  const chain = data.assets[0].assetContract.chain
  const transactionHash = transaction?.transactionHash
  const { origin } = useLocationContext()

  useEffect(() => {
    const poll = async () => {
      if (transactionHash && !isTransactionConfirmed) {
        try {
          const result = await pollTransaction({ transactionHash, chain })
          if (result?.status) {
            setIsTransactionConfirmed(true)
            onTransactionConfirmed?.()
            // We refetch to refresh ownerships after an arbitrary delay as there will be some time between the block being confirmed
            // and then being picked up by our watchers
            // TODO: We should get rid of this in favour of store updates
            setTimeout(() => {
              clearCache()
              refetch()
            }, TRANSACTION_CONFIRMED_DELAY)
          } else if (result && !result.status) {
            setDidTransactionFail(true)
          }
        } catch (error) {
          setDidTransactionFail(true)
          captureNoncriticalError(error)
        }
      }
    }
    poll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isTransactionConfirmed, refetch, transactionHash, chain])
  const asset = data.assets[0]

  const assetUrl = itemUrl ?? getAssetUrl(asset)
  const collectionUrl = getCollectionUrl(asset.collection)
  const assetSellUrl = getAssetUrl(asset, "sell")
  const fullAssetUrl = `${origin}${assetUrl}`

  const assetLinkContent = (
    <Link href={assetUrl}>{itemName || asset.displayName}</Link>
  )

  const collectionLinkContent = (
    <Link href={collectionUrl}>{asset.collection.name}</Link>
  )

  const giftRecipientLinkContent = (
    <Link href={`/${giftRecipientAddress}`} target="_blank">
      {giftRecipientAddress}
    </Link>
  )

  const transactionTitleText = isTransactionConfirmed
    ? t("assetSuccess.isComplete", "is complete!")
    : t("assetSuccess.isProcessing", "is processing...")

  const transactionProcessingText = t(
    "assetSuccess.confirmedBlockain",
    "It should be confirmed on the blockchain shortly.",
  )

  const itemSubject = t("assetSuccess.item", "item")

  const isSuccessState =
    (isTransactionConfirmed || !transactionHash) && !didTransactionFail

  const title = {
    created: t("assetSuccess.created", `You created {{item}}!`, {
      item: itemName || asset.displayName,
    }),
    bought: t(
      "assetSuccess.yourPurchase",
      `Your purchase {{transactionTitleText}}`,
      {
        transactionTitleText,
      },
    ),
    sold: t("assetSuccess.sale.default", `Your sale {{transactionTitleText}}`, {
      transactionTitleText,
    }),
    listed: isSuccessState
      ? t("assetSuccess.listed", `Your {{itemSubject}} has been listed!`, {
          itemSubject,
        })
      : t("assetSuccess.listing.default", `Listing your {{itemSubject}}...`, {
          itemSubject,
        }),
    offered: isSuccessState
      ? t("assetSuccess.offerMade", "Your offer has been made!")
      : t("assetSuccess.offerMaking", "Making your offer..."),
    transferred: t(
      "assetSuccess.transfer.default",
      `Your transfer {{transactionTitleText}}`,
      {
        transactionTitleText,
      },
    ),
    frozen: isTransactionConfirmed ? (
      <>{t("assetSuccess.metadataFrozen", "Your item's metadata is frozen!")}</>
    ) : (
      <>{t("assetSuccess.metadataFreezing", "Freezing metadata for item...")}</>
    ),
  }[mode]

  const subtitle = {
    bought: isTransactionConfirmed ? (
      <>
        {t(
          "assetSuccess.asset.purchase.success",
          `You purchased {{assetLinkContent}} from the {{collectionLinkContent}} collection.`,
          { assetLinkContent, collectionLinkContent },
        )}

        {giftRecipientAddress && (
          <>
            {" "}
            {t(
              "assetSuccess.gift.success",
              `It was automatically sent to {{giftRecipientLinkContent}}.`,
              { giftRecipientLinkContent },
            )}
          </>
        )}
      </>
    ) : (
      <>
        {t(
          "assetSuccess.purchase.progress",
          `Your purchase of {{assetLinkContent}} is processing. {{transactionProcessingText}}`,
          { assetLinkContent, transactionProcessingText },
        )}
      </>
    ),

    created: (
      <>
        {t(
          "assetSuccess.justCreated",
          `You just created {{assetLinkContent}}.`,
          {
            assetLinkContent,
          },
        )}
      </>
    ),

    sold: isTransactionConfirmed ? (
      <>
        {t(
          "assetSuccess.asset.sold.success",
          `You sold {{assetLinkContent}} from the {{collectionLinkContent}} collection.`,
          { assetLinkContent, collectionLinkContent },
        )}
      </>
    ) : (
      <>
        {t(
          "assetSuccess.sale.processing",
          `Your sale of {{itemName}} is processing.
        {{transactionProcessingText}}`,
          {
            itemName: itemName || asset.displayName,
            transactionProcessingText,
          },
        )}
      </>
    ),

    listed: isSuccessState ? (
      <>
        {t(
          "assetSuccess.asset.listing.success",
          `{{assetLinkContent}} from the {{collectionLinkContent}} collection has
        been listed for sale.`,
          { assetLinkContent, collectionLinkContent },
        )}
      </>
    ) : (
      t(
        "assetSuccess.listing.processing",
        `Your listing of {{assetLinkContent}} is processing.
        {{transactionProcessingText}}`,
        { assetLinkContent, transactionProcessingText },
      )
    ),

    offered: isSuccessState
      ? t(
          "assetSuccess.offered.success",
          `You made an offer for {{assetLinkContent}}.`,
          {
            assetLinkContent,
          },
        )
      : t(
          "assetSuccess.offer.progress",
          `Your offer for {{assetLinkContent}} is processing. {{transactionProcessingText}}`,
          { transactionProcessingText },
        ),
    transferred: isTransactionConfirmed
      ? t(
          "assetSuccess.asset.transferr.success",
          `You transferred {{assetLinkContent}}.`,
          {
            assetLinkContent,
          },
        )
      : t(
          "modals.transferProcessing",
          `Your transfer of {{assetLinkContent}} is processing. {{transactionProcessingText}}`,
          { transactionProcessingText, assetLinkContent },
        ),
    frozen: isTransactionConfirmed
      ? t(
          "assetSuccess.frozenMetadata.success",
          "Your asset's metadata has been successfully frozen! It may take a few minutes for this to be reflected on the OpenSea item page.",
        )
      : t(
          "assetSuccess.frozenMetadata.progress",
          "This process can take a few minutes. You can close this modal or keep it open to monitor its progress.",
        ),
  }[mode]

  const boughtFailureText = (
    <>
      {
        (t(
          "assetSuccess.purchase.error",
          `Your purchase of {{assetLinkContent}} failed. This usually happens when gas
      prices increase or because someone purchased the item before you.`,
        ),
        { assetLinkContent })
      }
    </>
  )

  const shareLinkMessage = {
    created: t(
      "assetSuccess.share.created",
      `Check out my new {{itemSubject}} on OpenSea!`,
      { itemSubject },
      { forceString: true },
    ),
    bought: t(
      "assetSuccess.share.bought",
      `Check out my new {{itemSubject}} on OpenSea!`,
      { itemSubject },
      { forceString: true },
    ),
    sold: null,
    listed: t(
      "assetSuccess.share.listed",
      `Check out my {{itemSubject}} listing on OpenSea!`,
      { itemSubject },
      { forceString: true },
    ),
    transferred: null,
    frozen: null,
    offered: null,
  }[mode]

  const assetMedia = didTransactionFail ? (
    <AssetModal.ImageContainerFailed>
      {assetMediaWidth => (
        <AssetMedia asset={data.assets[0]} size={assetMediaWidth} />
      )}
    </AssetModal.ImageContainerFailed>
  ) : (
    <AssetModal.ImageContainerSuccess>
      <StackedAssetMedia assets={data.assets} />
    </AssetModal.ImageContainerSuccess>
  )

  return (
    <>
      <AssetModal.Body
        hasFooter={isSuccessState && ["bought", "listed"].includes(mode)}
      >
        <>
          {transactionHash ? (
            isTransactionConfirmed || didTransactionFail ? (
              assetMedia
            ) : (
              <AssetModal.Loader />
            )
          ) : (
            assetMedia
          )}

          <Modal.Body.Title className="mb-2 mt-6">
            {didTransactionFail
              ? t("modal.transactionFailed", "Transaction failed")
              : title}
          </Modal.Body.Title>

          <AssetModal.Description>
            {didTransactionFail && mode === "bought"
              ? boughtFailureText
              : subtitle}
          </AssetModal.Description>

          {isSolana(chain) && showSolanaCongestionAlert && (
            <AssetModal.Alert>
              {t(
                "modal.requestProcessing",
                `Your request is processing. Delays or failed listings are possible
              due to network congestion.`,
              )}
            </AssetModal.Alert>
          )}

          {transactionHash && (
            <AssetModal.TransactionHash
              chain={chain}
              transactionHash={transactionHash}
            />
          )}
        </>

        {isSuccessState &&
          (mode === "bought" || mode === "listed" || mode === "created") &&
          shareLinkMessage && (
            <AssetModal.Share message={shareLinkMessage} url={fullAssetUrl} />
          )}
      </AssetModal.Body>

      {isSuccessState ? (
        mode === "bought" ? (
          <>
            <AssetModal.Footer>
              {!giftRecipientAddress && (
                <AssetModal.Footer.Button
                  href={assetSellUrl}
                  variant="secondary"
                >
                  {t("assetSuccess.listForSale", "List for sale")}
                </AssetModal.Footer.Button>
              )}
              <AssetModal.Footer.Button
                href={assetUrl}
                onClick={() => {
                  tracker.trackClickSuccessModalViewItem()
                  onClose?.()
                }}
              >
                {t("assetSuccess.viewItem", "View item")}
              </AssetModal.Footer.Button>
            </AssetModal.Footer>
          </>
        ) : mode === "listed" ? (
          <AssetModal.Footer>
            <Modal.Footer.Button
              href={assetUrl}
              onClick={() => {
                tracker.trackClickSuccessModalViewListing()
                onClose?.()
              }}
            >
              {t("assetSuccess.viewListing", "View listing")}
            </Modal.Footer.Button>
          </AssetModal.Footer>
        ) : null
      ) : null}
    </>
  )
}

const AssetModalBase = ({ children }: { children: React.ReactNode }) => {
  return children
}

const AssetModalTitle = ({ children }: { children: React.ReactNode }) => {
  return (
    <Block marginBottom="8px" marginTop="32px">
      <Modal.Body.Title asChild className="text-center">
        <h4>{children}</h4>
      </Modal.Body.Title>
    </Block>
  )
}

const AssetModalBody = ({
  children,
  hasFooter,
}: {
  children: React.ReactNode
  hasFooter: boolean
}) => {
  return (
    <>
      {/* Add empty header here to provide correct padding above body */}
      <Modal.Header />
      <Modal.Body centerAligned marginBottom={hasFooter ? 0 : "48px"}>
        {children}
      </Modal.Body>
    </>
  )
}

const AssetModalDescription = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text.Body asChild className="text-center text-secondary" size="medium">
      <p>{children}</p>
    </Text.Body>
  )
}

const AssetModalAlert = ({ children }: { children: React.ReactNode }) => {
  return (
    <CenterAligned className="mx-auto my-8 max-w-[90%]">
      <Alert>
        <Alert.Icon color="warning" fill={1} value="warning" />
        <Alert.Content>
          <Text.Body asChild className="text-primary" size="medium">
            <p>{children}</p>
          </Text.Body>
        </Alert.Content>
      </Alert>
    </CenterAligned>
  )
}

const AssetModalTransactionHash = ({
  transactionHash,
  chain,
}: {
  transactionHash: string
  chain: ChainIdentifier
}) => {
  const t = useTranslate("modals")
  const { getTransactionUrl } = useChains()
  const blockExplorerLink = getTransactionUrl(chain, transactionHash)
  return (
    <CenterAligned>
      <Text.Body
        className="mt-8 uppercase text-secondary"
        size="small"
        weight="semibold"
      >
        {t("assetSuccess.transactionId", "Transaction ID")}
      </Text.Body>
      <Link href={blockExplorerLink}>{truncateAddress(transactionHash)}</Link>
    </CenterAligned>
  )
}

const AssetModalShare = ({
  url,
  message,
}: {
  url: string
  message: string
}) => {
  const { theme } = useTheme()
  const t = useTranslate("modals")
  const iconFill = theme === "light" ? "charcoal" : "fog"
  const socialIcons = [
    {
      tooltip: "Facebook",
      url: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      icon: getSocialIcon({ name: "facebook", fill: iconFill }),
    },
    {
      tooltip: "Twitter",
      url: `https://twitter.com/intent/tweet?${qs.stringify({
        text: message,
        url,
        via: OPENSEA_TWITTER_HANDLE,
      })}`,
      icon: getSocialIcon({ name: "twitter", fill: iconFill }),
    },
    {
      tooltip: "Telegram",
      url: `https://t.me/share/url?${qs.stringify({
        url,
        text: message,
      })}`,
      icon: getSocialIcon({ name: "telegram", fill: iconFill }),
    },
  ]

  return (
    <CenterAligned>
      <Text.Body
        className="mt-8 uppercase text-secondary"
        size="small"
        weight="semibold"
      >
        {t("assetSuccess.shareTo", "Share to...")}
      </Text.Body>

      <Flex className="gap-1">
        <TextCopier label="Copy link" text={url}>
          {copy => (
            <IconButton onClick={copy}>
              <Icon value="link" />
            </IconButton>
          )}
        </TextCopier>

        {socialIcons.map(({ url, icon, tooltip }) => (
          <Tooltip content={tooltip} hideOnClick key={url}>
            <Link href={url}>
              <IconButton>{icon}</IconButton>
            </Link>
          </Tooltip>
        ))}
      </Flex>
    </CenterAligned>
  )
}

type AssetImageProps = {
  children: React.ReactNode | ((assetMediaWidth: number) => React.ReactNode)
}

const AssetImageContainer = ({
  children,
  assetMediaWidth,
}: AssetImageProps & {
  assetMediaWidth: number
}) => {
  return (
    <Block marginBottom="32px">
      {isFunction(children) ? children(assetMediaWidth) : children}
    </Block>
  )
}

const AssetModalImageContainerFailed = (props: AssetImageProps) => {
  const assetMediaWidth = 92
  return (
    <ImageContainer width={assetMediaWidth}>
      <Block
        className="absolute right-[-18px] top-[-18px]"
        style={{ zIndex: Z_INDEX.ITEM_CARD_SELECT_ICON }}
      >
        <Avatar className="bg-error text-white" icon="close" rounded />
      </Block>
      <AssetImageContainer assetMediaWidth={assetMediaWidth} {...props} />
    </ImageContainer>
  )
}

const AssetModalImageContainerSuccess = (props: AssetImageProps) => {
  const assetMediaWidth = 184

  return (
    <ImageContainer width={assetMediaWidth}>
      <AssetImageContainer assetMediaWidth={assetMediaWidth} {...props} />
    </ImageContainer>
  )
}

const AssetModalLoader = () => {
  return (
    <CenterAligned>
      <Spinner size="medium" />
    </CenterAligned>
  )
}

const AssetModal = Object.assign(AssetModalBase, {
  Body: AssetModalBody,
  Description: AssetModalDescription,
  Alert: AssetModalAlert,
  Share: AssetModalShare,
  TransactionHash: AssetModalTransactionHash,
  ImageContainerFailed: AssetModalImageContainerFailed,
  ImageContainerSuccess: AssetModalImageContainerSuccess,
  Footer: Modal.Footer,
  Loader: AssetModalLoader,
  Title: AssetModalTitle,
})

export const AssetSuccessModalContent = ({
  orderId,
  transaction,
  mode,
  didTransactionFail,
  ...rest
}: AssetSuccessModalContentProps) => {
  const t = useTranslate("modals")
  const [didOrderMatchFail, setDidOrderMatchFail] = useState(didTransactionFail)
  const [ultimateTransaction, setUltimateTransaction] = useState(transaction)
  const [hasNoCounterOrder, setHasNoCounterOrder] = useState(false)
  const [pollCount, setPollCount] = useState(0)

  const { tracker } = useItemAnalyticsContext()
  useMountEffect(() => {
    tracker.trackOpenSuccessModal({ mode })
  })

  useEffect(() => {
    if (orderId && !ultimateTransaction) {
      const { cancel } = poll({
        delay: POLL_DELAY,
        fn: async () => {
          clearCache()
          setPollCount(count => count + 1)
          const [{ orderStatus }] =
            await fetch<AssetSuccessModalContentOrderQuery>(
              graphql`
                query AssetSuccessModalContentOrderQuery(
                  $order: OrderRelayID!
                ) {
                  orderStatus(order: $order) {
                    __typename
                    ... on OrderMatchingFailed {
                      transactionDetails {
                        transactionHash
                      }
                    }
                    ... on OrderMatchingSuccessful {
                      transactionDetails {
                        transactionHash
                      }
                    }
                    ... on OrderMatchingProcessing {
                      message
                    }
                    ... on OrderMatchingNoCounterOrders {
                      message
                    }
                  }
                }
              `,
              { order: orderId as string },
            )

          if (
            orderStatus.__typename === "OrderMatchingFailed" ||
            orderStatus.__typename === "OrderMatchingSuccessful"
          ) {
            if (orderStatus.__typename === "OrderMatchingFailed") {
              setDidOrderMatchFail(true)
            }
            const transactionHash =
              orderStatus.transactionDetails?.transactionHash

            if (transactionHash) {
              setUltimateTransaction({ transactionHash })
            }

            return orderStatus
          } else if (
            orderStatus.__typename === "OrderMatchingNoCounterOrders"
          ) {
            setHasNoCounterOrder(true)
          }
          return undefined
        },
      })
      return cancel
    }

    return noop
  }, [orderId, ultimateTransaction])

  const orderLoadingState = (
    <>
      <Modal.Header />
      <Modal.Body>
        <ModalLoader />
        <Modal.Body.Title>
          {t("assetSuccess.request.progress", "Request in progress... ")}
        </Modal.Body.Title>
        <Text.Body asChild className="text-center text-primary" size="medium">
          <p>
            {pollCount > 30
              ? t(
                  "assetSuccess.request.feedback",
                  "This is taking longer than expected.",
                )
              : ""}
          </p>
        </Text.Body>
      </Modal.Body>
      <Modal.Footer />
    </>
  )

  const lazyContent = (
    <Suspense fallback={orderId ? orderLoadingState : <ModalLoader />}>
      <LazyAssetSuccessModalContent
        mode={mode}
        {...rest}
        didTransactionFail={didOrderMatchFail}
        transaction={ultimateTransaction}
      />
    </Suspense>
  )

  if (orderId) {
    if (hasNoCounterOrder) {
      const orderType =
        mode === "bought"
          ? "offer"
          : mode === "sold"
          ? "listing"
          : "offer or listing"
      return (
        <>
          <Modal.Header />
          <Modal.Body>
            <Modal.Body.Title>
              {t(
                "assetSuccess.problem.title",
                "There was a problem completing your request",
              )}
            </Modal.Body.Title>
            <Block marginY="24px">
              <Text.Body
                asChild
                className="text-center text-primary"
                size="medium"
              >
                <p>
                  {t(
                    "assetSuccess.problem.description",
                    `We weren't able to complete your request. Your {{orderType}} is
              still active and is able to be accepted. Please cancel your
              {{orderType}} if you don't want it to be fulfilled in the future.`,
                    { orderType },
                  )}
                </p>
              </Text.Body>
            </Block>
          </Modal.Body>
        </>
      )
    } else if (ultimateTransaction || didOrderMatchFail) {
      return lazyContent
    }
    return orderLoadingState
  }

  return lazyContent
}

const ImageContainer = styled.div<{ width: number }>`
  border-radius: ${props => props.theme.borderRadius.default};
  max-width: ${props => props.width}px;
  margin: 0 auto;
  position: relative;
`
