/**
 * @generated SignedSource<<aae3feadb23e68fce48c514142dd3f2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type item_url$data = {
  readonly __typename: "AssetBundleType";
  readonly " $fragmentSpreads": FragmentRefs<"bundle_url">;
  readonly " $fragmentType": "item_url";
} | {
  readonly __typename: "AssetType";
  readonly " $fragmentSpreads": FragmentRefs<"asset_url">;
  readonly " $fragmentType": "item_url";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "item_url";
};
export type item_url$key = {
  readonly " $data"?: item_url$data;
  readonly " $fragmentSpreads": FragmentRefs<"item_url">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "item_url"
};

(node as any).hash = "17b374cde23b9cde6b795dce8796edec";

export default node;
