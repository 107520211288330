/* eslint-disable tailwindcss/no-custom-classname */
import React, { useRef, useState } from "react"
import { CenterAligned } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { AssetMediaVideo_asset$key } from "@/lib/graphql/__generated__/AssetMediaVideo_asset.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { getFileFormat } from "@/lib/helpers/urls"
import {
  AssetMediaImage,
  AssetMediaImageBaseProps,
} from "./AssetMediaImage.react"
import { asBackgroundColor } from "./helpers"
import { PlayButton } from "./PlayButton.react"

type AssetMediaVideoProps = {
  asset: AssetMediaVideo_asset$key
  autoPlay?: boolean
  animationUrl: string
  imageConfig: AssetMediaImageBaseProps
  imagePreviewUrl?: string
  isMuted?: boolean
  showControls?: boolean
  useCustomPlayButton?: boolean
}

export const AssetMediaVideo = ({
  asset: assetKey,
  autoPlay,
  animationUrl,
  imageConfig,
  imagePreviewUrl,
  isMuted,
  showControls,
  useCustomPlayButton,
}: AssetMediaVideoProps) => {
  const videoRef = useRef<HTMLAudioElement & HTMLVideoElement>(null)
  const asset = useFragment(
    graphql`
      fragment AssetMediaVideo_asset on AssetType {
        backgroundColor
        ...AssetMediaImage_asset
      }
    `,
    assetKey,
  )

  const [isPlaying, setIsPlaying] = useState(false)

  const showVideo = autoPlay || isPlaying || !imagePreviewUrl

  const backgroundColor = asBackgroundColor(asset.backgroundColor)

  if (showVideo) {
    return (
      <div className="AssetMedia--animation">
        <CenterAligned
          className="AssetMedia--playback-wrapper"
          style={{ backgroundColor }}
          onContextMenu={e => e.preventDefault()}
        >
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            autoPlay={autoPlay || isPlaying}
            className="AssetMedia--video"
            controls={showControls}
            controlsList="nodownload"
            loop
            muted={isMuted}
            playsInline
            poster={imagePreviewUrl}
            preload="metadata"
            ref={videoRef}
            style={imageConfig.mediaStyles}
          >
            <source
              data-testid="AssetMedia--video"
              src={`${animationUrl}#t=0.001`}
              type={`video/${getFileFormat(animationUrl)}`}
            />
          </video>
          <PlayButton
            isPlaying={isPlaying}
            playingRef={videoRef}
            setIsPlaying={setIsPlaying}
            useCustomPlayButton={useCustomPlayButton}
          />
        </CenterAligned>
      </div>
    )
  }

  // showVideo being falsey => imagePreviewUrl is truthy
  return (
    <div className="AssetMedia--animation">
      <CenterAligned
        className="AssetMedia--playback-wrapper"
        style={{ backgroundColor }}
        onContextMenu={e => e.preventDefault()}
      >
        <AssetMediaImage {...imageConfig} asset={asset} url={imagePreviewUrl} />
        <PlayButton
          isPlaying={isPlaying}
          playingRef={videoRef}
          setIsPlaying={setIsPlaying}
          useCustomPlayButton={useCustomPlayButton}
        />
      </CenterAligned>
    </div>
  )
}
