import { useState } from "react"
import { useTransaction } from "@/components/blockchain"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "./useTranslate"

export const useTransactionProgress = () => {
  const t = useTranslate("hooks")
  const [progress, setProgress] = useState(0)
  const { showErrorMessage } = useToasts()
  const { pollTransaction } = useTransaction()

  const waitForTransaction = async ({
    hash,
    chain,
    onSuccess,
  }: {
    hash: string
    chain: ChainIdentifier
    onSuccess: () => unknown
  }) => {
    setProgress(15)
    try {
      setProgress(25)
      const blockhash = await pollTransaction({
        transactionHash: hash,
        chain,
        onPoll: () => setProgress(prev => prev + 5),
      })

      if (blockhash) {
        setProgress(100)
        onSuccess()
      } else {
        showErrorMessage(
          t(
            "transactionProgress.timeout",
            "Timed out while waiting for the transaction to finish.",
          ),
        )
      }
    } catch (error) {
      setProgress(0)
      throw error
    }
  }

  return { progress, waitForTransaction, setProgress }
}
