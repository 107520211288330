/**
 * @generated SignedSource<<0de6746279356e1e3de643409da6f1d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WalletAndAccountButtonAccountImage_data$data = {
  readonly getAccount: {
    readonly imageUrl: string;
  };
  readonly " $fragmentType": "WalletAndAccountButtonAccountImage_data";
};
export type WalletAndAccountButtonAccountImage_data$key = {
  readonly " $data"?: WalletAndAccountButtonAccountImage_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"WalletAndAccountButtonAccountImage_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "address"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "WalletAndAccountButtonAccountImage_data",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "address",
          "variableName": "address"
        }
      ],
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "getAccount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "e1ca7d3f57a93613ddb28f0f01d03fea";

export default node;
