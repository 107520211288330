import React from "react"
import {
  Flex,
  FlexColumn,
  Text,
  TextBodyProps,
  classNames,
  Skeleton,
} from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled, { css } from "styled-components"
import { ResponsiveValue, Theme, TLengthStyledSystem } from "styled-system"
import { VerificationIcon } from "@/components/collections/VerificationIcon.react"
import { Link, LinkProps } from "@/components/common/Link"
import { Block } from "@/design-system/Block"
import { Image } from "@/design-system/Image"
import {
  trackNavSearchResultClick,
  SearchArea,
  SearchType,
} from "@/lib/analytics/events/navSearchEvents"
import { GlobalCollectionCard_data$key } from "@/lib/graphql/__generated__/GlobalCollectionCard_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { getCollectionUrl } from "@/lib/helpers/collection"
import { verificationStatusHasBadge } from "@/lib/helpers/verification"
import { media, themeVariant } from "@/styles/styleUtils"
import { PLACEHOLDER_IMAGE } from "../../../constants"
import { CollectionCardContextMenu } from "../CollectionCardContextMenu"
import { GlobalStatsSubtitle } from "./GlobalStatsSubtitle.react"

export const getShowCollectionCard = (
  logo: string | null,
  banner: string | null,
  options?: {
    requireBannerImage?: boolean
    allowMissingLogo?: boolean
  },
) => {
  const hasBannerRequirements = !options?.requireBannerImage || !!banner
  const hasLogoRequirements = options?.allowMissingLogo || !!logo
  return hasLogoRequirements && hasBannerRequirements
}

type Props = {
  allowMissingLogo?: boolean
  connectionId?: string
  collection: GlobalCollectionCard_data$key
  padding?: ResponsiveValue<
    string | number | symbol,
    Required<Theme<TLengthStyledSystem>>
  >
  collectionIndex?: number
  queryName?: string
  resultArea?: SearchArea
  resultType?: SearchType
  requireBannerImage?: boolean
  showContextMenu?: boolean
  showStats?: boolean
  subtitle?: string
  onClick?: () => unknown
  overrideBannerUrl?: string | null
} & Pick<LinkProps, "eventParams" | "eventSource">

const GlobalCollectionCardBase = ({
  allowMissingLogo,
  collection: collectionKey,
  padding,
  collectionIndex,
  queryName,
  resultArea,
  resultType,
  requireBannerImage,
  connectionId,
  showContextMenu,
  showStats = false,
  subtitle,
  onClick: onClickProp,
  eventSource,
  eventParams,
  overrideBannerUrl,
}: Props) => {
  const data = useFragment(
    graphql`
      fragment GlobalCollectionCard_data on CollectionType
      @argumentDefinitions(
        showContextMenu: { type: "Boolean", defaultValue: false }
        showStats: { type: "Boolean", defaultValue: false }
      ) {
        logo
        banner
        name
        verificationStatus
        ...GlobalStatsSubtitle_data @include(if: $showStats)
        ...collection_url
        ...CollectionCardContextMenu_data @include(if: $showContextMenu)
      }
    `,
    collectionKey,
  )

  const showCollectionCard = getShowCollectionCard(data.logo, data.banner, {
    requireBannerImage,
    allowMissingLogo,
  })

  if (!showCollectionCard) {
    return null
  }
  const collectionUrl = getCollectionUrl(data)

  const onClick = (url: string, index?: number) => {
    index &&
      resultArea &&
      resultType &&
      trackNavSearchResultClick({
        resultIndex: index,
        resultUrl: url,
        resultArea,
        resultType,
        query: queryName,
        path: window.location.pathname,
      })
    onClickProp?.()
  }

  return (
    <Container padding={padding}>
      <Link
        eventParams={eventParams}
        eventSource={eventSource}
        href={collectionUrl}
        onClick={() => onClick(collectionUrl, collectionIndex)}
      >
        <Section>
          <Block
            className="relative"
            height="0"
            paddingBottom={`${(9 / 16) * 100}%`}
          >
            <CardBanner
              alt={data.name}
              height={400}
              layout="fill"
              objectFit="cover"
              src={overrideBannerUrl ?? data.banner ?? PLACEHOLDER_IMAGE}
            />
          </Block>
          {showContextMenu && (
            <ContextMenu>
              <CollectionCardContextMenu
                connectionId={connectionId}
                dataKey={data}
              />
            </ContextMenu>
          )}

          <Flex className="z-[100] -mt-7 rounded-xl p-4">
            <CollectionLogoContainer>
              <SquareImage
                alt={data.name}
                height={70}
                layout="fixed"
                objectFit="cover"
                src={data.logo ?? PLACEHOLDER_IMAGE}
                width={70}
              />
            </CollectionLogoContainer>
            <FlexColumn className="overflow-hidden">
              <Flex
                className={classNames(
                  "items-center justify-start overflow-hidden",
                  subtitle || showStats ? "mt-7" : "mt-10",
                )}
              >
                <TextContainer asChild weight="semibold">
                  <div>{data.name}</div>
                </TextContainer>
                {verificationStatusHasBadge(data.verificationStatus) && (
                  <VerificationIcon
                    showTooltip={false}
                    size="small"
                    verificationStatus={data.verificationStatus}
                  />
                )}
              </Flex>
              {showStats && <GlobalStatsSubtitle dataKey={data} />}
              {subtitle && (
                <TextContainer asChild color="secondary" size="small">
                  <div>{subtitle}</div>
                </TextContainer>
              )}
            </FlexColumn>
          </Flex>
        </Section>
      </Link>
    </Container>
  )
}

const CardSkeleton = () => (
  <Skeleton className="relative h-auto p-1">
    <Section>
      <Skeleton.Row>
        <StyledSkeletonBanner />
      </Skeleton.Row>
      <StyledSkeletonInfo>
        <StyledSkeletonBlock />
        <Flex className="w-full items-center pt-2">
          <Skeleton.Line className="h-[18px] w-[200px]" />
        </Flex>
      </StyledSkeletonInfo>
    </Section>
  </Skeleton>
)

export const GlobalCollectionCard = Object.assign(GlobalCollectionCardBase, {
  Skeleton: CardSkeleton,
})

const ContextMenu = styled.div`
  display: block;

  ${media({
    md: css`
      display: none;
    `,
  })}
`

const Section = styled(Block)`
  background-color: ${props =>
    props.theme.colors.components.elevation.level1.regular.background};
  border-radius: ${props => props.theme.borderRadius.large};
  border: 1px solid ${props => props.theme.colors.components.border.level1};
  transition: 0.25s 0s ease-in-out;

  @media (hover: hover) {
    &:hover {
      background: ${props =>
        props.theme.colors.components.elevation.level2.background};
      border: 1px solid ${props => props.theme.colors.components.border.level2};
      ${ContextMenu} {
        display: block;
      }
    }
  }
`

const CollectionLogoContainer = styled.div`
  width: 78px;
  height: 78px;
  border: 4px solid
    ${props =>
      props.theme.colors.components.elevation.level1.regular.background};
  border-radius: ${props => props.theme.borderRadius.xlarge};
  box-shadow: ${props =>
    props.theme.colors.components.elevation.level1.regular.shadow};
  background-color: ${props =>
    props.theme.colors.components.elevation.level1.regular.background};
  position: relative;
  ${Section}:hover & {
    ${props =>
      themeVariant({
        variants: {
          light: {
            borderColor:
              props.theme.colors.components.elevation.level1.regular.background,
          },
          dark: {
            borderColor: props.theme.colors.ash,
            transition: "border 0.25s ease-in-out",
          },
        },
      })}
  }
`

const StyledSkeletonBanner = styled(Skeleton.Block)`
  height: 0;
  padding-bottom: ${(9 / 16) * 100}%;
  border-top-right-radius: ${props => props.theme.borderRadius.large};
  border-top-left-radius: ${props => props.theme.borderRadius.large};
`
const StyledSkeletonBlock = styled(Skeleton.Block)`
  width: 78px;
  height: 78px;
  margin-top: -78px;
  padding: 16px;
  border-radius: ${props => props.theme.borderRadius.large};
`
const StyledSkeletonInfo = styled(Skeleton)`
  justify-content: flex-end;
  flex-direction: column;
  padding: 16px;
  border-radius: ${props => props.theme.borderRadius.large};
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  background: ${props =>
    props.theme.colors.components.elevation.level1.regular.background};
  height: auto;
`
const Container = styled(Block)`
  display: inline-block;
  position: relative;
  width: 100%;
`

const SquareImage = styled(Image)`
  border-radius: ${props => props.theme.borderRadius.large};
`
const CardBanner = styled(Image)`
  background-color: ${props =>
    props.theme.colors.components.elevation.level1.regular.background};
  border-top-left-radius: ${props => props.theme.borderRadius.large};
  border-top-right-radius: ${props => props.theme.borderRadius.large};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .Image--image {
    position: absolute;
  }
`

const TextContainer = ({ className, ...props }: TextBodyProps) => (
  <Text
    className={classNames(
      "block items-center truncate break-all pl-4 text-left",
      className,
    )}
    {...props}
  />
)
