import type { DocumentProvidedProps } from "@/containers/AppProviders"
import type { Chain } from "@/hooks/useChains/types"
import { memoizeOne } from "../helpers/memoization"

export const CHAINS_DOCUMENT_ID = "__OS_CHAINS__"

const getDocumentChains = memoizeOne((): Chain[] => {
  return JSON.parse(
    document.querySelector(`#${CHAINS_DOCUMENT_ID}`)?.textContent || "{}",
  )
})

export const getHydratedChains = (
  pageProps: DocumentProvidedProps,
): Chain[] => {
  if (typeof window === "undefined") {
    return pageProps.chains
  }
  return getDocumentChains()
}
