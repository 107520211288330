import React from "react"
import { isEmpty } from "lodash"
import { useActiveAccount } from "@/containers/WalletProvider/WalletProvider.react"
import { useIsMintYourOwnEnabled } from "@/hooks/useFlag"
import { useIsBanned } from "@/hooks/useIsBanned"
import {
  LinkItem,
  useLanguageLinks,
  useLearnLinks,
  useNavLinks,
} from "@/lib/helpers/links"
import { IntercomSupportItem } from "../components/IntercomSupportItem.react"
import { LanguageSelectorBackItem } from "../components/LanguageSelectorBackItem.react"
import { LanguageSelectorItem } from "../components/LanguageSelectorItem.react"
import { LogoutItem } from "../components/LogoutItem.react"
import { NightModeToggleItem } from "../components/NightModeToggleItem.react"

type UseAccountDropdownItemsProps = {
  isSelectingLanguage: boolean
  setIsSelectingLanguage: (isSelecting: boolean) => void
}

type AccountDropdownItem = JSX.Element | LinkItem

export const useAccountDropdownItems = ({
  isSelectingLanguage,
  setIsSelectingLanguage,
}: UseAccountDropdownItemsProps):
  | AccountDropdownItem[]
  | AccountDropdownItem[][] => {
  const activeAccount = useActiveAccount()
  const isActiveAccountBanned = useIsBanned()

  const isMintYourOwnEnabled = useIsMintYourOwnEnabled()
  const links = useNavLinks()

  const logoutItems = activeAccount?.address
    ? [<LogoutItem key={activeAccount.address} />]
    : []

  const languageSelectorItems = [
    <LanguageSelectorItem
      key="language.select"
      onClick={() => setIsSelectingLanguage(true)}
    />,
  ]

  const languageLinkItems = useLanguageLinks()
  const learnLinks = useLearnLinks()

  const languageSelectionItems = [
    <LanguageSelectorBackItem
      key="language.back"
      onClick={() => setIsSelectingLanguage(false)}
    />,
    ...languageLinkItems,
  ]

  if (isSelectingLanguage) {
    return languageSelectionItems
  }

  if (isActiveAccountBanned) {
    return logoutItems
  }

  return [
    [links.profile, links.watchlist],
    [
      ...(links.deals ? [links.deals] : []),
      ...(isMintYourOwnEnabled ? [links.creatorStudio] : [links.collections]),
      ...(links.create ? [links.create] : []),
      links.os2,
    ],
    [
      links.settings,
      ...languageSelectorItems,
      <NightModeToggleItem key="dark-mode-toggle" />,
    ],
    ...(isEmpty(logoutItems) ? [] : [logoutItems]),
    [...learnLinks, <IntercomSupportItem />],
  ]
}
