import { useContextSelector } from "use-context-selector"
import { UnleashTogglesContext } from "@/containers/FlagProvider.react"

type UseVariantReturnType =
  | {
      enabled: true
      name: string
      payload: {
        type: "string" | "json" | "csv"
        value: string
      }
    }
  | {
      enabled: false
      name: string
      payload: undefined
    }

export const useVariant = (name: string): UseVariantReturnType =>
  useContextSelector(UnleashTogglesContext, ({ toggles }) => {
    const toggle = toggles[name]

    if (toggle?.variant) {
      return toggle.variant as UseVariantReturnType
    }

    return {
      name,
      enabled: false,
      payload: undefined,
    }
  })
