import React from "react"
import { SpaceBetween, Text, classNames } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { StackedAssetMedia } from "@/components/assets/StackedAssetMedia"
import { Link } from "@/components/common/Link"
import { Item, ItemProps } from "@/design-system/Item"
import { useChains } from "@/hooks/useChains"
import { useTranslate } from "@/hooks/useTranslate"
import { AssetItem_asset$key } from "@/lib/graphql/__generated__/AssetItem_asset.graphql"

export type AssetItemProps = {
  assetDataKey: AssetItem_asset$key
  transactionHash?: string
  isLoading?: boolean
  renderContentExtra?: (assetId: string) => React.ReactNode
  renderAdditionalContentExtra?: (assetId: string) => React.ReactNode
  renderExtra?: (assetId: string) => React.ReactNode
  variant?: "medium" | "small"
} & Omit<ItemProps, "variant">

const MEDIUM_ASSET_SIZE = 84
const SMALL_ASSET_SIZE = 66

export const AssetItem = ({
  assetDataKey,
  isLoading,
  renderContentExtra,
  renderAdditionalContentExtra,
  renderExtra,
  variant = "medium",
  transactionHash,
  ...assetItemExtraProps
}: AssetItemProps) => {
  const t = useTranslate("sell")
  const { getTransactionUrl, getBlockExplorerName } = useChains()

  const asset = useFragment(
    graphql`
      fragment AssetItem_asset on AssetType {
        chain {
          identifier
        }
        displayName
        relayId
        collection {
          name
        }
        ...StackedAssetMedia_assets
      }
    `,
    assetDataKey,
  )

  const size = variant === "medium" ? MEDIUM_ASSET_SIZE : SMALL_ASSET_SIZE
  const { href, ...itemProps } = assetItemExtraProps

  return (
    <Item
      {...itemProps}
      className={classNames("flex-col", itemProps.className)}
      variant="unstyled"
    >
      <SpaceBetween>
        <Item.Avatar className="rounded-xl" size={size}>
          <Link href={href} style={{ display: "flex" }}>
            <StackedAssetMedia
              assets={[asset]}
              isLoading={isLoading ?? Boolean(transactionHash)}
              key={asset.relayId}
              variant="small"
            />
          </Link>
        </Item.Avatar>
        <Item.Content>
          <Item.Title data-testid="asset-item-name">
            <Text asChild size="medium" weight="semibold">
              <div>{asset.displayName}</div>
            </Text>
          </Item.Title>
          <Item.Description>
            <Text asChild size="small">
              <div>{asset.collection.name}</div>
            </Text>
          </Item.Description>
          {renderContentExtra?.(asset.relayId)}
          {renderAdditionalContentExtra?.(asset.relayId)}

          {transactionHash ? (
            <Item.Description>
              <Link
                href={getTransactionUrl(
                  asset.chain.identifier,
                  transactionHash,
                )}
              >
                {t("explorerLink", "View on {{explorerLink}}", {
                  explorerLink: getBlockExplorerName(asset.chain.identifier),
                })}
              </Link>
            </Item.Description>
          ) : null}
        </Item.Content>

        {renderExtra?.(asset.relayId)}
      </SpaceBetween>
    </Item>
  )
}
