import React from "react"
import { Text } from "@opensea/ui-kit"
import { Button } from "@/design-system/Button"
import { Flex } from "@/design-system/Flex"
import { Grid } from "@/design-system/Grid/Grid.react"
import { useTranslate } from "@/hooks/useTranslate"

type Props = {
  resetError: () => void
}

export const AssetItemError = ({ resetError }: Props) => {
  const t = useTranslate("components")

  const errorText = t(
    "assetItem.error.description",
    "There has been an issue fetching this data. Try to refresh.",
  )

  return (
    <Grid.Item>
      <Flex alignItems="center" className="text-center" flexDirection="column">
        <Text.Body size="small">{errorText}</Text.Body>

        <Button
          className="sm:mt-2.5"
          icon="refresh"
          size="small"
          variant="secondary"
          onClick={resetError}
        >
          {t("assetItem.error.refresh", "Refresh")}
        </Button>
      </Flex>
    </Grid.Item>
  )
}
