import React from "react"
import { HUES } from "../../styles/themes"

type Props = {
  className?: string
  height?: number
  width?: number
  fill?: string
}

export const GunzscanLogo = ({
  className,
  fill = HUES.gray,
  width = 18,
  height = 18,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 575.45 300"
    className={className}
    width={width}
    height={height}
    fill={fill}
  >
    <g>
      <g>
        <g id="BG">
          <path d="M563.96,166.15l-.06,.03V36.6L527.24,0H0V222.11l77.98,77.89h78.47l11.5-11.45h48.18l11.5,11.45h124.89v-11.45h107.69l11.5,11.45h79.77c13.23,0,23.98-10.7,23.98-23.94v-98.42l-11.5-11.48Zm-12.54,122.39c-6.91,0-12.51-5.59-12.51-12.49s5.6-12.49,12.51-12.49,12.51,5.59,12.51,12.49-5.6,12.49-12.51,12.49Z" />
          <path d="M556.69,280.67l.03,.03c0-.26-.03-.52-.06-.78,0-.26,0-.49-.03-.68,0-.29-.06-.62-.13-.91-.06-.29-.16-.59-.29-.85-.13-.26-.29-.49-.52-.65-.23-.19-.49-.32-.81-.42,.68-.29,1.17-.68,1.47-1.2,.29-.52,.46-1.14,.46-1.82,0-.45-.1-.88-.26-1.3-.16-.39-.39-.75-.72-1.04-.29-.29-.68-.55-1.11-.72-.42-.16-.91-.26-1.47-.26h-6.48v12h2.64v-4.68h2.64c.65,0,1.14,.13,1.43,.42,.29,.29,.49,.75,.59,1.37,.07,.49,.13,.98,.16,1.53,.03,.52,.13,.98,.26,1.37h2.64c-.13-.16-.23-.36-.29-.62-.06-.23-.1-.49-.13-.78Zm-3-5.6v.03c-.29,.26-.75,.39-1.37,.39h-2.9v-3.38h2.9c.62,0,1.08,.13,1.37,.39,.29,.26,.46,.68,.46,1.27s-.16,1.04-.46,1.3Z" />
        </g>
        <g id="GUNZ">
          <path
            fill="#fff"
            d="M219,206.01h-26.19V11.42h-47.95V217.43c8.99,8.98,14.04,14.02,23.03,23.04h75.96c8.99-8.98,14.04-14.02,23.03-23.04V11.42h-47.95V206.01h.06Z"
          />
          <path
            fill="#fff"
            d="M11.4,217.43c8.99,8.98,14.04,14.02,23.03,23.04H110.4c8.99-8.98,14.04-14.02,23.03-23.04V57.33h-62.71v34.42h14.79v114.23h-26.19V45.84h74.14V11.42H11.4V217.43Z"
          />
          <path
            fill="#fff"
            d="M352.45,105.31L313.49,11.42h-35.15V240.43h47.95v-93.89l38.96,93.89h35.15V11.42h-47.95V105.31Z"
          />
          <path
            fill="#fff"
            d="M515.85,11.42h-103.88V45.87h46.68l-46.84,72.36v15.65h46.97l-46.81,72.29v34.26h103.88v-34.42h-46.68l46.68-72.13v-15.65h-46.94l46.94-72.52V11.42Z"
          />
        </g>
      </g>
    </g>
  </svg>
)
