/**
 * @generated SignedSource<<ed4e348ba21c116b252e23c6bd80d1f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileImage_data$data = {
  readonly imageUrl: string;
  readonly " $fragmentType": "ProfileImage_data";
};
export type ProfileImage_data$key = {
  readonly " $data"?: ProfileImage_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileImage_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileImage_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    }
  ],
  "type": "AccountType",
  "abstractKey": null
};

(node as any).hash = "d530f7d2f491feca9849e238ccfacc84";

export default node;
