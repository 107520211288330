import React, { useEffect, useRef, useState } from "react"
import {
  Icon,
  Separator,
  Text,
  InlineFlex,
  SpaceBetween,
  FlexColumn,
  Flex,
} from "@opensea/ui-kit"
import useMergedRef from "@react-hook/merged-ref"
import { graphql, useQueryLoader } from "react-relay"
import styled from "styled-components"
import {
  trackClickSwitchChain,
  trackCloseNetworkBalanceDropdown,
  trackOpenNetworkBalanceDropdown,
} from "@/components/nav/WalletPopover/analytics"
import { Z_INDEX } from "@/constants/zIndex"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { useTheme } from "@/design-system/Context"
import { Dropdown } from "@/design-system/Dropdown"
import { Item } from "@/design-system/Item"
import { List } from "@/design-system/List"
import { useChains } from "@/hooks/useChains"
import { getEquallySizedChainLogo } from "@/hooks/useChains/useChainOptions"
import { useIsOpenWithClickAway } from "@/hooks/useIsOpenWithClickAway"
import { useTranslate } from "@/hooks/useTranslate"
import { NetworkBalanceDropdownQuery } from "@/lib/graphql/__generated__/NetworkBalanceDropdownQuery.graphql"
import { getEthereumChain } from "@/lib/helpers/chainUtils"
import THEMES from "@/styles/themes"
import { useWalletPopoverContext } from "../../WalletPopoverContext.react"
import { ManageBalance } from "./components/ManageBalance.react"
import { NetworkTokenBalances } from "./components/NetworkTokenBalances.react"
import { NetworkTotalFundsUsd } from "./components/NetworkTotalFundsUsd.react"
import { SwitchNetworkListContent } from "./components/SwitchNetworkListContent/SwitchNetworkListContent.react"

export const NETWORK_BALANCE_DROPDOWN_QUERY = graphql`
  query NetworkBalanceDropdownQuery(
    $address: AddressScalar!
    $fundsChain: ChainScalar!
    $baseCurrencyChain: ChainScalar!
    $baseCurrencySymbol: String!
  ) {
    ...NetworkTotalFundsUsd_data
      @arguments(address: $address, chain: $fundsChain)
    ...NetworkTokenBalances_data
      @arguments(address: $address, chain: $fundsChain)

    # Used by child components
    # eslint-disable-next-line relay/must-colocate-fragment-spreads
    ...ManageBalanceBridgeFunds_data
      @arguments(
        address: $address
        baseCurrencyChain: $baseCurrencyChain
        baseCurrencySymbol: $baseCurrencySymbol
      )
    # eslint-disable-next-line relay/must-colocate-fragment-spreads
    ...ManageBalanceAddFunds_data
      @arguments(address: $address, chain: $fundsChain)
  }
`
export const NetworkBalanceDropdown = () => {
  const t = useTranslate("components")
  const { wallet } = useWallet()
  const { theme } = useTheme()
  const { chain: activeChain } = useWallet()
  const { getChainName, getNativeCurrencySymbol } = useChains()
  const {
    keepOpenOnClickRefCallback: keepWalletPopoverOpenOnClickRefCallback,
  } = useWalletPopoverContext()

  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<NetworkBalanceDropdownQuery>(NETWORK_BALANCE_DROPDOWN_QUERY)

  useEffect(() => {
    const ethereumChain = getEthereumChain()

    loadQuery({
      address: wallet.getActiveAddressStrict(),
      fundsChain: activeChain ?? ethereumChain,
      baseCurrencyChain: ethereumChain,
      baseCurrencySymbol: getNativeCurrencySymbol(ethereumChain),
    })

    const unsubscribeFromWalletChanges = wallet.onChange(() => {
      if (!wallet.address || !activeChain) {
        return
      }

      loadQuery({
        address: wallet.address,
        fundsChain: activeChain,
        baseCurrencyChain: ethereumChain,
        baseCurrencySymbol: getNativeCurrencySymbol(ethereumChain),
      })
    })

    return () => {
      disposeQuery()
      unsubscribeFromWalletChanges()
    }
  }, [activeChain, disposeQuery, getNativeCurrencySymbol, loadQuery, wallet])

  const [isSwitchingChains, setIsSwitchingChains] = useState(false)

  const dropdownRef = useRef<HTMLDivElement>(null)
  const {
    isOpen: isDropdownOpen,
    toggle: toggleDropdownOpen,
    keepOpenOnClickRefCallback: keepDropdownOpenOnClickRefCallback,
  } = useIsOpenWithClickAway(dropdownRef)

  const keepOpenOnClickRefCallback = useMergedRef(
    keepWalletPopoverOpenOnClickRefCallback,
    keepDropdownOpenOnClickRefCallback,
  )

  if (!activeChain) {
    return null
  }

  const ChainLogo = getEquallySizedChainLogo(activeChain)

  return (
    <Dropdown
      content={renderContentProps => (
        <StyledList ref={keepOpenOnClickRefCallback} showBorder={false}>
          {isSwitchingChains ? (
            <SwitchNetworkListContent
              {...renderContentProps}
              onClickBack={() => setIsSwitchingChains(false)}
            />
          ) : (
            <>
              <NetworkTokenBalances
                {...renderContentProps}
                activeChain={activeChain}
                keepOpenOnClickRefCallback={keepOpenOnClickRefCallback}
                queryReference={queryReference}
              />
              <Separator />
              <ManageBalance
                keepOpenOnClickRefCallback={keepOpenOnClickRefCallback}
                queryReference={queryReference ?? undefined}
                onClickSwitchChain={() => {
                  trackClickSwitchChain()
                  setIsSwitchingChains(true)
                }}
              />
            </>
          )}
        </StyledList>
      )}
      matchReferenceWidth
      maxHeight="none"
      minWidth={0}
      popoverRef={dropdownRef}
      visible={isDropdownOpen}
      zIndex={Z_INDEX.NAVBAR}
      onHidden={() => {
        trackCloseNetworkBalanceDropdown()
        setIsSwitchingChains(false)
      }}
      onShown={() => trackOpenNetworkBalanceDropdown()}
    >
      <StyledItem
        className="h-12 cursor-pointer p-3 hover:border-level-3 focus:border-level-3"
        onClick={toggleDropdownOpen}
      >
        <FlexColumn className="w-full items-start gap-1">
          <SpaceBetween className="w-full gap-x-1.5">
            <Flex className="items-center gap-3">
              {ChainLogo && (
                <ChainLogo fill={THEMES[theme].colors.text.primary} />
              )}
              <Text.Body weight="semibold">
                {getChainName(activeChain)}
              </Text.Body>
            </Flex>
            <Text.Body className="text-secondary" size="medium">
              ·
            </Text.Body>
            <InlineFlex className="items-center gap-2">
              <NetworkTotalFundsUsd queryReference={queryReference} />
              <Icon
                aria-label={
                  isDropdownOpen
                    ? t("networkBalanceDropdown.showLess", "Show less")
                    : t("networkBalanceDropdown.showMore", "Show more")
                }
                className="cursor-pointer"
                value={
                  isDropdownOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"
                }
              />
            </InlineFlex>
          </SpaceBetween>
        </FlexColumn>
      </StyledItem>
    </Dropdown>
  )
}

const StyledItem = styled(Item)`
  color: ${props => props.theme.colors.text.primary};
  width: unset;
  border: 1px solid ${props => props.theme.colors.components.border.level1};
  border-radius: ${props => props.theme.borderRadius.large};
` as typeof Item

const StyledList = styled(List)`
  background-color: ${props =>
    props.theme.colors.components.elevation.level3.background};
`
