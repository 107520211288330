import React from "react"
import { Flex, Icon, Text } from "@opensea/ui-kit"
import { Dropdown } from "@/design-system/Dropdown"
import { useRouter } from "@/hooks/useRouter"
import { useTranslate } from "@/hooks/useTranslate"
import { getShortLocaleLabel } from "@/lib/helpers/i18n"

type LanguageSelectorItemProps = {
  onClick: () => unknown
}

export const LanguageSelectorItem = ({
  onClick,
}: LanguageSelectorItemProps) => {
  const t = useTranslate("components")
  const { locale: currentLocale } = useRouter()

  return (
    <Dropdown.Item onClick={onClick}>
      <Dropdown.Item.Avatar icon="language" />
      <Dropdown.Item.Content>
        <Dropdown.Item.Title>
          {t("accountDropdown.language", "Language")}
        </Dropdown.Item.Title>
      </Dropdown.Item.Content>
      <Dropdown.Item.Action>
        <Flex>
          <Text className="text-secondary" size="medium">
            {getShortLocaleLabel(currentLocale || "")}
          </Text>
          <Icon className="cursor-pointer text-primary" value="chevron_right" />
        </Flex>
      </Dropdown.Item.Action>
    </Dropdown.Item>
  )
}
