import { useMemo } from "react"
import { graphql, useFragment } from "react-relay"
import { getTokenPricePayment } from "@/components/assets/price/TokenPrice.react"
import { useTotalPriceOrderData_orderData$key } from "@/lib/graphql/__generated__/useTotalPriceOrderData_orderData.graphql"
import { BigNumber, bn, displayFiat } from "@/lib/helpers/numberUtils"
import { readOrderDataPrices } from "../utils/readOrderDataPrice"

type TotalPricePerSymbol = Record<
  string,
  | {
      price: BigNumber
      usdPrice: BigNumber
      tokenPricePayment: ReturnType<typeof getTokenPricePayment>
    }
  | undefined
>

export const useTotalPriceOrderData = (
  orderDataKey: useTotalPriceOrderData_orderData$key | null,
) => {
  const orderData = useFragment(
    graphql`
      fragment useTotalPriceOrderData_orderData on OrderDataType
      @relay(plural: true) {
        ...readOrderDataPrices
      }
    `,
    orderDataKey,
  )

  const initialTotals: {
    totalPricePerSymbol: TotalPricePerSymbol
    totalUsdPrice: BigNumber
  } = useMemo(
    () => ({
      totalPricePerSymbol: {},
      totalUsdPrice: bn(0),
    }),
    [],
  )

  const totalPrices = useMemo(
    () =>
      orderData?.reduce((acc, orderData) => {
        const { usdPrice, price, tokenPricePayment } =
          readOrderDataPrices(orderData)

        return {
          totalPricePerSymbol: {
            ...acc.totalPricePerSymbol,
            [tokenPricePayment.symbol]: {
              price: (
                acc.totalPricePerSymbol[tokenPricePayment.symbol]?.price ||
                bn(0)
              ).plus(price),

              usdPrice: (
                acc.totalPricePerSymbol[tokenPricePayment.symbol]?.usdPrice ||
                bn(0)
              ).plus(usdPrice),

              tokenPricePayment,
            },
          },
          totalUsdPrice: acc.totalUsdPrice.plus(usdPrice),
        }
      }, initialTotals) ?? initialTotals,
    [initialTotals, orderData],
  )

  return {
    totalPricePerSymbol: totalPrices.totalPricePerSymbol,
    totalUsdPrice: displayFiat(totalPrices.totalUsdPrice),
  }
}
