import { useMemo, useCallback } from "react"
// eslint-disable-next-line no-restricted-imports
import { NextRouter, useRouter as useNextRouter } from "next/router"
import { useWalletModal } from "@/containers/WalletModalProvider.react"
import {
  useActiveAccount,
  useWallet,
} from "@/containers/WalletProvider/WalletProvider.react"
import { useLocationContext } from "@/context/location"
import { parseQueryParams, stringifyQueryParams } from "@/lib/helpers/urls"
import { hrefRequiresAuth, hrefRequiresWallet } from "@/lib/routes"
import { convertCustomUrl } from "./helpers"
import { Url, TransitionOptions, QueryParams } from "./types"

export const pathWithoutQuery = (path: string) => {
  return path.split(/\?/)[0] ?? ""
}

const getQueryParamsString = (nextRouter: NextRouter) => {
  return nextRouter.asPath.split(/\?/)[1] ?? ""
}

// There is a "query" object on next router instance, but that one includes
// path params and is thus mostly useless when building links
export const getQueryParams = (nextRouter: NextRouter) => {
  return parseQueryParams(getQueryParamsString(nextRouter))
}

export const useRouter = () => {
  const nextRouter = useNextRouter()
  const locationContext = useLocationContext()

  const { login, isAuthenticated } = useWallet()
  const activeAccount = useActiveAccount()
  const { startWalletModalAuthFlow } = useWalletModal()

  const createNavigationPromise = useCallback(
    <T>(pathname: string, callback: () => T) =>
      new Promise<T>((resolve, reject) => {
        const requiresAuth = hrefRequiresAuth(pathname)
        const requiresWallet = hrefRequiresWallet(pathname)

        if (requiresWallet || requiresAuth) {
          if (!activeAccount) {
            startWalletModalAuthFlow(async () => {
              if (requiresAuth && !isAuthenticated) {
                await login().catch(reject)
              }
              resolve(callback())
            })
          } else if (!isAuthenticated && requiresAuth) {
            login()
              .then(() => resolve(callback()))
              .catch(reject)
          } else {
            resolve(callback())
          }
        } else {
          resolve(callback())
        }
      }),
    [activeAccount, isAuthenticated, login, startWalletModalAuthFlow],
  )

  const getPathname = useCallback(
    (url: Url) => {
      return typeof url === "string" ? url : url.pathname ?? nextRouter.pathname
    },
    [nextRouter],
  )

  const onChange = useCallback(
    (handler: (...evts: unknown[]) => void) => {
      nextRouter.events.on("routeChangeComplete", handler)
      return () => nextRouter.events.off("routeChangeComplete", handler)
    },
    [nextRouter],
  )

  return useMemo(
    () => ({
      ...locationContext,
      push: (url: Url, as?: Url, options?: TransitionOptions) =>
        createNavigationPromise(getPathname(url), () =>
          nextRouter.push(
            convertCustomUrl(url),
            as ? convertCustomUrl(as) : undefined,
            options,
          ),
        ),
      replace: (url: Url, as?: Url, options?: TransitionOptions) =>
        createNavigationPromise(getPathname(url), () =>
          nextRouter.replace(
            convertCustomUrl(url),
            as ? convertCustomUrl(as) : undefined,
            options,
          ),
        ),
      back: nextRouter.back,
      events: nextRouter.events,
      onChange,
      updateQuery: (queryParams: QueryParams) =>
        nextRouter.push(
          {
            pathname: pathWithoutQuery(nextRouter.asPath),
            query: stringifyQueryParams(
              { ...getQueryParams(nextRouter), ...queryParams },
              { addQueryPrefix: false },
            ),
          },
          undefined,
          { shallow: true },
        ),
      get href() {
        return `${locationContext.origin}${
          nextRouter.asPath
        }${getQueryParamsString(nextRouter)}`
      },
      get query() {
        return nextRouter.query
      },
      get asPath() {
        return nextRouter.asPath
      },
      get pathname() {
        return nextRouter.pathname
      },
      get queryParams() {
        return getQueryParams(nextRouter)
      },
      get queryParamsString() {
        return getQueryParamsString(nextRouter)
      },
      get locale() {
        return nextRouter.locale
      },
      get asPathWithoutQuery() {
        return pathWithoutQuery(nextRouter.asPath)
      },
    }),
    [
      locationContext,
      nextRouter,
      onChange,
      createNavigationPromise,
      getPathname,
    ],
  )
}
