import { useState } from "react"
import { useUpdateEffect } from "react-use"
import { useDebounce } from "use-debounce"
import { useActiveAccount } from "@/containers/WalletProvider/WalletProvider.react"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import { useIsDelegatedQuery } from "@/lib/graphql/__generated__/useIsDelegatedQuery.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { isValidAddress } from "@/lib/helpers/address"
import { isQualifiedName } from "@/lib/helpers/ens"

type UseIsDelegatedProps = {
  identifier: string
  chain?: ChainIdentifier
  debounce?: number
  allowEnsNames?: boolean
}

export const useIsDelegated = ({
  identifier,
  chain,
  debounce = 500,
  allowEnsNames,
}: UseIsDelegatedProps) => {
  const activeAccount = useActiveAccount()
  const isWalletConnected = !!activeAccount

  const [debouncedAddress] = useDebounce(identifier, debounce)

  const isValidIdentifier = (identifier: string) => {
    if (allowEnsNames) {
      return isValidAddress(identifier) || isQualifiedName(identifier)
    }
    return isValidAddress(identifier)
  }

  const [data, refetch, isLoading] =
    useNoSuspenseLazyLoadQuery<useIsDelegatedQuery>(
      graphql`
        query useIsDelegatedQuery($identifier: String!, $chain: ChainScalar) {
          accountHelpers {
            isDelegated(identifier: $identifier, chain: $chain)
          }
        }
      `,
      { identifier: debouncedAddress, chain },
      { skip: !isWalletConnected || !isValidIdentifier(identifier) },
    )

  const [isDelegated, setIsDelegated] = useState(false)

  useUpdateEffect(() => {
    if (isValidIdentifier(identifier) && identifier === debouncedAddress) {
      setIsDelegated(Boolean(data?.accountHelpers.isDelegated))
    }
  }, [data])

  useUpdateEffect(() => {
    if (isValidIdentifier(identifier) && identifier === debouncedAddress) {
      setIsDelegated(Boolean(data?.accountHelpers.isDelegated))
      return
    }

    setIsDelegated(false)
  }, [identifier])

  return {
    isDelegated,
    refetch,
    isLoading:
      isLoading ||
      (isValidIdentifier(identifier) && identifier !== debouncedAddress),
  }
}
