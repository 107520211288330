import React, {
  useMemo,
  createContext,
  useState,
  useCallback,
  ReactNode,
  useContext,
  useRef,
  useEffect,
} from "react"
import { noop } from "lodash"
import dynamic from "next/dynamic"
import { WalletModal } from "@/components/nav/WalletModal/WalletModal.react"
import {
  OSWalletModal,
  useIsCustomAuthWalletEnabled,
  useIsLoginRefreshEnabled,
} from "@/features/os-wallet"
import { useOSWalletAuth } from "@/features/os-wallet/hooks/useOSWalletAuth"
import { useActiveAccount } from "./WalletProvider/WalletProvider.react"

const MipdContextProvider = dynamic(
  async () =>
    (await import("@/features/os-wallet/MipdContextProvider.react"))
      .MipdContextProvider,
  {
    ssr: false,
  },
)

type WalletModalContextType = {
  isWalletModalOpen: boolean
  startWalletModalAuthFlow: (
    onAuthenticatedCallback?: () => unknown,
    onCloseCallback?: () => unknown,
    isFromMint?: boolean,
  ) => void
  handleUnconnectedWallet: (callback: () => unknown) => void
  closeWalletModal: () => void
}

const WalletModalContext = createContext<WalletModalContextType>({
  isWalletModalOpen: false,
  startWalletModalAuthFlow: noop,
  handleUnconnectedWallet: noop,
  closeWalletModal: noop,
})

type Props = {
  children: ReactNode
}

export function WalletModalProvider({ children }: Props) {
  const activeAccount = useActiveAccount()
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false)
  const [isFromMint, setIsFromMint] = useState(false)
  const onConnectedCallbackRef = useRef<(() => unknown) | null>(null)
  const onCloseCallbackRef = useRef<(() => unknown) | null>(null)
  const isCustomAuthWalletEnabled = useIsCustomAuthWalletEnabled()
  const { loginTriggered } = useOSWalletAuth()
  const showNewLogin = useIsLoginRefreshEnabled()

  const closeWalletModal = useCallback(() => {
    setIsWalletModalOpen(false)
  }, [])

  // Runs when wallet modal is closed by user (clicking close button or outside modal, etc.)
  const onCloseWalletModal = useCallback(() => {
    onCloseCallbackRef.current?.()
    closeWalletModal()
  }, [closeWalletModal])

  const startWalletModalAuthFlow = useCallback(
    (
      onAuthenticatedCallback?: () => unknown,
      onCloseCallback?: () => unknown,
      isFromMint?: boolean,
    ) => {
      setIsWalletModalOpen(true)
      setIsFromMint(!!isFromMint)

      // Set callback to be run on authentication
      onConnectedCallbackRef.current = onAuthenticatedCallback ?? null
      // Set callback to be run on modal close
      onCloseCallbackRef.current = onCloseCallback ?? null
    },
    [],
  )

  const handleUnconnectedWallet = useCallback(
    (callback: () => unknown) => {
      if (!activeAccount) {
        startWalletModalAuthFlow(callback)
        return
      }

      callback()
    },
    [startWalletModalAuthFlow, activeAccount],
  )

  useEffect(() => {
    if (loginTriggered) {
      startWalletModalAuthFlow()
    }
  }, [loginTriggered, startWalletModalAuthFlow])

  useEffect(() => {
    if (activeAccount) {
      if (isWalletModalOpen) {
        // Close wallet modal once wallet is connected
        setIsWalletModalOpen(false)
      }

      // Execute callback now that wallet is connected
      onConnectedCallbackRef.current?.()

      // Reset callback
      onConnectedCallbackRef.current = null
    }
  }, [activeAccount, isWalletModalOpen])

  const value = useMemo(
    () => ({
      startWalletModalAuthFlow,
      isWalletModalOpen,
      handleUnconnectedWallet,
      closeWalletModal,
    }),
    [
      startWalletModalAuthFlow,
      isWalletModalOpen,
      handleUnconnectedWallet,
      closeWalletModal,
    ],
  )

  return (
    <WalletModalContext.Provider value={value}>
      {children}
      {isCustomAuthWalletEnabled ? (
        <MipdContextProvider>
          <OSWalletModal
            isFromMint={isFromMint}
            isOpen={isWalletModalOpen}
            showNewLogin={showNewLogin}
            onClose={onCloseWalletModal}
          />
        </MipdContextProvider>
      ) : (
        <WalletModal isOpen={isWalletModalOpen} onClose={onCloseWalletModal} />
      )}
    </WalletModalContext.Provider>
  )
}

export const useWalletModal = () => useContext(WalletModalContext)
