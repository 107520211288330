import React, { useCallback, useEffect, useRef, useState } from "react"
import {
  AuthCode,
  CenterAligned,
  FlexColumn,
  Icon,
  MaterialIcon,
  Spinner,
  Text,
} from "@opensea/ui-kit"
import { Button } from "@/design-system/Button"
import { Modal } from "@/design-system/Modal"
import { Tooltip } from "@/design-system/Tooltip"
import { RESEND_CODE_TIMEOUT_SECONDS } from "@/features/os-wallet/components/constants"
import { AuthCodeExpiration } from "@/features/os-wallet/components/OSWalletModal/components/AuthCodeExpiration.react"
import { useTranslate } from "@/hooks/useTranslate"
import { WalletModalHeader } from "./WalletModalHeader.react"
import { WalletSteps } from "./WalletStepIndicator.react"

type Props = {
  title: React.ReactNode
  subtitle?: React.ReactNode
  description: React.ReactNode
  icon?: MaterialIcon
  error?: boolean
  resendTooltip?: React.ReactNode
  authCodeExpiration?: { minutes: number; seconds: number }
  onAuthCode: (authCode: string) => Promise<void>
  onResendAuthCode?: () => Promise<void>
} & Partial<WalletSteps>

export const WalletAuthCodeModal = ({
  title,
  subtitle,
  description,
  resendTooltip,
  icon,
  error,
  step,
  totalSteps,
  authCodeExpiration,
  onAuthCode,
  onResendAuthCode,
}: Props) => {
  const t = useTranslate("common")
  const [loading, setLoading] = useState<boolean>(false)
  const [expired, setExpired] = useState<boolean>(false)
  const [canResendCode, setCanResendCode] = useState<boolean>(true)
  const resetExpirationRef = useRef<(() => void) | null>(null)
  const showAuthCodeExpiration =
    authCodeExpiration?.minutes || authCodeExpiration?.seconds

  useEffect(() => {
    if (canResendCode) {
      return
    }
    const timeout = setTimeout(() => {
      setCanResendCode(true)
    }, RESEND_CODE_TIMEOUT_SECONDS * 1000)
    return () => clearTimeout(timeout)
  }, [canResendCode])

  const handleAuthCode = useCallback(
    async (authCode: string) => {
      setLoading(true)
      await onAuthCode(authCode)
      setLoading(false)
    },
    [onAuthCode],
  )

  const handleAuthCodeExpired = useCallback(() => {
    setExpired(true)
  }, [])

  const handleResend = useCallback(async () => {
    if (!canResendCode) {
      return
    }
    setExpired(false)
    setCanResendCode(false)
    await onResendAuthCode?.()
    resetExpirationRef.current?.()
  }, [canResendCode, onResendAuthCode])

  return (
    <Modal.Body
      className="rounded-[12px]"
      data-testid="wallet-auth-modal"
      style={{ padding: 0 }}
    >
      <FlexColumn className="relative max-h-[760px] overflow-auto rounded-t-[12px] bg-base-2">
        <WalletModalHeader step={step} totalSteps={totalSteps}>
          <Text.Heading asChild className="mt-6" responsive size="small">
            <h2>{title}</h2>
          </Text.Heading>
          {subtitle && (
            <Text.Body asChild className="mt-1" color="secondary" size="small">
              <h3>{subtitle}</h3>
            </Text.Body>
          )}
        </WalletModalHeader>
        <CenterAligned className="mx-6 mb-6 rounded-xl bg-component-gray-1 px-4 py-8">
          {loading && icon ? (
            <CenterAligned className="mb-2 h-[48px]">
              <Spinner size="small" />
            </CenterAligned>
          ) : icon ? (
            <Icon className="mb-2" size={48} value={icon} />
          ) : null}
          <Text className="mb-6 max-w-full text-center">{description}</Text>
          <AuthCode
            autoFocus
            disabled={expired || loading}
            error={error}
            overrides={{
              Inputs: {
                className: "bg-white dark:bg-component-gray-1",
              },
            }}
            onComplete={handleAuthCode}
          />
          {showAuthCodeExpiration &&
            (expired ? (
              <Text.Body className="mt-2" color="secondary" size="small">
                {t(
                  "wallet.opensea.authCodeExpired",
                  "Code expired, please request a new code.",
                )}
              </Text.Body>
            ) : (
              <AuthCodeExpiration
                className="mt-2"
                minutes={authCodeExpiration.minutes}
                seconds={authCodeExpiration.seconds}
                onEnd={handleAuthCodeExpired}
                onReset={reset => (resetExpirationRef.current = reset)}
              />
            ))}
        </CenterAligned>
        {onResendAuthCode && (
          <Tooltip content={resendTooltip} disabled={canResendCode}>
            <div className="mx-6 mb-6">
              <Button
                className="w-full"
                disabled={!canResendCode}
                variant="secondary"
                onClick={handleResend}
              >
                {t("wallet.opensea.resendAuthenticationCode", "Resend code")}
              </Button>
            </div>
          </Tooltip>
        )}
      </FlexColumn>
    </Modal.Body>
  )
}
