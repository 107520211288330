/**
 * @generated SignedSource<<c1a6ceddd58fe7353063275b90e29f38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useHandleBlockchainActions_bulk_accept_offers$data = {
  readonly method: {
    readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_transaction">;
  };
  readonly offersToAccept: ReadonlyArray<{
    readonly orderData: {
      readonly openedAt: string;
    };
  }>;
  readonly " $fragmentType": "useHandleBlockchainActions_bulk_accept_offers";
};
export type useHandleBlockchainActions_bulk_accept_offers$key = {
  readonly " $data"?: useHandleBlockchainActions_bulk_accept_offers$data;
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_bulk_accept_offers">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useHandleBlockchainActions_bulk_accept_offers"
};

(node as any).hash = "6c25557d425389ed943739e8d7e38d54";

export default node;
