/* eslint-disable tailwindcss/no-custom-classname */
import React, { forwardRef, useState, useRef, useEffect } from "react"
import { Flex, Icon, MaterialIcon, MaterialTheme, Text } from "@opensea/ui-kit"
import clsx from "clsx"
import styled, { css } from "styled-components"
import { BasePanel } from "@/components/layout/BasePanel.react"
import { FrameConsumer } from "@/components/layout/Frame.react"
import { Block } from "@/design-system/Block"
import { selectClassNames } from "@/lib/helpers/styling"
import { classNames, themeVariant } from "@/styles/styleUtils"

// FIXME: I think there are issues with the open/closing animation not always working
const ANIMATION_DURATION_IN_MILLISECONDS = 100

type BasePanelProps = {
  /**
   * Unique string id for panel, do not use auto-gen'd id to avoid server-client mismatch
   */
  id: string
  bodyClassName?: string
  children?: React.ReactNode
  className?: string
  headerClassName?: string
  footerClassName?: string
  FooterButton?: React.ReactNode
  isHeaderPadded?: boolean
  isContentPadded?: boolean
  iconTheme?: MaterialTheme
  maxHeight?: number
  mode?: "start-closed" | "start-open" | "always-open" | "controlled"
  onClick?: (isOpen: boolean) => unknown
  open?: boolean
  title: React.ReactNode
  subtitle?: React.ReactNode
  variant?: "default" | "warning" | "secondary"
  /**
   * Boolean flag that will unmount children when it's collapsed
   */
  unmountChildrenOnClose?: boolean
}

type PanelWithIconProps = BasePanelProps & {
  icon: MaterialIcon
  iconClassName?: string
}

type PanelWithCustomIconProps = BasePanelProps & {
  icon: JSX.Element
}

export type PanelProps =
  | BasePanelProps
  | PanelWithIconProps
  | PanelWithCustomIconProps

const hasCustomIcon = (
  props: Partial<PanelProps>,
): props is PanelWithCustomIconProps => {
  return React.isValidElement((props as PanelWithCustomIconProps).icon)
}

const hasMaterialIcon = (
  props: Partial<PanelProps>,
): props is PanelWithIconProps => {
  return typeof (props as PanelWithIconProps).icon === "string"
}

export const Panel = forwardRef<HTMLDivElement, PanelProps>(function Panel(
  {
    bodyClassName,
    children,
    className,
    headerClassName,
    footerClassName,
    FooterButton,
    id,
    isContentPadded = true,
    isHeaderPadded = true,
    maxHeight,
    mode = "start-closed",
    open,
    onClick,
    title,
    subtitle,
    variant = "default",
    unmountChildrenOnClose,
    iconTheme,
    ...rest
  },
  ref,
) {
  const [contentHeight, setContentHeight] = useState<number | undefined>(0)
  const [isOpen, setIsOpen] = useState(
    () =>
      !!React.Children.count(children) &&
      (mode === "start-open" || mode === "always-open"),
  )
  const isDisabled = !React.Children.count(children)
  const isControlled = mode === "controlled"
  const shouldBeOpen = isControlled ? open : isOpen

  const contentRef = useRef<HTMLDivElement>(null)

  const handleClick = () => {
    if (isControlled) {
      onClick?.(!open)
    } else {
      if (React.Children.count(children) && mode !== "always-open") {
        setIsOpen(!isOpen)
      }
    }
  }

  useEffect(() => {
    let timeout: number
    if (contentHeight) {
      timeout = window.setTimeout(() => {
        setContentHeight(undefined)
      }, ANIMATION_DURATION_IN_MILLISECONDS)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [contentHeight])

  useEffect(() => {
    setContentHeight(contentRef.current?.getBoundingClientRect().height)
  }, [isOpen])

  const headerId = `Header ${id}`
  const bodyId = `Body ${id}`

  return (
    <FrameConsumer>
      {({ isFramed }) => (
        <DivContainer
          className={selectClassNames(
            "Panel",
            {
              alwaysOpen: mode === "always-open",
              isOpen: shouldBeOpen,
              isClosed: !shouldBeOpen,
              isFramed,
              warning: variant === "warning",
              secondary: variant === "secondary",
            },
            className,
          )}
          data-testid="Panel"
          ref={ref}
        >
          <BasePanel className="Panel--panel">
            {({ Header, Body, Footer }) => (
              <>
                <Header
                  aria-controls={bodyId}
                  aria-expanded={shouldBeOpen ? "true" : "false"}
                  className={clsx("Panel--header", headerClassName)}
                  disabled={isDisabled}
                  id={headerId}
                  onClick={handleClick}
                >
                  <Block className="w-full">
                    <Flex
                      className={classNames(
                        "w-full",
                        isHeaderPadded ? "p-5" : "p-0",
                      )}
                    >
                      {hasMaterialIcon(rest) ? (
                        <Icon
                          aria-hidden="true"
                          className={classNames(
                            "Panel--icon",
                            rest.iconClassName,
                          )}
                          value={rest.icon}
                          variant={iconTheme}
                        />
                      ) : hasCustomIcon(rest) ? (
                        <Block aria-hidden="true" marginRight="10px">
                          {rest.icon}
                        </Block>
                      ) : undefined}
                      {typeof title === "string" ? (
                        <Text.Body asChild size="medium" weight="semibold">
                          <h3>{title}</h3>
                        </Text.Body>
                      ) : (
                        title
                      )}

                      <Icon
                        aria-hidden="true"
                        className={selectClassNames("Panel", {
                          toggle: true,
                          isEnabled: !isDisabled,
                        })}
                        value={shouldBeOpen ? "expand_less" : "expand_more"}
                        variant={iconTheme}
                      />
                    </Flex>
                    {subtitle && isOpen && <Block>{subtitle}</Block>}
                  </Block>
                </Header>
                <Body
                  aria-labelledby={headerId}
                  className={selectClassNames("Panel", {
                    body: true,
                    "body-secondary": variant === "secondary",
                    "body-warning": variant === "warning",
                    "body-is-closed": !shouldBeOpen,
                  })}
                  id={bodyId}
                  role="region"
                >
                  <div
                    className="Panel--content-container"
                    ref={contentRef}
                    style={{
                      height: shouldBeOpen ? contentHeight || "initial" : 0,
                      maxHeight,
                      overflow: maxHeight ? "auto" : undefined,
                    }}
                  >
                    <div
                      className={selectClassNames(
                        "Panel",
                        {
                          isContentPadded,
                        },
                        bodyClassName,
                      )}
                    >
                      {unmountChildrenOnClose && !shouldBeOpen
                        ? null
                        : children}
                    </div>
                  </div>
                </Body>
                {shouldBeOpen && FooterButton && (
                  <Footer className={clsx("Panel--footer", footerClassName)}>
                    {FooterButton}
                  </Footer>
                )}
              </>
            )}
          </BasePanel>
        </DivContainer>
      )}
    </FrameConsumer>
  )
})

const secondaryVariantBackgroundColor = css`
  ${props =>
    themeVariant({
      variants: {
        dark: {
          backgroundColor: props.theme.colors.onyx,
        },
        light: {
          backgroundColor: props.theme.colors.mist,
        },
      },
    })}
`

const warningVariantBorder = css`
  border: 1px solid ${props => props.theme.colors.warning};
`

const warningVariantBackgroundColor = css`
  background-color: ${props =>
    props.theme.colors.withOpacity.warning.veryLight};
`

const DivContainer = styled.div`
  &.Panel--isOpen {
    .Panel--content-container {
      overflow: visible;
    }
  }

  &.Panel--isClosed {
    .Panel--body {
      border: none;
      &.Panel--body-is-closed {
        display: none;
      }
    }
  }

  &.Panel--isFramed {
    .Panel--panel,
    .Panel--header,
    .Panel--body {
      border-radius: 0;
    }

    .Panel--panel {
      border-top: none;
      border-left: none;
      border-right: none;
      margin-bottom: -1px;
      margin-top: 1px;
    }

    .Panel--header {
      border: 0;
      margin: 0;
    }

    .Panel--body {
      border-left: 0;
      border-right: 0;
    }
  }

  .Panel--icon {
    margin-right: 10px;
  }

  .Panel--toggle {
    margin-left: auto;
    color: ${props => props.theme.colors.text.primary};
  }

  .Panel--header {
    padding: 0;

    &:hover {
      .Panel--toggle.Panel--isEnabled {
        color: ${props => props.theme.colors.text.primary};
      }
    }
  }

  .Panel--content-container {
    overflow: hidden;
    transition: height ${ANIMATION_DURATION_IN_MILLISECONDS}ms;
  }

  .Panel--isContentPadded {
    padding: 20px;
    border-bottom-left-radius: ${props => props.theme.borderRadius.default};
    border-bottom-right-radius: ${props => props.theme.borderRadius.default};
  }

  &.Panel--alwaysOpen {
    .Panel--header {
      cursor: initial;

      .Panel--toggle {
        display: none;
      }
    }
  }

  &.Panel--warning,
  &.Panel--secondary {
    .Panel--panel {
      border-bottom: none;
    }

    &.Panel--isClosed {
      border-radius: ${props => props.theme.borderRadius.default};

      .Panel--body-warning,
      .Panel--body-secondary {
        border: none;
      }

      .Panel--header {
        border-radius: ${props => props.theme.borderRadius.default};
      }
    }

    .Panel--body {
      border-radius: ${props => {
        const radiusDefault = props.theme.borderRadius.default

        return `0 0 ${radiusDefault} ${radiusDefault}`
      }};
    }

    .Panel--header {
      margin-bottom: 0px;
      padding: 20px 15px;

      &[aria-expanded="true"] {
        border-bottom: none;
        border-radius: ${props => {
          const radiusDefault = props.theme.borderRadius.default

          return `${radiusDefault} ${radiusDefault} 0 0`
        }};
      }
    }
  }

  &.Panel--warning {
    &.Panel--isClosed {
      ${warningVariantBorder}
    }

    .Panel--body-warning {
      ${warningVariantBackgroundColor}
      ${warningVariantBorder}
    }

    .Panel--header {
      ${warningVariantBackgroundColor}

      &[aria-expanded="true"] {
        ${warningVariantBorder}
        border-bottom: none;
      }
    }
  }

  &.Panel--secondary {
    .Panel--body-secondary {
      ${secondaryVariantBackgroundColor}
    }

    .Panel--header {
      ${secondaryVariantBackgroundColor}
    }
  }
`
