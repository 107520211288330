/**
 * @generated SignedSource<<e254b06ce4f6927abe259d25a4e96527>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FulfillSwapOrderAction_data$data = {
  readonly __typename: "FulfillSwapOrderActionType";
  readonly swapData: {
    readonly " $fragmentSpreads": FragmentRefs<"SwapDataHeader_swap">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_fulfill_swap_order">;
  readonly " $fragmentType": "FulfillSwapOrderAction_data";
};
export type FulfillSwapOrderAction_data$key = {
  readonly " $data"?: FulfillSwapOrderAction_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"FulfillSwapOrderAction_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FulfillSwapOrderAction_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SwapDataType",
      "kind": "LinkedField",
      "name": "swapData",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SwapDataHeader_swap"
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useHandleBlockchainActions_fulfill_swap_order",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TransactionSubmissionDataType",
          "kind": "LinkedField",
          "name": "method",
          "plural": false,
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "useHandleBlockchainActions_transaction",
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "useTransaction_transaction",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AddressDataType",
                      "kind": "LinkedField",
                      "name": "source",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AddressDataType",
                      "kind": "LinkedField",
                      "name": "destination",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "data",
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SwapDataType",
          "kind": "LinkedField",
          "name": "swapData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "openedAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "FulfillSwapOrderActionType",
  "abstractKey": null
};
})();

(node as any).hash = "450c2c570306b67cb01eb64391df209f";

export default node;
