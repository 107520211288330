import type { ComponentType } from "react"
import styled from "styled-components"
import { Block, BlockProps } from "@/design-system/Block"
import { FormControl } from "@/design-system/FormControl"

export type FormProps = BlockProps &
  JSX.IntrinsicElements["form"] & {
    ref?: React.RefObject<HTMLFormElement>
  }

const FormBase = styled(Block).attrs({
  as: "form",
})`` as ComponentType<FormProps>

const StyledFormControl = styled(FormControl)`
  & + & {
    margin-top: 24px;
  }
`

export const Form = Object.assign(FormBase, {
  Control: StyledFormControl,
})
