import { ethers } from "ethers"
import type TFortmaticModule from "fortmatic"
import type { WidgetMode } from "fortmatic/dist/cjs/src/core/sdk"
import { FORTMATIC_API_KEY, FORTMATIC_TESTNET_API_KEY } from "@/constants"
import { WALLET_NAME } from "@/constants/wallet"
import { Chain } from "@/hooks/useChains/types"
import { getEthereumChain } from "@/lib/helpers/chainUtils"
import Web3EvmProvider, {
  ETHERS_WEB3_PROVIDER_NETWORK,
  ExternalProvider,
  Web3Provider,
} from "./web3EvmProvider"

class FortmaticProvider extends Web3EvmProvider {
  fortmatic: WidgetMode
  provider: Web3Provider

  constructor(Fortmatic: typeof TFortmaticModule, chains: Chain[]) {
    super(chains)
    this.fortmatic = new Fortmatic(
      getEthereumChain() === "SEPOLIA"
        ? FORTMATIC_TESTNET_API_KEY
        : FORTMATIC_API_KEY,
    )
    this.provider = new ethers.providers.Web3Provider(
      this.fortmatic.getProvider() as unknown as ExternalProvider,
      ETHERS_WEB3_PROVIDER_NETWORK,
    )
  }

  connect = () => {
    return this.getAccounts()
  }

  disconnect = () => {
    return this.fortmatic.user.logout()
  }

  getName = () => {
    return WALLET_NAME.Fortmatic
  }
}

export const createFortmaticProvider = async (
  chains: Chain[],
): Promise<FortmaticProvider> => {
  const Fortmatic = (await import("fortmatic")).default
  return new FortmaticProvider(Fortmatic, chains)
}
