import { ComponentProps } from "react"
import { classNames } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { List, ListProps } from "@/design-system/List"
import { isInteractiveElement } from "@/design-system/utils"

type DropdownItemProps = ComponentProps<typeof List.Item>

const DROPDOWN_LIST_PADDING = 8

export const DropdownItem = styled(List.Item).attrs<DropdownItemProps>({
  interactive: false,
})<DropdownItemProps>`
  ${props =>
    isInteractiveElement(props) &&
    !props.disabled &&
    css`
      :hover:not([disabled]) {
        background: ${props => props.theme.colors.components.background.gray1};
      }
      :active:not([disabled]) {
        background: ${props => props.theme.colors.components.background.gray2};
      }
    `}
`

DropdownItem.Avatar = styled(List.Item.Avatar).attrs<
  ComponentProps<typeof List.Item.Avatar>
>(props => ({
  style: { ...props.style, marginRight: "16px" },
}))``

DropdownItem.Title = styled(List.Item.Title)``

DropdownItem.Content = styled(List.Item.Content).attrs<
  ComponentProps<typeof List.Item.Content>
>(props => ({
  className: classNames("mr-0", props.className),
}))``

const DropdownListHeader = styled(List.Header)`
  margin-left: -${DROPDOWN_LIST_PADDING}px;
  margin-right: -${DROPDOWN_LIST_PADDING}px;
  padding-left: ${DROPDOWN_LIST_PADDING + 16}px;
  padding-right: ${DROPDOWN_LIST_PADDING + 16}px;
`

const DropdownListBase = styled(List).attrs<ListProps>({
  variant: "framed",
  showBorder: false,
})<ListProps>`
  background-color: ${props =>
    props.theme.colors.components.elevation.level3.background};
  padding: ${DROPDOWN_LIST_PADDING}px;
  position: relative;

  ${DropdownItem} {
    border-radius: ${props => props.theme.borderRadius.button};
  }

  // Ensure that the header is top-aligned correctly.
  :has(${DropdownListHeader}) {
    padding-top: 0;
  }
`

export const DropdownDivider = styled.li.attrs({
  "aria-hidden": true,
})`
  height: 1px;
  margin: ${DROPDOWN_LIST_PADDING}px -${DROPDOWN_LIST_PADDING}px;
  width: calc(100% + ${DROPDOWN_LIST_PADDING}px * 2);
  background-color: ${props => props.theme.colors.components.border.level2};
`

export const DropdownList = Object.assign(DropdownListBase, {
  Header: DropdownListHeader,
  Group: List.Group,
})
