/**
 * @generated SignedSource<<0afc905c0d8527ab6905686eaaed9efb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderSide = "ASK" | "BID" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CreateOrderAction_data$data = ReadonlyArray<{
  readonly __typename: "CreateOrderActionType";
  readonly orderData: {
    readonly isCounterOrder: boolean;
    readonly item: {
      readonly quantity?: string;
    } | null;
    readonly perUnitPrice: {
      readonly symbol: string;
      readonly unit: string;
    };
    readonly side: OrderSide;
    readonly " $fragmentSpreads": FragmentRefs<"OrderDataHeader_order">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_create_order">;
  readonly " $fragmentType": "CreateOrderAction_data";
}>;
export type CreateOrderAction_data$key = ReadonlyArray<{
  readonly " $data"?: CreateOrderAction_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateOrderAction_data">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CreateOrderAction_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderDataType",
      "kind": "LinkedField",
      "name": "orderData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "item",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quantity",
                  "storageKey": null
                }
              ],
              "type": "AssetQuantityDataType",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "side",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCounterOrder",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceType",
          "kind": "LinkedField",
          "name": "perUnitPrice",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "symbol",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OrderDataHeader_order"
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useHandleBlockchainActions_create_order",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SignAndPostType",
          "kind": "LinkedField",
          "name": "method",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "clientMessage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "clientSignatureStandard",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "serverSignature",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "orderData",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ChainType",
              "kind": "LinkedField",
              "name": "chain",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "identifier",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "CreateOrderActionType",
  "abstractKey": null
};

(node as any).hash = "22a501d13b6cbe2717cf48d27fae62a6";

export default node;
