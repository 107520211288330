/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react"
import { LinkProps as DSLinkProps, Link as DSLink } from "@opensea/ui-kit"
import NextLink, { LinkProps as NextLinkProps } from "next/link"
import { useRouter } from "@/hooks/useRouter"
import { useAnalyticsContext } from "@/lib/analytics"
import { parseQueryParams, stringifyQueryParams } from "@/lib/helpers/urls"
import { hrefRequiresAuth, hrefRequiresWallet } from "@/lib/routes"
import { ExternalLink, ExternalLinkProps } from "../ExternalLink"
import { useTrackClickLink } from "./analytics"

export type LinkProps = DSLinkProps &
  Partial<
    Pick<
      ExternalLinkProps,
      "rel" | "target" | "onClick" | "eventSource" | "eventParams" | "disabled"
    >
  > &
  Pick<NextLinkProps, "shallow" | "passHref" | "scroll" | "locale">

const getRelativeRoute = (href: string) => {
  const [path, search = ""] = href.split("?")
  const params = parseQueryParams(search)
  return `${path}${stringifyQueryParams(params)}`
}

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  function Link(
    {
      href,
      eventSource: eventSourceFromProps,
      eventParams: eventParamsFromProps,
      onClick,
      shallow,
      scroll,
      passHref = true,
      ...props
    },
    ref,
  ) {
    const trackClickLink = useTrackClickLink()
    const { eventSource, eventParams } = useAnalyticsContext({
      eventSource: eventSourceFromProps,
      eventParams: eventParamsFromProps,
    })
    const router = useRouter()

    const handleInternalLinkClick = async (
      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => {
      onClick?.(e)
      if (eventSource) {
        trackClickLink({
          url: href,
          target: props.target,
          source: eventSource,
          currentUrl: window.location.href,
          type: "internal",
          ...eventParams, // Add extra untyped eventParams to the event
        })
      }

      if (!href) {
        return
      }

      const requiresWallet = hrefRequiresWallet(href)
      const requiresAuth = hrefRequiresAuth(href)

      if (requiresWallet || requiresAuth) {
        e.preventDefault()
        e.stopPropagation()
        await router.push(href)
      }
    }

    if (!href) {
      return <DSLink {...props} ref={ref} onClick={handleInternalLinkClick} />
    }

    if (href.startsWith("/")) {
      const route = getRelativeRoute(href)

      return (
        <NextLink
          href={route}
          legacyBehavior
          locale={props.locale}
          passHref={passHref}
          scroll={scroll}
          shallow={shallow}
        >
          <DSLink {...props} ref={ref} onClick={handleInternalLinkClick} />
        </NextLink>
      )
    }

    return (
      <ExternalLink
        eventSource={eventSource}
        href={href}
        ref={ref}
        onClick={onClick}
        {...props}
      />
    )
  },
)
