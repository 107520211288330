import React, { useCallback, useState } from "react"
import { Text } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { StackedAssetMedia } from "@/components/assets/StackedAssetMedia"
import { CollectionLink } from "@/components/collections/CollectionLink"
import { Link } from "@/components/common/Link"
import { Item } from "@/design-system/Item"
import { useChains } from "@/hooks/useChains"
import { useTranslate } from "@/hooks/useTranslate"
import {
  useTrackBlockchainAttempt,
  useTrackBlockchainError,
} from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/hooks"
import { AssetApprovalAction_data$key } from "@/lib/graphql/__generated__/AssetApprovalAction_data.graphql"
import { useTransaction } from "../useTransaction"
import {
  BaseBlockchainActionProps,
  BlockchainActionModalContent,
} from "./BlockchainActionModalContent.react"
import { useBlockchainActionProgress } from "./useBlockchainActionProgress"
import { useHandleBlockchainActions } from "./useHandleBlockchainActions"

type Props = BaseBlockchainActionProps & {
  dataKey: AssetApprovalAction_data$key
}

export const AssetApprovalAction = ({ dataKey, onEnd }: Props) => {
  const t = useTranslate("components")

  const actions = useFragment(
    graphql`
      fragment AssetApprovalAction_data on AssetApprovalActionType
      @relay(plural: true) {
        __typename
        asset {
          chain {
            identifier
          }
          ...StackedAssetMedia_assets
          assetContract {
            ...CollectionLink_assetContract
          }
          collection {
            __typename
            ...CollectionLink_collection
          }
        }
        ...useHandleBlockchainActions_approve_asset
      }
    `,
    dataKey,
  )

  const [actionIndex, setActionIndex] = useState(0)
  const [transactionHash, setTransactionHash] = useState<string>()
  const { getTransactionUrl, getBlockExplorerName } = useChains()

  const { approveAsset } = useHandleBlockchainActions()
  const trackBlockchainAttempt = useTrackBlockchainAttempt()
  const [trackBlockchainError, errorTags] = useTrackBlockchainError()

  const { pollTransaction } = useTransaction()

  const executeAction = useCallback(async () => {
    if (actionIndex < actions.length) {
      trackBlockchainAttempt({ blockchainAction: "approval" })
      const transaction = await approveAsset(actions[actionIndex])

      setTransactionHash(transaction.transactionHash)

      await pollTransaction({ ...transaction, showMessageOnSuccess: true })

      setTransactionHash("")

      if (actionIndex === actions.length - 1) {
        onEnd()
      } else {
        setActionIndex(index => index + 1)
      }
    }
  }, [
    actionIndex,
    actions,
    approveAsset,
    onEnd,
    pollTransaction,
    trackBlockchainAttempt,
  ])

  const action = actions[actionIndex]

  const { progress, attemptAction } = useBlockchainActionProgress({
    executeAction,
    action,
    onError: trackBlockchainError({ blockchainAction: "approval" }),
    errorTags,
  })

  const assets = actions.map(({ asset }) => asset)

  const asset = action.asset

  return (
    <BlockchainActionModalContent>
      <BlockchainActionModalContent.Header>
        <BlockchainActionModalContent.Title>
          {t(
            "blockchain.approve.title",
            {
              "0": "Approve collections",
              one: "Approve collection",
              other: "Approve collections",
            },
            { count: actions.length },
          )}
        </BlockchainActionModalContent.Title>
      </BlockchainActionModalContent.Header>
      <BlockchainActionModalContent.Body>
        <Item variant="unstyled">
          <Item.Avatar size={84}>
            <StackedAssetMedia
              assets={assets.slice(actionIndex)}
              isLoading={!!transactionHash}
              type="collection"
              variant="small"
            />
          </Item.Avatar>
          <Item.Content>
            <Item.Title data-testid="asset-approval-collection">
              <Text asChild size="medium" weight="semibold">
                <div>
                  <CollectionLink
                    assetContract={asset.assetContract}
                    collection={asset.collection}
                    linkStyle={{
                      color: "inherit",
                      fontSize: "inherit",
                      fontWeight: "inherit",
                    }}
                    target="_blank"
                    withTooltip
                  />
                </div>
              </Text>
            </Item.Title>
            {transactionHash && (
              <Item.Description>
                <Link
                  href={getTransactionUrl(
                    asset.chain.identifier,
                    transactionHash,
                  )}
                >
                  {t("explorerLink", "View on {{explorerLink}}", {
                    explorerLink: getBlockExplorerName(asset.chain.identifier),
                  })}
                </Link>
              </Item.Description>
            )}
          </Item.Content>

          {actions.length > 1 && (
            <Item.Side>
              <Text
                asChild
                data-testid="asset-approval-progress"
                size="medium"
                weight="semibold"
              >
                <div>
                  {t("progressText", "{{currentNumber}} of {{totalNumber}}", {
                    currentNumber: actionIndex + 1,
                    totalNumber: actions.length,
                  })}
                </div>
              </Text>
            </Item.Side>
          )}
        </Item>

        <BlockchainActionModalContent.Body.Separator />

        <BlockchainActionModalContent.Body.GoToWallet />
        <BlockchainActionModalContent.Body.Text>
          {t(
            "blockchain.approve.description",
            "You'll be asked to approve this collection from your wallet. You only need to approve each collection once.",
          )}
        </BlockchainActionModalContent.Body.Text>
        {!progress && (
          <BlockchainActionModalContent.Body.Button onClick={attemptAction}>
            {t("blockchain.approve.cta", "Continue")}
          </BlockchainActionModalContent.Body.Button>
        )}
      </BlockchainActionModalContent.Body>
    </BlockchainActionModalContent>
  )
}
