import { useCallback } from "react"
import { some } from "lodash"
import {
  FieldPath,
  FieldValues,
  RefCallBack,
  RegisterOptions,
  useForm as useFormNative,
  UseFormRegisterReturn,
  UseFormReturn as UseFormNativeReturn,
  UseFormProps,
} from "react-hook-form"

type CustomUseFormRegisterReturn = Omit<UseFormRegisterReturn, "ref"> & {
  inputRef: RefCallBack
  id: string
}

type CustomUseFormRegister<TFieldValues extends FieldValues> = <
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  name: TFieldName,
  options?: RegisterOptions<TFieldValues, TFieldName>,
) => CustomUseFormRegisterReturn

export type UseFormReturn<
  TFieldValues extends FieldValues = FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
> = Omit<
  UseFormNativeReturn<TFieldValues, TContext, TTransformedValues>,
  "register"
> & {
  register: CustomUseFormRegister<TFieldValues>
}

/**
 * Slightly modifies the behaviour of "register" callback to be compatible
 * with our {@link Input} component which uses the "inputRef" prop for the
 * native input element. Additionally, returns the "id" as a "name" prop mirror
 * to avoid duplication and keep them in sync.
 *
 * If any re-rendering issues occur when referencing formState fields, try using
 * {@link react-hook-form#useFormState} instead to listen for specific fields.
 */
export const useForm = <
  TFieldValues extends FieldValues = FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
>(
  props?: UseFormProps<TFieldValues, TContext>,
): UseFormReturn<TFieldValues, TContext, TTransformedValues> => {
  const { register: nativeRegister, ...rest } = useFormNative<
    TFieldValues,
    TContext,
    TTransformedValues
  >(props)

  const register = useCallback<CustomUseFormRegister<TFieldValues>>(
    (...registerParams) => {
      const { ref, ...rest } = nativeRegister(...registerParams)
      return { inputRef: ref, id: rest.name, ref, ...rest }
    },
    [nativeRegister],
  )

  return { ...rest, register }
}

export const deepSearchForAnyDirtyField = (
  dirtyFields: object | undefined,
): boolean => {
  if (!dirtyFields) {
    return false
  }
  return some(dirtyFields, (value: object | boolean) => {
    if (typeof value === "object") {
      return deepSearchForAnyDirtyField(value)
    } else {
      return value
    }
  })
}
