import { getTrackingFn } from "../utils"

export const trackClickBulkCancelButton = getTrackingFn<{
  address?: string
}>("click bulk cancel button")

export const trackBulkCancelSuccess = getTrackingFn<{
  address?: string
}>("bulk cancel success")

export const trackBulkCancelFailure = getTrackingFn<{
  address?: string
}>("bulk cancel failure")
