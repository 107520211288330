import { graphql } from "relay-runtime"
import { getTokenPricePayment } from "@/components/assets/price/TokenPrice.react"
import { readOfferDataToAcceptPerUnitPrice_offerToAccept$data } from "@/lib/graphql/__generated__/readOfferDataToAcceptPerUnitPrice_offerToAccept.graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"

type OrderDataToFillPrices = {
  price: BigNumber
  usdPrice: BigNumber
  tokenPricePayment: ReturnType<typeof getTokenPricePayment>
}

export const readOfferDataToAcceptPerUnitPrice = inlineFragmentize<
  readOfferDataToAcceptPerUnitPrice_offerToAccept$data,
  OrderDataToFillPrices
>(
  graphql`
    fragment readOfferDataToAcceptPerUnitPrice_offerToAccept on OfferToAcceptType
    @inline {
      orderData {
        perUnitPrice {
          usd
          unit
        }
        payment {
          ...TokenPricePayment
        }
      }
    }
  `,
  ({ orderData: { perUnitPrice, payment } }) => {
    const basePrice = bn(perUnitPrice.unit)
    const fiatBasePrice = bn(perUnitPrice.usd)

    const price = basePrice

    const usdPrice = fiatBasePrice

    const tokenPricePayment = getTokenPricePayment(payment)

    return {
      price,
      usdPrice,
      tokenPricePayment,
    }
  },
)
