import { ethers } from "ethers"
import { WALLET_NAME } from "@/constants/wallet"
import { Chain } from "@/hooks/useChains/types"
import BrowserWeb3Provider from "./browserWeb3Provider"
import { detectProvider } from "./detect"
import { PhantomSolanaWallet } from "./phantomSolanaProvider"
import {
  ETHERS_WEB3_PROVIDER_NETWORK,
  ExternalProvider,
} from "./web3EvmProvider"

type PhantomEthereumProvider = ExternalProvider & {
  isPhantom: boolean
}

declare global {
  interface Window {
    phantom?: {
      ethereum?: PhantomEthereumProvider
      solana?: PhantomSolanaWallet
    }
  }
}

const detectPhantomProvider = (timeout = 3000) => {
  return detectProvider({
    timeout,
    key: "phantom.ethereum" as keyof Window,
    isInstalled: (wallet: PhantomEthereumProvider | undefined) =>
      wallet !== undefined && wallet.isPhantom,
  })
}

class PhantomProvider extends BrowserWeb3Provider {
  public getName(): WALLET_NAME {
    return WALLET_NAME.Phantom
  }
}

export const createPhantomEvmProvider = async (chains: Chain[]) => {
  const ethereum = await detectPhantomProvider()
  if (ethereum) {
    return new PhantomProvider(
      new ethers.providers.Web3Provider(ethereum, ETHERS_WEB3_PROVIDER_NETWORK),
      chains,
    )
  }
  return undefined
}
