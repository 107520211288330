import React, { useCallback } from "react"
import { useFragment } from "react-relay"
import { MintYourOwnProgress } from "@/features/mint-your-own/components/MintYourOwnProgress"
import { LOADING_CONTENT_STATE } from "@/features/mint-your-own/components/MintYourOwnProgress/MintYourOwnProgress.react"
import { useTranslate } from "@/hooks/useTranslate"
import { MintYourOwnCollectionAction_data$key } from "@/lib/graphql/__generated__/MintYourOwnCollectionAction_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import {
  BlockchainActionModalContent,
  BaseBlockchainActionProps,
} from "./BlockchainActionModalContent.react"
import { useBlockchainActionProgress } from "./useBlockchainActionProgress"
import { useHandleBlockchainActions } from "./useHandleBlockchainActions"

type MintYourOwnCollectionActionProps = BaseBlockchainActionProps & {
  dataKey: MintYourOwnCollectionAction_data$key
}

export const MintYourOwnCollectionAction = ({
  dataKey,
  onEnd,
}: MintYourOwnCollectionActionProps) => {
  const t = useTranslate("components")
  const data = useFragment<MintYourOwnCollectionAction_data$key>(
    graphql`
      fragment MintYourOwnCollectionAction_data on MintYourOwnCollectionActionType {
        __typename
        ...useHandleBlockchainActions_mint_your_own_collection
      }
    `,
    dataKey,
  )

  const { mintYourOwnCollection } = useHandleBlockchainActions()

  const executeAction = useCallback(async () => {
    const transaction = await mintYourOwnCollection(data)
    onEnd({ transaction })
  }, [data, onEnd, mintYourOwnCollection])

  const { attemptAction, progress } = useBlockchainActionProgress({
    executeAction,
    action: data,
  })

  return (
    <MintYourOwnProgress
      customRender={
        !progress && (
          <BlockchainActionModalContent.Body.Button onClick={attemptAction}>
            {t("blockchain.deployContract.cta", "Continue")}
          </BlockchainActionModalContent.Body.Button>
        )
      }
      state={LOADING_CONTENT_STATE.WAITING_SIGNATURE}
    />
  )
}
