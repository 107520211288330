export const capitalize = (str: string): string => {
  if (!str) {
    return str
  }
  return `${str[0].toLocaleUpperCase()}${str.substring(1)}`
}

export const removeLeadingAts = (str: string): string => str.replace(/^@+/, "")

export const checkAndReplace = (
  str: string,
  toCheck: string,
  replaceWith: string,
) => {
  if (!str) {
    return str
  }

  return str.split(toCheck).join(replaceWith)
}

export function snakeCaseToCapitalCase(str: string) {
  if (!str) {
    return str
  }

  return checkAndReplace(str, "_", " ").split(" ").map(capitalize).join(" ")
}

export function snakeCaseToSentenceCase(str: string) {
  return capitalize(checkAndReplace(str, "_", " ").toLowerCase())
}

export function truncateText(str: string, maxLength = 50): string {
  if (!str || str.length <= maxLength) {
    return str
  }
  return str.substr(0, maxLength) + "..."
}

export function truncateTextInMiddle(
  str: string,
  { before, after }: { before: number; after: number },
): string {
  if (str.length <= before) {
    return str
  }

  return (
    truncateText(str, before) + str.substring(str.length - after, str.length)
  )
}

export const isAscii = (str: string): boolean => /^[\x00-\x7F]*$/.test(str)

export const isPrintableAscii = (str: string): boolean =>
  /^[\x1F-\x7F]*$/.test(str)

export const isAsciiLettersOrDigits = (str: string): boolean =>
  /^[A-Za-z0-9]*$/.test(str)

export const strip = (str: string): string => str.replace(/^\s+|\s+$/g, "")

export const EN_DASH = "–"
export const EM_DASH = "—"
export const NBSP = "\xa0"

export const getTokenIdSuffix = (tokenId: string) =>
  tokenId.substring(tokenId.length - 8)
