import React, { useEffect } from "react"
import { Icon, UnstyledButton, Text, FlexColumn, Flex } from "@opensea/ui-kit"
import { graphql, useQueryLoader } from "react-relay"
import styled from "styled-components"
import { InfoIcon } from "@/components/common/InfoIcon.react"
import { trackClickArrow } from "@/components/nav/WalletPopover/analytics"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { useMountEffect } from "@/hooks/useMountEffect"
import { BridgeOrWrapBalancesQuery } from "@/lib/graphql/__generated__/BridgeOrWrapBalancesQuery.graphql"
import { isPolygon, isSolana } from "@/lib/helpers/chainUtils"
import { BridgeOrWrapDirection } from "../BridgeOrWrapForm/BridgeOrWrapForm.react"
import { NativeFundsDisplay } from "../WalletAndAccountButton/components/NativeFundsDisplay.react"
import { BridgeOrWrapBalancesFundsDisplay } from "./components/BridgeOrWrapBalancesFundsDisplay.react"
import {
  ARROW_ICON_SIZE,
  ARROW_BUTTON_PADDING,
  ARROW_BUTTON_SIZE,
} from "./constants"
import { useCurrencyTitles } from "./hooks/useCurrencyTitles.react"
import { useGetQueryArgs } from "./hooks/useGetQueryArgs"

export const BRIDGE_OR_WRAP_BALANCES_QUERY = graphql`
  query BridgeOrWrapBalancesQuery(
    $address: AddressScalar!
    $wrappedCurrencySymbol: String!
    $wrappedCurrencyChain: ChainScalar!
    $baseCurrencySymbol: String!
    $baseCurrencyChain: ChainScalar!
    $baseCurrencyIsChainNativeCurrency: Boolean!
  ) {
    ...BridgeOrWrapBalancesFundsDisplay_data
      @arguments(
        address: $address
        wrappedCurrencySymbol: $wrappedCurrencySymbol
        wrappedCurrencyChain: $wrappedCurrencyChain
        baseCurrencySymbol: $baseCurrencySymbol
        baseCurrencyChain: $baseCurrencyChain
        baseCurrencyIsChainNativeCurrency: $baseCurrencyIsChainNativeCurrency
      )
  }
`

type BridgeOrWrapBalancesProps = {
  direction: BridgeOrWrapDirection
  displayArrow: boolean
  setDirection: (direction: BridgeOrWrapDirection) => unknown
}

export const BridgeOrWrapBalances = ({
  direction,
  displayArrow,
  setDirection,
}: BridgeOrWrapBalancesProps) => {
  const { chain: activeChain, wallet } = useWallet()

  const { baseCurrencyTitle, wrappedCurrencyTitle, wrappedCurrencyTitleInfo } =
    useCurrencyTitles(activeChain)

  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<BridgeOrWrapBalancesQuery>(BRIDGE_OR_WRAP_BALANCES_QUERY)

  const getQueryArgs = useGetQueryArgs()

  useMountEffect(() => {
    const unsubWallet = wallet.onChange(() => {
      const queryArgs = getQueryArgs()
      if (queryArgs) {
        loadQuery(queryArgs)
      }
    })

    return () => {
      disposeQuery()
      unsubWallet()
    }
  })

  useEffect(() => {
    const queryArgs = getQueryArgs()
    if (queryArgs) {
      loadQuery(queryArgs)
    }
  }, [getQueryArgs, loadQuery])

  const queryArgs = getQueryArgs()

  return (
    <Flex className="relative">
      <FlexColumn className="flex-1 items-center">
        <Text.Heading size="small">
          {queryArgs?.baseCurrencyIsChainNativeCurrency ? (
            <NativeFundsDisplay />
          ) : (
            <BridgeOrWrapBalancesFundsDisplay
              queryReference={queryReference}
              variant="base"
            />
          )}
        </Text.Heading>
        <Text.Body className="text-secondary" size="medium">
          {baseCurrencyTitle}
        </Text.Body>
      </FlexColumn>
      <FlexColumn className="flex-1 items-center">
        <Text.Heading size="small">
          <BridgeOrWrapBalancesFundsDisplay
            queryReference={queryReference}
            variant="wrapped"
          />
        </Text.Heading>
        <Text.Body className="text-secondary" size="medium">
          <Flex className="items-center gap-1">
            {wrappedCurrencyTitle}
            {wrappedCurrencyTitleInfo && (
              <InfoIcon
                overrides={{
                  Icon: { size: 16 },
                  Tooltip: {
                    contentPadding: "12px",
                    interactive: true,
                    maxWidth: 500,
                    placement: "bottom-end",
                  },
                }}
                tooltipContent={wrappedCurrencyTitleInfo}
              />
            )}
          </Flex>
        </Text.Body>
      </FlexColumn>
      {displayArrow && (
        <ArrowButton
          as={UnstyledButton}
          direction={direction}
          disabled={isPolygon(activeChain) || isSolana(activeChain)}
          onClick={() => {
            trackClickArrow()
            setDirection(direction === "out" ? "in" : "out")
          }}
        >
          <Icon size={ARROW_ICON_SIZE} value="arrow_forward" />
        </ArrowButton>
      )}
    </Flex>
  )
}

type ArrowButtonProps = {
  direction: BridgeOrWrapDirection
}

const ArrowButton = styled(UnstyledButton)<ArrowButtonProps>`
  border-radius: ${props => props.theme.borderRadius.circle};
  padding: ${ARROW_BUTTON_PADDING}px;

  position: absolute;
  top: calc(50% - ${ARROW_BUTTON_SIZE / 2}px);
  left: calc(50% - ${ARROW_BUTTON_SIZE / 2}px);

  transform: ${props =>
    props.direction === "out" ? undefined : "rotate(180deg)"};

  transition:
    transform 0.25s ease-in-out,
    background-color 0.25s ease-in-out;

  :hover:not([disabled]),
  :focus {
    background-color: ${props =>
      props.theme.colors.components.background.gray1};
  }
`
