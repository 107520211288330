/**
 * @generated SignedSource<<b9ed4a880f25b04d15e42c8e519fee16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useDropStages$data = {
  readonly endTime: string;
  readonly startTime: string;
  readonly " $fragmentType": "useDropStages";
};
export type useDropStages$key = {
  readonly " $data"?: useDropStages$data;
  readonly " $fragmentSpreads": FragmentRefs<"useDropStages">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useDropStages"
};

(node as any).hash = "5790f6551678b68a86a111d8d2c0022d";

export default node;
