/**
 * @generated SignedSource<<cd1e219188380aedbaede0d87a39aedc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type readOfferDataToAcceptPerUnitPrice_offerToAccept$data = {
  readonly orderData: {
    readonly payment: {
      readonly " $fragmentSpreads": FragmentRefs<"TokenPricePayment">;
    };
    readonly perUnitPrice: {
      readonly unit: string;
      readonly usd: string;
    };
  };
  readonly " $fragmentType": "readOfferDataToAcceptPerUnitPrice_offerToAccept";
};
export type readOfferDataToAcceptPerUnitPrice_offerToAccept$key = {
  readonly " $data"?: readOfferDataToAcceptPerUnitPrice_offerToAccept$data;
  readonly " $fragmentSpreads": FragmentRefs<"readOfferDataToAcceptPerUnitPrice_offerToAccept">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readOfferDataToAcceptPerUnitPrice_offerToAccept"
};

(node as any).hash = "c606046384f9b554b3069914917de771";

export default node;
