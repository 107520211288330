import { useCallback } from "react"
import { WALLET_NAME } from "@/constants/wallet"
import { useWalletModal } from "@/containers/WalletModalProvider.react"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { useOSWalletTrigger } from "@/features/os-wallet/hooks/useOSWalletTrigger"
import { getOrSetDeviceId } from "@/lib/auth/storage"
import { useLoginWithPrivyToken } from "./useLoginWithPrivyToken"
import { useSetEmbeddedWallet } from "./useSetEmbeddedWallet"

/**
 * Returns a function to complete login or signup flow to OS wallet:
 * 1. Email login with auth code
 * 2. [signup] MFA auth code enrolled
 * 3. MFA auth code confirmed
 * 4. [login] open OS wallet
 */
export const useAuthenticateOSWallet = () => {
  const { wallet, setPrivyAccessToken } = useWallet()
  const { openOSWallet } = useOSWalletTrigger()
  const { closeWalletModal } = useWalletModal()
  const loginWithPrivyToken = useLoginWithPrivyToken()
  const setEmbeddedWallet = useSetEmbeddedWallet()

  return useCallback(
    async ({
      accessToken,
      privyId,
      shouldOpenWalletAfterAuth = true,
    }: {
      accessToken: string
      privyId: string
      shouldOpenWalletAfterAuth?: boolean
    }) => {
      closeWalletModal()
      if (shouldOpenWalletAfterAuth) {
        openOSWallet()
      }

      try {
        const deviceId = getOrSetDeviceId()
        await loginWithPrivyToken({ deviceId, accessToken })
        setPrivyAccessToken(accessToken)
      } catch {
        // If token exchange fails then signature challenge will be prompted
      }

      await wallet.install(WALLET_NAME.OpenSeaWallet)
      await setEmbeddedWallet({ privyId })
    },
    [
      closeWalletModal,
      loginWithPrivyToken,
      openOSWallet,
      setEmbeddedWallet,
      setPrivyAccessToken,
      wallet,
    ],
  )
}
