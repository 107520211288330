import {
  EquallySizedChainLogo,
  EquallySizedChainLogos,
} from "@/components/svgs/EquallySizedChainLogos/index"
import { SelectOption } from "@/design-system/Select"
import { getChainSlug } from "@/lib/helpers/chainUtils"
import { useMemo } from "react"
import { ChainIdentifier } from "./types"
import { useChains } from "./useChains"

const CHAIN_IDENTIFIER_LOGO_MAPPING: Record<
  ChainIdentifier,
  EquallySizedChainLogo | undefined
> = {
  ETHEREUM: EquallySizedChainLogos.Ethereum,
  KLAYTN: EquallySizedChainLogos.Klaytn,
  MATIC: EquallySizedChainLogos.Polygon,
  MUMBAI: EquallySizedChainLogos.Polygon,
  AMOY: EquallySizedChainLogos.Polygon,
  RINKEBY: EquallySizedChainLogos.Ethereum,
  GOERLI: EquallySizedChainLogos.Ethereum,
  SEPOLIA: EquallySizedChainLogos.Ethereum,
  SOLANA: EquallySizedChainLogos.Solana,
  SOLDEV: EquallySizedChainLogos.Solana,
  ARBITRUM: EquallySizedChainLogos.Arbitrum,
  ARBITRUM_GOERLI: EquallySizedChainLogos.Arbitrum,
  ARBITRUM_SEPOLIA: EquallySizedChainLogos.Arbitrum,
  ARBITRUM_NOVA: EquallySizedChainLogos.Arbitrum,
  OPTIMISM: EquallySizedChainLogos.Optimism,
  OPTIMISM_GOERLI: EquallySizedChainLogos.Optimism,
  OPTIMISM_SEPOLIA: EquallySizedChainLogos.Optimism,
  AVALANCHE: EquallySizedChainLogos.Avalanche,
  AVALANCHE_FUJI: EquallySizedChainLogos.Avalanche,
  BAOBAB: EquallySizedChainLogos.Klaytn,
  BSC: EquallySizedChainLogos.Binance,
  BSC_TESTNET: EquallySizedChainLogos.Binance,
  BASE: EquallySizedChainLogos.Base,
  BASE_GOERLI: EquallySizedChainLogos.Base,
  BASE_SEPOLIA: EquallySizedChainLogos.Base,
  BLAST: EquallySizedChainLogos.Blast,
  BLAST_SEPOLIA: EquallySizedChainLogos.Blast,
  GUNZILLA_TESTNET: EquallySizedChainLogos.Gunzilla,
  SEI: EquallySizedChainLogos.Sei,
  SEI_DEVNET: EquallySizedChainLogos.Sei,
  SEI_TESTNET: EquallySizedChainLogos.Sei,
  ZORA: EquallySizedChainLogos.Zora,
  ZORA_TESTNET: EquallySizedChainLogos.Zora,
  ZORA_SEPOLIA: EquallySizedChainLogos.Zora,
  B3_SEPOLIA: EquallySizedChainLogos.B3,
  B3: EquallySizedChainLogos.B3,
  FLOW_TESTNET: EquallySizedChainLogos.Flow,
  FLOW: EquallySizedChainLogos.Flow,
  APE_CURTIS: EquallySizedChainLogos.Ape,
  APE_CHAIN: EquallySizedChainLogos.Ape,
  SONEIUM_MINATO: EquallySizedChainLogos.Soneium,
  SONEIUM: EquallySizedChainLogos.Soneium,
  MONAD_TESTNET: EquallySizedChainLogos.Monad,
  RONIN: EquallySizedChainLogos.Ronin,
  SAIGON_TESTNET: EquallySizedChainLogos.Ronin,
  BERA_CHAIN: EquallySizedChainLogos.Bera,
  SHAPE: EquallySizedChainLogos.Shape,
  UNICHAIN: EquallySizedChainLogos.Unichain,
  // eslint-disable-next-line relay/no-future-added-value
  "%future added value": undefined,
}

// TODO: @mcabrams - this should be implemented in ChainLogo.tsx once design team
// decides how to consolidate across site.
export const getEquallySizedChainLogo = (chain: ChainIdentifier) => {
  return CHAIN_IDENTIFIER_LOGO_MAPPING[chain]
}

type Options = {
  useChainIdentifier?: boolean
  useChainFilter?: boolean
}

export const useChainOptions = (options?: Options) => {
  const { chains, getChainName, isChainFiltersEnabled } = useChains()
  const useChainFilter =
    options?.useChainFilter !== undefined ? options.useChainFilter : true

  return useMemo(
    () => [
      {
        label: "All chains",
        value: "all",
      },
      ...chains
        .filter(chain => (useChainFilter ? isChainFiltersEnabled(chain) : true))
        .map(chain => ({
          label: getChainName(chain),
          value: options?.useChainIdentifier ? chain : getChainSlug(chain),
          Logo: getEquallySizedChainLogo(chain),
        })),
    ],
    [chains, getChainName, options, useChainFilter, isChainFiltersEnabled],
  )
}

export const getSmallChainOptions = (chainOptions: SelectOption[]) =>
  chainOptions.map(obj => {
    return { ...obj, avatar: undefined }
  })
