/**
 * @generated SignedSource<<613788b6236ddece594b9f5a60cfea4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type walletBalanceQuery$variables = {
  address: string;
  paymentAssetId: string;
};
export type walletBalanceQuery$data = {
  readonly blockchain: {
    readonly balanceOf: {
      readonly unit: string;
    };
  };
};
export type walletBalanceQuery = {
  response: walletBalanceQuery$data;
  variables: walletBalanceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "address"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "paymentAssetId"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BlockchainType",
    "kind": "LinkedField",
    "name": "blockchain",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "identity",
            "variableName": "address"
          },
          {
            "kind": "Variable",
            "name": "paymentAsset",
            "variableName": "paymentAssetId"
          }
        ],
        "concreteType": "PriceType",
        "kind": "LinkedField",
        "name": "balanceOf",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unit",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "walletBalanceQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "walletBalanceQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8b5a8ee2d77267cbefc704af959f6406",
    "id": null,
    "metadata": {},
    "name": "walletBalanceQuery",
    "operationKind": "query",
    "text": "query walletBalanceQuery(\n  $paymentAssetId: PaymentAssetRelayID!\n  $address: AddressScalar!\n) {\n  blockchain {\n    balanceOf(paymentAsset: $paymentAssetId, identity: $address) {\n      unit\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d661a1913853c4a66211bc10952254b9";

export default node;
