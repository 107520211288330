import React from "react"

type Props = {
  className?: string
  width?: number
  fill?: string
}

export const PolygonLogo = ({
  className,
  fill = "gray",
  width = 20,
}: Props) => {
  return (
    <svg
      className={className}
      fill="none"
      height="24"
      style={{ width, height: width }}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0436 8.69125C16.6788 8.47701 16.203 8.47701 15.7986 8.69125L12.9437 10.3456L11.0048 11.4247L8.14995 13.0791C7.78516 13.2933 7.30935 13.2933 6.90491 13.0791L4.63689 11.7858C4.2721 11.5715 4.01437 11.1748 4.01437 10.7424V8.19137C4.01437 7.75893 4.23245 7.36616 4.63689 7.14796L6.86923 5.89032C7.23401 5.67608 7.70982 5.67608 8.11426 5.89032L10.3466 7.14796C10.7114 7.36219 10.9691 7.75893 10.9691 8.19137V9.84574L12.908 8.73092V7.07655C12.908 6.64411 12.69 6.25134 12.2855 6.03314L8.15391 3.66068C7.78912 3.44644 7.31331 3.44644 6.90888 3.66068L2.70589 6.03314C2.30145 6.24737 2.08337 6.64411 2.08337 7.07655V11.8572C2.08337 12.2896 2.30145 12.6824 2.70589 12.9006L6.91284 15.273C7.27763 15.4873 7.75344 15.4873 8.15788 15.273L11.0127 13.6544L12.9517 12.5396L15.8065 10.9209C16.1713 10.7067 16.6471 10.7067 17.0516 10.9209L19.2839 12.1785C19.6487 12.3928 19.9064 12.7895 19.9064 13.2219V15.7729C19.9064 16.2054 19.6883 16.5981 19.2839 16.8163L17.0516 18.1097C16.6868 18.3239 16.211 18.3239 15.8065 18.1097L13.5742 16.852C13.2094 16.6378 12.9517 16.2411 12.9517 15.8086V14.1543L11.0127 15.2691V16.9235C11.0127 17.3559 11.2308 17.7487 11.6353 17.9669L15.8422 20.3393C16.207 20.5536 16.6828 20.5536 17.0872 20.3393L21.2942 17.9669C21.659 17.7526 21.9167 17.3559 21.9167 16.9235V12.1428C21.9167 11.7104 21.6986 11.3176 21.2942 11.0994L17.0436 8.69125Z"
        fill={fill}
      />
    </svg>
  )
}
