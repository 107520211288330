import React, { forwardRef } from "react"
import { Media, TextHeadingProps, Text } from "@opensea/ui-kit"
import clsx from "clsx"
import { useScroll } from "react-use"
import styled from "styled-components"
import { Block, BlockProps } from "@/design-system/Block"
import { useModalContext } from "@/design-system/Modal/ModalContext"
import { useCallbackRef } from "@/hooks/useCallbackRef"
import { useLayoutEffect } from "@/hooks/useIsomorphicLayoutEffect"
import { useLockBodyScroll } from "@/hooks/useLockBodyScroll"
import { useSize } from "@/hooks/useSize"
import {
  MOBILE_MODAL_PADDING,
  MODAL_PADDING,
  CENTER_ALIGNED_MODAL_PADDING,
  CENTER_ALIGNED_MOBILE_MODAL_PADDING,
} from "../../constants"
import { ModalBodyProps } from "../../types"

const ModalBodyBase = ({
  centerAligned,
  children,
  className,
  lockBodyScroll = true,
  ...blockProps
}: ModalBodyProps) => {
  const [ref, setRef] = useCallbackRef<HTMLDivElement>()
  const [_, height] = useSize(ref)
  const { y } = useScroll(ref)

  const { setIsModalBodyScrolled, setIsModalBodyFullyScrolled } =
    useModalContext()

  // Prevents scrolling the html body behind the modal. Cannot lock body scroll
  // on the ModalDialog since it disables scrolling on the modal body on mobile.
  useLockBodyScroll(lockBodyScroll, ref)

  useLayoutEffect(() => {
    if (ref.current) {
      setIsModalBodyScrolled(y > 0)
    }
  }, [y, ref, setIsModalBodyScrolled])

  useLayoutEffect(() => {
    if (ref.current) {
      const isOverflowed = ref.current.scrollHeight > ref.current.clientHeight
      const isFullyScrolled =
        ref.current.offsetHeight + ref.current.scrollTop >=
        ref.current.scrollHeight

      setIsModalBodyFullyScrolled(isOverflowed ? isFullyScrolled : true)
    }
  }, [y, height, ref, setIsModalBodyFullyScrolled])

  return (
    <StyledModalBody
      className={className}
      paddingX={
        centerAligned
          ? {
              _: CENTER_ALIGNED_MOBILE_MODAL_PADDING,
              md: CENTER_ALIGNED_MODAL_PADDING,
            }
          : { _: MOBILE_MODAL_PADDING, sm: MODAL_PADDING }
      }
      paddingY={centerAligned ? 0 : MOBILE_MODAL_PADDING}
      ref={setRef}
      {...blockProps}
    >
      {children}
    </StyledModalBody>
  )
}

const ModalBodyTitle = ({
  children,
  className,
  ...textProps
}: Omit<TextHeadingProps, "size">) => {
  const commonProps = {
    ...textProps,
    role: "heading",
  } as Partial<TextHeadingProps>
  return (
    <>
      <Media greaterThanOrEqual="xl">
        {mediaClassNames => (
          <Text.Heading
            asChild
            {...commonProps}
            className={clsx("text-center", mediaClassNames, className)}
            size="large"
          >
            <h4>{children}</h4>
          </Text.Heading>
        )}
      </Media>
      <Media between={["md", "xl"]}>
        {mediaClassNames => (
          <Text.Heading
            asChild
            {...commonProps}
            className={clsx("text-center", mediaClassNames, className)}
            size="medium"
          >
            <h4>{children}</h4>
          </Text.Heading>
        )}
      </Media>
      <Media lessThan="md">
        {mediaClassNames => (
          <Text.Heading
            {...commonProps}
            asChild
            className={clsx("text-center", mediaClassNames, className)}
            size="small"
          >
            <h4>{children}</h4>
          </Text.Heading>
        )}
      </Media>
    </>
  )
}

export const StyledModalBody = styled(Block).attrs<BlockProps>({
  as: "section",
})<BlockProps>`
  height: ${props => props.height ?? "100%"};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`

const ModalBodyHeading = forwardRef<
  HTMLDivElement,
  Omit<TextHeadingProps, "size" | "weight">
>(function ModalBodyHeader({ children, ...props }, ref) {
  return (
    <StyledModalBodyHeading asChild ref={ref} size="medium" {...props}>
      <div>{children}</div>
    </StyledModalBodyHeading>
  )
})

const StyledModalBodyHeading = styled(Text.Heading)`
  text-align: center;
  width: 100%;
  word-break: break-word;
`

export const ModalBody = Object.assign(ModalBodyBase, {
  Title: ModalBodyTitle,
  Heading: ModalBodyHeading,
})
