import { IncomingMessage } from "http"
import { breakpoints } from "@opensea/ui-kit"
import { UAParser } from "ua-parser-js"
import { IS_SERVER } from "@/constants/environment"
import { memoizeOne } from "./memoization"

type OS =
  | "AIX"
  | "Amiga OS"
  | "Android"
  | "Arch"
  | "Bada"
  | "BeOS"
  | "BlackBerry"
  | "CentOS"
  | "Chromium OS"
  | "Contiki"
  | "Fedora"
  | "Firefox OS"
  | "FreeBSD"
  | "Debian"
  | "DragonFly"
  | "Gentoo"
  | "GNU"
  | "Haiku"
  | "Hurd"
  | "iOS"
  | "Joli"
  | "Linpus"
  | "Linux"
  | "Mac OS"
  | "Mageia"
  | "Mandriva"
  | "MeeGo"
  | "Minix"
  | "Mint"
  | "Morph OS"
  | "NetBSD"
  | "Nintendo"
  | "OpenBSD"
  | "OpenVMS"
  | "OS/2"
  | "Palm"
  | "PCLinuxOS"
  | "Plan9"
  | "Playstation"
  | "QNX"
  | "RedHat"
  | "RIM Tablet OS"
  | "RISC OS"
  | "Sailfish"
  | "Series40"
  | "Slackware"
  | "Solaris"
  | "SUSE"
  | "Symbian"
  | "Tizen"
  | "Ubuntu"
  | "UNIX"
  | "VectorLinux"
  | "WebOS"
  | "Windows [Phone/Mobile]"
  | "Zenwalk"

/**
 * Get boolean for whether the client or server is a desktop
 * @param {object} request node request instance
 */
export function clientIsDesktop(
  request: IncomingMessage | undefined = undefined,
) {
  if (request) {
    const ua = request.headers["user-agent"]

    if (!ua) {
      return false
    }

    const userAgent = parseUserAgent(ua)

    return !(
      userAgent.getDevice().type === "mobile" ||
      userAgent.getDevice().type === "tablet"
    )
  }

  if (IS_SERVER) {
    return true
  }

  // on client
  return document.body.clientWidth > breakpoints.lg
}

const parseUserAgent = memoizeOne((userAgent: string | undefined) => {
  return new UAParser(userAgent)
})

export const isMobileDevice = (userAgent: string | undefined) => {
  return parseUserAgent(userAgent).getDevice().type === "mobile"
}

export const getDeviceOS = (userAgent: string | undefined) => {
  if (!userAgent) {
    return undefined
  }
  return parseUserAgent(userAgent).getOS().name as OS
}

export const getDeviceOSClient = () => {
  return getDeviceOS(window.navigator.userAgent)
}

export const getDeviceType = (userAgent: string | undefined) => {
  return parseUserAgent(userAgent).getDevice().type
}
