import React from "react"
import { HUES } from "../../styles/themes"

type Props = {
  className?: string
  width?: number
  height?: number
  fill?: string
}

export const WebsiteExternalIcon = ({
  className,
  fill = HUES.gray,
  height,
  width,
}: Props) => (
  <svg
    className={className}
    fill="none"
    style={{ height, width }}
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.45026 13.3164C1.95026 13.3164 1.53093 13.1469 1.19226 12.8078C0.853149 12.4691 0.683594 12.0498 0.683594 11.5498V2.44977C0.683594 1.94977 0.853149 1.53022 1.19226 1.19111C1.53093 0.852439 1.95026 0.683105 2.45026 0.683105H7.00026V2.44977H2.45026V11.5498H11.5503V6.99977H13.3169V11.5498C13.3169 12.0498 13.1476 12.4691 12.8089 12.8078C12.4698 13.1469 12.0503 13.3164 11.5503 13.3164H2.45026ZM5.76693 9.46644L4.53359 8.23311L10.3336 2.44977H8.33359V0.683105H13.3169V5.66644H11.5503V3.66644L5.76693 9.46644Z"
      fill={fill}
    />
  </svg>
)
