/**
 * @generated SignedSource<<671ecd41114fcdf7b337fce71520d7ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type BulkPurchaseErrorReason = "ORDER_UNAVAILABLE" | "ORDER_UPDATED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ShoppingCartContextProvider_inline_error$data = {
  readonly originalOrder: {
    readonly relayId: string;
  };
  readonly reason: BulkPurchaseErrorReason;
  readonly updatedOrder: {
    readonly " $fragmentSpreads": FragmentRefs<"ShoppingCartContextProvider_inline_order">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ShoppingCart_errors">;
  readonly " $fragmentType": "ShoppingCartContextProvider_inline_error";
};
export type ShoppingCartContextProvider_inline_error$key = {
  readonly " $data"?: ShoppingCartContextProvider_inline_error$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShoppingCartContextProvider_inline_error">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ShoppingCartContextProvider_inline_error"
};

(node as any).hash = "35cc5bd3c86560b6ec5fbb3bae742dd9";

export default node;
