import React from "react"

type Props = {
  height?: number
  width?: number
}

export const CheckCircleFilledIcon = ({ height = 24, width = 24 }: Props) => (
  <svg
    height={height}
    viewBox="0 0 32 32"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 2.66667C8.63999 2.66667 2.66666 8.64001 2.66666 16C2.66666 23.36 8.63999 29.3333 16 29.3333C23.36 29.3333 29.3333 23.36 29.3333 16C29.3333 8.64001 23.36 2.66667 16 2.66667Z"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    />
    <path
      clipRule="evenodd"
      d="M2.66666 16C2.66666 8.64001 8.63999 2.66667 16 2.66667C23.36 2.66667 29.3333 8.64001 29.3333 16C29.3333 23.36 23.36 29.3333 16 29.3333C8.63999 29.3333 2.66666 23.36 2.66666 16ZM13.3333 18.8933L22.12 10.1067L24 12L13.3333 22.6667L7.99999 17.3333L9.87999 15.4533L13.3333 18.8933Z"
      fill="#34C77B"
      fillRule="evenodd"
      xmlns="http://www.w3.org/2000/svg"
    />
  </svg>
)
