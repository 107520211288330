import {
  BigNumber,
  MAX_DISPLAYED_DECIMAL_PLACES,
} from "@/lib/helpers/numberUtils"

export const formatQuantity = (quantity: BigNumber) => {
  const quantityRoundedDown = quantity.decimalPlaces(
    MAX_DISPLAYED_DECIMAL_PLACES,
    BigNumber.ROUND_DOWN,
  )

  if (!quantity.isZero() && quantityRoundedDown.isZero()) {
    return quantity.toPrecision(1).toString()
  }

  return quantityRoundedDown.toString()
}
