/**
 * @generated SignedSource<<23f8f0a499fca28edf4bd9e03b42539e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useHandleBlockchainActions_approve_asset$data = {
  readonly method: {
    readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_transaction">;
  };
  readonly " $fragmentType": "useHandleBlockchainActions_approve_asset";
};
export type useHandleBlockchainActions_approve_asset$key = {
  readonly " $data"?: useHandleBlockchainActions_approve_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_approve_asset">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useHandleBlockchainActions_approve_asset"
};

(node as any).hash = "c1756ac14b262148a401b3acb42c55a8";

export default node;
