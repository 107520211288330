import React from "react"
import { classNames, Flex, FlexColumn, Input, Text } from "@opensea/ui-kit"
import { Link } from "@/components/common/Link"
import { PRIVACY_POLICY_URL } from "@/constants"
import { Button } from "@/design-system/Button"
import { Form } from "@/design-system/Form"
import { FormControl } from "@/design-system/FormControl"
import {
  PRIVY_TERMS_URL,
  WALLET_TERMS_OF_SERVICE_URL,
} from "@/features/os-wallet/components/constants"
import { useForm } from "@/hooks/useForm"
import { useTranslate } from "@/hooks/useTranslate"
import { EMAIL_VALIDATION_RULE } from "@/lib/helpers/validation"

type Props = {
  className?: string
  onEmailSubmit: (email: string) => Promise<void>
}

type FormData = {
  email: string
}

export const OSLoginClassic = ({ className, onEmailSubmit }: Props) => {
  const t = useTranslate("common")
  const validateEmailDomain = useValidateEmailDomain()
  const {
    formState: { errors, isSubmitting, isValid },
    register,
    handleSubmit,
  } = useForm<FormData>({
    mode: "onSubmit",
  })

  const onSubmit = handleSubmit(async ({ email }) => await onEmailSubmit(email))

  return (
    <FlexColumn className={classNames("w-full", className)}>
      <Flex className="items-center gap-2">
        <Text.Heading asChild responsive size="small">
          <h2>{t("wallet.opensea.connect", "Connect to OpenSea")}</h2>
        </Text.Heading>
      </Flex>
      <Form className="mt-4 flex w-full gap-3" onSubmit={onSubmit}>
        <FormControl
          className="w-full"
          error={errors.email?.message}
          hideLabel
          label={t("wallet.opensea.email", "Email")}
        >
          <Input
            autoComplete="email"
            className="flex-1 bg-white py-2.5 dark:bg-component-gray-1"
            disabled={isSubmitting}
            placeholder={t("wallet.opensea.email", "Email")}
            type="email"
            {...register("email", {
              required: t("wallet.opensea.emailRequired", "Email is required."),
              pattern: EMAIL_VALIDATION_RULE,
              validate: {
                domain: validateEmailDomain,
              },
            })}
          />
        </FormControl>
        <Button
          aria-label={t("wallet.opensea.login", "Login")}
          // self-start prevents button from stretching vertically when error is shown.
          className="flex-none self-start"
          disabled={!isValid || isSubmitting}
          icon="arrow_forward"
          isLoading={isSubmitting}
          type="submit"
          variant="primary"
        />
      </Form>
      <Text className="mt-4" color="secondary" size="tiny">
        {t(
          "wallet.opensea.loginTerms",
          "By continuing you agree to our {{termsLink}} & {{privacyPolicyLink}} and Privy's {{privyTermsLink}}.",
          {
            termsLink: (
              <Link
                className="text-secondary"
                href={WALLET_TERMS_OF_SERVICE_URL}
                target="_blank"
                variant="subtle"
              >
                {t("wallet.opensea.terms", "Terms")}
              </Link>
            ),
            privacyPolicyLink: (
              <Link
                className="text-secondary"
                href={PRIVACY_POLICY_URL}
                target="_blank"
                variant="subtle"
              >
                {t("wallet.opensea.privacyPolicy", "Privacy Policy")}
              </Link>
            ),
            privyTermsLink: (
              <Link
                className="text-secondary"
                href={PRIVY_TERMS_URL}
                variant="subtle"
              >
                {t("wallet.opensea.terms", "Terms")}
              </Link>
            ),
          },
        )}
      </Text>
    </FlexColumn>
  )
}

const INVALID_EMAIL_DOMAIN_REGEX: RegExp = /\.(cu|ir|sy|kp)$/gi

const useValidateEmailDomain = () => {
  const t = useTranslate("common")

  return (email: string) =>
    !INVALID_EMAIL_DOMAIN_REGEX.test(email) ||
    t("wallet.opensea.emailDomain", "Email domain is not supported.")
}
