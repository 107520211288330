import { useChains } from "@/hooks/useChains"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { useTranslate } from "@/hooks/useTranslate"
import { isPolygon, isSolana } from "@/lib/helpers/chainUtils"
import { POLYGON_BRIDGE_LINK } from "../../../../../../constants"
import { BridgeOrWrapDirection } from "../BridgeOrWrapForm.react"
import { Tab } from "../components/Tabs.react"

export const useDirectionOptions = (
  chain: ChainIdentifier | null | undefined,
): Tab<BridgeOrWrapDirection>[] => {
  const t = useTranslate("components")
  const { getChainName } = useChains()

  if (!chain) {
    return [
      { value: "out", label: t("bridgeOrWrapForm.wrap", "Wrap") },
      { value: "in", label: t("bridgeOrWrapForm.unwrap", "Unwrap") },
    ]
  }

  if (isSolana(chain)) {
    return [
      {
        value: "out",
        label: t("bridgeOrWrapForm.wrap", "Wrap"),
        disabledReason: t(
          "bridgeOrWrapForm.wrapUnsupported",
          "Only unwrapping is supported on {{network}}.",
          { network: getChainName(chain) },
        ),
      },
      { value: "in", label: t("bridgeOrWrapForm.unwrap", "Unwrap") },
    ]
  }

  if (isPolygon(chain)) {
    return [
      {
        value: "out",
        label: t("bridgeOrWrapForm.bridgeToPolygon", "Bridge to Polygon"),
      },
      {
        value: "in",
        label: t("bridgeOrWrapForm.bridgeToEthereum", "Bridge to Ethereum"),
        href: POLYGON_BRIDGE_LINK,
      },
    ]
  }

  return [
    { value: "out", label: t("bridgeOrWrapForm.wrap", "Wrap") },
    { value: "in", label: t("bridgeOrWrapForm.unwrap", "Unwrap") },
  ]
}
