import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { noop } from "lodash"
import { createContext } from "use-context-selector"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { useTheme } from "@/design-system/Context"
import { useClickAway } from "@/hooks/useClickAway"
import { useIsOpen } from "@/hooks/useIsOpen"
import { asyncNoop } from "@/lib/helpers/functions"
import { useIsOSWalletEnabled } from "./flags"
import { useVessel } from "./hooks/useVessel"
import { OSWalletIFramePopover } from "./OSWalletIFramePopover.react"
import { setExternalOSWalletData } from "./storage"

type OSWalletContextType = {
  openOSWallet: () => void
  closeOSWallet: () => void
  toggleOSWallet: () => void
  loginTriggered: boolean
  /**
   * Element that popover should align itself below
   */
  anchorRef: React.RefObject<HTMLElement>
  sendLoginCode: (email: string) => Promise<void>
  loginWithCode: (
    code: string,
  ) => Promise<{ accessToken: string; privyId: string; isNewUser: boolean }>
  createEmbeddedWallet: () => Promise<void>
  checkMfaEnrollment: () => Promise<{ isReady: boolean; isEnrolled: boolean }>
  initMfaEnrollmentWithSms: (phoneNumber: string) => Promise<void>
  submitMfaEnrollmentWithSms: (
    phoneNumber: string,
    mfaCode: string,
  ) => Promise<void>
  initMfaEnrollmentWithTotp: () => Promise<{
    secret: string
    authUrl: string
  }>
  submitMfaEnrollmentWithTotp: (mfaCode: string) => Promise<void>
}

const DEFAULT_CONTEXT: OSWalletContextType = {
  openOSWallet: noop,
  closeOSWallet: noop,
  toggleOSWallet: noop,
  loginTriggered: false,
  anchorRef: { current: null },
  sendLoginCode: asyncNoop,
  loginWithCode: async () => ({
    accessToken: "",
    privyId: "",
    isNewUser: true,
  }),
  createEmbeddedWallet: asyncNoop,
  checkMfaEnrollment: async () => ({
    isReady: false,
    isEnrolled: false,
  }),
  initMfaEnrollmentWithSms: asyncNoop,
  submitMfaEnrollmentWithSms: asyncNoop,
  initMfaEnrollmentWithTotp: async () => ({
    secret: "",
    authUrl: "",
  }),
  submitMfaEnrollmentWithTotp: asyncNoop,
}

export const OSWalletContext = createContext(DEFAULT_CONTEXT)

type OSWalletContextProviderProps = {
  children: React.ReactNode
  deviceId: string
}

export const OSWalletContextProvider = ({
  children,
  deviceId,
}: OSWalletContextProviderProps) => {
  const isOSWalletEnabled = useIsOSWalletEnabled()
  const { theme } = useTheme()
  const { providers, wallet, setPrivyAccessToken } = useWallet()
  const {
    isOpen,
    open: openOSWallet,
    close: closeOSWallet,
    toggle: toggleOSWallet,
  } = useIsOpen(false)

  const anchorRef = useRef<HTMLElement>(null)

  const [allowClickAway, setAllowClickAway] = useState(true)
  const [loginTriggered, setLoginTriggered] = useState(false)

  const { preventClickAwayRefCallback: keepWalletOpenOnClickCallbackRef } =
    useClickAway(anchorRef, () => {
      if (allowClickAway) {
        closeOSWallet()
      }
    })

  // Needs to trigger the child provider WalletModalProvider to initiate the login flow.
  const triggerOSLogin = useCallback(async () => {
    setLoginTriggered(true)
  }, [])
  useEffect(() => {
    if (loginTriggered) {
      setLoginTriggered(false)
    }
  }, [loginTriggered])

  const { initVesselCallbackRef, emitEvent, vesselConnected, triggerAction } =
    useVessel({
      openOSWallet,
      closeOSWallet,
      triggerOSLogin,
      setAllowClickAway,
    })

  const installedWallets = useMemo(() => {
    return providers.map(provider => provider.getName())
  }, [providers])

  // Set external OS wallet data cookie and emit event to opensea-wallet when wallet changes
  useEffect(() => {
    const unsubscribe = wallet.onChange(async () => {
      const activeChain = await wallet.getChain()
      await setExternalOSWalletData({
        activeAccount: wallet.activeAccount
          ? {
              address: wallet.activeAccount.address,
              walletName: wallet.activeAccount.walletName,
            }
          : undefined,
        activeChain,
        installedWallets,
        theme,
        deviceId,
      })
      emitEvent({ type: "event", event: "CookieDataChange" })
    })

    return () => {
      unsubscribe()
    }
  }, [deviceId, emitEvent, installedWallets, theme, wallet])

  // Set external OS wallet data cookie and emit event to opensea-wallet
  // when active chain, installed providers or theme changes
  const { chain: activeChain } = useWallet()
  useEffect(() => {
    const setWalletData = async () => {
      if (activeChain) {
        await setExternalOSWalletData({
          activeAccount: wallet.activeAccount
            ? {
                address: wallet.activeAccount.address,
                walletName: wallet.activeAccount.walletName,
              }
            : undefined,
          activeChain,
          installedWallets,
          theme,
          deviceId,
        })
        emitEvent({
          type: "event",
          event: "CookieDataChange",
        })
      }
    }
    setWalletData()
  }, [activeChain, deviceId, emitEvent, installedWallets, theme, wallet])

  useEffect(() => {
    emitEvent({ type: "event", event: "WalletToggle", visible: isOpen })
  }, [emitEvent, isOpen, vesselConnected])

  const sendLoginCode = useCallback(
    async (email: string) => {
      await triggerAction({
        type: "action",
        action: "SendLoginCode",
        email,
      })
    },
    [triggerAction],
  )
  const loginWithCode = useCallback(
    async (code: string) => {
      const result = (await triggerAction({
        type: "action",
        action: "LoginWithCode",
        code,
      })) as { accessToken: string; privyId: string; isNewUser: boolean }
      setPrivyAccessToken(result.accessToken)
      return result
    },
    [setPrivyAccessToken, triggerAction],
  )
  const createEmbeddedWallet = useCallback(async () => {
    await triggerAction({
      type: "action",
      action: "CreateEmbeddedWallet",
    })
  }, [triggerAction])
  const checkMfaEnrollment = useCallback(async () => {
    return (await triggerAction({
      type: "action",
      action: "CheckMfaEnrollment",
    })) as { isReady: boolean; isEnrolled: boolean }
  }, [triggerAction])
  const initMfaEnrollmentWithSms = useCallback(
    async (phoneNumber: string) => {
      await triggerAction({
        type: "action",
        action: "InitMfaEnrollmentWithSms",
        phoneNumber,
      })
    },
    [triggerAction],
  )
  const submitMfaEnrollmentWithSms = useCallback(
    async (phoneNumber: string, mfaCode: string) => {
      await triggerAction({
        type: "action",
        action: "SubmitMfaEnrollmentWithSms",
        phoneNumber,
        mfaCode,
      })
    },
    [triggerAction],
  )
  const initMfaEnrollmentWithTotp = useCallback(async () => {
    return (await triggerAction({
      type: "action",
      action: "InitMfaEnrollmentWithTotp",
    })) as { secret: string; authUrl: string }
  }, [triggerAction])
  const submitMfaEnrollmentWithTotp = useCallback(
    async (mfaCode: string) => {
      await triggerAction({
        type: "action",
        action: "SubmitMfaEnrollmentWithTotp",
        mfaCode,
      })
    },
    [triggerAction],
  )

  return (
    <OSWalletContext.Provider
      value={{
        openOSWallet,
        closeOSWallet,
        toggleOSWallet,
        anchorRef,
        loginTriggered,
        sendLoginCode,
        loginWithCode,
        createEmbeddedWallet,
        checkMfaEnrollment,
        initMfaEnrollmentWithSms,
        submitMfaEnrollmentWithSms,
        initMfaEnrollmentWithTotp,
        submitMfaEnrollmentWithTotp,
      }}
    >
      {children}
      {isOSWalletEnabled && (
        <OSWalletIFramePopover
          anchorRef={anchorRef}
          closeWallet={closeOSWallet}
          initVesselCallbackRef={initVesselCallbackRef}
          isFocused={isOpen && !allowClickAway}
          isOpen={isOpen}
          keepWalletOpenOnClickCallbackRef={keepWalletOpenOnClickCallbackRef}
        />
      )}
    </OSWalletContext.Provider>
  )
}
