import { getYear } from "date-fns"
import type { SelectOption } from "@/design-system/Select"
import { useRouter } from "@/hooks/useRouter"
import type { RankingsPageTop_data$data } from "@/lib/graphql/__generated__/RankingsPageTop_data.graphql"
import type { RankingsPageTrending_data$data } from "@/lib/graphql/__generated__/RankingsPageTrending_data.graphql"
import { RankingsPageWatchlist_data$data } from "@/lib/graphql/__generated__/RankingsPageWatchlist_data.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { BigNumber, shortSymbolDisplay, bn } from "@/lib/helpers/numberUtils"
import { stringifyQueryParams } from "@/lib/helpers/urls"
import { DEFAULT_SORT_BY, PAGE_SIZE } from "./constants"
import type { ColumnSortKey, RankingsPageTab } from "./types"

export const ELIGIBLE_TRENDING_COLLECTIONS = 500

const MINIMUM_PERCENTAGE = 0.00001

const percentageStats: SortableStat[] = ["percentUniqueOwners", "percentListed"]

const MAX_COLLECTIONS: Record<RankingsPageTab, number> = {
  top: 1000,
  trending: 100,
  watchlist: 1000,
}

type SortableStat =
  | "totalSupply"
  | "numOwners"
  | "volume"
  | "volumeChange"
  | "numOfSales"
  | "floorPrice"
  | "floorPriceChange"
  | "percentUniqueOwners"
  | "percentListed"

export const getRows = (
  collections:
    | RankingsPageTop_data$data["topCollectionsByCategory"]
    | RankingsPageTrending_data$data["trendingCollectionsByCategory"]
    | NonNullable<
        RankingsPageWatchlist_data$data["getAccount"]["user"]
      >["collectionWatchlist"],
  watchlist:
    | NonNullable<
        RankingsPageWatchlist_data$data["getAccount"]["user"]
      >["collectionWatchlist"]
    | null,
  columnSortKey: ColumnSortKey,
  sortAscending: boolean,
  currentPage: number,
) => {
  // sort across all collections and slice afterwards
  const start = 0
  const end = getNodes(collections).length

  // Handle watchlist default sort/pinning
  if (watchlist && columnSortKey === "DEFAULT") {
    const watchlistNodes = getNodes(watchlist)
      .map((collection, index) => ({
        ...collection,
        index,
      }))
      .slice(start, end)

    const sortByPinnedDate = (
      a: (typeof watchlistNodes)[number],
      b: (typeof watchlistNodes)[number],
    ) => {
      const aPinnedDate = a.pinnedDate
      const bPinnedDate = b.pinnedDate
      return aPinnedDate > bPinnedDate ? 1 : bPinnedDate > aPinnedDate ? -1 : 0
    }

    return watchlistNodes
      .sort((a, b) => sortByPinnedDate(a, b))
      .slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE)
  }

  const nodes = getNodes(collections)
    .map((collection, index) => ({
      ...collection,
      index,
    }))
    .slice(start, end)

  const sortByVolume = (
    a: (typeof nodes)[number],
    b: (typeof nodes)[number],
  ) => {
    const aVolume = a.windowCollectionStats.volume.eth
    const bVolume = b.windowCollectionStats.volume.eth
    if (bn(aVolume).isGreaterThan(bVolume)) {
      return -1
    }
    if (bn(bVolume).isGreaterThan(aVolume)) {
      return 1
    }

    return 0
  }

  const sort = (
    a: (typeof nodes)[number],
    b: (typeof nodes)[number],
    stat: SortableStat,
  ) => {
    let aStat: number, bStat: number
    const hideA = sortAscending ? -1 : 1
    const hideB = sortAscending ? 1 : -1

    if (stat === "percentUniqueOwners") {
      aStat =
        a.windowCollectionStats["numOwners"] /
        parseFloat(a.statsV2["totalQuantity"])
      bStat =
        b.windowCollectionStats["numOwners"] /
        parseFloat(b.statsV2["totalQuantity"])
    } else if (stat === "percentListed") {
      aStat =
        a.windowCollectionStats["totalListed"] /
        a.windowCollectionStats["totalSupply"]
      bStat =
        b.windowCollectionStats["totalListed"] /
        b.windowCollectionStats["totalSupply"]

      // Send ERC-1155 collections to the bottom
      if (
        a.windowCollectionStats["totalSupply"] !==
        parseFloat(a.statsV2["totalQuantity"])
      ) {
        return hideA
      }
      if (
        b.windowCollectionStats["totalSupply"] !==
        parseFloat(b.statsV2["totalQuantity"])
      ) {
        return hideB
      }
    } else if (stat === "floorPrice") {
      aStat = parseFloat(a.windowCollectionStats.floorPrice?.eth ?? "0")
      bStat = parseFloat(b.windowCollectionStats.floorPrice?.eth ?? "0")

      // Send collections without floor prices to the bottom
      if (aStat === 0 || a.windowCollectionStats["totalListed"] === 0) {
        return hideA
      }
      if (bStat === 0 || b.windowCollectionStats["totalListed"] === 0) {
        return hideB
      }
    } else if (stat == "floorPriceChange") {
      aStat = a.floorPricePercentChange || 0
      bStat = b.floorPricePercentChange || 0
    } else if (stat === "numOfSales") {
      aStat = parseFloat(a.windowCollectionStats.numOfSales)
      bStat = parseFloat(b.windowCollectionStats.numOfSales)
    } else if (stat === "volume") {
      aStat = parseFloat(a.windowCollectionStats.volume.eth)
      bStat = parseFloat(b.windowCollectionStats.volume.eth)
    } else {
      aStat = a.windowCollectionStats[stat]
      bStat = b.windowCollectionStats[stat]
    }

    // Send "—" values to the bottom
    if (percentageStats.includes(stat)) {
      if (aStat && (aStat > 1 || aStat < MINIMUM_PERCENTAGE)) {
        return hideA
      }
      if (bStat && (bStat > 1 || bStat < MINIMUM_PERCENTAGE)) {
        return hideB
      }
    }
    if (stat === "volumeChange" || stat === "floorPriceChange") {
      if (aStat === 0) {
        return hideA
      }
      if (bStat === 0) {
        return hideB
      }
    }

    if (aStat && bStat) {
      return aStat > bStat ? -1 : bStat > aStat ? 1 : 0
    } else if (aStat) {
      return -1
    } else {
      return 1
    }
  }

  const sortStat = getSortStat(columnSortKey)
  if (sortStat) {
    nodes.sort((a, b) => sort(a, b, sortStat))
  }
  if (columnSortKey === "VOLUME") {
    nodes.sort((a, b) => sortByVolume(a, b))
  }

  const sortedNodes = sortAscending ? nodes.reverse() : nodes
  return sortedNodes.slice(
    currentPage * PAGE_SIZE,
    (currentPage + 1) * PAGE_SIZE,
  )
}

const getSortStat = (columnSortKey: ColumnSortKey) => {
  if (columnSortKey === "FLOOR PRICE") {
    return "floorPrice"
  }
  if (columnSortKey === "VOL % CHANGE") {
    return "volumeChange"
  }
  if (columnSortKey === "FP % CHANGE") {
    return "floorPriceChange"
  }
  if (columnSortKey === "SALES") {
    return "numOfSales"
  }
  if (columnSortKey === "% UNIQUE OWNERS") {
    return "percentUniqueOwners"
  }
  if (columnSortKey === "% ITEMS LISTED") {
    return "percentListed"
  }
  return false
}

export const getOptionFromValue = (
  value: string,
  options: SelectOption[],
  fallback: SelectOption,
) => {
  if (!value) {
    return fallback
  }
  return options.find(opt => opt.value === value) ?? fallback
}

export const selectOptionSerializer = (opt: SelectOption) => opt.value

export const isCollectionPinned = (date?: number) => {
  if (date) {
    const pinnedDate = new Date(date * 1000)
    return getYear(pinnedDate) !== 9999
  }
  return false
}

export const formatCollectionVolume = (
  price: BigNumber,
  showPreciseVolume = false,
) => {
  const value = bn(price)
  if (value.isZero()) {
    return "0"
  }
  const min = bn(0.01)
  return `${
    value.isLessThan(min)
      ? `< ${min.toFixed(2)}`
      : value.absoluteValue().isLessThan(1)
      ? value.toFixed(2)
      : showPreciseVolume
      ? value.decimalPlaces(1)
      : value.absoluteValue().isLessThan(10000)
      ? Number(value.toFixed(0)).toLocaleString()
      : shortSymbolDisplay(value, { digits: 0, threshold: 10_000 })
  }`
}

export const formatCollectionValueChange = (value: BigNumber) => {
  return `${
    value.absoluteValue().isLessThan(1)
      ? value.toFixed(2)
      : Math.min(Number(value.toFixed(0)), 999_999_999).toLocaleString()
  }`
}

export const formatCollectionSales = (value: BigNumber) => {
  return `${
    value.absoluteValue().isLessThan(10000)
      ? Number(value.toFixed(0)).toLocaleString()
      : shortSymbolDisplay(value, { digits: 0, threshold: 10_000 })
  }`
}

export const formatCollectionFloorPrice = (price: string) => {
  const value = bn(price)
  if (value.isZero()) {
    return "0"
  }
  const min = bn(0.01)
  if (value.isGreaterThan(min)) {
    if (value.isInteger()) {
      return Number(value).toLocaleString()
    }
    return `${value.toFixed(2)}`
  }
  return `< ${min.toFixed(2)}`
}

export const showPreciseVolume = (timeWindow: string) => {
  return timeWindow === "one_hour_volume" || timeWindow === "six_hour_volume"
}

export const getPercentFormatted = (percent: BigNumber) => {
  if (percent.isLessThan(1)) {
    return percent.dp(2).toLocaleString()
  }
  return percent.toFixed(0).toLocaleString()
}

export const getMaxNumberOfCollections = (
  currentTab: RankingsPageTab,
  timeWindow: string,
) => {
  if (
    currentTab !== "watchlist" &&
    (timeWindow === "one_hour_volume" || timeWindow === "six_hour_volume")
  ) {
    return 100
  }
  return MAX_COLLECTIONS[currentTab]
}

export const getRankingsPageHref = (
  currentTab: string,
  sortByValue: string | undefined,
  chainValue: string,
  category: string | undefined,
) => {
  const rankingsPageParams = {
    chain: chainValue !== "all" ? chainValue : undefined,
    sortBy: sortByValue !== DEFAULT_SORT_BY ? sortByValue : undefined,
    category,
  }
  const tabPath = currentTab === "top" ? "" : `/${currentTab}`
  return `/rankings${tabPath}${stringifyQueryParams(rankingsPageParams)}`
}

export const useRankingsPageCanonicalUrl = (chainValue: string) => {
  const { locale, origin } = useRouter()

  const rankingsPageParams = {
    chain: chainValue !== "all" ? chainValue : undefined,
  }

  return `${origin}${
    locale === "en-US" ? "" : `/${locale}`
  }/rankings${stringifyQueryParams(rankingsPageParams)}`
}
