import React, { ReactNode, createContext, useMemo, useState } from "react"
import { useRafState, useUpdateEffect } from "react-use"
import { FILTER_BAR_HEIGHT } from "@/components/layout/AccountOrCollectionPage/constants"
import { AssetCardVariant } from "@/components/search/assets/AssetSearchView"
import {
  getFilterStateFromLS,
  updateFilterStateLS,
} from "@/components/search/assets/AssetSearchView/utils"
import { STICKY_TAB_NAVIGATION_HEIGHT } from "@/design-system/TabNavigation/constants"
import { useMountEffect } from "@/hooks/useMountEffect"
import { NAV_HEIGHT_PX } from "@/styles/variables"

type ContextType = {
  sidebarOpen: boolean
  toggleSidebar: (_: boolean) => void
  // Separate flag for handling mobile filter drawer open/closed state
  isMobileFilterDrawerOpen: boolean
  // Separate handler for handling opening/closing mobile filter drawer
  setIsMobileFilterDrawerOpen: (_: boolean) => void
  // Note(@auster-eth): flag for checking whether there is sticky element to help remove shadow on navbar
  hasStickyElement: boolean
  setHasStickyElement: (_: boolean) => void
  // Note(@auster-eth): Top offset to be used for header sticky
  headerTopOffset: number
  // Note(@auster-eth): Top offset to be used for filter bar sticky
  filterTopOffset: number
  // Note(@auster-eth): Top offset to be used for the filter content sticky + search scroll position
  contentTopOffset: number
  // Flag for table list view
  isListViewEnabled: boolean
  // Only valid for collections
  isCollectionRarityEnabled: boolean
  // Currently selected card variant
  selectedCardVariant: AssetCardVariant | undefined
  setSelectedCardVariant: (_: AssetCardVariant) => void
  // Used to determine whether or not we have live data
  isSearchStateAcceptableForPolling: boolean
  setIsSearchStateAcceptableForPolling: (_: boolean) => void

  isFooterShown: boolean
  setIsFooterShown: (_: boolean) => void

  // Used to determine whether or not the Buy Now filter is toggled
  isBuyNowToggled: boolean
  setIsBuyNowToggled: (_: boolean) => void

  quickBuyModalOrderRelayId: string | undefined
  setQuickBuyModalOrderRelayId: (_: string | undefined) => void

  // Used for offers page once user has scrolled past the main stats
  scrolledPastStats: boolean
  setScrolledPastStats: (_: boolean) => void
}

export const DEFAULT_CONTEXT: ContextType = {
  sidebarOpen: true,
  isMobileFilterDrawerOpen: false,
  setIsMobileFilterDrawerOpen: () => null,
  toggleSidebar: () => null,
  hasStickyElement: false,
  setHasStickyElement: () => null,
  headerTopOffset: NAV_HEIGHT_PX,
  filterTopOffset: NAV_HEIGHT_PX,
  contentTopOffset: NAV_HEIGHT_PX,
  isListViewEnabled: false,
  isCollectionRarityEnabled: false,
  selectedCardVariant: undefined,
  setSelectedCardVariant: () => null,
  isSearchStateAcceptableForPolling: false,
  setIsSearchStateAcceptableForPolling: () => null,
  isFooterShown: false,
  setIsFooterShown: () => null,
  isBuyNowToggled: false,
  setIsBuyNowToggled: () => null,
  quickBuyModalOrderRelayId: undefined,
  setQuickBuyModalOrderRelayId: () => null,
  scrolledPastStats: false,
  setScrolledPastStats: () => null,
}

export const AccountOrCollectionPageContext =
  createContext<ContextType>(DEFAULT_CONTEXT)

type Props = {
  children: ReactNode
  initialSidebarOpen?: boolean
  initialMobileFilterDrawerOpen?: boolean
  isListViewEnabled?: boolean
  isCollectionRarityEnabled?: boolean
  initialSelectedCardVariant?: AssetCardVariant
  contentTopOffset?: number
  filterTopOffset?: number
}

export function AccountOrCollectionPageContextProvider({
  initialSidebarOpen = true,
  initialMobileFilterDrawerOpen = false,
  children,
  isListViewEnabled = false,
  isCollectionRarityEnabled = false,
  initialSelectedCardVariant,
  contentTopOffset = FILTER_BAR_HEIGHT +
    NAV_HEIGHT_PX +
    STICKY_TAB_NAVIGATION_HEIGHT,
  filterTopOffset = NAV_HEIGHT_PX + STICKY_TAB_NAVIGATION_HEIGHT,
}: Props) {
  const [quickBuyModalOrderRelayId, setQuickBuyModalOrderRelayId] = useState<
    string | undefined
  >(undefined)
  const [isSidebarOpen, setIsSidebarOpen] = useRafState(initialSidebarOpen)
  const [isMobileFilterDrawerOpen, setIsMobileFilterDrawerOpen] = useRafState(
    initialMobileFilterDrawerOpen,
  )
  const [hasStickyElement, setHasStickyElement] = useRafState(false)
  const [selectedCardVariant, setSelectedCardVariant] = useRafState(
    initialSelectedCardVariant,
  )
  const [
    isSearchStateAcceptableForPolling,
    setIsSearchStateAcceptableForPolling,
  ] = useState(false)
  const [isBuyNowToggled, setIsBuyNowToggled] = useState(false)
  const [isFooterShown, setIsFooterShown] = useState(false)
  const [scrolledPastStats, setScrolledPastStats] = useState(false)

  const value = useMemo(
    () => ({
      sidebarOpen: isSidebarOpen,
      isMobileFilterDrawerOpen,
      setIsMobileFilterDrawerOpen,
      toggleSidebar: setIsSidebarOpen,
      headerTopOffset: NAV_HEIGHT_PX,
      filterTopOffset,
      hasStickyElement,
      setHasStickyElement,
      contentTopOffset,
      isListViewEnabled,
      isCollectionRarityEnabled,
      selectedCardVariant,
      setSelectedCardVariant,
      isSearchStateAcceptableForPolling,
      setIsSearchStateAcceptableForPolling,
      isFooterShown,
      setIsFooterShown,
      isBuyNowToggled,
      setIsBuyNowToggled,
      quickBuyModalOrderRelayId,
      setQuickBuyModalOrderRelayId,
      scrolledPastStats,
      setScrolledPastStats,
    }),
    // eslint doesn't know about the useRafState setters
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isSidebarOpen,
      isMobileFilterDrawerOpen,
      hasStickyElement,
      filterTopOffset,
      contentTopOffset,
      isListViewEnabled,
      isCollectionRarityEnabled,
      selectedCardVariant,
      isSearchStateAcceptableForPolling,
      isFooterShown,
      isBuyNowToggled,
      quickBuyModalOrderRelayId,
      scrolledPastStats,
      setScrolledPastStats,
    ],
  )

  useMountEffect(() => {
    const filterStateFromLS = getFilterStateFromLS()

    setIsSidebarOpen(
      filterStateFromLS ? filterStateFromLS === "open" : initialSidebarOpen,
    )
  })

  useUpdateEffect(() => {
    updateFilterStateLS(isSidebarOpen ? "open" : "closed")
  }, [isSidebarOpen])

  return (
    <AccountOrCollectionPageContext.Provider value={value}>
      {children}
    </AccountOrCollectionPageContext.Provider>
  )
}
