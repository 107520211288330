import React, { useCallback, useState } from "react"
import { Text } from "@opensea/ui-kit"
import { ConnectionHandler, useFragment } from "react-relay"
import { Modal } from "@/design-system/Modal"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { CollectionManagerDeleteModal_data$key } from "@/lib/graphql/__generated__/CollectionManagerDeleteModal_data.graphql"
import { CollectionManagerDeleteModalMutation } from "@/lib/graphql/__generated__/CollectionManagerDeleteModalMutation.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"

type Props = {
  isOpen: boolean
  onClose: () => unknown
  onDelete?: () => Promise<unknown>
  isCollection?: boolean
  connectionId?: string
  dataKey: CollectionManagerDeleteModal_data$key | null
}

export const CollectionManagerDeleteModal = ({
  isOpen,
  isCollection,
  connectionId,
  onClose,
  onDelete,
  dataKey,
}: Props) => {
  const t = useTranslate("modals")
  const data = useFragment(
    graphql`
      fragment CollectionManagerDeleteModal_data on CollectionType {
        relayId
      }
    `,
    dataKey,
  )
  const { showSuccessMessage, attempt } = useToasts()
  const { mutate } = useGraphQL()
  const [deleting, setDeleting] = useState(false)
  const deleteCollection = useCallback(async () => {
    if (!data) {
      return
    }

    const { relayId } = data
    attempt(
      async () => {
        setDeleting(true)
        await mutate<CollectionManagerDeleteModalMutation>(
          graphql`
            mutation CollectionManagerDeleteModalMutation(
              $input: CollectionDeleteMutationInput!
            ) {
              collections {
                delete(input: $input) {
                  relayId
                }
              }
            }
          `,
          { input: { collection: relayId } },
          {
            shouldAuthenticate: true,

            updater: store => {
              const relayId = data.relayId
              if (!relayId || !connectionId) {
                return
              }
              const connection = store.get(connectionId)
              if (!connection) {
                return
              }
              ConnectionHandler.deleteNode(connection, relayId)
            },
          },
        )

        showSuccessMessage(
          t("forms.dataForm.submitSuccess", "Collection deleted"),
        )
        onClose()
      },
      {
        onError: () => {
          setDeleting(false)
        },
      },
    )
  }, [data, mutate, t, onClose, showSuccessMessage, attempt, connectionId])
  return (
    <Modal closable={false} isOpen={isOpen} onClose={onClose}>
      <Modal.Header />
      <Modal.Body centerAligned>
        <Modal.Body.Title>
          {isCollection
            ? t(
                "collectionManager.delete.titleCollection",
                `Are you sure you want to delete this collection?`,
              )
            : t(
                "collectionManager.delete.titleItem",
                "Are you sure you want to delete this item?",
              )}
        </Modal.Body.Title>
        <Text
          asChild
          className="pb-2.5 pt-4 text-center text-secondary"
          size="small"
        >
          <p>
            {isCollection
              ? t(
                  "deleteCollection.confirmDelete",
                  "This will delete this collection and hide all of its items, and can only be done if you own all items in the collection.",
                )
              : t(
                  "deleteItem.confirm",
                  "This can only be done if you own all copies in circulation.",
                )}
          </p>
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button variant="secondary" onClick={onClose}>
          {t("collectionManager.cancel", "Cancel")}
        </Modal.Footer.Button>
        <Modal.Footer.Button
          isLoading={deleting}
          variant="destructive"
          onClick={() => {
            if (isCollection) {
              deleteCollection()
            } else {
              onDelete?.()
            }
          }}
        >
          {isCollection
            ? t("collectionManager.collection.delete", `Delete collection`)
            : t("collectionManager.item.delete", "Delete item")}
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  )
}
