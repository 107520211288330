import { graphql, useFragment } from "react-relay"
import { useIsItemSafelisted_item$key } from "@/lib/graphql/__generated__/useIsItemSafelisted_item.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { isSafelisted } from "@/lib/helpers/verification"

export const useIsItemSafelisted = (
  itemDataKey: useIsItemSafelisted_item$key | null,
) => {
  const item = useFragment(
    graphql`
      fragment useIsItemSafelisted_item on ItemType {
        __typename

        ... on AssetType {
          collection {
            slug
            verificationStatus
          }
        }

        ... on AssetBundleType {
          assetQuantities(first: 30) {
            edges {
              node {
                asset {
                  collection {
                    slug
                    verificationStatus
                  }
                }
              }
            }
          }
        }
      }
    `,
    itemDataKey,
  )

  if (!item || item.__typename === "%other") {
    return { isSafelisted: false, collectionSlug: undefined }
  }

  const isAssetCollectionUnreviewed =
    item.__typename === "AssetType" &&
    !isSafelisted(item.collection.verificationStatus)

  const areBundleAssetsUnreviewed =
    item.__typename === "AssetBundleType" &&
    getNodes(item.assetQuantities).some(
      ({ asset }) => !isSafelisted(asset.collection.verificationStatus),
    )

  const collectionSlug =
    item.__typename === "AssetType"
      ? item.collection.slug
      : getNodes(item.assetQuantities).find(
          ({ asset }) => !isSafelisted(asset.collection.verificationStatus),
        )?.asset.collection.slug

  return {
    isSafelisted: !(isAssetCollectionUnreviewed || areBundleAssetsUnreviewed),
    collectionSlug,
  }
}
