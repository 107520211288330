/**
 * @generated SignedSource<<0fa66131b74b72898e8c472484c24ea9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useTotalPrice_orders$data = ReadonlyArray<{
  readonly payment: {
    readonly symbol: string;
    readonly " $fragmentSpreads": FragmentRefs<"TokenPricePayment">;
  };
  readonly perUnitPriceType: {
    readonly unit: string;
    readonly usd: string;
  };
  readonly relayId: string;
  readonly " $fragmentType": "useTotalPrice_orders";
}>;
export type useTotalPrice_orders$key = ReadonlyArray<{
  readonly " $data"?: useTotalPrice_orders$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTotalPrice_orders">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "useTotalPrice_orders",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "perUnitPriceType",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "usd",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unit",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentAssetType",
      "kind": "LinkedField",
      "name": "payment",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineDataFragmentSpread",
          "name": "TokenPricePayment",
          "selections": [
            (v0/*: any*/)
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};
})();

(node as any).hash = "ef386337abe77a21db68c12f2c194456";

export default node;
