import React, { useCallback, useState } from "react"
import { graphql, useFragment } from "react-relay"
import { useTransaction } from "@/components/blockchain"
import { SwapDataHeader } from "@/components/blockchain/swaps/SwapDataHeader"
import { useTranslate } from "@/hooks/useTranslate"
import {
  useTrackBlockchainAttempt,
  useTrackBlockchainError,
} from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/hooks"
import { CancelSwapOrdersAction_data$key } from "@/lib/graphql/__generated__/CancelSwapOrdersAction_data.graphql"
import {
  BaseBlockchainActionProps,
  BlockchainActionModalContent,
} from "./BlockchainActionModalContent.react"
import { useBlockchainActionProgress } from "./useBlockchainActionProgress"
import { useHandleBlockchainActions } from "./useHandleBlockchainActions"

type Props = BaseBlockchainActionProps & {
  dataKey: CancelSwapOrdersAction_data$key
}

export const CancelSwapOrdersAction = ({ dataKey, onEnd }: Props) => {
  const t = useTranslate("components")
  const actions = useFragment<CancelSwapOrdersAction_data$key>(
    graphql`
      fragment CancelSwapOrdersAction_data on CancelSwapOrdersActionType
      @relay(plural: true) {
        __typename
        swapsData {
          ...SwapDataHeader_swap
        }
        ...useHandleBlockchainActions_cancel_swap_orders
      }
    `,
    dataKey,
  )

  const [actionIndex, setActionIndex] = useState(0)
  const { cancelSwapOrders } = useHandleBlockchainActions()
  const [transactionHash, setTransactionHash] = useState<string>()
  const trackBlockchainAttempt = useTrackBlockchainAttempt()
  const [trackBlockchainError, errorTags] = useTrackBlockchainError()
  const { pollTransaction } = useTransaction()

  const action = actions[actionIndex]
  const { swapsData } = action

  const executeAction = useCallback(async () => {
    if (actionIndex >= actions.length) {
      onEnd()
    }

    trackBlockchainAttempt({ blockchainAction: "coreAction" })
    const transaction = await cancelSwapOrders(action)
    setTransactionHash(transaction.transactionHash)

    await pollTransaction({ ...transaction, showMessageOnSuccess: true })

    setTransactionHash("")

    if (actionIndex === actions.length - 1) {
      onEnd()
    } else {
      setActionIndex(index => index + 1)
    }
  }, [
    action,
    actionIndex,
    actions.length,
    cancelSwapOrders,
    onEnd,
    pollTransaction,
    trackBlockchainAttempt,
  ])

  const { attemptAction, progress } = useBlockchainActionProgress({
    executeAction,
    action,
    onError: trackBlockchainError({ blockchainAction: "coreAction" }),
    errorTags,
  })

  return (
    <BlockchainActionModalContent>
      <BlockchainActionModalContent.Header>
        <BlockchainActionModalContent.Title>
          {t("blockchain.cancelSwap.title", "Cancel deal")}
        </BlockchainActionModalContent.Title>
      </BlockchainActionModalContent.Header>
      <BlockchainActionModalContent.Body>
        {/* TODO: Show multiple swaps */}
        <SwapDataHeader data={swapsData[0]} transactionHash={transactionHash} />
        <BlockchainActionModalContent.Body.Separator />
        <BlockchainActionModalContent.Body.GoToWallet />
        <BlockchainActionModalContent.Body.Text>
          {t(
            "blockchain.cancelSwap.description",
            "You'll be asked to cancel this deal from your wallet.",
          )}
        </BlockchainActionModalContent.Body.Text>
        {!progress && (
          <BlockchainActionModalContent.Body.Button onClick={attemptAction}>
            {t("blockchain.cancelSwap.cta", "Continue")}
          </BlockchainActionModalContent.Body.Button>
        )}
      </BlockchainActionModalContent.Body>
    </BlockchainActionModalContent>
  )
}
