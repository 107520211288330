import { useFlag } from "@/hooks/useFlag"

export const useIsOSWalletEnabled = () => {
  return useFlag("is_os_wallet_enabled")
}

export const useIsCustomAuthWalletEnabled = () => {
  return useFlag("custom_auth_wallet_enabled")
}

export const useIsLoginRefreshEnabled = () => {
  return useFlag("login_refresh")
}

export const useCreateEmbeddedWalletManual = () => {
  return useFlag("create_embedded_wallet_manual")
}
