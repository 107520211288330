import { addSeconds, isBefore } from "date-fns"
import {
  Middleware,
  RelayNetworkLayerResponse,
} from "react-relay-network-modern"
import { getWallet } from "@/containers/WalletProvider/wallet"
import Auth from "@/lib/auth"
import {
  trackAuthRefreshDueToExpiredToken,
  trackAuthRefreshDueToUnauthenticated,
  trackJWTInvalidExpirationDate,
} from "@/lib/auth/analytics"
import { getIsAuthStateSimplificationEnabled } from "@/lib/auth/flags"
import {
  getSession,
  getWalletAddressCookie,
  isJWTExpirationData,
  removeSession,
} from "@/lib/auth/storage"
import { captureNoncriticalError } from "@/lib/sentry"

export const AUTH_TOKEN_PREFIX = "JWT "

const handleExpiredJWT = async (): Promise<undefined> => {
  if (!getIsAuthStateSimplificationEnabled()) {
    return
  }
  const activeAddress = getWallet().activeAccount?.address
  if (!activeAddress) {
    return
  }

  const jwtExpirationData = getWalletAddressCookie(activeAddress).get()

  if (
    !isJWTExpirationData(jwtExpirationData) ||
    !jwtExpirationData.jwt_expiration
  ) {
    return
  }

  const jwtExpirationTime = Date.parse(jwtExpirationData.jwt_expiration)
  if (isNaN(jwtExpirationTime)) {
    trackJWTInvalidExpirationDate({
      jwtExpiration: jwtExpirationData.jwt_expiration,
    })
    return
  }

  // If the token has expired (or will expire in 30 seconds), refresh it
  // This should very rarely be reached, as we proactively refresh tokens every 9m (1m before expiration)
  if (isBefore(jwtExpirationTime, addSeconds(new Date(), 30))) {
    try {
      const refreshed = await Auth.refresh()
      trackAuthRefreshDueToExpiredToken({ refreshed: Boolean(refreshed) })
    } catch (ex) {
      captureNoncriticalError(ex)
    }
  }
}

const tokenRefreshMiddleware = (): Middleware => {
  return next =>
    async (req): Promise<RelayNetworkLayerResponse> => {
      // Avoid infinite loop
      if (req.getID() !== "authRefreshMutation") {
        await handleExpiredJWT()
      }
      const response = await next(req)
      const unauthenticated = response.errors?.some(
        // TODO: This is very fragile, and we should have better error handling!
        error =>
          error.message.startsWith("[401]") ||
          error.message.startsWith("[403]"),
      )
      if (unauthenticated) {
        try {
          const wallet = getWallet()
          if (wallet.activeAccount) {
            removeSession(wallet.activeAccount.address)

            if (getIsAuthStateSimplificationEnabled()) {
              const refreshed = await Auth.refresh()
              trackAuthRefreshDueToUnauthenticated({
                refreshed: Boolean(refreshed),
              })

              if (refreshed) {
                return next(req)
              }
            }
            const authenticated = await Auth.login({
              activeAccount: wallet.activeAccount,
              ensureLoginCompatibleNetwork: wallet.ensureLoginCompatibleNetwork,
              getProviderOrRedirect: wallet.getProviderOrRedirect,
              setAuthenticatedAccount: wallet.setAuthenticatedAccount,
              sign: wallet.sign,
            })
            if (authenticated) {
              if (getIsAuthStateSimplificationEnabled()) {
                return next(req)
              }
              const session = await getSession(wallet.activeAccount.address)
              if (session) {
                req.fetchOpts.headers[
                  "Authorization"
                ] = `${AUTH_TOKEN_PREFIX}${session.token}`
                return next(req) // re-run query with new token
              }
            }
          }
        } catch {
          // ignore any error and just return the original response
        }
      }
      return response
    }
}

export default tokenRefreshMiddleware
