import { TypedDataUtils } from "eth-sig-util"
import { bufferToHex } from "ethereumjs-util"
import { ethers } from "ethers"
import { walletAddEthereumChainParameters } from "@/hooks/useChains"
import {
  Chain,
  ChainByIdentifier,
  ChainByNetworkId,
} from "@/hooks/useChains/types"
import {
  createChainByIdentifierLookup,
  createChainByNetworkIdLookup,
} from "@/hooks/useChains/utils"
import { getTrackingFn } from "@/lib/analytics"
import { Address, WalletAccountKey } from "@/lib/chain/chain"
import { ClientSignatureStandard } from "@/lib/graphql/__generated__/useHandleBlockchainActions_cancel_orders.graphql"
import { bn } from "@/lib/helpers/numberUtils"
import { poll } from "@/lib/helpers/promise"
import { captureNoncriticalError } from "@/lib/sentry"
import Provider, {
  JsonRpcError,
  OnChainChangeHandler,
  Transaction,
  TransactionOptions,
  TransactionResponse,
  USER_REJECTED_REQUEST_ERROR_CODE,
} from "../provider"
import type { SignOptions } from "../wallet"
import { addBreadcrumb } from "@sentry/nextjs"

type Method =
  | "eth_getBlockByNumber"
  | "eth_requestAccounts"
  | "personal_sign"
  | "eth_signTypedData_v4"
  | "wallet_addEthereumChain"
  | "wallet_switchEthereumChain"
  | "SOLANA"
  | "SOLANA_MESSAGE"

type Event = "accountsChanged" | "chainChanged"

export type Web3Provider = ethers.providers.Web3Provider

export type ExternalProvider = ethers.providers.ExternalProvider & {
  off?: <T>(event: Event, handler: (value: T) => unknown) => void
  on?: <T>(event: Event, handler: (value: T) => unknown) => void
}

const UNADDED_CHAIN_ERROR_CODE = 4902
const UNRECOGNIZED_CHAIN_ID_CODE = -32603
const PENDING_CHAIN_SWITCH_ERROR_CODE = -32002

// This allows ethers to actually switch chains https://docs.ethers.io/v5/concepts/best-practices/
export const ETHERS_WEB3_PROVIDER_NETWORK = "any" as const

const trackUnexpectedChainId = getTrackingFn<{
  chainId: number
}>("unexpected chain id")

export default abstract class Web3EvmProvider extends Provider {
  abstract provider: Web3Provider
  protected readonly chainByNetworkId: ChainByNetworkId
  protected readonly chainByIdentifier: ChainByIdentifier

  public constructor(chains: Chain[]) {
    super()
    this.chainByNetworkId = createChainByNetworkIdLookup(chains)
    this.chainByIdentifier = createChainByIdentifierLookup(chains)
  }

  public connect(): Promise<WalletAccountKey[]> {
    return this.request<Address[]>("eth_requestAccounts")
      .then(addresses => this.mapToAccounts(addresses))
      .catch(err => {
        captureNoncriticalError(err)
        return []
      })
  }

  protected async mapToAccounts(
    addresses: Address[],
  ): Promise<WalletAccountKey[]> {
    const chain = await this.getChain()
    const walletName = this.getName()
    const walletMetadata = this.getMetadata()
    return addresses.map(a => ({
      address: a.toLowerCase(),
      chain,
      walletName,
      walletMetadata,
    }))
  }

  /**
   * Returns:
   * - the chain, if supported on OpenSea
   * - null, if the chain is unsupported
   * - undefined, if any error is encountered.
   */
  public async getChain() {
    try {
      const chainId = await this.getChainId()
      if (chainId in this.chainByNetworkId) {
        return this.chainByNetworkId[chainId].identifier
      } else {
        return null
      }
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  public async getChainId() {
    const chainId = bn(await this.provider.send("eth_chainId", [])).toNumber()
    return chainId
  }

  public async getAccounts(): Promise<WalletAccountKey[]> {
    const addresses = await this.provider.listAccounts()
    return this.mapToAccounts(addresses)
  }

  private onEvent<T>(
    event: Event,
    handler: (...args: T[]) => unknown,
  ): () => unknown {
    // The inner external provider has the proper event listeners that we care about
    const provider = this.provider.provider as ExternalProvider
    provider.on?.(event, handler)
    return () => provider.off?.(event, handler)
  }

  public onAccountsChange(handler: (accounts: WalletAccountKey[]) => unknown) {
    return this.onEvent<Address[]>("accountsChanged", addresses =>
      this.mapToAccounts(addresses).then(handler),
    )
  }

  public onChainChange(handler: OnChainChangeHandler) {
    return this.onEvent<string>("chainChanged", paramNetworkId => {
      const networkId = Number(paramNetworkId)
      const chainIdentifier =
        networkId in this.chainByNetworkId
          ? this.chainByNetworkId[networkId].identifier
          : null

      if (!chainIdentifier) {
        trackUnexpectedChainId({
          chainId: networkId,
        })
      }
      handler(chainIdentifier)
    })
  }

  protected async request<T>(
    method: Method,
    params: unknown[] = [],
  ): Promise<T> {
    return this.provider.send(method, params)
  }

  public async sign(message: string, address: string, options?: SignOptions) {
    const clientSignatureStandard =
      options?.clientSignatureStandard ?? "PERSONAL"

    const isTyped = clientSignatureStandard !== "PERSONAL"

    const hexMessage = bufferToHex(Buffer.from(message, "utf8"))

    const signature = await this.request<string>("personal_sign", [
      isTyped
        ? `0x${TypedDataUtils.sign(
            TypedDataUtils.sanitizeData(JSON.parse(message)),
            true,
          ).toString("hex")}`
        : hexMessage,
      address,
    ])

    return signature
  }

  public async signTypedData(
    message: string,
    address: string,
    options?: SignOptions,
  ) {
    const clientSignatureStandard =
      options?.clientSignatureStandard ?? "TYPED_DATA_V4"

    const signatureToMethod: Record<ClientSignatureStandard, Method> = {
      PERSONAL: "personal_sign",
      TYPED_DATA_V4: "eth_signTypedData_v4",
      SOLANA: "SOLANA",
      SOLANA_MESSAGE: "SOLANA_MESSAGE",
      "%future added value": "personal_sign",
    }
    addBreadcrumb({
      category: "blockchain",
      message: "signTypedData",
      data: {
        message: message,
        address: address,
        options: options,
      },
    })

    const signature = await this.request<string>(
      signatureToMethod[clientSignatureStandard],
      [address, message],
    )

    // Version of signature should be 27 or 28 for Ethereum signatures, but 0 and 1
    //  are also possible versions returned, ie from Ledger signing that need to be adapted
    let v = parseInt(signature.slice(-2), 16)

    if (v < 27) {
      v += 27
    }

    const normalizedSignature = signature.slice(0, -2) + v.toString(16)

    return normalizedSignature
  }

  public async transact(
    {
      source,
      destination,
      value,
      data,
    }: Transaction & {
      source: NonNullable<Transaction["source"]>
    },
    { transactAtBlockTimestamp }: TransactionOptions = {},
  ): Promise<TransactionResponse> {
    const accounts = await this.getAccounts()
    if (!accounts.some(a => a.address === source)) {
      throw new Error(`Not connected to account ${source}`)
    }

    const txConfig = {
      from: source,
      to: destination,
      value: value?.toString(),
      data,
    }
    addBreadcrumb({
      category: "blockchain",
      message: "transact",
      data: {
        source: source,
        destination: destination,
        data: data,
      },
    })
    // This whole thing is hacky but ethers has some really unnecessary caching on their block numbers
    // as well as it being dependent on the RPC provider which is not always at the latest block
    // This was causing execution reverted when fulfilling orders when executed too quickly after the order was created
    // https://linear.app/opensea/issue/MRKT-839/error-cannot-estimate-gas-transaction-may-fail-or-may-require-manual
    // https://opensea.slack.com/archives/C039FDX04P9/p1661296602347979?thread_ts=1661295050.297509&cid=C039FDX04P9

    if (transactAtBlockTimestamp) {
      await poll({
        delay: 1000,
        fn: async () => {
          const block = await this.provider.getBlock("latest")

          if (block.timestamp >= transactAtBlockTimestamp) {
            return true
          }

          return undefined
        },
      }).value
    }

    // This throws if the transaction will revert
    const gasLimit = await this.provider.estimateGas(txConfig)

    const signer = this.provider.getSigner(source)
    const opts = {
      ...txConfig,
      // Adds a 30% buffer to the gas limit
      gasLimit: gasLimit.mul(130).div(100),
      // Do not provide a gasPrice so the wallet can set it based on user preferences.
    }
    const transaction = await signer.sendTransaction(opts)
    return { hash: transaction.hash, evmResponse: transaction }
  }

  public async estimateGas({
    source,
    destination,
    value,
    data,
  }: Transaction & {
    source: NonNullable<Transaction["source"]>
  }): Promise<string> {
    const accounts = await this.getAccounts()
    if (!accounts.some(a => a.address === source)) {
      throw new Error(`Not connected to account ${source}`)
    }

    const txConfig = {
      from: source,
      to: destination,
      value: value?.toString(),
      data,
    }

    // This throws if the transaction will revert
    const gasLimit = await this.provider.estimateGas(txConfig)
    // Adds a 30% buffer to the gas limit
    const adjustedGasLimit = gasLimit.mul(130).div(100)

    const gasPrice = await this.provider.getGasPrice()

    return ethers.utils
      .formatEther(adjustedGasLimit.mul(gasPrice).toString())
      .toString()
  }

  public async getNativeCurrencyBalance(address: string): Promise<string> {
    const balance = await this.provider.getBalance(address)
    return balance.toString()
  }

  public switchChain = async (chain: Chain) => {
    if (!chain.networkId) {
      throw Error(`Chain ${chain.identifier} network ID was not found`)
    }
    const chainId = ethers.utils.hexStripZeros(
      ethers.utils.hexlify(Number(chain.networkId)),
    )
    try {
      await this.request("wallet_switchEthereumChain", [{ chainId }])
    } catch (switchError) {
      switch (switchError.code) {
        case UNRECOGNIZED_CHAIN_ID_CODE:
        case UNADDED_CHAIN_ERROR_CODE:
          try {
            await this.request("wallet_addEthereumChain", [
              walletAddEthereumChainParameters(chainId, chain),
            ])
          } catch (error) {
            captureNoncriticalError(switchError)
            addBreadcrumb({
              category: "blockchain",
              message: "wallet_addEthereumChain failed",
              data: {
                error: error.message,
                chainId: chainId,
                chain: chain,
              },
              level: "error",
            })
            if (JsonRpcError.isUserCancellationOrPending(error)) {
              // This error will get handled properly upstream, i.e caller decides what to do with a user cancellation
              // This is likely user rejecting to add a network to the wallet
              throw error
            }
            throw Error(
              "There was a problem switching the network. Try again in your wallet settings.",
            )
          }
          break
        case USER_REJECTED_REQUEST_ERROR_CODE:
          throw Error(switchError.message)
        case PENDING_CHAIN_SWITCH_ERROR_CODE:
          throw Error(
            "Please accept or reject the pending network switch request",
          )
        default:
          captureNoncriticalError(switchError)
          addBreadcrumb({
            category: "blockchain",
            message: "wallet_switchEthereumChain failed default case",
            data: {
              error: JSON.stringify(switchError),
              chainId: chainId,
              chain: chain,
            },
            level: "error",
          })
          throw Error(
            "There was a problem switching the network. Try again in your wallet settings.",
          )
      }
    }
  }

  public getTransactionCount = async (address: string): Promise<number> => {
    return this.provider.getTransactionCount(address, "latest")
  }
}
