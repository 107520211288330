/* eslint-disable @typescript-eslint/no-explicit-any */
import { FragmentRef, GraphQLTaggedNode, readInlineData } from "react-relay"
import type { _RefType, FragmentType } from "relay-runtime"

const isFragmentRef = <T extends _RefType<any>>(
  ref: T | FragmentRef<T>,
): ref is FragmentRef<T> => {
  return "__fragments" in ref
}

type KeyType<TData = unknown> = Readonly<{
  " $data"?: TData | undefined
  " $fragmentSpreads": FragmentType
}>

export const inlineFragmentize =
  <T extends _RefType<any>, U = T>(
    inlineFragment: GraphQLTaggedNode,
    fmap: (x: T) => U,
  ) =>
  (ref: T | FragmentRef<T>): U => {
    if (isFragmentRef(ref)) {
      const data = readInlineData<KeyType<T>>(inlineFragment, ref)
      return fmap(data)
    } else {
      return fmap(ref as T)
    }
  }
