/**
 * @generated SignedSource<<9b188d851238075cd24d6bab9c60174a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type asset_display_name$data = {
  readonly name: string | null;
  readonly tokenId: string;
  readonly " $fragmentType": "asset_display_name";
};
export type asset_display_name$key = {
  readonly " $data"?: asset_display_name$data;
  readonly " $fragmentSpreads": FragmentRefs<"asset_display_name">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "asset_display_name"
};

(node as any).hash = "5cc61d648b55dff23226594285291284";

export default node;
