/**
 * @generated SignedSource<<3989d22ae3601dd719a5505c05ccce64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateSwapOrderAction_data$data = ReadonlyArray<{
  readonly __typename: "CreateSwapOrderActionType";
  readonly swapData: {
    readonly " $fragmentSpreads": FragmentRefs<"SwapDataHeader_swap">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_create_swap_order">;
  readonly " $fragmentType": "CreateSwapOrderAction_data";
}>;
export type CreateSwapOrderAction_data$key = ReadonlyArray<{
  readonly " $data"?: CreateSwapOrderAction_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateSwapOrderAction_data">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CreateSwapOrderAction_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SwapDataType",
      "kind": "LinkedField",
      "name": "swapData",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SwapDataHeader_swap"
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useHandleBlockchainActions_create_swap_order",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SignAndPostSwapType",
          "kind": "LinkedField",
          "name": "method",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "clientMessage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "clientSignatureStandard",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "serverSignature",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "swapData",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ChainType",
              "kind": "LinkedField",
              "name": "chain",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "identifier",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "CreateSwapOrderActionType",
  "abstractKey": null
};

(node as any).hash = "ec65c30439e76814dc9452f1cfc038d7";

export default node;
