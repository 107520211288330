import { addHours, fromUnixTime, isAfter } from "date-fns"
import jwtDecode from "jwt-decode"
import * as yup from "yup"
import type { JWTPayload } from "./types"

const jwtSchema = yup.object().shape({
  exp: yup.number().required().positive(),
  origIat: yup.number().required().positive(),
  address: yup.string().required(),
  iss: yup.string().required().oneOf(["OpenSea"]),
})

export const decodeJwtToken = (token: string): JWTPayload => {
  return jwtDecode<JWTPayload>(token)
}

export const matchesJwtSchema = (payload: JWTPayload) => {
  return jwtSchema.isValidSync(payload)
}

export const isJwtExpired = (payload: JWTPayload) => {
  const exp = addHours(fromUnixTime(payload.exp), -1)
  return isAfter(new Date(), exp)
}
