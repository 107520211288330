import React from "react"
import { Icon, UnstyledButton } from "@opensea/ui-kit"
import styled from "styled-components"
import { interactiveStylesPrimary } from "@/design-system/utils"
import { useShoppingCartAddOrRemoveOrders } from "@/features/shopping-cart/utils/ShoppingCartContextProvider"
import { useTranslate } from "@/hooks/useTranslate"

const InteractiveIcon = styled(Icon)`
  ${interactiveStylesPrimary};
`

type RemoveFromCartButtonProps = {
  orderRelayId: string
}

export const RemoveFromCartButton = ({
  orderRelayId,
}: RemoveFromCartButtonProps) => {
  const t = useTranslate("bulkPurchase")

  const { removeOrder } = useShoppingCartAddOrRemoveOrders()

  return (
    <UnstyledButton
      onClick={() => {
        removeOrder(orderRelayId)
      }}
    >
      <InteractiveIcon
        size={20}
        title={t("shoppingCart.cta.remove", "Remove item")}
        value="delete"
      />
    </UnstyledButton>
  )
}
