import React from "react"
import { AccountRecentViewsProvider } from "./accountRecentViews"
import { CollectionRecentViewsProvider } from "./collectionRecentViews"

type RecentViewsProviderProps = {
  children: React.ReactNode
}

export const RecentViewsProvider = ({ children }: RecentViewsProviderProps) => {
  return (
    <CollectionRecentViewsProvider>
      <AccountRecentViewsProvider>{children}</AccountRecentViewsProvider>
    </CollectionRecentViewsProvider>
  )
}
