import React, { useEffect } from "react"
import {
  Icon,
  UnstyledButton,
  TextBodyProps,
  Text,
  Label,
  SpaceBetween,
  Input,
  Flex,
} from "@opensea/ui-kit"
import { useFormState, useWatch } from "react-hook-form"
import styled from "styled-components"
import { Block, BlockProps } from "@/design-system/Block"
import { Form } from "@/design-system/Form"
import { AddressFormData, useAddressInputV2 } from "@/hooks/useAddressInput"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { useForm } from "@/hooks/useForm"
import { useIsOpen } from "@/hooks/useIsOpen"
import { useTranslate } from "@/hooks/useTranslate"

export type CheckoutGiftRecipient = {
  address: string
  isEmpty: boolean
  isValid: boolean
  destination: string
}

type Props = BlockProps & {
  chain: ChainIdentifier | undefined
  withIcon?: boolean
  onChange: (recipient: CheckoutGiftRecipient) => void
  onExpand: () => void
  overrides?: {
    Text: Partial<TextBodyProps>
  }
  defaultValues?: FormData
}

type FormData = AddressFormData

export const CheckoutGiftRecipientInput = ({
  chain,
  onChange,
  onExpand,
  withIcon = true,
  overrides,
  defaultValues,
  ...blockProps
}: Props) => {
  const t = useTranslate("components")
  const { isOpen, toggle } = useIsOpen()

  const giftRecipientForm = useForm<FormData>({
    mode: "onChange",
    defaultValues: defaultValues ?? {
      destination: "",
      address: "",
    },
  })
  const giftRecipientAddressInput = useAddressInputV2<FormData>(
    giftRecipientForm,
    { chain },
  )
  const { control } = giftRecipientForm
  const [giftRecipientAddress, giftRecipientDestination] = useWatch({
    control,
    name: ["address", "destination"],
  })
  const { isValid } = useFormState({ control })

  useEffect(() => {
    onChange({
      address: giftRecipientAddress.trim(),
      isEmpty: !giftRecipientDestination,
      isValid: !!giftRecipientAddress && isValid,
      destination: giftRecipientDestination.trim(),
    })
  }, [giftRecipientAddress, giftRecipientDestination, isValid, onChange])

  return (
    <Block {...blockProps}>
      <FullWidthButton
        aria-expanded={isOpen}
        id="checkout-gift-wallet-toggle"
        onClick={() => {
          if (!isOpen) {
            onExpand()
          }
          toggle()
        }}
      >
        <SpaceBetween className="w-full items-center">
          <Flex className="items-center">
            {withIcon && (
              <Icon className="mr-3" value="account_balance_wallet" />
            )}

            <Label
              className="cursor-pointer"
              htmlFor="checkout-gift-wallet-toggle"
            >
              <Text.Body weight="semibold" {...overrides?.Text}>
                {t(
                  "trade.giftRecipient.sendDifferentWallet",
                  "Send to a different wallet",
                )}
              </Text.Body>
            </Label>
          </Flex>
          <Icon value={isOpen ? "expand_less" : "expand_more"} />
        </SpaceBetween>
      </FullWidthButton>
      {isOpen && (
        <Block marginTop="20px">
          <StyledFormControl
            {...giftRecipientAddressInput.formControlProps}
            hideLabel
            label={t("trade.giftRecipient.label", "Wallet address")}
          >
            <Input {...giftRecipientAddressInput.inputProps} />
          </StyledFormControl>
        </Block>
      )}
    </Block>
  )
}

const FullWidthButton = styled(UnstyledButton)`
  cursor: pointer;
  width: 100%;
`

const StyledFormControl = styled(Form.Control)`
  margin-bottom: 0;
`
