import { init } from "@sentry/nextjs"
import { getEnvironment } from "@/constants/environment"

type Options = Parameters<typeof init>[0]

export const MAX_VALUE_LENGTH = 1024

export const SENTRY_COMMON_CONFIG: Partial<Options> = {
  enabled: process.env.NODE_ENV === "production",
  dsn: process.env.SENTRY_DSN,
  environment: getEnvironment(),
  maxValueLength: MAX_VALUE_LENGTH,
  ignoreErrors: [
    "ParamError",
    "PollingBlockTracker",
    "RRNLRequestError",
    "Failed to fetch",
    "Load failed",
    "NetworkError when attempting to fetch resource",
    "AbortError",
    "EvalError",
    "undefined is not an object (evaluating 'window.webkit.messageHandlers')",
    "user rejected transaction",
    "User rejected the transaction",
    "User canceled",
    "User rejected the request.",
    "User denied message signature",
    "Already processing eth_requestAccounts. Please wait.",
    "Refused to compile or instantiate WebAssembly",
    "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
    "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
    "Error: No active wallet found",
    "Error: Request limit exceeded.",
    "Please accept or reject the pending network switch request",
    "TypeError: f.setRequestHeader is not a function",
  ],
}
