import React, { useEffect } from "react"
import { Text, FlexColumn } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import styled from "styled-components"
import { useContextSelector } from "use-context-selector"
import { StackedAssetMedia } from "@/components/assets/StackedAssetMedia"
import { Block } from "@/design-system/Block"
import { Modal } from "@/design-system/Modal"
import { GaslessCancelLearnMoreLink } from "@/features/cancel-orders/components/GaslessCancelLearnMoreLink.react"
import { CancelOrderContext } from "@/features/cancel-orders/context/CancelOrderContext.react"
import { trackOpenGaslessCancelProcessingModal } from "@/features/cancel-orders/utils/analytics"
import { useGlobalModal } from "@/hooks/useGlobalModal"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { GaslessCancellationProcessingModal_items$key } from "@/lib/graphql/__generated__/GaslessCancellationProcessingModal_items.graphql"
import { OrderSide } from "@/lib/graphql/__generated__/OrderType.graphql"

type GaslessCancellationProcessingModalProps = {
  cancellationId: string
  items: GaslessCancellationProcessingModal_items$key
  orderSide: OrderSide
  cancelOnChain: () => unknown
  onDone: () => unknown
  onFail: () => unknown
}

export const GaslessCancellationProcessingModal = ({
  cancellationId,
  items: itemsDataKey,
  orderSide,
  cancelOnChain,
  onDone,
  onFail,
}: GaslessCancellationProcessingModalProps) => {
  const t = useTranslate("components")
  const { closeModal } = useGlobalModal()

  useMountEffect(() => {
    trackOpenGaslessCancelProcessingModal()
  })

  const failedCount = useContextSelector(
    CancelOrderContext,
    v =>
      v.cancellations.find(cancellation => cancellation.id === cancellationId)
        ?.failedCount,
  )

  const ordersAwaitingFinalizationCount = useContextSelector(
    CancelOrderContext,
    v =>
      v.cancellations.find(cancellation => cancellation.id === cancellationId)
        ?.ordersAwaitingFinalization.size,
  )

  useEffect(() => {
    if (ordersAwaitingFinalizationCount !== 0) {
      return // Not done processing
    }

    if (failedCount) {
      onFail()
      return
    }

    onDone()
  }, [failedCount, onDone, onFail, ordersAwaitingFinalizationCount])

  const items = useFragment(
    graphql`
      fragment GaslessCancellationProcessingModal_items on ItemType
      @relay(plural: true) {
        ...StackedAssetMedia_assets
      }
    `,
    itemsDataKey,
  )

  return (
    <>
      <Modal.Header></Modal.Header>
      <Modal.Body className="text-center" paddingX="64px">
        <Block marginTop="8px">
          <StackedAssetMedia assets={items} isLoading />
        </Block>
        <FlexColumn className="mt-8 gap-2">
          <Modal.Body.Title>
            {t(
              "gaslessCancel.processingModal.title",
              "Cancellation processing",
            )}
          </Modal.Body.Title>
          <Text.Body className="block text-secondary" size="medium">
            {orderSide === "BID"
              ? t(
                  "gaslessCancel.processingModal.body.offer",
                  {
                    "0": "Canceling may take up to a few minutes, during which the offers may be fulfilled.",
                    one: "Canceling may take up to a few minutes, during which the offer may be fulfilled.",
                    other:
                      "Canceling may take up to a few minutes, during which the offers may be fulfilled.",
                  },
                  { count: ordersAwaitingFinalizationCount ?? 0 },
                )
              : t(
                  "gaslessCancel.processingModal.body.listing",
                  {
                    "0": "Canceling may take up to a few minutes, during which the listings may be fulfilled.",
                    one: "Canceling may take up to a few minutes, during which the listing may be fulfilled.",
                    other:
                      "Canceling may take up to a few minutes, during which the listings may be fulfilled.",
                  },
                  { count: ordersAwaitingFinalizationCount ?? 0 },
                )}
            {t(
              "gaslessCancel.processingModal.body.remainder",
              "You can leave the window and cancellation will continue in the background. {{learnMoreLink}}",
              { learnMoreLink: <GaslessCancelLearnMoreLink /> },
            )}
          </Text.Body>
        </FlexColumn>
      </Modal.Body>
      <Modal.Footer>
        <StyledModalFooterButton variant="secondary" onClick={cancelOnChain}>
          {t(
            "gaslessCancel.processingModal.cancelFasterWithGasFee",
            "Cancel faster with gas fee",
          )}
        </StyledModalFooterButton>
        <Modal.Footer.Button
          href="/account/offers"
          variant="primary"
          onClick={closeModal}
        >
          {t("gaslessCancel.processingModal.viewInProfile", "View in profile")}
        </Modal.Footer.Button>
      </Modal.Footer>
    </>
  )
}

const StyledModalFooterButton = styled(Modal.Footer.Button)`
  padding-left: 8px;
  padding-right: 8px;
`
