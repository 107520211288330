import { RefCallback } from "react"
import { useClickAway as useReactUseClickAway } from "react-use"
import { useElementSet } from "./useElementSet"

export type UseClickAwayReturn = {
  /**
   * Add this ref to content to prevent closing when the content is clicked
   */
  preventClickAwayRefCallback: RefCallback<HTMLElement>
}

/**
 * Trigger a callback on click event outside of clickAwayRef. Similar to useClickAway
 * from react-use but returns a preventClickAwayRefCallback that can be added as a ref
 * to elements to prevent triggering the callback when they (or their child elements)
 * are clicked.
 */
export const useClickAway = <
  TElement extends HTMLElement = HTMLDivElement,
  TEvent extends Event = Event,
>(
  clickAwayRef: React.RefObject<TElement>,
  onClickAway: (event: TEvent) => unknown,
): UseClickAwayReturn => {
  const { addToSet, getElements } = useElementSet()

  useReactUseClickAway<TEvent>(clickAwayRef, event => {
    // click is within modal opened by wallet so do nothing
    if (
      getElements().some(modalElement =>
        modalElement.contains(event.target as HTMLElement),
      )
    ) {
      return
    }

    onClickAway(event)
  })

  const preventClickAwayRefCallback: RefCallback<HTMLElement> = element => {
    if (element) {
      addToSet(element)
    }
  }

  return {
    preventClickAwayRefCallback,
  }
}
