import { useCallback } from "react"
import { Currency } from "@/components/nav/WalletPopover/types"
import { useChains } from "@/hooks/useChains"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { isPolygon, getEthereumChain } from "@/lib/helpers/chainUtils"

type ChainCurrencies = {
  baseCurrency: Currency
  wrappedCurrency: Currency
}

export const useGetChainCurrencies = () => {
  const { getNativeCurrencySymbol, getWrappedCurrencySymbol } = useChains()

  return useCallback(
    (chain: ChainIdentifier | null | undefined): ChainCurrencies => {
      if (!chain) {
        return {
          baseCurrency: {
            symbol: "ETH",
            chain: "ETHEREUM",
          },
          wrappedCurrency: {
            symbol: "WETH",
            chain: "ETHEREUM",
          },
        }
      }

      return {
        baseCurrency: {
          symbol: isPolygon(chain) ? "ETH" : getNativeCurrencySymbol(chain),
          chain: isPolygon(chain) ? getEthereumChain() : chain,
        },
        wrappedCurrency: {
          symbol: getWrappedCurrencySymbol(chain),
          chain,
        },
      }
    },
    [getNativeCurrencySymbol, getWrappedCurrencySymbol],
  )
}

export const useChainCurrencies = (
  chain: ChainIdentifier | null | undefined,
): ChainCurrencies => {
  const getChainCurrencies = useGetChainCurrencies()
  return getChainCurrencies(chain)
}
