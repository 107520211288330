import React, { CSSProperties } from "react"
import { Flex, Spinner } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import styled from "styled-components"
import { AssetMedia, AssetMediaProps } from "@/components/assets/AssetMedia"
import { Block } from "@/design-system/Block"
import { Image } from "@/design-system/Image"
import { StackedAssetMedia_assets$key } from "@/lib/graphql/__generated__/StackedAssetMedia_assets.graphql"

type Variant = "default" | "large" | "small" | "xsmall" | "xxsmall" | "xxxsmall"

export type StackedAssetMediaProps = {
  assets: StackedAssetMedia_assets$key
  backgroundCover?: CSSProperties["background"]
  isLoading?: boolean
  type?: "asset" | "collection"
  variant?: Variant
  overrides?: { AssetMedia: { props: Partial<AssetMediaProps> } }
}

export const StackedAssetMedia = ({
  assets: assetDataKeys,
  backgroundCover,
  isLoading = false,
  type = "asset",
  variant = "default",
  overrides,
}: StackedAssetMediaProps) => {
  const assets = useFragment(
    graphql`
      fragment StackedAssetMedia_assets on AssetType @relay(plural: true) {
        relayId
        ...AssetMedia_asset
        collection {
          logo
        }
      }
    `,
    assetDataKeys,
  )

  const numCards = Math.min(assets.length, variant === "default" ? 3 : 2)
  const numMediaCards = Math.max(1, numCards - 1)

  // TODO: Rename all sizes to avoid too many small variants.
  // ie. default => xl, small => lg, xsmall => default, xxsmall => med, xxxsmall => small
  // https://linear.app/opensea/issue/CLCTR-4377
  const baseSize = {
    default: 184,
    large: 128,
    small: 72,
    xsmall: 56,
    xxsmall: 48,
    xxxsmall: 40,
  }[variant]

  const verticalOffsetPerCard = {
    default: 16,
    large: 14,
    small: 12,
    xsmall: 10,
    xxsmall: 6,
    xxxsmall: 6,
  }[variant]

  const sizeOffsetPerCard = {
    default: 20,
    large: 16,
    small: 12,
    xsmall: 10,
    xxsmall: 8,
    xxxsmall: 8,
  }[variant]

  const baseBorderRadius = {
    default: 10,
    large: 10,
    small: 10,
    xsmall: 8,
    xxsmall: 6,
    xxxsmall: 6,
  }[variant]

  const borderRadiusOffsetPerCard = 2
  const assetsDisplayed = assets.slice(0, numCards)
  const totalHeight = baseSize + (numCards - 1) * verticalOffsetPerCard

  return (
    <Block
      className="bg-inherit"
      display="inline-block"
      height={`${totalHeight}px`}
      width={`${baseSize}px`}
    >
      <Block
        className="relative w-full bg-inherit"
        display="inline-block"
        height="100%"
      >
        <>
          {/* Iterating in reverse order so assets show on top of each other */}
          {assetsDisplayed.reverse().map((asset, index) => {
            const reverseIndex = numCards - index - 1
            const sizeIncrement = sizeOffsetPerCard * reverseIndex
            const size = baseSize - sizeIncrement
            const bottom = verticalOffsetPerCard * reverseIndex + sizeIncrement
            const left = (sizeOffsetPerCard * reverseIndex) / 2
            const borderRadius = `${
              baseBorderRadius - borderRadiusOffsetPerCard * reverseIndex
            }px`

            return (
              <React.Fragment key={asset.relayId}>
                {index <= numCards && (
                  <BackgroundCover
                    className="bg-elevation-2"
                    data-testid="stacked-image"
                    height={size}
                    style={{
                      background: backgroundCover,
                      borderRadius,
                      bottom: bottom + verticalOffsetPerCard / 3,
                      left,
                    }}
                    width={size}
                  />
                )}
                <AssetMediaContainer
                  className="absolute"
                  style={{
                    borderRadius,
                    bottom,
                    left,
                  }}
                >
                  {/* Separate relative block to ensure loading overlay is on top of asset media. */}
                  <Block className="relative" style={{ borderRadius }}>
                    {reverseIndex === numMediaCards ? (
                      <PlaceholderMedia
                        height={size}
                        style={{ borderRadius }}
                        width={size}
                      />
                    ) : type === "asset" ? (
                      <AssetMedia
                        asset={asset}
                        size={size}
                        {...overrides?.AssetMedia.props}
                      />
                    ) : asset.collection.logo ? (
                      <Block className="relative" height={size} width={size}>
                        <Image
                          alt=""
                          height={size}
                          layout="fill"
                          objectFit="contain"
                          src={asset.collection.logo}
                          width={size}
                        />
                      </Block>
                    ) : (
                      <PlaceholderMedia height={size} width={size} />
                    )}
                    {isLoading && reverseIndex === 0 && (
                      <LoadingOverlay style={{ height: size, width: size }}>
                        <Spinner color="white" />
                      </LoadingOverlay>
                    )}
                  </Block>
                </AssetMediaContainer>
              </React.Fragment>
            )
          })}
        </>
      </Block>
    </Block>
  )
}

const AssetMediaContainer = styled(Block)`
  background: ${props =>
    props.theme.colors.components.elevation.level2.background};
  border: 1px solid ${props => props.theme.colors.components.border.level1};
  overflow: hidden;
`

const BackgroundCover = styled(Block)`
  position: absolute;
`

export const PlaceholderMedia = styled(Block)`
  background: ${props => props.theme.colors.fog};
`

const LoadingOverlay = styled(Flex)`
  align-items: center;
  background: ${props => props.theme.colors.withOpacity.charcoal.light};
  justify-content: center;
  position: absolute;
  top: 0;
`
