import { Client, createClient } from "graphql-ws"
import {
  ConcreteBatch,
  Middleware,
  MiddlewareSync,
  RelayNetworkLayer,
  SubscribeFunction,
  RelayObservable,
  Variables,
} from "react-relay-network-modern"
import { Environment, Observable, RecordSource, Store } from "relay-runtime"
import { RecordMap } from "relay-runtime/lib/store/RelayStoreTypes"
import API from "@/lib/api"
import { getSingleSignedQuery } from "./middlewares/signQueryMiddleware"

const RETRY_ATTEMPTS = 100
const CONNECTION_ACK_TIMEOUT = 12_000
const LAZY_CLOSE_TIMEOUT = 3_000

export const createServerEnvironment = (
  middleware: (Middleware | MiddlewareSync | null)[],
  initialRecord?: RecordMap,
) => {
  return new Environment({
    store: new Store(new RecordSource(initialRecord)),
    network: new RelayNetworkLayer(middleware),
  })
}

type OperationWithCacheId = ConcreteBatch & {
  cacheID: string | null
}

// The typescript definitions from react-relay-network-modern are wrong so we have to do some unsafe
// casting, see:
// https://github.com/relay-tools/react-relay-network-modern/pull/148
// https://github.com/relay-tools/react-relay-network-modern/issues/140
const createSubscribeFn =
  (client: Client): SubscribeFunction =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (operation: ConcreteBatch, variables: Variables): RelayObservable<any> => {
    const op = operation as OperationWithCacheId
    return (
      Observable.create(sink => {
        return client.subscribe(
          {
            operationName: op.name,
            query: "",
            variables,
            extensions: {
              "x-signed-query": getSingleSignedQuery(op.name),
              "x-query-id": op.cacheID || op.id,
            },
          },
          sink,
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }) as unknown as RelayObservable<any>
    ).catch(err => {
      if (
        err[0] &&
        err[0].extensions &&
        err[0].extensions.code === "UNKNOWN_QUERY_ID"
      ) {
        return Observable.create(sink => {
          if (!op.text) {
            return sink.error(new Error("Operation text cannot be empty"))
          }
          return client.subscribe(
            {
              operationName: op.name,
              query: op.text,
              variables,
              extensions: {
                "x-signed-query": getSingleSignedQuery(op.name),
                "x-query-id": op.cacheID || op.id,
              },
            },
            sink,
          )
        })
      } else {
        throw err
      }
    })
  }

export const createClientEnvironment = (
  middleware: (Middleware | MiddlewareSync | null)[],
  serializedStore?: RecordMap,
) => {
  const subscriptionUrl = API.getSubscriptionUrl()
  const wsClient = createClient({
    url: `${subscriptionUrl}/graphql/`,
    retryAttempts: RETRY_ATTEMPTS,
    connectionAckWaitTimeout: CONNECTION_ACK_TIMEOUT,
    lazyCloseTimeout: LAZY_CLOSE_TIMEOUT,
  })

  return new Environment({
    store: new Store(new RecordSource(serializedStore)),
    network: new RelayNetworkLayer(middleware, {
      subscribeFn: createSubscribeFn(wsClient),
    }),
  })
}
