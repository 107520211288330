import React from "react"

type Props = {
  className?: string
  width?: number
  fill?: string
}

export const GunzillaLogo = ({
  className,
  fill = "black",
  width = 20,
}: Props) => (
  <svg
    fill={fill}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 579 579"
    width={width}
  >
    <circle cx="289.5" cy="289.5" r="289.5" fill="black" />
    <path
      d="M389.513 170H190V360.268L238.858 409H389.513V344.552L347.09 302.197H389.513V287.544L332.406 230.536H263.469V244.714L306.39 287.544H263.469V301.723L325.018 363.14H276.001L248.784 335.959V215.882H389.513V170Z"
      fill="white"
    />
  </svg>
)
