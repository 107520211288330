/**
 * @generated SignedSource<<ed14f306281dbe8ca5f315a6bcfdf32d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TokenPricePayment$data = {
  readonly symbol: string;
  readonly " $fragmentType": "TokenPricePayment";
};
export type TokenPricePayment$key = {
  readonly " $data"?: TokenPricePayment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TokenPricePayment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "TokenPricePayment"
};

(node as any).hash = "e1cf9954974978fe4781087fa4700ce0";

export default node;
