import { useCallback } from "react"
import { useTrackingFn } from "@/lib/analytics/hooks/useTrackingFn"
import { BlockchainAction } from "./types"

type Properties = {
  blockchainAction: BlockchainAction
}

export const useTrackBlockchainAttempt = () => {
  const trackingFn = useTrackingFn<Properties>(
    "core marketplace action blockchain attempt",
  )

  return useCallback(
    (properties: Properties) => trackingFn(properties),
    [trackingFn],
  )
}
