/**
 * @generated SignedSource<<be3ff50add74f2676614896b11be0a72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MoonPayCheckoutModalQuery$variables = {
  order: string;
};
export type MoonPayCheckoutModalQuery$data = {
  readonly moonpay: {
    readonly moonpayFiatCheckoutWidgetData: {
      readonly externalTransactionId: string;
      readonly url: string;
    } | null;
  };
};
export type MoonPayCheckoutModalQuery = {
  response: MoonPayCheckoutModalQuery$data;
  variables: MoonPayCheckoutModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MoonpayType",
    "kind": "LinkedField",
    "name": "moonpay",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "order",
            "variableName": "order"
          }
        ],
        "concreteType": "MoonpayFiatCheckoutWidgetDataType",
        "kind": "LinkedField",
        "name": "moonpayFiatCheckoutWidgetData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "externalTransactionId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MoonPayCheckoutModalQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MoonPayCheckoutModalQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a9d031b4acee5e6aa15a98411995ef82",
    "id": null,
    "metadata": {},
    "name": "MoonPayCheckoutModalQuery",
    "operationKind": "query",
    "text": "query MoonPayCheckoutModalQuery(\n  $order: OrderRelayID!\n) {\n  moonpay {\n    moonpayFiatCheckoutWidgetData(order: $order) {\n      url\n      externalTransactionId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2b1bc956942eda6ca46a1bf63eeee23e";

export default node;
