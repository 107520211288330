/**
 * @generated SignedSource<<6eb30cd8efd54307fa640406a1ffbc31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PriceTotals_ordersFilled$data = {
  readonly failedOrders: ReadonlyArray<{
    readonly orderData: {
      readonly payment: {
        readonly symbol: string;
        readonly " $fragmentSpreads": FragmentRefs<"TokenPricePayment">;
      };
    };
  }>;
  readonly successfulOrders: ReadonlyArray<{
    readonly order: {
      readonly payment: {
        readonly " $fragmentSpreads": FragmentRefs<"TokenPricePayment">;
      };
    };
    readonly pricePaid: {
      readonly symbol: string;
      readonly unit: string;
      readonly usd: string;
    };
  }>;
  readonly transaction: {
    readonly payment: {
      readonly " $fragmentSpreads": FragmentRefs<"TokenPricePayment">;
    };
    readonly transactionFee: {
      readonly symbol: string;
      readonly unit: string;
      readonly usd: string;
    };
  };
  readonly " $fragmentType": "PriceTotals_ordersFilled";
};
export type PriceTotals_ordersFilled$key = {
  readonly " $data"?: PriceTotals_ordersFilled$data;
  readonly " $fragmentSpreads": FragmentRefs<"PriceTotals_ordersFilled">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v1 = {
  "kind": "InlineDataFragmentSpread",
  "name": "TokenPricePayment",
  "selections": [
    (v0/*: any*/)
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "PaymentAssetType",
  "kind": "LinkedField",
  "name": "payment",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usd",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PriceTotals_ordersFilled",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderFilledType",
      "kind": "LinkedField",
      "name": "successfulOrders",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderV2Type",
          "kind": "LinkedField",
          "name": "order",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceType",
          "kind": "LinkedField",
          "name": "pricePaid",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v4/*: any*/),
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderToFillType",
      "kind": "LinkedField",
      "name": "failedOrders",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderDataType",
          "kind": "LinkedField",
          "name": "orderData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PaymentAssetType",
              "kind": "LinkedField",
              "name": "payment",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TransactionReceiptType",
      "kind": "LinkedField",
      "name": "transaction",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceType",
          "kind": "LinkedField",
          "name": "transactionFee",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v0/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "BulkPurchaseFilledType",
  "abstractKey": null
};
})();

(node as any).hash = "e2e2cd244d2f9861df9bb9b5776e3b51";

export default node;
