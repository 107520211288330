import { getTrackingFn } from "../utils"

type SelfServePrimaryDropFormProps = {
  collectionSlug: string
  collectionRelayId: string
}

// Deploy your contract
export const trackContractDeploySignature = getTrackingFn(
  "[sspd] deploy - signature",
)
export const trackContractDeployPending = getTrackingFn(
  "[sspd] deploy - pending",
)
export const trackContractDeployDeployed = getTrackingFn(
  "[sspd] deploy - deployed",
)
export const trackContractDeployFailed = getTrackingFn("[sspd] deploy - failed")

// Edit your collection
type InputInteractionProps = SelfServePrimaryDropFormProps & {
  inputName: string
}
export const trackSspdSaveDraft =
  getTrackingFn<SelfServePrimaryDropFormProps>("[sspd] edit - save")
export const trackSspdPublish = getTrackingFn<SelfServePrimaryDropFormProps>(
  "[sspd] edit - publish",
)
export const trackSspdInputInteraction = trackOnlyOnce<InputInteractionProps>(
  getTrackingFn<InputInteractionProps>("[sspd] edit - input"),
)
export const trackDownloadAllowlistTemplate = getTrackingFn(
  "[sspd] edit - allowlist template",
)

// Discard changes
export const trackSspdDiscardModalSave = getTrackingFn(
  "[sspd] discard modal - save",
)
export const trackSspdDiscardModalDiscard = getTrackingFn(
  "[sspd] discard modal - discard",
)

// helper function
function trackOnlyOnce<T>(fn: (args: T) => void) {
  let hasBeenCalled = false
  return (args: T) => {
    if (!hasBeenCalled) {
      fn(args)
      hasBeenCalled = true
    }
  }
}
