import { stringifyQueryParams } from "@/lib/helpers/urls"
import type { NextUrl, Url } from "./types"

export const convertCustomUrl = (url: Url): NextUrl => {
  if (typeof url === "string") {
    return url
  }
  return {
    ...url,
    query:
      typeof url.query === "string"
        ? url.query
        : stringifyQueryParams(url.query, { addQueryPrefix: false }),
  }
}
