import React from "react"
import { identity } from "lodash"
import { useFragment } from "react-relay"
import { _FragmentRefs } from "relay-runtime"
import { OrderPrice$key } from "@/lib/graphql/__generated__/OrderPrice.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"
import {
  getTokenPricePayment,
  TokenPrice,
  TokenPriceProps,
} from "./TokenPrice.react"

type Variant = "perUnit" | "total"

export type OrderPriceProps = Pick<
  TokenPriceProps,
  | "color"
  | "fontWeight"
  | "isInline"
  | "symbolVariant"
  | "className"
  | "compactDisplay"
  | "maxDecimalPlaces"
  | "noTooltip"
> & {
  order: OrderPrice$key
  variant?: Variant
  map?: (price: BigNumber) => BigNumber
}

export const OrderPrice = ({
  order,
  variant = "total",
  map = identity,
  ...rest
}: OrderPriceProps) => {
  const data = useFragment(
    graphql`
      fragment OrderPrice on OrderV2Type {
        priceType {
          unit
        }
        perUnitPriceType {
          unit
        }
        payment {
          ...TokenPricePayment
        }
      }
    `,
    order,
  )

  const { priceType, perUnitPriceType } = data

  const tokenPricePayment = getTokenPricePayment(data.payment)

  const basePrice =
    variant === "total" ? bn(priceType.unit) : bn(perUnitPriceType.unit)

  const currentPrice = bn(basePrice)

  const price = map(currentPrice)

  return <TokenPrice {...tokenPricePayment} price={price} {...rest} />
}
