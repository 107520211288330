/**
 * @generated SignedSource<<1cc665b5e5fd9da3d4a08fb20d6c1b54>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useFulfillingListingsWillReactivateOrdersQuery$variables = {
  assets: ReadonlyArray<string>;
  toAddress: string;
};
export type useFulfillingListingsWillReactivateOrdersQuery$data = {
  readonly assetsTransferability: {
    readonly assetsWithReactivatableOrders: ReadonlyArray<{
      readonly relayId: string;
    }>;
  };
};
export type useFulfillingListingsWillReactivateOrdersQuery = {
  response: useFulfillingListingsWillReactivateOrdersQuery$data;
  variables: useFulfillingListingsWillReactivateOrdersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "assets"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "toAddress"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "assets",
    "variableName": "assets"
  },
  {
    "kind": "Variable",
    "name": "toAddress",
    "variableName": "toAddress"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useFulfillingListingsWillReactivateOrdersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AssetsTransferabilityType",
        "kind": "LinkedField",
        "name": "assetsTransferability",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetType",
            "kind": "LinkedField",
            "name": "assetsWithReactivatableOrders",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useFulfillingListingsWillReactivateOrdersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AssetsTransferabilityType",
        "kind": "LinkedField",
        "name": "assetsTransferability",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetType",
            "kind": "LinkedField",
            "name": "assetsWithReactivatableOrders",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "93278e5e0bd8cd533ab38d48f2731a17",
    "id": null,
    "metadata": {},
    "name": "useFulfillingListingsWillReactivateOrdersQuery",
    "operationKind": "query",
    "text": "query useFulfillingListingsWillReactivateOrdersQuery(\n  $assets: [AssetRelayID!]!\n  $toAddress: AddressScalar!\n) {\n  assetsTransferability(assets: $assets, toAddress: $toAddress) {\n    assetsWithReactivatableOrders {\n      relayId\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8c27390d8fd9de65df753dbebc087931";

export default node;
