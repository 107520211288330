import { addMonths, differenceInMinutes, isAfter, subMinutes } from "date-fns"
import type { ValidationRule } from "react-hook-form"
import {
  MAXIMUM_DURATION_IN_MONTHS,
  MINIMUM_LISTING_DURATION_IN_MINUTES,
  TIME_WINDOW_IN_MINUTES,
} from "@/constants/index"
import { DateRange } from "@/features/orders/hooks/useDateRangeOptions"
import { validateStartTime } from "@/features/sell/components/SellFlow/utils"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { isMultichain } from "@/lib/helpers/chainUtils"
import {
  BigNumber,
  bn,
  isValidNumericInput,
  NumberInput,
} from "@/lib/helpers/numberUtils"
import { hasHTTPS, isHttpUrl } from "@/lib/helpers/urls"
import { isValidAddress } from "./address"
import { isQualifiedName } from "./ens"
import { Maybe } from "./type"

export const isValidNumberWithDecimals = ({
  allowEmpty,
  maxDecimals,
}: {
  allowEmpty?: boolean
  maxDecimals?: number
}) => ({
  isValidNumber: (v: string) =>
    (allowEmpty && v === "") || !bn(v).isNaN() || "Please enter a valid amount",
  maxDecimals: (v: string) =>
    (allowEmpty && v === "") ||
    isValidNumericInput(v, maxDecimals) ||
    (v === "0"
      ? "The amount must be greater than 0"
      : maxDecimals === 0
      ? "The amount must be a positive integer"
      : `The amount cannot have precision greater than ${maxDecimals} decimal places`),
})

export const isValidDateRange = {
  isMinimumAfterStartEndDate: (v: DateRange) =>
    differenceInMinutes(v.end, v.start) >=
      MINIMUM_LISTING_DURATION_IN_MINUTES ||
    `End date must be at least ${MINIMUM_LISTING_DURATION_IN_MINUTES} minutes after the start date`,
  isMinimumEndDate: (v: DateRange) => {
    return (
      differenceInMinutes(v.end, new Date(), { roundingMethod: "round" }) >=
        MINIMUM_LISTING_DURATION_IN_MINUTES ||
      `End date must be at least ${MINIMUM_LISTING_DURATION_IN_MINUTES} minutes after now`
    )
  },
  isMaximumEndDate: (v: DateRange) =>
    !isAfter(
      subMinutes(v.end, 2 * TIME_WINDOW_IN_MINUTES),
      addMonths(v.start, MAXIMUM_DURATION_IN_MONTHS),
    ) ||
    `End date must be less than ${MAXIMUM_DURATION_IN_MONTHS} months after the start date`,
  isValidStartDate: (v: DateRange) =>
    validateStartTime(v.start) || "Start date must be now or in the future",
}

const isValidAddressOrEns = (value: string) =>
  isValidAddress(value) || isQualifiedName(value)

export const validateAddressOrEns = (value: string) =>
  isValidAddressOrEns(value) || "Invalid address or ENS name"

export const getIsAboveMinimumListingPriceUsd =
  (
    chain: ChainIdentifier,
    usdSpotPrice: Maybe<NumberInput>,
    minimumListingPrice: BigNumber,
  ) =>
  (value: string) => {
    const bnValue = bn(value)

    if (!usdSpotPrice || bnValue.isNaN() || !isMultichain(chain)) {
      return true
    }

    const listingPriceUsd = bnValue.times(bn(usdSpotPrice))
    return (
      listingPriceUsd.isGreaterThanOrEqualTo(minimumListingPrice) ||
      `Listing price must be greater than $${minimumListingPrice.toFixed(
        2,
      )} USD`
    )
  }

export const EMAIL_VALIDATION_RULE: ValidationRule<RegExp> = {
  value: /\S+@\S+\.\S+/,
  message: "Entered value does not match email format.",
}

export const SLUG_VALIDATION_RULE: ValidationRule<RegExp> = {
  value: /^[a-z0-9-]*$/,
  message:
    "Entered value must only contain lowercase letters, numbers, and hyphens",
}

export const SECURITY_CODE_VALIDATION_RULE: ValidationRule<RegExp> = {
  value: /^\d{6}$/,
  message: "Entered value does not match security code format.",
}

export type WithInputValue = {
  value?: string | undefined
  inputValue: string
}
export const validateWithInput = (field: WithInputValue, message?: string) =>
  Boolean(field.inputValue ? field.value : true) || message

export const validateUrl = (inputValue: string, prefix = "") => {
  let url = `${prefix}${prefix ? inputValue.replace(prefix, "") : inputValue}`
  if (!prefix && inputValue && !hasHTTPS(inputValue)) {
    url = `https://${inputValue}`
  }
  if (prefix && url === prefix) {
    url = ""
  }
  return isHttpUrl(url) ? url : ""
}

export const removePrefix = (inputValue: string, prefix = "") => {
  const match = inputValue.match(new RegExp(`${prefix}(.*)`))
  return match ? match[1] : inputValue
}
