/**
 * @generated SignedSource<<8cac5982042624fa4b338b879f6bdaa0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetMediaEditions_asset$data = {
  readonly decimals: number | null;
  readonly ownedQuantity?: string | null;
  readonly " $fragmentType": "AssetMediaEditions_asset";
};
export type AssetMediaEditions_asset$key = {
  readonly " $data"?: AssetMediaEditions_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssetMediaEditions_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "identity"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showQuantity"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssetMediaEditions_asset",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "decimals",
      "storageKey": null
    },
    {
      "condition": "showQuantity",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "identity",
              "variableName": "identity"
            }
          ],
          "kind": "ScalarField",
          "name": "ownedQuantity",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "e3011624f0c111f4fb961a74fff2b71a";

export default node;
