import { useTrackingFn } from "@/lib/analytics/hooks/useTrackingFn"

export const useTrackClickLink = () =>
  useTrackingFn<{
    url?: string
    target?: string
    source?: string
    currentUrl: string
    type: "internal" | "external"
  }>("click link")
