import {
  ProviderInterface,
  createCoinbaseWalletSDK,
} from "@coinbase/wallet-sdk"
import { ethers } from "ethers"
import { WALLET_NAME } from "@/constants/wallet"
import { SITE_URL_STRING } from "@/context/location"
import { Chain } from "@/hooks/useChains/types"
import { OPENSEA_LOGO_IMG } from "../../../constants"
import Web3EvmProvider, {
  ETHERS_WEB3_PROVIDER_NETWORK,
  Web3Provider,
} from "./web3EvmProvider"

class CoinbaseWalletProvider extends Web3EvmProvider {
  coinbaseProvider: ProviderInterface
  provider: Web3Provider

  constructor(coinbaseProvider: ProviderInterface, chains: Chain[]) {
    super(chains)
    this.coinbaseProvider = coinbaseProvider
    this.provider = new ethers.providers.Web3Provider(
      this.coinbaseProvider,
      ETHERS_WEB3_PROVIDER_NETWORK,
    )
  }

  disconnect = async () => {
    await this.coinbaseProvider.disconnect()
  }

  getName = () => {
    return WALLET_NAME.CoinbaseWallet
  }
}

export const createCoinbaseWalletProvider = async (
  chains: Chain[],
): Promise<CoinbaseWalletProvider> => {
  const coinbaseSdk = createCoinbaseWalletSDK({
    appName: "OpenSea",
    appLogoUrl: SITE_URL_STRING + OPENSEA_LOGO_IMG,
    appChainIds: chains.flatMap(chain => {
      if (!chain.networkId) {
        return [] // We should always have a network id
      }
      const chainIdAsInt = parseInt(chain.networkId)
      if (Number.isNaN(chainIdAsInt)) {
        return [] // We should always have a valid network id
      }
      // Including these chain ids breaks coinbase wallet
      const chainsToExclude = [
        80002, // Amoy (Polygon Sepolia)
      ]
      if (chainsToExclude.includes(chainIdAsInt)) {
        return []
      }
      return chainIdAsInt
    }),
  })
  return new CoinbaseWalletProvider(coinbaseSdk.getProvider(), chains)
}
