/* eslint-disable tailwindcss/no-custom-classname */
import React from "react"
import dynamic from "next/dynamic"
import { useFragment } from "react-relay"
import type { ModelSceneProps } from "@/components/viz/ModelScene.react"
import { Block } from "@/design-system/Block"
import { AssetMediaWebgl_asset$key } from "@/lib/graphql/__generated__/AssetMediaWebgl_asset.graphql"
import { graphql } from "@/lib/graphql/graphql"
import {
  AssetMediaImage,
  AssetMediaImageBaseProps,
} from "./AssetMediaImage.react"
import { asBackgroundColor } from "./helpers"

const DynamicModelScene = dynamic<ModelSceneProps>(
  () => import("@/components/viz/ModelScene.react").then(mod => mod.ModelScene),
  { ssr: false },
)

type AssetMediaWebglProps = {
  asset: AssetMediaWebgl_asset$key
  showModel?: boolean
  animationUrl: string
  imageConfig: AssetMediaImageBaseProps
  imagePreviewUrl: string
}

export const AssetMediaWebgl = ({
  asset: assetKey,
  showModel,
  animationUrl,
  imageConfig,
  imagePreviewUrl,
}: AssetMediaWebglProps) => {
  const asset = useFragment(
    graphql`
      fragment AssetMediaWebgl_asset on AssetType {
        backgroundColor
        ...AssetMediaImage_asset
      }
    `,
    assetKey,
  )

  const backgroundColor = asBackgroundColor(asset.backgroundColor)

  if (showModel) {
    return (
      <div className="AssetMedia--animation">
        <Block className="w-full" minHeight="500px">
          <DynamicModelScene
            backgroundColor={backgroundColor}
            url={animationUrl}
          />
        </Block>
      </div>
    )
  }

  return (
    <div className="AssetMedia--animation">
      <AssetMediaImage {...imageConfig} asset={asset} url={imagePreviewUrl} />
    </div>
  )
}
