/**
 * @generated SignedSource<<76d2dfa6e4a649a3fe4cbe98350311e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useTotalPriceOrderData_orderData$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"readOrderDataPrices">;
  readonly " $fragmentType": "useTotalPriceOrderData_orderData";
}>;
export type useTotalPriceOrderData_orderData$key = ReadonlyArray<{
  readonly " $data"?: useTotalPriceOrderData_orderData$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTotalPriceOrderData_orderData">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "useTotalPriceOrderData_orderData",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "readOrderDataPrices",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceType",
          "kind": "LinkedField",
          "name": "perUnitPrice",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "usd",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unit",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PaymentAssetType",
          "kind": "LinkedField",
          "name": "payment",
          "plural": false,
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "TokenPricePayment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symbol",
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "item",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quantity",
                  "storageKey": null
                }
              ],
              "type": "AssetQuantityDataType",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "OrderDataType",
  "abstractKey": null
};

(node as any).hash = "390dd0b30699c017d680e6cac2ca08c4";

export default node;
