import React from "react"
import { Dropdown } from "@/design-system/Dropdown"
import { useTranslate } from "@/hooks/useTranslate"

type LanguageSelectorBackItemProps = {
  onClick: () => unknown
}

export const LanguageSelectorBackItem = ({
  onClick,
}: LanguageSelectorBackItemProps) => {
  const t = useTranslate("components")

  return (
    <Dropdown.Item key="language.back" onClick={onClick}>
      <Dropdown.Item.Avatar icon="arrow_back" />
      <Dropdown.Item.Content>
        {t("nav.languageSelectorBack", "Choose language")}
      </Dropdown.Item.Content>
    </Dropdown.Item>
  )
}
