import bs58 from "bs58"

const KEY_LENGTH = 32

export const isValidAddress = (value: string) => {
  try {
    const key = bs58.decode(value)
    return key.length === KEY_LENGTH
  } catch (_) {
    return false
  }
}

export const SOLANA_NULL_ACCOUNT = "11111111111111111111111111111111"
