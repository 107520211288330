import React, { useCallback } from "react"
import {
  Icon,
  Media,
  Separator,
  UnstyledButton,
  Text,
  SpaceBetween,
  FlexColumn,
} from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import styled from "styled-components"
import { TokenPrice } from "@/components/assets/price/TokenPrice.react"
import { InfoIcon } from "@/components/common/InfoIcon.react"
import { Link } from "@/components/common/Link"
import { BulkPurchaseModal } from "@/components/trade/BulkPurchaseModal"
import {
  CheckoutGiftRecipient,
  CheckoutGiftRecipientInput,
} from "@/components/trade/CheckoutModal/components/CheckoutGiftRecipientInput.react"
import {
  PaymentMethodProps,
  PaymentOption,
} from "@/components/trade/CheckoutModal/components/PaymentMethod"
import { Block } from "@/design-system/Block"
import { BottomSheet } from "@/design-system/BottomSheet"
import { Button, ButtonSize } from "@/design-system/Button"
import { Flex } from "@/design-system/Flex"
import { Item } from "@/design-system/Item"
import { interactiveStylesPrimary } from "@/design-system/utils"
import { useTotalPrice } from "@/features/shopping-cart/hooks/useTotalPrice"
import {
  ItemSideOverflow,
  ItemSideOverflowStacked,
} from "@/features/shopping-cart/styles"
import {
  trackClickCompletePurchase,
  trackClickShoppingCartGiftRecipient,
  trackCloseCart,
} from "@/features/shopping-cart/utils/analytics"
import {
  useShoppingCartOrderToQuantity,
  useShoppingCartAddOrRemoveOrders,
  useShoppingCartTotalAssetIdsInCart,
  useShoppingCartViewActions,
  useShoppingCartViewIsLoading,
  useShoppingCartViewIsOpen,
} from "@/features/shopping-cart/utils/ShoppingCartContextProvider"
import { useTranslate } from "@/hooks/useTranslate"
import { ShoppingCartDetailedView_errors$key } from "@/lib/graphql/__generated__/ShoppingCartDetailedView_errors.graphql"
import { ShoppingCartDetailedView_orders$key } from "@/lib/graphql/__generated__/ShoppingCartDetailedView_orders.graphql"
import { OrderList } from "../OrderList/OrderList.react"
import { ShoppingCartPaymentMethod } from "../ShoppingCartPaymentMethod"

type Props = {
  orders: ShoppingCartDetailedView_orders$key | null
  errors: ShoppingCartDetailedView_errors$key | null
  giftRecipient: CheckoutGiftRecipient
  setGiftRecipient: (giftRecipient: CheckoutGiftRecipient) => unknown
  paymentMethod: PaymentOption
  setPaymentMethod: NonNullable<PaymentMethodProps["onChange"]>
}

export const ShoppingCartDetailedView = ({
  orders: ordersDataKey,
  errors: errorsDataKey,
  giftRecipient,
  paymentMethod,
  setGiftRecipient,
  setPaymentMethod,
}: Props) => {
  const totalAssetIdsInCart = useShoppingCartTotalAssetIdsInCart()
  const orderToQuantity = useShoppingCartOrderToQuantity()
  const isLoading = useShoppingCartViewIsLoading()
  const isOpen = useShoppingCartViewIsOpen()
  const { close } = useShoppingCartViewActions()
  const { clear: clearCart, refresh: refreshCart } =
    useShoppingCartAddOrRemoveOrders()
  const t = useTranslate("bulkPurchase")

  const orders = useFragment(
    graphql`
      fragment ShoppingCartDetailedView_orders on OrderV2Type
      @relay(plural: true) {
        relayId
        item {
          chain {
            identifier
          }
        }
        supportsGiftingOnPurchase
        ...useTotalPrice_orders
        ...OrderList_orders
      }
    `,
    ordersDataKey,
  )

  const errors = useFragment(
    graphql`
      fragment ShoppingCartDetailedView_errors on BulkPurchaseErrorType
      @relay(plural: true) {
        ...useTotalPrice_errors
        ...OrderList_errors
      }
    `,
    errorsDataKey,
  )
  const { totalPricePerSymbol, totalUsdPrice } = useTotalPrice({
    orders,
    errors,
    orderToQuantity,
  })

  const numItems = totalAssetIdsInCart.length
  const hasItems = Boolean(numItems)
  const hasErrors = Boolean(errors?.length)

  const closeCart = useCallback(async () => {
    trackCloseCart()
    close()
  }, [close])

  const chain = orders?.[0]?.item.chain.identifier

  const showGiftRecipientInput =
    hasItems &&
    orders?.every(o => o.supportsGiftingOnPurchase) &&
    paymentMethod !== "card"

  const cartTooltip = (
    <InfoIcon
      overrides={{
        Button: { style: { verticalAlign: "middle" } },
        Icon: { size: 20 },
        Tooltip: {
          interactive: true,
          maxWidth: 200,
        },
      }}
      tooltipContent={t(
        "shoppingCart.tooltip",
        "Items in your cart are not guaranteed at purchase. {{learnMoreLink}}",
        {
          learnMoreLink: (
            <Link href="https://support.opensea.io/articles/8866991">
              {t("shoppingCart.learnMoreLink", "Learn more")}
            </Link>
          ),
        },
      )}
    />
  )

  const yourItems = Boolean(orders?.length) && (
    <Text.Body weight="semibold">
      {t(
        "itemCount",
        {
          "0": "{{count}} items",
          one: "{{count}} item",
          other: "{{count}} items",
        },
        { count: numItems },
      )}
    </Text.Body>
  )

  const clearCartButton = Boolean(orders?.length) && (
    <UnstyledButton onClick={clearCart}>
      <InteractiveTextBody size="small" weight="semibold">
        {t("shoppingCart.clear", "Clear all")}
      </InteractiveTextBody>
    </UnstyledButton>
  )

  const totalPriceContent = hasItems && (
    <>
      <Separator className="-ml-6 mb-3 w-[calc(100%+48px)]" />
      <SpaceBetween data-testid="shopping-cart-total-price">
        <Item className="border-0 px-0 pt-3">
          <Item.Content>
            <Item.Title className="block h-full">
              {t("totalPrice", "Total price")}
            </Item.Title>
          </Item.Content>

          <Item.Side className="max-w-full">
            <Item.Title>
              <ItemSideOverflowStacked>
                {Object.values(totalPricePerSymbol).map(
                  price =>
                    price && (
                      <Text.Body
                        key={price.tokenPricePayment.symbol}
                        size="medium"
                        weight="semibold"
                      >
                        <TokenPrice
                          {...price.tokenPricePayment}
                          fontWeight={600}
                          key={price.tokenPricePayment.symbol}
                          price={price.price}
                          symbolVariant="raw"
                        />
                      </Text.Body>
                    ),
                )}
              </ItemSideOverflowStacked>
            </Item.Title>
            <Item.Description>
              <ItemSideOverflow>{totalUsdPrice}</ItemSideOverflow>
            </Item.Description>
          </Item.Side>
        </Item>
      </SpaceBetween>
    </>
  )

  const paymentMethodInput = orders?.length === 1 && !giftRecipient.isValid && (
    <ShoppingCartPaymentMethod
      listingId={orders[0].relayId}
      paddingY="12px"
      paymentMethod={paymentMethod}
      setPaymentMethod={setPaymentMethod}
    />
  )

  const giftRecipientInput = showGiftRecipientInput && (
    <CheckoutGiftRecipientInput
      chain={chain}
      defaultValues={{
        address: giftRecipient.address,
        destination: giftRecipient.destination,
      }}
      overrides={{ Text: { weight: "regular" } }}
      paddingY="12px"
      withIcon={false}
      onChange={setGiftRecipient}
      onExpand={trackClickShoppingCartGiftRecipient}
    />
  )

  const renderCta = (size: ButtonSize) =>
    hasErrors ? (
      <Button
        className="mt-4 w-full"
        icon="refresh"
        size={size}
        variant="secondary"
        onClick={refreshCart}
      >
        {t("shoppingCart.refresh", "Refresh")}
      </Button>
    ) : (
      <BulkPurchaseModal.Trigger
        className="mt-4 w-full"
        disabled={!giftRecipient.isEmpty && !giftRecipient.isValid}
        size={size}
        onClick={() => {
          if (orders && chain) {
            trackClickCompletePurchase({
              numberOfItems: orders.length,
              chain,
            })
          }
        }}
      />
    )

  return (
    <>
      <Media lessThan="lg">
        <BottomSheet
          data-testid="shopping-cart"
          isOpen={isOpen}
          onClose={closeCart}
        >
          <BottomSheet.Header>
            <BottomSheet.Title>
              {t("shoppingCart.title", "Your cart")}

              <Block as="span" marginLeft="8px" verticalAlign="top">
                {cartTooltip}
              </Block>
            </BottomSheet.Title>
          </BottomSheet.Header>
          <BottomSheet.Body>
            <SpaceBetween className="mb-3">
              {yourItems}

              {clearCartButton}
            </SpaceBetween>

            <OrderList errors={errors} isLoading={isLoading} orders={orders} />
          </BottomSheet.Body>
          <BottomSheet.Footer>
            <FlexColumn className="w-full">
              {totalPriceContent}
              {paymentMethodInput}
              {giftRecipientInput}
              {renderCta("large")}
            </FlexColumn>
          </BottomSheet.Footer>
        </BottomSheet>
      </Media>
      <Media greaterThanOrEqual="lg">
        <FlexColumn
          className="max-h-full w-full flex-nowrap overflow-auto overflow-x-hidden bg-elevation-2 p-6"
          data-testid="shopping-cart"
        >
          <Block as="header" marginBottom="12px">
            <SpaceBetween className="mb-6">
              <Flex alignItems="center">
                <Text.Heading asChild className="mr-2" size="small">
                  <h4>{t("shoppingCart.title", "Your cart")}</h4>
                </Text.Heading>

                {cartTooltip}
              </Flex>

              <UnstyledButton onClick={closeCart}>
                <InteractiveIcon size={24} title="Close" value="close" />
              </UnstyledButton>
            </SpaceBetween>

            <Separator className="-ml-6 mb-3 w-[calc(100%+48px)]" />

            <SpaceBetween className="mt-6">
              {yourItems}

              {clearCartButton}
            </SpaceBetween>
          </Block>

          <OrderList errors={errors} isLoading={isLoading} orders={orders} />

          <Block marginBottom="16px" />

          {totalPriceContent}
          {paymentMethodInput}
          {giftRecipientInput}
          {renderCta("large")}
        </FlexColumn>
      </Media>
    </>
  )
}

const InteractiveIcon = styled(Icon)`
  color: ${props => props.theme.colors.text.primary};

  ${interactiveStylesPrimary};
`

const InteractiveTextBody = styled(Text.Body)`
  ${interactiveStylesPrimary};
`
