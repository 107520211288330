import { WALLET_NAME } from "@/constants/wallet"
import { getTrackingFn } from "@/lib/analytics"

type ProviderSwitchParams = {
  fromMetadata?: string
  from?: WALLET_NAME
  to: WALLET_NAME
  toMetadata: string | undefined
}

export const trackClickWalletSwitchOption = getTrackingFn<ProviderSwitchParams>(
  "click wallet switch option",
)

export const trackWalletSwitched =
  getTrackingFn<ProviderSwitchParams>("wallet switched")
