import React, { useMemo } from "react"
import { useShoppingCartTotalAssetIdsInCart } from "@/features/shopping-cart/utils/ShoppingCartContextProvider"
import { TrackingContextProvider } from "../TrackingContext.react"

type ShoppingCartContext = {
  itemCount: number
}

export type ShoppingCartTrackingContext = {
  ShoppingCart: ShoppingCartContext
}

type ShoppingCartTrackingContextProviderProps = {
  children: React.ReactNode
}

export const ShoppingCartTrackingContextProvider = ({
  children,
}: ShoppingCartTrackingContextProviderProps) => {
  const totalAssetIdsInCart = useShoppingCartTotalAssetIdsInCart()

  const itemIdsInCart = new Set(totalAssetIdsInCart)
  const itemCount = itemIdsInCart.size

  const properties: ShoppingCartContext = useMemo(
    () => ({
      itemCount,
    }),
    [itemCount],
  )

  return (
    <TrackingContextProvider name="ShoppingCart" properties={properties}>
      {children}
    </TrackingContextProvider>
  )
}
