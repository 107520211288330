import React, { ComponentProps } from "react"
import { FallbackRender } from "@sentry/nextjs"
import { AssetItemError } from "@/features/sell/components/AssetItemError.react"
import { getFirstGraphqlResponseErrorStatus } from "@/lib/graphql/error"
import { captureNoncriticalError } from "@/lib/sentry"
import { useTrackServerError, useTrackServerAttempt } from "./hooks"

export const CoreMarketplaceActionErrorFallback = ({
  error,
  resetError,
  trackServerError,
  errorTags,
}: ComponentProps<FallbackRender> & {
  trackServerError: ReturnType<typeof useTrackServerError>[0]
  errorTags: ReturnType<typeof useTrackServerError>[1]
}) => {
  const status = getFirstGraphqlResponseErrorStatus(error)
  const trackServerAttempt = useTrackServerAttempt()

  if (status) {
    trackServerAttempt()
    trackServerError({ errorCode: status })
  }

  captureNoncriticalError(error, { tags: errorTags })

  // Note: this is a generic error component
  return <AssetItemError resetError={resetError} />
}
