/* eslint-disable tailwindcss/no-custom-classname */
import React, { ComponentProps } from "react"
import Head from "next/head"
import styled from "styled-components"
import { Image } from "@/design-system/Image"
import { useRouter } from "@/hooks/useRouter"
import {
  resizeImage,
  getImgixPath,
  getNextImageFixedSizes,
  shouldUseSeadnUrl,
} from "@/lib/helpers/urls"

export type ItemImageProps = {
  objectFit?: "contain" | "cover" | "none"
  priority?: boolean
  alt?: string
  size?: number
  height?: number
  width?: number
  sizes?: ComponentProps<typeof Image>["sizes"]
  onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void
  backgroundColor?: string | undefined
  url: string
  cardDisplayStyle?: "COVER" | "CONTAIN" | "PADDED" | undefined
  optimizeImageSizing?: boolean
  rawImage?: boolean // Raw will be used for layout="raw" on next 12. It's for cases where we dont know the rendered image height.
  assetImageUrl?: string | null
  mediaStyles?: React.CSSProperties
}

export const ItemImage = React.forwardRef<HTMLImageElement, ItemImageProps>(
  function ItemImage(
    {
      objectFit,
      priority,
      alt,
      size,
      height,
      width,
      sizes,
      onError,
      backgroundColor,
      url,
      cardDisplayStyle,
      optimizeImageSizing,
      rawImage,
      assetImageUrl,
      mediaStyles,
    },
    ref,
  ) {
    const { pathname } = useRouter()
    const isUsingSize = size != null || width != null
    const resizedUrl = url && resizeImage(url, { size, width, pathname })

    const shouldOptimizeSeadnUrl = shouldUseSeadnUrl(url)
    const sizing =
      cardDisplayStyle === "COVER"
        ? "cover"
        : cardDisplayStyle === "CONTAIN"
        ? "contain"
        : undefined

    const imgixPath = getImgixPath(url)

    const optimizeImage = imgixPath != null && optimizeImageSizing

    if (rawImage) {
      return (
        <>
          {priority && assetImageUrl && (
            <Head>
              <link as="image" href={assetImageUrl} rel="preload" />
            </Head>
          )}
          <ImageContainer
            className="AssetMedia--img"
            style={{
              backgroundColor,
              height: height || size || "100%",
              width: width || size || "100%",
            }}
          >
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              alt={alt}
              className="Image--image"
              ref={ref}
              src={resizedUrl}
              style={{
                objectFit: isUsingSize ? sizing || "contain" : "contain",
                width: sizing === "cover" && isUsingSize ? "100%" : "auto",
                height: sizing === "cover" && isUsingSize ? "100%" : "auto",
                maxWidth: "100%",
                maxHeight: "100%",
                ...mediaStyles,
              }}
            />
          </ImageContainer>
        </>
      )
    }

    return (
      <ImageContainer
        className="AssetMedia--img"
        style={{
          backgroundColor,
          height: size || "100%",
          width: width || size || "100%",
        }}
      >
        {shouldOptimizeSeadnUrl ? (
          <Image.Next13
            alt={alt ?? ""}
            fill
            priority={priority}
            resizeImageProps={{ size, width, pathname }}
            sizes={
              sizes ??
              (width || size
                ? getNextImageFixedSizes(width, size)
                : "(max-width: 600px) 200px, 380px")
            }
            src={url}
            style={{ objectFit: objectFit || sizing || "contain" }}
            onError={onError}
          />
        ) : (
          <Image.Next13
            alt={alt ?? ""}
            fill
            priority={priority}
            resizeImageProps={{ size, width, pathname }}
            sizes={
              sizes ??
              (optimizeImage ? getNextImageFixedSizes(width, size) : undefined)
            }
            src={optimizeImage && imgixPath ? imgixPath : resizedUrl}
            style={{ objectFit: objectFit || sizing || "contain" }}
            unoptimized={!optimizeImage}
          />
        )}
      </ImageContainer>
    )
  },
)

export const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: inherit;
  > img {
    border-radius: inherit;
  }
`
