import React from "react"
import { Flex, Text, Skeleton } from "@opensea/ui-kit"
import styled from "styled-components"
import { Block } from "@/design-system/Block"
import { Image } from "@/design-system/Image"
import { PreviewList } from "./PreviewList.react"

type CardTooltipContentProps = {
  imageUrl?: string | null
  imageAltText?: string
  mainContent: React.ReactNode
  middleContent: React.ReactNode
  lowerContent: React.ReactNode
  isRoundLogo?: boolean
  width?: number
}

const CardTooltipContent_ = ({
  imageUrl,
  imageAltText,
  mainContent,
  lowerContent,
  middleContent,
  isRoundLogo,
  width,
}: CardTooltipContentProps) => {
  return (
    <Container width={width}>
      <Flex className="mb-4 items-center">
        <LogoContainer isRoundLogo={isRoundLogo}>
          {imageUrl && (
            <Logo
              alt={imageAltText}
              height={48}
              objectFit="cover"
              src={imageUrl}
              width={48}
            />
          )}
        </LogoContainer>
        <Flex className="flex-col items-start">{mainContent}</Flex>
      </Flex>
      <Block marginBottom={lowerContent ? 16 : undefined}>
        {middleContent}
      </Block>
      {lowerContent}
    </Container>
  )
}

type StatProps = {
  title: string
  subtitle: string
  symbol?: string
}

const Stat = ({ title, subtitle, symbol }: StatProps) => {
  return (
    <Flex className="flex-col items-start">
      <Text asChild className="text-start" size="small" weight="semibold">
        {symbol ? (
          <Flex className="items-center whitespace-nowrap">
            {title} {symbol}
          </Flex>
        ) : (
          <p>{title}</p>
        )}
      </Text>
      <Text
        asChild
        className="whitespace-nowrap text-start text-secondary"
        size="small"
      >
        <p>{subtitle}</p>
      </Text>
    </Flex>
  )
}

const Container = styled(Block)`
  border-radius: ${props => props.theme.borderRadius.default};
  overflow: hidden;
  padding: 16px;
  background: ${props => props.theme.colors.components.elevation.level2};
`

const Logo = styled(Image)`
  background: ${props => props.theme.colors.components.elevation.level2};
`

const LogoContainer = styled(Flex)<{ isRoundLogo?: boolean }>`
  justify-content: center;
  position: relative;
  background-color: ${props => props.theme.colors.fog};
  overflow: hidden;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  margin-right: 16px;

  &,
  &:before {
    border-radius: ${props => (props.isRoundLogo ? "100%" : "10px")};
  }

  &:before {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 1px solid ${props => props.theme.colors.components.border.level1};
    pointer-events: none;
    z-index: 100;
  }
`

type SkeletonProps = {
  isRoundLogo?: boolean
}

const Skeleton_ = ({ isRoundLogo }: SkeletonProps) => (
  <Container>
    <Skeleton className="h-full">
      <Flex className="h-full flex-col justify-between">
        <Flex className="items-center">
          <LogoContainer isRoundLogo={isRoundLogo}>
            <Skeleton.Block className="h-full w-full" />
          </LogoContainer>
          <Flex className="flex-col items-start">
            <Skeleton.Line className="mb-2 h-[18px] w-[200px]" />
            <Skeleton.Line className="h-[14px] w-[120px]" />
          </Flex>
        </Flex>
        <Flex className="w-full justify-between p-4">
          {new Array(5).fill(null).map((_, i) => (
            <Flex className="w-[15%] flex-wrap" key={`${i}stat`}>
              <Skeleton.Row className="w-full">
                <Skeleton.Line className="h-[14px] w-full" />
              </Skeleton.Row>
              <Skeleton.Row className="h-2 w-full" />
              <Skeleton.Row className="w-full">
                <Skeleton.Line className="h-[14px] w-full" />
              </Skeleton.Row>
            </Flex>
          ))}
        </Flex>
        <PreviewList.Skeleton count={3} imageSize={100} />
      </Flex>
    </Skeleton>
  </Container>
)

type ErrorProps = {
  children: React.ReactNode
}

const Error = ({ children }: ErrorProps) => {
  return (
    <Flex className="p-4">
      <Text weight="semibold">{children}</Text>
    </Flex>
  )
}

const StatsWrapper = styled(Flex)`
  grid-column-gap: 24px;
`

export const CardTooltipContent = Object.assign(CardTooltipContent_, {
  Error,
  Stat,
  StatsWrapper,
  Skeleton: Skeleton_,
})
