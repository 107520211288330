import { useMemo } from "react"
import { getWalletConfiguration } from "@/constants/wallet"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { SelectOption } from "@/design-system/Select"
import { useChains } from "@/hooks/useChains"
import { getChain } from "@/hooks/useChains/context"
import { ChainIdentifier } from "@/hooks/useChains/types"

export const useChainOptions = (): {
  chainOptions: SelectOption<ChainIdentifier>[]
  activeChainOption: SelectOption<ChainIdentifier> | undefined
} => {
  const { provider: activeProvider, chain: activeChain } = useWallet()
  const { chains, getChainName } = useChains()

  const chainOptions = useMemo(() => {
    const supportedChains = chains.filter(chain => {
      // No active wallet
      if (!activeProvider) {
        return false
      }

      // Wallet does not support chain
      const walletName = activeProvider.getName()
      const chainData = getChain(chain)
      if (!getWalletConfiguration(walletName).supportsChain(chainData)) {
        return false
      }

      return true
    })

    const supportedChainOptions = supportedChains.map(chain => ({
      value: chain,
      label: getChainName(chain),
    }))

    const activeChainOption = supportedChainOptions.find(
      chainOption => chainOption.value === activeChain,
    )

    if (!activeChainOption) {
      throw new Error("Failed to find chain option matching provided chain")
    }

    return [
      activeChainOption, // active chain options is always first
      ...supportedChainOptions.filter(
        chainOption => chainOption !== activeChainOption,
      ),
    ]
  }, [activeChain, activeProvider, chains, getChainName])

  const activeChainOption = useMemo(() => {
    return chainOptions.find(chainOption => chainOption.value === activeChain)
  }, [activeChain, chainOptions])

  return { chainOptions, activeChainOption }
}
