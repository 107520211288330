/**
 * @generated SignedSource<<b9e118ecb8ac451115bd4c6b07f431db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useFulfillingListingsWillReactivateOrders_orders$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"useTotalItems_orders">;
  readonly " $fragmentType": "useFulfillingListingsWillReactivateOrders_orders";
}>;
export type useFulfillingListingsWillReactivateOrders_orders$key = ReadonlyArray<{
  readonly " $data"?: useFulfillingListingsWillReactivateOrders_orders$data;
  readonly " $fragmentSpreads": FragmentRefs<"useFulfillingListingsWillReactivateOrders_orders">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "useFulfillingListingsWillReactivateOrders_orders",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useTotalItems_orders"
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};

(node as any).hash = "5fb972f35720aa3f2abd72c9cdac9e62";

export default node;
