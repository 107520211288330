import { chunk, first, partition } from "lodash"
import type { NextPageContext } from "next"
import { Buffer } from "safe-buffer"
import { IS_TESTNET } from "@/constants/testnet"
import { WALLET_NAME } from "@/constants/wallet"
import { initWallet } from "@/containers/WalletProvider/wallet"
import { getChain } from "@/hooks/useChains/context"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { Chain } from "@/hooks/useChains/types"
import {
  getCounterpartNetworkChain,
  isChainOnMatchingNetwork,
} from "@/hooks/useChains/utils"
import { trackUser } from "@/lib/analytics/trackUser"
import Auth, { LoggedInAccount } from "@/lib/auth"
import {
  getAuthenticatedAccount,
  setAuthenticatedAccountCookie,
} from "@/lib/auth/account"
import { AuthenticatedAccount, IdentityKey } from "@/lib/auth/types"
import chain, { Address, WalletAccountKey } from "@/lib/chain/chain"
import Web3EvmProvider from "@/lib/chain/providers/web3EvmProvider"
import { IdentityInputType } from "@/lib/graphql/__generated__/DefaultAccountPageQuery.graphql"
import { ClientSignatureStandard } from "@/lib/graphql/__generated__/useHandleBlockchainActions_cancel_orders.graphql"
import { wallet_accountKey$data } from "@/lib/graphql/__generated__/wallet_accountKey.graphql"
import { walletBalanceBySymbolQuery } from "@/lib/graphql/__generated__/walletBalanceBySymbolQuery.graphql"
import { walletBalanceQuery } from "@/lib/graphql/__generated__/walletBalanceQuery.graphql"
import { walletCheckQuery } from "@/lib/graphql/__generated__/walletCheckQuery.graphql"
import { walletMultiQuery } from "@/lib/graphql/__generated__/walletMultiQuery.graphql"
import {
  walletQuery,
  walletQuery$data,
} from "@/lib/graphql/__generated__/walletQuery.graphql"
import { fetch, graphql } from "@/lib/graphql/graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { addressesEqual } from "@/lib/helpers/address"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"
import Publisher from "@/lib/helpers/publisher"
import Router from "@/lib/helpers/router"
import { UnreachableCaseError } from "@/lib/helpers/type"
import { getIsAuthStateSimplificationEnabled } from "../auth/flags"
import Cookie from "../cookie"
import { clearCache } from "../graphql/environment/middlewares/cacheMiddleware"
import { stringifyQueryParams } from "../helpers/urls"
import { captureNoncriticalError } from "../sentry"
import Provider, {
  Transaction,
  TransactionOptions,
  TransactionResponse,
} from "./provider"
import {
  clearConnectedAccounts,
  getConnectedAccounts,
  saveConnectedAccounts,
} from "./storage"

const COOKIE_KEY = "os-wallet"
const TESTNET_COOKIE_KEY = "os-wallet-testnet"

const MAX_ACCOUNTS_TO_LOAD_PER_REQUEST = 32

export type Account = walletQuery$data["getAccount"]

export type WalletAccount = Account &
  AuthenticatedAccount & {
    walletName: WALLET_NAME
  }

export type ActiveAccount = WalletAccount | undefined

type WalletData = {
  activeAccount?: ActiveAccount
}

export type SignOptions = {
  clientSignatureStandard: ClientSignatureStandard
}

type ActiveWallet = WALLET_NAME | undefined

export type WalletState = {
  data: WalletData | undefined
  name: WALLET_NAME | undefined
}

// Stores currently active wallet, so we can deterministically select it on reload
const activeWalletCookie = new Cookie<WALLET_NAME | undefined>("active_wallet")

// Stores list of previously seen accounts & currently active account
const walletDataCookie = new Cookie<WalletData>(
  IS_TESTNET ? TESTNET_COOKIE_KEY : COOKIE_KEY,
)

export default class Wallet {
  static wallet: Wallet | undefined

  static toAccountKey = ({ address }: { address: string }): IdentityKey => ({
    address,
  })

  public static readAccountKey = inlineFragmentize<
    wallet_accountKey$data,
    IdentityKey
  >(
    graphql`
      fragment wallet_accountKey on AccountType @inline {
        address
      }
    `,
    Wallet.toAccountKey,
  )

  private static fetchAccount = async (
    address: string,
  ): Promise<Account | undefined> => {
    const [{ getAccount }] = await fetch<walletQuery>(
      graphql`
        query walletQuery($identity: AddressScalar!) {
          getAccount(address: $identity) {
            address
            imageUrl
            nickname
            relayId
            isCompromised
            user {
              relayId
              username
              publicUsername
              hasAffirmativelyAcceptedOpenseaTerms
              email
            }
            metadata {
              isBanned
              isDisabled
            }
          }
        }
      `,
      { identity: address },
    )
    return getAccount
  }

  static getAccounts = async (
    accountKeys: WalletAccountKey[],
  ): Promise<readonly WalletAccount[]> => {
    if (accountKeys.length === 0) {
      return []
    }

    const [{ getAccountsByAddresses }] = await fetch<walletMultiQuery>(
      graphql`
        query walletMultiQuery($addresses: [AddressScalar!]!) {
          getAccountsByAddresses(addresses: $addresses) {
            address
            imageUrl
            nickname
            relayId
            isCompromised
            user {
              relayId
              username
              publicUsername
              hasAffirmativelyAcceptedOpenseaTerms
              email
            }
            metadata {
              isBanned
              isDisabled
            }
          }
        }
      `,
      { addresses: accountKeys.map(({ address }) => address) },
    )

    const walletAccounts = getAccountsByAddresses
      .map((acc, i) => ({
        ...acc,
        ...(accountKeys[i]?.address
          ? getAuthenticatedAccount(accountKeys[i].address)
          : {}),
        walletName: accountKeys[i]?.walletName,
      }))
      .filter(({ walletName }) => Boolean(walletName)) as WalletAccount[]

    return walletAccounts
  }

  public static stateFromContext = (context?: NextPageContext): WalletState => {
    return {
      data: walletDataCookie.get(context),
      name: activeWalletCookie.get(context),
    }
  }

  public static fromContext = (context: NextPageContext) => {
    if (typeof window === "undefined") {
      return new Wallet(Wallet.stateFromContext(context))
    }
    return initWallet(Wallet.stateFromContext(context))
  }

  private _accounts: Record<Address, WalletAccount> = {}

  // Stores currently active wallet, so we can deterministically select it on reload
  private activeWallet: ActiveWallet

  private publisher: Publisher = new Publisher()

  // Prior to getIsAuthStateSimplificationEnabled() being enabled, this represents a connected account (possibly authenticated),
  // after getIsAuthStateSimplificationEnabled() is enabled, this represents an authenticated account and will return undefined if the account is not authenticated
  activeAccount: ActiveAccount

  get address(): string | undefined {
    return this.activeAccount?.address
  }

  constructor(state: WalletState | undefined) {
    this.setState(state)
    this.setAccountsFromStorage()
  }

  private setAccountsFromStorage = (): void => {
    const accounts = getConnectedAccounts()
    this._accounts = this.toInternalRepresentation(accounts)
  }

  setState = (state: WalletState | undefined) => {
    this.activeAccount = state?.data?.activeAccount
    this.activeWallet = state?.name
  }

  onChange = (onChange: (wallet: Wallet) => unknown): (() => void) =>
    this.publisher.subscribe(() => onChange(this))

  getActiveAccountKey = (): IdentityKey | undefined => {
    return this.activeAccount && { address: this.activeAccount.address }
  }

  getActiveAccountKeyStrict = (): IdentityKey => {
    if (!this.activeAccount) {
      throw new UnreachableCaseError(this.activeAccount as never)
    }
    return { address: this.activeAccount.address }
  }

  getActiveAddressStrict = () => {
    return this.getActiveAccountKeyStrict().address
  }

  getActiveAccountUsername = (): string | null | undefined => {
    return this.activeAccount?.user?.publicUsername
  }

  isCurrentIdentity = (identity: IdentityInputType) => {
    if (!this.activeAccount) {
      return false
    }

    if (
      identity.address &&
      addressesEqual(identity.address, this.activeAccount.address)
    ) {
      return true
    }

    if (
      identity.username &&
      identity.username === this.activeAccount.user?.username
    ) {
      return true
    }

    return false
  }

  getActiveWallet = (): ActiveWallet => {
    return this.activeWallet
  }

  hasRegisteredUser = (): boolean => {
    return !!this.activeAccount?.user?.publicUsername
  }

  persistWalletState = (publish = true) => {
    if (this.activeWallet) {
      activeWalletCookie.set(this.activeWallet, {
        secure: true,
        sameSite: "Lax",
      })
    } else {
      activeWalletCookie.remove()
    }

    if (this.activeAccount) {
      walletDataCookie.set(
        {
          activeAccount: this.activeAccount,
        },
        {
          secure: true,
          sameSite: "Lax",
        },
      )
    } else {
      walletDataCookie.remove()
    }

    if (Object.keys(this._accounts).length > 0) {
      saveConnectedAccounts(Object.values(this._accounts))
    } else {
      clearConnectedAccounts()
    }

    Auth.getValidSession(this.activeAccount?.address)
    if (publish) {
      this.publisher.publish()
    }
  }

  private toInternalRepresentation = (data: ReadonlyArray<WalletAccount>) => {
    return data.reduce(
      (accounts, account) => ({ ...accounts, [account.address]: account }),
      {} as Record<Address, WalletAccount>,
    )
  }

  private addAccount = async (
    accountKey: WalletAccountKey,
  ): Promise<ActiveAccount> => {
    const { walletName, address } = accountKey
    const existingAccount = this._accounts[address]

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (existingAccount) {
      return existingAccount
    }

    const account = await Wallet.fetchAccount(address)
    if (!account) {
      return undefined
    }

    const walletAccount: WalletAccount = {
      ...account,
      ...getAuthenticatedAccount(account.address),
      walletName,
    }
    this._accounts[walletAccount.address] = walletAccount
    return walletAccount
  }

  private loadAccounts = async (
    accountsKeys: ReadonlyArray<WalletAccountKey>,
  ): Promise<ReadonlyArray<WalletAccount>> => {
    const [cachedAccountKeys, accountsToLoad] = partition(
      accountsKeys,
      (accKey: WalletAccountKey) => this._accounts[accKey.address],
    )
    const cachedAccounts = cachedAccountKeys.map(
      ({ address }) => this._accounts[address],
    )
    const chunkedLoadedAccounts = await Promise.all(
      chunk(accountsToLoad, MAX_ACCOUNTS_TO_LOAD_PER_REQUEST).map(
        chunkedAccountsToLoad => Wallet.getAccounts(chunkedAccountsToLoad),
      ),
    )
    const loadedAccounts = chunkedLoadedAccounts.flat()

    loadedAccounts.forEach(acc => {
      this._accounts[acc.address] = acc
    })

    return cachedAccounts.concat(loadedAccounts)
  }

  /**
   * Install a new wallet.
   *
   * 1. Try to add/init the provider
   * 2. Switch to that wallet provider, if present
   *
   * @param walletName
   * @returns active account
   */
  public install = async (
    walletName: WALLET_NAME,
  ): Promise<Account | undefined> => {
    const provider = await chain.addProvider(walletName)
    if (!provider) {
      return
    }
    return this.connect(provider)
  }

  /**
   * Switches to a specific wallet.
   *
   * 1. Fetches account keys from the provider
   * 2. If wallet is locked (if no accounts are matching), trigger a connection
   * 3. Handle the switch given a list of new account keys
   *
   * @param provider that we're switching to
   * @returns active account
   */
  public switch = async (provider: Provider): Promise<Account | undefined> => {
    const walletName = provider.getName()
    const accountKeys = await provider.getAccounts()
    if (accountKeys.length === 0) {
      return this.connect(provider)
    }
    return this.handleSwitch(walletName, accountKeys)
  }

  private connect = async (provider: Provider) => {
    const walletName = provider.getName()
    const accountKeys = await provider.connect()
    Auth.setLogoutCookie(false)
    return this.handleSwitch(walletName, accountKeys)
  }

  public handleAccountsChange = async (
    provider: Provider,
    accountKeys: WalletAccountKey[],
  ): Promise<ActiveAccount> => {
    const walletName = provider.getName()
    const selectedAccount = first(accountKeys)
    if (!selectedAccount) {
      if (provider.hasNoLockingConcept()) {
        return this.connect(provider)
      } else {
        return this.lock(walletName)
      }
    } else {
      return this.handleAccountChanged(selectedAccount)
    }
  }

  private loginAccount = async (
    account: WalletAccount,
  ): Promise<LoggedInAccount | undefined> => {
    const loginInput = {
      activeAccount: account,
      ensureLoginCompatibleNetwork: this.ensureLoginCompatibleNetwork,
      getProviderOrRedirect: () =>
        this.getProviderOrRedirect(undefined, undefined, account),
      setAuthenticatedAccount: this.setAuthenticatedAccount,
      sign: async (
        message: string | Buffer,
        options?: SignOptions,
      ): Promise<string> => {
        const provider = await this.getProviderOrRedirect(
          undefined,
          undefined,
          account,
        )
        if (!provider) {
          throw new Error(
            "Could not find wallet provider matching current account.",
          )
        }
        return provider.sign(message, account.address, options)
      },
    }

    const loggedInAccount = await Auth.login(loginInput)

    return loggedInAccount
  }

  /**
   * Lock a wallet.
   *
   * 1. If this is the only provider connected, user will be essentially logged out.
   * 2. If there are more providers, pick a random account from the remaining providers as the active one.
   * 3. Save the new provider selection
   * 4. Persist wallet state & publish updates
   *
   * @param walletName
   * @returns activeAccount
   */
  lock = (walletName: WALLET_NAME): ActiveAccount => {
    Object.values(this._accounts).forEach(account => {
      if (account.walletName === walletName) {
        delete this._accounts[account.address]
      }
    })

    if (this.activeAccount?.walletName === walletName) {
      this.activeAccount = first(Object.values(this._accounts))
      if (this.activeAccount) {
        this.checkActiveAccount()
      }
    }

    this.activeWallet = this.activeAccount?.walletName
    this.persistWalletState()
    return this.activeAccount
  }

  /**
   * Initialize wallet with accounts. This can include accounts from multiple providers, and should be called
   * at application load time & after all providers are initialized.
   *
   * 1. If no accounts are found, clear out the wallet as that most likely means that all providers are locked.
   * 2. Try to find a account that comes from a provider that was used in the previous session and set it as active.
   * 3. If no such account is found, pick a random account as active.
   * 4. Persist wallet state & publish updates
   *
   * @param keys provider account keys. Those should already be filtered for compatible network
   * @returns active account
   */
  public initialize = async (
    keys: ReadonlyArray<WalletAccountKey>,
  ): Promise<Account | undefined> => {
    const loggedOutCookie = new Cookie<boolean | undefined>(
      "opensea_logged_out",
    )
    if (loggedOutCookie.get()) {
      this.clear()
      return
    }
    if (keys.length === 0 && this.activeAccount) {
      this.clear()
      return
    }

    const accounts = await this.loadAccounts(keys)
    if (accounts.length === 0 && this.activeAccount) {
      this.clear()
      return
    }

    const activeAccount = this.activeWallet
      ? accounts.find(acc => acc.walletName === this.activeWallet)
      : first(accounts)

    // We need to verify account is authenticated before setting as active if auth state simplification is enabled
    const authenticatedActiveAccount = getIsAuthStateSimplificationEnabled()
      ? Auth.getIsAuthenticated(activeAccount?.address)
        ? activeAccount
        : undefined
      : activeAccount

    this._accounts = this.toInternalRepresentation(accounts)
    this.activeAccount = authenticatedActiveAccount
    this.activeWallet = this.activeAccount?.walletName
    if (this.activeAccount) {
      this.trackUserWithAccount(this.activeAccount)
    }
    this.checkActiveAccount()
    this.persistWalletState()
    return this.activeAccount
  }

  private async setActiveAccount(account: WalletAccount): Promise<void> {
    let activeAccount = undefined
    if (getIsAuthStateSimplificationEnabled()) {
      const loggedInAccount = await this.loginAccount(account)
      if (!loggedInAccount) {
        return
      }
      activeAccount = { ...account, ...loggedInAccount }
    }
    this.trackUserWithAccount(account)
    this.activeAccount = activeAccount || account
    this.checkActiveAccount()
  }

  /**
   * Handle account being changed in wallet.
   *
   * 1. Load account
   * 2. If account is associated from the currently active wallet, set is as the active account
   * 3. Persist wallet state & publish the updated
   *
   * @param accountKey
   * @returns active account
   */
  private handleAccountChanged = async (
    accountKey: WalletAccountKey,
  ): Promise<ActiveAccount> => {
    const account = await this.addAccount(accountKey)
    if (account && account.walletName === this.activeWallet) {
      await this.setActiveAccount(account)
    }
    this.persistWalletState()
    return this.activeAccount
  }

  private handleSwitch = async (
    walletName: WALLET_NAME,
    accountKeys: WalletAccountKey[],
  ): Promise<ActiveAccount> => {
    const accounts = await this.loadAccounts(accountKeys)
    this.activeWallet = walletName
    const account = first(accounts)
    if (account) {
      await this.setActiveAccount(account)
    }
    this.persistWalletState()
    return this.activeAccount
  }

  private trackUserWithAccount = (account: WalletAccount) => {
    trackUser({
      id: account.relayId,
      address: account.address,
      username: account.user?.username,
      publicUsername: account.user?.publicUsername ?? undefined,
      email: account.user?.email ?? undefined,
      internalUserId: account.user?.relayId ?? undefined,
    })
  }

  private checkActiveAccount = () => {
    if (this.activeAccount) {
      fetch<walletCheckQuery>(
        graphql`
          query walletCheckQuery {
            accountHelpers {
              checkAccount
            }
          }
        `,
        {},
      )
    }
  }

  /**
   * Disconnects from all providers & clears all session data
   */
  public disconnect = async () => {
    await Promise.all(chain.providers.map(p => p.disconnect()))
    this.clear()
  }

  private clear = (publish = true): void => {
    this.activeWallet = undefined
    this.activeAccount = undefined
    this._accounts = {}
    this.persistWalletState(publish)
  }

  public sign = async (
    message: string | Buffer,
    options?: SignOptions,
  ): Promise<string> => {
    const { accountKey, provider } =
      await this.UNSAFE_getActiveAccountAndProviderOrRedirect()
    return provider.sign(message, accountKey.address, options)
  }

  public signTypedData = async (
    message: string | Buffer,
    options?: SignOptions,
  ): Promise<string> => {
    const { accountKey, provider } =
      await this.UNSAFE_getActiveAccountAndProviderOrRedirect()
    return provider.signTypedData(message, accountKey.address, options)
  }

  public transact = async (
    transaction: Omit<Transaction, "source">,
    transactionOptions?: TransactionOptions,
  ): Promise<TransactionResponse> => {
    const { accountKey, provider } =
      await this.UNSAFE_getActiveAccountAndProviderOrRedirect()
    return provider.transact(
      {
        ...transaction,
        source: accountKey.address,
      },
      transactionOptions,
    )
  }

  public estimateGas = async (
    transaction: Omit<Transaction, "source">,
  ): Promise<BigNumber> => {
    const { accountKey, provider } =
      await this.UNSAFE_getActiveAccountAndProviderOrRedirect()
    const gasLimit = await provider.estimateGas({
      ...transaction,
      source: accountKey.address,
    })
    return bn(gasLimit)
  }

  public getNativeCurrencyBalance = async (
    address?: string,
  ): Promise<BigNumber> => {
    const provider = await this.getProvider()
    if (!this.activeAccount || !provider) {
      throw new Error("Attempted to get balance with no wallet connected")
    }
    const balance = await provider.getNativeCurrencyBalance(
      address ?? this.activeAccount.address,
    )
    return bn(balance)
  }

  public getProvider = async (
    account?: ActiveAccount,
  ): Promise<Provider | undefined> => {
    const activeAccount = account ?? this.activeAccount
    if (!activeAccount) {
      return undefined
    }
    return chain.findProvider(activeAccount.address, activeAccount.walletName)
  }

  public getChain = () => {
    return this.getProvider().then(provider => provider?.getChain())
  }

  public ensureLoginCompatibleNetwork = async () => {
    const userChainIdentifier = await this.getChain()
    if (userChainIdentifier) {
      const userChain = getChain(userChainIdentifier)
      if (!isChainOnMatchingNetwork(userChain)) {
        const counterpartNetworkChainIdentifier =
          getCounterpartNetworkChain(userChain)

        const counterpartNetworkChain = getChain(
          counterpartNetworkChainIdentifier,
        )

        await this.switchChain(counterpartNetworkChain)
      }
    }
  }

  private getRedirectLocation = (context?: NextPageContext) => {
    const path = Router.getPath(context)
    // Path is already the login page, don't redirect with referrer
    if (path.startsWith("/login")) {
      import("@/components/app/tracking")
        .then(({ trackWalletRedirectLoginReferrer }) =>
          trackWalletRedirectLoginReferrer({ path }),
        )
        .catch(captureNoncriticalError)
      return "/login"
    }
    const query = { referrer: Router.getPath(context) }
    return `/login${stringifyQueryParams(query)}`
  }

  public redirect = (context: NextPageContext | undefined) => {
    // Clear the wallet state for next login attempt
    this.clear(false)
    const Location = this.getRedirectLocation(context)
    if (context?.res) {
      context.res.writeHead(302, { Location })
      context.res.end()
    } else {
      Router.push(Location)
    }
  }

  public getProviderOrRedirect = async (
    context?: NextPageContext,
    track?: () => void,
    account?: ActiveAccount,
  ): Promise<Provider | undefined> => {
    const provider = await this.getProvider(account)
    if (provider) {
      return provider
    }
    track?.()
    this.redirect(context)
    return undefined
  }

  public UNSAFE_getActiveAccountAndProviderOrRedirect = async (): Promise<{
    account: Account
    accountKey: IdentityKey
    provider: Provider
  }> => {
    const provider = await this.getProviderOrRedirect()
    const account = this.activeAccount
    const accountKey = this.getActiveAccountKey()
    if (account && accountKey && provider) {
      return { account, accountKey, provider }
    }
    throw new Error("Could not find wallet provider matching current account.")
  }

  public getBaseBalance = async (
    paymentAssetId: string,
  ): Promise<BigNumber> => {
    if (!this.activeAccount) {
      return bn(0)
    }

    const { address } = this.activeAccount

    clearCache()

    const [data] = await fetch<walletBalanceQuery>(
      graphql`
        query walletBalanceQuery(
          $paymentAssetId: PaymentAssetRelayID!
          $address: AddressScalar!
        ) {
          blockchain {
            balanceOf(paymentAsset: $paymentAssetId, identity: $address) {
              unit
            }
          }
        }
      `,
      { paymentAssetId, address },
    )

    return bn(data.blockchain.balanceOf.unit)
  }

  public getBalanceBySymbol = async (
    symbol: string,
    chain?: ChainIdentifier,
  ): Promise<BigNumber> => {
    if (!this.activeAccount) {
      return bn(0)
    }
    const [{ paymentAsset }] = await fetch<walletBalanceBySymbolQuery>(
      graphql`
        query walletBalanceBySymbolQuery(
          $symbol: String!
          $chain: ChainScalar
        ) {
          paymentAsset(symbol: $symbol, chain: $chain) {
            relayId
          }
        }
      `,
      { symbol, chain },
    )
    return this.getBaseBalance(paymentAsset.relayId)
  }

  public switchChain = async (chain: Chain) => {
    const provider = await this.getProviderOrRedirect()
    if (provider instanceof Web3EvmProvider) {
      return provider.switchChain(chain)
    }
    throw new Error(`Switching chain is not supported on ${chain.displayName}`)
  }

  public updateUsername = async (newUsername: string | null) => {
    const activeAccount = this.activeAccount
    if (activeAccount && activeAccount.user) {
      this.activeAccount = {
        ...activeAccount,
        user: {
          ...activeAccount.user,
          publicUsername: newUsername,
        },
      }
      this._accounts[this.activeAccount.address] = this.activeAccount
    }
    this.persistWalletState()
  }

  public updateUser = async (user: Account["user"]) => {
    const activeAccount = this.activeAccount
    if (activeAccount) {
      this.activeAccount = {
        ...activeAccount,
        user,
      }
      this._accounts[this.activeAccount.address] = this.activeAccount
    }
    this.persistWalletState()
  }

  public setAuthenticatedAccount = async (
    authedAccount: AuthenticatedAccount,
  ) => {
    if (
      !this.activeAccount ||
      this.activeAccount.address !== authedAccount.address
    ) {
      return
    }

    this.activeAccount = { ...this.activeAccount, ...authedAccount }
    this._accounts[this.activeAccount.address] = this.activeAccount
    if (!getIsAuthStateSimplificationEnabled()) {
      setAuthenticatedAccountCookie(authedAccount.address, authedAccount)
    }
    this.persistWalletState()
  }

  public getTransactionCount = async () => {
    const { accountKey, provider } =
      await this.UNSAFE_getActiveAccountAndProviderOrRedirect()
    return await provider.getTransactionCount(accountKey.address)
  }
}
