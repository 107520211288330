/**
 * @generated SignedSource<<714ab3ca04817abada262b6b16084a0f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
export type Swap = "BRIDGE" | "DEPOSIT_TO_ESCROW" | "UNWRAP" | "WITHDRAW_FROM_ESCROW" | "WRAP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ManageBalanceBridgeFunds_data$data = {
  readonly wallet: {
    readonly fundsOf: {
      readonly supportedSwaps: ReadonlyArray<{
        readonly chain: {
          readonly identifier: ChainIdentifier;
        };
        readonly swapType: Swap;
        readonly symbol: string;
      }>;
    };
  };
  readonly " $fragmentType": "ManageBalanceBridgeFunds_data";
};
export type ManageBalanceBridgeFunds_data$key = {
  readonly " $data"?: ManageBalanceBridgeFunds_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"ManageBalanceBridgeFunds_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "address"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baseCurrencyChain"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baseCurrencySymbol"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ManageBalanceBridgeFunds_data",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "address",
          "variableName": "address"
        }
      ],
      "concreteType": "WalletType",
      "kind": "LinkedField",
      "name": "wallet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "chain",
              "variableName": "baseCurrencyChain"
            },
            {
              "kind": "Variable",
              "name": "symbol",
              "variableName": "baseCurrencySymbol"
            }
          ],
          "concreteType": "WalletFundsType",
          "kind": "LinkedField",
          "name": "fundsOf",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetSwapType",
              "kind": "LinkedField",
              "name": "supportedSwaps",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symbol",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ChainType",
                  "kind": "LinkedField",
                  "name": "chain",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "identifier",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "swapType",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "738d9487fd566a9ce8a32287bcc4fbbd";

export default node;
