import React from "react"
import { classNames, Skeleton } from "@opensea/ui-kit"
import { times } from "lodash"
import { collectionDetailsModalDetailStyles } from "../../CollectionDetailsModal.react"
import { DEFAULT_ROW_COUNT } from "../../constants"

export const CollectionDetailsSkeleton = () => (
  <Skeleton className="mt-9">
    {times(DEFAULT_ROW_COUNT, idx => (
      <Skeleton.Row
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className="CollectionDetailsModal--detail CollectionDetailsModal--detail-content"
        key={idx}
      >
        <Skeleton.Line
          className={classNames(
            "text-md leading-md text-secondary",
            collectionDetailsModalDetailStyles,
          )}
        />
        <Skeleton.Line className="w-full" />
      </Skeleton.Row>
    ))}
  </Skeleton>
)
