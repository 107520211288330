import React from "react"
import { Flex, FlexColumn, Text } from "@opensea/ui-kit"
import { Button } from "@/design-system/Button"
import { useTranslate } from "@/hooks/useTranslate"

type Props = {
  resetError: () => void
}

export const WalletFundsError = ({ resetError }: Props) => {
  const t = useTranslate("components")
  const errorText = t(
    "walletFunds.error.description",
    "There has been an issue fetching this data. Please try to refresh.",
  )

  return (
    <Flex>
      <FlexColumn>
        <Text.Body>{errorText}</Text.Body>
        <Button
          className="mt-6"
          icon="refresh"
          size="small"
          variant="secondary"
          onClick={resetError}
        >
          {t("walletFunds.error.refresh", "Refresh")}
        </Button>
      </FlexColumn>
    </Flex>
  )
}
