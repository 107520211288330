import React from "react"
import { Icon, Text, FlexColumn } from "@opensea/ui-kit"
import { Link } from "@/components/common/Link"
import { Block } from "@/design-system/Block"
import { Modal } from "@/design-system/Modal"
import { useTranslate } from "@/hooks/useTranslate"

type InactiveListingWarningModalContentProps = {
  /**
   * Total count of items that the action involves
   */
  numItems: number
  numItemsWithInactiveListing: number
  onContinue: () => unknown
}

export const InactiveListingWarningModalContent = ({
  numItems,
  numItemsWithInactiveListing,
  onContinue,
}: InactiveListingWarningModalContentProps) => {
  const t = useTranslate("orders")

  return (
    <>
      <Modal.Header />
      <Modal.Body>
        <FlexColumn className="items-center">
          <Block marginY="24px">
            <Icon className="text-red-2" size={64} value="error" />
          </Block>
          <Block className="text-center" marginBottom="4px">
            <Modal.Body.Title>
              {t(
                "inactiveListingsWarning.title",
                {
                  0: "One or more items have an inactive listing",
                  one: "This item has an inactive listing",
                  other: "One or more items have an inactive listing",
                },
                { count: numItems },
              )}
            </Modal.Body.Title>
          </Block>
          <Text className="text-center" size="medium">
            {t(
              "inactiveListingsWarning.description",
              {
                0: "The inactive listings were never canceled and will be reactivated and fulfillable upon purchase.",
                one: "The inactive listing was never canceled and will be reactivated and fulfillable upon purchase.",
                other:
                  "The inactive listings were never canceled and will be reactivated and fulfillable upon purchase.",
              },
              { count: numItemsWithInactiveListing },
            )}{" "}
            <Link href="https://support.opensea.io/articles/8867019">
              {t("inactiveListingsWarning.learnMore", "Learn more")}
            </Link>
          </Text>
        </FlexColumn>
      </Modal.Body>
      <Modal.Footer className="gap-4" flexDirection="column">
        <Modal.Footer.Button onClick={onContinue}>
          {t("inactiveListingsWarning.continue", "Continue with purchase")}
        </Modal.Footer.Button>
        <Modal.Footer.Button
          href="/account?tab=listings_inactive"
          target="_blank"
          variant="secondary"
        >
          {t("inactiveListingsWarning.review", "Review inactive listing")}
        </Modal.Footer.Button>
      </Modal.Footer>
    </>
  )
}
