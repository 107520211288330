import React from "react"
import { CenterAligned, Text } from "@opensea/ui-kit"
import Loading from "@/components/common/Loading.react"
import { Modal } from "@/design-system/Modal"
import { useTranslate } from "@/hooks/useTranslate"

type Props = {
  isOpen: boolean
  onClose: () => unknown
  numTransactions: number
}

export const BulkCancellationModal = ({
  isOpen,
  onClose,
  numTransactions,
}: Props) => {
  const t = useTranslate("settings")
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header>
        <Modal.Header.Title>
          {t("bulkCancel.initiatedModal.title", "Cancellation initiated")}
        </Modal.Header.Title>
      </Modal.Header>
      <Modal.Body>
        <CenterAligned>
          <Loading />
          <Text asChild weight="semibold">
            <p>{t("bulkCancel.initiateModal.processing", "Processing...")}</p>
          </Text>
          {numTransactions > 1 && (
            <Text asChild className="text-center">
              <p>
                {t(
                  "bulkCancel.initiateModal.numTransactions",
                  "You have {{numTransactions}} transactions to cancel all your listings and offers.",
                  { numTransactions },
                )}
              </p>
            </Text>
          )}
          <Text asChild className="text-center">
            <p>
              {t(
                "bulkCancel.initiateModal.body",
                "This may take several minutes to complete. You can close this window and your listings and offers will continue being cancelled in the background.",
              )}
            </p>
          </Text>
        </CenterAligned>
      </Modal.Body>
    </Modal>
  )
}
