/**
 * @generated SignedSource<<7267b10ff252e2f6ed63a30bb7d65e60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type collection_live_stats$data = {
  readonly numOpenCriteriaOffers: string;
  readonly statsV2: {
    readonly numOwners: number;
    readonly totalQuantity: string;
    readonly totalSupply: number;
    readonly totalVolume: {
      readonly symbol: string;
      readonly unit: string;
    };
  };
  readonly totalCriteriaOfferVolume: {
    readonly symbol: string;
    readonly unit: string;
  };
  readonly " $fragmentType": "collection_live_stats";
};
export type collection_live_stats$key = {
  readonly " $data"?: collection_live_stats$data;
  readonly " $fragmentSpreads": FragmentRefs<"collection_live_stats">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "collection_live_stats"
};

(node as any).hash = "a4ab301a8d3b0219439d489c1948638f";

export default node;
