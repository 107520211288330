import React, { useState } from "react"
import {
  Icon,
  useIsLessThanLg,
  UnstyledButton,
  Text,
  VerticalAligned,
  classNames,
  Input,
} from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import styled, { css } from "styled-components"
import { AssetMedia } from "@/components/assets/AssetMedia"
import { ItemQuantityBadge } from "@/components/assets/ItemQuantityBadge.react"
import { OrderPrice } from "@/components/assets/price/OrderPrice.react"
import { StackedAssetMedia } from "@/components/assets/StackedAssetMedia"
import { CollectionLink } from "@/components/collections/CollectionLink"
import { Link } from "@/components/common/Link"
import { Overflow } from "@/components/common/Overflow"
import { Block } from "@/design-system/Block"
import { useTheme } from "@/design-system/Context"
import { Flex } from "@/design-system/Flex"
import { Item } from "@/design-system/Item"
import { List } from "@/design-system/List"
import { Tooltip } from "@/design-system/Tooltip"
import { interactiveStylesPrimary } from "@/design-system/utils"
import {
  CollectionUnreviewedPill,
  ItemStatusContainer,
  ItemStatusVariant,
  StatusIcon,
} from "@/features/collections/components/CollectionUnreviewedPill.react"
import { ItemSideOverflow } from "@/features/shopping-cart/styles"
import {
  trackDecrementItemQuantity,
  trackIncrementItemQuantity,
} from "@/features/shopping-cart/utils/analytics"
import {
  useShoppingCartActions,
  useShoppingCartAddOrRemoveOrders,
  useShoppingCartOrderToQuantity,
  useShoppingCartViewActions,
  useShoppingCartLocalErrors,
} from "@/features/shopping-cart/utils/ShoppingCartContextProvider"
import { useHover } from "@/hooks/useHover"
import { useIsItemSafelisted } from "@/hooks/useIsItemSafelisted"
import { useIsOpen } from "@/hooks/useIsOpen"
import { useItemFees } from "@/hooks/useItemFees"
import { useTranslate } from "@/hooks/useTranslate"
import { ItemTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/ItemTrackingContext.react"
import { OrderListItem_error$key } from "@/lib/graphql/__generated__/OrderListItem_error.graphql"
import { OrderListItem_order$key } from "@/lib/graphql/__generated__/OrderListItem_order.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { getAssetUrl } from "@/lib/helpers/asset"
import {
  BigNumber,
  bn,
  display,
  MAX_DISPLAYED_DECIMAL_PLACES,
} from "@/lib/helpers/numberUtils"
import { themeVariant } from "@/styles/styleUtils"
import THEMES from "@/styles/themes"
import { RemoveFromCartButton } from "./RemoveFromCartButton.react"

type OrderItemProps = {
  order: OrderListItem_order$key | null
  error: OrderListItem_error$key | null
  hasReactivatableListing: boolean
}

export const OrderListItem = ({
  order: orderDataKey,
  error: errorDataKey,
  hasReactivatableListing,
}: OrderItemProps) => {
  const { theme } = useTheme()
  const orderToQuantity = useShoppingCartOrderToQuantity()
  const { incrementOrderQuantity, decrementOrderQuantity, setOrderQuantity } =
    useShoppingCartActions()
  const localErrors = useShoppingCartLocalErrors()
  const { removeOrder } = useShoppingCartAddOrRemoveOrders()
  const { close } = useShoppingCartViewActions()
  const isSmall = useIsLessThanLg()

  const [isHovered, hoverRef] = useHover()
  const [isFocused, setIsFocused] = useState(false)
  const { isOpen: isBundleExpanded, toggle: toggleIsBundleExpanded } =
    useIsOpen()

  const t = useTranslate("bulkPurchase")

  const order = useFragment(
    graphql`
      fragment OrderListItem_order on OrderV2Type {
        relayId
        makerOwnedQuantity
        item {
          __typename
          displayName

          ... on AssetType {
            assetContract {
              ...CollectionLink_assetContract
            }
            collection {
              ...CollectionLink_collection
            }
            ...AssetMedia_asset
            ...asset_url
            ...useItemFees_item
          }

          ... on AssetBundleType {
            assetQuantities(first: 30) {
              edges {
                node {
                  asset {
                    displayName
                    relayId
                    assetContract {
                      ...CollectionLink_assetContract
                    }
                    collection {
                      ...CollectionLink_collection
                    }
                    ...StackedAssetMedia_assets
                    ...AssetMedia_asset
                    ...asset_url
                  }
                }
              }
            }
          }
          # eslint-disable-next-line relay/must-colocate-fragment-spreads we're using it :(
          ...itemEvents_dataV2
          ...useIsItemSafelisted_item
          ...ItemTrackingContext_item
        }
        remainingQuantityType
        ...OrderPrice
      }
    `,
    orderDataKey,
  )

  const error = useFragment(
    graphql`
      fragment OrderListItem_error on BulkPurchaseErrorType {
        updatedOrder {
          relayId
          ...OrderPrice
        }
        reason
      }
    `,
    errorDataKey,
  )

  const { creatorFeesText } = useItemFees(
    order?.item.__typename === "AssetType" ? order.item : null,
  )

  const { isSafelisted, collectionSlug } = useIsItemSafelisted(
    order?.item ?? null,
  )

  if (!order) {
    return null
  }

  const updatedOrder = error?.updatedOrder

  const item = order.item

  const quantity =
    orderToQuantity[order.relayId] ||
    (updatedOrder?.relayId ? orderToQuantity[updatedOrder.relayId] : 0) ||
    0

  const orderPrice = (
    <OrderPrice
      fontWeight={400}
      map={price => price.multipliedBy(quantity)}
      maxDecimalPlaces={MAX_DISPLAYED_DECIMAL_PLACES}
      order={order}
      symbolVariant="raw"
      variant="perUnit"
    />
  )

  const updatedOrderPrice = updatedOrder && (
    <OrderPrice
      fontWeight={400}
      map={price => price.multipliedBy(quantity)}
      maxDecimalPlaces={MAX_DISPLAYED_DECIMAL_PLACES}
      order={updatedOrder}
      symbolVariant="raw"
      variant="perUnit"
    />
  )

  let statusMessage: string | undefined = undefined
  let statusTooltip: React.ReactNode | undefined = undefined
  let statusVariant: ItemStatusVariant = "warning"

  if (hasReactivatableListing) {
    statusMessage = t(
      "shoppingCart.errors.reactivatableListing",
      "Existing listing",
    )
    statusTooltip = t(
      "shoppingCart.errors.tooltip.reactivatableListing",
      "You have an existing listing for this item that will reactivate upon purchase. {{link}}",
      {
        link: (
          <Link href="https://support.opensea.io/articles/8867019">
            {t("shoppingCart.errors.tooltip.learnMore", "Learn more")}
          </Link>
        ),
      },
    )
    statusVariant = "error"
  }

  switch (error?.reason) {
    case "ORDER_UPDATED":
      statusMessage = t("shoppingCart.errors.price", "Price change")
      statusTooltip = t(
        "shoppingCart.errors.tooltip.price",
        "This item has had a recent price change.",
      )
      statusVariant = "warning"
      break
    case "ORDER_UNAVAILABLE":
      statusMessage = t("shoppingCart.errors.notAvailable", "Unavailable")
      statusTooltip = t(
        "shoppingCart.errors.tooltip.unavailable",
        "This item is no longer available to purchase.",
      )
      statusVariant = "warning"
  }

  const localErrorsForThisOrder = localErrors[order.relayId]
  if (localErrorsForThisOrder?.length) {
    // Currently there is only one reason for local errors
    statusMessage = t("shoppingCart.errors.makerOwnership", "Invalid quantity")
    statusTooltip = t(
      "shoppingCart.errors.tooltip.makerOwnership",
      "Seller only owns {{makerOwnedQuantity}} of the item and you have more than {{makerOwnedQuantity}} in your cart",
      {
        makerOwnedQuantity: display(order.makerOwnedQuantity),
      },
    )
    statusVariant = "error"
  }

  // Strangely, __typename doesn't show AssetBundleType
  const bundleAssetQuantities = item.assetQuantities
    ? getNodes(item.assetQuantities)
    : undefined

  const isQuantityShown = bn(order.remainingQuantityType).isGreaterThan(1)

  const isIncrementQuantityDisabled = bn(quantity).isGreaterThanOrEqualTo(
    BigNumber.min(order.remainingQuantityType, order.makerOwnedQuantity),
  )

  const isInteractive = isSmall || isHovered || isFocused
  const hasError = Boolean(error?.reason)

  return (
    <ItemTrackingContextProvider item={order.item}>
      <StyledItem data-testid="shopping-cart-item" ref={hoverRef}>
        <ItemQuantityBadge
          overrides={{
            QuantityBadge: {
              props: {
                className: classNames("right-[5px] top-[-2px] lg:top-[-10px]"),
              },
            },
          }}
          quantity={quantity}
        >
          {bundleAssetQuantities ? (
            <Item.Avatar
              className="cursor-pointer"
              height={84}
              width={74}
              onClick={toggleIsBundleExpanded}
            >
              <StackedAssetMedia
                assets={bundleAssetQuantities.map(({ asset }) => asset)}
                backgroundCover={
                  isHovered
                    ? THEMES[theme].colors.components.background.gray2
                    : THEMES[theme].colors.components.elevation.level2
                        .background
                }
                variant="small"
              />
            </Item.Avatar>
          ) : (
            <Item.Avatar className="relative rounded-xl" size={72}>
              <Link href={getAssetUrl(item)} variant="subtle" onClick={close}>
                <AssetMedia asset={item} size={72} />
              </Link>
            </Item.Avatar>
          )}
        </ItemQuantityBadge>

        <Item.Content>
          <VerticalAligned className="w-full">
            {bundleAssetQuantities ? (
              <Item.Title
                className="cursor-pointer"
                size="small"
                onClick={toggleIsBundleExpanded}
              >
                <Overflow>{item.displayName}</Overflow>
              </Item.Title>
            ) : (
              <Item.Title>
                <Link href={getAssetUrl(item)} variant="subtle" onClick={close}>
                  <Overflow className="text-primary">
                    <Text.Body size="small" weight="semibold">
                      {item.displayName}
                    </Text.Body>
                  </Overflow>
                </Link>
              </Item.Title>
            )}

            {bundleAssetQuantities ? (
              <Item.Description size="small">
                <Overflow>
                  {t(
                    "itemCount",
                    {
                      "0": "{{count}} items",
                      one: "{{count}} item",
                      other: "{{count}} items",
                    },
                    { count: bundleAssetQuantities.length },
                  )}
                </Overflow>
              </Item.Description>
            ) : (
              item.collection && (
                <Item.Description>
                  <Overflow>
                    <Text.Body size="small">
                      <CollectionLink
                        assetContract={item.assetContract}
                        collection={item.collection}
                        disabled
                        isSmall
                        linkStyle={{
                          color: THEMES[theme].colors.text.primary,
                          opacity: 1,
                        }}
                        onClick={close}
                      />
                    </Text.Body>
                  </Overflow>
                </Item.Description>
              )
            )}

            {isSmall && (
              <Text.Body className="mr-1.5 flex" size="small" weight="semibold">
                {orderPrice}
              </Text.Body>
            )}

            {statusMessage && statusTooltip ? (
              <Tooltip
                content={() => <Block maxWidth="200px">{statusTooltip}</Block>}
                interactive
              >
                <ItemStatusContainer variant={statusVariant}>
                  <StatusIcon
                    $statusVariant={statusVariant}
                    className="mr-1"
                    size={16}
                    value="error"
                  />
                  <Text.Body className="text-gray-3" size="tiny">
                    {statusMessage}
                  </Text.Body>
                </ItemStatusContainer>
              </Tooltip>
            ) : !isSafelisted && collectionSlug ? (
              <CollectionUnreviewedPill collectionSlug={collectionSlug} />
            ) : (
              <Text.Body className="text-secondary" size="tiny">
                <Overflow>{creatorFeesText}</Overflow>
              </Text.Body>
            )}
          </VerticalAligned>
        </Item.Content>
        <Item.Side className="max-w-full">
          {isInteractive ? (
            <Flex alignItems="center" className="gap-[18px]">
              {quantity <= 1 || hasError ? (
                <RemoveFromCartButton orderRelayId={order.relayId} />
              ) : (
                <UnstyledButton
                  onClick={() => {
                    decrementOrderQuantity(order.relayId)
                    trackDecrementItemQuantity(order.item)
                  }}
                >
                  <InteractiveIcon
                    size={20}
                    title={t(
                      "shoppingCart.cta.decrement",
                      "Decrement quantity",
                    )}
                    value="remove"
                  />
                </UnstyledButton>
              )}

              {isQuantityShown && !hasError && (
                <>
                  <Item.Title>
                    <StyledInput
                      $isFocused={isFocused}
                      max={order.remainingQuantityType}
                      min={0}
                      overrides={{
                        Input: { style: { textAlign: "center" } },
                      }}
                      placeholder={order.remainingQuantityType}
                      type="number"
                      value={quantity.toString()}
                      width="52px"
                      onBlur={() => {
                        setIsFocused(false)

                        if (quantity <= 0) {
                          removeOrder(order.relayId)
                        }
                      }}
                      onChange={e => {
                        setOrderQuantity(
                          order.relayId,
                          BigNumber.min(
                            e.target.value,
                            order.remainingQuantityType,
                          ).toNumber(),
                        )
                      }}
                      onFocus={() => setIsFocused(true)}
                    />
                  </Item.Title>
                  <UnstyledButton
                    disabled={isIncrementQuantityDisabled}
                    onClick={() => {
                      incrementOrderQuantity(order.relayId)
                      trackIncrementItemQuantity(order.item)
                    }}
                  >
                    <InteractiveIcon
                      className={
                        isIncrementQuantityDisabled
                          ? "text-secondary"
                          : undefined
                      }
                      size={20}
                      title={t(
                        "shoppingCart.cta.increment",
                        "Increment quantity",
                      )}
                      value="add"
                    />
                  </UnstyledButton>
                </>
              )}
            </Flex>
          ) : (
            <>
              <Item.Title>
                <Text.Body size="small">
                  <ItemSideOverflow>
                    {updatedOrder ? updatedOrderPrice : orderPrice}
                  </ItemSideOverflow>
                </Text.Body>
              </Item.Title>
              {updatedOrder && (
                <Item.Description>
                  <Text.Body size="small">
                    <ItemSideOverflow>
                      <OldPrice>{orderPrice}</OldPrice>
                    </ItemSideOverflow>
                  </Text.Body>
                </Item.Description>
              )}
            </>
          )}
        </Item.Side>
      </StyledItem>

      {isBundleExpanded && bundleAssetQuantities && (
        <ExpandedBundleContainer>
          <List showBorder={false} variant="framed">
            {bundleAssetQuantities.map(({ asset }) => (
              <BundleItem key={asset.relayId}>
                <Item.Avatar className="relative rounded-xl" size={40}>
                  <Link
                    href={getAssetUrl(asset)}
                    variant="subtle"
                    onClick={close}
                  >
                    <AssetMedia asset={asset} size={40} />
                  </Link>
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>
                    <Link
                      href={getAssetUrl(asset)}
                      variant="subtle"
                      onClick={close}
                    >
                      <Overflow>
                        <Text.Body
                          className="text-primary"
                          size="small"
                          weight="semibold"
                        >
                          {asset.displayName}
                        </Text.Body>
                      </Overflow>
                    </Link>
                  </Item.Title>
                  <Item.Description>
                    <Text.Body size="small">
                      <CollectionLink
                        assetContract={asset.assetContract}
                        collection={asset.collection}
                        disabled
                        isSmall
                        linkStyle={{
                          color: THEMES[theme].colors.text.primary,
                          opacity: 1,
                        }}
                        onClick={close}
                      />
                    </Text.Body>
                  </Item.Description>
                </Item.Content>
              </BundleItem>
            ))}
          </List>
        </ExpandedBundleContainer>
      )}
    </ItemTrackingContextProvider>
  )
}

const OldPrice = styled(Block)`
  opacity: 0.4;
  text-decoration: line-through;
`

const StyledItem = styled(Item)`
  padding: 8px;
  margin: 4px 16px;
  width: calc(100% - 32px);

  && {
    border-radius: ${props => props.theme.borderRadius.button};
  }

  &:hover {
    background: ${props => props.theme.colors.components.background.gray1};
  }
`

const InteractiveIcon = styled(Icon)`
  ${interactiveStylesPrimary};
`

// TODO: Move this to design system as a better component

const StyledInput = styled(Input)<{ $isFocused: boolean }>`
  ${props =>
    !props.$isFocused &&
    css`
      border: 0;

      &:hover {
        box-shadow: none;
      }
    `}
`

export const ExpandedBundleContainer = styled(Block)`
  && {
    border-radius: 0;
    border-top: 1px solid
      ${props => props.theme.colors.components.border.level2};
    border-bottom: 1px solid
      ${props => props.theme.colors.components.border.level2};
  }

  ${props =>
    themeVariant({
      variants: {
        light: { background: props.theme.colors.lightGray },
        dark: { background: props.theme.colors.oil },
      },
    })}
`

export const BundleItem = styled(Item)`
  padding: 12px 48px;
`
