"use client"

import React, { useEffect, useState } from "react"
import { Icon, Link, Text, UnstyledButton, classNames } from "@opensea/ui-kit"
import localFont from "next/font/local"
import { Z_INDEX } from "@/constants/zIndex"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { useRouter } from "@/hooks/useRouter"
import { useTranslate } from "@/hooks/useTranslate"
import { MAX_BANNER_HEIGHT, useAnnouncementBanner } from "../utils/context"
import { useOpenBetaAnnouncementBannerEnabled } from "./useOpenBetaAnnouncementBannerEnabled"

const gtAmericaMono = localFont({
  preload: true,
  src: "../../../public/fonts/GT-America-Mono-Regular.woff2",
  display: "swap",
  fallback: [
    "ui-monospace",
    "SFMono-Regular",
    "Menlo",
    "Monaco",
    "Consolas",
    "Liberation Mono",
    "Courier New",
    "monospace",
  ],
  variable: "--font-family-mono",
})

const OS2_BANNER_DISMISSED = "is_os2_beta_banner_dismissed_v2"

export const useShowOs2AnnouncementBanner = () => {
  const connectedAddress = useConnectedAddress()
  const { pathname } = useRouter()
  const [show, setShow] = useState(false)
  const enabled = useOpenBetaAnnouncementBannerEnabled()

  useEffect(() => {
    // Never show banner on studio, covers up header.
    if (pathname.startsWith("/studio") || pathname.startsWith("/deal")) {
      setShow(false)
      return
    }

    if (enabled) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [connectedAddress, pathname])

  return show
}

const useOs2BannerDismissal = () => {
  const [isDismissed, setIsDismissed] = React.useState(() => {
    if (typeof window !== "undefined") {
      return Boolean(localStorage.getItem(OS2_BANNER_DISMISSED))
    }
    return false
  })

  const dismissBanner = React.useCallback(() => {
    if (typeof window !== "undefined") {
      localStorage.setItem(OS2_BANNER_DISMISSED, "true")
      setIsDismissed(true)
    }
  }, [])

  return {
    isDismissed,
    dismissBanner,
  }
}

export const OpenBetaAnnouncementBanner = () => {
  const t = useTranslate("home")
  const { setAnnouncementBannerHeight, setIsAnnouncementBannerShown } =
    useAnnouncementBanner()
  const { isDismissed, dismissBanner } = useOs2BannerDismissal()

  useEffect(() => {
    setAnnouncementBannerHeight(MAX_BANNER_HEIGHT)
    setIsAnnouncementBannerShown(true)

    return () => {
      setIsAnnouncementBannerShown(false)
    }
  }, [setAnnouncementBannerHeight, setIsAnnouncementBannerShown])

  const ctaText = t("os2Banner.button", "Upgrade Now")
  const heading = t("os2Banner.title", "Try the new OpenSea.")

  if (typeof window === "undefined" || isDismissed) {
    return null
  }

  return (
    <header
      className={classNames(
        gtAmericaMono.className,
        "flex w-full items-center justify-center",
      )}
      style={{
        zIndex: Z_INDEX.NAVBAR + 1,
        top: 0,
        position: "relative",
        background:
          "linear-gradient(90deg, #050F44 0%, #000 51%, #050F44 98.5%), linear-gradient(90deg, #0C156D 0%, #1D2DC9 47%, #0F69F2 100%)",
      }}
    >
      <Link
        className="flex flex-col items-center gap-3 py-5 text-center font-ibm-plex-mono uppercase text-white md:flex-row"
        href="https://opensea.io/os2"
        variant="unstyled"
        onClick={() => {
          window.location.reload()
        }}
      >
        <Text>{heading}</Text>
        <Text className="underline">
          {ctaText}
          <Icon className="align-middle" size={24} value="chevron_right" />
        </Text>
      </Link>

      <UnstyledButton className="absolute right-5" onClick={dismissBanner}>
        <Icon className="text-primary" value="close" />
      </UnstyledButton>
    </header>
  )
}
