import React from "react"
import { useLazyLoadQuery } from "react-relay"
import { useMountEffect } from "@/hooks/useMountEffect"
import {
  trackOpenReviewCollectionDetailsOfferModal,
  trackCloseReviewCollectionDetailsOfferModal,
} from "@/lib/analytics/events/collectionEvents"
import { CollectionOfferCollectionDetailsQuery } from "@/lib/graphql/__generated__/CollectionOfferCollectionDetailsQuery.graphql"
import { getFirstNode, graphql } from "@/lib/graphql/graphql"
import { CollectionDetailsContent } from "../CollectionDetailsContent"

type CollectionOfferDetailsProps = {
  collectionSlug: string
  hasConfirmedDetails: boolean
}

export const CollectionOfferCollectionDetails = ({
  collectionSlug,
  hasConfirmedDetails,
}: CollectionOfferDetailsProps) => {
  const { collection } =
    useLazyLoadQuery<CollectionOfferCollectionDetailsQuery>(
      graphql`
        query CollectionOfferCollectionDetailsQuery(
          $collection: CollectionSlug!
        ) {
          collection(collection: $collection) {
            slug
            ...CollectionDetailsContent_data
            assetContracts(first: 1) {
              edges {
                node {
                  ...CollectionDetailsContent_contractdata
                }
              }
            }
          }
        }
      `,
      { collection: collectionSlug },
    )

  const assetContract = getFirstNode(collection?.assetContracts)

  useMountEffect(() => {
    trackOpenReviewCollectionDetailsOfferModal({
      collectionSlug: collection?.slug ?? "",
    })
    return () => {
      // Does not count when the user closes the modal after already confirmed the
      // collection details and moved to the checkout page.
      if (!hasConfirmedDetails) {
        trackCloseReviewCollectionDetailsOfferModal({
          collectionSlug: collection?.slug ?? "",
        })
      }
    }
  })

  if (!collection) {
    return null
  }

  return (
    <CollectionDetailsContent
      assetContractKey={assetContract ?? null}
      collectionDataKey={collection}
    />
  )
}
