import { ChainIdentifier } from "@/hooks/useChains/types"
import { paymentAssetQuery } from "@/lib/graphql/__generated__/paymentAssetQuery.graphql"
import { fetch, graphql } from "@/lib/graphql/graphql"

export const resolvePaymentAsset = async (
  symbol: string,
  chain?: ChainIdentifier,
) => {
  const [data, dispose] = await fetch<paymentAssetQuery>(
    graphql`
      query paymentAssetQuery($symbol: String!, $chain: ChainScalar) {
        paymentAsset(symbol: $symbol, chain: $chain) {
          decimals
          asset {
            relayId
          }
        }
      }
    `,
    { symbol, chain },
  )
  return [
    {
      decimals: data.paymentAsset.decimals,
      assetRelayId: data.paymentAsset.asset.relayId,
    },
    dispose,
  ] as const
}
