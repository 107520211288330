import React from "react"
import { Text } from "@opensea/ui-kit"
import { ErrorBoundary } from "@sentry/nextjs"
import { graphql, useFragment } from "react-relay"
import { Overflow } from "@/components/common/Overflow"
import { EMPTY_PRICE_DISPLAY } from "@/constants/index"
import { Item, ItemProps } from "@/design-system/Item"
import { DateRange } from "@/features/orders/hooks/useDateRangeOptions"
import { useTranslate } from "@/hooks/useTranslate"
import { ConfirmationItem_asset$key } from "@/lib/graphql/__generated__/ConfirmationItem_asset.graphql"
import { ConfirmationItem_asset_item_payment_asset$key } from "@/lib/graphql/__generated__/ConfirmationItem_asset_item_payment_asset.graphql"
import { ConfirmationItem_assets$key } from "@/lib/graphql/__generated__/ConfirmationItem_assets.graphql"
import { ConfirmationItem_extra_payment_asset$key } from "@/lib/graphql/__generated__/ConfirmationItem_extra_payment_asset.graphql"
import { ConfirmationItem_payment_asset$key } from "@/lib/graphql/__generated__/ConfirmationItem_payment_asset.graphql"
import { BigNumber, displayUSD } from "@/lib/helpers/numberUtils"
import { captureNoncriticalError } from "@/lib/sentry"
import { AssetItem, AssetItemProps } from "./AssetItem.react"
import { AssetItemError } from "./AssetItemError.react"

export type ConfirmationItemDetails = {
  reservedBuyerAddress?: string
  price: BigNumber
  quantity: BigNumber
  reservePrice?: BigNumber
  duration: DateRange
}

type AssetConfirmationExtraProps = Pick<ConfirmationItemDetails, "price"> & {
  paymentAssetDataKey: ConfirmationItem_extra_payment_asset$key
}

const AssetConfirmationExtra = ({
  price,
  paymentAssetDataKey,
}: AssetConfirmationExtraProps) => {
  const paymentAsset = useFragment(
    graphql`
      fragment ConfirmationItem_extra_payment_asset on PaymentAssetType {
        symbol
        usdSpotPrice
      }
    `,
    paymentAssetDataKey,
  )

  const usdSpotPrice = paymentAsset.usdSpotPrice

  const renderPrice = () => (
    <Overflow lines={1}>
      {!price.eq(0) ? price.toString() : EMPTY_PRICE_DISPLAY}{" "}
      {paymentAsset.symbol}
    </Overflow>
  )

  return (
    <>
      <Item.Side className="max-w-full">
        <Item.Title
          aria-label={`Price: ${price.toString()} ${paymentAsset.symbol || ""}`}
          className="flex justify-end"
          data-testid="OrderDataHeader-price"
        >
          {renderPrice()}
        </Item.Title>
        <Item.Description>
          {usdSpotPrice
            ? `$${displayUSD(price.multipliedBy(usdSpotPrice))}`
            : "$0.00"}
        </Item.Description>
      </Item.Side>
    </>
  )
}

type AssetConfirmationItemProps = Omit<
  AssetItemProps,
  "assetDataKey" | "variant"
> & {
  assetDataKey: ConfirmationItem_asset$key
  paymentAssetDataKey: ConfirmationItem_asset_item_payment_asset$key | null
  confirmationDetails: ConfirmationItemDetails
  renderAdditionalContentExtra?: () => React.ReactNode
}

export const AssetConfirmationItem = ({
  assetDataKey,
  paymentAssetDataKey,
  confirmationDetails,
  isLoading,
  renderContentExtra,
  renderAdditionalContentExtra,
  renderExtra,
  ...restProps
}: AssetConfirmationItemProps) => {
  const t = useTranslate("sell")

  const asset = useFragment(
    graphql`
      fragment ConfirmationItem_asset on AssetType {
        chain {
          displayName
        }
        ...AssetItem_asset
      }
    `,
    assetDataKey,
  )

  const paymentAsset = useFragment(
    graphql`
      fragment ConfirmationItem_asset_item_payment_asset on PaymentAssetType {
        ...ConfirmationItem_extra_payment_asset
      }
    `,
    paymentAssetDataKey,
  )

  const { price, quantity } = confirmationDetails

  return (
    <ErrorBoundary
      fallback={({ error, resetError }) => {
        captureNoncriticalError(error)
        return <AssetItemError resetError={resetError} />
      }}
    >
      <AssetItem
        assetDataKey={asset}
        isLoading={isLoading}
        renderAdditionalContentExtra={renderAdditionalContentExtra}
        renderContentExtra={
          renderContentExtra ??
          (() => (
            <>
              {!quantity.isEqualTo(1) ? (
                <Item.Description>
                  <Text.Body asChild size="small">
                    <div>
                      {t(
                        "assetItem.quantityWithValue",
                        "Quantity: {{quantity}}",
                        {
                          quantity: quantity.toString(),
                        },
                      )}
                    </div>
                  </Text.Body>
                </Item.Description>
              ) : null}

              <Item.Description>
                {t("assetItem.chain", "Chain: {{chainDisplayName}}", {
                  chainDisplayName: asset.chain.displayName,
                })}
              </Item.Description>
            </>
          ))
        }
        renderExtra={
          renderExtra ??
          (() =>
            paymentAsset && (
              <AssetConfirmationExtra
                paymentAssetDataKey={paymentAsset}
                price={price.times(quantity)}
              />
            ))
        }
        {...restProps}
      />
    </ErrorBoundary>
  )
}

export type ConfirmationItemProps = {
  assetDataKey: ConfirmationItem_assets$key
  paymentAssetDataKey: ConfirmationItem_payment_asset$key | null
  confirmationDetails: ConfirmationItemDetails
  transactionHash?: string
  isLoading?: boolean
  renderContentExtra?: () => React.ReactNode
  renderAdditionalContentExtra?: () => React.ReactNode
  renderExtra?: () => React.ReactNode
} & ItemProps

export const ConfirmationItem = ({
  assetDataKey,
  paymentAssetDataKey,
  confirmationDetails,
  isLoading,
  renderContentExtra,
  renderAdditionalContentExtra,
  renderExtra,
  transactionHash,
  ...itemProps
}: ConfirmationItemProps) => {
  const assets = useFragment(
    graphql`
      fragment ConfirmationItem_assets on AssetType @relay(plural: true) {
        ...ConfirmationItem_asset
      }
    `,
    assetDataKey,
  )

  const paymentAsset = useFragment(
    graphql`
      fragment ConfirmationItem_payment_asset on PaymentAssetType {
        ...ConfirmationItem_asset_item_payment_asset
      }
    `,
    paymentAssetDataKey,
  )

  return (
    <AssetConfirmationItem
      assetDataKey={assets[0]}
      confirmationDetails={confirmationDetails}
      isLoading={isLoading}
      paymentAssetDataKey={paymentAsset}
      renderAdditionalContentExtra={renderAdditionalContentExtra}
      renderContentExtra={renderContentExtra}
      renderExtra={renderExtra}
      transactionHash={transactionHash}
      {...itemProps}
    />
  )
}
