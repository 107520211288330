import { IS_TESTNET } from "@/constants/testnet"
import { isKlaytn, isSolana } from "@/lib/helpers/chainUtils"
import type {
  Chain,
  ChainByIdentifier,
  ChainByNetworkId,
  ChainIdentifier,
} from "./types"

export const createChainByNetworkIdLookup = (chains: Chain[]) => {
  return chains.reduce((acc, chain) => {
    if (chain.networkId) {
      acc[Number(chain.networkId)] = chain
    }
    return acc
  }, {} as ChainByNetworkId)
}

export const createChainByIdentifierLookup = (chains: Chain[]) => {
  return chains.reduce((acc, chain) => {
    acc[chain.identifier] = chain
    return acc
  }, {} as ChainByIdentifier)
}

// TODO(Meemaw): Not optimal. Should we source this from BE?
export const getCounterpartNetworkChain = (chain: Chain): ChainIdentifier => {
  if (isKlaytn(chain.identifier)) {
    return chain.identifier === "BAOBAB" ? "KLAYTN" : "BAOBAB"
  } else if (isSolana(chain.identifier)) {
    return chain.identifier === "SOLDEV" ? "SOLANA" : "SOLDEV"
  }
  // All other EVM chains will ask you to switch to Ethereum/Rinkeby instead of e.g Polygon for Mumbai
  return chain.canonicalChain === "ETHEREUM" ? "SEPOLIA" : "ETHEREUM"
}

export const isChainOnMatchingNetwork = (chain: Chain) => {
  return IS_TESTNET ? chain.network === "TESTNET" : chain.network === "MAINNET"
}
