import styled from "styled-components"
import { themeVariant } from "@/styles/styleUtils"
import type { FiatPriceProps } from "./FiatPrice.react"

export const PriceContainer = styled.div<
  Pick<FiatPriceProps, "fontWeight" | "color" | "position">
>`
  position: ${props => props.position};
  color: ${props => props.color || props.theme.colors.text.primary};
  width: fit-content;
  max-width: 100%;

  &.Price--main {
    align-items: center;
    display: flex;
    font-weight: ${props => props.fontWeight ?? 600};
  }

  &.Price--isInline {
    display: inline-flex;
  }

  &.Price--isRawSymbol {
    .Price--amount {
      margin-left: 0;
    }
  }

  .Price--amount {
    width: 100%;
  }

  .Price--raw-symbol {
    font-weight: ${props => props.fontWeight ?? "normal"};
  }

  .Price--fiat-amount {
    font-weight: ${props => props.fontWeight ?? "normal"};
  }
  .Price--fiat-amount-secondary {
    color: ${props => props.theme.colors.text.secondary};
  }

  .Price--eth-icon {
    ${themeVariant({
      variants: {
        dark: {
          filter: "brightness(3)",
        },
      },
    })}
  }
`
