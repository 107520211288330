import type { _FragmentRefs } from "relay-runtime"
import { ChainIdentifier } from "@/hooks/useChains/types"
import type { bundle_url$data } from "@/lib/graphql/__generated__/bundle_url.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { getChainSlug } from "@/lib/helpers/chainUtils"
import { stringifyQueryParams } from "./urls"

const readUrlIdentifiers = inlineFragmentize<bundle_url$data>(
  graphql`
    fragment bundle_url on AssetBundleType @inline {
      slug
      chain {
        identifier
      }
    }
  `,
  identifiers => identifiers,
)

export const getBundleUrl = (
  ref: bundle_url$data | _FragmentRefs<"bundle_url">,
) => {
  const {
    slug,
    chain: { identifier },
  } = readUrlIdentifiers(ref)

  return getBundleLink({ slug, chain: identifier })
}

const getBundleLink = ({
  chain,
  slug,
}: {
  chain: ChainIdentifier
  slug: string | null
}) => {
  return `/bundles/${getChainSlug(chain)}/${slug}`
}

export const getBundleSellUrl = (
  chain: ChainIdentifier,
  assets: string[],
  slug?: string | null,
) => {
  return `/bundle/${getChainSlug(chain)}/sell${stringifyQueryParams({
    assets,
    bundleSlug: slug,
  })}`
}
