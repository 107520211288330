import React, { useCallback } from "react"
import { Flex, Text } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { TokenPrice } from "@/components/assets/price/TokenPrice.react"
import { StackedAssetMedia } from "@/components/assets/StackedAssetMedia"
import { Item } from "@/design-system/Item"
import { useTotalItems } from "@/features/shopping-cart/hooks/useTotalItems"
import { useTotalPriceOrderData } from "@/features/shopping-cart/hooks/useTotalPriceOrderData"
import { ItemSideOverflowStacked } from "@/features/shopping-cart/styles"
import { useTranslate } from "@/hooks/useTranslate"
import {
  useTrackBlockchainAttempt,
  useTrackBlockchainError,
} from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/hooks"
import { CreateBulkOrderAction_data$key } from "@/lib/graphql/__generated__/CreateBulkOrderAction_data.graphql"
import { StackedAssetMedia_assets$key } from "@/lib/graphql/__generated__/StackedAssetMedia_assets.graphql"
import {
  BaseBlockchainActionProps,
  BlockchainActionModalContent,
} from "./BlockchainActionModalContent.react"
import { useBlockchainActionProgress } from "./useBlockchainActionProgress"
import { useHandleBlockchainActions } from "./useHandleBlockchainActions"

type Props = BaseBlockchainActionProps & {
  dataKey: CreateBulkOrderAction_data$key
}

export const CreateBulkOrderAction = ({ dataKey, onEnd }: Props) => {
  const t = useTranslate("components")
  const action = useFragment(
    graphql`
      fragment CreateBulkOrderAction_data on CreateBulkOrderActionType {
        __typename
        orderDatas {
          item {
            ... on AssetQuantityDataType {
              asset {
                ...StackedAssetMedia_assets
              }
            }
          }
          ...useTotalItems_ordersData
          ...useTotalPriceOrderData_orderData
        }
        ...useHandleBlockchainActions_create_bulk_order
      }
    `,
    dataKey,
  )

  const assets = action.orderDatas
    .map(orderData => orderData.item?.asset)
    .filter(asset => asset !== undefined) as StackedAssetMedia_assets$key

  const { totalPricePerSymbol, totalUsdPrice } = useTotalPriceOrderData(
    action.orderDatas,
  )

  const totalItems = useTotalItems({
    ordersDataDataKey: action.orderDatas,
  })

  const { createBulkOrder } = useHandleBlockchainActions()

  const trackBlockchainAttempt = useTrackBlockchainAttempt()
  const [trackBlockchainError, errorTags] = useTrackBlockchainError()

  const executeAction = useCallback(async () => {
    trackBlockchainAttempt({ blockchainAction: "coreAction" })
    const {
      orders: {
        create: { orders },
      },
    } = await createBulkOrder(action)

    onEnd({
      createdOrders: orders,
    })
  }, [action, createBulkOrder, onEnd, trackBlockchainAttempt])

  const { attemptAction, progress } = useBlockchainActionProgress({
    executeAction,
    action,
    onError: trackBlockchainError({ blockchainAction: "coreAction" }),
    errorTags,
  })

  const title = t(
    "blockchain.complete.listing",
    {
      0: "Complete listings",
      one: "Complete listing",
      other: "Complete listings",
    },
    { count: totalItems.length },
  )

  const actionText: React.ReactNode = t(
    "blockchain.create.listing.prompt",
    {
      0: "You'll be asked to review and confirm these listings from your wallet.",
      one: "You'll be asked to review and confirm this listing from your wallet.",
      other:
        "You'll be asked to review and confirm these listings from your wallet.",
    },
    { count: totalItems.length },
  )

  return (
    <BlockchainActionModalContent>
      <BlockchainActionModalContent.Header>
        <BlockchainActionModalContent.Title>
          {title}
        </BlockchainActionModalContent.Title>
      </BlockchainActionModalContent.Header>
      <BlockchainActionModalContent.Body>
        <Item variant="unstyled">
          <Item.Avatar size={84}>
            <StackedAssetMedia assets={assets} isLoading variant="small" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title data-testid="create-bulk-order-number-items">
              <Text asChild size="medium" weight="semibold">
                <div>
                  {t("numberItems", "{{totalNumber}} items", {
                    totalNumber: totalItems.length,
                  })}
                </div>
              </Text>
            </Item.Title>
          </Item.Content>
          <Item.Side>
            <Item.Title data-testid="create-bulk-order-total-price">
              <ItemSideOverflowStacked>
                <Text.Body weight="semibold">
                  {Object.keys(totalPricePerSymbol).map(symbol => {
                    const price = totalPricePerSymbol[symbol]
                    return (
                      price && (
                        <Flex key={symbol}>
                          <TokenPrice
                            {...price.tokenPricePayment}
                            fontWeight={600}
                            price={price.price}
                            symbol={price.tokenPricePayment.symbol}
                            symbolVariant="raw"
                          />
                        </Flex>
                      )
                    )
                  })}
                </Text.Body>
              </ItemSideOverflowStacked>
            </Item.Title>
            <Item.Description>{totalUsdPrice}</Item.Description>
          </Item.Side>
        </Item>
        <BlockchainActionModalContent.Body.Separator />
        <BlockchainActionModalContent.Body.GoToWallet />
        <BlockchainActionModalContent.Body.Text>
          {actionText}
        </BlockchainActionModalContent.Body.Text>
        {!progress && (
          <BlockchainActionModalContent.Body.Button onClick={attemptAction}>
            {t("blockchain.fulfill.cta", "Continue")}
          </BlockchainActionModalContent.Body.Button>
        )}
      </BlockchainActionModalContent.Body>
    </BlockchainActionModalContent>
  )
}
