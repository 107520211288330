import React, { useRef } from "react"
import { classNames, useIsLessThanSm } from "@opensea/ui-kit"
import useMergedRef from "@react-hook/merged-ref"
import { IFrame, StyledIFrameContainer } from "@/components/common/IFrame.react"
import { Z_INDEX } from "@/constants/zIndex"
import { BottomSheet } from "@/design-system/BottomSheet"
import { Overlay } from "@/design-system/Overlay"
import { OS_WALLET_URL } from "@/features/os-wallet/constants"
import { useLockBodyScroll } from "@/hooks/useLockBodyScroll"

const DEFAULT_WALLET_HEIGHT = 550
const DEFAULT_WALLET_HEIGHT_PX = `${DEFAULT_WALLET_HEIGHT}px`

const DEFAULT_WALLET_WIDTH = 400
const DEFAULT_WALLET_WIDTH_PX = `${DEFAULT_WALLET_WIDTH}px`

type OSWalletIFramePopoverProps = {
  isOpen: boolean
  isFocused: boolean
  closeWallet: () => void
  anchorRef: React.RefObject<HTMLElement>
  initVesselCallbackRef: React.RefCallback<HTMLIFrameElement>
  keepWalletOpenOnClickCallbackRef: React.RefCallback<HTMLElement>
}

export const OSWalletIFramePopover = ({
  isOpen,
  isFocused,
  closeWallet,
  anchorRef,
  initVesselCallbackRef,
  keepWalletOpenOnClickCallbackRef,
}: OSWalletIFramePopoverProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const mergedRef = useMergedRef(keepWalletOpenOnClickCallbackRef, containerRef)

  // Lock body scroll when wallet is open on mobile.
  const isMobile = useIsLessThanSm()
  const isBodyScrollLocked = isMobile && isOpen
  useLockBodyScroll(isBodyScrollLocked, containerRef)

  const renderContent = ({
    width,
    height,
  }: {
    width: string
    height: string
  }) => {
    return (
      <div
        className={classNames({ "pointer-events-none": !isOpen })}
        ref={mergedRef}
        style={{ width, height }}
      >
        <IFrame
          allow="clipboard-write"
          id="os-wallet-iframe"
          overrides={{
            IFrameContainer: WalletIFrameContainer,
          }}
          ref={initVesselCallbackRef}
          url={OS_WALLET_URL}
          withSpinner={false}
        />
      </div>
    )
  }

  if (isMobile) {
    return (
      <BottomSheet
        closeOnOverlayClick
        isOpen={isOpen}
        keepMounted
        overrides={{
          Dialog: {
            props: {
              style: {
                overflow: "hidden",
              },
            },
          },
          CloseRoot: {
            style: {
              display: "none",
            },
          },
          Overlay: {
            props: {
              zIndex: isOpen ? Z_INDEX.OS_WALLET_POPOVER : -1,
            },
          },
        }}
        onClose={closeWallet}
      >
        {renderContent({
          height: DEFAULT_WALLET_HEIGHT_PX,
          width: "100%",
        })}
      </BottomSheet>
    )
  }

  const boundingBox = anchorRef.current?.getBoundingClientRect()
  const positionStyles = boundingBox
    ? {
        left: boundingBox.right - DEFAULT_WALLET_WIDTH,
        top: boundingBox.bottom + 11,
      }
    : {
        right: "100px",
        top: "60px",
      }

  return (
    <>
      <Overlay active={isFocused} zIndex={Z_INDEX.OS_WALLET_POPOVER} />
      <div
        className="fixed rounded-default shadow-elevation-2"
        style={{
          ...positionStyles,
          display: isOpen ? "block" : "none",
          zIndex: Z_INDEX.OS_WALLET_POPOVER,
        }}
      >
        {renderContent({
          width: DEFAULT_WALLET_WIDTH_PX,
          height: DEFAULT_WALLET_HEIGHT_PX,
        })}
      </div>
    </>
  )
}

type WalletIFrameContainerProps = {
  children?: React.ReactNode
}

const WalletIFrameContainer = ({ children }: WalletIFrameContainerProps) => {
  const isMobile = useIsLessThanSm()

  return (
    <StyledIFrameContainer style={{ border: isMobile ? "none" : undefined }}>
      {children}
    </StyledIFrameContainer>
  )
}
