import React, { useEffect, useMemo, useState } from "react"
import { UnleashClient } from "unleash-proxy-client"
import { createContext } from "use-context-selector"
import { useMountEffect } from "@/hooks/useMountEffect"
import Wallet, { WalletAccount } from "@/lib/chain/wallet"
import {
  createUnleashConfig,
  getUnleashContext,
  IToggle,
} from "@/lib/feature-flags/unleash"

type FlagProviderProps = {
  wallet: Wallet
  toggles: IToggle[] | undefined
  sessionId: string | undefined
  deviceId: string
  children: React.ReactNode
}

type TogglesState = Record<string, IToggle | undefined>

type Context = {
  toggles: TogglesState
}

let clientToggles: TogglesState = {}

const getFlags = () => {
  if (typeof window === "undefined") {
    throw new Error("getFlags is only supported on client")
  }
  return clientToggles
}

// Check if a feature flag is enabled (without hooks).
// Note:
// - This should never be called within React components!! (it won't update/re-render on changes)
// - This should never be called on server!! (it will fail)
export const isFlagEnabled = (featureName: string): boolean => {
  const flags = getFlags()
  return flags[featureName]?.enabled ?? false
}

export const UnleashTogglesContext = createContext<Context>({
  toggles: {},
})

const mapToggles = (toggles: IToggle[]) =>
  toggles.reduce(
    (acc, toggle) => ({ ...acc, [toggle.name]: toggle }),
    {} as TogglesState,
  )

export let client: UnleashClient | undefined = undefined

const initClient = (
  initialToggles: IToggle[] | undefined,
  activeAccount: WalletAccount | undefined,
  sessionId: string | undefined,
  deviceId: string,
): UnleashClient => {
  const create = () => {
    return new UnleashClient(
      createUnleashConfig(
        initialToggles,
        getUnleashContext(activeAccount, sessionId, deviceId),
      ),
    )
  }
  if (typeof window === "undefined") {
    return create()
  }
  if (!client) {
    client = create()
    client.start()
  }
  return client
}

export const FlagProvider = ({
  toggles: initialToggles,
  sessionId,
  deviceId,
  wallet,
  children,
}: FlagProviderProps) => {
  const unleashClient = initClient(
    initialToggles,
    wallet.activeAccount,
    sessionId,
    deviceId,
  )

  const [toggles, setTogglesMap] = useState<TogglesState>(() =>
    mapToggles(initialToggles || []),
  )

  useMountEffect(() => {
    unleashClient.on("update", () => {
      setTogglesMap(mapToggles(unleashClient.getAllToggles()))
    })
  })

  useEffect(() => {
    return wallet.onChange(async wallet => {
      unleashClient.updateContext(
        getUnleashContext(wallet.activeAccount, sessionId, deviceId),
      )
    })
  }, [unleashClient, wallet, sessionId, deviceId])

  const value = useMemo(() => ({ toggles }), [toggles])
  clientToggles = toggles

  return (
    <UnleashTogglesContext.Provider value={value}>
      {children}
    </UnleashTogglesContext.Provider>
  )
}
