import { useCallback } from "react"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { useTrackingFn } from "@/lib/analytics/hooks/useTrackingFn"
import { useRefreshFundsMutation } from "@/lib/graphql/__generated__/useRefreshFundsMutation.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"

export const useRefreshFunds = () => {
  const t = useTranslate("components")
  const { mutate } = useGraphQL()
  const { attempt, showSuccessMessage } = useToasts()

  const trackRefreshFunds = useTrackingFn("refresh funds")
  const trackRefreshFundsError = useTrackingFn<{ message: string }>(
    "refresh funds error",
  )

  return useCallback(async () => {
    await attempt(
      async () => {
        trackRefreshFunds()
        await mutate<useRefreshFundsMutation>(
          graphql`
            mutation useRefreshFundsMutation {
              wallet {
                refreshFunds {
                  symbol
                  chain
                  quantity
                }
              }
            }
          `,
          {},
          {
            shouldAuthenticate: true,
          },
        )
        showSuccessMessage(
          t("fundsRefreshedMessage", "Funds successfully refreshed"),
        )
      },
      { onError: error => trackRefreshFundsError({ message: error.message }) },
    )
  }, [
    attempt,
    mutate,
    showSuccessMessage,
    t,
    trackRefreshFunds,
    trackRefreshFundsError,
  ])
}
