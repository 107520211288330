import { PublicKey } from "@solana/web3.js"
import EventEmitter from "eventemitter3"
import { noop } from "lodash"
import { WALLET_NAME } from "@/constants/wallet"
import type { WalletAccountKey } from "../chain"
import type { OnChainChangeHandler } from "../provider"
import { detectProvider } from "./detect"
import { SolanaProvider, SolanaWallet } from "./solanaProvider"

type PhantomSolanaWalletEvents = {
  connect(...args: unknown[]): unknown
  disconnect(...args: unknown[]): unknown
  accountChanged(key: PublicKey | null): unknown
}

type ConnectOpts = {
  onlyIfTrusted: boolean
}

type ConnectionResponse = {
  publicKey: PublicKey
}

export type PhantomSolanaWallet = SolanaWallet &
  EventEmitter<PhantomSolanaWalletEvents> & {
    isPhantom?: boolean
    connect: (opts?: Partial<ConnectOpts>) => Promise<ConnectionResponse>
    disconnect(): Promise<void>
    _handleDisconnect(...args: unknown[]): unknown
  }

class WalletError extends Error {
  public error?: Error

  constructor(message?: string, err?: Error) {
    super(message)
    this.error = err
  }
}

const detectPhantomSolanaProvider = (timeout = 3000) => {
  return detectProvider({
    timeout,
    key: "phantom.solana" as keyof Window,
    isInstalled: PhantomSolanaProvider.isInstalled,
  })
}

export class PhantomSolanaProvider extends SolanaProvider<PhantomSolanaWallet> {
  private _eagerConnectionInFlight?: Promise<WalletAccountKey[]> = undefined
  private _connectionInFlight?: Promise<WalletAccountKey[]> = undefined

  constructor(phantom: PhantomSolanaWallet) {
    super(phantom)
    this._eagerConnectionInFlight = this._wallet
      .connect({ onlyIfTrusted: true })
      .then(({ publicKey }) => this.mapToAccounts([publicKey]))
      .catch(() => {
        // NOTE(@laurafiuza): We were throwing a user facing Phantom 4001 RPC error.
        // This catches that error and fails silently.
        // See the "Eagerly connected" section below:
        // https://docs.phantom.app/integrating/extension-and-in-app-browser-web-apps/establishing-a-connection
        // Above article links out to the following code snippet I also used:
        // https://github.com/phantom-labs/sandbox/blob/b57fdd0e65ce4f01290141a01e33d17fd2f539b9/src/App.tsx#L95-L97
        return []
      })
      .finally(() => {
        this._eagerConnectionInFlight = undefined
      })
  }

  public static init = async () => {
    const solana = await detectPhantomSolanaProvider()
    if (this.isInstalled(solana)) {
      return new PhantomSolanaProvider(solana)
    }
    return undefined
  }

  public static isInstalled = (
    solana: PhantomSolanaWallet | undefined,
  ): solana is PhantomSolanaWallet => {
    return (solana && solana.isPhantom) ?? false
  }

  get connecting(): boolean {
    return this._connectionInFlight !== undefined
  }

  get connected(): boolean {
    return this._wallet.isConnected
  }

  connect = () => {
    if (this.connected) {
      return this.getAccounts()
    }
    if (this._eagerConnectionInFlight) {
      return this._eagerConnectionInFlight
    }
    if (this.connecting && this._connectionInFlight) {
      return this._connectionInFlight
    }

    try {
      // Phantom doesn't reject or emit an event if the popup is closed so we need
      // to promisify that ourselved
      const handleDisconnect = this._wallet._handleDisconnect
      try {
        this._connectionInFlight = new Promise<WalletAccountKey[]>(
          (resolve, reject) => {
            const connect = () => {
              this._wallet.off("connect", connect)
              resolve(this.getAccounts())
            }

            this._wallet._handleDisconnect = (...args: unknown[]) => {
              this._wallet.off("connect", connect)
              reject(new WalletError())
              return handleDisconnect.apply(this._wallet, args)
            }

            this._wallet.on("connect", connect)

            this._wallet.connect().catch(reason => {
              this._wallet.off("connect", connect)
              reject(reason)
            })
          },
        )
        return this._connectionInFlight
      } catch (error) {
        if (error instanceof WalletError) {
          throw error
        }
        throw new WalletError(error?.message, error)
      } finally {
        this._wallet._handleDisconnect = handleDisconnect
      }
    } finally {
      this._connectionInFlight = undefined
    }
  }

  disconnect = () => {
    return this._wallet.disconnect()
  }

  hasNoLockingConcept(): boolean {
    return true
  }

  getAccounts = async (): Promise<WalletAccountKey[]> => {
    if (this._eagerConnectionInFlight) {
      return this._eagerConnectionInFlight
    }
    if (this.connecting && this._connectionInFlight) {
      return this._connectionInFlight
    }
    if (!this.connected || !this._wallet.publicKey) {
      return []
    }
    return this.mapToAccounts([this._wallet.publicKey])
  }

  getName = (): WALLET_NAME => {
    return WALLET_NAME.Phantom
  }

  onAccountsChange = (_handler: (accounts: WalletAccountKey[]) => unknown) => {
    const handler = (publicKey: PublicKey | null) => {
      return _handler(this.mapToAccounts(publicKey ? [publicKey] : []))
    }

    this._wallet.on("accountChanged", handler)

    return () => {
      this._wallet.off("accountChanged", handler)
    }
  }

  onChainChange = (_handler: OnChainChangeHandler) => {
    return noop
  }
}
