import React, { useCallback } from "react"
import {
  CenterAligned,
  classNames,
  Flex,
  Icon,
  Item,
  List,
  Separator,
  Spinner,
  Text,
  twDataIdSelector,
} from "@opensea/ui-kit"
import { ConnectCompatibleWallet } from "@/components/nav/WalletSidebar/ConnectCompatibleWallet"
import { Modal } from "@/design-system/Modal"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { useOSWalletAuth } from "@/features/os-wallet/hooks/useOSWalletAuth"
import { useTranslate } from "@/hooks/useTranslate"
import { OSLoginClassic } from "./OSLoginClassic.react"
import { WalletEmailCodeModal } from "./WalletEmailCodeModal.react"
import { WalletModalHeader } from "./WalletModalHeader.react"

const DEFAULT_SHOWN_WALLET_COUNT = 3

export const WalletSelectionModalClassic = () => {
  const t = useTranslate("common")
  const { onNext } = useMultiStepFlowContext()
  const { sendLoginCode } = useOSWalletAuth()

  const handleOSWalletEmail = useCallback(
    async (email: string) => {
      try {
        await sendLoginCode(email)
        onNext(() => <WalletEmailCodeModal email={email} />)
      } catch (error) {
        return
      }
    },
    [onNext, sendLoginCode],
  )

  return (
    <Modal.Body
      className="relative max-h-[724px] rounded-[12px] [-webkit-overflow-scrolling:touch]"
      data-testid="wallet-modal"
      style={{ padding: 0 }}
    >
      <WalletModalHeader>
        <div className="mb-2 mt-6 w-full">
          <OSLoginClassic onEmailSubmit={handleOSWalletEmail} />
        </div>
      </WalletModalHeader>
      <Flex className="mt-[-15px] w-full items-center">
        <Separator className="flex-1" />
        <Text.Body
          className="flex-none rounded-lg border border-level-1 bg-elevation-1 p-2 py-1.5 uppercase opacity-80"
          color="secondary"
          size="tiny"
          weight="semibold"
        >
          {t("wallet.opensea.or", "Or")}
        </Text.Body>
        <Separator className="flex-1" />
      </Flex>
      <div className="mt-2 w-full px-2">
        <ConnectCompatibleWallet
          customHeader={<></>}
          defaultShownWalletCount={DEFAULT_SHOWN_WALLET_COUNT}
          renderList={wallets => (
            <List
              className={twDataIdSelector(
                Item,
                "[&_[data-id=Item]]:rounded-lg",
              )}
              showBorder={false}
              variant="framed"
            >
              {wallets.map(
                ({
                  walletName,
                  logo,
                  actionProps,
                  annotation,
                  supportedOnPlatform,
                  installingWallet,
                  notSupportedText,
                }) => (
                  <List.Item
                    className="rounded-lg"
                    key={walletName}
                    {...actionProps}
                  >
                    <List.Item.Avatar
                      alt={walletName}
                      size={30}
                      src={logo ?? ""}
                    />
                    <List.Item.Content>
                      <List.Item.Title weight="semibold">
                        {walletName}
                      </List.Item.Title>
                    </List.Item.Content>
                    {installingWallet !== walletName && annotation && (
                      <List.Item.Side>
                        <CenterAligned className="rounded-md bg-component-gray-1 p-2 capitalize">
                          <Text
                            className="text-secondary opacity-80"
                            size="tiny"
                            weight="semibold"
                          >
                            {annotation}
                          </Text>
                        </CenterAligned>
                      </List.Item.Side>
                    )}
                    {!supportedOnPlatform && (
                      <List.Item.Side>
                        <CenterAligned className="rounded-md bg-component-gray-1 p-2 capitalize">
                          <Text
                            className="text-secondary opacity-80"
                            size="tiny"
                            weight="semibold"
                          >
                            {notSupportedText}
                          </Text>
                        </CenterAligned>
                      </List.Item.Side>
                    )}

                    {walletName === installingWallet && (
                      <List.Item.Side className="ml-1">
                        <Spinner />
                      </List.Item.Side>
                    )}
                  </List.Item>
                ),
              )}
            </List>
          )}
          renderShowMore={({
            toggleShowMore,
            label,
            showingAll,
            ...moreProps
          }) => (
            <div
              className={classNames(
                "sticky bottom-0 w-full rounded-lg bg-elevation-2 pb-2",
                { relative: showingAll },
              )}
            >
              <Item
                className="rounded-lg bg-elevation-2 p-4 hover:bg-component-gray-1 active:bg-component-gray-1"
                variant="unstyled"
                onClick={toggleShowMore}
                {...moreProps}
              >
                <Item.Avatar
                  className="rounded-lg bg-component-gray-1"
                  size={30}
                >
                  <Icon size={24} value="more_horiz" />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title weight="semibold">{label}</Item.Title>
                </Item.Content>
              </Item>
            </div>
          )}
          source="wallet modal"
        />
      </div>
    </Modal.Body>
  )
}
