/**
 * @generated SignedSource<<1270ff53521af5d7f2add900499aff8a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useIsAvailableForBuyWithCard_data$data = {
  readonly fiatCheckoutAvailability: boolean | null;
  readonly " $fragmentType": "useIsAvailableForBuyWithCard_data";
};
export type useIsAvailableForBuyWithCard_data$key = {
  readonly " $data"?: useIsAvailableForBuyWithCard_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"useIsAvailableForBuyWithCard_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "listingId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useIsAvailableForBuyWithCard_data",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "listingId",
          "variableName": "listingId"
        }
      ],
      "kind": "ScalarField",
      "name": "fiatCheckoutAvailability",
      "storageKey": null
    }
  ],
  "type": "MoonpayType",
  "abstractKey": null
};

(node as any).hash = "f28441cb60113f8ec8ba84c1a2c7e744";

export default node;
