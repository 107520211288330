/**
 * @generated SignedSource<<70883927ef9622e2eaa51669e9ce0ba2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderList_errors$data = ReadonlyArray<{
  readonly originalOrder: {
    readonly relayId: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"OrderListItem_error">;
  readonly " $fragmentType": "OrderList_errors";
}>;
export type OrderList_errors$key = ReadonlyArray<{
  readonly " $data"?: OrderList_errors$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderList_errors">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "OrderList_errors",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderV2Type",
      "kind": "LinkedField",
      "name": "originalOrder",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "relayId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrderListItem_error"
    }
  ],
  "type": "BulkPurchaseErrorType",
  "abstractKey": null
};

(node as any).hash = "1ecc1d9409dd81945a7485effe30c055";

export default node;
