import React from "react"
import { Separator, Text, Skeleton } from "@opensea/ui-kit"
import styled from "styled-components"
import { Button } from "@/design-system/Button"
import { Modal, ModalBodyProps } from "@/design-system/Modal"
import { useTranslate } from "@/hooks/useTranslate"
import { useHandleBlockchainActionsCreateBulkOrderMutation$data } from "@/lib/graphql/__generated__/useHandleBlockchainActionsCreateBulkOrderMutation.graphql"
import { useHandleBlockchainActionsCreateOrderMutation$data } from "@/lib/graphql/__generated__/useHandleBlockchainActionsCreateOrderMutation.graphql"
import { useHandleBlockchainActionsCreateSwapOrderMutation$data } from "@/lib/graphql/__generated__/useHandleBlockchainActionsCreateSwapOrderMutation.graphql"
import { Transaction } from "./useHandleBlockchainActions"

export type BlockchainActionOnEnd = (data?: {
  transaction?: Transaction
  transactions?: Transaction[]
  createdOrder?: useHandleBlockchainActionsCreateOrderMutation$data["orders"]["create"]["order"]
  createdOrders?: useHandleBlockchainActionsCreateBulkOrderMutation$data["orders"]["create"]["orders"]
  createdSwapOrder?: useHandleBlockchainActionsCreateSwapOrderMutation$data["swaps"]["create"]
}) => unknown

export type BaseBlockchainActionProps = {
  title?: React.ReactNode
  onPrevious?: () => unknown
  onEnd: BlockchainActionOnEnd
  onNext?: BlockchainActionOnEnd
  onError?: (error: Error) => unknown
}

const BlockchainActionModalContentBodyText = styled(Text.Body).attrs({
  size: "medium",
})`
  display: block;
  margin-bottom: 24px;
`

const BlockchainActionModalContentBody = (props: ModalBodyProps) => {
  return <Modal.Body {...props} />
}

const BlockchainActionModalContentBodyGoToWallet = () => {
  const t = useTranslate("components")

  return (
    <Text.Body asChild className="mb-1" size="medium" weight="semibold">
      <div>{t("blockchain.goToWallet", "Go to your wallet")}</div>
    </Text.Body>
  )
}

BlockchainActionModalContentBody.Text = BlockchainActionModalContentBodyText
BlockchainActionModalContentBody.GoToWallet =
  BlockchainActionModalContentBodyGoToWallet
BlockchainActionModalContentBody.Button = Button

const BlockchainModalSeparator = () => {
  return <Separator className="my-6" />
}

BlockchainActionModalContentBody.Separator = BlockchainModalSeparator

export const BlockchainActionModalContent = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return <>{children}</>
}

BlockchainActionModalContent.Header = Modal.Header
BlockchainActionModalContent.Title = Modal.Title
BlockchainActionModalContent.Body = BlockchainActionModalContentBody

const BlockchainActionModalContentSkeleton = () => (
  <BlockchainActionModalContent>
    <BlockchainActionModalContent.Header>
      <Skeleton className="items-center">
        <Skeleton.Line className="h-[22px] w-1/2" />
      </Skeleton>
    </BlockchainActionModalContent.Header>
    <BlockchainActionModalContent.Body>
      <BlockchainActionModalContent.Body.Text>
        <Skeleton>
          <Skeleton.Line className="w-1/4" />
          <Skeleton.Line />
          <Skeleton.Line className="w-1/2" />
        </Skeleton>
      </BlockchainActionModalContent.Body.Text>
    </BlockchainActionModalContent.Body>
  </BlockchainActionModalContent>
)

BlockchainActionModalContent.Skeleton = BlockchainActionModalContentSkeleton
