import { useRef } from "react"
import {
  Disposable,
  GraphQLTaggedNode,
  OperationType,
  VariablesOf,
} from "relay-runtime"
import { fetch } from "@/lib/graphql/graphql"
import { Promiseable } from "@/lib/helpers/promise"
import { useMountEffect } from "./useMountEffect"
import { usePageVisibility } from "./usePageVisibility"

const DEFAULT_DELAY = 15_000 // 15 seconds

type UsePollingQueryOptions<TQuery extends OperationType> = {
  delay?: number
  skipOnHidden?: boolean
  skip?: boolean
  onPoll?: (response: TQuery["response"]) => Promiseable<unknown>
}

export const usePollingQuery = <TQuery extends OperationType>(
  gqlQuery: GraphQLTaggedNode,
  variables?: VariablesOf<TQuery>,
  {
    delay = DEFAULT_DELAY,
    skipOnHidden = false,
    skip: skipProp = false,
    onPoll,
  }: UsePollingQueryOptions<TQuery> = {},
) => {
  const variablesRef = useRef(variables)
  variablesRef.current = variables

  const onPollRef = useRef(onPoll)
  onPollRef.current = onPoll
  const isPageVisible = usePageVisibility()

  const skip = (!isPageVisible && skipOnHidden) || skipProp
  const skipRef = useRef(skip)
  skipRef.current = skip

  useMountEffect(() => {
    let shouldContinuePolling = true
    let currentTimeout: NodeJS.Timeout | undefined = undefined
    const disposables: Disposable[] = []

    const poll = async () => {
      if (skipRef.current || !variablesRef.current) {
        currentTimeout = setTimeout(poll, delay)
        return
      }
      const [newData, disposable] = await fetch<TQuery>(
        gqlQuery,
        variablesRef.current,
        undefined,
        undefined,
        { retain: true },
      )

      // cleanup disposable if component was unmounted during fetching data
      if (!shouldContinuePolling) {
        disposable?.dispose()
        return
      }

      if (disposable) {
        disposables.push(disposable)
      }
      await onPollRef.current?.(newData)
      currentTimeout = setTimeout(poll, delay)
    }

    currentTimeout = setTimeout(poll, delay)

    return () => {
      // stop polling
      shouldContinuePolling = false
      currentTimeout && clearTimeout(currentTimeout)
      // cleanup disposables
      disposables.forEach(disposable => disposable.dispose())
    }
  })
}
