import React from "react"
import {
  Icon,
  MaterialIcon,
  UnstyledButton,
  Text,
  Container,
  CenterAligned,
  useIsLessThanMd,
  Flex,
} from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import styled from "styled-components"
import { Link } from "@/components/common/Link"
import { navBannerTransition } from "@/components/nav/Navbar/Navbar.react"
import {
  NewFeatureAnnouncementBanner_data$data,
  NewFeatureAnnouncementBanner_data$key,
} from "@/lib/graphql/__generated__/NewFeatureAnnouncementBanner_data.graphql"
import { MAX_BANNER_HEIGHT } from "../utils/context"
import { EVENT_SOURCE } from "./AnnouncementBanner.react"

interface NewFeatureAnnouncementBannerProps {
  data: NewFeatureAnnouncementBanner_data$key
  onClose: () => void
}

export const NewFeatureAnnouncementBanner = ({
  data: dataKey,
  onClose,
}: NewFeatureAnnouncementBannerProps) => {
  const data = useFragment(
    graphql`
      fragment NewFeatureAnnouncementBanner_data on AnnouncementBannerType {
        text
        url
        heading
        headingMobile
        ctaText
        isClosable
        icon
      }
    `,
    dataKey,
  )

  return <NewFeatureAnnouncementBannerDisplay {...data} onClose={onClose} />
}

export const NewFeatureAnnouncementBannerDisplay = ({
  heading,
  headingMobile,
  text,
  url,
  ctaText,
  icon,
  isClosable,
  onClose,
  onClick,
}: Omit<NewFeatureAnnouncementBanner_data$data, " $fragmentType"> & {
  onClose?: () => unknown
  onClick?: () => unknown
}) => {
  const ContentWrappedWithUrl = ({ content }: { content: JSX.Element }) => {
    return (
      <Link eventSource={EVENT_SOURCE} href={url ?? undefined}>
        {content}
      </Link>
    )
  }

  const isSmall = useIsLessThanMd()
  const textContent = (
    <Flex className="py-3">
      <Flex className="items-center py-1">
        <p className="flex items-center gap-2">
          <StyledText color="white" weight="semibold">
            {isSmall ? headingMobile : heading}
          </StyledText>
          <StyledText className="ml-2 !font-normal" color="white">
            {text} <b className="underline">{ctaText}</b>
          </StyledText>
        </p>
      </Flex>
    </Flex>
  )

  return (
    <BannerContainer style={{ maxHeight: MAX_BANNER_HEIGHT }} onClick={onClick}>
      <Flex className="w-full items-center justify-between">
        <Flex className="w-full md:place-items-center md:justify-center">
          <Flex className="gap-2 md:place-items-center">
            <ContentWrappedWithUrl content={textContent} />
            {icon && (
              <CenterAligned>
                <Icon color="white" value={icon as MaterialIcon}></Icon>
              </CenterAligned>
            )}
          </Flex>
        </Flex>
        <CenterAligned className="ml-3 mr-0 xl:mr-2">
          {isClosable && (
            <UnstyledButton onClick={onClose}>
              <Icon color="white" value="close" />
            </UnstyledButton>
          )}
        </CenterAligned>
      </Flex>
    </BannerContainer>
  )
}

const BannerContainer = styled(Container).attrs({ role: "banner" })`
  ${navBannerTransition}
  display: flex;
  align-items: center;
  top: 0px;
  width: 100%;
  background: linear-gradient(90deg, #2081e2 0.5%, #5d32e9 99.74%);
  border-bottom: 1px solid
    ${({ theme }) => theme.colors.components.border.level1};
`

const StyledText = styled(Text).attrs({
  size: "small",
  weight: "semibold",
})`
  display: contents;
  max-height: ${MAX_BANNER_HEIGHT}px;
  overflow-y: hidden;
`
