import {
  PublicKey,
  Connection,
  Transaction as SolanaTransaction,
  Message,
  SendOptions,
  RpcResponseAndContext,
  SignatureStatus,
} from "@solana/web3.js"
import { Buffer as SafeBuffer } from "safe-buffer"
import { SOLANA_RPC_URL } from "@/constants/rpc"
import { IS_TESTNET } from "@/constants/testnet"
import type { Promiseable } from "@/lib/helpers/promise"
import type { WalletAccountKey } from "../chain"
import Provider, { Transaction, TransactionResponse } from "../provider"
import type { SignOptions } from "../wallet"

const SolanaErrorCodeMap: Record<string, string> = {
  "4001": "The User rejected the request.",
  "4100":
    "The requested method and/or account has not been authorized by the user.",
  "-32603": "Something went wrong with your Solana provider",
  "-32000": "Missing or invalid parameters",
  "-32601": "Solana provider does not recognize the method",
}

export type SolanaWallet = {
  isConnected: boolean
  publicKey?: PublicKey
  signTransaction(transaction: SolanaTransaction): Promise<SolanaTransaction>
  signAllTransactions(
    transactions: SolanaTransaction[],
  ): Promise<SolanaTransaction[]>
  signMessage(message: Uint8Array): Promise<{ signature: Uint8Array }>
}

function hexToBytes(hex: string) {
  const bytes = []
  for (let c = 0; c < hex.length; c += 2) {
    bytes.push(parseInt(hex.substr(c, 2), 16))
  }
  return bytes
}

export abstract class SolanaProvider<W extends SolanaWallet> extends Provider {
  protected readonly _wallet: W
  private connection: Connection | undefined

  public constructor(wallet: W) {
    super()
    this._wallet = wallet
  }

  public getChain = () => {
    return IS_TESTNET ? "SOLDEV" : "SOLANA"
  }

  public getChainId = () => {
    return undefined
  }

  protected mapToAccounts = (publicKeys: PublicKey[]): WalletAccountKey[] => {
    const chain = this.getChain()
    const walletName = this.getName()
    const walletMetadata = this.getMetadata()
    return publicKeys.map(publicKey => ({
      address: publicKey.toString(),
      chain,
      walletName,
      walletMetadata,
    }))
  }

  private signPersonalMessage = async (message: string) => {
    try {
      const result = await this._wallet.signMessage(
        new TextEncoder().encode(message),
      )
      return Buffer.from(result.signature).toString("base64")
    } catch (error) {
      if (error.code in SolanaErrorCodeMap) {
        throw new Error(error.message)
      }
      throw new Error("A transaction error occurred")
    }
  }

  private signSolanaMessage = async (message: string | SafeBuffer) => {
    if (Buffer.isBuffer(message)) {
      throw new Error("Wrong Message Data Type")
    }
    const buf = hexToBytes(message as string)
    const transaction = SolanaTransaction.from(buf as unknown as Uint8Array)
    try {
      const signedTransaction = await this._wallet.signTransaction(transaction)
      const signature = signedTransaction.signatures.find(
        sig => sig.publicKey.toBase58() === this._wallet.publicKey?.toBase58(),
      )
      if (!signature?.signature) {
        throw new Error("No matching signature")
      }
      return Buffer.from(signature.signature).toString("hex")
    } catch (error) {
      if (error.code in SolanaErrorCodeMap) {
        throw new Error(error.message)
      }
      console.error(error)
      throw new Error("A transaction error occurred")
    }
  }

  sign = async (
    message: string | SafeBuffer,
    _address: string,
    options?: SignOptions,
  ) => {
    switch (options?.clientSignatureStandard) {
      case undefined:
      case "PERSONAL":
        if (typeof message !== "string") {
          throw new Error("Expected a string like personal message")
        }
        return this.signPersonalMessage(message)
      case "SOLANA":
        return this.signSolanaMessage(message)
      default:
        throw new Error(
          `Unsupported Client Signature Standard: ${options?.clientSignatureStandard}`,
        )
    }
  }

  signTypedData = (
    message: string | SafeBuffer,
    address: string,
    options?: SignOptions,
  ): Promiseable<string> => {
    return this.sign(message, address, options)
  }

  private getConnection = (): Connection => {
    if (!this.connection) {
      this.connection = new Connection(SOLANA_RPC_URL)
    }
    return this.connection
  }

  public buildTransactionFromData = (data: string): SolanaTransaction => {
    const buffer = hexToBytes(data)
    try {
      return SolanaTransaction.from(buffer)
    } catch {
      try {
        const message = Message.from(buffer)
        return SolanaTransaction.populate(message)
      } catch (error) {
        throw new Error("Failed to create transaction from data!")
      }
    }
  }

  async transact({ data }: Transaction): Promise<TransactionResponse> {
    if (data) {
      const transaction = this.buildTransactionFromData(data)
      const connection = this.getConnection()
      // we skip preflight since this is already simulated by the wallet
      const sendOptions: SendOptions = { skipPreflight: true }
      const signedTransaction = await this._wallet.signTransaction(transaction)
      const sendTransaction = () =>
        connection.sendRawTransaction(
          signedTransaction.serialize(),
          sendOptions,
        )
      const intervalId = setInterval(async () => {
        try {
          const transactionHash = await sendTransaction()
          const transaction = await this.getSignatureStatus(transactionHash)
          if (!this.transactionIsPending(transaction)) {
            clearInterval(intervalId)
          }
        } catch (e) {
          clearInterval(intervalId)
        }
      }, 3000)
      return sendTransaction().then(hash => {
        return {
          hash,
        }
      })
    }

    throw Error("Tried to do transact with no data")
  }

  public async estimateGas(): Promise<string> {
    throw Error("Not implemented")
  }

  public async getNativeCurrencyBalance(address: string): Promise<string> {
    const connection = this.getConnection()
    const balance = await connection.getBalance(new PublicKey(address))
    return balance.toString()
  }

  async getSignatureStatus(
    signature: string,
  ): Promise<RpcResponseAndContext<SignatureStatus | null>> {
    const connection = this.getConnection()
    return connection.getSignatureStatus(signature)
  }

  transactionIsPending(
    transaction: RpcResponseAndContext<SignatureStatus | null>,
  ): boolean {
    const transactionHasError = transaction.value?.err
    const transactionStatus = transaction.value?.confirmationStatus
    const transactionIsFinalized = transactionStatus === "finalized"
    const transactionIsConfirmed = transactionStatus === "confirmed"
    return !(
      transactionHasError ||
      transactionIsFinalized ||
      transactionIsConfirmed
    )
  }

  getTransactionCount = (_address: string): Promiseable<number> => {
    // Unimplemented
    return 0
  }
}
