import styled from "styled-components"
import { List } from "@/design-system/List"
import { Tooltip } from "@/design-system/Tooltip"

export const StyledList = styled(List)`
  background-color: transparent;
`

export const StyledListItem = styled(List.Item)`
  padding-top: 0;
  padding-bottom: 0;
  height: 56px;
`

export const WalletPopoverTooltip = styled(Tooltip)`
  padding: 12px 20px;
`
