/**
 * @generated SignedSource<<524d2bb378e1ecb869a96e1ce156d214>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionDetailsContent_contractdata$data = {
  readonly address: string;
  readonly blockExplorerLink: string;
  readonly " $fragmentType": "CollectionDetailsContent_contractdata";
};
export type CollectionDetailsContent_contractdata$key = {
  readonly " $data"?: CollectionDetailsContent_contractdata$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionDetailsContent_contractdata">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionDetailsContent_contractdata",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blockExplorerLink",
      "storageKey": null
    }
  ],
  "type": "AssetContractType",
  "abstractKey": null
};

(node as any).hash = "019e28211df0b2b087d554e26dcab096";

export default node;
