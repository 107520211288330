/**
 * @generated SignedSource<<a9877519a2d60fdd9880efa41815a87b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type authRefreshMutation$variables = {
  address: string;
  deviceId: string;
};
export type authRefreshMutation$data = {
  readonly AuthTypeV2: {
    readonly webRefresh: {
      readonly isEmployee: boolean;
    } | null;
  };
};
export type authRefreshMutation = {
  response: authRefreshMutation$data;
  variables: authRefreshMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "address"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuthTypeV2",
    "kind": "LinkedField",
    "name": "AuthTypeV2",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "address",
            "variableName": "address"
          },
          {
            "kind": "Variable",
            "name": "deviceId",
            "variableName": "deviceId"
          }
        ],
        "concreteType": "WebTokenAuth",
        "kind": "LinkedField",
        "name": "webRefresh",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isEmployee",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "authRefreshMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "authRefreshMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ed428656be4e79fb445f0a2839e0a74a",
    "id": null,
    "metadata": {},
    "name": "authRefreshMutation",
    "operationKind": "mutation",
    "text": "mutation authRefreshMutation(\n  $address: AddressScalar!\n  $deviceId: String!\n) {\n  AuthTypeV2 {\n    webRefresh(address: $address, deviceId: $deviceId) {\n      isEmployee\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6f5a89955fb07fe577365c02fae722b3";

export default node;
