import React, { Suspense, useEffect, useMemo, useRef } from "react"
import {
  useIsHydrated,
  CenterAligned,
  Flex,
  Icon,
  MaterialIcon,
  Media,
  Text,
  UnstyledButton,
  classNames,
} from "@opensea/ui-kit"
import { ErrorBoundary } from "@sentry/nextjs"
import { graphql, useLazyLoadQuery } from "react-relay"
import styled from "styled-components"
import { variant } from "styled-system"
import { Link } from "@/components/common/Link"
import { Z_INDEX } from "@/constants/zIndex"
import { Block } from "@/design-system/Block"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { useSize } from "@/hooks/useSize"
import {
  AnnouncementBannerQuery,
  AnnouncementBannerVariant,
} from "@/lib/graphql/__generated__/AnnouncementBannerQuery.graphql"
import { hideAnnouncementPersist, isAnnouncementBannerHidden } from "../utils"
import { trackCloseAnnouncementBanner } from "../utils/analytics"
import { useAnnouncementBanner } from "../utils/context"
import { NewFeatureAnnouncementBanner } from "./NewFeatureAnnouncementBanner.react"

export const EVENT_SOURCE = "AnnouncementBanner"
const DEFAULT_BANNER_BACKGROUND =
  "linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)"

export type AnnouncementBannerProps =
  | {
      displayContext?: "ALL_PAGES" | "HOMEPAGE_ONLY" | "NONE"
      allowSticky?: boolean
    }
  | {
      displayContext: "CHAIN"
      chain: ChainIdentifier
      allowSticky?: boolean
    }

const LazyAnnouncementBanner = ({
  displayContext = "ALL_PAGES",
  allowSticky,
  ...rest
}: AnnouncementBannerProps) => {
  const announcementBannerRef = useRef<HTMLDivElement>(null)
  const {
    setAnnouncementBannerHeight,
    setIsAnnouncementBannerShown,
    setIsAnnouncementBannerSticky,
    isAnnouncementBannerSticky,
    isAnnouncementBannerShown,
  } = useAnnouncementBanner()

  const [_, bannerHeight] = useSize(announcementBannerRef, {
    initialHeight: 0,
    initialWidth: 0,
  })

  useEffect(() => {
    if (bannerHeight) {
      setAnnouncementBannerHeight(bannerHeight)
    }
  }, [bannerHeight, setAnnouncementBannerHeight])

  const data = useLazyLoadQuery<AnnouncementBannerQuery>(
    graphql`
      query AnnouncementBannerQuery {
        announcementBanner {
          relayId
          text
          url
          heading
          headingMobile
          ctaText
          chain {
            identifier
          }
          displayMode
          variant
          isClosable
          icon
          leftAlign
          ...NewFeatureAnnouncementBanner_data
        }
      }
    `,
    {},
  )
  const isManuallyHidden = data.announcementBanner
    ? isAnnouncementBannerHidden(data.announcementBanner.relayId)
    : false

  const chain = "chain" in rest ? rest.chain : undefined

  const canShowAnnouncementBanner = useMemo(() => {
    if (!data.announcementBanner) {
      return false
    }

    if (isManuallyHidden) {
      return false
    }

    if (displayContext === "NONE") {
      return false
    }

    if (data.announcementBanner.displayMode === "ALL_PAGES") {
      return true
    }

    if (data.announcementBanner.displayMode !== displayContext) {
      return false
    }

    if (
      data.announcementBanner.displayMode === "CHAIN" &&
      data.announcementBanner.chain?.identifier !== chain
    ) {
      return false
    }

    return true
  }, [chain, data.announcementBanner, displayContext, isManuallyHidden])

  useEffect(() => {
    if (!canShowAnnouncementBanner) {
      setIsAnnouncementBannerShown(false)
    } else {
      if (!isAnnouncementBannerShown && !isManuallyHidden) {
        setIsAnnouncementBannerShown(true)
      }
    }
  }, [
    canShowAnnouncementBanner,
    isAnnouncementBannerShown,
    isManuallyHidden,
    setIsAnnouncementBannerShown,
  ])

  const { announcementBanner } = data

  useEffect(() => {
    const isSticky =
      allowSticky && announcementBanner?.variant === "NEW_FEATURE"

    setIsAnnouncementBannerSticky(!!isSticky)
  }, [allowSticky, announcementBanner?.variant, setIsAnnouncementBannerSticky])

  if (!announcementBanner) {
    return null
  }

  const {
    heading,
    headingMobile,
    text,
    url,
    ctaText,
    relayId,
    icon,
    variant,
    isClosable,
    leftAlign,
  } = announcementBanner

  const handleOnClose = () => {
    hideAnnouncementPersist(relayId)
    trackCloseAnnouncementBanner({ text })
    setIsAnnouncementBannerShown(false)
  }

  if (!canShowAnnouncementBanner) {
    return null
  }

  const textContent = (
    <Flex className={classNames("w-full", !leftAlign && "justify-center")}>
      <Media greaterThanOrEqual="sm">
        <Block paddingX="20px">
          <Text asChild className="mt-2.5 text-white" weight="semibold">
            <p>{heading}</p>
          </Text>
          <Text asChild className="mb-2.5 text-white">
            <p>{text}</p>
          </Text>
        </Block>
      </Media>

      <Media lessThan="sm">
        {mediaClassNames => (
          <span className={mediaClassNames}>
            {headingMobile && (
              <Text asChild className="ml-2" color="white" weight="semibold">
                <p>{headingMobile}</p>
              </Text>
            )}
            <Block marginLeft="8px">{text}</Block>
          </span>
        )}
      </Media>
    </Flex>
  )

  const ctaTextContent = (
    <Text
      asChild
      className="ml-2 w-[228px] px-5 text-right text-white underline"
    >
      <p>{ctaText}</p>
    </Text>
  )

  const ContentWrappedWithUrl = ({ content }: { content: JSX.Element }) => {
    return (
      <Link
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className="AnnouncementBanner--link-container"
        eventSource={EVENT_SOURCE}
        href={url ?? undefined}
      >
        {content}
      </Link>
    )
  }

  return (
    <header
      ref={announcementBannerRef}
      style={{
        zIndex: Z_INDEX.NAVBAR,
        ...(isAnnouncementBannerSticky ? { top: 0, position: "sticky" } : {}),
      }}
    >
      {variant === "NEW_FEATURE" ? (
        <NewFeatureAnnouncementBanner
          data={announcementBanner}
          onClose={handleOnClose}
        />
      ) : (
        <DivContainer variant={variant}>
          {icon && (
            <CenterAligned className="ml-4 sm:ml-8">
              <Icon value={icon as MaterialIcon} />
            </CenterAligned>
          )}
          <ContentWrappedWithUrl content={textContent} />
          {ctaText ? (
            <Media greaterThanOrEqual="sm">
              <ContentWrappedWithUrl content={ctaTextContent} />
            </Media>
          ) : null}
          {isClosable && (
            <CenterAligned className="mr-5">
              <UnstyledButton onClick={handleOnClose}>
                <Icon value="close" />
              </UnstyledButton>
            </CenterAligned>
          )}
        </DivContainer>
      )}
    </header>
  )
}

export const AnnouncementBanner = (props: AnnouncementBannerProps) => {
  const isHydrated = useIsHydrated()

  if (!isHydrated) {
    return null
  }

  return (
    <ErrorBoundary fallback={<></>}>
      <Suspense fallback={null}>
        <LazyAnnouncementBanner {...props} />
      </Suspense>
    </ErrorBoundary>
  )
}

const DivContainer = styled.div.attrs({ role: "banner" })<{
  variant: AnnouncementBannerVariant | null
}>`
  display: flex;
  align-items: center;
  top: 0;
  transition: top 0.5s;
  width: 100%;
  ${props =>
    variant({
      prop: "variant",
      variants: {
        ["DEFAULT"]: {
          background: DEFAULT_BANNER_BACKGROUND,
        },
        ["%future added value"]: {
          background: DEFAULT_BANNER_BACKGROUND,
        },
        ["DANGER"]: {
          background: props.theme.colors.coral,
        },
        ["WARNING"]: {
          background: props.theme.colors.seaHorse,
        },
      },
    })}
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .AnnouncementBanner--link-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 8px;
  }

  .AnnouncementBanner--alert {
    margin-left: 8px;
    margin-right: 4px;
  }

  .AnnouncementBanner--underlined {
    text-decoration: underline;
    margin-left: 8px;
  }
`
