import { graphql, readInlineData } from "relay-runtime"
import {
  OrderSide,
  OrderTypeEnum,
} from "@/lib/graphql/__generated__/OrderType.graphql"
import { readGaslessCancelResult_status$key } from "@/lib/graphql/__generated__/readGaslessCancelResult_status.graphql"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"
import { getResultFromStatuses } from "./getResultFromStatuses"

export type GaslessCancelStatus = {
  isFailed: boolean
  isFulfilled: boolean
  isFinalized: boolean
  fulfillmentPossibleUntilChainTimestamp: string | null
  quantityCancelled: BigNumber
  remainingQuantity: BigNumber
  orderRelayId: string
  orderSide: OrderSide
  orderType: OrderTypeEnum
}

export type GaslessCancelResult = {
  ordersAwaitingFinalization: Set<string>
  unfinalizedOrders: Set<string>
  // Count refers to orders
  failedCount: number
  fulfilledCount: number
  // Quantity refers to order item quantity
  canceledQuantity: BigNumber
  remainingQuantity: BigNumber
  latestFulfillmentPossibleUntilChainTimestamp: string | null
  statuses: Record<string, GaslessCancelStatus | undefined>
  ordersType: OrderTypeEnum | undefined
  ordersSide: OrderSide | undefined
}

export const readGaslessCancelResult = (
  statusDataKeys: readonly readGaslessCancelResult_status$key[],
): GaslessCancelResult => {
  const data = statusDataKeys.map(statusDataKey =>
    readInlineData(
      graphql`
        fragment readGaslessCancelResult_status on OrderGaslessCancelStatusType
        @inline {
          fulfillmentPossibleUntilChainTimestamp
          isFinalized
          quantityCancelled
          order {
            relayId
            orderType
            side
            remainingQuantityType
          }
        }
      `,
      statusDataKey,
    ),
  )

  const statuses: GaslessCancelStatus[] = data.map(status => {
    const isUnitiated =
      !status.isFinalized && !status.fulfillmentPossibleUntilChainTimestamp
    const isFulfilled =
      status.isFinalized && bn(status.quantityCancelled).isZero()
    return {
      fulfillmentPossibleUntilChainTimestamp:
        status.fulfillmentPossibleUntilChainTimestamp,
      isFinalized: status.isFinalized,
      isFailed: isUnitiated || isFulfilled,
      isFulfilled,
      quantityCancelled: bn(status.quantityCancelled),
      remainingQuantity: bn(status.order.remainingQuantityType),
      orderRelayId: status.order.relayId,
      orderSide: status.order.side ?? "ASK",
      orderType: status.order.orderType,
    }
  })

  return getResultFromStatuses(statuses)
}
