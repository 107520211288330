import { add } from "date-fns"
import Cookie from "@/lib/cookie"

const OS2_UPSELL_MODAL_COOKIE_KEY = "has_seen_os2_upsell_modal"

const getOs2UpsellModalCookie = (): Cookie<boolean | undefined> => {
  return new Cookie<boolean | undefined>(OS2_UPSELL_MODAL_COOKIE_KEY)
}

export const getHasSeenOs2UpsellModalCookie = (): boolean => {
  return getOs2UpsellModalCookie().get() ?? false
}

export const setOs2UpsellModalCookie = (value: boolean): void => {
  const expires = add(new Date(), { days: 14 })
  getOs2UpsellModalCookie().set(value, {
    expires,
    secure: true,
    sameSite: "Lax",
  })
}
