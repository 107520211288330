import React, { forwardRef } from "react"
import { Skeleton, SkeletonProps } from "@opensea/ui-kit"
import styled from "styled-components"
import { AvatarProps } from "../Avatar"
import { Item, ItemProps } from "../Item"

const ItemSkeletonBase = styled(Item)`` as React.ComponentType<ItemProps>

const ItemSkeletonContent = styled(Item.Content)``

const ItemSkeletonSide = styled(Item.Side)`
  width: 50%;
  align-items: flex-end;
`

const ItemSkeletonAvatar = forwardRef<
  HTMLDivElement,
  Omit<AvatarProps, "variant">
>(function ItemSkeletonAvatar({ size, ...rest }, ref) {
  return (
    <Skeleton.Circle
      ref={ref}
      style={{ height: size, width: size }}
      {...rest}
    />
  )
})

const ItemSkeletonAction = (props: SkeletonProps) => {
  return (
    <Item.Action>
      <Skeleton.Circle {...props} />
    </Item.Action>
  )
}
const ItemSkeletonTitle = styled(Skeleton.Line)<SkeletonProps>`
  margin-bottom: 3px;
  width: 50%;
  max-width: 180px;
`

const ItemSkeletonDescription = styled(Skeleton.Line)<SkeletonProps>`
  width: 35%;
  max-width: 150px;
  height: 14px;
  margin-top: 3px;
`

export const ItemSkeleton = Object.assign(ItemSkeletonBase, {
  Content: ItemSkeletonContent,
  Title: ItemSkeletonTitle,
  Description: ItemSkeletonDescription,
  Side: ItemSkeletonSide,
  Avatar: ItemSkeletonAvatar,
  Action: ItemSkeletonAction,
})
