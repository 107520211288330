import React from "react"
import { UnstyledButton, Text, Flex } from "@opensea/ui-kit"
import { rgba } from "polished"
import styled, { css } from "styled-components"
import { Link } from "@/components/common/Link"
import { useIsMintYourOwnEnabled } from "@/hooks/useFlag"
import { useIsBanned } from "@/hooks/useIsBanned"
import { useTranslate } from "@/hooks/useTranslate"
import { useStatLinks, useDropLinks } from "@/lib/helpers/links"
import { HUES } from "@/styles/themes"
import { NavbarDropdown } from "./NavbarDropdown.react"

type NavbarDropdownsProps = {
  isBackgroundTransparent: boolean
}

export const NavbarDropdowns = ({
  isBackgroundTransparent,
}: NavbarDropdownsProps) => {
  const t = useTranslate("components")
  const statLinks = useStatLinks()
  const dropLinks = useDropLinks()
  const isActiveAccountBanned = useIsBanned()
  const isMintYourOwnEnabled = useIsMintYourOwnEnabled()
  if (isActiveAccountBanned) {
    return null
  }

  return (
    <Flex className="items-center gap-8">
      <NavbarDropdown items={dropLinks}>
        <NavbarDropdownButton
          $isBackgroundTransparent={isBackgroundTransparent}
          href="/drops"
        >
          <Text.Body weight="semibold">{t("nav.drops_v2", "Drops")}</Text.Body>
        </NavbarDropdownButton>
      </NavbarDropdown>
      <NavbarDropdown items={statLinks}>
        <NavbarDropdownButton
          $isBackgroundTransparent={isBackgroundTransparent}
          href="/rankings"
        >
          <Text.Body weight="semibold">{t("nav.stats", "Stats")}</Text.Body>
        </NavbarDropdownButton>
      </NavbarDropdown>
      {isMintYourOwnEnabled && (
        <NavbarDropdownButton
          $isBackgroundTransparent={isBackgroundTransparent}
          href="/studio/create?ref=nav"
        >
          <Text.Body weight="semibold">{t("nav.create", "Create")}</Text.Body>
        </NavbarDropdownButton>
      )}
    </Flex>
  )
}

type NavbarDropdownButtonProps = {
  href: string
  $isBackgroundTransparent: boolean
}

const NavbarDropdownButton = styled(UnstyledButton).attrs({
  as: Link,
})<NavbarDropdownButtonProps>`
  white-space: nowrap;

  ${props =>
    props.$isBackgroundTransparent
      ? css`
          background-color: transparent;
          color: ${HUES.white};

          :hover,
          :focus {
            color: ${rgba(HUES.white, 0.8)};
          }
          :active {
            color: ${rgba(HUES.white, 0.6)};
          }
        `
      : css`
          color: ${props.theme.colors.text.primary};

          :hover,
          :focus {
            color: ${props => rgba(props.theme.colors.text.primary, 0.8)};
          }
          :active {
            color: ${props => rgba(props.theme.colors.text.primary, 0.6)};
          }
        `}
`
