/**
 * @generated SignedSource<<501b9f1404a27438422083bd8910e511>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ShoppingCartContextProvider_inline_order$data = {
  readonly item: {
    readonly assetQuantities?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly asset: {
            readonly relayId: string;
          };
        } | null;
      } | null>;
    };
    readonly chain: {
      readonly identifier: ChainIdentifier;
    };
    readonly relayId: string;
  };
  readonly maker: {
    readonly relayId: string;
  };
  readonly makerOwnedQuantity: string;
  readonly payment: {
    readonly relayId: string;
  };
  readonly priceType: {
    readonly usd: string;
  };
  readonly relayId: string;
  readonly remainingQuantityType: string;
  readonly taker: {
    readonly address: string;
    readonly " $fragmentSpreads": FragmentRefs<"wallet_accountKey">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ShoppingCart_orders" | "useTotalItems_orders">;
  readonly " $fragmentType": "ShoppingCartContextProvider_inline_order";
};
export type ShoppingCartContextProvider_inline_order$key = {
  readonly " $data"?: ShoppingCartContextProvider_inline_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShoppingCartContextProvider_inline_order">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ShoppingCartContextProvider_inline_order"
};

(node as any).hash = "88155fa0e772a76ca272663379b4dac0";

export default node;
