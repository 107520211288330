import React, { useState } from "react"
import dynamic from "next/dynamic"
import { graphql, useFragment } from "react-relay"
import {
  BlockchainActionList_data$data,
  BlockchainActionList_data$key,
} from "@/lib/graphql/__generated__/BlockchainActionList_data.graphql"
import { UnreachableCaseError } from "@/lib/helpers/type"
import { AskForDepositAction } from "./AskForDepositAction.react"
import { AskForSwapAction } from "./AskForSwapAction.react"
import { AssetApprovalAction } from "./AssetApprovalAction.react"
import { AssetBurnToRedeemAction } from "./AssetBurnToRedeemAction.react"
import { AssetSwapAction } from "./AssetSwapAction.react"
import { AssetTransferAction } from "./AssetTransferAction.react"
import {
  BaseBlockchainActionProps,
  BlockchainActionModalContent,
  BlockchainActionOnEnd,
} from "./BlockchainActionModalContent.react"
import { BulkAcceptOffersAction } from "./BulkAcceptOffersAction.react"
import { BulkFulfillOrdersAction } from "./BulkFulfillOrdersAction.react"
import { CancelOrderAction } from "./CancelOrderAction/CancelOrderAction.react"
import { CancelSwapOrdersAction } from "./CancelSwapOrdersAction.react"
import { CreateBulkOrderAction } from "./CreateBulkOrderAction.react"
import { CreateOrderAction } from "./CreateOrderAction.react"
import { CreateSwapOrderAction } from "./CreateSwapOrderAction.react"
import { DeployContractAction } from "./DeployContractAction.react"
import { FulfillOrderAction } from "./FulfillOrderAction.react"
import { FulfillSwapOrderAction } from "./FulfillSwapOrderAction.react"
import { MintAction } from "./MintAction.react"
import { MintYourOwnCollectionAction } from "./MintYourOwnCollectionAction.react"
import { PaymentAssetApprovalAction } from "./PaymentAssetApprovalAction.react"
import { SetCreatorFeesAction } from "./SetCreatorFeesAction.react"
import { SetRoyaltyInfoAction } from "./SetRoyaltyInfoAction.react"
import { SetTransferValidatorAction } from "./SetTransferValidator.react"
import {
  UpdateDropMechanicsAction,
  UpdateDropMechanicsActionProps,
} from "./UpdateDropMechanicsAction.react"
import { UpdatePreRevealAction } from "./UpdatePreRevealAction.react"

type Props = {
  dataKey: BlockchainActionList_data$key
  onEnd: BlockchainActionOnEnd
  onError?: (error: Error) => unknown
  onNext?: BlockchainActionOnEnd
  overrides?: Partial<
    Record<BlockchainActionType, { props: Partial<BaseBlockchainActionProps> }>
  > & {
    // overrides for specific components
    DropMechanicsUpdateActionType?: {
      props?: Partial<Omit<UpdateDropMechanicsActionProps, "dataKey">>
    }
  }
}

export type BlockchainActionType =
  BlockchainActionList_data$data[number]["__typename"]

export const BlockchainActionListBase = ({
  dataKey,
  onNext,
  onEnd,
  onError,
  overrides,
}: Props) => {
  const [currentStep, setCurrentStep] = useState(0)

  const actions = useFragment(
    graphql`
      fragment BlockchainActionList_data on BlockchainActionType
      @relay(plural: true) {
        __typename
        ... on AssetApprovalActionType {
          ...AssetApprovalAction_data
        }
        ... on AskForDepositType {
          __typename
          ...AskForDepositAction_data
        }
        ... on AskForSwapType {
          __typename
          ...AskForSwapAction_data
        }
        ... on AssetSwapActionType {
          __typename
          ...AssetSwapAction_data
        }
        ... on AssetTransferActionType {
          __typename
          ...AssetTransferAction_data
        }
        ... on CreateOrderActionType {
          __typename
          ...CreateOrderAction_data
        }
        ... on CreateBulkOrderActionType {
          __typename
          ...CreateBulkOrderAction_data
        }
        ... on CreateSwapOrderActionType {
          __typename
          ...CreateSwapOrderAction_data
        }
        ... on CancelOrderActionType {
          __typename
          ...CancelOrderAction_data
        }
        ... on CancelSwapOrdersActionType {
          __typename
          ...CancelSwapOrdersAction_data
        }
        ... on FulfillOrderActionType {
          __typename
          ...FulfillOrderAction_data
        }
        ... on FulfillSwapOrderActionType {
          __typename
          ...FulfillSwapOrderAction_data
        }
        ... on BulkAcceptOffersActionType {
          __typename
          ...BulkAcceptOffersAction_data
        }
        ... on BulkFulfillOrdersActionType {
          __typename
          ...BulkFulfillOrdersAction_data
        }
        ... on PaymentAssetApprovalActionType {
          __typename
          ...PaymentAssetApprovalAction_data
        }
        ... on MintActionType {
          __typename
          ...MintAction_data
        }
        ... on DropContractDeployActionType {
          __typename
          ...DeployContractAction_data
        }
        ... on DropMechanicsUpdateActionType {
          __typename
          ...UpdateDropMechanicsAction_data
        }
        ... on SetCreatorFeesActionType {
          __typename
          ...SetCreatorFeesAction_data
        }
        ... on SetTransferValidatorActionType {
          __typename
          ...SetTransferValidatorAction_data
        }
        ... on SetRoyaltyInfoActionType {
          __typename
          ...SetRoyaltyInfoAction_data
        }
        ... on CollectionTokenMetadataUpdateActionType {
          __typename
          ...UpdatePreRevealAction_data
        }
        ... on AssetBurnToRedeemActionType {
          __typename
          ...AssetBurnToRedeemAction_data
        }
        ... on MintYourOwnCollectionActionType {
          __typename
          ...MintYourOwnCollectionAction_data
        }
      }
    `,
    dataKey,
  )

  const nextStep = (
    stepData: Parameters<BlockchainActionOnEnd>[0],
    stepIndex?: number,
  ) => {
    const nextStepIndex = stepIndex ?? currentStep + 1

    if (nextStepIndex < actions.length) {
      setCurrentStep(nextStepIndex)
      onNext?.(stepData)
    } else if (nextStepIndex === actions.length) {
      onEnd(stepData)
    }
  }

  const getBatchedActions = <T extends (typeof actions)[number]>(
    action: T,
  ): { batchedActions: T[]; endIndex: number } => {
    let endIndex = currentStep + 1

    while (
      endIndex < actions.length &&
      actions[endIndex].__typename === action.__typename
    ) {
      endIndex += 1
    }

    return {
      batchedActions: actions.slice(currentStep, endIndex) as T[],
      endIndex,
    }
  }

  const action = actions[currentStep]

  const baseProps: BaseBlockchainActionProps = {
    onEnd: nextStep,
    onError,
  }

  const actionOverrides = overrides?.[action.__typename]?.props

  switch (action.__typename) {
    case "AskForDepositType":
      return (
        <AskForDepositAction
          dataKey={action}
          {...baseProps}
          {...actionOverrides}
        />
      )
    case "AskForSwapType":
      return (
        <AskForSwapAction
          dataKey={action}
          {...baseProps}
          {...actionOverrides}
        />
      )
    case "AssetApprovalActionType": {
      const { batchedActions, endIndex } = getBatchedActions(action)

      return (
        <AssetApprovalAction
          dataKey={batchedActions}
          onEnd={stepData => nextStep(stepData, endIndex)}
          onError={onError}
          onNext={onNext}
          {...actionOverrides}
        />
      )
    }
    case "AssetSwapActionType":
      return (
        <AssetSwapAction dataKey={action} {...baseProps} {...actionOverrides} />
      )
    case "AssetTransferActionType":
      return (
        <AssetTransferAction
          dataKey={action}
          {...baseProps}
          {...actionOverrides}
        />
      )
    case "CancelOrderActionType": {
      const { batchedActions, endIndex } = getBatchedActions(action)
      return (
        <CancelOrderAction
          dataKey={batchedActions}
          onEnd={stepData => nextStep(stepData, endIndex)}
          onError={onError}
          onNext={onNext}
          {...actionOverrides}
        />
      )
    }
    case "CancelSwapOrdersActionType": {
      const { batchedActions, endIndex } = getBatchedActions(action)
      return (
        <CancelSwapOrdersAction
          dataKey={batchedActions}
          onEnd={stepData => nextStep(stepData, endIndex)}
          onError={onError}
          onNext={onNext}
          {...actionOverrides}
        />
      )
    }
    case "CreateOrderActionType": {
      const { batchedActions, endIndex } = getBatchedActions(action)

      return (
        <CreateOrderAction
          dataKey={batchedActions}
          onEnd={stepData => nextStep(stepData, endIndex)}
          onError={onError}
          onNext={onNext}
          {...actionOverrides}
        />
      )
    }
    case "CreateBulkOrderActionType":
      return (
        <CreateBulkOrderAction
          dataKey={action}
          {...baseProps}
          {...actionOverrides}
        />
      )
    case "CreateSwapOrderActionType": {
      const { batchedActions, endIndex } = getBatchedActions(action)

      return (
        <CreateSwapOrderAction
          dataKey={batchedActions}
          onEnd={stepData => nextStep(stepData, endIndex)}
          onError={onError}
          onNext={onNext}
          {...actionOverrides}
        />
      )
    }
    case "FulfillOrderActionType":
      return (
        <FulfillOrderAction
          dataKey={action}
          {...baseProps}
          {...actionOverrides}
        />
      )
    case "FulfillSwapOrderActionType":
      return (
        <FulfillSwapOrderAction
          dataKey={action}
          {...baseProps}
          {...actionOverrides}
        />
      )
    case "BulkAcceptOffersActionType": {
      const { batchedActions, endIndex } = getBatchedActions(action)

      return (
        <BulkAcceptOffersAction
          dataKey={batchedActions}
          onEnd={stepData => nextStep(stepData, endIndex)}
          {...actionOverrides}
        />
      )
    }
    case "BulkFulfillOrdersActionType": {
      const { batchedActions, endIndex } = getBatchedActions(action)

      return (
        <BulkFulfillOrdersAction
          dataKey={batchedActions}
          onEnd={stepData => nextStep(stepData, endIndex)}
          {...actionOverrides}
        />
      )
    }
    case "MintActionType":
      return <MintAction dataKey={action} {...baseProps} {...actionOverrides} />
    case "PaymentAssetApprovalActionType": {
      const { batchedActions, endIndex } = getBatchedActions(action)

      return (
        <PaymentAssetApprovalAction
          dataKey={batchedActions}
          onEnd={stepData => nextStep(stepData, endIndex)}
          onError={onError}
          onNext={onNext}
          {...actionOverrides}
        />
      )
    }

    case "DropContractDeployActionType":
      return (
        <DeployContractAction
          dataKey={action}
          {...baseProps}
          {...actionOverrides}
        />
      )
    case "DropMechanicsUpdateActionType":
      return (
        <UpdateDropMechanicsAction
          dataKey={action}
          {...baseProps}
          {...actionOverrides}
        />
      )
    case "MintYourOwnCollectionActionType":
      return (
        <MintYourOwnCollectionAction
          dataKey={action}
          {...baseProps}
          {...actionOverrides}
        />
      )
    case "SetCreatorFeesActionType":
      return (
        <SetCreatorFeesAction
          dataKey={action}
          {...baseProps}
          {...actionOverrides}
        />
      )
    case "SetTransferValidatorActionType":
      return (
        <SetTransferValidatorAction
          dataKey={action}
          {...baseProps}
          {...actionOverrides}
        />
      )
    case "SetRoyaltyInfoActionType":
      return (
        <SetRoyaltyInfoAction
          dataKey={action}
          {...baseProps}
          {...actionOverrides}
        />
      )
    case "CollectionTokenMetadataUpdateActionType":
      return (
        <UpdatePreRevealAction
          dataKey={action}
          {...baseProps}
          {...actionOverrides}
        />
      )
    case "AssetBurnToRedeemActionType":
      return (
        <AssetBurnToRedeemAction
          dataKey={action}
          {...baseProps}
          {...actionOverrides}
        />
      )
    case "%other":
    default:
      throw new UnreachableCaseError(
        `${action.__typename} not supported in action list` as never,
      )
  }
}

export const blockchainActionListGenerator = (loader: JSX.Element | null) => {
  return dynamic<Props>(
    () =>
      import("../BlockchainActionList").then(
        mod => mod.BlockchainActionListBase,
      ),
    // TODO: It'd be nice if we could use `suspense: true` here instead so we could
    // render N skeletons per action
    { loading: () => loader },
  )
}

export const BlockchainActionList = blockchainActionListGenerator(
  <BlockchainActionModalContent.Skeleton />,
)
