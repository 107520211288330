import React, { Suspense, useEffect } from "react"
import { SpaceBetween, Text } from "@opensea/ui-kit"
import { graphql, useLazyLoadQuery } from "react-relay"
import {
  PaymentMethod,
  PaymentMethodProps,
  PaymentOption,
} from "@/components/trade/CheckoutModal/components/PaymentMethod"
import { usePaymentMethod } from "@/components/trade/CheckoutModal/usePaymentMethod"
import { Block, BlockProps } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { useTranslate } from "@/hooks/useTranslate"
import { ShoppingCartPaymentMethodQuery } from "@/lib/graphql/__generated__/ShoppingCartPaymentMethodQuery.graphql"

type Props = {
  listingId: string
  paymentMethod: PaymentOption
  setPaymentMethod: NonNullable<PaymentMethodProps["onChange"]>
} & BlockProps

const ShoppingCartPaymentMethodBase = ({
  listingId,
  setPaymentMethod,
  paymentMethod,
  ...blockProps
}: Props) => {
  const data = useLazyLoadQuery<ShoppingCartPaymentMethodQuery>(
    graphql`
      query ShoppingCartPaymentMethodQuery($listingId: OrderRelayID!) {
        blockchain {
          bulkPurchase(ordersToFill: [{ order: $listingId }]) {
            orders {
              ...usePaymentMethod_order
            }
          }
        }
        moonpay {
          ...usePaymentMethod_moonpay @arguments(listingId: $listingId)
        }
      }
    `,
    { listingId },
  )

  const paymentMethodProps = usePaymentMethod({
    order: data.blockchain.bulkPurchase.orders[0],
    moonpay: data.moonpay,
  })

  useEffect(() => {
    if (!paymentMethodProps.enabled) {
      setPaymentMethod("crypto")
    }
  }, [paymentMethodProps.enabled, setPaymentMethod])

  return paymentMethodProps.enabled ? (
    <ShoppingCartPaymentMethodButton
      defaultValue={paymentMethod}
      paymentMethodProps={paymentMethodProps}
      onChange={setPaymentMethod}
      {...blockProps}
    />
  ) : null
}

const ShoppingCartPaymentMethodButton = ({
  onChange,
  paymentMethodProps,
  defaultValue,
  ...blockProps
}: PaymentMethodProps) => {
  const t = useTranslate("bulkPurchase")

  return (
    <Block {...blockProps}>
      <SpaceBetween className="w-full items-center">
        <Flex alignItems="center">
          <Text>{t("paymentMethod.payment", "Payment method")}</Text>
        </Flex>
        <Flex alignItems="center">
          <Text.Body size="medium">
            {defaultValue === "crypto"
              ? t("paymentMethod.crypto", "Crypto")
              : t("paymentMethod.card", "Card")}
          </Text.Body>
        </Flex>
      </SpaceBetween>

      <Block marginTop="20px">
        <PaymentMethod
          defaultValue={defaultValue}
          paymentMethodProps={paymentMethodProps}
          withTitle={false}
          onChange={onChange}
        />
      </Block>
    </Block>
  )
}

export const ShoppingCartPaymentMethod = (props: Props) => {
  return (
    <Suspense fallback={null}>
      <ShoppingCartPaymentMethodBase {...props} />
    </Suspense>
  )
}
