import React, { useEffect, useState } from "react"
import {
  Icon,
  useIsLessThanSm,
  UnstyledButton,
  VerticalAligned,
  Flex,
  classNames,
} from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { CheckCircleFilledIcon } from "@/components/svgs/CheckCircleFilledIcon.react"
import { Block } from "@/design-system/Block"
import { media } from "@/styles/styleUtils"
import { selectClassNames } from "../../lib/helpers/styling"
import { Values } from "../../lib/helpers/type"

export const TOAST_VARIANTS = {
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
} as const

export type ToastVariant = Values<typeof TOAST_VARIANTS>

const VARIANT_PROPERTIES = {
  info: {
    className: "text-blue-3",
    value: "info",
    fill: 1,
  },
  warning: {
    className: "text-yellow-1",
    value: "warning",
    fill: 1,
  },
  error: {
    className: "text-red-2",
    value: "error",
    fill: 1,
  },
} as const

export type ToastT = {
  variant: ToastVariant
  key: string
  content: React.ReactNode
  onClick?: () => unknown
  timeout?: number | false
}

type Props = {
  toast: ToastT
  onClose: (key: string) => unknown
  timeout?: number | false
}

const CLOSE_ICON_SIZE = 24

export const Toast = ({
  toast: { key, variant, content, onClick },
  onClose,
  timeout = 2_000,
}: Props) => {
  const [isClosing, setIsClosing] = useState(false)
  const isMobile = useIsLessThanSm()

  useEffect(() => {
    if (timeout === false) {
      return
    }

    const timeoutId = setTimeout(() => {
      setIsClosing(true)
    }, timeout)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [timeout])

  const size = isMobile ? 24 : 32

  return (
    <DivContainer
      className={selectClassNames("Toast", { isClosing })}
      role="alert"
      variant={variant}
      onAnimationEnd={() => isClosing && onClose(key)}
      onClick={onClick}
    >
      <Flex
        className={classNames(
          "relative items-center",
          timeout === false && "pr-9",
        )}
      >
        <Flex className="relative mr-2 items-center">
          <Block height={size} width={size}>
            {variant === "success" ? (
              <CheckCircleFilledIcon height={size} width={size} />
            ) : (
              <StatusIcon size={size} {...VARIANT_PROPERTIES[variant]} />
            )}
          </Block>
        </Flex>
        {content}
        {timeout === false ? (
          <VerticalAligned
            className="absolute right-0"
            style={{
              top: `calc(50% - ${CLOSE_ICON_SIZE / 2}px)`,
            }}
          >
            <UnstyledButton onClick={() => setIsClosing(true)}>
              <Icon
                aria-label="Dismiss"
                className="text-secondary"
                size={CLOSE_ICON_SIZE}
                value="close"
              />
            </UnstyledButton>
          </VerticalAligned>
        ) : null}
      </Flex>
    </DivContainer>
  )
}

const StatusIcon = styled(Icon)`
  position: absolute;
`

const DivContainer = styled(Block)<{
  variant: ToastVariant
  onClick?: () => unknown
}>`
  font-size: 14px;
  font-weight: 600;
  box-sizing: border-box;
  animation: fadeInBottom ease-in-out 0.3s;

  border-radius: ${props => props.theme.borderRadius.toast};
  box-shadow: ${props => props.theme.colors.components.elevation.level3.shadow};
  color: ${props => props.theme.colors.text.primary};
  cursor: ${props => (props.onClick ? "pointer" : undefined)};

  width: fit-content;
  max-width: 100%;
  padding: 16px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.Toast--isClosing {
    animation: fadeOutBottom ease-in-out 0.3s;
    transform: translateY(100%);
    opacity: 0;
  }

  ${media({
    sm: css`
      font-size: 16px;
      max-width: 480px;
    `,
  })}

  background: ${props =>
    props.theme.colors.components.elevation.level3.background};

  @keyframes fadeInBottom {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeOutBottom {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(100%);
    }
  }
`
