import React, { useState } from "react"
import { Text } from "@opensea/ui-kit"
import {
  PHONE_AUTH_CODE_EXPIRATION,
  RESEND_CODE_TIMEOUT_SECONDS,
  SIGNUP_STEPS,
} from "@/features/os-wallet/components/constants"
import { useAuthenticateOSWallet } from "@/features/os-wallet/hooks/useOpenOSWalletAfterLogin"
import { useOSWalletAuth } from "@/features/os-wallet/hooks/useOSWalletAuth"
import { useTranslate } from "@/hooks/useTranslate"
import { WalletAuthCodeModal } from "./WalletAuthCodeModal.react"
import { WalletMfaEnrollmentModalProps } from "./WalletMfaEnrollmentModal.react"

type Props = WalletMfaEnrollmentModalProps & {
  phoneNumber: string
}

export const WalletPhoneCodeModal = ({
  accessToken,
  phoneNumber,
  privyId,
}: Props) => {
  const t = useTranslate("common")
  const authenticateOSWallet = useAuthenticateOSWallet()
  const { initMfaEnrollmentWithSms, submitMfaEnrollmentWithSms } =
    useOSWalletAuth()

  const [error, setError] = useState<boolean>(false)

  const handleAuthCode = async (authCode: string) => {
    setError(false)
    try {
      await submitMfaEnrollmentWithSms(phoneNumber, authCode)
      await authenticateOSWallet({
        accessToken,
        privyId,
        shouldOpenWalletAfterAuth: false,
      })
    } catch (error) {
      setError(true)
    }
  }

  const handleResendAuthCode = async () => {
    setError(false)
    try {
      await initMfaEnrollmentWithSms(phoneNumber)
    } catch (error) {
      setError(true)
    }
  }

  return (
    <WalletAuthCodeModal
      authCodeExpiration={PHONE_AUTH_CODE_EXPIRATION}
      description={t(
        "wallet.opensea.mfa.phone.description",
        "A one-time authentication code has been sent to {{phoneNumber}}",
        {
          phoneNumber: (
            <Text className="truncate" weight="semibold">
              {phoneNumber}
            </Text>
          ),
        },
      )}
      error={error}
      icon="smartphone"
      resendTooltip={t(
        "wallet.opensea.mfa.phone.resendTooltip",
        {
          "0": "If no text message within {{count}} seconds, click to resend.",
          one: "If no text message within {{count}} second, click to resend.",
          other:
            "If no text message within {{count}} seconds, click to resend.",
        },
        { count: RESEND_CODE_TIMEOUT_SECONDS },
      )}
      step={4}
      title={t("wallet.opensea.mfa.phone.enterCode", "Enter code")}
      totalSteps={SIGNUP_STEPS}
      onAuthCode={handleAuthCode}
      onResendAuthCode={handleResendAuthCode}
    />
  )
}
