import React, { useCallback } from "react"
import { Text, CenterAligned } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { ShoppingCart as ShoppingCartIcon } from "@/components/svgs/material-symbols/ShoppingCart.react"
import { ShoppingCart } from "@/features/shopping-cart/components/ShoppingCart"
import {
  trackCloseCart,
  trackOpenCart,
} from "@/features/shopping-cart/utils/analytics"
import {
  useShoppingCartTotalAssetIdsInCart,
  useShoppingCartViewActions,
  useShoppingCartViewIsOpen,
} from "@/features/shopping-cart/utils/ShoppingCartContextProvider"
import { media } from "@/styles/styleUtils"
import { NavbarBackground } from "../../styles"
import { NavButton } from "../elements"
import { useNavbarIconSize } from "../useNavbarIconSize"

type ShoppingCartButtonProps = {
  background?: NavbarBackground
}

export const ShoppingCartButton = ({ background }: ShoppingCartButtonProps) => {
  const totalAssetIdsInCart = useShoppingCartTotalAssetIdsInCart()
  const isCartOpen = useShoppingCartViewIsOpen()
  const { open: openCart, close: closeCart } = useShoppingCartViewActions()
  const iconSize = useNavbarIconSize()

  const toggleCart = useCallback(() => {
    if (isCartOpen) {
      closeCart()
      trackCloseCart()
    } else {
      openCart()
      trackOpenCart()
    }
  }, [closeCart, isCartOpen, openCart])

  const onShoppingCartSidebarClickAway = (e: MouseEvent | TouchEvent) => {
    const node = e.target as HTMLElement

    //  We should not close shopping cart on click away if there is a modal open. There might be a cleaner way to do this.
    if (
      node.closest('div[role="dialog"]') ||
      node.querySelector('div[role="dialog"]')
    ) {
      return
    }
    closeCart()
  }

  return (
    <ShoppingCartButtonContainer>
      <ShoppingCart
        onShoppingCartSidebarClickAway={onShoppingCartSidebarClickAway}
      >
        <NavButton $background={background} onClick={toggleCart}>
          <ShoppingCartIcon
            className="mr-[-0.5px]"
            size={iconSize}
            title="Cart"
          />
          {totalAssetIdsInCart.length ? (
            <ShoppingCartCountContainer>
              <Text.Body
                asChild
                className="mt-[1px] text-white"
                size="tiny"
                weight="semibold"
              >
                <div>{totalAssetIdsInCart.length}</div>
              </Text.Body>
            </ShoppingCartCountContainer>
          ) : undefined}
        </NavButton>
      </ShoppingCart>
    </ShoppingCartButtonContainer>
  )
}

const ShoppingCartButtonContainer = styled(CenterAligned)`
  ${media(
    {
      lg: css`
        margin-right: -21px;
      `,
    },
    {
      variant: "lessThan",
    },
  )}
  ${media(
    {
      md: css`
        margin-right: 0;
      `,
    },
    {
      variant: "lessThan",
    },
  )}
`

const ShoppingCartCountContainer = styled(CenterAligned)`
  background: ${props => props.theme.colors.primary};
  border-radius: ${props => props.theme.borderRadius.circle};
  color: ${props => props.theme.colors.text.on.primary};

  height: 18px;
  width: 18px;

  position: absolute;
  top: 4px;
  right: 6px;
`
