/**
 * @generated SignedSource<<365409f937d707ace96b6672589bc569>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useHandleBlockchainActions_fulfill_order$data = {
  readonly method: {
    readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_transaction">;
  };
  readonly orderData: {
    readonly openedAt: string;
  };
  readonly " $fragmentType": "useHandleBlockchainActions_fulfill_order";
};
export type useHandleBlockchainActions_fulfill_order$key = {
  readonly " $data"?: useHandleBlockchainActions_fulfill_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_fulfill_order">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useHandleBlockchainActions_fulfill_order"
};

(node as any).hash = "80eb7c0564f3948ae41dd15b7bb76993";

export default node;
