import type { CSSProperties } from "react"
import { classNames } from "@opensea/ui-kit"
import { darken, rgba } from "polished"
import styled from "styled-components"
import { Z_INDEX } from "../../constants/zIndex"
import type { BlockProps } from "../Block"

type OverlayVariant = "round" | "square"

export type OverlayProps = BlockProps & {
  active: boolean
  backgroundColor?: CSSProperties["backgroundColor"]
  transitionDuration?: number
  offset?: string
  variant?: OverlayVariant
  borderRadius?: CSSProperties["borderRadius"]
} & Pick<CSSProperties, "position" | "zIndex">

export const Overlay = styled.div.attrs<OverlayProps>(props => ({
  "aria-hidden": !props.active,
  className: classNames("fixed", props.className),
  style: { zIndex: props.zIndex ?? Z_INDEX.OVERLAY, ...props.style },
}))<OverlayProps>`
  top: ${props => props.offset ?? 0};
  bottom: ${props => props.offset ?? 0};
  left: ${props => props.offset ?? 0};
  right: ${props => props.offset ?? 0};

  opacity: ${props => (props.active ? 1 : 0)};
  pointer-events: ${props => (props.active ? "auto" : "none")};
  transition: ${({ transitionDuration }) =>
    transitionDuration
      ? `opacity ${transitionDuration}s ease-in-out`
      : undefined};

  border-radius: ${props =>
    props.borderRadius ??
    (props.variant == "round" ? props.theme.borderRadius.circle : undefined)};

  background-color: ${props =>
    props.backgroundColor ?? darken(1, rgba(props.theme.colors.base1, 0.6))};
`
