import { useMemo, useEffect } from "react"
import { IS_SERVER } from "@/constants/environment"

export const usePortalNode = (parent?: () => HTMLElement) => {
  const node = useMemo(
    () => (IS_SERVER ? null : document.createElement("div")),
    [],
  )

  // Portal container
  useEffect(() => {
    const getParent = (): HTMLElement | null => {
      return parent?.() ?? document.body
    }

    if (node) {
      getParent()?.appendChild(node)
    }

    return () => {
      if (node) {
        getParent()?.removeChild(node)
      }
    }
  }, [node, parent])

  return node
}
