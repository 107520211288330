import React from "react"
import dynamic from "next/dynamic"
import { UncontrolledModalProps } from "@/design-system/Modal"
import { MultiStepModal } from "@/design-system/Modal/MultiStepModal.react"
import { AddFundsModalContentProps } from "./AddFundsModalContent.react"

export type AddFundsModalProps = Pick<
  UncontrolledModalProps,
  "trigger" | "overlayRef"
> &
  AddFundsModalContentProps & {
    onClose?: () => void
  }

const AddFundsModalContent = dynamic(
  async () =>
    (await import("./AddFundsModalContent.react")).AddFundsModalContent,
  {
    ssr: false,
  },
)

export const AddFundsModal = ({
  trigger,
  overlayRef,
  ...addFundsModalProps
}: AddFundsModalProps) => {
  return (
    <MultiStepModal overlayRef={overlayRef} trigger={trigger}>
      {() => <AddFundsModalContent {...addFundsModalProps} />}
    </MultiStepModal>
  )
}
