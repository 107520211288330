import type { ServerResponse } from "http"
import type { NextPageContext } from "next"
import absoluteUrl from "next-absolute-url"
import { OperationType, getRequest } from "relay-runtime"
import { IS_SERVER } from "@/constants/environment"
import type { GetInitialPageProvidedProps } from "@/containers/AppProviders"
import { getCreateServerResponseNonceIdempotent } from "@/features/security/csp/nonce"
import Wallet from "@/lib/chain/wallet"
import { createServerEnvironment } from "@/lib/graphql/environment"
import { RelayMetadata } from "@/lib/graphql/environment/types"
import { hasGraphQLResponseErrorWithStatus } from "@/lib/graphql/error"
import { fetch } from "@/lib/graphql/graphql"
import { GraphQLPageParams } from "@/lib/graphql/GraphQLPage.react"
import { hasIpRightsViolationError } from "@/lib/helpers/errors"
import Router from "@/lib/helpers/router"
import { pathnameRequiresAuth, pathnameRequiresWallet } from "@/lib/routes"
import { getThemeFromCookie } from "@/styles/themes"
import {
  checkWallet,
  setStaticPageCachingHeader,
  setPageCachingHeader,
} from "./utils"

export const getAppInitialProps = async <TOperation extends OperationType>(
  ctx: NextPageContext,
  graphqlPageParams?: GraphQLPageParams<TOperation>,
): Promise<GetInitialPageProvidedProps<TOperation>> => {
  // On SSR include auth only if its needed. This ensures we can properly cache pages
  // that do not depend on auth.
  const includeAuth =
    typeof window !== "undefined"
      ? true
      : pathnameRequiresAuth(ctx.pathname) ||
        pathnameRequiresWallet(ctx.pathname) ||
        ctx.pathname === "/login"

  const nonce = getCreateServerResponseNonceIdempotent(ctx.res)
  const wallet = includeAuth ? Wallet.fromContext(ctx) : new Wallet(undefined)

  const { isAuthenticated, isWalletRedirect, session } = await checkWallet(
    wallet,
    ctx,
  )

  const metadata: RelayMetadata = {
    address: wallet.address,
    activeAccountAddress: wallet.activeAccount?.address,
    token: session?.token,
  }

  if (ctx.req?.headers["CF-Connecting-IP"]) {
    metadata["CF-Connecting-IP"] = ctx.req.headers["CF-Connecting-IP"] as string
  }

  const theme = getThemeFromCookie(ctx)
  const initialProps: GetInitialPageProvidedProps<TOperation> = {
    walletState: includeAuth ? Wallet.stateFromContext(ctx) : undefined,
    theme,
    isAuthenticated,
    locationContext: absoluteUrl(ctx.req),
    nonce,
    query: graphqlPageParams?.query,
    variables: graphqlPageParams?.variables,
  }

  const set404response = async (res: ServerResponse) => {
    res.statusCode = 404
    initialProps.isPageNotFound = true
  }

  if (isWalletRedirect || !IS_SERVER) {
    return initialProps
  }

  if (!graphqlPageParams?.query) {
    setStaticPageCachingHeader(ctx)
    return initialProps
  }

  const { query, cachePolicy, isPageNotFound, variables, redirectUrl } =
    graphqlPageParams

  const environment = createServerEnvironment(metadata)
  initialProps.ssrEnvironment = environment

  try {
    /**
     * We fetch the query and as a side-effect it will populate relay's environment with the
     * results. We then pass the serialized version of it to the client via __wired__. Look
     * at _document's SidecarScript component.
     *
     * This process of fetching the query and serializing the environment is an adaptation of
     * https://github.com/relay-tools/react-relay-network-modern-ssr#server
     */
    const [data] = await fetch<TOperation>(
      query,
      variables ?? {},
      undefined,
      environment,
    )

    if (isPageNotFound?.(data)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await set404response(ctx.res!)
    }

    const redirectLocation = redirectUrl?.(data)
    if (redirectLocation) {
      if (ctx.res) {
        ctx.res.writeHead(301, { Location: redirectLocation })
        ctx.res.end()
      }
    }

    setPageCachingHeader({
      context: ctx,
      queryName: getRequest(query).operation.name,
      cachePolicy,
      metadata,
    })

    initialProps.initialRecords = environment.getStore().getSource().toJSON()

    return initialProps
  } catch (error) {
    if (ctx.res && hasGraphQLResponseErrorWithStatus(error, 404)) {
      if (hasIpRightsViolationError(error)) {
        await Router.replace(
          "/",
          {
            show_ip_rights_delisted_notice: hasIpRightsViolationError(error),
          },
          ctx.res,
        )
      }
      await set404response(ctx.res)
    }
    return initialProps
  }
}

/**
 * This is a no-op HOC that forces next-translate to properly load translations on getInitialProps.
 * This is only needed because next-translate-plugin's loader's getInitialProps detection is bad, but
 * if it detects a page wrapped with a HOC, it forces loading with getInitialProps
 */
export const forceNextTranslateGetInitialProps = (page: {
  // Explicitly not using NextPage & GraphQLNextPage type for convenience reasons (not having to pass in generics)
  getInitialProps?: (context: NextPageContext) => unknown
}) => {
  if (!page.getInitialProps) {
    throw new Error(
      "This HOC should only be used on pages with getInitialProps",
    )
  }

  return page
}
