import { RefCallback, useRef } from "react"

type UseElementSetReturn = {
  addToSet: RefCallback<HTMLElement>
  getElements: () => HTMLElement[]
}

export const useElementSet = (): UseElementSetReturn => {
  const elementsRef = useRef<Set<HTMLElement>>(new Set())

  const addToSet: RefCallback<HTMLElement> = element => {
    if (element) {
      elementsRef.current.add(element)
    }
  }

  const getElements = () => {
    return Array.from(elementsRef.current)
  }

  return {
    addToSet,
    getElements,
  }
}
