import React, { useState } from "react"
import {
  CenterAligned,
  Flex,
  FlexColumn,
  List,
  Separator,
  Spinner,
  Text,
  UnstyledButton,
  useTheme,
} from "@opensea/ui-kit"
import localforage from "localforage"
import { useLocalStorage } from "react-use"
import { ConnectCompatibleWallet } from "@/components/nav/WalletSidebar/ConnectCompatibleWallet"
import { MastercardLogo } from "@/components/svgs/MastercardLogo.react"
import { VisaLogo } from "@/components/svgs/VisaLogo.react"
import { WALLET_NAME } from "@/constants/wallet"
import { LAST_CONNECTED_WALLET_KEY } from "@/containers/WalletProvider/localstorage"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { LAST_CONNECTED_EMAIL_KEY } from "@/features/os-wallet/localstorage"
import {
  getWalletNameFrom6963Rdns,
  useMipdContext,
} from "@/features/os-wallet/MipdContextProvider.react"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { MoreWalletsModal } from "./MoreWalletsModal.react"
import { OSLogin } from "./OSLogin.react"
import { OSWalletModalBody } from "./OSWalletModalBody.react"

const DEFAULT_SHOWN_WALLET_COUNT = 3
const DEFAULT_EXTENSION_WALLET_ORDER: Array<WALLET_NAME> = [
  WALLET_NAME.MetaMask,
  WALLET_NAME.CoinbaseWallet,
  WALLET_NAME.Trust,
  WALLET_NAME.Phantom,
]
type Props = {
  isFromMint?: boolean
}
export const WalletSelectionModal = ({ isFromMint }: Props) => {
  const t = useTranslate("common")
  const { onNext } = useMultiStepFlowContext()
  const [lastConnectedWallet, setLastConnectedWallet] =
    useState<WALLET_NAME | null>()
  const [lastConnectedEmail] = useLocalStorage<string>(LAST_CONNECTED_EMAIL_KEY)
  const { eip6963providers } = useMipdContext()
  const { theme } = useTheme()

  useMountEffect(() => {
    const getLastConnected = async () => {
      setLastConnectedWallet(
        await localforage.getItem(LAST_CONNECTED_WALLET_KEY),
      )
    }
    getLastConnected()
  })

  const extensionWallets = (eip6963providers || [])
    .map(provider => getWalletNameFrom6963Rdns(provider.info.rdns))
    .filter(
      (walletName): walletName is WALLET_NAME =>
        walletName !== undefined && walletName !== lastConnectedWallet,
    )
    .sort((a, b) => {
      const indexA = DEFAULT_EXTENSION_WALLET_ORDER.indexOf(a)
      const indexB = DEFAULT_EXTENSION_WALLET_ORDER.indexOf(b)

      // Elements not found in DEFAULT_EXTENSION_WALLET_ORDER will be moved to the end
      const defaultIndex = DEFAULT_EXTENSION_WALLET_ORDER.length
      const orderA = indexA !== -1 ? indexA : defaultIndex
      const orderB = indexB !== -1 ? indexB : defaultIndex

      return orderA - orderB
    })
  const priorityWallets =
    lastConnectedWallet && lastConnectedWallet !== WALLET_NAME.OpenSeaWallet
      ? [lastConnectedWallet, ...extensionWallets]
      : extensionWallets

  const loginMethods = {
    email: (
      <FlexColumn className="w-full gap-4">
        <OSLogin
          defaultEmail={lastConnectedEmail || ""}
          hasLoginCallback={isFromMint}
        />
        <Text.Body color="secondary" size="tiny">
          {t(
            "wallet.opensea.createNewWallet",
            "If you haven't logged in using your email before, you will create a new wallet using this email.",
          )}
        </Text.Body>
        {isFromMint && (
          <Flex className="w-full items-center justify-center gap-2">
            <VisaLogo isDark={theme === "dark"} />
            <CenterAligned className="h-[24px] w-[35px] rounded border-[1.5px] border-level-1 bg-white dark:bg-black">
              <MastercardLogo />
            </CenterAligned>
            <Text.Body className="text-center" color="secondary" size="small">
              {!lastConnectedEmail
                ? t(
                    "wallet.opensea.cardPaymentsWalletWillBeCreated",
                    "Card payments. Wallet will be created.",
                  )
                : t("wallet.opensea.bestForCard", "Best for card payments")}
            </Text.Body>
          </Flex>
        )}
      </FlexColumn>
    ),
    other: (
      <div className="w-full">
        <ConnectCompatibleWallet
          customHeader={<></>}
          defaultShownWalletCount={DEFAULT_SHOWN_WALLET_COUNT}
          priorityWallets={priorityWallets}
          renderList={wallets => (
            <List className="border-0 shadow-elevation-1 dark:bg-component-gray-1">
              {wallets.map(
                ({
                  walletName,
                  logo,
                  actionProps,
                  annotation,
                  installingWallet,
                }) => (
                  <List.Item
                    className="px-4 py-3"
                    key={walletName}
                    {...actionProps}
                  >
                    <List.Item.Avatar
                      alt={walletName}
                      size={30}
                      src={logo ?? ""}
                    />
                    <List.Item.Content>
                      <List.Item.Title weight="semibold">
                        {walletName}
                      </List.Item.Title>
                    </List.Item.Content>
                    {installingWallet !== walletName &&
                      walletName === lastConnectedWallet && (
                        <List.Item.Side>
                          <CenterAligned className="rounded-md bg-component-gray-1 p-2 capitalize">
                            <Text
                              className="text-secondary opacity-80"
                              size="tiny"
                              weight="semibold"
                            >
                              {walletName === lastConnectedWallet
                                ? t("wallet.opensea.lastUsed", "Last used")
                                : annotation}
                            </Text>
                          </CenterAligned>
                        </List.Item.Side>
                      )}

                    {walletName === installingWallet && (
                      <List.Item.Side className="ml-1">
                        <Spinner />
                      </List.Item.Side>
                    )}
                  </List.Item>
                ),
              )}
            </List>
          )}
          source="wallet modal"
        />
        <UnstyledButton
          className="mx-auto mt-3 block p-2"
          onClick={() => onNext(() => <MoreWalletsModal />)}
        >
          <Text.Body color="secondary" size="small">
            {t("wallet.opensea.moreWalletOptions", "More wallet options")}
          </Text.Body>
        </UnstyledButton>
      </div>
    ),
  }

  return (
    <OSWalletModalBody
      additionalHeight={isFromMint ? 58 : 30}
      title={t("wallet.opensea.connect", "Connect to OpenSea")}
    >
      <FlexColumn className="h-full items-center gap-4 pb-5">
        {loginMethods.other}

        <Flex className="w-full items-center">
          <Separator className="flex-1" />
          <Text.Body
            className="flex-none p-2 py-1.5 uppercase"
            color="secondary"
            size="tiny"
          >
            {t("wallet.opensea.or", "Or")}
          </Text.Body>
          <Separator className="flex-1" />
        </Flex>

        {loginMethods.email}
      </FlexColumn>
    </OSWalletModalBody>
  )
}
