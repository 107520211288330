/**
 * @generated SignedSource<<262ec59f64cb54bccfa239da1be69419>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionLink_assetContract$data = {
  readonly address: string;
  readonly blockExplorerLink: string;
  readonly " $fragmentType": "CollectionLink_assetContract";
};
export type CollectionLink_assetContract$key = {
  readonly " $data"?: CollectionLink_assetContract$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionLink_assetContract">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionLink_assetContract",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blockExplorerLink",
      "storageKey": null
    }
  ],
  "type": "AssetContractType",
  "abstractKey": null
};

(node as any).hash = "1f3463574af3c39de5d24f87ecbc4897";

export default node;
