/**
 * @generated SignedSource<<b7650af0cbb20ea521461e5db0780ff4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DropStatus = "DRAFT" | "INACTIVE" | "PUBLISHED" | "TEASER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DropActionContext_data$data = {
  readonly dropv2: {
    readonly status: DropStatus;
  } | null;
  readonly " $fragmentType": "DropActionContext_data";
};
export type DropActionContext_data$key = {
  readonly " $data"?: DropActionContext_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"DropActionContext_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DropActionContext_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "dropv2",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "22b28725a9846a6cad963c62d3319e77";

export default node;
