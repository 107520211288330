import type { ServerResponse } from "http"
import type { NextPageContext } from "next"
import clientRouter, { NextRouter } from "next/router"
import qs from "qs"
import { IS_SERVER } from "../../constants/environment"
import * as urls from "./urls"

// DEPRECATED
// This module is being deprecated and is considered legacy
// For new code, please use `useRouter` hook to obtain the router instance

// Pushing or replacing a route with this deprecated router will not trigger
// wallet connection or authentication if pushing to a protected route.

// Remaining usage of this router is limited to calls inside of `getInitialProps`
// where useRouter cannot be used and server-side routing is required.

type QueryParams = Record<
  string,
  | Record<string, unknown>
  | string
  | undefined
  | string[]
  | qs.ParsedQs[]
  | boolean
>

const get = (): NextRouter | undefined => (IS_SERVER ? undefined : clientRouter)

type NextRouterPushOptions = Parameters<typeof clientRouter.push>["2"]

const push = (
  pathname: string,
  params?: QueryParams,
  routerOptions?: NextRouterPushOptions,
): Promise<boolean> =>
  clientRouter.push(
    `${pathname}${params ? stringifyQueryParams(params) : ""}`,
    undefined,
    routerOptions,
  )

const replace = async (
  pathname: string,
  params?: QueryParams,
  response?: ServerResponse,
): Promise<boolean> => {
  const href = `${pathname}${params ? stringifyQueryParams(params) : ""}`
  if (response) {
    response.writeHead(302, { Location: href })
    response.end()
    return true
  }
  return clientRouter.replace(href)
}

const getQueryString = (context?: NextPageContext): string => {
  const query = (context ?? get())?.asPath?.split(/\?/)[1]
  return query ?? ""
}

const getQueryParams = (context?: NextPageContext): qs.ParsedQs => ({
  ...context?.query,
  ...urls.parseQueryParams(getQueryString(context)),
})

const stringifyQueryParams = (params: QueryParams): string =>
  urls.stringifyQueryParams(params)

const getPathname = (context?: NextPageContext): string => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return (getPath(context)?.match(/^[^?]*/) ?? [])[0] ?? ""
}

const getPath = (context?: NextPageContext): string => {
  return (context ?? get())?.asPath ?? ""
}

/**
 * @deprecated consider using useRouter instead
 */
const Router = {
  getPath,
  getPathname,
  getQueryParams,
  push,
  replace,
}

export default Router
