import React, { MutableRefObject, useEffect, useRef, useState } from "react"
import {
  Icon,
  useIsLessThanSm,
  UnstyledButton,
  CenterAligned,
  FlexColumn,
  useIsLessThanMd,
  Flex,
} from "@opensea/ui-kit"
import { useWindowHeight } from "@react-hook/window-size"
import { isFunction, noop } from "lodash"
import { useKeyPressEvent } from "react-use"
import styled, { css, FlattenSimpleInterpolation } from "styled-components"
import { Block } from "@/design-system/Block"
import { ModalContext } from "@/design-system/Modal/ModalContext"
import { useCallbackRef } from "@/hooks/useCallbackRef"
import { useSize } from "@/hooks/useSize"
import { UnreachableCaseError } from "@/lib/helpers/type"
import { media } from "@/styles/styleUtils"
import {
  MOBILE_MODAL_HEADER_Y_PADDING,
  MODAL_HEADER_TOP_PADDING,
  MODAL_HEADING_TITLE_Y_PADDING,
  MOBILE_MODAL_PADDING,
  MODAL_PADDING,
  AXIS_MARGIN,
  SIZE_MAPPINGS,
} from "../../constants"
import { focusFirstNode, handleTabPress } from "../../focus"
import { BaseModalProps } from "../../types"

export const ModalDialog = ({
  children,
  onClose,
  className,
  focusFirstFocusableElement = true,
  closeOnEscape = true,
  lockFocus = true,
  closable = true,
  overrides,
  returnFocusOnClose = true,
  customCloseIcon,
  closeLabel = "Close",
  size = "medium",
  position = "top",
}: Omit<BaseModalProps, "isOpen" | "parent" | "closeOnOverlayClick">) => {
  const lastActiveElementRef = useRef<HTMLElement>(
    null,
  ) as MutableRefObject<HTMLElement | null>
  const isMobile = useIsLessThanSm()
  const [dialogRef, setRef] = useCallbackRef<HTMLDivElement>()
  const [isModalBodyScrolled, setIsModalBodyScrolled] = useState(false)
  const [isModalBodyFullyScrolled, setIsModalBodyFullyScrolled] =
    useState(false)
  const [hasModalHeaderTitle, setHasModalHeaderTitle] = useState(false)
  const [width, _] = useSize(dialogRef)
  const isExtraLargeWidth = width >= SIZE_MAPPINGS["xlarge"]

  useKeyPressEvent("Escape", closeOnEscape ? onClose : undefined)

  // Focus lock
  useEffect(() => {
    const dialog = dialogRef.current
    const onKeyDown = (event: KeyboardEvent) => {
      if (lockFocus && event.key === "Tab" && dialog) {
        handleTabPress(dialog, event)
      }
    }
    document.addEventListener("keydown", onKeyDown)
    return () => {
      document.removeEventListener("keydown", onKeyDown)
    }
  }, [lockFocus, dialogRef])

  // Focus first focusable element
  useEffect(() => {
    const dialog = dialogRef.current
    if (dialog && focusFirstFocusableElement) {
      lastActiveElementRef.current = document.activeElement as HTMLElement
      focusFirstNode(dialog)
    }

    // return focus to the element that triggered modal
    return () => {
      if (returnFocusOnClose) {
        lastActiveElementRef.current?.focus()
      }
    }
  }, [focusFirstFocusableElement, dialogRef, returnFocusOnClose])

  const isLessThanMd = useIsLessThanMd()

  const renderModalContent = (isFullHeight?: boolean) => {
    const padding = isMobile ? MOBILE_MODAL_PADDING : MODAL_PADDING
    return (
      <ModalContext.Provider
        value={{
          isModalBodyScrolled,
          isExtraLargeWidth,
          hasModalHeaderTitle,
          isModalBodyFullyScrolled,
          setIsModalBodyScrolled,
          setIsModalBodyFullyScrolled,
          setHasModalHeaderTitle,
        }}
      >
        <Dialog
          $isFullHeight={isFullHeight}
          $position={position}
          $width={SIZE_MAPPINGS[size]}
          aria-modal="true"
          className={className}
          ref={setRef}
          role="dialog"
          onClick={(event: MouseEvent) => {
            // We are preventing dialog from closing on dialog click
            event.stopPropagation()
          }}
          {...overrides?.Dialog?.props}
        >
          {isFunction(children) ? children(onClose ?? noop) : children}

          {/* this comes after children so that firstFocusableElement is not close icon */}
          {onClose && closable && (
            <>
              {customCloseIcon ? (
                <Block onClick={onClose}>{customCloseIcon}</Block>
              ) : (
                <Flex
                  className="absolute"
                  style={{
                    right: padding,
                    top: isLessThanMd
                      ? `${
                          MOBILE_MODAL_HEADER_Y_PADDING +
                          (hasModalHeaderTitle
                            ? MODAL_HEADING_TITLE_Y_PADDING
                            : 0)
                        }px`
                      : `${
                          MODAL_HEADER_TOP_PADDING +
                          (hasModalHeaderTitle
                            ? MODAL_HEADING_TITLE_Y_PADDING
                            : 0)
                        }px`,
                  }}
                  {...overrides?.CloseRoot}
                >
                  <UnstyledButton onClick={onClose}>
                    <StyledIcon aria-label={closeLabel} value="close" />
                  </UnstyledButton>
                </Flex>
              )}
            </>
          )}
        </Dialog>
      </ModalContext.Provider>
    )
  }

  switch (position) {
    case "centered":
      return (
        <CenterAligned className="h-full">{renderModalContent()}</CenterAligned>
      )
    case "top":
      return <TopPaddedContainer>{renderModalContent}</TopPaddedContainer>
    default:
      throw new UnreachableCaseError(position)
  }
}

type DialogProps = {
  $width: number
  $position: "centered" | "top"
  $isFullHeight?: boolean
  $css: FlattenSimpleInterpolation
}

export const Dialog = styled(FlexColumn)<DialogProps>`
  position: relative;
  border-radius: ${props => props.theme.borderRadius.modal};
  background: ${props =>
    props.theme.colors.components.elevation.level2.background};
  width: ${props => props.$width}px;
  max-height: calc(100vh - ${AXIS_MARGIN * 2}px);
  max-width: calc(100% - ${AXIS_MARGIN}px);

  ${props =>
    props.$isFullHeight &&
    css`
      border-radius: 0;
    `}

  ${props => props.$css}

  ${media({
    md: css`
      border-radius: 12px;
    `,
  })}
`

const StyledIcon = styled(Icon)`
  color: ${props => props.theme.colors.text.primary};

  :hover {
    color: ${props => props.theme.colors.text.secondary};
  }
`

const TopPaddedContainer = ({
  children,
}: {
  children: (isFullHeight: boolean) => React.ReactNode
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [_, height] = useSize(ref)
  const windowHeight = useWindowHeight()
  const diff = windowHeight - height
  const desiredMargin = 0.1 * windowHeight

  const margin =
    diff <= 0 ? 0 : diff < desiredMargin ? Math.ceil(diff / 2) : desiredMargin

  return (
    <Flex
      // On mobile, prevents the browser top/bottom navbars from covering the bottom part of the modal.
      // Correctly configures the max-height of the modal to factor in the top/bottom navbars of the browser.
      className="max-h-full justify-center"
      ref={ref}
      style={{ marginBottom: margin, marginTop: margin }}
    >
      {children(margin === 0)}
    </Flex>
  )
}
