import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { NetworkUnsupportedGate } from "@/components/modals/NetworkUnsupportedGate"
import { CheckoutDepositModal } from "@/components/trade/CheckoutModal/components/CheckoutDepositModal"
import { PaymentOption } from "@/components/trade/CheckoutModal/components/PaymentMethod"
import { Z_INDEX } from "@/constants/zIndex"
import {
  useConnectedAddress,
  useWallet,
} from "@/containers/WalletProvider/WalletProvider.react"
import { Button, ButtonProps } from "@/design-system/Button"
import { MultiStepContextConsumer } from "@/design-system/Modal/MultiStepFlow.react"
import { MultiStepModal } from "@/design-system/Modal/MultiStepModal.react"
import { MoonPayCheckoutModal } from "@/features/moonpay"
import { BulkPurchaseActionModal } from "@/features/shopping-cart/components/BulkPurchaseActionModal"
import { useTotalPrice } from "@/features/shopping-cart/hooks/useTotalPrice"
import {
  useShoppingCartActions,
  useShoppingCartLocalErrors,
  useShoppingCartViewActions,
} from "@/features/shopping-cart/utils/ShoppingCartContextProvider"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { useIsMountedRef } from "@/hooks/useIsMounted"
import { useIsOpen } from "@/hooks/useIsOpen"
import { useTranslate } from "@/hooks/useTranslate"
import { BulkPurchaseFillType } from "@/lib/graphql/__generated__/BulkPurchaseActionModalQuery.graphql"
import { BulkPurchaseModal_orders$key } from "@/lib/graphql/__generated__/BulkPurchaseModal_orders.graphql"
import { first } from "@/lib/helpers/array"
import { BigNumber } from "@/lib/helpers/numberUtils"
import { InactiveListingWarningModalContent } from "../InactiveListingsWarning/InactiveListingsWarningModalContent.react"
import { useFulfillingListingsWillReactivateOrders } from "../InactiveListingsWarning/useFulfillingListingsWillReactivateOrders"

export type OrderToQuantity = Record<string, number>

type BulkPurchaseButtonContext = {
  chain?: ChainIdentifier
  isButtonDisabled: boolean
  isCheckingCart: boolean
  onSubmit: () => unknown
}

const BulkPurchaseButtonContext = createContext<BulkPurchaseButtonContext>({
  chain: undefined,
  isButtonDisabled: false,
  isCheckingCart: false,
  onSubmit: () => null,
})

type Props = {
  children: React.ReactNode
  paymentMethod: PaymentOption
  orders: BulkPurchaseModal_orders$key
  orderToQuantity: OrderToQuantity
  maxOrdersToFill?: number
  giftRecipientAddress?: string
  fillType: BulkPurchaseFillType
  onPurchased?: () => unknown
}

const BulkPurchaseModalBase = ({
  children,
  orders: orderDataKeys,
  orderToQuantity,
  maxOrdersToFill,
  paymentMethod,
  giftRecipientAddress,
  fillType,
  onPurchased,
}: Props) => {
  const { checkCartErrors } = useShoppingCartActions()
  const localErrors = useShoppingCartLocalErrors()
  const { wallet } = useWallet()
  const connectedAddress = useConnectedAddress()

  const {
    isOpen: isModalOpen,
    open: openModal,
    close: closeModal,
  } = useIsOpen()

  const [balancesBySymbol, setBalancesBySymbol] = useState<
    Record<string, BigNumber | undefined>
  >({})
  const isMountedRef = useIsMountedRef()

  const [isCheckingCart, setIsCheckingCart] = useState(false)

  const orders = useFragment(
    graphql`
      fragment BulkPurchaseModal_orders on OrderV2Type @relay(plural: true) {
        relayId
        item {
          __typename
          relayId
          chain {
            identifier
          }
        }
        payment {
          relayId
          symbol
        }
        ...useTotalPrice_orders
        ...useFulfillingListingsWillReactivateOrders_orders
      }
    `,
    orderDataKeys,
  )

  const { totalPricePerSymbol } = useTotalPrice({
    orders,
    orderToQuantity,
    maxOrdersToFill,
  })

  const paymentIdsKey = JSON.stringify(
    Array.from(new Set(orders.map(order => order.payment.relayId))),
  )

  const paymentIds = useMemo(
    () => Array.from(new Set(orders.map(order => order.payment.relayId))),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paymentIdsKey],
  )

  const fetchBalances = useCallback(async () => {
    setBalancesBySymbol({})

    if (paymentIds.length && connectedAddress) {
      const balances = await Promise.all(
        paymentIds.map(id => wallet.getBaseBalance(id)),
      )

      const balancesBySymbol = balances.reduce(
        (acc, balance, index) => {
          const paymentId = paymentIds[index]
          const symbol = orders.find(
            order => order.payment.relayId === paymentId,
          )?.payment.symbol

          if (symbol) {
            acc[symbol] = balance
          }

          return acc
        },
        {} as Record<string, BigNumber | undefined>,
      )

      if (isMountedRef.current) {
        setBalancesBySymbol(balancesBySymbol)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentIds, connectedAddress])

  useEffect(() => {
    fetchBalances()
  }, [fetchBalances])

  const missingPayment = Object.values(totalPricePerSymbol).find(payment => {
    const symbol = payment?.tokenPricePayment.symbol

    if (!symbol) {
      return false
    }

    const balanceBySymbol = balancesBySymbol[symbol]

    if (!balanceBySymbol) {
      return false
    }

    return balanceBySymbol.lt(payment.price)
  })

  const ordersToFill = useMemo(
    () =>
      Object.entries(orderToQuantity).flatMap(([order, itemFillAmount]) => {
        // For BUY_NOW fillType if maxOrdersToFill is provided then each multi-quantity listing
        // must be represented as multiple single-quantity listings so that we can limit the number
        // of editions purchased using maxOrdersToFill
        if (fillType === "BUY_NOW" && maxOrdersToFill !== undefined) {
          return Array(itemFillAmount).fill({
            order,
            itemFillAmount: "1",
          })
        }

        return {
          order,
          itemFillAmount: itemFillAmount.toString(),
        }
      }),
    [fillType, maxOrdersToFill, orderToQuantity],
  )

  const isButtonDisabled =
    orders.length === 0 || Object.keys(localErrors).length > 0

  const singleOrderInCart = orders.length === 1
  const firstOrderSupportsCard =
    orders[0] && orders[0].item.__typename === "AssetType"

  const hasEnoughFunds =
    !missingPayment || !missingPayment.tokenPricePayment.symbol

  const renderBulkPurchaseActionModal = () => (
    <BulkPurchaseActionModal
      fillType={fillType}
      giftRecipientAddress={giftRecipientAddress}
      maxOrdersToFill={maxOrdersToFill?.toString()}
      ordersToFill={ordersToFill}
      totalPricePerSymbol={totalPricePerSymbol}
      onClose={closeModal}
      onEnd={onPurchased}
    />
  )

  const onSubmit = useCallback(async () => {
    if (fillType === "CART") {
      setIsCheckingCart(true)
      const errors = await checkCartErrors()

      setIsCheckingCart(false)

      if (!errors.length) {
        openModal()
      }
    } else {
      openModal()
    }
  }, [checkCartErrors, fillType, openModal])

  const chain = first(orders)?.item.chain.identifier

  const value = useMemo(
    () => ({ chain, onSubmit, isCheckingCart, isButtonDisabled }),
    [chain, onSubmit, isCheckingCart, isButtonDisabled],
  )

  const {
    fulfillingWillReactivateOrders,
    assetsWithReactivatableOrders,
    numItems,
  } = useFulfillingListingsWillReactivateOrders({
    listingsDataKey: orders,
  })

  const renderBulkPurchaseModalContent = () =>
    // If either of `singleOrderInCart` or `firstOrderSupportsCard` are false,
    // the card payment option should not be displayed as an option and so
    // MoonPayCheckoutModal should not be used regardless of `paymentMethod`
    singleOrderInCart && firstOrderSupportsCard && paymentMethod === "card" ? (
      <MoonPayCheckoutModal
        assetIDs={[orders[0].item.relayId]}
        chainIdentifier={orders[0].item.chain.identifier}
        order={orders[0].relayId}
        onClose={closeModal}
        onTransactionConfirmed={onPurchased}
      />
    ) : !hasEnoughFunds ? (
      <MultiStepContextConsumer>
        {({ onReplace }) => (
          <CheckoutDepositModal
            amount={missingPayment.usdPrice}
            chain={orders[0].item.chain.identifier}
            symbol={missingPayment.tokenPricePayment.symbol}
            totalPrice={missingPayment.price}
            onFundsAdded={async () => {
              if (fillType === "CART") {
                setIsCheckingCart(true)
                const errors = await checkCartErrors()
                setIsCheckingCart(false)

                if (errors.length) {
                  closeModal()
                  return
                }
              }

              onReplace(renderBulkPurchaseActionModal())
            }}
          />
        )}
      </MultiStepContextConsumer>
    ) : (
      renderBulkPurchaseActionModal()
    )

  const renderBulkPurchaseModalContentWithListingWarningIfNeeded = () =>
    fulfillingWillReactivateOrders ? (
      <MultiStepContextConsumer>
        {({ onReplace }) => (
          <InactiveListingWarningModalContent
            numItems={numItems}
            numItemsWithInactiveListing={assetsWithReactivatableOrders.length}
            onContinue={() => onReplace(renderBulkPurchaseModalContent())}
          />
        )}
      </MultiStepContextConsumer>
    ) : (
      renderBulkPurchaseModalContent()
    )

  return (
    <BulkPurchaseButtonContext.Provider value={value}>
      {children}

      <MultiStepModal
        closeOnOverlayClick={false}
        focusFirstFocusableElement={false}
        isOpen={isModalOpen}
        overrides={{
          Overlay: {
            props: {
              style: {
                zIndex: Z_INDEX.MODAL + 1, // Hack to ensure this appears above cart on mobile
              },
            },
          },
        }}
        onClose={closeModal}
      >
        {renderBulkPurchaseModalContentWithListingWarningIfNeeded()}
      </MultiStepModal>
    </BulkPurchaseButtonContext.Provider>
  )
}

const Trigger = ({
  children,
  disabled,
  onClick,
  ...buttonProps
}: ButtonProps) => {
  const { chain, onSubmit, isCheckingCart, isButtonDisabled } = useContext(
    BulkPurchaseButtonContext,
  )
  const { close } = useShoppingCartViewActions()
  const t = useTranslate("bulkPurchase")

  return (
    <NetworkUnsupportedGate
      chainIdentifier={chain}
      shouldAuthenticate
      onNotLoggedIn={close}
    >
      {({ handleIfNotSupported }) => (
        <Button
          className="w-full"
          disabled={isButtonDisabled || disabled}
          isLoading={isCheckingCart}
          onClick={(
            event: React.MouseEvent<HTMLButtonElement> &
              React.MouseEvent<HTMLAnchorElement>,
          ) => {
            onClick?.(event)
            handleIfNotSupported(onSubmit)()
          }}
          {...buttonProps}
        >
          {children ?? t("cta.completePurchase", "Complete purchase")}
        </Button>
      )}
    </NetworkUnsupportedGate>
  )
}

export const BulkPurchaseModal = Object.assign(BulkPurchaseModalBase, {
  Trigger,
})
