// Needed to prevent regeneratorRuntime is not defined error
import "regenerator-runtime/runtime"
import { IS_STORYBOOK } from "@/constants/environment"
import Wallet, { WalletState } from "@/lib/chain/wallet"

let clientWallet: Wallet | undefined = undefined

export const initWallet = (walletState: WalletState): Wallet => {
  if (typeof window === "undefined") {
    return new Wallet(walletState)
  }
  if (!clientWallet) {
    clientWallet = new Wallet(walletState)
  }
  return clientWallet
}

export const getWallet = (): Wallet => {
  if (clientWallet === undefined) {
    throw new Error("Wallet not initialized!")
  }
  return clientWallet
}

export const setWallet = (wallet: Wallet) => {
  if (process.env.NODE_ENV !== "test" && !IS_STORYBOOK) {
    throw new Error("Only supported in tests")
  }
  clientWallet = wallet
}

export const isWalletInitialized = (): boolean => {
  return clientWallet !== undefined
}
