/**
 * @generated SignedSource<<94b051fb3591f701ce65cb22de483820>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetMediaContainer_asset$data = {
  readonly backgroundColor: string | null;
  readonly collection: {
    readonly " $fragmentSpreads": FragmentRefs<"useIsRarityEnabled_collection">;
  };
  readonly defaultRarityData?: {
    readonly " $fragmentSpreads": FragmentRefs<"RarityIndicator_data">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"AssetMediaEditions_asset">;
  readonly " $fragmentType": "AssetMediaContainer_asset";
};
export type AssetMediaContainer_asset$key = {
  readonly " $data"?: AssetMediaContainer_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssetMediaContainer_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "identity"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showQuantity"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showRarity"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssetMediaContainer_asset",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backgroundColor",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "identity",
          "variableName": "identity"
        },
        {
          "kind": "Variable",
          "name": "showQuantity",
          "variableName": "showQuantity"
        }
      ],
      "kind": "FragmentSpread",
      "name": "AssetMediaEditions_asset"
    },
    {
      "condition": "showRarity",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RarityDataType",
          "kind": "LinkedField",
          "name": "defaultRarityData",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RarityIndicator_data"
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionType",
      "kind": "LinkedField",
      "name": "collection",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useIsRarityEnabled_collection"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "c82cf1a08388dd2ea0d2f6ba7362c59a";

export default node;
