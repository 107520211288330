import React from "react"

type Props = {
  className?: string
  width?: number
  fill?: string
}

export const ArbitrumLogo = ({
  className,
  fill = "gray",
  width = 20,
}: Props) => (
  <svg
    className={className}
    fill="none"
    style={{ width, height: width }}
    viewBox="0 0 24 24"
  >
    <path
      d="M7.97992 8.7774C8.4494 8.01126 9.47044 7.765 10.4195 7.77796L11.5327 7.80677L4.97152 18.3282L5.74486 18.7732L12.3852 7.81685L15.3202 7.80677L8.6971 19.0397L11.4578 20.6281L11.7876 20.8182C11.9302 20.8744 12.0871 20.8773 12.2326 20.8268L14.8435 19.3133L12.0554 14.9382L13.7577 12.0508L17.4184 17.8213L15.6514 18.8453L19.5354 16.5944L18.1385 17.4037L14.2775 11.1579L15.7609 8.64059L19.75 14.8533V16.145C19.7471 16.3193 19.6679 16.4835 19.5354 16.5958L18.1385 17.4051L17.4184 17.8228L14.8435 19.3147L12.2326 20.8283C12.0886 20.8787 11.9302 20.8758 11.7876 20.8196L8.69854 19.0425L8.06777 20.1126L10.8443 21.7111C10.9365 21.7629 11.0186 21.809 11.0848 21.8479C11.1885 21.9055 11.2591 21.9458 11.285 21.9573C11.5168 22.0596 11.7689 22.1114 12.0223 22.1086C12.2556 22.1086 12.486 22.0653 12.7021 21.9804L20.2871 17.588C20.7206 17.251 20.9813 16.7398 21.0014 16.1911V7.79237C20.9741 7.17744 20.6457 6.61579 20.1244 6.29033L12.8259 2.09383C12.2974 1.83461 11.6796 1.83461 11.1525 2.09383C11.092 2.12407 4.05704 6.20392 4.05704 6.20392C3.95912 6.25144 3.86551 6.30617 3.77766 6.36953C3.31539 6.69932 3.0288 7.22208 3 7.78805V16.4777"
      fill={fill}
    />
  </svg>
)
