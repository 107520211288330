import React from "react"
import { Text, classNames } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { NetworkTotalFundsUsdDisplay_funds$key } from "@/lib/graphql/__generated__/NetworkTotalFundsUsdDisplay_funds.graphql"
import { BigNumber, bn, displayUSD } from "@/lib/helpers/numberUtils"

type NetworkTotalFundsUsdDisplayProps = {
  funds: NetworkTotalFundsUsdDisplay_funds$key
  chain?: ChainIdentifier
  className?: string
}

export const NetworkTotalFundsUsdDisplay = ({
  funds: fundsKey,
  chain: filterChain,
  className,
}: NetworkTotalFundsUsdDisplayProps) => {
  const funds = useFragment<NetworkTotalFundsUsdDisplay_funds$key>(
    graphql`
      fragment NetworkTotalFundsUsdDisplay_funds on WalletFundsType
      @relay(plural: true) {
        quantity
        usdPrice
        chain
      }
    `,
    fundsKey,
  )

  const chainFunds = funds.filter(
    fund => filterChain === undefined || fund.chain === filterChain,
  )

  const totalUsdValue = BigNumber.sum(
    ...chainFunds.map(fund => bn(fund.quantity).times(fund.usdPrice ?? 0)),
    bn(0),
  )

  return (
    <Text.Body
      className={classNames("whitespace-pre text-secondary", className)}
      size="medium"
    >
      ${displayUSD(totalUsdValue)} USD
    </Text.Body>
  )
}
