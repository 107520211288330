import React, { useCallback } from "react"
import { useFragment } from "react-relay"
import { useTranslate } from "@/hooks/useTranslate"
import { SetCreatorFeesAction_data$key } from "@/lib/graphql/__generated__/SetCreatorFeesAction_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import {
  BlockchainActionModalContent,
  BaseBlockchainActionProps,
} from "./BlockchainActionModalContent.react"
import { useBlockchainActionProgress } from "./useBlockchainActionProgress"
import { useHandleBlockchainActions } from "./useHandleBlockchainActions"

type Props = BaseBlockchainActionProps & {
  dataKey: SetCreatorFeesAction_data$key
}

export const SetCreatorFeesAction = ({ dataKey, onEnd }: Props) => {
  const t = useTranslate("components")
  const data = useFragment(
    graphql`
      fragment SetCreatorFeesAction_data on SetCreatorFeesActionType {
        __typename
        ...useHandleBlockchainActions_set_creator_fees
      }
    `,
    dataKey,
  )

  const { setCreatorFees } = useHandleBlockchainActions()

  const executeAction = useCallback(async () => {
    const transaction = await setCreatorFees(data)
    onEnd({ transaction })
  }, [data, onEnd, setCreatorFees])

  const { attemptAction, progress } = useBlockchainActionProgress({
    executeAction,
    action: data,
  })

  return (
    <BlockchainActionModalContent>
      <BlockchainActionModalContent.Header>
        <BlockchainActionModalContent.Title>
          {t("blockchain.setCreatorEarnings.title", "Confirm creator earnings")}
        </BlockchainActionModalContent.Title>
      </BlockchainActionModalContent.Header>
      <BlockchainActionModalContent.Body>
        <BlockchainActionModalContent.Body.GoToWallet />
        <BlockchainActionModalContent.Body.Text>
          {t(
            "blockchain.setCreatorEarnings.prompt",
            "Creator earnings are written on the blockchain, so you will need to approve the fee changes with your wallet before continuing.",
          )}
        </BlockchainActionModalContent.Body.Text>
        {!progress && (
          <BlockchainActionModalContent.Body.Button onClick={attemptAction}>
            {t("blockchain.setCreatorEarnings.cta", "Continue")}
          </BlockchainActionModalContent.Body.Button>
        )}
      </BlockchainActionModalContent.Body>
    </BlockchainActionModalContent>
  )
}
