/**
 * @generated SignedSource<<d223f374d1790e4362086d3bbd21260d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConfirmationItem_assets$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmationItem_asset">;
  readonly " $fragmentType": "ConfirmationItem_assets";
}>;
export type ConfirmationItem_assets$key = ReadonlyArray<{
  readonly " $data"?: ConfirmationItem_assets$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmationItem_assets">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ConfirmationItem_assets",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConfirmationItem_asset"
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "00fdb8b77af5c88fe4e542c016f5b70e";

export default node;
