/**
 * @generated SignedSource<<9a61aef0b00ed7cd60fc21812255419b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SwitchNetworkNetworkTotalFundsUsd_data$data = {
  readonly wallet: {
    readonly funds: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"NetworkTotalFundsUsdDisplay_funds">;
    }>;
  };
  readonly " $fragmentType": "SwitchNetworkNetworkTotalFundsUsd_data";
};
export type SwitchNetworkNetworkTotalFundsUsd_data$key = {
  readonly " $data"?: SwitchNetworkNetworkTotalFundsUsd_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwitchNetworkNetworkTotalFundsUsd_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "address"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwitchNetworkNetworkTotalFundsUsd_data",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "address",
          "variableName": "address"
        }
      ],
      "concreteType": "WalletType",
      "kind": "LinkedField",
      "name": "wallet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WalletFundsType",
          "kind": "LinkedField",
          "name": "funds",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "NetworkTotalFundsUsdDisplay_funds"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "1633ca0a23d628742e1ac32f90fe1b60";

export default node;
