/**
 * @generated SignedSource<<cd4273524f0dd21b1bc56b042578aa53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetMediaAnimation_asset$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AssetMediaContainer_asset" | "AssetMediaImage_asset" | "AssetMediaPlaceholderImage_asset">;
  readonly " $fragmentType": "AssetMediaAnimation_asset";
};
export type AssetMediaAnimation_asset$key = {
  readonly " $data"?: AssetMediaAnimation_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssetMediaAnimation_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssetMediaAnimation_asset",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssetMediaImage_asset"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssetMediaContainer_asset"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssetMediaPlaceholderImage_asset"
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "312fbf015a19208fd8dc3ad912179f56";

export default node;
