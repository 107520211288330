/**
 * @generated SignedSource<<c192524c9fc97112faeebf4f3911ba91>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WalletFundsQuery$variables = {
  address: string;
};
export type WalletFundsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"WalletFunds_data">;
};
export type WalletFundsQuery = {
  response: WalletFundsQuery$data;
  variables: WalletFundsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "address"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "address",
    "variableName": "address"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WalletFundsQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "WalletFunds_data"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WalletFundsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "WalletType",
        "kind": "LinkedField",
        "name": "wallet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WalletFundsType",
            "kind": "LinkedField",
            "name": "funds",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "chain",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "usdPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "quantity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "image",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetSwapType",
                "kind": "LinkedField",
                "name": "supportedSwaps",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "swapType",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChainType",
                    "kind": "LinkedField",
                    "name": "chain",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "identifier",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "65e747cfc3dd243f1a38c828abc46214",
    "id": null,
    "metadata": {},
    "name": "WalletFundsQuery",
    "operationKind": "query",
    "text": "query WalletFundsQuery(\n  $address: AddressScalar!\n) {\n  ...WalletFunds_data_2WA0fu\n}\n\nfragment FundListItem_data on WalletFundsType {\n  name\n  symbol\n  image\n  quantity\n  usdPrice\n  chain\n  supportedSwaps {\n    swapType\n    symbol\n    chain {\n      identifier\n    }\n  }\n}\n\nfragment WalletFunds_data_2WA0fu on Query {\n  wallet(address: $address) {\n    funds {\n      symbol\n      chain\n      ...utils_calculateFundsBalance\n      ...FundListItem_data\n      id\n    }\n  }\n}\n\nfragment utils_calculateFundsBalance on WalletFundsType {\n  usdPrice\n  quantity\n}\n"
  }
};
})();

(node as any).hash = "b75156265dc255485baad593eb57bfa8";

export default node;
