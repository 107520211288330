import { Environment, commitLocalUpdate } from "relay-runtime"
import { Currency } from "@/components/nav/WalletPopover/types"
import { StaleFund } from "@/components/nav/WalletPopover/WalletPopoverContext.react"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { bn } from "@/lib/helpers/numberUtils"
import { MapNullable } from "@/lib/helpers/type"
import { BridgeOrWrapDirection } from "../BridgeOrWrapForm.react"

// Using nullable properties in case the data is not present in the relay store
type WalletFunds = MapNullable<{
  chain: ChainIdentifier
  symbol: string
  quantity: string
}>

type UpdateRelayStoreOnSwapProps = {
  address: string
  quantity: string
  fromCurrency: Currency
  toCurrency: Currency
  direction: BridgeOrWrapDirection
  setStaleFund: (staleFund: StaleFund) => unknown
}

export const updateRelayStoreOnBridgeOrWrap = (
  environment: Environment,
  {
    address,
    quantity: swapQuantity,
    fromCurrency,
    toCurrency,
    direction,
    setStaleFund,
  }: UpdateRelayStoreOnSwapProps,
) => {
  commitLocalUpdate(environment, store => {
    const walletRecord = store.getRoot().getLinkedRecord("wallet", {
      address,
    })

    const toFunds = walletRecord?.getLinkedRecord<WalletFunds>("fundsOf", {
      symbol: toCurrency.symbol,
      chain: toCurrency.chain,
    })
    const toFundsQuantity = toFunds?.getValue("quantity")

    const fromFunds = walletRecord?.getLinkedRecord<WalletFunds>("fundsOf", {
      symbol: fromCurrency.symbol,
      chain: fromCurrency.chain,
    })
    const fromFundsQuantity = fromFunds?.getValue("quantity")

    // Set stale fund before updating store value
    // Use wrapped currency as stale fund since it changes by the exact quantity
    const wrappedCurrency = direction === "out" ? toCurrency : fromCurrency
    const wrappedCurrencyQuantity =
      direction === "out" ? toFundsQuantity : fromFundsQuantity
    if (wrappedCurrencyQuantity) {
      setStaleFund({
        ...wrappedCurrency,
        quantity: bn(wrappedCurrencyQuantity),
      })
    }

    // Update wallet fundsOf field for toCurrency
    if (toFundsQuantity) {
      const adjustedToQuantity = bn(toFundsQuantity).plus(swapQuantity)
      toFunds?.setValue(adjustedToQuantity.toString(), "quantity")
    }

    // Update wallet fundsOf field for fromCurrency
    if (fromFundsQuantity) {
      const adjustedFromQuantity = bn(fromFundsQuantity).minus(swapQuantity)
      fromFunds?.setValue(adjustedFromQuantity.toString(), "quantity")
    }

    // Update wallet funds field
    const walletFundsRecords =
      walletRecord?.getLinkedRecords<WalletFunds[]>("funds")
    walletFundsRecords?.forEach(fundRecord => {
      // Errors in sentry point to fundRecord sometimes being undefined
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!fundRecord) {
        return
      }

      const quantity = fundRecord.getValue("quantity")
      if (!quantity) {
        return // Nothing to update
      }

      const chain = fundRecord.getValue("chain")
      const symbol = fundRecord.getValue("symbol")

      // Adjust store value for fromCurrency
      if (chain === fromCurrency.chain && symbol === fromCurrency.symbol) {
        const adjustedQuantity = bn(quantity).minus(swapQuantity)
        fundRecord.setValue(adjustedQuantity.toString(), "quantity")
        return
      }

      // Adjust store value for toCurrency
      if (chain === toCurrency.chain && symbol === toCurrency.symbol) {
        const adjustedQuantity = bn(quantity).plus(swapQuantity)
        fundRecord.setValue(adjustedQuantity.toString(), "quantity")
        return
      }
    })
  })
}
