import React, { ComponentPropsWithoutRef } from "react"
import { Icon, InlineFlex, Text, UnstyledButton } from "@opensea/ui-kit"
import styled from "styled-components"
import { variant } from "styled-system"
import { FauxLinkText } from "@/components/common/FauxLinkText.react"
import { Z_INDEX } from "@/constants/zIndex"
import { Tooltip } from "@/design-system/Tooltip"
import { useGlobalModal } from "@/hooks/useGlobalModal"
import { useTranslate } from "@/hooks/useTranslate"
import { CollectionDetailsModal } from "./CollectionDetailsModal"

export type ItemStatusVariant = "warning" | "error"

type Props = ComponentPropsWithoutRef<typeof CollectionDetailsModal>

export const CollectionUnreviewedPill = ({ collectionSlug }: Props) => {
  const t = useTranslate("common")
  const { openModal } = useGlobalModal()
  const open = () => {
    if (collectionSlug) {
      openModal(
        _ => (
          <CollectionDetailsModal
            collectionSlug={collectionSlug}
            hideCheckbox
          />
        ),
        {
          overrides: {
            Overlay: {
              props: {
                style: { zIndex: Z_INDEX.MODAL + 5 },
              },
            },
          },
          focusFirstFocusableElement: false,
        },
      )
    }
  }

  return (
    <Tooltip
      content={t(
        "unreviewed.tooltip",
        "This item is from an {{separator}}unreviewed collection.{{separator}}{{showDetails}}",
        {
          separator: <br />,
          showDetails: (
            <UnstyledButton onClick={open}>
              <FauxLinkText size="small">
                {t("details.show", "Show details")}
              </FauxLinkText>
            </UnstyledButton>
          ),
        },
      )}
      interactive
    >
      <ItemStatusContainer variant="warning" onClick={open}>
        <StatusIcon
          $statusVariant="warning"
          className="mr-1"
          size={16}
          value="error"
        />
        <Text.Body className="text-gray-3" size="tiny">
          {t("shoppingCart.status.errors.unreviewed", "Unreviewed")}
        </Text.Body>
      </ItemStatusContainer>
    </Tooltip>
  )
}

export const ItemStatusContainer = styled(InlineFlex)<{
  variant: ItemStatusVariant
}>`
  margin-top: 4px;
  padding-left: 2px;
  padding-right: 4px;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  width: fit-content;

  ${props =>
    variant({
      variants: {
        warning: { background: props.theme.colors.lightSeaHorse },
        error: { background: props.theme.colors.lightCoral },
      },
    })}
`

export const StatusIcon = styled(Icon)<{ $statusVariant: ItemStatusVariant }>`
  ${props =>
    variant({
      prop: "$statusVariant",
      variants: {
        warning: { color: props.theme.colors.darkSeaHorse },
        error: { color: props.theme.colors.darkCoral },
      },
    })}
`
