import React from "react"
import { useTheme } from "@/design-system/Context"
import { Dropdown } from "@/design-system/Dropdown"
import { LinkItem as LinkItemType } from "@/lib/helpers/links"

type LinkItemProps = LinkItemType

export const LinkItem = ({
  label,
  icon,
  url,
  onClick,
  image,
  sideContent,
}: LinkItemProps) => {
  const { theme } = useTheme()
  return (
    <Dropdown.Item href={url} key={url} onClick={onClick}>
      {image ? (
        <Dropdown.Item.Avatar src={`${image}-${theme}.svg`} />
      ) : icon ? (
        <Dropdown.Item.Avatar icon={icon} />
      ) : null}
      <Dropdown.Item.Content>
        <Dropdown.Item.Title>{label}</Dropdown.Item.Title>
      </Dropdown.Item.Content>

      {sideContent && (
        <Dropdown.Item.Side className="ml-4">{sideContent}</Dropdown.Item.Side>
      )}
    </Dropdown.Item>
  )
}
