import React from "react"
import { Dropdown } from "@/design-system/Dropdown"
import { useTranslate } from "@/hooks/useTranslate"
import { useIntercom } from "@/hooks/useIntercom"
import { SUPPORT_URL } from "@/constants"
import { useTrackingFn } from "@/lib/analytics/hooks/useTrackingFn"

declare global {
  interface Window {
    Intercom: (command: string, ...args: any[]) => void
  }
}

export const IntercomSupportItem = () => {
  const t = useTranslate("common")
  const isIntercomAvailable = useIntercom()
  const trackClick = useTrackingFn<{ inApp: boolean }>(
    "intercom support item click",
  )

  const handleClick = () => {
    if (isIntercomAvailable) {
      window.Intercom("onShow", function () {
        window.Intercom("show")
      })
      trackClick({ inApp: true })
    } else {
      window.open(SUPPORT_URL, "_blank")
      trackClick({ inApp: false })
    }
  }

  return (
    <Dropdown.Item className="intercom-link" onClick={handleClick}>
      <Dropdown.Item.Avatar icon="contact_support" />
      <Dropdown.Item.Content>
        <Dropdown.Item.Title>
          {t("learnLinks.support", "Support")}
        </Dropdown.Item.Title>
      </Dropdown.Item.Content>
    </Dropdown.Item>
  )
}
