import { useState, useEffect } from "react"

export const useIntercom = () => {
  const [isIntercomAvailable, setIsIntercomAvailable] = useState(false)

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      typeof window.Intercom === "function"
    ) {
      setIsIntercomAvailable(true)
      return () => null
    } else {
      const checkIntercom = setInterval(() => {
        if (
          typeof window !== "undefined" &&
          typeof window.Intercom === "function"
        ) {
          setIsIntercomAvailable(true)
          clearInterval(checkIntercom)
        }
      }, 100)
      return () => clearInterval(checkIntercom)
    }
  }, [])

  return isIntercomAvailable
}
