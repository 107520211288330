import React, { useMemo } from "react"
import { Separator, SpaceBetween, Text, FlexColumn } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import {
  getTokenPricePayment,
  TokenPrice,
} from "@/components/assets/price/TokenPrice.react"
import { Block } from "@/design-system/Block"
import { TotalPricePerSymbol } from "@/features/shopping-cart/hooks/useTotalPrice"
import { useTranslate } from "@/hooks/useTranslate"
import { PriceTotals_ordersFilled$key } from "@/lib/graphql/__generated__/PriceTotals_ordersFilled.graphql"
import { bn, displayFiat } from "@/lib/helpers/numberUtils"

type Props = {
  ordersFilled: PriceTotals_ordersFilled$key
  totalPricePerSymbol: TotalPricePerSymbol
}

export const PriceTotals = ({
  ordersFilled: ordersFilledDataKey,
  totalPricePerSymbol,
}: Props) => {
  const { successfulOrders, failedOrders, transaction } = useFragment(
    graphql`
      fragment PriceTotals_ordersFilled on BulkPurchaseFilledType {
        successfulOrders {
          order {
            payment {
              ...TokenPricePayment
            }
          }
          pricePaid {
            unit
            usd
            symbol
          }
        }
        failedOrders {
          orderData {
            payment {
              symbol
              ...TokenPricePayment
            }
          }
        }
        transaction {
          transactionFee {
            unit
            symbol
            usd
          }
          payment {
            ...TokenPricePayment
          }
        }
      }
    `,
    ordersFilledDataKey,
  )

  const t = useTranslate("bulkPurchase")

  const paymentsUsed = useMemo(
    () =>
      Array.from(
        new Set([
          ...successfulOrders.map(({ pricePaid }) => pricePaid.symbol),
          ...failedOrders.map(({ orderData }) => orderData.payment.symbol),
        ]),
      ),
    [failedOrders, successfulOrders],
  )

  const subtotalPricePaidPerSymbol = useMemo(
    () =>
      paymentsUsed.reduce((acc, symbol) => {
        acc[symbol] = successfulOrders
          .filter(({ pricePaid }) => pricePaid.symbol === symbol)
          .reduce(
            (acc, { pricePaid, order: { payment } }) => ({
              price: acc.price.plus(pricePaid.unit),
              usdPrice: acc.usdPrice.plus(pricePaid.usd),
              tokenPricePayment: getTokenPricePayment(payment),
            }),
            {
              price: bn(0),
              usdPrice: bn(0),
              tokenPricePayment: { symbol },
            },
          )

        return acc
      }, {} as TotalPricePerSymbol),
    [paymentsUsed, successfulOrders],
  )

  const totalPricePaidPerSymbol = useMemo(() => {
    return {
      ...subtotalPricePaidPerSymbol,
      [transaction.transactionFee.symbol]: {
        price: bn(transaction.transactionFee.unit).plus(
          subtotalPricePaidPerSymbol[transaction.transactionFee.symbol]
            ?.price || 0,
        ),
        usdPrice: bn(transaction.transactionFee.usd).plus(
          subtotalPricePaidPerSymbol[transaction.transactionFee.symbol]
            ?.usdPrice || 0,
        ),
        tokenPricePayment: getTokenPricePayment(transaction.payment),
      },
    }
  }, [
    subtotalPricePaidPerSymbol,
    transaction.payment,
    transaction.transactionFee.symbol,
    transaction.transactionFee.unit,
    transaction.transactionFee.usd,
  ])

  const totalUnusedAmountPerSymbol = useMemo(() => {
    return Object.keys(totalPricePerSymbol).reduce<TotalPricePerSymbol>(
      (acc, symbol) => {
        const totalPrice = totalPricePerSymbol[symbol]
        const totalPricePaid = subtotalPricePaidPerSymbol[symbol]
        if (!totalPrice) {
          return acc
        }
        if (!totalPricePaid) {
          return { ...acc, [symbol]: totalPrice }
        }
        if (totalPrice.price.isLessThanOrEqualTo(totalPricePaid.price)) {
          return acc
        }
        return {
          ...acc,
          [symbol]: {
            price: totalPrice.price.minus(totalPricePaid.price),
            usdPrice: totalPrice.usdPrice.minus(totalPricePaid.usdPrice),
            tokenPricePayment: totalPrice.tokenPricePayment,
          },
        }
      },
      {},
    )
  }, [totalPricePerSymbol, subtotalPricePaidPerSymbol])

  const hasUnusedAmount = Object.keys(totalUnusedAmountPerSymbol).length > 0

  const totalUsdAmount = useMemo(
    () =>
      successfulOrders
        .reduce((acc, { pricePaid }) => acc.plus(pricePaid.usd), bn(0))
        .plus(transaction.transactionFee.usd),
    [successfulOrders, transaction.transactionFee.usd],
  )

  return (
    <>
      <SpaceBetween data-testid="bulk-purchase-subtotal">
        <Text.Body>{t("subtotal", "Subtotal")}</Text.Body>
        <FlexColumn className="items-end">
          {Object.values(
            hasUnusedAmount ? totalPricePerSymbol : subtotalPricePaidPerSymbol,
          ).map(
            price =>
              price && (
                <Text.Body key={price.tokenPricePayment.symbol} size="medium">
                  <TokenPrice
                    {...price.tokenPricePayment}
                    fontWeight={400}
                    key={price.tokenPricePayment.symbol}
                    price={price.price}
                    symbolVariant="raw"
                  />
                </Text.Body>
              ),
          )}
        </FlexColumn>
      </SpaceBetween>
      <SpaceBetween>
        <Text.Body>{t("gasFees", "Gas fees")}</Text.Body>
        <Text.Body>
          <TokenPrice
            fontWeight={400}
            {...getTokenPricePayment(transaction.payment)}
            price={bn(transaction.transactionFee.unit)}
            symbolVariant="raw"
          />
        </Text.Body>
      </SpaceBetween>
      {hasUnusedAmount && (
        <SpaceBetween>
          <Text.Body>{t("notUsed", "Not used")}</Text.Body>
          <FlexColumn className="items-end">
            {Object.values(totalUnusedAmountPerSymbol).map(
              price =>
                price && (
                  <Text.Body key={price.tokenPricePayment.symbol} size="medium">
                    <TokenPrice
                      {...price.tokenPricePayment}
                      fontWeight={400}
                      key={price.tokenPricePayment.symbol}
                      price={price.price.negated()}
                      symbolVariant="raw"
                    />
                  </Text.Body>
                ),
            )}
          </FlexColumn>
        </SpaceBetween>
      )}
      <Separator className="my-4" />

      <SpaceBetween>
        <Block>
          <Text.Body weight="semibold">
            {t("totalPrice", "Total price")}
          </Text.Body>
        </Block>
        <Block>
          <FlexColumn className="items-end">
            {Object.values(totalPricePaidPerSymbol).map(
              price =>
                price && (
                  <Text.Body
                    key={price.tokenPricePayment.symbol}
                    size="medium"
                    weight="semibold"
                  >
                    <TokenPrice
                      {...price.tokenPricePayment}
                      fontWeight={600}
                      price={price.price}
                      symbolVariant="raw"
                    />
                  </Text.Body>
                ),
            )}
            <Text.Body className="text-secondary" size="small">
              {displayFiat(totalUsdAmount)}
            </Text.Body>
          </FlexColumn>
        </Block>
      </SpaceBetween>
    </>
  )
}
