import React, { useContext } from "react"
import {
  FlexColumn,
  Icon,
  Spinner,
  Text,
  classNames,
  Skeleton,
} from "@opensea/ui-kit"
import { Flex } from "@/design-system/Flex"
import { Modal } from "@/design-system/Modal"
import { DropActionContext } from "@/features/collections/components/CollectionCreateOrUpdatePage/components/DropActionContext/DropActionContext.react"
import { StepIndicator } from "@/features/primary-drops/components/StepIndicator/StepIndicator.react"
import { useTranslate } from "@/hooks/useTranslate"

type LOADING_STATE = 0 | 1 | 2

export const LOADING_CONTENT_STATE = {
  SETTING_UP_TRANSACTION: 0,
  WAITING_SIGNATURE: 1,
  PENDING_TRANSACTION: 2,
} as const

export const LoadingContent = ({
  state,
  customRender,
  unpublish,
}: {
  state: LOADING_STATE
  customRender?: React.ReactNode
  unpublish?: boolean
}) => {
  const t = useTranslate("collectionEdit")
  const ariaLabel = useAriaLabel()
  const copy = useCopyContent(!!unpublish)
  const steps = unpublish
    ? [
        LOADING_CONTENT_STATE.WAITING_SIGNATURE,
        LOADING_CONTENT_STATE.PENDING_TRANSACTION,
      ]
    : [
        LOADING_CONTENT_STATE.SETTING_UP_TRANSACTION,
        LOADING_CONTENT_STATE.WAITING_SIGNATURE,
        LOADING_CONTENT_STATE.PENDING_TRANSACTION,
      ]

  const { ready, isAlreadyPublished } = useContext(DropActionContext)

  const title = unpublish
    ? t("publishingDrop.loading.title.unpublish", "Unpublishing drop")
    : isAlreadyPublished
    ? t("publishingDrop.loading.title.alreadyPublished", "Saving updates")
    : t("publishingDrop.loading.title.firstTimePublishing", "Publishing drop")

  return (
    <>
      <Modal.Header>
        <Modal.Header.Title>
          {!ready ? <Skeleton.Line className="h-6 w-[180px]" /> : title}
        </Modal.Header.Title>
      </Modal.Header>
      <Modal.Body>
        <FlexColumn aria-label={ariaLabel[state]} role="dialog">
          {steps.map(loadingState => {
            return (
              <>
                <Flex className="relative">
                  {loadingState < 2 && <Line />}
                  <StepIndicator.Circle
                    className={classNames(
                      "min-h-[48px] !min-w-[48px] rounded-full ",
                      {
                        "!border-solid border-level-2 border !bg-transparent":
                          state < loadingState,
                        "!bg-component-gray-1": state >= loadingState,
                      },
                    )}
                    isActive={false}
                    isPast={false}
                  >
                    {state === loadingState ? (
                      <Spinner />
                    ) : state > loadingState ? (
                      <Icon className="text-primary" size={32} value="done" />
                    ) : null}
                  </StepIndicator.Circle>
                  <FlexColumn className="mb-6 mt-2.5 pl-6">
                    <Text.Body size="medium" weight="semibold">
                      {copy[loadingState].title}
                    </Text.Body>
                    <Text.Body
                      className="mb-2 mt-0.5"
                      color="secondary"
                      size="small"
                    >
                      {copy[loadingState].description}
                    </Text.Body>
                    {state === loadingState && customRender}
                  </FlexColumn>
                </Flex>
              </>
            )
          })}
        </FlexColumn>
      </Modal.Body>
    </>
  )
}

const Line = () => (
  <Flex className="absolute -bottom-2.5 left-6 top-[58px] w-px bg-border-1" />
)

const useAriaLabel = () => {
  const t = useTranslate("collectionEdit")
  return {
    [LOADING_CONTENT_STATE.SETTING_UP_TRANSACTION]: t(
      "publishingDrop.loading.settingUpTransaction.ariaDescription",
      "Setting up transaction dialog",
    ),
    [LOADING_CONTENT_STATE.WAITING_SIGNATURE]: t(
      "publishingDrop.loading.waitingSignature.ariaDescription",
      "Waiting for signature dialog",
    ),
    [LOADING_CONTENT_STATE.PENDING_TRANSACTION]: t(
      "publishingDrop.loading.pendingTransaction.ariaDescription",
      "Pending transaction confirmation dialog",
    ),
  }
}

const useCopyContent = (unpublish: boolean) => {
  const t = useTranslate("collectionEdit")
  return {
    [LOADING_CONTENT_STATE.SETTING_UP_TRANSACTION]: {
      title: t(
        "publishingDrop.loading.settingUpTransaction.title",
        "Compiling drop details",
      ),
      description: t(
        "publishingDrop.loading.settingUpTransaction.description",
        "This may take a few minutes.",
      ),
    },
    [LOADING_CONTENT_STATE.WAITING_SIGNATURE]: {
      title: t(
        "publishingDrop.loading.waitingSignature.title",
        "Awaiting signature",
      ),
      description: unpublish
        ? t(
            "publishingDrop.loading.waitingSignature.unpublishDescription",
            "A blockchain transaction is required to unpublish your drop. Go to your wallet to sign and approve this transaction.",
          )
        : t(
            "publishingDrop.loading.waitingSignature.description",
            "A blockchain transaction is required to mint your schedule details on the blockchain. Go to your wallet to sign and approve this transaction.",
          ),
    },
    [LOADING_CONTENT_STATE.PENDING_TRANSACTION]: {
      title: unpublish
        ? t(
            "publishingDrop.loading.pendingTransaction.unpublishTitle",
            "Unpublishing your drop",
          )
        : t(
            "publishingDrop.loading.pendingTransaction.title",
            "Publishing your drop",
          ),
      description: t(
        "publishingDrop.loading.pendingTransaction.description",
        "Please stay on this page and keep this browser tab open. This may take a few minutes.",
      ),
    },
  }
}
