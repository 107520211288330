/**
 * @generated SignedSource<<991765cefccea4c6f8bd8964c682de0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AssetContractTokenStandard = "AUCTION_HOUSE" | "BITKEEP" | "BLUR" | "CONSIDERATION" | "CRYPTOKITTIES" | "CRYPTOPUNKS" | "CUSTOM" | "DECENTRALAND" | "ELEMENT" | "ENJIN_LEGACY" | "ERC1155" | "ERC20" | "ERC721" | "ETH_BRIDGE" | "JOEPEGS" | "KNOWNORIGIN" | "LOOKSRARE" | "MAKERSPLACE" | "METAPLEX" | "SEADROP" | "SOLANA" | "SPL" | "SUPERRARE" | "TOFU" | "TRANSFER_HELPER" | "TROVE" | "UNKNOWN" | "WYVERN" | "X2Y2" | "ZERO_EX" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SwapDataSide_assets$data = ReadonlyArray<{
  readonly asset: {
    readonly assetContract: {
      readonly tokenStandard: AssetContractTokenStandard | null;
    };
    readonly displayName: string;
    readonly relayId: string;
    readonly symbol: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"StackedAssetMedia_assets">;
  };
  readonly quantity: string;
  readonly " $fragmentType": "SwapDataSide_assets";
}>;
export type SwapDataSide_assets$key = ReadonlyArray<{
  readonly " $data"?: SwapDataSide_assets$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwapDataSide_assets">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SwapDataSide_assets",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetType",
      "kind": "LinkedField",
      "name": "asset",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "relayId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "symbol",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetContractType",
          "kind": "LinkedField",
          "name": "assetContract",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tokenStandard",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "StackedAssetMedia_assets"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantity",
      "storageKey": null
    }
  ],
  "type": "AssetQuantityDataType",
  "abstractKey": null
};

(node as any).hash = "c9d12110e5ef8930e19b7c2662d8a358";

export default node;
