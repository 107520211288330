import React, { useCallback } from "react"
import { CenterAligned, Spinner } from "@opensea/ui-kit"
import { noop } from "lodash"
import dynamic from "next/dynamic"
import { createContext, useContextSelector } from "use-context-selector"
import { DepositModal } from "@/components/trade/AddFundsModal/components/DepositModal.react"
import { IS_TESTNET } from "@/constants/testnet"
import { GlobalModalProps } from "@/containers"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { MOONPAY_KEY } from "@/features/moonpay/constants"
import { useGlobalModal } from "@/hooks/useGlobalModal"
import { useIsMoonPayAllowed } from "../../utils/useIsMoonPayAllowed"
import { WIDGET_HEIGHT, WIDGET_MAX_HEIGHT } from "../constants"
import { MoonPayBuyModal, MoonPayBuyModalProps } from "../MoonPayBuyModal"
import { getMoonPayCurrencyCode } from "../MoonPayBuyModal/utils"

const MoonPaySdkProvider = dynamic(
  () => import("@moonpay/moonpay-react").then(mod => mod.MoonPayProvider),
  {
    ssr: false,
    loading: () => (
      <CenterAligned
        style={{ height: WIDGET_HEIGHT, maxHeight: WIDGET_MAX_HEIGHT }}
      >
        <Spinner size="medium" />
      </CenterAligned>
    ),
  },
)

const withMoonPaySdkProvider = (children: React.ReactNode) => (
  <MoonPaySdkProvider apiKey={MOONPAY_KEY}>{children}</MoonPaySdkProvider>
)

type MoonPayContextType = {
  openBuyWidget: (
    props: MoonPayBuyModalProps,
    openFn?: (children: React.ReactNode, modalProps: GlobalModalProps) => void,
  ) => void
}

export const MoonPayContext = createContext<MoonPayContextType>({
  openBuyWidget: noop,
})

type Props = {
  children: React.ReactNode
}

export const MoonPayProvider = ({ children }: Props) => {
  const { openModal } = useGlobalModal()
  const { isTopupAllowed } = useIsMoonPayAllowed({ allowedByDefault: true })
  const connectedAddress = useConnectedAddress()

  const openBuyWidget = useCallback(
    (
      props?: MoonPayBuyModalProps,
      openFn: (
        children: React.ReactNode,
        modalProps: GlobalModalProps,
      ) => void = openModal,
    ) => {
      if (!connectedAddress) {
        throw new Error(
          "Cannot open MoonPay buy widget without a connected wallet",
        )
      }

      const isCurrencySupplied = !!props?.symbol
      const isCurrencySupported =
        !isCurrencySupplied || IS_TESTNET
          ? true
          : getMoonPayCurrencyCode(props.symbol, props.chain) !== undefined

      if (isTopupAllowed && isCurrencySupported) {
        openFn(withMoonPaySdkProvider(<MoonPayBuyModal {...props} />), {
          closeOnOverlayClick: true,
          closeOnEscape: true,
          closable: false,
          responsive: true,
        })
      } else {
        openFn(
          <DepositModal address={connectedAddress} symbol={props?.symbol} />,
          {},
        )
      }
    },
    [connectedAddress, isTopupAllowed, openModal],
  )

  return (
    <MoonPayContext.Provider value={{ openBuyWidget }}>
      {children}
    </MoonPayContext.Provider>
  )
}

export const useOpenMoonPayBuyWidget = () =>
  useContextSelector(MoonPayContext, ctx => ctx.openBuyWidget)
