import React from "react"
import { UnstyledButton, Text, Flex } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { Link } from "@/components/common/Link"
import { Tooltip } from "@/design-system/Tooltip"

export type Tab<T extends string> = {
  value: T
  label: string
  href?: string
  disabledReason?: React.ReactNode
}

type TabsProps<T extends string> = {
  tabs: Tab<T>[]
  activeTab: T
  handleClick: (tab: T) => unknown
}

export const Tabs = <T extends string>({
  tabs,
  activeTab,
  handleClick,
}: TabsProps<T>) => {
  return (
    <Flex>
      {tabs.map(({ value, label, href, disabledReason }) => {
        const isActive = value === activeTab
        const linkOrButtonProps = href
          ? {
              href,
              as: Link,
            }
          : {
              onClick: () => handleClick(value),
            }
        return (
          <TabButton
            {...linkOrButtonProps}
            disabled={Boolean(disabledReason)}
            isActive={isActive}
            key={value}
          >
            <Tooltip content={disabledReason} disabled={!disabledReason}>
              <Text.Body weight="semibold">{label}</Text.Body>
            </Tooltip>
          </TabButton>
        )
      })}
    </Flex>
  )
}

type TabButtonProps = {
  isActive: boolean
}

const TabButton = styled(UnstyledButton)<TabButtonProps>`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  width: 50%;
  justify-content: center;
  padding: 16px;
  position: relative;

  border-color: ${props => props.theme.colors.components.border.level2};

  ${props =>
    props.isActive
      ? css`
          ::after {
            content: " ";
            border-bottom: 2px solid ${props.theme.colors.text.primary};
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
          }
        `
      : css`
          color: ${props.theme.colors.text.secondary};
          transition: color 0.2s ease-out;

          // Override a style from Tooltip
          :hover:not([disabled]),
          :focus {
            color: ${props.theme.colors.text.primary};
          }
        `}
`
