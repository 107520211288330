import React from "react"
import { Flex } from "@opensea/ui-kit"
import { InfoIcon } from "@/components/common/InfoIcon.react"
import { useTranslate } from "@/hooks/useTranslate"

export const EscrowFundsInfo = () => {
  const t = useTranslate("components")
  return (
    <Flex>
      {t("escrowFundsInfo.offerBalance.title", "Offer balance")}
      <InfoIcon
        overrides={{
          Button: {
            style: { marginLeft: "4px" },
          },
          Icon: {
            size: 18,
          },
          Tooltip: {
            maxWidth: 290,
          },
        }}
        tooltipContent={t(
          "escrowFundsInfo.offerBalance.info",
          "Offers using SOL are made from your offer balance. You can easily transfer between your SOL balances.",
        )}
      />
    </Flex>
  )
}
