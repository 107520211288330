/**
 * @generated SignedSource<<a5e478ed37f70bb2501576d1c2a7fc4a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type OrderV2OrderType = "BASIC" | "CRITERIA" | "DUTCH" | "ENGLISH" | "%future added value";
export type OrderV2Side = "ASK" | "BID" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type readGaslessCancelResult_status$data = {
  readonly fulfillmentPossibleUntilChainTimestamp: string | null;
  readonly isFinalized: boolean;
  readonly order: {
    readonly orderType: OrderV2OrderType;
    readonly relayId: string;
    readonly remainingQuantityType: string;
    readonly side: OrderV2Side | null;
  };
  readonly quantityCancelled: string;
  readonly " $fragmentType": "readGaslessCancelResult_status";
};
export type readGaslessCancelResult_status$key = {
  readonly " $data"?: readGaslessCancelResult_status$data;
  readonly " $fragmentSpreads": FragmentRefs<"readGaslessCancelResult_status">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readGaslessCancelResult_status"
};

(node as any).hash = "e9fe5652d72284ff9d060a1e01648173";

export default node;
