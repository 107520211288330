import { useCallback, useMemo } from "react"
import { useTrackingFn } from "@/lib/analytics/hooks/useTrackingFn"
import { JsonRpcError } from "@/lib/chain/provider"
import { getFirstGraphqlResponseErrorStatus } from "@/lib/graphql/error"
import { BlockchainAction } from "./types"
import { useGetCoreMarketplaceAction } from "./useGetCoreMarketplaceAction"
import { useTrackServerError } from "./useTrackServerError"

type Properties = {
  errorCode?: string
  blockchainAction: BlockchainAction
}

export const useTrackBlockchainError = () => {
  const trackingFn = useTrackingFn<Properties>(
    "core marketplace action blockchain error",
  )

  const coreMarketplaceAction = useGetCoreMarketplaceAction()
  const [trackServerError] = useTrackServerError()

  const track = useCallback(
    (properties: Properties) => (error: Error) => {
      // In some cases, we may encounter a server error in case of order posting i.e. when creating listings/offers
      if (getFirstGraphqlResponseErrorStatus(error)) {
        trackServerError({
          errorCode: getFirstGraphqlResponseErrorStatus(error),
        })
      } else if (
        JsonRpcError.isExecutionRevertedError(error) &&
        coreMarketplaceAction
      ) {
        return trackingFn({
          errorCode: new JsonRpcError(error).code,
          ...properties,
        })
      }

      return undefined
    },
    [coreMarketplaceAction, trackServerError, trackingFn],
  )

  const errorTags = useMemo(
    () => (coreMarketplaceAction ? { coreMarketplaceAction } : undefined),
    [coreMarketplaceAction],
  )

  return [track, errorTags] as const
}
