import type { EventProperties } from "../types"
import { honeypot } from "@honeypot-run/core"

import {
  ROLLOUT_PERCENTAGE,
  HONEYPOT_PROXY_URL,
} from "@/lib/analytics/constants"
import { add as amplitudeAdd } from "@amplitude/analytics-browser"
import { IS_DEVELOPMENT, IS_PREVIEW } from "@/constants/environment"

function shouldTrigger(): boolean {
  const randomNumber = Math.random() * 100
  return randomNumber < ROLLOUT_PERCENTAGE
}

let isInitialized = false
const isEnabled = shouldTrigger() || IS_DEVELOPMENT || IS_PREVIEW

honeypot.setup({
  url: HONEYPOT_PROXY_URL,
})

const honeypotTrackEvents = [
  // internal Amplitude events
  "Page View",
  "Start Session",
  "End Session",
  // OS events
  "session_start",
  "session_end",
  "connect wallet",
  "select wallet",
  "provider accounts change",
  "auction bid entered confirmed",
  "click make offer",
  "auth refresh due to expired token",
  "click link",
  "search",
]

export const initializeHoneypot = () => {
  try {
    if (isEnabled && !isInitialized) {
      // event-level screening for certain event types
      amplitudeAdd(
        honeypot.amplitudePlugin({
          enrichEventProperties: true,
          includeEvents: honeypotTrackEvents,
          trackIdentities: true,
        }),
      )
      isInitialized = true
    }
  } catch (error) {
    if (IS_DEVELOPMENT || IS_PREVIEW) {
      console.error(error, "Failed to initialize Honeypot")
    }
  }
}

export const maybeAddHoneypotProperties = async (
  eventName: string,
  eventProperties?: EventProperties,
): Promise<EventProperties> => {
  let honeypotInfo = null
  try {
    if (!honeypotTrackEvents.includes(eventName)) {
      honeypotInfo = { honeypot: { ignored: true } }
    } else if (HONEYPOT_PROXY_URL && isEnabled) {
      honeypotInfo = { honeypot: await honeypot.get() }
    }
  } catch (error) {
    honeypotInfo = { honeypot: { error: error } }
  }
  return Promise.resolve({
    ...eventProperties,
    ...honeypotInfo,
  })
}
