import React from "react"
import { Icon } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { ACCOUNT_COLLECTION_VERIFICATION_ARTICLE } from "@/constants/support-articles"
import { Popover } from "@/design-system/Popover"
import { Tooltip, TooltipPlacement } from "@/design-system/Tooltip"
import { useTranslate } from "@/hooks/useTranslate"
import { Config } from "@/lib/graphql/__generated__/AccountLink_data.graphql"
import { classNames } from "@/styles/styleUtils"
import { Link } from "./Link"
import { VerifiedIcon, IconVariant, IconSize } from "./VerifiedIcon.react"

type Props = {
  config?: Config | null
  showTooltip?: boolean
  variant?: IconSize
  isCompromised: boolean
  tooltipPlacement?: TooltipPlacement
  className?: string
}

type StaticCheckmarkProps = {
  variant: IconVariant
  size: IconSize
}

type InteractiveCheckmarkProps = {
  href: string
  content: React.ReactNode
  placement: TooltipPlacement
  className?: string
} & StaticCheckmarkProps

const InteractiveCheckmark = ({
  href,
  content,
  placement,
  className,
  ...iconProps
}: InteractiveCheckmarkProps) => (
  <Popover content={() => content} placement={placement}>
    <Link
      className={classNames("mb-0.5 block leading-[0px]", className)}
      href={href}
      rel="noreferrer"
      target="_blank"
    >
      <StyledVerifiedIcon {...iconProps} />
    </Link>
  </Popover>
)

const VerifiedContent = () => {
  const t = useTranslate("components")
  return (
    <>
      {t("accountBadge.verified", "This is a verified account.")}{" "}
      <Link href={ACCOUNT_COLLECTION_VERIFICATION_ARTICLE}>
        {t("accountBadge.learnMoreLink", "Learn more")}
      </Link>
    </>
  )
}

const AccountBadge = ({
  config,
  showTooltip = true,
  variant = "small",
  tooltipPlacement = "right",
  isCompromised,
  className,
}: Props) => {
  const t = useTranslate("components")
  const isVerified = config === "VERIFIED"

  if (!isVerified && !isCompromised) {
    return null
  }

  return isCompromised ? (
    <Tooltip
      content={t(
        "accountBadge.compromised.tooltip",
        "This account may be compromised",
      )}
      placement={tooltipPlacement}
    >
      <StyledIcon
        className={classNames(className, "cursor-pointer text-red-2")}
        value="error"
      />
    </Tooltip>
  ) : showTooltip ? (
    <InteractiveCheckmark
      className={className}
      content={<VerifiedContent />}
      href={ACCOUNT_COLLECTION_VERIFICATION_ARTICLE}
      placement={tooltipPlacement}
      size={variant}
      variant={config as IconVariant}
    />
  ) : (
    <StyledVerifiedIcon
      className={className}
      size={variant}
      variant={config as IconVariant}
    />
  )
}

const iconStyles = css`
  margin-left: 4px;
  vertical-align: top;
`

const StyledVerifiedIcon = styled(VerifiedIcon)`
  ${iconStyles}
  vertical-align: sub;
`

const StyledIcon = styled(Icon)`
  ${iconStyles}
`

export default AccountBadge
