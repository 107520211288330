import React, { useMemo } from "react"
import { Flex, Media, Text } from "@opensea/ui-kit"
import { ErrorBoundary } from "@sentry/nextjs"
import { useFragment } from "react-relay"
import styled, { CSSProperties } from "styled-components"
import AccountBadge from "@/components/common/AccountBadge.react"
import { ExternalLinkProps } from "@/components/common/ExternalLink"
import { Link } from "@/components/common/Link"
import { ProfileImage } from "@/components/common/ProfileImage.react"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { Block, BlockProps } from "@/design-system/Block"
import { Tooltip, TooltipPlacement } from "@/design-system/Tooltip"
import { interactiveStylesPrimary } from "@/design-system/utils/styles"
import { useTranslate } from "@/hooks/useTranslate"
import { AccountLink_data$key } from "@/lib/graphql/__generated__/AccountLink_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { getAccountUrl, GetAccountUrlOptions } from "@/lib/helpers/accounts"
import { formatAddress, isNullAddress } from "@/lib/helpers/address"
import { BigNumber, quantityDisplay } from "@/lib/helpers/numberUtils"
import { truncateText } from "@/lib/helpers/stringUtils"
import { selectClassNames } from "@/lib/helpers/styling"
import { captureNoncriticalError } from "@/lib/sentry"
import { Overflow } from "../common/Overflow"
import { AccountTooltip } from "./AccountTooltip.react"

export type AccountLinkProps = GetAccountUrlOptions & {
  className?: string
  dataKey: AccountLink_data$key
  isOwner?: boolean
  mode?: "light"
  variant?: "only-image" | "no-image" | "both"
  ownedQuantity?: BigNumber
  target?: ExternalLinkProps["target"]
  iconSize?: number
  handleOverflow?: boolean
  justifyContent?: CSSProperties["justifyContent"]
  fontWeight?: CSSProperties["fontWeight"]
  testId?: string
  isLink?: boolean
  showBadge?: boolean
  height?: BlockProps["height"]
  inline?: boolean
  tooltipPlacement?: TooltipPlacement
  overrides?: {
    Text?: {
      props: Partial<BlockProps>
    }
    AccountTooltip?: {
      width: BlockProps["width"]
    }
  }
}

const MAX_USERNAME_LEN = 21

const InnerAccountLink = ({
  className,
  dataKey,
  isOwner,
  mode,
  variant = "both",
  ownedQuantity,
  target,
  iconSize = 22,
  handleOverflow = true,
  justifyContent,
  fontWeight = "inherit",
  testId = "AccountLink",
  isLink = true,
  showBadge = true,
  height,
  tab,
  inline = true,
  tooltipPlacement,
  overrides,
  ...getAccountUrlOptions
}: AccountLinkProps) => {
  const t = useTranslate("components")
  const { isActiveAccount } = useWallet()

  const data = useFragment(
    graphql`
      fragment AccountLink_data on AccountType {
        address
        config
        isCompromised
        user {
          publicUsername
        }
        displayName
        ...ProfileImage_data
        ...wallet_accountKey
        ...accounts_url
      }
    `,
    dataKey,
  )

  const { address, user, config, isCompromised, displayName } = data
  const truncatedPublicUsername = user?.publicUsername
    ? truncateText(user.publicUsername, MAX_USERNAME_LEN)
    : displayName ?? undefined

  const displayText = isActiveAccount(data)
    ? "you"
    : truncatedPublicUsername || formatAddress(address)
  const href = getAccountUrl(data, { tab, ...getAccountUrlOptions })

  const disabled = isNullAddress(address)

  const profileImageContent = useMemo(
    () => (
      <Tooltip content={displayText}>
        <Block>
          <ProfileImage
            className={selectClassNames("AccountLink", {
              image: !mode,
              "light-image": mode === "light",
            })}
            data={data}
            size={iconSize}
          />
        </Block>
      </Tooltip>
    ),
    [data, displayText, iconSize, mode],
  )

  return tab === "created" ? (
    <>
      <Media lessThan="md">
        <Flex className="items-center">
          <Text size="small">{t("accounts.by", "By")}&nbsp;</Text>
          <AccountTooltip address={address} placement={tooltipPlacement}>
            <Link disabled={disabled} href={href}>
              <CreatorText size="small" weight="semibold">
                {displayText}
              </CreatorText>
            </Link>
          </AccountTooltip>
          {showBadge && (
            <AccountBadge
              config={config}
              isCompromised={!!isCompromised}
              tooltipPlacement="bottom"
              variant="tiny"
            />
          )}
        </Flex>
      </Media>
      <Media greaterThanOrEqual="md">
        <Flex className="items-center">
          <Text>{t("accounts.by", "By")}&nbsp;</Text>
          <AccountTooltip address={address} placement={tooltipPlacement}>
            <Link disabled={disabled} href={href}>
              <CreatorText weight="semibold">{displayText}</CreatorText>
            </Link>
          </AccountTooltip>
          {showBadge && (
            <AccountBadge
              config={config}
              isCompromised={!!isCompromised}
              variant="tiny"
            />
          )}
        </Flex>
      </Media>
    </>
  ) : (
    <DivContainer
      className={selectClassNames(
        "AccountLink",
        {
          "light-container": mode === "light",
          "ellipsis-overflow": variant !== "both" && handleOverflow,
          "variant-both": variant === "both",
        },
        className,
      )}
      data-testid={testId}
      fontWeight={fontWeight}
      height={height}
      inline={inline}
      justifyContent={justifyContent}
    >
      {variant !== "no-image" && (
        <Flex asChild={isLink} className="items-center" style={{ fontWeight }}>
          {isLink ? (
            <Link
              disabled={disabled}
              href={getAccountUrl(data)}
              target={target}
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                e.stopPropagation()
              }
            >
              {profileImageContent}
            </Link>
          ) : (
            profileImageContent
          )}
        </Flex>
      )}
      <>
        {isOwner ? (
          ownedQuantity ? (
            <>
              {truncateText(quantityDisplay(ownedQuantity), 19)}
              &nbsp;{t("accounts.ownedByMidfix", "owned by")}&nbsp;
            </>
          ) : (
            <>{t("accounts.ownedByPrefix", "Owned by")}&nbsp;</>
          )
        ) : (
          ""
        )}
        {variant !== "only-image" && (
          <AccountTooltip
            address={address}
            placement={tooltipPlacement}
            width={overrides?.AccountTooltip?.width}
          >
            <Block
              className={selectClassNames("AccountLink", {
                "light-text": mode === "light",
                "ellipsis-overflow": handleOverflow,
                "ellipsis-variant-both": handleOverflow && variant === "both",
              })}
              {...(isLink
                ? {
                    as: Link,
                    disabled,
                    href,
                    target,
                    onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
                      e.stopPropagation()
                    },
                  }
                : undefined)}
              fontWeight={fontWeight}
              {...overrides?.Text?.props}
            >
              <Overflow>{displayText}</Overflow>
            </Block>
          </AccountTooltip>
        )}
      </>
      {showBadge && (
        <AccountBadge config={config} isCompromised={isCompromised} />
      )}
    </DivContainer>
  )
}

/**
 * @deprecated Use AccountLinkV2 instead
 */
export const AccountLink = (props: AccountLinkProps) => {
  const t = useTranslate("components")

  return (
    <ErrorBoundary
      fallback={({ error }) => {
        captureNoncriticalError(error, { extras: { relayKey: props.dataKey } })
        return (
          <Text.Body>{t("accounts.accountLinkErrorText", "Error")}</Text.Body>
        )
      }}
    >
      <InnerAccountLink {...props} />
    </ErrorBoundary>
  )
}

const CreatorText = styled(Text.Body)`
  ${interactiveStylesPrimary}
`

const DivContainer = styled(Block)<
  Pick<AccountLinkProps, "inline" | "fontWeight">
>`
  display: ${props => (props.inline ? "inline-flex" : "flex")};
  align-items: center;
  width: 100%;
  color: ${props => props.theme.colors.text.primary};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 600)};

  &.AccountLink--variant-both {
    display: ${props => (props.inline ? "inline-flex" : "flex")};
  }

  .AccountLink--ellipsis-variant-both {
    display: ${props => (props.inline ? "inline-block" : "block")};
  }

  .AccountLink--ellipsis-overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .AccountLink--image {
    display: ${props =>
      props.inline
        ? "initial"
        : "flex"}; // match height of inline child (rendered by next/image) using flex if inline prop is false
    cursor: pointer;
    margin-right: 12px;
  }

  &.AccountLink--light-container {
    .AccountLink--light-image {
      border: 1px solid white;
      border-radius: 50%;
      margin: 0 4px;
    }

    .AccountLink--light-text {
      color: white;
    }

    &:hover {
      .AccountLink--light-image {
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.6);
      }

      .AccountLink--light-text {
        text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.6);
      }
    }
  }
`
DivContainer.defaultProps = {
  height: "24px",
}
