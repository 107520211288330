import React from "react"
import styled from "styled-components"
import {
  space,
  SpaceProps,
  shadow,
  ShadowProps,
  size,
  SizeProps,
  layout,
  LayoutProps,
  typography,
  TypographyProps,
  flex,
  FlexProps,
  flexbox,
  FlexboxProps,
  color,
  ColorProps,
} from "styled-system"

export type BlockProps = SpaceProps &
  ShadowProps &
  SizeProps &
  LayoutProps &
  TypographyProps &
  FlexProps &
  FlexboxProps &
  ColorProps & {
    className?: string
    as?: React.ComponentType | React.ElementType
    children?: React.ReactNode
    style?: React.CSSProperties
  }

const invalidDomProps = new Set([
  // Styled system props
  ...[space, shadow, size, layout, typography, flex, flexbox, color].flatMap(
    c => c.propNames ?? [],
  ),

  // Random props from various components in our app
  "iconSide",
  "isBackgroundTransparent",
  "showBorder",
  "outline",
  "first",
  "error",
  "inline",
  "toastXOffset",
  "toastYOffset",
  "anchorSide",
  "fullWidth",
  "isFullPageView",
  "buttonId",
  "assetMediaSize",
  "lockedBodyScroll",
  "isOpen",
  "inView",
  "bannerHeight",
  "isVisible",
  "transitionDuration",
  "stickyFromBreakpoint",
  "widthOffset",
  "largeBPHeight",
  "transitionTimingFunction",
  "topOffset",
  "isDragging",
  "xOffset",
  "pillVariant",
  "disableZoomOnHover",
  "renderLink",
  "renderImage",
  "borderRadius",
  "zIndex",
])

export const Block = styled.div.withConfig({
  // This is needed during the transition to tailwind. We don't want to render invalid DOM attributes.
  // Build this piece by piece.
  shouldForwardProp: prop => !invalidDomProps.has(prop),
})<BlockProps>`
  ${space}
  ${shadow}
  ${size}
  ${layout}
  ${typography}
  ${flex}
  ${flexbox}
  ${color}
`
