import { createContext } from "react"
import Publisher from "@/lib/helpers/publisher"

export type AppContextProps = {
  refetchPublisher: Publisher
  updateContext: <K extends keyof Omit<AppContextProps, "updateContext">>(
    context: Pick<AppContextProps, K>,
  ) => void
  isWalletSidebarOpen: boolean
  stopCurrentlyPlayingMedia?: (
    refToCompare: (HTMLAudioElement & HTMLVideoElement) | null,
  ) => void
}

export const DEFAULT_APP_CONTEXT: AppContextProps = {
  refetchPublisher: new Publisher(),
  updateContext: async () => {
    // noop
  },
  isWalletSidebarOpen: false,
}

export const AppContext = createContext(DEFAULT_APP_CONTEXT)
