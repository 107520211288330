/**
 * @generated SignedSource<<3efc88a007b2e39c347c582447da1809>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionDetailsContent_data$data = {
  readonly createdDate: string;
  readonly isMintable: boolean;
  readonly name: string;
  readonly nativePaymentAsset: {
    readonly symbol: string;
    readonly " $fragmentSpreads": FragmentRefs<"PaymentAssetLogo_data">;
  };
  readonly owner: {
    readonly address: string;
    readonly createdDate: string;
    readonly displayName: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"AccountLink_data">;
  } | null;
  readonly representativeAsset: {
    readonly assetContract: {
      readonly address: string;
      readonly blockExplorerLink: string;
    };
  } | null;
  readonly slug: string;
  readonly statsV2: {
    readonly totalSales: number;
    readonly totalSupply: number;
    readonly totalVolume: {
      readonly unit: string;
      readonly usd: string;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"SocialLinks_collection">;
  readonly " $fragmentType": "CollectionDetailsContent_data";
};
export type CollectionDetailsContent_data$key = {
  readonly " $data"?: CollectionDetailsContent_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionDetailsContent_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdDate",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionDetailsContent_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AccountLink_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionStatsV2Type",
      "kind": "LinkedField",
      "name": "statsV2",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceType",
          "kind": "LinkedField",
          "name": "totalVolume",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "usd",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalSales",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalSupply",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentAssetType",
      "kind": "LinkedField",
      "name": "nativePaymentAsset",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "symbol",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PaymentAssetLogo_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMintable",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SocialLinks_collection"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetType",
      "kind": "LinkedField",
      "name": "representativeAsset",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetContractType",
          "kind": "LinkedField",
          "name": "assetContract",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "blockExplorerLink",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};
})();

(node as any).hash = "9f1fb84e99f65caf4ddc3cfc79f904d3";

export default node;
