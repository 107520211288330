import { useIsLessThanSm } from "@opensea/ui-kit"
import { parseISO } from "date-fns"
import { useContextSelector } from "use-context-selector"
import { UnleashTogglesContext } from "@/containers/FlagProvider.react"
import { useChains } from "@/hooks/useChains"
import { ChainIdentifier } from "./useChains/types"
import { useVariant } from "./useVariant"

export const useFlag = (name: string) =>
  useContextSelector(UnleashTogglesContext, ({ toggles }) =>
    Boolean(toggles[name]?.enabled),
  )

export const useIsTraitSearchFloorPriceEnabled = (slug: string) => {
  const { enabled, payload, name } = useVariant(
    "enable_trait_search_floor_price",
  )

  if (!enabled) {
    return false
  }

  if (name === "disable_by_collection_slug") {
    return !payload.value.split(",").includes(slug)
  }

  return true
}

export const useIsFarcasterMintFrameEnabled = (slug?: string) => {
  const { enabled, payload, name } = useVariant("enable_farcaster_mint_frame")

  if (!enabled || !slug) {
    return false
  }

  if (name === "enable_by_collection_slug") {
    return payload.value.split(",").includes(slug)
  }

  return false
}

export const useIsTosModalEnabled = () => {
  return useFlag("is_tos_modal_enabled")
}

export const useRoyaltyRegistryEnforcementDate = () => {
  const { payload } = useVariant("is_eligible_creator_fee_checks_enabled")

  return payload ? parseISO(payload.value) : undefined
}

export const useRoyaltyRegistryEnforcedChains = () => {
  const { chains } = useChains()
  const { payload, enabled } = useVariant("royalty_registry_enabled_chains")

  if (!enabled) {
    return chains
  }

  return payload.value.split(",") as ChainIdentifier[]
}

export const useIsOrderFulfillAuthEnabled = () => {
  return useFlag("is_order_fulfill_auth_enabled")
}

export const useMarketplaceFeePromotionEnabled = () => {
  return useFlag("enable_marketplace_fee_promotion")
}

export const useIsVirtualizedTableViewEnabled = () => {
  return useFlag("collection_table_view_enabled")
}

export const useEnableMmuUuidPrefix = () => {
  return useFlag("enable_mmu_uuid_prefix")
}

export const useIsUnifiedBalanceEnabled = () => {
  return useFlag("unified_balance")
}

export const useNewOfferPrecision = () => {
  return useFlag("new_offer_precision_enabled")
}

export const useIsWalletPopoverSwapEnabled = () => {
  return useFlag("wallet_popover_swap")
}

export const useIsThemisEnabled = () => {
  return useFlag("project-themis")
}

export const useIsWethRequestDealsEnabled = () => {
  return useFlag("enable_weth_request_deals")
}

type UseOSProGlobalLinkingExperience = "disabled" | "default_enabled"

export const useOSProGlobalLinkingExperience =
  (): UseOSProGlobalLinkingExperience => {
    const isDefaultOsProLinkingEnabled = useFlag(
      "is_default_os_pro_linking_enabled",
    )

    if (!isDefaultOsProLinkingEnabled) {
      return "disabled"
    }

    return "default_enabled"
  }

export const useIs1155ContractDeployEnabled = () => {
  return useFlag("enable_1155_contract_deploy")
}

export const useOwnerListingPriceOnAccountPage = () => {
  return useFlag("use_owner_listing_price_on_account_page")
}

export const useIsTraitHoverPreviewEnabled = () => {
  // Enables various project periscope hover previews
  // - trait assets preview
  // - collection stats preview
  // Author: Matt Abrams
  // Date: 2023-06-13
  const isMobile = useIsLessThanSm()
  const isEnabled = useFlag("is_project_periscope_enabled")
  if (isMobile) {
    return false
  }
  return isEnabled
}

export const useIsStatsPageCollectionHoverPreviewEnabled = () => {
  // Author: Matt Abrams
  // Date: 2023-06-27
  const isEnabled = useFlag("is_stats_page_collection_hover_preview_enabled")
  const isMobile = useIsLessThanSm()
  if (isMobile) {
    return false
  }
  return isEnabled
}

export const useIsHomePageStatsCollectionHoverPreviewEnabled = () => {
  // Author: Matt Abrams
  // Date: 2023-06-23
  const isEnabled = useFlag(
    "is_home_page_stats_collection_hover_preview_enabled",
  )
  const isMobile = useIsLessThanSm()
  if (isMobile) {
    return false
  }
  return isEnabled
}

export const useIsAccountTooltipEnabled = () => {
  // Enables account tooltip hover previews
  // Author: Matt Abrams
  // Date: 2023-06-21
  const isEnabled = useFlag("is_account_tooltip_enabled")
  const isMobile = useIsLessThanSm()
  if (isMobile) {
    return false
  }
  return isEnabled
}

export const useIsRedeemPageEnabled = () => {
  // Enables the redeem page
  // Author: Robert Sun
  // Date: 2023-07-13
  return useFlag("is_redeem_page_enabled")
}

export const useIsManageRedeemableCampaignsEnabled = () => {
  // Enables the redeem page
  // Author: Robert Sun
  // Date: 2023-08-25
  return useFlag("is_manage_redeemable_campaigns_enabled")
}

export const useIsMerchandisingViewEnabled = () => {
  // Enables the merchandising view and collection page refresh
  // Author: Roy Liu
  // Date: 2023-07-24
  return useFlag("enable_merchandising_view")
}

export const useIsMintYourOwnEnabled = () => {
  // Enables the new creator studio
  // Author: Chase
  // Date: 2023-08-20
  return useFlag("is_mint_your_own_enabled")
}

export const useIsOGDealsEnabled = () => {
  // Enables open graph deals metadata
  //Author: Mike Judge
  //Date: 2023-09-07
  return useFlag("enable_og_deals")
}

export const useIsSSFEnabled = () => {
  // SSF is enabled if MYO is NOT enabled. Easier to read this way.
  // Author: Ian H
  // Date: 2023-10-02
  return !useFlag("is_mint_your_own_enabled")
}

export const useIsRedeemModuleEnabled = () => {
  // Enables the redeem module on the collection page
  // Robert Sun
  // 10/12/2023
  return useFlag("is_redeem_module_enabled")
}

export const useIsFloorPricePercentChangeEnabled = () => {
  // Enables the floor price percent change column on the stats page and the homepage
  // Lucas Myers
  // Date: 2023-10-18
  return useFlag("is_floor_price_percent_change_ui_enabled")
}

export const useIsMerchandisingGTMEnabled = () => {
  // Merchandising GTM stuff like callouts and modals
  // Author: Marcus B
  // Date: 2023-10-11
  return useFlag("is_merchandising_gtm_enabled")
}

export const useDropCollectionSlugRewrite: () => Record<
  string,
  string
> | null = () => {
  const { enabled, payload } = useVariant("enable_drop_collection_slug_rewrite")

  if (!enabled) {
    return null
  }

  return JSON.parse(payload.value)
}

export const useDisabledWallets = () => {
  // Disables given wallets as an option in the UI
  // Author: Roy L
  // Date: 2023-12-14
  const { enabled, payload } = useVariant("disabled_wallets")

  if (!enabled) {
    return []
  }

  return payload.value.split(",")
}

export const useIsCustomMintConfirmationCtaEnabled = (slug: string) => {
  const { enabled, payload } = useVariant("enable_custom_mint_cta")

  if (!enabled) {
    return null
  }

  const ctaBySlug = JSON.parse(payload.value)
  return ctaBySlug[slug] ?? null
}

export const useIsBaseOnchainRegistryEnabled = () => {
  // Enables the base onchain registry
  // See: https://docs.base.org/docs/tools/registry-api/
  // Author: Ian H.
  // Date: 2024-07-09
  return useFlag("enable_base_onchain_registry")
}

export const useIsOs2UpsellModalEnabled: () => {
  enabled: boolean
  dismissible?: boolean
} = () => {
  const { enabled, name, payload } = useVariant("enable_os2_upsell_modal")

  if (enabled) {
    return {
      enabled,
      dismissible: name === "dismissible" && payload.value === "true",
    }
  }

  return { enabled: false }
}
