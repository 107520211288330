import { graphql, useFragment } from "react-relay"
import { useFlag } from "@/hooks/useFlag"
import { useVariant } from "@/hooks/useVariant"
import { useIsRarityEnabled_collection$key } from "@/lib/graphql/__generated__/useIsRarityEnabled_collection.graphql"

export type UseIsRarityEnabledResult = {
  isRarityEnabled: boolean
  isRarityDisplayed: boolean
}

export const useIsRarityEnabled = (
  collectionDataKey: useIsRarityEnabled_collection$key | null,
): boolean => {
  const data = useFragment(
    graphql`
      fragment useIsRarityEnabled_collection on CollectionType {
        slug
        enabledRarities
      }
    `,
    collectionDataKey,
  )

  // Allow rarity to be displayed for whitelisted collections.
  // Two separate flags are used since we only employee gate on FE,
  // but check whitelisted collection slugs on both FE and BE.
  const isCollectionAllowlistEnabled = useFlag(
    "enable_rarity_collection_allowlist",
  )
  const { enabled, payload, name } = useVariant("rarity_collection_allowlist")

  if (!data) {
    return false
  }

  if (isCollectionAllowlistEnabled) {
    if (enabled && name === "collection-slugs") {
      const whitelistedSlugs = payload.value.split(",")
      const isCollectionWhitelisted =
        !!data.slug && whitelistedSlugs.includes(data.slug)
      if (isCollectionWhitelisted) {
        return true
      }
    }
  }

  return data.enabledRarities.includes("OPENRARITY")
}
