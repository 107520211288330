import React, { useCallback, useEffect, useState } from "react"
import {
  Icon,
  Text,
  Label,
  Input,
  CenterAligned,
  FlexColumn,
} from "@opensea/ui-kit"
import { graphql, useQueryLoader, useRelayEnvironment } from "react-relay"
import { useUpdateEffect } from "react-use"
import { useHandleBlockchainActions } from "@/components/blockchain/BlockchainActionList"
import {
  trackSubmitAddToOfferBalance,
  trackClickUnwrap,
  trackSubmitWithdrawFromOfferBalance,
  trackClickWrap,
} from "@/components/nav/WalletPopover/analytics"
import { useNativeCurrencyBalance } from "@/containers/WalletBalanceProvider/NativeCurrencyBalanceProvider.react"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { Tooltip } from "@/design-system/Tooltip"
import { useChains } from "@/hooks/useChains"
import { useForm } from "@/hooks/useForm"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useToasts } from "@/hooks/useToasts"
import { useTransactionProgress } from "@/hooks/useTransactionProgress"
import { useTranslate } from "@/hooks/useTranslate"
import { BridgeOrWrapFormQuery } from "@/lib/graphql/__generated__/BridgeOrWrapFormQuery.graphql"
import { isEthereum, isPolygon } from "@/lib/helpers/chainUtils"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"
import { useWalletPopoverContext } from "../../WalletPopoverContext.react"
import { BridgeOrWrapFormMaxButton } from "./components/BridgeOrWrapFormMaxButton.react"
import { Tabs } from "./components/Tabs.react"
import { BRIDGE_TIME_LOWER_LIMIT, BRIDGE_TIME_UPPER_LIMIT } from "./constants"
import { useActionTitle } from "./hooks/useActionTitle"
import { useChainCurrencies } from "./hooks/useChainCurrencies"
import { useDirectionOptions } from "./hooks/useDirectionOptions"
import { useGetQueryArgs } from "./hooks/useGetQueryArgs"
import { formatQuantity } from "./utils/formatQuantity"
import { resolveSwapAction } from "./utils/resolveSwapActions"
import { updateRelayStoreOnBridgeOrWrap } from "./utils/updateRelayStoreOnBridgeOrWrap"
import { useValidateQuantityInput } from "./utils/useValidateQuantityInput"

export const BRIDGE_OR_WRAP_FORM_QUERY = graphql`
  query BridgeOrWrapFormQuery(
    $address: AddressScalar!
    $wrappedCurrencySymbol: String!
    $wrappedCurrencyChain: ChainScalar!
    $baseCurrencySymbol: String!
    $baseCurrencyChain: ChainScalar!
    $baseCurrencyIsChainNativeCurrency: Boolean!
  ) {
    ...BridgeOrWrapFormMaxButton_data
      @arguments(
        address: $address
        wrappedCurrencySymbol: $wrappedCurrencySymbol
        wrappedCurrencyChain: $wrappedCurrencyChain
        baseCurrencySymbol: $baseCurrencySymbol
        baseCurrencyChain: $baseCurrencyChain
        baseCurrencyIsChainNativeCurrency: $baseCurrencyIsChainNativeCurrency
      )
  }
`

type BridgeOrWrapFormData = {
  quantity: string
}

const DEFAULT_FORM_DATA: BridgeOrWrapFormData = {
  quantity: "",
}

export type BridgeOrWrapDirection =
  | "out" //out from primary balance to secondary balance
  | "in" // in to primary balance from secondary balance

type BridgeOrWrapFormProps = {
  direction?: BridgeOrWrapDirection
  setDirection?: (direction: BridgeOrWrapDirection) => unknown
}

export const BridgeOrWrapForm = ({
  direction: directionProp = "out",
  setDirection: setDirectionProp,
}: BridgeOrWrapFormProps) => {
  const t = useTranslate("components")
  const { attempt, showSuccessMessage } = useToasts()
  const { swapAsset } = useHandleBlockchainActions()
  const { waitForTransaction } = useTransactionProgress()
  const { wallet } = useWallet()
  const { chain, switchChain } = useWallet()
  const { getChain, getChainName } = useChains()
  const { setStaleFund } = useWalletPopoverContext()
  const { refreshNativeCurrencyBalance } = useNativeCurrencyBalance()

  const [direction, setStateDirection] =
    useState<BridgeOrWrapDirection>(directionProp)
  const setDirection = useCallback(
    (direction: BridgeOrWrapDirection) => {
      setDirectionProp?.(direction)
      setStateDirection(direction)
    },
    [setDirectionProp],
  )
  useUpdateEffect(() => {
    setStateDirection(directionProp)
  }, [directionProp])

  const { baseCurrency, wrappedCurrency } = useChainCurrencies(chain)
  const actionTitle = useActionTitle(direction, chain)
  const tabs = useDirectionOptions(chain)

  const fromCurrency = direction === "out" ? baseCurrency : wrappedCurrency
  const toCurrency = direction === "out" ? wrappedCurrency : baseCurrency

  const environment = useRelayEnvironment()
  const {
    register,
    formState,
    handleSubmit,
    setValue,
    reset,
    setFocus,
    watch,
    trigger,
    getValues,
  } = useForm<BridgeOrWrapFormData>({
    mode: "onChange",
    defaultValues: {
      quantity: "",
    },
  })

  const quantity = watch("quantity")

  const [max, setMax] = useState(bn(Number.POSITIVE_INFINITY))
  const [isMaxSet, setIsMaxSet] = useState(false)
  const onClickMaxButton = (max: BigNumber) => {
    // Set displayed quantity to be max rounded down to max decimal places
    setValue("quantity", formatQuantity(max), {
      shouldValidate: true,
    })
    setIsMaxSet(true)
  }

  const getQuantityInputError = useValidateQuantityInput({
    max,
    symbol: fromCurrency.symbol,
  })

  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<BridgeOrWrapFormQuery>(BRIDGE_OR_WRAP_FORM_QUERY)

  const getQueryArgs = useGetQueryArgs()

  useMountEffect(() => {
    const unsubWallet = wallet.onChange(() => {
      const queryArgs = getQueryArgs()
      if (queryArgs) {
        loadQuery(queryArgs)
      }
    })

    setFocus("quantity")

    return () => {
      disposeQuery()
      unsubWallet()
    }
  })

  useEffect(() => {
    const queryArgs = getQueryArgs()
    if (queryArgs) {
      loadQuery(queryArgs)
    }
  }, [getQueryArgs, loadQuery])

  useUpdateEffect(() => {
    if (isMaxSet) {
      setValue("quantity", formatQuantity(max))
    }

    if (quantity.trim() !== "") {
      trigger("quantity")
    }
  }, [max])

  const [isSubmitting, setIsSubmitting] = useState(false)
  const onSubmit = handleSubmit(async ({ quantity }) => {
    setIsSubmitting(true)

    if (direction === "out") {
      trackSubmitAddToOfferBalance({ quantity })
    } else {
      trackSubmitWithdrawFromOfferBalance({ quantity })
    }

    // Use max quantity (unrounded) if max was set
    const swapQuantity = isMaxSet ? max.toString() : quantity

    await attempt(async () => {
      if (!chain) {
        throw new Error("Failed to get chain from wallet provider.")
      }

      // Perform swap
      const [swapAction, dispose] = await resolveSwapAction({
        quantity: swapQuantity,
        direction,
        baseCurrency,
        swapCurrency: wrappedCurrency,
      })

      try {
        const transaction = await swapAsset(swapAction)
        await waitForTransaction({
          hash: transaction.transactionHash,
          chain: fromCurrency.chain,
          onSuccess: async () => {
            // Reset form before updating local relay store (which triggers form validation)
            reset(DEFAULT_FORM_DATA)

            // Perform local updates for all chains other than Polygon since bridging takes long on Polygon
            if (!isPolygon(chain)) {
              const address = wallet.getActiveAddressStrict()

              // Update relay store
              updateRelayStoreOnBridgeOrWrap(environment, {
                quantity: swapQuantity,
                fromCurrency,
                toCurrency,
                address,
                direction,
                setStaleFund,
              })

              // Update native currency balance
              refreshNativeCurrencyBalance()
            }

            if (isEthereum(fromCurrency.chain) && isPolygon(toCurrency.chain)) {
              showSuccessMessage(
                t(
                  "bridgeOrWrapForm.onSubmit.bridgeTransactionCompleted",
                  "Deposit transaction completed. It will take {{bridgeTimeLowerLimit}}—{{bridgeTimeUpperLimit}} minutes to appear in your {{network}} balance.",
                  {
                    network: getChainName(toCurrency.chain),
                    bridgeTimeLowerLimit: BRIDGE_TIME_LOWER_LIMIT,
                    bridgeTimeUpperLimit: BRIDGE_TIME_UPPER_LIMIT,
                  },
                ),
              )
              // switch user back to polygon chain after submitting eth bridging transaction
              await switchChain(getChain(toCurrency.chain))
              return
            }

            showSuccessMessage(
              t(
                "bridgeOrWrapForm.onSubmit.transactionCompleted",
                "Transaction completed.",
              ),
            )
          },
        })
      } finally {
        // dispose of payment assets
        dispose()
        setIsSubmitting(false)
      }
    })
  })

  const polygonBridgingTooltipContent =
    isPolygon(chain) && direction === "out"
      ? t(
          "bridgeOrWrapForm.polygonBridgingTime",
          "It will take {{bridgeTimeLowerLimit}}—{{bridgeTimeUpperLimit}} minutes for funds to appear in your {{chainName}} balance.",
          {
            chainName: getChainName(chain),
            bridgeTimeLowerLimit: BRIDGE_TIME_LOWER_LIMIT,
            bridgeTimeUpperLimit: BRIDGE_TIME_UPPER_LIMIT,
          },
        )
      : undefined

  return (
    <FlexColumn asChild className="gap-8">
      <form onSubmit={onSubmit}>
        <Tabs
          activeTab={direction}
          handleClick={direction => {
            if (direction === "out") {
              trackClickWrap()
            } else {
              trackClickUnwrap()
            }

            setDirection(direction)
          }}
          tabs={tabs}
        />
        <Block className="relative w-full px-4">
          <Label
            className="relative block w-full"
            htmlFor="wallet-convert-form"
          >
            <Input
              aria-label={t("bridgeOrWrapForm.quantity.label", "Quantity")}
              endEnhancer={
                <Text.Heading className="text-secondary" size="small">
                  {direction === "out"
                    ? baseCurrency.symbol
                    : wrappedCurrency.symbol}
                </Text.Heading>
              }
              overrides={{
                Container: {
                  className: "flex items-baseline justify-center px-0",
                },
                Input: {
                  className:
                    "h-14 w-auto text-center text-display-lg text-secondary caret-text-secondary",
                },
              }}
              {...register("quantity", { validate: getQuantityInputError })}
              id="wallet-convert-form"
              maxLength={8}
              placeholder="0"
              size={getValues("quantity").length + 1 || 1} // add 1 to length for extra spacing around width characters like "4"
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === " ") {
                  // Prevent user from entering spaces (which throw off faux input)
                  event.preventDefault()
                }

                // User has changed the value from the max that was previously set
                setIsMaxSet(false)
              }}
            />
            <CenterAligned className="absolute right-0 top-0 h-full min-w-[56px]">
              <BridgeOrWrapFormMaxButton
                baseCurrency={baseCurrency}
                direction={direction}
                isAtMax={isMaxSet}
                queryReference={queryReference}
                setMax={setMax}
                wrappedCurrency={wrappedCurrency}
                onClick={onClickMaxButton}
              />
            </CenterAligned>
          </Label>
          {formState.errors.quantity?.message && (
            <CenterAligned className="absolute left-0 top-[calc(100%+4px)] w-full">
              <Text.Body className="text-red-2" size="small">
                <Icon
                  className="align-middle text-red-2"
                  size={14}
                  value="error"
                />{" "}
                {formState.errors.quantity.message}
              </Text.Body>
            </CenterAligned>
          )}
        </Block>
        <FlexColumn className="gap-5 px-4 pb-5">
          <Tooltip
            content={polygonBridgingTooltipContent}
            disabled={!polygonBridgingTooltipContent}
            placement="bottom"
          >
            <Button
              className="w-full"
              disabled={!formState.isValid || isSubmitting}
              isLoading={isSubmitting}
              type="submit"
            >
              {actionTitle}
            </Button>
          </Tooltip>
        </FlexColumn>
      </form>
    </FlexColumn>
  )
}
