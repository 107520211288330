/**
 * @generated SignedSource<<7a2444ff91865d2775427ed1214ba32d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type walletMultiQuery$variables = {
  addresses: ReadonlyArray<string>;
};
export type walletMultiQuery$data = {
  readonly getAccountsByAddresses: ReadonlyArray<{
    readonly address: string;
    readonly imageUrl: string;
    readonly isCompromised: boolean;
    readonly metadata: {
      readonly isBanned: boolean;
      readonly isDisabled: boolean;
    } | null;
    readonly nickname: string | null;
    readonly relayId: string;
    readonly user: {
      readonly email: string | null;
      readonly hasAffirmativelyAcceptedOpenseaTerms: boolean | null;
      readonly publicUsername: string | null;
      readonly relayId: string;
      readonly username: string;
    } | null;
  }>;
};
export type walletMultiQuery = {
  response: walletMultiQuery$data;
  variables: walletMultiQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "addresses"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "addresses",
    "variableName": "addresses"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nickname",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCompromised",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicUsername",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasAffirmativelyAcceptedOpenseaTerms",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "AccountMetadataType",
  "kind": "LinkedField",
  "name": "metadata",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBanned",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDisabled",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "walletMultiQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "getAccountsByAddresses",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserType",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "walletMultiQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "getAccountsByAddresses",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserType",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/),
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "044e6217aad563b8775cbe8ba6876a57",
    "id": null,
    "metadata": {},
    "name": "walletMultiQuery",
    "operationKind": "query",
    "text": "query walletMultiQuery(\n  $addresses: [AddressScalar!]!\n) {\n  getAccountsByAddresses(addresses: $addresses) {\n    address\n    imageUrl\n    nickname\n    relayId\n    isCompromised\n    user {\n      relayId\n      username\n      publicUsername\n      hasAffirmativelyAcceptedOpenseaTerms\n      email\n      id\n    }\n    metadata {\n      isBanned\n      isDisabled\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "80448f3c9975ae6b3c20598896a40791";

export default node;
