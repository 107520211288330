/**
 * @generated SignedSource<<4eae5b03f15986fbb7e356911ee3907c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShoppingCart_orders$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"BulkPurchaseModal_orders" | "ShoppingCartDetailedView_orders">;
  readonly " $fragmentType": "ShoppingCart_orders";
}>;
export type ShoppingCart_orders$key = ReadonlyArray<{
  readonly " $data"?: ShoppingCart_orders$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShoppingCart_orders">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ShoppingCart_orders",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShoppingCartDetailedView_orders"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BulkPurchaseModal_orders"
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};

(node as any).hash = "c8b303790fe23dd8f2b3a94580e394d4";

export default node;
