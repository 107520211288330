/* eslint-disable relay/must-colocate-fragment-spreads */
import { graphql } from "relay-runtime"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { usePollingQuery } from "@/hooks/usePollingQuery"
import { useVariant } from "@/hooks/useVariant"
import { WalletPopoverDataPollerClosedQuery } from "@/lib/graphql/__generated__/WalletPopoverDataPollerClosedQuery.graphql"
import { WalletPopoverDataPollerOpenQuery } from "@/lib/graphql/__generated__/WalletPopoverDataPollerOpenQuery.graphql"
import { WalletPopoverDataPollerStaleFundQuery } from "@/lib/graphql/__generated__/WalletPopoverDataPollerStaleFundQuery.graphql"
import { useGetQueryArgs as useGetFormBalancesQueryArgs } from "./components/BridgeOrWrapBalances/hooks/useGetQueryArgs"
import { useGetQueryArgs as useGetFormQueryArgs } from "./components/BridgeOrWrapForm/hooks/useGetQueryArgs"
import { useGetQueryArgs as useGetButtonQueryArgs } from "./components/WalletAndAccountButton/hooks/useGetQueryArgs"
import { useWalletPopoverContext } from "./WalletPopoverContext.react"

const DEFAULT_POLLING_INTERVAL = 10_000 // 10s

const useWalletPopoverDataPolling = () => {
  const { enabled, payload } = useVariant("wallet_popover_data_polling")

  if (payload && !Number.isNaN(Number(payload.value))) {
    return {
      pollingInterval: Number(payload.value),
      enabled,
    }
  }

  return {
    pollingInterval: DEFAULT_POLLING_INTERVAL,
    enabled,
  }
}

const WALLET_POPOVER_DATA_POLLER_CLOSED_QUERY = graphql`
  query WalletPopoverDataPollerClosedQuery(
    $address: AddressScalar!
    $wrappedCurrencySymbol: String!
    $wrappedCurrencyChain: ChainScalar!
  ) {
    # WalletAndAccountButton
    ...WalletAndAccountButtonFundsDisplay_data
      @arguments(
        address: $address
        wrappedCurrencySymbol: $wrappedCurrencySymbol
        wrappedCurrencyChain: $wrappedCurrencyChain
      )
  }
`

const WALLET_POPOVER_DATA_POLLER_OPEN_QUERY = graphql`
  query WalletPopoverDataPollerOpenQuery(
    $address: AddressScalar!
    $wrappedCurrencySymbol: String!
    $wrappedCurrencyChain: ChainScalar!
    $baseCurrencySymbol: String!
    $baseCurrencyChain: ChainScalar!
    $baseCurrencyIsChainNativeCurrency: Boolean!
  ) {
    # WalletAndAccountButton
    ...WalletAndAccountButtonFundsDisplay_data
      @arguments(
        address: $address
        wrappedCurrencySymbol: $wrappedCurrencySymbol
        wrappedCurrencyChain: $wrappedCurrencyChain
      )

    # BridgeOrWrapForm
    ...BridgeOrWrapBalancesFundsDisplay_data
      @arguments(
        address: $address
        wrappedCurrencySymbol: $wrappedCurrencySymbol
        wrappedCurrencyChain: $wrappedCurrencyChain
        baseCurrencySymbol: $baseCurrencySymbol
        baseCurrencyChain: $baseCurrencyChain
        baseCurrencyIsChainNativeCurrency: $baseCurrencyIsChainNativeCurrency
      )
    ...BridgeOrWrapFormMaxButton_data
      @arguments(
        address: $address
        baseCurrencySymbol: $baseCurrencySymbol
        baseCurrencyChain: $baseCurrencyChain
        wrappedCurrencySymbol: $wrappedCurrencySymbol
        wrappedCurrencyChain: $wrappedCurrencyChain
        baseCurrencyIsChainNativeCurrency: $baseCurrencyIsChainNativeCurrency
      )

    # NetworkBalanceDropdown
    ...NetworkTotalFundsUsd_data
      @arguments(address: $address, chain: $wrappedCurrencyChain)
    ...NetworkTokenBalances_data
      @arguments(address: $address, chain: $wrappedCurrencyChain)
  }
`

const WALLET_POPOVER_DATA_POLLER_STALE_FUND_QUERY = graphql`
  query WalletPopoverDataPollerStaleFundQuery(
    $address: AddressScalar!
    $symbol: String!
    $chain: ChainScalar!
  ) {
    wallet(address: $address) {
      fundsOf(symbol: $symbol, chain: $chain) {
        quantity
      }
    }
  }
`

type WalletPopoverDataPollerProps = {
  isWalletPopoverOpen: boolean
}

export const WalletPopoverDataPoller = ({
  isWalletPopoverOpen,
}: WalletPopoverDataPollerProps): null => {
  const { wallet } = useWallet()
  const { staleFund, setStaleFund } = useWalletPopoverContext()
  const { enabled: isPollingEnabled, pollingInterval } =
    useWalletPopoverDataPolling()

  const getButtonQueryArgs = useGetButtonQueryArgs()
  const getFormQueryArgs = useGetFormQueryArgs()
  const getFormBalancesQueryArgs = useGetFormBalancesQueryArgs()

  const buttonQueryArgs = getButtonQueryArgs()
  const formQueryArgs = getFormQueryArgs()
  const formBalancesQueryArgs = getFormBalancesQueryArgs()

  usePollingQuery<WalletPopoverDataPollerClosedQuery>(
    WALLET_POPOVER_DATA_POLLER_CLOSED_QUERY,
    buttonQueryArgs ?? undefined,
    {
      delay: pollingInterval,
      skip: isWalletPopoverOpen || staleFund !== undefined || !isPollingEnabled,
      skipOnHidden: true,
    },
  )

  usePollingQuery<WalletPopoverDataPollerOpenQuery>(
    WALLET_POPOVER_DATA_POLLER_OPEN_QUERY,
    buttonQueryArgs && formQueryArgs && formBalancesQueryArgs
      ? {
          address: buttonQueryArgs.address,
          wrappedCurrencySymbol: buttonQueryArgs.wrappedCurrencySymbol,
          wrappedCurrencyChain: buttonQueryArgs.wrappedCurrencyChain,
          baseCurrencySymbol: formBalancesQueryArgs.baseCurrencySymbol,
          baseCurrencyChain: formBalancesQueryArgs.baseCurrencyChain,
          baseCurrencyIsChainNativeCurrency:
            formBalancesQueryArgs.baseCurrencyIsChainNativeCurrency,
        }
      : undefined,
    {
      delay: pollingInterval,
      skip:
        !isWalletPopoverOpen || staleFund !== undefined || !isPollingEnabled,
      skipOnHidden: true,
    },
  )

  usePollingQuery<WalletPopoverDataPollerStaleFundQuery>(
    WALLET_POPOVER_DATA_POLLER_STALE_FUND_QUERY,
    staleFund && wallet.address
      ? {
          address: wallet.address,
          symbol: staleFund.symbol,
          chain: staleFund.chain,
        }
      : undefined,
    {
      delay: pollingInterval,
      skip: !isPollingEnabled,
      skipOnHidden: true,
      onPoll: data => {
        if (staleFund === undefined) {
          return
        }

        if (staleFund.quantity.isEqualTo(data.wallet.fundsOf.quantity)) {
          return // staleFund is still stale
        }

        setStaleFund(undefined)
      },
    },
  )

  return null
}
