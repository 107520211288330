/* eslint-disable tailwindcss/no-custom-classname */
import React, { useState } from "react"
import { Flex, Icon, Text, UnstyledButton } from "@opensea/ui-kit"
import { useTheme } from "@/design-system/Context"
import { Dropdown } from "@/design-system/Dropdown"
import { Image } from "@/design-system/Image"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useTranslate } from "@/hooks/useTranslate"
import { AnalyticsContextProvider } from "@/lib/analytics"
import { useExploreLinks } from "@/lib/helpers/links"
import { CategorySlug } from "../../constants"
import { CollectionsScroller } from "./CollectionsScroller.react"

const EVENT_SOURCE = "TrendingCollections"

export const TrendingCollections = () => {
  const t = useTranslate("components")
  const [category, setCategory] = useState<CategorySlug>()
  const [chain, setChain] = useState<ChainIdentifier>()
  const [label, setLabel] = useState("all categories")
  const exploreLinks = useExploreLinks()
  const { theme } = useTheme()

  return (
    <Flex asChild className="mb-[100px] mt-[60px] flex-col items-center">
      <section>
        <Flex className="justify-center">
          <Text.Heading
            asChild
            className="mx-2 my-14 text-center"
            size="medium"
          >
            <h2>
              {t("trending.trendingIn", "Trending in")}{" "}
              <Dropdown
                content={({ close, List, Item }) => (
                  <List>
                    {exploreLinks.map(link =>
                      link.label !== "New" ? (
                        <Item
                          key={link.url}
                          onClick={() => {
                            setCategory(link.trendingData.category)
                            setChain(link.trendingData.chain)
                            setLabel(
                              link.url === "/assets"
                                ? t(
                                    "trending.allCategories.lower",
                                    "all categories",
                                  )
                                : link.label,
                            )
                            close()
                          }}
                        >
                          <Item.Content>
                            <Flex className="items-center">
                              <Image
                                alt={`${link.label} category image`}
                                height={24}
                                src={`${link.image}-${theme}.svg`}
                                width={24}
                              />
                              <Item.Title className="ml-2">
                                {link.url === "/assets"
                                  ? t(
                                      "trending.allCategories.upper",
                                      "All Categories",
                                    )
                                  : link.label}
                              </Item.Title>
                            </Flex>
                          </Item.Content>
                        </Item>
                      ) : null,
                    )}
                  </List>
                )}
              >
                <Flex
                  asChild
                  className="inline-flex cursor-pointer items-center justify-center"
                >
                  <UnstyledButton>
                    <Text.Heading
                      asChild
                      className="text-center"
                      color="interactive-primary-styles"
                      size="medium"
                    >
                      <h3>{label}</h3>
                    </Text.Heading>
                    <Icon
                      className="TrendingCollections--icon text-blue-3"
                      value="expand_more"
                    />
                  </UnstyledButton>
                </Flex>
              </Dropdown>
            </h2>
          </Text.Heading>
        </Flex>
        <AnalyticsContextProvider eventSource={EVENT_SOURCE}>
          <CollectionsScroller
            variables={{
              categories: category ? [category] : undefined,
              chains: chain ? [chain] : undefined,
            }}
          />
        </AnalyticsContextProvider>
      </section>
    </Flex>
  )
}
