import { IS_SERVER } from "@/constants/environment"
import { IS_TESTNET } from "@/constants/testnet"
import type { WalletAccount } from "./wallet"

const OS_CONNECTED_ACCOUNTS_KEY = IS_TESTNET
  ? "os-connected-accounts-testnet"
  : "os-connected-accounts"

export const saveConnectedAccounts = (accounts: WalletAccount[]): void => {
  if (IS_SERVER) {
    return
  }
  localStorage.setItem(OS_CONNECTED_ACCOUNTS_KEY, JSON.stringify(accounts))
}

export const clearConnectedAccounts = (): void => {
  if (IS_SERVER) {
    return
  }
  localStorage.removeItem(OS_CONNECTED_ACCOUNTS_KEY)
}

export const getConnectedAccounts = (): WalletAccount[] => {
  if (IS_SERVER) {
    return []
  }
  const accounts = localStorage.getItem(OS_CONNECTED_ACCOUNTS_KEY)
  return accounts ? JSON.parse(accounts) : []
}
