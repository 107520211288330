import { MaterialIcon } from "@opensea/ui-kit"
import QP from "@/lib/qp/qp"
import { Tab } from "../types"

export type SettingsTab =
  | "general"
  | "account"
  | "profile"
  | "notifications"
  | "security"
  | "support"
  | "offers"
  | "featured"
  | "developer"
  | "verifications"

export const tabTypeParam = QP.makeEnumParam<Tab["type"]>({
  account: undefined,
  general: undefined,
  profile: undefined,
  notifications: undefined,
  offers: undefined,
  security: undefined,
  support: undefined,
  featured: undefined,
  developer: undefined,
  verifications: undefined,
})

export type SettingsTabProps = {
  tab: SettingsTab
  label: string
  icon: MaterialIcon
}

export const SETTINGS_TABS: SettingsTabProps[] = [
  { tab: "profile", label: "Profile", icon: "account_circle" },
  { tab: "featured", label: "Featured items", icon: "auto_awesome" }, // Only tab is used, label and icon are ignored
  {
    tab: "notifications",
    label: "Notifications",
    icon: "notifications",
  },
  {
    tab: "offers",
    label: "Offers",
    icon: "sell",
  },
  { tab: "support", label: "Account support", icon: "security" },
  { tab: "developer", label: "Developer", icon: "code" },
  { tab: "verifications", label: "Verification & badging", icon: "verified" },
]

export const BIO_MAX_DESC_LENGTH = 140
export const MAX_USERNAME_LENGTH = 45
export const MAX_WEBSITE_URL_LENGTH = 2000
export const USERNAME_REGEXP = /^[A-Za-z0-9_-]+$/
export const USERNAME_QUERY_DEBOUNCE_WAIT = 750
