import { WALLET_NAME } from "@/constants/wallet"
import { useTrackingFn } from "@/lib/analytics/hooks/useTrackingFn"

export const useWalletSwitcherTracking = () => ({
  trackClickRefreshFunds: useTrackingFn("click wallet switcher refresh funds"),
  trackClickWalletSwitcherLogout: useTrackingFn("click wallet switcher logout"),
  trackOpenWalletSwitcher: useTrackingFn("open wallet switcher"),
  trackCloseWalletSwitcher: useTrackingFn("close wallet switcher"),
  trackClickWalletSwitcherOption: useTrackingFn<{
    walletName: WALLET_NAME
  }>("click wallet switcher option"),
})
