import React, { forwardRef } from "react"
import { VerticalAligned, Input, InputProps } from "@opensea/ui-kit"
import { Search } from "@/components/svgs/material-symbols/Search.react"

type IconWrapperProps = {
  children: React.ReactNode
}

export type SearchInputProps = Omit<
  InputProps,
  "startEnhancer" | "overrides"
> & {
  withIcon?: boolean
  overrides?: InputProps["overrides"] & {
    IconWrapper?: (props: IconWrapperProps) => JSX.Element
  }
}

export const SearchInput = forwardRef<HTMLDivElement, SearchInputProps>(
  function SearchInput({ withIcon, overrides, ...rest }, ref) {
    const { IconWrapper, ...restOverrides } = overrides || {}
    const IconWrapperComponent = IconWrapper ?? DefaultIconWrapper

    return (
      <Input
        overrides={restOverrides}
        ref={ref}
        startEnhancer={
          withIcon ? (
            <IconWrapperComponent>
              <Search aria-label="search" />
            </IconWrapperComponent>
          ) : undefined
        }
        {...rest}
      />
    )
  },
)

SearchInput.defaultProps = {
  clearable: true,
  withIcon: true,
  type: "search",
}

const DefaultIconWrapper = ({ children }: { children: React.ReactNode }) => (
  <VerticalAligned className="mr-2 max-w-[24px]">{children}</VerticalAligned>
)
