/* eslint-disable tailwindcss/no-custom-classname */
import React from "react"
import { Icon, FlexColumn, Text, classNames } from "@opensea/ui-kit"
import { intlFormat } from "date-fns"
import { useFragment } from "react-relay"
import { AccountLink } from "@/components/accounts/AccountLink.react"
import { VerificationIcon } from "@/components/collections/VerificationIcon.react"
import { Link } from "@/components/common/Link"
import { SocialLinks } from "@/components/common/SocialLinks"
import { Block } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { Popover } from "@/design-system/Popover"
import { useTranslate } from "@/hooks/useTranslate"
import { AnalyticsContextProvider } from "@/lib/analytics"
import { CollectionDetailsContent_contractdata$key } from "@/lib/graphql/__generated__/CollectionDetailsContent_contractdata.graphql"
import { CollectionDetailsContent_data$key } from "@/lib/graphql/__generated__/CollectionDetailsContent_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { truncateAddress } from "@/lib/helpers/address"
import { dateFromISO8601, useFromNow } from "@/lib/helpers/datetime"
import {
  bn,
  display,
  displayUSD,
  normalizePriceDisplay,
} from "@/lib/helpers/numberUtils"
import { EMPTY_PRICE_DISPLAY } from "../../../../../../constants"
import { collectionDetailsModalDetailStyles } from "../../CollectionDetailsModal.react"
import { EVENT_SOURCE } from "../../constants"

type ContentProps = {
  collectionDataKey: CollectionDetailsContent_data$key
  assetContractKey: CollectionDetailsContent_contractdata$key | null
}

export const CollectionDetailsContent = ({
  collectionDataKey,
  assetContractKey,
}: ContentProps) => {
  const t = useTranslate("collections")
  const collection = useFragment(
    graphql`
      fragment CollectionDetailsContent_data on CollectionType {
        name
        createdDate
        slug
        owner {
          address
          createdDate
          displayName
          ...AccountLink_data
        }
        statsV2 {
          totalVolume {
            unit
            usd
          }
          totalSales
          totalSupply
        }
        nativePaymentAsset {
          symbol
          ...PaymentAssetLogo_data
        }
        isMintable
        ...SocialLinks_collection
        representativeAsset {
          assetContract {
            address
            blockExplorerLink
          }
        }
      }
    `,
    collectionDataKey,
  )

  const {
    createdDate,
    isMintable,
    name,
    owner,
    slug,
    statsV2: stats,
    nativePaymentAsset,
    representativeAsset,
  } = collection

  const assetContract = useFragment(
    graphql`
      fragment CollectionDetailsContent_contractdata on AssetContractType {
        address
        blockExplorerLink
      }
    `,
    assetContractKey,
  )

  const renderFromNow = useFromNow()

  const address =
    assetContract?.address ?? representativeAsset?.assetContract.address
  const blockExplorerLink =
    assetContract?.blockExplorerLink ??
    representativeAsset?.assetContract.blockExplorerLink

  if (!address || !blockExplorerLink) {
    return null
  }

  const { totalSales, totalSupply, totalVolume } = stats
  const irregularCollectionName = /( {2})|[-_~|]/.test(name)

  const unitTotalVolume = bn(totalVolume.unit)
  const usdTotalVolume = bn(totalVolume.usd)

  const detailRows = [
    {
      type: t("details.rows.collectionName", "Collection name"),
      info: {
        heading: (
          <Flex>
            <Link href={`/collection/${slug}`} target="_blank">
              {name}
            </Link>
            {isMintable && (
              <VerificationIcon size="small" verificationStatus="MINTABLE" />
            )}
          </Flex>
        ),
        warningText:
          irregularCollectionName &&
          t(
            "details.specialCharactersWarning",
            "Name includes special characters or irregular spacing",
          ),
      },
    },
    {
      type: t("details.rows.creator", "Creator"),
      info: {
        heading: owner && (
          <AccountLink dataKey={owner} target="_blank" variant="no-image" />
        ),
        subheading:
          owner &&
          // TODO(i18n): localize date
          t("details.memberSince", "Member since {{date}}", {
            date: intlFormat(dateFromISO8601(owner.createdDate), {
              month: "short",
              day: "numeric",
              year: "numeric",
            }),
          }),
      },
    },
    {
      type: t("details.rows.totalSales", "Total sales"),
      info: {
        heading: t(
          "details.totalSales",
          {
            0: "{{displayCount}} sales",
            one: "{{displayCount}} sale",
            other: "{{displayCount}} sales",
          },
          {
            count: totalSales,
            displayCount: display(totalSales),
          },
        ),
      },
    },
    {
      type: t("details.rows.totalVolume", "Total volume"),
      info: {
        heading: !unitTotalVolume.isZero() ? (
          <Flex alignItems="center">
            <Block as="span">
              {normalizePriceDisplay(
                unitTotalVolume,
                unitTotalVolume.isLessThan(10) ? 3 : 0,
              )}{" "}
              {nativePaymentAsset.symbol}
            </Block>
          </Flex>
        ) : (
          EMPTY_PRICE_DISPLAY
        ),
        subheading: !usdTotalVolume.isZero()
          ? `$${displayUSD(usdTotalVolume)}`
          : undefined,
      },
    },
    {
      type: t("details.rows.socialLinks", "Social links"),
      info: {
        heading: (
          <SocialLinks
            collectionKey={collection}
            emptyPlaceholder={t("details.notSpecified", "Not specified")}
          />
        ),
      },
    },
    {
      type: t("details.rows.contractAddress", "Contract address"),
      info: {
        heading: (
          <Link href={blockExplorerLink} target="_blank">
            {truncateAddress(address)}
          </Link>
        ),
      },
    },
    {
      type: t("details.rows.totalItems", "Total items"),
      info: {
        heading: t(
          "details.totalItems",
          {
            0: "{{displayCount}} items",
            one: "{{displayCount}} item",
            other: "{{displayCount}} items",
          },
          {
            count: totalSupply,
            displayCount: display(totalSupply),
          },
          { forceString: true },
        ),
      },
    },
    {
      type: t("details.rows.createdDate", "Created date"),
      info: {
        heading: renderFromNow(dateFromISO8601(createdDate)),
      },
    },
  ]

  return (
    <AnalyticsContextProvider eventSource={EVENT_SOURCE}>
      <div className="CollectionDetailsModal--content">
        <ul className="CollectionDetailsModal--details">
          {detailRows.map(({ type, info }) => {
            const subheading = info.subheading && (
              <Text color="secondary" size="small">
                {info.subheading}
              </Text>
            )
            return (
              <li key={type}>
                <Flex className="CollectionDetailsModal--detail-content">
                  <Text
                    className={collectionDetailsModalDetailStyles}
                    color="secondary"
                    size="medium"
                  >
                    {type}
                  </Text>
                  <FlexColumn className="flex-wrap">
                    {info.warningText && (
                      <Popover content={() => info.warningText}>
                        <Icon
                          className="align-middle text-yellow-1"
                          fill={1}
                          value="warning"
                        />
                      </Popover>
                    )}
                    <Text
                      className={classNames(
                        "mr-2 max-w-full",
                        info.warningText ? "ml-2" : "ml-0",
                      )}
                    >
                      {info.heading}
                    </Text>
                    {subheading}
                  </FlexColumn>
                </Flex>
              </li>
            )
          })}
        </ul>
      </div>
    </AnalyticsContextProvider>
  )
}
