import React from "react"
import { CenterAligned, Text } from "@opensea/ui-kit"
import { Block } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { Image } from "@/design-system/Image"
import { useTranslate } from "@/hooks/useTranslate"
import { MOONPAY_LOGO_IMG } from "../../../constants"

export const MoonPayAttribution = () => {
  const t = useTranslate("moonpay")
  return (
    <CenterAligned>
      <Flex alignItems="center">
        <Text
          asChild
          className="inline"
          color="secondary"
          size="tiny"
          weight="semibold"
        >
          <p>{t("attribution.body", "Powered by ")}</p>
        </Text>
        <Block
          className="relative"
          height="30px"
          marginBottom="8px"
          marginLeft="8px"
          width="99px"
        >
          <Image alt="MoonPay logo" layout="fill" src={MOONPAY_LOGO_IMG} />
        </Block>
      </Flex>
    </CenterAligned>
  )
}
