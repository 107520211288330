import { omitBy } from "lodash"
import type { GetServerSidePropsContext, NextPageContext } from "next/types"
import Cookie from "@/lib/cookie"
import type { AuthenticatedAccount } from "./types"

const getAuthenticatedAccountCookie = (address: string) => {
  return new Cookie<AuthenticatedAccount | undefined>(`account_${address}`)
}

export const setAuthenticatedAccountCookie = (
  address: string,
  value: AuthenticatedAccount,
) => {
  const authedAccount = getAuthenticatedAccountCookie(address)
  authedAccount.set(
    omitBy(value, v => !v), // hide falsy values
    {
      secure: true,
      sameSite: "Strict",
      expires: 1,
    },
  )
}

export const getAuthenticatedAccount = (
  address: string,
  context?: NextPageContext | GetServerSidePropsContext,
) => {
  const authedAccountCookie = getAuthenticatedAccountCookie(address)
  return authedAccountCookie.get(context)
}
