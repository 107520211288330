import React, { createContext, useContext, useState } from "react"
import { noop } from "lodash"
import { BigNumber } from "@/lib/helpers/numberUtils"
import { Currency } from "./types"

export type StaleFund = Currency & { quantity: BigNumber }

type WalletPopoverContextType = {
  /**
   * Used by useWalletPopoverDataPoller to pause normal polling and poll for the
   * wallet quantity of staleFund until an update is seen and normal polling is resumed
   */
  staleFund: StaleFund | undefined
  setStaleFund: (staleFund: StaleFund | undefined) => void
  keepOpenOnClickRefCallback: (instance: HTMLElement | null) => void
}

const DEFAULT_CONTEXT: WalletPopoverContextType = {
  staleFund: undefined,
  setStaleFund: noop,
  keepOpenOnClickRefCallback: noop,
}

const WalletPopoverContext =
  createContext<WalletPopoverContextType>(DEFAULT_CONTEXT)

type WalletPopoverContextProviderProps = {
  keepOpenOnClickRefCallback: (instance: HTMLElement | null) => void
  children: React.ReactNode
}

export const WalletPopoverContextProvider = ({
  keepOpenOnClickRefCallback,
  children,
}: WalletPopoverContextProviderProps) => {
  const [staleFund, setStaleFund] = useState<StaleFund | undefined>(undefined)

  return (
    <WalletPopoverContext.Provider
      value={{ staleFund, setStaleFund, keepOpenOnClickRefCallback }}
    >
      {children}
    </WalletPopoverContext.Provider>
  )
}

export const useWalletPopoverContext = () => useContext(WalletPopoverContext)
