import React, { RefCallback } from "react"
import { CenterAligned, Icon, Text, Skeleton } from "@opensea/ui-kit"
import { PreloadedQuery } from "react-relay"
import { StyledListItem } from "@/components/nav/WalletPopover/elements"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { Tooltip } from "@/design-system/Tooltip"
import { useChains } from "@/hooks/useChains"
import { useTranslate } from "@/hooks/useTranslate"
import { NetworkBalanceDropdownQuery } from "@/lib/graphql/__generated__/NetworkBalanceDropdownQuery.graphql"
import {
  getArbitrumChain,
  getBaseL2Chain,
  getEthereumChain,
  getOptimismChain,
  getPolygonChain,
  isEthereum,
  isPolygon,
} from "@/lib/helpers/chainUtils"
import { useChainOptions } from "../hooks/useChainOptions"
import { ManageBalanceAddFunds } from "./ManageBalanceAddFunds.react"
import { ManageBalanceBridgeFunds } from "./ManageBalanceBridgeFunds.react"

type ManageBalanceProps = {
  queryReference: PreloadedQuery<NetworkBalanceDropdownQuery> | null | undefined
  onClickSwitchChain: () => unknown
  keepOpenOnClickRefCallback: RefCallback<HTMLElement>
}

export const ManageBalance = ({
  queryReference,
  onClickSwitchChain,
  keepOpenOnClickRefCallback,
}: ManageBalanceProps) => {
  const t = useTranslate("components")
  const { chain: activeChain } = useWallet()
  const { getNativeCurrencySymbol, getWrappedCurrencySymbol } = useChains()
  const { chainOptions } = useChainOptions()

  const canSwitchChains = chainOptions.length > 1

  return (
    <Block paddingTop="8px">
      <Block paddingX="16px" paddingY="8px">
        <Text.Body className="uppercase text-secondary" size="tiny">
          {t("networkTokens.manage", "Manage")}
        </Text.Body>
      </Block>
      <Tooltip
        content={t(
          "walletPopover.switchChains.notSupported",
          "This wallet cannot switch to any other supported chains.",
        )}
        disabled={canSwitchChains}
      >
        <StyledListItem
          className="border-0"
          disabled={!canSwitchChains}
          onClick={onClickSwitchChain}
        >
          <StyledListItem.Avatar icon="link" />
          <StyledListItem.Content>
            <Text.Body weight="semibold">
              {t("networkTokens.switchChain", "Switch chain")}
            </Text.Body>
          </StyledListItem.Content>
          <StyledListItem.Side>
            <Icon value="chevron_right" />
          </StyledListItem.Side>
        </StyledListItem>
      </Tooltip>
      <ManageBalanceAddFunds
        keepOpenOnClickRefCallback={keepOpenOnClickRefCallback}
        queryReference={queryReference}
      />
      {isEthereum(activeChain) ? (
        <>
          <ManageBalanceBridgeFunds
            fromCurrency={{
              chain: activeChain,
              symbol: getNativeCurrencySymbol(activeChain),
            }}
            keepOpenOnClickRefCallback={keepOpenOnClickRefCallback}
            queryReference={queryReference}
            toCurrency={{
              chain: getPolygonChain(),
              symbol: getWrappedCurrencySymbol(getPolygonChain()),
            }}
          />
          <ManageBalanceBridgeFunds
            fromCurrency={{
              chain: activeChain,
              symbol: getNativeCurrencySymbol(activeChain),
            }}
            keepOpenOnClickRefCallback={keepOpenOnClickRefCallback}
            queryReference={queryReference}
            toCurrency={{
              chain: getOptimismChain(),
              symbol: getNativeCurrencySymbol(getOptimismChain()),
            }}
          />
          <ManageBalanceBridgeFunds
            fromCurrency={{
              chain: activeChain,
              symbol: getNativeCurrencySymbol(activeChain),
            }}
            keepOpenOnClickRefCallback={keepOpenOnClickRefCallback}
            queryReference={queryReference}
            toCurrency={{
              chain: getArbitrumChain(),
              symbol: getNativeCurrencySymbol(getArbitrumChain()),
            }}
          />
          <ManageBalanceBridgeFunds
            fromCurrency={{
              chain: activeChain,
              symbol: getNativeCurrencySymbol(activeChain),
            }}
            keepOpenOnClickRefCallback={keepOpenOnClickRefCallback}
            queryReference={queryReference}
            toCurrency={{
              chain: getBaseL2Chain(),
              symbol: getNativeCurrencySymbol(getBaseL2Chain()),
            }}
          />
        </>
      ) : activeChain && !isPolygon(activeChain) ? (
        <ManageBalanceBridgeFunds
          fromCurrency={{
            chain: getEthereumChain(),
            symbol: "ETH",
          }}
          keepOpenOnClickRefCallback={keepOpenOnClickRefCallback}
          queryReference={queryReference}
          toCurrency={{
            chain: activeChain,
            symbol: getNativeCurrencySymbol(activeChain),
          }}
        />
      ) : null}
    </Block>
  )
}

export const ActionListItemSkeleton = () => (
  <StyledListItem className="border-0">
    <CenterAligned className="mr-4">
      <Skeleton.Circle className="h-6 w-6 rounded-full" />
    </CenterAligned>
    <StyledListItem.Content className="h-6">
      <Skeleton.Line className="h-4 w-20" />
    </StyledListItem.Content>
  </StyledListItem>
)
