/**
 * @generated SignedSource<<be4df06e61cc9f60166da156f04cb110>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProfileImageUploadMutation$variables = {};
export type ProfileImageUploadMutation$data = {
  readonly accounts: {
    readonly uploadProfilePicture: {
      readonly fields: string;
      readonly method: string;
      readonly token: string;
      readonly url: string;
    };
  };
};
export type ProfileImageUploadMutation = {
  response: ProfileImageUploadMutation$data;
  variables: ProfileImageUploadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AccountMutationType",
    "kind": "LinkedField",
    "name": "accounts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UploadContextType",
        "kind": "LinkedField",
        "name": "uploadProfilePicture",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "method",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fields",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileImageUploadMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProfileImageUploadMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "7711e64a1859d36964928638a86e8113",
    "id": null,
    "metadata": {},
    "name": "ProfileImageUploadMutation",
    "operationKind": "mutation",
    "text": "mutation ProfileImageUploadMutation {\n  accounts {\n    uploadProfilePicture {\n      url\n      method\n      fields\n      token\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a129acbc1773a7dcfb2de643c6a097d7";

export default node;
