import { TippyProps } from "@tippyjs/react"
import { noop } from "lodash"
import { followCursor, Plugin } from "tippy.js"

export const keepMounted: Plugin<TippyProps> = {
  name: "keepMounted",
  defaultValue: true,
  fn: instance => {
    const originalDuration = instance.props.duration

    // Noops to prevent Tippy from unmounting the instance
    instance.unmount = noop
    let isMounted = false

    return {
      onCreate() {
        // If the element to mount the Tooltip into is not on the DOM, calling .show() will fail.
        if (document.body.contains(instance.reference)) {
          // Prevents the Tooltip from starting to animate in
          instance.setProps({ duration: 0 })
          instance.show()
        }
      },
      onShow() {
        if (!isMounted) {
          instance.hide()
        }
      },
      onHide() {
        if (!isMounted) {
          instance.setProps({ duration: originalDuration })
          isMounted = true
        }
      },
    }
  },
}

export { followCursor }
