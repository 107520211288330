import { UnstyledButton } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { media } from "@/styles/styleUtils"
import { navSolidBackground } from "../nav_styles"
import { navbarInteractiveStyles } from "../styles"

export const navButtonStyles = css`
  border-radius: ${props => props.theme.borderRadius.large};
  padding: 0 12px;
  position: relative;
  height: 48px;
  min-width: 48px;

  ${navbarInteractiveStyles}

  ${({ $background, theme }) =>
    media(
      {
        lg: css`
          backdrop-filter: none;
          background-color: transparent;
          border-radius: 0;
          border: none;

          :hover,
          :focus {
            background-color: transparent;
          }

          color: ${$background === "transparent" && theme.colors.white};
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: content-box;
          margin-left: 2px;
          margin-right: 2px;
          padding-left: 4px;
          padding-right: 4px;
          width: 28px;
        `,
      },
      { variant: "lessThan" },
    )}
  ${navSolidBackground}

  ${media(
    {
      sm: css`
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 1px;
        margin-right: 1px;
      `,
    },
    { variant: "lessThan" },
  )}
`

export const NavButton = styled(UnstyledButton)`
  ${navButtonStyles}
`
