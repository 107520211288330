import * as ethereumjsUtils from "ethereumjs-util"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { isSolana } from "@/lib/helpers/chainUtils"
import * as evmUtils from "./evm"
import * as solanaUtils from "./solana"

export * from "./format"

export const isValidSolanaAddress = solanaUtils.isValidAddress

const isValidEvmAddress = (value: string) => {
  return ethereumjsUtils.isValidAddress(value)
}

export const isValidAddress = (value: string) => {
  return isValidEvmAddress(value) || isValidSolanaAddress(value)
}

export const resolveIdentity = (identifier: string) => {
  if (isValidAddress(identifier)) {
    return { address: identifier }
  }

  if (identifier.includes(".")) {
    return { name: identifier }
  }

  return { username: identifier }
}

/**
 * See if two addresses are equal
 *
 * @param addressOne address
 * @param addressTwo address
 */
export function addressesEqual(
  addressOne: string | null | undefined,
  addressTwo: string | null | undefined,
) {
  if (!addressOne || !addressTwo) {
    return addressOne == addressTwo
  }

  if (isValidEvmAddress(addressOne) && isValidEvmAddress(addressTwo)) {
    return addressOne.toLowerCase() === addressTwo.toLowerCase()
  }

  return addressOne === addressTwo
}

export const isNullAddress = (address: string | undefined | null) => {
  return (
    address === evmUtils.EVM_NULL_ACCOUNT ||
    address === solanaUtils.SOLANA_NULL_ACCOUNT
  )
}

export const getAddressInputPlaceholder = (chain: ChainIdentifier) => {
  if (isSolana(chain)) {
    return "e.g. EatSz…"
  }
  return "e.g. 0x1ed3... or destination.eth, destination.lens"
}

export const getChecksumAddress = (address: string) => {
  return isValidEvmAddress(address)
    ? ethereumjsUtils.toChecksumAddress(address)
    : address
}
