/* istanbul ignore file */
import React, { useState } from "react"
import { useIsLessThanSm, Text, classNames, Flex } from "@opensea/ui-kit"
import { darken, transparentize } from "polished"
import { graphql, useFragment } from "react-relay"
import { useUpdateEffect } from "react-use"
import styled from "styled-components"
import { variant } from "styled-system"
import { Block } from "@/design-system/Block"
import { useTranslate } from "@/hooks/useTranslate"
import { RarityIndicator_data$key } from "@/lib/graphql/__generated__/RarityIndicator_data.graphql"
import { display, shortSymbolDisplay } from "@/lib/helpers/numberUtils"
import { trackRarityIndicatorHovered } from "./analytics"
import { RarityIcon } from "./RarityIcon.react"
import { RarityTooltip } from "./RarityTooltip.react"
import { getPercentileBucket } from "./utils"

export type RarityIndicatorVariant = "transparent" | "solid"

type RarityIndicatorProps = {
  dataKey: RarityIndicator_data$key
  compact?: boolean
  variant?: RarityIndicatorVariant
}

export const useRarity = ({
  dataKey,
}: {
  dataKey?: RarityIndicator_data$key | null
}) => {
  const data = useFragment(
    graphql`
      fragment RarityIndicator_data on RarityDataType {
        rank
        rankPercentile
        rankCount
        maxRank
      }
    `,
    dataKey ?? null,
  )

  const rarityPercentileBucket = data
    ? getPercentileBucket(data.rankPercentile)
    : undefined

  return { rarityPercentileBucket, data }
}

export const RarityIndicator = React.memo(function RarityIndidcator({
  dataKey,
  compact,
  variant = "transparent",
}: RarityIndicatorProps) {
  const t = useTranslate("components")
  const isMobile = useIsLessThanSm()
  const { data, rarityPercentileBucket } = useRarity({ dataKey })

  const [isHovered, setIsHovered] = useState(false)

  useUpdateEffect(() => {
    if (isHovered && data) {
      trackRarityIndicatorHovered(data)
    }
  }, [isHovered])

  return (
    <RarityTooltip
      content={
        <Block>
          {rarityPercentileBucket !== undefined && (
            <Text.Body asChild className="mb-0.5" size="tiny" weight="semibold">
              <div>
                {t(
                  "rarityIndicator.percentileBucket",
                  "Top {{rarityPercentileBucket}}%",
                  {
                    rarityPercentileBucket,
                  },
                )}
              </div>
            </Text.Body>
          )}
          {data && (
            <>
              <RankText asChild size="tiny">
                <div>
                  {t(
                    "rarityIndicator.rank",
                    "Rarity rank: {{rank}} / {{maxRank}}",
                    {
                      rank: display(data.rank),
                      maxRank: display(data.maxRank),
                    },
                  )}
                </div>
              </RankText>
              {data.rankCount && data.rankCount > 1 && (
                <Text.Body
                  className="text-secondary"
                  size="tiny"
                  weight="semibold"
                >
                  {t(
                    "rarityIndicator.sharedRank",
                    "({{count}} items share this rank)",
                    {
                      count: data.rankCount,
                    },
                  )}
                </Text.Body>
              )}
            </>
          )}
        </Block>
      }
      onHide={() => setIsHovered(false)}
      onShow={() => setIsHovered(true)}
    >
      <Block>
        <RarityIconContainer
          className={classNames(
            "items-center overflow-hidden",
            compact ? "h-6 px-1.5" : "h-7 px-2",
          )}
          variant={variant}
          onClick={event => {
            // prevent tap through rarity indicator to asset page on mobile
            if (isMobile) {
              event.preventDefault()
              event.stopPropagation()
            }
          }}
        >
          <RarityIcon
            compact={compact}
            overrides={{
              ColoredRarityIcon: {
                marginLeft: "-1px",
                marginRight: "1px",
              },
            }}
            size={compact ? 12 : 14}
            variant={isHovered ? "colored" : "default"}
          />
          {data && (
            <Text.Body
              className={classNames(
                "text-primary",
                compact ? "ml-0.5" : "ml-[3px]",
              )}
              size={compact ? "tiny" : "small"}
              weight="semibold"
            >
              {compact && data.rank >= 10_000
                ? `${shortSymbolDisplay(data.rank, { digits: 0 })}+`
                : display(data.rank)}
            </Text.Body>
          )}
        </RarityIconContainer>
      </Block>
    </RarityTooltip>
  )
})

export const RankText = styled(Text.Body)``
RankText.defaultProps = {
  weight: "semibold",
}

const RarityIconContainer = styled(Flex)<{ variant: RarityIndicatorVariant }>`
  border-radius: 8px;
  border: 1px solid
    ${props =>
      props.theme.type === "light"
        ? props.theme.colors.components.border.level2
        : transparentize(0.9, "white")};

  ${props =>
    variant({
      variants: {
        solid: {
          backgroundColor: props.theme.colors.base1,
        },
        transparent: {
          backgroundColor: "initial",
        },
      },
    })}

  &:hover {
    border: 1px solid
      ${props =>
        props.theme.type === "light"
          ? darken(0.09, props.theme.colors.components.border.level2)
          : transparentize(0.7, "white")};
  }
`
