import React, { Suspense } from "react"
import { Text } from "@opensea/ui-kit"
import {
  graphql,
  PreloadedQuery,
  useFragment,
  usePreloadedQuery,
} from "react-relay"
import { TokenPrice } from "@/components/assets/price/TokenPrice.react"
import { useNativeCurrencyBalance } from "@/containers/WalletBalanceProvider/NativeCurrencyBalanceProvider.react"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { useChains } from "@/hooks/useChains"
import { WalletAndAccountButtonFundsDisplay_data$key } from "@/lib/graphql/__generated__/WalletAndAccountButtonFundsDisplay_data.graphql"
import { WalletAndAccountButtonQuery as BridgeOrWrapFormQueryType } from "@/lib/graphql/__generated__/WalletAndAccountButtonQuery.graphql"
import { isPolygon } from "@/lib/helpers/chainUtils"
import { bn } from "@/lib/helpers/numberUtils"
import { UnreachableCaseError } from "@/lib/helpers/type"
import { FundsDisplay } from "../../FundsDisplay.react"
import { WALLET_AND_ACCOUNT_BUTTON_QUERY } from "../WalletAndAccountButton.react"
import { NativeFundsDisplay } from "./NativeFundsDisplay.react"

type Variant = "native" | "wrapped" | "total"

type WalletAndAccountButtonFundsDisplayBaseProps = {
  queryReference: PreloadedQuery<BridgeOrWrapFormQueryType>
  variant: Variant
}

const WalletAndAccountButtonFundsDisplayBase = ({
  queryReference,
  variant,
}: WalletAndAccountButtonFundsDisplayBaseProps) => {
  const { chain: activeChain } = useWallet()
  const { getNativeCurrencySymbol, getWrappedCurrencySymbol } = useChains()

  const { nativeCurrencyBalance } = useNativeCurrencyBalance()
  const isNativeCurrencyBalanceLoading = nativeCurrencyBalance === undefined

  const data = usePreloadedQuery(
    WALLET_AND_ACCOUNT_BUTTON_QUERY,
    queryReference,
  )

  const {
    wallet: { wrappedCurrencyFunds },
  } = useFragment<WalletAndAccountButtonFundsDisplay_data$key>(
    graphql`
      fragment WalletAndAccountButtonFundsDisplay_data on Query
      @argumentDefinitions(
        address: { type: "AddressScalar!" }
        wrappedCurrencySymbol: { type: "String!" }
        wrappedCurrencyChain: { type: "ChainScalar!" }
      ) {
        wallet(address: $address) {
          wrappedCurrencyFunds: fundsOf(
            symbol: $wrappedCurrencySymbol
            chain: $wrappedCurrencyChain
          ) {
            quantity
            symbol
            ...FundsDisplay_walletFunds
          }
        }
      }
    `,
    data,
  )

  if (!activeChain) {
    return null
  }

  switch (variant) {
    case "total": {
      if (
        !isPolygon(activeChain) &&
        wrappedCurrencyFunds.symbol !== getWrappedCurrencySymbol(activeChain)
      ) {
        throw new Error("Tried to add incompatible symbols")
      }

      if (isNativeCurrencyBalanceLoading) {
        return <WalletAndAccountButtonFundsDisplaySkeleton />
      }

      const totalQuantity = nativeCurrencyBalance.plus(
        wrappedCurrencyFunds.quantity,
      )

      return (
        <Text.Body>
          <TokenPrice
            color="inherit"
            fontWeight="inherit"
            price={bn(totalQuantity)}
            symbol={getNativeCurrencySymbol(activeChain)}
            symbolVariant="raw"
          />
        </Text.Body>
      )
    }
    case "wrapped":
      return (
        <Text.Body>
          <FundsDisplay walletFunds={wrappedCurrencyFunds} />
        </Text.Body>
      )
    case "native":
      return (
        <Text.Body>
          <NativeFundsDisplay />
        </Text.Body>
      )
    default:
      throw new UnreachableCaseError(variant)
  }
}

const WalletAndAccountButtonFundsDisplaySkeleton = () => (
  <FundsDisplay.Skeleton className="w-[8ch]" height="24px" />
)

type WalletAndAccountButtonFundsDisplayProps = Omit<
  WalletAndAccountButtonFundsDisplayBaseProps,
  "queryReference"
> & {
  queryReference: PreloadedQuery<BridgeOrWrapFormQueryType> | undefined | null
}

export const WalletAndAccountButtonFundsDisplay = ({
  queryReference,
  ...restProps
}: WalletAndAccountButtonFundsDisplayProps) => {
  if (!queryReference) {
    return <WalletAndAccountButtonFundsDisplaySkeleton />
  }

  return (
    <Suspense fallback={<WalletAndAccountButtonFundsDisplaySkeleton />}>
      <WalletAndAccountButtonFundsDisplayBase
        queryReference={queryReference}
        {...restProps}
      />
    </Suspense>
  )
}
