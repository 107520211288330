import React from "react"
import { useLazyLoadQuery } from "react-relay"
import { useMountEffect } from "@/hooks/useMountEffect"
import {
  trackCloseReviewCollectionModal,
  trackOpenReviewCollectionModal,
} from "@/lib/analytics/events/itemEvents"
import { AssetCollectionDetailsQuery } from "@/lib/graphql/__generated__/AssetCollectionDetailsQuery.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { CollectionDetailsContent } from "../CollectionDetailsContent"

type AssetCollectionDetailsProps = {
  assetId: string
  hasConfirmedDetails: boolean
}

export const AssetCollectionDetails = ({
  assetId,
  hasConfirmedDetails,
}: AssetCollectionDetailsProps) => {
  const { asset } = useLazyLoadQuery<AssetCollectionDetailsQuery>(
    graphql`
      query AssetCollectionDetailsQuery($asset: AssetRelayID!) {
        asset(asset: $asset) {
          assetContract {
            ...CollectionDetailsContent_contractdata
          }
          collection {
            slug
            ...CollectionDetailsContent_data
          }
          ...itemEvents_data
        }
      }
    `,
    { asset: assetId },
  )

  const { assetContract, collection } = asset

  useMountEffect(() => {
    trackOpenReviewCollectionModal(asset, { collectionSlug: collection.slug })
    return () => {
      // Does not count when the user closes the modal after already confirmed the
      // collection details and moved to the checkout page.
      if (!hasConfirmedDetails) {
        trackCloseReviewCollectionModal(asset, {
          collectionSlug: collection.slug,
        })
      }
    }
  })

  return (
    <CollectionDetailsContent
      assetContractKey={assetContract}
      collectionDataKey={collection}
    />
  )
}
