import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { SpaceBetween, FlexColumn } from "@opensea/ui-kit"
import useMergedRef from "@react-hook/merged-ref"
import { ErrorBoundary } from "@sentry/nextjs"
import { useKeyPressEvent } from "react-use"
import styled from "styled-components"
import { Z_INDEX } from "@/constants/zIndex"
import {
  useConnectedAddress,
  useWallet,
} from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { Popover, PopoverProps } from "@/design-system/Popover"
import { useIsOpenWithClickAway } from "@/hooks/useIsOpenWithClickAway"
import { isBSC, isPolygon, isSolana } from "@/lib/helpers/chainUtils"
import { trackCloseWallet, trackOpenWallet } from "./analytics"
import { BridgeOrWrapBalances } from "./components/BridgeOrWrapBalances/BridgeOrWrapBalances.react"
import {
  BridgeOrWrapDirection,
  BridgeOrWrapForm,
} from "./components/BridgeOrWrapForm/BridgeOrWrapForm.react"
import { NetworkBalanceDropdown } from "./components/NetworkBalanceDropdown/NetworkBalanceDropdown.react"
import { WalletSwitcher } from "./components/WalletSwitcher/WalletSwitcher.react"
import { WalletPopoverContextProvider } from "./WalletPopoverContext.react"
import { WalletPopoverDataPoller } from "./WalletPopoverDataPoller.react"
import { WalletPopoverErrorFallback } from "./WalletPopoverErrorFallback.react"

const WalletPopoverContent = forwardRef<
  HTMLDivElement,
  Pick<WalletPopoverProps, "refreshData">
>(function WalletPopoverContent({ refreshData }, ref) {
  const { chain: activeChain } = useWallet()
  const isDisabledPaymentAsset = isBSC(activeChain)

  const [direction, setDirection] = useState<BridgeOrWrapDirection>(
    isSolana(activeChain) ? "in" : "out",
  )

  useEffect(() => {
    // Bridging back to ETH is not handled by WalletPopover
    if (isPolygon(activeChain) && direction === "in") {
      setDirection("out")
    }

    // Only unwrapping LSOL is supported in WalletPopover
    if (isSolana(activeChain) && direction === "out") {
      setDirection("in")
    }
  }, [activeChain, direction])

  return (
    <FlexColumn
      className="w-[540px] gap-8 rounded-md px-4 pb-4 pt-[18px] text-primary"
      data-testid="WalletPopover"
      ref={ref}
    >
      <SpaceBetween>
        <WalletSwitcher />
        <ErrorBoundary fallback={() => <></>}>
          <NetworkBalanceDropdown />
        </ErrorBoundary>
      </SpaceBetween>
      <ErrorBoundary
        fallback={({ resetError }) => (
          <WalletPopoverErrorFallback
            refreshData={refreshData}
            resetError={resetError}
          />
        )}
      >
        <BridgeOrWrapBalances
          direction={direction}
          displayArrow={!isDisabledPaymentAsset}
          setDirection={setDirection}
        />
        {!isDisabledPaymentAsset && (
          <BridgeOrWrapFormContainer>
            <BridgeOrWrapForm
              direction={direction}
              setDirection={setDirection}
            />
          </BridgeOrWrapFormContainer>
        )}
      </ErrorBoundary>
    </FlexColumn>
  )
})

const BridgeOrWrapFormContainer = styled(Block)`
  border-radius: ${props => props.theme.borderRadius.large};
  border-style: solid;
  border-width: 1px;
  border-color: ${props => props.theme.colors.components.border.level2};
`

type WalletPopoverProps = Omit<
  PopoverProps,
  | "content"
  | "trigger"
  | "contentPadding"
  | "variant"
  | "visible"
  | "interactive"
  | "children"
> & {
  children: (props: {
    isOpen: boolean
    open: () => void
    close: () => void
    toggle: () => void
  }) => JSX.Element
  refreshData: () => void
}

export const WalletPopover = ({
  children,
  refreshData,
  ...popoverProps
}: WalletPopoverProps) => {
  const connectedAddress = useConnectedAddress()

  const popoverRef = useRef<HTMLDivElement>(null)
  const {
    isOpen: isPopoverOpen,
    open: openPopover,
    close: closePopover,
    toggle: togglePopoverOpen,
    keepOpenOnClickRefCallback,
  } = useIsOpenWithClickAway(popoverRef)
  useKeyPressEvent("Escape", closePopover)

  const contentRef = useRef<HTMLDivElement>(null)
  const mergedContentRef = useMergedRef(contentRef, keepOpenOnClickRefCallback)

  const isWalletConnected = Boolean(connectedAddress)

  const renderContent = useCallback(() => {
    if (!isWalletConnected) {
      return null
    }

    return (
      <WalletPopoverContent ref={mergedContentRef} refreshData={refreshData} />
    )
  }, [isWalletConnected, mergedContentRef, refreshData])

  return (
    <WalletPopoverContextProvider
      keepOpenOnClickRefCallback={keepOpenOnClickRefCallback}
    >
      <Popover
        animation="shift-away"
        arrow={false}
        interactive
        maxWidth="540px"
        ref={popoverRef}
        zIndex={Z_INDEX.NAVBAR}
        {...popoverProps}
        content={renderContent}
        contentPadding={0}
        elevation="level2"
        visible={isPopoverOpen}
        onHidden={() => trackCloseWallet()}
        onShown={() => trackOpenWallet()}
      >
        {children({
          isOpen: isPopoverOpen,
          open: openPopover,
          close: closePopover,
          toggle: togglePopoverOpen,
        })}
      </Popover>
      <WalletPopoverDataPoller isWalletPopoverOpen={isPopoverOpen} />
    </WalletPopoverContextProvider>
  )
}
