import React from "react"
import { FlexColumn, classNames, Text } from "@opensea/ui-kit"
import { Modal } from "@/design-system/Modal"
import { AnimatedOSLogo } from "./AnimatedOSLogo.react"
import { WalletStepIndicator } from "./WalletStepIndicator.react"

type Props = {
  children?: React.ReactNode
  title: string
  className?: string
  onPrevious?: () => void
  showLogo?: boolean
  step?: number
  steps?: number
  additionalHeight?: number
}

// hack: height of exactly the contents of the WalletSelectionModal -- pass this along to keep the modal body height consistent
export const OS_WALLET_MODAL_BODY_HEIGHT = 592

export const OSWalletModalBody = ({
  children,
  title,
  className,
  onPrevious,
  showLogo = true,
  step,
  steps,
  additionalHeight,
}: Props) => {
  return (
    <>
      <Modal.Body
        className={classNames(
          "relative rounded-[12px] bg-component-gray-1 dark:bg-elevation-1",
          "[-webkit-overflow-scrolling:touch]",
        )}
        data-testid="wallet-modal"
      >
        <FlexColumn
          className={classNames(
            "items-center gap-6 pb-6 pt-4 text-center md:pt-10",
            !showLogo && "pt-20",
          )}
        >
          {showLogo && <AnimatedOSLogo />}

          <Text.Display asChild responsive size="small">
            <h2>{title}</h2>
          </Text.Display>
        </FlexColumn>

        <FlexColumn
          className={className}
          style={{
            // hack: height fits to exactly accomodate content of the above FlexColumn
            height: OS_WALLET_MODAL_BODY_HEIGHT + (additionalHeight || 0) - 232,
          }}
        >
          {children}
        </FlexColumn>
      </Modal.Body>

      {/* body renders on top of header (making the previous button unclickable) unless we place it after */}
      <Modal.Header className="absolute w-full" onPrevious={onPrevious}>
        {step && steps && (
          <WalletStepIndicator className="h-6" step={step} totalSteps={steps} />
        )}
      </Modal.Header>
    </>
  )
}
