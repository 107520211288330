/**
 * @generated SignedSource<<bf518bdb00d2bdfb6be6221e63b3857f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BulkCancelOrdersQuery$variables = {
  address?: string | null;
};
export type BulkCancelOrdersQuery$data = {
  readonly seaportActiveListings: {
    readonly count: number;
  };
  readonly seaportInactiveListings: {
    readonly count: number;
  };
  readonly seaportOffersMade: {
    readonly count: number;
  };
};
export type BulkCancelOrdersQuery = {
  response: BulkCancelOrdersQuery$data;
  variables: BulkCancelOrdersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "address"
  }
],
v1 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v2 = {
  "kind": "Literal",
  "name": "includeCriteriaOrders",
  "value": true
},
v3 = {
  "kind": "Literal",
  "name": "isExpired",
  "value": false
},
v4 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "address",
      "variableName": "address"
    }
  ],
  "kind": "ObjectValue",
  "name": "maker"
},
v5 = {
  "kind": "Literal",
  "name": "onlySeaport",
  "value": true
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v7 = {
  "kind": "Literal",
  "name": "takerAssetIsPayment",
  "value": true
},
v8 = [
  {
    "alias": "seaportOffersMade",
    "args": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      {
        "kind": "Literal",
        "name": "makerAssetIsPayment",
        "value": true
      },
      (v5/*: any*/)
    ],
    "concreteType": "OrderV2TypeConnection",
    "kind": "LinkedField",
    "name": "orders",
    "plural": false,
    "selections": (v6/*: any*/),
    "storageKey": null
  },
  {
    "alias": "seaportActiveListings",
    "args": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v7/*: any*/)
    ],
    "concreteType": "OrderV2TypeConnection",
    "kind": "LinkedField",
    "name": "orders",
    "plural": false,
    "selections": (v6/*: any*/),
    "storageKey": null
  },
  {
    "alias": "seaportInactiveListings",
    "args": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "kind": "Literal",
        "name": "isInactive",
        "value": true
      },
      (v4/*: any*/),
      (v5/*: any*/),
      (v7/*: any*/)
    ],
    "concreteType": "OrderV2TypeConnection",
    "kind": "LinkedField",
    "name": "orders",
    "plural": false,
    "selections": (v6/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BulkCancelOrdersQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BulkCancelOrdersQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "33a82e0be650be54b91e9f8172b8a071",
    "id": null,
    "metadata": {},
    "name": "BulkCancelOrdersQuery",
    "operationKind": "query",
    "text": "query BulkCancelOrdersQuery(\n  $address: AddressScalar\n) {\n  seaportOffersMade: orders(first: 1, onlySeaport: true, isExpired: false, makerAssetIsPayment: true, includeCriteriaOrders: true, maker: {address: $address}) {\n    count\n  }\n  seaportActiveListings: orders(first: 1, onlySeaport: true, isExpired: false, takerAssetIsPayment: true, includeCriteriaOrders: true, maker: {address: $address}) {\n    count\n  }\n  seaportInactiveListings: orders(first: 1, onlySeaport: true, isInactive: true, takerAssetIsPayment: true, includeCriteriaOrders: true, maker: {address: $address}) {\n    count\n  }\n}\n"
  }
};
})();

(node as any).hash = "8521df5f1ae819bc75c9809ee9df828e";

export default node;
