import BigNumber from "bignumber.js"

export { BigNumber } from "bignumber.js"

export const ETH_DECIMALS = 18

export type NumberInput = BigNumber | string | number

export const MIN_DISPLAY_FORMAT = 10_000

const MAX_DECIMAL_PLACES_BY_SYMBOL: Record<string, number> = {
  DAI: 2,
  ETH: 4,
  MANA: 0,
  USDC: 2,
  WETH: 4,
  USD: 2,
}

const DECIMAL_PLACES_BY_SYMBOL: Record<string, number> = {
  USD: 2,
}
export const MAX_DISPLAYED_DECIMAL_PLACES = 4

const MAX_BIG_NUMBER_DECIMAL_PLACES = 40

BigNumber.config({
  DECIMAL_PLACES: MAX_BIG_NUMBER_DECIMAL_PLACES,
  EXPONENTIAL_AT: [
    -MAX_BIG_NUMBER_DECIMAL_PLACES,
    MAX_BIG_NUMBER_DECIMAL_PLACES,
  ],
})

export const bn = (
  value: NumberInput | bigint,
  decimals?: number | null,
): BigNumber => {
  try {
    // toString() is used because numbers with more than 15 significant digits are not accepted
    return new BigNumber(value.toString()).shiftedBy(-(decimals ?? 0))
  } catch (_) {
    return new BigNumber(NaN)
  }
}

export const getMaxDecimals = (symbol: string): number => {
  const places = MAX_DECIMAL_PLACES_BY_SYMBOL[symbol]
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return places === undefined ? ETH_DECIMALS : places
}

export const displayUSD = (value: NumberInput, compactDisplay?: boolean) => {
  return display(value, "USD", compactDisplay)
}

export const displayFiat = (price: NumberInput, compactDisplay?: boolean) => {
  const bnPrice = bn(price)
  return bnPrice.isZero()
    ? "$0.00"
    : bnPrice.isLessThan(bn(0.01))
    ? "< $0.01"
    : `$${displayUSD(bnPrice, compactDisplay)}`
}

export const display = (
  value: NumberInput,
  symbol?: string,
  compactDisplay?: boolean,
): string => {
  return normalizePriceDisplay(
    bn(value),
    Math.min(
      MAX_DISPLAYED_DECIMAL_PLACES,
      symbol ? getMaxDecimals(symbol) : MAX_DISPLAYED_DECIMAL_PLACES,
    ),
    symbol,
    compactDisplay,
  )
}

export const quantityDisplay = (value: NumberInput): string => {
  // Odd to be using this, but it works well.
  return normalizePriceDisplay(value)
}
export const MAX_DECIMALS_FOR_OFFERS = 4
export const getOfferPricePrecision = (price: BigNumber) => {
  if (price.gt(bn(1))) {
    return 2
  }
  if (price.gt(bn(0.1))) {
    return 3
  }
  return 4
}
export const offerPriceRounding = (
  n: NumberInput,
  round?: BigNumber.RoundingMode,
) => {
  const value = bn(n)
  const result = value.decimalPlaces(
    getOfferPricePrecision(value),
    round ?? BigNumber.ROUND_DOWN,
  )
  return result
}

const MAX_DECIMALS_TINY_PRICE_INPUT = 4
const MAX_DECIMALS_LARGE_PRICE_INPUT = 2
const TINY_PRICE_CUTOFF = 0.2

/**
 * Round price DOWN to 3 or 2 decimals, depending on whether value
 * is < 0.2 or >= 0.2, respectively.
 * @param n Number to round
 * @returns BigNumber
 */
export const conditionalPriceRounding = (
  n: NumberInput,
  round?: BigNumber.RoundingMode,
) => {
  const value = bn(n)
  return value.decimalPlaces(
    value.gte(TINY_PRICE_CUTOFF)
      ? MAX_DECIMALS_LARGE_PRICE_INPUT
      : MAX_DECIMALS_TINY_PRICE_INPUT,
    round ?? BigNumber.ROUND_DOWN,
  )
}

export const isValidNumericInput = (
  str: string,
  maxDecimals?: number,
): boolean =>
  new RegExp(
    maxDecimals === 0
      ? "^([1-9]\\d*)?$"
      : `^(0|[1-9]\\d*)?([\\.\\,]\\d{0,${
          maxDecimals === undefined ? "" : maxDecimals
        }})?$`,
  ).test(str)

/**
 * Format a price for display as a localized string
 * @param num Price to normalize
 * @param decimals number of decimals allowed after decimal point
 * @param symbol symbol of price to display
 * @param compactDisplay
 */
export const normalizePriceDisplay = (
  num: NumberInput,
  decimals?: number,
  symbol?: string,
  compactDisplay?: boolean,
): string => {
  let ret
  if (decimals == null) {
    decimals = +num >= 1000 ? 2 : +num >= 10 ? 3 : 4
  }
  if (num === ".") {
    num = "0"
  }
  let str = (+num).toFixed(decimals)

  if (parseFloat(str) !== 0) {
    // This means that the number escapes the precision level
    // as a non-zero value, which is ideal.
    if (String(parseFloat(str)).length < str.length) {
      // Trailing zeroes exist after the precision is applied,
      // so we strip them out and return the result
      ret = String(parseFloat(str))
    } else if (parseFloat(str) == parseInt(str)) {
      // The number is basically N.00000
      // so we strip out the entire float and return it
      ret = String(parseInt(str))
    } else {
      ret = str
    }
  } else {
    str = (+num).toFixed(20)
    // 20 is the maximum precision allowed by toFixed
    // so we ignore precision and try to pull out the first
    // non-zero float

    // Return 0 if 20 decimal precision is not enough
    if (parseFloat(str) === 0) {
      ret = "0"
    } else {
      // 20 precision was enough but we may still have
      // trailing 0s. We want go through the string
      // to find the first non-zero decimal
      // and return the number up until that position
      // Ex. Turn 0.000000XYZ00 into 0.0000000X
      let cutoff = 0
      let inDecimal = false
      for (let i = 0, len = str.length; i < len; i++) {
        if (inDecimal && str[i] !== "0") {
          cutoff = i + 1
          break
        } else if (str[i] === ".") {
          inDecimal = true
        }
      }
      ret = str.substring(0, cutoff)
    }
  }

  if (compactDisplay) {
    // TODO (archanasankar): Uncomment when ICU format is fixed.
    //   // If the number if greater than 999,999, it makes it
    //   // easier to read with million/billion notation.
    //   // The decimals are set at 3 since these numbers are large.
    //   if (parseFloat(ret) >= 1000000) {
    //     return new Intl.NumberFormat("en-US", {
    //       notation: "compact",
    //       compactDisplay: "long",
    //       maximumFractionDigits: 3,
    //     }).format(parseFloat(ret))
    //   }
  }

  // Final processing
  return parseFloat(ret).toLocaleString(
    undefined,
    symbol && DECIMAL_PLACES_BY_SYMBOL[symbol]
      ? {
          minimumFractionDigits: DECIMAL_PLACES_BY_SYMBOL[symbol],
          maximumFractionDigits: DECIMAL_PLACES_BY_SYMBOL[symbol],
        }
      : {
          minimumSignificantDigits: 1,
        },
  )
}

export const basisPointsToPercentage = (basisPoints: NumberInput): string =>
  bn(basisPoints || 0)
    .div(100)
    .toString()

export const percentageToBasisPoints = (percentage: NumberInput): number =>
  parseInt(bn(percentage).times(100).toFixed(0))

export const multiplyBasisPoints = (amount: NumberInput, basisPoints: number) =>
  bn(amount).multipliedBy(basisPoints).div(10_000)

type ShortSymbolDisplayParams = {
  digits?: number
  threshold?: number
  formatDisplay?: boolean
  shortenBillion?: boolean
  noDecimalWholeNumber?: boolean
}

enum DisplaySuffix {
  Millions = "M",
  Thousands = "K",
}

export function shortSymbolDisplay(
  numberInput: NumberInput,
  {
    digits = 1,
    threshold = Number.MIN_VALUE,
    formatDisplay = false,
    shortenBillion = false,
    noDecimalWholeNumber = false,
  }: ShortSymbolDisplayParams = {},
) {
  const value = bn(numberInput)
  if (value.isLessThan(threshold)) {
    return formatDisplay ? quantityDisplay(value) : `${value}`
  }

  const floorValue = value.integerValue(BigNumber.ROUND_FLOOR)

  const renderedTotal = (divider: number, suffix: DisplaySuffix) => {
    let finalTotal = `${value.dividedBy(divider).toFixed(digits)}${suffix}`

    if (noDecimalWholeNumber) {
      // normalizePriceDisplay has access to parseFloat which
      // helps to remove the extra zeroes after the decimal place
      // for a whole number. ie. 10.0k => 10k, 10,900 => 10.9k
      finalTotal = `${normalizePriceDisplay(
        value.dividedBy(divider),
        digits,
      )}${suffix}`
    }
    return finalTotal
  }

  if (floorValue.isLessThan(1_000)) {
    return value.toFixed(0)
  } else if (floorValue.isLessThan(1_000_000)) {
    return renderedTotal(1_000, DisplaySuffix.Thousands)
  } else if (floorValue.isLessThan(1_000_000_000)) {
    return renderedTotal(1_000_000, DisplaySuffix.Millions)
  }
  return shortenBillion ? `> 1B` : `> 1 billion`
}

export const padEndZeros = (value: NumberInput, decimals: number) => {
  const valueStr = `${value}`
  const decimalPlaceIndex = valueStr.indexOf(".")
  if (decimalPlaceIndex === -1) {
    return `${valueStr}.`.padEnd(valueStr.length + 1 + decimals, "0")
  }

  const existingDecimalPlaces = valueStr.length - decimalPlaceIndex - 1
  return valueStr.padEnd(
    valueStr.length + decimals - existingDecimalPlaces,
    "0",
  )
}

export const padFrontZeroes = (
  value: NumberInput,
  desiredNumDigits: number,
) => {
  const valueStr = `${value}`
  const decimalPlaceIndex = valueStr.indexOf(".")
  if (decimalPlaceIndex === -1) {
    return valueStr.padStart(desiredNumDigits, "0")
  }

  return valueStr.padStart(desiredNumDigits + 1, "0")
}

export const calculatePercentages = (
  count: NumberInput,
  total: NumberInput,
) => {
  return Math.min(+bn(count).times(100).div(bn(total)), 100)
}

export const percentageDifference = (from: NumberInput, to: NumberInput) => {
  const ratio = bn(to).div(from)
  const diff = ratio.minus(1)
  const percentageDiff = diff.times(100)

  return percentageDiff
}

type RoundToIntegerAboveMinOptions = {
  decimals?: number
  min?: number
}

export const roundToIntegerAboveMin = (
  price: NumberInput,
  { decimals = 2, min = 1 }: RoundToIntegerAboveMinOptions = {},
): BigNumber => {
  const value = bn(price)
  if (value.isZero()) {
    return value
  }
  if (value.abs().isGreaterThan(min)) {
    return value.integerValue()
  }
  return value.decimalPlaces(decimals)
}

export const roundAboveMin = (price: NumberInput, decimals = 2) => {
  const value = bn(price)
  if (value.isZero()) {
    return "0"
  }
  const min = bn(10).pow(-decimals)
  if (value.isGreaterThanOrEqualTo(min)) {
    return `${display(value.decimalPlaces(decimals))}`
  }
  return `<${min}`
}

export const traitsToPercentage = (totalCount: number, traitCount: number) => {
  const traitsPercentage = totalCount ? (traitCount / totalCount) * 100.0 : 0

  //rounding logic if in [0,1] , show 2 figures, if in (1,100] show integer percentage
  const roundedPercentage =
    traitsPercentage >= 1.0
      ? Math.round(traitsPercentage)
      : traitsPercentage.toFixed(2)

  return roundedPercentage
}

export const getClosestSmallerMultiple = (
  num: NumberInput,
  multiple: NumberInput,
) => {
  return bn(multiple).times(
    bn(num).div(multiple).integerValue(BigNumber.ROUND_FLOOR),
  )
}

export const getClosestLargerMultiple = (
  num: NumberInput,
  multiple: NumberInput,
) => {
  return bn(multiple).times(
    bn(num).div(multiple).integerValue(BigNumber.ROUND_CEIL),
  )
}

export const SMALLEST_DISPLAYED_QUANTITY = bn(10).pow(
  -MAX_DISPLAYED_DECIMAL_PLACES,
)
