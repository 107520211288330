import { useVariant } from "@/hooks/useVariant"

const DEFAULT_POLLING_INTERVAL = 10_000 // 10s

export const useWalletProviderBalancePolling = () => {
  const { enabled, payload } = useVariant("wallet_provider_balance_polling")

  if (payload && !Number.isNaN(Number(payload.value))) {
    return {
      pollingInterval: Number(payload.value),
      enabled,
    }
  }

  return {
    pollingInterval: DEFAULT_POLLING_INTERVAL,
    enabled,
  }
}
