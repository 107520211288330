import React from "react"

type Props = {
  className?: string
  width?: number
  fill?: string
}

export function SoneiumLogo({
  className,
  fill = "lightgrey",
  width = 24,
}: Props) {
  return (
    <svg
      style={{ width, height: width }}
      viewBox="0 0 181 181"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill}
    >
      <rect x="0.125" y="0.149658" height="180.003" rx="90" fill="none" />
      <path
        d="M99.019 127.825C86.3252 130.841 72.4038 127.4 62.5041 117.495C49.7054 104.698 47.2073 85.7083 56.4532 70.666C60.2713 64.4554 63.8981 60.7427 67.0315 57.622C79.9474 44.7568 103.67 21.3391 103.67 21.3391C81.5202 16.9541 57.6622 23.3374 40.5026 40.5012C13.1658 67.8352 13.1658 112.16 40.5026 139.494C48.9775 147.968 59.0808 153.809 69.8133 157.034L99.0252 127.825H99.019Z"
        fill={fill}
      />
      <path
        d="M80.9827 52.17C93.6766 49.1541 107.598 52.5955 117.498 62.5004C130.296 75.2977 132.794 94.2872 123.549 109.329C119.731 115.54 116.104 119.253 112.97 122.374C100.054 135.239 76.332 158.656 76.332 158.656C98.4815 163.041 122.34 156.658 139.499 139.494C166.836 112.16 166.836 67.8352 139.499 40.5013C131.024 32.0273 120.921 26.1867 110.189 22.9612L80.9766 52.17H80.9827Z"
        fill={fill}
      />
    </svg>
  )
}
