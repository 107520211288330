import { graphql } from "relay-runtime"
import { getTokenPricePayment } from "@/components/assets/price/TokenPrice.react"
import { readOrderDataPrices$data } from "@/lib/graphql/__generated__/readOrderDataPrices.graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"

type OrderDataPrices = {
  price: BigNumber
  usdPrice: BigNumber
  tokenPricePayment: ReturnType<typeof getTokenPricePayment>
}

export const readOrderDataPrices = inlineFragmentize<
  readOrderDataPrices$data,
  OrderDataPrices
>(
  graphql`
    fragment readOrderDataPrices on OrderDataType @inline {
      perUnitPrice {
        usd
        unit
      }
      payment {
        ...TokenPricePayment
      }
      item {
        ... on AssetQuantityDataType {
          quantity
        }
      }
    }
  `,
  ({ perUnitPrice, payment, item }) => {
    const basePrice = bn(perUnitPrice.unit)
    const fiatBasePrice = bn(perUnitPrice.usd)
    const quantity = item?.quantity ?? "1"

    const currentPrice = bn(basePrice).multipliedBy(quantity)

    const fiatCurrentPrice = bn(fiatBasePrice).multipliedBy(quantity)

    const tokenPricePayment = getTokenPricePayment(payment)

    return {
      price: currentPrice,
      usdPrice: fiatCurrentPrice,
      tokenPricePayment,
    }
  },
)
