/* eslint-disable tailwindcss/no-custom-classname */
import React, { ComponentProps } from "react"
import { Icon } from "@opensea/ui-kit"
import styled from "styled-components"
import { Link } from "@/components/common/Link"
import { VerifiedIcon } from "@/components/common/VerifiedIcon.react"
import { ACCOUNT_COLLECTION_VERIFICATION_ARTICLE } from "@/constants/support-articles"
import { Popover } from "@/design-system/Popover"
import { useTranslate } from "@/hooks/useTranslate"
import { capitalize } from "@/lib/helpers/stringUtils"
import { Values } from "@/lib/helpers/type"
import type { VerificationStatusWithBadge } from "@/lib/helpers/verification"

const ICON_SIZE = {
  tiny: "tiny",
  small: "small",
  medium: "medium",
  large: "large",
} as const

type IconSize = Values<typeof ICON_SIZE>

type VerificationIconProps = {
  verificationStatus: VerificationStatusWithBadge
  size?: IconSize
  className?: string
  showTooltip?: boolean
  placement?: ComponentProps<typeof Popover>["placement"]
}

const POPPER_OPTIONS = {
  modifiers: [
    {
      name: "preventOverflow",
      options: {
        altAxis: true,
        altBoundary: true,
      },
    },
    {
      name: "flip",
      options: {
        fallbackPlacements: ["left", "bottom", "top"],
      },
    },
  ],
}

export const VerificationIcon = ({
  verificationStatus,
  size = "medium",
  className,
  showTooltip = true,
  placement = "right",
}: VerificationIconProps) => {
  const t = useTranslate("components")
  const child =
    verificationStatus === "VERIFIED" ? (
      <div aria-hidden="true" className="VerificationIcon--icon">
        <VerifiedIcon size={size} />
      </div>
    ) : (
      <Icon className="VerificationIcon--icon text-yellow-1" value="report" />
    )
  const tooltip =
    verificationStatus === "VERIFIED" ? (
      <div className="VerificationIcon--tooltip-content">
        {t(
          "collections.verification.tooltip",
          "This collection belongs to a verified account and has significant interest or sales.",
        )}{" "}
        <Link href={ACCOUNT_COLLECTION_VERIFICATION_ARTICLE}>
          {t("collections.verification.learnMoreLink", "Learn more")}
        </Link>
      </div>
    ) : (
      // TODO(i18n): Translate status & default collection name
      `${capitalize(verificationStatus)} collection`
    )

  return (
    <DivContainer
      $size={size}
      className={className}
      data-testid="verification-icon"
    >
      {showTooltip ? (
        <Popover
          content={() => tooltip}
          placement={placement}
          popperOptions={POPPER_OPTIONS}
        >
          {child}
        </Popover>
      ) : (
        <>{child}</>
      )}
    </DivContainer>
  )
}

const DivContainer = styled.div<{ $size: IconSize }>`
  display: flex;
  align-items: center;

  .VerificationIcon--icon {
    display: flex;
    font-size: 24px;
    margin: 0 4px;
    cursor: pointer;
  }
`
