import { useCallback, useEffect, useState } from "react"
import { MessageType, ParentFrame } from "@opensea/vessel"
import {
  ActionMessagePayload,
  EventMessagePayload,
  MessagePayload,
} from "@opensea/wallet-messages"
import { OS_WALLET_URL } from "@/features/os-wallet/constants"
import { useIsOSWalletEnabled } from "../flags"
import {
  useHandleVesselMessage,
  UseHandleVesselMessageProps,
} from "./useHandleVesselMessage"
import { useVesselLogger } from "./useVesselLogger"

type UseVesselProps = UseHandleVesselMessageProps

export const useVessel = (props: UseVesselProps) => {
  const vesselLog = useVesselLogger()
  const [vessel, setVessel] = useState<ParentFrame>()
  const [vesselConnected, setVesselConnected] = useState(false)

  const handleVesselMessage = useHandleVesselMessage(props)

  // Initialize with a callback ref on the iframe element
  const initVesselCallbackRef = useCallback(
    (node: HTMLIFrameElement | null) => {
      vesselLog("Initializing vessel", node)
      if (!node) {
        return
      }

      setVesselConnected(false)
      setVessel(vessel => {
        if (vessel) {
          vesselLog("Closing existing vessel")
          vessel.close()
        }
        return new ParentFrame({
          frame: node,
          url: OS_WALLET_URL,
        })
      })
    },
    [vesselLog],
  )

  // Connect to child frame
  useEffect(() => {
    if (!vessel) {
      return
    }

    const connectToChildFrame = async () => {
      vesselLog("Connecting to child frame", vessel)
      await vessel.connect({ isFrameLoaded: true })
      setVesselConnected(true)
    }
    connectToChildFrame()
  }, [vessel, vesselLog])

  // Listen for messages from child frame
  useEffect(() => {
    let unsubscribe: (() => void) | undefined
    if (vesselConnected) {
      unsubscribe = vessel?.on(MessageType.Message, async (payload, reply) => {
        vesselLog("Received message", payload)
        await handleVesselMessage(payload as MessagePayload, reply)
      })
    }
    return () => {
      unsubscribe?.()
    }
  }, [handleVesselMessage, vessel, vesselConnected, vesselLog])

  const isOSWalletEnabled = useIsOSWalletEnabled()
  const emitEvent = useCallback(
    (data: EventMessagePayload) => {
      if (!isOSWalletEnabled) {
        return
      }

      if (!vessel?.connected) {
        vesselLog("Tried to send a message while vessel was not connected")
        return
      }

      vesselLog("Sending message", data)
      vessel.emit(data)
    },
    [isOSWalletEnabled, vessel, vesselLog],
  )

  const triggerAction = useCallback(
    async (data: ActionMessagePayload) => {
      if (!isOSWalletEnabled) {
        return
      }

      if (!vessel?.connected) {
        vesselLog("Tried to send a message while vessel was not connected")
        return
      }

      vesselLog("Sending message", data)
      return await vessel.send(data)
    },
    [isOSWalletEnabled, vessel, vesselLog],
  )

  return {
    vesselConnected,
    initVesselCallbackRef,
    emitEvent,
    triggerAction,
  }
}
