import React, { useState } from "react"
import { CenterAligned, FlexColumn, Spinner, Text } from "@opensea/ui-kit"
import { QRCodeSVG } from "qrcode.react"
import { TextCopierButton } from "@/components/common/TextCopierButton.react"
import { SIGNUP_STEPS } from "@/features/os-wallet/components/constants"
import { useAuthenticateOSWallet } from "@/features/os-wallet/hooks/useOpenOSWalletAfterLogin"
import { useOSWalletAuth } from "@/features/os-wallet/hooks/useOSWalletAuth"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { WalletAuthCodeModal } from "./WalletAuthCodeModal.react"
import { WalletMfaEnrollmentModalProps } from "./WalletMfaEnrollmentModal.react"

export const WalletTotpCodeModal = ({
  accessToken,
  privyId,
}: WalletMfaEnrollmentModalProps) => {
  const t = useTranslate("common")
  const authenticateOSWallet = useAuthenticateOSWallet()
  const { initMfaEnrollmentWithTotp, submitMfaEnrollmentWithTotp } =
    useOSWalletAuth()
  const [totpData, setTotpData] = useState<{
    authUrl: string
    secret: string
  }>()
  const [error, setError] = useState<boolean>(false)

  useMountEffect(() => {
    const getTotpData = async () => {
      try {
        const { authUrl, secret } = await initMfaEnrollmentWithTotp()
        // TODO: Use secret above directly instead of parsing it from authUrl
        //  after Privy is updated to no longer encode the secret.
        const searchParams = new URL(authUrl).searchParams
        setTotpData({ authUrl, secret: searchParams.get("secret") ?? secret })
      } catch (error) {
        setError(true)
      }
    }
    getTotpData()
  })

  const handleAuthCode = async (authCode: string) => {
    setError(false)
    try {
      await submitMfaEnrollmentWithTotp(authCode)
      await authenticateOSWallet({
        accessToken,
        privyId,
        shouldOpenWalletAfterAuth: false,
      })
    } catch (error) {
      setError(true)
    }
  }

  return (
    <WalletAuthCodeModal
      description={
        <FlexColumn className="mb-4 min-h-[180px]">
          {totpData ? (
            <>
              <CenterAligned className="self-center rounded-xl bg-white p-3">
                <QRCodeSVG size={140} value={totpData.authUrl} />
              </CenterAligned>
              <TextCopierButton className="mt-1" text={totpData.secret}>
                <Text className="mt-2" color="secondary" size="tiny">
                  {t(
                    "wallet.opensea.mfa.authenticator.copyKeyCode",
                    "Can't scan? Copy the key:",
                  )}
                  <div>{totpData.secret}</div>
                </Text>
              </TextCopierButton>
            </>
          ) : (
            <CenterAligned className="h-[140px] w-[140px] self-center rounded-xl bg-white p-3">
              <Spinner />
            </CenterAligned>
          )}
        </FlexColumn>
      }
      error={error}
      step={3}
      subtitle={t(
        "wallet.opensea.mfa.authenticator.subtitle",
        "Scan QR code using an authenticator app",
      )}
      title={t(
        "wallet.opensea.mfa.authenticator.title",
        "Set up authenticator app",
      )}
      totalSteps={SIGNUP_STEPS}
      onAuthCode={handleAuthCode}
    />
  )
}
