import React, { RefCallback, Suspense } from "react"
import { Text } from "@opensea/ui-kit"
import {
  graphql,
  PreloadedQuery,
  useFragment,
  usePreloadedQuery,
} from "react-relay"
import { Overflow } from "@/components/common/Overflow"
import { trackClickBridge } from "@/components/nav/WalletPopover/analytics"
import { StyledListItem } from "@/components/nav/WalletPopover/elements"
import { Currency } from "@/components/nav/WalletPopover/types"
import {
  SwapModal,
  SwapModalProps,
} from "@/components/nav/WalletSidebar/SwapModal.react"
import { useChains } from "@/hooks/useChains"
import { useGlobalModal } from "@/hooks/useGlobalModal"
import { useTranslate } from "@/hooks/useTranslate"
import { ManageBalanceBridgeFunds_data$key } from "@/lib/graphql/__generated__/ManageBalanceBridgeFunds_data.graphql"
import { NetworkBalanceDropdownQuery } from "@/lib/graphql/__generated__/NetworkBalanceDropdownQuery.graphql"
import { NETWORK_BALANCE_DROPDOWN_QUERY } from "../NetworkBalanceDropdown.react"
import { ActionListItemSkeleton } from "./ManageBalance.react"

type ManageBalanceBridgeFundsBaseProps = Omit<
  SwapModalProps,
  "from" | "to" | "onSuccess" | "trigger"
> & {
  queryReference: PreloadedQuery<NetworkBalanceDropdownQuery>
  fromCurrency: Currency
  toCurrency: Currency
  keepOpenOnClickRefCallback: RefCallback<HTMLElement>
}

const ManageBalanceBridgeFundsBase = ({
  queryReference,
  fromCurrency,
  toCurrency,
  keepOpenOnClickRefCallback,
}: ManageBalanceBridgeFundsBaseProps) => {
  const t = useTranslate("components")
  const { getChainName } = useChains()
  const { openModal } = useGlobalModal()

  const data = usePreloadedQuery(NETWORK_BALANCE_DROPDOWN_QUERY, queryReference)

  const {
    wallet: {
      fundsOf: { supportedSwaps },
    },
  } = useFragment<ManageBalanceBridgeFunds_data$key>(
    graphql`
      fragment ManageBalanceBridgeFunds_data on Query
      @argumentDefinitions(
        address: { type: "AddressScalar!" }
        baseCurrencySymbol: { type: "String!" }
        baseCurrencyChain: { type: "ChainScalar!" }
      ) {
        wallet(address: $address) {
          fundsOf(symbol: $baseCurrencySymbol, chain: $baseCurrencyChain) {
            supportedSwaps {
              symbol
              chain {
                identifier
              }
              swapType
            }
          }
        }
      }
    `,
    data,
  )

  const bridgingSwap = supportedSwaps.find(
    ({ symbol, swapType, chain }) =>
      swapType === "BRIDGE" &&
      chain.identifier === toCurrency.chain &&
      symbol === toCurrency.symbol,
  )

  if (!bridgingSwap) {
    return null // Bridging to this currency not supported
  }

  const openSwapModal = () => {
    openModal(
      <SwapModal
        from={{
          chain: {
            identifier: fromCurrency.chain,
          },
          symbol: fromCurrency.symbol,
        }}
        to={{
          chain: {
            identifier: bridgingSwap.chain.identifier,
          },
          symbol: bridgingSwap.symbol,
        }}
      />,
      { overlayRef: keepOpenOnClickRefCallback },
    )
  }

  return (
    <StyledListItem
      className="border-0 text-left"
      onClick={() => {
        trackClickBridge({ fromCurrency, toCurrency })
        openSwapModal()
      }}
    >
      <StyledListItem.Avatar icon="swap_horiz" />
      <StyledListItem.Content>
        <Overflow overrides={{ Tooltip: { variant: "card" } }}>
          <Text.Body weight="semibold">
            {t("networkTokens.bridge", "Bridge to {{chainName}}", {
              chainName: getChainName(toCurrency.chain),
            })}
          </Text.Body>
        </Overflow>
      </StyledListItem.Content>
    </StyledListItem>
  )
}

type ManageBalanceBridgeFundsProps = Omit<
  ManageBalanceBridgeFundsBaseProps,
  "queryReference"
> & {
  queryReference: PreloadedQuery<NetworkBalanceDropdownQuery> | undefined | null
}

export const ManageBalanceBridgeFunds = ({
  queryReference,
  ...restProps
}: ManageBalanceBridgeFundsProps) => {
  if (!queryReference) {
    return <ActionListItemSkeleton />
  }

  return (
    <Suspense fallback={<ActionListItemSkeleton />}>
      <ManageBalanceBridgeFundsBase
        queryReference={queryReference}
        {...restProps}
      />
    </Suspense>
  )
}
