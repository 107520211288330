import React from "react"
import { CenterAligned, Text, UnstyledButton } from "@opensea/ui-kit"
import { setOs2UpsellModalCookie } from "@/components/modals/Os2UpsellModal/utils"
import { Button } from "@/design-system/Button"
import { Image } from "@/design-system/Image"
import { Modal } from "@/design-system/Modal"
import { useIsOs2UpsellModalEnabled } from "@/hooks/useFlag"
import { useIsOpen } from "@/hooks/useIsOpen"
import { useTranslate } from "@/hooks/useTranslate"

export const Os2UpsellModal = () => {
  const t = useTranslate("modals")
  const { isOpen: isModalOpen, close: closeModal } = useIsOpen(true)
  const { dismissible } = useIsOs2UpsellModalEnabled()
  const onClick = () => {
    closeModal()
    setOs2UpsellModalCookie(true)
    window.location.replace("https://opensea.io/os2")
  }
  const onClose = () => {
    closeModal()
    setOs2UpsellModalCookie(true)
  }

  return (
    <Modal
      className="overflow-hidden"
      closeOnEscape
      closeOnOverlayClick
      isOpen={isModalOpen}
      onClose={onClose}
    >
      <Modal.Header className="!p-0">
        <Image
          alt=""
          height={300}
          src="/static/images/os2-upsell.png"
          width={600}
          className="object-cover"
        />
      </Modal.Header>
      <Modal.Body>
        <CenterAligned className="gap-2">
          <Modal.Body.Title>
            {t("os2Upsell.title", "Start Earning XP on OS2")}
          </Modal.Body.Title>
          <Text className="text-center text-secondary">
            {t(
              "os2Upsell.body",
              "Experience our reimagined marketplace with 0.5% platform fees, seamless cross-chain swaps, and an XP system designed to reward participation.",
            )}
          </Text>
        </CenterAligned>
      </Modal.Body>
      <Modal.Footer className="!flex-col">
        <Button className="w-full" onClick={onClick}>
          {t("os2Upsell.enter", "Enter OS2")}
        </Button>
        {dismissible && (
          <UnstyledButton className="mt-4 justify-center" onClick={onClose}>
            <Text className="text-center text-secondary" size="tiny">
              {t("os2Upsell.dismiss", "No thanks, I'll stick with OS Classic")}
            </Text>
          </UnstyledButton>
        )}
      </Modal.Footer>
    </Modal>
  )
}
