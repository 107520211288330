import { GraphQLTaggedNode, useLazyLoadQuery } from "react-relay"
import {
  createOperationDescriptor,
  Subscription,
  Disposable,
  fetchQuery,
  getRequest,
  OperationType,
  Environment,
  CacheConfig,
} from "relay-runtime"
import { MapNonNullable } from "../helpers/type"
import { getEnvironment } from "./environment"

export { graphql } from "react-relay"

type RefetchOptions = {
  force?: boolean
  fetchPolicy?: "store-or-network" | "network-only"
}

export type LazyLoadQueryOptions = Parameters<typeof useLazyLoadQuery>[2]

export type Connection<T extends Record<string, unknown>> = {
  readonly edges: ReadonlyArray<{ readonly node: T | null } | null>
}

export type Node<T extends Connection<Record<string, unknown>> | undefined> =
  NonNullable<NonNullable<NonNullable<T>["edges"][number]>["node"]>

type BaseGraphQLProps<TOperation extends OperationType> = {
  cacheMaxAge?: number
  refetch: () => void
  variables: MapNonNullable<TOperation["variables"]>
}

export type GraphQLProps<TOperation extends OperationType> =
  BaseGraphQLProps<TOperation> & {
    data: TOperation["response"] | null
    error: Error | null
  }

export type LoadedGraphQLProps<TOperation extends OperationType> =
  BaseGraphQLProps<TOperation> & {
    data: TOperation["response"]
  }

export type GraphQLInitialProps<
  TOperation extends OperationType,
  Props = object,
> = Props &
  Pick<GraphQLProps<TOperation>, "variables"> &
  Pick<GraphQLProps<TOperation>, "cacheMaxAge">

// refetchify() adds these props to the component.
// The component's `this.props` will have the type `Props & RefetchProps<FooQuery>`
export type RefetchProps<TOperation extends OperationType> = {
  refetch: (
    variables?:
      | Partial<TOperation["variables"]>
      | ((
          fragmentVariables: TOperation["variables"],
        ) => Partial<TOperation["variables"]>),
    options?: {
      renderVariables?: Partial<TOperation["variables"]> | null
    } & RefetchOptions,
  ) => Promise<void>
}

export type PageProps = {
  hasMore: () => boolean
  isLoading: () => boolean
  loadMore: (count: number, options?: RefetchOptions | null) => Promise<void>
}

export const getNodes = <T extends Record<string, unknown>>(
  connection: Connection<T> | undefined,
): Array<Node<Connection<T>>> => {
  if (!connection) {
    return []
  }
  return connection.edges
    .map(edge => edge && edge.node)
    .filter((x): x is NonNullable<T> => x !== null)
}

export const getFirstNode = <T extends Record<string, unknown>>(
  connection: Connection<T> | undefined,
): Node<Connection<T>> | undefined => getNodes(connection)[0]

export const fetch = async <TOperation extends OperationType>(
  query: GraphQLTaggedNode,
  variables: TOperation["variables"],
  networkCacheConfig?: CacheConfig,
  environment?: Environment,
  options: {
    retain?: boolean
  } = {},
): Promise<
  [TOperation["response"], Disposable | undefined, Subscription | undefined]
> => {
  const relayEnvironment = environment ?? getEnvironment()

  const result = fetchQuery(relayEnvironment, query, variables, {
    networkCacheConfig,
  })

  let subscription: Subscription | undefined = undefined

  const promise = new Promise((resolve, reject) => {
    let resolved = false
    subscription = result.subscribe({
      next(val) {
        if (!resolved) {
          resolved = true
          resolve(val)
        }
      },
      error: reject,
      complete: () => {
        if (!resolved) {
          resolved = true
          resolve(undefined)
        }
      },
    })
  })

  if (options.retain) {
    // based on https://github.com/facebook/relay/blob/f8123fd31e77011f0932d88259a1636bebf3284f/website/versioned_docs/version-v11.0.0/guided-tour/managing-data-outside-react/retaining-queries.md
    const queryRequest = getRequest(query)
    const queryDescriptor = createOperationDescriptor(queryRequest, variables)
    const disposable = relayEnvironment.retain(queryDescriptor)
    return [await promise, disposable, subscription]
  }

  return [await promise, undefined, subscription]
}

export const pkFromRelayId = (relayId: string) => {
  const globalId = Buffer.from(relayId, "base64").toString("binary")
  return Number(globalId.split(":")[1])
}

export const toRelayId = (type: string, id: number) => {
  return Buffer.from(`${type}:${id}`).toString("base64")
}
