import { useContextSelector } from "use-context-selector"
import { OSWalletContext } from "@/features/os-wallet"

export const useOSWalletAuth = () => ({
  loginTriggered: useContextSelector(
    OSWalletContext,
    ctx => ctx.loginTriggered,
  ),
  sendLoginCode: useContextSelector(OSWalletContext, ctx => ctx.sendLoginCode),
  loginWithCode: useContextSelector(OSWalletContext, ctx => ctx.loginWithCode),
  createEmbeddedWallet: useContextSelector(
    OSWalletContext,
    ctx => ctx.createEmbeddedWallet,
  ),
  checkMfaEnrollment: useContextSelector(
    OSWalletContext,
    ctx => ctx.checkMfaEnrollment,
  ),
  initMfaEnrollmentWithSms: useContextSelector(
    OSWalletContext,
    ctx => ctx.initMfaEnrollmentWithSms,
  ),
  submitMfaEnrollmentWithSms: useContextSelector(
    OSWalletContext,
    ctx => ctx.submitMfaEnrollmentWithSms,
  ),
  initMfaEnrollmentWithTotp: useContextSelector(
    OSWalletContext,
    ctx => ctx.initMfaEnrollmentWithTotp,
  ),
  submitMfaEnrollmentWithTotp: useContextSelector(
    OSWalletContext,
    ctx => ctx.submitMfaEnrollmentWithTotp,
  ),
})
