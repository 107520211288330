import React from "react"
import {
  Icon,
  UnstyledButton,
  Text,
  TextBodyProps,
  Label,
  classNames,
  SpaceBetween,
  CenterAligned,
  FlexColumn,
  FlexColumnProps,
  Flex,
} from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { ResponsiveModal } from "@/components/common/ResponsiveModal"
import { Modal } from "@/design-system/Modal"
import { useTranslate } from "@/hooks/useTranslate"

export type FormControlProps = FlexColumnProps &
  Pick<JSX.IntrinsicElements["label"], "htmlFor"> & {
    children: JSX.Element
    // Labels should always be specified for inputs for a11y - they are read by
    // screen readers when navigating to and focusing the input.
    // If the label should be visually hidden, set `hideLabel` to true.
    label: React.ReactNode
    modalTooltip?: { content: React.ReactNode }
    captionRight?: React.ReactNode
    captionLeft?: React.ReactNode
    tip?: React.ReactNode
    tipAlign?: "bottom" | "right"
    error?: React.ReactNode
    warning?: React.ReactNode
    disabled?: boolean
    className?: string
    required?: boolean
    hideLabel?: boolean
    inline?: boolean
    overrides?: {
      FirstRowContainer?: {
        className?: string
      }
    }
  }

export const FormControl = ({
  children,
  label,
  modalTooltip,
  htmlFor,
  captionLeft,
  captionRight,
  error,
  warning,
  disabled = false,
  required,
  hideLabel = false,
  tip,
  tipAlign = "bottom",
  inline = false,
  overrides,
  ...rest
}: FormControlProps) => {
  const t = useTranslate("designSystem")
  const onlyChildrenProps = children.props

  const captionLeftElement = error || warning || captionLeft
  const showCaption = captionLeftElement || captionRight

  const $error = Boolean(error)
  const $warning = Boolean(warning)

  const content = React.cloneElement(children, {
    disabled: onlyChildrenProps.disabled || disabled,
    error: onlyChildrenProps.error || $error,
  })

  const LabelContainer = tipAlign === "bottom" ? FlexColumn : SpaceBetween

  const htmlForWithFallback = htmlFor || onlyChildrenProps.id

  return (
    <FlexColumn {...rest}>
      <Flex
        className={classNames(
          inline ? "flex-row items-center justify-between" : "flex-col",
          overrides?.FirstRowContainer?.className,
        )}
      >
        {(label || tip) && (
          <LabelContainer className={hideLabel || inline ? undefined : "mb-3"}>
            {label && (
              <Flex
                className={classNames(
                  "items-center",
                  tip && tipAlign === "bottom" && "mb-1",
                )}
              >
                <Label
                  // TODO: @marcus - extract this modalTooltip stuff out to a LabelWithTooltip component.
                  className={classNames(!modalTooltip && "w-full")}
                  htmlFor={htmlForWithFallback}
                  required={required}
                  visuallyHidden={hideLabel}
                >
                  {label}
                </Label>
                {modalTooltip && (
                  <ResponsiveModal
                    closable={false}
                    trigger={open => (
                      <UnstyledButton
                        aria-label={t(
                          "formControl.modalTooltip.ariaLabel",
                          "Info",
                        )}
                        onClick={e => {
                          e.preventDefault()
                          open()
                        }}
                      >
                        <Icon
                          className="pl-1 text-primary"
                          size={16}
                          value="info"
                        />
                      </UnstyledButton>
                    )}
                  >
                    {close => (
                      <>
                        <Modal.Header>
                          <Modal.Header.Title>{label}</Modal.Header.Title>
                        </Modal.Header>
                        <StyledModalBody>
                          <Text.Body className="text-secondary" size="medium">
                            {modalTooltip.content}
                          </Text.Body>
                        </StyledModalBody>
                        <Modal.Footer>
                          <Modal.Footer.Button
                            variant="secondary"
                            onClick={() => {
                              close()
                            }}
                          >
                            {t("formControl.modalTooltip.ok", "Ok")}
                          </Modal.Footer.Button>
                        </Modal.Footer>
                      </>
                    )}
                  </ResponsiveModal>
                )}
              </Flex>
            )}

            {tip && (
              <Text.Body className="text-secondary" size="tiny">
                {tip}
              </Text.Body>
            )}
          </LabelContainer>
        )}

        {inline ? <CenterAligned>{content}</CenterAligned> : content}
      </Flex>

      {showCaption && (
        <SpaceBetween className="mt-1">
          <Caption
            $error={$error}
            $warning={$warning}
            aria-describedby={htmlForWithFallback}
            role={$error ? "status" : undefined}
          >
            {($error || $warning) && (
              <AlertIcon
                $error={$error}
                $warning={$warning && !$error}
                aria-label={
                  $error
                    ? t("formControl.error", "Error")
                    : t("formControl.warning", "Warning")
                }
                className="mr-1"
                size={14}
                value="error"
              />
            )}
            <span>{captionLeftElement}</span>
          </Caption>
          {captionRight && (
            <Caption className="ml-6 text-right">{captionRight}</Caption>
          )}
        </SpaceBetween>
      )}
    </FlexColumn>
  )
}

type CaptionsProps = TextBodyProps & {
  $error?: boolean
  $warning?: boolean
}

const Caption = styled(Text.Body).attrs({
  size: "small",
})<CaptionsProps>`
  color: ${props => props.theme.colors.text.secondary};
  display: flex;

  ${props =>
    props.$warning &&
    css`
      color: ${props.theme.colors.text.primary};
    `};

  ${props =>
    props.$error &&
    css`
      color: ${props.theme.colors.error};
    `}
`

const AlertIcon = styled(Icon)<{ $error: boolean; $warning: boolean }>`
  align-self: flex-start;

  // Match caption text line height to ensure it's centered with the first line
  // of text. Important to avoid being overridden by .material-icons style.
  line-height: inherit !important;

  ${props =>
    props.$warning &&
    css`
      color: ${props.theme.colors.warning};
    `}

  ${props =>
    props.$error &&
    css`
      color: ${props.theme.colors.error};
    `}
`
const StyledModalBody = styled(props => <Modal.Body {...props} />)`
  padding-top: 0;
  padding-bottom: 0;
`
