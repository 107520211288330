/* eslint-disable tailwindcss/no-custom-classname */
import React from "react"
import { Container, Flex, Text } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { ExternalLink } from "@/components/common/ExternalLink"
import { Link } from "@/components/common/Link"
import { useLocationContext } from "@/context/location"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { useTheme } from "@/design-system/Context"
import { Image } from "@/design-system/Image"
import { useIsIosWebview } from "@/hooks/useIsIosWebview"
import { useTranslate } from "@/hooks/useTranslate"
import {
  useResourceLinks,
  useMyAccountLinks,
  useStatLinks,
  getSocialLinks,
  LinkItem,
  useExploreLinks,
} from "@/lib/helpers/links"
import { media, themeVariant } from "@/styles/styleUtils"
import { LEARN_URL, OPENSEA_WHITE_LOGO_IMG, SUPPORT_URL } from "../../constants"
import { MailingSignupForm } from "./MailingSignupForm.react"
import { useIntercom } from "@/hooks/useIntercom"
import { useTrackingFn } from "@/lib/analytics/hooks/useTrackingFn"

type Props = {
  external?: boolean
}

const Footer = ({ external = false }: Props) => {
  const t = useTranslate("components")
  const exploreLinks = useExploreLinks()
  const accountLinks = useMyAccountLinks()
  const statLinks = useStatLinks()
  const resourceLinks = useResourceLinks()
  const { origin } = useLocationContext()
  const isIntercomAvailable = useIntercom()
  const trackClick = useTrackingFn<{ inApp: boolean }>("intercom footer click")

  const handleClick = () => {
    if (isIntercomAvailable) {
      window.Intercom("show")
      trackClick({ inApp: true })
    } else {
      window.open(SUPPORT_URL, "_blank")
      trackClick({ inApp: false })
    }
  }

  const companyLinks = [
    {
      url: "/about",
      label: t("footer.links.about", "About"),
    },
    {
      url: "/careers",
      label: t("footer.links.careers", "Careers"),
    },
    {
      url: "/opensea-ventures",
      label: t("footer.links.ventures", "Ventures"),
    },
  ]
  const learnArticleLinks = [
    {
      url: `${LEARN_URL}/what-are-nfts`,
      label: t("footer.links.whatAreNfts", "What is an NFT?"),
    },
    {
      url: `${LEARN_URL}/how-to-buy-nft`,
      label: t("footer.links.howToBuyNft", "How to buy an NFT"),
    },
    {
      url: `${LEARN_URL}/what-are-nft-drops`,
      label: t("footer.links.whatAreNFTDrops", "What are NFT drops?"),
    },
    {
      url: `${LEARN_URL}/how-to-sell-nfts`,
      label: t(
        "footer.links.howToSellNfts",
        "How to sell an NFT using OpenSea",
      ),
    },
    {
      url: `${LEARN_URL}/how-to-create-an-nft`,
      label: t(
        "footer.links.howToCreateAnNft",
        "How to create an NFT on OpenSea",
      ),
    },
    {
      url: `${LEARN_URL}/what-is-crypto-wallet`,
      label: t("footer.links.whatIsCryptoWallet", "What is a crypto wallet?"),
    },
    {
      url: `${LEARN_URL}/what-is-cryptocurrency`,
      label: t("footer.links.whatIsCryptocurrency", "What is cryptocurrency?"),
    },
    {
      url: `${LEARN_URL}/nft-gas-fees`,
      label: t("footer.links.nftGasFees", "What are blockchain gas fees?"),
    },
    {
      url: `${LEARN_URL}/what-is-blockchain`,
      label: t("footer.links.whatIsBlockchain", "What is a blockchain?"),
    },
    {
      url: `${LEARN_URL}/what-is-web3`,
      label: t("footer.links.whatIsWeb3", "What is web3?"),
    },
    {
      url: `${LEARN_URL}/how-to-stay-protected-in-web3`,
      label: t(
        "footer.links.howToStayProtectedInWeb3",
        "How to stay protected in web3",
      ),
    },
  ]
  const { theme: themeName } = useTheme()

  const isIosWebview = useIsIosWebview()
  if (isIosWebview) {
    return null
  }

  return (
    <DivContainer>
      <Container className="Footer--container">
        <Flex className="Footer--row">
          <Flex className="Footer--column Footer--third">
            <Block className="Footer--section-header">
              {t("footer.stayInTheLoop", "Stay in the loop")}
            </Block>
            <Block className="Footer--text">
              {t(
                "footer.joinOurMailingList",
                "Join our mailing list to stay in the loop with our newest feature releases, NFT drops, and tips and tricks for navigating OpenSea.",
              )}
            </Block>
            <MailingSignupForm />
          </Flex>
          <Flex className="Footer--column Footer--third">
            <Block className="Footer--section-header">
              {t("footer.joinTheCommunity", "Join the community")}
            </Block>

            <Flex className="flex-wrap justify-center">
              {getSocialLinks({ width: 30, fill: "white" }).map(link => {
                const button = (
                  <Button
                    aria-label={link.label}
                    className="Footer--social-button"
                    variant={themeName === "dark" ? "secondary" : "primary"}
                  >
                    {link.logo}
                  </Button>
                )
                if (link.external) {
                  return (
                    <ExternalLink
                      eventSource="Footer"
                      href={link.url}
                      key={link.url}
                    >
                      {button}
                    </ExternalLink>
                  )
                }
                return (
                  <Link eventSource="Footer" href={link.url} key={link.url}>
                    {button}
                  </Link>
                )
              })}
            </Flex>
          </Flex>
          <Flex className="Footer--column Footer--third">
            <Block className="Footer--section-header">
              {t("footer.support", "Need help?")}
            </Block>
            <Button
              className="Footer--support-button"
              type="submit"
              onClick={handleClick}
            >
              {t("footer.contactSupport", "Contact Support")}
            </Button>
          </Flex>
        </Flex>
        <Flex className="Footer--row">
          <Flex className="Footer--column Footer--quarter">
            <Image
              alt={t("brand.logo.alt", "Logo")}
              height={44}
              src={`https://opensea.io${OPENSEA_WHITE_LOGO_IMG}`}
              width={44}
            />
            <Link
              className="Footer--section-header"
              eventSource="Footer"
              href={origin}
            >
              {t("brand.companyName", "OpenSea")}
            </Link>
            <Block className="Footer--text">
              {t(
                "brand.companyDescription",
                "The world’s first and largest digital marketplace for crypto collectibles and non-fungible tokens (NFTs). Buy, sell, and discover exclusive digital items.",
              )}
            </Block>
          </Flex>
          <Flex className="Footer--three-quarters">
            <Flex className="Footer--link-column">
              <Text asChild color="white" weight="semibold">
                <h3>{t("footer.marketplaceCTA", "Marketplace")}</h3>
              </Text>
              <ul className="Footer--link-list">
                {exploreLinks.map(link => (
                  <li className="Footer--link-wrapper" key={link.url}>
                    <FooterLink external={external} link={link} />
                  </li>
                ))}
              </ul>
            </Flex>
            <Flex className="Footer--link-column">
              <Text asChild color="white" weight="semibold">
                <h3>{t("footer.myAccount", "My Account")}</h3>
              </Text>
              <ul className="Footer--link-list">
                {accountLinks.map(link => (
                  <li className="Footer--link-wrapper" key={link.url}>
                    <FooterLink external={external} link={link} />
                  </li>
                ))}
              </ul>
              <Text asChild className="mt-12" color="white" weight="semibold">
                <h3>{t("footer.stats", "Stats")}</h3>
              </Text>
              <ul className="Footer--link-list">
                {statLinks.map(link => (
                  <li className="Footer--link-wrapper" key={link.url}>
                    <FooterLink external={external} link={link} />
                  </li>
                ))}
              </ul>
            </Flex>
            <Flex className="Footer--link-column">
              <Text asChild color="white" weight="semibold">
                <h3>{t("footer.resources", "Resources")}</h3>
              </Text>
              <ul className="Footer--link-list">
                {resourceLinks.map(link => (
                  <li className="Footer--link-wrapper" key={link.url}>
                    <FooterLink external link={link} />
                  </li>
                ))}
              </ul>
            </Flex>
            <Flex className="Footer--link-column">
              <Text asChild color="white" weight="semibold">
                <h3>{t("footer.company", "Company")}</h3>
              </Text>
              <ul className="Footer--link-list">
                {companyLinks.map(link => (
                  <li className="Footer--link-wrapper" key={link.url}>
                    <FooterLink external={external} link={link} />
                  </li>
                ))}
              </ul>
              <Text asChild className="mt-12" color="white" weight="semibold">
                <h3>{t("footer.learn", "Learn")}</h3>
              </Text>
              <ul className="Footer--link-list">
                {learnArticleLinks.map(link => (
                  <li className="Footer--link-wrapper" key={link.url}>
                    <FooterLink external={external} link={link} />
                  </li>
                ))}
              </ul>
            </Flex>
          </Flex>
        </Flex>

        <Flex className="Footer--bottom">
          <Flex className="Footer--bottom-section">
            <p>
              {t(
                "footer.copyright",
                "© 2018 - {{currentYear}} Ozone Networks, Inc",
                { currentYear: new Date().getFullYear() },
              )}
            </p>
          </Flex>
          <Flex className="Footer--bottom-section">
            <Link className="Footer--link" eventSource="Footer" href="/privacy">
              {t("footer.privacyPolicy", "Privacy Policy")}
            </Link>
            <Link className="Footer--link" eventSource="Footer" href="/tos">
              {t("footer.termsOfService", "Terms of Service")}
            </Link>
          </Flex>
        </Flex>
      </Container>
    </DivContainer>
  )
}

export default Footer

const FooterLink = ({
  link,
  external,
}: {
  link: LinkItem
  external: boolean
}) => {
  return (
    <Link
      className="Footer--link"
      eventSource="Footer"
      href={link.url}
      target={external ? "_blank" : undefined}
    >
      {link.label}
    </Link>
  )
}

const DivContainer = styled.div`
  color: ${props => props.theme.colors.white};
  height: auto;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;

  ${props =>
    themeVariant({
      variants: {
        light: {
          backgroundColor: props.theme.colors.darkSeaBlue,
        },
        dark: {
          backgroundColor: props.theme.colors.components.background.gray1,
        },
      },
    })}

  .Footer--container {
    .Footer--column {
      flex-direction: column;
      padding-top: 20px;
      padding-left: 0;

      &.Footer--third {
        width: 100%;
        align-items: center;
        text-align: center;

        ${media({
          xl: css`
            width: 33%;
            padding-top: 40px;
            align-items: flex-start;
            text-align: left;

            &:first-of-type {
              padding-right: 64px;
            }

            &:last-of-type {
              padding-left: 64px;
            }
          `,
        })}
      }

      &.Footer--quarter {
        width: 100%;
        align-items: center;
        text-align: center;

        ${media({
          lg: css`
            width: 25%;
            padding-top: 40px;
            align-items: flex-start;
            text-align: left;
          `,
        })}
      }
    }

    .Footer--three-quarters {
      width: 100%;
      height: fit-content;
      align-items: flex-start;
      padding-top: 20px;
      padding-left: 0;
      justify-content: space-around;
      flex-wrap: wrap;

      ${media({
        lg: css`
          width: 75%;
          padding-top: 40px;
          align-items: flex-start;
          padding-left: 72px;
        `,
      })}

      .Footer--link-column {
        flex-direction: column;
        height: 50%;
        width: 50%;
        padding-top: 20px;
        align-items: center;

        &:first-of-type {
          margin-bottom: 16px;
        }

        ${media({
          md: css`
            width: 20%;
            height: 100%;
            padding-top: 0;
            align-items: flex-start;
          `,
        })}
      }
    }

    .Footer--bottom {
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      margin-top: 20px;
      margin-bottom: 20px;
      flex-wrap: wrap;

      .Footer--bottom-section {
        width: 100%;
        justify-content: center;
        text-align: center;

        .Footer--link {
          color: ${props => props.theme.colors.white};
          margin-top: 0;
          font-size: 12px;

          &:last-of-type {
            margin-left: 16px;
          }

          &:hover {
            font-weight: 500;
          }
        }

        ${media({
          md: css`
            width: 75%;
            justify-content: flex-start;
            text-align: initial;

            &:last-of-type {
              width: 25%;
              justify-content: flex-end;
            }
          `,
        })}
      }
    }

    .Footer--link-list {
      margin-top: 0;
    }

    .Footer--text {
      font-size: 16px;
      color: ${props => props.theme.colors.white};
    }

    .Footer--section-header {
      color: ${props => props.theme.colors.white};
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 8px;
      margin-top: 8px;
    }

    .Footer--social-button {
      margin-right: 12px;
      width: 54px;
      height: 54px;
      margin-top: 8px;

      &:hover {
        filter: brightness(1.1);
        border: 1px solid rgba(0, 0, 0, 0);
        background-color: ${props => props.theme.colors.primary};
      }
    }

    .Footer--support-button {
      white-space: nowrap;

      &:hover {
        filter: brightness(1.1);
        border: 0;
        background-color: ${props => props.theme.colors.primary};
      }
    }

    .Footer--link {
      color: ${props => props.theme.colors.white};

      &:hover {
        font-weight: 500;
      }
    }

    .Footer--link-wrapper {
      font-size: 14px;
      margin-top: 12px;
      text-align: center;

      ${media({
        md: css`
          text-align: initial;
        `,
      })}
    }

    .Footer--row {
      flex-wrap: wrap;
      padding-bottom: 40px;
      margin-bottom: 20px;
      border-bottom: solid 1px
        ${props => props.theme.colors.withOpacity.fog.light};
    }
  }
`
