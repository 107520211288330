import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"
import { isFunction, noop } from "lodash"

export type RenderStep =
  | React.ReactNode
  | ((context: MultiStepContext) => React.ReactNode)

export type MultiStepFlowProps = {
  children: RenderStep
  onPrevious?: () => unknown
}

type MultiStepContext = {
  onPrevious?: () => unknown
  onNext: (render: RenderStep) => unknown
  onReplace: (render: RenderStep) => unknown
}

const DEFAULT_CONTEXT: MultiStepContext = {
  onPrevious: undefined,
  onNext: noop,
  onReplace: noop,
}

const MultiStepFlowContext = createContext(DEFAULT_CONTEXT)

export const useMultiStepFlowContext = () => useContext(MultiStepFlowContext)

export const MultiStepContextConsumer = MultiStepFlowContext.Consumer

export const MultiStepFlow = ({ children, onPrevious }: MultiStepFlowProps) => {
  const [steps, setSteps] = useState<RenderStep[]>([children])

  const handlePrevious = useCallback(() => {
    onPrevious?.()
    setSteps(prev => prev.slice(0, -1))
  }, [onPrevious])

  const onNext = useCallback((render: RenderStep) => {
    setSteps(prev => [...prev, render])
  }, [])

  const onReplace = useCallback((render: RenderStep) => {
    setSteps([render])
  }, [])

  const currentStep = steps[steps.length - 1]

  const value = useMemo(
    () => ({
      onPrevious: steps.length <= 1 ? undefined : handlePrevious,
      onNext,
      onReplace,
    }),
    [onNext, onReplace, handlePrevious, steps.length],
  )

  return (
    <MultiStepFlowContext.Provider value={value}>
      {isFunction(currentStep) ? currentStep(value) : currentStep}
    </MultiStepFlowContext.Provider>
  )
}
