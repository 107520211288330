import React, { RefCallback, Suspense } from "react"
import { Text } from "@opensea/ui-kit"
import {
  graphql,
  PreloadedQuery,
  useFragment,
  usePreloadedQuery,
} from "react-relay"
import { StyledListItem } from "@/components/nav/WalletPopover/elements"
import { AddFundsModal } from "@/components/trade/AddFundsModal"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { useChains } from "@/hooks/useChains"
import { useTranslate } from "@/hooks/useTranslate"
import { ManageBalanceAddFunds_data$key } from "@/lib/graphql/__generated__/ManageBalanceAddFunds_data.graphql"
import { NetworkBalanceDropdownQuery } from "@/lib/graphql/__generated__/NetworkBalanceDropdownQuery.graphql"
import { NETWORK_BALANCE_DROPDOWN_QUERY } from "../NetworkBalanceDropdown.react"
import { ActionListItemSkeleton } from "./ManageBalance.react"

type ManageBalanceAddFundsBaseProps = {
  queryReference: PreloadedQuery<NetworkBalanceDropdownQuery>
  keepOpenOnClickRefCallback: RefCallback<HTMLElement>
}

const ManageBalanceAddFundsBase = ({
  queryReference,
  keepOpenOnClickRefCallback,
}: ManageBalanceAddFundsBaseProps) => {
  const t = useTranslate("components")
  const { chain: activeChain } = useWallet()
  const { getNativeCurrencySymbol } = useChains()
  const nativeCurrencySymbol = activeChain
    ? getNativeCurrencySymbol(activeChain)
    : undefined

  const data = usePreloadedQuery(NETWORK_BALANCE_DROPDOWN_QUERY, queryReference)

  const {
    wallet: { funds },
  } = useFragment<ManageBalanceAddFunds_data$key>(
    graphql`
      fragment ManageBalanceAddFunds_data on Query
      @argumentDefinitions(
        address: { type: "AddressScalar!" }
        chain: { type: "ChainScalar!" }
      ) {
        wallet(address: $address) {
          funds(chain: $chain) {
            chain
            symbol
          }
        }
      }
    `,
    data,
  )

  if (funds.some(fund => fund.symbol === nativeCurrencySymbol)) {
    // Adding funds is handled through the native token in NetworkTokenBalances
    // so if it is present, do not render this generic add funds option in ManageBalance
    return null
  }

  return (
    <AddFundsModal
      overlayRef={keepOpenOnClickRefCallback}
      trigger={open => (
        <StyledListItem className="border-0" onClick={open}>
          <StyledListItem.Avatar icon="add" />
          <StyledListItem.Content>
            <Text.Body weight="semibold">
              {t("networkTokens.addFunds", "Add funds")}
            </Text.Body>
          </StyledListItem.Content>
        </StyledListItem>
      )}
    />
  )
}

type ManageBalanceAddFundsProps = Omit<
  ManageBalanceAddFundsBaseProps,
  "queryReference"
> & {
  queryReference: PreloadedQuery<NetworkBalanceDropdownQuery> | undefined | null
}

export const ManageBalanceAddFunds = ({
  queryReference,
  ...restProps
}: ManageBalanceAddFundsProps) => {
  if (!queryReference) {
    return <ActionListItemSkeleton />
  }

  return (
    <Suspense fallback={<ActionListItemSkeleton />}>
      <ManageBalanceAddFundsBase
        queryReference={queryReference}
        {...restProps}
      />
    </Suspense>
  )
}
