import React, { useEffect } from "react"
import { Container, Text } from "@opensea/ui-kit"
import { useLocalStorage } from "react-use"
import { Modal } from "@/design-system/Modal"
import { useGlobalModal } from "@/hooks/useGlobalModal"
import { usePageVisibility } from "@/hooks/usePageVisibility"
import { useTranslate } from "@/hooks/useTranslate"
import Auth from "@/lib/auth"

interface SwitchAccountsModalContentProps {
  address: string | undefined
  doAccountChange: () => Promise<void>
}

export const SwitchAccountsModalContent = ({
  address,
  doAccountChange,
}: SwitchAccountsModalContentProps) => {
  const t = useTranslate("components")
  const { closeModal } = useGlobalModal()
  const pageVisible = usePageVisibility()
  const [_, setSignatureInProgress] = useLocalStorage(
    "signatureInProgress",
    false,
  )
  useEffect(() => {
    const reallyDoAccountChange = async () => {
      const signatureInProgress =
        localStorage.getItem("signatureInProgress") === "true"

      if (pageVisible && !signatureInProgress) {
        setSignatureInProgress(true)
        try {
          await doAccountChange()
        } finally {
          setSignatureInProgress(false)
        }

        if (Auth.getIsAuthenticated(address)) {
          closeModal()
        }
      }
    }

    reallyDoAccountChange()
  }, [
    address,
    pageVisible,
    closeModal,
    doAccountChange,
    setSignatureInProgress,
  ])

  return (
    <>
      <Modal.Header textAlign="center">
        <Text.Heading asChild size="small">
          <h4>{t("switchAccountsModal.modalHeader", "Sign in your wallet")}</h4>
        </Text.Heading>
      </Modal.Header>
      <Modal.Body textAlign="center">
        <Container className="mb-12">
          <Text.Body>
            {t(
              "switchAccountsModal.bodyText",
              "You will be prompted to sign a message to authenticate, please check your wallet.",
            )}
          </Text.Body>
        </Container>
      </Modal.Body>
    </>
  )
}
