import { ChainIdentifier } from "@/hooks/useChains/types"
import { getTrackingFn } from "@/lib/analytics"
import { getItemTrackingFnV2 } from "@/lib/analytics/events/itemEvents"
import { BulkPurchaseFillType } from "@/lib/graphql/__generated__/BulkPurchaseActionModalQuery.graphql"
import { BulkPurchaseErrorReason } from "@/lib/graphql/__generated__/BulkPurchaseErrorType.graphql"

export const trackOpenCart = getTrackingFn("open cart")
export const trackCloseCart = getTrackingFn("close cart")
export const trackIncrementItemQuantity = getItemTrackingFnV2(
  "increment cart item quantity",
)
export const trackDecrementItemQuantity = getItemTrackingFnV2(
  "decrement cart item quantity",
)
export const trackClickCompletePurchase = getTrackingFn<{
  numberOfItems: number
  chain: ChainIdentifier
}>("click complete purchase with cart")
export const trackLoadCartOnPageLoad = getTrackingFn<{ numberOfItems: number }>(
  "load cart on page load",
)
export const trackCartError = getTrackingFn<{
  errorReason: BulkPurchaseErrorReason
}>("show error on cart")
export const trackClickShoppingCartGiftRecipient = getTrackingFn(
  "click shopping cart gift recipient",
)
export const trackBulkPurchaseProcessed = getTrackingFn<{
  processedState: "success" | "partial success" | "fail"
  isGift: boolean
  chain: ChainIdentifier
  fillType: BulkPurchaseFillType
}>("bulk purchase processed")
export const trackShowInactiveListing = getTrackingFn<{
  numInactiveListings: number
}>("show inactive listing on cart")
