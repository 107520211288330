import React, { forwardRef } from "react"
import { Media, classNames } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { Block, BlockProps } from "@/design-system/Block"
import { Button, ButtonProps } from "@/design-system/Button"
import { useModalContext } from "@/design-system/Modal/ModalContext"
import { media } from "@/styles/styleUtils"
import { MOBILE_MODAL_PADDING, MODAL_PADDING } from "../../constants"

export const ModalFooter = (props: BlockProps) => {
  const { isExtraLargeWidth, isModalBodyFullyScrolled } = useModalContext()

  return (
    <StyledModalFooter
      $hasBorder={!isModalBodyFullyScrolled}
      justifyContent={isExtraLargeWidth ? "flex-end" : "center"}
      padding={{ _: MOBILE_MODAL_PADDING, sm: MODAL_PADDING }}
      {...props}
    />
  )
}

const StyledModalFooter = styled(Block).attrs<BlockProps>({
  as: "footer",
})<{ $hasBorder: boolean }>`
  border-top: ${props =>
    props.$hasBorder &&
    css`1px solid ${props.theme.colors.components.border.level2}`};
  display: flex;
  width: 100%;
  flex-direction: column;
  grid-row-gap: 8px;
  ${media({
    sm: css`
      flex-direction: row;
      grid-column-gap: 12px;
    `,
  })}
`

const ModalFooterButton = forwardRef<HTMLButtonElement, ButtonProps>(
  function ModalFooterButton({ className, ...props }, ref) {
    const { isExtraLargeWidth } = useModalContext()

    return (
      <ButtonContainer $fullWidth={!isExtraLargeWidth}>
        <Media greaterThanOrEqual="md">
          <Button
            className={classNames(
              isExtraLargeWidth ? undefined : "w-full",
              className,
            )}
            {...props}
            ref={ref}
          />
        </Media>
        <Media lessThan="md">
          <Button
            className={classNames(
              isExtraLargeWidth ? undefined : "w-full",
              className,
            )}
            {...props}
            ref={ref}
          />
        </Media>
      </ButtonContainer>
    )
  },
)

ModalFooter.Button = ModalFooterButton

const ButtonContainer = styled(Block)<{ $fullWidth: boolean }>`
  ${({ $fullWidth }) =>
    $fullWidth
      ? css`
          width: 100%;
        `
      : ""};
`
