import { Currency } from "@/components/nav/WalletPopover/types"
import { useHandleBlockchainActions_transaction$key } from "@/lib/graphql/__generated__/useHandleBlockchainActions_transaction.graphql"
import {
  BigNumber,
  SMALLEST_DISPLAYED_QUANTITY,
  MAX_DISPLAYED_DECIMAL_PLACES,
} from "@/lib/helpers/numberUtils"
import { BridgeOrWrapDirection } from "../BridgeOrWrapForm.react"
import { resolveSwapAction } from "./resolveSwapActions"

type EstimateGasForSwapProps = {
  baseCurrency: Currency
  swapCurrency: Currency
  direction: BridgeOrWrapDirection
  estimateGas: (
    dataKey: useHandleBlockchainActions_transaction$key,
  ) => Promise<BigNumber>
}

export const estimateGasForSwap = async ({
  baseCurrency,
  swapCurrency,
  direction,
  estimateGas,
}: EstimateGasForSwapProps) => {
  const gasEstimateQuantity = SMALLEST_DISPLAYED_QUANTITY.toString() // Use dummy  for estimate

  const [swapAction, dispose] = await resolveSwapAction({
    quantity: gasEstimateQuantity,
    direction,
    baseCurrency,
    swapCurrency,
  })

  if (!swapAction.method) {
    throw new Error(
      "Expected swap action to contain a transaction submission method",
    )
  }

  const gasEstimate = await estimateGas(swapAction.method)
  const getRoundedGasEstimate = () => {
    const adjustedGasEstimate = gasEstimate.times(1.5)
    const roundedGasEstimate = adjustedGasEstimate.decimalPlaces(
      MAX_DISPLAYED_DECIMAL_PLACES,
      BigNumber.ROUND_UP,
    )
    if (roundedGasEstimate.isZero()) {
      return SMALLEST_DISPLAYED_QUANTITY
    }
    return roundedGasEstimate
  }

  dispose()

  return getRoundedGasEstimate()
}
