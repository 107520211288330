import React from "react"

type Props = {
  className?: string
  width?: number
  height?: number
  fill?: string
}

export const OptimismAltLogo = ({
  className,
  fill = "#707A83",
  width = 20,
  height = 20,
}: Props) => (
  <svg
    className={className}
    fill={fill}
    style={{ width, height }}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.55283 16.9742C5.5089 16.9742 4.65362 16.7286 3.98691 16.2373C3.32897 15.7373 3 15.0268 3 14.1056C3 13.9126 3.02195 13.6758 3.06578 13.395C3.17988 12.7635 3.34216 12.0046 3.55268 11.1186C4.14919 8.7062 5.68878 7.5 8.17138 7.5C8.84686 7.5 9.45213 7.61403 9.98728 7.84209C10.5224 8.06138 10.9435 8.39476 11.2505 8.84218C11.5575 9.28076 11.7111 9.80714 11.7111 10.4212C11.7111 10.6054 11.6891 10.8379 11.6452 11.1186C11.5137 11.8993 11.3558 12.6582 11.1715 13.395C10.8645 14.5969 10.3338 15.4961 9.57934 16.0926C8.82491 16.6803 7.81611 16.9742 6.55283 16.9742ZM6.73705 15.0794C7.22837 15.0794 7.645 14.9346 7.98716 14.6451C8.33808 14.3557 8.58808 13.9127 8.73718 13.3161C8.93893 12.4915 9.09251 11.7721 9.19778 11.1581C9.23284 10.9739 9.25037 10.7853 9.25037 10.5922C9.25037 9.79396 8.83367 9.39479 8.00034 9.39479C7.50902 9.39479 7.08797 9.53953 6.73705 9.82902C6.39497 10.1186 6.14931 10.5616 6.00022 11.1581C5.84229 11.7458 5.68436 12.4652 5.5265 13.3161C5.49137 13.4915 5.47384 13.6758 5.47384 13.8688C5.47384 14.6759 5.89496 15.0794 6.73705 15.0794Z"
      fill={fill}
    />
    <path
      d="M12.3151 16.8424C12.2186 16.8424 12.144 16.8117 12.0914 16.7502C12.0475 16.68 12.0344 16.6011 12.052 16.5134L13.8678 7.96024C13.8854 7.86375 13.9336 7.78478 14.0126 7.72335C14.0916 7.66199 14.1749 7.63127 14.2626 7.63127H17.7628C18.7366 7.63127 19.5173 7.83303 20.105 8.23655C20.7016 8.64013 20.9999 9.22346 20.9999 9.98666C20.9999 10.206 20.9736 10.4341 20.9209 10.6709C20.7016 11.6798 20.2586 12.4254 19.5919 12.9079C18.934 13.3904 18.0304 13.6316 16.8812 13.6316H15.1048L14.4995 16.5134C14.4819 16.6099 14.4336 16.6888 14.3547 16.7502C14.2758 16.8117 14.1924 16.8424 14.1047 16.8424H12.3151ZM16.9733 11.8157C17.3417 11.8157 17.6619 11.7148 17.9339 11.5131C18.2146 11.3113 18.3988 11.0218 18.4866 10.6446C18.5128 10.4954 18.526 10.3639 18.526 10.2499C18.526 9.99543 18.4515 9.80244 18.3023 9.67088C18.1532 9.53048 17.8988 9.46035 17.5391 9.46035H15.9601L15.46 11.8157H16.9733Z"
      fill={fill}
    />
  </svg>
)
