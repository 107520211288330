/**
 * @generated SignedSource<<414cce94b4f1d809409d5243c34c9e3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useTotalPriceOrderDataToFill_ordersToFill$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"readOrderDataToFillPrices_orderDataToFill">;
  readonly " $fragmentType": "useTotalPriceOrderDataToFill_ordersToFill";
}>;
export type useTotalPriceOrderDataToFill_ordersToFill$key = ReadonlyArray<{
  readonly " $data"?: useTotalPriceOrderDataToFill_ordersToFill$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTotalPriceOrderDataToFill_ordersToFill">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "useTotalPriceOrderDataToFill_ordersToFill",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "readOrderDataToFillPrices_orderDataToFill",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderDataType",
          "kind": "LinkedField",
          "name": "orderData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceType",
              "kind": "LinkedField",
              "name": "perUnitPrice",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "usd",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unit",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PaymentAssetType",
              "kind": "LinkedField",
              "name": "payment",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "TokenPricePayment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "symbol",
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "itemFillAmount",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "OrderToFillType",
  "abstractKey": null
};

(node as any).hash = "f1b52234ec98c85c2abe47aa1faaea51";

export default node;
