/**
 * @generated SignedSource<<fbdbdc17b0a98a9200982ce6ee0df465>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useHandleBlockchainActions_burnToRedeem$data = {
  readonly method: {
    readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_transaction">;
  };
  readonly " $fragmentType": "useHandleBlockchainActions_burnToRedeem";
};
export type useHandleBlockchainActions_burnToRedeem$key = {
  readonly " $data"?: useHandleBlockchainActions_burnToRedeem$data;
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_burnToRedeem">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useHandleBlockchainActions_burnToRedeem"
};

(node as any).hash = "dbd69beb68c381ac6098bb335f50747e";

export default node;
