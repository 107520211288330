import React, { Suspense, useState } from "react"
import { CenterAligned, Text, Checkbox, classNames } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { InfoIcon } from "@/components/common/InfoIcon.react"
import { Link } from "@/components/common/Link"
import { Block } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { Modal } from "@/design-system/Modal"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { useTranslate } from "@/hooks/useTranslate"
import { AnalyticsContextProvider } from "@/lib/analytics"
import { media } from "@/styles/styleUtils"
import { AssetCollectionDetails } from "./components/AssetCollectionDetails"
import { CollectionDetailsSkeleton } from "./components/CollectionDetailsSkeleton"
import { CollectionOfferCollectionDetails } from "./components/CollectionOfferCollectionDetails"
import { EVENT_SOURCE } from "./constants"

type AssetCollectionDetailsProps = {
  assetId: string
  collectionSlug?: undefined
}

type CollectionOfferDetailsProps = {
  assetId?: undefined
  collectionSlug: string
}

type QueryProps = AssetCollectionDetailsProps | CollectionOfferDetailsProps

type ModalProps = {
  hideCheckbox?: boolean
  renderNextModal?: () => React.ReactNode
  onChange?: (checked: boolean) => void
} & QueryProps

export const CollectionDetailsModal = ({
  assetId,
  collectionSlug,
  onChange,
  renderNextModal,
  hideCheckbox,
}: ModalProps) => {
  const t = useTranslate("collections")
  const [hasConfirmed, setHasConfirmed] = useState(false)
  const { onNext } = useMultiStepFlowContext()
  const handleNext = () => {
    setHasConfirmed(true)

    if (renderNextModal) {
      onNext(renderNextModal())
    }
  }

  return (
    <AnalyticsContextProvider eventSource={EVENT_SOURCE}>
      <Modal.Header
        padding={{ _: "24px 48px 24px 16px", lg: "32px 64px 24px 24px" }}
      >
        <Modal.Header.Title className="mb-2">
          {t(
            "collectionDetailsModal.title",
            "This is an unreviewed collection",
          )}
        </Modal.Header.Title>
        <Flex>
          <Text color="secondary" size="medium">
            {t(
              "details.reviewPrompt",
              "Review this information to ensure it’s what you want to buy.",
            )}{" "}
            <InfoIcon
              overrides={{
                Button: { style: { verticalAlign: "middle" } },
                Icon: { size: 16 },
              }}
              tooltipContent={t("details.learnMoreText", "{{link}}.", {
                link: (
                  <Link href="https://opensea.io/learn/web3/how-to-stay-protected-in-web3">
                    {t(
                      "details.learnMoreLinkCTA",
                      "Learn more about protecting yourself from scams here",
                    )}
                  </Link>
                ),
              })}
            />
          </Text>
        </Flex>
      </Modal.Header>
      <StyledBody>
        <Flex alignItems="center" justifyContent="center"></Flex>
        <Suspense fallback={<CollectionDetailsSkeleton />}>
          {assetId && (
            <AssetCollectionDetails
              assetId={assetId}
              hasConfirmedDetails={hasConfirmed}
            />
          )}
          {collectionSlug && (
            <CollectionOfferCollectionDetails
              collectionSlug={collectionSlug}
              hasConfirmedDetails={hasConfirmed}
            />
          )}
        </Suspense>
      </StyledBody>

      {!hideCheckbox && (
        <Modal.Footer alignItems="flex-start">
          <CenterAligned>
            <Flex>
              <Checkbox
                autoFocus
                checked={hasConfirmed}
                id="review-confirmation"
                onCheckedChange={checked => {
                  handleNext()
                  onChange?.(checked)
                }}
              />
              <Block marginLeft="8px">
                <Text asChild className="text-left" color="primary">
                  <label htmlFor="review-confirmation">
                    {t(
                      "details.unreviewed.acknowledgement",
                      "I understand that OpenSea has not reviewed this collection and blockchain transactions are irreversible.",
                    )}
                  </label>
                </Text>
              </Block>
            </Flex>
          </CenterAligned>
        </Modal.Footer>
      )}
    </AnalyticsContextProvider>
  )
}

const StyledBody = styled(Modal.Body)`
  .CollectionDetailsModal--content {
    border: 1px solid ${props => props.theme.colors.components.border.level2};
    border-radius: ${props => props.theme.borderRadius.list};
    padding: 12px, 0px, 12px, 0px;
  }

  .CollectionDetailsModal--details {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .CollectionDetailsModal--detail-content {
    align-items: center;
    padding: 12px 16px;
    font-size: 16px;
  }

  .CollectionDetailsModal--detail-type {
    color: ${props => props.theme.colors.text.secondary};
    flex: none;
    line-height: 24px;
    margin-right: 16px;
    flex-shrink: 0;
    ${media({
      xs: css`
        width: 88px;
      `,
      lg: css`
        width: 200px;
      `,
    })}
  }
`

export const collectionDetailsModalDetailStyles = classNames(
  "mr-4 flex-none shrink-0 xs:w-[88px] lg:w-[200px]",
)
