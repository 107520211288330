import React, { createContext, useContext, useSyncExternalStore } from "react"
import {
  EIP1193Provider,
  EIP6963ProviderDetail,
  Store,
  createStore,
} from "mipd"
import { WALLET_NAME } from "@/constants/wallet"

export const getWalletNameFrom6963Rdns = (
  rdns: string,
): WALLET_NAME | undefined => {
  switch (rdns) {
    case "com.coinbase.wallet":
      return WALLET_NAME.CoinbaseWallet
    case "app.phantom":
      return WALLET_NAME.Phantom
    case "io.metamask":
      return WALLET_NAME.MetaMask
    case "com.trustwallet.app":
      return WALLET_NAME.Trust
    default:
      return
  }
}

type MipdContextType = {
  eip6963providers?: readonly EIP6963ProviderDetail<
    EIP1193Provider,
    // eslint-disable-next-line @typescript-eslint/ban-types
    (string & {}) | "com.coinbase" | "com.enkrypt" | "io.metamask" | "io.zerion"
  >[]
}
const MipdContext = createContext<MipdContextType>({
  eip6963providers: undefined,
})

type Props = {
  children: React.ReactNode
}

const store = typeof window !== "undefined" ? createStore() : ({} as Store)
export const MipdContextProvider = ({ children }: Props) => {
  const eip6963providers = useSyncExternalStore(
    store.subscribe,
    store.getProviders,
  )

  return (
    <MipdContext.Provider value={{ eip6963providers }}>
      {children}
    </MipdContext.Provider>
  )
}

export const useMipdContext = () => useContext(MipdContext)
