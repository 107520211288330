import type TBitskiModule from "bitski"
import { AuthenticationStatus } from "bitski"
import { ethers } from "ethers"
import { WALLET_NAME } from "@/constants/wallet"
import { Chain } from "@/hooks/useChains/types"
import { getEthereumChain } from "@/lib/helpers/chainUtils"
import { BITSKI_CLIENT_ID } from "../../../constants"
import type { WalletAccountKey } from "../chain"
import Web3EvmProvider, {
  ETHERS_WEB3_PROVIDER_NETWORK,
  ExternalProvider,
  Web3Provider,
} from "./web3EvmProvider"

class BitskiProvider extends Web3EvmProvider {
  Bitski: typeof TBitskiModule
  bitski: TBitskiModule.Bitski
  provider: Web3Provider

  constructor(Bitski: typeof TBitskiModule, chains: Chain[], origin: string) {
    super(chains)

    this.bitski = new Bitski.Bitski(
      BITSKI_CLIENT_ID,
      `${origin}/callback/bitski`,
    )
    this.Bitski = Bitski
    this.provider = new ethers.providers.Web3Provider(
      (getEthereumChain() === "SEPOLIA"
        ? this.bitski.getProvider({ networkName: "sepolia" })
        : this.bitski.getProvider()) as unknown as ExternalProvider,
      ETHERS_WEB3_PROVIDER_NETWORK,
    )
  }

  private shouldSignIn = (status: AuthenticationStatus): boolean => {
    return [
      AuthenticationStatus.NotConnected,
      AuthenticationStatus.Expired,
    ].includes(status)
  }

  connect = async (): Promise<WalletAccountKey[]> => {
    const authStatus = await this.bitski.getAuthStatus()
    if (this.shouldSignIn(authStatus)) {
      await this.bitski.signInRedirect()
    }
    const connections = await super.connect()

    // If the user disconnects from Bitski's webapp,
    // there will be stale logged-in status in localStorage
    if (connections.length === 0) {
      await this.bitski.signInRedirect()
      return super.connect()
    }
    return connections
  }

  disconnect = () => {
    return this.bitski.signOut()
  }

  getName = () => {
    return WALLET_NAME.Bitski
  }
}

export const createBitskiProvider = async (
  chains: Chain[],
): Promise<BitskiProvider> => {
  const Bitski = await import("bitski")
  return new BitskiProvider(Bitski, chains, window.location.origin)
}
