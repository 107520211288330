import React from "react"
import { UnstyledButton } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { TooltipProps } from "@/design-system/Tooltip"
import { TextCopier } from "./TextCopier.react"

export type TextCopierButtonProps = {
  children?: React.ReactNode
  className?: string
  label?: string
  text: string
  truncationWidth?: string
  placement?: TooltipProps["placement"]
  onCopy?: () => unknown
}

export const TextCopierButton = React.forwardRef<
  HTMLButtonElement,
  TextCopierButtonProps
>(function TextCopierButton(
  {
    children,
    text,
    label,
    className,
    truncationWidth,
    placement = "top",
    onCopy,
  },
  ref,
) {
  return (
    <TextCopier label={label} placement={placement} text={text}>
      {copy => (
        <StyledContainer
          $isTruncated={truncationWidth !== undefined}
          className={className}
          ref={ref}
          style={{ width: truncationWidth }}
          onClick={() => {
            copy()
            onCopy?.()
          }}
        >
          {children || text}
        </StyledContainer>
      )}
    </TextCopier>
  )
})

const StyledContainer = styled(UnstyledButton)<{ $isTruncated: boolean }>`
  ${props =>
    props.$isTruncated &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`
