/* eslint-disable tailwindcss/no-custom-classname */
import React from "react"
import {
  Icon,
  Breakpoint,
  Media,
  UnstyledButton,
  VerticalAligned,
  classNames,
  Text,
  Flex,
} from "@opensea/ui-kit"
import styled from "styled-components"
import { CopyAddress } from "@/components/common/CopyAddress.react"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { Drawer } from "@/design-system/Drawer"
import { Image } from "@/design-system/Image"
import { useTranslate } from "@/hooks/useTranslate"
import { getChecksumAddress } from "@/lib/helpers/address"
import { WalletMenu } from "./WalletMenu.react"

type WalletSidebarHeaderProps = {
  fullscreenBreakpoint: Breakpoint
  close: () => unknown
}

export const WalletSidebarHeader = ({
  fullscreenBreakpoint,
  close,
}: WalletSidebarHeaderProps) => {
  const t = useTranslate("common")
  const { activeAccount } = useWallet()

  const { providers } = useWallet()

  const address = activeAccount?.address
  const username = activeAccount?.user?.publicUsername
  const imageUrl = activeAccount?.imageUrl

  const textContent = (
    <Flex>
      <VerticalAligned className="mr-2 rounded-full border border-level-2">
        {imageUrl ? (
          <AccountImage
            alt={t("walletSidebarHeader.accountImageAlt", "Account image")}
            height={26}
            objectFit="cover"
            src={imageUrl}
            width={26}
          />
        ) : (
          <Icon size={30} value="account_circle" />
        )}
      </VerticalAligned>
      <VerticalAligned>
        <Text className="max-w-[160px] truncate" weight="semibold">
          {username ||
            t(
              "walletSidebarHeader.myWalletOrWallets",
              { 0: "My wallets", one: "My wallet", other: "My wallets" },
              { count: address ? providers.length : 1 },
            )}
        </Text>
      </VerticalAligned>
    </Flex>
  )

  return (
    <StyledHeader>
      <Drawer.Title className="flex">
        <Media lessThan={fullscreenBreakpoint}>
          {mediaClassNames => (
            <VerticalAligned className={classNames("mr-2", mediaClassNames)}>
              <UnstyledButton onClick={close}>
                <Icon
                  className="text-secondary"
                  title={t("walletSidebarHeader.back", "Back")}
                  value="chevron_left"
                />
              </UnstyledButton>
            </VerticalAligned>
          )}
        </Media>

        {address ? <WalletMenu>{textContent}</WalletMenu> : textContent}
      </Drawer.Title>

      {address && (
        <Drawer.Subtitle className="WalletSidebar--subtitle">
          <CopyAddress address={getChecksumAddress(address)} />
        </Drawer.Subtitle>
      )}
    </StyledHeader>
  )
}

const AccountImage = styled(Image)`
  border-radius: ${props => props.theme.borderRadius.circle};
`

const StyledHeader = styled(Drawer.Header)`
  .WalletSidebar--user-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .WalletSidebar--subtitle > *:hover {
    color: ${props => props.theme.colors.text.secondary};
  }
`
