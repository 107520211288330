export type MapNonNullable<T> = {
  [K in keyof T]: NonNullable<T[K]>
}

export type MapNullable<T> = {
  [K in keyof T]: T[K] | null
}

export type Values<T extends Record<string, unknown>> = T[keyof T]

/*
 * Useful for exhausting union type options.
 * If new value is added to an union type, TS will fail if the case is not handled.
 */
export class UnreachableCaseError extends Error {
  public constructor(val: never) {
    super(`Unreachable case: ${val}`)
  }
}

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}

export type Maybe<T> = T | null | undefined

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export type DistributiveOmit<T, K extends string> = T extends unknown
  ? Omit<T, K>
  : never

// Useful for making the keys of an object required but preserving undefined if it was originally optional
export type RequiredKeys<T> = {
  [K in keyof Required<T>]: T[K]
}

export type PartiallyOptional<T, K extends keyof T> = Omit<T, K> &
  Partial<Pick<T, K>>
