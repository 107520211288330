import { ethers } from "ethers"
import { WALLET_NAME } from "@/constants/wallet"
import { Chain } from "@/hooks/useChains/types"
import BrowserWeb3Provider from "./browserWeb3Provider"
import { detectProvider } from "./detect"
import { ExternalProvider } from "./web3EvmProvider"

type AvalancheEthereumProvider = ExternalProvider & {
  isAvalanche: boolean
}

declare global {
  interface Window {
    avalanche?: AvalancheEthereumProvider
  }
}

const detectAvalancheProvider = (timeout = 3000) => {
  return detectProvider({
    timeout,
    key: "avalanche",
    initializationEvent: "avalanche#initialized",
    isInstalled: (wallet: AvalancheEthereumProvider | undefined) =>
      wallet !== undefined && wallet.isAvalanche,
  })
}

class AvalancheCoreProvider extends BrowserWeb3Provider {
  public getName(): WALLET_NAME {
    return WALLET_NAME.Core
  }
}

export const createAvalancheCoreProvider = async (chains: Chain[]) => {
  const ethereum = await detectAvalancheProvider()
  if (ethereum) {
    return new AvalancheCoreProvider(
      new ethers.providers.Web3Provider(ethereum),
      chains,
    )
  }
  return undefined
}
