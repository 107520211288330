import { useCallback } from "react"
import { useLoginWithPrivyTokenMutation$variables } from "@/lib/graphql/__generated__/useLoginWithPrivyTokenMutation.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"

export const useLoginWithPrivyToken = () => {
  const { mutate } = useGraphQL()

  return useCallback(
    async (variables: useLoginWithPrivyTokenMutation$variables) => {
      return await mutate(
        graphql`
          mutation useLoginWithPrivyTokenMutation(
            $deviceId: String!
            $accessToken: String!
          ) {
            ExchangeToken {
              exchange(deviceId: $deviceId, exchangeToken: $accessToken) {
                address
                isEmployee
              }
            }
          }
        `,
        variables,
      )
    },
    [mutate],
  )
}
