import type { ProfileImageUploadMutation } from "@/lib/graphql/__generated__/ProfileImageUploadMutation.graphql"

export const MEGABYTE_TO_BYTES = 1_048_576
const GIGABYTE_TO_BYTES = 1_073_741_824

export const getBytesFromMegabytes = (megabytes: number) => {
  return megabytes * MEGABYTE_TO_BYTES
}

export const getGigabytesFromBytes = (bytes: number) => {
  return bytes / GIGABYTE_TO_BYTES
}

export type UploadContext =
  ProfileImageUploadMutation["response"]["accounts"]["uploadProfilePicture"]

const getFileExtension = (file: File) => {
  return file.name.split(".").pop()
}

export const uploadFile = async (
  context: UploadContext,
  file: File,
  controller?: AbortController,
) => {
  const formData = new FormData()
  const fields = JSON.parse(context.fields)
  let contentType = file.type
  if (!contentType) {
    switch (getFileExtension(file)) {
      case "glb": {
        contentType = "model/gltf-binary"
        break
      }
      case "gltf":
        contentType = "model/gltf+json"
        break
    }
  }
  formData.append("Content-Type", contentType)
  for (const key of Object.keys(fields)) {
    formData.append(key, fields[key])
  }
  formData.append("file", file) // The file must be the last element
  const response = await fetch(context.url, {
    method: context.method,
    body: formData,
    signal: controller?.signal,
  })

  if (!response.ok) {
    throw new Error(await response.text())
  }

  return response
}
