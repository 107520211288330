import { Expiration } from "@/features/os-wallet/components/OSWalletModal/components/AuthCodeExpiration.react"

export const RESEND_CODE_TIMEOUT_SECONDS = 30
export const WALLET_TERMS_OF_SERVICE_URL = "/tosw"
export const PRIVY_TERMS_URL = "https://www.privy.io/user-terms-of-service"
export const SIGNUP_STEPS = 4
export const MFA_STEPS = {
  phone: 4,
  totp: 3,
}

export const EMAIL_AUTH_CODE_EXPIRATION: Expiration = {
  minutes: 10,
  seconds: 0,
}

export const PHONE_AUTH_CODE_EXPIRATION: Expiration = {
  minutes: 10,
  seconds: 0,
}
