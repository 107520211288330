import { noop } from "lodash"
import {
  Middleware,
  MiddlewareNextFn,
  RelayNetworkLayerResponse,
  RelayRequestAny,
  RRNLError,
  RRNLRequestError,
} from "react-relay-network-modern"
import { isFetchError } from "../../../graphql/error"
import { wait } from "../../../helpers/promise"

type RetryOnStatusCodeFn = (
  status: number,
  req: RelayRequestAny,
  res: RelayNetworkLayerResponse,
) => boolean

type RetryMiddlewareOptions = {
  retryDelayBase?: number
  logger?: ((msg: string) => void) | false
  retryOnStatusCode?: RetryOnStatusCodeFn
  maxAttempts?: number
}

/**
 * Implementation based on https://github.com/relay-tools/react-relay-network-modern/blob/master/src/middlewares/retry.js
 * but modified to support our custom "x-ratelimit-retry-after" header
 *
 */
const retryMiddleware = ({
  retryDelayBase = 1500,
  logger,
  retryOnStatusCode = (status, _req, _res) => {
    // We used to retry on 5xx, but removing that logic for now.
    return status === 429
  },
  maxAttempts = 5,
}: RetryMiddlewareOptions = {}): Middleware => {
  return next => req => {
    return makeRetriableRequest({
      req,
      next,
      retryDelayBase,
      retryOnStatusCode,
      maxAttempts,
      logger:
        logger === false
          ? noop
          : logger || console.log.bind(console, "[RELAY-NETWORK]"),
    })
  }
}

type MakeRetriableRequestParams = {
  req: RelayRequestAny
  next: MiddlewareNextFn
  retryDelayBase: number
  logger: (msg: string) => void
  retryOnStatusCode: RetryOnStatusCodeFn
  maxAttempts: number
}

class RRNLRetryMiddlewareError extends RRNLError {
  constructor(msg: string) {
    super(msg)
    this.name = "RRNLRetryMiddlewareError"
  }
}

const makeRetriableRequest = async (
  o: MakeRetriableRequestParams,
  attempt = 1,
  delay = 0,
) => {
  const makeRetry = async (
    delay: number,
    prevError: Error,
    originalError: Error,
    options: { attemptOnce?: boolean } = {},
  ) => {
    const newAttempt = attempt + 1
    if (newAttempt <= o.maxAttempts) {
      o.logger(
        `${
          prevError.message
        } will retry ${o.req.getID()} in ${delay}ms; attempt ${newAttempt}`,
      )
      return makeRetriableRequest(
        o,
        options.attemptOnce ? o.maxAttempts : newAttempt,
        delay,
      )
    }
    throw originalError
  }

  const makeRequest = async (): Promise<RelayNetworkLayerResponse> => {
    try {
      return await o.next(o.req)
    } catch (e) {
      const requestError: RRNLRequestError | TypeError | undefined = e

      if (requestError && isFetchError(requestError)) {
        return makeRetry(0, requestError, e, { attemptOnce: true })
      }

      if (requestError instanceof TypeError) {
        throw e
      }

      // no response from server (no internet connection), make new attempt
      if (
        requestError &&
        !requestError.res &&
        !(requestError instanceof RRNLRetryMiddlewareError) &&
        requestError.name !== "AbortError"
      ) {
        // linear backoff 1500ms -> 3000ms -> ...
        return makeRetry(o.retryDelayBase * attempt, requestError, e)
      }

      // response with invalid statusCode
      if (
        requestError &&
        requestError.res &&
        o.retryOnStatusCode(requestError.res.status, o.req, requestError.res)
      ) {
        const err = new RRNLRetryMiddlewareError(
          `Wrong response status ${requestError.res.status}, retrying...`,
        )

        let timeout = o.retryDelayBase
        if (requestError.res.status === 429) {
          const retryAfter =
            requestError.res.headers?.["x-ratelimit-retry-after"]

          if (retryAfter) {
            timeout = Math.ceil(Number.parseInt(retryAfter, 10) / 1000)
          }
        } else {
          // linear backoff 1000ms -> 2000ms -> ...
          timeout *= attempt
        }
        return makeRetry(timeout, err, e)
      }
      throw e
    }
  }

  if (delay) {
    await wait(delay)
  }
  return makeRequest()
}

export default retryMiddleware()
