import React from "react"
import { useIsLessThanSm } from "@opensea/ui-kit"
import { BottomSheet } from "@/design-system/BottomSheet"
import { MultiStepModal } from "@/design-system/Modal"
import { useGlobalModal } from "@/hooks/useGlobalModal"

export const GlobalModal = () => {
  const { modalProps, closeModal, isOpen } = useGlobalModal()
  const isSmallScreen = useIsLessThanSm()

  return modalProps?.responsive && isSmallScreen ? (
    <BottomSheet closable={false} isOpen={isOpen} onClose={closeModal}>
      {typeof modalProps.children === "function"
        ? modalProps.children(closeModal)
        : modalProps.children}
    </BottomSheet>
  ) : (
    <MultiStepModal isOpen={isOpen} onClose={closeModal} {...modalProps} />
  )
}
