/**
 * @generated SignedSource<<b93b7901ba60479fb511f4fd845e5cb5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StackedAssetMedia_assets$data = ReadonlyArray<{
  readonly collection: {
    readonly logo: string | null;
  };
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"AssetMedia_asset">;
  readonly " $fragmentType": "StackedAssetMedia_assets";
}>;
export type StackedAssetMedia_assets$key = ReadonlyArray<{
  readonly " $data"?: StackedAssetMedia_assets$data;
  readonly " $fragmentSpreads": FragmentRefs<"StackedAssetMedia_assets">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "StackedAssetMedia_assets",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssetMedia_asset"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionType",
      "kind": "LinkedField",
      "name": "collection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "logo",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "ee60afbf1ed5af7aabf09841810f7415";

export default node;
