import React from "react"

type Props = {
  className?: string
  width?: number
  fill?: string
}

export function UnichainLogo({
  className,
  fill = "lightgrey",
  width = 24,
}: Props) {
  return (
    <svg
      style={{ width, height: width }}
      viewBox="0 0 257 256"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill}
    >
      <rect transform="translate(0.5)" fill={fill} fillRule="evenodd" />
      <path
        d="M234.684 125.98C177.129 125.98 130.52 79.3245 130.52 21.8159H126.48V125.98H22.3159V130.02C79.8714 130.02 126.48 176.675 126.48 234.184H130.52V130.02H234.684V125.98Z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  )
}
