import {
  addMonths,
  differenceInMinutes,
  isAfter,
  isBefore,
  subMinutes,
} from "date-fns"
import {
  MAXIMUM_DURATION_IN_MONTHS,
  MINIMUM_LISTING_DURATION_IN_MINUTES,
  TIME_WINDOW_IN_MINUTES,
} from "../../../../constants"

const AlmostNow = subMinutes(new Date(), TIME_WINDOW_IN_MINUTES)

export const getDefaultExpirationDate = () => addMonths(new Date(), 1)

export const validateStartTime = (startTime: Date) =>
  !isBefore(startTime, AlmostNow)

export const validateEndTime = (startTime: Date, endTime: Date) =>
  differenceInMinutes(endTime, startTime) >=
    MINIMUM_LISTING_DURATION_IN_MINUTES &&
  !isAfter(endTime, addMonths(startTime, MAXIMUM_DURATION_IN_MONTHS))
