import React, {
  useMemo,
  useCallback,
  ComponentProps,
  ReactNode,
  useState,
  useRef,
} from "react"
import { Media, breakpoints } from "@opensea/ui-kit"
import { useIsomorphicLayoutEffect } from "react-use"
import styled, { css } from "styled-components"
import {
  Autoplay,
  FreeMode,
  Keyboard,
  Pagination,
  Navigation,
  Scrollbar,
  Mousewheel,
  Thumbs,
  SwiperOptions,
} from "swiper"
import type _SwiperClass from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { Block } from "@/design-system/Block"
import { useIsMounted } from "@/hooks/useIsMounted"
import { media, themeVariant } from "@/styles/styleUtils"
import "swiper/css/bundle"
import THEMES from "@/styles/themes"
import { ArrowButton } from "./ArrowButton.react"
import { HideCarouselSlidesBehindBlock } from "./HideCarouselSlidesBehindBlock"

// Swiper gets the types of it's modules wrong, they should all be optional
type SwiperModules =
  | "a11y"
  | "autoplay"
  | "controller"
  | "coverflowEffect"
  | "cubeEffect"
  | "fadeEffect"
  | "flipEffect"
  | "creativeEffect"
  | "cardsEffect"
  | "hashNavigation"
  | "history"
  | "keyboard"
  | "mousewheel"
  | "navigation"
  | "pagination"
  | "parallax"
  | "scrollbar"
  | "thumbs"
  | "virtual"
  | "zoom"
  | "freeMode"

type SwiperClass = Omit<_SwiperClass, SwiperModules> &
  Partial<Pick<_SwiperClass, SwiperModules>>

export type BaseSlide = {
  id: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

type ResponsiveBreakpointConfig = {
  slidesPerView: number | "auto"
  spaceBetween: number
  slidesPerGroup?: number
}

export type ResponsiveConfig = {
  default: ResponsiveBreakpointConfig
  xs?: ResponsiveBreakpointConfig
  sm?: ResponsiveBreakpointConfig
  md?: ResponsiveBreakpointConfig
  lg?: ResponsiveBreakpointConfig
  xl?: ResponsiveBreakpointConfig
  xxl?: ResponsiveBreakpointConfig
  xxxl?: ResponsiveBreakpointConfig
}

export type AutoplayConfig = {
  delay?: number
  disableOnInteraction?: boolean
  pauseOnMouseEnter?: boolean
}

type SwiperWithThumbnailNavigation<T> =
  | {
      /**
       * Optional configuration to quickly set up thumbnail pagination, defaults to false
       */
      enableThumbPagination: boolean
      renderThumb: (slide: T, isActive: boolean) => ReactNode
      thumbnailNavigationConfigOverride?: ComponentProps<typeof Swiper>
    }
  | {
      enableThumbPagination?: false | undefined
      renderThumb?: never
      thumbnailNavigationConfigOverride?: never
    }

type Interpolation = ReturnType<typeof css>

/**
 * If "card" variant used, use "overflow: hidden" on the outer container
 */
export type ExperimentalCarouselVariantType =
  | "default"
  | "card"
  | "drop-carousel"
  | "spotlight"
  | "item-card"

export type Props<T extends BaseSlide> = {
  /**
   * Optional styles override
   */
  overrides?: {
    /**
     * Optional css overrides for carousel container
     */
    containerStyles?: Interpolation
  }
  /**
   * Optional configuration to configure usage of arrows, defaults to 1 slide at a time
   */
  enableArrowControls?: boolean
  /**
   * Optional config to enable autoplay
   */
  autoplayConfig?: AutoplayConfig
  className?: string
  enableCssMode?: boolean
  /**
   * Optional configuration to quickly set up dot pagination, defaults to false
   */
  enableDotPagination?: boolean
  // Render the dots below swiper slides instead of above
  dotPaginationBelowSlides?: boolean
  enableMousewheel?: boolean
  /**
   * Optional flag to enable freely scrolling using mousewheel, defaults to true
   */
  enableFreeScroll?: boolean
  /**
   * optional configuration for sm/md screen sizes, overrides default slidesPerView/spaceBetween if present
   */
  loop?: boolean
  loopedSlides?: number
  speed?: number
  responsiveConfig?: ResponsiveConfig
  showScrollbar?: boolean
  slides: Array<T>
  // Optional thumbnail navigation overrides. Will default to `slides` if not provided
  thumbs?: Array<T>
  slidesPerView?: number | "auto"
  spaceBetween?: number
  watchSlidesProgress?: boolean
  centeredSlides?: boolean
  centeredSlidesBounds?: boolean
  slidesOffsetAfter?: number
  slidesOffsetBefore?: number
  renderSlide: (slide: T) => ReactNode
  handleActiveIndexChange?: (index: number) => void
  carouselConfigOverride?: ComponentProps<typeof Swiper>
  // Unique id that identifies the carousel on the page
  id: string
  /**
   * If "card" variant used, use "overflow: hidden" on the outer container
   */
  variant?: ExperimentalCarouselVariantType | undefined
  /**
   * Optional configuration for setting the height of the arrow buttons as the slide item width.
   * This is can be used in the rare case the carousel height is larger than the slide height due
   * to the auto-sizing of the Carousel library. Instead of the full-height arrow buttons taking
   * the carousel container height that is larger than the slide, it can take something closer to the
   * height of the slide/image. This currently is only used in the BulkTransferPage.
   */
  slideWidthAsArrowButtonHeight?: boolean
  /**
   * optional configuration for setting the height of the arrow buttons as the slide item height
   */
  slideHeightAsArrowButtonHeight?: boolean
  autoHeight?: SwiperOptions["autoHeight"]
  onInit?: (swiper: SwiperClass) => void
} & SwiperWithThumbnailNavigation<T>

const NAV_LEFT_CLASS = "swiper-nav-left"
const NAV_RIGHT_CLASS = "swiper-nav-right"
const DOTS_CLASS = "pagination-dots"

const mousewheelConfig = { forceToAxis: true }

export const Carousel = <T extends BaseSlide>({
  autoHeight = true,
  overrides,
  enableArrowControls = true,
  autoplayConfig,
  carouselConfigOverride,
  centeredSlides,
  centeredSlidesBounds,
  className,
  enableMousewheel = true,
  enableCssMode = false,
  enableDotPagination = false,
  dotPaginationBelowSlides = false,
  enableFreeScroll = true,
  enableThumbPagination = false,
  id,
  handleActiveIndexChange,
  loop = false,
  loopedSlides,
  slidesOffsetAfter,
  slidesOffsetBefore,
  speed,
  responsiveConfig,
  showScrollbar = true,
  slideHeightAsArrowButtonHeight,
  slideWidthAsArrowButtonHeight,
  slides,
  slidesPerView = 1,
  spaceBetween = 16,
  thumbnailNavigationConfigOverride,
  thumbs,
  renderSlide,
  renderThumb,
  watchSlidesProgress = false,
  variant = "default",
  onInit,
}: Props<T>) => {
  const { containerStyles } = overrides || {}
  const [modules] = useState(() => [
    Keyboard,
    ...(enableMousewheel ? [Mousewheel, Scrollbar] : []),
    ...(enableFreeScroll ? [FreeMode] : []),
    ...(autoplayConfig ? [Autoplay] : []),
    ...(enableDotPagination ? [Pagination] : []),
    ...(enableThumbPagination ? [Thumbs] : []),
    ...(enableArrowControls || enableThumbPagination ? [Navigation] : []),
  ])

  const [slideWidth, setSlideWidth] = useState<number | undefined>()
  const [slideHeight, setSlideHeight] = useState<number | undefined>()

  const [_autoplayConfig] = useState<ComponentProps<typeof Swiper>["autoplay"]>(
    autoplayConfig
      ? {
          delay: 3000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
          ...autoplayConfig,
        }
      : undefined,
  )

  const [_responsiveConfig] = useState(
    responsiveConfig
      ? {
          [breakpoints.xs]: responsiveConfig.xs ?? responsiveConfig.default,
          [breakpoints.sm]: responsiveConfig.sm ?? responsiveConfig.default,
          [breakpoints.md]: responsiveConfig.md ?? responsiveConfig.default,
          [breakpoints.lg]: responsiveConfig.lg ?? responsiveConfig.default,
          [breakpoints.xl]: responsiveConfig.xl ?? responsiveConfig.default,
          [breakpoints.xxl]: responsiveConfig.xxl ?? responsiveConfig.default,
          [breakpoints.xxxl]: responsiveConfig.xxxl ?? responsiveConfig.default,
        }
      : undefined,
  )

  const [paginationConfig] = useState<
    ComponentProps<typeof Swiper>["pagination"]
  >(
    enableDotPagination
      ? {
          clickable: true,
          el: dotPaginationBelowSlides ? `.${DOTS_CLASS}` : undefined,
        }
      : undefined,
  )

  const [thumbsSwiper, setThumbsSwiper] = useState<_SwiperClass | null>(null)

  const thumbConfig = useMemo(
    () => ({ swiper: thumbsSwiper, autoScrollOffset: 1 }),
    [thumbsSwiper],
  )
  const thumbnails = thumbs ?? slides

  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  const renderedSlides = useMemo(() => {
    return slides.map(slide => (
      <SwiperSlide key={slide.id}>{renderSlide(slide)}</SwiperSlide>
    ))
  }, [slides, renderSlide])

  const onActiveIndexChange = useCallback(
    ({ activeIndex, realIndex }: SwiperClass) => {
      setActiveSlideIndex(activeIndex)
      if (handleActiveIndexChange) {
        handleActiveIndexChange(realIndex)
      }
    },
    [setActiveSlideIndex, handleActiveIndexChange],
  )

  const navLeftClass = `${NAV_LEFT_CLASS}${id}`
  const navRightClass = `${NAV_RIGHT_CLASS}${id}`

  const navigationConfig = {
    // Selectors must start with a letter
    prevEl: `.${navLeftClass}`,
    nextEl: `.${navRightClass}`,
  }
  const isMounted = useIsMounted()

  const swiperRef = useRef<SwiperClass | null>(null)
  useIsomorphicLayoutEffect(() => {
    if (swiperRef.current) {
      // On the first render, swiper can't update the class names of the arrows
      // because they don't exist yet. There's probably a cleaner way here,
      // involving useSwiper and swiper's Navigation component.
      swiperRef.current.navigation?.update()
      setSlideWidth(swiperRef.current.slides[0]?.clientWidth)
      setSlideHeight(swiperRef.current.slides[0]?.clientHeight)
    }
  })

  //  Optional configuration for slides that require it to be 'overflow: visible; and the item bleed over the container.
  //  It is best to ensure the Container component has 'overflow: hidden', where ever this ExperimentalCarousel is a child of.
  const allowVisibleOverflowBleed = variant === "card"

  // arrowButtonHeight is a programmatic calculation of the slide using the
  // component ref. You can use the related props "slideHeightAsArrowButtonHeight" +
  // "slideWidthAsArrowButtonHeight" to set the ArrowButton height accordingly.
  let arrowButtonHeight: number | undefined

  if (slideHeightAsArrowButtonHeight && slideWidthAsArrowButtonHeight) {
    arrowButtonHeight = slideHeight
  }

  if (slideHeightAsArrowButtonHeight || slideWidthAsArrowButtonHeight) {
    arrowButtonHeight = slideHeightAsArrowButtonHeight
      ? slideHeight
      : slideWidth
  }

  return (
    <>
      <StyledContainer
        $containerStyles={containerStyles}
        $variant={variant}
        className="relative"
      >
        {allowVisibleOverflowBleed && (
          <Media greaterThanOrEqual="sm">
            {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
            <HideCarouselSlidesBehindBlock className="left" />
          </Media>
        )}
        {enableArrowControls && (
          <ArrowButton
            carouselVariant={variant}
            className={`${NAV_LEFT_CLASS} ${navLeftClass}`}
            direction="back"
            height={arrowButtonHeight}
          />
        )}
        <Swiper
          autoHeight={autoHeight}
          autoplay={_autoplayConfig}
          breakpoints={_responsiveConfig}
          centeredSlides={centeredSlides}
          centeredSlidesBounds={centeredSlidesBounds}
          className={className}
          cssMode={enableCssMode}
          direction="horizontal"
          keyboard
          loop={loop}
          loopedSlides={loopedSlides}
          modules={modules}
          mousewheel={
            enableMousewheel
              ? { ...mousewheelConfig, thresholdDelta: 25 }
              : undefined
          }
          navigation={enableArrowControls ? navigationConfig : undefined}
          observeParents
          observeSlideChildren
          observer
          pagination={paginationConfig}
          preventInteractionOnTransition
          resizeObserver
          scrollbar={showScrollbar}
          slidesOffsetAfter={slidesOffsetAfter}
          slidesOffsetBefore={slidesOffsetBefore}
          slidesPerView={slidesPerView}
          spaceBetween={spaceBetween}
          speed={speed}
          style={{
            overflow: allowVisibleOverflowBleed ? "visible" : "hidden",
          }}
          threshold={25}
          thumbs={enableThumbPagination ? thumbConfig : undefined}
          watchSlidesProgress={watchSlidesProgress}
          onActiveIndexChange={onActiveIndexChange}
          onInit={swiper => {
            swiperRef.current = swiper as SwiperClass
            onInit?.(swiper)
          }}
          {...responsiveConfig?.default}
          {...carouselConfigOverride}
        >
          {renderedSlides}
        </Swiper>
        {enableArrowControls && (
          <ArrowButton
            carouselVariant={variant}
            className={`${NAV_RIGHT_CLASS} ${navRightClass}`}
            direction="forward"
            height={arrowButtonHeight}
          />
        )}
        {allowVisibleOverflowBleed && (
          <Media greaterThanOrEqual="sm">
            {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
            <HideCarouselSlidesBehindBlock className="right" />
          </Media>
        )}
      </StyledContainer>
      {dotPaginationBelowSlides && (
        <PaginationContainer
          $containerStyles={containerStyles}
          className={DOTS_CLASS}
        ></PaginationContainer>
      )}
      {enableThumbPagination && isMounted && (
        <StyledContainer $containerStyles={containerStyles} marginTop="16px">
          <Swiper
            breakpoints={{
              [breakpoints.xs]: {
                spaceBetween: 8,
              },
              [breakpoints.lg]: {
                spaceBetween: 12,
              },
            }}
            modules={modules}
            slidesPerView={4}
            threshold={100}
            watchSlidesProgress
            onSwiper={setThumbsSwiper}
            {...thumbnailNavigationConfigOverride}
          >
            {thumbnails.map((slide, thumbIndex) => (
              <SwiperSlide key={`thumb-${slide.id}`}>
                {renderThumb
                  ? renderThumb(slide, thumbIndex === activeSlideIndex)
                  : renderSlide(slide)}
              </SwiperSlide>
            ))}
          </Swiper>
        </StyledContainer>
      )}
    </>
  )
}

const StyledContainer = styled(Block)<{
  $containerStyles?: Interpolation
  $variant?: ExperimentalCarouselVariantType
}>`
  ${props =>
    props.$variant === "card"
      ? css`
          margin: 0 0 24px 0;
        `
      : null}

  ${props => props.$containerStyles}

  ${media({
    lg: css`
      & > .${NAV_LEFT_CLASS}, & > .${NAV_RIGHT_CLASS} {
        opacity: 0;
        transition: opacity 0.2s linear;
      }

      &:hover {
        .${NAV_LEFT_CLASS}, .${NAV_RIGHT_CLASS} {
          &:not(:disabled) {
            transition: opacity 0.2s linear;
            opacity: 1;
          }
        }
      }
    `,
  })}
`

const PaginationContainer = styled(StyledContainer)`
  &&& {
    display: flex;
    justify-content: center;
    position: relative;
    --swiper-pagination-bottom: 0;
    height: 8px;
    margin-top: 12px;
    --swiper-pagination-bullet-inactive-color: ${({ theme }) =>
      theme.colors.components.background.gray2};
    --swiper-pagination-bullet-inactive-opacity: 1;
    ${themeVariant({
      variants: {
        light: {
          ["--swiper-pagination-color"]:
            THEMES.dark.colors.components.elevation.level1.subtle.background,
        },
        dark: {
          ["--swiper-pagination-color"]:
            THEMES.light.colors.components.elevation.level1.subtle.background,
        },
      },
    })};

    .swiper-pagination-bullet {
      background: ${props => props.theme.colors.icons.primary};
    }

    .swiper-pagination-bullet-active {
      background: ${props => props.theme.colors.primary};
    }
  }
`

export const CAROUSEL_RESPONSIVE_CONFIG_DEFAULT: ResponsiveConfig = {
  default: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 8,
  },
  sm: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 8,
  },
  md: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 16,
  },
  lg: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 16,
  },
  xl: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 16,
  },
  xxl: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 16,
  },
}

export const CAROUSEL_RESPONSIVE_CONFIG_SIX_CARDS: ResponsiveConfig = {
  default: {
    slidesPerView: 1.6,
    slidesPerGroup: 1,
    spaceBetween: 8,
  },
  sm: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 8,
  },
  md: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 16,
  },
  lg: {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 16,
  },
  xl: {
    slidesPerView: 5,
    slidesPerGroup: 5,
    spaceBetween: 16,
  },
  xxl: {
    slidesPerView: 6,
    slidesPerGroup: 6,
    spaceBetween: 16,
  },
  xxxl: {
    slidesPerView: 6,
    slidesPerGroup: 6,
    spaceBetween: 16,
  },
}
