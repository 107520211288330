import { graphql } from "relay-runtime"
import {
  accountRecentViews_data$data,
  Config,
} from "@/lib/graphql/__generated__/accountRecentViews_data.graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { getAccountUrl } from "@/lib/helpers/accounts"
import { createRecentViewsEntity } from "./createRecentViewsEntity"

type AccountRecentView = {
  address: string
  config: Config | null
  imageUrl: string
  relayId: string
  isCompromised: boolean
  user: { publicUsername: string | null } | null
  metadata: { isBanned: boolean; isDisabled: boolean } | null
  accountUrl: string
}

export const readAccountRecentViews = inlineFragmentize<
  accountRecentViews_data$data,
  AccountRecentView
>(
  graphql`
    fragment accountRecentViews_data on AccountType @inline {
      address
      config
      imageUrl
      relayId
      isCompromised
      user {
        publicUsername
      }
      metadata {
        isBanned
        isDisabled
      }
      ...accounts_url
    }
  `,
  data => ({
    address: data.address,
    config: data.config,
    imageUrl: data.imageUrl,
    relayId: data.relayId,
    isCompromised: data.isCompromised,
    user: data.user ? { publicUsername: data.user.publicUsername } : null,
    metadata: data.metadata
      ? {
          isBanned: data.metadata.isBanned,
          isDisabled: data.metadata.isDisabled,
        }
      : null,
    accountUrl: getAccountUrl(data),
  }),
)

export const {
  useEntityRecentViews: useAccountRecentViews,
  EntityRecentViewsProvider: AccountRecentViewsProvider,
} = createRecentViewsEntity<AccountRecentView>({
  storageId: "accountRecentViews",
  itemIdKey: "relayId",
})
