import React, { Suspense, useMemo, useState } from "react"
import BigNumber from "bignumber.js"
import { noop } from "lodash"
import { ModalLoader } from "@/components/common/ModalLoader.react"
import { useChainCurrencies } from "@/components/nav/WalletPopover/components/BridgeOrWrapForm/hooks/useChainCurrencies"
import { SwapModalContent } from "@/components/nav/WalletSidebar/SwapModalContent.react"
import { useNativeCurrencyBalance } from "@/containers/WalletBalanceProvider/NativeCurrencyBalanceProvider.react"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { Modal } from "@/design-system/Modal"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { MoonPayTopupModal, useIsMoonPayAllowed } from "@/features/moonpay"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useTranslate } from "@/hooks/useTranslate"
import { getPolygonChain } from "@/lib/helpers/chainUtils"
import { UnreachableCaseError } from "@/lib/helpers/type"
import { AddFundsMenu, AddFundsMenuTab } from "./components/AddFundsMenu.react"
import { DepositModal } from "./components/DepositModal.react"

type AddFundsModalContentCommonProps = {
  onFundsAdded?: () => unknown
  requiredAssetAmount?: BigNumber
  fundsToAdd?: BigNumber
}

type AddFundsModalContentWithoutPaymentAssetProps =
  AddFundsModalContentCommonProps & {
    symbol?: undefined
    chain?: undefined
  }

type AddFundsModalContentWithPaymentAssetProps =
  AddFundsModalContentCommonProps & {
    symbol: string
    chain: ChainIdentifier
  }

export type AddFundsModalContentBaseProps =
  | AddFundsModalContentWithoutPaymentAssetProps
  | AddFundsModalContentWithPaymentAssetProps

const AddFundsModalContentBase = ({
  fundsToAdd,
  onFundsAdded,
  requiredAssetAmount,
  symbol,
  chain,
}: AddFundsModalContentBaseProps) => {
  const t = useTranslate("components")
  const { onPrevious } = useMultiStepFlowContext()
  const { wallet } = useWallet()
  const address = wallet.getActiveAccountKeyStrict().address

  const { isTopupAllowed, isLoading } = useIsMoonPayAllowed()
  const { baseCurrency, wrappedCurrency } = useChainCurrencies(chain)
  const showWrapOption = chain && symbol === wrappedCurrency.symbol

  const { nativeCurrencyBalance } = useNativeCurrencyBalance()
  const hasSufficientBalanceForWrap =
    !requiredAssetAmount ||
    nativeCurrencyBalance?.isGreaterThan(requiredAssetAmount)
  const defaultToWrapOption =
    showWrapOption && chain !== getPolygonChain() && hasSufficientBalanceForWrap

  const [activeTab, setActiveTab] = useState<AddFundsMenuTab>(
    defaultToWrapOption ? "wrap" : isTopupAllowed ? "card" : "deposit",
  )

  const renderTabMenu = useMemo(
    () =>
      function RenderTabMenu() {
        return (
          <AddFundsMenu
            activeTab={activeTab}
            chain={chain}
            symbol={symbol}
            onSelectTab={setActiveTab}
          />
        )
      },
    [activeTab, chain, symbol],
  )

  if (isLoading) {
    return <ModalLoader />
  }

  switch (activeTab) {
    case "deposit":
      return (
        <DepositModal
          address={address}
          renderTabMenu={renderTabMenu}
          symbol={symbol}
        />
      )

    case "card":
      return (
        <MoonPayTopupModal
          chain={chain}
          fiatValue={fundsToAdd}
          renderTabMenu={renderTabMenu}
          symbol={symbol}
          onDone={onFundsAdded}
        />
      )

    case "wrap":
      return (
        <>
          <Modal.Header onPrevious={onPrevious}>
            <Modal.Header.Title className="text-center">
              {t("addFunds.title.convert", "Convert tokens")}
            </Modal.Header.Title>
          </Modal.Header>
          {renderTabMenu()}
          <SwapModalContent
            fixedToCurrency
            from={{
              symbol: baseCurrency.symbol,
              chain: {
                identifier: baseCurrency.chain,
              },
            }}
            initialQuantity={requiredAssetAmount?.toString()}
            to={{
              symbol: wrappedCurrency.symbol,
              chain: {
                identifier: wrappedCurrency.chain,
              },
            }}
            onSuccess={onFundsAdded ?? noop}
          />
        </>
      )

    default:
      throw new UnreachableCaseError(activeTab)
  }
}

export type AddFundsModalContentProps =
  | AddFundsModalContentWithoutPaymentAssetProps
  | AddFundsModalContentWithPaymentAssetProps

export const AddFundsModalContent = (props: AddFundsModalContentProps) => {
  return (
    <Suspense fallback={<ModalLoader />}>
      <AddFundsModalContentBase {...props} />
    </Suspense>
  )
}
