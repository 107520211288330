import React from "react"
import { Icon } from "@opensea/ui-kit"
import { Block } from "@/design-system/Block"
import { trackCopyAddress } from "@/lib/analytics/events/appEvents"
import { truncateAddress } from "@/lib/helpers/address"
import { TextCopierButton } from "./TextCopierButton.react"

type Props = {
  address: string
  className?: string
  showIcon?: boolean
}

export const CopyAddress = ({ address, className, showIcon }: Props) => (
  <TextCopierButton
    className={className}
    text={address}
    onCopy={trackCopyAddress}
  >
    <Block>{truncateAddress(address)}</Block>
    {showIcon && <Icon className="ml-2" size={16} value="content_copy" />}
  </TextCopierButton>
)
