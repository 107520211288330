export const ANNOUNCEMENT_BANNER_LOCAL_STORAGE_KEY = "announcementBanner"

const ONE_WEEK_MILLIS = 1000 * 60 * 60 * 24 * 7

const getAnnouncementBannerData = () => {
  const data: Record<string, number> = JSON.parse(
    localStorage.getItem(ANNOUNCEMENT_BANNER_LOCAL_STORAGE_KEY) ?? "{}",
  )

  return data
}

export const hideAnnouncementPersist = (relayId: string) => {
  const now = Date.now()
  const data = getAnnouncementBannerData()

  const updatedData = Object.keys(data).reduce(
    (acc, key) => {
      const hiddenAt = data[key]
      // Cleanup storage keys that are older than 1 week so it is not an ever growing list
      if (now - hiddenAt < ONE_WEEK_MILLIS) {
        return { ...acc, [key]: hiddenAt }
      }
      return acc
    },
    { [relayId]: now },
  )
  localStorage.setItem(
    ANNOUNCEMENT_BANNER_LOCAL_STORAGE_KEY,
    JSON.stringify(updatedData),
  )
}

export const isAnnouncementBannerHidden = (relayId: string) => {
  const data = getAnnouncementBannerData()

  return Boolean(data[relayId])
}
