import React, { Suspense, SuspenseProps } from "react"
import { useIsHydrated } from "@opensea/ui-kit"

type SSRSuspenseProps = SuspenseProps & {
  hasSsrData?: boolean
}

export const SsrSuspense = ({
  hasSsrData = false,
  ...suspenseProps
}: SSRSuspenseProps) => {
  const isHydrated = useIsHydrated()

  if (!isHydrated) {
    return hasSsrData ? suspenseProps.children : suspenseProps.fallback
  }

  return <Suspense {...suspenseProps} />
}
