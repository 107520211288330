import { omitBy, isNil } from "lodash"
import { IS_SERVER } from "@/constants/environment"
import { getActiveChain } from "@/containers/WalletProvider/activeChain"
import {
  getWallet,
  isWalletInitialized,
} from "@/containers/WalletProvider/wallet"
import { getChains } from "@/hooks/useChains/context"
import { createChainByIdentifierLookup } from "@/hooks/useChains/utils"
import * as I18nConfig from "@/i18n"
import chain from "@/lib/chain/chain"
import Web3EvmProvider from "@/lib/chain/providers/web3EvmProvider"
import { getDeviceType } from "@/lib/helpers/layout"
import { getThemeFromCookie } from "@/styles/themes"
import { getDefaultTrackingContext } from "../TrackingContext/utils"
import type { EventProperties } from "../types"
import { trackAmplitudeEvent } from "./amplitude"

const getWalletTrackingParameters = async () => {
  if (!isWalletInitialized()) {
    return {}
  }
  const wallet = getWallet()
  const accountKey = wallet.getActiveAccountKey()
  const provider = await wallet.getProvider()
  const activeChain = getActiveChain()
  const chainId = await provider?.getChainId()

  const web3Info = {
    web3Present: chain.providers.some(
      provider => provider instanceof Web3EvmProvider,
    ),
    web3Unlocked: !!wallet.activeAccount,
    web3Network: activeChain
      ? createChainByIdentifierLookup(getChains())[activeChain].networkId
      : undefined,
    web3Wallet: provider?.getName(),
    web3WalletMetadata: provider?.getMetadata(),
    // TODO: Change this balance to actually fetch or get from logged in cookie?
    walletBalance: undefined,
  }
  const walletInfo = {
    accountPresent: !!accountKey,
    chain: activeChain,
    chainId,
    providerName: provider?.getName(),
    providerMetadata: provider?.getMetadata(),
    providerPresent: !!provider,
    isBanned: wallet.activeAccount?.metadata?.isBanned,
    isDisabled: wallet.activeAccount?.metadata?.isDisabled,
    isEmployee: wallet.activeAccount?.isEmployee,
  }

  return { ...web3Info, ...walletInfo }
}

const legacyGetCommonTrackingParameters = async () => {
  const walletInfo = await getWalletTrackingParameters()
  const lang =
    I18nConfig.locales?.find(lo =>
      window.location.pathname.startsWith(`/${lo}`),
    ) || I18nConfig.defaultLocale

  const pageInfo = {
    path: window.location.pathname,
    queryString: window.location.search,
    title: document.title,
    url: window.location.href,
  }

  const userAgent = window.navigator.userAgent
  const clientInfo: Record<string, unknown> = {
    userAgent,
    cookiesEnabled: window.navigator.cookieEnabled,
    device: getDeviceType(userAgent),
    lang,
    screenSize: {
      width: window.screen.width,
      height: window.screen.height,
    },
    windowSize: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
  }

  if ("connection" in window.navigator) {
    clientInfo["connectionSpeed"] = window.navigator.connection.effectiveType
  }

  const themeInfo = {
    theme: getThemeFromCookie(),
  }

  return {
    pageInfo: {
      ...walletInfo,
      ...themeInfo,
      ...pageInfo,
    },
    clientInfo,
  }
}

export const legacyTrackWithGlobalParams = async (
  eventName: string,
  eventProperties?: EventProperties,
) => {
  if (IS_SERVER) {
    return
  }

  const { pageInfo } = await legacyGetCommonTrackingParameters()
  const { Meta, Browser } = getDefaultTrackingContext()

  const eventPropertiesWithGlobalProperties = omitBy(
    {
      ...pageInfo,
      ...eventProperties,
      ...Meta,
      ...Browser,
    },
    isNil,
  )

  await trackAmplitudeEvent(eventName, eventPropertiesWithGlobalProperties)
}
