/* eslint-disable tailwindcss/no-custom-classname */
import React, { useRef, useState } from "react"
import { useFragment } from "react-relay"
import { AssetMediaAudio_asset$key } from "@/lib/graphql/__generated__/AssetMediaAudio_asset.graphql"
import { graphql } from "@/lib/graphql/graphql"
import {
  AssetMediaImage,
  AssetMediaImageBaseProps,
} from "./AssetMediaImage.react"
import { asBackgroundColor } from "./helpers"
import { PlayButton } from "./PlayButton.react"

type AssetMediaAudioProps = {
  asset: AssetMediaAudio_asset$key
  url: string
  imageUrl: string
  autoPlay?: boolean
  isMuted?: boolean
  showControls?: boolean
  useCustomPlayButton?: boolean
  imageConfig: AssetMediaImageBaseProps
}

export const AssetMediaAudio = ({
  asset: assetKey,
  autoPlay,
  imageConfig,
  imageUrl,
  isMuted,
  showControls,
  url,
  useCustomPlayButton,
}: AssetMediaAudioProps) => {
  const audioRef = useRef<HTMLAudioElement & HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const asset = useFragment(
    graphql`
      fragment AssetMediaAudio_asset on AssetType {
        backgroundColor
        ...AssetMediaImage_asset
      }
    `,
    assetKey,
  )

  const backgroundColor = asBackgroundColor(asset.backgroundColor)

  return (
    <div className="AssetMedia--animation">
      <div
        className="AssetMedia--playback-wrapper"
        style={{ backgroundColor }}
        onContextMenu={e => e.preventDefault()}
      >
        <AssetMediaImage {...imageConfig} asset={asset} url={imageUrl} />
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <audio
          autoPlay={autoPlay}
          className="AssetMedia--audio"
          controls={showControls}
          controlsList="nodownload"
          data-testid="AssetMedia--audio"
          loop
          muted={isMuted}
          preload="none"
          ref={audioRef}
          src={url}
        />
        <PlayButton
          isPlaying={isPlaying}
          playingRef={audioRef}
          setIsPlaying={setIsPlaying}
          useCustomPlayButton={useCustomPlayButton}
        />
      </div>
    </div>
  )
}
