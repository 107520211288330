import React, { useEffect, useState } from "react"
import localforage from "localforage"

const isLocalForageAvailable = (): boolean => {
  if (typeof window === "undefined") {
    return false
  }
  return (
    localforage.supports(localforage.INDEXEDDB) ||
    localforage.supports(localforage.WEBSQL) ||
    localforage.supports(localforage.LOCALSTORAGE)
  )
}

/**
 * useState hook where the state is persisted in localforage (IndexDB).
 *
 * Prefer over react-use/useLocalStorage when the data being persisted is large.
 */
export const useLocalForageState = <T>(
  key: string,
  initialValue: T,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [state, setState] = useState<T>(initialValue)
  const canUseLocalForage = isLocalForageAvailable()

  useEffect(() => {
    if (!canUseLocalForage) {
      return
    }

    const loadPersistedState = async () => {
      const persistedState = await localforage.getItem<T>(key)
      if (persistedState !== null) {
        setState(persistedState)
      }
    }
    loadPersistedState()
  }, [canUseLocalForage, key])

  useEffect(() => {
    if (!canUseLocalForage) {
      return
    }

    const persistState = async () => {
      await localforage.setItem(key, state)
    }
    persistState()
  }, [canUseLocalForage, key, state])

  return [state, setState]
}
