import React, { CSSProperties } from "react"
import { Icon, IconProps, UnstyledButton } from "@opensea/ui-kit"
import styled from "styled-components"
import { Tooltip, TooltipProps } from "@/design-system/Tooltip"
import { classNames } from "@/styles/styleUtils"

type InfoIconProps = {
  tooltipContent: TooltipProps["content"]
  overrides?: {
    Button?: {
      style?: CSSProperties
    }
    Icon?: Pick<IconProps, "size" | "className">
    Tooltip?: Pick<
      TooltipProps,
      "contentPadding" | "interactive" | "maxWidth" | "placement"
    >
  }
}

export const InfoIcon = ({ tooltipContent, overrides }: InfoIconProps) => {
  return (
    <Tooltip
      {...overrides?.Tooltip}
      content={tooltipContent}
      interactive={overrides?.Tooltip?.interactive ?? true}
      touch
    >
      <InfoIconButton {...overrides?.Button}>
        <Icon
          {...overrides?.Icon}
          className={classNames("text-secondary", overrides?.Icon?.className)}
          size={overrides?.Icon?.size ?? 20}
          value="info"
        />
      </InfoIconButton>
    </Tooltip>
  )
}

const InfoIconButton = styled(UnstyledButton)`
  i {
    transition: all 0.2s ease;
  }

  &:hover {
    i {
      color: ${props => props.theme.colors.text.primary};
    }
  }
`
