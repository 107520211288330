import React from "react"
import { isFunction } from "lodash"
import { Modal } from "./Modal.react"
import { MultiStepFlow, MultiStepFlowProps } from "./MultiStepFlow.react"
import { BaseModalProps, ModalProps } from "./types"

export type BaseMultiStepModalProps = BaseModalProps &
  Omit<MultiStepFlowProps, "children">

export type MultiStepModalProps = ModalProps & BaseMultiStepModalProps

export const MultiStepModal = ({
  children,
  onPrevious,
  ...rest
}: MultiStepModalProps) => {
  return (
    <Modal {...rest}>
      {close => (
        <MultiStepFlow onPrevious={onPrevious}>
          {isFunction(children) ? children(close) : children}
        </MultiStepFlow>
      )}
    </Modal>
  )
}
