import React from "react"
import type { SocialBarItem } from "@/components/layout/AccountOrCollectionPage/components/SocialBar"
import { removeLeadingAts } from "@/lib/helpers/stringUtils"
import { UnreachableCaseError } from "./type"

type SocialName = "github" | "instagram" | "linkedin" | "medium" | "twitter"

export const getSocialAccountUrl = (social: SocialName, username: string) => {
  switch (social) {
    case "github":
      return `https://github.com/${username}`
    case "instagram":
      return `https://instagram.com/${removeLeadingAts(username)}`
    case "linkedin":
      return `https://linkedin.com/in/${username}`
    case "medium":
      return `https://www.medium.com/${username}`
    case "twitter":
      return `https://twitter.com/${username}`
    default:
      throw new UnreachableCaseError(social)
  }
}

type ItemConfig = {
  getIcon: () => JSX.Element
  extraTooltipText: () => JSX.Element
}

type GetInstagramItemConfig = ItemConfig & {
  connectedInstagramUsername: string | undefined | null
  instagramUsername: string | undefined | null
}

export const getInstagramItem = ({
  connectedInstagramUsername,
  instagramUsername,
  getIcon,
  extraTooltipText,
}: GetInstagramItemConfig): SocialBarItem | null => {
  if (connectedInstagramUsername) {
    return {
      url: getSocialAccountUrl("instagram", connectedInstagramUsername),
      icon: getIcon(),
      tooltip: (
        <>
          <div>Instagram</div>
          {extraTooltipText()}
        </>
      ),
    }
  }

  if (instagramUsername) {
    return {
      url: getSocialAccountUrl("instagram", instagramUsername),
      icon: getIcon(),
      tooltip: "Instagram",
    }
  }

  return null
}

type GetTwitterItemConfig = ItemConfig & {
  connectedTwitterUsername: string | undefined | null
  twitterUsername: string | undefined | null
}

export const getTwitterItem = ({
  connectedTwitterUsername,
  twitterUsername,
  getIcon,
  extraTooltipText,
}: GetTwitterItemConfig): SocialBarItem | null => {
  if (connectedTwitterUsername) {
    return {
      url: getSocialAccountUrl("twitter", connectedTwitterUsername),
      icon: getIcon(),
      tooltip: (
        <>
          <div>Twitter</div>
          {extraTooltipText()}
        </>
      ),
    }
  }

  if (twitterUsername) {
    return {
      url: getSocialAccountUrl("twitter", twitterUsername),
      icon: getIcon(),
      tooltip: "Twitter",
    }
  }

  return null
}
