import React from "react"
import { Text } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { Block } from "@/design-system/Block"
import { AssetMediaEditions_asset$key } from "@/lib/graphql/__generated__/AssetMediaEditions_asset.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { bn, quantityDisplay } from "@/lib/helpers/numberUtils"

type AssetMediaEditionsProps = {
  asset: AssetMediaEditions_asset$key
}

export const AssetMediaEditions = ({
  asset: assetKey,
}: AssetMediaEditionsProps) => {
  const asset = useFragment(
    graphql`
      fragment AssetMediaEditions_asset on AssetType
      @argumentDefinitions(
        identity: { type: "IdentityInputType", defaultValue: {} }
        showQuantity: { type: "Boolean", defaultValue: false }
      ) {
        decimals
        ownedQuantity(identity: $identity) @include(if: $showQuantity)
      }
    `,
    assetKey,
  )

  const quantity = asset.ownedQuantity
  const quantityDisplayed = quantity
    ? bn(quantity, asset.decimals || 0)
    : undefined

  return quantityDisplayed && bn(quantityDisplayed).isGreaterThan(bn(1)) ? (
    <EditionsContainer alignItems="center">
      <Text className="block" size="tiny" weight="semibold">
        x{quantityDisplay(quantityDisplayed)}
      </Text>
    </EditionsContainer>
  ) : null
}

const EditionsContainer = styled(Block)`
  background-color: ${props =>
    props.theme.colors.components.elevation.level1.regular.background};
  border-radius: 8px;
  left: 10px;
  padding: 4px 8px;
  position: absolute;
  top: 10px;
  z-index: 1;
`
