import { isNil } from "lodash"
import type { NextPageContext } from "next"
import NextRouter from "next/router"
import { IS_SERVER } from "@/constants/environment"
import Auth from "@/lib/auth"
import { getIsAuthStateSimplificationEnabled } from "@/lib/auth/flags"
import type { Session } from "@/lib/auth/types"
import Wallet from "@/lib/chain/wallet"
import { store } from "@/lib/graphql/environment/middlewares/metadataMiddleware"
import type { RelayMetadata } from "@/lib/graphql/environment/types"
import type { GraphQLPageCachePolicy } from "@/lib/graphql/GraphQLPage.react"
import { pathnameRequiresAuth, pathnameRequiresWallet } from "@/lib/routes"
import { trackRedirectToWalletPage } from "./tracking"

const DEFAULT_MAX_AGE = 5
const DEFAULT_ANONYMOUS_MAX_AGE = 30

type CheckWalletResult = {
  isWalletRedirect: boolean
  isAuthenticated: boolean
  session: Session | undefined
}

type PageCachingHeaderProps = {
  context: NextPageContext
  queryName: string
  cachePolicy?: GraphQLPageCachePolicy
  metadata?: RelayMetadata
}

export async function checkWallet(
  wallet: Wallet,
  context?: NextPageContext,
): Promise<CheckWalletResult> {
  const pathname = context?.pathname ?? NextRouter.pathname

  let session
  let isAuthenticated
  if (getIsAuthStateSimplificationEnabled()) {
    session = undefined
    isAuthenticated = Auth.getIsAuthenticated(
      wallet.activeAccount?.address,
      context,
    )
  } else {
    session = Auth.getValidSession(wallet.activeAccount?.address, context)
    isAuthenticated = Boolean(session)
  }

  if (
    await checkRedirectToWalletPage(wallet, isAuthenticated, pathname, context)
  ) {
    return { isWalletRedirect: true, isAuthenticated, session }
  }

  return { isWalletRedirect: false, isAuthenticated, session }
}

/**
 * Check if redirect to wallet page is required. If so, do the redirect (server or client side).
 *
 * @param {Wallet} wallet
 * @param {boolean} isAuthenticated
 * @param {NextPageContext} context
 * @returns {boolean} if redirect happened
 */
export async function checkRedirectToWalletPage(
  wallet: Wallet,
  isAuthenticated: boolean,
  pathname: string,
  context: NextPageContext | undefined,
): Promise<boolean> {
  const requiresWallet = pathnameRequiresWallet(pathname)
  const requiresAuth = pathnameRequiresAuth(pathname)

  if ((requiresWallet || requiresAuth) && isNil(wallet.activeAccount)) {
    wallet.redirect(context)

    trackRedirectToWalletPage({
      pathname,
      isAuthenticated,
      requiresWallet,
      requiresAuth,
      activeAccount: wallet.activeAccount,
      nextRouterPathname: IS_SERVER ? undefined : NextRouter.pathname,
      nextRouterAsPath: IS_SERVER ? undefined : NextRouter.asPath,
      pageContextPathname: context?.pathname,
      pageContextAsPath: context?.asPath,
    })

    return true
  }

  // This is probably not ideal, as user might already have the wallet connected
  // but we don't know that on the server. Still it is the best we can do without leaking the
  // auth pages on SSR.
  if (IS_SERVER && requiresAuth && !isAuthenticated) {
    wallet.redirect(context)
    return true
  }

  return false
}

export const setStaticPageCachingHeader = (context: NextPageContext) => {
  context.res?.setHeader(
    "Cache-Control",
    `public, max-age=${DEFAULT_ANONYMOUS_MAX_AGE}, stale-while-revalidate=${
      DEFAULT_ANONYMOUS_MAX_AGE * 2
    }`,
  )
}

const formatHeaders = (arr: Array<string>) => arr.filter(e => !!e).join(", ")

export const setPageCachingHeader = ({
  context,
  queryName,
  cachePolicy,
  metadata,
}: PageCachingHeaderProps) => {
  const { res } = context

  // client side page navigation
  if (!res) {
    return
  }

  const isAnonymous = !metadata?.address && !metadata?.token

  const defaultMaxAge = isAnonymous
    ? DEFAULT_ANONYMOUS_MAX_AGE
    : DEFAULT_MAX_AGE

  const maxAge = cachePolicy?.maxAge ?? defaultMaxAge
  const revalidate = cachePolicy?.revalidate
    ? `stale-while-revalidate=${cachePolicy.revalidate}`
    : ""

  if (isAnonymous) {
    res.setHeader(
      "Cache-Control",
      formatHeaders(["public", `max-age=${maxAge}`, revalidate]),
    )
    return
  }

  const responseMetadata = store.get(queryName)
  if (responseMetadata) {
    const backendScope = responseMetadata.cacheControl.scope
    const frontendScope = cachePolicy?.scope?.toLowerCase()
    res.setHeader(
      "Cache-Control",
      formatHeaders([
        frontendScope ?? backendScope,
        `max-age=${maxAge}`,
        revalidate,
      ]),
    )
  }
}
