import { TypedDataUtils } from "eth-sig-util"
import { ethers } from "ethers"
import { noop } from "lodash"
import { Buffer } from "safe-buffer"
import { WALLET_NAME } from "@/constants/wallet"
import {
  Chain,
  ChainByNetworkId,
  ChainIdentifier,
} from "@/hooks/useChains/types"
import { createChainByNetworkIdLookup } from "@/hooks/useChains/utils"
import { ClientSignatureStandard } from "@/lib/graphql/__generated__/useHandleBlockchainActions_cancel_orders.graphql"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"
import type { WalletAccountKey, Address } from "../chain"
import Provider, {
  OnChainChangeHandler,
  Transaction,
  TransactionId,
  TransactionResponse,
} from "../provider"
import type { SignOptions } from "../wallet"

type Event = "accountsChanged" | "networkChanged"

type KlayProvider = {
  enable: () => Promise<Address[]>
  isKaikas?: boolean
  networkVersion: number
  off: <T>(event: Event, handler: (value: T) => unknown) => void
  on: <T>(event: Event, handler: (value: T) => unknown) => void
  send<T>(
    method: string,
    params: Array<unknown>,
  ): Promise<{ id: number; jsonrpc: string; result: T }>
}

type Klay = {
  currentProvider: KlayProvider
  getAccounts: () => Promise<Address[]>
  estimateGas: (transaction: {
    from?: Address
    to?: Address
    value?: BigNumber
    data?: string
  }) => Promise<number>
  sendTransaction: (transaction: {
    from?: Address
    to?: Address
    value?: BigNumber
    gasLimit?: number
    data?: string
  }) => Promise<{ transactionHash: TransactionId }>
  sign: (message: string, address: string) => Promise<string>
  getGasPrice: () => Promise<number>
  getBalance: (address: string) => Promise<number>
  getTransactionCount: (
    address: string,
    blockNumber: number | "earliest" | "latest" | "pending",
  ) => Promise<number>
}

type Caver = {
  klay: Klay
}

declare global {
  interface Window {
    caver?: Caver
  }
}

type Method =
  | "eth_getBlockByNumber"
  | "eth_requestAccounts"
  | "personal_sign"
  | "eth_signTypedData"
  | "eth_signTypedData_v1"
  | "eth_signTypedData_v3"
  | "eth_signTypedData_v4"
  | "wallet_addEthereumChain"
  | "wallet_switchEthereumChain"
  | "SOLANA"
  | "SOLANA_MESSAGE"

// TODO: Refactor this to extend from Web3EvmProvider?
export default class KlaytnEvmProvider extends Provider {
  klay: Klay
  protected readonly chainByNetworkId: ChainByNetworkId

  constructor(klay: Klay, chains: Chain[]) {
    super()
    this.klay = klay
    this.chainByNetworkId = createChainByNetworkIdLookup(chains)
  }

  public static init = (chains: Chain[]) => {
    const { caver } = window
    if (caver) {
      return new KlaytnEvmProvider(caver.klay, chains)
    }
    return undefined
  }

  connect = () => {
    return this.klay.currentProvider.enable().then(this.mapToAccounts)
  }

  disconnect = noop

  getChain = (): ChainIdentifier => {
    return this.chainByNetworkId[this.getChainId()].identifier
  }

  getChainId = () => this.klay.currentProvider.networkVersion

  mapToAccounts = (addresses: Address[]): WalletAccountKey[] => {
    const chain = this.getChain()
    const walletName = this.getName()
    const walletMetadata = this.getMetadata()
    return addresses.map(address => ({
      address: address.toLowerCase(),
      chain,
      walletName,
      walletMetadata,
    }))
  }

  getAccounts = async (): Promise<WalletAccountKey[]> => {
    const addresses = await this.klay.getAccounts()
    return this.mapToAccounts(addresses)
  }

  getName = (): WALLET_NAME => {
    if (this.klay.currentProvider.isKaikas) {
      return WALLET_NAME.Kaikas
    }
    return WALLET_NAME.Native
  }

  private onEvent = <T>(
    event: Event,
    handler: (...args: T[]) => unknown,
  ): (() => unknown) => {
    this.klay.currentProvider.on<T>(event, handler)
    return () => this.klay.currentProvider.off(event, handler)
  }

  onAccountsChange = (handler: (accounts: WalletAccountKey[]) => unknown) => {
    return this.onEvent<Address[]>("accountsChanged", addresses =>
      handler(this.mapToAccounts(addresses)),
    )
  }

  onChainChange = (handler: OnChainChangeHandler) => {
    return this.onEvent("networkChanged", () => handler(this.getChain()))
  }

  sign = async (
    message: string | Buffer,
    address: string,
    options?: SignOptions,
  ) => {
    if (
      !options?.clientSignatureStandard ||
      options.clientSignatureStandard === "PERSONAL"
    ) {
      return this.klay.sign(message.toString(), address)
    }

    const typedData = TypedDataUtils.sanitizeData(
      JSON.parse(message.toString()),
    )
    const hash = TypedDataUtils.sign(typedData, true) // Boolean true signifies to use TypedData V4 (as opposed to V1).
    const signature = await this.klay.sign("0x" + hash.toString("hex"), address)

    return signature
  }

  protected async request<T>(
    method: Method,
    params: unknown[] = [],
  ): Promise<T> {
    const { result } = await this.klay.currentProvider.send<T>(method, params)

    return result
  }

  public async signTypedData(
    message: string,
    address: string,
    options?: SignOptions,
  ) {
    const clientSignatureStandard =
      options?.clientSignatureStandard ?? "TYPED_DATA_V4"

    const signatureToMethod: Record<ClientSignatureStandard, Method> = {
      PERSONAL: "personal_sign",
      TYPED_DATA_V4: "eth_signTypedData_v4",
      SOLANA: "SOLANA",
      SOLANA_MESSAGE: "SOLANA_MESSAGE",
      // eslint-disable-next-line relay/no-future-added-value
      "%future added value": "personal_sign",
    }

    const signature = await this.request<string>(
      signatureToMethod[clientSignatureStandard],
      [address, message],
    )

    // Version of signature should be 27 or 28 for Ethereum signatures, but 0 and 1
    // are also possible versions returned, ie from Ledger signing that need to be adapted
    let v = parseInt(signature.slice(-2), 16)

    if (v < 27) {
      v += 27
    }

    const normalizedSignature = signature.slice(0, -2) + v.toString(16)

    return normalizedSignature
  }

  async transact({
    source,
    destination,
    value,
    data,
  }: Transaction & {
    source: NonNullable<Transaction["source"]>
  }): Promise<TransactionResponse> {
    const accounts = await this.getAccounts()
    if (!accounts.some(a => a.address === source)) {
      throw new Error(`Not connected to account ${source}`)
    }
    const gasLimit = await this.klay.estimateGas({
      from: source,
      to: destination,
      value,
      data,
    })
    const transaction = await this.klay.sendTransaction({
      from: source,
      to: destination,
      value,
      gasLimit,
      data,
    })
    return { hash: transaction.transactionHash }
  }

  public async estimateGas({
    source,
    destination,
    value,
    data,
  }: Transaction & {
    source: NonNullable<Transaction["source"]>
  }): Promise<string> {
    const accounts = await this.getAccounts()
    if (!accounts.some(a => a.address === source)) {
      throw new Error(`Not connected to account ${source}`)
    }

    const txConfig = {
      from: source,
      to: destination,
      value,
      data,
    }

    // This throws if the transaction will revert
    const gasLimit = await this.klay.estimateGas(txConfig)
    // Adds a 30% buffer to the gas limit
    const adjustedGasLimit = bn(gasLimit).times(130).div(100)

    const gasPrice = await this.klay.getGasPrice()
    // Adds a 50% buffer to the gas price
    const adjustedGasPrice = bn(gasPrice).times(150).div(100)

    // Klay on Klaytn shares same unit conversions as Ether
    return ethers.utils
      .formatEther(adjustedGasLimit.times(adjustedGasPrice).toString())
      .toString()
  }

  public async getNativeCurrencyBalance(address: string): Promise<string> {
    const balance = await this.klay.getBalance(address)
    return balance.toString()
  }

  public getTransactionCount = async (address: string): Promise<number> => {
    return this.klay.getTransactionCount(address, "latest")
  }
}
