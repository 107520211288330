import React, { Suspense } from "react"
import { CenterAligned, Skeleton } from "@opensea/ui-kit"
import {
  graphql,
  PreloadedQuery,
  useFragment,
  usePreloadedQuery,
} from "react-relay"
import styled from "styled-components"
import { useNavbarIconSize } from "@/components/nav/Navbar/useNavbarIconSize"
import { AccountCircle } from "@/components/svgs/material-symbols/AccountCircle.react"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { Image } from "@/design-system/Image"
import { useTranslate } from "@/hooks/useTranslate"
import { WalletAndAccountButtonAccountImage_data$key } from "@/lib/graphql/__generated__/WalletAndAccountButtonAccountImage_data.graphql"
import { WalletAndAccountButtonQuery as BridgeOrWrapFormQueryType } from "@/lib/graphql/__generated__/WalletAndAccountButtonQuery.graphql"
import { WALLET_AND_ACCOUNT_BUTTON_QUERY } from "../WalletAndAccountButton.react"

type WalletAndAccountButtonAccountImageBaseProps = {
  queryReference: PreloadedQuery<BridgeOrWrapFormQueryType>
  size?: number
}

const WalletAndAccountButtonAccountImageBase = ({
  queryReference,
  size = 28,
}: WalletAndAccountButtonAccountImageBaseProps) => {
  const t = useTranslate("components")

  const data = usePreloadedQuery(
    WALLET_AND_ACCOUNT_BUTTON_QUERY,
    queryReference,
  )

  const {
    getAccount: { imageUrl },
  } = useFragment<WalletAndAccountButtonAccountImage_data$key>(
    graphql`
      fragment WalletAndAccountButtonAccountImage_data on Query
      @argumentDefinitions(address: { type: "AddressScalar!" }) {
        getAccount(address: $address) {
          imageUrl
        }
      }
    `,
    data,
  )

  return (
    <AccountImage
      alt={t("nav.profilePictureAlt", "Account")}
      height={size}
      layout="fixed"
      objectFit="cover"
      src={imageUrl}
      width={size}
    />
  )
}

const WalletAndAccountButtonAccountImageSkeleton = () => (
  <Skeleton.Circle className="h-7 w-7" />
)

type WalletAndAccountButtonAccountImageProps = Omit<
  WalletAndAccountButtonAccountImageBaseProps,
  "queryReference"
> & {
  queryReference: PreloadedQuery<BridgeOrWrapFormQueryType> | undefined | null
}

export const WalletAndAccountButtonAccountImage = ({
  queryReference,
  size = 28,
  ...restProps
}: WalletAndAccountButtonAccountImageProps) => {
  const connectedAddress = useConnectedAddress()
  const iconSize = useNavbarIconSize()

  if (!connectedAddress) {
    return (
      <CenterAligned>
        <AccountCircle size={iconSize} />
      </CenterAligned>
    )
  }

  if (!queryReference) {
    return <WalletAndAccountButtonAccountImageSkeleton />
  }

  return (
    <Suspense fallback={<WalletAndAccountButtonAccountImageSkeleton />}>
      <WalletAndAccountButtonAccountImageBase
        queryReference={queryReference}
        size={size}
        {...restProps}
      />
    </Suspense>
  )
}

const AccountImage = styled(Image)`
  border: 2px solid ${props => props.theme.colors.components.border.level1};
  border-radius: ${props => props.theme.borderRadius.pill};
`
