import * as seadn from "@opensea/seadn"
import qs from "qs"
import {
  OPENSEA_INTERNAL_ASSETS_ONLY_URL,
  OPENSEA_USER_ASSET_URL,
} from "@/constants/urls"

const isSeadnV2 = (url: URL): boolean => {
  switch (url.hostname) {
    case "i2.seadn.io":
    case "i2c.seadn.io":
      return true
    default:
      return false
  }
}

export const shouldUseSeadnUrl = (imageUrl: string) => {
  try {
    const urlImage = new URL(imageUrl)
    if (isSeadnV2(urlImage)) {
      return true
    }
    const host = urlImage.hostname
    const allowedHosts = ["openseauserdata.com", "i.seadn.io"]
    return allowedHosts.includes(host) && seadn.isSupportedFormat(imageUrl)
  } catch (error) {
    return false
  }
}

export const SEADN_HOST = "https://i.seadn.io"

export const forceHttps = (rawUrl: string | null) => {
  if (rawUrl) {
    try {
      const urlObject = new URL(rawUrl)
      // for whatever reason ipfs and many other protocals node doesn't recognize get treated like a special protocol
      // which won't allow you to change it via the setter
      // https://nodejs.org/api/url.html#special-schemes
      // so instead of
      // urlObject.protocol = "https:"

      // we'll just always manipulate the rawUrl string based on parsing the protocol with the url object

      // also as weird as using replace may feel here ...
      // 1. not having a protocol marker throws an error when creating a URL object (so the string will always have and start with a protocol)
      // 2. this invocation replace always only replaces the first occurrence of a string (so will always be first instance and not break something further along in the string)
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace#parameters

      if (urlObject.hostname === "storage.localhost.openseabeta.com") {
        return rawUrl
      }

      // hardcode https only
      return rawUrl.replace(urlObject.protocol, "https:")
    } catch {
      return null
    }
  }
  return null
}

export const matchGoogleMedia = (inputUrl: string) => {
  try {
    const url = new URL(inputUrl)
    const splittedHost = url.hostname.split(".")
    return (
      splittedHost.length == 3 &&
      splittedHost[1] == "googleusercontent" &&
      splittedHost[2] == "com" &&
      url.protocol == "https:" &&
      url.pathname != "/"
    )
  } catch (error) {
    return false
  }
}

export const matchIMGIX = (inputUrl: string) => {
  try {
    const url = new URL(inputUrl)
    const allowedHosts = [
      "img.seadn.io",
      "img-staging.seadn.io",
      "img.openseadn.io",
      "img-staging.openseadn.io",
    ]
    const hostName = url.hostname
    // path can only match for "files/***"; can't match for only "/files"
    const path =
      url.pathname.split("/").length === 3 ? url.pathname.split("/")[1] : ""
    const protocol = url.protocol
    return (
      allowedHosts.includes(hostName) && path == "files" && protocol == "https:"
    )
  } catch (error) {
    return false
  }
}

// Used to mitigate pixelation in browsers.
// Supports displays that use more pixels that the defined resolution (such as Retina).
const boostImageSize = (size: number) => Math.max(size, Math.min(size * 2, 128))

export type ResizeImageProps = {
  height?: number
  size?: number
  width?: number
  dpr?: number
  freezeAnimation?: boolean
  cropToSquare?: boolean
  pathname?: string
}

// TODO(@laurafiuza): Make this function local to the design-system/Image.
// First we need to create a BackgroundImage component in order to remove
// the remaining calls of resizeImage outside of the design system Image
// component.

/**
 * @deprecated since this function is called within Image component.
 */
export const resizeImage = (
  imageUrl: string,
  {
    height,
    size,
    width,
    freezeAnimation,
    cropToSquare,
    pathname,
  }: ResizeImageProps,
) => {
  const onCollectionPage = pathname ? pathname.includes("/collection/") : false
  const onAssetPage = pathname && pathname.includes("/assets/")

  const imgixMatch = matchIMGIX(imageUrl)
  const isSeadnUrl = shouldUseSeadnUrl(imageUrl)

  if (imgixMatch) {
    const imgixUrl = new URL(imageUrl)
    const params = imgixUrl.searchParams

    // Remove any pre-existing size params
    params.delete("h")
    params.delete("w")

    if (onCollectionPage) {
      params.set("h", boostImageSize(720).toString())
      params.set("w", boostImageSize(720).toString())
    } else {
      if (height) {
        params.set("h", boostImageSize(height).toString())
      }
      if (width) {
        params.set("w", boostImageSize(width).toString())
      }

      // Note: This only behaves the same with fit=clip
      // https://docs.imgix.com/apis/rendering/size/fit#clip
      if (size) {
        params.set("h", boostImageSize(size).toString())
        params.set("w", boostImageSize(size).toString())
      }
      if (onAssetPage) {
        params.delete("auto")
      }
    }

    if (freezeAnimation) {
      params.set("frame", "1")
    }

    return `${imgixUrl.origin}${imgixUrl.pathname}${imgixUrl.search}`
  }

  // TODO (@auster-eth): Clean up later to support next/image sourcesets on single asset view
  if (isSeadnUrl) {
    return seadn.resizeImage(imageUrl, {
      freezeAnimation,
      width: size || width,
      height: size || height,
    })
  }

  const googleMatch = matchGoogleMedia(imageUrl)
  if (googleMatch) {
    const url = new URL(imageUrl)
    // Strip the parameters from pathname
    const pathMatch = url.pathname.match(/\/[A-Za-z0-9_-]+/)
    if (pathMatch) {
      return [
        "https://" + url.hostname + pathMatch[0],
        [
          !(height || size || width) ? "s0" : "",
          height ? `h${boostImageSize(height)}` : "",
          size ? `s${boostImageSize(size)}` : "",
          width ? `w${boostImageSize(width)}` : "",
          freezeAnimation ? "k" : "",
          cropToSquare ? "c" : "",
        ]
          .filter(x => x)
          .join("-"),
      ]
        .filter(x => x)
        .join("=")
    }
  }

  return imageUrl
}

export const getImgixPath = (imageUrl: string) => {
  const imgixMatch = matchIMGIX(imageUrl)
  if (imgixMatch) {
    const imgixUrl = new URL(imageUrl)
    return imgixUrl.pathname
  }
  return null
}

export const getOriginalMedia = (imageUrl: string | null) => {
  if (!imageUrl) {
    return null
  }
  const url = new URL(imageUrl)
  if (url.hostname === "openseauserdata.com") {
    url.hostname = "dl.openseauserdata.com"
    url.pathname = "cache/originImage" + url.pathname
  }
  return url.toString()
}

export const getNextImageFixedSizes = (width?: number, size?: number) => {
  if (width != null) {
    return `${width}px`
  }
  if (size != null) {
    return `${size}px`
  }
  return "100%"
}

export const largeFrozenImage = (imageUrl: string) => {
  return resizeImage(imageUrl, { width: 1400, freezeAnimation: true })
}

export const isVideoUrl = (url: string) =>
  [".webm", ".mp4", ".m4v", ".ogg", ".ogv", ".mov"].some(ext =>
    url.toLowerCase().endsWith(ext),
  )

const YOUTUBE_EMBED_HOSTNAME_WHITELIST = [
  "youtu.be",
  "youtube.com",
  "www.youtube.com",
]

export const isYouTubeUrl = (rawUrl: string) => {
  try {
    const url = new URL(rawUrl)
    return (
      url.protocol === "https:" &&
      YOUTUBE_EMBED_HOSTNAME_WHITELIST.includes(url.hostname)
    )
  } catch {
    return false
  }
}

export const getYouTubeVideoId = (rawUrl: string) => {
  const url = new URL(rawUrl)
  return (
    url.searchParams.get("v") ||
    (YOUTUBE_EMBED_HOSTNAME_WHITELIST.includes(url.hostname)
      ? (url.pathname.includes("embed") && url.pathname.split("/")[2]) ||
        url.pathname.split("/")[1]
      : "")
  ).replace(/[^a-zA-Z0-9-_]/g, "")
}

export const isAudioUrl = (url: string) =>
  [".mp3", ".wav", ".oga"].some(ext => url.toLowerCase().endsWith(ext))

export const isWebGlUrl = (url: string) =>
  [".glb", ".gltf"].some(ext => url.toLowerCase().endsWith(ext))

export const getHttpUrl = (value: string): URL | undefined => {
  try {
    const url = new URL(value)
    const { protocol } = url
    if (protocol === "http:" || protocol === "https:") {
      return url
    }
    return undefined
  } catch {
    return undefined
  }
}

export const isHttpUrl = (value: string): boolean => {
  return getHttpUrl(value) !== undefined
}

export const hasHTTPS = (str: string): boolean => /^https?:\/\//.test(str)

export const isAnimatableImage = (url: string) =>
  url.toLowerCase().endsWith(".gif") || url.toLowerCase().endsWith(".png")

export const isImageUrl = (url: string) =>
  isAnimatableImage(url) ||
  url.toLowerCase().endsWith(".jpg") ||
  url.toLowerCase().endsWith(".jpeg")

export const getFileFormat = (url: string) => {
  // TODO: add handling to exlcude TLDs if a url gets passed in
  const splitUrl = url.split(".")
  if (splitUrl.length <= 1) {
    return null
  }
  const fileType = splitUrl[splitUrl.length - 1]
  // m4v is not a standard content-type and needs to be prefixed with "x-"
  if (fileType === "m4v") {
    return "x-m4v"
  } else if (fileType == "mov") {
    // mov files have a "video/quicktime" content-type, but chrome doesn't support that. mp4 works
    return "mp4"
  }
  return fileType
}

export const escapeRelativeUrl = (url: string) => {
  // Strip out `.com .io .whatever` to avoid redirecting users to a bad site
  return `/${url
    .replace(/\s/g, "")
    .replace(/((\/|\\)+)/, "")
    .replace(/\.[^/]*\/?/g, "")}`
}

export const stringifyQueryParams = (
  obj: unknown,
  options?: qs.IStringifyOptions,
) => {
  return qs.stringify(obj, {
    addQueryPrefix: true,
    arrayFormat: "indices",
    encodeValuesOnly: true,
    ...options,
  })
}

export const parseQueryParams = (queryString: string): qs.ParsedQs =>
  qs.parse(queryString, {
    arrayLimit: 256,
    ignoreQueryPrefix: true,
  })

// TODO: @archana-s, @dariozubi remove this util function when we get rid of OPENSEA_INTERNAL_ASSETS_ONLY_URL on the db
export const fixAssetHost = (inputUrl: string | null) => {
  const OLDER_IMG_HOST =
    "https://storage.googleapis.com/opensea-prod.appspot.com/"
  const OLD_IMG_HOST = `${OPENSEA_INTERNAL_ASSETS_ONLY_URL}`
  const NEW_IMG_HOST = `${OPENSEA_USER_ASSET_URL}`

  const olderImageHostObj = new URL(OLDER_IMG_HOST)
  const oldImageHostObj = new URL(OLD_IMG_HOST)
  const newImageHostObj = new URL(NEW_IMG_HOST)

  const httpsUrl = forceHttps(inputUrl)

  if (!httpsUrl) {
    return inputUrl
  }

  const urlObject = new URL(httpsUrl)

  // Check if the hostname matches https://storage.googleapis.com AND the pathname matches opensea-prod.appspot.com/
  if (
    urlObject.hostname === olderImageHostObj.hostname &&
    urlObject.pathname.startsWith(olderImageHostObj.pathname)
  ) {
    urlObject.hostname = newImageHostObj.hostname
    // remove opensea-prod.appspot.com/
    urlObject.pathname = urlObject.pathname.replace(
      olderImageHostObj.pathname,
      "/",
    )
  }

  if (urlObject.hostname === oldImageHostObj.hostname) {
    urlObject.hostname = newImageHostObj.hostname
  }

  return urlObject.toString()
}

export function formatAssetImageUrl(
  url: string | null,
  fallbackUrl?: string | null,
  useFallbackIfComplexFallback = false,
): string {
  if (useFallbackIfComplexFallback) {
    const isAnimated = fallbackUrl && fallbackUrl.toLowerCase().endsWith(".gif")
    const isSvg = fallbackUrl && fallbackUrl.toLowerCase().endsWith(".svg")
    if (fallbackUrl && (isAnimated || isSvg)) {
      return fixAssetHost(fallbackUrl) || ""
    }
  }

  return fixAssetHost(url || fallbackUrl || "") || ""
}

export const isValidUrl = (url: string) => {
  return /[(http(s)?):\\(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/.test(
    url,
  )
}
