import React from "react"
import {
  Icon,
  Media,
  UnstyledButton,
  classNames,
  useIsLessThanSm,
  Text,
  linkVariants,
  Flex,
} from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { Menu } from "@/components/svgs/material-symbols/Menu.react"
import { Wallet } from "@/components/svgs/material-symbols/Wallet.react"
import { useWalletModal } from "@/containers/WalletModalProvider.react"
import {
  useActiveAccount,
  useWallet,
} from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { useIsOSWalletEnabled } from "@/features/os-wallet/flags"
import { useOSWalletTrigger } from "@/features/os-wallet/hooks/useOSWalletTrigger"
import { useIsBanned } from "@/hooks/useIsBanned"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { media } from "@/styles/styleUtils"
import { NavItem } from "../../NavItem.react"
import { NavbarBackground, navbarInteractiveClasses } from "../../styles"
import { WalletAndAccountButton } from "../../WalletPopover/components/WalletAndAccountButton/WalletAndAccountButton.react"
import { NavButton } from "../elements"
import { useNavbarIconSize } from "../useNavbarIconSize"
import { BannedAccountWalletButton } from "./BannedAccountWalletButton.react"
import { ShoppingCartButton } from "./ShoppingCartButton.react"

interface NavbarRightProps {
  className?: string
  background?: NavbarBackground
  isMobileMenuClosing?: boolean
  isMobileMenuVisible?: boolean
  isMobileSearchBarOpen: boolean
  mobileMenuToggleNavItemRef?: React.RefObject<HTMLLIElement>
  mobileSearchBarToggleNavItemRef?: React.RefObject<HTMLButtonElement>
  openSearchMobile?: () => void
  openWalletSidebar?: () => void
  setIsNavMobileOpen?: (isNavMobileOpen: boolean) => void
  showHamburgerMenu?: boolean
  showShoppingCart?: boolean
  showSearch?: boolean
  showWalletAndProfile?: boolean
}

export const NavbarRight = ({
  className,
  background,
  isMobileMenuClosing,
  isMobileMenuVisible,
  isMobileSearchBarOpen,
  mobileMenuToggleNavItemRef,
  mobileSearchBarToggleNavItemRef,
  openSearchMobile,
  openWalletSidebar,
  setIsNavMobileOpen,
  showHamburgerMenu = true,
  showSearch = true,
  showShoppingCart = true,
  showWalletAndProfile = true,
}: NavbarRightProps) => {
  const t = useTranslate("components")

  const activeAccount = useActiveAccount()
  const isActiveAccountBanned = useIsBanned()
  const isOSWalletEnabled = useIsOSWalletEnabled()
  const iconSize = useNavbarIconSize()
  const isSmallBp = useIsLessThanSm()

  const { logout } = useWallet()
  const { toggleOSWallet } = useOSWalletTrigger()
  const { showSuccessMessage } = useToasts()
  const { startWalletModalAuthFlow } = useWalletModal()

  return (
    <>
      <ContainerList
        $isMobileSearchBarOpen={isMobileSearchBarOpen}
        className={classNames(className, "min-w-0 sm:shrink-0")}
      >
        {/* Mobile login button */}
        <Media lessThan="md">
          {mediaClassNames => (
            <>
              {!activeAccount && showWalletAndProfile && openWalletSidebar && (
                <UnstyledButton
                  className={classNames(
                    mediaClassNames,
                    navbarInteractiveClasses({
                      background:
                        background === "transparent"
                          ? "transparent"
                          : "default",
                    }),
                    "relative h-9 min-w-0 rounded-xl px-3 py-2",
                  )}
                  onClick={() =>
                    isOSWalletEnabled
                      ? startWalletModalAuthFlow(toggleOSWallet)
                      : openWalletSidebar()
                  }
                >
                  <Wallet />
                  <Block className="truncate text-start" ml="8px">
                    {t("navMobileList.login", "Login")}
                  </Block>
                </UnstyledButton>
              )}
            </>
          )}
        </Media>

        {/* Search button */}
        <Media lessThan="lg">
          {mediaClassNames => (
            <>
              {showSearch && (
                <NavButton
                  $background={background}
                  className={classNames(
                    mediaClassNames,
                    "lg:mr-3",
                    !activeAccount && "relative left-2 lg:left-0",
                  )}
                  ref={mobileSearchBarToggleNavItemRef}
                  onClick={openSearchMobile}
                >
                  <Icon
                    size={iconSize}
                    title={t("navMobileList.search", "Open search bar")}
                    value="search"
                  />
                </NavButton>
              )}
            </>
          )}
        </Media>

        {/* Wallet and Shopping cart buttons */}
        {!isActiveAccountBanned && (
          <>
            {/* Mobile */}
            <Media lessThan="md">
              {mediaClassNames => (
                <>
                  {activeAccount && (
                    <ButtonsContainer className={mediaClassNames}>
                      {showWalletAndProfile && (
                        <WalletAndAccountButton
                          background={background}
                          visibleModules={
                            isSmallBp ? ["wallet"] : ["wallet", "account"]
                          }
                        />
                      )}
                      {showShoppingCart && (
                        <ShoppingCartButton background={background} />
                      )}
                    </ButtonsContainer>
                  )}
                </>
              )}
            </Media>

            <Media greaterThanOrEqual="md">
              {mediaClassNames => (
                <>
                  <ButtonsContainer className={mediaClassNames}>
                    {showWalletAndProfile && (
                      <WalletAndAccountButton background={background} />
                    )}
                    {showShoppingCart && (
                      <ShoppingCartButton background={background} />
                    )}
                  </ButtonsContainer>
                </>
              )}
            </Media>
          </>
        )}

        <Flex className="items-center gap-4">
          {isActiveAccountBanned && (
            <>
              <BannedAccountWalletButton background={background} />
              <NavItem
                onClick={async () => {
                  await logout()
                  showSuccessMessage(
                    t(
                      "nav.logoutMessage",
                      "You have been logged out successfully.",
                    ),
                  )
                }}
              >
                <Text
                  className={classNames(
                    "whitespace-nowrap pr-[15%]",
                    linkVariants(),
                  )}
                >
                  {t("nav.logout", "Log out")}
                </Text>
              </NavItem>
            </>
          )}
        </Flex>
      </ContainerList>

      <Media lessThan="md">
        {mediaClassNames => (
          <>
            {!isMobileSearchBarOpen && showHamburgerMenu && (
              <HamburgerMenuNavItem
                className={mediaClassNames}
                icon={isMobileMenuVisible ? "close" : Menu}
                iconSize={iconSize}
                iconTitle={
                  isMobileMenuVisible
                    ? t("nav.closeMenu.title", "Close menu")
                    : t("nav.openMenu.title", "Open menu")
                }
                isRoot
                ref={mobileMenuToggleNavItemRef}
                onClick={() =>
                  !isMobileMenuClosing &&
                  setIsNavMobileOpen?.(!isMobileMenuVisible)
                }
              />
            )}
          </>
        )}
      </Media>
    </>
  )
}

const ContainerList = styled.ul<{ $isMobileSearchBarOpen: boolean }>`
  display: flex;
  margin: 0;
  align-items: center;

  .Navbar--dropdown {
    top: -6px;
  }

  ${({ $isMobileSearchBarOpen }) =>
    media(
      {
        lg: css`
          column-gap: 0;
          display: ${$isMobileSearchBarOpen ? "none" : "flex"};
        `,
      },
      { variant: "lessThan" },
    )}
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  margin: 0;
  align-items: center;
  column-gap: 0;

  ${media({
    lg: css`
      column-gap: 12px;
    `,
  })}
`

const HamburgerMenuNavItem = styled(NavItem)`
  width: 48px;
  margin-left: 10px;

  ${media(
    {
      sm: css`
        margin-left: -4px;
      `,
    },
    { variant: "lessThan" },
  )}
  ${media(
    {
      md: css`
        margin-right: -14px;
        margin-left: 0;
      `,
    },
    { variant: "lessThan" },
  )}
`
