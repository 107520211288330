import { parseQueryParams } from "@/lib/helpers/urls"
import { legacyTrackWithGlobalParams } from "./amplitude/legacyTrackWithGlobalParams"
import { PAGE_VIEW_TRACKED_QUERY_PARAM_KEYS } from "./constants"

/**
 * @deprecated Use useTrackingFn instead
 *
 * Get a strongly typed tracking function with event name preset (no params)
 * @param eventName Event name to use for tracking
 * @returns Strongly typed tracking function with event name preset
 */
export function getTrackingFn<_T extends undefined>(
  eventName: string,
): () => void

/**
 * @deprecated Use useTrackingFn instead
 *
 * Get a strongly typed tracking function with params and with event name preset
 * @param eventName Event name to use for tracking
 * @returns Strongly typed tracking function with params and with event name preset
 */
export function getTrackingFn<T extends Record<string, unknown>>(
  eventName: string,
): (params: T) => void

// implementation
export function getTrackingFn(
  eventName: string,
): (params?: Record<string, unknown>) => void {
  return (params?: Record<string, unknown>) => {
    legacyTrackWithGlobalParams(eventName, params)
  }
}

/**
 * @deprecated Use PageTrackingContextProvider which automatically tracks Page View events
 *
 * Get a strongly page typed tracking function with page name preset (no params)
 * @param pageName Page name to use for tracking
 * @returns Strongly page typed tracking function with page name preset
 */
export function getPageTrackingFn<_T extends undefined>(
  pageName: string,
): () => void

/**
 * @deprecated Use PageTrackingContextProvider which automatically tracks Page View events
 *
 * Get a strongly page typed tracking function with params and with page name preset
 * @param pageName Page name to use for tracking
 * @returns Strongly page typed tracking function with params and with page name preset
 */
export function getPageTrackingFn<T extends Record<string, unknown>>(
  pageName: string,
): (params: T) => void

// implementation
export function getPageTrackingFn(
  pageName: string,
): (params?: Record<string, unknown>) => void {
  return (params?: Record<string, unknown>) => {
    const queryParams = parseQueryParams(window.location.search)
    legacyTrackWithGlobalParams("Page View", {
      properties: {
        ...params,
        pageName,
        queryParams: PAGE_VIEW_TRACKED_QUERY_PARAM_KEYS.reduce(
          (trackedQueryParams, trackedQueryParamKey) => {
            return {
              ...trackedQueryParams,
              [trackedQueryParamKey]: queryParams[trackedQueryParamKey],
            }
          },
          {},
        ),
      },
    })
  }
}
