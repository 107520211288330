import React, { useMemo } from "react"
import {
  INSTAGRAM_URL,
  REDDIT_URL,
  TWITTER_URL,
  YOUTUBE_URL,
  TIKTOK_URL,
} from "@/constants/index"

type LinkedDataJsonWebSite = {
  "@type": "WebSite"
  name: string
  url: string
  potentialAction: {
    "@type": "SearchAction"
    target: string
    "query-input": string
  }
}

type LinkedDataJsonOrganization = {
  "@type": "Organization"
  name: string
  url: string
  logo: string
  sameAs: string[]
}

type LinkedDataJsonProduct = {
  "@type": "Product"
  name: string
  image: string
  description: string
  brand: {
    "@type": "Brand"
    name: string
  }
  offers?: {
    "@type": "Offer"
    priceCurrency: "USD"
    price: string
    priceValidUntil?: string // ISO 8601
    url: string
  }
}

type LinkedDataJsonTable = {
  "@type": "Table"
  about: string
}

export type PageLinkedDataJson = LinkedDataJsonProduct | LinkedDataJsonTable

type OrgLinkedDataJson = LinkedDataJsonWebSite | LinkedDataJsonOrganization

const defaultLinkedDataJson: OrgLinkedDataJson[] = [
  {
    "@type": "WebSite",
    name: "OpenSea",
    url: "https://opensea.io",
    potentialAction: {
      "@type": "SearchAction",
      target: "https://opensea.io/assets?search[query]={search_term_string}",
      "query-input": "required name=search_term_string",
    },
  },
  {
    "@type": "Organization",
    name: "OpenSea",
    url: "https://opensea.io",
    logo: "https://opensea.io/static/images/favicon/180x180.png",
    sameAs: [INSTAGRAM_URL, REDDIT_URL, TWITTER_URL, YOUTUBE_URL, TIKTOK_URL],
  },
]

export const useLinkedDataJsonScriptElement = (
  pageSchema?: PageLinkedDataJson,
) => {
  const schemas = useMemo((): (OrgLinkedDataJson | PageLinkedDataJson)[] => {
    if (!pageSchema) {
      return defaultLinkedDataJson
    }

    return [...defaultLinkedDataJson, pageSchema]
  }, [pageSchema])

  return (
    <>
      {schemas.map(schema => (
        <script key={schema["@type"]} type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            ...schema,
          })}
        </script>
      ))}
    </>
  )
}
