/**
 * @generated SignedSource<<0a2b7e810285c115166112dee4295782>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BridgeOrWrapBalancesFundsDisplay_data$data = {
  readonly wallet: {
    readonly baseCurrencyFunds?: {
      readonly " $fragmentSpreads": FragmentRefs<"FundsDisplay_walletFunds">;
    };
    readonly wrappedCurrencyFunds: {
      readonly " $fragmentSpreads": FragmentRefs<"FundsDisplay_walletFunds">;
    };
  };
  readonly " $fragmentType": "BridgeOrWrapBalancesFundsDisplay_data";
};
export type BridgeOrWrapBalancesFundsDisplay_data$key = {
  readonly " $data"?: BridgeOrWrapBalancesFundsDisplay_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"BridgeOrWrapBalancesFundsDisplay_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "FundsDisplay_walletFunds"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "address"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baseCurrencyChain"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baseCurrencyIsChainNativeCurrency"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baseCurrencySymbol"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "wrappedCurrencyChain"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "wrappedCurrencySymbol"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "BridgeOrWrapBalancesFundsDisplay_data",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "address",
          "variableName": "address"
        }
      ],
      "concreteType": "WalletType",
      "kind": "LinkedField",
      "name": "wallet",
      "plural": false,
      "selections": [
        {
          "alias": "wrappedCurrencyFunds",
          "args": [
            {
              "kind": "Variable",
              "name": "chain",
              "variableName": "wrappedCurrencyChain"
            },
            {
              "kind": "Variable",
              "name": "symbol",
              "variableName": "wrappedCurrencySymbol"
            }
          ],
          "concreteType": "WalletFundsType",
          "kind": "LinkedField",
          "name": "fundsOf",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "condition": "baseCurrencyIsChainNativeCurrency",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "alias": "baseCurrencyFunds",
              "args": [
                {
                  "kind": "Variable",
                  "name": "chain",
                  "variableName": "baseCurrencyChain"
                },
                {
                  "kind": "Variable",
                  "name": "symbol",
                  "variableName": "baseCurrencySymbol"
                }
              ],
              "concreteType": "WalletFundsType",
              "kind": "LinkedField",
              "name": "fundsOf",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "3d942f23172f3e4f186bd3f9ead9d3ef";

export default node;
