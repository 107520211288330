import React, { useState, useMemo, useEffect, useCallback } from "react"
import localforage from "localforage"
import { FragmentRef } from "relay-runtime"
import { IToggle } from "unleash-proxy-client"
import { useContext, useContextSelector } from "use-context-selector"
import * as AppUtils from "@/components/app/utils"
import { SwitchAccountsModalContent } from "@/components/modals/SwitchAccountsModalContent.react"
import { trackWalletSwitched } from "@/components/nav/WalletSidebar/analytics"
import { getWalletConfiguration } from "@/constants/wallet"
import { useAppContext } from "@/hooks/useAppContext"
import { useChains } from "@/hooks/useChains"
import { Chain, ChainIdentifier } from "@/hooks/useChains/types"
import { isChainOnMatchingNetwork } from "@/hooks/useChains/utils"
import { useGlobalModal } from "@/hooks/useGlobalModal"
import { useMountEffect } from "@/hooks/useMountEffect"
import { usePageVisibility } from "@/hooks/usePageVisibility"
import { resetAmplitudeUser } from "@/lib/analytics/amplitude"
import { WalletTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/WalletTrackingContext.react"
import Auth from "@/lib/auth"
import { getIsAuthStateSimplificationEnabled } from "@/lib/auth/flags"
import { IdentityKey } from "@/lib/auth/types"
import chainModule, { WalletAccountKey } from "@/lib/chain/chain"
import Provider, {
  USER_REJECTED_REQUEST_ERROR_CODE,
} from "@/lib/chain/provider"
import Wallet from "@/lib/chain/wallet"
import { wallet_accountKey$data } from "@/lib/graphql/__generated__/wallet_accountKey.graphql"
import { addressesEqual } from "@/lib/helpers/address"
import { first } from "@/lib/helpers/array"
import { captureNoncriticalError } from "@/lib/sentry"
import { setActiveChain } from "./activeChain"
import { useWalletProviderTracking } from "./analytics"
import { WalletContext } from "./context"
import { LAST_CONNECTED_WALLET_KEY } from "./localstorage"

type Props = {
  children: React.ReactNode
  wallet: Wallet
  isAuthenticated: boolean
  chains: Chain[]
  toggles: IToggle[]
}

export const WalletProvider = ({
  children,
  wallet,
  toggles,
  chains: chainDefinitions,
  isAuthenticated: isInitiallyAuthenticated,
}: Props) => {
  const { trackConnectWallet, trackLogout, trackProviderAccountsChange } =
    useWalletProviderTracking()
  const [activeAccount, setActiveAccount] = useState(wallet.activeAccount)
  const [isAuthenticated, setIsAuthenticated] = useState(
    isInitiallyAuthenticated,
  )
  const { chains, getChain } = useChains()
  const [chain, setChain] = useState<ChainIdentifier | null | undefined>()
  const [isCompatibleNetwork, setIsCompatibleNetwork] = useState(true)
  const [
    isIncompatibleNetworkBannerHidden,
    setIsIncompatibleNetworkBannerHidden,
  ] = useState(false)
  const { refetchPublisher, updateContext } = useAppContext()

  const [activeProvider, setActiveProvider] = useState<Provider>()
  const [installedProviders, setInstalledProviders] = useState(
    chainModule.providers,
  )

  const providers = useMemo(() => {
    return installedProviders.filter(provider => {
      const configuration = getWalletConfiguration(provider.getName())
      return chains.some(enabledChain =>
        configuration.supportsChain(getChain(enabledChain)),
      )
    })
  }, [installedProviders, chains, getChain])

  const hideIncompatibleNetworkBanner = useCallback(
    () => setIsIncompatibleNetworkBannerHidden(true),
    [],
  )

  const login = useCallback(async () => {
    const account = await Auth.login({
      activeAccount: wallet.activeAccount,
      ensureLoginCompatibleNetwork: wallet.ensureLoginCompatibleNetwork,
      getProviderOrRedirect: wallet.getProviderOrRedirect,
      setAuthenticatedAccount: wallet.setAuthenticatedAccount,
      sign: wallet.sign,
    })
    setIsAuthenticated(Boolean(account))
    return account
  }, [wallet])

  const [privyAccessToken, setPrivyAccessToken] = useState<string | undefined>(
    undefined,
  )

  const os2Login = useCallback(async () => {
    await Auth.refresh()
    await Auth.os2Login({
      activeAccount: wallet.activeAccount,
      ensureLoginCompatibleNetwork: wallet.ensureLoginCompatibleNetwork,
      getProviderOrRedirect: wallet.getProviderOrRedirect,
      setAuthenticatedAccount: wallet.setAuthenticatedAccount,
      sign: wallet.sign,
      privyAccessToken,
    })
  }, [privyAccessToken, wallet])

  const logout = useCallback(async () => {
    trackLogout()
    await wallet.disconnect()
    await Auth.logout()
    setIsAuthenticated(false)
    resetAmplitudeUser()
  }, [trackLogout, wallet])

  const updateChain = useCallback(
    async (newChain: ChainIdentifier | null | undefined) => {
      setChain(newChain)
      setIsIncompatibleNetworkBannerHidden(false)
      if (newChain) {
        setIsCompatibleNetwork(isChainOnMatchingNetwork(getChain(newChain)))
      }
    },
    [setChain, getChain],
  )

  const isActiveAccount = useCallback(
    (
      account: FragmentRef<wallet_accountKey$data> | undefined | null,
    ): boolean => {
      if (!account || !activeAccount) {
        return false
      }
      const { address } = Wallet.readAccountKey(account)
      return addressesEqual(activeAccount.address, address)
    },
    [activeAccount],
  )

  const switchProvider = useCallback(
    async (nextProvider: Provider) => {
      if (activeProvider === nextProvider) {
        return
      }
      const walletName = nextProvider.getName()
      try {
        await wallet.switch(nextProvider)
        setActiveProvider(nextProvider)
        trackWalletSwitched({
          fromMetadata: activeProvider?.getMetadata(),
          from: activeProvider?.getName(),
          to: walletName,
          toMetadata: nextProvider.getMetadata(),
        })
      } catch (error) {
        if (error.error?.code !== USER_REJECTED_REQUEST_ERROR_CODE) {
          captureNoncriticalError(error)
        }
      }
    },
    [wallet, activeProvider],
  )

  const switchChain = useCallback(
    async (chain: Chain) => {
      const providerChain = await activeProvider?.getChain()

      // If we're trying to switch between EVM and non-EVM chains, we should check to see if there are any existing providers
      // that can handle the chain switch. If there are, we should switch to that provider. An example of this would be Phantom which
      // can support both EVM and Solana.
      if (providerChain && chain.isEvm !== getChain(providerChain).isEvm) {
        const otherInstalledProviders = providers.filter(
          p => p !== activeProvider,
        )
        for (const installedProvider of otherInstalledProviders) {
          if (
            installedProvider.getName() === activeProvider?.getName() &&
            getWalletConfiguration(installedProvider.getName()).supportsChain(
              chain,
            )
          ) {
            return switchProvider(installedProvider)
          }
        }

        throw new Error(
          `Switching chain is not supported on ${activeProvider?.getName()}`,
        )
      }

      return wallet.switchChain(chain)
    },
    [getChain, activeProvider, providers, switchProvider, wallet],
  )

  const showIncompatibleNetworkBanner =
    !isCompatibleNetwork && !isIncompatibleNetworkBannerHidden

  useEffect(() => {
    setActiveChain(chain)
  }, [chain])

  // -- Wallet hooks --
  useMountEffect(() => {
    chainModule.init(chainDefinitions, toggles)

    const initActiveProvider = async () => {
      if (!wallet.activeAccount?.walletName) {
        return
      }
      const activeProvider = await chainModule.addProvider(
        wallet.activeAccount.walletName,
      )

      setActiveProvider(activeProvider)
      if (!activeProvider) {
        updateChain(undefined)
        return
      }
      const chain = await activeProvider.getChain()
      updateChain(chain)
    }

    const initWallet = async () => {
      // Wait for active provider and detectable providers to be initialized
      // before initializing the wallet to ensure wallet is initialized with
      // account keys from all providers
      await Promise.all([
        initActiveProvider(),
        chainModule.addDetectableProviders(),
      ])

      const accountKeys = await chainModule.getAccounts()
      await wallet.initialize(accountKeys)
    }

    const initWalletOptimistically = async () => {
      // For SSG, try to optimistically get active wallet from cookies on client
      // instead of waiting for wallet events, as this will be much quicker.
      // We do this in a effect to prevent hydration errors
      if (!wallet.activeAccount) {
        const walletState = Wallet.stateFromContext()

        if (walletState.data?.activeAccount) {
          wallet.setState(walletState)
          const isAuthenticated = await Auth.getIsAuthenticated(
            wallet.activeAccount,
          )
          setActiveAccount(wallet.activeAccount)
          setIsAuthenticated(isAuthenticated)
          // setting the active provider and updating the chain will trigger a re-render
          const activeProvider = await chainModule.addProvider(
            walletState.data.activeAccount.walletName,
          )
          setActiveProvider(activeProvider)
          updateChain(await activeProvider?.getChain())
        }
      }
    }

    initWalletOptimistically()
    initWallet()

    // We believe the "Connecting ..." problem is a race condition exists sometimes,
    // so let's try to re-init the wallet after 500ms
    setTimeout(() => initWallet(), 500)

    return chainModule.onProviderChange(setInstalledProviders)
  })

  const isPageVisible = usePageVisibility()
  const { openModal, closeModal } = useGlobalModal()
  useEffect(() => {
    const unsub = activeProvider?.onAccountsChange(
      async (accountKeys: WalletAccountKey[]) => {
        const connectedAccount = first(accountKeys)

        const doAccountChange = async () => {
          const activeAccount = await wallet.handleAccountsChange(
            activeProvider,
            accountKeys,
          )
          const isAuthenticated = Auth.getIsAuthenticated(
            activeAccount?.address,
          )
          setIsAuthenticated(isAuthenticated)
          setActiveAccount(activeAccount)
          trackProviderAccountsChange({
            walletName: activeProvider.getName(),
            address: activeAccount?.address ?? "",
          })
        }

        if (
          !getIsAuthStateSimplificationEnabled() ||
          Auth.getIsAuthenticated(connectedAccount?.address)
        ) {
          await doAccountChange()
          return
        }

        // Reset signature in progress in case signatureInProgress is in bad state
        localStorage.setItem("signatureInProgress", "false")
        openModal(
          <SwitchAccountsModalContent
            address={connectedAccount?.address}
            doAccountChange={doAccountChange}
          />,
          {},
        )
      },
    )
    return () => {
      unsub?.()
    }
  }, [
    wallet,
    activeProvider,
    trackProviderAccountsChange,
    isPageVisible,
    openModal,
    closeModal,
  ])

  useEffect(() => {
    const handleWalletChange = async (wallet: Wallet) => {
      const maybeProvider = await wallet.getProvider()
      const maybeChain = await maybeProvider?.getChain()
      // this will redirect from the login page if needed
      const { isAuthenticated } = await AppUtils.checkWallet(wallet)
      setIsAuthenticated(isAuthenticated)
      updateChain(maybeChain)
      setActiveProvider(maybeProvider)
      setActiveAccount(wallet.activeAccount)

      if (maybeProvider && maybeChain && wallet.address) {
        await localforage.setItem(
          LAST_CONNECTED_WALLET_KEY,
          maybeProvider.getName(),
        )
        trackConnectWallet({
          address: wallet.address,
          chain: maybeChain,
          walletName: maybeProvider.getName(),
        })
      }

      refetchPublisher.publish()
    }

    return wallet.onChange(handleWalletChange)
  }, [
    wallet,
    login,
    refetchPublisher,
    updateChain,
    updateContext,
    trackConnectWallet,
  ])

  useEffect(() => {
    return activeProvider?.onChainChange(updateChain)
  }, [activeProvider, updateChain])

  const value = useMemo(
    () => ({
      chain,
      provider: activeProvider,
      providers,
      switchProvider,
      switchChain,
      showIncompatibleNetworkBanner,
      hideIncompatibleNetworkBanner,
      login,
      os2Login,
      logout,
      isAuthenticated,
      activeAccount,
      setActiveAccount,
      isActiveAccount,
      wallet,
      setPrivyAccessToken,
    }),
    [
      chain,
      activeProvider,
      providers,
      switchProvider,
      switchChain,
      showIncompatibleNetworkBanner,
      hideIncompatibleNetworkBanner,
      login,
      os2Login,
      logout,
      isAuthenticated,
      activeAccount,
      setActiveAccount,
      isActiveAccount,
      wallet,
      setPrivyAccessToken,
    ],
  )

  return (
    <WalletContext.Provider value={value}>
      <WalletTrackingContextProvider>{children}</WalletTrackingContextProvider>
    </WalletContext.Provider>
  )
}

export const useWallet = () => useContext(WalletContext)

export const useConnectedAddress = () =>
  useContextSelector(WalletContext, context => context.activeAccount?.address)

export const useActiveAccount = () =>
  useContextSelector(WalletContext, context => context.activeAccount)

export const useActiveIdentity = (): IdentityKey | undefined => {
  const address = useConnectedAddress()
  return useMemo(() => (address ? { address } : undefined), [address])
}
