import { cva } from "class-variance-authority"
import { rgba } from "polished"
import { css } from "styled-components"
import { variant } from "styled-system"
import { HUES } from "@/styles/themes"

export const navBackground = cva("transition-colors duration-200 ease-in-out", {
  variants: {
    background: {
      default:
        "bg-component-gray-1 text-primary hover:bg-component-gray-2 dark:bg-component-gray-2 dark:hover:bg-component-gray-3",
      dark_override: "dark bg-component-gray-1 hover:bg-component-gray-2",
      transparent:
        "bg-transparent dark:bg-component-gray-2 dark:hover:bg-component-gray-3",
    },
  },
})

export const navSolidBackground = css`
  transition: background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1);

  ${variant({
    prop: "$background",
    variants: {
      light: {
        backgroundColor: rgba(HUES.smoke, 0.04),
        color: HUES.smoke,
      },
      dark_1: {
        backgroundColor: rgba(HUES.white, 0.04),
        color: HUES.white,
      },
      dark_2: {
        backgroundColor: rgba(HUES.white, 0.08),
        color: HUES.white,
      },
    },
  })}

  :hover {
    ${variant({
      prop: "$background",
      variants: {
        light: {
          backgroundColor: rgba(HUES.smoke, 0.08),
        },
        dark_1: {
          backgroundColor: rgba(HUES.white, 0.08),
        },
        dark_2: {
          backgroundColor: rgba(HUES.white, 0.12),
        },
      },
    })}
  }
`
