import React, { useCallback } from "react"
import { useFragment } from "react-relay"
import {
  LoadingContent,
  LOADING_CONTENT_STATE,
} from "@/features/collections/components/CollectionCreateOrUpdatePage/components/UpdateDropMechanicsModal/components/UpdateDropMechanicsTransactionModal/components/LoadingContent"
import { useTranslate } from "@/hooks/useTranslate"
import { UpdateDropMechanicsAction_data$key } from "@/lib/graphql/__generated__/UpdateDropMechanicsAction_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import {
  BlockchainActionModalContent,
  BaseBlockchainActionProps,
} from "./BlockchainActionModalContent.react"
import { useBlockchainActionProgress } from "./useBlockchainActionProgress"
import { useHandleBlockchainActions } from "./useHandleBlockchainActions"

export type UpdateDropMechanicsActionProps = BaseBlockchainActionProps & {
  dataKey: UpdateDropMechanicsAction_data$key
  unpublish?: boolean
}

export const UpdateDropMechanicsAction = ({
  dataKey,
  onEnd,
  unpublish,
}: UpdateDropMechanicsActionProps) => {
  const t = useTranslate("components")
  const data = useFragment<UpdateDropMechanicsAction_data$key>(
    graphql`
      fragment UpdateDropMechanicsAction_data on DropMechanicsUpdateActionType {
        __typename
        ...useHandleBlockchainActions_update_drop_mechanics
      }
    `,
    dataKey,
  )

  const { updateDropMechanics } = useHandleBlockchainActions()

  const executeAction = useCallback(async () => {
    const transaction = await updateDropMechanics(data)
    onEnd({ transaction })
  }, [data, onEnd, updateDropMechanics])

  const { attemptAction, progress } = useBlockchainActionProgress({
    executeAction,
    action: data,
  })

  return (
    <LoadingContent
      customRender={
        !progress && (
          <BlockchainActionModalContent.Body.Button onClick={attemptAction}>
            {t("blockchain.deployContract.cta", "Continue")}
          </BlockchainActionModalContent.Body.Button>
        )
      }
      state={LOADING_CONTENT_STATE.WAITING_SIGNATURE}
      unpublish={!!unpublish}
    />
  )
}
