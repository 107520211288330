/**
 * @generated SignedSource<<935b918d75f44ecf0a3cc6cbbb05d575>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderListItem_order$data = {
  readonly item: {
    readonly __typename: string;
    readonly assetContract?: {
      readonly " $fragmentSpreads": FragmentRefs<"CollectionLink_assetContract">;
    };
    readonly assetQuantities?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly asset: {
            readonly assetContract: {
              readonly " $fragmentSpreads": FragmentRefs<"CollectionLink_assetContract">;
            };
            readonly collection: {
              readonly " $fragmentSpreads": FragmentRefs<"CollectionLink_collection">;
            };
            readonly displayName: string;
            readonly relayId: string;
            readonly " $fragmentSpreads": FragmentRefs<"AssetMedia_asset" | "StackedAssetMedia_assets" | "asset_url">;
          };
        } | null;
      } | null>;
    };
    readonly collection?: {
      readonly " $fragmentSpreads": FragmentRefs<"CollectionLink_collection">;
    };
    readonly displayName: string;
    readonly " $fragmentSpreads": FragmentRefs<"AssetMedia_asset" | "ItemTrackingContext_item" | "asset_url" | "itemEvents_dataV2" | "useIsItemSafelisted_item" | "useItemFees_item">;
  };
  readonly makerOwnedQuantity: string;
  readonly relayId: string;
  readonly remainingQuantityType: string;
  readonly " $fragmentSpreads": FragmentRefs<"OrderPrice">;
  readonly " $fragmentType": "OrderListItem_order";
};
export type OrderListItem_order$key = {
  readonly " $data"?: OrderListItem_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderListItem_order">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "AssetContractType",
  "kind": "LinkedField",
  "name": "assetContract",
  "plural": false,
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionLink_assetContract"
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "CollectionType",
  "kind": "LinkedField",
  "name": "collection",
  "plural": false,
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionLink_collection"
    }
  ],
  "storageKey": null
},
v4 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "AssetMedia_asset"
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "AssetContractType",
  "kind": "LinkedField",
  "name": "assetContract",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tokenId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "kind": "InlineDataFragmentSpread",
  "name": "asset_url",
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/)
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderListItem_order",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "makerOwnedQuantity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v8/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "useItemFees_item"
            }
          ],
          "type": "AssetType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 30
                }
              ],
              "concreteType": "AssetQuantityTypeConnection",
              "kind": "LinkedField",
              "name": "assetQuantities",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetQuantityTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AssetQuantityType",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AssetType",
                          "kind": "LinkedField",
                          "name": "asset",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v0/*: any*/),
                            (v2/*: any*/),
                            (v3/*: any*/),
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "StackedAssetMedia_assets"
                            },
                            (v4/*: any*/),
                            (v8/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "assetQuantities(first:30)"
            }
          ],
          "type": "AssetBundleType",
          "abstractKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "itemEvents_dataV2",
          "selections": [
            (v0/*: any*/),
            (v7/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                (v6/*: any*/),
                (v5/*: any*/)
              ],
              "type": "AssetType",
              "abstractKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useIsItemSafelisted_item"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ItemTrackingContext_item"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remainingQuantityType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrderPrice"
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};
})();

(node as any).hash = "c015ac6bd8de38562f13f7167596d96b";

export default node;
