/**
 * @generated SignedSource<<f6d3766b8c3d50d35da939bd9154207c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TSAccountStateType = "BANNED" | "COMPROMISED" | "DISABLED" | "LIVE" | "SUSPENDED" | "%future added value";
export type AccountBannedPageQuery$variables = {
  address: string;
};
export type AccountBannedPageQuery$data = {
  readonly getAccount: {
    readonly tsAccountState: TSAccountStateType;
  };
};
export type AccountBannedPageQuery = {
  response: AccountBannedPageQuery$data;
  variables: AccountBannedPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "address"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "address",
    "variableName": "address"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tsAccountState",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountBannedPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "getAccount",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountBannedPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "getAccount",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "58c5ee88e8ca143f030d98b82c999992",
    "id": null,
    "metadata": {},
    "name": "AccountBannedPageQuery",
    "operationKind": "query",
    "text": "query AccountBannedPageQuery(\n  $address: AddressScalar!\n) {\n  getAccount(address: $address) {\n    tsAccountState\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "55d5ceca4ec6ff10fd7f604349b5b14a";

export default node;
