import React, { Suspense } from "react"
import { Flex, Text } from "@opensea/ui-kit"
import { ErrorBoundary } from "@sentry/nextjs"
import { useLazyLoadQuery } from "react-relay"
import styled from "styled-components"
import { AssetMedia } from "@/components/assets/AssetMedia"
import AccountBadge from "@/components/common/AccountBadge.react"
import { CardTooltipContent } from "@/components/common/CardTooltipContent.react"
import { Link } from "@/components/common/Link"
import { Overflow } from "@/components/common/Overflow"
import { PreviewList } from "@/components/common/PreviewList.react"
import { IS_SERVER } from "@/constants/environment"
import { Block, BlockProps } from "@/design-system/Block"
import { Tooltip, TooltipPlacement } from "@/design-system/Tooltip"
import { useIsAccountTooltipEnabled } from "@/hooks/useFlag"
import { useTranslate } from "@/hooks/useTranslate"
import { AccountTooltipQuery } from "@/lib/graphql/__generated__/AccountTooltipQuery.graphql"
import { getNodes, graphql } from "@/lib/graphql/graphql"
import { truncateAddress } from "@/lib/helpers/address"
import { getItemUrl } from "@/lib/helpers/item"
import { shortSymbolDisplay } from "@/lib/helpers/numberUtils"
import { captureNoncriticalError } from "@/lib/sentry"

type Props = {
  address: string
  children: React.ReactNode
  placement?: TooltipPlacement
  disabled?: boolean
  width?: BlockProps["width"]
}

const NULL_ADDRESS = "0x0000000000000000000000000000000000000000"

export const AccountTooltip = ({
  address,
  children,
  disabled,
  placement = "top",
  width,
}: Props) => {
  const isAccountTooltipEnabled = useIsAccountTooltipEnabled()
  const stopPropagation = (e: React.MouseEvent) => e.stopPropagation()
  if (!isAccountTooltipEnabled) {
    return <>{children}</>
  }

  return (
    <Tooltip
      animation="fade-vertical"
      appendTo={IS_SERVER ? undefined : document.body}
      arrow={false}
      content={
        <ErrorBoundary
          fallback={({ error }) => {
            captureNoncriticalError(error, { extras: { address } })
            return <AccountTooltipError />
          }}
        >
          <Suspense fallback={<CardTooltipContent.Skeleton isRoundLogo />}>
            <div aria-hidden="true" onClick={stopPropagation}>
              <AccountTooltipContent address={address} />
            </div>
          </Suspense>
        </ErrorBoundary>
      }
      contentPadding={0}
      disabled={disabled || address === NULL_ADDRESS}
      interactive
      maxWidth={332}
      placement={placement}
      touch={false}
    >
      <Block as="span" width={width}>
        {children}
      </Block>
    </Tooltip>
  )
}

const AccountTooltipContent = ({ address }: Pick<Props, "address">) => {
  const t = useTranslate("account")
  const data = useLazyLoadQuery<AccountTooltipQuery>(
    graphql`
      query AccountTooltipQuery(
        $address: AddressScalar!
        $identity: IdentityInputType!
      ) {
        getAccount(address: $address) {
          address
          displayName
          imageUrl
          isCompromised
          config
          totalSales
          createdAssetCount
        }
        previewItems: searchItems(
          identity: $identity
          first: 3
          resultType: ASSETS
        ) {
          totalCount
          edges {
            node {
              ... on AssetType {
                id
                name
                ...AssetMedia_asset
                ...item_url
              }
            }
          }
        }
      }
    `,
    { address, identity: { address } },
  )

  const account = data.getAccount

  const {
    imageUrl,
    isCompromised,
    config,
    displayName,
    totalSales,
    createdAssetCount,
  } = account

  const previewItems = getNodes(data.previewItems)

  const collectedCount = data.previewItems.totalCount
  const name = displayName || t("accountTooltip.unnamed", "Unnamed")

  return (
    <CardTooltipContent
      imageAltText={`${name} profile image`}
      imageUrl={imageUrl}
      isRoundLogo
      lowerContent={
        previewItems.length > 0 ? (
          <PreviewList count={previewItems.length}>
            {previewItems.map(item => (
              <PreviewList.Item key={item.id}>
                <Link href={getItemUrl(item)}>
                  <AssetMedia
                    alt={item.name ?? ""}
                    asset={item}
                    rawImage
                    size={100}
                    width={100}
                  />
                </Link>
              </PreviewList.Item>
            ))}
          </PreviewList>
        ) : undefined
      }
      mainContent={
        <>
          <Flex className="items-center">
            <Text className="text-start" size="medium" weight="semibold">
              <Flex className="items-center">
                <Overflow>{name}</Overflow>
                <VerifiedIcon
                  config={config}
                  isCompromised={isCompromised}
                  showTooltip={false}
                />
              </Flex>
            </Text>
          </Flex>
          <Flex className="items-center">
            <Text className="text-secondary" size="medium">
              {truncateAddress(account.address)}
            </Text>
          </Flex>
        </>
      }
      middleContent={
        <>
          <CardTooltipContent.StatsWrapper>
            <CardTooltipContent.Stat
              subtitle={t("accountTooltip.totalCollected", "collected")}
              title={shortSymbolDisplay(collectedCount)}
            />
            {createdAssetCount > 0 && (
              <CardTooltipContent.Stat
                subtitle={t("accountTooltip.totalCreated", "created")}
                title={shortSymbolDisplay(createdAssetCount)}
              />
            )}
            <CardTooltipContent.Stat
              subtitle={t("accountTooltip.totalSales", "sold")}
              title={shortSymbolDisplay(totalSales)}
            />
          </CardTooltipContent.StatsWrapper>
        </>
      }
      width={332}
    />
  )
}

const AccountTooltipError = () => {
  const t = useTranslate("components")
  return (
    <CardTooltipContent.Error>
      {t(
        "collectionTooltip.error",
        "There has been an issue fetching this data. Please refresh and try again.",
      )}
    </CardTooltipContent.Error>
  )
}

const VerifiedIcon = styled(AccountBadge)`
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
`
