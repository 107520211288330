import { UAParser } from "ua-parser-js"
import { IS_SERVER } from "@/constants/environment"

type BrowserContext = {
  userAgent: string
  cookiesEnabled: boolean
  /**
   * The undefined value is used when device type is not indicated in the user agent.
   * Desktops and laptops do not indicate device type.
   */
  device: string | undefined
  os: string | undefined
  connectionSpeed: string | undefined
  screenSize: {
    width: number
    height: number
  }
  windowSize: {
    width: number
    height: number
  }
}

export type BrowserTrackingContext = {
  Browser: BrowserContext
}

export const getBrowserContext = (): BrowserContext | undefined => {
  if (IS_SERVER) {
    return undefined
  }

  const userAgent = window.navigator.userAgent
  const parsedUserAgent = UAParser(userAgent)

  const connectionSpeed = (
    window.navigator.connection as Navigator["connection"] | undefined
  )?.effectiveType // connection can be undefined in some browsers

  return {
    userAgent,
    device: parsedUserAgent.device.type,
    os: parsedUserAgent.os.name,
    connectionSpeed,
    cookiesEnabled: window.navigator.cookieEnabled,
    screenSize: {
      width: window.screen.width,
      height: window.screen.height,
    },
    windowSize: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
  }
}
