import { differenceInCalendarDays } from "date-fns"
import { _FragmentRefs } from "relay-runtime"
import { IS_NEW_CUTOFF_DAYS } from "@/features/rankings/components/RankingsPage/constants"
import { asset_display_name$data } from "@/lib/graphql/__generated__/asset_display_name.graphql"
import { asset_edit_url$data } from "@/lib/graphql/__generated__/asset_edit_url.graphql"
import { asset_url$data } from "@/lib/graphql/__generated__/asset_url.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { getChainSlug, getSolanaChain } from "./chainUtils"

const readAssetUrlIdentifier = inlineFragmentize<asset_url$data>(
  graphql`
    fragment asset_url on AssetType @inline {
      assetContract {
        address
      }
      tokenId
      chain {
        identifier
      }
    }
  `,
  identifiers => identifiers,
)

export const getAssetUrl = (
  ref: asset_url$data | _FragmentRefs<"asset_url">,
  action?: "bid" | "sell" | "order" | "transfer" | "cancel",
) => {
  const {
    tokenId,
    assetContract: { address },
    chain: { identifier: chain },
  } = readAssetUrlIdentifier(ref)

  let assetUrl = `/assets/${getChainSlug(chain)}/`
  if (chain !== getSolanaChain()) {
    assetUrl += `${address}/`
  }
  return assetUrl + `${tokenId}/${action ?? ""}`
}

const readAssetEditUrlIdentifier = inlineFragmentize<asset_edit_url$data>(
  graphql`
    fragment asset_edit_url on AssetType @inline {
      assetContract {
        address
        chain
      }
      tokenId
      collection {
        slug
      }
    }
  `,
  identifiers => identifiers,
)

export const getAssetEditUrl = (
  ref: asset_edit_url$data | _FragmentRefs<"asset_edit_url">,
) => {
  const {
    tokenId,
    assetContract: { address, chain },
    collection: { slug },
  } = readAssetEditUrlIdentifier(ref)

  return `/collection/${slug}/asset/${getChainSlug(
    chain,
  )}/${address}/${tokenId}/edit`
}

// TODO(Meemaw): Extract this into a resolver
const readAssetDisplayNameIdentifier =
  inlineFragmentize<asset_display_name$data>(
    graphql`
      fragment asset_display_name on AssetType @inline {
        tokenId
        name
      }
    `,
    identifiers => identifiers,
  )

export const getAssetDisplayName = (
  ref: asset_display_name$data | _FragmentRefs<"asset_display_name">,
) => {
  const { name, tokenId } = readAssetDisplayNameIdentifier(ref)
  return name || `#${tokenId}`
}

export const isNewAsset = (creationDate: Date) =>
  differenceInCalendarDays(new Date(), creationDate) < IS_NEW_CUTOFF_DAYS
