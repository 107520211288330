/**
 * @generated SignedSource<<79d4c7bd0d45855dd0a8669d3b0d747a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderInvalidationReason = "ACCOUNT_DISABLED" | "ASSET_DISABLED" | "ASSET_OWNERSHIP_UPDATE" | "ASSET_REQUIRES_APPROVAL" | "CANCEL_TRANSACTION" | "COLLECTION_DISABLED" | "ERC20_APPROVAL_REVOKED" | "EXECUTION_REVERTED_ERROR" | "EXPIRED" | "GASLESS_CANCEL" | "INSUFFICIENT_BALANCE" | "MATCH_ORDERS_ERROR" | "NOT_EXECUTABLE" | "ON_CHAIN_VALIDATION_ERROR" | "ORDER_MALFORMED" | "PREMATCHED" | "TRAIT_OFFERS_DISABLED" | "TRANSACTION_SUBMISSION_ERROR" | "%future added value";
export type useGaslessCancelOrdersMutation$variables = {
  orders: ReadonlyArray<string>;
};
export type useGaslessCancelOrdersMutation$data = {
  readonly orders: {
    readonly gaslessCancel: {
      readonly statuses: ReadonlyArray<{
        readonly order: {
          readonly invalidationReason: OrderInvalidationReason | null;
          readonly isCancelled: boolean;
          readonly isValid: boolean;
          readonly relayId: string;
          readonly remainingQuantityType: string;
        };
        readonly " $fragmentSpreads": FragmentRefs<"readGaslessCancelResult_status">;
      }>;
    };
  };
};
export type useGaslessCancelOrdersMutation = {
  response: useGaslessCancelOrdersMutation$data;
  variables: useGaslessCancelOrdersMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orders"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "orders",
    "variableName": "orders"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCancelled",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isValid",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invalidationReason",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remainingQuantityType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fulfillmentPossibleUntilChainTimestamp",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFinalized",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantityCancelled",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orderType",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "side",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useGaslessCancelOrdersMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrdersMutationType",
        "kind": "LinkedField",
        "name": "orders",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "OrderGaslessCancelType",
            "kind": "LinkedField",
            "name": "gaslessCancel",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderGaslessCancelStatusType",
                "kind": "LinkedField",
                "name": "statuses",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderV2Type",
                    "kind": "LinkedField",
                    "name": "order",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "readGaslessCancelResult_status",
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrderV2Type",
                        "kind": "LinkedField",
                        "name": "order",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "args": null,
                    "argumentDefinitions": []
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useGaslessCancelOrdersMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrdersMutationType",
        "kind": "LinkedField",
        "name": "orders",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "OrderGaslessCancelType",
            "kind": "LinkedField",
            "name": "gaslessCancel",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderGaslessCancelStatusType",
                "kind": "LinkedField",
                "name": "statuses",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderV2Type",
                    "kind": "LinkedField",
                    "name": "order",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d655607fd6589712e2c4c60ba09368f1",
    "id": null,
    "metadata": {},
    "name": "useGaslessCancelOrdersMutation",
    "operationKind": "mutation",
    "text": "mutation useGaslessCancelOrdersMutation(\n  $orders: [OrderRelayID!]!\n) {\n  orders {\n    gaslessCancel(orders: $orders) {\n      statuses {\n        order {\n          relayId\n          isCancelled\n          isValid\n          invalidationReason\n          remainingQuantityType\n          id\n        }\n        ...readGaslessCancelResult_status\n      }\n    }\n  }\n}\n\nfragment readGaslessCancelResult_status on OrderGaslessCancelStatusType {\n  fulfillmentPossibleUntilChainTimestamp\n  isFinalized\n  quantityCancelled\n  order {\n    relayId\n    orderType\n    side\n    remainingQuantityType\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7fcb7faf2ccc8f6e8deb41850c569e01";

export default node;
