import React from "react"
import styled from "styled-components"
import { variant } from "styled-system"
import { Values } from "../../lib/helpers/type"

const ICON_SIZES = {
  MICRO: "micro",
  TINY: "tiny",
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
}

const ICON_VARIANTS = {
  DEFAULT: "default",
  WHITE: "white",
}

export type IconVariant = Values<typeof ICON_VARIANTS>

export type IconSize = Values<typeof ICON_SIZES>

type Props = {
  className?: string
  size: IconSize
  variant?: IconVariant
}

export const VerifiedIcon = ({
  className,
  size,
  variant = "default",
}: Props) => {
  if (variant === ICON_VARIANTS.WHITE) {
    return (
      <StyledSvg
        $size={size}
        aria-label="verified-icon"
        className={className}
        fill="none"
        viewBox="0 0 43 46"
      >
        <path
          clipRule="evenodd"
          d="M23.6741 2.0787C22.3933 0.494275 19.9777 0.494276 18.6969 2.0787L16.932 4.26193C16.1363 5.24636 14.835 5.66918 13.6126 5.3405L10.9015 4.61157C8.93402 4.08257 6.97973 5.50245 6.87485 7.5371L6.73034 10.3407C6.66518 11.6049 5.86095 12.7118 4.67879 13.1644L2.05704 14.1682C0.154385 14.8967 -0.592088 17.1941 0.519004 18.9018L2.05002 21.255C2.74036 22.316 2.74036 23.6842 2.05002 24.7453L0.519003 27.0984C-0.592088 28.8061 0.154384 31.1035 2.05704 31.832L4.67879 32.8358C5.86095 33.2884 6.66518 34.3954 6.73034 35.6595L6.87485 38.4631C6.97973 40.4978 8.93402 41.9177 10.9015 41.3887L13.6126 40.6597C14.835 40.3311 16.1363 40.7539 16.932 41.7383L18.6969 43.9215C19.9777 45.506 22.3933 45.506 23.6741 43.9215L25.4389 41.7383C26.2347 40.7539 27.536 40.3311 28.7584 40.6597L31.4695 41.3887C33.4369 41.9177 35.3912 40.4978 35.4961 38.4631L35.6406 35.6595C35.7058 34.3953 36.51 33.2884 37.6922 32.8358L40.3139 31.832C42.2166 31.1035 42.963 28.8061 41.8519 27.0984L40.3209 24.7453C39.6306 23.6842 39.6306 22.316 40.3209 21.255L41.8519 18.9018C42.963 17.1941 42.2166 14.8967 40.3139 14.1682L37.6922 13.1644C36.51 12.7118 35.7058 11.6049 35.6406 10.3407L35.4961 7.53709C35.3912 5.50244 33.4369 4.08257 31.4695 4.61157L28.7584 5.3405C27.536 5.66918 26.2347 5.24636 25.4389 4.26193L23.6741 2.0787ZM32.8789 17.6449L29.9646 14.7307L18.6075 26.0878L14.1075 21.5878L11.1933 24.5021L18.6075 31.9163L32.8789 17.6449Z"
          fill="white"
          fillRule="evenodd"
        />
      </StyledSvg>
    )
  }

  return (
    <StyledSvg
      $size={size}
      aria-label="verified-icon"
      className={className}
      fill="none"
      viewBox="0 0 30 30"
    >
      <BlueCheckBackground d="M13.474 2.80108C14.2729 1.85822 15.7271 1.85822 16.526 2.80108L17.4886 3.9373C17.9785 4.51548 18.753 4.76715 19.4892 4.58733L20.9358 4.23394C22.1363 3.94069 23.3128 4.79547 23.4049 6.0278L23.5158 7.51286C23.5723 8.26854 24.051 8.92742 24.7522 9.21463L26.1303 9.77906C27.2739 10.2474 27.7233 11.6305 27.0734 12.6816L26.2903 13.9482C25.8918 14.5928 25.8918 15.4072 26.2903 16.0518L27.0734 17.3184C27.7233 18.3695 27.2739 19.7526 26.1303 20.2209L24.7522 20.7854C24.051 21.0726 23.5723 21.7315 23.5158 22.4871L23.4049 23.9722C23.3128 25.2045 22.1363 26.0593 20.9358 25.7661L19.4892 25.4127C18.753 25.2328 17.9785 25.4845 17.4886 26.0627L16.526 27.1989C15.7271 28.1418 14.2729 28.1418 13.474 27.1989L12.5114 26.0627C12.0215 25.4845 11.247 25.2328 10.5108 25.4127L9.06418 25.7661C7.86371 26.0593 6.6872 25.2045 6.59513 23.9722L6.48419 22.4871C6.42773 21.7315 5.94903 21.0726 5.24777 20.7854L3.86969 20.2209C2.72612 19.7526 2.27673 18.3695 2.9266 17.3184L3.70973 16.0518C4.10824 15.4072 4.10824 14.5928 3.70973 13.9482L2.9266 12.6816C2.27673 11.6305 2.72612 10.2474 3.86969 9.77906L5.24777 9.21463C5.94903 8.92742 6.42773 8.26854 6.48419 7.51286L6.59513 6.0278C6.6872 4.79547 7.86371 3.94069 9.06418 4.23394L10.5108 4.58733C11.247 4.76715 12.0215 4.51548 12.5114 3.9373L13.474 2.80108Z" />
      <path
        d="M13.5 17.625L10.875 15L10 15.875L13.5 19.375L21 11.875L20.125 11L13.5 17.625Z"
        fill="white"
        stroke="white"
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg<{
  $size: IconSize
}>`
  ${variant({
    prop: "$size",
    variants: {
      [ICON_SIZES.MICRO]: {
        height: "0.75rem",
        width: "0.75rem",
      },
      [ICON_SIZES.TINY]: {
        height: "1.0rem",
        width: "1.0rem",
      },
      [ICON_SIZES.SMALL]: {
        height: "1.25rem",
        width: "1.25rem",
      },
      [ICON_SIZES.MEDIUM]: {
        height: "1.5rem",
        width: "1.5rem",
      },
      [ICON_SIZES.LARGE]: {
        height: "2.375rem",
        width: "2.375rem",
      },
    },
  })};
`

const BlueCheckBackground = styled.path`
  fill: ${({ theme }) => theme.colors.seaBlue};
`
