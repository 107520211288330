import { useMemo } from "react"
import { graphql, useFragment } from "react-relay"
import { useTotalItems_orders$key } from "@/lib/graphql/__generated__/useTotalItems_orders.graphql"
import { useTotalItems_ordersData$key } from "@/lib/graphql/__generated__/useTotalItems_ordersData.graphql"
import { getNodes } from "@/lib/graphql/graphql"

export const useTotalItems = ({
  ordersDataKey,
  ordersDataDataKey,
  criteriaAssetIds,
}: {
  ordersDataKey?: useTotalItems_orders$key | null
  ordersDataDataKey?: useTotalItems_ordersData$key | null
  criteriaAssetIds?: string[]
}) => {
  const orders = useFragment(
    graphql`
      fragment useTotalItems_orders on OrderV2Type @relay(plural: true) {
        item {
          relayId

          ... on AssetBundleType {
            assetQuantities(first: 30) {
              edges {
                node {
                  asset {
                    relayId
                  }
                }
              }
            }
          }
        }
      }
    `,
    ordersDataKey || null,
  )

  const ordersData = useFragment(
    graphql`
      fragment useTotalItems_ordersData on OrderDataType @relay(plural: true) {
        item {
          ... on AssetQuantityDataType {
            asset {
              relayId
            }
          }

          ... on AssetBundleType {
            assetQuantities(first: 30) {
              edges {
                node {
                  asset {
                    relayId
                  }
                }
              }
            }
          }
        }
      }
    `,
    ordersDataDataKey || null,
  )

  const totalAssetIdsInOrders = useMemo(() => {
    return (orders || []).flatMap(order =>
      order.item.assetQuantities
        ? getNodes(order.item.assetQuantities).map(
            assetQuantity => assetQuantity.asset.relayId,
          )
        : [order.item.relayId],
    )
  }, [orders])

  const totalAssetIdsInOrdersData = useMemo(() => {
    return (ordersData || []).flatMap((order, index) =>
      order.item?.assetQuantities
        ? getNodes(order.item.assetQuantities).map(
            assetQuantity => assetQuantity.asset.relayId,
          )
        : order.item?.asset
        ? [order.item.asset.relayId]
        : criteriaAssetIds?.[index]
        ? criteriaAssetIds[index]
        : [],
    )
  }, [criteriaAssetIds, ordersData])

  return [...totalAssetIdsInOrders, ...totalAssetIdsInOrdersData]
}
