import React from "react"

type Props = {
  className?: string
  width?: number
  height?: number
  fill?: string
}

export const KlaytnLogo = ({
  className,
  fill = "#707A83",
  width = 20,
  height = 20,
}: Props) => (
  <svg
    className={className}
    fill={fill}
    style={{ width, height }}
    viewBox="2 2 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.1524 5.95767L13.1545 11.9129L19.1524 17.8655C22.1128 14.4588 22.1128 9.3643 19.1524 5.95767Z"
      fill={fill}
    />
    <path
      d="M12.1651 21L17.9544 18.5992L12.1651 12.8526L6.37671 18.5992L12.1651 21Z"
      fill={fill}
    />
    <path
      d="M12.6468 3L7.38354 15.7014L11.6709 11.4168L17.9526 5.17857L12.6468 3Z"
      fill={fill}
    />
    <path
      d="M10.5615 4.54095L3 12.0635C3.03084 14.1459 3.76822 16.1286 5.09695 17.7176L10.5615 4.54095Z"
      fill={fill}
    />
  </svg>
)
