import React, { useCallback, useEffect, useRef, useState } from "react"
import { FlexColumn, Text, UnstyledButton } from "@opensea/ui-kit"
import { Button } from "@/design-system/Button"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import {
  MFA_STEPS,
  PHONE_AUTH_CODE_EXPIRATION,
  RESEND_CODE_TIMEOUT_SECONDS,
} from "@/features/os-wallet/components/constants"
import { useAuthenticateOSWallet } from "@/features/os-wallet/hooks/useOpenOSWalletAfterLogin"
import { useOSWalletAuth } from "@/features/os-wallet/hooks/useOSWalletAuth"
import { useTranslate } from "@/hooks/useTranslate"
import { AuthCodeExpiration } from "../AuthCodeExpiration.react"
import { OSWalletModalBody } from "./OSWalletModalBody.react"
import { SetupAuthCode } from "./SetupAuthCode.react"
import { WalletMfaEnrollmentModalProps } from "./WalletMfaEnrollmentModal.react"

type Props = WalletMfaEnrollmentModalProps & {
  phoneNumber: string
}

export const WalletPhoneCodeModal = ({
  accessToken,
  phoneNumber,
  privyId,
}: Props) => {
  const t = useTranslate("common")
  const authenticateOSWallet = useAuthenticateOSWallet()
  const { onPrevious } = useMultiStepFlowContext()
  const { initMfaEnrollmentWithSms, submitMfaEnrollmentWithSms } =
    useOSWalletAuth()
  const [value, setValue] = useState<string>("")
  const [error, setError] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)
  const [expired, setExpired] = useState<boolean>(false)
  const [canResendCode, setCanResendCode] = useState<boolean>(true)
  const resetExpirationRef = useRef<(() => void) | null>(null)
  const authCodeExpiration = PHONE_AUTH_CODE_EXPIRATION
  const showAuthCodeExpiration =
    authCodeExpiration.minutes || authCodeExpiration.seconds

  useEffect(() => {
    if (canResendCode) {
      return
    }
    const timeout = setTimeout(() => {
      setCanResendCode(true)
    }, RESEND_CODE_TIMEOUT_SECONDS * 1000)
    return () => clearTimeout(timeout)
  }, [canResendCode])

  const getErrorMessage = (reason: string) => {
    if (reason === "MfaVerificationFailed") {
      return t(
        "wallet.opensea.phoneMfa.error.verificationFailed",
        "Verification failed",
      )
    } else if (reason === "MaxMfaRetries") {
      return t(
        "wallet.opensea.phoneMfa.error.maxRetries",
        "Max retries reached",
      )
    } else if (reason === "MfaTimeout") {
      return t("wallet.opensea.phoneMfa.error.timeout", "Timeout reached")
    } else {
      return t(
        "wallet.opensea.phoneMfa.error.unknown",
        "{{phoneNumber}} may already be in use. Please verify your number is correct or log in to another account",
        {
          phoneNumber: (
            <Text.Body
              className="whitespace-nowrap"
              color="error"
              size="tiny"
              weight="semibold"
            >
              {phoneNumber}
            </Text.Body>
          ),
        },
      )
    }
  }

  const handleAuthCode = async (authCode: string) => {
    setLoading(true)
    try {
      await submitMfaEnrollmentWithSms(phoneNumber, authCode)
      await authenticateOSWallet({
        accessToken,
        privyId,
        shouldOpenWalletAfterAuth: false,
      })
    } catch (error) {
      setError(error.reason)
    } finally {
      setLoading(false)
    }
  }

  const handleResendAuthCode = async () => {
    if (!canResendCode) {
      return
    }
    setExpired(false)
    setCanResendCode(false)
    setError("")

    try {
      await initMfaEnrollmentWithSms(phoneNumber)
    } catch (error) {
      setError(error.reason)
    } finally {
      resetExpirationRef.current?.()
    }
  }

  const handleAuthCodeExpired = useCallback(() => {
    setExpired(true)
  }, [])

  return (
    <OSWalletModalBody
      className="justify-between pb-2"
      showLogo={false}
      step={3}
      steps={MFA_STEPS["phone"]}
      title={t("wallet.opensea.enterCode", "Enter Code")}
      onPrevious={onPrevious}
    >
      <FlexColumn className="items-center gap-4 text-center">
        <SetupAuthCode
          disabled={expired || loading}
          error={!!error}
          value={value}
          onComplete={handleAuthCode}
        />
        {error ? (
          <Text.Body color="error" size="tiny">
            {getErrorMessage(error)}
          </Text.Body>
        ) : (
          <Text.Body color="secondary" size="tiny">
            {t(
              "wallet.opensea.phone.oneTimeCode",
              "A one time authentication code has been sent to {{phoneNumber}}.",
              {
                phoneNumber: (
                  <Text.Body
                    className="whitespace-nowrap"
                    color="secondary"
                    size="tiny"
                    weight="semibold"
                  >
                    {phoneNumber}
                  </Text.Body>
                ),
              },
            )}
          </Text.Body>
        )}
      </FlexColumn>

      <FlexColumn className="gap-4 text-center">
        <Button
          variant="secondary"
          onClick={async () => {
            setValue(await navigator.clipboard.readText())
          }}
        >
          {t("wallet.opensea.pasteFromClipboard", "Paste from clipboard")}
        </Button>
        {showAuthCodeExpiration &&
          (expired ? (
            <UnstyledButton className="mx-auto" onClick={handleResendAuthCode}>
              <Text.Body color="interactive-primary-styles" size="tiny">
                {t(
                  "wallet.opensea.authCodeExpired",
                  "Code expired, please request a new code.",
                )}
              </Text.Body>
            </UnstyledButton>
          ) : (
            <span>
              <Text.Body color="secondary" size="tiny">
                {t("wallet.opensea.expiresIn", "Expires in ")}
                <AuthCodeExpiration
                  minutes={authCodeExpiration.minutes}
                  seconds={authCodeExpiration.seconds}
                  size="tiny"
                  onEnd={handleAuthCodeExpired}
                  onReset={reset => (resetExpirationRef.current = reset)}
                />
                .&nbsp;
                <UnstyledButton onClick={handleResendAuthCode}>
                  <Text.Body color="interactive-primary-styles" size="tiny">
                    {t("wallet.opensea.resendCode", "Resend code")}
                  </Text.Body>
                </UnstyledButton>
              </Text.Body>
            </span>
          ))}
      </FlexColumn>
    </OSWalletModalBody>
  )
}
