import React, { MutableRefObject, useCallback, useEffect, useRef } from "react"
import {
  QueryRenderer,
  GraphQLTaggedNode,
  useRelayEnvironment,
  FetchPolicy,
} from "react-relay"
import { OperationType } from "relay-runtime"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { useAppContext } from "@/hooks/useAppContext"
import { handleError } from "./error"
import { GraphQLProps } from "./graphql"
import type { GraphQLComponent } from "./GraphQLPage.react"

type GraphQLRendererProps<TOperation extends OperationType, TProps> = {
  component: GraphQLComponent<TOperation, TProps>
  handleError: typeof handleError
  props: TProps & Pick<Partial<GraphQLProps<TOperation>>, "variables">
  query: GraphQLTaggedNode
  fetchPolicy?: FetchPolicy
}

export const GraphQLRenderer = <TOperation extends OperationType, TProps>({
  component: Component,
  handleError,
  props,
  query,
  fetchPolicy = "store-or-network",
}: GraphQLRendererProps<TOperation, TProps>) => {
  const retryRef = useRef() as MutableRefObject<(() => void) | null>
  const environment = useRelayEnvironment()
  const { refetchPublisher } = useAppContext()
  const { login, logout } = useWallet()

  const refetch = useCallback(() => {
    refetchPublisher.publish()
  }, [refetchPublisher])

  useEffect(() => {
    const unsub = refetchPublisher.subscribe(() => retryRef.current?.())
    return () => {
      unsub()
    }
  }, [refetchPublisher])

  return (
    <QueryRenderer<TOperation>
      environment={environment}
      fetchPolicy={fetchPolicy}
      query={query}
      render={({ error, props: data, retry }) => {
        retryRef.current = retry
        if (error) {
          handleError(error, login, logout)
        }

        return (
          <Component
            {...props}
            data={data}
            error={error}
            refetch={refetch}
            variables={props.variables ?? ({} as TOperation["variables"])}
          />
        )
      }}
      variables={props.variables ?? {}}
    />
  )
}
