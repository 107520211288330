import React, { forwardRef, useRef, useState } from "react"
import useMergedRef from "@react-hook/merged-ref"
import styled, { css } from "styled-components"
import { Tooltip, TooltipProps } from "@/design-system/Tooltip"
import { useMountEffect } from "@/hooks/useMountEffect"

const getIsOverflowed = (element: HTMLDivElement | null) => {
  if (!element) {
    return false
  }

  const { clientHeight, clientWidth, scrollHeight, scrollWidth } = element

  return scrollHeight > clientHeight || scrollWidth > clientWidth
}

export type OverflowProps = {
  children: React.ReactNode
  className?: string
  as?: keyof JSX.IntrinsicElements | React.ComponentType
  lines?: number
  /**
   * breakWord: Instead of breaking at the word, leaving white-space between it
   * and the next sibling it breaks at the furthest character.
   * This should be paired with a set number of lines prop.
   */
  breakWord?: boolean
  /**
   * Prevents click event from bubbling up when the content is overflowed.
   * Useful for allowing tap to reveal tooltip on mobile when content is in an
   * interactive element.
   */
  preventClickThrough?: boolean
  overrides?: {
    Tooltip?: Partial<TooltipProps>
  }
}

export const Overflow = forwardRef<HTMLDivElement, OverflowProps>(
  function Overflow(
    {
      children,
      className,
      as,
      lines,
      breakWord,
      preventClickThrough,
      overrides = {},
    },
    ref,
  ) {
    const containerRef = useRef<HTMLDivElement>(null)
    const [overflowed, setOverflowed] = useState(false)

    useMountEffect(() => {
      const isOverflowed = getIsOverflowed(containerRef.current)
      setOverflowed(isOverflowed)
    })

    return (
      <Tooltip content={children} disabled={!overflowed} {...overrides.Tooltip}>
        <OverflowContainer
          $breakWord={breakWord}
          $lines={lines}
          as={as}
          className={className}
          ref={useMergedRef(ref, containerRef)}
          tabIndex={!overflowed ? -1 : 0}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            if (overflowed && preventClickThrough) {
              event.preventDefault()
              event.stopPropagation()
            }
          }}
          onMouseOver={() => {
            const isOverflowed = getIsOverflowed(containerRef.current)
            setOverflowed(isOverflowed)
          }}
        >
          {children}
        </OverflowContainer>
      </Tooltip>
    )
  },
)

export const OverflowContainer = styled.div<{
  $lines?: number
  $breakWord?: boolean
}>`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${props =>
    props.$lines &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: ${props.$lines};
      -webkit-box-orient: vertical;
      white-space: normal;
    `}

  ${props =>
    props.$breakWord &&
    css`
      // Instead of breaking at the word, leaving white-space between it
      // and the next sibling it breaks at the furthest character.
      // This should be paired with a set number of lines prop.
      overflow-wrap: anywhere;
      word-break: break-all;
    `}
`
