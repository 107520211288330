import { rgba } from "polished"
import styled, { css } from "styled-components"
import { Z_INDEX } from "@/constants/zIndex"
import { classNames, media } from "@/styles/styleUtils"
import { Block, BlockProps } from "../Block"

type HideCarouselSlidesBehindBlockType = Omit<BlockProps, "className"> & {
  className: "left" | "right"
}

/**
 * This component is used as a container overlay to hide the slides showing
 * when carousel container removes hidden overflow. It is a hack to give the same
 * carousel effect while allowing each slides box-shadow to bleed over the container.
 */
export const HideCarouselSlidesBehindBlock = styled(
  Block,
).attrs<HideCarouselSlidesBehindBlockType>(props => ({
  className: classNames("absolute top-0", props.className),
  width: { _: "16px", sm: "32px", xxl: "64px" },
  style: { zIndex: Z_INDEX.ITEM_CARD_SELECT_ICON, ...props.style },
}))<HideCarouselSlidesBehindBlockType>`
  /* -4px used to extend block to cover drop-shadow vertically */
  margin-top: -4px;
  /* 20px used to extend block to cover drop-shadow vertically */
  height: calc(100% + 20px);

  &.left {
    left: 0;
    transform: translateX(-100%);

    ${media({
      xs: css`
        background: linear-gradient(
          to left,
          ${props => rgba(props.theme.colors.base1, 0)},
          ${props => props.theme.colors.base1} 50%
        );
      `,
      sm: css`
        background: linear-gradient(
          to left,
          ${props => rgba(props.theme.colors.base1, 0)},
          ${props => props.theme.colors.base1} 25%
        );
      `,
      md: css`
        background: linear-gradient(
          to left,
          ${props => rgba(props.theme.colors.base1, 0)},
          ${props => props.theme.colors.base1} 40%
        );
      `,
      xxl: css`
        background: linear-gradient(
          to left,
          ${props => rgba(props.theme.colors.base1, 0)},
          ${props => props.theme.colors.base1} 25%
        );
      `,
    })}
  }

  &.right {
    right: 0;
    transform: translateX(100%);

    ${media({
      xs: css`
        background: linear-gradient(
          to right,
          ${props => rgba(props.theme.colors.base1, 0)},
          ${props => props.theme.colors.base1} 50%
        );
      `,
      sm: css`
        background: linear-gradient(
          to right,
          ${props => rgba(props.theme.colors.base1, 0)},
          ${props => props.theme.colors.base1} 25%
        );
      `,
      md: css`
        background: linear-gradient(
          to right,
          ${props => rgba(props.theme.colors.base1, 0)},
          ${props => props.theme.colors.base1} 40%
        );
      `,
      xxl: css`
        background: linear-gradient(
          to right,
          ${props => rgba(props.theme.colors.base1, 0)},
          ${props => props.theme.colors.base1} 25%
        );
      `,
    })}
  }
`
