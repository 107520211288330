import React, { useEffect } from "react"
import { AccountBannedPage } from "@/components/pages/AccountBannedPage"
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from "@/constants/index"
import { useOSWalletTrigger } from "@/features/os-wallet/hooks/useOSWalletTrigger"
import { useIsBanned } from "@/hooks/useIsBanned"
import { useRouter } from "@/hooks/useRouter"

type Props = {
  children: React.ReactNode
}

export const AccountBannedGate = ({ children }: Props) => {
  const router = useRouter()
  const isActiveWalletBanned = useIsBanned()
  const { closeOSWallet } = useOSWalletTrigger()

  // Close OS wallet (if open) when user is banned
  useEffect(() => {
    if (isActiveWalletBanned) {
      closeOSWallet()
    }
  }, [closeOSWallet, isActiveWalletBanned])

  if (
    isActiveWalletBanned &&
    ![PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL].includes(router.pathname)
  ) {
    return <AccountBannedPage />
  }

  return <>{children}</>
}
