/**
 * @generated SignedSource<<51e44bc2c279f541ffe4211e1589d0f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GaslessCancellationProcessingModal_items$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"StackedAssetMedia_assets">;
  readonly " $fragmentType": "GaslessCancellationProcessingModal_items";
}>;
export type GaslessCancellationProcessingModal_items$key = ReadonlyArray<{
  readonly " $data"?: GaslessCancellationProcessingModal_items$data;
  readonly " $fragmentSpreads": FragmentRefs<"GaslessCancellationProcessingModal_items">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "GaslessCancellationProcessingModal_items",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StackedAssetMedia_assets"
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};

(node as any).hash = "a4d1d7401d6f6f835656a145283e63eb";

export default node;
