import React, { useCallback, useState } from "react"
import { Text } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { Item } from "@/design-system/Item"
import { useTranslate } from "@/hooks/useTranslate"
import {
  useTrackBlockchainAttempt,
  useTrackBlockchainError,
} from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/hooks"
import { CreateOrderAction_data$key } from "@/lib/graphql/__generated__/CreateOrderAction_data.graphql"
import { bn } from "@/lib/helpers/numberUtils"
import { OrderDataHeader } from "../orders/OrderDataHeader"
import { useTransaction } from "../useTransaction"
import {
  BaseBlockchainActionProps,
  BlockchainActionModalContent,
} from "./BlockchainActionModalContent.react"
import { useBlockchainActionProgress } from "./useBlockchainActionProgress"
import { useHandleBlockchainActions } from "./useHandleBlockchainActions"

type Props = BaseBlockchainActionProps & {
  dataKey: CreateOrderAction_data$key
}

export const CreateOrderAction = ({ dataKey, onEnd, onNext, title }: Props) => {
  const t = useTranslate("components")
  const actions = useFragment(
    graphql`
      fragment CreateOrderAction_data on CreateOrderActionType
      @relay(plural: true) {
        __typename
        orderData {
          item {
            ... on AssetQuantityDataType {
              quantity
            }
          }
          side
          isCounterOrder
          perUnitPrice {
            unit
            symbol
          }
          ...OrderDataHeader_order
        }
        ...useHandleBlockchainActions_create_order
      }
    `,
    dataKey,
  )

  const [actionIndex, setActionIndex] = useState(0)
  const [transactionHash, setTransactionHash] = useState<string>()

  const { createOrder } = useHandleBlockchainActions()
  const { pollTransaction } = useTransaction()
  const trackBlockchainAttempt = useTrackBlockchainAttempt()
  const [trackBlockchainError, errorTags] = useTrackBlockchainError()

  const executeAction = useCallback(async () => {
    if (actionIndex < actions.length) {
      trackBlockchainAttempt({ blockchainAction: "coreAction" })
      const {
        orders: {
          create: { order, transaction },
        },
      } = await createOrder(actions[actionIndex])

      if (transaction) {
        setTransactionHash(transaction.transactionHash)

        await pollTransaction({
          chain: transaction.chain.identifier,
          transactionHash: transaction.transactionHash,
        })

        setTransactionHash("")
      }

      const onNextProps = {
        createdOrder: order,
        transaction: transaction
          ? {
              transactionHash: transaction.transactionHash,
              chain: transaction.chain.identifier,
              blockExplorerLink: transaction.blockExplorerLink,
            }
          : undefined,
      }

      onNext?.(onNextProps)

      if (actionIndex === actions.length - 1) {
        onEnd(onNextProps)
      } else {
        setActionIndex(index => index + 1)
      }
    }
  }, [
    actionIndex,
    actions,
    createOrder,
    pollTransaction,
    onEnd,
    onNext,
    trackBlockchainAttempt,
  ])

  const action = actions[actionIndex]

  const { attemptAction, progress } = useBlockchainActionProgress({
    executeAction,
    action,
    onError: trackBlockchainError({ blockchainAction: "coreAction" }),
    errorTags,
  })

  const {
    orderData: { side, isCounterOrder: isFulfill, perUnitPrice, item },
  } = action

  const quantity = item?.quantity ?? 1
  const makeOfferTitle = t("blockchain.maker.offer.newTitle", "Approve offer")

  const defaultTitle = isFulfill
    ? side === "ASK"
      ? t("fulfillAction.approve.title", "Complete checkout")
      : t("fulfillAction.acceptOffer.title", "Accept your offer")
    : side === "ASK"
    ? t(
        "blockchain.approve.listing",
        {
          0: "Approve listings",
          one: "Approve listing",
          other: "Approve listings",
        },
        { count: actions.length },
      )
    : makeOfferTitle

  let actionText: React.ReactNode

  if (isFulfill) {
    actionText =
      side === "ASK"
        ? t(
            "blockchain.create.fulfillListing.prompt",
            "You'll be asked to review and confirm this sale from your wallet.",
          )
        : t(
            "blockchain.create.fulfillOffer.prompt",
            "You'll be asked to review and confirm this purchase from your wallet.",
          )
  } else {
    actionText =
      side === "ASK"
        ? t(
            "blockchain.create.listing.prompt",
            {
              0: "You'll be asked to review and confirm these listings from your wallet.",
              one: "You'll be asked to review and confirm this listing from your wallet.",
              other:
                "You'll be asked to review and confirm these listings from your wallet.",
            },
            { count: actions.length },
          )
        : t(
            "blockchain.create.offer.prompt",
            "You'll be asked to review and confirm this offer from your wallet.",
          )
  }

  return (
    <BlockchainActionModalContent>
      <BlockchainActionModalContent.Header>
        <BlockchainActionModalContent.Title>
          {title ?? defaultTitle}
        </BlockchainActionModalContent.Title>
      </BlockchainActionModalContent.Header>
      <BlockchainActionModalContent.Body>
        <OrderDataHeader
          dataKey={action.orderData}
          isLoading={Boolean(progress)}
          renderContentExtra={
            actions.length > 1
              ? () => (
                  <>
                    <Item.Description>
                      <Text.Body
                        asChild
                        data-testid="create-order-price"
                        size="small"
                      >
                        <div>
                          {perUnitPrice.unit} {perUnitPrice.symbol}{" "}
                          {bn(quantity).isGreaterThan(1) && <>(x{quantity})</>}
                        </div>
                      </Text.Body>
                    </Item.Description>
                  </>
                )
              : undefined
          }
          renderExtra={
            actions.length > 1
              ? () => (
                  <Item.Side>
                    <Item.Title data-testid="create-order-progress">
                      {t(
                        "progressText",
                        "{{currentNumber}} of {{totalNumber}}",
                        {
                          currentNumber: actionIndex + 1,
                          totalNumber: actions.length,
                        },
                      )}
                    </Item.Title>
                  </Item.Side>
                )
              : undefined
          }
          transactionHash={transactionHash}
        />
        <BlockchainActionModalContent.Body.Separator />

        <BlockchainActionModalContent.Body.GoToWallet />
        <BlockchainActionModalContent.Body.Text>
          {actionText}
        </BlockchainActionModalContent.Body.Text>
        {!progress && (
          <BlockchainActionModalContent.Body.Button onClick={attemptAction}>
            {t("blockchain.create.cta", "Continue")}
          </BlockchainActionModalContent.Body.Button>
        )}
      </BlockchainActionModalContent.Body>
    </BlockchainActionModalContent>
  )
}
