import React, { Suspense } from "react"
import { CenterAligned, Skeleton } from "@opensea/ui-kit"
import {
  graphql,
  PreloadedQuery,
  useFragment,
  usePreloadedQuery,
} from "react-relay"
import { NetworkBalanceDropdownQuery } from "@/lib/graphql/__generated__/NetworkBalanceDropdownQuery.graphql"
import { NetworkTotalFundsUsd_data$key } from "@/lib/graphql/__generated__/NetworkTotalFundsUsd_data.graphql"
import { NETWORK_BALANCE_DROPDOWN_QUERY } from "../NetworkBalanceDropdown.react"
import { NetworkTotalFundsUsdDisplay } from "./NetworkTotalFundsUsdDisplay.react"

type NetworkTotalFundsUsdBaseProps = {
  queryReference: PreloadedQuery<NetworkBalanceDropdownQuery>
  className?: string
}

const NetworkTotalFundsUsdBase = ({
  queryReference,
  className,
}: NetworkTotalFundsUsdBaseProps) => {
  const data = usePreloadedQuery(NETWORK_BALANCE_DROPDOWN_QUERY, queryReference)

  const {
    wallet: { funds },
  } = useFragment<NetworkTotalFundsUsd_data$key>(
    graphql`
      fragment NetworkTotalFundsUsd_data on Query
      @argumentDefinitions(
        address: { type: "AddressScalar!" }
        chain: { type: "ChainScalar!" }
      ) {
        wallet(address: $address) {
          funds(chain: $chain) {
            ...NetworkTotalFundsUsdDisplay_funds
          }
        }
      }
    `,
    data,
  )

  return <NetworkTotalFundsUsdDisplay className={className} funds={funds} />
}

const NetworkTotalFundsUsdSkeleton = () => (
  <CenterAligned className="h-6">
    <Skeleton.Line className="h-4 w-[100px]" />
  </CenterAligned>
)

type NetworkTotalFundsUsdProps = Omit<
  NetworkTotalFundsUsdBaseProps,
  "queryReference"
> & {
  queryReference: PreloadedQuery<NetworkBalanceDropdownQuery> | undefined | null
}

export const NetworkTotalFundsUsd = ({
  queryReference,
  ...restProps
}: NetworkTotalFundsUsdProps) => {
  if (!queryReference) {
    return <NetworkTotalFundsUsdSkeleton />
  }

  return (
    <Suspense fallback={<NetworkTotalFundsUsdSkeleton />}>
      <NetworkTotalFundsUsdBase
        queryReference={queryReference}
        {...restProps}
      />
    </Suspense>
  )
}
