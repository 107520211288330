import { useCallback } from "react"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { useChains } from "@/hooks/useChains"
import { BridgeOrWrapFormQuery$variables } from "@/lib/graphql/__generated__/BridgeOrWrapFormQuery.graphql"
import { isPolygon, getEthereumChain } from "@/lib/helpers/chainUtils"

export const useGetQueryArgs = (): (() =>
  | BridgeOrWrapFormQuery$variables
  | undefined) => {
  const { wallet } = useWallet()
  const { chain } = useWallet()
  const { getNativeCurrencySymbol, getWrappedCurrencySymbol } = useChains()

  return useCallback((): BridgeOrWrapFormQuery$variables | undefined => {
    if (!chain || !wallet.address) {
      return undefined
    }

    const baseCurrencySymbol = isPolygon(chain)
      ? "ETH"
      : getNativeCurrencySymbol(chain)
    const baseCurrencyChain = isPolygon(chain) ? getEthereumChain() : chain

    return {
      address: wallet.address,
      wrappedCurrencySymbol: getWrappedCurrencySymbol(chain),
      wrappedCurrencyChain: chain,
      baseCurrencySymbol,
      baseCurrencyChain,
      baseCurrencyIsChainNativeCurrency:
        baseCurrencySymbol === getNativeCurrencySymbol(chain),
    }
  }, [chain, getNativeCurrencySymbol, getWrappedCurrencySymbol, wallet.address])
}
