import { ChainIdentifier } from "@/hooks/useChains/types"
import { getBlockchainActionsCancelAllOrdersActionQuery } from "@/lib/graphql/__generated__/getBlockchainActionsCancelAllOrdersActionQuery.graphql"
import { fetch, graphql } from "@/lib/graphql/graphql"

export const getCancelAllOrdersActions = async (chain: ChainIdentifier) => {
  const [data] = await fetch<getBlockchainActionsCancelAllOrdersActionQuery>(
    graphql`
      query getBlockchainActionsCancelAllOrdersActionQuery(
        $chain: ChainScalar!
      ) {
        blockchain {
          bulkCancelAllOrdersActions(chain: $chain) {
            ...useHandleBlockchainActions_cancel_all_orders
          }
        }
      }
    `,
    { chain },
  )
  return data.blockchain.bulkCancelAllOrdersActions
}
