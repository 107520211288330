import React from "react"
import { Text } from "@opensea/ui-kit"
import { Block, BlockProps } from "@/design-system/Block"
import { useTheme } from "@/design-system/Context"

type RarityIconProps = BlockProps & {
  size?: number
  variant?: "default" | "colored"
  compact?: boolean
  overrides?: {
    ColoredRarityIcon?: BlockProps
  }
}

export const RarityIcon = ({
  size = 14,
  variant = "default",
  compact = true,
  overrides = {},
  ...blockProps
}: RarityIconProps) => {
  return (
    <Block
      alignItems="center"
      display="inline-flex"
      height={size}
      justifyContent="center"
      width={size}
      {...blockProps}
      className="relative"
    >
      <Text.Body
        className="absolute text-center text-primary"
        size={compact ? "tiny" : "small"}
        style={{
          transform:
            variant === "default" ? undefined : `translate(0, -${size}px)`,
          opacity: variant === "default" ? 1 : 0,
          transition: "transform 0.2s ease-out, opacity 0.2s ease-out",
          width: size,
        }}
        weight="semibold"
      >
        #
      </Text.Body>

      <ColoredRarityIcon
        overrides={overrides.ColoredRarityIcon}
        size={size}
        variant={variant}
      />
    </Block>
  )
}

type ColoredRarityIconProps = Pick<RarityIconProps, "size" | "variant"> & {
  className?: string
  overrides?: BlockProps
}

const ColoredRarityIcon = ({
  size = 14,
  variant = "default",
  className,
  overrides = {},
}: ColoredRarityIconProps) => {
  const { theme } = useTheme()

  return (
    <Block
      as="svg"
      className={className}
      fill="none"
      height={size}
      style={{
        transform:
          variant === "colored" ? undefined : `translate(0, ${size}px)`,
        opacity: variant === "colored" ? 1 : 0,
        transition: "transform 0.2s ease-out, opacity 0.2s ease-out",
      }}
      viewBox="0 0 20 20"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...overrides}
    >
      <g clipPath="url(#clip0_951_820)">
        <path
          clipRule="evenodd"
          d="M16.5661 16.566C20.1924 12.9397 20.1924 7.06031 16.5661 3.43401C12.9398 -0.192288 7.06045 -0.192288 3.43416 3.43401C-0.192142 7.06031 -0.192142 12.9397 3.43416 16.566C7.06045 20.1923 12.9398 20.1923 16.5661 16.566ZM9.99994 4.37201L4.37195 10L9.99994 15.628L15.6279 10L9.99994 4.37201Z"
          fill="url(#paint0_linear_951_820)"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_951_820"
          x1="11.5"
          x2="2"
          y1="20"
          y2="6"
        >
          {theme === "light" ? (
            <>
              <stop stopColor="#FF479F" />
              <stop offset="0.598958" stopColor="#6784F9" />
              <stop offset="1" stopColor="#00C1FF" />
            </>
          ) : (
            <>
              <stop stopColor="#FF5282" />
              <stop offset="0.59375" stopColor="#A78DEE" />
              <stop offset="1" stopColor="#5AD3FF" />
            </>
          )}
        </linearGradient>
        <clipPath id="clip0_951_820">
          <rect fill="white" height="20" width="20" />
        </clipPath>
      </defs>
    </Block>
  )
}
