import React, { useCallback, useState } from "react"
import { graphql, useFragment } from "react-relay"
import { SwapDataHeader } from "@/components/blockchain/swaps/SwapDataHeader"
import { useTranslate } from "@/hooks/useTranslate"
import {
  useTrackBlockchainAttempt,
  useTrackBlockchainError,
} from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/hooks"
import { CreateSwapOrderAction_data$key } from "@/lib/graphql/__generated__/CreateSwapOrderAction_data.graphql"
import {
  BaseBlockchainActionProps,
  BlockchainActionModalContent,
} from "./BlockchainActionModalContent.react"
import { useBlockchainActionProgress } from "./useBlockchainActionProgress"
import { useHandleBlockchainActions } from "./useHandleBlockchainActions"

type Props = BaseBlockchainActionProps & {
  dataKey: CreateSwapOrderAction_data$key
}

export const CreateSwapOrderAction = ({
  dataKey,
  onEnd,
  onNext,
  title,
}: Props) => {
  const t = useTranslate("components")
  const actions = useFragment(
    graphql`
      fragment CreateSwapOrderAction_data on CreateSwapOrderActionType
      @relay(plural: true) {
        __typename
        swapData {
          ...SwapDataHeader_swap
        }
        ...useHandleBlockchainActions_create_swap_order
      }
    `,
    dataKey,
  )

  const [actionIndex, setActionIndex] = useState(0)

  const { createSwapOrder } = useHandleBlockchainActions()
  const trackBlockchainAttempt = useTrackBlockchainAttempt()
  const [trackBlockchainError, errorTags] = useTrackBlockchainError()

  const executeAction = useCallback(async () => {
    if (actionIndex < actions.length) {
      trackBlockchainAttempt({ blockchainAction: "coreAction" })
      const {
        swaps: { create: createdSwap },
      } = await createSwapOrder(actions[actionIndex])

      const onNextProps = {
        createdSwapOrder: createdSwap,
      }

      onNext?.(onNextProps)

      if (actionIndex === actions.length - 1) {
        onEnd(onNextProps)
      } else {
        setActionIndex(index => index + 1)
      }
    }
  }, [
    actionIndex,
    actions,
    createSwapOrder,
    onEnd,
    onNext,
    trackBlockchainAttempt,
  ])

  const action = actions[actionIndex]

  const { attemptAction, progress } = useBlockchainActionProgress({
    executeAction,
    action,
    onError: trackBlockchainError({ blockchainAction: "coreAction" }),
    errorTags,
  })

  return (
    <BlockchainActionModalContent>
      <BlockchainActionModalContent.Header>
        <BlockchainActionModalContent.Title>
          {title ?? t("blockchain.createSwap.title", "Send deal")}
        </BlockchainActionModalContent.Title>
      </BlockchainActionModalContent.Header>
      <BlockchainActionModalContent.Body>
        <SwapDataHeader data={action.swapData} />

        <BlockchainActionModalContent.Body.Separator />

        <BlockchainActionModalContent.Body.GoToWallet />
        <BlockchainActionModalContent.Body.Text>
          {t(
            "blockchain.createSwap.prompt",
            "You'll be asked to review and confirm this deal from your wallet.",
          )}
        </BlockchainActionModalContent.Body.Text>
        {!progress && (
          <BlockchainActionModalContent.Body.Button onClick={attemptAction}>
            {t("blockchain.createSwap.cta", "Send")}
          </BlockchainActionModalContent.Body.Button>
        )}
      </BlockchainActionModalContent.Body>
    </BlockchainActionModalContent>
  )
}
