import { groupBy, mapValues, orderBy } from "lodash"
import { graphql, _FragmentRefs } from "relay-runtime"
import { utils_calculateFundsBalance$data } from "@/lib/graphql/__generated__/utils_calculateFundsBalance.graphql"
import { WalletFunds_data$data } from "@/lib/graphql/__generated__/WalletFunds_data.graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { bn } from "@/lib/helpers/numberUtils"

const FUNDS_PREDEFINED_ORDER = ["ETH", "WETH", "USDC", "SOL", "LSOL"]

type WalletFund = NonNullable<WalletFunds_data$data["wallet"]>["funds"][number]

export const orderWalletFunds = <
  T extends Pick<WalletFund, "chain" | "symbol">,
>(
  funds: ReadonlyArray<T>,
) => {
  const bySymbol = groupBy(funds, o => o.symbol)
  const bySymbolAndChain = mapValues(bySymbol, v => orderBy(v, ["chain"]))

  return Object.keys(bySymbolAndChain)
    .sort((a, b) => {
      const o1 = FUNDS_PREDEFINED_ORDER.indexOf(a)
      const o2 = FUNDS_PREDEFINED_ORDER.indexOf(b)
      if (o1 === -1 && o2 === -1) {
        return a.localeCompare(b)
      }
      if (o1 === -1) {
        return 1
      }
      if (o2 === -1) {
        return -1
      }
      return o1 - o2
    })
    .reduce(
      (acc, symbol) => [...acc, ...bySymbolAndChain[symbol]],
      [] as ReadonlyArray<T>,
    )
}

const readFundsBalance = inlineFragmentize<utils_calculateFundsBalance$data>(
  graphql`
    fragment utils_calculateFundsBalance on WalletFundsType @inline {
      usdPrice
      quantity
    }
  `,
  identifiers => identifiers,
)

export const calculateFundsBalance = (
  funds: ReadonlyArray<
    | utils_calculateFundsBalance$data
    | _FragmentRefs<"utils_calculateFundsBalance">
  >,
) => {
  return funds.reduce((sum, fund) => {
    const { usdPrice, quantity } = readFundsBalance(fund)
    return usdPrice ? sum.plus(bn(quantity).times(usdPrice)) : sum
  }, bn(0))
}
