import React, { Suspense } from "react"
import { useLazyLoadQuery } from "react-relay"
import { usePrevious, useUpdateEffect } from "react-use"
import { IFrame } from "@/components/common/IFrame.react"
import { ModalLoader } from "@/components/common/ModalLoader.react"
import { AssetSuccessModalContent } from "@/components/modals/AssetSuccessModalContent.react"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { Modal } from "@/design-system/Modal"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { useChains } from "@/hooks/useChains"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { useItemAnalyticsContext } from "@/lib/analytics/ItemAnalyticsContext"
import { MoonPayCheckoutModalQuery } from "@/lib/graphql/__generated__/MoonPayCheckoutModalQuery.graphql"
import { ChainIdentifier } from "@/lib/graphql/__generated__/QuickBuyModalContentAction_order.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { useMoonPayNftTxStatus } from "../../utils/useMoonPayNftTxStatus"

type MoonPayCheckoutModalComponentProps = {
  order: string
  assetIDs: string[]
  chainIdentifier: ChainIdentifier
  onClose: () => unknown
  onTransactionConfirmed?: () => unknown
}

const MoonPayCheckoutModalComponent = ({
  order,
  assetIDs,
  chainIdentifier,
  onClose,
  onTransactionConfirmed,
}: MoonPayCheckoutModalComponentProps) => {
  const { chain, switchChain } = useWallet()
  const { getChain } = useChains()
  const t = useTranslate("moonpay")
  const { onPrevious, onReplace } = useMultiStepFlowContext()
  const { tracker } = useItemAnalyticsContext()

  const data = useLazyLoadQuery<MoonPayCheckoutModalQuery>(
    graphql`
      query MoonPayCheckoutModalQuery($order: OrderRelayID!) {
        moonpay {
          moonpayFiatCheckoutWidgetData(order: $order) {
            url
            externalTransactionId
          }
        }
      }
    `,
    { order },
    { fetchPolicy: "network-only" },
  )

  if (!data.moonpay.moonpayFiatCheckoutWidgetData) {
    throw new Error("MoonPay NFT checkout widget data is not defined")
  }

  const { url, externalTransactionId } =
    data.moonpay.moonpayFiatCheckoutWidgetData

  const { nftTransaction, cryptoTransaction } = useMoonPayNftTxStatus({
    externalTransactionId,
  })

  const analyticsParams = {
    orderId: order,
    widgetUrl: url,
    externalTransactionId,
  }

  const prevCryptoTransaction = usePrevious(cryptoTransaction)
  useUpdateEffect(() => {
    if (
      cryptoTransaction !== undefined &&
      prevCryptoTransaction === undefined
    ) {
      tracker.trackStartMoonPayNftTx(analyticsParams)
    }

    if (
      cryptoTransaction?.status === "failed" &&
      prevCryptoTransaction?.status !== "failed"
    ) {
      tracker.trackMoonPayNftTxFail(analyticsParams)
    }

    if (nftTransaction?.transactionHash) {
      tracker.trackMoonPayNftTxSuccess({
        ...analyticsParams,
        transactionHash: nftTransaction.transactionHash,
      })
      onReplace(
        <AssetSuccessModalContent
          mode="bought"
          transaction={{ transactionHash: nftTransaction.transactionHash }}
          variables={{ assetIDs }}
          onClose={onClose}
          onTransactionConfirmed={onTransactionConfirmed}
        />,
      )
    }
  }, [nftTransaction, cryptoTransaction])

  useMountEffect(() => {
    tracker.trackOpenMoonPayCheckoutModal(analyticsParams)

    // Allow users option of switchChain or Moonpay if insufficient amount
    const maybeSwitchChain = async () => {
      if (chain && chain !== chainIdentifier) {
        return await switchChain(getChain(chainIdentifier))
      }
    }
    // Give user option to switch to correct chain if on incorrect one
    maybeSwitchChain()
  })

  return (
    <>
      <Modal.Header onPrevious={onPrevious}>
        <Modal.Header.Title>
          {t("checkoutModal.title", "Checkout with MoonPay")}
        </Modal.Header.Title>
      </Modal.Header>

      <Modal.Body className="mb-2">
        <Block height="min(max(calc(100vh - 254px), 700px), 1000px)">
          <IFrame
            allow="accelerometer; autoplay; camera; gyroscope; payment"
            url={url}
          />
        </Block>
      </Modal.Body>
    </>
  )
}

export const MoonPayCheckoutModal = ({
  ...props
}: MoonPayCheckoutModalComponentProps) => {
  return (
    <Suspense fallback={<ModalLoader />}>
      <MoonPayCheckoutModalComponent {...props} />
    </Suspense>
  )
}
