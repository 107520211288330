import React from "react"
import { CenterAligned } from "@opensea/ui-kit"

type Props = {
  size?: number
}

export const AnimatedOSLogo = ({ size = 99 }: Props) => {
  return (
    <CenterAligned
      className="overflow-hidden rounded-full drop-shadow-lg"
      style={{ height: size, width: size }}
    >
      <video
        autoPlay
        className="max-w-fit"
        disableRemotePlayback
        loop
        muted
        playsInline
        preload="auto"
        style={{ height: size + 2, width: size + 2 }}
      >
        <source
          src="https://raw.seadn.io/files/os-logo-glowing.mp4"
          type="video/mp4"
        />
      </video>
    </CenterAligned>
  )
}
