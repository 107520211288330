import React, { useCallback, useState } from "react"
import { Text } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { StackedAssetMedia } from "@/components/assets/StackedAssetMedia"
import { CollectionLink } from "@/components/collections/CollectionLink"
import { Link } from "@/components/common/Link"
import { Item } from "@/design-system/Item"
import { useChains } from "@/hooks/useChains"
import { useTranslate } from "@/hooks/useTranslate"
import { AssetBurnToRedeemAction_data$key } from "@/lib/graphql/__generated__/AssetBurnToRedeemAction_data.graphql"
import { useTransaction } from "../useTransaction"
import {
  BaseBlockchainActionProps,
  BlockchainActionModalContent,
} from "./BlockchainActionModalContent.react"
import { useBlockchainActionProgress } from "./useBlockchainActionProgress"
import { useHandleBlockchainActions } from "./useHandleBlockchainActions"

type Props = BaseBlockchainActionProps & {
  dataKey: AssetBurnToRedeemAction_data$key
}

export const AssetBurnToRedeemAction = ({ dataKey, onEnd }: Props) => {
  const t = useTranslate("components")
  const data = useFragment(
    graphql`
      fragment AssetBurnToRedeemAction_data on AssetBurnToRedeemActionType {
        __typename
        ...useHandleBlockchainActions_burnToRedeem
        asset {
          chain {
            identifier
          }
          assetContract {
            ...CollectionLink_assetContract
          }
          collection {
            __typename
            ...CollectionLink_collection
          }
          displayName
          ...StackedAssetMedia_assets
        }
      }
    `,
    dataKey,
  )
  const [transactionHash, setTransactionHash] = useState<string>()
  const { pollTransaction } = useTransaction()
  const { getTransactionUrl, getBlockExplorerName } = useChains()

  const { burnToRedeemAsset } = useHandleBlockchainActions()

  const executeAction = useCallback(async () => {
    const transaction = await burnToRedeemAsset(data)

    setTransactionHash(transaction.transactionHash)

    const polledTransaction = await pollTransaction({
      ...transaction,
      showMessageOnError: true,
    })

    if (polledTransaction?.status) {
      onEnd({ transaction })
    }
  }, [burnToRedeemAsset, data, pollTransaction, onEnd])

  const { progress, attemptAction } = useBlockchainActionProgress({
    executeAction,
    action: data,
  })

  return (
    <BlockchainActionModalContent>
      <BlockchainActionModalContent.Header>
        <BlockchainActionModalContent.Title>
          {t("blockchain.burn.title", "Approve burn")}
        </BlockchainActionModalContent.Title>
      </BlockchainActionModalContent.Header>
      <BlockchainActionModalContent.Body>
        <Item variant="unstyled">
          <Item.Avatar size={84}>
            <StackedAssetMedia
              assets={[data.asset]}
              isLoading={!!transactionHash}
              type="asset"
              variant="small"
            />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>
              <Text asChild size="medium" weight="semibold">
                <div>{data.asset.displayName}</div>
              </Text>
            </Item.Title>

            <Item.Description>
              <Text asChild className="text-secondary" size="small">
                <div>
                  <CollectionLink
                    assetContract={data.asset.assetContract}
                    collection={data.asset.collection}
                    linkStyle={{
                      color: "inherit",
                      fontSize: "inherit",
                      fontWeight: "inherit",
                    }}
                    target="_blank"
                    withTooltip
                  />
                </div>
              </Text>

              {transactionHash && (
                <Link
                  href={getTransactionUrl(
                    data.asset.chain.identifier,
                    transactionHash,
                  )}
                >
                  {t("explorerLink", "View on {{explorerLink}}", {
                    explorerLink: getBlockExplorerName(
                      data.asset.chain.identifier,
                    ),
                  })}
                </Link>
              )}
            </Item.Description>
          </Item.Content>
        </Item>
        <BlockchainActionModalContent.Body.Separator />
        <BlockchainActionModalContent.Body.GoToWallet />
        <BlockchainActionModalContent.Body.Text>
          {t(
            "blockchain.burn.prompt",
            "You'll be asked to approve this burn from your wallet.",
          )}
        </BlockchainActionModalContent.Body.Text>

        {!progress && (
          <BlockchainActionModalContent.Body.Button onClick={attemptAction}>
            {t("blockchain.swap.cta", "Continue")}
          </BlockchainActionModalContent.Body.Button>
        )}
      </BlockchainActionModalContent.Body>
    </BlockchainActionModalContent>
  )
}
