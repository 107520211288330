import React from "react"

type Props = {
  className?: string
  width?: number
  fill?: string
}

export const BlastLogo = ({ className, fill = "black", width = 20 }: Props) => (
  <svg
    className={className}
    fill={fill}
    style={{ width, height: width }}
    viewBox="0 0 215 215"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M161 106.4L190.3 91.8001L200.4 60.8001L180.2 46.1001H45.7L14.6 69.2001H172.7L164.3 95.2001H100.9L94.8 114.2H158.2L140.4 168.9L170.1 154.2L180.7 121.4L160.8 106.8L161 106.4Z"
      fill="black"
    />
    <path
      d="M59.3 145.4L77.6 88.4002L57.3 73.2002L26.8 168.9H140.4L148 145.4H59.3Z"
      fill="black"
    />
  </svg>
)
