import React from "react"
import {
  twDataIdSelector,
  Item,
  CenterAligned,
  Spinner,
  Text,
  List,
  classNames,
} from "@opensea/ui-kit"
import { ConnectCompatibleWallet } from "@/components/nav/WalletSidebar/ConnectCompatibleWallet"
import { Modal } from "@/design-system/Modal"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { useTranslate } from "@/hooks/useTranslate"
import { OS_WALLET_MODAL_BODY_HEIGHT } from "./OSWalletModalBody.react"

export const MoreWalletsModal = () => {
  const t = useTranslate("common")
  const { onPrevious } = useMultiStepFlowContext()
  const HEIGHT = OS_WALLET_MODAL_BODY_HEIGHT + 15 - 88 // hack: height fits to exactly accomodate content modal header height + padding

  return (
    <>
      <Modal.Header
        className="rounded-t-[12px] bg-component-gray-1 p-0 text-center dark:bg-elevation-1"
        onPrevious={onPrevious}
      >
        <Text.Heading size="small">
          {t("wallet.moreWallets.connectYourWallet", "Connect your wallet")}
        </Text.Heading>
      </Modal.Header>
      <Modal.Body
        className={classNames(
          "rounded-b-[12px] bg-component-gray-1 dark:bg-elevation-1",
          "[-webkit-overflow-scrolling:touch]",
        )}
        style={{
          height: HEIGHT,
          maxHeight: HEIGHT,
          padding: 8,
        }}
      >
        <ConnectCompatibleWallet
          customHeader={<></>}
          defaultShowAllOptions
          renderList={wallets => (
            <List
              className={twDataIdSelector(
                Item,
                "[&_[data-id=Item]]:rounded-lg",
              )}
              showBorder={false}
              variant="framed"
            >
              {wallets.map(
                ({
                  walletName,
                  logo,
                  actionProps,
                  annotation,
                  supportedOnPlatform,
                  installingWallet,
                  notSupportedText,
                }) => (
                  <List.Item
                    className="rounded-lg"
                    key={walletName}
                    {...actionProps}
                  >
                    <List.Item.Avatar
                      alt={walletName}
                      size={30}
                      src={logo ?? ""}
                    />
                    <List.Item.Content>
                      <List.Item.Title weight="semibold">
                        {walletName}
                      </List.Item.Title>
                    </List.Item.Content>
                    {installingWallet !== walletName && annotation && (
                      <List.Item.Side>
                        <CenterAligned className="rounded-md bg-component-gray-1 p-2 capitalize">
                          <Text
                            className="text-secondary opacity-80"
                            size="tiny"
                            weight="semibold"
                          >
                            {annotation}
                          </Text>
                        </CenterAligned>
                      </List.Item.Side>
                    )}
                    {!supportedOnPlatform && (
                      <List.Item.Side>
                        <CenterAligned className="rounded-md bg-component-gray-1 p-2 capitalize">
                          <Text
                            className="text-secondary opacity-80"
                            size="tiny"
                            weight="semibold"
                          >
                            {notSupportedText}
                          </Text>
                        </CenterAligned>
                      </List.Item.Side>
                    )}

                    {walletName === installingWallet && (
                      <List.Item.Side className="ml-1">
                        <Spinner />
                      </List.Item.Side>
                    )}
                  </List.Item>
                ),
              )}
            </List>
          )}
          source="wallet modal"
        />
      </Modal.Body>
    </>
  )
}
