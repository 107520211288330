import { useCallback } from "react"
import {
  useConnectedAddress,
  useWallet,
} from "@/containers/WalletProvider/WalletProvider.react"
import { useChains } from "@/hooks/useChains"
import { WalletAndAccountButtonQuery$variables } from "@/lib/graphql/__generated__/WalletAndAccountButtonQuery.graphql"

/**
 * Returns undefined when wallet is not connected
 */
export const useGetQueryArgs = (): (() =>
  | WalletAndAccountButtonQuery$variables
  | undefined) => {
  const address = useConnectedAddress()
  const { chain } = useWallet()
  const { getWrappedCurrencySymbol, isChainEnabled } = useChains()

  return useCallback(() => {
    if (!address || !chain || !isChainEnabled(chain)) {
      return undefined
    }

    return {
      address,
      wrappedCurrencySymbol: getWrappedCurrencySymbol(chain),
      wrappedCurrencyChain: chain,
    }
  }, [chain, getWrappedCurrencySymbol, address, isChainEnabled])
}
