import React from "react"

export const MastercardLogo = () => (
  <svg
    fill="none"
    height="14"
    viewBox="0 0 23 14"
    width="23"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M11.75 12.1569C10.5584 13.1852 9.01276 13.806 7.32377 13.806C3.55511 13.806 0.5 10.7154 0.5 6.90299C0.5 3.09057 3.55511 0 7.32377 0C9.01276 0 10.5584 0.620752 11.75 1.64903C12.9416 0.620752 14.4872 0 16.1762 0C19.9449 0 23 3.09057 23 6.90299C23 10.7154 19.9449 13.806 16.1762 13.806C14.4872 13.806 12.9416 13.1852 11.75 12.1569Z"
      fill="#ED0006"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M11.75 12.1569C13.2172 10.8908 14.1475 9.0068 14.1475 6.90299C14.1475 4.79917 13.2172 2.91517 11.75 1.64903C12.9416 0.620752 14.4872 0 16.1762 0C19.9449 0 23 3.09057 23 6.90299C23 10.7154 19.9449 13.806 16.1762 13.806C14.4872 13.806 12.9416 13.1852 11.75 12.1569Z"
      fill="#F9A000"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M11.75 1.64917C13.2172 2.9153 14.1475 4.79929 14.1475 6.9031C14.1475 9.0069 13.2172 10.8909 11.75 12.157C10.2828 10.8909 9.35251 9.0069 9.35251 6.9031C9.35251 4.79929 10.2828 2.9153 11.75 1.64917Z"
      fill="#FF5E00"
      fillRule="evenodd"
    />
  </svg>
)
