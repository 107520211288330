import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { Options, Modifier } from "@popperjs/core"
import { ErrorBoundary } from "@sentry/nextjs"
import Tippy, { TippyProps } from "@tippyjs/react"
import { isBoolean, isFunction, merge, noop } from "lodash"
import { transparentize } from "polished"
import styled from "styled-components"
import { variant } from "styled-system"
import type { Instance, Placement } from "tippy.js"
import "tippy.js/dist/tippy.css"
import { inter } from "@/styles/fonts"
import { Values } from "../../lib/helpers/type"
import { followCursor, keepMounted } from "./plugins"

export const TOOLTIP_PLACEMENT: Record<string, Placement> = {
  AUTO: "auto",
  AUTO_START: "auto-start",
  AUTO_END: "auto-end",
  BOTTOM: "bottom",
  BOTTOM_START: "bottom-start",
  BOTTOM_END: "bottom-end",
  TOP: "top",
  TOP_START: "top-start",
  TOP_END: "top-end",
  LEFT: "left",
  LEFT_START: "left-start",
  LEFT_END: "left-end",
  RIGHT: "right",
  RIGHT_START: "right-start",
  RIGHT_END: "right-end",
} as const

export const TOOLTIP_VARIANTS = ["default", "card", "translucent"] as const

type Variant = (typeof TOOLTIP_VARIANTS)[number]

export type TooltipInstance = Instance

export type TooltipPlacement = Values<typeof TOOLTIP_PLACEMENT>

type TippyContent = TippyProps["content"]

export type TooltipContent = TippyContent | (() => TippyContent)

export type TooltipOffset = TippyProps["offset"]

export type TooltipProps = Omit<
  TippyProps,
  "theme" | "content" | "getReferenceClientRect" | "animation" | "plugins"
> & {
  contentPadding?: CSSProperties["padding"]
  borderRadius?: CSSProperties["borderRadius"]
  animation?: "fade-vertical" | "fade" | "shift-away" | boolean
  duration?: TippyProps["duration"]
  variant?: Variant
  content: TooltipContent
  lazy?: boolean
  // The actual getReferenceClientRect's type is too restrictive and not needed.
  getReferenceClientRect?: () => Partial<DOMRect>
  hideOnScroll?: boolean
  matchReferenceWidth?: boolean
  elevation?: "level2" | "level3"
}

const SAME_WIDTH_MODIFIER: Modifier<"sameWidth", Options> = {
  name: "sameWidth",
  enabled: true,
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`
  },
  phase: "beforeWrite",
  requires: ["computeStyles"],
}

const DEFAULT_TOOLTIP_POPPER_OPTIONS: Partial<Options> = {
  modifiers: [
    {
      name: "preventOverflow",
      options: {
        altAxis: true,
        tether: false,
      },
    },
  ],
}

export const Tooltip = React.forwardRef<Element, TooltipProps>(function Tooltip(
  {
    content,
    disabled,
    hideOnClick = false,
    offset = [0, 10],
    variant = "default",
    visible,
    contentPadding = "12px 20px",
    borderRadius,
    popperOptions,
    animation,
    lazy = true,
    hideOnScroll = false,
    onTrigger,
    onHidden,
    onShow,
    onShown,
    getReferenceClientRect,
    matchReferenceWidth: matchContentWidth,
    elevation = "level3",
    duration,
    touch = false,
    ...rest
  },
  ref,
) {
  if (process.env.NODE_ENV === "test") {
    duration = 0
    animation = false
  }

  const [tippyInstance, setTippyInstance] = useState<Instance>()
  const [isShown, setIsShown] = useState(visible)
  const isDisabled = disabled ?? !content

  const renderedContent = useMemo(() => {
    if (!lazy || visible || isShown) {
      const renderedContent = isFunction(content) ? content() : content

      // If its a text-node, we need to wrap it in a tag to avoid a React error.
      // Ref https://github.com/facebook/react/issues/11538#issuecomment-390386520
      if (typeof renderedContent === "string") {
        return <span>{renderedContent}</span>
      }

      return renderedContent
    }
    return undefined
  }, [content, isShown, lazy, visible])

  const close = useCallback(() => tippyInstance?.hide(), [tippyInstance])

  useEffect(() => {
    if (hideOnScroll && isShown) {
      window.addEventListener("scroll", close)
    }
    return () => {
      if (hideOnScroll && isShown) {
        window.removeEventListener("scroll", close)
      }
    }
  }, [hideOnScroll, close, isShown])

  const plugins = useMemo(() => {
    const plugins: TippyProps["plugins"] = []

    if (rest.followCursor) {
      plugins.push(followCursor)
    }

    if (!lazy) {
      plugins.push(keepMounted)
    }

    return plugins
  }, [rest.followCursor, lazy])

  return (
    <ErrorBoundary>
      <StyledTippy
        {...rest}
        $borderRadius={borderRadius}
        $contentPadding={contentPadding}
        $elevation={elevation}
        $variant={variant}
        animation={animation}
        content={renderedContent}
        disabled={isDisabled}
        duration={duration}
        getReferenceClientRect={getReferenceClientRect as () => DOMRect}
        hideOnClick={!isBoolean(visible) ? hideOnClick : undefined}
        offset={offset}
        plugins={plugins}
        popperOptions={merge(
          {},
          DEFAULT_TOOLTIP_POPPER_OPTIONS, // apply default options
          matchContentWidth ? { modifiers: [SAME_WIDTH_MODIFIER] } : {}, // apply same with modifier
          popperOptions, // apply options from props
        )}
        ref={ref}
        touch={touch}
        visible={isBoolean(visible) ? visible : undefined}
        onHidden={instance => {
          onHidden?.(instance)
          setIsShown(false)
        }}
        onShow={instance => {
          onShow?.(instance)
          setTippyInstance(instance)
        }}
        onShown={onShown ?? noop}
        onTrigger={(instance, evt) => {
          onTrigger?.(instance, evt)
          setIsShown(true)
        }}
      />
    </ErrorBoundary>
  )
})

const StyledTippy = styled(Tippy)<{
  $variant: Variant
  $contentPadding: TooltipProps["contentPadding"]
  $borderRadius: TooltipProps["borderRadius"]
  $elevation: TooltipProps["elevation"]
}>`
  [data-tippy-root] {
    max-width: calc(100vw - 10px);
  }

  &.tippy-box {
    position: relative;
    background-color: ${props =>
      props.$elevation === "level3"
        ? props.theme.colors.components.elevation.level3.background
        : props.theme.colors.components.elevation.level2.background};
    color: ${props => props.theme.colors.text.primary};
    border-radius: ${props =>
      props.$borderRadius ?? props.theme.borderRadius.default};
    font-family: ${inter.style.fontFamily}, sans-serif;
    font-variant-ligatures: no-contextual;
    font-size: 14px;
    font-weight: 600;
    outline: 0;
    transition-property: transform, visibility, opacity;
    word-wrap: break-word;
    box-shadow: ${props =>
      props.$elevation === "level3"
        ? props.theme.colors.components.elevation.level3.shadow
        : props.theme.colors.components.elevation.level2.shadow};

    ${props =>
      variant({
        prop: "$variant",
        variants: {
          translucent: {
            backdropFilter: "blur(20px)",
            backgroundColor:
              props.theme.type === "light"
                ? transparentize(
                    0.4,
                    props.theme.colors.components.elevation.level3.background,
                  )
                : transparentize(0.6, props.theme.colors.ash),
            color: props.theme.colors.text.primary,
            boxShadow: props.theme.shadows.translucentTooltip,
            fontWeight: 500,
          },
        },
      })}
  }

  &.tippy-box[data-placement^="top"] > .tippy-arrow {
    bottom: 0;
  }

  &.tippy-box[data-animation="fade-vertical"][data-state="hidden"][data-placement^="top"] {
    opacity: 0;
    transform: translateY(12px);
  }
  &.tippy-box[data-animation="fade-vertical"][data-state="visible"][data-placement^="top"] {
    opacity: 100;
    transform: translateY(0px);
  }
  &.tippy-box[data-animation="fade-vertical"][data-state="hidden"][data-placement^="bottom"] {
    opacity: 0;
    transform: translateY(-12px);
  }
  &.tippy-box[data-animation="fade-vertical"][data-state="visible"][data-placement^="bottom"] {
    opacity: 100;
    transform: translateY(0px);
  }

  &.tippy-box[data-placement^="top"] > .tippy-arrow:before {
    bottom: -7px;
    left: 0;
    border-width: 8px 8px 0;
    border-top-color: initial;
    transform-origin: center top;
  }

  &.tippy-box[data-placement^="bottom"] > .tippy-arrow {
    top: 0;
  }

  &.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
    top: -7px;
    left: 0;
    border-width: 0 8px 8px;
    border-bottom-color: initial;
    transform-origin: center bottom;
  }

  &.tippy-box[data-placement^="left"] > .tippy-arrow {
    right: 0;
  }

  &.tippy-box[data-placement^="left"] > .tippy-arrow:before {
    border-width: 8px 0 8px 8px;
    border-left-color: initial;
    right: -7px;
    transform-origin: center left;
  }

  &.tippy-box[data-placement^="right"] > .tippy-arrow {
    left: 0;
  }

  &.tippy-box[data-placement^="right"] > .tippy-arrow:before {
    left: -7px;
    border-width: 8px 8px 8px 0;
    border-right-color: initial;
    transform-origin: center right;
  }

  .tippy-arrow {
    width: 16px;
    height: 16px;
    background-color: ${props =>
      props.theme.colors.components.elevation.level3.background};
    color: ${props =>
      props.theme.colors.components.elevation.level3.background};

    ${() =>
      variant({
        prop: "$variant",
        variants: {
          translucent: {
            // hide arrow as it noticably overlays content
            display: "none",
          },
        },
      })}
  }

  .tippy-arrow:before {
    content: "";
    position: absolute;
    border-color: transparent;
    border-style: solid;
  }

  .tippy-content {
    position: relative;
    padding: ${props => props.$contentPadding};
    z-index: 1;
    text-align: center;
  }
`
