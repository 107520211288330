import React, { forwardRef, useState } from "react"
import styled from "styled-components"
import { FileInput, FileInputProps } from "@/design-system/FileInput"
import { Image } from "@/design-system/Image"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { first } from "@/lib/helpers/array"
import { getBytesFromMegabytes } from "@/lib/helpers/file"
import { MAX_MEDIA_SIZE_MB } from "../../../constants"

const MAX_SIZE = getBytesFromMegabytes(MAX_MEDIA_SIZE_MB)

export type ImageInputValue = {
  url: string
  file?: File
}

export type ImageInputProps = Omit<
  FileInputProps,
  "accept" | "onChange" | "aria-label"
> & {
  onChange?: (value: ImageInputValue | undefined) => unknown
  value?: ImageInputValue
}

export const ImageInput = forwardRef<HTMLDivElement, ImageInputProps>(
  function ImageInput(
    { onChange, shape = "square", value, maxSize = MAX_SIZE, ...rest },
    ref,
  ) {
    const t = useTranslate("components")

    // We keep this local state so we are able to revoke object urls to prevent memory leaks
    const [previewUrl, setPreviewUrl] = useState<string | undefined>()

    // revoke object url for current image when component unmounts
    useMountEffect(() => {
      return () => {
        if (previewUrl) {
          URL.revokeObjectURL(previewUrl)
        }
      }
    })

    return (
      <FileInput
        accept={{ "image/*": [] }}
        maxSize={maxSize}
        preview={
          value?.url && (
            <StyledImage
              $variant={shape}
              alt={t(
                "forms.imageInput.alt",
                "{{url}} preview image",
                {
                  url: value.url,
                },
                { forceString: true },
              )}
              layout="fill"
              objectFit="cover"
              src={value.url}
            />
          )
        }
        ref={ref}
        shape={shape}
        onChange={files => {
          const file = first(files)
          // revoke object url for previous image
          if (previewUrl) {
            URL.revokeObjectURL(previewUrl)
          }

          if (onChange) {
            if (file) {
              const url = URL.createObjectURL(file)
              onChange({ file, url })
              setPreviewUrl(url)
            } else {
              onChange(undefined)
            }
          }
        }}
        {...rest}
      />
    )
  },
)

const StyledImage = styled(Image)<{ $variant: "round" | "square" }>`
  border-radius: ${props =>
    props.$variant === "round"
      ? props.theme.borderRadius.circle
      : props.theme.borderRadius.default};
`
