/* eslint-disable tailwindcss/no-custom-classname */
import React, { useState } from "react"
import {
  Icon,
  UnstyledButton,
  Text,
  Spinner,
  FlexEnd,
  CenterAligned,
  Flex,
} from "@opensea/ui-kit"
import { noop } from "lodash"
import { useLazyLoadQuery } from "react-relay"
import styled from "styled-components"
import { useHandleBlockchainActions } from "@/components/blockchain/BlockchainActionList"
import { BlockchainActionList } from "@/components/blockchain/BlockchainActionList/BlockchainActionList.react"
import { BalanceLabel } from "@/components/common/BalanceLabel.react"
import { FundItem } from "@/components/common/FundItem"
import NumericInput from "@/components/forms/NumericInput.react"
import { useActiveAccount } from "@/containers/WalletProvider/WalletProvider.react"
import { Dropdown } from "@/design-system/Dropdown"
import { Modal } from "@/design-system/Modal"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { Tooltip } from "@/design-system/Tooltip"
import { useChains } from "@/hooks/useChains"
import { useToasts } from "@/hooks/useToasts"
import { useTransactionProgress } from "@/hooks/useTransactionProgress"
import { useTranslate } from "@/hooks/useTranslate"
import { SwapModalContentFromQuery } from "@/lib/graphql/__generated__/SwapModalContentFromQuery.graphql"
import { SwapModalContentToQuery } from "@/lib/graphql/__generated__/SwapModalContentToQuery.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { getSymbolDisplay } from "@/lib/helpers/chainUtils"
import { bn, ETH_DECIMALS } from "@/lib/helpers/numberUtils"
import { querySwapActions } from "@/lib/helpers/swap"
import { UnreachableCaseError } from "@/lib/helpers/type"
import { POLYGON_BRIDGE_LINK } from "../../../constants"
import { SwapIdentifier } from "./types"

const BRIDGE_TIME_UPPER_LIMIT_MINS = 30

export type SwapModalContentProps = {
  from: SwapIdentifier
  to: SwapIdentifier
  /**
   * If true, the "to" currency will be fixed and cannot be changed.
   */
  fixedToCurrency?: boolean
  initialQuantity?: string
  onViewWallet?: () => unknown
  onSuccess?: () => unknown
}

export const SwapModalContent = ({
  from: initialFrom,
  to: initialTo,
  fixedToCurrency,
  initialQuantity,
  onViewWallet,
  onSuccess,
}: SwapModalContentProps) => {
  const t = useTranslate("components")
  const [value, setValue] = useState<string | undefined>(initialQuantity ?? "")
  const [amount, setAmount] = useState(initialQuantity ?? "")
  const [from, setFrom] = useState(initialFrom)
  const [to, setTo] = useState(initialTo)
  const { progress, waitForTransaction } = useTransactionProgress()
  const { swapAsset } = useHandleBlockchainActions()
  const { onNext } = useMultiStepFlowContext()
  const step =
    progress >= 100 ? "success" : progress > 0 ? "processing" : "input"

  const activeAccount = useActiveAccount()
  const { attempt, showSuccessMessage } = useToasts()
  const address = activeAccount?.address ?? ""
  const { getChainName } = useChains()

  const isMaticReverseSwap =
    from.chain.identifier === "MUMBAI" ||
    from.chain.identifier === "MATIC" ||
    from.chain.identifier === "AMOY"

  const isBridge = from.chain.identifier !== to.chain.identifier
  const bridgeTime = t(
    "swapModal.bridgeTime",
    "It will take up to {{bridgeTimeUpperLimitMins}} minutes for the {{symbol}} to appear in your {{network}} account.",
    {
      bridgeTimeUpperLimitMins: BRIDGE_TIME_UPPER_LIMIT_MINS,
      symbol: getSymbolDisplay(to.symbol),
      network: getChainName(to.chain.identifier),
    },
  )

  const renderFundItem = ({
    fund,
    reverseFund,
    setFund,
  }: {
    fund: typeof fromFund
    reverseFund: typeof toFund
    setFund: typeof setFrom
  }) => {
    const { supportedSwaps } = reverseFund
    const hasMultipleSwaps = supportedSwaps.length > 1

    return hasMultipleSwaps && !fixedToCurrency ? (
      <Dropdown
        items={supportedSwaps}
        renderItem={({ Item, item, close }) => (
          <Item
            onClick={() => {
              setFund(item)
              close()
            }}
          >
            <Item.Content>
              <Item.Title>{item.symbol}</Item.Title>
              <Item.Description>
                {getChainName(item.chain.identifier)}
              </Item.Description>
            </Item.Content>
          </Item>
        )}
      >
        <FundItem
          actions={
            <Icon
              className="WrapUnwrapModalContent--icon"
              value="expand_more"
            />
          }
          chain={fund.chain}
          className="WrapUnwrapModalContent--fund-item"
          image={fund.image}
          name={fund.name}
          symbol={fund.symbol}
          onClick={noop}
        />
      </Dropdown>
    ) : (
      <FundItem
        chain={fund.chain}
        className="WrapUnwrapModalContent--fund-item"
        image={fund.image}
        name={fund.name}
        symbol={fund.symbol}
      />
    )
  }

  const {
    wallet: { fundsOf: fromFund },
    paymentAsset: fromPaymentAsset,
  } = useLazyLoadQuery<SwapModalContentFromQuery>(
    graphql`
      query SwapModalContentFromQuery(
        $address: AddressScalar!
        $symbol: String!
        $chain: ChainScalar!
      ) {
        paymentAsset(symbol: $symbol, chain: $chain) {
          asset {
            relayId
            decimals
          }
        }
        wallet(address: $address) {
          fundsOf(symbol: $symbol, chain: $chain) {
            name
            symbol
            chain
            quantity
            image
            usdPrice
            supportedSwaps {
              symbol
              chain {
                identifier
              }
            }
          }
        }
      }
    `,
    { chain: from.chain.identifier, symbol: from.symbol, address },
  )

  const {
    wallet: { fundsOf: toFund },
    paymentAsset: toPaymentAsset,
  } = useLazyLoadQuery<SwapModalContentToQuery>(
    graphql`
      query SwapModalContentToQuery(
        $address: AddressScalar!
        $symbol: String!
        $chain: ChainScalar!
      ) {
        paymentAsset(symbol: $symbol, chain: $chain) {
          asset {
            relayId
            decimals
          }
        }
        wallet(address: $address) {
          fundsOf(symbol: $symbol, chain: $chain) {
            name
            symbol
            chain
            quantity
            image
            usdPrice
            supportedSwaps {
              symbol
              chain {
                identifier
              }
            }
          }
        }
      }
    `,
    { chain: to.chain.identifier, symbol: to.symbol, address },
  )

  const swapFromTo = () => {
    setFrom(to)
    setTo(from)
  }

  const [swapInProgress, setSwapInProgress] = useState(false)
  const swap = async () => {
    setSwapInProgress(true)

    if (isMaticReverseSwap) {
      window.open(POLYGON_BRIDGE_LINK)
      return
    }

    await attempt(
      async () => {
        const swapActions = await querySwapActions({
          amount,
          fromAssetDecimals: fromPaymentAsset.asset.decimals ?? ETH_DECIMALS,
          fromAsset: fromPaymentAsset.asset.relayId,
          toAsset: toPaymentAsset.asset.relayId,
        })

        if (swapActions.actionsV2.length === 1) {
          const [action] = swapActions.actionsV2

          const transaction = await swapAsset(action)

          await waitForTransaction({
            hash: transaction.transactionHash,
            chain: from.chain.identifier,
            onSuccess: () => {
              onSuccess?.()

              if (isBridge) {
                showSuccessMessage(
                  t(
                    "swapModal.bridgeTransactionCompleted",
                    "Transaction submitted. {{bridgeTime}}",
                    { bridgeTime },
                  ),
                )
                return
              }

              showSuccessMessage(
                t("swapModal.transactionCompleted", "Transaction completed."),
              )
            },
          })
          return
        }

        onNext(() => (
          <BlockchainActionList
            dataKey={swapActions.actionsV2}
            onEnd={onSuccess ?? noop}
          />
        ))
      },
      {
        onError: () => {
          setSwapInProgress(false)
        },
      },
    )

    setSwapInProgress(false)
  }

  const renderContent = () => {
    switch (step) {
      case "processing":
        return (
          <>
            <StyledBody as={CenterAligned} className="h-[360px]">
              <Flex className="justify-center">
                <Spinner />
              </Flex>
              <SwapModalText weight="semibold">
                {t("swapModal.processing.title", "Processing...")}
              </SwapModalText>
              {isBridge ? (
                <SwapModalText>
                  {t(
                    "swapModal.processing.bridgeDescription",
                    "After the initial transaction is processed, it will take up to {{bridgeTimeUpperLimit}} minutes for the {{symbol}} to appear in your {{network}} account.",
                    {
                      symbol: getSymbolDisplay(to.symbol),
                      bridgeTimeUpperLimit: BRIDGE_TIME_UPPER_LIMIT_MINS,
                      network: getChainName(to.chain.identifier),
                    },
                  )}
                </SwapModalText>
              ) : (
                <SwapModalText>
                  {t(
                    "swapModal.processing.description",
                    "Your {{symbol}} will be added to your account once the transaction is processed.",
                    { symbol: getSymbolDisplay(to.symbol) },
                  )}
                </SwapModalText>
              )}
            </StyledBody>

            <Modal.Footer>
              <Modal.Footer.Button disabled onClick={swap}>
                {t("swapModal.convertTokens", "Convert tokens")}
              </Modal.Footer.Button>
            </Modal.Footer>
          </>
        )
      case "input": {
        return (
          <>
            <StyledBody>
              <div className="WrapUnwrapModalContent--input-container">
                <BalanceLabel
                  label={t("swapModal.from", "From")}
                  quantity={fromFund.quantity}
                  symbol={getSymbolDisplay(fromFund.symbol)}
                  usdPrice={fromFund.usdPrice}
                />
                <NumericInput
                  autoFocus
                  inputClassName="WrapUnwrapModalContent--input"
                  inputValue={amount}
                  isRequired
                  max={bn(fromFund.quantity)}
                  maxDecimals={fromPaymentAsset.asset.decimals ?? undefined}
                  placeholder={t("swapModal.amount.placeholder", "Amount")}
                  value={value}
                  onChange={({ inputValue, value }) => {
                    setAmount(inputValue)
                    setValue(value)
                  }}
                >
                  {renderFundItem({
                    fund: fromFund,
                    reverseFund: toFund,
                    setFund: setFrom,
                  })}
                </NumericInput>
              </div>
              {!fixedToCurrency && (
                <FlexEnd className="WrapUnwrapModalContent--swap-icon-container">
                  <Tooltip content={t("swapModal.swap", "Swap")}>
                    <UnstyledButton onClick={swapFromTo}>
                      <Icon
                        aria-label={t("swapModal.swap", "Swap")}
                        className="WrapUnwrapModalContent--icon"
                        value="swap_vertical_circle"
                      />
                    </UnstyledButton>
                  </Tooltip>
                </FlexEnd>
              )}
              <div className="WrapUnwrapModalContent--input-container">
                <BalanceLabel
                  label={t("swapModal.to", "To")}
                  quantity={toFund.quantity}
                  symbol={getSymbolDisplay(toFund.symbol)}
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  usdPrice={toFund.usdPrice!}
                />
                <NumericInput
                  disabled
                  inputClassName="WrapUnwrapModalContent--input"
                  inputValue={amount}
                  isRequired
                  max={bn(fromFund.quantity)}
                  maxDecimals={fromPaymentAsset.asset.decimals ?? undefined}
                  placeholder={t("swapModal.amount.placeholder", "Amount")}
                  value={value}
                  onChange={noop}
                >
                  {renderFundItem({
                    fund: toFund,
                    reverseFund: fromFund,
                    setFund: setTo,
                  })}
                </NumericInput>
              </div>
            </StyledBody>

            <Modal.Footer>
              <Tooltip content={bridgeTime} disabled={!isBridge}>
                <Modal.Footer.Button
                  disabled={
                    !isMaticReverseSwap &&
                    (amount.trim() === "" ||
                      bn(amount).isZero() ||
                      bn(amount).isGreaterThan(fromFund.quantity))
                  }
                  isLoading={swapInProgress}
                  onClick={swap}
                >
                  {t("swapModal.convertTokens", "Convert tokens")}
                </Modal.Footer.Button>
              </Tooltip>
            </Modal.Footer>
          </>
        )
      }
      case "success":
        return (
          <>
            <StyledBody>
              <Flex className="justify-center">
                <Icon className="text-green-2" size={48} value="check_circle" />
              </Flex>
              <SwapModalText weight="semibold">
                {t(
                  "swapModal.transactionComplete.title",
                  "Transaction Complete!",
                )}
              </SwapModalText>
              {isBridge ? (
                <SwapModalText>{bridgeTime}</SwapModalText>
              ) : (
                <SwapModalText>
                  {t(
                    "swapModal.transactionComplete.description",
                    "Your {{amount}} {{symbol}} has been added to your account and will appear in your wallet shortly.",
                    {
                      amount,
                      symbol: getSymbolDisplay(to.symbol),
                    },
                  )}
                </SwapModalText>
              )}
            </StyledBody>
            <Modal.Footer>
              {onViewWallet && (
                <Modal.Footer.Button onClick={onViewWallet}>
                  {t("swapModal.viewWallet", "View Wallet")}
                </Modal.Footer.Button>
              )}
            </Modal.Footer>
          </>
        )
      default:
        throw new UnreachableCaseError(step)
    }
  }

  return renderContent()
}

const StyledBody = styled(Modal.Body)`
  .WrapUnwrapModalContent--fund-item {
    border: none;
    width: 200px;
    padding: 0;
  }

  .WrapUnwrapModalContent--icon {
    color: ${props => props.theme.colors.text.primary};

    :hover {
      color: ${props => props.theme.colors.text.primary};
    }
  }

  .WrapUnwrapModalContent--swap-icon-container {
    margin: 20px 0;
  }

  .WrapUnwrapModalContent--input-container {
    height: 132px;
  }

  .Input--main {
    .WrapUnwrapModalContent--input {
      height: auto;
    }
  }
`

const SwapModalText = styled(Text.Body).attrs({
  size: "medium",
})`
  text-align: center;
  display: block;
  margin: 16px 0;
`
