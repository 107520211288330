/**
 * @generated SignedSource<<890eff7c7b14c482cf2d7af04ac806d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserTransactionState = "CANCELING" | "CANCELLED" | "PENDING" | "REJECTED" | "REQUESTED" | "SUCCESSFUL" | "%future added value";
export type useUpdateTransactionStatusToRejectedMutation$variables = {
  userTransactionId: any;
};
export type useUpdateTransactionStatusToRejectedMutation$data = {
  readonly userTransaction: {
    readonly updateStatus: {
      readonly relayId: string;
      readonly state: UserTransactionState | null;
    };
  };
};
export type useUpdateTransactionStatusToRejectedMutation = {
  response: useUpdateTransactionStatusToRejectedMutation$data;
  variables: useUpdateTransactionStatusToRejectedMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userTransactionId"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "state",
    "value": "REJECTED"
  },
  {
    "kind": "Variable",
    "name": "userTransactionId",
    "variableName": "userTransactionId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateTransactionStatusToRejectedMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserTransactionMutationType",
        "kind": "LinkedField",
        "name": "userTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "UserTransactionType",
            "kind": "LinkedField",
            "name": "updateStatus",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUpdateTransactionStatusToRejectedMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserTransactionMutationType",
        "kind": "LinkedField",
        "name": "userTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "UserTransactionType",
            "kind": "LinkedField",
            "name": "updateStatus",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4d804e1c2346824a634539634e520d23",
    "id": null,
    "metadata": {},
    "name": "useUpdateTransactionStatusToRejectedMutation",
    "operationKind": "mutation",
    "text": "mutation useUpdateTransactionStatusToRejectedMutation(\n  $userTransactionId: UserTransactionRelayID!\n) {\n  userTransaction {\n    updateStatus(state: REJECTED, userTransactionId: $userTransactionId) {\n      relayId\n      state\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c2518fe61743eeee0d850cbc809cee98";

export default node;
