import React from "react"
import type { MaterialSymbolProps } from "./types"

export const ArrowRightAlt = ({ size = 24, ...rest }: MaterialSymbolProps) => {
  return (
    <svg
      fill="currentColor"
      height={size}
      role="img"
      viewBox="0 -960 960 960"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <title>Arrow Right</title>
      <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z" />
    </svg>
  )
}
