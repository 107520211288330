/**
 * @generated SignedSource<<002d259dda1d82a0954db928a69342a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type Config = "AFFILIATE" | "AFFILIATE_BLACKLISTED" | "AFFILIATE_REQUESTED" | "EMPLOYEE" | "MODERATOR" | "PARTNER" | "STAFF" | "VERIFIED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type accountRecentViews_data$data = {
  readonly address: string;
  readonly config: Config | null;
  readonly imageUrl: string;
  readonly isCompromised: boolean;
  readonly metadata: {
    readonly isBanned: boolean;
    readonly isDisabled: boolean;
  } | null;
  readonly relayId: string;
  readonly user: {
    readonly publicUsername: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"accounts_url">;
  readonly " $fragmentType": "accountRecentViews_data";
};
export type accountRecentViews_data$key = {
  readonly " $data"?: accountRecentViews_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"accountRecentViews_data">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "accountRecentViews_data"
};

(node as any).hash = "6040e202f1fd237ce10ff1b64e9e79ee";

export default node;
