/**
 * @generated SignedSource<<64b54e14d9c984e0677e7af831eef571>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useHandleBlockchainActions_ask_for_asset_swap$data = {
  readonly fromAsset: {
    readonly decimals: number | null;
    readonly relayId: string;
  };
  readonly toAsset: {
    readonly relayId: string;
  };
  readonly " $fragmentType": "useHandleBlockchainActions_ask_for_asset_swap";
};
export type useHandleBlockchainActions_ask_for_asset_swap$key = {
  readonly " $data"?: useHandleBlockchainActions_ask_for_asset_swap$data;
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_ask_for_asset_swap">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useHandleBlockchainActions_ask_for_asset_swap"
};

(node as any).hash = "63bcb73b23c6f9c26dca6881b3a275e1";

export default node;
