import { createContext, useContext } from "react"
import { noop } from "lodash"

type ModalContext = {
  isModalBodyScrolled: boolean
  isModalBodyFullyScrolled: boolean
  isExtraLargeWidth: boolean
  hasModalHeaderTitle: boolean
  setIsModalBodyScrolled: (isModalBodyScrolled: boolean) => unknown
  setIsModalBodyFullyScrolled: (isModalBodyFullyScrolled: boolean) => unknown
  setHasModalHeaderTitle: (hasModalHeaderTitle: boolean) => unknown
}

export const ModalContext = createContext<ModalContext>({
  isModalBodyScrolled: false,
  isModalBodyFullyScrolled: false,
  isExtraLargeWidth: false,
  hasModalHeaderTitle: false,
  setIsModalBodyScrolled: noop,
  setIsModalBodyFullyScrolled: noop,
  setHasModalHeaderTitle: noop,
})

export const useModalContext = () => useContext(ModalContext)
