import * as z from "zod"

export const unleashEnvironmentSchema = z
  .union([
    z.literal("development"),
    z.literal("staging"),
    z.literal("preprod"),
    z.literal("production"),
  ])
  .optional()

export type UnleashEnvironment = z.infer<typeof unleashEnvironmentSchema>
