/**
 * @generated SignedSource<<52ae67ec6d01bf308e6e673a4d7f8e5a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SwitchNetworkListContentQuery$variables = {
  address: string;
};
export type SwitchNetworkListContentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SwitchNetworkNetworkTotalFundsUsd_data">;
};
export type SwitchNetworkListContentQuery = {
  response: SwitchNetworkListContentQuery$data;
  variables: SwitchNetworkListContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "address"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "address",
    "variableName": "address"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SwitchNetworkListContentQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "SwitchNetworkNetworkTotalFundsUsd_data"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SwitchNetworkListContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "WalletType",
        "kind": "LinkedField",
        "name": "wallet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WalletFundsType",
            "kind": "LinkedField",
            "name": "funds",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "quantity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "usdPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "chain",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0dd92344f8ac9aeed280ad6476db21c3",
    "id": null,
    "metadata": {},
    "name": "SwitchNetworkListContentQuery",
    "operationKind": "query",
    "text": "query SwitchNetworkListContentQuery(\n  $address: AddressScalar!\n) {\n  ...SwitchNetworkNetworkTotalFundsUsd_data_2WA0fu\n}\n\nfragment NetworkTotalFundsUsdDisplay_funds on WalletFundsType {\n  quantity\n  usdPrice\n  chain\n}\n\nfragment SwitchNetworkNetworkTotalFundsUsd_data_2WA0fu on Query {\n  wallet(address: $address) {\n    funds {\n      ...NetworkTotalFundsUsdDisplay_funds\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cc7a908839884fd896bdcd60ad5f007d";

export default node;
