import * as z from "zod"

export const envSchema = z
  .union([
    z.literal("development"),
    z.literal("test:unit"),
    z.literal("test:e2e"),
    z.literal("preview"),
    z.literal("staging"),
    z.literal("production"),
  ])
  .optional()

export type Environment = z.infer<typeof envSchema>
