import { useCallback, useEffect, useRef, useState } from "react"
import { useIsMountedRef } from "@/hooks/useIsMounted"
import { useToasts } from "@/hooks/useToasts"
import { CaptureExceptionArgs } from "@/lib/sentry"
import { BlockchainActionType } from "./BlockchainActionList.react"

const AUTO_PROGRESSABLE_ACTIONS: BlockchainActionType[] = [
  "AssetApprovalActionType",
  "AssetTransferActionType",
  "CancelOrderActionType",
  "CancelSwapOrdersActionType",
  "CreateOrderActionType",
  "CreateBulkOrderActionType",
  "CreateSwapOrderActionType",
  "FulfillOrderActionType",
  "PaymentAssetApprovalActionType",
  "MintActionType",
  "BulkFulfillOrdersActionType",
]

type BlockchainAction = { __typename: BlockchainActionType }

type BlockchainActionProgress = {
  action: BlockchainAction
  executeAction: () => unknown
  onError?: (error: Error) => unknown
  autoProgress?: boolean
  errorTags?: CaptureExceptionArgs["tags"]
}

export const useBlockchainActionProgress = ({
  action,
  executeAction,
  onError,
  autoProgress = true,
  errorTags,
}: BlockchainActionProgress) => {
  const [progress, setProgress] = useState(0)
  const progressRef = useRef(0)
  const autoProgressRef = useRef(
    autoProgress && AUTO_PROGRESSABLE_ACTIONS.includes(action.__typename),
  )
  const isMountedRef = useIsMountedRef()
  const { attempt } = useToasts()

  const setProgressAndRef = useCallback(
    (p: number) => {
      if (isMountedRef.current) {
        progressRef.current = p
        setProgress(p)
      }
    },
    [isMountedRef],
  )

  const attemptAction = useCallback(async () => {
    setProgressAndRef(50)

    await attempt(executeAction, {
      onError: error => {
        setProgressAndRef(0)
        autoProgressRef.current = false
        onError?.(error)
      },
      errorTags: {
        blockchain_action_type: action.__typename as string,
        ...errorTags,
      },
    })

    if (progressRef.current > 0) {
      setProgressAndRef(100)
    }
  }, [
    setProgressAndRef,
    attempt,
    executeAction,
    action.__typename,
    errorTags,
    onError,
  ])

  // Reset progress if we change actions
  useEffect(() => {
    autoProgressRef.current = autoProgress
    setProgressAndRef(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action])

  useEffect(() => {
    if (autoProgressRef.current && progressRef.current === 0) {
      attemptAction()
    }
  }, [action, attemptAction])

  return { progress, attemptAction, setProgress }
}
