import React, { ElementRef, forwardRef } from "react"
import { IconButtonProps, IconButton as DSIconButton } from "@opensea/ui-kit"
import { Link, LinkProps } from "@/components/common/Link"

const RenderLink = (props: LinkProps) => {
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <Link {...props} />
}

export const IconButton = forwardRef<
  ElementRef<typeof DSIconButton>,
  IconButtonProps
>(function IconButton(props, ref) {
  return <DSIconButton renderLink={RenderLink} {...props} ref={ref} />
})
