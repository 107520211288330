/**
 * @generated SignedSource<<cb19ea6efe100cca170f545f46f6a966>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MoonPayTopupModalQuery$variables = {
  currencyCode?: string | null;
  usdAmount?: string | null;
};
export type MoonPayTopupModalQuery$data = {
  readonly moonpay: {
    readonly cryptoWidgetData: {
      readonly externalTransactionId: string;
      readonly url: string;
    };
  };
};
export type MoonPayTopupModalQuery = {
  response: MoonPayTopupModalQuery$data;
  variables: MoonPayTopupModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "currencyCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "usdAmount"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MoonpayType",
    "kind": "LinkedField",
    "name": "moonpay",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "currencyCode",
            "variableName": "currencyCode"
          },
          {
            "kind": "Variable",
            "name": "usdAmount",
            "variableName": "usdAmount"
          }
        ],
        "concreteType": "MoonpayWidgetDataType",
        "kind": "LinkedField",
        "name": "cryptoWidgetData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "externalTransactionId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MoonPayTopupModalQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MoonPayTopupModalQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8cbf0ac01126ada826bdbc4d4000ff99",
    "id": null,
    "metadata": {},
    "name": "MoonPayTopupModalQuery",
    "operationKind": "query",
    "text": "query MoonPayTopupModalQuery(\n  $currencyCode: String\n  $usdAmount: String\n) {\n  moonpay {\n    cryptoWidgetData(currencyCode: $currencyCode, usdAmount: $usdAmount) {\n      externalTransactionId\n      url\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5d1fd214d1c734d6b786ab17131e6d1d";

export default node;
