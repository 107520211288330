import { truncateTextInMiddle } from "@/lib/helpers/stringUtils"
import { MAX_ADDRESS_LENGTH } from "../../../constants"
import * as solanaUtils from "./solana"

export const formatAddress = (address: string) => {
  if (solanaUtils.isValidAddress(address)) {
    return address.substring(0, MAX_ADDRESS_LENGTH)
  }
  return address.substring(2, MAX_ADDRESS_LENGTH + 2).toUpperCase()
}

export const truncateAddress = (address: string) => {
  return truncateTextInMiddle(address, { before: 6, after: 4 })
}
