import { range } from "lodash"
import { AvatarProps } from "@/design-system/Avatar"
import { SelectOption } from "@/design-system/Select"
import { useCategoriesInfo } from "@/hooks/useCategoriesInfo"
import { useTranslate } from "@/hooks/useTranslate"
import { CollectionSort } from "@/lib/graphql/__generated__/CollectionFilterLazyQuery.graphql"
import { TrendingCollectionSort } from "@/lib/graphql/__generated__/HomePageStatsTablesLazyQuery.graphql"
import { StatsTimeWindow } from "@/lib/graphql/__generated__/RankingsPageTopQuery.graphql"
import { ColumnSortsHookReturnValue, ColumnSortKey, RankingsRow } from "./types"

export const EVENT_SOURCE = "Rankings"

export const avatarProps: AvatarProps = {
  className: "bg-white",
  size: 24,
  rounded: true,
}

export const useCategoryOptions = (params?: {
  excludeAll?: boolean
  excludeNonStandard?: boolean
  excludeAvatars?: boolean
  includeNew?: boolean
}): SelectOption[] => {
  const {
    excludeAll = false,
    includeNew = false,
    excludeNonStandard = false,
    excludeAvatars = false,
  } = params || {}

  const CATEGORIES = useCategoriesInfo()
  const t = useTranslate("rankings")

  const options: Array<{
    slug: string
    name: string
  }> = [...CATEGORIES]

  if (includeNew) {
    options.unshift({
      slug: "new",
      name: t("categories.new.name", "New"),
    })
  }

  if (!excludeAll) {
    options.unshift({
      slug: DEFAULT_CATEGORY,
      name: t("categories.all.name", "All categories"),
    })
  }

  if (!excludeNonStandard) {
    options.push(
      {
        slug: "domain-names",
        name: t("categories.domainNames.name", "Domain Names"),
      },
      {
        slug: "music",
        name: t("categories.music.name", "Music"),
      },
      {
        slug: "sports-collectibles",
        name: t("categories.sportsCollectibles.name", "Sports Collectibles"),
      },
      {
        slug: "virtual-worlds",
        name: t("categories.virtual-worlds.name", "Virtual Worlds"),
      },
    )
  }

  return options.map(cat => ({
    label: cat.name,
    value: cat.slug,
    avatar: excludeAvatars
      ? undefined
      : {
          ...avatarProps,
          src: `/static/images/icons/${cat.slug}-light.svg`,
          alt: cat.name,
        },
  }))
}

export const DEFAULT_SORT_BY_INDEX = 0
export const DEFAULT_SORT_BY = "one_hour_volume"
export const DEFAULT_CATEGORY = "allnfts"

export const useSortByTimeOptions: () => SelectOption[] = () => {
  const t = useTranslate("rankings")
  return [
    {
      label: t("sortByTime.lastHour", "1h"),
      value: "one_hour_volume",
    },
    {
      label: t("sortByTime.lastSixHours", "6h"),
      value: "six_hour_volume",
    },
    {
      label: t("sortByTime.lastDay", "24h"),
      value: "one_day_volume",
    },
    {
      label: t("sortByTime.lastWeek", "7d"),
      value: "seven_day_volume",
    },
    {
      label: t("sortByTime.lastMonth", "30d"),
      value: "thirty_day_volume",
    },
    {
      label: t("sortByTime.allTime", "All"),
      value: "total_volume",
    },
  ]
}

export const useTrendingSortByTimeOptions: () => SelectOption[] = () => {
  const t = useTranslate("rankings")
  return [
    {
      label: t("sortByTime.lastHour", "1h"),
      value: "one_hour_volume",
    },
    {
      label: t("sortByTime.lastSixHours", "6h"),
      value: "six_hour_volume",
    },
    {
      label: t("sortByTime.lastDay", "24h"),
      value: "one_day_volume",
    },
    {
      label: t("sortByTime.lastSevenDays", "7d"),
      value: "seven_day_volume",
    },
  ]
}

export const QUERY_PARAM_TO_SORT = new Map<string, CollectionSort>([
  ["one_hour_volume", "ONE_HOUR_VOLUME"],
  ["six_hour_volume", "SIX_HOUR_VOLUME"],
  ["seven_day_volume", "SEVEN_DAY_VOLUME"],
  ["total_volume", "TOTAL_VOLUME"],
  ["thirty_day_volume", "THIRTY_DAY_VOLUME"],
  ["one_day_volume", "ONE_DAY_VOLUME"],
])

export const QUERY_PARAM_TO_TOP_SORT = new Map<string, TrendingCollectionSort>([
  ["one_hour_volume", "ONE_HOUR_VOLUME"],
  ["six_hour_volume", "SIX_HOUR_VOLUME"],
  ["seven_day_volume", "SEVEN_DAY_VOLUME"],
  ["total_volume", "TOTAL_VOLUME"],
  ["thirty_day_volume", "THIRTY_DAY_VOLUME"],
  ["one_day_volume", "ONE_DAY_VOLUME"],
])

export const QUERY_PARAM_TO_TRENDING_SORT = new Map<
  string,
  TrendingCollectionSort
>([
  ["one_hour_volume", "ONE_HOUR_VOLUME_DIFF"],
  ["six_hour_volume", "SIX_HOUR_VOLUME_DIFF"],
  ["one_day_volume", "ONE_DAY_VOLUME_DIFF"],
  ["seven_day_volume", "SEVEN_DAY_VOLUME_DIFF"],
  ["thirty_day_volume", "THIRTY_DAY_VOLUME_DIFF"],
  ["total_volume", "TOTAL_VOLUME"],
])

/**
 * This is a mapping to support the new algorithm for trending collections on the home and rankings
 * pages. It should not be used for category pages.
 */
export const QUERY_PARAM_TO_TRENDING_SORT_BY_SALES = new Map<
  string,
  TrendingCollectionSort
>([
  ["one_hour_volume", "ONE_HOUR_SALES"],
  ["six_hour_volume", "SIX_HOUR_SALES"],
  ["one_day_volume", "ONE_DAY_SALES"],
  ["seven_day_volume", "SEVEN_DAY_SALES"],
])

export const SORT_BY_TO_TIME_WINDOW = new Map<string, StatsTimeWindow>([
  ["one_hour_volume", "ONE_HOUR"],
  ["six_hour_volume", "SIX_HOUR"],
  ["seven_day_volume", "SEVEN_DAY"],
  ["total_volume", "ALL_TIME"],
  ["thirty_day_volume", "THIRTY_DAY"],
  ["one_day_volume", "ONE_DAY"],
])

export const IS_FLOOR_PRICE_PERCENT_CHANGE_SUPPORTED = new Map<string, boolean>(
  [
    ["one_hour_volume", true],
    ["six_hour_volume", true],
    ["one_day_volume", true],
    ["seven_day_volume", true],
    ["thirty_day_volume", false],
    ["total_volume", false],
  ],
)

export const FLOOR_PRICE_PERCENT_CHANGE_SUPPORTED_SORT_BY_VALUES = [
  "one_hour_volume",
  "six_hour_volume",
  "one_day_volume",
  "seven_day_volume",
]

/**
 * Determines whether the API supports floorPricePercentChange query for a given view
 */
export const isFloorPricePercentChangeSupported = (
  sortByValue: string,
  category: string = DEFAULT_CATEGORY,
  isWatchlistTab: boolean = false,
): boolean => {
  return (
    FLOOR_PRICE_PERCENT_CHANGE_SUPPORTED_SORT_BY_VALUES.includes(sortByValue) &&
    category == DEFAULT_CATEGORY &&
    !isWatchlistTab
  )
}

export const PAGE_SIZE = 100
export const IS_NEW_CUTOFF_DAYS = 21

export const useColumnSorts = (
  includeFloorPricePercentChange: boolean = false,
): ColumnSortsHookReturnValue => {
  const t = useTranslate("rankings")
  const columnSorts = {
    DEFAULT: {
      desktopLabel: t("columnSort.desktopLabel.default", "Default"),
      mobile: {
        label: t("columnSort.mobileLabel.default", "Default sort"),
        allowSortDescend: false,
        show: true,
      },
    },
    VOLUME: {
      desktopLabel: t("columnSort.desktopLabel.volume", "Volume"),
      mobile: {
        label: t("columnSort.mobileLabel.volume", "Volume"),
        allowSortDescend: true,
        show: true,
      },
    },
    "VOL % CHANGE": {
      desktopLabel: t("columnSort.desktopLabel.volumeChange", "% Change"),
      mobile: {
        label: t("columnSort.mobileLabel.volumeChange", "Vol. change"),
        allowSortDescend: true,
        show: true,
      },
    },
    "FLOOR PRICE": {
      desktopLabel: t("columnSort.desktopLabel.floorPrice", "Floor price"),
      mobile: {
        label: t("columnSort.mobileLabel.floorPrice", "Floor price"),
        allowSortDescend: true,
        show: true,
      },
    },
    "FP % CHANGE": {
      desktopLabel: t("columnSort.desktopLabel.floorPriceChange", "% Change"),
      mobile: {
        label: t("columnSort.mobileLabel.floorPriceChange", "FP change"),
        allowSortDescend: true,
        show: true,
      },
    },
    SALES: {
      desktopLabel: t("columnSort.desktopLabel.sales", "Sales"),
      mobile: {
        label: t("columnSort.mobileLabel.sales", "Sales"),
        allowSortDescend: true,
        show: false,
      },
    },
    "% UNIQUE OWNERS": {
      desktopLabel: t(
        "columnSort.desktopLabel.uniqueOwners",
        "% Unique owners",
      ),
      mobile: {
        label: t("columnSort.mobileLabel.uniqueOwners", "Unique owners"),
        allowSortDescend: true,
        show: false,
      },
    },
    "% ITEMS LISTED": {
      desktopLabel: t("columnSort.desktopLabel.itemsListed", "% Items listed"),
      mobile: {
        label: t("columnSort.mobileLabel.itemsListed", "Items listed"),
        allowSortDescend: true,
        show: false,
      },
    },
  } as const
  const sortKeys = Object.keys(columnSorts) as Array<ColumnSortKey>
  const visibleColumnSortKeys = sortKeys.filter(key => {
    if (key == "FP % CHANGE" && !includeFloorPricePercentChange) {
      return false
    }
    return key !== "DEFAULT"
  })

  return {
    columnSorts,
    sortKeys,
    visibleColumnSortKeys,
  }
}

export const MOCK_ROW_DATA = { windowCollectionStats: {} } as RankingsRow

export const MOCK_ITEMS = range(PAGE_SIZE).map(() => MOCK_ROW_DATA)

export function getTrendingCollectionsSortBy(
  sortBy: string,
  category?: string,
) {
  // We only want to use the new trending algorithm for the "All" category
  if (!category) {
    const value = QUERY_PARAM_TO_TRENDING_SORT_BY_SALES.get(sortBy)

    if (value) {
      return value
    }

    return QUERY_PARAM_TO_TRENDING_SORT.get(sortBy)
  }

  return QUERY_PARAM_TO_TRENDING_SORT.get(sortBy)
}

export function getTopCollectionsSortBy(sortBy: string) {
  return QUERY_PARAM_TO_TOP_SORT.get(sortBy)
}
