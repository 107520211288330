import React, { useCallback, useEffect, useRef, useState } from "react"
import useMergedRef from "@react-hook/merged-ref"
import { noop } from "lodash"
import { createPortal } from "react-dom"
import styled from "styled-components"
import { Button } from "@/design-system/Button"
import { useIsMounted } from "@/hooks/useIsMounted"
import { useIsOpen } from "@/hooks/useIsOpen"
import { usePortalNode } from "@/hooks/usePortalNode/usePortalNode"
import { IS_SERVER } from "../../constants/environment"
import { Z_INDEX } from "../../constants/zIndex"
import { Form } from "../Form"
import { Overlay } from "../Overlay"
import { ModalBody, StyledModalBody } from "./components/ModalBody"
import { ModalDialog } from "./components/ModalDialog"
import { ModalFooter } from "./components/ModalFooter"
import { ModalTitle, ModalHeader } from "./components/ModalHeader"
import type {
  ModalProps,
  ControlledModalProps,
  UncontrolledModalProps,
} from "./types"

export const isControlledModal = (
  props: ModalProps,
): props is ControlledModalProps => {
  return (props as Partial<ControlledModalProps>).isOpen !== undefined
}

const ModalBase = (props: ModalProps) => {
  const isMounted = useIsMounted()

  if (isControlledModal(props)) {
    if (IS_SERVER || !isMounted) {
      return null
    }

    return <ControlledModal {...props} />
  }

  return IS_SERVER || !isMounted ? (
    <>{props.trigger(noop)}</>
  ) : (
    <UncontrolledModal {...props} />
  )
}

const UncontrolledModal = ({
  trigger,
  children,
  initiallyOpen,
  disabled,
  onClose,
  ...baseModalProps
}: UncontrolledModalProps) => {
  const { isOpen, open, close } = useIsOpen(initiallyOpen)

  const handleClose = useCallback(() => {
    onClose?.()
    close()
  }, [close, onClose])

  if (disabled) {
    return <>{trigger(noop)}</>
  }

  return (
    <>
      {trigger(open)}
      <ControlledModal
        {...baseModalProps}
        isOpen={isOpen}
        onClose={handleClose}
      >
        {children}
      </ControlledModal>
    </>
  )
}

const ControlledModal = ({
  isOpen,
  onClose,
  parent,
  overlayRef,
  closeOnOverlayClick,
  keepMounted,
  ...rest
}: ControlledModalProps) => {
  const node = usePortalNode(parent)

  // Check if this component has a child input
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [hasInput, setHasInput] = useState(false)

  const checkIfHasInput = useCallback(() => {
    containerRef.current &&
      setHasInput(
        containerRef.current.querySelectorAll("input").length > 0 ||
          containerRef.current.querySelectorAll("textarea").length > 0,
      )
  }, [])

  useEffect(() => {
    if (isOpen) {
      checkIfHasInput()
    }
  }, [isOpen, checkIfHasInput])

  const setContainerRef = (ref: HTMLDivElement | null) => {
    containerRef.current = ref
    checkIfHasInput()
  }

  const mergedContainerRef = useMergedRef(
    ...(overlayRef ? [overlayRef, setContainerRef] : [setContainerRef]),
  )

  const content = (
    <Overlay
      active={isOpen}
      backgroundColor="rgba(0, 0, 0, 0.8)"
      ref={mergedContainerRef}
      transitionDuration={0.3}
      zIndex={Z_INDEX.MODAL}
      {...rest.overrides?.Overlay?.props}
      onClick={
        closeOnOverlayClick ?? !hasInput
          ? (event: React.MouseEvent<HTMLDivElement>) => {
              event.preventDefault()
              event.stopPropagation()
              onClose?.()
            }
          : undefined
      }
    >
      {isOpen || keepMounted ? (
        <ModalDialog {...rest} onClose={onClose} />
      ) : null}
    </Overlay>
  )

  return node ? createPortal(content, node) : content
}

const ModalForm = styled(Form)`
  max-height: inherit;
  display: flex;
  flex-direction: column;

  ${StyledModalBody} {
    height: auto;
  }
`

export const Modal = Object.assign(ModalBase, {
  Header: ModalHeader,
  Title: ModalTitle,
  Body: ModalBody,
  Footer: ModalFooter,
  Form: ModalForm,
  Button,
})
