import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { Text, InlineFlex, Spinner, CenterAligned } from "@opensea/ui-kit"
import {
  graphql,
  PreloadedQuery,
  useFragment,
  usePreloadedQuery,
} from "react-relay"
import styled from "styled-components"
import { useHandleBlockchainActions } from "@/components/blockchain/BlockchainActionList"
import { trackClickMax } from "@/components/nav/WalletPopover/analytics"
import { WalletPopoverTooltip } from "@/components/nav/WalletPopover/elements"
import { Currency } from "@/components/nav/WalletPopover/types"
import { useNativeCurrencyBalance } from "@/containers/WalletBalanceProvider/NativeCurrencyBalanceProvider.react"
import { Button } from "@/design-system/Button"
import { useTranslate } from "@/hooks/useTranslate"
import { BridgeOrWrapFormMaxButton_data$key } from "@/lib/graphql/__generated__/BridgeOrWrapFormMaxButton_data.graphql"
import { BridgeOrWrapFormQuery } from "@/lib/graphql/__generated__/BridgeOrWrapFormQuery.graphql"
import { isPolygon, isSolana } from "@/lib/helpers/chainUtils"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"
import { themeVariant } from "@/styles/styleUtils"
import {
  BRIDGE_OR_WRAP_FORM_QUERY,
  BridgeOrWrapDirection,
} from "../BridgeOrWrapForm.react"
import { useMemoizedEstimateGasForSwap } from "../hooks/useMemoizedEstimateGasForSwap"

type BridgeOrWrapFormMaxButtonBaseProps = {
  queryReference: PreloadedQuery<BridgeOrWrapFormQuery>
  direction: BridgeOrWrapDirection
  setMax: (max: BigNumber) => unknown
  onClick: (max: BigNumber) => unknown
  baseCurrency: Currency
  wrappedCurrency: Currency
  isAtMax: boolean
}

const BridgeOrWrapFormMaxButtonBase = ({
  queryReference,
  direction,
  setMax: setMaxProp,
  onClick: onClickProp,
  baseCurrency,
  wrappedCurrency,
  isAtMax,
}: BridgeOrWrapFormMaxButtonBaseProps) => {
  const t = useTranslate("components")
  const { estimateGas } = useHandleBlockchainActions()

  const estimateGasForSwap = useMemoizedEstimateGasForSwap()

  const data = usePreloadedQuery(BRIDGE_OR_WRAP_FORM_QUERY, queryReference)

  const {
    wallet: { baseCurrencyFunds, wrappedCurrencyFunds },
  } = useFragment<BridgeOrWrapFormMaxButton_data$key>(
    graphql`
      fragment BridgeOrWrapFormMaxButton_data on Query
      @argumentDefinitions(
        address: { type: "AddressScalar!" }
        baseCurrencySymbol: { type: "String!" }
        baseCurrencyChain: { type: "ChainScalar!" }
        wrappedCurrencySymbol: { type: "String!" }
        wrappedCurrencyChain: { type: "ChainScalar!" }
        baseCurrencyIsChainNativeCurrency: { type: "Boolean!" }
      ) {
        wallet(address: $address) {
          baseCurrencyFunds: fundsOf(
            symbol: $baseCurrencySymbol
            chain: $baseCurrencyChain
          ) @skip(if: $baseCurrencyIsChainNativeCurrency) {
            quantity
          }
          wrappedCurrencyFunds: fundsOf(
            symbol: $wrappedCurrencySymbol
            chain: $wrappedCurrencyChain
          ) {
            quantity
          }
        }
      }
    `,
    data,
  )

  const { nativeCurrencyBalance } = useNativeCurrencyBalance()

  const balance = useMemo(
    () =>
      bn(
        direction === "out"
          ? baseCurrencyFunds?.quantity ?? nativeCurrencyBalance ?? 0
          : wrappedCurrencyFunds.quantity,
      ),
    [
      baseCurrencyFunds?.quantity,
      direction,
      nativeCurrencyBalance,
      wrappedCurrencyFunds.quantity,
    ],
  )

  const [isAdjustingForGas, setIsAdjustingForGas] = useState(false)
  const [isAdjustedForGas, setIsAdjustedForGas] = useState(false)
  const [max, setMaxState] = useState(balance)
  const setMax = useCallback(
    (max: BigNumber) => {
      setMaxState(max)
      setMaxProp(max)
    },
    [setMaxProp],
  )

  const isMaxZero = max.isZero()
  const isMaxNegative = max.isNegative()

  const adjustMaxForGasEstimate = useCallback(async (): Promise<BigNumber> => {
    if (
      balance.isZero() || // No need to adjust for gas
      baseCurrency.chain !== wrappedCurrency.chain || // Do not attempt to estimate gas if switching chains is needed
      direction === "in" || // Do not adjust for gas since gas is paid in native currency
      isSolana(baseCurrency.chain) // Gas estimation on solana is not currently implemented
    ) {
      setIsAdjustedForGas(false)
      setMax(balance)
      return balance
    }

    setIsAdjustingForGas(true)

    const gasEstimate = await estimateGasForSwap({
      direction,
      baseCurrency: {
        symbol: baseCurrency.symbol,
        chain: baseCurrency.chain,
      },
      swapCurrency: {
        symbol: wrappedCurrency.symbol,
        chain: wrappedCurrency.chain,
      },
      estimateGas,
    })

    const max = balance.minus(gasEstimate)
    setMax(max)

    setIsAdjustedForGas(true)
    setIsAdjustingForGas(false)

    return max
  }, [
    balance,
    baseCurrency.chain,
    baseCurrency.symbol,
    wrappedCurrency.chain,
    wrappedCurrency.symbol,
    direction,
    estimateGasForSwap,
    estimateGas,
    setMax,
  ])

  useEffect(() => {
    adjustMaxForGasEstimate()
  }, [adjustMaxForGasEstimate])

  const onClick = async () => {
    trackClickMax()
    onClickProp(max)
  }

  const renderTooltipContent = () => {
    if (balance.isZero()) {
      return null
    }

    if (isAtMax) {
      if (direction === "out") {
        return (
          <>
            {isPolygon(wrappedCurrency.chain)
              ? t(
                  "bridgeOrWrapForm.max.atMaxBridge",
                  "This is the max you can bridge",
                )
              : t(
                  "bridgeOrWrapForm.max.atMaxWrap",
                  "This is the max you can wrap",
                )}
            {!isPolygon(wrappedCurrency.chain) && (
              <>
                <br />
                {t(
                  "bridgeOrWrapForm.max.gasFees",
                  "(leaving enough funds to pay for gas fees)",
                )}
              </>
            )}
          </>
        )
      } else {
        return (
          <>
            {t(
              "bridgeOrWrapForm.max.atMaxIn",
              "This is the max you can unwrap",
            )}
          </>
        )
      }
    }

    if ((isMaxZero || isMaxNegative) && direction === "out") {
      t(
        "bridgeOrWrapForm.max.notEnoughFundsForGas",
        "You don't have enough funds to cover gas fees",
      )
    }

    return (
      <>
        {t("bridgeOrWrapForm.max.setToMax", "Set to max")}
        <br />
        {isAdjustingForGas
          ? t(
              "bridgeOrWrapForm.max.setToMaxEstimating",
              "(estimating gas fees...)",
            )
          : isAdjustedForGas
          ? t(
              "bridgeOrWrapForm.max.gasFees",
              "(leaving enough funds to pay for gas fees)",
            )
          : null}
      </>
    )
  }

  return (
    <WalletPopoverTooltip content={renderTooltipContent()} placement="top-end">
      <InlineFlex>
        <StyledButton
          disabled={isMaxZero || isMaxNegative || isAtMax}
          size="small"
          variant="secondary"
          onClick={onClick}
        >
          <Text.Body size="small" weight="semibold">
            {t("bridgeOrWrapForm.max.label", "Max")}
          </Text.Body>
        </StyledButton>
      </InlineFlex>
    </WalletPopoverTooltip>
  )
}

const BridgeOrWrapFormMaxButtonSkeleton = () => (
  <CenterAligned className="absolute right-0 h-full">
    <StyledButton disabled size="small" variant="secondary">
      <CenterAligned className="h-5 w-[3ch]">
        <Spinner />
      </CenterAligned>
    </StyledButton>
  </CenterAligned>
)

type BridgeOrWrapFormMaxButtonProps = Omit<
  BridgeOrWrapFormMaxButtonBaseProps,
  "queryReference"
> & {
  queryReference: PreloadedQuery<BridgeOrWrapFormQuery> | undefined | null
}

export const BridgeOrWrapFormMaxButton = ({
  queryReference,
  ...restProps
}: BridgeOrWrapFormMaxButtonProps) => {
  if (!queryReference) {
    return <BridgeOrWrapFormMaxButtonSkeleton />
  }

  return (
    <Suspense fallback={<BridgeOrWrapFormMaxButtonSkeleton />}>
      <BridgeOrWrapFormMaxButtonBase
        queryReference={queryReference}
        {...restProps}
      />
    </Suspense>
  )
}

const StyledButton = styled(Button)`
  ${props =>
    themeVariant({
      variants: {
        dark: {
          backgroundColor:
            props.theme.colors.components.elevation.level1.regular.background,
        },
      },
    })}
`
