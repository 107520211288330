import React from "react"
import { Flex, Media, classNames } from "@opensea/ui-kit"
import { NavSearch } from "@/components/nav/NavSearch.react"
import { Block } from "@/design-system/Block"
import { useTranslate } from "@/hooks/useTranslate"
import { NavItem } from "../../NavItem.react"

export const NavbarCenter = ({
  closeSearchMobile,
  isActiveAccountBanned,
  isMobileSearchBarOpen,
  isTransparent,
  searchQuery,
  searchRef,
  visibleBannerHeight,
}: {
  closeSearchMobile: () => void
  isActiveAccountBanned: boolean
  isMobileSearchBarOpen: boolean
  isTransparent: boolean
  searchQuery?: string
  searchRef: React.RefObject<HTMLInputElement>
  visibleBannerHeight: number
}) => {
  const t = useTranslate("components")

  return (
    <Block
      maxWidth={{
        xl: "480px",
        md: "100%",
      }}
      width="100%"
    >
      {/* Searchbar Mobile */}
      <Media lessThan="lg">
        <Block display={isMobileSearchBarOpen ? "flex" : "none"} width="100%">
          {isMobileSearchBarOpen && (
            <NavItem
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className="Navbar--close-search-bar pl-2.5"
              icon="chevron_left"
              iconClassName="!text-secondary"
              iconTitle={t("nav.closeSearch.title", "Close search bar")}
              isRoot
              onClick={closeSearchMobile}
            />
          )}
          {!isActiveAccountBanned ? (
            <NavSearch
              bannerHeight={visibleBannerHeight}
              closeSearchMobile={closeSearchMobile}
              inputRef={searchRef}
              isBackgroundTransparent={false}
              key={searchQuery}
              query={searchQuery || ""}
            />
          ) : null}
        </Block>
      </Media>

      {/* Searchbar Desktop */}
      {!isActiveAccountBanned ? (
        <Media greaterThanOrEqual="lg">
          {mediaClassNames => (
            <Flex
              className={classNames(
                "h-full w-full items-center",
                mediaClassNames,
              )}
            >
              <NavSearch
                bannerHeight={visibleBannerHeight}
                inputRef={searchRef}
                isBackgroundTransparent={isTransparent}
                key={searchQuery}
                query={searchQuery || ""}
              />
            </Flex>
          )}
        </Media>
      ) : null}
    </Block>
  )
}
