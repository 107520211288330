/* eslint-disable tailwindcss/no-custom-classname */
import React, { useMemo, useState } from "react"
import { Flex, Text, Skeleton } from "@opensea/ui-kit"
import { useLazyLoadQuery, useRefetchableFragment } from "react-relay"
import { useInterval } from "react-use"
import styled, { css } from "styled-components"
import { AddFundsModal } from "@/components/trade/AddFundsModal"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { List } from "@/design-system/List/List.react"
import { useTranslate } from "@/hooks/useTranslate"
import { WalletFunds_data$key } from "@/lib/graphql/__generated__/WalletFunds_data.graphql"
import { WalletFundsQuery } from "@/lib/graphql/__generated__/WalletFundsQuery.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { isBSC } from "@/lib/helpers/chainUtils"
import { displayUSD } from "@/lib/helpers/numberUtils"
import { FundListItem } from "./FundListItem.react"
import { calculateFundsBalance, orderWalletFunds } from "./utils"

const REFETCH_INTERVAL = 30 * 1000 // every 30 seconds
const EMPTYLIST = [] as const

type WalletFundsViewProps = {
  data: WalletFunds_data$key | null
  address: string
}

export const WalletFundsView = ({
  data: dataKey,
  address,
}: WalletFundsViewProps) => {
  const t = useTranslate("components")
  const [data, refetch] = useRefetchableFragment(
    graphql`
      fragment WalletFunds_data on Query
      @argumentDefinitions(address: { type: "AddressScalar!" })
      @refetchable(queryName: "WalletFunds_data_query") {
        wallet(address: $address) {
          funds {
            symbol
            chain
            ...utils_calculateFundsBalance
            ...FundListItem_data
          }
        }
      }
    `,
    dataKey,
  )

  const [showAllOptions, setShowAllOptions] = useState(false)
  const funds = data?.wallet.funds ?? EMPTYLIST
  const isLoading = data?.wallet.funds === undefined
  const showViewAllTokensButton = funds.length > 3
  const totalBalance = useMemo(() => calculateFundsBalance(funds), [funds])

  const orderedFunds = useMemo(
    () => orderWalletFunds(funds).filter(data => !isBSC(data.chain)),
    [funds],
  )

  useInterval(
    () => refetch({ address }, { fetchPolicy: "network-only" }),
    REFETCH_INTERVAL,
  )

  const renderFundsList = () => {
    if (!isLoading && funds.length === 0) {
      return null
    }

    return (
      <List className="WalletFunds--list">
        {isLoading ? (
          <>
            <FundListItem.Skeleton />
            <FundListItem.Skeleton />
            <FundListItem.Skeleton />
          </>
        ) : (
          (showAllOptions ? orderedFunds : orderedFunds.slice(0, 3)).map(
            data => {
              return (
                <FundListItem
                  data={data}
                  key={`${data.symbol}-${data.chain}`}
                  refetch={() =>
                    refetch({ address }, { fetchPolicy: "network-only" })
                  }
                />
              )
            },
          )
        )}
      </List>
    )
  }

  return (
    <DivContainer $showViewAllTokensButton={showViewAllTokensButton}>
      <Block>
        <Flex className="WalletFunds--balance justify-center">
          <Block>
            <Text
              asChild
              className="mb-1.5 text-center"
              color="secondary"
              size="small"
              weight="semibold"
            >
              <p>{t("walletFunds.totalBalance.label", "Total balance")}</p>
            </Text>
            <Text.Heading asChild className="text-center" size="small">
              <h4>
                {isLoading ? (
                  <Skeleton.Line className="h-6" />
                ) : (
                  `$${displayUSD(totalBalance)} USD`
                )}
              </h4>
            </Text.Heading>
          </Block>
        </Flex>

        <AddFundsModal
          trigger={open => (
            <Button className="WalletFunds--add-funds" onClick={open}>
              {t("walletFunds.addFunds", "Add Funds")}
            </Button>
          )}
        />
      </Block>

      <Block marginTop={24}>{renderFundsList()}</Block>

      {showViewAllTokensButton && (
        <Button
          className="WalletFunds--show-more"
          variant="secondary"
          onClick={() => setShowAllOptions(prev => !prev)}
        >
          {showAllOptions
            ? t("walletFunds.showFewerTokens", "Show Fewer Tokens")
            : t("walletFunds.showAllTokens", "View All Available Tokens")}
        </Button>
      )}
    </DivContainer>
  )
}

const DivContainer = styled.div<{ $showViewAllTokensButton: boolean }>`
  ${props =>
    props.$showViewAllTokensButton &&
    css`
      .WalletFunds--list {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
      }
    `}

  .WalletFunds--show-more {
    width: 100%;
    border-radius: 0;
    border-bottom-left-radius: ${props => props.theme.borderRadius.default};
    border-bottom-right-radius: ${props => props.theme.borderRadius.default};
  }

  .WalletFunds--balance {
    border: 1px solid ${props => props.theme.colors.components.border.level2};
    border-radius: ${props => props.theme.borderRadius.default};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .WalletFunds--add-funds {
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`

type WalletFundsProps = {
  address: string
}

export const WalletFunds = ({ address }: WalletFundsProps) => {
  const data = useLazyLoadQuery<WalletFundsQuery>(
    graphql`
      query WalletFundsQuery($address: AddressScalar!) {
        ...WalletFunds_data @arguments(address: $address)
      }
    `,
    { address },
  )

  return <WalletFundsView address={address} data={data} />
}
