import type { EthereumProvider } from "@walletconnect/ethereum-provider"
import { ethers } from "ethers"
import { IS_TESTNET } from "@/constants/testnet"
import { WALLET_NAME } from "@/constants/wallet"
import { Chain } from "@/hooks/useChains/types"
import { Network } from "@/lib/graphql/__generated__/useChainsQuery.graphql"
import { getEthereumChain } from "@/lib/helpers/chainUtils"
import { WalletAccountKey } from "../../chain"
import Web3EvmProvider, {
  ETHERS_WEB3_PROVIDER_NETWORK,
  Web3Provider,
} from "../web3EvmProvider"
import {
  WALLET_CONNECT_CLOUD_PROJECT_ID,
  WALLET_CONNECT_CUSTOM_RPC_CHAINS,
} from "./constants"

type TEthereumProvider = Awaited<ReturnType<typeof EthereumProvider.init>>

class WalletConnectV2Provider extends Web3EvmProvider {
  ethereumProvider: TEthereumProvider
  provider: Web3Provider

  constructor(ethereumProvider: TEthereumProvider, chains: Chain[]) {
    super(chains)
    this.ethereumProvider = ethereumProvider
    this.provider = new ethers.providers.Web3Provider(
      this.ethereumProvider,
      ETHERS_WEB3_PROVIDER_NETWORK,
    )
  }

  connect = async () => {
    await this.ethereumProvider.connect()
    const _accounts = await this.ethereumProvider.enable()
    return this.mapToAccounts(_accounts)
  }

  disconnect = () => {
    return this.ethereumProvider.disconnect()
  }

  getAccounts = async (): Promise<WalletAccountKey[]> => {
    if (!this.ethereumProvider.connected) {
      await this.connect()
    }
    const addresses = await this.provider.listAccounts()
    return this.mapToAccounts(addresses)
  }

  getName = () => {
    return WALLET_NAME.WalletConnect
  }

  public async signTypedData(message: string, address: string) {
    const signature = await this.request<string>("eth_signTypedData_v4", [
      address,
      message,
    ])

    // Version of signature should be 27 or 28 for Ethereum signatures
    let v = parseInt(signature.slice(-2), 16)
    if (v < 27) {
      v += 27
    }
    const normalizedSignature = signature.slice(0, -2) + v.toString(16)
    return normalizedSignature
  }
}

export const createWalletConnectV2Provider = async (
  chains: Chain[],
): Promise<WalletConnectV2Provider> => {
  const { EthereumProvider } = await import("@walletconnect/ethereum-provider")

  const network: Network = IS_TESTNET ? "TESTNET" : "MAINNET"
  const ethereumChain = chains.find(
    chain => chain.identifier === getEthereumChain(),
  )
  if (!ethereumChain) {
    throw new Error("Ethereum chain not found")
  }

  // TODO: Provide as field in Chain in BE, e.g. `walletConnectRpcMapRequired`.
  // Must provide custom RPCs for chains not supported by WalletConnect:
  // https://github.com/WalletConnect/blockchain-api/blob/master/SUPPORTED_CHAINS.md
  const rpcMap = WALLET_CONNECT_CUSTOM_RPC_CHAINS.reduce(
    (rpcMap, customRpcChain) => {
      const chain = chains.find(chain => chain.identifier === customRpcChain)
      if (chain?.networkId && chain.publicRpcUrl) {
        rpcMap[chain.networkId] = chain.publicRpcUrl
      }
      return rpcMap
    },
    {} as Record<string, string>,
  )

  const ethereumProvider = await EthereumProvider.init({
    projectId: WALLET_CONNECT_CLOUD_PROJECT_ID,
    // All OS supported chains are optional
    optionalChains: [
      // Fixed first element to satisfy WalletConnect types
      Number(ethereumChain.networkId),
      ...chains
        .filter(chain => chain.isEvm && chain.network === network)
        .map(chain => Number(chain.networkId))
        .filter(networkId => networkId !== Number(ethereumChain.networkId)),
    ],
    showQrModal: true,
    qrModalOptions: {
      privacyPolicyUrl: "https://opensea.io/privacy",
      termsOfServiceUrl: "https://opensea.io/tos",
      themeMode: "light",
      themeVariables: {
        "--wcm-z-index": "9999",
      },
    },
    rpcMap,
  })

  return new WalletConnectV2Provider(ethereumProvider, chains)
}
