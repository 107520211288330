import React, { Suspense } from "react"
import {
  graphql,
  PreloadedQuery,
  useFragment,
  usePreloadedQuery,
} from "react-relay"
import { NativeFundsDisplay } from "@/components/nav/WalletPopover/components/WalletAndAccountButton/components/NativeFundsDisplay.react"
import { BridgeOrWrapBalancesFundsDisplay_data$key } from "@/lib/graphql/__generated__/BridgeOrWrapBalancesFundsDisplay_data.graphql"
import { BridgeOrWrapBalancesQuery } from "@/lib/graphql/__generated__/BridgeOrWrapBalancesQuery.graphql"
import { UnreachableCaseError } from "@/lib/helpers/type"
import { FundsDisplay } from "../../FundsDisplay.react"
import { BRIDGE_OR_WRAP_BALANCES_QUERY } from "../BridgeOrWrapBalances.react"

type Variant = "native" | "wrapped" | "base"

type BridgeOrWrapBalancesFundsDisplayBaseProps = {
  queryReference: PreloadedQuery<BridgeOrWrapBalancesQuery>
  variant: Variant
}

const BridgeOrWrapBalancesFundsDisplayBase = ({
  queryReference,
  variant,
}: BridgeOrWrapBalancesFundsDisplayBaseProps) => {
  const data = usePreloadedQuery(BRIDGE_OR_WRAP_BALANCES_QUERY, queryReference)

  const {
    wallet: { wrappedCurrencyFunds, baseCurrencyFunds },
  } = useFragment<BridgeOrWrapBalancesFundsDisplay_data$key>(
    graphql`
      fragment BridgeOrWrapBalancesFundsDisplay_data on Query
      @argumentDefinitions(
        address: { type: "AddressScalar!" }
        wrappedCurrencySymbol: { type: "String!" }
        wrappedCurrencyChain: { type: "ChainScalar!" }
        baseCurrencySymbol: { type: "String!" }
        baseCurrencyChain: { type: "ChainScalar!" }
        baseCurrencyIsChainNativeCurrency: { type: "Boolean!" }
      ) {
        wallet(address: $address) {
          wrappedCurrencyFunds: fundsOf(
            symbol: $wrappedCurrencySymbol
            chain: $wrappedCurrencyChain
          ) {
            ...FundsDisplay_walletFunds
          }
          baseCurrencyFunds: fundsOf(
            symbol: $baseCurrencySymbol
            chain: $baseCurrencyChain
          ) @skip(if: $baseCurrencyIsChainNativeCurrency) {
            ...FundsDisplay_walletFunds
          }
        }
      }
    `,
    data,
  )

  switch (variant) {
    case "wrapped":
      return <FundsDisplay walletFunds={wrappedCurrencyFunds} />
    case "base":
      if (!baseCurrencyFunds) {
        return <BridgeOrWrapBalancesFundsDisplaySkeleton />
      }
      return <FundsDisplay walletFunds={baseCurrencyFunds} />
    case "native":
      return <NativeFundsDisplay />
    default:
      throw new UnreachableCaseError(variant)
  }
}

const BridgeOrWrapBalancesFundsDisplaySkeleton = FundsDisplay.Skeleton

type BridgeOrWrapBalancesFundsDisplayProps = Omit<
  BridgeOrWrapBalancesFundsDisplayBaseProps,
  "queryReference"
> & {
  queryReference: PreloadedQuery<BridgeOrWrapBalancesQuery> | undefined | null
}

export const BridgeOrWrapBalancesFundsDisplay = ({
  queryReference,
  ...restProps
}: BridgeOrWrapBalancesFundsDisplayProps) => {
  if (!queryReference) {
    return <BridgeOrWrapBalancesFundsDisplaySkeleton />
  }

  return (
    <Suspense fallback={<BridgeOrWrapBalancesFundsDisplaySkeleton />}>
      <BridgeOrWrapBalancesFundsDisplayBase
        queryReference={queryReference}
        {...restProps}
      />
    </Suspense>
  )
}
