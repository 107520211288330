import { graphql } from "relay-runtime"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { collectionRecentViews_data$data } from "@/lib/graphql/__generated__/collectionRecentViews_data.graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { getCollectionUrl } from "@/lib/helpers/collection"
import { createRecentViewsEntity } from "./createRecentViewsEntity"

export type CollectionRecentView = {
  slug: string
  collectionUrl: string
  logo: string | null
  imageUrl?: string | null
  isVerified: boolean
  name: string
  relayId: string
  statsV2: {
    totalSupply: number
  }
  defaultChain: {
    identifier: ChainIdentifier
  }
}

export const readCollectionRecentViews = inlineFragmentize<
  collectionRecentViews_data$data,
  CollectionRecentView
>(
  graphql`
    fragment collectionRecentViews_data on CollectionType @inline {
      relayId
      slug
      logo
      isVerified
      name
      statsV2 {
        totalSupply
      }
      defaultChain {
        identifier
      }
      ...collection_url
    }
  `,
  data => ({
    relayId: data.relayId,
    slug: data.slug,
    logo: data.logo,
    isVerified: data.isVerified,
    name: data.name,
    statsV2: {
      totalSupply: data.statsV2.totalSupply,
    },
    defaultChain: {
      identifier: data.defaultChain.identifier,
    },
    collectionUrl: getCollectionUrl(data),
  }),
)

export const {
  useEntityRecentViews: useCollectionRecentViews,
  EntityRecentViewsProvider: CollectionRecentViewsProvider,
} = createRecentViewsEntity<CollectionRecentView>({
  // NOTE: this is named recentViews rather than collectorRecentViews for
  // backwards compatibility with the old useRecentViews hook. Moving forward,
  // these should be properly namespaced.
  storageId: "recentViews",
  itemIdKey: "relayId",
})
