import React, { useState } from "react"
import {
  CenterAligned,
  Flex,
  FlexColumn,
  Icon,
  Spinner,
  Text,
} from "@opensea/ui-kit"
import { QRCodeSVG } from "qrcode.react"
import { TextCopierButton } from "@/components/common/TextCopierButton.react"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { MFA_STEPS } from "@/features/os-wallet/components/constants"
import { useAuthenticateOSWallet } from "@/features/os-wallet/hooks/useOpenOSWalletAfterLogin"
import { useOSWalletAuth } from "@/features/os-wallet/hooks/useOSWalletAuth"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { OSWalletModalBody } from "./OSWalletModalBody.react"
import { SetupAuthCode } from "./SetupAuthCode.react"
import { WalletMfaEnrollmentModalProps } from "./WalletMfaEnrollmentModal.react"

export const WalletTotpCodeModal = ({
  accessToken,
  privyId,
}: WalletMfaEnrollmentModalProps) => {
  const t = useTranslate("common")
  const authenticateOSWallet = useAuthenticateOSWallet()
  const { onPrevious } = useMultiStepFlowContext()
  const { initMfaEnrollmentWithTotp, submitMfaEnrollmentWithTotp } =
    useOSWalletAuth()
  const [loading, setLoading] = useState<boolean>(false)
  const [totpData, setTotpData] = useState<{
    authUrl: string
    secret: string
  }>()
  const [error, setError] = useState<string>("")

  const getErrorMessage = (reason: string) => {
    if (reason === "MfaVerificationFailed") {
      setError(
        t(
          "wallet.opensea.totpMfa.error.verificationFailed",
          "Verification failed",
        ),
      )
    } else if (reason === "MaxMfaRetries") {
      setError(
        t("wallet.opensea.totpMfa.error.maxRetries", "Max retries reached"),
      )
    } else if (reason === "MfaTimeout") {
      setError(t("wallet.opensea.totpMfa.error.timeout", "Timeout reached"))
    } else {
      setError(
        t("wallet.opensea.totpMfa.error.unknown", "Something went wrong"),
      )
    }
  }

  useMountEffect(() => {
    const getTotpData = async () => {
      try {
        const { authUrl, secret } = await initMfaEnrollmentWithTotp()
        // TODO: Use secret above directly instead of parsing it from authUrl
        //  after Privy is updated to no longer encode the secret.
        const searchParams = new URL(authUrl).searchParams
        setTotpData({ authUrl, secret: searchParams.get("secret") ?? secret })
      } catch (error) {
        getErrorMessage(error.reason)
      }
    }
    getTotpData()
  })

  const handleAuthCode = async (authCode: string) => {
    setError("")
    setLoading(true)
    try {
      await submitMfaEnrollmentWithTotp(authCode)
      await authenticateOSWallet({
        accessToken,
        privyId,
        shouldOpenWalletAfterAuth: false,
      })
    } catch (error) {
      getErrorMessage(error.reason)
    } finally {
      setLoading(false)
    }
  }

  return (
    <OSWalletModalBody
      className="justify-between"
      showLogo={false}
      step={2}
      steps={MFA_STEPS["totp"]}
      title={t(
        "wallet.opensea.scanQRCode",
        "Scan QR code using an authenticator app",
      )}
      onPrevious={onPrevious}
    >
      <FlexColumn className="mt-2 min-h-[180px] items-center">
        {totpData ? (
          <>
            <CenterAligned className="mb-8 self-center rounded-xl bg-white p-3">
              <QRCodeSVG
                className="shadow-elevation-1"
                size={100}
                value={totpData.authUrl}
              />
            </CenterAligned>
            <SetupAuthCode
              disabled={loading}
              error={!!error}
              onComplete={handleAuthCode}
            />
            <TextCopierButton className="mt-1" text={totpData.secret}>
              <Text className="mt-2" color="secondary" size="tiny">
                {t(
                  "wallet.opensea.mfa.authenticator.copyKeyCode",
                  "Can't scan? Copy the key:",
                )}
                <div>{totpData.secret}</div>
              </Text>
            </TextCopierButton>
            {error && (
              <Text.Body color="error" size="tiny">
                {error}
              </Text.Body>
            )}
          </>
        ) : (
          <CenterAligned className="h-[100px] w-[100px] self-center rounded-xl bg-white p-3 dark:bg-component-gray-1">
            <Spinner />
          </CenterAligned>
        )}
        <Flex className="mt-4 items-center rounded-lg border-level-2 bg-white  p-2 shadow-elevation-2 dark:bg-component-gray-1">
          <div className="pr-2">
            <Icon color="warning" size={24} value="warning" />
          </div>
          <Text.Body color="secondary" size="tiny" weight="semibold">
            {t(
              "wallet.opensea.mfa.losingAuthMethodWarning",
              "Losing your authentication method without exporting your private keys will result in account access loss.",
            )}
          </Text.Body>
        </Flex>
      </FlexColumn>
    </OSWalletModalBody>
  )
}
