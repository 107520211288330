import React from "react"
import { Text, FlexColumn } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { Link } from "@/components/common/Link"
import { useChains } from "@/hooks/useChains"
import { useTranslate } from "@/hooks/useTranslate"
import { SwapDataHeader_swap$key } from "@/lib/graphql/__generated__/SwapDataHeader_swap.graphql"
import { truncateAddress } from "@/lib/helpers/address"
import { SwapDataSide } from "./SwapDataSide.react"

type Props = {
  data: SwapDataHeader_swap$key
  transactionHash?: string
}

export const SwapDataHeader = ({ data: dataKey, transactionHash }: Props) => {
  const t = useTranslate("components")
  const { getTransactionUrl, getBlockExplorerName } = useChains()
  const swap = useFragment(
    graphql`
      fragment SwapDataHeader_swap on SwapDataType {
        maker {
          address
          displayName
        }
        taker {
          address
          displayName
        }
        makerAssets {
          asset {
            chain {
              identifier
            }
          }
          ...SwapDataSide_assets
        }
        takerAssets {
          ...SwapDataSide_assets
        }
      }
    `,
    dataKey,
  )
  const { maker, taker, makerAssets, takerAssets } = swap
  const isLoading = Boolean(transactionHash)
  const chain = makerAssets[0].asset.chain.identifier
  return (
    <FlexColumn>
      <Text.Body
        className="mb-4"
        data-testid="maker-name"
        size="medium"
        weight="semibold"
      >
        {maker.displayName ?? truncateAddress(maker.address)}
      </Text.Body>
      <SwapDataSide assets={makerAssets} isLoading={isLoading} side="maker" />
      <Text.Body
        className="mb-4 mt-6"
        data-testid="taker-name"
        size="medium"
        weight="semibold"
      >
        {taker.displayName ?? truncateAddress(taker.address)}
      </Text.Body>
      <SwapDataSide assets={takerAssets} isLoading={isLoading} side="taker" />
      {transactionHash ? (
        <Text.Body className="mt-4" size="small">
          <Link href={getTransactionUrl(chain, transactionHash)}>
            {t("explorerLink", "View on {{explorerLink}}", {
              explorerLink: getBlockExplorerName(chain),
            })}
          </Link>
        </Text.Body>
      ) : null}
    </FlexColumn>
  )
}
