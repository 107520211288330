import { useRef, useState } from "react"
import { useMountEffect } from "./useMountEffect"

export const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState(false)

  useMountEffect(() => {
    setIsMounted(true)
    return () => setIsMounted(false)
  })

  return isMounted
}

export const useIsMountedRef = () => {
  const isMounted = useRef(false)

  useMountEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  })

  return isMounted
}
