/**
 * @generated SignedSource<<622a34e6300739ff1f52ff8391bceffa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VerificationStatus = "MINTABLE" | "SAFELISTED" | "UNAPPROVED" | "VERIFIED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CollectionLink_collection$data = {
  readonly name: string;
  readonly slug: string;
  readonly verificationStatus: VerificationStatus;
  readonly " $fragmentSpreads": FragmentRefs<"collection_url">;
  readonly " $fragmentType": "CollectionLink_collection";
};
export type CollectionLink_collection$key = {
  readonly " $data"?: CollectionLink_collection$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionLink_collection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionLink_collection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "verificationStatus",
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "collection_url",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCategory",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};
})();

(node as any).hash = "87d969bb78bb155b8e2d4e28b9467dcd";

export default node;
