import { useCallback } from "react"
import { ScrollingPaginatorProps } from "@/design-system/ScrollingPaginator"

export const getLoadMoreFromPagination =
  (pagination?: Omit<ScrollingPaginatorProps, "intersectionObserver">) =>
  async () => {
    if (pagination) {
      if (pagination.page.hasMore() && !pagination.page.isLoading()) {
        pagination.onLoadStart?.()
        await pagination.page.loadMore(pagination.size)
        pagination.onLoad?.()
      }
    }
  }

export const useLoadMoreFromPagination = (
  pagination?: Omit<ScrollingPaginatorProps, "intersectionObserver">,
): (() => Promise<void>) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(getLoadMoreFromPagination(pagination), [pagination])
}
