/**
 * @generated SignedSource<<87db9344c4c84b5e7db60a158767796a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type readOrderDataToFillPrices_orderDataToFill$data = {
  readonly itemFillAmount: string;
  readonly orderData: {
    readonly payment: {
      readonly " $fragmentSpreads": FragmentRefs<"TokenPricePayment">;
    };
    readonly perUnitPrice: {
      readonly unit: string;
      readonly usd: string;
    };
  };
  readonly " $fragmentType": "readOrderDataToFillPrices_orderDataToFill";
};
export type readOrderDataToFillPrices_orderDataToFill$key = {
  readonly " $data"?: readOrderDataToFillPrices_orderDataToFill$data;
  readonly " $fragmentSpreads": FragmentRefs<"readOrderDataToFillPrices_orderDataToFill">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readOrderDataToFillPrices_orderDataToFill"
};

(node as any).hash = "8afabe3c2c93ef27ddc32c833ea50218";

export default node;
