/**
 * @generated SignedSource<<3f8494502e1731758936c3ebc742e51b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useHandleBlockchainActions_set_creator_fees$data = {
  readonly method: {
    readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_transaction">;
  };
  readonly " $fragmentType": "useHandleBlockchainActions_set_creator_fees";
};
export type useHandleBlockchainActions_set_creator_fees$key = {
  readonly " $data"?: useHandleBlockchainActions_set_creator_fees$data;
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_set_creator_fees">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useHandleBlockchainActions_set_creator_fees"
};

(node as any).hash = "963c9446ae16c30e68487e007755c2e6";

export default node;
