import React, { useEffect } from "react"
import { DndContext, useDraggable } from "@dnd-kit/core"
import { Media, useIsLessThanBreakpoint } from "@opensea/ui-kit"
import { useWindowSize } from "react-use"
import { DRAWER_WIDTH_FULLSCREEN } from "@/design-system/Drawer"
import { useIsMounted } from "@/hooks/useIsMounted"
import { OffsetDrawer, OffsetDrawerProps } from "./components/OffsetDrawer"
import { SwipeHandle } from "./components/SwipeHandle.react"

export const OPEN_DRAWER_THRESHOLD = 200

export type SwipeableDrawerProps = Omit<
  OffsetDrawerProps,
  "isDragging" | "xOffset"
> & {
  id: string
  setIsOpen: (isOpen: boolean) => void
}

export const SwipeableDrawerInner = ({
  id,
  isOpen,
  setIsOpen,
  isVisible: isVisibleProp,
  fullscreenBreakpoint = "lg",
  ...drawerProps
}: SwipeableDrawerProps) => {
  const isMobile = useIsLessThanBreakpoint(fullscreenBreakpoint)
  const { width: windowWidth } = useWindowSize()
  const draggableProps = useDraggable({
    id,
  })

  const { isDragging, transform } = draggableProps
  const swipeOffset = transform?.x ? Math.min(0, transform.x) : 0
  const minDrawerOffset = isMobile ? -windowWidth : -DRAWER_WIDTH_FULLSCREEN
  const drawerOffset = Math.max(swipeOffset, minDrawerOffset)

  useEffect(() => {
    if (swipeOffset === 0) {
      return
    }
    const isSwipeOffsetPastThreshold =
      Math.abs(swipeOffset) >= OPEN_DRAWER_THRESHOLD
    setIsOpen(isSwipeOffsetPastThreshold)

    // Don't re-run when `setIsOpen` is updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swipeOffset])

  return (
    <>
      <Media lessThan="xl">
        {mediaClassName =>
          !isOpen && (
            <SwipeHandle {...draggableProps} className={mediaClassName} />
          )
        }
      </Media>
      <OffsetDrawer
        {...drawerProps}
        isDragging={isDragging}
        isOpen={isOpen}
        isVisible={isDragging || isOpen || isVisibleProp}
        transitionDuration={isDragging ? 0 : undefined}
        xOffset={drawerOffset}
      />
    </>
  )
}

export const SwipeableDrawer = (props: SwipeableDrawerProps) => {
  const isMounted = useIsMounted()

  if (!isMounted) {
    return null
  }

  return (
    <DndContext>
      <SwipeableDrawerInner {...props} />
    </DndContext>
  )
}
