import type { _FragmentRefs } from "relay-runtime"
import type { VerificationStatus } from "@/lib/graphql/__generated__/ItemType.graphql"

const STATUSES_WITH_BADGE = ["VERIFIED", "MINTABLE"] as const

export type VerificationStatusWithBadge = (typeof STATUSES_WITH_BADGE)[number]

export const verificationStatusHasBadge = (
  status: VerificationStatus,
): status is VerificationStatusWithBadge => {
  return STATUSES_WITH_BADGE.includes(status as VerificationStatusWithBadge)
}

const SAFELISTED_STATUSES = ["VERIFIED", "SAFELISTED"] as const

type SafelistedStatus = (typeof SAFELISTED_STATUSES)[number]

export const isSafelisted = (
  status: VerificationStatus | undefined,
): status is SafelistedStatus => {
  if (!status) {
    return false
  }

  return SAFELISTED_STATUSES.includes(status as SafelistedStatus)
}

export type { VerificationStatus }
