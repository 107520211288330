import React from "react"
import {
  classNames,
  Icon,
  IconButton,
  IconButtonProps,
  IconProps,
} from "@opensea/ui-kit"

type Props = {
  className?: string
  icon: IconProps["value"]
} & IconButtonProps

export const WalletModalHeaderButton = ({
  className,
  icon,
  onClick,
  ...props
}: Props) => {
  return (
    <IconButton
      className={classNames(
        "h-9 w-9 bg-black/40 text-white backdrop-blur-sm hover:bg-black/50 active:bg-black/40",
        className,
      )}
      onClick={onClick}
      {...props}
    >
      <Icon size={24} value={icon} />
    </IconButton>
  )
}
