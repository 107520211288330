import React, { Suspense } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { BannedAccountWalletButtonQuery } from "@/lib/graphql/__generated__/BannedAccountWalletButtonQuery.graphql"
import {
  WalletAndAccountButton,
  WalletAndAccountButtonProps,
} from "../../WalletPopover/components/WalletAndAccountButton/WalletAndAccountButton.react"

type LazyBannedAccountWalletButtonProps = Pick<
  WalletAndAccountButtonProps,
  "background"
> & {
  connectedAddress: string
}

const LazyBannedAccountWalletButton = ({
  connectedAddress,
  background,
}: LazyBannedAccountWalletButtonProps) => {
  const {
    getAccount: { isWalletBanned, isEmbeddedWallet },
  } = useLazyLoadQuery<BannedAccountWalletButtonQuery>(
    graphql`
      query BannedAccountWalletButtonQuery($address: AddressScalar!) {
        getAccount(address: $address) {
          isWalletBanned
          isEmbeddedWallet
        }
      }
    `,
    { address: connectedAddress },
  )

  return !isWalletBanned && isEmbeddedWallet ? (
    <WalletAndAccountButton
      background={background}
      hideBalances
      visibleModules={["wallet"]}
    />
  ) : null
}

export const BannedAccountWalletButton = ({
  background,
}: Pick<WalletAndAccountButtonProps, "background">) => {
  const connectedAddress = useConnectedAddress()
  return connectedAddress ? (
    <Suspense>
      <LazyBannedAccountWalletButton
        background={background}
        connectedAddress={connectedAddress}
      />
    </Suspense>
  ) : null
}
