/**
 * @generated SignedSource<<055bf9818d42f57e90fa564fff403a67>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderSide = "ASK" | "BID" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OrderDataHeader_order$data = {
  readonly closedAt: string;
  readonly englishAuctionReservePrice: {
    readonly unit: string;
  } | null;
  readonly isCounterOrder: boolean;
  readonly item: {
    readonly __typename: "AssetQuantityDataType";
    readonly asset: {
      readonly " $fragmentSpreads": FragmentRefs<"ConfirmationItem_assets">;
    };
    readonly quantity: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
  readonly openedAt: string;
  readonly orderCriteria: {
    readonly collection: {
      readonly " $fragmentSpreads": FragmentRefs<"CollectionOfferDetails_collection">;
    };
    readonly quantity: string | null;
    readonly trait: {
      readonly traitType: string;
      readonly value: string | null;
    } | null;
  } | null;
  readonly payment: {
    readonly " $fragmentSpreads": FragmentRefs<"ConfirmationItem_payment_asset">;
  };
  readonly perUnitPrice: {
    readonly unit: string;
  };
  readonly price: {
    readonly symbol: string;
    readonly unit: string;
    readonly usd: string;
  };
  readonly recipient: {
    readonly address: string;
  } | null;
  readonly side: OrderSide;
  readonly " $fragmentType": "OrderDataHeader_order";
};
export type OrderDataHeader_order$key = {
  readonly " $data"?: OrderDataHeader_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderDataHeader_order">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderDataHeader_order",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetType",
              "kind": "LinkedField",
              "name": "asset",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ConfirmationItem_assets"
                }
              ],
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "type": "AssetQuantityDataType",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "recipient",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "side",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "openedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "perUnitPrice",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "price",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "symbol",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "usd",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentAssetType",
      "kind": "LinkedField",
      "name": "payment",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ConfirmationItem_payment_asset"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "englishAuctionReservePrice",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCounterOrder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderCriteriaType",
      "kind": "LinkedField",
      "name": "orderCriteria",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionType",
          "kind": "LinkedField",
          "name": "collection",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CollectionOfferDetails_collection"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TraitType",
          "kind": "LinkedField",
          "name": "trait",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "traitType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "OrderDataType",
  "abstractKey": null
};
})();

(node as any).hash = "35b25dd6f226ecf63d4acb24336e24e8";

export default node;
