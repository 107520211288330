import React, { useRef } from "react"
import { useTheme as useNextTheme } from "@opensea/next-themes"
import { Switch, Label } from "@opensea/ui-kit"
import { DARK_MODE_TOGGLE_ICON_ROTATION } from "@/components/nav/constants"
import { useTheme } from "@/design-system/Context"
import { Dropdown } from "@/design-system/Dropdown"
import { useTranslate } from "@/hooks/useTranslate"
import { persistThemeSelection } from "@/styles/themes"

export const NightModeToggleItem = () => {
  const t = useTranslate("components")
  const toggleRef = useRef<HTMLButtonElement>(null)
  const { theme } = useTheme()
  const { toggleTheme } = useNextTheme()
  const isDarkMode = theme === "dark"

  const changeTheme = () => {
    persistThemeSelection(toggleTheme())
  }

  return (
    <Dropdown.Item onClick={() => toggleRef.current?.click()}>
      <Dropdown.Item.Avatar
        icon="mode_night"
        style={{ transform: DARK_MODE_TOGGLE_ICON_ROTATION }}
      />
      <Dropdown.Item.Content>
        <Dropdown.Item.Title asChild>
          <Label htmlFor="night-mode-toggle">
            {t("nav.nightMode.title", "Night Mode")}
          </Label>
        </Dropdown.Item.Title>
      </Dropdown.Item.Content>
      <Dropdown.Item.Side className="ml-4">
        <Switch
          checked={isDarkMode}
          id="night-mode-toggle"
          ref={toggleRef}
          onCheckedChange={changeTheme}
        />
      </Dropdown.Item.Side>
    </Dropdown.Item>
  )
}
