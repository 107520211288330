import { createContext, useContext } from "react"
import absoluteUrl from "next-absolute-url"
import { IS_DEVELOPMENT } from "@/constants/environment"

export type LocationContext = ReturnType<typeof absoluteUrl>

export const FRAME_SITE_URL_STRING = IS_DEVELOPMENT
  ? "http://localhost:3002"
  : "https://opensea-frames.vercel.app"
export const SITE_URL_STRING = process.env.SITE_URL ?? "http://localhost:3000"
const SITE_URL = new URL(SITE_URL_STRING)

export const DEFAULT_LOCATION_CONTEXT: LocationContext = {
  origin: SITE_URL.origin,
  host: SITE_URL.host,
  protocol: SITE_URL.protocol,
}

const LocationContext = createContext<LocationContext>(DEFAULT_LOCATION_CONTEXT)

export const useLocationContext = () => useContext(LocationContext)

export const LocationContextProvider = LocationContext.Provider
