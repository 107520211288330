/**
 * @generated SignedSource<<4cdfbffce51c3597f7b65b92b1e30e3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShoppingCart_errors$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"ShoppingCartDetailedView_errors">;
  readonly " $fragmentType": "ShoppingCart_errors";
}>;
export type ShoppingCart_errors$key = ReadonlyArray<{
  readonly " $data"?: ShoppingCart_errors$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShoppingCart_errors">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ShoppingCart_errors",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShoppingCartDetailedView_errors"
    }
  ],
  "type": "BulkPurchaseErrorType",
  "abstractKey": null
};

(node as any).hash = "52cf388f3e99e6c4934b5754c03bbfda";

export default node;
