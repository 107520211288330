/**
 * @generated SignedSource<<8a2e27c1502bf17d8f59918d5581c69b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BridgeOrWrapFormMaxButton_data$data = {
  readonly wallet: {
    readonly baseCurrencyFunds?: {
      readonly quantity: string;
    };
    readonly wrappedCurrencyFunds: {
      readonly quantity: string;
    };
  };
  readonly " $fragmentType": "BridgeOrWrapFormMaxButton_data";
};
export type BridgeOrWrapFormMaxButton_data$key = {
  readonly " $data"?: BridgeOrWrapFormMaxButton_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"BridgeOrWrapFormMaxButton_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "quantity",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "address"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baseCurrencyChain"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baseCurrencyIsChainNativeCurrency"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baseCurrencySymbol"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "wrappedCurrencyChain"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "wrappedCurrencySymbol"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "BridgeOrWrapFormMaxButton_data",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "address",
          "variableName": "address"
        }
      ],
      "concreteType": "WalletType",
      "kind": "LinkedField",
      "name": "wallet",
      "plural": false,
      "selections": [
        {
          "condition": "baseCurrencyIsChainNativeCurrency",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            {
              "alias": "baseCurrencyFunds",
              "args": [
                {
                  "kind": "Variable",
                  "name": "chain",
                  "variableName": "baseCurrencyChain"
                },
                {
                  "kind": "Variable",
                  "name": "symbol",
                  "variableName": "baseCurrencySymbol"
                }
              ],
              "concreteType": "WalletFundsType",
              "kind": "LinkedField",
              "name": "fundsOf",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ]
        },
        {
          "alias": "wrappedCurrencyFunds",
          "args": [
            {
              "kind": "Variable",
              "name": "chain",
              "variableName": "wrappedCurrencyChain"
            },
            {
              "kind": "Variable",
              "name": "symbol",
              "variableName": "wrappedCurrencySymbol"
            }
          ],
          "concreteType": "WalletFundsType",
          "kind": "LinkedField",
          "name": "fundsOf",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "ab90550d16f477ccf6d663e261abe1a3";

export default node;
