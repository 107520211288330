/**
 * @generated SignedSource<<c91c4b58aa2020475e7446c4d94ec827>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useHandleBlockchainActions_mint_asset$data = {
  readonly method: {
    readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_transaction">;
  };
  readonly startTime: string;
  readonly " $fragmentType": "useHandleBlockchainActions_mint_asset";
};
export type useHandleBlockchainActions_mint_asset$key = {
  readonly " $data"?: useHandleBlockchainActions_mint_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_mint_asset">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useHandleBlockchainActions_mint_asset"
};

(node as any).hash = "7b0fbba2233432665cae822d29eb9a8e";

export default node;
