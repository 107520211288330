/**
 * @generated SignedSource<<e511972205854d0c88a32ba774386a5d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useHandleBlockchainActionsCreateSwapOrderMutation$variables = {
  clientSignature: string;
  serverSignature: string;
  swapData: string;
};
export type useHandleBlockchainActionsCreateSwapOrderMutation$data = {
  readonly swaps: {
    readonly create: {
      readonly __typename: "SwapType";
      readonly relayId: string;
    };
  };
};
export type useHandleBlockchainActionsCreateSwapOrderMutation = {
  response: useHandleBlockchainActionsCreateSwapOrderMutation$data;
  variables: useHandleBlockchainActionsCreateSwapOrderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientSignature"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "serverSignature"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "swapData"
},
v3 = [
  {
    "kind": "Variable",
    "name": "clientSignature",
    "variableName": "clientSignature"
  },
  {
    "kind": "Variable",
    "name": "serverSignature",
    "variableName": "serverSignature"
  },
  {
    "kind": "Variable",
    "name": "swapData",
    "variableName": "swapData"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useHandleBlockchainActionsCreateSwapOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SwapMutationType",
        "kind": "LinkedField",
        "name": "swaps",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "SwapType",
            "kind": "LinkedField",
            "name": "create",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "useHandleBlockchainActionsCreateSwapOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SwapMutationType",
        "kind": "LinkedField",
        "name": "swaps",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "SwapType",
            "kind": "LinkedField",
            "name": "create",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c80c80932269d83937d0476941afb0c9",
    "id": null,
    "metadata": {},
    "name": "useHandleBlockchainActionsCreateSwapOrderMutation",
    "operationKind": "mutation",
    "text": "mutation useHandleBlockchainActionsCreateSwapOrderMutation(\n  $swapData: JSONString!\n  $clientSignature: String!\n  $serverSignature: String!\n) {\n  swaps {\n    create(swapData: $swapData, clientSignature: $clientSignature, serverSignature: $serverSignature) {\n      __typename\n      relayId\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "686b6f5bda8e146435b2a9377a7ce1f7";

export default node;
