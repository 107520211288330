import { ethers } from "ethers"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { ensAddressQuery } from "@/lib/graphql/__generated__/ensAddressQuery.graphql"
import { ensQuery } from "@/lib/graphql/__generated__/ensQuery.graphql"
import { fetch, graphql } from "@/lib/graphql/graphql"

export const resolveAddressFromEns = async (
  name: string,
  chain?: ChainIdentifier,
) => {
  const [data] = await fetch<ensQuery>(
    graphql`
      query ensQuery($name: String!, $chain: ChainScalar) {
        accountHelpers {
          resolveEnsName(name: $name)
          isDelegated(identifier: $name, chain: $chain)
        }
      }
    `,
    { name, chain },
  )
  return {
    address: data.accountHelpers.resolveEnsName ?? undefined,
    isDelegated: data.accountHelpers.isDelegated,
  }
}

export const resolveLensName = async (name: string, rpcUrl: string) => {
  const provider = new ethers.providers.JsonRpcProvider(rpcUrl)
  const address = await provider.resolveName(name + ".xyz")
  return address ?? undefined
}

export const resolveEnsFromAddress = async (name: string) => {
  const data = await fetch<ensAddressQuery>(
    graphql`
      query ensAddressQuery($identifier: String!) {
        findAccount(identifier: $identifier) {
          names {
            name
          }
        }
      }
    `,
    { identifier: name },
  )

  if (data[0].findAccount?.names.length == 0) {
    return
  }

  return data[0].findAccount?.names[0].name
}
