import { getTrackingFn } from "@/lib/analytics"
import type { ActiveAccount } from "@/lib/chain/wallet"

type WalletRedirectProps = {
  pathname: string
  nextRouterPathname: string | undefined
  nextRouterAsPath: string | undefined
  pageContextPathname: string | undefined
  pageContextAsPath: string | undefined
  isAuthenticated: boolean
  requiresWallet: boolean
  requiresAuth: boolean
  activeAccount: ActiveAccount
}

export const trackRedirectToWalletPage = getTrackingFn<WalletRedirectProps>(
  "redirect to wallet page",
)
export const trackRedirectFromWalletPage = getTrackingFn<
  WalletRedirectProps & { referrer: string }
>("redirect from wallet page")
export const trackWalletPageLoginReferrer = getTrackingFn<
  Omit<WalletRedirectProps, "requiresWallet" | "requiresAuth">
>("wallet page login referrer")
export const trackWalletRedirectLoginReferrer = getTrackingFn<{ path: string }>(
  "wallet redirect login referrer",
)
