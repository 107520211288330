import React, { createContext, useCallback, useMemo, useState } from "react"
import { noop } from "lodash"
import { BaseModalProps, BaseMultiStepModalProps } from "@/design-system/Modal"
import { useIsOpen } from "@/hooks/useIsOpen"

type BaseGlobalModalProps = (BaseModalProps | BaseMultiStepModalProps) & {
  responsive?: boolean
}
export type GlobalModalProps = Omit<BaseGlobalModalProps, "children">
type GlobalModalChildren = BaseGlobalModalProps["children"]

type GlobalModalContext = {
  isOpen: boolean
  openModal: (
    children: GlobalModalChildren,
    modalProps: GlobalModalProps,
  ) => void
  closeModal: () => void
  modalProps: BaseGlobalModalProps | undefined
}

const DEFAULT_CONTEXT: GlobalModalContext = {
  isOpen: false,
  openModal: noop,
  closeModal: noop,
  modalProps: undefined,
}

export const GlobalModalContext =
  createContext<GlobalModalContext>(DEFAULT_CONTEXT)

type GlobalModalProviderProps = {
  children: React.ReactNode
}

export const GlobalModalProvider = ({ children }: GlobalModalProviderProps) => {
  const { isOpen, open, close } = useIsOpen(false)
  const [modalProps, setModalProps] = useState<BaseGlobalModalProps>()

  const openModal = useCallback(
    (children: GlobalModalChildren, modalProps: GlobalModalProps) => {
      setModalProps({ ...modalProps, children })
      open()
    },
    [open],
  )

  const closeModal = useCallback(() => {
    close()
    setModalProps(undefined)
  }, [close])

  const value: GlobalModalContext = useMemo(
    () => ({
      isOpen,
      openModal,
      closeModal,
      modalProps,
    }),
    [isOpen, openModal, closeModal, modalProps],
  )

  return (
    <GlobalModalContext.Provider value={value}>
      {children}
    </GlobalModalContext.Provider>
  )
}
