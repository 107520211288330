import { useIsLessThanMd, useIsLessThanXl } from "@opensea/ui-kit"

export const useCarousel = () => {
  const isLarge = !useIsLessThanXl()
  const isSmall = useIsLessThanMd()

  const slidesToShow = isSmall ? 1 : isLarge ? 3 : 2
  const showArrows = !isSmall

  return { slidesToShow, showArrows }
}
