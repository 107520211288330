import React, { useCallback } from "react"
import { FlexColumn } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import {
  DeployContractProgress,
  LOADING_CONTENT_STATE,
} from "@/features/collections/components/CreateCollectionModal/components/DeployContractProgress"
import { useIsMintYourOwnEnabled } from "@/hooks/useFlag"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { trackContractDeploySignature } from "@/lib/analytics/events/primaryDropsSelfServe"
import { DeployContractAction_data$key } from "@/lib/graphql/__generated__/DeployContractAction_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import {
  BlockchainActionModalContent,
  BaseBlockchainActionProps,
} from "./BlockchainActionModalContent.react"
import { useBlockchainActionProgress } from "./useBlockchainActionProgress"
import { useHandleBlockchainActions } from "./useHandleBlockchainActions"

type Props = BaseBlockchainActionProps & {
  dataKey: DeployContractAction_data$key
}

export const DeployContractAction = ({ dataKey, onEnd }: Props) => {
  const t = useTranslate("components")
  const isMintYourOwnEnabled = useIsMintYourOwnEnabled()
  const data = useFragment(
    graphql`
      fragment DeployContractAction_data on DropContractDeployActionType {
        __typename
        ...useHandleBlockchainActions_deploy_contract
      }
    `,
    dataKey,
  )

  const { deployContract } = useHandleBlockchainActions()

  const executeAction = useCallback(async () => {
    const transaction = await deployContract(data)
    onEnd({ transaction })
  }, [data, onEnd, deployContract])

  const { attemptAction, progress } = useBlockchainActionProgress({
    executeAction,
    action: data,
  })

  useMountEffect(trackContractDeploySignature)

  if (isMintYourOwnEnabled) {
    return (
      <DeployContractProgress
        customRender={
          !progress && (
            <BlockchainActionModalContent.Body.Button onClick={attemptAction}>
              {t("blockchain.deployContract.cta", "Continue")}
            </BlockchainActionModalContent.Body.Button>
          )
        }
        state={LOADING_CONTENT_STATE.WAITING_SIGNATURE}
      />
    )
  }

  return (
    <FlexColumn data-testid="deploy-contract-action-container">
      <BlockchainActionModalContent>
        <BlockchainActionModalContent.Header>
          <BlockchainActionModalContent.Title>
            {t("blockchain.deployContract.title", "Deploy your contract")}
          </BlockchainActionModalContent.Title>
        </BlockchainActionModalContent.Header>
        <BlockchainActionModalContent.Body>
          <BlockchainActionModalContent.Body.GoToWallet />
          <BlockchainActionModalContent.Body.Text>
            {t(
              "blockchain.deployContract.prompt",
              "Approve the transaction in your wallet to complete the contract deploy.",
            )}
          </BlockchainActionModalContent.Body.Text>
          {!progress && (
            <BlockchainActionModalContent.Body.Button onClick={attemptAction}>
              {t("blockchain.deployContract.cta", "Continue")}
            </BlockchainActionModalContent.Body.Button>
          )}
        </BlockchainActionModalContent.Body>
      </BlockchainActionModalContent>
    </FlexColumn>
  )
}
