import {
  useState,
  useRef,
  MutableRefObject,
  useCallback,
  RefObject,
} from "react"

type UseElementStateOptions = {
  on: "focus" | "mouseover" | "mousedown"
  off: "blur" | "mouseout" | "mouseleave" | "mouseup"
  initialState?: boolean
  ignoreDisabled?: boolean
}

type ElementState<T> = [boolean, (node: T | null) => void, RefObject<T | null>]

export function useElementState<T extends Element>({
  on,
  off,
  initialState = false,
  ignoreDisabled = false,
}: UseElementStateOptions): ElementState<T> {
  const [value, setValue] = useState(initialState)
  const ref = useRef<T>(null) as MutableRefObject<T | null>
  const setOn = useCallback(() => setValue(true), [])
  const setOff = useCallback(() => setValue(false), [])

  const callbackRef = useCallback(
    (node: T | null) => {
      if (
        ref.current != null &&
        (ignoreDisabled ? !ref.current.hasAttribute("disabled") : true)
      ) {
        ref.current.removeEventListener(on, setOn)
        ref.current.removeEventListener(off, setOff)
      }

      ref.current = node

      if (
        ref.current != null &&
        (ignoreDisabled ? !ref.current.hasAttribute("disabled") : true)
      ) {
        ref.current.addEventListener(on, setOn)
        ref.current.addEventListener(off, setOff)
      }
    },
    [ignoreDisabled, on, setOn, off, setOff],
  )

  return [value, callbackRef, ref]
}
