import React from "react"
import * as I18nConfig from "@/i18n"

type Props = {
  origin: string
  pathname: string
}

export function SEOLanguageLinks({ origin, pathname }: Props) {
  const appliedPathname = pathname === "/" ? "" : pathname
  return (
    <>
      {I18nConfig.locales?.map(locale => (
        <link
          href={`${origin}${
            locale === "en-US" ? "" : `/${locale}`
          }${appliedPathname}`}
          hrefLang={locale === "en-US" ? "en" : locale}
          key={locale}
          rel="alternate"
        />
      ))}
    </>
  )
}
