import React from "react"
import { ibmPlexMono, inter } from "./fonts"

export const Fonts = () => {
  return (
    <>
      <style global jsx>{`
        :root {
          --font-family-inter: ${inter.style.fontFamily};
          --font-family-ibm-plex-mono: ${ibmPlexMono.style.fontFamily};
        }
      `}</style>
    </>
  )
}
