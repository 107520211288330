/* eslint-disable tailwindcss/no-custom-classname */
import React from "react"
import { Icon, Input, FlexColumn, Text, Flex } from "@opensea/ui-kit"
import { useCopyToClipboard } from "react-use"
import styled from "styled-components"
import { Link } from "@/components/common/Link"
import { Button } from "@/design-system/Button"
import { Image } from "@/design-system/Image"
import { Modal } from "@/design-system/Modal"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { Tooltip, TOOLTIP_PLACEMENT } from "@/design-system/Tooltip"
import { useAutoResetToggle } from "@/hooks/useAutoResetToggle"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { trackOpenDepositModal } from "@/lib/analytics/events/checkoutEvents"
import {
  COINBASE_LINK,
  GEMINI_LINK,
  KRAKEN_LINK,
  ETORO_LINK,
} from "../../../../constants"

type Props = {
  address: string
  symbol?: string
  renderTabMenu?: () => React.ReactNode
}

export const DepositModal = ({ address, symbol, renderTabMenu }: Props) => {
  const t = useTranslate("components")
  const { onPrevious } = useMultiStepFlowContext()
  const [_, copy] = useCopyToClipboard()
  const copyWalletAddress = () => {
    address && copy(address)
    onCopied()
  }
  const [isCopied, onCopied] = useAutoResetToggle()

  useMountEffect(() => {
    trackOpenDepositModal({ address, symbol })
  })

  const symbolText = !symbol ? "crypto" : symbol
  const displayMessage = isCopied
    ? t("trade.depositModal.copied", "Copied!")
    : t("trade.depositModal.copy", "Copy")

  return (
    <DivContainer>
      <Modal.Header leftAlign={false} onPrevious={onPrevious}>
        <Modal.Header.Title>
          {t("trade.depositModal.title", "Add funds")}
        </Modal.Header.Title>
      </Modal.Header>

      {renderTabMenu?.()}

      <Modal.Body>
        <FlexColumn className="items-center p-0 pt-2">
          <Image
            alt=""
            height={100}
            objectFit="cover"
            src="/static/images/walletImg.png"
            width={100}
          />
          <Text asChild className="my-6 text-center">
            <p>
              {t(
                "trade.depositModal.transferFunds",
                "Transfer funds from an {{exchangeLink}} or another wallet to your wallet address below:",
                {
                  exchangeLink: (
                    <Tooltip
                      content={t(
                        "trade.depositModal.transferFundsExchangeLink",
                        "An exchange allows individuals to trade cryptocurrencies. Compatible crypto exchanges include {{coinbaseLink}}, {{geminiLink}}, {{krakenLink}}, {{eToroLink}}, and many other exchanges.",
                        {
                          coinbaseLink: (
                            <Link href={COINBASE_LINK}> Coinbase</Link>
                          ),
                          geminiLink: <Link href={GEMINI_LINK}> Gemini</Link>,
                          krakenLink: <Link href={KRAKEN_LINK}> Kraken</Link>,
                          eToroLink: <Link href={ETORO_LINK}> eToro</Link>,
                        },
                      )}
                      interactive
                      placement={TOOLTIP_PLACEMENT.BOTTOM}
                    >
                      <span className="DepositModal--label">
                        {" "}
                        {t("trade.depositModal.exchange", "exchange")}
                        <span className="DepositModal--info-icon">
                          <Icon
                            className="text-blue-3"
                            size={18}
                            value="info"
                          />
                        </span>
                      </span>
                    </Tooltip>
                  ),
                },
              )}
            </p>
          </Text>
          <Flex className="w-full">
            <Input
              disabled
              id="walletAddress"
              name="walletAddress"
              value={address}
            />
            <Tooltip
              content={displayMessage}
              hideOnClick={false}
              placement="top"
            >
              <Button className="ml-4 w-1/4" onClick={copyWalletAddress}>
                {t("trade.depositModal.copy", "Copy")}
              </Button>
            </Tooltip>
          </Flex>
          {symbol == "ETH" && (
            <Text
              asChild
              className="pt-10 text-center"
              color="secondary"
              size="small"
              weight="semibold"
            >
              <p>
                {t(
                  "trade.depositModal.onlyAllowed",
                  "Only send {{symbolText}} or any other ERC-20 token to this address.",
                  { symbolText },
                )}
              </p>
            </Text>
          )}
        </FlexColumn>
      </Modal.Body>
    </DivContainer>
  )
}

const DivContainer = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 20px;
  vertical-align: middle;
  justify-content: center;
  width: 100%;

  header {
    border-bottom: none;
  }

  .DepositModal--label {
    font-weight: 600;
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
    margin: auto;
    line-height: 14px;

    &:hover {
      color: ${props => props.theme.colors.darkSeaBlue};
    }

    .DepositModal--info-icon {
      display: inline-block;
      margin: auto;
      margin-left: 2px;
      vertical-align: middle;
    }
  }

  .DepositModal--button {
    display: flex;
    justify-content: center;
  }
`
