import React, { ComponentProps, ElementRef, forwardRef } from "react"
import {
  Item as DSItem,
  ItemProps as DSItemProps,
  Flex,
  classNames,
} from "@opensea/ui-kit"
import { Link, LinkProps } from "@/components/common/Link"
import { AvatarImage } from "../Avatar"

export type ItemProps = DSItemProps &
  Pick<LinkProps, "eventParams" | "eventSource">

const ItemAvatar = (props: ComponentProps<typeof DSItem.Avatar>) => {
  return <DSItem.Avatar {...props} renderImage={AvatarImage} />
}

const RenderLink = (props: LinkProps) => {
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <Link {...props} />
}

const ItemBase = forwardRef<ElementRef<typeof DSItem>, ItemProps>(
  function ItemBase(props, ref) {
    return <DSItem renderLink={RenderLink} {...props} ref={ref} />
  },
)

// NOTE: This is not in the DS repo as it's unclear if this should even be an actual component. Follow up with design later.
const ItemFooter = ({
  children,
  className,
}: {
  children?: React.ReactNode
  className?: string
}) => {
  return (
    <Flex
      className={classNames(
        "order-6 mt-4 w-full items-center justify-between border-t border-level-2 pt-4",
        className,
      )}
    >
      {children}
    </Flex>
  )
}

export const Item = Object.assign({}, DSItem, ItemBase, {
  Avatar: ItemAvatar,
  Footer: ItemFooter,
})
