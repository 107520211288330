export const DATE_FORMAT_STRING = "MMMM do, yyyy"
export const DATETIME_FORMAT_STRING = "MMMM d, yyyy 'at' h:mm a"
export const MONTH_DATE_FORMAT_STRING = "MMMM d"
// export const SHORT_MONTH_DATE_FORMAT_STRING = "MMM d"
export const SHORT_MONTH_DATETIME_FORMAT_STRING = "MMM d 'at' h:mm a"
export const MONTH_YEAR_FORMAT_STRING = "MMMM yyyy"

export const SHORT_DATE_FORMAT_STRING = "MMM d, yyyy"
export const SHORT_MONTH_YEAR_FORMAT_STRING = "MMM y"
export const SHORT_TIME_FORMAT_STRING = "h:mm a"
