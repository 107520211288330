import React, {
  ComponentProps,
  ComponentPropsWithoutRef,
  forwardRef,
} from "react"
import * as seadn from "@opensea/seadn"
import NextImage from "next/image"
import NextLegacyImage from "next/legacy/image"
import {
  shouldUseSeadnUrl,
  resizeImage,
  ResizeImageProps,
} from "@/lib/helpers/urls"

type BaseProps = {
  resizeImageProps?: ResizeImageProps
  skipSrcset?: boolean
}

type NextLegacyImageProps = ComponentProps<typeof NextLegacyImage> & BaseProps
export type NextImageProps = ComponentPropsWithoutRef<typeof NextImage> &
  BaseProps

export type ImageProps = NextLegacyImageProps | NextImageProps

const seadnLoader: ComponentProps<typeof NextLegacyImage>["loader"] = ({
  src,
  width,
}) => {
  return seadn.resizeImage(src, { width })
}

const noSrcsetSeadnLoader: ComponentProps<typeof NextLegacyImage>["loader"] = ({
  src,
}) => {
  return src
}

const getImageComponentProps = <T extends ImageProps>({
  resizeImageProps,
  skipSrcset,
  ...props
}: T): Omit<T, "resizeImageProps" | "skipSrcset"> => {
  const { src, layout } = props

  let { width: clientWidth, height: clientHeight } = props

  const isSeadnUrl = typeof src === "string" && shouldUseSeadnUrl(String(src))
  const unoptimized =
    typeof props.unoptimized === "undefined" ? !isSeadnUrl : props.unoptimized

  if ("fill" in props || layout === "fill") {
    // Next.js doesn't support fill layout with width and height props starting from v13.
    // TODO: Refactor components that pass width and height along with fill layout to not pass them.
    clientWidth = undefined
    clientHeight = undefined
  }

  if (!isSeadnUrl) {
    // Resized image will only have its width and height set if it's path has a imgix host.
    const resizedImage = resizeImage(String(src), {
      height: Number(props.height),
      width: Number(props.width),
      ...resizeImageProps,
    })

    return {
      ...props,
      height: clientHeight,
      src: resizedImage,
      unoptimized: resizedImage !== src || unoptimized,
      width: clientWidth,
    }
  }

  const customLoader = skipSrcset ? noSrcsetSeadnLoader : seadnLoader

  return {
    ...props,
    height: clientHeight,
    loader: customLoader,
    src,
    width: clientWidth,
  }
}

/**
 * @deprecated Use Next13Image instead.
 */
const ImageBase = (props: NextLegacyImageProps) => {
  const imageComponentProps = getImageComponentProps(props)

  return <NextLegacyImage {...imageComponentProps} />
}

const Next13Image = forwardRef<HTMLImageElement, NextImageProps>(
  function Next13Image(props: NextImageProps, ref) {
    const imageComponentProps = getImageComponentProps(props)

    return <NextImage ref={ref} {...imageComponentProps} />
  },
)

export const Image = Object.assign(ImageBase, {
  Next13: Next13Image,
})
