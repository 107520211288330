import {
  METAMASK_MOBILE_URL,
  OPERA_TOUCH_URL,
  WALLET_NAME,
  TRUST_WALLET_URL,
  getWalletConfiguration,
  PHANTOM_MOBILE_URL,
  KAIKAS_MOBILE_URL,
} from "@/constants/wallet"
import { useRouter } from "@/hooks/useRouter"

/**
 * Get a logo for a wallet type
 * @param type The wallet type or type of injected (native) wallet
 * @param vertical whether to use a veritical image
 */
export function getWalletLogo(
  type: WALLET_NAME,
  displayType: "horizontal" | "vertical" = "horizontal",
): string {
  const configuration = getWalletConfiguration(type)
  return displayType === "vertical"
    ? configuration.alternativeLogo
    : configuration.logo
}

export function getMobileWalletLink(
  walletName: WALLET_NAME,
  routerInfo: ReturnType<typeof useRouter>,
) {
  switch (walletName) {
    case WALLET_NAME.MetaMask:
      return `${METAMASK_MOBILE_URL}${routerInfo.host}`
    case WALLET_NAME.Trust:
      return `${TRUST_WALLET_URL}${routerInfo.href}`
    case WALLET_NAME.OperaTouch:
      return OPERA_TOUCH_URL
    case WALLET_NAME.Phantom:
      return PHANTOM_MOBILE_URL
    case WALLET_NAME.Kaikas:
      return `${KAIKAS_MOBILE_URL}${routerInfo.host}`
    default:
      return undefined
  }
}
