import React, { CSSProperties, forwardRef, useCallback } from "react"
import {
  CenterAligned,
  Icon,
  useIsLessThanSm,
  UnstyledButton,
  FlexColumn,
  Text,
} from "@opensea/ui-kit"
import useMergedRef from "@react-hook/merged-ref"
import { rgba } from "polished"
import { DropzoneOptions, useDropzone } from "react-dropzone"
import styled, { css } from "styled-components"
import { variant } from "styled-system"
import { Z_INDEX } from "@/constants/zIndex"
import { Block } from "@/design-system/Block"
import { useTranslate } from "@/hooks/useTranslate"
import { MEGABYTE_TO_BYTES } from "@/lib/helpers/file"
import { Flex } from "../Flex"

type FileInputShape = "round" | "square"

type FileInputVariant = "default" | "overlay"

type FileInputPlaceholderVariant = "image" | "descriptive" | "add"

export type FileInputProps = Pick<
  JSX.IntrinsicElements["input"],
  "id" | "name" | "children" | "aria-label" | "className"
> & {
  preview?: React.ReactNode
  onChange?: (files: File[]) => unknown
  inputRef?:
    | React.RefObject<HTMLInputElement>
    | ((instance: HTMLInputElement) => void)
  shape?: FileInputShape
  variant?: FileInputVariant
  removable?: boolean
  extra?: React.ReactNode
  overlay?: React.ReactNode
  previewOverlay?: React.ReactNode
  disableInputOverlay?: React.ReactNode
  closeIcon?: React.ReactNode
  placeholderVariant?: FileInputPlaceholderVariant
} & {
  // TODO: Consider removing these in favor of tailwind utilities
  // in className for style overrides.
  height?: CSSProperties["height"]
  width?: CSSProperties["width"]
  minHeight?: CSSProperties["minHeight"]
  minWidth?: CSSProperties["minWidth"]
  maxWidth?: CSSProperties["maxWidth"]
  maxHeight?: CSSProperties["maxHeight"]
  zIndex?: CSSProperties["zIndex"]
} & Pick<
    DropzoneOptions,
    "accept" | "disabled" | "maxSize" | "validator" | "multiple" | "maxFiles"
  >

const useErrorCodeCopy: (_?: number) => Record<string, string> = (
  maxSize?: number,
) => {
  const t = useTranslate("designSystem")
  return {
    "file-too-large": maxSize
      ? t(
          "fileInput.largeFile.customError",
          "File is larger than {{maxSize}}MB",
          { maxSize: maxSize / MEGABYTE_TO_BYTES },
          { forceString: true },
        )
      : t("file.largeFile.error", "File is too large"),
    "file-invalid-type": t(
      "file.unsupportedFile.error",
      "Unsupported file type",
    ),
    "too-many-files": t("file.tooManyFiles.error", "Too many files"),
    "file-too-small": t("file.smallFile.error", "File is too small"),
  }
}

export const FileInput = forwardRef<HTMLDivElement, FileInputProps>(
  function FileInput(
    {
      accept,
      preview,
      onChange,
      id,
      name,
      inputRef,
      variant = "default",
      shape = "square",
      disabled = false,
      removable = true,
      extra,
      children,
      overlay,
      maxSize,
      previewOverlay,
      closeIcon,
      disableInputOverlay,
      validator,
      multiple = false,
      placeholderVariant = "image",
      maxFiles,
      height,
      width,
      maxHeight,
      maxWidth,
      minHeight,
      minWidth,
      zIndex,
      ...rest
    },
    ref,
  ) {
    const t = useTranslate("designSystem")
    const errorCopy = useErrorCodeCopy(maxSize)
    const isLessThanSm = useIsLessThanSm()

    const onDrop = useCallback(
      (acceptedFiles: File[]) => {
        onChange?.(acceptedFiles)
      },
      [onChange],
    )

    const { getRootProps, getInputProps, fileRejections, isDragActive } =
      useDropzone({
        onDrop,
        multiple,
        accept,
        disabled,
        maxSize,
        validator,
        maxFiles,
      })

    const renderPlaceholder = () => {
      if (preview || variant === "overlay") {
        return null
      }

      if (placeholderVariant === "image") {
        return (
          <Icon
            className="text-secondary"
            size={32}
            value={fileRejections.length ? "help" : "image"}
          />
        )
      }

      if (placeholderVariant === "add") {
        return (
          <CenterAligned>
            <Icon size={32} value="add" />
          </CenterAligned>
        )
      }

      return (
        <CenterAligned className="items-center gap-2">
          <Flex marginBottom="8px">
            <Icon size={isLessThanSm ? 32 : 40} value="upload" />
          </Flex>
          <CenterAligned>
            <Text.Body responsive weight="semibold">
              {isDragActive
                ? t("fileInput.dropMedia", "Drop media")
                : t("fileInput.dragAndDrop", "Drag and drop media")}
            </Text.Body>
            <Text.Body color="blue-3" size="small" weight="semibold">
              {t("fileInput.browseFiles", "Browse files")}
            </Text.Body>
          </CenterAligned>
        </CenterAligned>
      )
    }

    const { ref: rootRef, color: _color, ...rootProps } = getRootProps()

    return (
      <Container
        {...rest}
        {...rootProps}
        $disabled={disabled}
        $dragActive={isDragActive}
        $showBorder={!preview}
        aria-label={
          rest["aria-label"] ??
          t(
            "fileInput.filetype.label",
            "Select an image, video, audio or 3D model file",
          )
        }
        ref={useMergedRef(ref, rootRef)}
        role="button"
        shape={shape}
        style={{
          height,
          minHeight,
          maxHeight,
          width,
          minWidth,
          maxWidth,
          zIndex,
        }}
        variant={variant}
      >
        <input
          disabled={disabled}
          id={id}
          name={name}
          ref={inputRef}
          {...getInputProps()}
        />

        {fileRejections.length ? (
          <>
            {renderPlaceholder()}
            {extra}
            {fileRejections
              .flatMap(r => r.errors)
              .map(error => (
                <FlexColumn key={`${error.code}-${error.message}`}>
                  <Text.Body
                    className="text-center text-red-2"
                    size="small"
                    weight="semibold"
                  >
                    {errorCopy[error.code]}
                  </Text.Body>
                </FlexColumn>
              ))}
          </>
        ) : preview ? (
          <>
            {removable ? (
              <RemoveFileContainer shape={shape}>
                <UnstyledButton
                  aria-label="Remove"
                  onClick={event => {
                    event.stopPropagation()
                    event.preventDefault()
                    onChange?.([])
                  }}
                >
                  {closeIcon ? (
                    closeIcon
                  ) : (
                    <Icon className="text-white" size={24} value="delete" />
                  )}
                </UnstyledButton>
              </RemoveFileContainer>
            ) : null}

            <ImageOverlayContainer>{renderPlaceholder()}</ImageOverlayContainer>
            {preview}
            {previewOverlay && (
              <PreviewOverlayContainer>
                {previewOverlay}
              </PreviewOverlayContainer>
            )}
          </>
        ) : (
          <>
            {renderPlaceholder()}
            {extra}
          </>
        )}

        {children}

        {(!disableInputOverlay || preview) && (
          <FileInputOverlay
            $hasPreview={!!preview}
            $shape={shape}
            $variant={variant}
          >
            {!preview ? overlay : null}
          </FileInputOverlay>
        )}
      </Container>
    )
  },
)

const absolutePosition = css`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const ImageOverlayContainer = styled(CenterAligned)`
  ${absolutePosition}
  z-index: 1;
  opacity: 0;
  transition: 0.1s opacity ease-in;
`

const PreviewOverlayContainer = styled(CenterAligned)`
  ${absolutePosition}
  z-index: 1;
  opacity: 1;
  transition: 0.25s opacity ease-in-out;
`

const RemoveFileContainer = styled(Block)<{ shape: FileInputShape }>`
  position: absolute;
  opacity: 0;
  z-index: ${Z_INDEX.OVERLAY + 1};
  transition: 0.1s opacity ease-in;

  ${variant({
    prop: "shape",
    variants: {
      square: {
        right: "16px",
        top: "16px",
      },
      round: {
        right: "-8px",
        top: "-8px",
      },
    },
  })};
`

export const borderStyles = css`
  border: 1px dashed ${props => props.theme.colors.components.border.level3};

  :hover {
    border: 1px solid ${props => props.theme.colors.components.border.level3};
  }
`

const FileInputOverlay = styled(CenterAligned)<{
  $hasPreview: boolean
  $shape: FileInputShape
  $variant: FileInputVariant
}>`
  ${absolutePosition}
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${Z_INDEX.OVERLAY};
  opacity: 0;
  pointer-events: none;
  border-radius: ${({ $shape, $variant, theme }) =>
    $variant === "overlay"
      ? undefined
      : $shape === "round"
      ? theme.borderRadius.circle
      : undefined};
  background: ${props =>
    props.$hasPreview
      ? rgba(props.theme.colors.black, 0.6)
      : props.theme.colors.components.background.gray1};
  transition: 0.1s opacity ease-in;
`
const Container = styled(CenterAligned)<{
  $disabled: boolean
  $showBorder: boolean
  $dragActive: boolean
  variant: FileInputVariant
  shape: FileInputShape
}>`
  position: relative;
  cursor: pointer;
  transition:
    0.25s background-color ease-in-out,
    0.25s border-color ease-in-out;
  overflow: hidden;

  ${({ $showBorder }) => ($showBorder ? borderStyles : "")}

  ${({ $dragActive }) =>
    $dragActive &&
    css`
      ${FileInputOverlay} {
        opacity: 1;
      }
    `}

  :hover {
    ${ImageOverlayContainer} {
      opacity: 1;
    }

    ${PreviewOverlayContainer} {
      opacity: 0;
    }

    ${FileInputOverlay} {
      opacity: 1;
    }

    ${RemoveFileContainer} {
      opacity: 1;
    }
  }

  ${props =>
    props.$disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}

  ${props =>
    props.variant === "overlay" &&
    css`
      && {
        ${absolutePosition}
        border: none;
      }
    `}

  ${props =>
    variant({
      prop: "shape",
      variants: {
        round: {
          borderRadius: props.theme.borderRadius.circle,
        },
        square: {
          borderRadius: "12px",
        },
      },
    })}
`
