import React, { useState } from "react"
import { Icon, UnstyledButton, Spinner, VerticalAligned } from "@opensea/ui-kit"
import { sortBy } from "lodash"
import { getWalletConfiguration } from "@/constants/wallet"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { Dropdown } from "@/design-system/Dropdown"
import { useTranslate } from "@/hooks/useTranslate"
import Provider from "@/lib/chain/provider"
import { useRefreshFunds } from "../WalletPopover/components/WalletSwitcher/useRefreshFunds"
import { trackClickWalletSwitchOption } from "./analytics"

type WalletMenuProps = {
  children: React.ReactNode
}

export const WalletMenu = ({ children }: WalletMenuProps) => {
  const t = useTranslate("components")
  const {
    providers,
    provider: activeProvider,
    switchProvider,
    logout,
  } = useWallet()
  const [switchingProvider, setSwitchingProvider] = useState<Provider>()
  const [isLoggingOut, setIsLoggingOut] = useState(false)
  const [isRefresingFunds, setIsRefreshingFunds] = useState(false)

  const refreshFunds = useRefreshFunds()
  const refreshFundsHandler = async (onDone: () => unknown) => {
    setIsRefreshingFunds(true)
    await refreshFunds()
    setIsRefreshingFunds(false)
    onDone()
  }

  return (
    <Dropdown
      content={({ List, Item, close }) => (
        <List>
          {sortBy(providers, provider =>
            provider === activeProvider ? 0 : 1,
          ).map(provider => {
            const walletName = provider.getName()
            const { alternativeLogo } = getWalletConfiguration(walletName)
            const isActive = provider === activeProvider
            const isSwitching = switchingProvider === provider
            let isDisabled = switchingProvider !== undefined
            if (isActive) {
              isDisabled = false
            }

            const onClick = isActive
              ? undefined
              : async () => {
                  setSwitchingProvider(provider)
                  trackClickWalletSwitchOption({
                    fromMetadata: activeProvider?.getMetadata(),
                    from: activeProvider?.getName(),
                    to: walletName,
                    toMetadata: provider.getMetadata(),
                  })

                  try {
                    await switchProvider(provider)
                    close()
                  } finally {
                    setSwitchingProvider(undefined)
                  }
                }

            return (
              <Item disabled={isDisabled} key={walletName} onClick={onClick}>
                <Item.Avatar alt="" src={alternativeLogo} />
                <Item.Content>
                  <Item.Title>{walletName}</Item.Title>
                </Item.Content>

                {isActive && (
                  <Item.Side>
                    <Icon className="text-green-2" value="check" />
                  </Item.Side>
                )}

                {isSwitching && (
                  <Item.Side>
                    <Spinner />
                  </Item.Side>
                )}
              </Item>
            )
          })}

          <Item
            disabled={isLoggingOut}
            onClick={async () => {
              setIsLoggingOut(true)
              try {
                await logout()
                close()
              } finally {
                setIsLoggingOut(false)
              }
            }}
          >
            <Item.Avatar icon="logout" />
            <Item.Content>
              <Item.Title>{t("walletMenu.logOut", "Log out")}</Item.Title>
            </Item.Content>

            {isLoggingOut && (
              <Item.Side>
                <Spinner />
              </Item.Side>
            )}
          </Item>

          <Item
            disabled={isRefresingFunds}
            onClick={() => refreshFundsHandler(close)}
          >
            <Item.Avatar icon="sync" />
            <Item.Content>
              <Item.Title>
                {t("walletMenu.refreshFunds", "Refresh funds")}
              </Item.Title>
            </Item.Content>

            {isRefresingFunds && (
              <Item.Side>
                <Spinner />
              </Item.Side>
            )}
          </Item>
        </List>
      )}
    >
      <UnstyledButton>
        <VerticalAligned>{children}</VerticalAligned>
        <VerticalAligned className="p-1">
          <Icon
            className="text-secondary"
            title={t("walletMenu.back", "Back")}
            value="keyboard_arrow_down"
          />
        </VerticalAligned>
      </UnstyledButton>
    </Dropdown>
  )
}
