/**
 * @generated SignedSource<<1230d359edc6fb29255a1d5dacd1ede7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConfirmationItem_asset$data = {
  readonly chain: {
    readonly displayName: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"AssetItem_asset">;
  readonly " $fragmentType": "ConfirmationItem_asset";
};
export type ConfirmationItem_asset$key = {
  readonly " $data"?: ConfirmationItem_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmationItem_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConfirmationItem_asset",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChainType",
      "kind": "LinkedField",
      "name": "chain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssetItem_asset"
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "1ead5a1cea0cdc27633894e7aeb05541";

export default node;
