import React, { useRef } from "react"
import {
  breakpoints,
  UnstyledButton,
  Text,
  Spinner,
  Flex,
} from "@opensea/ui-kit"
import { rgba } from "polished"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
import { ExternalLink } from "@/components/common/ExternalLink"
import { LEARN_URL } from "@/constants/index"
import { Block } from "@/design-system/Block"
import { useTheme } from "@/design-system/Context"
import { Item } from "@/design-system/Item"
import { List } from "@/design-system/List"
import { Modal } from "@/design-system/Modal"
import { useTranslate } from "@/hooks/useTranslate"
import { themeVariant } from "@/styles/styleUtils"
import THEMES, { HUES } from "@/styles/themes"
import { ConnectCompatibleWallet } from "../WalletSidebar/ConnectCompatibleWallet"

type Props = {
  isOpen?: boolean
  onClose: () => void
}

export function WalletModal({ isOpen = false, onClose }: Props) {
  const { theme } = useTheme()
  const t = useTranslate("common")
  const scrollableDivRef = useRef<HTMLDivElement | null>(null)

  const { ref: triggerRef, inView } = useInView({
    root: scrollableDivRef.current,
  })

  return (
    <Modal
      focusFirstFocusableElement={false}
      isOpen={isOpen}
      size="medium"
      onClose={onClose}
    >
      <StyledModalBody>
        <Flex
          className="max-h-[75vh] flex-col items-center overflow-auto"
          data-testid="wallet-modal"
          ref={scrollableDivRef}
        >
          <Flex
            className="sticky top-0 w-full items-center justify-center bg-elevation-2 p-4 sm:p-6"
            style={{
              boxShadow: inView
                ? undefined
                : theme === "light"
                ? THEMES.light.shadows.borderBottom
                : THEMES.dark.shadows.borderBottom,
            }}
          >
            <Text.Heading asChild size="small">
              <h4>{t("wallet.modalHeader", "Connect your wallet")}</h4>
            </Text.Heading>
          </Flex>
          <span ref={triggerRef} />
          <StyledContent padding={{ _: "0 16px", sm: "0 24px" }}>
            <ConnectCompatibleWallet
              customHeader={
                <Block
                  className="border-b border-level-2"
                  padding={{ _: "0 8px 24px", lg: "0 24px 24px" }}
                  textAlign="center"
                >
                  <Text className="text-secondary" size="medium">
                    {t(
                      "connectCompatibleWallet.wallet.modal.header",
                      "If you don't have a wallet, you can select a provider and create one now. {{learnMore}}",
                      {
                        learnMore: (
                          <ExternalLink
                            href={`${LEARN_URL}/what-is-crypto-wallet`}
                          >
                            {t(
                              "connectCompatibleWallet.wallet.modal.learn.more",
                              "Learn more",
                            )}
                          </ExternalLink>
                        ),
                      },
                    )}
                  </Text>
                </Block>
              }
              renderList={wallets => {
                return (
                  <StyledList>
                    {wallets.map(
                      ({
                        walletName,
                        logo,
                        actionProps,
                        annotation,
                        supportedOnPlatform,
                        installingWallet,
                        notSupportedText,
                      }) => {
                        return (
                          <li key={walletName}>
                            <StyledItem {...actionProps}>
                              <Item.Avatar
                                alt={walletName}
                                size={30}
                                src={logo ?? ""}
                              />
                              <Item.Content>
                                <Text weight="semibold">{walletName}</Text>
                              </Item.Content>
                              {installingWallet !== walletName && (
                                <Item.Side>
                                  <Text
                                    className="uppercase text-secondary"
                                    size="tiny"
                                    weight="semibold"
                                  >
                                    {annotation}
                                  </Text>
                                </Item.Side>
                              )}
                              {!supportedOnPlatform && (
                                <Item.Side>
                                  <Text
                                    className="uppercase text-secondary"
                                    size="tiny"
                                    weight="semibold"
                                  >
                                    {notSupportedText}
                                  </Text>
                                </Item.Side>
                              )}

                              {walletName === installingWallet && (
                                <Item.Side className="ml-1">
                                  <Spinner />
                                </Item.Side>
                              )}
                            </StyledItem>
                          </li>
                        )
                      },
                    )}
                  </StyledList>
                )
              }}
              renderShowMore={({
                toggleShowMore,
                label,
                showingAll: _showingAll,
                ...moreProps
              }) => {
                return (
                  <ShowMoreButtonBG className="sticky bottom-0">
                    <ShowMoreButton onClick={toggleShowMore} {...moreProps}>
                      <Text weight="semibold">{label}</Text>
                    </ShowMoreButton>
                  </ShowMoreButtonBG>
                )
              }}
              source="wallet modal"
            />
          </StyledContent>
        </Flex>
      </StyledModalBody>
    </Modal>
  )
}

const StyledContent = styled(Block)`
  background: ${props =>
    props.theme.colors.components.elevation.level2.background};
`

const StyledItem = styled(Item)`
  padding: 16px 24px;

  @media (max-width: ${breakpoints.md}px) {
    padding: 16px;
  }

  box-shadow: none;
  cursor: pointer;

  &:hover {
    box-shadow: none;

    ${themeVariant({
      variants: {
        light: {
          background: rgba(HUES.fog, 0.2),
        },
        dark: {
          background: rgba(HUES.oil, 0.2),
        },
      },
    })}
  }

  &:active {
    box-shadow: none;

    ${themeVariant({
      variants: {
        light: {
          background: rgba(HUES.fog, 0.4),
        },
        dark: {
          background: rgba(HUES.oil, 0.4),
        },
      },
    })}
  }
`

const StyledList = styled(List).attrs({ showBorder: false })`
  margin: 0 -16px;

  @media (min-width: ${breakpoints.sm}px) {
    margin: 0 -24px;
  }

  background: ${props =>
    props.theme.colors.components.elevation.level2.background};
`

const StyledModalBody = styled(Modal.Body)`
  padding: 0;
  border-radius: ${props => props.theme.borderRadius.modal};
`

const ShowMoreButtonBG = styled(Block)`
  background: ${props =>
    props.theme.colors.components.elevation.level2.background};
  margin: 0 -16px;

  @media (min-width: ${breakpoints.sm}px) {
    margin: 0 -24px;
  }
`

const ShowMoreButton = styled(UnstyledButton)`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 24px;
  border-top: 1px solid ${props => props.theme.colors.components.border.level2};

  &:hover {
    ${themeVariant({
      variants: {
        light: {
          background: rgba(HUES.fog, 0.2),
        },
        dark: {
          background: rgba(HUES.oil, 0.2),
        },
      },
    })}
  }

  &:active {
    ${themeVariant({
      variants: {
        light: {
          background: rgba(HUES.fog, 0.4),
        },
        dark: {
          background: rgba(HUES.oil, 0.4),
        },
      },
    })}
  }
`
