import React from "react"
import { Text, FlexColumn } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { StackedAssetMedia } from "@/components/assets/StackedAssetMedia"
import { Block } from "@/design-system/Block"
import { Modal } from "@/design-system/Modal"
import { GaslessCancelLearnMoreLink } from "@/features/cancel-orders/components/GaslessCancelLearnMoreLink.react"
import { trackOpenGaslessCancelFailedModal } from "@/features/cancel-orders/utils/analytics"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { GaslessCancellationFailedModal_items$key } from "@/lib/graphql/__generated__/GaslessCancellationFailedModal_items.graphql"

type CommonProps = {
  items: GaslessCancellationFailedModal_items$key
}

type FulfilledFailureReasonProps = CommonProps

type RetryableFailureReasonProps = CommonProps & {
  cancelOnChain: () => unknown
  retry: () => unknown
}

type GaslessCancellationFailedModalProps =
  | FulfilledFailureReasonProps
  | RetryableFailureReasonProps

export const GaslessCancellationFailedModal = ({
  items: itemsDataKey,
  ...restProps
}: GaslessCancellationFailedModalProps) => {
  const t = useTranslate("components")

  useMountEffect(() => {
    trackOpenGaslessCancelFailedModal()
  })

  const items = useFragment(
    graphql`
      fragment GaslessCancellationFailedModal_items on ItemType
      @relay(plural: true) {
        ...StackedAssetMedia_assets
      }
    `,
    itemsDataKey,
  )

  const isRetryableFailure = "cancelOnChain" in restProps
  const isFulfilledFailure = !isRetryableFailure

  return (
    <>
      <Modal.Header></Modal.Header>
      <Modal.Body className="text-center" paddingX="64px">
        <Block marginTop="8px">
          <StackedAssetMedia assets={items} />
        </Block>
        <FlexColumn className="mt-8 gap-2">
          <Modal.Body.Title>
            {t("gaslessCancel.failedModal.title", "Cancellation failed")}
          </Modal.Body.Title>
          <Text.Body className="block text-secondary" size="medium">
            {isFulfilledFailure
              ? t(
                  "gaslessCancel.failedModal.body.fulfilled",
                  "Cancellation failed because the offer was fulfilled. {{learnMoreLink}}",
                  { learnMoreLink: <GaslessCancelLearnMoreLink /> },
                )
              : t(
                  "gaslessCancel.failedModal.body.unexpected",
                  "Cancellation failed unexpectedly. You can retry the cancellation or pay gas fees to cancel immediately on-chain. {{learnMoreLink}}",
                  { learnMoreLink: <GaslessCancelLearnMoreLink /> },
                )}
          </Text.Body>
        </FlexColumn>
      </Modal.Body>
      {isRetryableFailure && (
        <Modal.Footer>
          <Modal.Footer.Button
            variant="secondary"
            onClick={restProps.cancelOnChain}
          >
            {t(
              "gaslessCancel.failedModal.cancelFasterWithGasFee",
              "Cancel faster with gas fee",
            )}
          </Modal.Footer.Button>
          <Modal.Footer.Button variant="primary" onClick={restProps.retry}>
            {t("gaslessCancel.failedModal.retry", "Retry")}
          </Modal.Footer.Button>
        </Modal.Footer>
      )}
    </>
  )
}
