import React, { useState } from "react"
import { CenterAligned, Icon, Spinner, SpinnerProps } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { ImageInput, ImageInputValue } from "@/components/forms/ImageInput"
import { Block, BlockProps } from "@/design-system/Block"
import { Image } from "@/design-system/Image"
import { Lightbox } from "@/design-system/Lightbox"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { ProfileImage_data$key } from "@/lib/graphql/__generated__/ProfileImage_data.graphql"
import { ProfileImageMutation } from "@/lib/graphql/__generated__/ProfileImageMutation.graphql"
import { ProfileImageUploadMutation } from "@/lib/graphql/__generated__/ProfileImageUploadMutation.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"
import { uploadFile } from "@/lib/helpers/file"
import { selectClassNames } from "@/lib/helpers/styling"
import { withStyledPropsRemoved } from "@/styles/withStyledPropsRemoved.react"

type Props = {
  className?: string
  editable?: boolean
  isDynamic?: boolean
  loaderSize?: SpinnerProps["size"]
  size?: number
  data: ProfileImage_data$key
} & BlockProps

type ImageStatus = "done" | "standby" | "wait"

export const ProfileImage = ({
  className,
  editable,
  isDynamic,
  size,
  loaderSize,
  data: dataKey,
  ...blockProps
}: Props) => {
  const t = useTranslate("components")
  const [imageStatus, setImageStatus] = useState<ImageStatus>("standby")
  const { attempt, showSuccessMessage } = useToasts()
  const { mutate } = useGraphQL()

  const { imageUrl } = useFragment(
    graphql`
      fragment ProfileImage_data on AccountType {
        imageUrl
      }
    `,
    dataKey,
  )

  const editImage = async (
    value: ImageInputValue | undefined,
  ): Promise<void> => {
    const file = value?.file
    if (!file) {
      return
    }

    setImageStatus("wait")

    await attempt(
      async () => {
        const {
          accounts: { uploadProfilePicture },
        } = await mutate<ProfileImageUploadMutation>(
          graphql`
            mutation ProfileImageUploadMutation {
              accounts {
                uploadProfilePicture {
                  url
                  method
                  fields
                  token
                }
              }
            }
          `,
          {},
          { shouldAuthenticate: true },
        )
        await uploadFile(uploadProfilePicture, file)
        await mutate<ProfileImageMutation>(
          graphql`
            mutation ProfileImageMutation($input: AccountMutationInput!) {
              account(input: $input) {
                imageUrl
              }
            }
          `,
          { input: { profileImageToken: uploadProfilePicture.token } },
          { shouldAuthenticate: true },
        )

        showSuccessMessage(
          t(
            "profileImage.updateSuccessMessage",
            "Successfully updated profile picture",
          ),
        )
        setImageStatus("done")
      },
      {
        onError: () => {
          setImageStatus("done")
        },
      },
    )
  }

  const renderImage = ({ onClick }: { onClick?: () => unknown } = {}) => {
    return (
      <StyledImage
        alt={t("profileImage.alt", "User Profile Image")}
        height={size}
        isClickable={Boolean(onClick)}
        objectFit="cover"
        src={imageUrl}
        width={size}
        onClick={onClick}
      />
    )
  }

  const renderBody = () => {
    return (
      <>
        {editable && (
          <ImageInput
            aria-label={t("profileImage.label", "Select a profile image")}
            overlay={
              <CenterAligned className="h-full justify-center">
                <Icon className="text-white" value="edit" />
              </CenterAligned>
            }
            shape="round"
            variant="overlay"
            zIndex={2}
            onChange={editImage}
          />
        )}

        {imageStatus === "wait" ? (
          // eslint-disable-next-line tailwindcss/no-custom-classname
          <div className="ProfileImage--loader">
            <Spinner size={loaderSize} />
          </div>
        ) : (
          <Lightbox trigger={open => renderImage({ onClick: open })}>
            <StyledImage
              alt={t("profileImage.alt", "User Profile Image")}
              height={size}
              isClickable
              objectFit="cover"
              src={imageUrl}
              width={size}
            />
          </Lightbox>
        )}
      </>
    )
  }

  return (
    <DivContainer
      className={selectClassNames("ProfileImage", { editable }, className)}
      {...blockProps}
    >
      {isDynamic ? renderBody() : renderImage()}
    </DivContainer>
  )
}

const StyledImage = styled(withStyledPropsRemoved(Image, ["isClickable"]))<{
  isClickable: boolean
}>`
  cursor: ${props => (props.isClickable ? "pointer" : "default")};
  border-radius: ${props => props.theme.borderRadius.circle};
`

const DivContainer = styled(Block)`
  position: relative;

  .ProfileImage--loader {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
