import React from "react"
import { Flex, Text } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { StackedAssetMedia } from "@/components/assets/StackedAssetMedia"
import { Overflow } from "@/components/common/Overflow"
import { Block } from "@/design-system/Block"
import { useTranslate } from "@/hooks/useTranslate"
import { SwapDataSide_assets$key } from "@/lib/graphql/__generated__/SwapDataSide_assets.graphql"
import { bn, display } from "@/lib/helpers/numberUtils"

type Props = {
  assets: SwapDataSide_assets$key
  isLoading?: boolean
  side: "maker" | "taker"
}

export const SwapDataSide = ({ assets: assetsKey, isLoading, side }: Props) => {
  const t = useTranslate("components")
  const assets = useFragment<SwapDataSide_assets$key>(
    graphql`
      fragment SwapDataSide_assets on AssetQuantityDataType
      @relay(plural: true) {
        asset {
          relayId
          displayName
          symbol
          assetContract {
            tokenStandard
          }
          ...StackedAssetMedia_assets
        }
        quantity
      }
    `,
    assetsKey,
  )

  const nftAssets = assets.filter(
    asset => asset.asset.assetContract.tokenStandard !== "ERC20",
  )
  const paymentAssets = assets.filter(
    asset => asset.asset.assetContract.tokenStandard === "ERC20",
  )

  return (
    <Flex className="w-full items-center">
      <Block flex="none">
        <StackedAssetMedia
          assets={nftAssets.map(asset => asset.asset)}
          isLoading={isLoading}
          variant="xsmall"
        />
      </Block>
      <Block marginLeft="12px">
        <Text.Body
          asChild
          data-testid={
            side === "maker" ? "maker-item-count" : "taker-item-count"
          }
          size="small"
          weight="semibold"
        >
          <div>
            {t(
              "blockchain.swaps.side.itemCount",
              {
                "0": "{{count}} items",
                one: "{{count}} item",
                other: "{{count}} items",
              },
              { count: nftAssets.length },
            )}
            {paymentAssets.length > 0 &&
              paymentAssets.map((paymentAsset, index) => (
                <React.Fragment key={index}>
                  {" + "}
                  {display(
                    paymentAsset.quantity,
                    paymentAsset.asset.symbol ?? undefined,
                  )}{" "}
                  {paymentAsset.asset.symbol}
                </React.Fragment>
              ))}
          </div>
        </Text.Body>
        <Overflow lines={1}>
          <Text.Body asChild className="text-secondary" size="small">
            <div>
              {nftAssets.map((asset, index) => (
                <React.Fragment key={asset.asset.relayId}>
                  {bn(asset.quantity).gt(1) && (
                    <>
                      <Text.Body
                        className="text-secondary"
                        size="small"
                        weight="semibold"
                      >
                        [{display(asset.quantity)}x]
                      </Text.Body>{" "}
                    </>
                  )}
                  {asset.asset.displayName}
                  {index < nftAssets.length - 1 && <>, </>}
                </React.Fragment>
              ))}
            </div>
          </Text.Body>
        </Overflow>
      </Block>
    </Flex>
  )
}
