/**
 * @generated SignedSource<<0a0234835a8c6b28f732161b77b8467d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderSide = "ASK" | "BID" | "%future added value";
export type VerificationStatus = "MINTABLE" | "SAFELISTED" | "UNAPPROVED" | "VERIFIED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FulfillOrderAction_data$data = {
  readonly __typename: "FulfillOrderActionType";
  readonly criteriaAsset: {
    readonly " $fragmentSpreads": FragmentRefs<"OrderDataHeader_criteriaAsset">;
  } | null;
  readonly itemFillAmount: string;
  readonly orderData: {
    readonly item: {
      readonly __typename: "AssetBundleType";
      readonly collection: {
        readonly slug: string;
        readonly verificationStatus: VerificationStatus;
      } | null;
    } | {
      readonly __typename: "AssetQuantityDataType";
      readonly asset: {
        readonly collection: {
          readonly slug: string;
          readonly verificationStatus: VerificationStatus;
        };
      };
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
    readonly side: OrderSide;
    readonly " $fragmentSpreads": FragmentRefs<"OrderDataHeader_order">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_fulfill_order">;
  readonly " $fragmentType": "FulfillOrderAction_data";
};
export type FulfillOrderAction_data$key = {
  readonly " $data"?: FulfillOrderAction_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"FulfillOrderAction_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CollectionType",
    "kind": "LinkedField",
    "name": "collection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "verificationStatus",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FulfillOrderAction_data",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderDataType",
      "kind": "LinkedField",
      "name": "orderData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "item",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetType",
                  "kind": "LinkedField",
                  "name": "asset",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "type": "AssetQuantityDataType",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v1/*: any*/),
              "type": "AssetBundleType",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "side",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OrderDataHeader_order"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "itemFillAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssetType",
      "kind": "LinkedField",
      "name": "criteriaAsset",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OrderDataHeader_criteriaAsset"
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useHandleBlockchainActions_fulfill_order",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TransactionSubmissionDataType",
          "kind": "LinkedField",
          "name": "method",
          "plural": false,
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "useHandleBlockchainActions_transaction",
              "selections": [
                (v2/*: any*/),
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "useTransaction_transaction",
                  "selections": [
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AddressDataType",
                      "kind": "LinkedField",
                      "name": "source",
                      "plural": false,
                      "selections": (v4/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AddressDataType",
                      "kind": "LinkedField",
                      "name": "destination",
                      "plural": false,
                      "selections": (v4/*: any*/),
                      "storageKey": null
                    },
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "data",
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderDataType",
          "kind": "LinkedField",
          "name": "orderData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "openedAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "FulfillOrderActionType",
  "abstractKey": null
};
})();

(node as any).hash = "bff16694a72d4fe92b844e05aabca0f8";

export default node;
