import type { ConnectWalletSource } from "@/components/nav/WalletSidebar/ConnectCompatibleWallet"
import { WALLET_NAME } from "@/constants/wallet"
import { getTrackingFn } from "@/lib/analytics"

export const trackOpenSidebarWallet = getTrackingFn("open sidebar wallet")
export const trackCloseSidebarWallet = getTrackingFn("close sidebar wallet")

export const trackClickSideBarWalletMoreOptions = getTrackingFn<{
  symbol: string
  chain: string
}>("click wallet sidebar token more options")

export const trackSelectWallet = getTrackingFn<{
  source: ConnectWalletSource
  walletName: WALLET_NAME
}>("select wallet")
