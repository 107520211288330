import { getPageTrackingFn } from "../utils"
import type { CollectionSlugParams } from "./collectionEvents"
import { getItemFragmentPageTrackingFn } from "./itemEvents"
import type { RankingsPageFilterParams } from "./rankingsEvents"

export const trackHomePage = getPageTrackingFn("home page")
export const trackRankingsPage =
  getPageTrackingFn<RankingsPageFilterParams>("rankings page")
export const trackActivityPage = getPageTrackingFn("activity page")
export const trackAssetsPage = getPageTrackingFn("assets page")
export const trackOpenSeaVenturesPage = getPageTrackingFn("ventures page")
export const trackAboutPage = getPageTrackingFn("about page")
export const trackCareersPage = getPageTrackingFn("careers page")
export const trackTaxResourcesPage = getPageTrackingFn("tax resources page")
export const trackExploreCollectionsPage = getPageTrackingFn(
  "explore collections page",
)
export const trackCategoryPage = getPageTrackingFn<{ categorySlug: string }>(
  "category page",
)
export const trackCollectionManagerPage = getPageTrackingFn(
  "collection manager page",
)

export const trackCollectionManagerEditCollectionPage = getPageTrackingFn<
  CollectionSlugParams & {
    tab?: string
  }
>("collection manager edit collection page")
export const trackCollectionManagerCreateCollectionPage = getPageTrackingFn<{
  tab?: string
}>("collection manager create collection page")
export const trackAssetSellPage =
  getItemFragmentPageTrackingFn("asset sell page")
export const trackBundleTransferPage = getPageTrackingFn("bundle transfer page")
export const trackErrorPage = getPageTrackingFn<{
  error_code: number
  path: string
}>("error page")

export const trackAccountBannedPage = getPageTrackingFn("account banned page")

export const trackBulkListingPage = getPageTrackingFn("bulk listing page")

export const trackPrimaryDropsV2Page = getPageTrackingFn<{
  slug: string
  tab: string
}>("primary drops v2 page")

export const trackSelfServePrimaryDrops = getPageTrackingFn<{
  collectionSlug: string
  tab: string
}>("self serve primary drops")
