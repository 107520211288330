import Cookies from "js-cookie"

// Used to match against en-US or xx-XX
const FULL_LOCALE_STRING_REGEX = /\w{2}-\w{2}/
// days
const DEFAULT_COOKIE_EXPR = 99999

export const updateLocaleCookie = (locale: string) => {
  Cookies.set("NEXT_LOCALE", locale, {
    expires: DEFAULT_COOKIE_EXPR,
    secure: true,
  })
}

export const getShortLocaleLabel = (maybeLongLocale: string) => {
  if (maybeLongLocale.match(FULL_LOCALE_STRING_REGEX)) {
    return maybeLongLocale.split("-")[0] || ""
  }
  return maybeLongLocale
}
