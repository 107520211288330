import React, { useState } from "react"
import {
  Icon,
  Text,
  Spinner,
  SpaceBetween,
  CenterAligned,
  FlexColumn,
  Flex,
} from "@opensea/ui-kit"
import { graphql, useQueryLoader } from "react-relay"
import { useUpdateEffect } from "react-use"
import { Overflow } from "@/components/common/Overflow"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { useTheme } from "@/design-system/Context"
import { RenderDropdownContentProps } from "@/design-system/Dropdown"
import { List } from "@/design-system/List"
import { SelectOption } from "@/design-system/Select"
import { useChains } from "@/hooks/useChains"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { getEquallySizedChainLogo } from "@/hooks/useChains/useChainOptions"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { SwitchNetworkListContentQuery } from "@/lib/graphql/__generated__/SwitchNetworkListContentQuery.graphql"
import THEMES from "@/styles/themes"
import { useChainOptions } from "../../hooks/useChainOptions"
import { SwitchNetworkNetworkTotalFundsUsd } from "./SwitchNetworkNetworkTotalFundsUsd.react"

export const SWITCH_NETWORK_LIST_CONTENT_QUERY = graphql`
  query SwitchNetworkListContentQuery($address: AddressScalar!) {
    ...SwitchNetworkNetworkTotalFundsUsd_data @arguments(address: $address)
  }
`

type SwitchNetworkListContentProps = RenderDropdownContentProps & {
  onClickBack: () => unknown
}

export const SwitchNetworkListContent = ({
  onClickBack,
  close,
}: SwitchNetworkListContentProps) => {
  const t = useTranslate("components")
  const { wallet } = useWallet()
  const { chain: activeChain, switchChain } = useWallet()
  const { getChain } = useChains()
  const { theme } = useTheme()
  const { attempt } = useToasts()

  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<SwitchNetworkListContentQuery>(
      SWITCH_NETWORK_LIST_CONTENT_QUERY,
    )

  useMountEffect(() => {
    loadQuery({
      address: wallet.getActiveAddressStrict(),
    })

    const unsubscribeFromWalletChanges = wallet.onChange(() => {
      if (!wallet.address) {
        return
      }

      loadQuery({
        address: wallet.address,
      })
    })

    return () => {
      disposeQuery()
      unsubscribeFromWalletChanges()
    }
  })

  const { chainOptions, activeChainOption } = useChainOptions()

  const [isSwitchingChainTo, setIsSwitchingChainTo] =
    useState<ChainIdentifier>()

  const onSelectChain = async (
    option: SelectOption<ChainIdentifier> | undefined,
  ) => {
    if (!option || option === activeChainOption) {
      return
    }

    setIsSwitchingChainTo(option.value)
    await attempt(
      async () => {
        await switchChain(getChain(option.value))
      },
      { onError: () => setIsSwitchingChainTo(undefined) },
    )
  }

  useUpdateEffect(() => {
    setIsSwitchingChainTo(undefined)
    close() // close dropdown whenever activeChain changes
  }, [activeChain])

  return (
    <>
      <List.Item key="language.back" onClick={onClickBack}>
        <List.Item.Avatar icon="arrow_back" />
        <List.Item.Content>
          {t("networkBalanceDropdown.switchChain", "Switch chain")}
        </List.Item.Content>
      </List.Item>
      {chainOptions.map(chainOption => {
        const isActive = chainOption.value === activeChain
        const isSwitchingTo = chainOption.value === isSwitchingChainTo

        const ChainLogo = getEquallySizedChainLogo(chainOption.value)

        return (
          <List.Item
            className="border-0"
            key={chainOption.key ?? chainOption.value}
            onClick={isActive ? undefined : () => onSelectChain(chainOption)}
          >
            <FlexColumn className="w-full items-start gap-1">
              <SpaceBetween className="w-full">
                <Flex className="mr-2 min-w-0 items-center gap-3">
                  <Overflow
                    overrides={{
                      Tooltip: {
                        variant: "card",
                      },
                    }}
                  >
                    {ChainLogo && (
                      <div className="mr-[12px] inline-block align-sub">
                        <ChainLogo fill={THEMES[theme].colors.text.primary} />
                      </div>
                    )}
                    <Text.Body weight="semibold">{chainOption.label}</Text.Body>
                  </Overflow>
                </Flex>
                <CenterAligned>
                  {isSwitchingTo ? (
                    <Spinner />
                  ) : isActive ? (
                    <Icon value="done" />
                  ) : (
                    <SwitchNetworkNetworkTotalFundsUsd
                      chain={chainOption.value}
                      queryReference={queryReference}
                    />
                  )}
                </CenterAligned>
              </SpaceBetween>
            </FlexColumn>
          </List.Item>
        )
      })}
    </>
  )
}
