import React from "react"
import { Flex, Icon, Text, UnstyledButton } from "@opensea/ui-kit"
import styled from "styled-components"
import { Link } from "@/components/common/Link"
import { IS_TESTNET } from "@/constants/testnet"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { useChains } from "@/hooks/useChains"
import { useTranslate } from "@/hooks/useTranslate"
import * as sidecar from "@/server/sidecar/config"

type Network = "main" | "test"

export const IncompatibleNetworkBanner = () => {
  const t = useTranslate("components")
  const {
    showIncompatibleNetworkBanner,
    hideIncompatibleNetworkBanner,
    chain,
  } = useWallet()
  const { getChainName } = useChains()

  if (!showIncompatibleNetworkBanner || !chain) {
    return null
  }

  const content = ({
    page,
    wallet,
    goto,
    label,
  }: {
    page: Network
    wallet: Network
    goto: string
    label: string
  }) => {
    return (
      <Text color="black" size="tiny" weight="semibold">
        {t(
          "incompatibleNetworkBanner.description",
          "You're viewing data from the {{page}} network, but your wallet is connected to the {{wallet}} network ({{chain}}). To use OpenSea, please switch to {{gotoLink}}",
          {
            page,
            wallet,
            chain: getChainName(chain),
            gotoLink: (
              <Link className="underline" href={goto}>
                {label}
              </Link>
            ),
          },
        )}
      </Text>
    )
  }

  const goto = sidecar.getValue("counterNetworkWebsite")
  const label = new URL(goto).hostname
  return (
    <DivContainer>
      {IS_TESTNET
        ? content({
            page: "test",
            wallet: "main",
            goto,
            label,
          })
        : content({
            page: "main",
            wallet: "test",
            goto,
            label,
          })}

      <Flex className="ml-4">
        <UnstyledButton onClick={hideIncompatibleNetworkBanner}>
          <Icon value="close" />
        </UnstyledButton>
      </Flex>
    </DivContainer>
  )
}

const DivContainer = styled.div`
  align-items: center;
  background: ${props => props.theme.colors.warning};
  color: ${props => props.theme.colors.text.on.warning};
  display: flex;
  justify-content: center;
  padding: 6px 20px;
`
