import React from "react"
import { CenterAligned, Media, Text } from "@opensea/ui-kit"
import styled, { css } from "styled-components"
import { variant } from "styled-system"
import { FeaturedIcon } from "@/components/svgs/FeaturedIcon.react"
import { Block } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { Menu, MenuItemProps, MenuProps } from "@/design-system/Menu"
import { useNoSuspenseLazyLoadQuery } from "@/hooks/useNoSuspenseLazyLoadQuery"
import { useTranslate } from "@/hooks/useTranslate"
import { SettingsMenuQuery } from "@/lib/graphql/__generated__/SettingsMenuQuery.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { media, themeVariant } from "@/styles/styleUtils"
import { $nav_height } from "@/styles/variables"
import { SETTINGS_TABS, SettingsTab, SettingsTabProps } from "../../constants"

type SettingsMenuVariant = "page" | "global"

export type SettingsMenuProps = {
  activeTab?: SettingsTab
  address?: string
  variant?: SettingsMenuVariant
}

type getTabsProps = {
  isLargeScreen: boolean
}

export const SettingsMenu = ({
  activeTab,
  address,
  variant = "page",
}: SettingsMenuProps) => {
  const data = useNoSuspenseLazyLoadQuery<SettingsMenuQuery>(
    graphql`
      query SettingsMenuQuery($identity: AddressScalar!) {
        getAccount(address: $identity) {
          canViewVerificationForm
        }
      }
    `,
    { identity: address || "" },
  )

  const t = useTranslate("settings")

  const renderSettingsMenuTab = ({ label, icon, tab }: SettingsTabProps) => {
    return (
      <>
        {tab === "featured" ? (
          <FeaturedMenuItem
            active={activeTab === "featured"}
            direction="vertical"
            href="/account/settings?tab=featured"
            key={label}
            {...(activeTab === tab && { "aria-current": "page" })}
          />
        ) : (
          <Menu.Item
            $active={activeTab === tab}
            direction="vertical"
            href={`/account/settings?tab=${tab}`}
            key={label}
            {...(activeTab === tab && { "aria-current": "page" })}
          >
            <Menu.Avatar icon={icon} />
            <Menu.Title>{label}</Menu.Title>
          </Menu.Item>
        )}
      </>
    )
  }

  const getTabs = ({ isLargeScreen }: getTabsProps) => {
    return SETTINGS_TABS.filter(props => {
      if (props.tab === "featured" && !isLargeScreen) {
        return false
      }

      if (
        props.tab === "verifications" &&
        !data[0]?.getAccount.canViewVerificationForm
      ) {
        return false
      }

      return true
    })
  }

  return (
    <StyledContainer $variant={variant}>
      <Menu direction="vertical">
        {variant === "page" && (
          <Block padding="20px 10px">
            <Text.Body
              aria-label={t("menu.headerLabel", "Settings Menu Header")}
              className="text-secondary"
              size="medium"
              weight="semibold"
            >
              {t("menu.header", "Settings")}
            </Text.Body>
          </Block>
        )}
        <Media lessThan="lg">
          {getTabs({
            isLargeScreen: false,
          }).map(renderSettingsMenuTab)}
        </Media>
        <Media greaterThanOrEqual="lg">
          {getTabs({
            isLargeScreen: true,
          }).map(renderSettingsMenuTab)}
        </Media>
      </Menu>
    </StyledContainer>
  )
}

const FeaturedMenuItem = ({
  active,
  href,
  ...rest
}: { active: boolean; href: string } & MenuProps) => {
  const t = useTranslate("settings")
  return (
    <FeaturedIconContainer active={active} href={href} {...rest}>
      <Flex marginRight="16px">
        {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
        <FeaturedIcon className="FeaturedIcon" />
      </Flex>
      <CenterAligned>
        <Menu.Title>{t("menu.featuredItems", "Featured items")}</Menu.Title>
      </CenterAligned>
    </FeaturedIconContainer>
  )
}

const FeaturedIconContainer = styled(Menu.Item)<Pick<MenuItemProps, "$active">>`
  ${props =>
    themeVariant({
      variants: {
        dark: {
          fill: props.$active
            ? props.theme.colors.white
            : props.theme.colors.gray,
        },
        light: {
          fill: props.$active
            ? props.theme.colors.oil
            : props.theme.colors.gray,
        },
      },
    })}

  &:hover {
    ${props =>
      themeVariant({
        variants: {
          dark: {
            fill: props.theme.colors.white,
          },
          light: {
            fill: props.theme.colors.oil,
          },
        },
      })}
  }

  .FeaturedIcon {
    transition: fill 0.4s ease;
    fill: inherit;
  }
`

const StyledContainer = styled(Block)<{ $variant: SettingsMenuVariant }>`
  color: ${props => props.theme.colors.text.primary};
  padding: 0 12px;
  min-width: 250px;
  border-right: 1px solid
    ${props => props.theme.colors.components.border.level2};

  ${props =>
    variant({
      prop: "$variant",
      variants: {
        global: {
          "li a": {
            height: "80px",
            display: "flex",
            width: "100%",
            alignItems: "center",
            background: props.theme.colors.surface,
            color: props.theme.colors.text.primary,
          },
        },
        page: {
          "li a": {
            padding: "16px",
          },
        },
      },
    })}

  ${media({
    xl: css`
      align-self: flex-start;
      flex-shrink: 0;
      height: calc(100vh - ${$nav_height});
      position: sticky;
      top: ${$nav_height};
      width: 340px;
    `,
  })}
`
