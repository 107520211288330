import React from "react"

type Props = {
  className?: string
  width?: number
  height?: number
  fill?: string
}

export const SolanaLogo = ({
  className,
  fill = "#707A83",
  width = 20,
  height = 20,
}: Props) => (
  <svg
    className={className}
    fill={fill}
    style={{ width, height }}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.9057 17.1925L17.9343 20.3786C17.87 20.4478 17.7922 20.5031 17.7056 20.5408C17.6191 20.5786 17.5256 20.5982 17.4312 20.5983H3.34525C3.27806 20.5983 3.21235 20.5786 3.15615 20.5418C3.09995 20.505 3.05571 20.4526 3.02884 20.391C3.00197 20.3295 2.99363 20.2614 3.00486 20.1952C3.01608 20.1289 3.04638 20.0674 3.09203 20.0181L6.06015 16.832C6.12442 16.7628 6.20225 16.7076 6.28881 16.6698C6.37535 16.632 6.46877 16.6124 6.56322 16.6123H20.6492C20.7169 16.6109 20.7837 16.6296 20.8408 16.666C20.898 16.7024 20.9433 16.755 20.9706 16.817C20.9981 16.879 21.0065 16.9478 20.9949 17.0146C20.9834 17.0814 20.9524 17.1433 20.9057 17.1925ZM17.9343 10.775C17.8698 10.7061 17.7919 10.6511 17.7054 10.6134C17.6189 10.5756 17.5256 10.5559 17.4312 10.5554H3.34525C3.27806 10.5554 3.21235 10.575 3.15615 10.6118C3.09995 10.6487 3.05571 10.7011 3.02884 10.7626C3.00197 10.8242 2.99363 10.8923 3.00486 10.9585C3.01608 11.0247 3.04638 11.0863 3.09203 11.1356L6.06015 14.3234C6.12466 14.3922 6.20255 14.4472 6.28904 14.485C6.37554 14.5228 6.46884 14.5425 6.56322 14.543H20.6492C20.7162 14.5427 20.7816 14.5228 20.8377 14.4859C20.8937 14.4489 20.9376 14.3965 20.9643 14.335C20.9909 14.2735 20.9991 14.2055 20.9877 14.1395C20.9765 14.0734 20.9463 14.012 20.9007 13.9628L17.9343 10.775ZM3.34525 8.48605H17.4312C17.5256 8.48597 17.6191 8.4664 17.7056 8.4286C17.7922 8.39082 17.87 8.33559 17.9343 8.26638L20.9057 5.08028C20.9524 5.03107 20.9834 4.96915 20.9949 4.90233C21.0065 4.83551 20.9981 4.76676 20.9706 4.70474C20.9433 4.64272 20.898 4.59019 20.8408 4.55378C20.7837 4.51736 20.7169 4.49867 20.6492 4.50007H6.56322C6.46877 4.50017 6.37535 4.51973 6.28881 4.55752C6.20225 4.59532 6.12442 4.65054 6.06015 4.71975L3.09203 7.90585C3.04638 7.95513 3.01608 8.01667 3.00486 8.08291C2.99363 8.14915 3.00197 8.21721 3.02884 8.27879C3.05571 8.34037 3.09995 8.39277 3.15615 8.42958C3.21235 8.4664 3.27806 8.48602 3.34525 8.48605Z"
      fill={fill}
    />
  </svg>
)
