import React from "react"
import { Link } from "@/components/common/Link"
import { ACCOUNT_COLLECTION_VERIFICATION_ARTICLE } from "@/constants/support-articles"
import { Modal } from "@/design-system/Modal"
import { useTranslate } from "@/hooks/useTranslate"
import type { VerificationStatus } from "@/lib/helpers/verification"
import { Panel } from "../layout/Panel"

type CollectionStatusModalProps = {
  address?: string
  blockExplorerLink?: string
  verificationStatus: VerificationStatus
}

export const CollectionStatusModal = ({
  address,
  blockExplorerLink,
  verificationStatus,
}: CollectionStatusModalProps) => {
  const t = useTranslate("modals")
  return (
    <>
      <Modal.Header>
        <Modal.Header.Title>
          {t("collectionStatus.title", "What does this mean?")}
        </Modal.Header.Title>
      </Modal.Header>
      <Modal.Body>
        <Panel
          icon="verified"
          iconClassName="text-blue-3"
          id="collection-status-1"
          mode={
            verificationStatus === "VERIFIED" ? "start-open" : "start-closed"
          }
          title={t(
            "collectionStatus.badgedCollection.title",
            "Badged Collection",
          )}
        >
          {t(
            "collectionStatus.verified",
            `This collection belongs to a verified account and has significant
          interest or sales. `,
          )}
          <Link href={ACCOUNT_COLLECTION_VERIFICATION_ARTICLE}>
            {t("common.learn.link", "Learn more")}
          </Link>
          {verificationStatus === "VERIFIED" && address && blockExplorerLink ? (
            <>
              <br />
              <br />
              {t(
                "collectionStatus.mintingContract.description",
                "This token was minted on the smart contract at the address below:",
              )}
              <br />
              <br />
              <Link href={blockExplorerLink}>{address}</Link>
            </>
          ) : null}
        </Panel>
        <br />
        <Panel
          icon="report"
          iconClassName="text-yellow-1"
          iconTheme="outlined"
          id="collection-status-2"
          mode={
            verificationStatus === "MINTABLE" ? "start-open" : "start-closed"
          }
          title={t(
            "collectionStatus.mintableCollection.title",
            "Mintable Collection",
          )}
        >
          {t(
            "collectionStatus.mintableCollection.description",
            `Mintable collections allow users to create their own digital items,
          which could include duplicate or fake versions of items from other
          projects. You should always do your own research, proceed with
          caution, and be certain that the smart contract address of the project
          is exactly what you expect.`,
          )}
          {verificationStatus === "MINTABLE" && address && blockExplorerLink ? (
            <>
              <br />
              <br />
              {t(
                "collectionStatus.mintingContract.link",
                "This token was minted on the smart contract at the address below:",
              )}
              <br />
              <br />
              <Link href={blockExplorerLink}>{address}</Link>
            </>
          ) : null}
        </Panel>
      </Modal.Body>
    </>
  )
}
