import { graphql } from "relay-runtime"
import { getTokenPricePayment } from "@/components/assets/price/TokenPrice.react"
import { readOrderDataToFillPrices_orderDataToFill$data } from "@/lib/graphql/__generated__/readOrderDataToFillPrices_orderDataToFill.graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"

type OrderDataToFillPrices = {
  price: BigNumber
  usdPrice: BigNumber
  tokenPricePayment: ReturnType<typeof getTokenPricePayment>
}

export const readOrderDataToFillPrices = inlineFragmentize<
  readOrderDataToFillPrices_orderDataToFill$data,
  OrderDataToFillPrices
>(
  graphql`
    fragment readOrderDataToFillPrices_orderDataToFill on OrderToFillType
    @inline {
      orderData {
        perUnitPrice {
          usd
          unit
        }
        payment {
          ...TokenPricePayment
        }
      }
      itemFillAmount
    }
  `,
  ({ orderData: { perUnitPrice, payment }, itemFillAmount: quantity }) => {
    const basePrice = bn(perUnitPrice.unit)
    const fiatBasePrice = bn(perUnitPrice.usd)

    const currentPrice = bn(basePrice)

    const fiatCurrentPrice = bn(fiatBasePrice).multipliedBy(quantity)

    const tokenPricePayment = getTokenPricePayment(payment)

    return {
      price: currentPrice,
      usdPrice: fiatCurrentPrice,
      tokenPricePayment,
    }
  },
)
