import { useClickAway, UseClickAwayReturn } from "./useClickAway"
import { useIsOpen, UseIsOpenReturn } from "./useIsOpen"

type UseIsOpenWithClickAwayReturn = UseIsOpenReturn & {
  keepOpenOnClickRefCallback: UseClickAwayReturn["preventClickAwayRefCallback"]
}

/**
 * Returns useIsOpen result and a keepOpenOnClickRefCallback that can be added as
 * a ref to elements to prevent triggering a close when they (or their child elements)
 * are clicked.
 */
export const useIsOpenWithClickAway = <
  TElement extends HTMLElement = HTMLDivElement,
>(
  clickAwayRef: React.RefObject<TElement>,
): UseIsOpenWithClickAwayReturn => {
  const useIsOpenResult = useIsOpen()
  const { preventClickAwayRefCallback: keepOpenOnClickRefCallback } =
    useClickAway<TElement>(clickAwayRef, useIsOpenResult.close)

  return {
    ...useIsOpenResult,
    keepOpenOnClickRefCallback,
  }
}
