import React from "react"
import { Flex, Icon, classNames } from "@opensea/ui-kit"
import { useTheme } from "@/design-system/Context"
import { Image } from "@/design-system/Image"
import { useIsMintYourOwnEnabled } from "@/hooks/useFlag"
import { useRouter } from "@/hooks/useRouter"
import { updateLocaleCookie } from "@/lib/helpers/i18n"
import {
  LinkItem,
  useDropLinks,
  useResourceLinks,
  useStatLinks,
  getSocialLinksWithoutNewsletter,
  TrendingArray,
  LinkArray,
  useLanguageLinks,
  useNavLinks,
} from "@/lib/helpers/links"
import { Link } from "../common/Link"
import { NavItem } from "./NavItem.react"

const TrNavItem = ({ url, label, image, locale }: LinkItem) => {
  const { theme } = useTheme()
  const { asPath, locale: currentLocale } = useRouter()

  return (
    <NavItem
      href={url || asPath}
      locale={locale}
      onClick={() => {
        if (locale) {
          updateLocaleCookie(locale)
        }
      }}
    >
      <Flex className="grow items-center">
        {image ? (
          <Flex className="mr-2">
            <Image
              alt={`${label} image`}
              height={24}
              src={`${image}-${theme}.svg`}
              width={24}
            />
          </Flex>
        ) : null}
        {label}
        {!!locale && currentLocale === locale && (
          <Icon className="ml-auto text-primary" value="check" />
        )}
      </Flex>
    </NavItem>
  )
}

const NavItems = ({ links }: { links: TrendingArray | LinkArray }) => {
  return (
    <>
      {links.map(link =>
        "url" in link ? (
          <TrNavItem
            image={link.image}
            key={link.url}
            label={link.label}
            url={link.url}
          />
        ) : (
          <TrNavItem
            key={link.locale}
            label={link.label}
            locale={link.locale}
          />
        ),
      )}
    </>
  )
}

export const SocialsItem = ({ isMobile }: { isMobile?: boolean }) => {
  const { theme } = useTheme()
  return (
    <Flex
      className={classNames(
        "m-0 w-full max-w-[300px] items-center justify-between bg-elevation-2",
        isMobile ? "h-[30px]" : "h-5",
      )}
    >
      {getSocialLinksWithoutNewsletter({
        width: isMobile ? 30 : 24,
        fill: "text-secondary",
        withHoverChange: true,
        theme,
      }).map(link => (
        <Flex key={link.url}>
          <Link aria-label={link.label} href={link.url} rel="noreferrer">
            {link.logo}
          </Link>
        </Flex>
      ))}
    </Flex>
  )
}

function DropsMenu() {
  const links = useDropLinks()

  return <NavItems links={links} />
}

function StatsMenu() {
  const statLinks = useStatLinks()

  return <NavItems links={statLinks} />
}

function ResourcesMenu() {
  const resourceLink = useResourceLinks()

  return <NavItems links={resourceLink} />
}

function AccountMenu() {
  const links = useNavLinks()
  const isMintYourOwnEnabled = useIsMintYourOwnEnabled()

  return (
    <NavItems
      links={[
        links.profile,
        ...(links.deals ? [links.deals] : []),
        links.watchlist,
        links.favorites,
        ...(isMintYourOwnEnabled ? [links.creatorStudio] : [links.collections]),
        ...(links.create ? [links.create] : []),
      ]}
    />
  )
}

function LanguageMenu() {
  const links = useLanguageLinks()

  return <NavItems links={links} />
}

const navMenus = {
  drops: <DropsMenu />,
  stats: <StatsMenu />,
  resources: <ResourcesMenu />,
  account: <AccountMenu />,
  language: <LanguageMenu />,
}
export default navMenus
