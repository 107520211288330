import React from "react"
import { Text } from "@opensea/ui-kit"
import { Button } from "@/design-system/Button"
import { useTranslate } from "@/hooks/useTranslate"

type WalletPopoverErrorFallbackProps = {
  refreshData: () => void
  resetError: () => void
}

export const WalletPopoverErrorFallback = ({
  refreshData,
  resetError,
}: WalletPopoverErrorFallbackProps) => {
  const t = useTranslate("components")

  const onClickRetry = () => {
    refreshData()
    resetError()
  }

  return (
    <>
      <Text.Body>
        {t(
          "walletPopover.errorFallback.title",
          "An error occurred while fetching wallet data",
        )}
      </Text.Body>
      <Button variant="secondary" onClick={onClickRetry}>
        {t("walletPopover.errorFallback.retry", "Retry")}
      </Button>
    </>
  )
}
