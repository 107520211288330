/**
 * @generated SignedSource<<f96983d14259ec48dde2d1edafb68618>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ensAddressQuery$variables = {
  identifier: string;
};
export type ensAddressQuery$data = {
  readonly findAccount: {
    readonly names: ReadonlyArray<{
      readonly name: string;
    }>;
  } | null;
};
export type ensAddressQuery = {
  response: ensAddressQuery$data;
  variables: ensAddressQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "identifier"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "identifier",
    "variableName": "identifier"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Name",
  "kind": "LinkedField",
  "name": "names",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ensAddressQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "findAccount",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ensAddressQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "findAccount",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e15ee14dbaa2d0cc7c1bafaf14e78323",
    "id": null,
    "metadata": {},
    "name": "ensAddressQuery",
    "operationKind": "query",
    "text": "query ensAddressQuery(\n  $identifier: String!\n) {\n  findAccount(identifier: $identifier) {\n    names {\n      name\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b420ee965d7692726f5ca7b85de91392";

export default node;
