import React, { useCallback, useState } from "react"
import {
  FlexColumn,
  MaterialIcon,
  RadioButtonGroup,
  Text,
} from "@opensea/ui-kit"
import { Button } from "@/design-system/Button"
import { Modal } from "@/design-system/Modal"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { SIGNUP_STEPS } from "@/features/os-wallet/components/constants"
import { WalletTotpCodeModal } from "@/features/os-wallet/components/OSWalletModal/components/WalletTotpCodeModal.react"
import { useIsLoginRefreshEnabled } from "@/features/os-wallet/flags"
import { useTranslate } from "@/hooks/useTranslate"
import { WalletModalHeader } from "./WalletModalHeader.react"
import { WalletPhoneNumberModal } from "./WalletPhoneNumberModal.react"

type MfaValue = "phone" | "totp"

type MfaOption = {
  value: MfaValue
  icon: MaterialIcon
  title: string
  description: string
}

const DEFAULT_OPTION: MfaValue = "totp"

export type WalletMfaEnrollmentModalProps = {
  accessToken: string
  privyId: string
}

export const WalletMfaEnrollmentModal = ({
  accessToken,
  privyId,
}: WalletMfaEnrollmentModalProps) => {
  const t = useTranslate("common")
  const { onNext } = useMultiStepFlowContext()
  const [mfaValue, setMfaValue] = useState<MfaValue>(DEFAULT_OPTION)
  const isLoginRefresh = useIsLoginRefreshEnabled()
  const options: MfaOption[] = [
    {
      value: "totp",
      icon: "gpp_good" as MaterialIcon,
      title: t("wallet.opensea.securityOptionAuthenticator", "Authenticator"),
      description: t(
        "wallet.opensea.securityOptionAuthenticatorDescription",
        "Most secure option",
      ),
    },
    {
      value: "phone",
      icon: "smartphone",
      title: t("wallet.opensea.securityOptionSms", "SMS"),
      description: t(
        "wallet.opensea.securityOptionSmsDescription",
        "US and Canada only",
      ),
    },
  ]

  const handleContinue = useCallback(async () => {
    if (mfaValue === "phone") {
      onNext(() => (
        <WalletPhoneNumberModal accessToken={accessToken} privyId={privyId} />
      ))
    } else {
      onNext(() => (
        <WalletTotpCodeModal accessToken={accessToken} privyId={privyId} />
      ))
    }
  }, [accessToken, mfaValue, onNext, privyId])

  return (
    <Modal.Body
      className="rounded-[12px]"
      data-testid="wallet-auth-modal"
      style={{ padding: 0 }}
    >
      <FlexColumn className="relative max-h-[760px] overflow-auto rounded-t-[12px] bg-base-2">
        <WalletModalHeader
          step={isLoginRefresh ? undefined : 3}
          totalSteps={isLoginRefresh ? undefined : SIGNUP_STEPS}
        >
          <Text.Heading
            asChild
            className="mt-6 max-w-[240px]"
            responsive
            size="small"
          >
            <h2>
              {t(
                "wallet.opensea.chooseSecurityOption",
                "Choose security option to finish signup",
              )}
            </h2>
          </Text.Heading>
        </WalletModalHeader>
        <FlexColumn className="mb-6 mt-2 px-6">
          <RadioButtonGroup
            value={mfaValue}
            onValueChange={value => setMfaValue(value as MfaValue)}
          >
            {options.map(({ value, icon, title, description }) => (
              <RadioButtonGroup.Item
                className="bg-base-1"
                id={value}
                key={value}
                value={value}
              >
                <RadioButtonGroup.Item.Icon
                  className="self-center"
                  size={32}
                  value={icon}
                />
                <RadioButtonGroup.Item.Content>
                  <RadioButtonGroup.Item.Title>
                    {title}
                  </RadioButtonGroup.Item.Title>
                  <RadioButtonGroup.Item.Description weight="regular">
                    {description}
                  </RadioButtonGroup.Item.Description>
                </RadioButtonGroup.Item.Content>
              </RadioButtonGroup.Item>
            ))}
          </RadioButtonGroup>
          <Button className="mt-[104px]" onClick={handleContinue}>
            {t("wallet.opensea.continue.cta", "Continue")}
          </Button>
        </FlexColumn>
      </FlexColumn>
    </Modal.Body>
  )
}
