import { NextPageContext } from "next"
import { FILTER_STATE_KEY } from "@/components/assets/localstorage"
import {
  ACCOUNT_ASSET_CARD_VARIANT_COOKIE_KEY,
  ACCOUNT_TABLE_VIEW_ASSET_CARD_VARIANT_COOKIE_KEY,
  GLOBAL_ASSETS_CARD_VARIANT_COOKIE_KEY,
} from "@/components/search/assets/AssetSearchView/constants"
import {
  AssetCardPage,
  AssetCardVariant,
  FilterState,
} from "@/components/search/assets/AssetSearchView/types"
import { Search } from "@/hooks/useSearch"
import Cookie from "@/lib/cookie"
import { Promiseable } from "@/lib/helpers/promise"

export const mergeTraitFilterState = (
  state: Search,
  updateFilterState: (searchState: Partial<Search>) => Promiseable<unknown>,
  traitName: string,
  traitValue: string,
) => {
  const elem = state.stringTraits?.find(
    x => x.name.toLowerCase() == traitName.toLowerCase(),
  )

  if (state.stringTraits == null) {
    const merged = {
      stringTraits: [{ name: traitName, values: [traitValue] }],
    }
    updateFilterState(merged)
  } else {
    // craft an array with the filtered
    const traitValues =
      elem?.values.filter(v => v.toLowerCase() !== traitValue.toLowerCase()) ??
      []

    const merged = {
      stringTraits: [
        ...state.stringTraits.filter(
          t => t.name.toLowerCase() !== traitName.toLowerCase(),
        ),
        ...(traitValues.length
          ? [
              {
                name: traitName,
                values: traitValues.concat([traitValue]),
              },
            ]
          : [{ name: traitName, values: [traitValue] }]),
      ],
    }
    updateFilterState(merged)
  }
}

export const getCardVariantPageCookie = (
  page: AssetCardPage,
): Cookie<AssetCardVariant | undefined> => {
  const key = (() => {
    switch (page) {
      case "account":
        return ACCOUNT_ASSET_CARD_VARIANT_COOKIE_KEY
      case "account_table_view_supported":
        return ACCOUNT_TABLE_VIEW_ASSET_CARD_VARIANT_COOKIE_KEY
      case "global":
      default:
        return GLOBAL_ASSETS_CARD_VARIANT_COOKIE_KEY
    }
  })()
  return new Cookie<AssetCardVariant | undefined>(key)
}

export const getCardVariantFromCookie = (
  page: AssetCardPage,
  ctx?: NextPageContext,
): AssetCardVariant | undefined => {
  const cardVariantCookie = getCardVariantPageCookie(page)
  return cardVariantCookie.get(ctx)
}

export const updateCardVariantCookie = (
  page: AssetCardPage,
  cardVariant?: AssetCardVariant,
) => {
  const expires = new Date()
  expires.setFullYear(expires.getFullYear() + 1)

  getCardVariantPageCookie(page).set(cardVariant, {
    expires,
    secure: true,
    sameSite: "Lax",
  })
}

export const getFilterStateFromLS = () => {
  if (typeof window === "undefined") {
    return null
  }

  return localStorage.getItem(FILTER_STATE_KEY)
}

export const updateFilterStateLS = (filterState?: FilterState) => {
  localStorage.setItem(FILTER_STATE_KEY, filterState || "open")
}
