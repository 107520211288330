import React from "react"

type Props = {
  className?: string
  width?: number
  fill?: string
}

export const EthereumLogo = ({
  className,
  fill = "gray",
  width = 20,
}: Props) => (
  <svg
    className={className}
    fill="none"
    height="24"
    style={{ width, height: width }}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.527 12.2062L12 16.1938L5.46875 12.2062L12 1L18.527 12.2062ZM12 17.4742L5.46875 13.4867L12 23L18.5312 13.4867L12 17.4742Z"
      fill={fill}
    />
  </svg>
)
