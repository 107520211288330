/**
 * @generated SignedSource<<8383f1dc08192cdc202f1c4501e89e36>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetMediaVideo_asset$data = {
  readonly backgroundColor: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"AssetMediaImage_asset">;
  readonly " $fragmentType": "AssetMediaVideo_asset";
};
export type AssetMediaVideo_asset$key = {
  readonly " $data"?: AssetMediaVideo_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssetMediaVideo_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssetMediaVideo_asset",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backgroundColor",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssetMediaImage_asset"
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "4348c30accb6d42155ae51c121893c59";

export default node;
