import React from "react"
import { useFragment } from "react-relay"
import { useRouter } from "@/hooks/useRouter"
import { AssetMediaImage_asset$key } from "@/lib/graphql/__generated__/AssetMediaImage_asset.graphql"
import { graphql } from "@/lib/graphql/graphql"
import {
  ItemImage,
  ItemImageProps,
} from "../components/ItemImage/ItemImage.react"
import { asBackgroundColor } from "./helpers"

export type AssetMediaImageBaseProps = Omit<
  ItemImageProps,
  "backgroundColor" | "url" | "optimizeImageSizing" | "assetImageUrl"
>

type AssetMediaImageDisplayProps = {
  asset: AssetMediaImage_asset$key
  url: string
}

type AssetMediaImageProps = AssetMediaImageBaseProps &
  AssetMediaImageDisplayProps

export const AssetMediaImage = ({
  asset: assetKey,
  url,
  size,
  width,
  rawImage,
  priority,
  alt,
  mediaStyles,
  objectFit,
  sizes,
  cardDisplayStyle: cardDisplayStyleProp,
  onError,
  height,
}: AssetMediaImageProps) => {
  const asset = useFragment(
    graphql`
      fragment AssetMediaImage_asset on AssetType {
        backgroundColor
        imageUrl
        collection {
          displayData {
            cardDisplayStyle
          }
        }
      }
    `,
    assetKey,
  )

  const { pathname } = useRouter()
  const backgroundColor = asBackgroundColor(asset.backgroundColor)
  const onCollectionPage = pathname && pathname.includes("/collection/")
  const cardDisplayStyle =
    cardDisplayStyleProp ??
    asset.collection.displayData.cardDisplayStyle ??
    "CONTAIN"

  const isSVG = asset.imageUrl?.toLowerCase().endsWith(".svg")
  const svgDefaultMediaStyles = { width: "100%", height: "100%" }

  const mediaStylesOverrides = {
    ...(isSVG ? svgDefaultMediaStyles : {}),
    ...mediaStyles,
  }

  const optimizeImageSizing = !!onCollectionPage

  return (
    <ItemImage
      alt={alt}
      assetImageUrl={asset.imageUrl}
      backgroundColor={backgroundColor}
      cardDisplayStyle={cardDisplayStyle as ItemImageProps["cardDisplayStyle"]}
      height={height}
      mediaStyles={mediaStylesOverrides}
      objectFit={objectFit}
      optimizeImageSizing={optimizeImageSizing}
      priority={priority}
      rawImage={rawImage}
      size={size}
      sizes={sizes}
      url={url}
      width={width}
      onError={onError}
    />
  )
}
