import type { DocumentProvidedProps } from "@/containers/AppProviders"
import { memoizeOne } from "@/lib/helpers/memoization"
import type { IToggle } from "./types"

export const TOGGLES_DOCUMENT_ID = "__OS_TOGGLES__"

const getDocumentToggles = memoizeOne((): IToggle[] => {
  return JSON.parse(
    document.querySelector(`#${TOGGLES_DOCUMENT_ID}`)?.textContent || "{}",
  )
})

export const getToggles = (pageProps: DocumentProvidedProps): IToggle[] => {
  if (typeof window === "undefined") {
    return pageProps.toggles
  }
  return getDocumentToggles()
}
