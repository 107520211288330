import {
  Middleware,
  RelayNetworkLayerResponse,
} from "react-relay-network-modern"

const cacheControlPattern = /(public|private), max-age=(\d+)/

type CacheScope = "private" | "public"

/**
 * Gabriel: there is no way to change the response in the react-relay-network-modern to bubble up new data
 * This sets up an in-memory cache of the responses to be able to read the metadata without adding per-query resolvers.
 *
 * Memory is O(n) with n = distinct query count.
 */

type Metadata = {
  cacheControl: {
    scope: CacheScope
    maxAge: string
  }
}

class MetadataStore {
  private cache: Record<string, Metadata>

  constructor() {
    this.cache = {}
  }

  public set = (key: string, value: Metadata) => {
    this.cache[key] = value
  }

  public clear = () => {
    this.cache = {}
  }

  public get = (key: string): Metadata | undefined => {
    return this.cache[key]
  }
}

export const store = new MetadataStore()

const metadataMiddleware = (): Middleware => {
  return next =>
    async (request): Promise<RelayNetworkLayerResponse> => {
      if (request.isMutation() || request.isFormData()) {
        return next(request)
      }
      const response = await next(request)
      const headers = response.headers as Headers | undefined

      if (!headers || !response.data) {
        return response
      }

      const cacheControlValue = headers.get("cache-control")
      const cacheControlMatch = cacheControlValue?.match(cacheControlPattern)
      if (cacheControlMatch) {
        const scope = cacheControlMatch[1] as CacheScope
        const maxAge = cacheControlMatch[2] as string
        store.set(request.getID(), { cacheControl: { scope, maxAge } })
      }

      return response
    }
}

export default metadataMiddleware()
