/* eslint-disable relay/must-colocate-fragment-spreads */
import { ChainIdentifier } from "@/hooks/useChains/types"
import { transferQuery } from "@/lib/graphql/__generated__/transferQuery.graphql"
import { fetch, graphql } from "@/lib/graphql/graphql"
import { resolvePaymentAsset } from "./paymentAsset"

type ResolveTokenTransferActionProps = {
  sender: string
  recipient: string
  chain: ChainIdentifier
  symbol: string
  quantity: string
}

export const resolveTokenTransferAction = async ({
  sender,
  recipient,
  chain,
  symbol,
  quantity,
}: ResolveTokenTransferActionProps) => {
  const [{ assetRelayId }, disposePaymentAssetQuery] =
    await resolvePaymentAsset(symbol, chain)

  const [
    {
      blockchain: {
        transferActions: { actionsV2 },
      },
    },
    disposeTransferQuery,
  ] = await fetch<transferQuery>(
    graphql`
      query transferQuery(
        $sender: AddressScalar!
        $transferAssetInputs: [TransferAssetInputType!]!
      ) {
        blockchain {
          transferActions(
            sender: $sender
            transferAssetInputs: $transferAssetInputs
          ) {
            actionsV2 {
              __typename
              ... on AssetTransferActionType {
                method {
                  ...useHandleBlockchainActions_transaction
                }
              }
              ...useHandleBlockchainActions_transfer_asset
            }
          }
        }
      }
    `,
    {
      sender,
      transferAssetInputs: [
        {
          recipient,
          assetQuantity: {
            asset: assetRelayId,
            quantity,
          },
        },
      ],
    },
  )
  if (actionsV2.length !== 1) {
    throw new Error("Expected exactly one transfer action")
  }
  if (actionsV2[0].__typename !== "AssetTransferActionType") {
    throw new Error(
      "Expected transfer action to be of type AssetTransferActionType",
    )
  }
  const transferAction = actionsV2[0]

  const dispose = () => {
    disposePaymentAssetQuery?.dispose()
    disposeTransferQuery?.dispose()
  }

  return [transferAction, dispose] as const
}

export const resolveTokenTransferTransaction = async (
  props: ResolveTokenTransferActionProps,
) => {
  const [transferAction, dispose] = await resolveTokenTransferAction(props)
  if (!transferAction.method) {
    throw new Error("No transfer method")
  }
  return [transferAction.method, dispose] as const
}
