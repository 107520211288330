import React, { Suspense } from "react"
import { CenterAligned, Skeleton } from "@opensea/ui-kit"
import {
  graphql,
  PreloadedQuery,
  useFragment,
  usePreloadedQuery,
} from "react-relay"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { SwitchNetworkListContentQuery } from "@/lib/graphql/__generated__/SwitchNetworkListContentQuery.graphql"
import { SwitchNetworkNetworkTotalFundsUsd_data$key } from "@/lib/graphql/__generated__/SwitchNetworkNetworkTotalFundsUsd_data.graphql"
import { NetworkTotalFundsUsdDisplay } from "../NetworkTotalFundsUsdDisplay.react"
import { SWITCH_NETWORK_LIST_CONTENT_QUERY } from "./SwitchNetworkListContent.react"

type SwitchNetworkNetworkTotalFundsUsdBaseProps = {
  queryReference: PreloadedQuery<SwitchNetworkListContentQuery>
  className?: string
  chain: ChainIdentifier
}

const SwitchNetworkNetworkTotalFundsUsdBase = ({
  queryReference,
  className,
  chain,
}: SwitchNetworkNetworkTotalFundsUsdBaseProps) => {
  const data = usePreloadedQuery(
    SWITCH_NETWORK_LIST_CONTENT_QUERY,
    queryReference,
  )

  const {
    wallet: { funds },
  } = useFragment<SwitchNetworkNetworkTotalFundsUsd_data$key>(
    graphql`
      fragment SwitchNetworkNetworkTotalFundsUsd_data on Query
      @argumentDefinitions(address: { type: "AddressScalar!" }) {
        wallet(address: $address) {
          funds {
            ...NetworkTotalFundsUsdDisplay_funds
          }
        }
      }
    `,
    data,
  )

  return (
    <NetworkTotalFundsUsdDisplay
      chain={chain}
      className={className}
      funds={funds}
    />
  )
}

const SwitchNetworkNetworkTotalFundsUsdSkeleton = () => (
  <CenterAligned className="h-6">
    <Skeleton.Line className="h-4 w-[100px]" />
  </CenterAligned>
)

type SwitchNetworkNetworkTotalFundsUsdProps = Omit<
  SwitchNetworkNetworkTotalFundsUsdBaseProps,
  "queryReference"
> & {
  queryReference:
    | PreloadedQuery<SwitchNetworkListContentQuery>
    | undefined
    | null
}

export const SwitchNetworkNetworkTotalFundsUsd = ({
  queryReference,
  ...restProps
}: SwitchNetworkNetworkTotalFundsUsdProps) => {
  if (!queryReference) {
    return <SwitchNetworkNetworkTotalFundsUsdSkeleton />
  }

  return (
    <Suspense fallback={<SwitchNetworkNetworkTotalFundsUsdSkeleton />}>
      <SwitchNetworkNetworkTotalFundsUsdBase
        queryReference={queryReference}
        {...restProps}
      />
    </Suspense>
  )
}
