import { useEffect, useState } from "react"
import { UnreachableCaseError } from "@/lib/helpers/type"
import { captureWarning } from "@/lib/sentry"
import {
  MoonPayApiTransaction,
  MoonPayTransactionStatus,
  TxStatus,
  getMoonPayApiRetrieveExtTxUrl,
} from "../../utils/MoonPayTx"

const MOONPAY_TRANSACTION_STATUS_POLL_INTERVAL = 3_000

type MoonPayApiRetrieveExtTransactionResponse = MoonPayApiTransaction[]

export const useMoonPayTxStatus = ({
  externalTransactionId,
  onTxStatusChange,
}: {
  externalTransactionId: string
  onTxStatusChange?: (
    txStatus: TxStatus,
    apiResponse: MoonPayApiTransaction,
  ) => void
}) => {
  const [lastStatus, setLastStatus] = useState<MoonPayTransactionStatus>()

  useEffect(() => {
    let cancelled = false
    let pollTxStatusInterval: NodeJS.Timer | null = null
    const pollTxStatus = async () => {
      if (!externalTransactionId) {
        return
      }
      const response = await fetch(
        getMoonPayApiRetrieveExtTxUrl(externalTransactionId),
      )
      if (!response.ok) {
        // Transaction does not exist yet
        return
      }
      const apiResponse: MoonPayApiRetrieveExtTransactionResponse =
        await response.json()
      if (apiResponse.length > 1) {
        captureWarning(
          new Error(
            `MoonPay API returned more than one transaction with external id ${externalTransactionId}`,
          ),
        )
      }
      const [{ status, nftTransaction: _nftTransaction }] = apiResponse

      if (!cancelled && status !== lastStatus) {
        switch (status) {
          case "waitingPayment":
          case "pending":
          case "waitingAuthorization":
            onTxStatusChange?.("pending", apiResponse[0])
            break
          case "completed":
            onTxStatusChange?.("successful", apiResponse[0])
            break
          case "failed":
            onTxStatusChange?.("failed", apiResponse[0])
            break
          default:
            throw new UnreachableCaseError(status)
        }
        setLastStatus(status)
      }
    }

    if (externalTransactionId) {
      pollTxStatusInterval = setInterval(
        pollTxStatus,
        MOONPAY_TRANSACTION_STATUS_POLL_INTERVAL,
      )
    }

    return () => {
      cancelled = true
      if (pollTxStatusInterval) {
        // @ts-expect-error not sure why this is failing
        clearInterval(pollTxStatusInterval)
      }
    }
  }, [externalTransactionId, lastStatus, onTxStatusChange])

  return { status: lastStatus }
}
