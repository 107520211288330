import { useCallback } from "react"
import { addBreadcrumb } from "@sentry/nextjs"
import { getTransactionReceipt, waitForTransactionReceipt } from "@wagmi/core"
import { graphql, readInlineData } from "react-relay"
import { Address } from "viem"
import { useConfig } from "wagmi"
import { WALLET_NAME } from "@/constants/wallet"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { useChains } from "@/hooks/useChains"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { TransactionOptions, TransactionResponse } from "@/lib/chain/provider"
import { useTransaction_transaction$key } from "@/lib/graphql/__generated__/useTransaction_transaction.graphql"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"
import { useCreateRequestedTransaction } from "./user-transactions/useCreateRequestedTransaction"
import { useUpdateTransactionStatusToPending } from "./user-transactions/useUpdateTransactionStatusToPending"
import { useUpdateTransactionStatusToRejected } from "./user-transactions/useUpdateTransactionStatusToRejected"
import { useTransactionStatusStore } from "./useTransactionStatusStore"

const USE_TRANSACTION_TRANSACTION_FRAGMENT = graphql`
  fragment useTransaction_transaction on TransactionSubmissionDataType @inline {
    chain {
      identifier
    }
    source {
      value
    }
    destination {
      value
    }
    value
    data
  }
`

export type TransactionHash = string

type TransactionReceipt = {
  blockHash: string
  status: boolean
}

export const useTransaction = () => {
  const t = useTranslate("common")
  const { chain, switchChain, wallet } = useWallet()
  const { showSuccessMessage, showErrorMessage } = useToasts()
  const { getTransactionUrl, getChain } = useChains()
  const updateTransactionStatus = useTransactionStatusStore(
    state => state.updateTransactionStatus,
  )
  const config = useConfig()

  const getTransaction = useCallback(
    async (
      transactionHash: string,
      chain: ChainIdentifier,
    ): Promise<TransactionReceipt | undefined> => {
      try {
        const transaction = await getTransactionReceipt(config, {
          hash: transactionHash as Address,
          chainId: Number(getChain(chain).networkId),
        })

        return {
          blockHash: transaction.blockHash,
          status: transaction.status === "success",
        }
      } catch (error) {
        return undefined
      }
    },
    [config, getChain],
  )

  const pollTransaction = useCallback(
    async ({
      transactionHash,
      chain,
      showMessageOnSuccess,
      showMessageOnError,
    }: {
      transactionHash: string
      chain: ChainIdentifier
      onPoll?: () => unknown
      showMessageOnSuccess?: boolean
      showMessageOnError?: boolean
    }): Promise<TransactionReceipt | undefined> => {
      updateTransactionStatus("pending")
      try {
        const result = await waitForTransactionReceipt(config, {
          hash: transactionHash as Address,
          chainId: Number(getChain(chain).networkId),
        })

        const success = result.status === "success"

        if (success) {
          updateTransactionStatus("success")
          if (showMessageOnSuccess) {
            showSuccessMessage(
              t("transaction.completed", "Transaction completed."),
            )
          }
        } else {
          updateTransactionStatus("error")
          if (showMessageOnError) {
            showErrorMessage(t("transaction.failed", "Transaction failed."))
          }
        }

        return {
          blockHash: result.blockHash,
          status: success,
        }
      } catch (error) {
        updateTransactionStatus("error")
        if (showMessageOnError) {
          showErrorMessage(t("transaction.failed", "Transaction failed."))
        }
        return undefined
      }
    },
    [
      updateTransactionStatus,
      config,
      getChain,
      showSuccessMessage,
      t,
      showErrorMessage,
    ],
  )

  const createRequestedTransaction = useCreateRequestedTransaction()
  const updateTransactionStatusToPending = useUpdateTransactionStatusToPending()
  const updateTransactionStatusToRejected =
    useUpdateTransactionStatusToRejected()
  const transact = useCallback(
    async (
      dataKey: useTransaction_transaction$key,
      transactionOptions?: TransactionOptions,
    ): Promise<TransactionResponse> => {
      const {
        destination,
        value,
        data,
        chain: transactionChain,
      } = readInlineData(USE_TRANSACTION_TRANSACTION_FRAGMENT, dataKey)

      if (!chain || chain !== transactionChain.identifier) {
        await switchChain(getChain(transactionChain.identifier))
      }

      let computedDestination: string | undefined = destination?.value
      // For standard contract deploy actions, destination should be undefined but the resolver returns the zero address.
      // For proxy contract deploy actions, we still want to use the destination.value that is passed in from the resolver.
      if (
        transactionOptions?.isContractDeploy &&
        computedDestination === "0x0000000000000000000000000000000000000000"
      ) {
        computedDestination = undefined
      }

      const transactionData = {
        // Contract deploy actions don't have a destination
        destination: computedDestination,
        value: value ? bn(value) : undefined,
        data: data || undefined,
      }

      addBreadcrumb({
        category: "blockchain",
        message: "Transaction Submit",
        data: transactionData,
        level: "info",
      })

      let transactionResponse: TransactionResponse
      if (wallet.activeAccount?.walletName !== WALLET_NAME.OpenSeaWallet) {
        const userTransactionId = await createRequestedTransaction({
          toAddress: destination?.value ?? "",
          calldata: data ?? "",
          value: value ?? undefined,
          chainId: transactionChain.identifier,
        })

        try {
          transactionResponse = await wallet.transact(
            transactionData,
            transactionOptions,
          )
        } catch (error) {
          await updateTransactionStatusToRejected({ userTransactionId })
          throw error
        }

        await updateTransactionStatusToPending({
          userTransactionId,
          transactionHash: transactionResponse.hash,
          gasLimit: transactionResponse.evmResponse?.gasLimit.toString() ?? "0",
          maxPriorityFeePerGas:
            transactionResponse.evmResponse?.maxPriorityFeePerGas?.toString() ??
            "0",
        })
      } else {
        transactionResponse = await wallet.transact(
          transactionData,
          transactionOptions,
        )
      }

      return transactionResponse
    },
    [
      chain,
      wallet,
      switchChain,
      getChain,
      createRequestedTransaction,
      updateTransactionStatusToPending,
      updateTransactionStatusToRejected,
    ],
  )

  const estimateGas = useCallback(
    async (dataKey: useTransaction_transaction$key): Promise<BigNumber> => {
      const {
        destination,
        value,
        data,
        chain: transactionChain,
      } = readInlineData(USE_TRANSACTION_TRANSACTION_FRAGMENT, dataKey)

      if (chain && chain !== transactionChain.identifier) {
        await switchChain(getChain(transactionChain.identifier))
      }

      return wallet.estimateGas({
        // Contract deploy actions don't have a destination
        destination: destination?.value,
        value: value ? bn(value) : undefined,
        data: data || undefined,
      })
    },
    [chain, getChain, switchChain, wallet],
  )

  const getBlockExplorerLink = useCallback(
    (transactionHash: TransactionHash, overrideChain?: ChainIdentifier) => {
      const chainToUse = overrideChain || chain
      return chainToUse
        ? getTransactionUrl(chainToUse, transactionHash)
        : undefined
    },
    [chain, getTransactionUrl],
  )

  return {
    getTransaction,
    pollTransaction,
    transact,
    estimateGas,
    getBlockExplorerLink,
  }
}
