import React from "react"
import { Separator, InlineFlex } from "@opensea/ui-kit"
import { PreloadedQuery } from "react-relay"
import styled from "styled-components"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { useIsUnifiedBalanceEnabled } from "@/hooks/useFlag"
import { WalletAndAccountButtonQuery } from "@/lib/graphql/__generated__/WalletAndAccountButtonQuery.graphql"
import { isPolygon, isSolana } from "@/lib/helpers/chainUtils"
import { WalletAndAccountButtonFundsDisplay } from "./WalletAndAccountButtonFundsDisplay.react"

type WalletAndAccountButtonBalancesProps = {
  queryReference: PreloadedQuery<WalletAndAccountButtonQuery> | null | undefined
  isBackgroundTransparent: boolean
}

const WalletAndAccountButtonBalancesBase = ({
  queryReference,
  isBackgroundTransparent,
}: WalletAndAccountButtonBalancesProps) => {
  const { chain } = useWallet()

  const isUnifiedBalanceEnabled = useIsUnifiedBalanceEnabled()

  if (isSolana(chain)) {
    return (
      <WalletAndAccountButtonFundsDisplay
        queryReference={queryReference}
        variant="native"
      />
    )
  }

  // Polygon is a special case where native and wrapped currencies should not be totalled
  if (isPolygon(chain)) {
    return (
      <>
        <WalletAndAccountButtonFundsDisplay
          queryReference={queryReference}
          variant="wrapped"
        />
        <Block>
          <Separator
            className={isBackgroundTransparent ? "bg-white/20" : undefined}
            orientation="vertical"
          />
        </Block>
        <WalletAndAccountButtonFundsDisplay
          queryReference={queryReference}
          variant="native"
        />
      </>
    )
  }

  if (isUnifiedBalanceEnabled) {
    return (
      <WalletAndAccountButtonFundsDisplay
        queryReference={queryReference}
        variant="total"
      />
    )
  }

  return (
    <>
      <WalletAndAccountButtonFundsDisplay
        queryReference={queryReference}
        variant="native"
      />
      <Block>
        <Separator
          className={isBackgroundTransparent ? "bg-white/20" : undefined}
          orientation="vertical"
        />
      </Block>
      <WalletAndAccountButtonFundsDisplay
        queryReference={queryReference}
        variant="wrapped"
      />
    </>
  )
}

export const WalletAndAccountButtonBalances = ({
  className,
  ...props
}: WalletAndAccountButtonBalancesProps & {
  className?: string
}) => {
  return (
    <WalletAndAccountButtonContainer className={className}>
      <WalletAndAccountButtonBalancesBase {...props} />
    </WalletAndAccountButtonContainer>
  )
}

const WalletAndAccountButtonContainer = styled(InlineFlex)`
  column-gap: 12px;
`
