import { useTranslate } from "@/hooks/useTranslate"
import { PRODUCT_NAME } from "../../constants"

export const useDefaultTitle: () => string = () => {
  const t = useTranslate("hooks")
  return t(
    "defaultTitle",
    `{{PRODUCT_NAME}}, the largest NFT marketplace`,
    {
      PRODUCT_NAME,
    },
    { forceString: true },
  )
}
export const useDefaultDesc: () => string = () => {
  const t = useTranslate("common")
  return t(
    "defaultDescription",
    "OpenSea is the world's first and largest web3 marketplace for NFTs and crypto collectibles. Browse, create, buy, sell, and auction NFTs using OpenSea today.",
  )
}

export const useHomePageHeading: () => string = () => {
  const t = useTranslate("home")
  return t("homepage.heading", "Discover, collect, and sell extraordinary NFTs")
}

export const useHomePageSubheading: () => string = () => {
  const t = useTranslate("home")
  return t(
    "homepage.subheading",
    "OpenSea is the world's first and largest NFT marketplace",
  )
}

export const useVerifiedOwnershipTitle: () => string = () => {
  const t = useTranslate("common")
  return t("verifiedOwnership", "Ownership verified")
}
