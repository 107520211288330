import * as sidecar from "@/server/sidecar/config"
export * from "./headers"

export const GRAPHQL_API_URL =
  typeof window === "undefined"
    ? process.env.GRAPHQL_SERVER_API_URL ||
      process.env.SERVER_API_URL ||
      "https://api.opensea.io/graphql/"
    : sidecar.getValue("clientGraphqlApiUrl")

export const REST_API_URL =
  typeof window === "undefined"
    ? process.env.REST_SERVER_API_URL ||
      process.env.SERVER_API_URL ||
      "https://api.opensea.io"
    : sidecar.getValue("clientRestApiUrl")

export const ADMIN_URL =
  typeof window === "undefined"
    ? process.env.ADMIN_URL || "https://admin.privatesea.io"
    : sidecar.getValue("clientAdminUrl")

export const SUBSCRIPTION_API_URL =
  typeof window === "undefined"
    ? process.env.SUBSCRIPTION_API_URL || "wss://opensea.io/__api/subscriptions"
    : sidecar.getValue("clientSubscriptionUrl")
