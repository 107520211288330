import { getTrackingFn } from "../utils"

// Where the search results appeared.
export type SearchArea = "dropdown" | "search-page" | "collection-page"

export type SearchType =
  | "account"
  | "asset"
  | "collection"
  | "collection-recent"
  | "collection-drops"
  | "trait"

type SearchEvent = {
  query: string | undefined
  path: string
}

// Tracks when a search query was made without necessarily pressing <enter>.
export const trackNavSearchQuery = getTrackingFn<
  {
    hits: {
      accounts: string[]
      assets: string[]
      collections: string[]
      droppingCollections: string[]
      recentViews: string[]
    }
  } & SearchEvent
>("nav search query loaded")

// Tracks when a search query was made and the user pressed <enter>.
// Can appear before trackNavSearchQuery if the user types quickly
// and presses <enter> immediately after typing a search.
export const trackNavSearchEntered = getTrackingFn<SearchEvent>(
  "nav search query entered",
)

// Tracks when search is open with /
export const trackNavSearchKeyboardShortcut = getTrackingFn<{ key: string }>(
  "nav search slash shortcut",
)

// Tracks when a search query failed for whatever reason.
export const trackNavSearchFailed = getTrackingFn<SearchEvent>(
  "nav search query failed",
)

type ClickSearchResultParams = {
  resultIndex: number
  resultUrl: string
  resultArea: SearchArea
  resultType: SearchType
} & SearchEvent

// Tracks when a search result was clicked.
export const trackNavSearchResultClick = getTrackingFn<ClickSearchResultParams>(
  "nav search query result click",
)

// Tracks when a trait result was clicked.
export const trackNavTraitClick = getTrackingFn<
  {
    collection: string
    traitName: string
    traitValue: string
    resultArea: SearchArea
    resultType: SearchType
  } & SearchEvent
>("nav search trait result click")
