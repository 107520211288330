import React, { Suspense } from "react"
import { ErrorBoundary } from "@sentry/nextjs"
import { Z_INDEX } from "@/constants/zIndex"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { Drawer } from "@/design-system/Drawer"
import { captureNoncriticalError } from "@/lib/sentry"
import { $nav_height } from "../../../styles/variables"
import { ConnectCompatibleWallet } from "./ConnectCompatibleWallet"
import { WalletFunds, WalletFundsView } from "./WalletFunds.react"
import { WalletFundsError } from "./WalletFundsError.react"
import { WalletSidebarHeader } from "./WalletSidebarHeader.react"

export type WalletSidebarProps = {
  close: () => unknown
  onWalletSidebarClickAway: (e: MouseEvent | TouchEvent) => unknown
  isOpen: boolean
  bannerHeight: number
}

export const WalletSidebar = ({
  isOpen,
  close,
  onWalletSidebarClickAway,
  bannerHeight,
}: WalletSidebarProps) => {
  const address = useConnectedAddress()

  return (
    <Drawer
      bannerHeight={bannerHeight}
      data-testid="WalletSidebar"
      isOpen={isOpen}
      navbarOffset={$nav_height}
      zIndex={Z_INDEX.DRAWER_MOBILE + 1}
      onClickAway={onWalletSidebarClickAway}
    >
      {/* @ts-expect-error TODO(@meemaw): somehow broken ~ figure it out */}
      {({ fullscreenBreakpoint }) => (
        <>
          <WalletSidebarHeader
            close={close}
            fullscreenBreakpoint={fullscreenBreakpoint}
          />
          <Drawer.Body data-testid="WalletSidebar--body">
            {address ? (
              <ErrorBoundary
                fallback={({ error, resetError }) => {
                  captureNoncriticalError({
                    ...error,
                    message: `Address: ${address} -- ${error.message}`,
                    cause: error,
                  })
                  return <WalletFundsError resetError={resetError} />
                }}
              >
                <Suspense
                  fallback={<WalletFundsView address={address} data={null} />}
                >
                  <WalletFunds address={address} />
                </Suspense>
              </ErrorBoundary>
            ) : (
              <ConnectCompatibleWallet source="wallet sidebar" />
            )}
          </Drawer.Body>
        </>
      )}
    </Drawer>
  )
}
