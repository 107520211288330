import { OpenSeaWalletSDK, type EIP1193Provider } from "@opensea/wallet-sdk"
import { ethers } from "ethers"
import { WALLET_NAME } from "@/constants/wallet"
import { OS_WALLET_URL } from "@/features/os-wallet/constants"
import { Chain } from "@/hooks/useChains/types"
import Web3EvmProvider, {
  ETHERS_WEB3_PROVIDER_NETWORK,
  Web3Provider,
} from "./web3EvmProvider"

class OpenSeaWalletProvider extends Web3EvmProvider {
  ethereumProvider: EIP1193Provider
  provider: Web3Provider

  constructor(ethereumProvider: EIP1193Provider, chains: Chain[]) {
    super(chains)
    this.ethereumProvider = ethereumProvider
    this.provider = new ethers.providers.Web3Provider(
      ethereumProvider,
      ETHERS_WEB3_PROVIDER_NETWORK,
    )
  }

  disconnect = () => {
    return this.ethereumProvider.disconnect()
  }

  getName = () => {
    return WALLET_NAME.OpenSeaWallet
  }
}

export const createOpenSeaWalletProvider = async (
  chains: Chain[],
): Promise<OpenSeaWalletProvider> => {
  const sdk = await OpenSeaWalletSDK.init({
    url: OS_WALLET_URL,
    detectFrameTimeout: 10_000,
  })
  return new OpenSeaWalletProvider(await sdk.getEthereumProvider(), chains)
}
