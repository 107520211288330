/**
 * @generated SignedSource<<9da69f55f5b1a5a22b2e13500e032c94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useHandleBlockchainActions_cancel_swap_orders$data = {
  readonly method: {
    readonly __typename: "TransactionSubmissionDataType";
    readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_transaction">;
  };
  readonly " $fragmentType": "useHandleBlockchainActions_cancel_swap_orders";
};
export type useHandleBlockchainActions_cancel_swap_orders$key = {
  readonly " $data"?: useHandleBlockchainActions_cancel_swap_orders$data;
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_cancel_swap_orders">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useHandleBlockchainActions_cancel_swap_orders"
};

(node as any).hash = "bf66678cc996ffacca4b05a869521105";

export default node;
