import React from "react"
import { invert } from "polished"

type Props = {
  className?: string
  width?: number
  height?: number
  fill?: string
}

export const OptimismLogo = ({
  className,
  fill = "#707A83",
  width = 20,
  height = 20,
}: Props) => (
  <svg
    className={className}
    fill={fill}
    style={{ width, height }}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5Z"
      fill={fill}
    />
    <path
      d="M8.93957 14.7907C8.31436 14.7907 7.80213 14.6436 7.40283 14.3494C7.00879 14.0499 6.81177 13.6244 6.81177 13.0727C6.81177 12.9571 6.82491 12.8153 6.85116 12.6471C6.9195 12.2689 7.01669 11.8144 7.14277 11.2838C7.50002 9.83898 8.42209 9.11658 9.90893 9.11658C10.3135 9.11658 10.676 9.18487 10.9965 9.32145C11.317 9.45279 11.5691 9.65245 11.753 9.92041C11.9369 10.1831 12.0289 10.4983 12.0289 10.8661C12.0289 10.9764 12.0157 11.1157 11.9894 11.2838C11.9107 11.7514 11.8161 12.2058 11.7057 12.6471C11.5219 13.3669 11.204 13.9055 10.7522 14.2627C10.3003 14.6147 9.69616 14.7907 8.93957 14.7907ZM9.04991 13.6559C9.34416 13.6559 9.59368 13.5692 9.7986 13.3958C10.0088 13.2225 10.1585 12.9571 10.2478 12.5999C10.3686 12.106 10.4606 11.6752 10.5236 11.3074C10.5446 11.1971 10.5551 11.0842 10.5551 10.9685C10.5551 10.4904 10.3056 10.2514 9.80649 10.2514C9.51224 10.2514 9.26007 10.3381 9.04991 10.5114C8.84503 10.6849 8.6979 10.9502 8.60861 11.3074C8.51403 11.6594 8.41944 12.0903 8.3249 12.5999C8.30386 12.7049 8.29336 12.8153 8.29336 12.9308C8.29336 13.4142 8.54557 13.6559 9.04991 13.6559Z"
      fill={invert(fill)}
    />
    <path
      d="M12.3906 14.7119C12.3329 14.7119 12.2882 14.6935 12.2567 14.6567C12.2304 14.6147 12.2225 14.5674 12.233 14.5149L13.3206 9.39236C13.3311 9.33457 13.36 9.28728 13.4073 9.25049C13.4545 9.21374 13.5044 9.19534 13.557 9.19534H15.6533C16.2365 9.19534 16.704 9.31618 17.056 9.55784C17.4133 9.79955 17.592 10.1489 17.592 10.606C17.592 10.7373 17.5762 10.874 17.5447 11.0158C17.4133 11.62 17.148 12.0666 16.7487 12.3555C16.3547 12.6445 15.8135 12.789 15.1253 12.789H14.0614L13.6989 14.5149C13.6883 14.5727 13.6594 14.6199 13.6122 14.6567C13.5649 14.6935 13.5149 14.7119 13.4624 14.7119H12.3906ZM15.1805 11.7014C15.4011 11.7014 15.5929 11.641 15.7557 11.5202C15.9239 11.3993 16.0342 11.226 16.0867 11C16.1025 10.9107 16.1104 10.8319 16.1104 10.7636C16.1104 10.6112 16.0657 10.4957 15.9764 10.4169C15.8871 10.3328 15.7347 10.2908 15.5193 10.2908H14.5736L14.2741 11.7014H15.1805Z"
      fill={invert(fill)}
    />
  </svg>
)
