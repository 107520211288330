import { MoonPayApiTransaction } from "@/features/moonpay"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { getTrackingFn } from "../utils"

export type MakeOfferSource =
  | "item page"
  | "portfolio table collapsed row"
  | "portfolio table expanded row"

export const trackClickMakeOffer = getTrackingFn<{
  assetId: string
  source: MakeOfferSource
}>("click make offer")

export type MoonPayEventParams = {
  externalTransactionId: string
  isCheckout: boolean
  symbol?: string
  chain?: ChainIdentifier
  fiatCurrency?: string
  fiatValue?: number
  widgetUrl?: string
}
export const trackOpenMoonPayModal =
  getTrackingFn<MoonPayEventParams>("open moonpay modal")

export type MoonPayTxEventParams = MoonPayEventParams &
  Pick<
    MoonPayApiTransaction,
    | "cryptoTransactionId"
    | "baseCurrencyId"
    | "currencyId"
    | "quoteCurrencyAmount"
  > & {
    usdAmount: number
  }
export const trackStartMoonPayTx = getTrackingFn<MoonPayTxEventParams>(
  "start moonpay transaction",
)
export const trackMoonPayTxFail = getTrackingFn<MoonPayTxEventParams>(
  "moonpay transaction fail",
)
export const trackMoonPayTxSuccess = getTrackingFn<MoonPayTxEventParams>(
  "moonpay transaction success",
)
export const trackOpenDepositModal = getTrackingFn<{
  address: string
  symbol?: string
}>("open deposit modal")

export const trackConsolidatedCheckoutOpenDepositModal = getTrackingFn<{
  address: string
  symbol: string
}>("open deposit modal - consolidated checkout")

export const trackEnoughForGasFeesPrompt = getTrackingFn(
  "open enough for gas fees modal",
)
