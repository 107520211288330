import React, { useEffect } from "react"
import { Text, Spinner, CenterAligned } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import styled from "styled-components"
import { useFulfillingListingsWillReactivateOrders } from "@/components/trade/InactiveListingsWarning/useFulfillingListingsWillReactivateOrders"
import { Flex } from "@/design-system/Flex"
import { List, ListProps } from "@/design-system/List"
import { trackShowInactiveListing } from "@/features/shopping-cart/utils/analytics"
import { useTranslate } from "@/hooks/useTranslate"
import { OrderList_errors$key } from "@/lib/graphql/__generated__/OrderList_errors.graphql"
import { OrderList_orders$key } from "@/lib/graphql/__generated__/OrderList_orders.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { useShoppingCartViewIsOpen } from "../../utils/ShoppingCartContextProvider"
import { OrderListItem } from "./OrderListItem.react"

type OrderListProps = {
  orders: OrderList_orders$key | null
  errors: OrderList_errors$key | null
  isLoading: boolean
} & ListProps

export const OrderList = ({
  orders: ordersDataKey,
  errors: errorsDataKey,
  isLoading,
  ...listProps
}: OrderListProps) => {
  const isCartOpen = useShoppingCartViewIsOpen()

  const orders = useFragment(
    graphql`
      fragment OrderList_orders on OrderV2Type @relay(plural: true) {
        item {
          ... on AssetType {
            __typename
            relayId
          }

          ... on AssetBundleType {
            __typename
            assetQuantities(first: 30) {
              edges {
                node {
                  asset {
                    relayId
                  }
                }
              }
            }
          }
        }
        relayId
        ...OrderListItem_order
        ...useFulfillingListingsWillReactivateOrders_orders
      }
    `,
    ordersDataKey,
  )

  const errors = useFragment(
    graphql`
      fragment OrderList_errors on BulkPurchaseErrorType @relay(plural: true) {
        originalOrder {
          relayId
        }
        ...OrderListItem_error
      }
    `,
    errorsDataKey,
  )

  const t = useTranslate("bulkPurchase")

  const { assetsWithReactivatableOrders, fulfillingWillReactivateOrders } =
    useFulfillingListingsWillReactivateOrders({
      listingsDataKey: orders,
      skip: !isCartOpen,
    })

  useEffect(() => {
    if (fulfillingWillReactivateOrders) {
      trackShowInactiveListing({
        numInactiveListings: assetsWithReactivatableOrders.length,
      })
    }
  }, [assetsWithReactivatableOrders.length, fulfillingWillReactivateOrders])

  if (!orders) {
    return null
  }

  if (isLoading) {
    return (
      <CenterAligned className="my-10">
        <Spinner size="medium" />
      </CenterAligned>
    )
  }

  if (!orders.length) {
    return (
      <Flex
        height="100%"
        justifyContent="center"
        marginY="40px"
        textAlign="center"
      >
        <Text.Body asChild className="text-secondary" size="medium">
          <div>{t("empty", "Add items to get started.")}</div>
        </Text.Body>
      </Flex>
    )
  }

  return (
    <StyledList
      className="h-full min-h-[100px]"
      showBorder={false}
      variant="framed"
      {...listProps}
    >
      {orders.map(order => (
        <OrderListItem
          error={
            errors?.find(
              error => error.originalOrder.relayId === order.relayId,
            ) ?? null
          }
          hasReactivatableListing={
            !fulfillingWillReactivateOrders
              ? false
              : order.item.__typename === "AssetBundleType"
              ? getNodes(order.item.assetQuantities)
                  .map(({ asset }) => asset.relayId)
                  .some(relayId =>
                    assetsWithReactivatableOrders.includes(relayId),
                  )
              : order.item.__typename === "AssetType"
              ? Boolean(
                  assetsWithReactivatableOrders.includes(order.item.relayId),
                )
              : false
          }
          key={order.relayId}
          order={order}
        />
      ))}
    </StyledList>
  )
}

// This is so the list can ignore parent padding
const StyledList = styled(List)`
  margin-left: -24px;
  width: calc(100% + 48px);
`
