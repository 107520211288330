import { getBrowserContext } from "./contexts/BrowserTrackingContext"
import { getMetaContext } from "./contexts/MetaTrackingContext"
import type { TrackingContextType } from "./types"

export const getDefaultTrackingContext = (): TrackingContextType => {
  return {
    Browser: getBrowserContext(),
    Meta: getMetaContext(),
  }
}
