/**
 * @generated SignedSource<<af31fc04f60b37f3a3b28d9dcfbde8b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConfirmationItem_extra_payment_asset$data = {
  readonly symbol: string;
  readonly usdSpotPrice: number;
  readonly " $fragmentType": "ConfirmationItem_extra_payment_asset";
};
export type ConfirmationItem_extra_payment_asset$key = {
  readonly " $data"?: ConfirmationItem_extra_payment_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmationItem_extra_payment_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConfirmationItem_extra_payment_asset",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "symbol",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usdSpotPrice",
      "storageKey": null
    }
  ],
  "type": "PaymentAssetType",
  "abstractKey": null
};

(node as any).hash = "679a1d0082417a380c8ad3c6546462e9";

export default node;
