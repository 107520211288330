/**
 * @generated SignedSource<<c952cd4a26764a7488bf6aadefca2670>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useHandleBlockchainActions_set_transfer_validator$data = {
  readonly method: {
    readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_transaction">;
  };
  readonly " $fragmentType": "useHandleBlockchainActions_set_transfer_validator";
};
export type useHandleBlockchainActions_set_transfer_validator$key = {
  readonly " $data"?: useHandleBlockchainActions_set_transfer_validator$data;
  readonly " $fragmentSpreads": FragmentRefs<"useHandleBlockchainActions_set_transfer_validator">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useHandleBlockchainActions_set_transfer_validator"
};

(node as any).hash = "0476fa98a34f6e2da394e997fe6fb7ce";

export default node;
