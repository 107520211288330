import { useCallback } from "react"
import {
  useConnectedAddress,
  useWallet,
} from "@/containers/WalletProvider/WalletProvider.react"
import { useChains } from "@/hooks/useChains"
import { BridgeOrWrapBalancesQuery$variables } from "@/lib/graphql/__generated__/BridgeOrWrapBalancesQuery.graphql"
import { isPolygon, getEthereumChain } from "@/lib/helpers/chainUtils"

export const useGetQueryArgs = (): (() =>
  | BridgeOrWrapBalancesQuery$variables
  | undefined) => {
  const { chain } = useWallet()
  const connectedAddress = useConnectedAddress()
  const { getNativeCurrencySymbol, getWrappedCurrencySymbol } = useChains()

  return useCallback(() => {
    if (!chain || !connectedAddress) {
      return undefined
    }

    const baseCurrencySymbol = isPolygon(chain)
      ? "ETH"
      : getNativeCurrencySymbol(chain)
    const baseCurrencyChain = isPolygon(chain) ? getEthereumChain() : chain

    return {
      address: connectedAddress,
      wrappedCurrencySymbol: getWrappedCurrencySymbol(chain),
      wrappedCurrencyChain: chain,
      baseCurrencySymbol,
      baseCurrencyChain,
      baseCurrencyIsChainNativeCurrency:
        baseCurrencySymbol === getNativeCurrencySymbol(chain),
    }
  }, [
    chain,
    getNativeCurrencySymbol,
    getWrappedCurrencySymbol,
    connectedAddress,
  ])
}
