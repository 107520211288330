import React, { useEffect } from "react"
import Script from "next/script"
import { useRouter } from "@/hooks/useRouter"
import { GOOGLE_ANALYTICS_ID } from "../../constants"

type GAEventParams = {
  label: string
  action: string
  category: string
}

const formatLabel = (label: GAEventParams["label"]) => {
  const mightBeEmail = label.indexOf("@") !== -1

  if (mightBeEmail) {
    console.warn("[gtag]: This arg looks like an email address, redacting.")
    return "REDACTED (Potential Email Address)"
  }

  return label
}

const trackPageView = (path: string) => {
  window.gtag("config", GOOGLE_ANALYTICS_ID, {
    page_path: path,
  })
  window.gtag("set", GOOGLE_ANALYTICS_ID, {
    page: path,
  })
}

const trackEvent = (
  { action, category, label }: GAEventParams,
  hitCallback: () => void,
) => {
  if (typeof window.gtag === "function") {
    let safetyCallbackCalled = false
    const safetyCallback = () => {
      // Prevent callback firing twice in case of GA delayed response
      safetyCallbackCalled = true
      hitCallback()
    }

    const callbackTimer = setTimeout(safetyCallback, 250)

    const clearableCallback = () => {
      clearTimeout(callbackTimer)
      if (!safetyCallbackCalled) {
        hitCallback()
      }
    }

    window.gtag("event", action, {
      event_label: formatLabel(label),
      event_category: category,
      hit_callback: clearableCallback,
    })
  } else {
    // Proceed with callback even if gtag couldn't be initialized
    setTimeout(hitCallback, 0)
  }
}

export const trackOutboundLink = (
  label: GAEventParams["label"],
  callback: () => void,
) => {
  trackEvent(
    {
      action: "Click",
      category: "Outbound",
      label,
    },
    callback,
  )
}

type Props = {
  nonce?: string
}

export const GoogleAnalytics = ({ nonce }: Props) => {
  const router = useRouter()

  useEffect(() => {
    trackPageView(router.asPath)
  }, [router.asPath])

  return (
    <>
      <Script
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');
n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-KRLPHCL');`,
        }}
        id="gtag-manager"
        nonce={nonce}
      />
      <Script
        nonce={nonce}
        src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`}
        strategy="afterInteractive"
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${GOOGLE_ANALYTICS_ID}', {
                  page_path: window.location.pathname,
                });
              `,
        }}
        id="gtag-init"
        nonce={nonce}
        strategy="afterInteractive"
      />
    </>
  )
}
