// We have backend constraints limiting the number of accounts that can be authenticated
// due to the way we store sessions and max header size: https://opensea.slack.com/archives/C054H3EDVK9/p1696951063305369
export const MAX_AUTHENTICATED_ACCOUNTS_COUNT = 4

export const SESSION_STORAGE_KEY_PREFIX = "session_"
export const WALLET_ADDRESS_STORAGE_KEY_POSTFIX = "_wallet_address"

export const getSessionStorageKey = (address: string) => {
  return `${SESSION_STORAGE_KEY_PREFIX}${address}`
}

export const getWalletAddressStorageKey = (address: string) => {
  return `${address}${WALLET_ADDRESS_STORAGE_KEY_POSTFIX}`
}
