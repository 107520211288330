import React from "react"
import { Text, FlexColumn } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { StackedAssetMedia } from "@/components/assets/StackedAssetMedia"
import { Block } from "@/design-system/Block"
import { Modal } from "@/design-system/Modal"
import { PriceText } from "@/design-system/PriceText"
import { GaslessCancelLearnMoreLink } from "@/features/cancel-orders/components/GaslessCancelLearnMoreLink.react"
import {
  trackConfirmGaslessCancel,
  trackOpenGaslessCancellationModal,
} from "@/features/cancel-orders/utils/analytics"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { CancelOrderActionGaslessContent_action$key } from "@/lib/graphql/__generated__/CancelOrderActionGaslessContent_action.graphql"
import { filter } from "@/lib/helpers/array"

type CancelOrderActionGaslessContentProps = {
  action: CancelOrderActionGaslessContent_action$key
  attemptAction: () => Promise<void>
}

export const CancelOrderActionGaslessContent = ({
  action: actionDataKey,
  attemptAction,
}: CancelOrderActionGaslessContentProps) => {
  const t = useTranslate("components")

  useMountEffect(() => {
    trackOpenGaslessCancellationModal()
  })

  const { ordersData } = useFragment(
    graphql`
      fragment CancelOrderActionGaslessContent_action on CancelOrderActionType {
        ordersData {
          side
          orderType
          item {
            ... on AssetQuantityDataType {
              asset {
                displayName
                ...StackedAssetMedia_assets
              }
            }
          }
          price {
            unit
            symbol
          }
          orderCriteria {
            collection {
              name
              representativeAsset {
                ...StackedAssetMedia_assets
              }
            }
          }
        }
      }
    `,
    actionDataKey,
  )

  const orderCount = ordersData.length
  const firstOrderData = ordersData[0]

  const side = firstOrderData.side
  const price = firstOrderData.price

  const assets = filter(
    ordersData.map(orderData =>
      orderData.orderType === "CRITERIA"
        ? orderData.orderCriteria?.collection.representativeAsset
        : orderData.item?.asset,
    ),
  )

  const title =
    side === "ASK"
      ? t(
          "gaslessCancel.actionModal.title.listing",
          {
            "0": "Cancel listings?",
            one: "Cancel this listing?",
            other: "Cancel listings?",
          },
          { count: orderCount },
        )
      : t(
          "gaslessCancel.actionModal.title.offer",
          {
            "0": "Cancel offers?",
            one: "Cancel this offer?",
            other: "Cancel offers?",
          },
          { count: orderCount },
        )

  const isCollectionOffer = firstOrderData.orderType === "CRITERIA"
  const itemTerm = isCollectionOffer
    ? firstOrderData.orderCriteria?.collection.name
    : firstOrderData.item?.asset?.displayName

  const promptTranslationProps = {
    itemTerm: (
      <Text.Body className="text-secondary" size="medium" weight="semibold">
        {itemTerm}
      </Text.Body>
    ),
    price: (
      <PriceText
        className="text-secondary"
        size="medium"
        symbol={price.symbol}
        unit={price.unit}
        weight="semibold"
      />
    ),
    learnMoreLink: <GaslessCancelLearnMoreLink />,
    count: orderCount,
  }

  const singleOrderPrompt = isCollectionOffer
    ? t(
        "gaslessCancel.collectionOffer.single",
        "This will cancel your collection offer on {{itemTerm}} for {{price}} on OpenSea for free. {{learnMoreLink}}",
        promptTranslationProps,
      )
    : side === "ASK"
    ? t(
        "gaslessCancel.listing.single",
        "This will cancel your listing for {{itemTerm}} for {{price}} on OpenSea for free. {{learnMoreLink}}",
        promptTranslationProps,
      )
    : t(
        "gaslessCancel.offer.single",
        "This will cancel your offer for {{itemTerm}} for {{price}} on OpenSea for free. {{learnMoreLink}}",
        promptTranslationProps,
      )

  // No special prompt for collection offers since there could be a mix of
  // collection offers and single item offers.
  const multipleOrderPrompt =
    side === "ASK"
      ? t(
          "gaslessCancel.listing.multiple",
          {
            "0": "This will cancel {{count}} listings on OpenSea for free. {{learnMoreLink}}",
            one: "This will cancel {{count}} listing on OpenSea for free. {{learnMoreLink}}",
            other:
              "This will cancel {{count}} listings on OpenSea for free. {{learnMoreLink}}",
          },
          promptTranslationProps,
        )
      : t(
          "gaslessCancel.offer.multiple",
          {
            "0": "This will cancel {{count}} offers on OpenSea for free. {{learnMoreLink}}",
            one: "This will cancel {{count}} offer on OpenSea for free. {{learnMoreLink}}",
            other:
              "This will cancel {{count}} offers on OpenSea for free. {{learnMoreLink}}",
          },
          promptTranslationProps,
        )

  return (
    <>
      <Modal.Header></Modal.Header>
      <Modal.Body className="text-center" paddingX="64px">
        <Block marginTop="8px">
          <StackedAssetMedia assets={assets} />
        </Block>
        <FlexColumn className="mt-8 gap-2">
          <Modal.Body.Title>{title}</Modal.Body.Title>
          <Text.Body className="text-secondary" size="medium">
            {orderCount === 1 ? singleOrderPrompt : multipleOrderPrompt}
          </Text.Body>
        </FlexColumn>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button
          variant="primary"
          onClick={() => {
            trackConfirmGaslessCancel()
            attemptAction()
          }}
        >
          {t("gaslessCancel.actionModal.confirm", "Confirm")}
        </Modal.Footer.Button>
      </Modal.Footer>
    </>
  )
}
