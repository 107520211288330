import React, { useCallback, useState } from "react"
import { graphql, useFragment } from "react-relay"
import { useContextSelector } from "use-context-selector"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { CancelOrdersActionModal } from "@/features/cancel-orders/components/CancelOrdersActionModal/CancelOrdersActionModal.react"
import { GaslessCancellationFailedModal } from "@/features/cancel-orders/components/CancelOrdersActionModal/GaslessCancellationFailedModal.react"
import { GaslessCancellationProcessingModal } from "@/features/cancel-orders/components/CancelOrdersActionModal/GaslessCancellationProcessingModal.react"
import { CancelOrderContext } from "@/features/cancel-orders/context/CancelOrderContext.react"
import { useGaslessCancelOrderToasts } from "@/features/cancel-orders/hooks/useGaslessCancelOrderToasts.react"
import { getCancellationId } from "@/features/cancel-orders/utils/getCancellationId"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { CancelOrderAction_data$key } from "@/lib/graphql/__generated__/CancelOrderAction_data.graphql"
import { filter } from "@/lib/helpers/array"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"
import { UnreachableCaseError } from "@/lib/helpers/type"
import { useTransaction } from "../../useTransaction"
import { BaseBlockchainActionProps } from "../BlockchainActionModalContent.react"
import { useBlockchainActionProgress } from "../useBlockchainActionProgress"
import { useHandleBlockchainActions } from "../useHandleBlockchainActions"
import { CancelOrderActionGaslessContent } from "./components/CancelOrderActionGaslessContent.react"
import { CancelOrderActionOnChainContent } from "./components/CancelOrderActionOnChainContent.react"

type Props = BaseBlockchainActionProps & {
  dataKey: CancelOrderAction_data$key
}

export const CancelOrderAction = ({ dataKey, onEnd, onError }: Props) => {
  const t = useTranslate("components")
  const { showErrorMessage } = useToasts()

  const addCancellation = useContextSelector(
    CancelOrderContext,
    v => v.addCancellation,
  )
  const { showCancelOrderCompletedToast } = useGaslessCancelOrderToasts()

  const actions = useFragment(
    graphql`
      fragment CancelOrderAction_data on CancelOrderActionType
      @relay(plural: true) {
        __typename
        ordersData {
          orderType
          side
          item {
            ... on AssetQuantityDataType {
              asset {
                ...GaslessCancellationProcessingModal_items
                ...GaslessCancellationFailedModal_items
              }
              quantity
            }
          }
          orderCriteria {
            collection {
              representativeAsset {
                ...GaslessCancellationProcessingModal_items
                ...GaslessCancellationFailedModal_items
              }
            }
            quantity
          }
        }
        method {
          __typename
        }
        ...CancelOrderActionOnChainContent_action
        ...useHandleBlockchainActions_cancel_orders
        ...CancelOrderActionGaslessContent_action
      }
    `,
    dataKey,
  )

  const [actionIndex, setActionIndex] = useState(0)
  const { cancelOrders } = useHandleBlockchainActions()
  const { pollTransaction } = useTransaction()
  const { showSuccessMessage } = useToasts()
  const [transactionHash, setTransactionHash] = useState<string>()
  const { onReplace } = useMultiStepFlowContext()

  const { ordersData } = actions[actionIndex]

  const orderType = ordersData[0].orderType
  const side = ordersData[0].side

  const isEnglish = orderType === "ENGLISH"
  const isCriteria = orderType === "CRITERIA"

  const successMessage =
    side === "ASK"
      ? t(
          "orders.canceled.listing",
          {
            0: "Your listings have been canceled.",
            one: "Your listing has been canceled.",
            other: "Your listings have been canceled.",
          },
          { count: ordersData.length },
        )
      : isEnglish
      ? t(
          "orders.canceled.english",
          {
            0: "Your bids have been canceled.",
            one: "Your bid has been canceled.",
            other: "Your bids have been canceled.",
          },
          { count: ordersData.length },
        )
      : isCriteria
      ? t(
          "orders.canceled.criteria",
          {
            0: "Your collection offers have been canceled.",
            one: "Your collection offer has been canceled.",
            other: "Your collection offers have been canceled.",
          },
          { count: ordersData.length },
        )
      : t(
          "orders.canceled.offer",
          {
            0: "Your offers have been canceled.",
            one: "Your offer has been canceled.",
            other: "Your offers have been canceled.",
          },
          { count: ordersData.length },
        )

  const assets = filter(
    ordersData.map(orderData =>
      orderData.orderType === "CRITERIA"
        ? orderData.orderCriteria?.collection.representativeAsset
        : orderData.item?.asset,
    ),
  )

  const initialRemainingQuantity = BigNumber.sum(
    ...ordersData.map(orderData =>
      bn(orderData.item?.quantity ?? orderData.orderCriteria?.quantity ?? 1),
    ),
  )

  const executeAction = useCallback(async () => {
    const onComplete = () => {
      if (actionIndex === actions.length - 1) {
        onEnd()
      } else {
        setActionIndex(index => index + 1)
      }
    }

    if (actionIndex >= actions.length) {
      onEnd()
    }

    const cancelOrderResult = await cancelOrders(actions[actionIndex])

    if (!cancelOrderResult) {
      // This can only happen when cancelling Solana orders
      onComplete()
      return
    }

    if ("transactionHash" in cancelOrderResult) {
      // On-chain cancellation
      setTransactionHash(cancelOrderResult.transactionHash)

      await pollTransaction({ ...cancelOrderResult })

      setTransactionHash("")

      showSuccessMessage(successMessage)
    } else if ("statuses" in cancelOrderResult) {
      // Gasless cancellation
      const cancelOnChain = () => {
        onReplace(
          <CancelOrdersActionModal
            gaslessCancelEligibleOrders={false}
            orders={Array.from(cancelOrderResult.unfinalizedOrders)}
            onEnd={onEnd}
            onError={
              onError ??
              ((error?: Error) => {
                showErrorMessage(
                  error?.message ||
                    t(
                      "cancelOrder.error",
                      "Something went wrong while cancelling.",
                    ),
                )
              })
            }
          />,
        )
      }

      if (cancelOrderResult.ordersAwaitingFinalization.size) {
        // Set result in context to start polling for finalization
        addCancellation(cancelOrderResult, initialRemainingQuantity)
        // Replace with processing modal
        onReplace(
          <GaslessCancellationProcessingModal
            cancelOnChain={cancelOnChain}
            cancellationId={getCancellationId(cancelOrderResult)}
            items={assets}
            orderSide={side}
            onDone={onEnd}
            onFail={() => {
              onReplace(<GaslessCancellationFailedModal items={assets} />)
            }}
          />,
        )
        return
      }

      const totalCount = Object.keys(cancelOrderResult.statuses).length

      if (cancelOrderResult.failedCount === totalCount) {
        // Replace with failure modal
        onReplace(
          <GaslessCancellationFailedModal
            cancelOnChain={cancelOnChain}
            items={assets}
            retry={() => {
              onReplace(
                <CancelOrderAction
                  dataKey={dataKey}
                  onEnd={onEnd}
                  onError={onError}
                />,
              )
            }}
          />,
        )
        return
      }

      showCancelOrderCompletedToast({
        totalCount,
        failedCount: cancelOrderResult.failedCount,
        fulfilledCount: cancelOrderResult.fulfilledCount,
        canceledQuantity: cancelOrderResult.canceledQuantity,
        ordersSide: cancelOrderResult.ordersSide,
        initialRemainingQuantity,
      })
    } else {
      throw new UnreachableCaseError(cancelOrderResult)
    }

    onComplete()
  }, [
    actionIndex,
    actions,
    cancelOrders,
    onEnd,
    pollTransaction,
    showSuccessMessage,
    successMessage,
    showCancelOrderCompletedToast,
    initialRemainingQuantity,
    onReplace,
    onError,
    showErrorMessage,
    t,
    assets,
    dataKey,
    addCancellation,
    side,
  ])

  const action = actions[actionIndex]

  const isGaslessCancel = action.method.__typename === "GaslessCancelType"

  const { progress, attemptAction } = useBlockchainActionProgress({
    executeAction,
    action,
    autoProgress: !isGaslessCancel,
  })

  if (isGaslessCancel) {
    return (
      <CancelOrderActionGaslessContent
        action={action}
        attemptAction={attemptAction}
      />
    )
  }

  return (
    <CancelOrderActionOnChainContent
      action={action}
      attemptAction={attemptAction}
      numActions={actions.length}
      progress={progress}
      transactionHash={transactionHash}
    />
  )
}
