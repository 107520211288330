import React, { CSSProperties, useCallback, useState } from "react"
import {
  Media,
  Breakpoint,
  getBreakpointPixelValue,
  Text,
  SpaceBetween,
} from "@opensea/ui-kit"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { isFunction, noop } from "lodash"
import { createPortal } from "react-dom"
import { useUpdateEffect, useClickAway } from "react-use"
import styled, { css } from "styled-components"
import { variant } from "styled-system"
import { Z_INDEX } from "@/constants/zIndex"
import { Overlay, OverlayProps } from "@/design-system/Overlay"
import { useCallbackRef } from "@/hooks/useCallbackRef"
import { useIsMounted } from "@/hooks/useIsMounted"
import { useLayoutEffect } from "@/hooks/useIsomorphicLayoutEffect"
import { usePortalNode } from "@/hooks/usePortalNode/usePortalNode"
import { Block, BlockProps } from "../Block"
import { DRAWER_WIDTH_FULLSCREEN } from "./constants"

type ChildrenProps = {
  fullscreenBreakpoint: Breakpoint
}

export type DrawerProps = BlockProps & {
  transitionDuration?: number
  transitionTimingFunction?: string
  isOpen: boolean
  isVisible?: boolean
  onClickAway: (event: MouseEvent | TouchEvent) => void
  onClosed?: () => void
  children: ((props: ChildrenProps) => React.ReactNode) | React.ReactNode
  fullscreenBreakpoint?: Breakpoint
  navbarOffset?: string
  anchorSide?: "left" | "right" | "bottom"
  className?: string
  bannerHeight?: number
  overrides?: {
    Overlay: {
      props: Partial<OverlayProps>
    }
  }
} & Pick<CSSProperties, "left" | "top" | "right" | "bottom" | "zIndex">

export const renderChildren = (
  children: DrawerProps["children"],
  props: ChildrenProps,
) => {
  return isFunction(children) ? children(props) : children
}

function getDefaultTranslationByAnchorSide(
  anchorSide: DrawerProps["anchorSide"],
) {
  if (anchorSide === "left" || anchorSide === "right") {
    return `translate3d( ${anchorSide === "right" ? "100%" : "-100%"}, 0, 0)`
  }

  // bottom anchored
  return `translate3d(0, 100%, 0)`
}

const DrawerBase = ({
  anchorSide = "right",
  bannerHeight = 0,
  className,
  isOpen,
  isVisible: isVisibleProp,
  children,
  onClickAway,
  onClosed,
  transitionDuration = 0.3,
  fullscreenBreakpoint = "lg",
  navbarOffset = "0px",
  overrides,
  ...rest
}: DrawerProps) => {
  const [isClosing, setIsClosing] = useState(false)
  const [ref, setRef] = useCallbackRef<HTMLDivElement>()
  const isVisible = isVisibleProp ?? (isClosing || isOpen)
  const isMounted = useIsMounted()

  useClickAway(ref, isOpen ? onClickAway : noop)

  // TODO(dragonbear-os): This is a hack to work around https://github.com/willmcpo/body-scroll-lock/issues/237
  // This replicates useLockBodyScroll and adds the insanity with requestAnimationFrame. By making
  // RAF a no-op during the disableBodyScroll call we prevent the library from scrolling the
  // body to the top while on mobile.
  // The aforementioned library has a PR open to fix the problem but it has been months without
  // being merged.
  const element = ref.current
  useLayoutEffect(() => {
    if (!element) {
      return
    }

    if (isOpen) {
      const storedRAF = window.requestAnimationFrame
      window.requestAnimationFrame = () => 42
      disableBodyScroll(element)
      window.requestAnimationFrame = storedRAF
    } else {
      enableBodyScroll(element)
    }

    return () => {
      enableBodyScroll(element)
    }
  }, [isOpen, element])

  const clearIsClosing = useCallback(() => {
    setIsClosing(false)
    onClosed?.()
  }, [setIsClosing, onClosed])

  useUpdateEffect(() => {
    setIsClosing(!isOpen)
    ref.current?.addEventListener("transitionend", clearIsClosing)
    return () => {
      ref.current?.removeEventListener("transitionend", clearIsClosing)
    }
  }, [isOpen])

  const node = usePortalNode()

  const content = (
    <>
      <ContainerDiv
        $bannerHeight={`${bannerHeight}px`}
        $fullscreenBreakpoint={getBreakpointPixelValue(fullscreenBreakpoint)}
        $navbarOffset={navbarOffset}
        anchorSide={anchorSide}
        className={className}
        isOpen={isOpen}
        isVisible={isVisible}
        ref={setRef}
        transitionDuration={transitionDuration}
        {...rest}
      >
        {isVisible && renderChildren(children, { fullscreenBreakpoint })}
      </ContainerDiv>
      {isMounted && (
        <Media greaterThanOrEqual={fullscreenBreakpoint}>
          {mediaClassNames => (
            <Overlay
              active={isOpen}
              className={mediaClassNames}
              transitionDuration={transitionDuration}
              {...overrides?.Overlay.props}
            />
          )}
        </Media>
      )}
    </>
  )

  return node ? createPortal(content, node) : content
}

const Body = styled(Block)``

Body.defaultProps = {
  padding: "20px",
}

// TODO: Remove styled-component styling. Currently needed in the 'ContainerDiv' styling.
const Header = styled(SpaceBetween)`
  padding: 20px;
  border-bottom: 1px solid
    ${props => props.theme.colors.components.border.level2};
`

const Title = styled(Text.Body).attrs({ size: "medium", weight: "semibold" })``

const Subtitle = styled(Text.Body).attrs({ size: "small" })`
  display: flex;
`

const ContainerDiv = styled(Block).attrs<DrawerProps>(props => ({
  as: props.as ?? "aside",
}))<
  DrawerProps & {
    anchorSide: NonNullable<DrawerProps["anchorSide"]>
    $navbarOffset: string
    $bannerHeight: string
    $fullscreenBreakpoint: number
  }
>`
  position: fixed;

  ${props =>
    variant({
      prop: "anchorSide",

      variants: {
        left: {
          left: props.left ?? 0,
        },
        right: {
          right: props.right ?? 0,
        },
        bottom: {
          left: props.left ?? 0,
          right: props.right ?? 0,
        },
      },
    })};

  bottom: ${props => props.bottom ?? 0};

  top: ${({ $navbarOffset, $bannerHeight }) =>
    $bannerHeight !== "0px"
      ? `calc(${$bannerHeight} + ${$navbarOffset})`
      : undefined};

  z-index: ${props => props.zIndex ?? Z_INDEX.DRAWER};

  @media (max-width: ${props => props.$fullscreenBreakpoint - 1}px) {
    width: 100%;
    z-index: ${props => props.zIndex ?? Z_INDEX.DRAWER_MOBILE};
    border-width: 0px;
  }

  height: ${({ $navbarOffset, $bannerHeight, bottom }) =>
    `calc(100% - ${$bannerHeight} - ${$navbarOffset} - ${bottom ?? "0px"})`};

  background-color: ${props =>
    props.theme.type === "light"
      ? props.theme.colors.components.elevation.level2.background
      : props.theme.colors.components.elevation.level1.regular.background};
  overflow: auto;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  transition: ${({ transitionDuration }) =>
    transitionDuration
      ? `transform ${transitionDuration}s, opacity ${transitionDuration}s`
      : undefined};
  transition-timing-function: ${({ transitionTimingFunction }) =>
    transitionTimingFunction ? `${transitionTimingFunction}` : undefined};

  ${({ anchorSide, isOpen, isVisible }) =>
    isOpen
      ? css`
          visibility: visible;
          transform: translate3d(0, 0, 0);
          opacity: ${isVisible === undefined || isVisible ? 1 : 0};
        `
      : css`
          visibility: visible;
          transform: ${getDefaultTranslationByAnchorSide(anchorSide)};
          opacity: ${isVisible === undefined || !isVisible ? 0 : 1};
        `}

  ${Body} {
    padding-bottom: ${({ $navbarOffset }) => `${$navbarOffset}`};
    height: calc(100% - ${({ $navbarOffset }) => $navbarOffset});
  }

  ${Header} {
    height: ${({ $navbarOffset }) =>
      $navbarOffset === "0px" ? undefined : $navbarOffset};
  }

  ${Header} + ${Body} {
    height: calc(100% - calc(${({ $navbarOffset }) => $navbarOffset} * 2));
  }
`

ContainerDiv.defaultProps = {
  width: `${DRAWER_WIDTH_FULLSCREEN}px`,
}

export const Drawer = Object.assign(DrawerBase, {
  Header,
  Title,
  Subtitle,
  Body,
})
