/* eslint-disable relay/unused-fields */
import { useCallback, useMemo } from "react"
import { graphql } from "relay-runtime"
import { useTranslate } from "@/hooks/useTranslate"
import type { useChainsQuery } from "@/lib/graphql/__generated__/useChainsQuery.graphql"
import { fetch } from "@/lib/graphql/graphql"
import { isCanonicalChainIdentifier } from "@/lib/helpers/chainUtils"
import { memoizeOne } from "@/lib/helpers/memoization"
import { keys } from "@/lib/helpers/object"
import { useChainContext } from "./context"
import type { Chain, ChainIdentifier } from "./types"
import {
  createChainByIdentifierLookup,
  isChainOnMatchingNetwork,
} from "./utils"

const RELOAD_CHAINS_INTERVAL = 300000 // 5 minutes

const _loadChains = async () => {
  const [data] = await fetch<useChainsQuery>(
    graphql`
      query useChainsQuery {
        chains {
          identifier
          displayName
          publicRpcUrl
          networkId
          websiteUrl
          logo
          network
          isEvm
          canonicalChain
          description
          shortDescription
          isMintingToolSupported
          showFilters
          blockExplorer {
            url
            transactionUrlTemplate
            addressUrlTemplate
            name
          }
          nativeCurrency {
            symbol
            name
            decimals
          }
          wrappedCurrency {
            symbol
          }
          nativeCurrencyPaymentAsset {
            usdPrice
          }
        }
      }
    `,
    {},
  )

  return data.chains as Chain[]
}

export const loadChains = memoizeOne(_loadChains, {
  ttl: RELOAD_CHAINS_INTERVAL,
})

export const useChains = () => {
  const { chains: allKnownChains } = useChainContext()

  const identifierToChain = useMemo(
    () => createChainByIdentifierLookup(allKnownChains),
    [allKnownChains],
  )

  const getChain = useCallback(
    (chain: ChainIdentifier) => identifierToChain[chain],
    [identifierToChain],
  )

  const _isChainOnMatchingNetwork = useCallback(
    (chain: ChainIdentifier) => isChainOnMatchingNetwork(getChain(chain)),
    [getChain],
  )

  const chainIdentifiers = useMemo(
    () => keys(identifierToChain),
    [identifierToChain],
  )

  const isChainEnabled = useCallback(
    (chain: ChainIdentifier) => _isChainOnMatchingNetwork(chain),
    [_isChainOnMatchingNetwork],
  )

  const enabledChainIdentifiers = useMemo(
    () => chainIdentifiers.filter(chain => isChainEnabled(chain)),
    [chainIdentifiers, isChainEnabled],
  )

  const isChainSupportingMintingTool = useCallback(
    (chainIdentifier: ChainIdentifier) => {
      return getChain(chainIdentifier).isMintingToolSupported
    },
    [getChain],
  )

  const getChainName = useCallback(
    (chain: ChainIdentifier) => {
      const c = getChain(chain)

      // A scenario exists where we launch a chain but it's not public yet, in which case it might be undefined
      // eslint-disable-next-line
      if (c) {
        return c.displayName
      }

      return ""
    },
    [getChain],
  )

  const getTransactionUrl = useCallback(
    (chain: ChainIdentifier, hash: string) => {
      return getChain(chain).blockExplorer.transactionUrlTemplate.replace(
        ":hash",
        hash,
      )
    },
    [getChain],
  )

  const getBlockExplorerAddressUrl = useCallback(
    (chain: ChainIdentifier, address: string) => {
      return getChain(chain).blockExplorer.addressUrlTemplate.replace(
        ":address",
        address,
      )
    },
    [getChain],
  )

  const getBlockExplorerName = useCallback(
    (chain: ChainIdentifier) => {
      return getChain(chain).blockExplorer.name
    },
    [getChain],
  )

  const getWebsiteUrl = useCallback(
    (chain: ChainIdentifier) => getChain(chain).websiteUrl,
    [getChain],
  )

  const getChainLogo = useCallback(
    (chain: ChainIdentifier) => getChain(chain).logo,
    [getChain],
  )

  const isEvmChain = useCallback(
    (chain: ChainIdentifier) => {
      const c = getChain(chain)

      // A scenario exists where we launch a chain but it's not public yet, in which case it might be undefined
      // eslint-disable-next-line
      if (c) {
        return c.isEvm
      }

      return false
    },
    [getChain],
  )

  const getCanonicalChainIdentifier = useCallback(
    (chain: ChainIdentifier) => getChain(chain).canonicalChain,
    [getChain],
  )

  const isChainFiltersEnabled = useCallback(
    (chainIdentifier: ChainIdentifier) => {
      return getChain(chainIdentifier).showFilters
    },
    [getChain],
  )

  const getWrappedCurrencySymbol = useCallback(
    (chain: ChainIdentifier) => getChain(chain).wrappedCurrency.symbol,
    [getChain],
  )

  const getNativeCurrencySymbol = useCallback(
    (chain: ChainIdentifier) => getChain(chain).nativeCurrency.symbol,
    [getChain],
  )

  const getNativeCurrencyDecimals = useCallback(
    (chain: ChainIdentifier) => getChain(chain).nativeCurrency.decimals,
    [getChain],
  )

  return {
    chains: enabledChainIdentifiers,
    isChainEnabled,
    isChainSupportingMintingTool,
    getChainName,
    getTransactionUrl,
    getBlockExplorerAddressUrl,
    getBlockExplorerName,
    getWebsiteUrl,
    getChainLogo,
    getChain,
    isEvmChain,
    getCanonicalChainIdentifier,
    isChainFiltersEnabled,
    getWrappedCurrencySymbol,
    getNativeCurrencySymbol,
    getNativeCurrencyDecimals,
  }
}

export const useCurrencyDescription = (
  symbol: string,
  chain: ChainIdentifier,
) => {
  const t = useTranslate("hooks")
  const { getChainName } = useChains()
  return symbol
    ? `${symbol}${
        !isCanonicalChainIdentifier(chain)
          ? t(
              "chains.currencyDescription",
              ` on {{chainName}}`,
              {
                chainName: getChainName(chain),
              },
              { forceString: true },
            )
          : ""
      }`
    : t("chains.currencyUnknown", "Unknown currency")
}

export const walletAddEthereumChainParameters = (
  chainId: string,
  chain: Chain,
) => {
  return {
    chainId,
    rpcUrls: [chain.publicRpcUrl],
    chainName: chain.displayName,
    nativeCurrency: {
      name: chain.nativeCurrency.name,
      symbol: chain.nativeCurrency.symbol,
      decimals: chain.nativeCurrency.decimals,
    },
    blockExplorerUrls: [chain.blockExplorer.url],
  }
}
