export const PAGE_VIEW_TRACKED_QUERY_PARAM_KEYS: string[] = [
  // Marketing
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
  // Rankings page
  "sortBy",
  "chain",
  "category",
]

// Discreet Honeypot URL
export const HONEYPOT_PROXY_URL = "https://os1-a9xwj.quill.run/js"

// Rollout Plan: (1%, 5%, 25%, 60%, and 100%)
export const ROLLOUT_PERCENTAGE = 100
