import React, { useEffect, useState } from "react"
import { CenterAligned, Text, Alert } from "@opensea/ui-kit"
import { graphql } from "relay-runtime"
import { css } from "styled-components"
import { Link } from "@/components/common/Link"
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from "@/constants/index"
import {
  useActiveAccount,
  useWallet,
} from "@/containers/WalletProvider/WalletProvider.react"
import { Modal } from "@/design-system/Modal"
import { AnimatedOSLogo } from "@/features/os-wallet/components/OSWalletModal/components/refresh/AnimatedOSLogo.react"
import { AcceptTermsOfServiceModalMutation } from "@/lib/graphql/__generated__/AcceptTermsOfServiceModalMutation.graphql"
import { walletQuery } from "@/lib/graphql/__generated__/walletQuery.graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"

export const AcceptTermsOfServiceModal = () => {
  const activeAccount = useActiveAccount()
  const { logout, wallet } = useWallet()
  const { mutate } = useGraphQL()
  const [pendingSince, setPendingSince] = useState<Date | null>(null)
  const [showWarning, setShowWarning] = useState(false)

  useEffect(() => {
    const handler = setInterval(() => {
      if (
        pendingSince &&
        pendingSince.valueOf() < new Date().valueOf() - 5000
      ) {
        setShowWarning(true)
        clearInterval(handler)
      }
    }, 1000)
    return () => clearInterval(handler)
  }, [pendingSince, setPendingSince, setShowWarning])

  const setTermsAcceptance = async () => {
    setPendingSince(new Date())
    await mutate<AcceptTermsOfServiceModalMutation>(
      graphql`
        mutation AcceptTermsOfServiceModalMutation(
          $input: UserModifyMutationInput!
        ) {
          users {
            modify(input: $input) {
              relayId
              username
              publicUsername
              hasAffirmativelyAcceptedOpenseaTerms
              email
            }
          }
        }
      `,
      {
        input: {
          hasAffirmativelyAcceptedOpenseaTerms: true,
        },
      },
      {
        shouldAuthenticate: true,
        updater: async (store, data) => {
          if (activeAccount) {
            const accountRecord = store.get<walletQuery>(activeAccount.relayId)

            const userRecord = store.get<AcceptTermsOfServiceModalMutation>(
              data.users.modify.relayId,
            )

            if (userRecord) {
              accountRecord?.setLinkedRecord(userRecord, "user")
            }

            await wallet.updateUser(data.users.modify)
          }
        },
      },
    )
  }

  return (
    <Modal isOpen overrides={{ Dialog: { props: { $css: fadeInStyles } } }}>
      <Modal.Header />
      <Modal.Body>
        <CenterAligned>
          <AnimatedOSLogo size={140} />
        </CenterAligned>
        <Modal.Body.Title className="my-6">Welcome to OpenSea</Modal.Body.Title>
        <Text className="text-center">
          By connecting your wallet and using OpenSea, you agree to our{" "}
          <Link href={TERMS_OF_SERVICE_URL} target="_blank">
            Terms of Service
          </Link>{" "}
          and{" "}
          <Link href={PRIVACY_POLICY_URL} target="_blank">
            Privacy Policy
          </Link>
          .
        </Text>
        {showWarning && (
          <Alert className="-mb-6 mt-6">
            <Alert.Icon color="success" value="info" />
            <Alert.Content>
              <Alert.Title>
                Check your wallet for a signature request
              </Alert.Title>
            </Alert.Content>
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button variant="secondary" onClick={logout}>
          Cancel
        </Modal.Footer.Button>
        <Modal.Footer.Button
          disabled={!!pendingSince}
          onClick={setTermsAcceptance}
        >
          Accept and sign
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  )
}

const fadeInStyles = css`
  animation: fadeIn ease-in-out 1.25s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    /* Delay showing modal until 0.75s */
    60% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`
