/**
 * @generated SignedSource<<6fe638322279e168533c1acc2c87fb95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CollectionDeleteMutationInput = {
  clientMutationId?: string | null;
  collection: string;
};
export type CollectionManagerDeleteModalMutation$variables = {
  input: CollectionDeleteMutationInput;
};
export type CollectionManagerDeleteModalMutation$data = {
  readonly collections: {
    readonly delete: {
      readonly relayId: string;
    };
  };
};
export type CollectionManagerDeleteModalMutation = {
  response: CollectionManagerDeleteModalMutation$data;
  variables: CollectionManagerDeleteModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CollectionManagerDeleteModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CollectionMutationType",
        "kind": "LinkedField",
        "name": "collections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CollectionType",
            "kind": "LinkedField",
            "name": "delete",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CollectionManagerDeleteModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CollectionMutationType",
        "kind": "LinkedField",
        "name": "collections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CollectionType",
            "kind": "LinkedField",
            "name": "delete",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7395597f28ee805e03c149ff62465b17",
    "id": null,
    "metadata": {},
    "name": "CollectionManagerDeleteModalMutation",
    "operationKind": "mutation",
    "text": "mutation CollectionManagerDeleteModalMutation(\n  $input: CollectionDeleteMutationInput!\n) {\n  collections {\n    delete(input: $input) {\n      relayId\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2a471d7f5633ce86ea3c48c6b2cefdd8";

export default node;
