/**
 * @generated SignedSource<<dfedc3274d31fdd766f16d562b766869>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionCardContextMenu_data$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CollectionManagerDeleteModal_data" | "collection_url">;
  readonly " $fragmentType": "CollectionCardContextMenu_data";
};
export type CollectionCardContextMenu_data$key = {
  readonly " $data"?: CollectionCardContextMenu_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionCardContextMenu_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionCardContextMenu_data",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "collection_url",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCategory",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CollectionManagerDeleteModal_data"
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "ddeb2f3d6db00e626059e4a681c79776";

export default node;
