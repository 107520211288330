import ServerCookies from "cookies"
import Cookies, { CookieAttributes } from "js-cookie"
import type { GetServerSidePropsContext, NextPageContext } from "next"

export default class Cookie<T> {
  key: string

  constructor(key: string) {
    this.key = key
  }

  get(context?: NextPageContext | GetServerSidePropsContext): T | undefined {
    if (context?.req && context.res) {
      const cookie = new ServerCookies(context.req, context.res).get(this.key)
      if (!cookie) {
        return undefined
      }
      try {
        return JSON.parse(decodeURIComponent(cookie))
      } catch (_) {
        return undefined
      }
    }

    const cookie = Cookies.get(this.key)
    if (!cookie) {
      return undefined
    }

    try {
      return JSON.parse(cookie)
    } catch (_) {
      return undefined
    }
  }

  /**
   * Set cookie value
   * @param {T} value
   * @param {CookieAttributes} options
   * @returns {string | undefined} stringified cookie value
   */
  set = (value: T, options?: CookieAttributes) => {
    return Cookies.set(this.key, JSON.stringify(value), options)
  }

  /**
   * Remove cookie
   * @param {CookieAttributes} options
   */
  remove = (options?: CookieAttributes) => {
    return Cookies.remove(this.key, options)
  }
}
