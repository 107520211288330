import React from "react"
import { Flex, Text, classNames } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import {
  formatCollectionVolume,
  formatCollectionValueChange,
} from "@/features/rankings/components/RankingsPage/utils"
import { useTranslate } from "@/hooks/useTranslate"
import { GlobalStatsSubtitle_data$key } from "@/lib/graphql/__generated__/GlobalStatsSubtitle_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { bn } from "@/lib/helpers/numberUtils"
import {
  EMPTY_PRICE_DISPLAY,
  STATS_POSITIVE_PRICE_DISPLAY,
} from "../../../constants"

type Props = {
  dataKey: GlobalStatsSubtitle_data$key
}

export const GlobalStatsSubtitle = ({ dataKey }: Props) => {
  const data = useFragment(
    graphql`
      fragment GlobalStatsSubtitle_data on CollectionType {
        statsV2 {
          oneDayVolume {
            eth
            symbol
          }
          oneDayChange
        }
      }
    `,
    dataKey,
  )

  const t = useTranslate("components")
  const { oneDayVolume } = data.statsV2
  const volumeChange = bn(data.statsV2.oneDayChange)
  const formattedVolume = formatCollectionVolume(bn(oneDayVolume.eth))
  const volumeChangeString = formatCollectionValueChange(
    volumeChange.times(100),
  )
  const isVolumeChangePositive = volumeChange.isGreaterThan(0)
  const isVolumeChangeZero = volumeChange.isEqualTo(0)

  return (
    <Flex className="pl-4">
      <Text.Body className="text-secondary" size="medium">
        {t(
          "globalcollectioncard.stats.volume",
          "24h volume: {{ volume }} {{ symbol }} ",
          { volume: formattedVolume, symbol: oneDayVolume.symbol },
        )}
      </Text.Body>
      <Flex
        className={classNames(
          "pl-2",
          isVolumeChangePositive ? "text-success" : "text-error",
        )}
      >
        {isVolumeChangeZero ? (
          <>{EMPTY_PRICE_DISPLAY}</>
        ) : (
          <>
            {isVolumeChangePositive && <>{STATS_POSITIVE_PRICE_DISPLAY}</>}

            <Text.Body
              color={isVolumeChangePositive ? "success" : "error"}
              size="medium"
            >
              {volumeChangeString}%
            </Text.Body>
          </>
        )}
      </Flex>
    </Flex>
  )
}
