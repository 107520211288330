import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { useChains } from "@/hooks/useChains"
import { ChainIdentifier } from "@/hooks/useChains/types"
import {
  useUsdValueSortedTokenQuantities_walletFunds$data,
  useUsdValueSortedTokenQuantities_walletFunds$key,
} from "@/lib/graphql/__generated__/useUsdValueSortedTokenQuantities_walletFunds.graphql"
import { filter } from "@/lib/helpers/array"
import { isPolygon } from "@/lib/helpers/chainUtils"
import { BigNumber, bn } from "@/lib/helpers/numberUtils"

type TokenQuantity = {
  quantity: BigNumber
  symbol: string
  chain: ChainIdentifier
  usdValue: BigNumber
}

export const useUsdValueSortedTokenQuantities = (
  walletFundsDataKey: useUsdValueSortedTokenQuantities_walletFunds$key,
  chain: ChainIdentifier,
): TokenQuantity[] => {
  const { getNativeCurrencySymbol, getWrappedCurrencySymbol } = useChains()
  const walletFunds =
    useFragment<useUsdValueSortedTokenQuantities_walletFunds$key>(
      graphql`
        fragment useUsdValueSortedTokenQuantities_walletFunds on WalletFundsType
        @relay(plural: true) {
          quantity
          usdPrice
          chain
          symbol
        }
      `,
      walletFundsDataKey,
    )

  const tokenQuantities = walletFunds.map(createTokenQuantity)

  const nativeCurrencySymbol = getNativeCurrencySymbol(chain)
  const nativeTokenQuantity = tokenQuantities.find(
    tokenQuantity => tokenQuantity.symbol === nativeCurrencySymbol,
  )

  const wrappedCurrencySymbol = getWrappedCurrencySymbol(chain)
  const wrappedTokenQuantity = tokenQuantities.find(
    tokenQuantity => tokenQuantity.symbol === wrappedCurrencySymbol,
  )

  const sortedTokenQuantities = tokenQuantities
    .filter(
      tokenQuantity =>
        tokenQuantity !== nativeTokenQuantity &&
        tokenQuantity !== wrappedTokenQuantity,
    )
    .sort((a, b) => {
      return b.usdValue.comparedTo(a.usdValue)
    })

  if (isPolygon(chain)) {
    return filter([
      wrappedTokenQuantity,
      nativeTokenQuantity,
      ...sortedTokenQuantities,
    ])
  }

  return filter([
    nativeTokenQuantity,
    wrappedTokenQuantity,
    ...sortedTokenQuantities,
  ])
}

const createTokenQuantity = (
  fund: useUsdValueSortedTokenQuantities_walletFunds$data[number],
) => {
  return {
    quantity: bn(fund.quantity),
    symbol: fund.symbol,
    chain: fund.chain,
    usdValue: bn(fund.quantity).times(fund.usdPrice ?? 0),
  }
}
