import React, { ComponentProps, ElementRef, forwardRef } from "react"
import {
  AvatarProps as DSAvatarProps,
  Avatar as DSAvatar,
} from "@opensea/ui-kit"
import { Image } from "../Image"

export type AvatarProps = DSAvatarProps

export const AvatarImage = ({
  alt,
  placeholder: _placeholder,
  width: _width,
  height: _height,
  size,
  ...rest
}: ComponentProps<NonNullable<AvatarProps["renderImage"]>>) => {
  return <Image.Next13 alt={alt ?? ""} height={size} width={size} {...rest} />
}

export const Avatar = forwardRef<ElementRef<typeof DSAvatar>, AvatarProps>(
  function Avatar(props: AvatarProps, ref) {
    return <DSAvatar ref={ref} {...props} renderImage={AvatarImage} />
  },
)
