import React from "react"
import { CenterAligned, FlexColumn, Text } from "@opensea/ui-kit"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { useTranslate } from "@/hooks/useTranslate"

export const AccountSuspendedContent = () => {
  const t = useTranslate("components")
  const connectedAddress = useConnectedAddress()

  return (
    <CenterAligned className="xl:px-[20%] xl:py-6">
      <Text.Display asChild className="text-center" size="medium">
        <h1>
          {t(
            "accountSuspended.title",
            "Your account isn’t available right now",
          )}
        </h1>
      </Text.Display>

      <FlexColumn className="mt-10 gap-4">
        <Text.Body className="text-center" size="medium">
          {t(
            "accountSuspended.description",
            "Your account associated with wallet address {{connectedAddress}} isn’t available right now. We’re working on it, and hope to have things back up and running soon, so please check back later!",
            { connectedAddress },
          )}
        </Text.Body>
        <Text.Body className="text-center" size="medium">
          {t(
            "accountSuspended.appreciate",
            "We appreciate your patience and understanding.",
          )}
        </Text.Body>
      </FlexColumn>
    </CenterAligned>
  )
}
