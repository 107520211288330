import React from "react"
import { FlexColumn } from "@opensea/ui-kit"
import { Image } from "@/design-system/Image"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { useTranslate } from "@/hooks/useTranslate"
import { WalletModalHeaderButton } from "./WalletModalHeaderButton.react"
import { WalletStepIndicator, WalletSteps } from "./WalletStepIndicator.react"

type Props = {
  children?: React.ReactNode
} & Partial<WalletSteps>

export const WalletModalHeader = ({ children, step, totalSteps }: Props) => {
  const t = useTranslate("common")
  const { onPrevious } = useMultiStepFlowContext()
  return (
    <>
      <div className="absolute h-40 w-full">
        <Image.Next13
          alt=""
          fill
          priority
          src="/static/images/wallet/background-wallet.png"
          style={{ objectFit: "cover" }}
        />
      </div>
      {step && totalSteps && (
        <WalletStepIndicator
          className="absolute top-[18px] h-9 w-full"
          step={step}
          totalSteps={totalSteps}
        />
      )}
      {onPrevious && (
        <WalletModalHeaderButton
          aria-label={t("wallet.opensea.back", "Back")}
          className="absolute left-[18px] top-[18px]"
          icon="chevron_left"
          onClick={onPrevious}
        />
      )}
      <FlexColumn className="w-full bg-base-2 px-6 pb-6 pt-[74px]">
        <div>
          <div className="relative box-content h-[60px] w-[60px] rounded-full border-[3px] border-base-2 bg-base-2">
            <Image.Next13
              alt=""
              fill
              priority
              src="/static/images/wallet/logo-wallet.svg"
            />
          </div>
        </div>
        {children}
      </FlexColumn>
    </>
  )
}
