export const LAST_CLICKED_BEST_ASK_DATA_KEY = "lastClickedBestAskData"

const FROM_BUY_WITH_CARD_FILTER = "fromBuyWithCardFilter"
/**
 * Returns a key that stores the search state for the search that last produced
 * a click through. It is used and reset on the item page (page click leads to)
 * in the `useFiatAvailability` hook.
 * @param itemId relayId of asset or asset bundle that was clicked
 */
export const getFromBuyWithCardFilterKey = (itemId: string) => {
  return `${FROM_BUY_WITH_CARD_FILTER}-${itemId}`
}

export const FILTER_STATE_KEY = "searchFilterState"
