import React from "react"

type Props = {
  className?: string
  width?: number
  height?: number
  fill?: string
}

export const ArbitrumNovaLogo = ({
  className,
  fill = "#707A83",
  width = 12,
  height = 12,
}: Props) => (
  <svg
    className={className}
    fill={fill}
    style={{ width, height }}
    viewBox="0 0 2000 1359.88"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M693.83,0H510.66c-12.65,0-23.94,7.91-28.28,19.79L0.92,1339.67c-3.58,9.82,3.69,20.21,14.14,20.21h183.17
	c12.65,0,23.94-7.91,28.28-19.78L707.97,20.21C711.55,10.39,704.28,0,693.83,0z M916.62,553.04c-4.81-13.19-23.46-13.19-28.28,0
	L793.38,813.4c-2.43,6.66-2.43,13.97,0,20.63l184.6,506.06c4.33,11.88,15.63,19.78,28.28,19.78h183.17
	c10.45,0,17.72-10.39,14.14-20.21L916.62,553.04z M1383.57,807.88c4.81,13.19,23.46,13.19,28.27,0l287.32-787.67
	c3.58-9.82-3.69-20.21-14.14-20.21h-183.17c-12.65,0-23.94,7.9-28.28,19.79L1288.6,526.88c-2.43,6.66-2.43,13.96,0,20.62
	L1383.57,807.88z M1022.41,19.65C1018.12,7.85,1006.9,0,994.35,0H810.56c-12.64,0-23.93,7.9-28.27,19.78L300.84,1339.67
	c-3.58,9.82,3.69,20.21,14.14,20.21h183.18c12.65,0,23.94-7.9,28.28-19.78L895.06,329.52c2.42-6.63,11.79-6.63,14.2,0
	l368.63,1010.58c4.33,11.88,15.63,19.78,28.28,19.78h183.18c10.45,0,17.72-10.39,14.14-20.2L1022.41,19.65z M1984.94,0h-183.19
	c-12.64,0-23.94,7.9-28.27,19.78l-334.93,918.2c-2.43,6.66-2.43,13.96,0,20.62l94.98,260.38c4.81,13.19,23.46,13.19,28.27,0
	l331.55-908.93L1999.08,20.2C2002.66,10.39,1995.39,0,1984.94,0z"
    />
  </svg>
)
